(function () {
    'use strict';

    function geckoResponseMapDirective() {

        return {
            restrict: 'E',
            templateUrl: '/components/gecko-response-map/gecko-response-map.html',
            scope: {
                geoip: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($attrs) {
                var ctrl = this;

                if (!ctrl.title) {
                    ctrl.title = 'Map';
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoResponseMap', geckoResponseMapDirective);
})();