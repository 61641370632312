(function () {
    'use strict';

    function OrganiationsRoute($stateProvider) {
        $stateProvider.state('organisations', {
            url: "/organisations?{page}&{order}&{sort}&{search}&{organisation_type_id}",
            templateUrl: "/modules/organisations/views/index.84075416.html",
            controller: 'OrganisationsListCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisations',
            requires: [Gecko.ABILITY_ORGANISATIONS_VIEW],
            resolve: {
                organisationsQuery: function organisationsQuery($stateParams) {
                    var organisations = new Gecko.Organisation().include('organisation_type').includeCount('enrolments');

                    if ($stateParams.search) organisations.search($stateParams.search);

                    if ($stateParams.organisation_type_id) organisations.where('organisation_type_id', $stateParams.organisation_type_id);

                    if ($stateParams.order) organisations.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : null);

                    return organisations.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page).rfields({ organisation: ['field_1', 'field_2', 'field_3', 'field_4', 'field_5'] });
                },
                data: function data(organisationsQuery) {
                    var _query = {};

                    // Organisations
                    _query.organisations = organisationsQuery;

                    // Field List
                    _query.fieldList = new Gecko.Field().where('view_type', Gecko.Field.FIELD_TYPE_ORGANISATION).path('list_view');

                    return Gecko.multi(_query);
                },
                organisations: function organisations(data) {
                    return data.organisations;
                },
                fieldList: function fieldList(data) {
                    return data.fieldList;
                },
                selectedData: function selectedData(asyncOptions, $stateParams) {
                    return asyncOptions.getSelectedValues({
                        types: { model: 'OrganisationType', id: $stateParams.organisation_type_id, fields: { field: ['title'] } }
                    });
                }
            }
        }).state('organisation', {
            url: "/organisation/{organisation_id:int}",
            templateUrl: "/modules/organisations/views/view.6993482b.html",
            controller: 'OrganisationCtrl',
            controllerAs: 'ctrl',
            page_title: 'View Organisation',
            requires: [Gecko.ABILITY_ORGANISATIONS_VIEW],
            resolve: {
                organisation: function organisation($stateParams) {
                    if ($stateParams.organisation_id < 1) {
                        throw "Invalid organisation id";
                    }
                    return new Gecko.Organisation().include('organisation_type', 'current_values.field').get($stateParams.organisation_id);
                },
                organisationTypes: function organisationTypes() {
                    return new Gecko.OrganisationType().perPage('all').get();
                }
            }
        }).state('organisation.overview', {
            url: "/overview?{page}&{order}&{sort}",
            templateUrl: "/modules/organisations/views/overview.db8ea4ad.html",
            controller: 'OrganisationOverviewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisation Overview',
            requires: [Gecko.ABILITY_ORGANISATIONS_VIEW, Gecko.ABILITY_CONTACTS_VIEW],
            editRequires: [Gecko.ABILITY_ORGANISATIONS_UPDATE],
            resolve: {
                data: function data(geckoDataService) {
                    return geckoDataService.fetch(['organisation_types', 'organisation_fields', 'field_list_contacts']);
                },
                organisationTypes: function organisationTypes(data) {
                    return data.organisation_types;
                },
                fields: function fields(data) {
                    return data.organisation_fields;
                },
                fieldList: function fieldList(data) {
                    return data.field_list_contacts;
                },
                contacts: function contacts($stateParams) {
                    var contacts = new Gecko.Contact().where('organisation_id', $stateParams.organisation_id);
                    if ($stateParams.order) contacts.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    return contacts.page($stateParams.page || 1).perPage(GeckoUI.getPageLimit(this.self.name)).rfields({ contact: ['field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6'] }).get();
                }
            }
        }).state('organisation.responses', {
            url: "/responses?{page}&{order}&{sort}",
            templateUrl: "/modules/organisations/views/responses.814879c9.html",
            controller: 'OrganisationResponsesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisation Responses',
            requires: [Gecko.ABILITY_ORGANISATIONS_VIEW, Gecko.ABILITY_RESPONSES_VIEW],
            resolve: {
                responses: function responses($stateParams) {
                    return new Gecko.Response().where('contact_organisation_id', $stateParams.organisation_id).include('form', 'event', 'user', 'assigned', 'contact').perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ response: ['created_at'], form: ['name', 'internal_name'], event: ['title'], assigned: ['full_name', 'color'], contact: ['full_name'] }).get();
                }
            }
        }).state('organisation.calls', {
            url: "/calls?{page}&{user}&{campaign}&{outcome}&{from}&{to}&{autoUpdate}&{overview}",
            templateUrl: "/modules/calls/views/index.3be09d98.html",
            controller: 'CallsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisation Calls',
            requires: [Gecko.ABILITY_ORGANISATIONS_VIEW, Gecko.ABILITY_CALLS_VIEW],
            resolve: {
                defaultStatuses: function defaultStatuses() {
                    return [Gecko.Call.PREPARING, Gecko.Call.STARTED, Gecko.Call.ENDED, Gecko.Call.COMPLETE];
                },
                callIncludes: function callIncludes() {
                    return ['contact', 'user', 'outcome', 'campaign', 'number', 'all_campaigns'];
                },
                calls: function calls($stateParams, defaultStatuses, callIncludes) {

                    var calls = new Gecko.Call().include(callIncludes).where('status', defaultStatuses).where('contact_organisation_id', $stateParams.organisation_id).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1);

                    // Only show outbound if inbound call package is disabled
                    if (!Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL)) calls.where('type', Gecko.Call.TYPE_OUTBOUND);

                    if ($stateParams.user) {
                        calls.where('user_id', $stateParams.user);
                    }
                    if ($stateParams.campaign) {
                        calls.where('campaign_id', $stateParams.campaign);
                    }
                    if ($stateParams.outcome) {
                        calls.where('outcome_id', $stateParams.outcome);
                    }
                    calls.dates($stateParams.from, $stateParams.to);

                    return calls.rfields({ call: ['status', 'started_at', 'type', 'call_time', 'recording_url_mp3'], user: ['full_name', 'color'], contact: ['full_name', 'field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6'], campaign: ['title'], outcome: ['name'] }).get();
                },
                data: function data() {
                    var _query = {};

                    // Outcomes
                    _query.outcomes = new Gecko.Outcome().rfields({ outcome: ['name'] }).perPage('all');
                    // Campaigns
                    _query.campaigns = new Gecko.Campaign().include('all_campaigns').rfields({ campaign: ['title'] }).perPage('all');
                    // Users
                    _query.users = new Gecko.User().rfields({ user: ['full_name'] }).perPage('all');

                    return Gecko.multi(_query);
                },
                users: function users(data) {
                    return data.users;
                },
                campaigns: function campaigns(data) {
                    return data.campaigns;
                },
                outcomes: function outcomes(data) {
                    return data.outcomes;
                },
                user: function user($stateParams) {
                    if ($stateParams.overview && typeof $stateParams.user == 'string') {
                        return new Gecko.User().get($stateParams.user);
                    }
                    return false;
                },
                fieldList: function fieldList(geckoDataService) {
                    return geckoDataService.fetch(['field_list_contacts']).then(function (data) {
                        return data.field_list_contacts;
                    });
                }
            }
        }).state('organisation.messages', {
            url: "/messages?{page}&{order}&{sort}",
            templateUrl: "/modules/organisations/views/messages.0ad5ac7a.html",
            controller: 'OrganisationMessagesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisation Messages',
            requires: [Gecko.ABILITY_ORGANISATIONS_VIEW],
            resolve: {
                messages: function messages($stateParams) {
                    var messages = new Gecko.Message().include('sender', 'user', 'contact', 'message_events').where('contact_organisation_id', $stateParams.organisation_id).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1);
                    if ($stateParams.order) messages.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    return messages.rfields({ message: ['created_at', 'status', 'type', 'subject', 'body', 'direction', 'delivered', 'clicked', 'opened'], contact: ['full_name'], user: ['full_name', 'color'] }).get();
                }
            }
        }).state('organisation.attachments', {
            url: "/attachments?{page}&{order}&{sort}&{include_contacts}",
            templateUrl: "/modules/attachments/attachments.3e44d212.html",
            controller: 'AttachmentsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisation Attachments',
            requires: [Gecko.ABILITY_ORGANISATIONS_VIEW, Gecko.ABILITY_ATTACHMENTS_VIEW],
            editRequires: [Gecko.ABILITY_ORGANISATIONS_UPDATE],
            resolve: {
                files: function files($stateParams) {
                    var files = new Gecko.File().include('user', 'contact').page($stateParams.page).perPage(GeckoUI.getPageLimit(this.self.name)).orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc').where('organisation_id', $stateParams.organisation_id);

                    if (+$stateParams.include_contacts) files.include('organisation_contact_files'); // Include contact attachments.

                    return files.rfields({ file: ['created_at', 'title', 'mime', 'filesize', 'status', 'download_url'], user: ['full_name', 'color'] }).get();
                },
                entityData: function entityData($stateParams) {
                    return {
                        type: Gecko.File.MODEL_ORGANISATION,
                        param: 'organisation_id',
                        value: $stateParams.organisation_id
                    };
                }
            }
        }).state('organisationadd', {
            url: "/organisation/new",
            templateUrl: "/modules/organisations/views/add.48679542.html",
            controller: 'OrganisationAddCtrl',
            controllerAs: 'ctrl',
            page_title: 'Add Organisation',
            requires: [Gecko.ABILITY_ORGANISATIONS_CREATE],
            permissionCheck: function permissionCheck() {
                return Gecko.has(Gecko.Package.FEATURE_ORGANISATION);
            },
            resolve: {
                organisation: function organisation() {
                    return new Gecko.Organisation();
                },
                fields: function fields() {
                    return new Gecko.Field().where('field_type', Gecko.Field.FIELD_TYPE_ORGANISATION).get();
                },
                organisationTypes: function organisationTypes() {
                    return new Gecko.OrganisationType().perPage('all').get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(OrganiationsRoute);
})();