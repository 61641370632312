(function () {

    function LegacyEventTicketModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('legacyEventTicketModal', function (_attendance) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/modules/event_legacy/views/_modals/ticket.8317fa7c.html',
                controller: 'LegacyEventTicketModalCtrl',
                resolve: {
                    attendance: function attendance() {
                        return _attendance;
                    },
                    contact: function contact() {
                        return new Gecko.Contact().rfields({ contact: ['full_name', 'qr_url', 'email'] }).get(_attendance.related_contact.id);
                    }
                }
            };
        });
    }

    function LegacyEventTicketModalCtrl($modalInstance, attendance, contact) {
        var ctrl = this;
        ctrl.attendance = attendance;
        ctrl.contact = contact;

        ctrl.footerBtns = [{
            title: 'Close',
            icon: 'fa-times',
            btnClass: 'btn-default',
            action: function action() {
                $modalInstance.dismiss();
            }
        }];
    }

    angular.module('GeckoEngage').config(LegacyEventTicketModal).controller('LegacyEventTicketModalCtrl', LegacyEventTicketModalCtrl);
})();