(function () {
    'use strict';

    Gecko.Condition.TYPE_EMPTY = 'empty';
    Gecko.Condition.TYPE_NOT_EMPTY = '!empty';
    Gecko.Condition.TYPE_EQUALS = '=';
    Gecko.Condition.TYPE_NOT_EQUALS = '!=';
    Gecko.Condition.TYPE_CONTAINS = '%';
    Gecko.Condition.TYPE_NOT_CONTAINS = '!%';
    Gecko.Condition.TYPE_GREATER_THAN = '>';
    Gecko.Condition.TYPE_LESS_THAN = '<';

    Gecko.Condition.type_titles = [{
        id: Gecko.Condition.TYPE_EMPTY,
        label: 'Empty'
    }, {
        id: Gecko.Condition.TYPE_NOT_EMPTY,
        label: 'Not Empty'
    }, {
        id: Gecko.Condition.TYPE_EQUALS,
        label: 'Equal',
        hideDate: true
    }, {
        id: Gecko.Condition.TYPE_NOT_EQUALS,
        label: 'Not Equal',
        hideDate: true
    }, {
        id: Gecko.Condition.TYPE_CONTAINS,
        label: 'Contains',
        hideDate: true
    }, {
        id: Gecko.Condition.TYPE_NOT_CONTAINS,
        label: 'Not Contains',
        hideDate: true
    }, {
        id: Gecko.Condition.TYPE_GREATER_THAN,
        label: 'Greater Than'
    }, {
        id: Gecko.Condition.TYPE_LESS_THAN,
        label: 'Less Than'
    }];

    function geckoFormFieldCondition($filter, $formBuilderService, geckoDataService) {
        return {
            restrict: 'E',
            scope: {
                condition: '=',
                form: '=',
                field: '='
            },
            template: '\n                <gecko-fields fields="ctrl.fields" values="ctrl.condition" class="clearfix"></gecko-fields>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            require: ['geckoFormFieldCondition', '^form'],
            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];

                //Check condition field id and model
                if (!ctrl.condition.field_id) ctrl.condition.field_id = ctrl.field.id;
                if (!ctrl.condition.model) ctrl.condition.model = 'form_field';

                //Get form fields (and remove current field from array)
                var fieldOptions = $filter('orderBy')($formBuilderService.getFieldsArray(ctrl.form.pages), 'label');
                // Dont list new fields (prior to save) and refernces to current field
                fieldOptions = fieldOptions.filter(function (option) {
                    if (!option.id || option.id === ctrl.field.id) return false;
                    return true;
                });

                // Remove unsuitable field types
                fieldOptions = fieldOptions.filter(function (f) {
                    return [Gecko.Field.TYPE_CONSENT, Gecko.Field.TYPE_SECTION, Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_ORGANISATION, Gecko.Field.TYPE_REPEATABLE].indexOf(f.type) === -1;
                });

                // Helper functions to show and hide conditions
                var isOptionsField = function isOptionsField() {
                    return [Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI,, Gecko.Field.TYPE_PREFERRED_LANGUAGE].indexOf(GeckoUI.gobk(fieldOptions, 'id', ctrl.condition.dependency_id).type) !== -1;
                };

                var isDateField = function isDateField() {
                    return GeckoUI.gobk(fieldOptions, 'id', ctrl.condition.dependency_id).type === Gecko.Field.TYPE_DATE;
                };

                var hasFieldOptions = function hasFieldOptions() {
                    return $formBuilderService.getDependencyOptions(fieldOptions, ctrl.condition.dependency_id);
                };

                var typeIsShowHide = function typeIsShowHide() {
                    return [Gecko.Condition.TYPE_EMPTY, Gecko.Condition.TYPE_NOT_EMPTY].indexOf(ctrl.condition.type) !== -1;
                };

                var typeIsNumberComparison = function typeIsNumberComparison() {
                    return [Gecko.Condition.TYPE_GREATER_THAN, Gecko.Condition.TYPE_LESS_THAN].indexOf(ctrl.condition.type) !== -1;
                };

                var prepareTypes = function prepareTypes() {
                    if (isDateField()) {
                        return angular.copy(Gecko.Condition.type_titles).filter(function (t) {
                            return !t.hideDate;
                        });
                    }
                    return angular.copy(Gecko.Condition.type_titles);
                };

                var optionsRefreshing = false;

                //Settings fields
                ctrl.fields = [{
                    id: 'dependency_id',
                    label: 'Field',
                    type: Gecko.Field.TYPE_SELECT,
                    options: fieldOptions,
                    optionsKey: 'id',
                    optionsLabelKey: 'label',
                    colClass: 'col-xs-4 field-condition-first',
                    action: function action() {
                        return refreshDependencyOptions(true);
                    },
                    onChange: function onChange() {
                        // Set type options (dependent on field type)
                        var typeField = ctrl.fields.find(function (f) {
                            return f.id === 'type';
                        });
                        typeField.options = prepareTypes();

                        // Reset array value if field is changed to type date
                        if (isDateField() && angular.isArray(ctrl.condition.value)) {
                            ctrl.condition.value = null;
                        }
                    }
                }, {
                    id: 'type',
                    label: 'Type',
                    type: Gecko.Field.TYPE_SELECT,
                    options: prepareTypes(),
                    optionsKey: 'id',
                    optionsLabelKey: 'label',
                    colClass: 'col-xs-3'
                }, {
                    id: 'value-fake',
                    label: 'Value',
                    type: Gecko.Field.TYPE_MULTI,
                    colClass: 'col-xs-4',
                    config: { create: true },
                    optionsKey: '',
                    optionsLabelKey: '',
                    disabledWhen: function disabledWhen() {
                        return true;
                    },
                    hideWhen: function hideWhen() {
                        return !typeIsShowHide();
                    }
                }, {
                    id: 'value',
                    label: 'Value',
                    type: Gecko.Field.TYPE_MULTI,
                    colClass: 'col-xs-4',
                    config: { create: true },
                    optionsKey: '',
                    optionsLabelKey: '',
                    hideWhen: function hideWhen() {
                        return hasFieldOptions() || isDateField() || typeIsShowHide() || typeIsNumberComparison();
                    }
                }, {
                    id: 'value',
                    id2: 'value-options',
                    label: 'Value',
                    type: Gecko.Field.TYPE_MULTI,
                    options: $formBuilderService.getDependencyOptions(fieldOptions, ctrl.condition.dependency_id),
                    optionsKey: 'value',
                    optionsLabelKey: 'label',
                    colClass: 'col-xs-4',
                    hideWhen: function hideWhen() {
                        return !isOptionsField() || !hasFieldOptions() || typeIsShowHide() || typeIsNumberComparison() || optionsRefreshing;
                    }
                }, {
                    id: 'value',
                    label: 'Value',
                    type: Gecko.Field.TYPE_NUMBER,
                    colClass: 'col-xs-4',
                    hideWhen: function hideWhen() {
                        return isDateField() || !typeIsNumberComparison();
                    }
                }, {
                    id: 'value',
                    label: 'Value',
                    type: Gecko.Field.TYPE_DATE,
                    //utc: true,
                    colClass: 'col-xs-4',
                    hideWhen: function hideWhen() {
                        return !isDateField() || typeIsShowHide();
                    }
                }, {
                    type: Gecko.Field.TYPE_BUTTON,
                    colClass: 'col-xs-1 field-condition-last',
                    icon: 'fa-trash-alt',
                    btnClass: 'btn-danger',
                    action: function action() {
                        var index = indexOf(ctrl.form.conditions)(function (condition) {
                            return condition.id === ctrl.condition.id;
                        });

                        // Remove condition by index
                        if (index !== -1) {
                            ctrl.form.conditions.splice(index, 1);
                            formCtrl.$setDirty();
                        }
                    }

                }];

                var indexOf = function indexOf(arr) {
                    return function (findFunc) {
                        for (var index = 0; index < arr.length; index++) {
                            if (findFunc(arr[index], index, arr)) {
                                return index;
                            }
                        }
                        return -1;
                    };
                };

                //Update value options if suitable field type
                var refreshDependencyOptions = function refreshDependencyOptions(reset) {
                    if ($formBuilderService.getDependencyOptions(fieldOptions, ctrl.condition.dependency_id)) {
                        var field = fieldOptions.find(function (f) {
                            return f.id === ctrl.condition.dependency_id;
                        }) || {};
                        // Reset condition value when dependency changes
                        if (reset) ctrl.condition.value = null;

                        // Possibly a 'child' field; if so get optionId from the field's top level parent
                        var topLevelField = field.parent_id ? $formBuilderService.fieldOptionUtils.getTopParentField(fieldOptions, field.id) || {} : field;
                        var optionId = field.parent_id ? topLevelField.option_id : field.option_id;

                        optionsRefreshing = true;

                        // Options may not be set on dependency field yet
                        if (optionId && !topLevelField.options) {
                            var getOptionsFn = geckoDataService.fetchFromStore(['options']).then(function (data) {
                                return data.options.find(function (o) {
                                    return o.id === optionId;
                                }) || null;
                            }).then(function (option) {
                                if (option && option.values) {
                                    // If child field; set fetched options on the fields top level parent
                                    if (field.parent_id) {
                                        (fieldOptions.find(function (f) {
                                            return f.id === topLevelField.id;
                                        }) || {}).options = option.values;
                                    } else {
                                        field.options = option.values;
                                    }
                                }
                                return option;
                            }).then(function (option) {
                                return $formBuilderService.getDependencyOptions(fieldOptions, ctrl.condition.dependency_id, field.option_level);
                            });
                        } else {
                            var getOptionsFn = Promise.resolve($formBuilderService.getDependencyOptions(fieldOptions, ctrl.condition.dependency_id));
                        }

                        // Set updated options and reactivate the field
                        getOptionsFn.then(function (options) {
                            ctrl.fields.find(function (f) {
                                return f.id2 === 'value-options';
                            }).options = $formBuilderService.prepareOptions(field, options);
                        }).then(function () {
                            optionsRefreshing = false;
                            scope.$digest();
                        }).catch(function (err) {
                            optionsRefreshing = false;
                            scope.$digest();
                        });
                    }
                };

                refreshDependencyOptions(false);

                //
                // var _tempDependencyId = ctrl.condition.dependency_id;
                // var _tempType = ctrl.condition.type;

                // Object.defineProperties(ctrl.condition, {
                //     dependency_id : {
                //         get: function() {
                //             return _tempDependencyId;
                //         },
                //         set: function(value) {
                //             _tempDependencyId = value;

                //             console.log(ctrl.condition.value);
                //             if (isDateField() && (!ctrl.condition.value || !Number(ctrl.condition.value)) ) {
                //                 ctrl.condition.value = moment.utc().format('X');
                //             }
                //         }
                //     },
                //     type : {
                //         get: function() {
                //             return _tempType;
                //         },
                //         set: function(value) {
                //             _tempType = value;

                //             console.log(ctrl.condition.value);
                //             if (isDateField() && (!ctrl.condition.value || !Number(ctrl.condition.value)) ) {
                //                 ctrl.condition.value = moment.utc().format('X');
                //             }
                //         }
                //     }
                // });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFormFieldCondition', geckoFormFieldCondition);
})();