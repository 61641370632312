(function () {
    'use strict';

    function geckoAdvancedSearchModalFilter() {
        return {
            restrict: 'E',
            template: '\n                <div class="col-xs-12">\n                    <div class="clearfix">\n                        <div style="margin-bottom: 15px;">\n                            <gecko-search-conditions \n                                conditions="ctrl.conditions" \n                                logic-operator="ctrl.requirement">\n                            </gecko-search-conditions>\n                        </div>\n                        <div class="clearfix">\n                            <span class="pull-right">\n                                <gecko-search-conditions-add-btn \n                                    conditions="ctrl.conditions">\n                                </gecko-search-conditions-add-btn>\n                            </span>\n                        </div>\n                    </div>\n                </div>\n            ',
            scope: {
                conditions: '=',
                requirement: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: geckoAdvancedSearchModalFilterCtrl
        };
    }

    function geckoAdvancedSearchModalFilterCtrl() {
        var ctrl = this;
        ctrl.conditions = ctrl.conditions || [];
        ctrl.requirement = ctrl.requirement || 'ANY';
    }

    angular.module('GeckoEngage').directive('geckoAdvancedSearchModalFilter', geckoAdvancedSearchModalFilter).controller('geckoAdvancedSearchModalFilterCtrl', geckoAdvancedSearchModalFilterCtrl);
})();