(function () {
    'use strict';

    function asyncOptions($q, asyncCache) {
        var defaultConfig = {
            search: 'keywordSearch'
        };

        return {
            create: function create(geckoGetter, callback, config) {
                config = angular.extend({}, defaultConfig, config);
                if (!geckoGetter.params.per_page) {
                    geckoGetter.params.per_page = 10;
                } // Default to 10 items to display
                if (!geckoGetter.params.order_by) {
                    console.warn("asyncOptions queries should have an orderBy, this should be on their identifying label e.g. title/name");
                }
                return function (select) {
                    var self = this;
                    var onDataLoaded = function onDataLoaded(data) {
                        self.options = data.toArray();
                        if (callback) {
                            callback.call(self, select.search);
                        }
                        select.loadingOptions = false;
                        select.moreToLoad = self.options.length != data.pagination.total_items;
                    };
                    var onFail = function onFail(data) {
                        // When we get unauthorised disable the field since we don't have access to the data.
                        if (data.status_code === 403) {
                            self.disabledWhen = function () {
                                return true;
                            };
                        }
                    };
                    select.loadMore = function () {
                        select.loadingOptions = true;
                        asyncCache.get(angular.copy(geckoGetter).perPage('all')[config.search](select.search)).then(onDataLoaded).catch(onFail);
                    };

                    asyncCache.get(angular.copy(geckoGetter)[config.search](select.search)).then(onDataLoaded).catch(onFail);

                    select.loadingOptions = true;
                    select.allLoaded = false;
                };
            },
            getSelectedValues: function getSelectedValues(data) {
                var _query = {};

                Object.keys(data).forEach(function (key) {
                    var item = data[key];
                    if (item.id) {
                        _query[key] = new Gecko[item.model]().rfields(item.fields).path(item.id);
                    }
                });

                if (Object.keys(_query).length) return Gecko.multi(_query);
                return {};
            },
            pick: function pick(selectedData, key) {
                return selectedData[key] ? [selectedData[key]] : [];
            }
        };
    }

    angular.module('GeckoEngage.data').factory('asyncOptions', asyncOptions);
})();