(function () {
    'use strict';

    function geckoCallScriptCard($geckoCall) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-script-card.html',

            require: '^geckoCallPane',

            link: {
                pre: function pre(scope, iElement, iAttrs, ctrl) {

                    var scriptQuery = new Gecko.Form().include('pages:all.fields:all.conditions:all');

                    // Let's the API know how to fill the script template tags.
                    if ($geckoCall.call && $geckoCall.call.subscriber_id) {
                        scriptQuery.where('subscriber_id', $geckoCall.call.subscriber_id);
                    }

                    scriptQuery.get(ctrl.campaign.form_id).then(function (form) {
                        ctrl.form = form.toObject();
                        scope.$digest();
                    });
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallScriptCard', geckoCallScriptCard);
})();