(function () {
    'use strict';

    function UserSessionsCtrl(Gecko, tokens, $state, $stateParams) {
        var ctrl = this;
        ctrl.tokens = tokens.toInstances();
        ctrl.pagination = tokens.pagination;

        // Prepare device label (to be readable)
        var prepareDeviceLabels = function prepareDeviceLabels(tokens) {
            return tokens.map(function (t) {
                var deviceName = t.type;

                // Build label from device info
                if (t.related_device) {
                    deviceName = t.related_device.udid;
                    if (t.related_device.type) deviceName += ' (' + t.related_device.type + ')';
                }

                t.device_name = deviceName;
                return t;
            });
        };
        ctrl.tokens = prepareDeviceLabels(ctrl.tokens);

        ctrl.cols = [{
            key: 'revoked',
            colClass: 'text-center',
            status_title_none: true,
            status_styles: Gecko.Token.revoked_titles
        }, {
            title: 'Device',
            key: 'device_name',
            colClass: 'col-xs-4'
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Started at',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-4'
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Ended at',
            key: 'updated_at',
            sortKey: 'updated_at',
            colClass: 'col-xs-4',
            render: function render(value, col, token) {
                if (token.revoked) return value;
                return '';
            }
        }];

        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen(token) {
                return token.revoked;
            },
            items: [{
                title: 'Revoke permission',
                action: function action(token) {
                    return token.remove().then(function () {
                        GeckoUI.messenger.success('Permission revoked');
                        return $state.go($state.$current.name, $stateParams, { reload: true });
                    }).catch(function (err) {
                        return GeckoUI.messenger.error(err);
                    });
                }
            }]
        };
    }

    angular.module('GeckoEngage').controller('UserSessionsCtrl', UserSessionsCtrl);
})();