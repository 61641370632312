(function () {
    'use strict';

    function fileAvCacheService(geckoStore) {

        var cacheKey = 'gecko-file-av-status-cache';

        var getCacheObj = function getCacheObj() {
            return JSON.parse(geckoStore.get(cacheKey) || '{}');
        };
        var setCacheObj = function setCacheObj(cacheObj) {
            return geckoStore.set(cacheKey, JSON.stringify(cacheObj));
        };

        var _this = {
            get: function get(uuid) {
                return uuid ? getCacheObj()[uuid] : getCacheObj();
            },
            set: function set(uuid, status) {
                var newCacheObj = getCacheObj();
                newCacheObj[uuid] = status;
                setCacheObj(newCacheObj);
            },
            remove: function remove(uuid) {
                var newCacheObj = getCacheObj();
                delete newCacheObj[uuid];
                setCacheObj(newCacheObj);
            },
            removeAll: function removeAll() {
                setCacheObj({});
            }
        };

        return _this;
    }

    function fileAvService(fileAvCacheService, portalRequestService) {

        var _this = {
            extractUuid: function extractUuid(url) {
                try {
                    if (url) {
                        return new URL(url).pathname.split('/').filter(function (seg) {
                            return seg;
                        })[1];
                    }
                } catch (err) {}
                return null;
            },
            isDangerous: function isDangerous(fileUrl) {
                var cache = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

                return new Promise(function (resolve, reject) {
                    var fileUuid = _this.extractUuid(fileUrl);
                    var cachedAvStatus = fileAvCacheService.get(fileUuid);
                    var lookupAvStatus = !cache || cachedAvStatus === undefined || cachedAvStatus === Gecko.File.AV_STATUS_UNKNOWN;

                    var promise = void 0;
                    if (lookupAvStatus) {
                        promise = portalRequestService.get('/assets/' + fileUuid).then(function (res) {
                            return res.data.avStatus;
                        });
                    } else {
                        promise = Promise.resolve(cachedAvStatus);
                    }

                    return promise.then(function (avStatus) {
                        // Cache av status
                        if (cache) _this.setCachedStatus(fileUrl, avStatus);

                        var isDangerous = avStatus === Gecko.File.AV_STATUS_DANGEROUS;
                        resolve(isDangerous);
                    }).catch(function (err) {
                        // We dont care about previously uploaded files
                        if (cache) _this.setCachedStatus(fileUrl, Gecko.File.AV_STATUS_SAFE);
                        resolve(false);
                    });
                });
            },

            downloadFileWithAvStatusCheck: function downloadFileWithAvStatusCheck(fileUrl) {
                return _this.isDangerous(fileUrl).then(function (isDangerous) {

                    // File is considered DANGEROUS, user to confirm if download to proceed
                    if (isDangerous) {
                        return new Promise(function (resolve, reject) {
                            var alertText = 'This file has been identified by the virus scanner as malicious. Are you sure you want to download this file?';
                            return GeckoUI.dialog.confirm(alertText, function (ok) {
                                return resolve(ok ? true : false);
                            });
                        });
                    }

                    // File is considered SAFE, NO user intervention needed
                    return true;
                }).then(function (downloadFile) {
                    return downloadFile ? window.location.assign(fileUrl) : null;
                }).catch(function (err) {
                    throw err;
                });
            },

            setCachedStatus: function setCachedStatus(url, avStatus) {
                var uuid = _this.extractUuid(url);
                if (uuid) fileAvCacheService.set(uuid, avStatus);
            },

            removeCachedStatus: function removeCachedStatus(url) {
                var uuid = _this.extractUuid(url);
                if (uuid) fileAvCacheService.remove(uuid);
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').factory('fileAvCacheService', fileAvCacheService).factory('fileAvService', fileAvService);
})();