(function () {
    'use strict';

    function SettingsOptionsCtrl($scope, $state, options) {
        var ctrl = this;
        ctrl.options = options.toInstances();
        ctrl.pagination = options.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'name',
            sortKey: 'name',
            colClass: 'col-xs-6'
        }, {
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Options',
            key: 'values_count',
            sortKey: 'values_count',
            colClass: 'col-xs-6'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'settingsoption',
            params: { option_id: 'id' }
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Clone field option',
            action: function action(option) {
                new Gecko.Option().get(option.id).then(function (option) {
                    option.clone().then(function (clone) {
                        ctrl.options.unshift(clone);
                        $scope.$apply();
                        GeckoUI.messenger.success('Field option list was cloned successfully');
                    });
                });
            }
        }, {
            title: 'Remove field option',
            action: function action(option, index) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this field option list?', function (value) {
                    if (value) {
                        option.remove().then(function () {
                            ctrl.options.splice(index, 1);
                            $scope.$apply();
                            GeckoUI.messenger.success('Field option list has been deleted');
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Field Options',
            active: true
        }];
    }

    // Field options utility functions.
    function cleanOptions(options) {
        // Check valid options object has been specified
        if (!options || !options.map) return {};
        return options.map(function (option) {
            if (option.options) {
                option.options = cleanOptions(option.options);
            }

            return {
                value: option.value,
                code: option.code,
                hidden: option.hidden || false,
                options: option.options || []
            };
        });
    }

    // Copy display value over to export value and vice versa
    function sanitizeOptions(options) {
        var removedCount = 0;
        for (var i = options.length - 1; i >= 0; --i) {
            var option = options[i];
            // Call on sub options
            if (option.options) {
                removedCount += sanitizeOptions(option.options);
            }
            // Both Value and Code are empty and has no children then delete it
            if (!option.code && !option.value && option.options.length === 0) {
                options.splice(i, 1);
                ++removedCount;
                continue;
            }

            // Copy display to code
            if (!option.code) option.code = option.value;
            // Copy code to display
            if (!option.value) option.value = option.code;
        }
        return removedCount;
    }

    function SettingsOptionCtrl($scope, $state, $stateParams, $geckoModal, $filter, option, optionListParse, unsavedAlert) {
        var ctrl = this;
        ctrl.basicMode = false;
        ctrl.textOptions = null;
        ctrl.checkTextOptionsManageable = checkTextOptionsManageable;

        var MANAGEABLE_OPTIONS_LIMIT = 1000;

        $scope.option = option;
        $scope.addFieldOption = addFieldOption;
        $scope.addOptionNew = addOptionNew;
        $scope.deleteOption = deleteOption;

        // Clean the object values to remove angular tracking guff that may have been saved previously
        var cleanedOptions = cleanOptions($scope.option.values || []);
        $scope.option.values = cleanedOptions;
        $scope.optionValues = cleanedOptions;

        ctrl.optionsManageable = optionListParse.countOptions($scope.optionValues) < MANAGEABLE_OPTIONS_LIMIT;

        if (!ctrl.optionsManageable) {
            ctrl.basicMode = true;
            initTextOptions();
        }

        // Button setup
        ctrl.headerBtns = [{
            title: 'Switch to Text Mode',
            action: function action() {
                ctrl.basicMode = true;
                initTextOptions();
            },
            hideWhen: function hideWhen() {
                return ctrl.basicMode;
            }
        }, {
            title: 'Switch to Visual Mode',
            action: function action() {
                if (!parseTextOptions()) return;
                ctrl.basicMode = false;
            },
            hideWhen: function hideWhen() {
                return !ctrl.basicMode;
            },
            disabledWhen: function disabledWhen() {
                return !ctrl.optionsManageable;
            }
        }, {
            title: 'Import Options',
            icon: 'fa-download',
            btnClass: 'btn-primary',
            action: openImport
        }];
        $scope.footerBtns = [{
            preset: 'save',
            action: saveOptions
        }, {
            position: 'secondary',
            preset: 'remove',
            hideWhen: function hideWhen() {
                return $stateParams.option_id === 'new';
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this field options list?', function (value) {
                    if (value) {
                        $scope.option.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Field option list has been deleted');
                            $state.go('settingsoptions');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }, {
            position: 'secondary',
            title: 'Clone',
            icon: 'fa-copy',
            hideWhen: function hideWhen() {
                return $stateParams.option_id === 'new';
            },
            action: function action() {
                $scope.option.clone().then(function (clone) {
                    GeckoUI.messenger.success('Field option list was cloned successfully');
                    $state.go('settingsoption', { option_id: clone.id }, { reload: true });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }];

        // Table structure
        $scope.cols = [{
            title: 'Field',
            key: 'label',
            colClass: 'col-xs-6'
        }, {
            title: 'Form',
            key: 'related_form.name',
            render: function render(value, col, row) {
                if (row.related_form && row.related_form.internal_name) {
                    var val = row.related_form.internal_name;
                } else {
                    var val = value || '-';
                }
                // Check if form is deleted
                if (row.related_form && row.related_form.deleted_at) {
                    return val + ' (Archived)';
                }

                return val;
            },
            colClass: 'col-xs-6'
        }];

        // Table row action
        $scope.rowOptionsBtn = {
            items: [{
                render: function render(item, field) {
                    if (field.field_type == 'contact') {
                        return 'View contact field';
                    }
                    return 'View form';
                },
                action: function action(field) {
                    if (field.field_type == 'contact') {
                        return $state.go('settingscontactfield', { contactfield_id: field.id });
                    } else if (field.form_id) {
                        return $state.go('form.designer', { form_id: field.form_id });
                    }
                }
            }]

            // Breadcrumbs
        };$scope.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Field Options',
            click: function click() {
                $state.go('settingsoptions');
            }
        }, {
            label: $scope.option.name ? $scope.option.name : 'Add Field Options',
            active: true
        }];

        function checkTextOptionsManageable() {
            ctrl.optionsManageable = optionListParse.countTextOptions(ctrl.textOptions) < MANAGEABLE_OPTIONS_LIMIT;
        }

        function addFieldOption() {
            if ($scope.optionValues === null || !$scope.optionValues) {
                $scope.optionValues = [];
            }
            $scope.optionValues.push({
                code: '',
                value: '',
                hidden: false,
                options: []
            });
            ctrl.form.$setDirty();
        }

        function addOptionNew(obj) {
            if (obj.options === undefined) {
                obj.options = [];
            }
            obj.options.push({
                code: '',
                value: '',
                hidden: false,
                options: []
            });
            ctrl.form.$setDirty();
        }

        function deleteOption(array, index) {
            array.splice(index, 1);
            ctrl.form.$setDirty();
        }

        function initTextOptions() {
            ctrl.textOptions = optionListParse.createTextOptions($scope.optionValues);
        }

        function parseTextOptions() {
            // Parse options from text input
            try {
                var updatedOptions = optionListParse.parseTextOptions(ctrl.textOptions);
                $scope.option.values = updatedOptions;
                $scope.optionValues = updatedOptions;

                return true;
            } catch (e) {
                console.log('ERR', e);
                $scope.parseError = e;
                GeckoUI.messenger.error(e.message);
                return false;
            }
        }

        function saveOptions() {
            $scope.parseError = null;

            if (!ctrl.basicMode) {
                // Remove angular tracking guff before saving optionsValues
                $scope.option.values = cleanOptions($scope.optionValues);
            } else if (!parseTextOptions()) {
                return;
            }

            // show toast if user clicks save when nothing has changed
            if (!ctrl.form.$dirty) {
                return GeckoUI.messenger.success("Save not required when Field Options have not changed.");
            }

            ctrl.saveFieldOption = function () {
                var removedCount = sanitizeOptions($scope.option.values);
                $scope.option.save().then(function (data) {
                    GeckoUI.messenger.success('Option has been saved' + (removedCount ? ' (' + removedCount + ' empty options trimmed)' : ''));
                    unsavedAlert.clear();
                    $state.go('settingsoption', { option_id: data.id }, { reload: true });

                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Field Option Save Success', {
                        'option id': data.id || 'New',
                        'option name': data.name
                    });
                }).catch(function (data) {
                    GeckoUI.messenger.error(data.errors);

                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Field Option Save Error', {
                        'option id': $scope.option.id || 'New',
                        'option name': $scope.option.name,
                        'error': GeckoUI.renderError(data.errors)
                    });
                });
            };

            if ($stateParams.option_id === 'new' || $scope.option.related_fields.length === 0) {
                // if field ID is new or hasnt been used within a form yet
                ctrl.saveFieldOption();
            } else {
                // else warn user this could break workflows or filters
                var warningMessage = 'There are fields in the system currently using this field option list.<br/><br/>Changes to the list might break workflows or filters that are pointing to values within your list that have been changed or removed.';
                return GeckoUI.dialog.confirmCustom(warningMessage, [{ base: 'YES', text: 'Save anyway' }, { base: 'NO', text: 'Cancel' }], function (result) {
                    if (result) {
                        ctrl.saveFieldOption();
                    }
                });
            }
        }

        function openImport() {
            $geckoModal.fieldOptionsImportModal({ name: $scope.option.name, values: $scope.optionValues }).result.then(function (options) {
                ctrl.optionsManageable = optionListParse.countOptions(options) < MANAGEABLE_OPTIONS_LIMIT;
                $scope.optionValues = options;
                if (!ctrl.optionsManageable || ctrl.basicMode === true) {
                    ctrl.textOptions = optionListParse.createTextOptions($scope.optionValues);
                }
                ctrl.form.$setDirty();
            });
        }

        $scope.treeOptions = {
            dropped: function dropped() {
                if (ctrl.form) {
                    ctrl.form.$setDirty();
                }
            }
        };

        $scope.toggleShowHide = function (item) {
            item.hidden = !item.hidden;
            ctrl.form.$setDirty();
        };
    }

    angular.module('GeckoEngage').controller('SettingsOptionsCtrl', SettingsOptionsCtrl).controller('SettingsOptionCtrl', SettingsOptionCtrl);
})();