(function () {
    'use strict';

    function CallsQueueCtrl($scope, $geckoSocket, $geckoCall, $geckoVoip, $geckoConf, Gecko, _queuedCalls, _activeCalls, queuedCalls, activeCalls) {
        var ctrl = this;
        ctrl.queuedCalls = queuedCalls.toArray();
        ctrl.activeCalls = activeCalls.toArray();
        ctrl.queuePagination = queuedCalls.pagination;
        ctrl.activeCallsPagination = activeCalls.pagination;

        // Update the que when needed
        ctrl.updateCalls = function (data) {
            // Call queue
            if ([Gecko.Call.QUEUED, Gecko.Call.STARTED, Gecko.Call.ABANDONED].indexOf(data.status) != -1) {
                console.log('=== Queue Updated ===');

                _queuedCalls.get().then(function (queuedCalls) {
                    ctrl.queuedCalls = queuedCalls.toArray();
                    ctrl.queuePagination = queuedCalls.pagination;
                });
            }
            // Active calls
            if ([Gecko.Call.STARTED, Gecko.Call.ENDED].indexOf(data.status) != -1) {
                console.log('=== Active Calls Updated ===');
                _activeCalls.get().then(function (activeCalls) {
                    ctrl.activeCalls = activeCalls.toArray();
                    ctrl.activeCallsPagination = activeCalls.pagination;
                });
            }
        };

        // Update live
        $geckoSocket.registerEvent('call:new', ctrl.updateCalls);
        $geckoSocket.registerEvent('call:updated', ctrl.updateCalls);

        // Active Calls Table structure
        ctrl.cols = [{
            colClass: 'text-center',
            key: 'status',
            expandable: true,
            status_title_none: true,
            status_styles: Gecko.Call.statuses
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Started at',
            key: 'queued_at',
            colClass: 'col-xs-6'
        }, {
            title: 'Number',
            key: 'number.cleaned',
            colClass: 'col-xs-6'
        }];

        // Table drow dropdown
        ctrl.activeRowOptionsBtn = {};
        ctrl.activeRowOptionsBtn.items = [{
            title: 'Listen to Call Live',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CALLS_LISTEN);
            },
            action: function action(call) {
                return $geckoConf.listen(call.id);
            }
        }];

        // Queued Calls Table structure
        ctrl.cols = [{
            colClass: 'text-center',
            key: 'status',
            expandable: true,
            status_title_none: true,
            status_styles: Gecko.Call.statuses
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Queued at',
            key: 'queued_at',
            colClass: 'col-xs-6'
        }, {
            title: 'Number',
            key: 'number.cleaned',
            colClass: 'col-xs-6'
        }];
    }

    angular.module('GeckoEngage').controller('CallsQueueCtrl', CallsQueueCtrl);
})();