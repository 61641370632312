(function () {
    'use strict';

    function hostFields() {
        return {
            restrict: 'E',
            template: '\n                <gecko-fields fields="ctrl.fields" values="ctrl.host"></gecko-fields>\n            ',
            scope: {
                host: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($element) {
                var ctrl = this;

                // Add "social" fields
                if (!ctrl.host || !ctrl.host.social_options || angular.isArray(ctrl.host.social_options)) ctrl.host.social_options = {};

                // Field list
                ctrl.fields = [{
                    id: 'title',
                    label: 'Title'
                }, {
                    id: 'first_name',
                    label: 'First Name',
                    required: true
                }, {
                    id: 'last_name',
                    label: 'Last Name',
                    required: true
                }, {
                    id: 'email',
                    label: 'Email Address',
                    required: true
                }, {
                    id: 'position',
                    label: 'Position'
                }, {
                    id: 'bio',
                    label: 'Biography',
                    type: Gecko.Field.TYPE_TEXTAREA
                }, {
                    id: 'avatar',
                    label: 'Avatar',
                    type: Gecko.Field.TYPE_FILE,
                    mimetype: 'image/*',
                    description: 'Image of the host - 200px(w) x 200px(h) in <code>JPG</code> or <code>PNG</code>.'
                }, {
                    label: 'Social Links',
                    type: Gecko.Field.TYPE_TITLE,
                    colClass: 'col-xs-12'
                }, {
                    id: 'website',
                    label: 'Website',
                    obj: ctrl.host.social_options
                }, {
                    id: 'twitter',
                    label: 'Twitter',
                    obj: ctrl.host.social_options
                }, {
                    id: 'linkedin',
                    label: 'Linkedin',
                    obj: ctrl.host.social_options
                }];
            }
        };
    }
    angular.module('GeckoEngage').directive('hostFields', hostFields);
})();