(function () {
    'use strict';

    function geckoSegment($filter, $geckoModal) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-segment/gecko-segment.html',
            scope: {
                segments: '=',
                campaign: '=',
                senders: '=',
                multiData: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            require: ['geckoSegment', '?^form'],
            link: function geckoSegmentLink(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];
                scope.GeckoUI = GeckoUI;

                angular.forEach(ctrl.segments, function (segment) {

                    segment.conditions = segment.related_conditions.slice(); // Copy the conditions over.
                });

                function initSegments() {
                    ctrl.segments = ctrl.campaign.related_filter.related_children;

                    // Lookup structure for users to segments.
                    angular.forEach(ctrl.segments, function (segment) {
                        segment.user_ids = [];
                        segment.contactLoading = true;
                        segment.contactAmount = 0;

                        // Segment.conditions = Gecko.convertInstancesToObjects(segment.related_conditions || []);
                        angular.forEach(segment.related_users, function (user) {
                            segment.user_ids.push(user.id);
                        });
                    });
                }

                ctrl.contactAmount = function (segment) {
                    segment.contactLoading = true;

                    var inCampaignConditions = [{
                        model: 'subscriber',
                        type: '=',
                        value: [10, 20, 30, 40, 50, 100, 110],
                        campaign_id: ctrl.campaign.id }];

                    var conditions = inCampaignConditions.concat(segment.conditions);

                    new Gecko.Contact().perPage(1).page(1).search(conditions).then(function (contacts) {
                        segment.contactAmount = contacts.pagination.total_items;
                        segment.contactLoading = false;
                        scope.$digest();
                    }, function (err) {
                        GeckoUI.messenger.error(err.errors);
                        // Catching errors nicely
                        segment.contactLoading = false;
                        segment.contactAmount = 0;
                        scope.$digest();
                    });
                };

                initSegments();

                ctrl.userList = function (segment) {
                    if (!segment.user_ids || !segment.user_ids.length) return [];

                    var userMap = GeckoUI.arrayToObjectByKey(ctrl.campaign.related_users, 'id');

                    return segment.user_ids.filter(function (user_id) {
                        return userMap[user_id];
                    }).map(function (user_id) {
                        return userMap[user_id];
                    });
                };

                ctrl.removeSegment = function (segment) {
                    var index = ctrl.segments.indexOf(segment);
                    if (index !== -1) {
                        ctrl.segments.splice(index, 1);
                        if (formCtrl) formCtrl.$setDirty();
                    }
                };

                ctrl.addSegment = function () {
                    var segment = new Gecko.Filter();
                    segment.name = "New Segment";
                    segment.related_users = [];
                    segment.conditions = [];
                    segment.filter_requirement = Gecko.Filter.FILTER_OPTIONAL;
                    ctrl.segments.push(segment);
                    if (formCtrl) formCtrl.$setDirty();
                };

                ctrl.setSegmentFallback = function (segment) {
                    return function (value) {
                        if (arguments.length) {
                            segment[Gecko.Filter.FILTER_REQUIREMENT] = value ? Gecko.Filter.FILTER_OPTIONAL : Gecko.Filter.FILTER_REQUIRED;
                        }
                        switch (segment[Gecko.Filter.FILTER_REQUIREMENT]) {
                            case Gecko.Filter.FILTER_REQUIRED:
                                return false;
                            case Gecko.Filter.FILTER_OPTIONAL:
                                return true;
                            default:
                                return true;
                        }
                    };
                };

                ctrl.openUsersModal = function (segment) {

                    var modal = $geckoModal.segmentUsers($filter('orderBy')(ctrl.campaign.related_users, 'full_name'), segment);

                    modal.result.then(function (data) {
                        if (formCtrl) formCtrl.$setDirty();
                        segment.user_ids = data.selectedUsers;
                        segment.call_sender_id = data.call_sender_id;
                    });
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoSegment', geckoSegment);
})();