(function () {
    'use strict';

    function handleMassAction($geckoSocket, $state, $stateParams) {
        return function (response, stateName) {
            if (response.queue_id) {
                var watcher = $geckoSocket.registerEvent('queue:job', function (data) {
                    if (Gecko.user && Gecko.user.id === data.user_id && data.queue_id === response.queue_id && (data.status === 'complete' || data.status === 'failed')) {
                        // Mass action completed alert
                        if (data.status === 'complete') {
                            GeckoUI.messenger.success(data.message);
                        } else {
                            GeckoUI.messenger.error(data.message);
                        }
                        // Refresh the page (if still on it)
                        if (stateName && $state.$current.name === stateName) $state.go($state.$current.name, $stateParams, { reload: true });
                        // Unbind the watcher
                        watcher();
                    }
                }, true);

                // Mass action started alert
                GeckoUI.messenger.info(response.message);
            } else {
                // Refresh the page
                if (stateName && $state.$current.name === stateName) $state.go($state.$current.name, $stateParams, { reload: true });
                // Mass action complete alert
                GeckoUI.messenger.success(response.message);
            }
        };
    }

    function $dialogEventChangeStatus($rootScope, $geckoSocket, $state, $stateParams, handleMassAction) {
        return function (attendance, attendanceIDs, eventID) {
            var message = 'Attendee status',
                array = GeckoUI.mapObjectArray(Gecko.Attendance.status_titles, { value: 'id', label: 'title' });

            var callback = function callback(status) {
                if (status) {
                    if (attendance) {
                        // Copy attendance ref
                        var _attendance = angular.copy(attendance);
                        _attendance.status = status;

                        _attendance.include('event', 'contact').save().then(function (_attendance) {
                            GeckoUI.messenger.success('Attendee status updated');
                            // Set changed status back on original attendance record
                            attendance.status = _attendance.status;
                            return $state.go($state.$current.name, $stateParams, { reload: true });
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    } else if (attendanceIDs && Array.isArray(attendanceIDs) && eventID) {
                        new Gecko.Contact().massAction({ model: 'contact_ids', contact_ids: attendanceIDs }, Gecko.Workflow.Actions.attendance(eventID, status)).then(function (data) {
                            // Handle mass action
                            handleMassAction(data, $state.$current.name);
                        }).catch(GeckoUI.messenger.error);
                    }
                }
            };
            return GeckoUI.dialog.prompt(message, callback, array);
        };
    }

    function $dialogIntegrationSelect($filter) {
        return function (message, types, callback) {
            var addImportDialog = function addImportDialog(integrations) {
                var integration_options = [];

                // Add File Import option
                if (types && types.indexOf('file_import') !== -1) integration_options.push({ value: 'file_import', label: 'File Import' });

                if (integrations) {
                    angular.forEach(integrations, function (item) {
                        integration_options.push({ value: item.id, label: item.title + ' (' + $filter('formatDate')(item.created_at, Gecko.dateFormat.short) + ')' });
                    });
                }

                if (!message) message = 'Select an integration';
                var array = integration_options;

                return GeckoUI.dialog.prompt(message, callback, array);
            };

            var integrations = new Gecko.Integration().rfields({ 'integration': ['created_at', 'type', 'title'] });

            if (types) integrations.where('type', types);

            return integrations.perPage('all').get().then(function (integrations) {
                return addImportDialog(integrations.toArray());
            }).catch(function (err) {});
        };
    };

    function prepareImportMappingFieldOptions() {
        return function (type, fields) {
            var options = [];

            if (type === 'responses') {
                // Form fields
                options = fields || [];
                // Meta fields
                options.push({
                    id: 'event_id',
                    label: 'Event ID'
                });
                options.push({
                    id: 'user_id',
                    label: 'Captured By'
                });
                options.push({
                    id: 'note',
                    label: 'Note'
                });
            } else if (type === 'events') {
                options = [{
                    id: 'country',
                    label: 'Country'
                }, {
                    id: 'title',
                    label: 'Title'
                }, {
                    id: 'crm',
                    label: 'CRM Value'
                }, {
                    id: 'start_datetime',
                    label: 'Start Date'
                }, {
                    id: 'end_datetime',
                    label: 'End Date'
                }, {
                    id: 'cost',
                    label: 'Cost'
                }, {
                    id: 'parent_id',
                    label: 'Parent Event ID'
                }, {
                    id: 'timezone',
                    label: 'Timezone'
                }, {
                    id: 'maximum',
                    label: 'Maximum'
                }, {
                    id: 'delivery_method',
                    label: 'Delivery Method'
                }, {
                    id: 'webinar_description',
                    label: 'Webinar Description'
                }, {
                    id: 'webinar_url',
                    label: 'Webinar URL'
                }, {
                    id: 'description',
                    label: 'Description'
                }, {
                    id: 'checkin_start',
                    label: 'Self Check-in Time'
                }, {
                    id: 'tags',
                    label: 'Session Tags'
                }, {
                    id: 'registration_type',
                    label: 'Registration Type'
                }, {
                    id: 'closing_datetime',
                    label: 'Registration Closing Date'
                }, {
                    id: 'categories',
                    label: 'Categories'
                }];
            } else if (type === 'organisations') {
                // Organisation fields
                options = fields.filter(function (field) {
                    return !field.is_uneditable && field.field_type === Gecko.Field.FIELD_TYPE_ORGANISATION;
                });
                // Meta fields
                options.push({
                    id: 'organisation_type_id',
                    label: 'Organisation Type (ID)'
                });
                options.push({
                    id: 'organisation_type_id',
                    label: 'Organisation Type (Title)'
                });
            } else {
                options = fields.filter(function (field) {
                    return !field.is_uneditable && field.field_type === Gecko.Field.FIELD_TYPE_CONTACT;
                });
            }

            // Created at should appear for all import types
            options.push({
                id: 'created_at',
                label: 'Created At'
            });

            // Sort import fields alphabetically
            options.sort(function (a, b) {
                if (a.label > b.label) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                return 0;
            });

            return options;
        };
    }

    function getTopParentField() {
        return function (field, fields) {
            var getParent = function getParent(_field) {
                var p = (fields || []).find(function (f) {
                    return f.id === _field.parent_id;
                });
                if (p) {
                    if (p.parent_id) {
                        return getParent(p);
                    } else {
                        return p;
                    }
                }
                return _field;
            };
            return getParent(field);
        };
    }

    angular.module('GeckoEngage').service('handleMassAction', handleMassAction).service('prepareImportMappingFieldOptions', prepareImportMappingFieldOptions).factory('$dialogEventChangeStatus', $dialogEventChangeStatus).factory('$dialogIntegrationSelect', $dialogIntegrationSelect).service('getTopParentField', getTopParentField);
})();