(function () {
    'use strict';

    function OrganisationsListCtrl(organisations, fieldList, $state, $stateParams, $geckoModal, asyncOptions, selectedData) {
        var ctrl = this;
        ctrl.organisations = organisations.toArray();
        ctrl.fieldList = fieldList.toArray();
        ctrl.pagination = organisations.pagination;

        ctrl.tableFilters = [{
            title: 'Type',
            type: 'dropdown',
            options: asyncOptions.pick(selectedData, 'type'),
            optionsQuery: new Gecko.OrganisationType().orderBy('title').perPage('all'),
            optionsKey: 'id',
            optionsLabelKey: 'title',
            stateParam: 'organisation_type_id'
        }];

        // Table structure
        ctrl.cols = [];
        angular.forEach(ctrl.fieldList, function (col, index) {
            ctrl.cols.push({
                type: col.type,
                data_type: col.data_type,
                default_value: col.label.indexOf('Last') > -1 && col.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP ? 'Never' : '',
                title: col.label,
                key: 'field_' + (index + 1),
                sortKey: 'field_' + (index + 1),
                colClass: 12 % ctrl.fieldList.length == 0 ? 'col-md-' + 12 / ctrl.fieldList.length : ''
            });
        });
        ctrl.cols.push({
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Contacts',
            key: 'enrolments_count'
        });

        // Table row action
        ctrl.action = {
            state: 'organisation.overview',
            params: { organisation_id: 'id' }
        };

        // Cog drop down
        ctrl.optionsBtn = {
            iconRight: 'fa-cogs',
            btnTooltip: 'Actions'
        };
        ctrl.optionsBtn.items = [{
            title: 'Organisation field options',
            action: function action() {
                $geckoModal.listViewFieldModal(Gecko.Field.FIELD_TYPE_ORGANISATION);
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Organisations',
            click: function click() {
                return $state.go('organisations');
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    function OrganisationCtrl(organisation, $state, $geckoModal) {
        var ctrl = this;
        ctrl.organisation = organisation;

        ctrl.tabs = [{
            title: 'Overview',
            state: 'organisation.overview'
        }, {
            title: 'Responses',
            state: 'organisation.responses'
        }, {
            title: 'Calls',
            state: 'organisation.calls'
        }, {
            title: 'Messages',
            state: 'organisation.messages'
        }, {
            title: 'Attachments',
            state: 'organisation.attachments',
            lockedWhen: function lockedWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_ATTACHMENT);
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Organisations',
            click: function click() {
                return $state.go('organisations');
            }
        }, {
            label: ctrl.organisation.title,
            active: true
        }];

        // Action button
        ctrl.actionBtn = {
            title: 'Actions',
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary'
        };
        ctrl.actionBtn.items = [{
            title: 'Add contacts',
            action: function action() {
                if (GeckoUI.ableWithReason(Gecko.ABILITY_ENROLMENTS_CREATE) && GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_UPDATE)) {

                    $geckoModal.contactFind().result.then(function (conditions) {
                        return new Gecko.Contact().massAction(conditions, Gecko.Workflow.Actions.enrolment(ctrl.organisation.id)).catch(GeckoUI.messenger.error);
                    }).then(function (response) {
                        $state.go('organisation.overview', { organisation_id: ctrl.organisation.id }, { reload: true });
                        GeckoUI.messenger.success(response.message);
                    });
                }
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_ORGANISATIONS_UPDATE) || $state.current.page_title !== 'Organisation Overview';
            }
        }, {
            title: 'Remove this organisation',
            action: function action() {
                if (GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_DELETE)) {

                    GeckoUI.dialog.confirm('Are you sure you want to remove this Organisation?', function (result) {
                        if (result) {
                            ctrl.organisation.remove().then(function () {
                                GeckoUI.messenger.success('Organisation successfully removed');
                                $state.go('organisations');
                            });
                        }
                    });
                }
            }
        }];
    }

    function OrganisationOverviewCtrl(organisation, organisationTypes, fields, contacts, fieldList, $state, $geckoModal) {

        var ctrl = this;
        ctrl.organisation = organisation;
        ctrl.organisationTypes = organisationTypes;
        ctrl.fields = fields;
        ctrl.fieldList = fieldList;
        ctrl.contacts = contacts.toArray();
        ctrl.pagination = contacts.pagination;

        // Table structure
        ctrl.cols = [];
        angular.forEach(ctrl.fieldList, function (col, index) {
            ctrl.cols.push({
                type: col.type,
                data_type: col.data_type,
                default_value: col.label.indexOf('Last') > -1 && col.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP ? 'Never' : '',
                title: col.label,
                key: 'field_' + (index + 1),
                sortKey: 'field_' + (index + 1),
                colClass: 12 % ctrl.fieldList.length == 0 ? 'col-md-' + 12 / ctrl.fieldList.length : ''
            });
        });

        function addContactToOrganisation() {
            if (GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_DELETE) && GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_UPDATE)) {
                $geckoModal.contactFind().result.then(function (conditions) {
                    return new Gecko.Contact().massAction(conditions, Gecko.Workflow.Actions.enrolment(ctrl.organisation.id)).then(function (response) {
                        $state.go('organisation.contacts', { organisation_id: ctrl.organisation.id }, { reload: true });
                        GeckoUI.messenger.success(response.message);
                    }, function (error) {
                        GeckoUI.messenger.error(error.errors);
                    });
                });
            }
        }

        // Table row action
        ctrl.rowAction = {
            state: 'contact.overview',
            params: { contact_id: 'id' }
        };

        // Add fixed organisation fields.
        ctrl.fields.unshift({
            label: 'Type',
            id: 'organisation_type_id',
            display: 'related_organisation_type.title',
            obj: ctrl.organisation,
            options: organisationTypes,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            type: Gecko.Field.TYPE_SELECT
        });

        ctrl.values = ctrl.organisation.related_current_values;
    }

    function OrganisationAddCtrl(organisation, fields, organisationTypes, $state) {
        var ctrl = this;
        ctrl.organisation = organisation;
        ctrl.fields = fields.toArray();
        ctrl.values = {};
        ctrl.organisationTypes = organisationTypes.toArray();

        //
        angular.forEach(ctrl.fields, function (field) {
            if (field.help_text) {
                field.description = field.help_text;
                field.help_text = null;
            }
        });

        function saveOrganisation() {

            // Set the field values on the organisation
            ctrl.organisation.fields = {};

            angular.forEach(ctrl.fields, function (field) {
                ctrl.organisation.fields['field' + field.id] = ctrl.values[field.id];
            });

            ctrl.organisation.include('values').save().then(function (organisation) {
                GeckoUI.messenger.success('Organisation successfully saved');
                $state.go('organisation.overview', { organisation_id: organisation.id });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        }

        // Action buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: saveOrganisation
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Organisations',
            click: function click() {
                return $state.go('organisations');
            }
        }, {
            label: 'Add Organisation',
            active: true
        }];
    }

    function OrganisationResponsesCtrl($state, responses) {
        var ctrl = this;
        ctrl.responses = responses.toArray();
        ctrl.pagination = responses.pagination;

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Contact',
            key: 'contact.full_name',
            colClass: 'col-xs-2'
        }, {
            title: 'Form',
            key: 'form.name',
            render: function render(value, col, row) {
                if (row.form && row.form.internal_name) return row.form.internal_name;
                return value;
            },
            colClass: 'col-xs-2'
        }, {
            title: 'Event',
            key: 'event.title',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Assigned to',
            key: 'assigned.full_name',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Captured by',
            key: 'user.full_name',
            colClass: 'col-xs-2'
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(response) {
                if (response.contact_id) {
                    $state.go('contact.response', {
                        contact_id: response.contact_id,
                        response_id: response.id
                    });
                } else {
                    $state.go('responseview', {
                        response_id: response.id
                    });
                }
            }
        };
    }

    function OrganisationMessagesCtrl($scope, $filter, $geckoModal, messages) {
        var ctrl = this;
        ctrl.messages = messages.toArray();
        ctrl.pagination = messages.pagination;

        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Contact',
            key: 'contact.full_name',
            colClass: 'col-xs-3'
        }, {
            title: 'From',
            key: 'related_sender.name',
            type: Gecko.Field.TYPE_NAME,
            colClass: 'col-xs-3',
            render: function render(value, col, message) {
                if (message.sender) {
                    return message.sender.name;
                } else if (message.user) {
                    return message.user.full_name;
                }
                return 'System User';
            }
        }, {
            title: 'Type',
            key: 'type',
            sortKey: 'type',
            colClass: 'col-xs-2',
            render: function render(value) {
                if (value == Gecko.Message.TYPE_EMAIL) {
                    return 'Email';
                } else if (value == Gecko.Message.TYPE_SMS) {
                    return 'SMS';
                }
            }
        }, {
            title: 'Status',
            status_styles: [{
                id: 'sent',
                title: 'Sent',
                label: 'label-warning'
            }, {
                id: 'clicked',
                title: 'Clicked',
                label: 'label-success'
            }, {
                id: 'delivered-email',
                title: 'Delivered',
                label: 'label-warning'
            }, {
                id: 'opened',
                title: 'Opened',
                label: 'label-success'
            }, {
                id: 'delivered-sms',
                title: 'Delivered',
                label: 'label-success'
            }],
            colClass: 'col-xs-2',
            render: function render(val, col, message) {
                if (message.type == Gecko.Message.TYPE_SMS) {
                    if (message.delivered) {
                        return 'delivered-sms';
                    } else {
                        return 'sent';
                    }
                } else if (message.type == Gecko.Message.TYPE_EMAIL) {
                    if (message.clicked) {
                        return 'clicked';
                    } else if (message.opened) {
                        return 'opened';
                    } else if (message.delivered) {
                        return 'delivered-email';
                    } else {
                        return 'sent';
                    }
                }
            }
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View message',
            action: function action(message) {
                $geckoModal.messageViewModal(message);
            }
        }, {
            title: 'View message activity',
            action: function action(message) {
                $geckoModal.messageEventsViewModal(message.message_events);
            }
        }];
    }

    angular.module('GeckoEngage').controller('OrganisationsListCtrl', OrganisationsListCtrl).controller('OrganisationCtrl', OrganisationCtrl).controller('OrganisationOverviewCtrl', OrganisationOverviewCtrl).controller('OrganisationResponsesCtrl', OrganisationResponsesCtrl).controller('OrganisationMessagesCtrl', OrganisationMessagesCtrl).controller('OrganisationAddCtrl', OrganisationAddCtrl);
})();