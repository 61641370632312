(function () {
    'use strict';

    function CampaignsSegmentsCtrl(campaign, $scope, $state, unsavedAlert, multiData) {
        var ctrl = this;

        ctrl.campaign = campaign;
        ctrl.segments = campaign.related_filter.related_children;
        ctrl.multiData = multiData;

        // Prep Multidata
        ctrl.multiData.sms_outcomes = (ctrl.multiData.outcomes || []).filter(function (o) {
            return o.group === Gecko.Outcome.GROUP_SMS;
        });
        ctrl.multiData.call_outcomes = (ctrl.multiData.outcomes || []).filter(function (o) {
            return [Gecko.Outcome.GROUP_GLOBAL, Gecko.Outcome.GROUP_SYSTEM].indexOf(o.group) !== -1;
        });
        ctrl.multiData.call_camapigns = (ctrl.multiData.campaigns || []).filter(function (c) {
            return c.module === Gecko.Campaign.MODULE_CALL;
        });
        ctrl.multiData.sms_campaigns = (ctrl.multiData.campaigns || []).filter(function (c) {
            return c.module === Gecko.Campaign.MODULE_SMS;
        });
        ctrl.multiData.senders = (ctrl.multiData.senders || []).filter(function (s) {
            return s.outgoing_voice;
        });

        if (ctrl.multiData.senders) ctrl.senders = ctrl.multiData.senders;

        var campaignSave = function campaignSave() {

            ctrl.campaign.related_filter.children = ctrl.segments.map(function (segment) {

                // Setup some necessary variables on the condition
                segment.campaign_id = ctrl.campaign.id;
                segment.requirement = 'ALL'; // This need to be ALL for the segments to work along with the main campaign conditions.

                var _return = segment.toObject();
                _return.conditions = Gecko.clone(segment.conditions);
                delete _return.assigned;

                return _return;
            });

            ctrl.campaign.related_filter.include('children.conditions', 'children.users', 'conditions').where('campaign_id', ctrl.campaign.id).save().then(function () {

                unsavedAlert.clear();
                // Tell the user it was ok
                GeckoUI.messenger.success('Campaign saved');

                $state.go($state.current.name, {}, { reload: true });

                // // Then update the scope variable to the new one
                // Ctrl.segments       = ctrl.campaign.related_filter.related_children;
            }).catch(function (campaign) {
                GeckoUI.messenger.error(campaign.errors);
            });
        };

        $scope.$on('saveCampaign', function () {
            return campaignSave();
        });
    }

    angular.module('GeckoEngage').controller('CampaignsSegmentsCtrl', CampaignsSegmentsCtrl);
})();