(function () {
    'use strict';

    function ContactOverviewCtrl(contact, values, fields, history, outcomes, integrations, handleMassAction, $filter, $scope, $state, $stateParams) {

        var ctrl = this;
        ctrl.contact = contact;
        ctrl.values = values;
        ctrl.history = history.data;
        ctrl.hide_system_alerts = $stateParams.hide_system_alerts !== undefined ? $stateParams.hide_system_alerts == 'true' || $stateParams.hide_system_alerts == true : Gecko.getCurrentUser().config.hide_system_alerts;
        ctrl.contactValues = angular.copy(ctrl.values);

        ctrl.pagination = {
            current_page: history.current_page,
            total_pages: history.last_page,
            total_items: history.total,
            per_page: history.per_page,
            last_page: history.last_page,
            hideLimit: true
        };
        ctrl.outcomes = outcomes;
        // Prepare fields
        ctrl.fields = $filter('orderBy')(fields, 'order');
        ctrl.profileFields = $filter('filter')(ctrl.fields, { is_calculated: false, is_social: false });
        ctrl.calculatedFields = $filter('filter')(ctrl.fields, { is_calculated: true });
        ctrl.socialFields = $filter('filter')(ctrl.fields, { is_social: true });

        // Prepare synchronisations data fields and values
        ctrl.integrationFields = [];
        GeckoUI.prepareSyncsData(ctrl.contact.related_synchronisations, ctrl.integrationFields, ctrl.contact);

        // This is plebby (but exsists, sigh)
        angular.forEach(ctrl.integrationFields, function (f) {
            f.obj = ctrl.contact;
        });

        angular.forEach(ctrl.profileFields, function (field) {
            if (field.type !== Gecko.Field.TYPE_REPEATABLE) field.colClass = 'col-xs-6';
            //Display date values in utc
            if (field.type === Gecko.Field.TYPE_DATE) field.utc = true;

            if (field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) field.noBlank = true;
        });
        angular.forEach(ctrl.socialFields, function (field) {
            field.colClass = 'col-xs-6';
        });
        angular.forEach(ctrl.calculatedFields, function (field) {
            field.colClass = 'col-xs-6';
        });

        // Collapse/expand field sections
        ctrl.showProfileFields = true;
        ctrl.showCalculatedFields = true;
        ctrl.showContactHistory = true;
        ctrl.showSocialFields = true;
        ctrl.showIntegrationFields = true;
        ctrl.showConsents = true;
        ctrl.showTasks = true;
        ctrl.toggleCollapse = function (section) {
            ctrl[section] = !ctrl[section];
        };

        ctrl.toggleHideSystemAlerts = function () {

            var params = {
                page: $stateParams.page || 1,
                hide_system_alerts: !ctrl.hide_system_alerts
            };
            Gecko.getCurrentUser().setConfig('hide_system_alerts', !ctrl.hide_system_alerts);

            return $state.go($state.$current.name, params);
        };

        // Integration settings
        ctrl.integrations = integrations;
        if (ctrl.integrations.length) {
            // Prepare integration options
            ctrl.integrationOptions = angular.copy(ctrl.integrations);
            angular.forEach(ctrl.integrationOptions, function (integration) {
                integration.title = 'Sync to ' + integration.title + ' (' + $filter('formatDate')(integration.created_at, 'MMM Do YYYY') + ')';
                integration.action = function (data, index, integration) {
                    ctrl.contact.sync(integration.id).then(handleMassAction).catch(GeckoUI.messenger.error);
                };
            });

            ctrl.integrationOptions.unshift({
                title: 'Integrations',
                heading: true
            });

            // Sync dropdown
            ctrl.integrationDropdown = {
                icon: 'fa-cogs',
                items: ctrl.integrationOptions || []
            };
        }
    }

    angular.module('GeckoEngage').controller('ContactOverviewCtrl', ContactOverviewCtrl);
})();