(function () {

    function SaveTemplateModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('SaveTemplateModal', function (_conditions, _logicOperator, _exports, _schedule, _toggles) {
            return {
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal\n                            data-title="Save Template"\n                            footer-btns="ctrl.footerBtns"\n                            icon="fa-users">\n                                <div class="row">\n                                    <gecko-fields fields="ctrl.fields" values="ctrl"></gecko-fields>\n                                </div>\n                        </gecko-modal>\n                    ',
                controller: 'SaveTemplateModalCtrl',
                resolve: {
                    conditions: function conditions() {
                        return _conditions;
                    },
                    logicOperator: function logicOperator() {
                        return _logicOperator;
                    },
                    exports: function exports() {
                        return _exports;
                    },
                    schedule: function schedule() {
                        return _schedule;
                    },
                    toggles: function toggles() {
                        return _toggles;
                    }
                }
            };
        });
    }

    function SaveTemplateModalCtrl($scope, $modalInstance, $state, $stateParams, unsavedAlert, conditions, logicOperator, exports, schedule, toggles, geckoExportService) {
        var ctrl = this;
        ctrl.export = angular.copy(exports);

        ctrl.fields = [{
            id: "name",
            type: Gecko.Field.TYPE_TEXT,
            label: "Template title",
            obj: ctrl.export,
            description: "Give your template a descriptive title.",
            colClass: "col-xs-12"
        }];

        // called when save button clicked
        ctrl.saveTemplate = function () {
            return geckoExportService.saveTemplate(null, ctrl.export, conditions, logicOperator, schedule, toggles).then(function (template) {
                GeckoUI.messenger.success('Template saved with name: ' + template.name);
                $stateParams.export_template_id = template.id;
                return $state.go($state.$current.name, $stateParams, { reload: true });
            }).catch(function () {
                return GeckoUI.messenger.error('There was an issue saving your template, try again.');
            });
        };

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                ctrl.saveTemplate();
                $modalInstance.close();
            }
        }];
    }

    angular.module('GeckoEngage').config(SaveTemplateModal).controller('SaveTemplateModalCtrl', SaveTemplateModalCtrl);
})();