(function () {
    'use strict';

    function ContactOrganisationsCtrl(contact, organisationTypes, fields, $state, enrolments) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.enrolments = enrolments;
        ctrl.organisationTypes = organisationTypes;
        ctrl.fields = fields;
        ctrl.optionsBtn = [];
        ctrl.enrolmentFields = {};

        angular.forEach(ctrl.enrolments, function (enrolment, key) {

            // Clone fields for each enrolment
            ctrl.enrolmentFields[enrolment.id] = [{
                label: 'Organisation Type',
                id: 'organisation_type_id',
                display: 'organisation_type.title',
                type: Gecko.Field.TYPE_SELECT,
                obj: enrolment.organisation,
                options: ctrl.organisationTypes,
                optionsKey: 'id',
                optionsLabelKey: 'title'
            }].concat(ctrl.fields);

            ctrl.optionsBtn[key] = [{
                iconRight: 'fa-cogs',
                btnTooltip: 'Actions',
                items: [{
                    title: 'View Organisation',
                    action: function action() {
                        $state.go('organisation.overview', { organisation_id: enrolment.organisation.id });
                    }
                }, {
                    title: 'Remove from Organisation',
                    action: function action() {
                        new Gecko.BaseModel().call('/enrolments/' + enrolment.id, 'DELETE').then(function () {
                            var index = ctrl.enrolments.indexOf(enrolment);
                            if (index !== -1) {
                                ctrl.enrolments.splice(index, 1);
                            }

                            GeckoUI.messenger.success('Organisation removed from Contact');

                            $state.go($state.$current.name);
                        });
                    }
                }]
            }];
        });
    }

    angular.module('GeckoEngage').controller('ContactOrganisationsCtrl', ContactOrganisationsCtrl);
})();