(function () {
    'use strict';

    function geckoForm(geckoFormLinkFn) {
        return {
            restrict: 'AE',

            templateUrl: '/components/gecko-form/gecko-form.html',

            controller: function geckoFormCtrl() {},
            controllerAs: 'ctrl',
            bindToController: true,

            scope: {
                form: '=',
                contact_id: '=contactId',
                subscriber_id: '=subscriberId',
                event_id: '=eventId',
                call_id: '=callId',
                uuid: '=',
                viewOnSubmit: '&'
            },

            require: ['?^geckoCallPane', 'geckoForm'],

            link: geckoFormLinkFn

        };
    }

    var geckoFormLinkFn = function geckoFormLinkFn($state, $stateParams, $location, $anchorScroll, geckoResponse, munge, geckoStore, LoadingIndicator, fieldErrors) {
        return function (scope, iElement, iAttrs, ctrls) {
            scope.Gecko = Gecko;
            var ctrlPane = ctrls[0];
            var ctrl = ctrls[1];
            ctrl.saving = false;

            // Create unique save key for form values
            var formCacheKey = Gecko.account.routing_id + '|form:' + ctrl.form.id;

            var params = angular.copy($stateParams);
            if (ctrl.contact_id) params.contact_id = ctrl.contact_id;
            if (ctrl.subscriber_id) params.subscriber_id = ctrl.subscriber_id;
            if (ctrl.call_id) params.call_id = ctrl.call_id;

            var idParams = munge(params).keyFilter(/(contact|subscriber)_id/).done();

            formCacheKey += '|' + Object.keys(idParams).sort().map(function (v) {
                return v + ':' + idParams[v];
            }).join('|');

            // Restore values if they exist
            var savedValues = geckoStore.get(formCacheKey);

            if (savedValues) {
                ctrl.values = savedValues;
                if (scope.formCtrl) {
                    scope.formCtrl.$setDirty(); // If we've loaded cahced values set the form to be dirty.
                }
            } else {
                ctrl.values = {};
            }

            // Check if form has fields
            ctrl.hasFields = function () {
                return ctrl.form && ctrl.form.pages && ctrl.form.pages.length && ctrl.form.pages[0].fields && ctrl.form.pages[0].fields.length;
            };

            ctrl.formFields = ctrl.form.pages.reduce(function (fields, page) {
                return fields.concat(page.fields);
            }, []);
            ctrl.childFields = ctrl.formFields.filter(function (field) {
                return field.parent_id;
            });
            ctrl.parentFields = ctrl.childFields.map(function (field) {
                return GeckoUI.gobk(ctrl.formFields, 'id', field.parent_id);
            });
            ctrl.preFillValues = function (field, values) {
                if (field.options.length !== 1) {
                    values[field.id] = null;
                } else {
                    values[field.id] = field.options[0][field.optionsKey || 'value'];
                }
                return values;
            };
            ctrl.updateChildren = function (field) {
                if (!field.options) {
                    return false;
                }
                var fieldOption = field.options.find(function (o) {
                    return o.value == ctrl.values[field.id];
                });
                var children = ctrl.childFields.filter(function (f) {
                    return f.parent_id == field.id;
                });
                children.forEach(function (childField) {
                    childField.options = fieldOption && fieldOption.options ? fieldOption.options : [];
                    ctrl.values = ctrl.preFillValues(childField, ctrl.values);
                });
            };
            ctrl.parentFields.forEach(function (field) {
                scope.$watch(function () {
                    return ctrl.values[field.id];
                }, function () {
                    ctrl.updateChildren(field);
                });
                scope.$watch(function () {
                    return field.options;
                }, function () {
                    ctrl.updateChildren(field);
                });
            });

            // If we are a child of the call pane directive then we offer some services to the call pane.
            if (ctrlPane) {

                // Set the formCtrl on the call pane controller
                ctrlPane.formCtrl = scope.formCtrl;

                ctrlPane.submitForm = function () {
                    return scope.ctrl.saveResponse();
                };

                ctrlPane.discardForm = function () {
                    scope.formCtrl.$setPristine();
                    geckoStore.remove(formCacheKey);
                };
            }

            // Events for field update.
            scope.$on('geckoFieldsLoad', function (event, state) {
                event.stopPropagation();
                if (state === 'start') LoadingIndicator.show('form-load-' + scope.$id);
                if (state === 'complete') {
                    LoadingIndicator.hide('form-load-' + scope.$id);
                    ctrl.loaded = true;
                }
            });

            scope.$on('geckoFieldUpdate', function (event, field, values) {
                event.stopPropagation();
                geckoStore.set(formCacheKey, values);
            });

            ctrl.saveResponse = function () {

                ctrl.saving = true;

                var responseValues = {};
                if (ctrl.contact_id) responseValues.contact_id = ctrl.contact_id;
                if (ctrl.event_id) responseValues.event_id = ctrl.event_id;
                if (ctrl.uuid) responseValues.uuid = ctrl.uuid;else responseValues.form_id = ctrl.form.id;
                if (ctrl.call_id) responseValues.call_id = ctrl.call_id;

                return geckoResponse.save(munge(ctrl.form.pages).byKey('fields').flatten().done(), ctrl.values, responseValues).then(function (response) {

                    scope.formCtrl.$setPristine();
                    geckoStore.remove(formCacheKey);

                    if (ctrl.viewOnSubmit()) {
                        // Go to the new response.
                        if (response.contact_id) {
                            $state.go('contact.response', { contact_id: response.contact_id, response_id: response.id });
                        } else {
                            $state.go('responseview', { response_id: response.id });
                        }
                    } else {
                        ctrl.submitted = true;
                    }

                    // Notification of response created
                    GeckoUI.messenger.success('Response has been created.');

                    scope.$digest();
                }).catch(function (response) {
                    // TODO: Handle errors properly
                    ctrl.errors = Gecko.errorsAsArray(response.errors);
                    fieldErrors.setErrors(response.errors);
                    // Move back to first page
                    if (scope.pagingCtrl) scope.pagingCtrl.current = 0;

                    // Scroll back to the top
                    $location.hash('form-top-' + scope.$id);
                    $anchorScroll();

                    // Apply
                    scope.$digest();
                }).finally(function () {
                    return ctrl.saving = false;
                });
            };
        };
    };

    angular.module('GeckoEngage').factory('geckoFormLinkFn', geckoFormLinkFn).directive('geckoForm', geckoForm);
})();