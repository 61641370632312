/* global angular */
(function () {
    'use strict';

    function geckoUISelectTheme(uiSelectConfig) {
        uiSelectConfig.theme = 'gecko';
    }

    function geckoUISelectThemeTemplates($templateCache) {

        $templateCache.put('gecko/choices.tpl.html', '<ul class="ui-select-choices ui-select-choices-content ui-select-dropdown dropdown-menu"role="listbox"ng-show="$select.open && $select.items.length > 0"> \
                <li class="ui-select-choices-group" id="ui-select-choices-{{ $select.generatedId }}" > \
                    <div class="divider" ng-show="$select.isGrouped && $index > 0"></div> \
                    <div ng-show="$select.isGrouped" class="ui-select-choices-group-label dropdown-header" ng-bind="$group.name"></div> \
                    <div ng-attr-id="ui-select-choices-row-{{ $select.generatedId }}-{{$index}}" class="ui-select-choices-row"ng-class="{active: $select.isActive(this), disabled: $select.isDisabled(this)}" role="option"> \
                        <span class="ui-select-choices-row-inner"></span> \
                    </div> \
                </li> \
                <div ng-if="$select.moreToLoad && $select.loadingOptions" class="select-loading"> \
                    <img src="https://images.geckoform.com/logo/gecko-5E9E0A.svg"> \
                </div> \
                <a class="gecko-ui-select-load-more" ng-show="$select.moreToLoad && !$select.loadingOptions" ng-click="$select.loadMore($select)">Load More</a> \
            </ul>');
        $templateCache.put('gecko/match-multiple.tpl.html', '<span class="ui-select-match"> <span ng-repeat="$item in $select.selected track by $index"> <span class="ui-select-match-item"tabindex="-1"type="button"ng-disabled="$select.disabled"ng-click="$selectMultiple.activeMatchIndex = $index;"ng-class="{\'btn-primary\':$selectMultiple.activeMatchIndex === $index, \'select-locked\':$select.isLocked(this, $index)}"ui-select-sort="$select.selected"> <span class="close ui-select-match-close" ng-hide="$select.disabled" ng-click="$selectMultiple.removeChoice($index)" ng-class="ctrl.field.xClass($item)">&times;</span> <span uis-transclude-append></span> </span> </span> </span>');
        $templateCache.put('gecko/match.tpl.html', '<div class="ui-select-match" ng-hide="$select.open && $select.searchEnabled" ng-disabled="$select.disabled" ng-class="{\'btn-default-focus\':$select.focus}"> <span tabindex="-1"class="form-control ui-select-toggle"aria-label="{{ $select.baseTitle }} activate"ng-disabled="$select.disabled"ng-click="$select.activate()"style="outline: 0;"> <span ng-show="$select.isEmpty()" class="ui-select-placeholder text-muted">{{$select.placeholder}}</span> <span ng-hide="$select.isEmpty()" class="ui-select-match-text pull-left" ng-class="{\'ui-select-allow-clear\': $select.allowClear && !$select.isEmpty()}" ng-transclude=""></span> <i class="caret pull-right" ng-click="$select.toggle($event)"></i> <a ng-show="$select.allowClear && !$select.isEmpty() && ($select.disabled !== true)" aria-label="{{ $select.baseTitle }} clear" ng-click="$select.clear($event)" class="ui-select-clear-button"> <i class="fa fa-times" aria-hidden="true"></i> </a> </span> </div>');
        $templateCache.put('gecko/no-choice.tpl.html', '<ul class="ui-select-no-choice dropdown-menu"ng-show="$select.items.length == 0"> <li ng-transclude> </li> </ul>');
        $templateCache.put('gecko/select-multiple.tpl.html', '<div class="ui-select-container ui-select-multiple ui-select-bootstrap dropdown form-control" ng-class="{open: $select.open}"> <div> <div class="ui-select-match"></div> <input type="search"autocomplete="off"autocorrect="off"autocapitalize="off"spellcheck="false"class="ui-select-search input-xs"placeholder="{{$selectMultiple.getPlaceholder()}}"ng-disabled="$select.disabled"ng-click="$select.activate()"ng-model="$select.search" no-unsaved role="combobox"aria-label="{{ $select.baseTitle }}"ondrop="return false;"> </div> <div class="ui-select-choices"></div> <div class="ui-select-no-choice"></div> </div>');
        $templateCache.put('gecko/select.tpl.html', '<div class="ui-select-container ui-select-bootstrap dropdown" ng-class="{open: $select.open}"> <div class="ui-select-match"></div> <input type="search" autocomplete="off" tabindex="-1"aria-expanded="true"aria-label="{{ $select.baseTitle }}"aria-owns="ui-select-choices-{{ $select.generatedId }}"aria-activedescendant="ui-select-choices-row-{{ $select.generatedId }}-{{ $select.activeIndex }}"class="form-control ui-select-search"ng-class="{ \'ui-select-search-hidden\' : !$select.searchEnabled }"placeholder="{{$select.placeholder}}"ng-model="$select.search" no-unsaved ng-show="$select.open"> <div class="ui-select-choices"></div> <div class="ui-select-no-choice"></div> </div>');
    }

    angular.module('GeckoEngage').config(geckoUISelectTheme).run(geckoUISelectThemeTemplates);
})();