(function () {

    'use strict';

    function DevicesRoute($stateProvider) {
        $stateProvider.state('usersdevices', {
            url: "/settings/devices?{page}",
            templateUrl: "/modules/devices/views/index.1998bc46.html",
            controller: 'DevicesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Devices',
            requires: [Gecko.ABILITY_DEVICES_VIEW],
            redirectKey: 'module_devices',
            resolve: {
                devices: function devices($stateParams) {
                    return new Gecko.Device().include('user').perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ device: ['label', 'udid', 'type', 'app_version', 'os_version'], user: ['full_name'] }).get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(DevicesRoute);
})();