(function () {
    'use strict';

    function ExportsRoute($stateProvider) {
        $stateProvider.state('settingsexports', {
            page_title: 'Export Data',
            url: "/settings/exports?{page}&{from}&{to}&{search}&{type}&{frequency}&{status}&{user_id}",
            templateUrl: "/modules/exports/views/index.70841d40.html",
            controller: 'SettingsExportCtrl',
            controllerAs: 'ctrl',
            redirectKey: 'module_exports',
            requires: [Gecko.ABILITY_EXPORTS],
            resolve: {
                exports: function exports($stateParams) {
                    var exports = new Gecko.Export().perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).include('user', 'schedule').orderBy('updated_at', 'DESC');

                    exports.rfields({
                        export: ['status', 'progress', 'name', 'type', 'created_at', 'updated_at', 'user_id', 'export_count', 'download_url', 'structure_id'],
                        schedule: ['cron_details', 'last_run_at', 'next_run_at'],
                        user: ['full_name', 'color']
                    });

                    if (angular.isDefined($stateParams.from)) {
                        exports.where('export_created_after', $stateParams.from);
                    }
                    if (angular.isDefined($stateParams.to)) {
                        exports.where('export_created_before', $stateParams.to);
                    }
                    if (angular.isDefined($stateParams.type)) {
                        exports.where('type', $stateParams.type);
                    }
                    if (angular.isDefined($stateParams.status)) {
                        exports.where('status', $stateParams.status);
                    }
                    if (angular.isDefined($stateParams.user_id)) {
                        exports.where('export_user_id', $stateParams.user_id);
                    }

                    if (angular.isDefined($stateParams.search)) {
                        exports.search($stateParams.search);
                    }

                    if (angular.isDefined($stateParams.frequency)) {
                        if (['recurring', 'once'].indexOf($stateParams.frequency) !== -1) {
                            if ($stateParams.frequency === 'recurring') {
                                exports.base_slug = exports.base_slug += '/recurring';
                            } else {
                                exports.base_slug = exports.base_slug += '/single';
                            }
                        } else {
                            exports.where('schedule', $stateParams.frequency);
                        }
                    }

                    return exports.get();
                }

            }
        }).state('exportnew', {
            page_title: 'New Export',
            url: "/settings/exports/{export_id}?{form_id}&{event_id}&{campaign_id}&{broadcast_id}&{contacts}&{responses}&{export_template_id}",
            templateUrl: "/modules/exports/views/view.845515d5.html",
            controller: 'SettingsNewExportCtrl',
            controllerAs: 'ctrl',
            requires: [Gecko.ABILITY_EXPORTS],
            resolve: {
                data: function data(geckoDataService, exportTemplates) {
                    return geckoDataService.fetch(['outcomes', 'labels', 'users', 'templates', 'categories']).then(function (data) {
                        return {
                            exportTemplates: exportTemplates,
                            outcomes: data.outcomes.filter(function (o) {
                                return o.group === Gecko.Outcome.GROUP_GLOBAL;
                            }),
                            //labels: fn(data.labels),
                            users: data.users,
                            templates: data.templates,
                            categories: data.categories
                        };
                    });
                },
                exportTemplates: function exportTemplates() {
                    return new Gecko.ExportTemplate().perPage('all').orderBy('name').rfields({ exporttemplate: ['name'] }).get();
                },
                export_data: function export_data($stateParams) {
                    if ($stateParams.export_id != "new" && $stateParams.export_id !== "") {
                        var rfields = ['id', 'account_id', 'additional', 'created_at', 'updated_at', 'deleted_at', 'escape_output', 'expires_at', 'export_count', 'status', 'download_url', 'filter_data', 'type', 'ftp_config', 'progress', 'name', 'qr_codes', 'send_to', 'structure_data', 'historical_fields', 'recipients'];
                        return new Gecko.Export().rfields({ export: rfields }).include('schedule').get($stateParams.export_id);
                    } else {
                        return new Gecko.Export();
                    }
                },
                templateData: function templateData($stateParams) {
                    if ($stateParams.export_id === "new" && $stateParams.export_template_id) {
                        return new Gecko.ExportTemplate().orderBy('name').rfields({ exporttemplate: ['name', 'structure_data'] }).get($stateParams.export_template_id);
                    }
                    return false;
                },
                selectedData: function selectedData($stateParams, export_data, templateData) {
                    if (templateData) {
                        var filter = templateData.structure_data.structure_data.root_module.config;
                    } else {
                        var filter = export_data.filter_data;
                    }

                    var structure_data = export_data.structure_data;

                    var data_to_use = {};

                    if (!templateData && structure_data && !Array.isArray(structure_data)) {
                        data_to_use = structure_data.root_module.config;
                    } else {
                        data_to_use = filter;
                    }

                    if (export_data.related_schedule) filter = export_data.related_schedule.configuration.filter;

                    var campaignId = void 0,
                        eventId = void 0,
                        filterId = void 0,
                        formId = void 0,
                        broadcastId = void 0;
                    if (filter) {
                        campaignId = data_to_use.campaign_id;
                        eventId = data_to_use.event_id;
                        filterId = data_to_use.filter_id;
                        formId = data_to_use.form_id;
                        broadcastId = data_to_use.broadcast_id;
                    } else {
                        campaignId = $stateParams.campaign_id;
                        eventId = $stateParams.event_id;
                        formId = $stateParams.form_id;
                        broadcastId = $stateParams.broadcast_id;
                    }

                    var _querys = {};

                    if (campaignId) {
                        _querys.campaign = new Gecko.Campaign().rfields({ user: ['title'] }).path(campaignId);
                    }
                    if (eventId) {
                        _querys.event = new Gecko.Event().where('event_id', eventId).rfields({ event: ['nice_title'] });
                    }
                    if (filterId) {
                        _querys.filter = new Gecko.Filter().rfields({ filter: ['name'] }).path(filterId);
                    }
                    if (formId) {
                        _querys.form = new Gecko.Form().rfields({ form: ['name', 'internal_name'] }).path(formId);
                    }
                    if (broadcastId) {
                        _querys.broadcast = new Gecko.Campaign().rfields({ campaign: ['title'] }).path(broadcastId);
                    }

                    return Gecko.multi(_querys).then(function (data) {
                        if (data.event) data.event = data.event.data;
                        return data;
                    });
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(ExportsRoute);
})();