(function () {
    'use strict';

    function geckoCallPaneListen() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-pane-listen.html',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {},
            controller: function controller($rootScope, $scope, $geckoCall, $geckoConf, $geckoWatch, $geckoCallSidebar) {
                var ctrl = this;
                $scope.$geckoCall = $geckoCall;
                $scope.$geckoConf = $geckoConf;

                ctrl.call = $geckoConf.call;
                ctrl.contact = ctrl.call.related_contact;
                ctrl.user = ctrl.call.related_user;
                ctrl.number = ctrl.call.related_number;

                // Watch for contact updates
                $geckoWatch($geckoConf.call, 'related_contact', function (value) {
                    if (value) ctrl.contact = value;
                });
                // Watch for campaign
                $geckoWatch($geckoConf.call, 'related_campaign', function (value) {
                    if (value) {
                        ctrl.campaign = value;
                    }
                });

                // Get classes
                ctrl.getPaneClass = function () {
                    return {
                        'incoming': ctrl.call.type == Gecko.Call.TYPE_INBOUND,
                        'outgoing': ctrl.call.type == Gecko.Call.TYPE_OUTBOUND
                    };
                };

                ctrl.getUser = function () {
                    return ctrl.user && ctrl.user.full_name ? ctrl.user.full_name : 'Unknown';
                };

                ctrl.getContact = function () {
                    return ctrl.contact && ctrl.contact.full_name ? ctrl.contact.full_name : 'Unknown';
                };

                // Get pane title
                ctrl.getPaneTitle = function () {
                    return '<strong>' + ctrl.getUser() + '</strong> is on a call with <strong>' + ctrl.getContact() + '</strong>';
                };

                // Open listen pane
                $geckoCallSidebar.open = true;
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallPaneListen', geckoCallPaneListen);
})();