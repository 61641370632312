(function () {
    'use strict';

    function UserModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('segmentUsers', function (_allUsers, _segment) {
            return {
                templateUrl: '/components/gecko-segment/_modals/users.html',
                controllerAs: 'ctrl',
                controller: 'UserSelectModalCtrl',
                resolve: {
                    // Resolve the users passed in to allow selection from a filtered set of users
                    // Or just get all users.
                    allUsers: function allUsers() {
                        return _allUsers;
                    },
                    segment: function segment() {
                        return _segment;
                    },
                    sender: function sender(asyncCache) {
                        if (_segment.call_sender_id) {
                            return asyncCache.get(new Gecko.Sender().rfields({ sender: ['name'] }), _segment.call_sender_id);
                        }
                        return null;
                    }
                }
            };
        });
    }

    function UserSelectModalCtrl($modalInstance, asyncOptions, allUsers, segment, sender) {
        var ctrl = this;
        ctrl.segment = segment;
        ctrl.users = allUsers;
        ctrl.selectedUsers = angular.copy(segment.user_ids);

        ctrl.values = {
            selectedUsers: angular.copy(segment.user_ids),
            call_sender_id: ctrl.segment.call_sender_id
        };

        // Fields
        ctrl.fields = [{
            colClass: 'none',
            label: 'Segment users',
            key: 'selectedUsers',
            type: Gecko.Field.TYPE_MULTI,
            options: ctrl.users,
            optionsKey: 'id',
            optionsLabelKey: 'full_name'
        }, {
            colClass: 'none',
            label: 'Segment caller id',
            key: 'call_sender_id',
            type: Gecko.Field.TYPE_SELECT,
            options: sender ? [sender.toObject()] : [],
            getOptions: asyncOptions.create(new Gecko.Sender().where('outgoing_voice', 1).orderBy('name').rfields({ sender: ['name', 'type', 'tel'] }), null, { search: 'keywordSearch' }),
            choiceTemplate: 'sender-choice',
            matchTemplate: 'sender-match',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return !Gecko.User.hasOutboundOrInbound();
            },
            optionsGroup: function optionsGroup(item) {
                return item && item.type && item.type == Gecko.Sender.GECKOCHAT ? "GeckoChat" : undefined;
            }
        }];

        ctrl.footerBtns = [{
            preset: 'save',
            title: 'Done',
            action: function action() {
                $modalInstance.close(ctrl.values);
            }
        }];
    }

    angular.module('GeckoEngage').config(UserModals).controller('UserSelectModalCtrl', UserSelectModalCtrl);
})();