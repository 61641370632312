(function () {
    'use strict';

    function SettingsExportCtrl($state, $stateParams, $filter, $geckoSocket, exports) {
        var ctrl = this;

        ctrl.dateFrom = $stateParams.from;
        ctrl.dateTo = $stateParams.to;

        ctrl.exports = exports.toInstances();
        ctrl.exportsPagination = exports.pagination;
        ctrl.exports.param = 'page';

        $geckoSocket.watch('export', ctrl.exports, ['status', 'progress', 'export_count', 'download_url']);

        angular.forEach(ctrl.exports, function (_export) {
            Object.defineProperty(_export, "frequency", {
                get: function get() {
                    if (this.related_schedule) {
                        var frequency = "Recurring";
                        switch (this.related_schedule.cron_details.type) {
                            case "month":
                                frequency = "Monthly";
                                break;
                            case "week":
                                frequency = "Weekly";
                                break;
                            case "day":
                                frequency = "Daily";
                                break;
                            case "hour":
                                frequency = "Hourly";
                                break;
                        }
                        return frequency;
                    }

                    return "Once";
                }
            });
        });

        ctrl.cols = [{
            key: 'status',
            colClass: 'text-center',
            expandable: true,
            status_title_none: true,
            status_styles: Gecko.Export.statuses
        }, {
            title: 'Progress',
            key: 'progress',
            // Progress gets 'stuck' at 1% due to a race condition if an export is small
            // override 1% Progress if download_url is present as this only occurs when export is complete
            render: function render(value, _export, exportEntry) {
                if (value < 100 && exportEntry.download_url || exportEntry.status === Gecko.Export.STATUS_COMPLETE) return '100%';
                if (value) return value + '%';
                return "-";
            }
        }, {
            title: 'Name',
            key: 'name'
        }, {
            title: 'Frequency',
            key: 'frequency'
        }, {
            title: 'Export type',
            key: 'type',
            colClass: 'col-xs-3',
            render: function render(type) {
                return $filter('capitalize')(type);
            }
        }, {
            title: 'Export date',
            key: 'created_at',
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            colClass: 'col-xs-3'
        }, {
            title: 'Previous Run',
            key: 'related_schedule.last_run_at',
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            colClass: 'col-xs-3',
            utc: false
        }, {
            title: 'Next Run',
            key: 'related_schedule.next_run_at',
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            colClass: 'col-xs-3',
            utc: false
        }, {
            title: 'Exported by',
            type: Gecko.Field.TYPE_NAME,
            key: 'related_user.full_name',
            colClass: 'col-xs-3'
        }, {
            title: 'Item count',
            key: 'export_count',
            colClass: 'col-xs-3'
        }];

        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen(exportEntry) {
                return exportEntry.status != Gecko.Export.STATUS_COMPLETE && exportEntry.status != Gecko.Export.STATUS_EXPIRED && exportEntry.status != Gecko.Export.STATUS_FAILED;
            }
        };

        ctrl.rowOptionsBtn.items = [{
            title: 'Download files',
            action: function action(exportEntry) {
                window.open(exportEntry.download_url);
            },
            hideWhen: function hideWhen(exportEntry) {
                return exportEntry.status != Gecko.Export.STATUS_COMPLETE;
            }
        }, {
            title: 'Re-export',
            action: function action(exportEntry) {
                var _export = angular.copy(exportEntry);
                _export.clone(exportEntry.name).then(function () {
                    GeckoUI.messenger.success('You will receive an email with a link to download your export shortly.');
                    $state.go('settingsexports', {}, { reload: true });
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Re-Export Success', {
                        'export id': _export.id
                    });
                }).catch(function (err) {
                    // Error message
                    GeckoUI.messenger.error(err);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Re-Export Error', {
                        'export id': _export.id,
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            },
            hideWhen: function hideWhen(exportEntry) {
                return exportEntry.status != Gecko.Export.STATUS_EXPIRED;
            }
        }, {
            title: "Run Now",
            hideWhen: function hideWhen(exportEntry) {
                return !(exportEntry.related_schedule || exportEntry.schedule);
            },
            action: function action(exportEntry) {
                GeckoUI.dialog.confirm('Run the export schedule now?', function (value) {
                    if (value) {
                        var _exportSchedule = new Gecko.Schedule().fill(exportEntry.related_schedule);
                        return _exportSchedule.process().then(function () {
                            GeckoUI.messenger.success('Export has started');
                            return $state.go($state.$current.name, $stateParams, { reload: true });
                        }).catch(function (err) {
                            return GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }, {
            title: "Stop Recurring",
            hideWhen: function hideWhen(exportEntry) {
                return !(exportEntry.related_schedule || exportEntry.schedule);
            },
            action: function action(exportEntry) {
                GeckoUI.dialog.confirm('Are you sure you want to stop this recurring export?', function (value) {
                    if (value) {
                        var _export = new Gecko.Export().fill({
                            id: exportEntry.id,
                            recurring: 0
                        });
                        return _export.save().then(function () {
                            // Success message
                            GeckoUI.messenger.success('Export will no longer recur');
                            // Reload the page
                            return $state.go($state.$current.name, $stateParams, { reload: true });
                        }).catch(function (err) {
                            return GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }

        }];

        ctrl.filters = [{
            title: "Date Range",
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            }
        }, {
            title: 'Type',
            type: 'checkbox',
            options: [{ label: 'Contacts', value: Gecko.Export.TYPE_CONTACT }, { label: 'Responses', value: Gecko.Export.TYPE_RESPONSE }, { label: 'Campaigns', value: Gecko.Export.TYPE_CAMPAIGN }, { label: 'Organisations', value: Gecko.Export.TYPE_ORGANISATION }, { label: 'Calls', value: Gecko.Export.TYPE_CALL }, { label: 'Events', value: Gecko.Export.TYPE_EVENT }, { label: 'Attendees', value: Gecko.Export.TYPE_ATTENDANCE }, { label: 'Messages', value: Gecko.Export.TYPE_MESSAGE }],
            stateParam: 'type'
        }, {
            title: 'Frequency',
            type: 'radio',
            options: [{ label: 'Once', value: 'once' }, { label: 'All Recurring', value: 'recurring' }, { label: 'Hourly', value: 'hourly' }, { label: 'Daily', value: 'daily' }, { label: 'Weekly', value: 'weekly' }, { label: 'Monthly', value: 'monthly' }],
            stateParam: 'frequency'
        }, {
            title: 'Status',
            type: 'checkbox',
            options: [{ label: 'Completed', value: 30 }, { label: 'Started', value: 20 }, { label: 'Queued', value: 10 }, { label: 'Expired', value: 40 }, { label: 'Failed', value: 90 }],
            stateParam: 'status'
        }, {
            title: 'User',
            type: 'dropdown',
            optionsQuery: new Gecko.User().rfields({ user: ['full_name'] }).orderBy('full_name'),
            optionsKey: 'id',
            optionsLabelKey: 'full_name',
            stateParam: 'user_id'
        }];

        // Row Action
        ctrl.rowAction = {
            state: 'exportnew',
            params: { export_id: 'id' }
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Exports',
            active: true
        }];

        ctrl.newExport = function () {
            $state.go("exportnew", { export_id: "new" });
        };
    }

    function SettingsNewExportCtrl($state, $stateParams, $scope, data, export_data, selectedData, templateData, geckoExportService) {
        var ctrl = this;

        ctrl.data = data;

        ctrl.export = export_data;

        ctrl.selectedData = selectedData;
        ctrl.templateData = templateData;

        var isRecurring = geckoExportService.exportIsRecurring(ctrl.export);

        ctrl.exportBtn = {
            btnTitle: isRecurring ? 'Save Export' : 'Export',
            btnClass: isRecurring ? 'btn-success' : 'btn-primary',
            btnIcon: isRecurring ? 'fa-check-circle' : 'fa-share-square'
        };

        // HIDE when:
        // the export is locked (has id and NOT recurring)
        // OR
        // legacy is set in the query string
        ctrl.hideExportBtn = geckoExportService.exportStructureIsLocked(ctrl.export);

        ctrl.hideSaveTemplateBtn = !!ctrl.export.id || $stateParams.broadcast_id || $stateParams.contacts || $stateParams.event_id || $stateParams.form_id || $stateParams.responses;

        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Exports',
            click: function click() {
                $state.go('settingsexports');
            }
        }, {
            label: ctrl.export.id ? ctrl.export.name ? ctrl.export.name : "Export " + ctrl.export.id : 'New',
            active: true
        }];

        // broadcast received by components/gecko-export-ui/gecko-export-ui
        ctrl.doExport = function () {
            return $scope.$broadcast('do-export');
        };
        ctrl.saveAsTemplate = function () {
            return $scope.$broadcast('open-save-template-modal');
        };
        ctrl.updateTemplate = function () {
            return $scope.$broadcast('update-template');
        };

        ctrl.templateOptionsBtn = {
            btnTitle: "Save template",
            iconLeft: "fa-save",
            iconright: "fa-angle-down",
            hideWhen: function hideWhen() {
                return !ctrl.hideSaveTemplateBtn;
            }
        };

        ctrl.templateOptionsBtn.items = [{
            title: 'Update template',
            action: function action() {
                return ctrl.updateTemplate();
            }
        }, {
            title: 'Save as new template',
            action: function action() {
                return ctrl.saveAsTemplate();
            }
        }];
    }

    angular.module('GeckoEngage').controller('SettingsExportCtrl', SettingsExportCtrl).controller('SettingsNewExportCtrl', SettingsNewExportCtrl);
})();