(function () {
    'use strict';

    function geckoFormFieldIntegrations() {

        return {
            restrict: 'E',
            scope: {
                form: '=',
                field: '='
            },
            template: '\n                <gecko-fields \n                    ng-repeat="fields in ctrl.fields track by $index"\n                    fields="fields" \n                    values="ctrl.field">\n                </gecko-fields>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($formBuilderService, $filter) {
                var ctrl = this;
                ctrl.organisation_fields = $formBuilderService.data.organisation_fields;
                ctrl.integrations = $formBuilderService.data.integrations;

                // ORGANISATION field only supports ENGAGE_DYNAMICS
                if (ctrl.field.type === Gecko.Field.TYPE_ORGANISATION) {
                    var orgFieldSupportedIntegrations = [Gecko.Integration.TYPE_ENGAGE_DYNAMICS, Gecko.Integration.TYPE_RADIUS];
                    ctrl.integrations = ctrl.integrations.filter(function (i) {
                        return orgFieldSupportedIntegrations.includes(i.type);
                    });
                }

                //Fields
                ctrl.fields = [];

                // Prepare pre-set integration mappings
                // ctrl.field.integration_fields = ctrl.field.integration_fields ? ctrl.field.integration_fields.map(function(intField){
                //     // Get format from pivot (if exisits)
                //     var pivFormat = intField.pivot ? intField.pivot.format : null;
                //     var format = pivFormat || intField.format;
                //     const orgFieldId = intField.pivot ? intField.pivot.organisation_field_id : null;

                //     return {
                //         field_id: ctrl.field.id,
                //         integration_field_id: intField.id,
                //         organisation_field_id: orgFieldId,
                //         format: format
                //     }
                // }) : [];

                // Filter out Radius's ATTENDEE module
                angular.forEach(ctrl.integrations, function (integration) {
                    if (integration.type === Gecko.Integration.TYPE_RADIUS) {
                        integration.integration_fields = integration.integration_fields.filter(function (field) {
                            return field.module !== 'Attendees' && field.type !== 'rootlookup';
                        });
                    } else {
                        integration.integration_fields = integration.integration_fields.filter(function (field) {
                            return field.type !== 'rootlookup';
                        });
                    }
                });

                //Integration syncing
                var master_index = 0;
                angular.forEach(ctrl.integrations, function (integration, int_index) {

                    //Process integration fields
                    var integrationFields = $filter('orderBy')(integration.integration_fields, 'label');

                    var isFieldInIntegration = function isFieldInIntegration(field, integration) {
                        var findByIntegratioFieldId = GeckoUI.findById(field.integration_field_id);
                        return GeckoUI.indexOf(findByIntegratioFieldId)(integration.integration_fields) !== -1;
                    };

                    var reorderField = function reorderField(field, format, index) {
                        var currentIndex = GeckoUI.indexOf(function (intField) {
                            return isFieldInIntegration(intField, integration) && intField.format === format;
                        })(field.integration_fields);
                        if (currentIndex === -1 || currentIndex === index) {
                            return;
                        }
                        field.integration_fields = GeckoUI.moveElement(field.integration_fields)(currentIndex, index);
                    };

                    var createGetterSetter = function createGetterSetter(field, label, format, index) {
                        reorderField(field, format, index); // re-order the field in integration_fields so it is in the correct index
                        return 'id';
                    };

                    var addField = function addField(fields, index) {
                        return function (label, format) {
                            format = format || '';
                            fields.push({
                                id: createGetterSetter(ctrl.field, label, format, index), // create getter/setter for modifying the integration_fields array
                                label: integration.title + ' ' + label,
                                help_text: integration.title_with_date,
                                type: Gecko.Field.TYPE_SELECT,
                                colClass: ctrl.field.type === Gecko.Field.TYPE_ORGANISATION ? 'col-xs-6' : 'col-xs-12',
                                options: $filter('orderBy')(integration.integration_fields, 'label'),
                                optionsKey: 'id',
                                optionsLabelKey: 'label',
                                optionsGroup: function optionsGroup(opt) {
                                    return opt.module;
                                },
                                format: format,
                                obj: $formBuilderService.integrationFieldsMap[integration.id][ctrl.field.id] && $formBuilderService.integrationFieldsMap[integration.id][ctrl.field.id][format] ? $formBuilderService.integrationFieldsMap[integration.id][ctrl.field.id][format] : $formBuilderService.integrationFieldsMap[integration.id][ctrl.field.id]
                            });
                            if (ctrl.field.type === Gecko.Field.TYPE_ORGANISATION) {
                                fields.push({
                                    id: 'organisation_field_id', // create getter/setter for modifying the integration_fields array
                                    label: 'Organisation Field',
                                    type: Gecko.Field.TYPE_SELECT,
                                    colClass: 'col-xs-6',
                                    options: ctrl.organisation_fields,
                                    optionsKey: 'id',
                                    optionsLabelKey: 'label',
                                    obj: $formBuilderService.integrationFieldsMap[integration.id][ctrl.field.id]
                                });
                            }
                            master_index += 1;
                            fields.__proto__.and = addField(fields, master_index);
                            return fields;
                        };
                    };
                    var createIntegrationFields = function createIntegrationFields(label, format) {
                        var fields = [];
                        return addField(fields, master_index)(label, format);
                    };

                    //Name format
                    if (ctrl.field.type == Gecko.Field.TYPE_NAME) {
                        ctrl.fields[int_index] = createIntegrationFields('First Name Field', 'first_name').and('Last Name Field', 'last_name');
                        //Address format
                    } else if (ctrl.field.type == Gecko.Field.TYPE_ADDRESS) {
                        ctrl.fields[int_index] = createIntegrationFields('Address1', 'street_address').and('Address2', 'address_line_2').and('City', 'city').and('State / Province / Country', 'state').and('Post / Zip Code', 'postal_code').and('Country', 'country');
                        //Date format
                    } else if (ctrl.field.type == Gecko.Field.TYPE_DATE) {
                        ctrl.fields[int_index] = createIntegrationFields('Field', integration.type === 'salesforce' ? 'Y-m-d' : 'd/m/Y');
                        //Plain format
                    } else {
                        ctrl.fields[int_index] = createIntegrationFields('Field');
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFormFieldIntegrations', geckoFormFieldIntegrations);
})();