(function () {
    'use strict';

    function EventsLocationsCtrl($state, locations) {
        var ctrl = this;
        ctrl.locations = locations.toArray();
        ctrl.pagination = locations.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Title',
            key: 'title',
            sortKey: 'title',
            colClass: 'col-xs-6'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'events.location',
            params: { location_id: 'id' }
        };

        // Table row option buttons
        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_LOCATIONS_DELETE);
            }
        };
        ctrl.rowOptionsBtn.items = [{
            title: 'Remove location',
            action: function action(location) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this location?', function (value) {
                    if (value) {
                        new Gecko.Location().get(location.id).then(function (location) {
                            location.remove().then(function () {
                                GeckoUI.messenger.success('Location has been deleted');
                                $state.reload();
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Locations',
            active: true
        }];
    }

    function EventsLocationCtrl($state, $stateParams, unsavedAlert, location) {
        var ctrl = this;
        ctrl.location = location;

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                ctrl.location.save().then(function (location) {
                    GeckoUI.messenger.success('Location has been saved');
                    unsavedAlert.clear();
                    if ($stateParams.location_id == 'new') {
                        $state.go('events.location', {
                            location_id: location.id
                        });
                    }
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Location Save Success', {
                        'location id': location.id,
                        'location title': location.name
                    });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Location Save Error', {
                        'location id': ctrl.location.id || 'New',
                        'location name': ctrl.location.name,
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            }
        }, {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.location_id == 'new';
            },
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_LOCATIONS_DELETE)) {
                    return false;
                }
                GeckoUI.dialog.confirm('Are you sure you want to delete this location?', function (value) {
                    if (value) {
                        unsavedAlert.clear();
                        ctrl.location.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Location has been deleted');
                            $state.go('events.locations');
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Locations',
            click: function click() {
                $state.go('events.locations');
            }
        }, {
            label: ctrl.location.title ? ctrl.location.title : 'Add Location',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('EventsLocationsCtrl', EventsLocationsCtrl).controller('EventsLocationCtrl', EventsLocationCtrl);
})();