(function () {
    'use strict';

    var generateContactsController = function generateContactsController($scope, $state, $stateParams, LoadingIndicator, unsavedAlert, ctrl, vars, contactsRfields, contactFields, fieldList, getContactsService) {
        ctrl.filter = ctrl.broadcast.related_filter;
        ctrl.conditions = Gecko.convertInstancesToObjects(ctrl.filter.related_conditions ? ctrl.filter.related_conditions : []);
        ctrl.refreshFilter = null;
        ctrl.logicOperator = ctrl.filter.requirement || 'ALL';
        ctrl.isRefreshingContacts = false;

        // If we have contacts then we don't show the add condition message.
        ctrl.hasRefreshedContacts = !!ctrl.contacts.length;

        ctrl.refreshDisabled = function () {
            return ctrl.isRefreshingContacts || ctrl.hasInvalidConditions();
        };

        ctrl.pageChange = function (newPage) {
            $stateParams.page = newPage.$page;
            ctrl.logicOperator = ctrl.logicOperator || ctrl.filter.requirement || 'ALL';
            ctrl.refreshContacts();
        };

        ctrl.hasInvalidConditions = function () {
            return !getContactsService.checkConditionsValid(ctrl.conditions);
        };

        // Refresh contacts
        ctrl.refreshContacts = function () {
            ctrl.isRefreshingContacts = true;

            // Avoid loading all contacts for no reason
            if (ctrl.hasInvalidConditions()) {
                ctrl.isRefreshingContacts = false;
                return Promise.resolve({
                    data: [],
                    pagination: {}
                });
            }

            var order = $stateParams.order !== undefined ? $stateParams.order : 'field_1';
            var sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC';
            var page = $stateParams.page > 0 ? $stateParams.page : 1;
            var perPage = GeckoUI.getPageLimit($state.$current.name);

            var searchParams = {
                order: order,
                sort: sort,
                page: page,
                perPage: perPage,
                // filterId: null,
                conditions: ctrl.conditions,
                // search: null,
                conditionOperator: ctrl.logicOperator,
                includes: [],
                rFields: contactsRfields,
                number: $stateParams.number,
                // searchLabels: null,
                // labelOperator: null,
                contactFields: contactFields,
                fieldList: fieldList
                // labelList: null
            };
            var forceEngageSource = Gecko.has(Gecko.Package.FEATURE_NEW_SELECT_CONTACTS) === false;
            LoadingIndicator.resolve(getContactsService.getContacts(searchParams, forceEngageSource)).then(function (contacts) {
                ctrl.contacts = contacts.data;
                ctrl.pagination = contacts.pagination;
                ctrl.hasRefreshedContacts = true;
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            }).finally(function () {
                ctrl.isRefreshingContacts = false;
            });
        };

        // Save campaign
        ctrl.broadcastSave = function () {
            // Dont allow save if broadcast is COMPLETE
            if (ctrl.broadcast.status === Gecko.Campaign.COMPLETE) {
                return GeckoUI.messenger.error('This broadcast is now complete and can\'t be changed.');
            }

            ctrl.filter.requirement = ctrl.logicOperator || 'ALL';
            ctrl.filter.conditions = ctrl.conditions;

            ctrl.filter.include('conditions').save().then(function () {
                unsavedAlert.clear();
                GeckoUI.messenger.success('Contacts have been added to your broadcast.');

                // Navigate to workflows (if in wizard mode)
                if ($stateParams.wizard) $state.go('broadcast.workflows', $stateParams);
            }).catch(function (error) {
                GeckoUI.messenger.error(error);
            });
        };

        // Table structure
        ctrl.cols = ctrl.fieldList.map(function (col, index) {
            return {
                type: col.type,
                data_type: col.data_type,
                default_value: col.label.indexOf('Last') > -1 && col.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP ? 'Never' : '',
                title: col.label,
                key: 'field_' + (index + 1),
                sortKey: 'field_' + (index + 1),
                colClass: 'col-md-3',
                utc: !col.system && col.type == Gecko.Field.TYPE_DATE,
                date_format: !col.system && col.type == Gecko.Field.TYPE_DATE ? Gecko.dateFormat.short : undefined
            };
        });

        // Save Campaign
        $scope.$on('saveBroadcast', function () {
            return ctrl.broadcastSave();
        });
    };

    function BroadcastFiltersCtrl($scope, $state, $stateParams, $filter, $timeout, campaign, contacts, fieldList, unsavedAlert, LoadingIndicator, contactsRfields, contactFields, getContactsService) {
        var ctrl = this;

        ctrl.broadcast = campaign;
        ctrl.contacts = contacts.data;
        ctrl.pagination = contacts.pagination;
        ctrl.fieldList = fieldList;

        generateContactsController($scope, $state, $stateParams, LoadingIndicator, unsavedAlert, ctrl, {}, contactsRfields, contactFields, fieldList, getContactsService);
    }

    angular.module('GeckoEngage').controller('BroadcastFiltersCtrl', BroadcastFiltersCtrl);
})();