(function () {
    'use strict';

    function modifiers($parse) {

        return {
            restict: 'A',
            require: 'ngModel',

            link: function link(scope, iElement, iAttrs, ngModel) {
                var modifierFunctions = $parse(iAttrs.modifiers)(scope);

                if (modifierFunctions) {
                    if (modifierFunctions.parser && typeof modifierFunctions.parser === 'function') {
                        ngModel.$parsers.push(modifierFunctions.parser);
                    }

                    if (modifierFunctions.formatter && typeof modifierFunctions.formatter === 'function') {
                        ngModel.$formatters.push(modifierFunctions.formatter);
                    }
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('modifiers', modifiers);
})();