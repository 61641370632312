(function () {
    'use strict';

    function VoipNumbersRoute($stateProvider) {
        $stateProvider.state('voipnumbers', {
            url: "/settings/voip_numbers?{page}",
            templateUrl: "/modules/voip_numbers/views/index.87f1caf8.html",
            controller: 'SettingsVoipNumbersCtrl',
            controllerAs: 'ctrl',
            page_title: 'VoIP Numbers',
            requires: [Gecko.ABILITY_SENDERS_VOIP],
            redirectKey: 'module_voip_numbers',
            resolve: {
                senders: function senders($stateParams) {
                    return new Gecko.Sender().where('type', [Gecko.Sender.TEL_VERIFIED, Gecko.Sender.TEL_PURCHASED, Gecko.Sender.GECKOCHAT]).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ sender: ['created_at', 'name', 'tel', 'type', 'verified', 'outgoing_voice', 'incoming_voice', 'outgoing_sms', 'incoming_sms'] }).get();
                }
            }
        }).state('voipnumber', {
            url: "/settings/voip_numbers/{sender_id}",
            templateUrl: "/modules/voip_numbers/views/edit.c895a0d8.html",
            controller: 'SettingsVerifyVoipNumberCtrl',
            controllerAs: 'ctrl',
            page_title: 'Verify VoIP Number',
            redirectKey: 'module_voip_number_verify',
            requires: [Gecko.ABILITY_SENDERS_VOIP],
            resolve: {
                campaigns: function campaigns() {
                    // Only query campaign if inbound call is enabled
                    if (!Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL)) return [];
                    return new Gecko.Campaign().where('module', Gecko.Campaign.MODULE_CALL).get().then(function (campaigns) {
                        return campaigns.toInstances();
                    });
                },
                sender: function sender($stateParams) {
                    if ($stateParams.sender_id != 'new') {
                        return new Gecko.Sender().include('campaigns', 'users:all', 'inbound_campaigns', 'outbound_campaigns').rfields({ user: ['full_name'] }).get($stateParams.sender_id);
                    } else {
                        return new Gecko.Sender();
                    }
                }
            }
        }).state('voipnumberpurchase', {
            url: "/settings/voip_number_purchase",
            templateUrl: "/modules/voip_numbers/views/purchase.c8532d75.html",
            controller: 'SettingsPurchaseVoipNumberCtrl',
            controllerAs: 'ctrl',
            page_title: 'Purchase VoIP Number',
            redirectKey: 'module_voip_number_purchase',
            requires: [Gecko.ABILITY_SENDERS_VOIP],
            resolve: {
                sender: function sender() {
                    return new Gecko.Sender();
                },

                _supportedCountries: function _supportedCountries() {
                    return new Gecko.Sender().supportedCountries();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(VoipNumbersRoute);
})();