(function () {
    'use strict';

    function VisitAppTestCtrl($stateParams, $scope, geckoEventPickerService, OptionsPresets) {
        var ctrl = this;
        ctrl.event_id = null;
        ctrl.speed = 1500;

        var initFn = function initFn() {
            ctrl.selectedEvent = null;
            ctrl.attendances = [];
            ctrl.total;
            ctrl.current = 0;
            ctrl.attendance = null;
            ctrl.inProgress = false;
        };

        initFn();

        ctrl.fields = [geckoEventPickerService.prepareField({
            id: 'event_id',
            label: 'Pick an Event / Session to Test',
            type: Gecko.Field.TYPE_TIERED_SELECT,
            colClass: 'none',
            optionsInit: OptionsPresets.events.init,
            options: [[], [], []],
            getOptions: OptionsPresets.events.get,
            placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
            orderBy: 'title',
            optionsKey: 'id',
            optionsLabelKey: 'title',
            disabledWhen: function disabledWhen() {
                return ctrl.inProgress;
            }
        }), {
            id: 'speed',
            label: 'Speed',
            colClass: 'none',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            disabledWhen: function disabledWhen() {
                return ctrl.inProgress;
            },
            optionsKey: 'value',
            optionsLabelKey: 'label',
            options: [{
                value: 1500,
                label: '1.5 seconds'
            }, {
                value: 5000,
                label: '5 seconds'
            }, {
                value: 10000,
                label: '10 seconds'
            }, {
                value: 20000,
                label: '20 seconds'
            }, {
                value: 30000,
                label: '30 seconds'
            }, {
                value: 60000,
                label: '1 minute'
            }]
        }, {
            type: Gecko.Field.TYPE_BUTTON,
            colClass: 'none',
            btnLabel: 'Start Testing',
            icon: 'fa-play',
            btnClass: 'btn-primary',
            action: function action() {
                return ctrl.start();
            },
            hideWhen: function hideWhen() {
                return ctrl.inProgress;
            }
        }, {
            type: Gecko.Field.TYPE_BUTTON,
            colClass: 'none',
            btnLabel: 'Stop Testing',
            icon: 'fa-stop',
            btnClass: 'btn-danger',
            action: function action() {
                return ctrl.stop();
            },
            hideWhen: function hideWhen() {
                return !ctrl.inProgress;
            }
        }];

        // Reset when ctrl.event_id changes
        $scope.$watch('ctrl.event_id', function (newVal, oldVal) {
            if (newVal && oldVal && newVal !== oldVal) initFn();

            // Get selected event ref
            if (newVal !== oldVal) {
                new Gecko.Event().rfields({ event: ['title', 'type'] }).get(newVal).then(function (event) {
                    ctrl.selectedEvent = event;
                    $scope.$digest();
                });
            }
        }, true);

        var shuffle = function shuffle(array) {
            var currentIndex = array.length,
                randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                var _ref = [array[randomIndex], array[currentIndex]];
                array[currentIndex] = _ref[0];
                array[randomIndex] = _ref[1];
            }

            return array;
        };

        var getAttendances = function getAttendances() {

            return new Gecko.Attendance().include('contact').rfields({ 'attendance': ['contact_id', 'guest_count'], 'contact': ['qr_url', 'full_name', 'email'] }).where('event_id', ctrl.event_id).perPage(10000).get().then(function (data) {
                return {
                    attendances: data.toArray().map(function (a) {
                        return {
                            id: a.id,
                            contact_id: a.contact_id,
                            name: a.contact.full_name,
                            email: a.contact.email,
                            qr_url: a.contact.qr_url,
                            guest_count: a.guest_count
                        };
                    }),
                    total: data.pagination.total_items
                };
            }).then(function (data) {
                ctrl.attendances = shuffle(data.attendances);
                ctrl.total = data.total;
            });
        };

        var load = function load(attendance) {
            ctrl.attendance = attendance;
        };

        var interval = false;

        var intervalFn = function intervalFn() {
            // Reset count at the end of the attendances array
            if (!ctrl.attendances[ctrl.current]) {
                ctrl.current = 0;
            }

            load(ctrl.attendances[ctrl.current]);
            ctrl.current++;
        };

        ctrl.stop = function () {
            if (interval) {
                clearInterval(interval);
                interval = false;
            }
            ctrl.inProgress = false;
        };

        ctrl.start = function () {
            // Don't fetch when there is NO event_id
            if (!ctrl.event_id) return GeckoUI.messenger.error('Please pick an event/session to test');

            // Don't fetch when TYPE_SESSION
            if (ctrl.selectedEvent.type === Gecko.Event.TYPE_SESSION) {
                return GeckoUI.messenger.error('Please pick a top level event or session time to test');
            }

            ctrl.inProgress = true;

            if (!ctrl.attendances.length) {
                var attendancesQuery = getAttendances();
            } else {
                var attendancesQuery = Promise.resolve();
            }

            attendancesQuery.then(function () {
                //Load first attendance immediately
                intervalFn();

                interval = setInterval(intervalFn, ctrl.speed);
            });
        };
    }

    angular.module('GeckoEngage').controller('VisitAppTestCtrl', VisitAppTestCtrl);
})();