(function () {

    function LabelModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('labelModal', function (_target, _model) {
            return {
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal\n                            data-title="{{ctrl.modalTitle}}"\n                            footer-btns="ctrl.footerBtns"\n                            fields="ctrl.fields"\n                            values="ctrl">\n                        </gecko-modal>\n                    ',
                controller: 'LabelModalCtrl',
                resolve: {
                    target: function target() {
                        return _target;
                    },
                    model: function model() {
                        return _model || 'Contact';
                    },
                    labels: function labels(geckoDataService) {
                        return geckoDataService.fetch(['labels']).then(function (data) {
                            return data.labels;
                        });
                    }
                }
            };
        });
    }

    var label_id = -1;

    function LabelModalCtrl($scope, $modalInstance, target, model, labels, geckoDataService) {
        var ctrl = this;
        ctrl.target = target;
        ctrl.saving = false;
        var isMassAction = Array.isArray(target);

        if (ctrl.target.related_labels) {
            ctrl.labels = ctrl.target.related_labels.map(function (v) {
                return v.toObject();
            });
        } else {
            ctrl.labels = [];
        }

        ctrl.modalTitle = isMassAction ? 'Add ' + model + ' Labels' : 'Manage ' + model + ' Labels';
        var helpText = !isMassAction ? 'Labels removed from this ' + (model || '').toLowerCase() + ' will still be available for use elsewhere in Gecko.' : '';

        ctrl.fields = [{
            id: 'labels',
            type: Gecko.Field.TYPE_TAGGER,
            newItem: function newItem(string) {
                return { name: string, color: tinycolor.random().toHexString(), id: label_id-- };
            },
            label: 'Labels',
            description: 'Search existing or add new labels. ' + helpText,
            placeholder: 'Search and Select',
            matchTemplate: 'm-label-match',
            options: labels,
            getOptions: function getOptions() {},
            optionsKey: 'id',
            optionsLabelKey: 'name',
            colClass: 'col-md-12',
            xClass: function xClass(item) {
                if (item && item.color) return GeckoUI.getContrastColor(item.color);
                return 'white';
            }
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Save labels',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            disabledWhen: function disabledWhen() {
                return ctrl.saving;
            },
            action: function action() {

                ctrl.saving = true;

                var labels = ctrl.labels.map(function (v) {
                    v = angular.copy(v);
                    if (v.isTag) {
                        // Clean up the crap that ui-select needs to show new items in the tagger.
                        delete v.isTag;
                        delete v.id;
                    }
                    return v;
                });

                // Add to single contact/response
                if (!isMassAction) {

                    ctrl.target.labels = labels;

                    ctrl.target.include(ctrl.target.includes).rfields({ label: ['name', 'color'] }).replaceLabels().then(function (target) {
                        ctrl.target = target;
                        // Close modal
                        $modalInstance.close(target);
                        // Refresh stored labels
                        geckoDataService.fetch(['labels'], true);
                        $scope.$digest();
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                        $modalInstance.dismiss();
                    }).finally(function () {
                        ctrl.saving = false;
                    });
                    // Add to multiple contacts/responses
                } else {
                    var target = new Gecko[model]();
                    var massActionReq;
                    if (model === 'Contact') {
                        massActionReq = target.massAction({ model: 'contact_ids', contact_ids: ctrl.target }, Gecko.Workflow.Actions.assignContactLabel(labels));
                    } else if (model === 'Response') {
                        massActionReq = target.massAction({ model: 'response_ids', response_ids: ctrl.target }, Gecko.Workflow.Actions.assignLabel(labels));
                    }
                    massActionReq.then($modalInstance.close).then(function () {
                        // Refresh stored labels
                        geckoDataService.fetch(['labels'], true);
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                        // Close modal
                        $modalInstance.close();
                    }).finally(function () {
                        ctrl.saving = false;
                    });
                }
            }
        }];
    }

    angular.module('GeckoEngage').config(LabelModals).controller('LabelModalCtrl', LabelModalCtrl);
})();