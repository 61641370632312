(function () {
    'use strict';

    function EventSessionsCtrl($rootScope, $scope, event, sessions, locations, hosts, $geckoModal, $filter, $stateParams, $state, geckoDates, geckoStore) {
        var ctrl = this;
        ctrl.event = event;
        ctrl.sessions = sessions.toInstances();
        ctrl.locations = locations;
        ctrl.hosts = hosts;
        ctrl.pagination = sessions.pagination;

        //
        ctrl.isFiltered = $stateParams.search;
        ctrl.getPrettyDate = geckoDates.printStartTimeEndTime;

        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Edit session',
            action: function action(session) {
                // Open edit modal
                $geckoModal.eventSessionModal(ctrl.event, session, { locations: ctrl.locations, hosts: ctrl.hosts });
                // Close session times if open
                session.feedExpanded = false;
                // Remove times for session
                session.times = null;
            }
        }, {
            title: 'Clone session',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_CREATE);
            },
            action: function action(session) {
                return angular.copy(session).clone().then(function (session) {
                    $state.go($state.$current.name, $stateParams, { reload: true });
                    GeckoUI.messenger.success(session.title + ' was cloned successfully');
                }).catch(function (err) {
                    return GeckoUI.messenger.error(err);
                });
            }
        }, {
            title: 'Remove session',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_DELETE);
            },
            action: function action(session, index) {
                GeckoUI.dialog.confirm('Are you sure you want to remove <b>' + session.title + '</b>?', function (value) {
                    if (value) {
                        session.remove().then(function () {
                            GeckoUI.messenger.success('Session has been deleted.');
                            ctrl.sessions.splice(index, 1);
                        }).catch(function (err) {
                            return GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        }];

        function timesQuery(session, page) {
            if ($stateParams.exclude_guests) {
                var rfields = { event: ['title', 'start_datetime', 'end_datetime', 'total_single_attendances', 'total_attendances'] };
            } else {
                var rfields = { event: ['title', 'start_datetime', 'end_datetime', 'total_attendances'] };
            }
            return new Gecko.Event().rfields(rfields).where('parent_id', session.id).page(page || 1).perPage('all');
        }

        ctrl.headerBtns = [{
            icon: $stateParams.exclude_guests ? 'fa-users' : 'fa-user-slash',
            title: $stateParams.exclude_guests ? 'Include Guests' : 'Exclude Guests',
            //hideWhen: () => !ctrl.event.maximum_guests,
            action: function action() {
                if ($stateParams.exclude_guests) {
                    var params = Object.assign({}, $stateParams, { exclude_guests: null });
                } else {
                    var params = Object.assign({}, $stateParams, { exclude_guests: 1 });
                }
                return $state.go($state.$current.name, params, { reload: true });
            }
        }];

        // Open/close panel
        ctrl.open = function (session) {
            session.feedExpanded = !session.feedExpanded;
            // Close other panels
            if (session.feedExpanded) {
                ctrl.sessions.map(function (s) {
                    if (s.id !== session.id) s.feedExpanded = false;
                    return s;
                });
            }

            // Get Session times
            if (!session.times) {
                timesQuery(session).get().then(function (times) {
                    session.times = times.toArray();
                    $scope.$digest();
                });
            }
        };

        // Add new session
        $scope.$on('addSession', function (event) {
            return $geckoModal.eventSessionModal(ctrl.event, null, { locations: ctrl.locations, hosts: ctrl.hosts });
        });
    }

    angular.module('GeckoEngage').controller('EventSessionsCtrl', EventSessionsCtrl);
})();