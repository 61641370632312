(function () {
    'use strict';

    function OutcomesRoute($stateProvider) {
        $stateProvider.state('settingsoutcomes', {
            url: "/settings/outcomes?{page}&{search}",
            templateUrl: "/modules/outcomes/views/index.4ba6a9cd.html",
            controller: 'SettingsOutcomesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Outcomes',
            requires: [Gecko.ABILITY_OUTCOMES_VIEW],
            redirectKey: 'module_outcomes',
            resolve: {
                outcomes: function outcomes($stateParams) {
                    var outcomes = new Gecko.Outcome();
                    if ($stateParams.search) outcomes.where('keyword', $stateParams.search);
                    return outcomes.where('group', [Gecko.Outcome.GROUP_GLOBAL, Gecko.Outcome.GROUP_SYSTEM]).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).where('call_type', [Gecko.Outcome.CALL_TYPE_BOTH, Gecko.Outcome.CALL_TYPE_OUTBOUND]) // Hide until inbound released.
                    .rfields({ outcome: ['name', 'action', 'type'] }).get();
                }
            }
        }).state('settingsoutcome', {
            url: "/settings/outcomes/{outcome_id}",
            templateUrl: "/modules/outcomes/views/edit.80edee6a.html",
            controller: 'SettingsOutcomeCtrl',
            controllerAs: 'ctrl',
            page_title: 'Outcome',
            requires: [Gecko.ABILITY_OUTCOMES_VIEW],
            redirectKey: 'module_outcome',
            resolve: {
                outcome: function outcome($stateParams) {
                    if ($stateParams.outcome_id != 'new') {
                        return new Gecko.Outcome().get($stateParams.outcome_id);
                    } else {
                        return new Gecko.Outcome();
                    }
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(OutcomesRoute);
})();