(function () {
    'use strict';

    function ContactNotesCtrl($scope, $state, $geckoModal, contact, notes, $geckoChannel, $stateParams) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.notes = notes.toInstances();
        ctrl.pagination = notes.pagination;

        // Register channel
        $geckoChannel.register($state.$current.name, ['notes'], ctrl);
        $geckoChannel.set('notes', ctrl.notes);

        if ($stateParams.includeCalls === undefined) {
            $stateParams.includeCalls = 1;
        }

        if ($stateParams.includeResponses === undefined) {
            $stateParams.includeResponses = 1;
        }

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            colClass: 'col-xs-2'
        }, {
            title: 'Note',
            key: 'note',
            colClass: 'col-xs-6'
        }, {
            title: 'Type',
            colClass: '2',
            key: 'id',
            render: function render(value, col, row) {
                if (row.contact_id) {
                    return 'Contact';
                } else if (row.call_id) {
                    return 'Call';
                } else if (row.response_id) {
                    return 'Response';
                }
            }
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Created by',
            key: 'related_user.full_name',
            colClass: 'col-xs-2'
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(note) {
                if (note.user_id != Gecko.user.id) return false;
                $geckoModal.noteAddModal(ctrl.contact, note).result.then(function () {
                    $state.go('contact.notes', { contact_id: ctrl.contact.id }, { reload: true });
                    GeckoUI.messenger.success('Note updated.');
                });
            }
        };

        // Add response
        ctrl.headerBtns = [{
            title: 'Add note',
            icon: 'fa-plus',
            action: function action() {
                $geckoModal.noteAddModal(ctrl.contact).result.then(function (note) {
                    ctrl.notes.unshift(note);
                    $state.go('contact.notes', { contact_id: ctrl.contact.id }, { reload: true });
                    GeckoUI.messenger.success('Note added');
                });
            }
        }];

        ctrl.tableFilter = [{
            type: 'checkbox',
            title: 'Exclude Notes from:',
            options: [{
                label: 'Responses',
                value: 'responses'
            }, {
                label: 'Calls',
                value: 'calls'
            }],
            stateParam: 'exclude'
        }];
    }

    angular.module('GeckoEngage').controller('ContactNotesCtrl', ContactNotesCtrl);
})();