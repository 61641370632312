(function () {

    function CallModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('CallOutcomeModal', function (_call, _contact, _subscriber) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/call/call_outcome_modal.html',
                controller: 'CallOutcomeModalCtrl',
                resolve: {
                    call: function call() {
                        if (_call) {
                            return new Gecko.Call().get(_call.id);
                        }
                        return new Gecko.Call();
                    },
                    contact: function contact() {
                        return _contact;
                    },
                    subscriber: function subscriber() {
                        return _subscriber;
                    },
                    selectedData: function selectedData(asyncOptions) {
                        if (_subscriber) {
                            var campaign_id = _subscriber.campaign_id;
                        } else if (_call.related_campaign) {
                            var campaign_id = _call.related_campaign.id;
                        } else {
                            var campaign_id = _call.campaign_id;
                        }

                        return asyncOptions.getSelectedValues({
                            users: { model: 'User', id: _call.user_id, fields: { user: ['full_name'] } },
                            outcomes: { model: 'Outcome', id: _call.outcome_id, fields: { outcome: ['name'] } },
                            campaigns: { model: 'Campaign', id: campaign_id, fields: { campaign: ['title'] } }
                        });
                    }
                }
            };
        });
    }

    function CallOutcomeModalCtrl($scope, $state, $stateParams, $modalInstance, $filter, asyncOptions, call, contact, subscriber, selectedData) {
        var ctrl = this;
        ctrl.call = call;
        ctrl.contact = contact;
        ctrl.subscriber = subscriber;

        ctrl.currentOutcome = { action: null };
        new Gecko.Outcome().rfields({ outcome: ['name', 'action', 'require_note'] }).get(ctrl.call.outcome_id).then(function (outcome) {
            ctrl.currentOutcome = outcome;
        });

        if (ctrl.contact) ctrl.call.contact_id = ctrl.contact.id;
        if (ctrl.subscriber) {
            ctrl.call.subscriber_id = ctrl.subscriber.id;
            ctrl.call.campaign_id = ctrl.subscriber.campaign_id;
        }

        // Set type to outbound as default or beacuse is subscriber
        if (!ctrl.call.type || ctrl.subscriber) ctrl.call.type = Gecko.Call.TYPE_OUTBOUND;

        // If call is new, mark as complete & mark call with current user
        if (!ctrl.call.id) {
            ctrl.call.status = Gecko.Call.COMPLETE;
            ctrl.call.user_id = Gecko.user.id;
            ctrl.users = [{
                id: Gecko.user.id,
                full_name: Gecko.user.full_name
            }];
        } else {
            ctrl.users = asyncOptions.pick(selectedData, 'users');
        }

        ctrl.getOutcomes = function () {
            return new Gecko.Campaign().include('outcomes').get(ctrl.call.campaign_id).then(function (campaign) {
                var outcomeField = ctrl.fields.find(function (field) {
                    return field.key === 'outcome_id' && !field.getOptions;
                });
                outcomeField.options = campaign.related_outcomes;
            }).catch(function () {});
        };

        if (ctrl.call.campaign_id) ctrl.getOutcomes();

        // Set call_at date
        if (ctrl.subscriber && ctrl.subscriber.call_at) ctrl.call.call_at = ctrl.subscriber.call_at;

        // Set start date/time
        if (!ctrl.call.started_at) {
            ctrl.call.started_at = Math.floor(new Date(Number(new Date())).getTime() / 1000);
            ctrl.call.ended_at = ctrl.call.started_at + 60 * 5;
        }

        // Modal fields
        ctrl.fields = [{
            label: 'User',
            key: 'user_id',
            options: ctrl.users,
            getOptions: asyncOptions.create(new Gecko.User().orderBy('full_name').rfields({ user: ['full_name'] })),
            optionsKey: 'id',
            optionsLabelKey: 'full_name',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'none'
        }, {
            label: 'Campaign',
            key: 'campaign_id',
            options: asyncOptions.pick(selectedData, 'campaigns'),
            getOptions: asyncOptions.create(new Gecko.Campaign().where('module', Gecko.Campaign.MODULE_CALL).orderBy('title').rfields({ campaign: ['title'] })),
            optionsKey: 'id',
            optionsLabelKey: 'title',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'none',
            disabledWhen: function disabledWhen() {
                return ctrl.call.id || ctrl.subscriber;
            },
            onChange: function onChange() {
                if (ctrl.call.campaign_id) return ctrl.getOutcomes();
                return;
            }
        }, {
            label: 'Call type',
            key: 'type',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'none',
            noBlank: true,
            optionsLabelKey: 'title',
            options: [{
                id: Gecko.Call.TYPE_OUTBOUND,
                title: 'Outbound'
            }, {
                id: Gecko.Call.TYPE_INBOUND,
                title: 'Inbound'
            }],
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL) || ctrl.call.id || ctrl.subscriber;
            }
        }, {
            label: 'Start date',
            key: 'started_at',
            colClass: 'none',
            required: true,
            type: Gecko.Field.TYPE_DATETIME,
            interval: 1
        }, {
            label: 'End date',
            key: 'ended_at',
            colClass: 'none',
            required: true,
            type: Gecko.Field.TYPE_DATETIME,
            interval: 1
        }, {
            label: 'Outcome',
            key: 'outcome_id',
            required: true,
            options: asyncOptions.pick(selectedData, 'outcomes'),
            getOptions: asyncOptions.create(new Gecko.Outcome().rfields({ outcome: ['name', 'action', 'require_note'] }).orderBy('name').where('group', [Gecko.Outcome.GROUP_GLOBAL, Gecko.Outcome.GROUP_SYSTEM]), function () {
                if (ctrl.subscriber && !ctrl.subscriber.can_call && ctrl.subscriber.status == Gecko.Subscriber.DO_NOT_CALL) {
                    this.options = $filter('filter')(this.options, { 'action': '!' + Gecko.Outcome.ACTION_RESCHEDULE });
                }
                this.options = $filter('orderBy')(this.options, 'name');
            }),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'none',
            onChange: function onChange() {
                ctrl.currentOutcome = GeckoUI.gobk(this.options, 'id', ctrl.call.outcome_id);
            },
            hideWhen: function hideWhen() {
                return ctrl.call.campaign_id;
            }
        }, {
            label: 'Outcome',
            key: 'outcome_id',
            required: true,
            options: ctrl.outcomes,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'none',
            onChange: function onChange() {
                ctrl.currentOutcome = GeckoUI.gobk(this.options, 'id', ctrl.call.outcome_id);
            },
            hideWhen: function hideWhen() {
                return !ctrl.call.campaign_id;
            }
        }, {
            label: 'Note',
            key: 'note',
            type: Gecko.Field.TYPE_TEXTAREA,
            colClass: 'none',
            hideWhen: function hideWhen() {
                return !ctrl.currentOutcome.require_note;
            }
        }, {
            label: 'Reschedule for',
            key: 'call_at',
            type: Gecko.Field.TYPE_DATETIME,
            colClass: 'none',
            hideWhen: function hideWhen() {
                return ctrl.currentOutcome.action != Gecko.Outcome.ACTION_RESCHEDULE;
            }
        }, {
            label: 'Remove call consent',
            key: 'do_not_call',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'none',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || ctrl.currentOutcome.action == Gecko.Outcome.ACTION_RESCHEDULE;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.currentOutcome.action == Gecko.Call.ACTION_DO_NOT_CALL;
            },
            checkedWhen: function checkedWhen() {
                return ctrl.currentOutcome.action == Gecko.Call.ACTION_DO_NOT_CALL || ctrl.call.action == Gecko.Call.ACTION_DO_NOT_CALL;
            }
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {

                // Check action
                if (ctrl.call.do_not_call) {
                    ctrl.call.action = Gecko.Call.ACTION_DO_NOT_CALL;
                }

                if (!ctrl.call.do_not_sms && !ctrl.call.do_not_call) {
                    ctrl.call.action = ctrl.currentOutcome.action;
                }

                // Save call
                ctrl.call.include('outcome', 'user', 'contact', 'number', 'campaign').save().then(function (call) {
                    if ($state.$current.name == 'contact.subscriber') {
                        $modalInstance.dismiss();
                        $state.go($state.$current.name, $stateParams, { reload: true });
                    } else {
                        $modalInstance.close(call);
                    }
                    // Success message
                    GeckoUI.messenger.success('Call updated');
                }).catch(function (err) {
                    ctrl.alertMessage = GeckoUI.renderError(err.errors);
                    $scope.$digest();
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(CallModals).controller('CallOutcomeModalCtrl', CallOutcomeModalCtrl);
})();