(function () {
    'use strict';

    function DataSecurityCtrl($scope, $rootScope, $state, $geckoModal) {
        var ctrl = this;

        // Tabs
        ctrl.tabs = [{
            title: 'Preferences',
            state: 'datasecurity.preferences',
            hideWhen: function hideWhen() {
                return !(Gecko.able(Gecko.ABILITY_AUTO_ARCHIVE) || Gecko.able(Gecko.ABILITY_CONSENTS_VIEW));
            }
        }, {
            title: 'Activity Log',
            state: 'datasecurity.activity'
        }, {
            title: 'Deleted Contacts',
            state: 'datasecurity.deletedcontacts'
        }, {
            title: 'Consent Reasons',
            state: 'datasecurity.consentreasons',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW);
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                return $state.go('settings');
            }
        }, {
            label: 'Data Security Preferences',
            click: function click() {
                return $state.go('datasecurity.preferences');
            }
        }, {
            label: $state.$current.page_title,
            active: true
        }];
        // Update breadcrumb label during nav (remove when scope is destroyed)
        var unregisterFn = $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            ctrl.breadcrumbs[ctrl.breadcrumbs.length - 1].label = toState.page_title;
        });
        $scope.$on('$destroy', unregisterFn);

        ctrl.addReasonBtn = {
            title: 'New Reason',
            btnClass: 'btn-primary',
            icon: 'fa-plus',
            hideWhen: function hideWhen() {
                return $state.$current.name !== 'datasecurity.consentreasons';
            },
            action: function action() {
                return $geckoModal.consentReasonModal().result.then(function (reason) {
                    return $state.go($state.$current.name, {}, { reload: true });
                });
            }
        };
    }

    angular.module('GeckoEngage').controller('DataSecurityCtrl', DataSecurityCtrl);
})();