(function () {
    'use strict';

    function LandingPagesCtrl($scope, $state, landing_pages) {
        var ctrl = this;
        ctrl.landing_pages = landing_pages.toInstances();
        ctrl.pagination = landing_pages.pagination;

        // Table structure
        ctrl.cols = [{
            key: 'published',
            status_styles: [{
                id: 0,
                title: 'Unpublished',
                label: 'label-warning'
            }, {
                id: 1,
                title: 'Published',
                label: 'label-success'
            }],
            status_title_none: true,
            expandable: true
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Created at',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-4'
        }, {
            title: 'Title',
            key: 'title',
            sortKey: 'title',
            colClass: 'col-xs-4'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Created by',
            key: 'related_user.full_name',
            colClass: 'col-xs-4'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'landingpage',
            params: { landing_page_id: 'id' }
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View',
            action: function action(landing_page) {
                // Open landing page
                window.open(landing_page.url, '_blank');
            },
            hideWhen: function hideWhen(landing_page) {
                // Hide when not published
                return !landing_page.published;
            }
        }, {
            title: 'Clone',
            action: function action(landing_page, index) {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_LANDING_PAGES_CREATE)) return;
                GeckoUI.dialog.confirm('Are you sure you want to clone this landing page?', function (value) {
                    if (value) {
                        angular.copy(landing_page).clone().then(function (clone) {
                            // Add clone to list
                            ctrl.landing_pages.unshift(clone);
                            // Show success alert
                            GeckoUI.messenger.success('Landing page cloned');
                            $scope.$apply();
                        }).catch(function (error) {
                            GeckoUI.messenger.error(error.message);
                        });
                    }
                });
            }
        }, {
            title: 'Remove',
            action: function action(landing_page, index) {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_LANDING_PAGES_DELETE)) return;
                GeckoUI.dialog.confirm('Are you sure you want to remove this landing page?', function (value) {
                    if (value) {
                        landing_page.remove().then(function () {
                            // Remove from table
                            ctrl.landing_pages.splice(index, 1);
                            // Show success alert
                            GeckoUI.messenger.success('Landing page removed');
                            $scope.$apply();
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Landing Pages',
            click: function click() {
                $state.go('landingpages');
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    function LandingPageAddCtrl($scope, $filter, $state, landing_page) {
        var ctrl = this;
        ctrl.landing_page = landing_page;

        // Settings Card Fields
        ctrl.fields = [{
            id: '_title',
            label: 'Title',
            required: true,
            colClass: 'col-xs-12',
            description: 'This will be used to identify your landing page in Gecko.'
        }, {
            id: '_slug',
            label: 'URL Path',
            required: true,
            colClass: 'col-xs-8',
            description: 'The public URL identifier for your landing page. By default this will be the title, but can be edited at will.'
        }, {
            id: 'published',
            label: 'Published',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'col-xs-4'
        }];

        // Auto populate slug
        var slugUpdate = true;
        Object.defineProperty(ctrl.landing_page, '_title', {
            get: function get() {
                return ctrl.landing_page.title;
            },
            set: function set(value) {
                ctrl.landing_page.title = value;
                // Only update slug if slug field is unedited
                if (slugUpdate) ctrl.landing_page._slug = value;
            }
        });

        Object.defineProperty(ctrl.landing_page, '_slug', {
            get: function get() {
                return ctrl.landing_page.slug;
            },
            set: function set(value) {
                // Turn off auto generating slug if being edited
                if (ctrl.landing_page.title != value) {
                    slugUpdate = false;
                }
                ctrl.landing_page.slug = value.replace(/\s/g, '-').replace(/[^a-zA-Z0-9_-]/g, '').toLowerCase();
            }
        });

        // Settings Card buttons
        ctrl.footerBtns = [{
            preset: 'next',
            position: 'primary',
            action: function action() {
                ctrl.landing_page.save().then(function (landing_page) {
                    // Success message
                    GeckoUI.messenger.success('Landing page saved');
                    // Redirect to landing page builder
                    $state.go('landingpage', { landing_page_id: landing_page.id });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Landing Page Save Error', {
                        'landing page id': ctrl.landing_page.id || 'New',
                        'landing page name': ctrl.landing_page.title,
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Landing Pages',
            click: function click() {
                $state.go('landingpages');
            }
        }, {
            label: 'Add Landing Page',
            active: true
        }];
    }

    function LandingPageCtrl($scope, $state, $stateParams, $landingPageBuilder, LoadingIndicator, landing_page, blocks, gecko_chat_channels) {
        var ctrl = this;
        ctrl.landing_page = landing_page;
        ctrl.blocks = blocks.toInstances();
        ctrl.gecko_chat_channels = gecko_chat_channels;

        // Set default data object
        if (!ctrl.landing_page.config) ctrl.landing_page.config = {};
        // Set default styles object
        if (!ctrl.landing_page.styles) ctrl.landing_page.styles = {};

        // Open edit menu (and copy landing page to edit)
        ctrl.openMenu = function () {
            // Copy landing page
            ctrl._landing_page = angular.copy(ctrl.landing_page);
            // Render menu cards and fields
            renderMenuCards();
            // Open side menu
            ctrl.settingMenuOpen = true;
        };

        // Save landing page
        var saveLandingPage = function saveLandingPage() {
            // Show loading indicator
            LoadingIndicator.show('loading-indicator-landing-page-side-menu');
            // Save landing page
            ctrl._landing_page.save().then(function (landing_page) {
                GeckoUI.messenger.success('Landing page saved');
                // Update landing page
                ctrl.landing_page = landing_page;
                // Refresh menu data
                ctrl.openMenu();
                // Hide loading indicator
                LoadingIndicator.hide('loading-indicator-landing-page-side-menu');

                $scope.$digest();

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Landing Page Save Success', {
                    'landing page id': landing_page.id,
                    'landing page title': landing_page.title
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                // Hide loading indicator
                LoadingIndicator.hide('loading-indicator-landing-page-side-menu');

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Landing Page Save Error', {
                    'landing page id': ctrl._landing_page.id || 'New',
                    'landing page title': ctrl._landing_page.title,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        // Listen for CMD + S event
        $scope.$on('quickSave:' + $state.$current.name, function () {
            return saveLandingPage();
        });

        var renderMenuCards = function renderMenuCards() {

            // Settings Card Fields
            ctrl.settingsCardFields = [{
                id: 'title',
                label: 'Title',
                colClass: 'col-xs-12',
                required: true
            }, {
                id: 'slug',
                label: 'URL Path',
                colClass: 'col-xs-12',
                required: true
            }, {
                id: 'published',
                label: 'Published',
                colClass: 'col-xs-12',
                type: Gecko.Field.TYPE_TOGGLE,
                action: function action() {
                    saveLandingPage();
                }
            }, {
                id: 'url',
                label: 'Sharing Link',
                colClass: 'col-xs-12',
                type: Gecko.Field.TYPE_LINK,
                hideWhen: function hideWhen() {
                    return !ctrl._landing_page.published;
                }
            }, {
                id: 'social_media',
                label: 'Social Media Links',
                type: Gecko.Field.TYPE_TITLE
            }, {
                id: 'facebook_url',
                obj: ctrl._landing_page.config,
                label: 'Facebook Link',
                colClass: 'col-xs-12'
            }, {
                id: 'twitter_url',
                obj: ctrl._landing_page.config,
                label: 'Twitter Link',
                colClass: 'col-xs-12'
            }, {
                id: 'linkedin_url',
                obj: ctrl._landing_page.config,
                label: 'LinkedIn Link',
                colClass: 'col-xs-12'
            }, {
                id: 'appearance',
                label: 'Appearance',
                type: Gecko.Field.TYPE_TITLE
            }, {
                id: 'primary_color',
                obj: ctrl._landing_page.styles,
                label: 'Primary Color',
                type: Gecko.Field.TYPE_COLOR,
                colClass: 'col-xs-12'
            }, {
                id: 'text_color',
                obj: ctrl._landing_page.styles,
                label: 'Text Color',
                type: Gecko.Field.TYPE_COLOR,
                colClass: 'col-xs-6'
            }, {
                id: 'title_color',
                obj: ctrl._landing_page.styles,
                label: 'Title Color',
                type: Gecko.Field.TYPE_COLOR,
                colClass: 'col-xs-6'
            }, {
                id: 'subtitle_color',
                obj: ctrl._landing_page.styles,
                label: 'Subtitle Color',
                type: Gecko.Field.TYPE_COLOR,
                colClass: 'col-xs-6'
            }, {
                id: 'background_color',
                obj: ctrl._landing_page.styles,
                label: 'Background Color',
                type: Gecko.Field.TYPE_COLOR,
                colClass: 'col-xs-6'
            }, {
                id: 'analytics',
                label: 'Analytics',
                type: Gecko.Field.TYPE_TITLE
            }, {
                id: 'gtm_id',
                label: 'Google Tag Manager ID',
                colClass: 'col-xs-12',
                description: 'For example <code ng-non-bindable>GTM-XXXXXX</code>. For more information, click <a href="https://academy.geckoengage.com/en/articles/6967267-form-analytics-tracking#h_c3009f6719">here</a>.'
            }, {
                id: 'ignore_global_gtm_id',
                label: 'Ignore Global Google Tag Manager ID',
                colClass: 'col-xs-12',
                type: Gecko.Field.TYPE_TOGGLE,
                description: 'Ignore the global Google Tag Manager ID for this landing page.',
                hideWhen: function hideWhen() {
                    return ctrl._landing_page.gtm_id || !Gecko.account.gtm_id;
                }
            }, {
                label: 'GeckoChat Integration',
                type: Gecko.Field.TYPE_TITLE,
                colClass: 'col-xs-12'
            }, {
                id: 'chat_channel_id',
                label: 'Channel',
                type: Gecko.Field.TYPE_SELECT,
                description: 'Select a live chat widget from your instance of Gecko Chat to be embedded onto the landing page.',
                options: ctrl.gecko_chat_channels.data,
                optionsKey: 'widget_id',
                optionsLabelKey: 'name',
                hideWhen: function hideWhen() {
                    return ctrl.gecko_chat_channels.geckoChatIntegrationExists === false;
                },
                disabledWhen: function disabledWhen() {
                    return ctrl.gecko_chat_channels.geckoChatIntegrationExists === null;
                },
                colClass: 'col-xs-12'
            }, {
                type: Gecko.Field.TYPE_SCRIPT,
                break: true,
                colClass: 'alert alert-info',
                description: 'GeckoEngage integration has not been set up on GeckoChat. <a href="https://app.geckochat.io/#/settings/integrations" target="_blank">Create a GeckoChat Integration</a>',
                hideWhen: function hideWhen() {
                    return ctrl.gecko_chat_channels.geckoChatIntegrationExists === true || ctrl.gecko_chat_channels.geckoChatIntegrationExists === null;
                }
            }];

            // Settings Card buttons
            ctrl.settingsCardBtns = [{
                preset: 'save',
                action: saveLandingPage
            }];
        };

        // Re-order card
        ctrl.cols = [{
            key: 'type',
            render: function render(type) {
                return GeckoUI.gobk(Gecko.Block.type_titles, 'id', type).title + ' Block';
            },
            colClass: 'col-xs-12'
        }];

        // Reorder callback
        ctrl.reorderOptions = {
            disableItem: function disableItem(block) {
                if ([Gecko.Block.TYPE_HEADER, Gecko.Block.TYPE_FOOTER].indexOf(block.type) != -1) return true;
            },
            stop: function stop() {
                // Save order
                $landingPageBuilder.reorder(ctrl.blocks).then(function () {
                    GeckoUI.messenger.success('Block order updated.');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }

            // Build block css
        };ctrl.blockStyles = $landingPageBuilder.buildBlockStyles(ctrl.blocks);
        $scope.$watch('ctrl.blocks', function (newVal, oldVal) {
            if (newVal != oldVal) {
                ctrl.blockStyles = $landingPageBuilder.buildBlockStyles(ctrl.blocks);
            }
        }, true);
    }

    angular.module('GeckoEngage').controller('LandingPagesCtrl', LandingPagesCtrl).controller('LandingPageAddCtrl', LandingPageAddCtrl).controller('LandingPageCtrl', LandingPageCtrl);
})();