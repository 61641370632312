
var GeckoUI = {};

/**
 *  Messenger
 */

GeckoUI.messenger = {
    success: function success(message) {
        if (message !== undefined) new Messenger().post(message);
    },
    info: function info(message) {
        Messenger().post({
            message: GeckoUI.renderError(message),
            type: 'info'
        });
    },
    error: function error(errors) {
        Messenger().post({
            message: GeckoUI.renderError(errors),
            type: 'error'
        });
    }
};

GeckoUI.renderError = function (errors) {
    var defaultMessage = 'Sorry, there was an error. Please try again.';
    var error = angular.copy(errors);
    if (typeof error === 'string' && error.length > 0) {
        return error;
    }
    if (error instanceof Array && error.length > 0) {
        return GeckoUI.renderError(error[0]);
    }
    if (error instanceof Object && error.errors) {
        return GeckoUI.renderError(error.errors);
    }
    if (error instanceof Object && Object.keys(error).length > 0) {
        return GeckoUI.renderError(error[Object.keys(error)[0]]);
    }
    return defaultMessage;
};

/**
 *  Dialog modals.
 */

GeckoUI.dialog = {
    vex: function (_vex) {
        function vex(_x, _x2, _x3, _x4, _x5) {
            return _vex.apply(this, arguments);
        }

        vex.toString = function () {
            return _vex.toString();
        };

        return vex;
    }(function (type, message, callback, input, buttons) {
        if (message !== undefined) {
            var opts = {
                className: 'vex-theme-plain vex-theme-gecko',
                message: message
            };
            if (input !== undefined) opts.input = input;
            if (buttons !== undefined) opts.buttons = buttons;
            if (callback !== undefined) opts.callback = callback;
            vex.dialog[type](opts);
        }
    }),
    alert: function alert(message) {
        return GeckoUI.dialog.vex('alert', message);
    },
    confirm: function confirm(message, callback) {
        return GeckoUI.dialog.vex('confirm', message, callback);
    },
    confirmCustom: function confirmCustom(message, buttons, callback) {

        for (var i = 0; i < buttons.length; i++) {
            if (vex.dialog.buttons[buttons[i].base]) {
                buttons[i] = $.extend({}, vex.dialog.buttons[buttons[i].base], buttons[i]);
            }
        }

        return GeckoUI.dialog.vex('confirm', message, callback, undefined, buttons);
    },
    prompt: function prompt(message, callback, array, selectedIndex) {
        var input;
        if (array) {
            input = '<select name="vex" class="form-control gecko-ui-material">';
            // Add placeholder
            if (selectedIndex === undefined) input += '<option value="">Please select...</option>';

            for (var i = 0; i < array.length; i++) {
                if (selectedIndex !== undefined && i === selectedIndex) {
                    input += '<option selected value="' + array[i].value + '">' + array[i].label + '</option>';
                } else {
                    input += '<option value="' + array[i].value + '">' + array[i].label + '</option>';
                }
            }
            input += '</select>';
        } else {
            input = undefined;
        }
        return GeckoUI.dialog.vex('prompt', message, callback, input);
    },
    customButton: function customButton(text, resultValue) {
        return {
            text: text,
            type: 'button',
            click: function click($vexContent) {
                $vexContent.data().vex.value = resultValue;
                return vex.close($vexContent.data().vex.id);
            }
        };
    }
    // Custom: function(message, buttons, callback){
    //     Var _buttons = [];
    //     For (var i=0; i<buttons.length; i++){
    //         _buttons.push(
    //             $.extend({}, (buttons[i].value ? vex.dialog.buttons.YES : vex.dialog.buttons.NO), {className: (buttons[i].className ? buttons[i].className : ''), text: buttons[i].text, click: function($vexContent, event) {
    //                 $vexContent.data().vex.value = buttons[i].value;
    //                 Vex.close($vexContent.data().vex.id);
    //             }})
    //         );
    //     }
    //     Return GeckoUI.dialog.vex('open', message, callback, buttons);
    // }
};

/**
 *  Get object from array of objects with the id property.
 *  @param Array array
 *  @param Id int
 */

GeckoUI.getObjectByKey = function (array, key, value) {
    if (array == undefined || key == undefined) return false;
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] !== undefined && array[i][key] == value) return array[i];
    }
    return false;
};

GeckoUI.gobk = GeckoUI.getObjectByKey;

/**
 *  Get object array from array of objects with the id property.
 *  @param Array array
 *  @param Id int
 */

GeckoUI.getObjectArrayByKey = function (array, key, value) {
    if (array == undefined || key == undefined) return false;
    var _array = [];
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] !== undefined && array[i][key] == value) _array.push(array[i]);
    }
    return _array;
};

GeckoUI.goabk = GeckoUI.getObjectArrayByKey;

/**
 *
 */

GeckoUI.mapObjectArray = function (array, map, isFlat) {
    var _array = [];
    for (var i = 0; i < array.length; i++) {
        var _obj = {};
        for (var prop in map) {
            // Handle flat arrays
            if (isFlat) {
                _obj[prop] = array[i];
            } else {
                if (array[i][map[prop]]) {
                    _obj[prop] = array[i][map[prop]];
                } else {
                    _obj[prop] = null;
                }
            }
        }
        _array.push(_obj);
    }
    return _array;
};

/**
 *  Get value from an object using a dot literal path..
 *  @param Obj object
 *  @param Path string
 */

GeckoUI.getNestedObjectValue = function (obj, path) {
    path = path.split('.');
    var val = obj;
    for (var prop = 0; prop < path.length; prop++) {
        if (!val[path[prop]]) {
            val = '';
            break;
        }
        val = val[path[prop]];
    }
    return val;
};

/**
 *  Refresh order property for an array of objects.
 *  @param Array array
 *  @param orderProp string
 */

GeckoUI.refreshOrder = function (array, pageOrder, orderProp) {
    if (array !== undefined) {
        pageOrder = pageOrder !== undefined ? (pageOrder + 1) * 100 : 0;
        orderProp = orderProp === undefined ? 'order' : orderProp;
        angular.forEach(array, function (item, i) {
            item[orderProp] = pageOrder + (i + 1);
        });
    }
};

/**
 *  Remove an item from an array.
 *  @param Array array
 *  @param Item int
 */

GeckoUI.removeItem = function (array, index) {
    array.splice(index, 1);
};

/**
 *  Get an array of id from an array of objects
 *  @param Array array
 *  @return array
 */

GeckoUI.getIdArray = function (array, key) {
    var _array = [];
    angular.forEach(array, function (item) {
        _array.push(item[key != undefined ? key : 'id']);
    });
    return _array;
};

/**
 *  Filter array by object key value.
 *  @param Needle mixed
 *  @param Haystack array
 *  @param Key string
 *  @return mixed
 */

GeckoUI.getSingle = function (needle, haystack, key) {

    var _return = {};
    if (!key) key = 'id';

    angular.forEach(haystack, function (value) {
        if (needle == value[key]) {
            _return = value;
        }
    });

    return _return;
};

/**
 *  Get field from array with a field id.
 *  @param Array array
 *  @param Id int
 *  @return object
 */

GeckoUI.getField = function (array, id) {
    try {
        if (array !== undefined && id !== undefined) {
            if (String(id).indexOf('field:') !== -1) {
                id = String(id).replace('field:', '');
            }
            var _field = {};

            angular.forEach(array, function (field) {
                if (id == field.id) {
                    _field = field;
                }
            });

            return _field;
        }
    } catch (e) {
        // Pass
    }
};

GeckoUI.getFieldOptions = function (array, id, field) {

    if (!field) {
        field = GeckoUI.getField(array, id);
    }

    if (!field) {
        return [];
    }

    if (field.parent_id) {
        return GeckoUI.getParentFieldOptions(array, id, field);
    } else if (field.option_id && field.option) {
        return field.option.values;
    } else {
        return field.values;
    }
};

GeckoUI.createNumberRange = function (start, end, step, options) {
    var numbers = [];

    for (var i = start; i < end; i += step) {
        if (options.displayFunc) {
            numbers.push({ value: i, display: options.displayFunc(i) });
        } else {
            numbers.push(i);
        }
    }

    return numbers;
};

GeckoUI.roundToInterval = function (value, interval) {
    return Math.round(value / interval) * interval;
};

GeckoUI.padLeft = function (value, padChar, len) {
    var sVal = String(value);
    if (!len || len < 1) {
        return sVal;
    }

    while (sVal.length < len) {
        sVal = padChar + sVal;
    }
    return sVal;
};

GeckoUI.decomposeSeconds = function (seconds) {
    var time = {};
    time.seconds = seconds % 60;
    var totalMinutes = Math.floor(seconds / 60);
    time.minutes = totalMinutes % 60;
    var totalHours = Math.floor(totalMinutes / 60);
    time.hours = totalHours % 24;
    time.days = Math.floor(totalHours / 24);

    return time;
};

GeckoUI.composeSeconds = function (time) {
    return (((time.days || 0) * 24 + (time.hours || 0)) * 60 + (time.minutes || 0)) * 60;
};

//
GeckoUI.arrayToObjectByKey = function (array, key, valueKey) {
    var obj = new Object(null);

    array.forEach(function (val) {
        if (valueKey) obj[val[key]] = val[valueKey];else obj[val[key]] = val;
    });

    return obj;
};

GeckoUI.arrayToObjectKeys = function (array, defaultValue) {
    defaultValue = typeof defaultValue === 'undefined' ? true : defaultValue;
    var obj = {};
    angular.forEach(array, function (value) {
        obj[value] = defaultValue;
    });

    return obj;
};

GeckoUI.objectKeysToArray = function (object) {
    var array = [];
    angular.forEach(object, function (value, key) {
        if (value) array.push(key);
    });

    return array;
};

/**
 * Return an array from a value.
 * If it's already an array just return that.
 * If it's undefined return the defaultValue or an empty array.
 */
GeckoUI.wrapArray = function (value, defaultValue) {
    return value ? angular.isArray(value) ? value : [value] : defaultValue || [];
};

/**
 * Removes duplicate entries by a given key or function
 */
GeckoUI.dedupeBy = function (array, key) {
    var getValue = function getValue(item, key) {
        var keys = key.split('.');
        if (keys.length === 1) {
            return item[keys[0]];
        } else {
            return getValue(item[keys.shift()], keys.join('.'));
        }
    };
    var seenValues = [];
    var filterFunc = function filterFunc(item, index) {
        var val;
        if (typeof key === 'function') {
            val = key(item, index, array);
        }
        if (typeof key !== 'function') {
            val = getValue(item, key);
        }

        if (seenValues.indexOf(val) === -1) {
            seenValues.push(val);
            return true;
        } else {
            return false;
        }
    };
    return array.filter(filterFunc);
};

(function () {
    'use strict';

    var units = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    GeckoUI.humanizeBytes = function (value, precision) {

        if (value == null || value === '') {
            return '—';
        }

        var unitPrefixIndex = 0;

        while (value >= 1024 && unitPrefixIndex < units.length - 1) {
            value /= 1024;
            unitPrefixIndex++;
        }

        if (value && precision) return value.toFixed(precision) + units[unitPrefixIndex];
        return value + units[unitPrefixIndex];
    };
})();

GeckoUI.ableWithReason = function (ability) {

    if (!ability) {
        console.warn('Undefined ability used.');
        return;
    }
    if (!Gecko.able(ability)) {
        GeckoUI.messenger.error(Gecko.unableReason(ability));
    }
    return Gecko.able(ability);
};

/**
 * Takes a find function that returns a boolean for each array element, if it ever returns true the whole function will return the index
 *  it was on otherwise it will return -1 indicating the item was not in the array, this function is curried to make it more functional.
 * @param  findFunc  A function that takes in the array item, current index and the array itself and returns a boolean
 * @param  array     The array you want to run the find function on
 * @return Number    The index of the element or -1
 * e.g. GeckoUI.indexOf( function(item){ return item === 5 } )( [4, 5, 6] ) > 1
 */
GeckoUI.indexOf = function (findFunc) {
    return function (array) {
        for (var index = 0; index < array.length; index++) {
            if (findFunc(array[index], index, array)) {
                return index;
            }
        }
        return -1;
    };
};

/**
 * Takes an id and then returns a function that can be used in find, filter or indexOf statements for finding an element with the
 *  given id.
 * @param  id       The id you are searching for
 * @param  thing    The item you are checking(generally the find, filter and indexOf statements will pass this through automatically)
 * @return Boolean  Whether the tested thing has the given id
 * e.g. [{id: 1}, {id: 2}, {id: 3}].find( GeckoUI.findById(2) ) > {id: 2}
 * e.g. GeckoUI.indexOf( GeckoUI.findById(2) )( [{id: 1}, {id: 2}, {id: 3}] ) > 1
 */
GeckoUI.findById = function (id) {
    return function (thing) {
        return thing.id === id;
    };
};

/**
 * Takes an array and the index of the element you want to move and then the index of where you want to move it to.
 * @param  array        The array you want to move an element in
 * @param  currentIndex The index of the element you want to move
 * @param  newIndex     The index you want to move the element to
 * @return Array        The re-ordered array
 * e.g. GeckoUI.moveElement( [0, 1, 2, 3, 4, 5] )( 0, 3 ) > [1, 2, 3, 0, 4, 5]
 */
GeckoUI.moveElement = function (array) {
    return function (currentIndex, newIndex) {
        var reOrderedArray = [].concat(array); // Create a new array to maintain the passed arrays state
        reOrderedArray.splice(newIndex, 0, reOrderedArray.splice(currentIndex, 1)[0]);
        return reOrderedArray;
    };
};

GeckoUI.accountTheme = function () {
    if (Gecko.account) {
        return tinycolor(Gecko.account.color);
    }
};

/**
 * Takes a function and returns a new function that will cache it's results, for use in expensive functions or functions that are
 * run often.  The caching is based off of the arguments passed in so it's important to note this only really works for pure
 * functions(functions that use no outside scope) or functions where the outside scope used won't change.
 * @param  func             The function that should have caching applied to it.
 * @return cacheEnabledFunc The function with caching applied.
 */
GeckoUI.cachedFunc = function (func) {
    var cache = {};
    var cacheEnabledFunc = function cacheEnabledFunc() {
        var key = JSON.stringify(Array.prototype.slice.call(arguments)); // Generate a unique key based on the arguments
        if (cache[key] !== undefined) return cache[key]; // Return a cached result if we have one
        cache[key] = func.apply(func, arguments); // Call the function with the passed in arguments and cache it
        return cache[key];
    };
    return cacheEnabledFunc;
};

/**
 * Old GeckoService functions
 */
GeckoUI.getContrastColor = function (hexcolor) {
    if (!hexcolor) return 'white';
    hexcolor = hexcolor.indexOf('#') > -1 ? hexcolor.replace('#', '') : hexcolor;
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
};

GeckoUI.getPageLimit = function (state) {
    var config = JSON.parse(localStorage.getItem('gecko_pagination_limits_' + Gecko.account.routing_id) || "{}");
    if (config && config[state]) {
        return config[state];
    }
    return Gecko.DEFAULT_PER_PAGE;
};

GeckoUI.getRegion = function () {
    if (Gecko.useNewAuth() && window.TokenManager) {
        return window.TokenManager.getRegion();
    } else if (Gecko.token && Gecko.token.region) {
        return Gecko.token.region;
    }
    return '';
};

GeckoUI.getDefaultPlaceholder = function (type) {
    var defaults = {};
    defaults[Gecko.Field.TYPE_NAME] = "First Name|Last Name";
    defaults[Gecko.Field.TYPE_EMAIL] = "your.name@company.com";
    defaults[Gecko.Field.TYPE_NUMBER] = "e.g. 123";
    defaults[Gecko.Field.TYPE_URL] = "www.example.com";
    return defaults[type];
};

/**
 * Runs a reduce operation on a given object by looping through all of the keys and passing the value, key, index,
 *array of keys and object back to the reduce function.
 * @param  object      The object you want to run the reduce on
 * @param  reduceFunc:-
 *              @param  reduction The current state of the reduce function
 *              @param  item      The current value of the current key
 *              @param  key       The current key
 *              @param  index     The current index of the reduce
 *              @param  keys      An array of all the objects keys
 *              @param  object    The object itself
 *              @return any       The new state of the reduce function
 * @return reduction    The final state of the reduce function
 */
GeckoUI.reduceObject = function (reduceFunc, initialVal) {
    return function (object) {
        return Object.keys(object).reduce(function (reduction, key, index, keys) {
            var item = object[key];
            return reduceFunc(reduction, item, key, index, keys, object);
        }, initialVal);
    };
};

/**
 * Runs a map operation on a given object by looping through all of the keys and passing the value, key, index,
 *array of keys and object back to the map function.
 * @param  object      The object you want to run the map on
 * @param  mapFunc:-
 *              @param  item      The current value of the current key
 *              @param  key       The current key
 *              @param  index     The current index of the map
 *              @param  keys      An array of all the objects keys
 *              @param  object    The object itself
 *              @return any       The new value to set
 * @return reduction    The new object with the modified values
 */
GeckoUI.mapObject = function (mapFunc) {
    return function (object) {
        return GeckoUI.reduceObject(function (reduction, item, key, index, keys, object) {
            reduction[key] = mapFunc(item, key, index, keys, object);
            return reduction;
        }, {})(object);
    };
};

/**
 * @param  evt Intercom event to trigger
 * @param  meta Meta object to send to Intercom
 */

GeckoUI.triggerIntercomEvent = function (evt, meta) {
    try {
        // Add timestamp
        if (meta) meta.timestamp = String(new Date());
        window.Intercom('trackEvent', evt, meta || {});
    } catch (e) {} // eslint-disable-line no-empty
};

/**
 * A helper function that can turn a function into a curried version of that function very useful for Array functions e.g.
    var changeName = GeckoUI.curry(function(newName, item) {
        item.name = newName;
        return item;
    })
    var items = [{id: 1, name: 'test'}, {id: 2, name: 'tset'}];
    var modified = items.map(changeName('A new name')); // [{id: 1, name: 'A new name'}, {id: 2, name: 'A new name'}];
    // this can then still be called as normal
    var item = {id: 3, name: 'estt'}
    var newItem = changeName('A new name', item); // {id: 3, name: 'A new name'}
 * @param fx A function you want to curry
 * @return A new curried version of that function
 */
GeckoUI.curry = function (fx) {
    // http://blog.carbonfive.com/2015/01/14/gettin-freaky-functional-wcurried-javascript/
    var arity = fx.length;

    return function f1() {
        var args = Array.prototype.slice.call(arguments, 0);
        if (args.length >= arity) {
            return fx.apply(null, args);
        } else {
            return function f2() {
                var args2 = Array.prototype.slice.call(arguments, 0);
                return f1.apply(null, args.concat(args2));
            };
        }
    };
};

GeckoUI.serialize = function (params) {
    var _params = '';
    for (var key in params) {
        if (_params != '') {
            _params += '&';
        }
        _params += key + '=' + encodeURIComponent(params[key]);
    }
    return _params;
};

/**
 * @param  syncs Synchronisations array
 * @param  fields Fields array
 * @param  fields Values object
 */

GeckoUI.prepareSyncsData = function (syncs, fields, values) {
    if (Array.isArray(syncs) && syncs.length && Array.isArray(fields) && values) {
        syncs.filter(function (sync) {
            if (sync.third_party_id && sync.related_integration && sync.related_type != 'consents') {
                // Add value
                values[sync.related_integration.title_with_date + '_id'] = sync.third_party_id;
                // Add field
                fields.push({
                    label: sync.related_integration.title_with_date.charAt(0).toUpperCase() + sync.related_integration.title_with_date.slice(1) + ' ID',
                    key: sync.related_integration.title_with_date + '_id',
                    type: Gecko.Field.TYPE_NOEDIT,
                    noEdit: true
                });
                return sync;
            }
        });

        return values;
    }
    return false;
};

/**
 * There are issue with Etc/GMT plus/minus formatting, this has to be inversed to make sense (sigh)
 * @param  timezone string
 */

GeckoUI.fixGmtTimezone = function (timezone) {
    if (timezone.indexOf('Etc/GMT+') !== -1) {
        return timezone.replace('+', '-');
    } else if (timezone.indexOf('Etc/GMT-') !== -1) {
        return timezone.replace('-', '+');
    }
    return timezone;
};

GeckoUI.prepareNameClass = function (prefix, string) {
    if (string === undefined) return '';
    return (prefix + '-' + string).replace(new RegExp('fa-', 'g'), '').replace(new RegExp(' ', 'g'), '-').toLowerCase();
};

GeckoUI.prepareIndexClass = function (prefix, index) {
    if (index === undefined) return '';
    return (prefix + '-' + index).replace(new RegExp('fa-', 'g'), '').replace(new RegExp(' ', 'g'), '-').toLowerCase();
};

GeckoUI.loadExternalScript = function (url, callback) {
    try {
        setTimeout(function () {
            var script = document.createElement('script');
            if (callback) script.onload = callback;
            script.src = url;
            // Add script tags
            document.head.appendChild(script);
        });
    } catch (e) {}
};

GeckoUI.generateUuid = function () {
    var uuid = "",
        i,
        random;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;

        if (i == 8 || i == 12 || i == 16 || i == 20) {
            uuid += "-";
        }
        uuid += (i == 12 ? 4 : i == 16 ? random & 3 | 8 : random).toString(16);
    }
    return uuid;
};

GeckoUI.htmlHasContent = function (html) {
    html = html || '';
    if (html.indexOf('img') !== -1 || html.indexOf('svg') !== -1) return true;
    return !!(html || '').replace(/(<([^>]+)>)/ig, '');
};