(function () {

    function SnapshotModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('snapshotModal', function () {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/snapshot/modal.html',
                controller: 'SnapshotModalCtrl'
            };
        });
    }

    function SnapshotModalCtrl($scope, $modalInstance, $stateParams, $state) {
        var ctrl = this;
        ctrl.id = $stateParams.dashboard_id;

        ctrl.snapshot = new Gecko.Snapshot();

        ctrl.fields = [{
            id: 'name',
            type: Gecko.Field.TYPE_TEXT,
            description: "Snapshots are useful for comparing data sets over a period of time.",
            colClass: 'col-md-12'
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Ok',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            action: function action() {

                if ($stateParams.campaign_id) {
                    ctrl.snapshot.campaign_id = $stateParams.campaign_id;
                } else if ($stateParams.form_id) {
                    ctrl.snapshot.form_id = $stateParams.form_id;
                }
                ctrl.snapshot.dashboard_id = ctrl.id;

                if ($stateParams.override_date) {
                    ctrl.snapshot.override_date = $stateParams.override_date;
                } else if ($stateParams.override_date === 'custom') {
                    ctrl.snapshot.override_date = $stateParams.date_from + ':' + $stateParams.date_to;
                }

                if ($stateParams.filter_id) {
                    ctrl.snapshot.filter = { filter_id: $stateParams.filter_id };
                }

                ctrl.snapshot.save().then(function (data) {
                    $state.go('dashboardsnapshotview', { dashboard_id: ctrl.id, snapshot_id: data.id });
                    $modalInstance.close();
                    GeckoUI.messenger.success('Snapshot created');
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(SnapshotModals).controller('SnapshotModalCtrl', SnapshotModalCtrl);
})();