(function () {
    'use strict';

    function geckoRepeatableField() {
        return {

            templateUrl: '/components/gecko-repeatable-field/gecko-repeatable-field.html',
            scope: {
                field: '=',
                value: "="
            },
            bindToController: true,
            controllerAs: 'ctrl',
            controller: function controller() {
                var ctrl = this;
                ctrl.addRow = function () {
                    if (ctrl.value) {
                        ctrl.value.push([]);
                    } else {
                        ctrl.value = [[]];
                    }
                };

                ctrl.removeRow = function (row_id) {
                    ctrl.value.splice(row_id, 1);
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoRepeatableField', geckoRepeatableField);
})();