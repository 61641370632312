(function () {
    'use strict';

    function changeLogService(env, portalRequestService) {
        var _this = {

            get: function get(_params) {

                // Dont load change log locally
                if (env() === 'local') {
                    return Promise.resolve().then(function () {
                        return {
                            data: [],
                            pagination: {
                                perPage: null,
                                prev: null,
                                next: null,
                                page: null
                            }
                        };
                    });
                }

                var params = GeckoUI.serialize(_params);

                return portalRequestService.get('/changelog?app=geckoform' + (params ? '&' + params : '')).then(function (res) {
                    var data = res.data.map(function (item) {
                        return {
                            id: item.id,
                            release_type: item.releaseType,
                            link: item.link,
                            title: item.title,
                            strapline: item.body,
                            created_at: item.createdAt,
                            model_name: 'changelog'
                        };
                    });
                    return {
                        data: data,
                        pagination: res.pagination
                    };
                });
            }

        };

        return _this;
    }

    angular.module('GeckoEngage').factory('changeLogService', changeLogService);
})();