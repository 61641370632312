/*! jQuery UI - v1.10.2 - 2013-03-14
* http://jqueryui.com
* Copyright 2013 jQuery Foundation and other contributors; Licensed MIT */
(function (e, t) {
  function i(t, i) {
    var a,
        n,
        r,
        o = t.nodeName.toLowerCase();return "area" === o ? (a = t.parentNode, n = a.name, t.href && n && "map" === a.nodeName.toLowerCase() ? (r = e("img[usemap=#" + n + "]")[0], !!r && s(r)) : !1) : (/input|select|textarea|button|object/.test(o) ? !t.disabled : "a" === o ? t.href || i : i) && s(t);
  }function s(t) {
    return e.expr.filters.visible(t) && !e(t).parents().addBack().filter(function () {
      return "hidden" === e.css(this, "visibility");
    }).length;
  }var a = 0,
      n = /^ui-id-\d+$/;e.ui = e.ui || {}, e.extend(e.ui, { version: "1.10.2", keyCode: { BACKSPACE: 8, COMMA: 188, DELETE: 46, DOWN: 40, END: 35, ENTER: 13, ESCAPE: 27, HOME: 36, LEFT: 37, NUMPAD_ADD: 107, NUMPAD_DECIMAL: 110, NUMPAD_DIVIDE: 111, NUMPAD_ENTER: 108, NUMPAD_MULTIPLY: 106, NUMPAD_SUBTRACT: 109, PAGE_DOWN: 34, PAGE_UP: 33, PERIOD: 190, RIGHT: 39, SPACE: 32, TAB: 9, UP: 38 } }), e.fn.extend({ focus: function (t) {
      return function (i, s) {
        return "number" == typeof i ? this.each(function () {
          var t = this;setTimeout(function () {
            e(t).focus(), s && s.call(t);
          }, i);
        }) : t.apply(this, arguments);
      };
    }(e.fn.focus), scrollParent: function scrollParent() {
      var t;return t = e.ui.ie && /(static|relative)/.test(this.css("position")) || /absolute/.test(this.css("position")) ? this.parents().filter(function () {
        return (/(relative|absolute|fixed)/.test(e.css(this, "position")) && /(auto|scroll)/.test(e.css(this, "overflow") + e.css(this, "overflow-y") + e.css(this, "overflow-x"))
        );
      }).eq(0) : this.parents().filter(function () {
        return (/(auto|scroll)/.test(e.css(this, "overflow") + e.css(this, "overflow-y") + e.css(this, "overflow-x"))
        );
      }).eq(0), /fixed/.test(this.css("position")) || !t.length ? e(document) : t;
    }, zIndex: function zIndex(i) {
      if (i !== t) return this.css("zIndex", i);if (this.length) for (var s, a, n = e(this[0]); n.length && n[0] !== document;) {
        if (s = n.css("position"), ("absolute" === s || "relative" === s || "fixed" === s) && (a = parseInt(n.css("zIndex"), 10), !isNaN(a) && 0 !== a)) return a;n = n.parent();
      }return 0;
    }, uniqueId: function uniqueId() {
      return this.each(function () {
        this.id || (this.id = "ui-id-" + ++a);
      });
    }, removeUniqueId: function removeUniqueId() {
      return this.each(function () {
        n.test(this.id) && e(this).removeAttr("id");
      });
    } }), e.extend(e.expr[":"], { data: e.expr.createPseudo ? e.expr.createPseudo(function (t) {
      return function (i) {
        return !!e.data(i, t);
      };
    }) : function (t, i, s) {
      return !!e.data(t, s[3]);
    }, focusable: function focusable(t) {
      return i(t, !isNaN(e.attr(t, "tabindex")));
    }, tabbable: function tabbable(t) {
      var s = e.attr(t, "tabindex"),
          a = isNaN(s);return (a || s >= 0) && i(t, !a);
    } }), e("<a>").outerWidth(1).jquery || e.each(["Width", "Height"], function (i, s) {
    function a(t, i, s, a) {
      return e.each(n, function () {
        i -= parseFloat(e.css(t, "padding" + this)) || 0, s && (i -= parseFloat(e.css(t, "border" + this + "Width")) || 0), a && (i -= parseFloat(e.css(t, "margin" + this)) || 0);
      }), i;
    }var n = "Width" === s ? ["Left", "Right"] : ["Top", "Bottom"],
        r = s.toLowerCase(),
        o = { innerWidth: e.fn.innerWidth, innerHeight: e.fn.innerHeight, outerWidth: e.fn.outerWidth, outerHeight: e.fn.outerHeight };e.fn["inner" + s] = function (i) {
      return i === t ? o["inner" + s].call(this) : this.each(function () {
        e(this).css(r, a(this, i) + "px");
      });
    }, e.fn["outer" + s] = function (t, i) {
      return "number" != typeof t ? o["outer" + s].call(this, t) : this.each(function () {
        e(this).css(r, a(this, t, !0, i) + "px");
      });
    };
  }), e.fn.addBack || (e.fn.addBack = function (e) {
    return this.add(null == e ? this.prevObject : this.prevObject.filter(e));
  }), e("<a>").data("a-b", "a").removeData("a-b").data("a-b") && (e.fn.removeData = function (t) {
    return function (i) {
      return arguments.length ? t.call(this, e.camelCase(i)) : t.call(this);
    };
  }(e.fn.removeData)), e.ui.ie = !!/msie [\w.]+/.exec(navigator.userAgent.toLowerCase()), e.support.selectstart = "onselectstart" in document.createElement("div"), e.fn.extend({ disableSelection: function disableSelection() {
      return this.bind((e.support.selectstart ? "selectstart" : "mousedown") + ".ui-disableSelection", function (e) {
        e.preventDefault();
      });
    }, enableSelection: function enableSelection() {
      return this.unbind(".ui-disableSelection");
    } }), e.extend(e.ui, { plugin: { add: function add(t, i, s) {
        var a,
            n = e.ui[t].prototype;for (a in s) {
          n.plugins[a] = n.plugins[a] || [], n.plugins[a].push([i, s[a]]);
        }
      }, call: function call(e, t, i) {
        var s,
            a = e.plugins[t];if (a && e.element[0].parentNode && 11 !== e.element[0].parentNode.nodeType) for (s = 0; a.length > s; s++) {
          e.options[a[s][0]] && a[s][1].apply(e.element, i);
        }
      } }, hasScroll: function hasScroll(t, i) {
      if ("hidden" === e(t).css("overflow")) return !1;var s = i && "left" === i ? "scrollLeft" : "scrollTop",
          a = !1;return t[s] > 0 ? !0 : (t[s] = 1, a = t[s] > 0, t[s] = 0, a);
    } });
})(jQuery);