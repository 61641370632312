(function () {
    'use strict';

    function EventWorkflowsCtrl($scope, $state, $stateParams, $filter, event, workflows, storedData, unsavedAlert) {
        $scope.event = event;
        $scope.event.related_workflows = workflows;
        $scope.multiData = storedData;

        // Save Event
        function eventSave() {

            $scope.event.workflows = Gecko.convertInstancesToObjects($scope.event.related_workflows);

            angular.copy($scope.event).include('workflows', 'workflows.condtions').save().then(function (event) {
                // Tell the user it was ok
                GeckoUI.messenger.success('Event workflows saved');
                unsavedAlert.clear();
                $scope.$digest();
            }).catch(function (errors) {
                GeckoUI.messenger.error(errors.errors);
            });
        }

        $scope.$on('saveEvent', function () {
            return eventSave();
        });
    }

    angular.module('GeckoEngage').controller('EventWorkflowsCtrl', EventWorkflowsCtrl);
})();