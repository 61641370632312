(function () {
    'use strict';

    function breadcrumbs() {
        return {
            restrict: 'E',
            templateUrl: '/components/breadcrumbs/breadcrumbs.html',
            scope: {
                data: "=data"
            },
            controller: function controller($scope) {
                $scope.$watch('data', function () {
                    if (!$scope.data) {
                        $scope.breadcrumbs = $scope.$parent.breadcrumbs;
                    } else {
                        $scope.breadcrumbs = $scope.data;
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('breadcrumbs', breadcrumbs);
})();