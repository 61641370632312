(function () {

    function EventModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('eventAddContactModal', function (_contact, _attendances, _event_id, _config) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/event/event_contact_modal.html',
                controller: 'EventAddContactModalCtrl',
                resolve: {
                    event_id: function event_id() {
                        return _event_id;
                    },
                    contact: function contact() {
                        if (angular.isArray(_contact) || _contact.id) return _contact;
                        return new Gecko.Contact().get(_contact);
                    },
                    attendances: function attendances() {
                        if (_attendances) return _attendances;
                        var contact_id = _contact.id ? _contact.id : _contact;
                        if (Array.isArray(contact_id)) return null;
                        return new Gecko.Attendance().include('event').where('contact_id', contact_id).get().then(function (attendances) {
                            return attendances.toArray();
                        });
                    },
                    config: function config() {
                        return angular.extend({}, {
                            disabledLevels: null,
                            title: 'Add to ' + (_event_id ? 'session' : 'event'),
                            attendanceStatus: Gecko.account.default_attendance_added_status,
                            btnTitle: 'Add',
                            btnIcon: 'fa-plus'
                        }, _config);
                    }
                }
            };
        }).modalAdd('eventAddToModal', function (_target) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/event/event_add_to_modal.html',
                controller: 'EventAddToModalCtrl',
                resolve: {
                    target: function target() {
                        return _target;
                    }
                }
            };
        }).modalAdd('eventTimeTravelModal', function (_event) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/event/event_timetravel_modal.html',
                controller: 'EventTimeTravelModalCtrl',
                resolve: {
                    event: function event() {
                        return _event;
                    }
                }
            };
        });
    }

    function EventAddContactModalCtrl($scope, $filter, $modalInstance, $state, $stateParams, $geckoSocket, event_id, contact, attendances, config, OptionsPresets, asyncOptions, geckoEventPickerService, handleMassAction) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.attendances = GeckoUI.getIdArray(attendances, 'event_id');
        ctrl.config = Object.assign({}, { attendanceStatus: Gecko.account.default_attendance_added_status }, config);
        // Modal title
        ctrl.title = ctrl.config.title;
        ctrl.saving = false;

        // Prepare data if standalone contact
        if (!angular.isArray(ctrl.contact)) {
            // Prepare attendance
            ctrl.attendance = new Gecko.Attendance().create({
                contact_id: ctrl.contact.id,
                event_id: event_id,
                created_at: Math.floor(new Date().getTime() / 1000),
                status: ctrl.config.attendanceStatus
            });
        } else {
            ctrl.attendance = {
                event_id: event_id,
                status: ctrl.config.attendanceStatus
            };
        }

        ctrl.sessions = [];

        var contactNotAlreadyAttending = function contactNotAlreadyAttending(event) {
            return ctrl.attendances.indexOf(event.id) === -1;
        };

        // Field list
        ctrl.fields = [geckoEventPickerService.prepareField({
            id: 'event_id',
            label: event_id ? 'Session' : 'Event',
            type: Gecko.Field.TYPE_TIERED_SELECT,
            colClass: 'none',
            optionsInit: OptionsPresets.events.init,
            options: [[], [], []],
            getOptions: OptionsPresets.events.get,
            placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
            optionsFilter: [null, contactNotAlreadyAttending],
            orderBy: 'nice_title',
            optionsKey: 'id',
            optionsLabelKey: 'nice_title'
        }, ctrl.config.disabledLevels), {
            id: 'status',
            label: 'Status',
            colClass: 'none',
            type: Gecko.Field.TYPE_SELECT,
            options: Gecko.Attendance.status_titles,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            noBlank: true
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: ctrl.config.btnTitle,
            icon: ctrl.config.btnIcon,
            btnClass: 'btn-primary',
            disabledWhen: function disabledWhen() {
                return ctrl.saving;
            },
            action: function action() {
                ctrl.saving = true;

                ctrl.alertMessage = '';
                var event_id = ctrl.attendance.event_id;

                if (!event_id) return;

                // Check if event session is picked, a session time is included also.
                if (!geckoEventPickerService.sessionCheck(ctrl.fields[0], event_id)) return;

                // Add single contact to event
                if (!angular.isArray(ctrl.contact)) {
                    if (!event_id) return false;

                    // Cache attendance
                    var attendance = angular.copy(ctrl.attendance);

                    // Set as session
                    if (attendance.session_id) {
                        attendance.event_id = attendance.session_id;
                    } else {
                        // Pass back related event
                        ctrl.attendance.related_event = GeckoUI.gobk(ctrl.events, 'id', event_id);
                    }

                    // Save attendance
                    attendance.include('event').save().then(function (attendance) {
                        if (attendance.session_id) {
                            $modalInstance.dismiss();
                            $state.go($state.$current.name, $stateParams, { reload: true });
                        } else {
                            $modalInstance.close(attendance);
                        }
                    }).catch(function (err) {
                        ctrl.alertMessage = GeckoUI.renderError(err.errors);
                        $scope.$digest();
                    }).finally(function () {
                        return ctrl.saving = false;
                    });

                    // Add multiple contacts to event
                } else {
                    var conditions = { model: 'contact_ids', contact_ids: ctrl.contact };
                    new Gecko.Contact().massAction(angular.copy(conditions), Gecko.Workflow.Actions.attendance(event_id, ctrl.attendance.status)).then(function (data) {
                        // Handle the mass action
                        handleMassAction(data);
                    }).catch(GeckoUI.messenger.error).finally(function () {
                        return ctrl.saving = false;
                    });;

                    $modalInstance.close();
                }
            }
        }];
    }

    function EventAddToModalCtrl($modalInstance, target, OptionsPresets, geckoEventPickerService) {
        var ctrl = this;
        ctrl.target = target;
        ctrl.values = {};

        // Field list
        ctrl.fields = [geckoEventPickerService.prepareField({
            id: 'id',
            label: 'Event',
            type: Gecko.Field.TYPE_TIERED_SELECT,
            colClass: 'none',
            optionsInit: OptionsPresets.events.init,
            options: [[], []],
            getOptions: OptionsPresets.events.get,
            placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
            orderBy: 'nice_title',
            optionsKey: 'id',
            optionsLabelKey: 'nice_title',
            description: 'Retroactively change which event the selected responses were captured at.'
        })];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Add',
            icon: 'fa-plus',
            btnClass: 'btn-primary',
            action: function action() {
                if (ctrl.values.id) {
                    $modalInstance.close(ctrl.values.id);
                } else {
                    $modalInstance.dismiss();
                }
            }
        }];
    }

    function EventTimeTravelModalCtrl($modalInstance, event, geckoDates) {
        var ctrl = this;
        ctrl.event = event;

        // Prepare EVENT epochs
        ctrl.event.start_date = geckoDates.toEpoch(ctrl.event.start_datetime);

        ctrl.title = 'Event Time Travel';

        ctrl.fields = [{
            id: 'start_date',
            label: 'New Start Date',
            type: Gecko.Field.TYPE_DATETIME,
            colClass: 'none',
            utc: true,
            description: event.timezone.includes('Etc/') || event.timezone.includes('UTC') ? 'Please note that daylight savings deviations are not accounted for.' : ''
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Timetravel',
            icon: 'fa-clock',
            btnClass: 'btn-primary',
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to move this event?', function (value) {
                    if (value) {
                        ctrl.event.rfields({ event: ['title', 'start_datetime', 'end_datetime'] }).timetravel(ctrl.event.start_datetime).then(function (data) {
                            $modalInstance.close(data);
                        }).catch(function (err) {
                            ctrl.alertMessage = GeckoUI.renderError(err);
                        });
                    }
                });
            }
        }];

        ctrl.alertMessage = 'By moving the start date we will change all the events sessions and session times by the same amount. E.g. Move the event forward 1 day, the sessions and session times will also move forward 1 day.';

        // Only allow sensible dates to be set
        var _tempDates = {
            start_date: ctrl.event.start_date
        };
        geckoDates.initSetters(_tempDates, ctrl.event, [{ get: 'start_date', set: 'start_datetime' }]);
    }

    angular.module('GeckoEngage').config(EventModals).controller('EventAddContactModalCtrl', EventAddContactModalCtrl).controller('EventAddToModalCtrl', EventAddToModalCtrl).controller('EventTimeTravelModalCtrl', EventTimeTravelModalCtrl);
})();