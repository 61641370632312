(function () {
    'use strict';

    function geckoCard() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card/gecko-card.html',
            transclude: true,
            scope: {},
            link: function link(scope, element, attrs, ctrl, transclude) {
                transclude(scope.$parent, function (clone, scope) {
                    element.find('.transclude-gecko-card').append(clone);
                });
            }
        };
    }

    function geckoCardBody() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card/gecko-card-body.html',
            transclude: true,
            scope: {
                padded: '@',
                paddedVertical: '@',
                paddedHorizontal: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $attrs) {
                var ctrl = this;
            },
            link: function link(scope, element, attrs, ctrl, transclude) {
                transclude(scope.$parent, function (clone, scope) {
                    element.find('.transclude-gecko-card-body').append(clone);
                });
            }
        };
    }

    function geckoCardHeader() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card/gecko-card-header.html',
            transclude: true,
            scope: {
                title: '@',
                titleEditable: '=',
                editableDefault: '=',
                icon: '@',
                helpText: '@',
                noTransclude: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($attrs, $transclude) {
                var ctrl = this;
                ctrl.noTransclude = ctrl.noTransclude == undefined ? 0 : parseInt(ctrl.noTransclude);
                ctrl.editable = !!$attrs.titleEditable;
            },
            link: function link(scope, element, attrs, ctrl, transclude) {
                transclude(scope.$parent, function (clone, scope) {
                    element.find('.transclude-gecko-card-header').append(clone);
                });
            }

        };
    }

    function geckoCardFooter() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card/gecko-card-footer.html',
            transclude: true,
            scope: {},
            link: function link(scope, element, attrs, ctrl, transclude) {
                transclude(scope.$parent, function (clone, scope) {
                    element.find('.transclude-gecko-card-footer').append(clone);
                });
            }
        };
    }

    function geckoCardAlert($timeout) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card/gecko-card-alert.html',
            transclude: true,
            scope: {
                collapsable: '=',
                type: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                ctrl.getType = function (type) {
                    if (type) {
                        return 'alert-' + type.replace('alert-', '');
                    }
                    return 'alert-warning';
                };
            },
            link: function link(scope, element, attrs, ctrl, transclude) {

                $timeout(function () {
                    transclude(scope.$parent, function (clone, scope) {
                        element.find('.transclude-gecko-card-alert').append(clone);
                    });
                });

                // Enable collapsable alert messages
                if (ctrl.collapsable) {

                    // Classes
                    var _classes = function _classes() {
                        return {
                            'helptext-expanded': !ctrl.collapsed,
                            'helptext-compressed': ctrl.collapsed,
                            'helptext': true
                        };
                    };

                    // Toggle styles
                    ctrl.toggle = function (auto) {
                        if (!auto) ctrl.collapsed = !ctrl.collapsed;
                        ctrl.classes = _classes();
                        ctrl.collapseTitle = ctrl.collapsed ? 'Expand help text' : 'Hide help text';
                        ctrl.collapseIcon = ctrl.collapsed ? 'fa-expand-alt' : 'fa-compress-alt';
                    };

                    // Check message 'height'
                    var checkCollapsable = function checkCollapsable() {
                        var alertElement = $(element).find('.alert');

                        if (alertElement.height() >= 60) {
                            ctrl.collapsed = true;
                            ctrl.toggle(true);
                            ctrl.showToggle = true;
                        } else {
                            ctrl.collapsed = false;
                            ctrl.toggle(true);
                            ctrl.showToggle = false;
                        }
                        scope.$digest();
                    };

                    // Check again when window is resized
                    setTimeout(checkCollapsable, 1);
                    $(window).on('resize', function () {
                        return checkCollapsable();
                    });
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCard', geckoCard).directive('geckoCardHeader', geckoCardHeader).directive('geckoCardBody', geckoCardBody).directive('geckoCardFooter', geckoCardFooter).directive('geckoCardAlert', geckoCardAlert);
})();