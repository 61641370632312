(function () {
    'use strict';

    function LegacyEventOverviewCtrl($scope, $state, $stateParams, $filter, $geckoChannel, $geckoModal, $dialogEventChangeStatus, handleMassAction, event, attendancesQuery, attendances, statConfig, stat, data, $timeout, history) {
        var ctrl = this;
        ctrl.event = event;
        ctrl.attendances = attendances.toInstances();
        ctrl.pagination = attendances.pagination;
        ctrl.sessions = data.sessions.toArray();
        ctrl.stat = stat;
        ctrl.history = history.data;
        ctrl.pagination_act = {
            current_page: history.current_page,
            total_pages: history.last_page,
            total_items: history.total,
            per_page: history.per_page,
            last_page: history.last_page,
            param: "act_page",
            hideLimit: true
        };
        ctrl.attendeeSelects = [];

        ctrl.moreThanTenDays = ctrl.event.end_date && moment(ctrl.event.end_date, 'X').diff(moment(), 'days') < -10;

        // Stats card
        var buildStats = function buildStats(stat) {
            return [{
                title: 'Invited',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Invited').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.INVITED });
                }
            }, {
                title: 'Registered',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Registered').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.REGISTERED });
                }
            }, {
                title: 'Attended',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Attended').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.ATTENDED });
                }
            }, {
                title: 'Engaged',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Engaged').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.ENGAGED });
                }
            }, {
                title: 'Waitlisted',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Waitlisted').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.WAITLISTED });
                }
            }, {
                title: 'Cancelled',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Cancelled').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.CANCELLED });
                }
            }, {
                title: 'Removed',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Removed').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.REMOVED });
                }
            }];
        };

        ctrl.stats = buildStats(stat);

        var objectsToOptions = function objectsToOptions(labelKey) {
            return function (objects) {
                return objects.map(function (item) {
                    return { label: item[labelKey], value: item.id };
                });
            };
        };
        ctrl.filters = [{
            title: 'Status',
            type: 'checkbox',
            options: objectsToOptions('title')(Gecko.Attendance.status_titles),
            stateParam: 'status'
        }, {
            title: 'Sync Status',
            type: 'checkbox',
            options: objectsToOptions('title')(Gecko.Attendance.sync_status_styles),
            stateParam: 'sync_status'
        }];
        if (!ctrl.event.parent_id && ctrl.sessions.length) {
            ctrl.filters = ctrl.filters.concat([{
                title: 'Session',
                type: 'checkbox',
                options: objectsToOptions('title')(ctrl.sessions),
                stateParam: 'session',
                hideWhen: function hideWhen() {
                    return ctrl.event.parent_id || !ctrl.sessions.length;
                }
            }]);
        }

        ctrl.tableOptionsBtn = {
            iconRight: 'fa-cogs',
            btnTooltip: 'Actions'
        };

        ctrl.tableOptionsBtn.items = [{
            title: 'Add Contacts',
            action: function action() {
                $geckoModal.contactFind().result.then(function (conditions) {
                    return new Gecko.Contact().massAction(conditions, Gecko.Workflow.Actions.attendance(ctrl.event.id, Gecko.account.default_attendance_added_status)).catch(GeckoUI.messenger.error);
                }).then(function (response) {
                    $state.go($state.$current.name, $stateParams, { reload: true });
                    GeckoUI.messenger.success(response.message);
                });
            },
            hideWhen: function hideWhen() {
                return ctrl.event.attendances_count >= ctrl.event.maximum && !ctrl.event.auto_waitlist && ctrl.event.maximum != null;
            }
        }, {
            title: 'Email attendees',
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE) || !GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_UPDATE)) return;

                var statuses = $stateParams.status || [Gecko.Event.STATUS_REGISTERED, Gecko.Event.STATUS_INVITED, Gecko.Event.STATUS_ATTENDED, Gecko.Event.STATUS_ENGAGED];

                var conditions = [{ model: Gecko.Condition.MODEL_ATTENDANCE, event_id: ctrl.event.id, value: statuses }];

                if ($stateParams.search) {
                    conditions.push({ model: Gecko.Condition.MODEL_KEYWORD, value: $stateParams.search });
                }

                $geckoModal.messageSendModal(conditions, { event_id: ctrl.event.id });
            }
        }];

        // Table structure
        ctrl.cols = [{
            key: 'sync_status',
            renderHtml: true,
            status_title_none: true,
            expandable: false,
            icon_styles: Gecko.Attendance.sync_status_styles
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date added',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-4'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Name',
            key: 'related_contact.full_name',
            sortKey: 'contact.full_name',
            colClass: 'col-xs-4'
        }, {
            title: 'Status',
            key: 'status',
            colClass: 'col-xs-4',
            status_styles: Gecko.Attendance.status_titles
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'contact.overview',
            params: { contact_id: 'contact_id' }
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View RSVP page',
            action: function action(attendance) {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_BOOKING_SELF_SERVICE)) {
                    return;
                }
                window.open(attendance.rsvp, '_blank');
            },
            hideWhen: function hideWhen(attendance) {
                if (!Gecko.able(Gecko.ABILITY_EVENTS_BOOKING_SELF_SERVICE)) {
                    return true;
                }
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || attendance.status == Gecko.Attendance.REMOVED;
            }
        }, {
            title: 'View ticket information',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || !Gecko.able(Gecko.ABILITY_EVENTS_ATTENDEE_QRS);
            },
            action: function action(attendance) {
                $geckoModal.legacyEventTicketModal(attendance);
            }
        }, {
            title: 'Add to Session',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || ctrl.event.parent_id;
            },
            action: function action(attendance) {
                $geckoModal.eventAddContactModal(attendance.contact_id, false, ctrl.event.id).result.then(function () {
                    GeckoUI.messenger.success('Contact added to session');
                });
            }
        }, {
            title: 'Change status',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || !Gecko.able(Gecko.ABILITY_EVENTS_ATTENDEE_QRS);
            },
            action: function action(attendance) {
                $dialogEventChangeStatus(attendance);
            }
        }, {
            title: 'Remove from ' + ($state.$current.name == 'legacy-event.session' ? 'Session' : 'Event'),
            hideWhen: function hideWhen(attendance) {
                return attendance.status == Gecko.Attendance.REMOVED;
            },
            action: function action(attendance) {
                GeckoUI.dialog.confirm('Are you sure you want to remove this contact from this ' + ($state.$current.name == 'legacy-event.session' ? 'session' : 'event') + '?', function (value) {
                    if (value) {
                        // Change attendance status
                        attendance.status = Gecko.Attendance.REMOVED;
                        // Save attendance
                        attendance.save().then(function () {
                            GeckoUI.messenger.success('Contact removed from ' + ($state.$current.name == 'legacy-event.session' ? 'session' : 'event'));
                            $scope.$digest();
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }];

        ctrl.selectOptions = {
            noPersist: true
            /*UniqueKey: 'contact_id',
            selectQuery: attendancesQuery,
            initialTotalOptions: ctrl.pagination.total_items*/
        };

        ctrl.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !ctrl.attendeeSelects.length;
            }
        };

        function getContactIDsForAttendances(attendanceIds) {
            return attendanceIds.map(function (attendeeID) {
                return ctrl.attendances.find(function (attendee) {
                    return attendee.id == attendeeID;
                }).contact_id;
            });
        }

        ctrl.actionsBtn.items = [{
            title: 'Add to Session',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || ctrl.event.parent_id;
            },
            action: function action() {
                // TODO: We need contact ids for changing status here GEC-1675
                var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                $geckoModal.eventAddContactModal(contactIds, false, ctrl.event.id).result.then(function () {
                    ctrl.attendeeSelects.length = 0;
                });
            }
        }, {
            title: 'Change status',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || !Gecko.able(Gecko.ABILITY_EVENTS_ATTENDEE_QRS);
            },
            action: function action() {
                // TODO: We need contact ids for changing status here GEC-1675
                var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                $dialogEventChangeStatus(null, contactIds, ctrl.event.id, function () {
                    // Reset attendee array
                    ctrl.attendeeSelects.length = 0;
                });
            }
        }, {
            title: 'Email Attendees',
            hideWhen: function hideWhen() {
                return false;
            },
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return;

                if (!ctrl.attendeeSelects.length) return false;
                var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                $geckoModal.messageSendModal(contactIds, { event_id: ctrl.event.id }).result.then(function () {
                    // Reset attendee array
                    ctrl.attendeeSelects.length = 0;
                });
            }
        }, {
            title: 'SMS Attendees',
            hideWhen: function hideWhen() {
                return false;
            },
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return;

                if (!ctrl.attendeeSelects.length) return false;
                var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects, { event_id: ctrl.event.id, type: Gecko.Message.TYPE_SMS });
                $geckoModal.messageSendModal(contactIds, Gecko.Message.TYPE_SMS).result.then(function () {
                    // Reset attendee array
                    ctrl.attendeeSelects.length = 0;
                });
            }
        }, {
            title: 'Remove from ' + ($state.$current.name == 'legacy-event.session' ? 'Session' : 'Event'),
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to remove these contacts from this ' + ($state.$current.name == 'legacy-event.session' ? 'session' : 'event') + '?', function (value) {
                    if (value) {
                        var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                        new Gecko.Contact().massAction({ model: 'contact_ids', contact_ids: contactIds }, Gecko.Workflow.Actions.attendance(ctrl.event.id, Gecko.Attendance.REMOVED)).then(function (ma_data) {
                            // Handle mass action
                            handleMassAction(data, $state.$current.name);
                            // Reset attendee array
                            ctrl.attendeeSelects.length = 0;
                        }).catch(GeckoUI.messenger.error);
                    }
                });
            }
        }];
    }

    angular.module('GeckoEngage').controller('LegacyEventOverviewCtrl', LegacyEventOverviewCtrl);
})();