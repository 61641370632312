(function () {

    'use strict';

    function VerifiedSendersRoute($stateProvider) {
        $stateProvider.state('verifiedsenders', {
            url: '/settings/verified',
            templateUrl: "/modules/senders/views/verifiedsenders.ff2623c6.html",
            controller: 'SettingsSendersCtrl',
            controllerAs: 'ctrl',
            page_title: 'Verified Senders',
            requires: [Gecko.ABILITY_SENDERS_VIEW]
        }).state('verifiedsenders.senders', {
            url: '/senders?{page}&{search}&{order}&{sort}',
            templateUrl: '/modules/senders/views/senders_index.27325254.html',
            controller: 'VerifiedSendersCtrl',
            controllerAs: 'ctrl',
            page_title: 'Senders',
            requires: [Gecko.ABILITY_SENDERS_VIEW],
            redirectKey: 'module_senders',
            resolve: {
                senders: function senders($stateParams) {
                    var senders = new Gecko.Sender();
                    if ($stateParams.search) senders.where('keyword', $stateParams.search);
                    if ($stateParams.order) senders.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    return senders.where('type', Gecko.Sender.EMAIL).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ sender: ['name', 'email', 'verified', 'verified_domain_id'] }).get();
                }
            }
        }).state('verifiedsenders.domains', {
            url: '/domains?{page}&{search}&{order}&{sort}',
            templateUrl: '/modules/senders/views/domains_index.f567b756.html',
            controller: 'VerifiedDomainsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Domains',
            requires: [Gecko.ABILITY_SENDERS_VIEW],
            redirectKey: 'module_domains',
            resolve: {
                verified_domains: function verified_domains($stateParams) {
                    var domains = new Gecko.VerifiedDomain();
                    if ($stateParams.search) domains.where('keyword', $stateParams.search);
                    if ($stateParams.order) domains.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    return domains.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ sender: ['domain_name', 'verified'] }).get();
                }
            }
        }).state('verifiedsenders.sender', {
            url: '/senders/{sender_id}',
            templateUrl: '/modules/senders/views/sender_edit.63efc004.html',
            controller: 'VerifiedSenderCtrl',
            controllerAs: 'ctrl',
            page_title: 'Sender',
            redirectKey: 'module_sender',
            requires: [function ($stateParams) {
                if ($stateParams.sender_id === 'new') return Gecko.ABILITY_SENDERS_CREATE;
                return Gecko.ABILITY_SENDERS_VIEW;
            }],
            resolve: {
                sender: function sender($stateParams) {
                    if ($stateParams.sender_id != 'new') {
                        return new Gecko.Sender().get($stateParams.sender_id);
                    } else {
                        return new Gecko.Sender().fill({ type: Gecko.Sender.EMAIL });
                    }
                }
            }
        }).state('verifiedsenders.domain', {
            url: '/domains/{verified_domain_id}',
            templateUrl: '/modules/senders/views/domain_edit.9bdecd4e.html',
            controller: 'VerifiedDomainCtrl',
            controllerAs: 'ctrl',
            page_title: 'Domain',
            redirectKey: 'module_domain',
            requires: [function ($stateParams) {
                if ($stateParams.verified_domain_id === 'new') return Gecko.ABILITY_SENDERS_CREATE;
                return Gecko.ABILITY_SENDERS_VIEW;
            }],
            resolve: {
                verified_domain: function verified_domain($stateParams) {
                    if ($stateParams.verified_domain_id != 'new') {
                        return new Gecko.VerifiedDomain().get($stateParams.verified_domain_id);
                    } else {
                        return new Gecko.VerifiedDomain();
                    }
                }
            }
        }).state('verifiedsenders.verification_callback', {
            url: '/verify_sender',
            template: '\n                    <gecko-card>\n                        <gecko-card-body padded="true">\n                            <div class="alert alert-warning" style="margin:0;">\n                                Please wait while your sender/domain is verified.\n                            </div>\n                        </gecko-card-body>\n                    </gecko-card>\n                ',
            controller: 'VerifiedSenderVerifyCtrl',
            controllerAs: 'ctrl',
            page_title: 'Please wait while your sender/domain is verified.'
        });
    }

    angular.module('GeckoEngage.routes').config(VerifiedSendersRoute);
})();