(function () {
    'use strict';

    function VoipTestRoute($stateProvider) {
        $stateProvider.state('voiptest', {
            url: "/settings/voip_test",
            templateUrl: "/modules/voip_test/views/view.5df0e7ae.html",
            controller: 'VoipTestCtrl',
            controllerAs: 'ctrl',
            page_title: 'VOIP Test',
            requires: [Gecko.ABILITY_CALLS_CREATE]
        });
    }

    angular.module('GeckoEngage.routes').config(VoipTestRoute);
})();