;(function () {
    'use strict';

    var TEMPLATE_USAGE_TYPE_FORM = 'workflow_form';
    var TEMPLATE_USAGE_TYPE_EVENT = 'workflow_event';
    var TEMPLATE_USAGE_TYPE_CAMPAIGN_EMAIL = 'campaign_email';
    var TEMPLATE_USAGE_TYPE_CAMPAIGN_SMS = 'campaign_sms';
    var TEMPLATE_USAGE_TYPE_CAMPAIGN_EMAIL_WORKFLOW = 'workflow_campaign_email';
    var TEMPLATE_USAGE_TYPE_CAMPAIGN_SMS_WORKFLOW = 'workflow_campaign_sms';
    var TEMPLATE_USAGE_TYPE_CAMPAIGN_CALL_WORKFLOW = 'workflow_campaign_call';
    var TEMPLATE_USAGE_TYPE_SCRIPT_CALL_WORKFLOW = 'workflow_script';

    function TemplateUsageCtrl($state, $stateParams, usage) {
        var ctrl = this;

        ctrl.usage = usage.data;
        ctrl.pagination = Object.assign({}, usage.pagination, { hideLimit: true });

        ctrl.rowAction = {
            action: function action(usage) {
                switch (usage.type) {
                    case TEMPLATE_USAGE_TYPE_FORM:
                        return $state.go('form.workflows', { form_id: usage.id });
                        break;
                    case TEMPLATE_USAGE_TYPE_EVENT:
                        return $state.go('event.workflows', { event_id: usage.id });
                        break;
                    case TEMPLATE_USAGE_TYPE_CAMPAIGN_EMAIL:
                    case TEMPLATE_USAGE_TYPE_CAMPAIGN_SMS:
                        return $state.go('broadcast.overview', { broadcast_id: usage.id });
                        break;
                    case TEMPLATE_USAGE_TYPE_CAMPAIGN_EMAIL_WORKFLOW:
                    case TEMPLATE_USAGE_TYPE_CAMPAIGN_SMS_WORKFLOW:
                        return $state.go('broadcast.workflows', { broadcast_id: usage.id });
                        break;
                    case TEMPLATE_USAGE_TYPE_CAMPAIGN_CALL_WORKFLOW:
                        return $state.go('campaignsedit.workflows', { campaign_id: usage.id });
                        break;
                    case TEMPLATE_USAGE_TYPE_SCRIPT_CALL_WORKFLOW:
                        return $state.go('script.workflows', { form_id: usage.id });
                        break;
                }
                return;
            }
        };

        ctrl.renderType = function (type) {
            switch (type) {
                case TEMPLATE_USAGE_TYPE_FORM:
                    return "Form Workflow";
                    break;
                case TEMPLATE_USAGE_TYPE_EVENT:
                    return "Event Workflow";
                    break;
                case TEMPLATE_USAGE_TYPE_CAMPAIGN_CALL_WORKFLOW:
                    return "Call Campaign";
                    break;
                case TEMPLATE_USAGE_TYPE_CAMPAIGN_EMAIL:
                    return "Email Broadcast";
                    break;
                case TEMPLATE_USAGE_TYPE_CAMPAIGN_SMS:
                    return "SMS Broadcast";
                    break;
                case TEMPLATE_USAGE_TYPE_CAMPAIGN_EMAIL_WORKFLOW:
                    return "Email Broadcast Workflow";
                    break;
                case TEMPLATE_USAGE_TYPE_CAMPAIGN_SMS_WORKFLOW:
                    return "SMS Broadcast Workflow";
                    break;
                case TEMPLATE_USAGE_TYPE_SCRIPT_CALL_WORKFLOW:
                    return "Call Script Workflow";
                    break;
            }
            return type;
        };

        ctrl.cols = [{
            title: 'Name',
            key: 'name'
            //sortKey: 'name',
        }, {
            title: 'Type',
            key: 'type',
            //sortKey: 'type',
            render: ctrl.renderType
        }];
    }

    angular.module('GeckoEngage').controller('TemplateUsageCtrl', TemplateUsageCtrl);
})();