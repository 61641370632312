(function () {

    function SelectorModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('selectorModal', function (_config) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/selector/selector-modal.html',
                controller: 'SelectorModalCtrl',
                resolve: {
                    config: function config() {
                        return _config;
                    }
                }
            };
        });
    }

    /*
     * The key to setting up this modal is the config object
     * It's basic structure is as follows:
     *  {
     *      title:  the title of the modal,
     *      model:  the model name (lowercase) of the type of data you want to get (e.g. `form`)
     *      fieldLabel: the label that is shown above the dropdown, should this not be defined it will
     *                  default to a capitalized version of the model name
     *      optionsKey:    generally this will be 'id' but it can be changed to the liked of 'uuid'
     *               - it is the key on the object that is selected when the user selects an object out of the list
     *      optionsLabel:  this is the property on the list that is displayed to the user in the dropdown
     *
     *      (The following are optional configurations:)
     *      wheres: this can be either an object or an array of objects; the structure for both is:
     *                  {
     *                      key:    the field that you want to check against
     *                      value:  the value you want to match against
     *                  }
     *      postFunc: this is a function that will be called after the getter for the async dropdown to alter the options returned
     *  }
     *
     * More details can be found @ https://geckolabs.atlassian.net/wiki/display/DOCS/Selector+Modal
     */

    function SelectorModalCtrl($scope, $modalInstance, asyncOptions, $filter, config) {
        var ctrl = this;
        ctrl.values = {};
        ctrl.modalTitle = config.title;

        var capitalizedModel = $filter('capitalize')(config.model);

        if (!config.fieldLabel) config.fieldLabel = capitalizedModel;

        var rfieldsObj = {};
        rfieldsObj[config.model] = (config.rfields || []).concat([config.optionsKey, config.optionsLabel]);

        var getter = new Gecko[capitalizedModel]().orderBy(config.optionsLabel);

        if (config.wheres) {
            if (Array.isArray(config.wheres)) {
                angular.forEach(config.wheres, function (where) {
                    getter.where(where.key, where.value);
                });
            } else {
                getter.where(config.wheres.key, config.wheres.value);
            }
        }
        getter.rfields(rfieldsObj);

        if (!config.postFunc || typeof config.postFunc !== "function") config.postFunc = angular.noop;
        // Field list
        ctrl.fields = [{
            id: 'value',
            label: config.fieldLabel,
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'none',
            options: [],
            getOptions: asyncOptions.create(getter.orderBy(config.optionsLabel), config.postFunc),
            optionsLabelKey: config.optionsLabel,
            optionsKey: config.optionsKey,
            description: config.description
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Select',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            action: function action() {
                if (ctrl.value) {
                    $modalInstance.close(ctrl.value);
                } else {
                    $modalInstance.dismiss();
                }
            }
        }];
    }

    angular.module('GeckoEngage').config(SelectorModals).controller('SelectorModalCtrl', SelectorModalCtrl);
})();