(function () {
    'use strict';

    function geckoStatWidgets() {
        return {
            restrict: 'EA',
            template: '\n                <div class="row">\n\n                    <div ng-repeat="widget in ::(ctrl.widgets) track by $index">\n\n                        <gecko-stat-widget\n                            ng-class="::(ctrl.renderWidgetClasses(widget))"\n                            widget="::(widget)">\n                        </gecko-stat-widget>\n\n                    </div>\n\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                widgets: '='
            },
            controller: function controller() {
                var ctrl = this;

                // Only allow these types (for now)
                ctrl.widgets = (ctrl.widgets || []).filter(function (widget) {
                    return [Gecko.Widget.TYPE_LINE, Gecko.Widget.TYPE_BAR, Gecko.Widget.TYPE_PIE, Gecko.Widget.TYPE_TABLE, Gecko.Widget.TYPE_INTEGER, Gecko.Widget.TYPE_GEO].indexOf(widget.type) !== -1;
                });

                ctrl.renderWidgetClasses = function (widget) {
                    return {
                        'col-sm-6': [Gecko.Widget.TYPE_PIE, Gecko.Widget.TYPE_INTEGER, Gecko.Widget.TYPE_TABLE].indexOf(widget.type) !== -1,
                        'col-sm-12': [Gecko.Widget.TYPE_PIE, Gecko.Widget.TYPE_INTEGER, Gecko.Widget.TYPE_TABLE].indexOf(widget.type) === -1
                    };
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoStatWidgets', geckoStatWidgets);
})();