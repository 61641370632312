(function () {

    function ContactAddModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('contactAddModal', function (_target, _obj) {
            return {
                controllerAs: 'ctrl',
                size: 'lg',
                template: '\n                        <gecko-modal\n                            class="contact-add-modal"\n                            data-title="{{ctrl.title}}"\n                            footer-btns="ctrl.footerBtns">\n\n                                <gecko-fields fields="ctrl.topFields" values="ctrl"></gecko-fields>\n\n                                <gecko-add-modal-filter\n                                    ng-show="ctrl.searchMode == \'filter\'"\n                                    conditions="ctrl.conditions"\n                                    requirement="ctrl.requirement"\n                                    contact-fields="ctrl.contactFields"\n                                    total-contacts="ctrl.totalContacts"\n                                    total-is-refreshing="ctrl.totalIsRefreshing">\n                                </gecko-add-modal-filter>\n\n                                <gecko-add-modal-search\n                                    ng-show="ctrl.searchMode == \'quick_search\'"\n                                    total-contacts="ctrl.totalContacts"\n                                    total-is-refreshing="ctrl.totalIsRefreshing"\n                                    selected-contacts="ctrl.selectedContacts">\n                                </gecko-add-modal-search>\n\n                                <gecko-fields fields="ctrl.bottomFields" values="ctrl"></gecko-fields>\n\n                        </gecko-modal>\n\n                        <div ng-if="ctrl.totalIsRefreshing" class="contact-add-modal-loading">\n                            <i class="fa fa-sync fa-spin"></i>\n                        </div>\n                    ',
                controller: 'ContactAddModalCtrl',
                resolve: {
                    target: function target() {
                        return _target;
                    },
                    obj: function obj() {
                        return _obj;
                    },
                    contactFields: function contactFields(geckoDataService) {
                        return geckoDataService.fetch(['contact_fields']).then(function (data) {
                            return data.contact_fields;
                        });
                    }
                }
            };
        });
    }

    function ContactAddModalCtrl($modalInstance, target, obj, contactFields) {
        var SEARCH_TYPE_QUICK_SEARCH = 'quick_search';
        var SEARCH_TYPE_FILTER = 'filter';

        var ctrl = this;
        ctrl.target = target || {};
        ctrl.obj = obj || {};
        ctrl.title = ctrl.obj.title || 'Add Contacts';
        ctrl.contactFields = contactFields;

        // Default to quick search
        ctrl.searchMode = SEARCH_TYPE_QUICK_SEARCH;

        // Get target type
        var TARGET_TYPE_EVENT = 'event';
        ctrl.isType = {
            event: ctrl.target.object_key === TARGET_TYPE_EVENT
        };

        ctrl.totalContacts = 0;
        ctrl.totalIsRefreshing = false;
        ctrl.fetchingTotal = false;
        ctrl.selectedContacts = [];
        ctrl.conditions = [];
        ctrl.requirement = 'ANY';

        ctrl.getTotal = function (conditions, requirement) {
            return new Gecko.Contact().searchCount(conditions, requirement);
        };

        ctrl.addByFilter = function () {
            var conditions = {
                conditions: ctrl.conditions,
                requirement: ctrl.requirement
            };

            if (ctrl.isType.event) {
                var action = Gecko.Workflow.Actions.attendance(ctrl.target.id, ctrl.status);
            } else {
                var action = {};
            }

            return ctrl.applyMassAction(conditions, action);
        };

        ctrl.addBySearch = function () {
            var conditions = [{ model: 'contact_ids', contact_ids: ctrl.selectedContacts }];

            if (ctrl.isType.event) {
                var action = Gecko.Workflow.Actions.attendance(ctrl.target.id, ctrl.status);
            } else {
                var action = {};
            }

            return ctrl.applyMassAction(conditions, action);
        };

        ctrl.applyMassAction = function (conditions, action) {
            return new Gecko.Contact().massAction(conditions, action).then($modalInstance.close).catch(function (err) {
                GeckoUI.messenger.error(err);
                $modalInstance.close();
            });
        };

        ctrl.add = function () {
            return ctrl.searchMode === SEARCH_TYPE_FILTER ? ctrl.addByFilter() : ctrl.addBySearch();
        };

        ctrl.topFields = [{
            id: 'searchMode',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            colClass: 'none',
            options: [{
                value: SEARCH_TYPE_QUICK_SEARCH,
                icon: 'fa-search',
                title: 'Quick Search'
            }, {
                value: SEARCH_TYPE_FILTER,
                icon: 'fa-sliders-h',
                title: 'Filter'
            }]
        }];

        ctrl.bottomFields = [];

        if (ctrl.isType.event) {
            // Properties
            ctrl.status = Gecko.Attendance.REGISTERED;
            // Fields
            ctrl.bottomFields.push({
                id: 'status',
                label: 'Status',
                colClass: 'col-md-12',
                type: Gecko.Field.TYPE_SELECT,
                options: Gecko.Attendance.status_titles,
                optionsKey: 'id',
                optionsLabelKey: 'title',
                noBlank: true
            });
        }

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Add Contacts',
            preset: 'add',
            btnClass: 'btn-primary',
            hideWhen: function hideWhen() {
                return ctrl.searchMode === SEARCH_TYPE_FILTER ? !ctrl.totalContacts : !ctrl.selectedContacts.length;
            },
            action: function action() {
                // Stop when fetching search total
                if (ctrl.fetchingTotal) return;

                ctrl.fetchingTotal = true;

                if (ctrl.searchMode === SEARCH_TYPE_FILTER) {
                    var getTotal = ctrl.getTotal(ctrl.conditions, ctrl.requirement);
                } else {
                    var getTotal = ctrl.getTotal([{ model: 'contact_ids', contact_ids: ctrl.selectedContacts }]);
                }

                getTotal.then(function (total) {
                    ctrl.fetchingTotal = false;

                    // Confirm alert
                    if (ctrl.isType.event) {
                        var confirmMessage = '<strong>' + total + '</strong> contacts will be added to <strong>' + ctrl.target.title + '</strong> with the status of <strong>' + Gecko.Attendance.status_titles.find(function (s) {
                            return s.id === ctrl.status;
                        }).title + '</strong>';
                    } else {
                        var confirmMessage = '<strong>' + total + '</strong> contacts will be added .';
                    }
                    GeckoUI.dialog.confirm(confirmMessage, function (ok) {
                        if (ok) ctrl.add();
                    });
                }).catch(function () {
                    return ctrl.fetchingTotal = false;
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(ContactAddModal).controller('ContactAddModalCtrl', ContactAddModalCtrl);
})();