(function () {
    'use strict';

    function geckoStore() {

        var memStorage = Object.create(null);

        var userStoreKeys = ['hide-progress', 'pages-history'];

        var sessionStoreKeys = ['gecko_geo', 'gecko_contact_search'];

        var accountStoreKeys = [];

        var memStoreKeys = [];

        var keysToStore = Object.create(null);

        userStoreKeys.forEach(function (key) {
            keysToStore[key] = Gecko.user;
        });

        accountStoreKeys.forEach(function (key) {
            keysToStore[key] = Gecko.account;
        });

        sessionStoreKeys.forEach(function (key) {
            keysToStore[key] = sessionStorage;
        });

        memStoreKeys.forEach(function (key) {
            keysToStore[key] = memStorage;
        });

        var mainStore;

        // Initialise main storage
        if (!mainStore) {
            if (testStore('localStorage')) {
                mainStore = localStorage;
            } else if (testStore('sessionStorage')) {
                mainStore = sessionStorage;
            }
            // If we can't use local or session storage then default to an in memory object.
            else {
                    mainStore = memStorage;
                }
        }

        function testStore(type) {
            try {
                var storage = window[type],
                    x = '__storage_test__';
                storage.setItem(x, x);
                storage.removeItem(x);
                return true;
            } catch (e) {
                return false;
            }
        }

        function getStore(key) {

            var store = keysToStore[key];
            if (store) return store;

            return mainStore;
        }

        return {
            set: function set(key, value) {

                if (typeof getStore(key).setConfig === 'function') getStore(key).setConfig(key, value);else getStore(key)[key] = JSON.stringify(value);
            },

            get: function get(key) {

                var store = getStore(key);

                if (typeof store.getConfig === 'function') return store.getConfig(key);else if (store[key]) return JSON.parse(store[key]);
            },

            remove: function remove(key) {
                var store = getStore(key);
                if (store.removeItem && typeof store.removeItem === 'function') {
                    store.removeItem(key);
                } else {
                    delete store[key];
                }
            }
        };
    }

    angular.module('GeckoEngage').factory('geckoStore', geckoStore);
})();