(function () {
    'use strict';

    function EventsHostsCtrl($state, hosts) {
        var ctrl = this;
        ctrl.hosts = hosts.toArray();
        ctrl.pagination = hosts.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'name',
            sortKey: 'name',
            colClass: 'col-xs-6'
        }, {
            title: 'Email',
            key: 'email',
            sortKey: 'email',
            colClass: 'col-xs-6'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'events.host',
            params: { host_id: 'id' }
        };

        // Table row option buttons
        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_HOSTS_DELETE);
            }
        };
        ctrl.rowOptionsBtn.items = [{
            title: 'Remove host',
            action: function action(host) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this host?', function (value) {
                    if (value) {
                        new Gecko.Host().get(host.id).then(function (host) {
                            host.remove().then(function () {
                                GeckoUI.messenger.success('Host has been deleted');
                                $state.reload();
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Hosts',
            active: true
        }];
    }

    function EventsHostCtrl($state, $stateParams, unsavedAlert, host) {
        var _this = this;

        var ctrl = this;
        ctrl.host = host;

        ctrl.save = function () {
            _this.host.save().then(function (h) {
                GeckoUI.messenger.success('Host has been saved');
                unsavedAlert.clear();
                if ($stateParams.host_id == 'new') {
                    $state.go('events.host', {
                        host_id: h.id
                    });
                }
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Host Save Success', {
                    'host id': h.id,
                    'host title': h.name
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Host Save Error', {
                    'host id': ctrl.host.id || 'New',
                    'host name': ctrl.host.name,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: ctrl.save.bind(ctrl)
        }, {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.host_id == 'new';
            },
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_HOSTS_DELETE)) {
                    return false;
                }
                GeckoUI.dialog.confirm('Are you sure you want to delete this host?', function (value) {
                    if (value) {
                        unsavedAlert.clear();
                        ctrl.host.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Host has been deleted');
                            $state.go('events.hosts');
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Hosts',
            click: function click() {
                $state.go('events.hosts');
            }
        }, {
            label: ctrl.host.name ? ctrl.host.name : 'Add Host',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('EventsHostsCtrl', EventsHostsCtrl).controller('EventsHostCtrl', EventsHostCtrl);
})();