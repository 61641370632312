(function () {
    'use strict';

    function HostsRoute($stateProvider) {
        $stateProvider.state('events.hosts', {
            url: "/hosts?{page}&{sort}&{order}&{search}",
            templateUrl: "/modules/hosts/views/index.4a1d0deb.html",
            controller: 'EventsHostsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Hosts',
            resolve: {
                hosts: function hosts($stateParams) {
                    var hosts = new Gecko.Host();
                    if ($stateParams.search) hosts.where('host_search', $stateParams.search);
                    if ($stateParams.order) {
                        hosts.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    } else {
                        hosts.orderBy('name');
                    }
                    return hosts.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ host: ['name', 'email'] }).get();
                }
            }
        }).state('events.host', {
            url: "/hosts/{host_id}",
            templateUrl: "/modules/hosts/views/edit.4765b9e0.html",
            controller: 'EventsHostCtrl',
            controllerAs: 'ctrl',
            page_title: 'Host',
            requires: [function ($stateParams) {
                return $stateParams.host_id === 'new' ? Gecko.ABILITY_HOSTS_CREATE : Gecko.ABILITY_HOSTS_UPDATE;
            }],
            resolve: {
                host: function host($stateParams) {
                    if ($stateParams.host_id != 'new') {
                        return new Gecko.Host().get($stateParams.host_id);
                    } else {
                        return new Gecko.Host();
                    }
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(HostsRoute);
})();