(function () {

    function CallModals($geckoModalProvider) {
        $geckoModalProvider.modalAdd('callInfoModal', function (_call) {
            return {
                controllerAs: 'ctrl',
                size: 'lg',
                templateUrl: '/components/_modals/call/call_info_modal.html',
                controller: 'callInfoModalCtrl',
                resolve: {
                    call: function call() {
                        return new Gecko.Call().include(['contact', 'user', 'sender', 'outcome', 'campaign', 'number', 'all_campaigns']).rfields({
                            'contact': ['full_name', 'email'],
                            'sender': ['name', 'tel'],
                            'outcome': ['name'],
                            'campaign': ['title'],
                            'user': ['full_name', 'email'],
                            'number': ['cleaned']
                        }).get(_call.id);
                    }
                }
            };
        });
    }

    function callInfoModalCtrl($scope, Gecko, $sceDelegate, $filter, call) {
        $scope.Gecko = Gecko;
        var ctrl = this;
        ctrl.call = call;
        ctrl.startedAt = call.started_at ? $filter('formatDate')(call.started_at, Gecko.dateFormat.longTime) : 'Abandoned';
        ctrl.theirNumber = call.related_number && call.related_number.cleaned ? call.related_number.cleaned : 'Unknown Number';
        ctrl.ourNumber = call.related_sender && call.related_sender.name ? call.related_sender.name : null;
        ctrl.showAudio = !!call.recording_url_mp3;

        ctrl.getTitle = function () {
            if (call.type === Gecko.Call.TYPE_OUTBOUND && ctrl.ourNumber) {
                return ctrl.ourNumber + ' called ' + ctrl.theirNumber + ' - ' + ctrl.startedAt;
            }
            if (call.type === Gecko.Call.TYPE_OUTBOUND && !ctrl.ourNumber) {
                return 'Called ' + ctrl.theirNumber + ' - ' + ctrl.startedAt;
            }
            if (call.type === Gecko.Call.TYPE_INBOUND && ctrl.ourNumber) {
                return ctrl.theirNumber + ' called ' + ctrl.ourNumber + ' - ' + ctrl.startedAt;
            }
            if (call.type === Gecko.Call.TYPE_INBOUND && !ctrl.ourNumber) {
                return ctrl.thierNumber + ' called - ' + ctrl.startedAt;
            }
            return 'Call Info - ' + ctrl.startedAt;
        };
        ctrl.getUrl = function () {
            return $sceDelegate.trustAs('resourceUrl', call.recording_url_mp3);
        };

        ctrl.callData = {
            started_at: $filter('formatDate')(call.started_at),
            ended_at: $filter('formatDate')(call.ended_at),
            call_time: $filter('secondsAsTime')(call.call_time),
            contact: GeckoUI.getNestedObjectValue(call, 'related_contact.full_name') || 'Unknown',
            contact_email: GeckoUI.getNestedObjectValue(call, 'related_contact.email') || 'Unknown',
            number: GeckoUI.getNestedObjectValue(call, 'related_number.cleaned'),
            campaign: GeckoUI.getNestedObjectValue(call, 'related_campaign.title'),
            outcome: GeckoUI.getNestedObjectValue(call, 'related_outcome.name'),
            user: GeckoUI.getNestedObjectValue(call, 'related_user.full_name'),
            email: GeckoUI.getNestedObjectValue(call, 'related_user.email'),
            sender: call.related_sender ? GeckoUI.getNestedObjectValue(call, 'related_sender.name') + ' (' + GeckoUI.getNestedObjectValue(call, 'related_sender.tel') + ')' : '',
            status: GeckoUI.gobk(Gecko.Call.statuses, 'id', call.status).title
        };
    }

    angular.module('GeckoEngage').config(CallModals).controller('callInfoModalCtrl', callInfoModalCtrl);
})();