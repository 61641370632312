var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
    'use strict';

    function tieredSelect($q) {

        return {
            restrict: 'E',
            templateUrl: '/components/tiered-select/tiered-select.html',

            scope: {
                field: '='
            },

            controller: angular.noop,
            controllerAs: 'ctrl',
            bindToController: true,

            require: ['tieredSelect', 'ngModel'],
            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var modelCtrl = ctrls[1];
                if (!modelCtrl) return;

                if (!ctrl.field.options) {
                    ctrl.field.options = [[], []];
                }

                ctrl._values = [];
                ctrl.values = [];

                modelCtrl.$render = function () {

                    if (ctrl.field.optionsInit) {
                        $q.when(ctrl.field.optionsInit(modelCtrl.$modelValue)).then(function (values) {
                            values.forEach(function (v, i) {
                                // When we change the model we need to do this refresh of the options.
                                // This may be causing a double refresh on directive compile though. :(
                                var select = ctrl.selectCtrls[i] || { search: '' };
                                ctrl.refresh(ctrl.field.getOptions[i], select, i);

                                var key = v[ctrl.field.optionsKey];
                                ctrl._values[i] = (typeof key === 'undefined' ? 'undefined' : _typeof(key)) !== undefined ? key : v;

                                // Add the option to the list of options if it is not already there.
                                if (ctrl.field.options[i] instanceof Array && ctrl.field.options[i].length && !GeckoUI.getObjectByKey(ctrl.field.options[i], ctrl.field.optionsKey, key)) {

                                    ctrl.field.options[i].unshift(v);
                                } else {
                                    ctrl.field.options[i] = [v];
                                }
                            });

                            // If field has allValues property, set it
                            if (ctrl.field.allValues) ctrl.field.allValues = ctrl._values;
                        });
                    }
                };

                // Assign new array of bound getOptions functions.
                ctrl.field.getOptions = ctrl.field.getOptions.map(function (f) {
                    // Bind the getOptions functions to the field object;
                    return f.bind(ctrl.field);
                });

                ctrl.field.getOptions.forEach(function (f, i, array) {

                    Object.defineProperty(ctrl.values, i, {
                        get: function get() {
                            return ctrl._values[i];
                        },
                        set: function set(value) {
                            // Reset child options
                            if (ctrl.field.options[i + 1] && ctrl.field.options[i + 1].length) {
                                ctrl.field.options[i + 1] = [];
                            }

                            // If field has allValues property, set it
                            if (ctrl.field.allValues) ctrl.field.allValues = ctrl._values;

                            ctrl._values[i] = value;

                            var next = i + 1;
                            if (next < ctrl.field.getOptions.length) {
                                var select = ctrl.selectCtrls[next] || { search: '' };
                                ctrl.refresh(array[next], select, next);
                                ctrl._values.length = next; // Truncate the values above this one.
                            }
                            if (value) {
                                modelCtrl.$setViewValue(value);
                            } else if (i > 0) {
                                modelCtrl.$setViewValue(ctrl._values[i - 1]);
                            } else {
                                modelCtrl.$setViewValue(null);
                            }
                        }
                    });
                });

                ctrl.selectCtrls = [];

                ctrl.refresh = function (getter, select, i) {
                    if (typeof getter !== 'function') return;
                    ctrl.selectCtrls[i] = select;
                    var promise = getter(select, scope);
                    var filter = ctrl.field.optionsFilter instanceof Array ? ctrl.field.optionsFilter[i] : ctrl.field.optionsFilter;
                    if (promise && promise.then) {
                        promise.then(function () {
                            if (filter) ctrl.field.options[i] = ctrl.field.options[i].filter(filter);
                        });
                    }
                };

                ctrl.parentHasValue = function (index) {
                    if (index > 0) {
                        return ctrl.values[index - 1] != null;
                    }
                    return true;
                };

                ctrl.noBlank = function (index) {
                    if (ctrl.field.noBlank instanceof Array) {
                        return ctrl.field.noBlank[index];
                    }
                    return ctrl.field.noBlank;
                };

                ctrl.levelIsDisabaled = function (index) {
                    if (ctrl.field.disabledLevels && index < ctrl.field.disabledLevels) {
                        return true;
                    }
                    return false;
                };

                var templateBase = '/components/tiered-select/';

                ctrl.getTemplateUrl = function (type) {
                    return templateBase + 'select-templates/' + ctrl.field[type] + '.html';
                };
            }

        };
    }

    angular.module('GeckoEngage').directive('tieredSelect', tieredSelect);
})();