(function () {

    function ConsentReasonModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('consentReasonModal', function (_reason) {
            return {
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal \n                            data-title="Add a Consent Reason"\n                            fields="ctrl.fields"\n                            values="ctrl.reason"\n                            footer-btns="ctrl.footerBtns">\n                        </gecko-modal>\n                    ',
                controller: 'ConsentReasonModalCtrl',
                resolve: {
                    reason: function reason() {
                        return _reason;
                    }
                }
            };
        });
    }

    function ConsentReasonModalCtrl($modalInstance, reason) {
        var ctrl = this;
        if (reason) {
            ctrl.reason = reason;
        } else {
            ctrl.reason = new Gecko.Consent().create({});
        }

        ctrl.buildGroupValue = function (set) {
            if (set) {
                ctrl.reason.group_general = ctrl.reason.group.indexOf(Gecko.Consent.GROUP_GENERAL) !== -1 ? 1 : 0;
                ctrl.reason.group_call = ctrl.reason.group.indexOf(Gecko.Consent.GROUP_CALL) !== -1 ? 1 : 0;
                ctrl.reason.group_email = ctrl.reason.group.indexOf(Gecko.Consent.GROUP_EMAIL) !== -1 ? 1 : 0;
                ctrl.reason.group_sms = ctrl.reason.group.indexOf(Gecko.Consent.GROUP_SMS) !== -1 ? 1 : 0;
            } else {
                ctrl.reason.group = [];
                if (ctrl.reason.group_general) ctrl.reason.group.push(Gecko.Consent.GROUP_GENERAL);
                if (ctrl.reason.group_call) ctrl.reason.group.push(Gecko.Consent.GROUP_CALL);
                if (ctrl.reason.group_email) ctrl.reason.group.push(Gecko.Consent.GROUP_EMAIL);
                if (ctrl.reason.group_sms) ctrl.reason.group.push(Gecko.Consent.GROUP_SMS);
            }
        };
        // Set 'group' array value
        ctrl.buildGroupValue();

        ctrl.fields = [{
            id: 'title',
            label: 'Name',
            colClass: 'none',
            help_text: 'The name of the consent reason will be visible to the data subject on the consent field and in the contact portal.'
        }, {
            id: 'group',
            label: 'Group',
            help_text: 'Assign a consent reason to a group. You will be able to use consent reasons of the email/SMS type only within email/SMS templates. Consent reasons in the call group can only be used on call campaigns. General consent fields can be shown and used across any templates or campaigns.',
            type: Gecko.Field.TYPE_MULTI,
            colClass: 'none',
            options: Gecko.Consent.group_titles,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            noBlank: true,
            disabledWhen: function disabledWhen() {
                return ctrl.reason.system;
            }
        }, {
            id: 'description',
            label: 'Description',
            help_text: 'The description of the consent reason will be shown on the consent field and within the contact portal.',
            type: Gecko.Field.TYPE_TEXTAREA,
            colClass: 'none'
        }, {
            id: 'export_given',
            label: 'Export Given Value',
            colClass: 'none'
        }, {
            id: 'export_withheld',
            label: 'Export No Given Value',
            colClass: 'none'
        }];

        ctrl.footerBtns = [{
            title: (ctrl.reason.id ? 'Save' : 'Add') + ' Reason',
            preset: ctrl.reason.id ? 'save' : 'add',
            action: function action() {
                // Set 'group' flags on reason
                ctrl.buildGroupValue(true);

                ctrl.reason.save().then(function (reason) {
                    // Save content reason
                    GeckoUI.messenger.success('Content reason saved');
                    // Close modal
                    $modalInstance.close(ctrl.reason);
                }).catch(function (err) {
                    return GeckoUI.messenger.error(err.errors);
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(ConsentReasonModal).controller('ConsentReasonModalCtrl', ConsentReasonModalCtrl);
})();