(function () {

    function FormFieldSettingsModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('formFieldSettingsModal', function (_field, _form) {
            return {
                size: 'lg',
                controllerAs: 'ctrl',
                templateUrl: '/modules/form/views/_modals/field.11d5544f.html',
                controller: 'formFieldSettingsModalCtrl',
                resolve: {
                    form: function form() {
                        return _form;
                    },
                    field: function field() {
                        return _field;
                    },
                    selectedData: function selectedData(asyncOptions) {
                        var asyncReqs = {};

                        // Event field - event_category_id value
                        if ((_field.values || {}).event_category_id) asyncReqs['event_category_id'] = { model: 'Category', id: (_field.values || {}).event_category_id, fields: { category: ['title'] } };
                        // Event field - event_id value
                        if ((_field.values || {}).event_id) asyncReqs['event_id'] = { model: 'Event', id: (_field.values || {}).event_id, fields: { event: ['nice_title'] } };
                        // Organisation field - organisation_type_id value
                        if (_field.organisation_type_id) asyncReqs['organisation_type_id'] = { model: 'OrganisationType', id: _field.organisation_type_id, fields: { organisationtype: ['title'] } };

                        return asyncOptions.getSelectedValues(asyncReqs);
                    }
                }
            };
        });
    }

    function formFieldSettingsModalCtrl($modalInstance, $modal, $filter, $scope, field, form, $formBuilderService, selectedData) {
        var ctrl = this;
        ctrl.field = field;
        ctrl.form = form;
        ctrl.selectedData = selectedData;

        //Tabs
        ctrl.tabs = [{
            title: 'Settings'
        }, {
            title: 'Options',
            hideWhen: function hideWhen() {
                return [Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_MULTI, Gecko.Field.TYPE_MATRIX, Gecko.Field.TYPE_REPEATABLE, Gecko.Field.TYPE_CONSENT, Gecko.Field.TYPE_EVENT, Gecko.Field.TYPE_PREFERRED_LANGUAGE].indexOf(ctrl.field.type) == -1;
            }
        }, {
            title: 'Conditions'
        }, {
            title: 'Integrations',
            hideWhen: function hideWhen() {
                return !ctrl.field.id || [Gecko.Field.TYPE_EVENT, Gecko.Field.TYPE_PAGEBREAK, Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_SECTION, Gecko.Field.TYPE_MATRIX, Gecko.Field.TYPE_REPEATABLE, Gecko.Field.TYPE_MEDIA,
                //Gecko.Field.TYPE_ORGANISATION,
                Gecko.Field.TYPE_CONSENT].indexOf(ctrl.field.type) != -1;
            }
        }];

        ctrl.isActiveTab = function (index) {
            return $modal.isActiveTab(index);
        };

        $scope.$watch('fieldModalForm.$dirty', function (newVal) {
            $modalInstance.isDirty = newVal;
        });

        var modalCloseFn = $scope.$on('modal.closing', function (event) {
            event.preventDefault();
            modalCloseFn();
            return $modalInstance.close();
        });

        var labelType = $formBuilderService.fieldTypes.find(function (f) {
            return f.id === ctrl.field.type;
        });

        ctrl.modalTitle = (labelType || {}).title;

        //Modal buttons
        ctrl.footerBtns = [{
            title: 'Update',
            icon: 'fa-check-circle',
            btnClass: 'btn-primary',
            safeMode: true,
            action: function action() {
                $modalInstance.close();
            }
        }];
    }

    angular.module('GeckoEngage').config(FormFieldSettingsModal).controller('formFieldSettingsModalCtrl', formFieldSettingsModalCtrl);
})();