(function () {
    'use strict';

    function SettingsImportCtrl($scope, $state, $stateParams, $filter, import_data, integration, integration_fields) {
        var ctrl = this;

        ctrl.import = import_data;
        ctrl.integration = integration;
        ctrl.integration_fields = integration_fields ? integration_fields.toArray() : false;
        // Ctrl.integration_filters       = integration_filters ? integration_filters : false;
        if (ctrl.import.config) {
            ctrl.import.config = ctrl.import.config;
        } else {
            ctrl.import.config = ctrl.integration.type !== 'radius' ? { search: {} } : {};
        }
        ctrl.import.integration_id = ctrl.import.integration_id ? ctrl.import.integration_id : $stateParams.integration_id;
        ctrl.import.type = ctrl.import.type ? ctrl.import.type : ctrl.integration.type;
        ctrl.import.prevent_duplicates = ctrl.import.prevent_duplicates ? ctrl.import.prevent_duplicates : 1;
        ctrl.import.import_to = ctrl.import.import_to ? ctrl.import.import_to : 'contacts';

        // Fix for the editing of Integration filters
        if (ctrl.import.config && ctrl.import.config.search && ctrl.import.config.search.field) {
            ctrl.import.config.search.field = String(ctrl.import.config.search.field);
        }

        if (ctrl.integration.type == Gecko.Integration.TYPE_CONNECT) {
            ctrl.import.config_folder = 'loading';
        }

        if (ctrl.integration.type == Gecko.Integration.TYPE_RADIUS) {
            ctrl.import.target = 'loading';
        }

        if (ctrl.integration.type === Gecko.Integration.TYPE_SALEFORCE && !ctrl.import.config.list) {
            ctrl.import.config.list = 'loading';
        }

        // Deal with 3rd Party stuff
        var loading = true;
        integration.getFilters().then(function (filters) {
            // Set filters
            ctrl.integration_filters = ctrl.integration.type === Gecko.Integration.TYPE_SALEFORCE ? filters.Contact : filters;

            if (ctrl.integration.type == Gecko.Integration.TYPE_CONNECT) {
                // Build groups dropdown options
                ctrl.integration_filters_folders = [];
                for (var prop in ctrl.integration_filters) {
                    ctrl.integration_filters_folders.push({
                        id: prop,
                        name: prop
                    });
                }
                // Set filters in dropdown
                GeckoUI.gobk(ctrl.fields, 'id', 'config_folder').options = ctrl.integration_filters_folders;
            }

            if (ctrl.integration.type === Gecko.Integration.TYPE_SALEFORCE) {
                GeckoUI.gobk(ctrl.fields, 'id', 'list').options = ctrl.integration_filters;
            }

            if (ctrl.integration.type == Gecko.Integration.TYPE_RADIUS) {
                // Set filters in dropdown
                GeckoUI.gobk(ctrl.fields, 'id', 'target').options = ctrl.integration_filters;
            }

            if (ctrl.integration.type == Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
                GeckoUI.gobk(ctrl.fields, 'id', 'path').options = ctrl.integration_filters;
            }

            loading = false;
            $scope.$digest();
        });

        if (!ctrl.import.related_schedule) {
            ctrl.import.related_schedule = {};
        }
        ctrl.fields = [{
            label: 'Import Title',
            id: 'title',
            colClass: 'no-padding',
            description: 'The name of this import will be used to identify it throughout Gecko.'
        }, {
            label: 'Prevent Duplicates?',
            id: 'prevent_duplicates',
            description: 'We can combine duplicate data to make things cleaner.',
            colClass: 'no-padding',
            type: Gecko.Field.TYPE_TOGGLE,
            checkedWhen: function checkedWhen() {
                return ctrl.import.prevent_duplicates === 1;
            }
        }, {
            label: 'Salesforce Contact List',
            id: 'list',
            obj: ctrl.import.config,
            description: 'Choose which Salesforce Contact List you want to import data from.',
            colClass: 'no-padding',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.integration_filters || [{ id: 'loading', label: 'Loading...' }],
            optionsKey: 'id',
            optionsLabelKey: 'label',
            disabledWhen: function disabledWhen() {
                return loading;
            },
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_SALEFORCE;
            }
        }, {
            label: 'Dynamics Advanced Find',
            id: 'path',
            obj: ctrl.import.config,
            description: 'Choose which Advanced Find you want to import data from.',
            colClass: 'no-padding',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.integration_filters || [{ id: 'loading', label: 'Loading...' }],
            optionsKey: 'path',
            optionsLabelKey: 'name',
            optionsGroup: function optionsGroup(option) {
                return option.viewtype;
            },
            disabledWhen: function disabledWhen() {
                return loading;
            },
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_ENGAGE_DYNAMICS;
            }
        }, {
            label: 'Radius Targets',
            id: 'target',
            obj: ctrl.import.config,
            description: 'Choose which Radius Target you want to use when importing data.',
            colClass: 'no-padding',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.integration_filters || [{ id: 'loading', name: 'Loading...' }],
            optionsKey: 'id',
            optionsLabelKey: 'name',
            disabledWhen: function disabledWhen() {
                return loading;
            },
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_RADIUS;
            }
        }, {
            label: 'Connect Folder',
            id: 'config_folder',
            description: 'Choose which Connect Folder you want to import data from.',
            colClass: 'no-padding',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.integration_filters_folders || [{ id: 'loading', name: 'Loading...' }],
            optionsKey: 'id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_CONNECT;
            },
            disabledWhen: function disabledWhen() {
                return loading;
            },
            action: function action() {
                GeckoUI.gobk(ctrl.fields, 'id', 'config').options = ctrl.integration_filters[ctrl.import.config_folder];
            }
        }, {
            label: 'Connect Filters',
            id: 'config',
            colClass: 'no-padding',
            description: 'Choose which Connect Filter you want to use when importing data.',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.integration_filters || [],
            optionsKey: 'id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_CONNECT || !ctrl.import.config_folder || ctrl.import.config_folder == 'loading';
            }
        }, {
            id: 'recurring',
            label: 'Recurring Import?',
            description: 'We can run this import repeatedly for you to bring data in on a scheduled basis.',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'no-padding',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_RECURRING_IMPORT);
            }
        }, {
            id: "cron_details",
            label: "Frequency",
            type: Gecko.Field.TYPE_SCHEDULE,
            colClass: 'no-padding',
            break: true,
            hideWhen: function hideWhen() {
                return !ctrl.import.recurring;
            },
            obj: ctrl.import.related_schedule
        }, {
            id: 'unlink',
            label: 'Unlink Contacts?',
            description: 'If checked then Contacts who are no longer in the import will be unlinked from it and will no longer match conditions based on the import.',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'no-paddding',
            trueValue: 1,
            falseValue: 0,
            hideWhen: function hideWhen() {
                return !ctrl.import.recurring;
            }
        }];

        ctrl.headerBtns = [{
            title: 'Rollback',
            icon: 'fa-undo',
            safeMode: true,
            hideWhen: function hideWhen() {
                return !ctrl.import.id;
            },
            action: function action() {
                GeckoUI.dialog.confirm('All contacts and responses associated with this import, whether the import created them or not, will be deleted. Are you sure you want to rollback this import?', function (value) {
                    if (value) {
                        ctrl.import.rollback().then(function () {
                            $state.go('settingsimports');
                            GeckoUI.messenger.success('Your import is being reverted, the content will be removed shortly.');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }];

        var createImportSchedule = function createImportSchedule(related_schedule) {
            if (!related_schedule || Object.keys(related_schedule).length === 0) {
                return undefined;
            }
            var schedule = related_schedule;
            schedule.type = Gecko.Schedule.TYPE_IMPORT;

            switch (schedule.cron_details.type) {
                case 'hour':
                    schedule.cron_details.hour = '*';
                    break;
                case 'day':
                    schedule.cron_details.day = '*';
                    schedule.cron_details.dayofweek = '*';
                    break;

                case 'week':
                    schedule.cron_details.day = '*';
                    break;

                case 'month':
                    schedule.cron_details.dayofweek = '*';
                    break;
            }

            schedule.cron = schedule.cron_details.minute + ' ' + schedule.cron_details.hour + ' ' + schedule.cron_details.day + ' ' + schedule.cron_details.month + ' ' + schedule.cron_details.dayofweek;
            return schedule;
        };

        ctrl.footerBtns = [{
            title: 'Save Import',
            preset: 'save',
            safeMode: true,
            action: function action() {
                ctrl.import.schedule = createImportSchedule(ctrl.import.related_schedule);

                ctrl.import.save().then(function (import_data) {
                    GeckoUI.messenger.success("Import saved");
                    if ($stateParams.import_id == 'new') {
                        $state.go('importsview', { import_id: import_data.id });
                    }
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Import Integration Success', {
                        'import id': import_data.id,
                        'import name': import_data.title
                    });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Import Integration Error', {
                        'import id': ctrl.import.id || 'New',
                        'import title': ctrl.import.title,
                        'error': GeckoUI.renderError(err)
                    });
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Imports',
            click: function click() {
                $state.go('settingsimports');
            }
        }, {
            label: ctrl.import.type ? $filter('capitalize')(ctrl.import.type) : 'Add Import',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('SettingsImportCtrl', SettingsImportCtrl);
})();