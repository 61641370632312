(function () {
    'use strict';

    function geckoTimeDirective() {

        return {
            restrict: 'E',
            scope: {
                elementId: '@id',

                utc: '=?',
                value: '=',
                isDuration: '=',
                interval: '=',
                labeled: '='
            },
            templateUrl: '/components/gecko-datetime/gecko-time.html',

            link: function link(scope, iElement, iAttrs, ctrl) {

                if (ctrl.isDuration) {
                    iElement.addClass('duration');
                }
            },

            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller(geckoDates) {

                var ctrl = this;

                var minuteInterval = Number(ctrl.interval) || 5;

                var createSetter;

                if (ctrl.isDuration) {
                    createSetter = function createSetter(key) {
                        return function (value) {
                            var time = GeckoUI.decomposeSeconds(ctrl.value); // Would use moment.duration here but it's values aren't settable.
                            if (arguments.length) {
                                time[key] = Number(value) || 0;
                                ctrl.value = GeckoUI.composeSeconds(time);
                            }
                            return time[key];
                        };
                    };
                } else {
                    createSetter = function createSetter(key) {
                        return function (value) {
                            var date = ctrl.utc ? moment.utc(ctrl.value, 'X') : moment(ctrl.value, 'X');

                            if (arguments.length) {

                                if (!date.isValid()) {
                                    date = ctrl.utc ? moment.utc() : moment();
                                    date.minutes(GeckoUI.roundToInterval(date.minutes(), minuteInterval));
                                    date.seconds(0);
                                }

                                date[key](Number(value) || 0);
                                ctrl.value = Number(date.format('X'));
                            }
                            if (!date.isValid()) {
                                return null;
                            }
                            return date[key]();
                        };
                    };
                }

                // Time steps
                if (!ctrl.isDuration && geckoDates.is12HrTimeFormat()) {
                    ctrl.hourSteps = GeckoUI.createNumberRange(0, 24, 1, { displayFunc: function displayFunc(value) {
                            return value;
                        } }).map(function (h) {
                        // Convert display hour
                        h.display = geckoDates.prepare12HrOptionLabel(h.value);
                        return h;
                    });
                } else {
                    ctrl.hourSteps = GeckoUI.createNumberRange(0, 24, 1, { displayFunc: function displayFunc(value) {
                            return GeckoUI.padLeft(value, '0', 2);
                        } });
                }

                ctrl.minSteps = GeckoUI.createNumberRange(0, 60, minuteInterval, { displayFunc: function displayFunc(value) {
                        return GeckoUI.padLeft(value, '0', 2);
                    } });

                ctrl.days = createSetter('days');
                ctrl.hour = createSetter('hours');
                ctrl.minute = createSetter('minutes');
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoTime', geckoTimeDirective);
})();