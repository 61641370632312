(function () {
    'use strict';

    function SettingsCtrl($state, $filter, Gecko, changeLogService, geckoStore) {
        var ctrl = this;

        // Get changelog
        changeLogService.get({ perPage: 1 }).then(function (data) {
            ctrl.changeLogs = data.data;
            // Show shortened version
            // if (ctrl.changeLogs[0] && ctrl.changeLogs[0].strapline && ctrl.changeLogs[0].strapline.length > 100) {
            //     console.log(ctrl.changeLogs[0].strapline);
            //     ctrl.changeLogs[0].strapline = ctrl.changeLogs[0].strapline.slice(0, 50) + '...';
            // }
        });

        // Get activity
        if (Gecko.able(Gecko.ABILITY_AUDIT)) {
            new Gecko.Activity().include('user').limit(5).get().then(function (activity) {
                ctrl.activity = activity.toArray();
            });
        } else {
            ctrl.activity = new Gecko.Activity();
        }

        var counter = 0;
        ctrl.pageHistory = (geckoStore.get('pages-history') || []).filter(function (page) {
            if (page.toState && page.toParams && counter < 5) {
                counter += 1;
                return true;
            } else {
                return false;
            }
        });

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'List',
            active: true
        }];

        ctrl.activeTab = 'All Settings';
        ctrl.changeTab = function (tabTitle) {
            ctrl.activeTab = tabTitle;
        };

        // Settings Content
        ctrl.settingsContent = [{
            title: 'All Settings',
            items: [],
            template: '/modules/settings/views/settings-list.66f78d45.html'
        }, {
            title: 'Account',
            icon: 'fa-user',
            items: [{
                title: 'Account Settings',
                state: 'settingsaccount',
                description: 'Change system wide options and preferences - branding, default IDs, timezone etc.',
                tags: 'logo, image, customise, background, color, colour, customize, default, date'
            }, {
                title: 'Email & SMS Templates',
                state: 'settingstemplates',
                description: 'View, edit or create templates to be used in messages sent through automation workflows.',
                tags: 'email, sms, templates, text, body, html, reply, confirmation, e-mail, message, tags, mail, merge'
            }, {
                title: 'Verified Senders & Domains',
                state: 'verifiedsenders.senders',
                description: 'Add addresses & domains that can be used to send emails from Gecko.',
                tags: 'email, sender, verified, e-mail, address, from, domain'
            }, {
                title: 'Categories',
                state: 'settingscategories',
                description: 'View, add and edit categories used for grouping.',
                tags: 'categories, tags, structure, filter, find'
            }],
            template: '/modules/settings/views/settings-list.66f78d45.html'
        }, {
            title: 'User Management',
            icon: 'fa-copy',
            items: [{
                title: 'My User Settings',
                state: 'user({user_id:Gecko.user.id})',
                stateParams: { user_id: Gecko.user.id },
                description: 'Edit your personal details such as your password, email and avatar colour.',
                tags: 'logo, image, customise, background, color, colour, customize, default, date, password, email, phone, telepehone, avatar, record, voip'
            }, {
                title: 'Users',
                state: 'users',
                description: 'View, add and edit users who can access the system.',
                tags: 'users, team, member, staff, person, agent, add, create, password, account'
            }, {
                title: 'User Groups',
                state: 'usersgroups',
                description: 'Create and manage system permissions for users within specific groups.',
                tags: 'user, groups, permissions, security, abilities, limit, mobile, administrator'
            }, {
                title: 'Mobile Devices',
                state: 'usersdevices',
                description: 'Track your devices and app downloads.',
                tags: 'iphone, ipad, tablet, download, devices, app, rename'
            }],
            template: '/modules/settings/views/settings-list.66f78d45.html'
        }, {
            title: 'Field Management',
            icon: 'fa-users',
            items: [{
                title: 'Contact Fields',
                state: 'settingscontactfields',
                description: 'View, add and edit fields you want to associate with your contacts.',
                tags: 'contact, fields, contact fields, profile, overview, student'
            }, {
                title: 'Organisation Fields',
                state: 'organisationfields',
                description: 'View, edit or add data fields in your organisations.',
                tags: 'organisations, organizations, fields',
                hideWhen: function hideWhen() {
                    return !Gecko.has(Gecko.Package.FEATURE_ORGANISATION);
                }
            }, {
                title: 'Organisation Types',
                state: 'organisationtypes',
                description: 'Manage the type of organisations you want available in your system.',
                tags: 'organisation, organization, university, school, institutions',
                hideWhen: function hideWhen() {
                    return !Gecko.has(Gecko.Package.FEATURE_ORGANISATION);
                }
            }, {
                title: 'Field Options',
                state: 'settingsoptions',
                description: 'Create, edit or import lists to be used across forms, contact fields and scripts.',
                tag: 'list, options, option template, country, course, parent, child, nationality, temlpate, directory, dropdown, drop down, checkboxes, radio options, '
            }],
            template: '/modules/settings/views/settings-list.66f78d45.html'
        }, {
            title: 'Call & SMS',
            icon: 'fa-phone-square',
            hideWhen: function hideWhen() {
                return !(Gecko.User.hasOutboundOrInbound() || Gecko.has(Gecko.Package.FEATURE_SMS));
            },
            items: [{
                title: 'Outcomes',
                state: 'settingsoutcomes',
                description: 'View, edit or create call results listed at the conclusion of your calls.',
                tags: 'call, phone, results, conclusion, outcomes, end, status',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_OUTCOMES_CUSTOM);
                }
            }, {
                title: 'Telephone Numbers',
                state: 'settingsnumbers',
                description: 'View contact phone numbers and manage "do not call" numbers.',
                tags: 'call, sms, do not call, blocked',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_NUMBERS_VIEW);
                }
            }, {
                title: 'VoIP Numbers',
                state: 'voipnumbers',
                description: 'View, purchase or edit VoIP telephone numbers to be used in call campaigns or SMS broadcasts.',
                tags: 'call, sms, voip, number, buy, landline',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_SENDERS_VOIP);
                }
            }, {
                title: 'Usage & Costs',
                state: 'usageandcosts.transactions',
                description: 'View your account wide bills and charges, monitor your VoIP usage and the remaining balance on your calls.',
                tags: 'money, cost, bills, voip, price, call, sms, usage, balance, expenditure',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_TRANSACTIONS_VIEW);
                }
            }, {
                title: 'Test VoIP Connection',
                state: 'voiptest',
                description: 'Test your network and browser configuration to ensure you can make calls through Gecko VoIP.',
                tags: 'voip, call, users, help',
                hideWhen: function hideWhen() {
                    return !Gecko.User.hasOutboundOrInbound();
                }
            }],
            template: '/modules/settings/views/settings-list.66f78d45.html'
        }, {
            title: 'Data Management',
            icon: 'fa-file',
            items: [{
                title: 'Integrations',
                state: 'settingsintegrations',
                description: 'View, add and edit integrations or attributes.',
                tags: 'radius, hobsons, connect, sync, salesforce, microsoft, dynamics, dotmailer, zapier, EMS'
            }, {
                title: 'Import Data',
                state: 'settingsimports',
                description: 'Import responses, contacts, events or organisations from a CSV spreadsheet or from your CRM.',
                tags: 'import, responses, events, contact, events, organizations, csv, excel, spreadsheet, crm, radius, connect, hobsons, dynamics, microsoft, zapier, scheduled, recurring'
            }, {
                title: 'Export Data',
                state: 'settingsexports',
                description: 'Export your contacts, responses, campaigns, organisations, calls, events and more.',
                tags: 'export, recurring, ftp, scheduled, data, spreadsheet, excel, contacts, responses, forms, campaigns, broadcasts, organisations, organizations, calls, events, attendees'
            }, {
                title: 'Labels',
                state: 'settingslabels',
                description: 'View, add and edit labels used throughout Gecko to filter and organise responses and contacts.',
                tags: 'labels, tags, structure, filter, find, colour, color'
            }, {
                title: 'Data Security Preferences',
                // Performance issues 'Activity Log' tab, avoid using as initial view when possible. 
                state: !(Gecko.able(Gecko.ABILITY_AUTO_ARCHIVE) || Gecko.able(Gecko.ABILITY_CONSENTS_VIEW)) ? 'datasecurity.activity' : 'datasecurity.preferences',
                description: 'View system-wide activity, manage default consent options and manage your privacy policy.',
                tags: 'monitor, history, changes, track, errors, activity, data, security, consent, gdpr, privacy'
            }],
            template: '/modules/settings/views/settings-list.66f78d45.html'
        }];

        var notHidden = function notHidden(item) {
            return item.hideWhen ? !item.hideWhen() : true;
        };
        ctrl.settingsContent[0].items = ctrl.settingsContent.reduce(function (items, section) {
            if (section.title === 'All Settings') {
                return items;
            }
            var sectionItems = section.items.map(function (item) {
                item.sectionTitle = section.title;
                return item;
            }).filter(notHidden);

            return items.concat(sectionItems);
        }, []);

        ctrl.getSettingItems = function (settingTitle) {
            return ctrl.settingsContent.find(function (setting) {
                return setting.title === settingTitle;
            }).items;
        };

        ctrl.resultItemClass = function (i) {
            return 'settings-search-result-' + i;
        };

        var resultItemActiveIndex = -1;
        var resultItemActiveClass = 'settings-search-result-active';

        // Navigate list with keys (38 up, 40 down)
        ctrl.navigateItems = function (evt, isBlur) {
            // Reset on blur
            if (isBlur) {
                $('.settings-search-result-' + resultItemActiveIndex).removeClass(resultItemActiveClass);
                resultItemActiveIndex = -1;
                return;
            }

            // Enter has been pressed, navigate to page
            if (evt.which === 13 && resultItemActiveIndex > -1) {
                // Is the list being filtered?
                if (ctrl.searchText) {
                    var setting = $filter('filter')(ctrl.settingsContent[0].items, ctrl.searchText)[resultItemActiveIndex];
                } else {
                    var setting = ctrl.settingsContent[0].items[resultItemActiveIndex];
                }
                return $state.go(setting.state.replace(/\(.+/, ''), setting.stateParams || {});
            }

            // Remove current highlight class
            $('.settings-search-result-' + resultItemActiveIndex).removeClass(resultItemActiveClass);

            // Only allow UP and DOWN keys
            if ([38, 40].indexOf(evt.which) === -1) {
                resultItemActiveIndex = -1;
                return;
            }

            // Update active index
            if (evt.which === 38) {
                resultItemActiveIndex--;
            } else {
                resultItemActiveIndex++;
            }

            // Apply higlight class
            $('.settings-search-result-' + resultItemActiveIndex).addClass(resultItemActiveClass);
        };
    }

    angular.module('GeckoEngage').controller('SettingsCtrl', SettingsCtrl);
})();