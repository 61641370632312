(function () {
    'use strict';

    function geckoSideMenuToggle() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-side-menu/gecko-side-menu-toggle.html',
            scope: true,
            controller: function controller($scope, geckoSideMenuService, geckoStore) {
                $scope.Gecko = Gecko;
                $scope.geckoSideMenuService = geckoSideMenuService;

                // Save menu state to local storage
                var saveMenuPref = function saveMenuPref() {
                    if (geckoSideMenuService.expanded) {
                        geckoStore.set('gecko-side-menu-expanded', true);
                        geckoStore.set('gecko-side-menu-collapsed', null);
                    } else {
                        geckoStore.set('gecko-side-menu-expanded', null);
                        geckoStore.set('gecko-side-menu-collapsed', true);
                    }
                };

                $scope.changeMenuClass = function () {
                    // Toggle menu pref
                    if (geckoSideMenuService.expanded) {
                        geckoSideMenuService.expanded = false;
                        geckoSideMenuService.collapsed = true;
                    } else {
                        geckoSideMenuService.expanded = true;
                        geckoSideMenuService.collapsed = false;
                    }
                    // Save menu pref
                    saveMenuPref();
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoSideMenuToggle', geckoSideMenuToggle);
})();