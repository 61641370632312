(function () {
    'use strict';

    function DataSecurityActivityCtrl(activities, integrations, filterValues, $stateParams, $state, $filter, asyncOptions) {
        var ctrl = this;
        ctrl.page = $stateParams.page > 0 ? $stateParams.page : 1;
        ctrl.per_page = 25;
        ctrl.dateFrom = $stateParams.from;
        ctrl.dateTo = $stateParams.to;

        // Table structure
        ctrl.cols = [{
            title: 'User',
            key: 'user.full_name',
            sortKey: 'user_id',
            render: function render(value) {
                if (!value) return 'System User';
                return value;
            },
            colClass: 'col-xs-2',
            type: Gecko.Field.TYPE_NAME
        }, {
            title: 'Type',
            key: 'actionable_model_name',
            sortKey: 'actionable_type',
            colClass: 'col-xs-1',
            render: function render(value) {
                return $filter('capitalize')(value == 'hobsons' ? 'connect' : value);
            }
        }, {
            title: 'Action',
            key: 'type',
            sortKey: 'type',
            colClass: 'col-xs-1',
            render: function render(value) {
                return $filter('titlecase')(value);
            },
            status_styles: Gecko.Activity.status_styles
        }, {
            title: 'Message',
            key: 'message',
            colClass: 'col-xs-6'
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-2'
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Activity Log',
            active: true
        }];

        // Check if activity is being filtered
        ctrl.hasFilter = false;
        angular.forEach($stateParams, function (param) {
            if (param) ctrl.hasFilter = true;
        });

        ctrl.resetFilter = function () {
            return $state.go($state.current, { from: null, to: null, user: null, action: null, type: null, page: null, order: null, sort: null }, { reload: true });
        };

        // ToArray is filtering out the actionable entry in the array
        ctrl.activities = activities.data;

        ctrl.pagination = activities.pagination;

        ctrl.rowIcon = function (activity) {
            switch (activity.type) {
                case 'create':
                    return 'fa-plus';
                case 'delete':
                    return 'fa-times';
                case 'error':
                    return 'fa-warning';
                case 'radius':
                case 'hobsons':
                case 'salesforce':
                case 'ems':
                case 'export':
                    return 'fa-info-circle';
                case 'update':
                    return 'fa-check';
                case 'view':
                    return 'fa-circle';
                case 'auto_archive':
                case 'mass_action':
                default:
                    return;
            }
        };

        var uniqueifyArray = function uniqueifyArray(array) {
            var mappedArray = array.reduce(function (mapped, item) {
                mapped[item] = null;
                return mapped;
            }, {});
            return Object.keys(mappedArray);
        };

        var uniqueifyObjectArray = function uniqueifyObjectArray(array) {
            return function (uniqueKey) {
                var valueArray = array.map(function (item) {
                    return item[uniqueKey];
                });
                var uniqueValueArray = uniqueifyArray(valueArray);
                return uniqueValueArray.map(function (value) {
                    return array.find(function (item) {
                        return item[uniqueKey] === value;
                    });
                });
            };
        };

        ctrl.filterActions = [{
            value: 'update',
            label: 'Update'
        }, {
            value: 'delete',
            label: 'Delete'
        }, {
            value: 'create',
            label: 'Create'
        }, {
            value: 'view',
            label: 'View'
        }, {
            value: 'error',
            label: 'Error'
        }, {
            value: 'export',
            label: 'Export'
        }, {
            value: Gecko.Activity.TYPE_AUTO_ARCHIVE,
            label: 'Auto Archive'
        }, {
            value: Gecko.Activity.TYPE_MASS_ACTION,
            label: 'Mass Action'
        }];
        var uniqueIntegrations = uniqueifyObjectArray(integrations)('type').map(function (integration) {
            var type = integration.type;
            if (type === 'engage-dynamics') type = 'dynamics';
            return { value: type, label: type.substr(0, 1).toUpperCase() + type.substr(1) };
        });

        ctrl.filterActions = ctrl.filterActions.concat(uniqueIntegrations);

        ctrl.filters = [{
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            }
        }, {
            title: 'Users',
            type: 'dropdown',
            options: asyncOptions.pick(filterValues, 'user').concat({ id: "0", full_name: 'System User' }),
            getOptions: asyncOptions.create(new Gecko.User().orderBy('full_name').rfields({ user: ['full_name'] }), function (search) {
                if ('system user'.indexOf(search.toLowerCase()) !== -1) {
                    this.options.push({ id: "0", full_name: 'System User' });
                }
            }),
            optionsKey: 'id',
            optionsLabelKey: 'full_name',
            stateParam: 'user'
        }, {
            title: 'Actions',
            type: 'checkbox',
            options: ctrl.filterActions,
            stateParam: 'action'
        }, {
            title: 'Types',
            type: 'checkbox',
            options: [{
                value: 'account',
                label: 'Account'
            }, {
                value: 'attendance',
                label: 'Attendance'
            }, {
                value: 'call',
                label: 'Call'
            }, {
                value: 'campaign',
                label: 'Campaign'
            }, {
                value: 'consent',
                label: 'Consent'
            }, {
                value: 'contact',
                label: 'Contact'
            }, {
                value: 'dashboard',
                label: 'Dashboard'
            }, {
                value: 'event',
                label: 'Event'
            }, {
                value: 'export',
                label: 'Export'
            }, {
                value: 'field',
                label: 'Field'
            }, {
                value: 'file',
                label: 'File'
            }, {
                value: 'form',
                label: 'Form'
            }, {
                value: 'group',
                label: 'Group'
            }, {
                value: 'import',
                label: 'Import'
            }, {
                value: 'integration',
                label: 'Integration'
            }, {
                value: 'integrationField',
                label: 'Integration Field'
            }, {
                value: 'label',
                label: 'Label'
            }, {
                value: 'note',
                label: 'Note'
            }, {
                value: 'option',
                label: 'Option'
            }, {
                value: 'organisation',
                label: 'Organisation'
            }, {
                value: 'outcome',
                label: 'Outcome'
            }, {
                value: 'report',
                label: 'Report'
            }, {
                value: 'response',
                label: 'Response'
            }, {
                value: 'schedule',
                label: 'Schedule'
            }, {
                value: 'sender',
                label: 'Sender'
            }, {
                value: 'snapshot',
                label: 'Snapshot'
            }, {
                value: 'subscriber',
                label: 'Subscriber'
            }, {
                value: 'synchronisation',
                label: 'Synchronisation'
            }, {
                value: 'template',
                label: 'Template'
            }, {
                value: 'user',
                label: 'User'
            }],
            stateParam: 'type'
        }];
    }

    angular.module('GeckoEngage').controller('DataSecurityActivityCtrl', DataSecurityActivityCtrl);
})();