(function () {

    function LegacyEventSessionModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('legacyEventSessionModal', function (_eventParent, _event) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/modules/event_legacy/views/_modals/session.29db8764.html',
                controller: 'LegacyEventSessionModalCtrl',
                resolve: {
                    eventParent: function eventParent() {
                        return _eventParent;
                    },
                    event: function event() {
                        if (_event) {
                            return _event;
                        } else {
                            return new Gecko.Event();
                        }
                    }
                }
            };
        });
    }

    function LegacyEventSessionModalCtrl($state, $scope, $stateParams, $modalInstance, $filter, eventParent, event, geckoDates) {
        var ctrl = this;
        ctrl.event = event;

        var prepareEpochs = function prepareEpochs(event) {
            ctrl.event.start_date = geckoDates.toEpoch(event.start_datetime);
            ctrl.event.end_date = geckoDates.toEpoch(event.end_datetime);
            ctrl.event.closing_date = geckoDates.toEpoch(event.closing_datetime);
        };

        // Prepare event epochs (for date pickers)
        prepareEpochs(ctrl.event);

        if (!ctrl.event.type) {
            ctrl.event.type === Gecko.Event.TYPE_SESSION_LEGACY;
        }

        // Field list
        ctrl.fields = [{
            id: 'id',
            label: 'ID',
            colClass: 'none',
            disabledWhen: function disabledWhen() {
                return ctrl.event.id;
            },
            hideWhen: function hideWhen() {
                return !ctrl.event.id;
            }
        }, {
            colClass: 'none',
            id: 'title',
            label: 'Title',
            required: true
        }, {
            colClass: 'none',
            id: 'start_date',
            label: 'Start Date',
            type: Gecko.Field.TYPE_DATETIME,
            utc: true
        }, {
            colClass: 'none',
            id: 'end_date',
            label: 'End Date',
            type: Gecko.Field.TYPE_DATETIME,
            utc: true
        }, {
            colClass: 'none',
            id: 'location',
            label: 'Location'
        }, {
            colClass: 'none',
            id: 'cost',
            label: 'Cost'
        }, {
            id: 'auto_waitlist',
            label: 'Auto Waitlist',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: false,
            colClass: 'none'
        }, {
            id: 'maximum',
            label: 'Maximum Attendees',
            type: Gecko.Field.TYPE_NUMBER,
            colClass: 'none'
        }];

        ctrl.footerBtns = [{
            id: 'save',
            preset: 'save',
            action: function action() {
                ctrl.event.parent_id = eventParent.id;
                ctrl.event.save().then(function (event) {
                    GeckoUI.messenger.success('Session changes have been saved');
                    prepareEpochs(event);
                    $modalInstance.close(event);

                    $scope.$digest();
                }).catch(function (err) {
                    GeckoUI.messenger.error(Gecko.errorsAsArray(err.errors));
                });
            }
        }, {
            id: 'remove',
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return !ctrl.event.id;
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this Session?', function (value) {
                    if (value) {
                        ctrl.event.remove().then(function () {
                            GeckoUI.messenger.success('Event removed');
                            $modalInstance.dismiss();
                        }).fail(function (err) {
                            GeckoUI.messenger.error(Gecko.errorsAsArray(err.errors));
                        });
                    }
                });
            }
        }];

        var _tempDates = {
            start_date: ctrl.event.start_date,
            end_date: ctrl.event.end_date,
            closing_date: ctrl.event.closing_date
        };
        geckoDates.initSetters(_tempDates, ctrl.event, [{ get: 'start_date', set: 'start_datetime' }, { get: 'end_date', set: 'end_datetime' }, { get: 'closing_date', set: 'closing_datetime' }]);
    }

    angular.module('GeckoEngage').config(LegacyEventSessionModal).controller('LegacyEventSessionModalCtrl', LegacyEventSessionModalCtrl);
})();