(function () {
    'use strict';

    var addImport = function addImport($dialogIntegrationSelect, message, types, callback) {
        return $dialogIntegrationSelect(message, types, callback);
    };

    function SettingsImportsCtrl($scope, $state, $filter, $stateParams, imports, $dialogIntegrationSelect, $geckoSocket) {
        var ctrl = this;
        ctrl.imports = imports.toInstances();
        ctrl.pagination = imports.pagination;

        $geckoSocket.watch('import', ctrl.imports, ['status', 'progress']);

        ctrl.imports.forEach(function (_import) {
            Object.defineProperties(_import, {
                frequency: {
                    get: function get() {
                        if (this.related_schedule) {
                            var knownSchedules = {
                                '0 * * * *': 'Hourly',
                                '0 */3 * * *': 'Every 3 hours',
                                '0 */12 * * *': 'Every 12 hours',
                                '0 0 * * *': 'Daily',
                                '0 0 * * 0': 'Weekly'
                            };
                            if (knownSchedules[this.related_schedule.cron]) {
                                return knownSchedules[this.related_schedule.cron];
                            }
                            return Gecko.Import.FREQUENCY_TITLES.find(function (frequency) {
                                return frequency.id == this.related_schedule.cron_details.type;
                            }.bind(this)).title;
                        }
                        return 'Once';
                    }
                }
            });
        });

        // Table columns
        ctrl.tableCols = [{
            key: 'status',
            colClass: 'text-center',
            expandable: true,
            status_title_none: true,
            status_styles: Gecko.Import.status_titles
        }, {
            title: 'Progress',
            key: 'progress',
            render: function render(value, col, _import) {
                if (value) return value + '%';
                if (_import.status == Gecko.Import.STATUS_COMPLETE) return '100%';
                return '-';
            }
        }, {
            title: 'Title',
            key: 'title'
        }, {
            title: 'Frequency',
            key: 'frequency'
        }, {
            title: 'Data Type',
            key: 'import_to',
            render: function render(value) {
                // If there is no type set, lets just assume its Contacts
                if (!value) {
                    return 'Contacts';
                }
                // Or we just capitalize the type
                return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
            }
        }, {
            title: 'Source',
            key: 'type',
            render: function render(type) {
                return GeckoUI.getObjectByKey(Gecko.Import.titles, 'id', type).title;
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Import Date',
            key: 'created_at',
            utc: false
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Previous Run',
            key: 'schedule.last_run_at',
            utc: false
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Next Run',
            key: 'schedule.next_run_at',
            utc: false
        }, {
            title: 'Imported By',
            type: Gecko.Field.TYPE_NAME,
            key: 'related_user.full_name'
        }, {
            title: 'Item count',
            key: 'total',
            colClass: 'col-xs-3'
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(savedImport) {
                // If it's a recurring CSV Import
                if (savedImport.type === 'csv' && savedImport.schedule) {
                    return $state.go('importrecurringedit.options', { import_id: savedImport.id });
                }
                // If it's a normal CSV import
                if (savedImport.status === Gecko.Import.STATUS_UPLOADED) {
                    return $state.go('importconfig', { import_id: savedImport.id });
                }
                // If it's an integration import
                if (savedImport.status != Gecko.Import.STATUS_ROLLED_BACK) {
                    return $state.go('importsview', { import_id: savedImport.id });
                }
            }
        };

        ctrl.rowOptionsBtn = {
            items: [{
                title: 'Re-import',
                action: function action(row) {
                    if (row.schedule) {
                        row.trigger().then(function (import_data) {
                            GeckoUI.messenger.success(row.title + " has been triggered and will be processed shortly");
                            $state.go('importsview', { import_id: row.id }, { reload: true });
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    } else {
                        return $state.go('settingsimportfile', { import_id: row.id });
                    }
                },
                hideWhen: function hideWhen(row) {
                    return row.type === 'csv' && row.schedule;
                }
            }, {
                title: 'Remove import',
                action: function action(row) {
                    return row.remove().then(function () {
                        $state.reload();
                        GeckoUI.messenger.success('Your import has been removed');
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                    });
                },
                hideWhen: function hideWhen(row) {
                    return !(row.type === 'csv' && row.schedule);
                }
            }],
            hideWhen: function hideWhen(row) {
                return row.type !== 'csv' && !row.schedule;
            }
        };

        var importTypes = ['file_import', Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_SALEFORCE, Gecko.Integration.TYPE_ENGAGE_DYNAMICS];

        // Add new import dialog
        ctrl.addImport = addImport.bind(ctrl, $dialogIntegrationSelect, 'What type of data do you want to import?', importTypes, function (integrationId) {
            if (integrationId == 'file_import') {
                return $state.go('settingsimportfile');
            } else if (integrationId) {
                return $state.go('settingsimport', { import_id: 'new', integration_id: integrationId });
            }
        });

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { label: object[labelKey], value: object.id };
                });
            };
        };
        ctrl.filters = [{
            title: 'Source',
            type: 'checkbox',
            options: objectsToOptions(Gecko.Import.titles)('title'),
            stateParam: 'source'
        }, {
            title: 'Frequency',
            type: 'radio',
            options: [{ label: 'Once', value: 'once' }, { label: 'All Recurring', value: 'recurring' }, { label: 'Daily', value: 'daily' }, { label: 'Weekly', value: 'weekly' }, { label: 'Monthly', value: 'monthly' }],
            stateParam: 'frequency'
        }, {
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            }
        }, {
            title: 'Type',
            type: 'checkbox',
            options: [{ label: 'Contacts', value: 'contacts' }, { label: 'Responses', value: 'responses' }, { label: 'Events', value: 'events' }, { label: 'Organisations', value: 'organisations' }],
            stateParam: 'type'
        }, {
            title: 'Progress',
            type: 'radio',
            options: [{ label: 'In Progress', value: Gecko.Import.STATUS_STARTED }, { label: 'Done', value: Gecko.Import.STATUS_COMPLETE }],
            stateParam: 'progress'
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Imports',
            active: true
        }];
    }

    function SettingsCSVImportConfigCtrl($scope, $state, csv_import, import_fields, consents, prepareImportMappingFieldOptions) {

        $scope.consentdata = consents.data;
        $scope.csv_import = csv_import;
        $scope.import_form = null;
        $scope.import_fields = [];
        $scope.selected_fields = csv_import.selected_fields || [];
        $scope.header_rows = true;
        $scope.validation = true;
        $scope.integrations = 0;
        $scope.workflows = {
            assign: true,
            label: true,
            campaign: true,
            email: false,
            sms: false
        };
        $scope.cols = [true, true, true, false, false];

        // Ensure we have a full-length list of selected fields to pass up
        if (typeof $scope.selected_fields[$scope.csv_import.field_sample[0].length - 1] === 'undefined') {
            $scope.selected_fields[$scope.csv_import.field_sample[0].length - 1] = null;
        }

        // Prepare field options
        if ($scope.csv_import.import_to === 'responses') {
            // Make sure there is form fields!
            if ($scope.csv_import.related_form && $scope.csv_import.related_form.related_fields) {
                $scope.import_fields = prepareImportMappingFieldOptions('responses', $scope.csv_import.related_form.related_fields);
            } else {
                $scope.import_fields = prepareImportMappingFieldOptions('responses', []);
            }
        } else {
            $scope.import_fields = prepareImportMappingFieldOptions($scope.csv_import.import_to, import_fields ? import_fields.toArray() : []);
        }

        if ($scope.csv_import.import_to === 'contacts') {
            $scope.import_fields.push({
                id: 'consent_data',
                type: 'consent',
                label: 'Consent',
                option: consents.data
            });
        }

        $scope.requiredFields = $scope.import_fields.filter(function (f) {
            return f.required;
        });
        $scope.isMapped = function (id) {
            return ($scope.selected_fields || []).find(function (f) {
                return f && f.field_id === id;
            });
        };

        $scope.configure = function () {
            var config = {
                selected_fields: $scope.selected_fields,
                skip_rows: $scope.header_rows ? 1 : 0,
                validation: $scope.validation ? 1 : 0,
                enabled_workflows: $scope.workflows,
                integrations: $scope.integrations,
                title: $scope.csv_import.title
            };

            $scope.csv_import.configure(config).then(function (import_data) {
                $scope.csv_import = null;
                new Messenger().post('Your CSV is being imported and will be available shortly.');
                $state.go('importsview', { import_id: import_data.id });
                $scope.$apply();

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Import CSV Success', {
                    'import id': import_data.id,
                    'import name': import_data.title
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Import CSV Error', {
                    'import title': $scope.csv_import.title,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        // Breadcrumbs
        $scope.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Imports',
            click: function click() {
                $state.go('settingsimports');
            }
        }, {
            label: 'Import CSV',
            active: true
        }];
    }

    function fieldSelectorValidityClass() {
        return {
            restrict: 'A',
            controller: function controller() {},
            link: function link(scope, iElement, iAttrs, ctrl) {
                ctrl.setValid = function () {
                    iElement.addClass('valid');
                    iElement.removeClass('invalid');
                };
                ctrl.setInvalid = function () {
                    iElement.addClass('invalid');
                    iElement.removeClass('valid');
                };
                ctrl.setInitial = function () {
                    iElement.removeClass('valid invalid');
                };
            }
        };
    }

    function fieldSelector() {
        return {
            transclude: true,
            restrict: 'AE',
            scope: {
                fields: '=',
                selected: '=',
                i: '=',
                consents: '='
            },
            require: '^fieldSelectorValidityClass',
            link: function link($scope, iElement, iAttrs, validity, $timeout) {
                // Initialise import mapping
                $scope.tmp = $scope.selected[$scope.i] ? $scope.selected[$scope.i].field_id : null;
                $scope.sub_op = null;
                $scope.sub = null;

                populateSubOptions();
                // Initialise sub option
                if ($scope.selected[$scope.i] && $scope.selected[$scope.i].option) {
                    $scope.sub_op = $scope.selected[$scope.i].option;
                }

                function populateSubOptions() {
                    if (['created_at', 'start_date', 'end_date', 'start_datetime', 'end_datetime', 'closing_datetime'].indexOf($scope.tmp) !== -1) {
                        $scope.sub = {
                            'Y-m-d H:i:s': 'yyyy-mm-dd 08:45:45',
                            'ym-d H:i:s': 'yy-mm-dd 08:45:45',
                            'd/m/Y H:i': 'dd/mm/yyyy 08:45',
                            'd/m/y H:i': 'dd/mm/yy 08:45',
                            'd-m-Y H:i': 'dd-mm-yyyy 08:45',
                            'd-m-y H:i': 'dd-mm-yy 08:45',
                            'm/d/Y H:i': 'mm/dd/yyyy 08:45',
                            'm/d/y H:i': 'mm/dd/yy 08:45',
                            'm-d-Y H:i': 'mm-dd-yyyy 08:45',
                            'm-d-y H:i': 'mm-dd-yy 08:45'
                        };
                    }

                    angular.forEach($scope.fields, function (f) {
                        if (f.id == $scope.tmp) {
                            if (f.type == 'name') {
                                $scope.sub = {
                                    'first_name': 'First',
                                    'last_name': 'Last',
                                    'first_last': 'First Last',
                                    'last_first': 'Last First',
                                    'first_comma_last': 'First, Last',
                                    'last_comma_first': 'Last, First'
                                };
                            } else if (f.type == 'address') {
                                $scope.sub = {
                                    'street_address': 'Street',
                                    'address_line_2': 'Line 2',
                                    'postal_code': 'Post Code',
                                    'city': 'City',
                                    'state': 'State',
                                    'country': 'Country'
                                };
                            } else if (f.type == 'tel') {
                                $scope.sub = {
                                    'country_code': 'Country Abbr.',
                                    'dial_code': 'Dial Code',
                                    'number': 'Number'
                                };
                            } else if (f.type == 'consent') {

                                $scope.sub = {
                                    'comma_separated_consents': 'Comma-separated Consents'
                                };

                                if ($scope.consents) {
                                    $scope.consents.forEach(function (consent) {
                                        if (!consent) return;
                                        $scope.sub['consent_' + consent.id] = consent.title;
                                    });
                                }
                            }
                        }
                    });

                    if ($scope.sub && !$scope.sub_op && validity.setInvalid) {
                        validity.setInvalid();
                    }
                }

                $scope.doSelected = function () {
                    if (!$scope.tmp) {
                        $scope.selected[$scope.i] = null;
                        validity.setInitial();
                    } else {
                        $scope.selected[$scope.i] = {
                            'field_id': $scope.tmp
                        };
                        validity.setValid();
                    }

                    $scope.sub = null;
                    $scope.sub_op = null;

                    populateSubOptions();
                };

                $scope.doSub = function () {
                    $scope.selected[$scope.i].option = $scope.sub_op;
                    if ($scope.sub_op) {
                        return validity.setValid();
                    }
                    validity.setInvalid();
                };
            },
            template: '<div>' + '<span><select class="form-control" chosen="{width: \'100%\'}" ng-options="f.id as f.label for f in fields" ng-model="tmp" ng-change="doSelected()"><option value="">- Don\'t import -</option></select></span>' + '<span class="sub" ng-show="sub"><select class="form-control" chosen="{width: \'100%\'}" ng-options="k as v for (k, v) in sub" ng-model="sub_op" ng-change="doSub()" ><option value="">- Format -</option></select></span>' + '</div>'
        };
    }

    function SettingsImportViewCtrl($scope, $state, $stateParams, $filter, $timeout, import_data, import_results, fieldList, activity, $dialogIntegrationSelect, $geckoSocket) {
        var ctrl = this;
        ctrl.import = import_data;
        ctrl.results = import_results.toArray();
        if (activity) ctrl.activity = activity.toArray();
        ctrl.pagination = import_results.pagination;

        //Add new import dialog
        //ctrl.addImport = addImport.bind(ctrl, $state, $filter);

        // Add new import dialog
        ctrl.addImport = addImport.bind(ctrl, $dialogIntegrationSelect, 'What type of data do you want to import?', ['file_import', Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_SALEFORCE, Gecko.Integration.TYPE_ENGAGE_DYNAMICS], function (integrationId) {
            if (integrationId == 'file_import') {
                return $state.go('settingsimportfile');
            } else if (integrationId) {
                return $state.go('settingsimport', { import_id: 'new', integration_id: integrationId });
            }
        });

        // Watch import progress
        if ([Gecko.Import.STATUS_PENDING, Gecko.Import.STATUS_STARTED].indexOf(ctrl.import.status) != -1) {
            $geckoSocket.watch('import', ctrl.import, ['status', 'progress'], function (data) {
                if (data.status === Gecko.Import.STATUS_COMPLETE) {
                    $state.go($state.$current.name, $stateParams, { reload: true });
                }
            });
        }

        // Stats card
        ctrl.stats = [{
            title: 'Imported',
            aggregate: ctrl.import.successful || 0
        }, {
            title: 'Failed Validation',
            aggregate: ctrl.import.failed || 0,
            caption: {
                title: 'Download failed items',
                action: function action() {
                    window.location.href = ctrl.import.failed_url;
                },
                hideWhen: function hideWhen(stat) {
                    return !stat.aggregate || ctrl.import.type !== Gecko.Import.TYPE_CSV || ctrl.import.status !== Gecko.Import.STATUS_COMPLETE;
                }
            }
        }, {
            title: 'Ignored Duplicates',
            aggregate: ctrl.import.duplicated || 0
        }, {
            title: 'Merged',
            aggregate: ctrl.import.merged || 0
        }];

        ctrl.all_time_stats = [{
            title: 'Imported',
            aggregate: ctrl.import.all_time_successful || 0
        }, {
            title: 'Ignored Duplicates',
            aggregate: ctrl.import.all_time_duplicated || 0
        }, {
            title: 'Merged',
            aggregate: ctrl.import.all_time_merged || 0
        }];

        ctrl.headerBtns = [{
            title: 'View All Time Summary',
            icon: 'fa-retweet',
            action: function action() {
                ctrl.allTimeVisible = !ctrl.allTimeVisible;
            }
        }];

        ctrl.allTimeVisible = false;
        ctrl.allTimeHeaderBtns = [{
            title: 'View Summary',
            icon: 'fa-retweet',
            action: function action() {
                ctrl.allTimeVisible = !ctrl.allTimeVisible;
            }
        }];

        // Action buttons for recurring
        ctrl.actionsBtn = {
            icon: 'fa-cogs',
            btnTooltip: 'Actions'
        };
        ctrl.actionsBtn.items = [{
            title: 'Edit Recurring Import',
            action: function action() {
                $state.go('settingsimport', { import_id: ctrl.import.id, integration_id: ctrl.import.integration_id });
            }
        }, {
            title: 'Trigger Import Now',
            action: function action() {
                ctrl.import.trigger().then(function (import_data) {
                    GeckoUI.messenger.success(import_data.title + " has been triggered and will be processed shortly");
                    $state.go('importsview', $stateParams, { reload: true });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err);
                });
            }
        }, {
            title: 'Remove Recurring Import',
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this recurring import?', function (value) {
                    if (value) {
                        ctrl.import.remove().then(function () {
                            $state.go('settingsimports');
                            GeckoUI.messenger.success('Your recurring import has been deleted.');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }];

        // Stat card options
        ctrl.optionsBtn = {
            iconRight: 'fa-cogs',
            btnTooltip: 'Actions',
            hideWhen: function hideWhen() {
                return ctrl.import.status !== Gecko.Import.STATUS_COMPLETE;
            }
        };

        ctrl.optionsBtn.items = [{
            title: 'Rollback this import',
            hideWhen: function hideWhen() {
                return !ctrl.import.id || !(ctrl.import.status === Gecko.Import.STATUS_COMPLETE) || ctrl.import.import_to === 'events';
            },
            action: function action() {
                GeckoUI.dialog.confirm('All contacts and responses associated with this import, whether the import created them or not, will be deleted. Are you sure you want to rollback this import?', function (value) {
                    if (value) {
                        ctrl.import.rollback().then(function () {
                            $state.go('settingsimports');
                            GeckoUI.messenger.success('Your import is being reverted, the content will be removed shortly.');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }];

        if (ctrl.import.type == 'csv') {
            ctrl.optionsBtn.items.push({
                title: 'Re-import',
                action: function action() {
                    return $state.go('settingsimportfile', { import_id: ctrl.import.id });
                },
                hideWhen: function hideWhen() {
                    return ctrl.import.status != Gecko.Import.STATUS_COMPLETE;
                }
            });
        } else {
            ctrl.optionsBtn.items.push({
                title: 'Re-trigger import',
                action: function action() {
                    ctrl.import.trigger().then(function (import_data) {
                        GeckoUI.messenger.success(row.title + " has been triggered and will be processed shortly");
                        $state.go('importsview', { import_id: ctrl.import.id }, { reload: true });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                    });
                },
                hideWhen: function hideWhen() {
                    return !ctrl.import.schedule;
                }
            });
        }

        // Contacts table
        if (ctrl.import.import_to == 'contacts') {
            // Table structure
            ctrl.fieldList = fieldList ? fieldList.toArray() : false;
            ctrl.cols = [];
            angular.forEach(ctrl.fieldList, function (col, index) {
                ctrl.cols.push({
                    type: col.type,
                    data_type: col.data_type,
                    default_value: col.label.indexOf('Last') > -1 && col.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP ? 'Never' : '',
                    title: col.label,
                    key: 'field_' + (index + 1),
                    sortKey: 'field_' + (index + 1),
                    colClass: 12 % ctrl.fieldList.length == 0 ? 'col-md-' + 12 / ctrl.fieldList.length : '',
                    utc: !col.system && col.type == Gecko.Field.TYPE_DATE,
                    date_format: !col.system && col.type == Gecko.Field.TYPE_DATE ? Gecko.dateFormat.short : undefined
                });
            });

            // Table row action
            ctrl.rowAction = {
                state: 'contact.overview',
                params: { contact_id: 'id' }
            };
        } else if (ctrl.import.import_to == 'organisations') {

            // Loop through each organisation and format the values to something useful
            angular.forEach(ctrl.results, function (organisation) {
                angular.forEach(organisation.values, function (value) {
                    organisation['field_' + value.field_id] = value.safe;
                });
            });

            // Table structure
            ctrl.fieldList = fieldList ? fieldList.toArray() : false;

            ctrl.cols = [{
                key: 'title',
                title: 'Title',
                colClass: 'col-xs-3'
            }];

            angular.forEach(ctrl.fieldList, function (col) {
                ctrl.cols.push({
                    type: col.type,
                    data_type: col.data_type,
                    title: col.label,
                    key: 'field_' + col.id,
                    colClass: 12 % ctrl.fieldList.length == 0 ? 'col-md-' + 12 / ctrl.fieldList.length : ''
                });
            });

            // Table row action
            ctrl.rowAction = {
                state: 'organisation.overview',
                params: { organisation_id: 'id' }
            };
        } else if (ctrl.import.import_to == 'responses') {
            // Table structure
            ctrl.cols = [{
                data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
                title: 'Date created',
                key: 'created_at',
                sortKey: 'created_at',
                colClass: 'col-md-3'
            }, {
                type: Gecko.Field.TYPE_NAME,
                title: 'Contact',
                key: 'related_contact.full_name',
                colClass: 'col-md-3',
                render: function render(value, col, row) {
                    if (!value) return row.first_field;
                    return value;
                }
            }, {
                title: 'Email',
                key: 'related_contact.email',
                colClass: 'col-md-3',
                render: function render(value, col, row) {
                    if (!value) return row.second_field;
                    return value;
                }
            }, {
                title: 'Form',
                key: 'form.name',
                render: function render(value, col, row) {
                    if (row.form && row.form.internal_name) return row.form.internal_name;
                    return value;
                },
                colClass: 'col-md-3'
            }];

            // Table row action
            ctrl.rowAction = {
                state: 'responseview',
                params: { response_id: 'id' }
            };
        }

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Imports',
            click: function click() {
                $state.go('settingsimports');
            }
        }, {
            label: ctrl.import.title,
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('SettingsImportsCtrl', SettingsImportsCtrl).controller('SettingsImportViewCtrl', SettingsImportViewCtrl).controller('SettingsCSVImportConfigCtrl', SettingsCSVImportConfigCtrl).directive('fieldSelector', fieldSelector).directive('fieldSelectorValidityClass', fieldSelectorValidityClass);
})();