var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

!function () {
  var a = {},
      b = function b(_b) {
    for (var c = a[_b], e = c.deps, f = c.defn, g = e.length, h = new Array(g), i = 0; i < g; ++i) {
      h[i] = d(e[i]);
    }var j = f.apply(null, h);if (void 0 === j) throw "module [" + _b + "] returned undefined";c.instance = j;
  },
      c = function c(b, _c, d) {
    if ("string" != typeof b) throw "module id must be a string";if (void 0 === _c) throw "no dependencies for " + b;if (void 0 === d) throw "no definition function for " + b;a[b] = { deps: _c, defn: d, instance: void 0 };
  },
      d = function d(c) {
    var d = a[c];if (void 0 === d) throw "module [" + c + "] was undefined";return void 0 === d.instance && b(c), d.instance;
  },
      e = function e(a, b) {
    for (var c = a.length, e = new Array(c), f = 0; f < c; ++f) {
      e[f] = d(a[f]);
    }b.apply(null, e);
  },
      f = {};f.bolt = { module: { api: { define: c, require: e, demand: d } } };var g = c,
      h = function h(a, b) {
    g(a, [], function () {
      return b;
    });
  };h("1", window), h("6", tinymce.util.Tools.resolve), g("2", ["6"], function (a) {
    return a("tinymce.ThemeManager");
  }), g("13", ["6"], function (a) {
    return a("tinymce.EditorManager");
  }), g("c", ["6"], function (a) {
    return a("tinymce.util.Tools");
  }), g("2f", ["13", "c"], function (a, b) {
    var c = function c(a) {
      return a.getParam("branding", !0);
    },
        d = function d(a) {
      return e(a) !== !1;
    },
        e = function e(a) {
      return a.getParam("menubar");
    },
        f = function f(a) {
      return a.getParam("statusbar", !0);
    },
        g = function g(a) {
      return a.getParam("toolbar_items_size");
    },
        h = function h(a) {
      var b = a.getParam("resize", "vertical");return b === !1 ? "none" : "both" === b ? "both" : "vertical";
    },
        i = function i(a) {
      return a.getParam("readonly", !1);
    },
        j = function j(a) {
      return a.getParam("fixed_toolbar_container");
    },
        k = function k(a) {
      return a.getParam("inline_toolbar_position_handler");
    },
        l = function l(a) {
      return a.getParam("menu");
    },
        m = function m(a) {
      return a.getParam("removed_menuitems", "");
    },
        n = function n(a) {
      return a.getParam("min_width", 100);
    },
        o = function o(a) {
      return a.getParam("min_height", 100);
    },
        p = function p(a) {
      return a.getParam("max_width", 65535);
    },
        q = function q(a) {
      return a.getParam("max_height", 65535);
    },
        r = function r(b) {
      var c = b.settings,
          d = c.skin,
          e = c.skin_url;if (d !== !1) {
        var f = d ? d : "lightgray";e = e ? b.documentBaseURI.toAbsolute(e) : a.baseURL + "/skins/" + f;
      }return e;
    },
        s = function s(a) {
      return a.getParam("inline", !1);
    },
        t = function t(a, b) {
      for (var c = [], d = 1; d < 10; d++) {
        var e = a["toolbar" + d];if (!e) break;c.push(e);
      }var f = a.toolbar ? [a.toolbar] : [b];return c.length > 0 ? c : f;
    },
        u = function u(a) {
      var c = a.getParam("toolbar"),
          d = "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image";return c === !1 ? [] : b.isArray(c) ? b.grep(c, function (a) {
        return a.length > 0;
      }) : t(a.settings, d);
    };return { isBrandingEnabled: c, hasMenubar: d, getMenubar: e, hasStatusbar: f, getToolbarSize: g, getResize: h, isReadOnly: i, getFixedToolbarContainer: j, getInlineToolbarPositionHandler: k, getMenu: l, getRemovedMenuItems: m, getMinWidth: n, getMinHeight: o, getMaxWidth: p, getMaxHeight: q, getSkinUrl: r, isInline: s, getToolbars: u };
  }), g("2j", ["6"], function (a) {
    return a("tinymce.dom.DOMUtils");
  }), g("b", ["6"], function (a) {
    return a("tinymce.ui.Factory");
  }), g("2k", [], function () {
    var a = function a(_a) {
      return _a.fire("SkinLoaded");
    },
        b = function b(a) {
      return a.fire("ResizeEditor");
    },
        c = function c(a) {
      return a.fire("BeforeRenderUI");
    };return { fireSkinLoaded: a, fireResizeEditor: b, fireBeforeRenderUI: c };
  }), g("37", [], function () {
    var a = function a(_a2, b) {
      return function () {
        var c = _a2.find(b)[0];c && c.focus(!0);
      };
    },
        b = function b(_b2, c) {
      _b2.shortcuts.add("Alt+F9", "", a(c, "menubar")), _b2.shortcuts.add("Alt+F10,F10", "", a(c, "toolbar")), _b2.shortcuts.add("Alt+F11", "", a(c, "elementpath")), c.on("cancel", function () {
        _b2.focus();
      });
    };return { addKeys: b };
  }), h("12", document), g("3o", ["6"], function (a) {
    return a("tinymce.geom.Rect");
  }), g("2u", ["6"], function (a) {
    return a("tinymce.util.Delay");
  }), g("3c", ["b", "c", "2f"], function (a, b, c) {
    var d = function d(c, _d, e) {
      var f,
          g = [];if (_d) return b.each(_d.split(/[ ,]/), function (b) {
        var d,
            h = function h() {
          var a = c.selection;b.settings.stateSelector && a.selectorChanged(b.settings.stateSelector, function (a) {
            b.active(a);
          }, !0), b.settings.disabledStateSelector && a.selectorChanged(b.settings.disabledStateSelector, function (a) {
            b.disabled(a);
          });
        };"|" === b ? f = null : (f || (f = { type: "buttongroup", items: [] }, g.push(f)), c.buttons[b] && (d = b, b = c.buttons[d], "function" == typeof b && (b = b()), b.type = b.type || "button", b.size = e, b = a.create(b), f.items.push(b), c.initialized ? h() : c.on("init", h)));
      }), { type: "toolbar", layout: "flow", items: g };
    },
        e = function e(a, _e) {
      var f = [],
          g = function g(b) {
        b && f.push(d(a, b, _e));
      };if (b.each(c.getToolbars(a), function (a) {
        g(a);
      }), f.length) return { type: "panel", layout: "stack", classes: "toolbar-grp", ariaRoot: !0, ariaRemember: !0, items: f };
    };return { createToolbar: d, createToolbars: e };
  }), g("38", ["12", "2j", "3o", "b", "2u", "c", "2f", "3c"], function (a, b, c, d, e, f, g, h) {
    var i = b.DOM,
        j = function j(a) {
      return { left: a.x, top: a.y, width: a.w, height: a.h, right: a.x + a.w, bottom: a.y + a.h };
    },
        k = function k(a) {
      f.each(a.contextToolbars, function (a) {
        a.panel && a.panel.hide();
      });
    },
        l = function l(a, b) {
      a.moveTo(b.left, b.top);
    },
        m = function m(a, b, c) {
      b = b ? b.substr(0, 2) : "", f.each({ t: "down", b: "up" }, function (d, e) {
        a.classes.toggle("arrow-" + d, c(e, b.substr(0, 1)));
      }), f.each({ l: "left", r: "right" }, function (d, e) {
        a.classes.toggle("arrow-" + d, c(e, b.substr(1, 1)));
      });
    },
        n = function n(a, b, c, d, e, f) {
      return f = j({ x: b, y: c, w: f.w, h: f.h }), a && (f = a({ elementRect: j(d), contentAreaRect: j(e), panelRect: f })), f;
    },
        o = function o(b) {
      var j,
          o = function o() {
        return b.contextToolbars || [];
      },
          p = function p(a) {
        var c, d, e;return c = i.getPos(b.getContentAreaContainer()), d = b.dom.getRect(a), e = b.dom.getRoot(), "BODY" === e.nodeName && (d.x -= e.ownerDocument.documentElement.scrollLeft || e.scrollLeft, d.y -= e.ownerDocument.documentElement.scrollTop || e.scrollTop), d.x += c.x, d.y += c.y, d;
      },
          q = function q(a, d) {
        var e,
            f,
            h,
            j,
            o,
            q,
            r,
            s,
            t = g.getInlineToolbarPositionHandler(b);if (!b.removed) {
          if (!a || !a.toolbar.panel) return void k(b);r = ["bc-tc", "tc-bc", "tl-bl", "bl-tl", "tr-br", "br-tr"], o = a.toolbar.panel, d && o.show(), h = p(a.element), f = i.getRect(o.getEl()), j = i.getRect(b.getContentAreaContainer() || b.getBody()), s = 25, "inline" !== i.getStyle(a.element, "display", !0) && (h.w = a.element.clientWidth, h.h = a.element.clientHeight), b.inline || (j.w = b.getDoc().documentElement.offsetWidth), b.selection.controlSelection.isResizable(a.element) && h.w < s && (h = c.inflate(h, 0, 8)), e = c.findBestRelativePosition(f, h, j, r), h = c.clamp(h, j), e ? (q = c.relativePosition(f, h, e), l(o, n(t, q.x, q.y, h, j, f))) : (j.h += f.h, h = c.intersect(j, h), h ? (e = c.findBestRelativePosition(f, h, j, ["bc-tc", "bl-tl", "br-tr"]), e ? (q = c.relativePosition(f, h, e), l(o, n(t, q.x, q.y, h, j, f))) : l(o, n(t, h.x, h.y, h, j, f))) : o.hide()), m(o, e, function (a, b) {
            return a === b;
          });
        }
      },
          r = function r(a) {
        return function () {
          var c = function c() {
            b.selection && q(v(b.selection.getNode()), a);
          };e.requestAnimationFrame(c);
        };
      },
          s = function s() {
        j || (j = b.selection.getScrollContainer() || b.getWin(), i.bind(j, "scroll", r(!0)), b.on("remove", function () {
          i.unbind(j, "scroll");
        }));
      },
          t = function t(c) {
        var e;return c.toolbar.panel ? (c.toolbar.panel.show(), void q(c)) : (s(), e = d.create({ type: "floatpanel", role: "dialog", classes: "tinymce tinymce-inline arrow", ariaLabel: "Inline toolbar", layout: "flex", direction: "column", align: "stretch", autohide: !1, autofix: !0, fixed: !0, border: 1, items: h.createToolbar(b, c.toolbar.items), oncancel: function oncancel() {
            b.focus();
          } }), c.toolbar.panel = e, e.renderTo(a.body).reflow(), void q(c));
      },
          u = function u() {
        f.each(o(), function (a) {
          a.panel && a.panel.hide();
        });
      },
          v = function v(a) {
        var c,
            d,
            e,
            f = o();for (e = b.$(a).parents().add(a), c = e.length - 1; c >= 0; c--) {
          for (d = f.length - 1; d >= 0; d--) {
            if (f[d].predicate(e[c])) return { toolbar: f[d], element: e[c] };
          }
        }return null;
      };b.on("click keyup setContent ObjectResized", function (a) {
        ("setcontent" !== a.type || a.selection) && e.setEditorTimeout(b, function () {
          var a;a = v(b.selection.getNode()), a ? (u(), t(a)) : u();
        });
      }), b.on("blur hide contextmenu", u), b.on("ObjectResizeStart", function () {
        var a = v(b.selection.getNode());a && a.toolbar.panel && a.toolbar.panel.hide();
      }), b.on("ResizeEditor ResizeWindow", r(!0)), b.on("nodeChange", r(!1)), b.on("remove", function () {
        f.each(o(), function (a) {
          a.panel && a.panel.remove();
        }), b.contextToolbars = {};
      }), b.shortcuts.add("ctrl+shift+e > ctrl+shift+p", "", function () {
        var a = v(b.selection.getNode());a && a.toolbar.panel && a.toolbar.panel.items()[0].focus();
      });
    };return { addContextualToolbars: o };
  }), h("2y", Array), h("2z", Error), g("z", ["2y", "2z"], function (a, b) {
    var c = function c() {},
        d = function d(a, b) {
      return function () {
        return a(b.apply(null, arguments));
      };
    },
        e = function e(a) {
      return function () {
        return a;
      };
    },
        f = function f(a) {
      return a;
    },
        g = function g(a, b) {
      return a === b;
    },
        h = function h(b) {
      for (var c = new a(arguments.length - 1), d = 1; d < arguments.length; d++) {
        c[d - 1] = arguments[d];
      }return function () {
        for (var d = new a(arguments.length), e = 0; e < d.length; e++) {
          d[e] = arguments[e];
        }var f = c.concat(d);return b.apply(null, f);
      };
    },
        i = function i(a) {
      return function () {
        return !a.apply(null, arguments);
      };
    },
        j = function j(a) {
      return function () {
        throw new b(a);
      };
    },
        k = function k(a) {
      return a();
    },
        l = function l(a) {
      a();
    },
        m = e(!1),
        n = e(!0);return { noop: c, compose: d, constant: e, identity: f, tripleEquals: g, curry: h, not: i, die: j, apply: k, call: l, never: m, always: n };
  }), h("3p", Object), g("3f", ["z", "3p"], function (a, b) {
    var c = a.never,
        d = a.always,
        e = function e() {
      return f;
    },
        f = function () {
      var f = function f(a) {
        return a.isNone();
      },
          g = function g(a) {
        return a();
      },
          h = function h(a) {
        return a;
      },
          i = function i() {},
          j = { fold: function fold(a, b) {
          return a();
        }, is: c, isSome: c, isNone: d, getOr: h, getOrThunk: g, getOrDie: function getOrDie(a) {
          throw new Error(a || "error: getOrDie called on none.");
        }, or: h, orThunk: g, map: e, ap: e, each: i, bind: e, flatten: e, exists: c, forall: d, filter: e, equals: f, equals_: f, toArray: function toArray() {
          return [];
        }, toString: a.constant("none()") };return b.freeze && b.freeze(j), j;
    }(),
        g = function g(a) {
      var b = function b() {
        return a;
      },
          h = function h() {
        return k;
      },
          i = function i(b) {
        return g(b(a));
      },
          j = function j(b) {
        return b(a);
      },
          k = { fold: function fold(b, c) {
          return c(a);
        }, is: function is(b) {
          return a === b;
        }, isSome: d, isNone: c, getOr: b, getOrThunk: b, getOrDie: b, or: h, orThunk: h, map: i, ap: function ap(b) {
          return b.fold(e, function (b) {
            return g(b(a));
          });
        }, each: function each(b) {
          b(a);
        }, bind: j, flatten: b, exists: j, forall: j, filter: function filter(b) {
          return b(a) ? k : f;
        }, equals: function equals(b) {
          return b.is(a);
        }, equals_: function equals_(b, d) {
          return b.fold(c, function (b) {
            return d(a, b);
          });
        }, toArray: function toArray() {
          return [a];
        }, toString: function toString() {
          return "some(" + a + ")";
        } };return k;
    },
        h = function h(a) {
      return null === a || void 0 === a ? f : g(a);
    };return { some: g, none: e, from: h };
  }), h("3g", String), g("2l", ["3f", "2y", "2z", "3g"], function (a, b, c, d) {
    var e = function () {
      var a = b.prototype.indexOf,
          c = function c(b, _c2) {
        return a.call(b, _c2);
      },
          d = function d(a, b) {
        return u(a, b);
      };return void 0 === a ? d : c;
    }(),
        f = function f(b, c) {
      var d = e(b, c);return d === -1 ? a.none() : a.some(d);
    },
        g = function g(a, b) {
      return e(a, b) > -1;
    },
        h = function h(a, b) {
      return t(a, b).isSome();
    },
        i = function i(a, b) {
      for (var c = [], d = 0; d < a; d++) {
        c.push(b(d));
      }return c;
    },
        j = function j(a, b) {
      for (var c = [], d = 0; d < a.length; d += b) {
        var e = a.slice(d, d + b);c.push(e);
      }return c;
    },
        k = function k(a, c) {
      for (var d = a.length, e = new b(d), f = 0; f < d; f++) {
        var g = a[f];e[f] = c(g, f, a);
      }return e;
    },
        l = function l(a, b) {
      for (var c = 0, d = a.length; c < d; c++) {
        var e = a[c];b(e, c, a);
      }
    },
        m = function m(a, b) {
      for (var c = a.length - 1; c >= 0; c--) {
        var d = a[c];b(d, c, a);
      }
    },
        n = function n(a, b) {
      for (var c = [], d = [], e = 0, f = a.length; e < f; e++) {
        var g = a[e],
            h = b(g, e, a) ? c : d;h.push(g);
      }return { pass: c, fail: d };
    },
        o = function o(a, b) {
      for (var c = [], d = 0, e = a.length; d < e; d++) {
        var f = a[d];b(f, d, a) && c.push(f);
      }return c;
    },
        p = function p(a, b) {
      if (0 === a.length) return [];for (var c = b(a[0]), d = [], e = [], f = 0, g = a.length; f < g; f++) {
        var h = a[f],
            i = b(h);i !== c && (d.push(e), e = []), c = i, e.push(h);
      }return 0 !== e.length && d.push(e), d;
    },
        q = function q(a, b, c) {
      return m(a, function (a) {
        c = b(c, a);
      }), c;
    },
        r = function r(a, b, c) {
      return l(a, function (a) {
        c = b(c, a);
      }), c;
    },
        s = function s(b, c) {
      for (var d = 0, e = b.length; d < e; d++) {
        var f = b[d];if (c(f, d, b)) return a.some(f);
      }return a.none();
    },
        t = function t(b, c) {
      for (var d = 0, e = b.length; d < e; d++) {
        var f = b[d];if (c(f, d, b)) return a.some(d);
      }return a.none();
    },
        u = function u(a, b) {
      for (var c = 0, d = a.length; c < d; ++c) {
        if (a[c] === b) return c;
      }return -1;
    },
        v = b.prototype.push,
        w = function w(a) {
      for (var d = [], e = 0, f = a.length; e < f; ++e) {
        if (!b.prototype.isPrototypeOf(a[e])) throw new c("Arr.flatten item " + e + " was not an array, input: " + a);v.apply(d, a[e]);
      }return d;
    },
        x = function x(a, b) {
      var c = k(a, b);return w(c);
    },
        y = function y(a, b) {
      for (var c = 0, d = a.length; c < d; ++c) {
        var e = a[c];if (b(e, c, a) !== !0) return !1;
      }return !0;
    },
        z = function z(a, b) {
      return a.length === b.length && y(a, function (a, c) {
        return a === b[c];
      });
    },
        A = b.prototype.slice,
        B = function B(a) {
      var b = A.call(a, 0);return b.reverse(), b;
    },
        C = function C(a, b) {
      return o(a, function (a) {
        return !g(b, a);
      });
    },
        D = function D(a, b) {
      for (var c = {}, e = 0, f = a.length; e < f; e++) {
        var g = a[e];c[d(g)] = b(g, e);
      }return c;
    },
        E = function E(a) {
      return [a];
    },
        F = function F(a, b) {
      var c = A.call(a, 0);return c.sort(b), c;
    },
        G = function G(b) {
      return 0 === b.length ? a.none() : a.some(b[0]);
    },
        H = function H(b) {
      return 0 === b.length ? a.none() : a.some(b[b.length - 1]);
    };return { map: k, each: l, eachr: m, partition: n, filter: o, groupBy: p, indexOf: f, foldr: q, foldl: r, find: s, findIndex: t, flatten: w, bind: x, forall: y, exists: h, contains: g, equal: z, reverse: B, chunk: j, difference: C, mapToObject: D, pure: E, sort: F, range: i, head: G, last: H };
  }), g("39", ["2l", "z", "c", "2f"], function (a, b, c, d) {
    var e = { file: { title: "File", items: "newdocument restoredraft | preview | print" }, edit: { title: "Edit", items: "undo redo | cut copy paste pastetext | selectall" }, view: { title: "View", items: "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen" }, insert: { title: "Insert", items: "image link media template codesample inserttable | charmap hr | pagebreak nonbreaking anchor toc | insertdatetime" }, format: { title: "Format", items: "bold italic underline strikethrough superscript subscript codeformat | blockformats align | removeformat" }, tools: { title: "Tools", items: "spellchecker spellcheckerlanguage | a11ycheck" }, table: { title: "Table" }, help: { title: "Help" } },
        f = function f() {
      return { name: "|", item: { text: "|" } };
    },
        g = function g(a, b) {
      var c = b ? { name: a, item: b } : null;return "|" === a ? f() : c;
    },
        h = function h(b, c) {
      return a.findIndex(b, function (a) {
        return a.name === c;
      }).isSome();
    },
        i = function i(a) {
      return a && "|" === a.item.text;
    },
        j = function j(b, c) {
      var d = a.filter(b, function (a) {
        return c.hasOwnProperty(a.name) === !1;
      }),
          e = a.filter(d, function (a, b, c) {
        return !i(a) || !i(c[b - 1]);
      });return a.filter(e, function (a, b, c) {
        return !i(a) || b > 0 && b < c.length - 1;
      });
    },
        k = function k(b, d, i, _k) {
      var l, m, n, o;return d ? (m = d[_k], o = !0) : m = e[_k], m && (l = { text: m.title }, n = [], c.each((m.items || "").split(/[ ,]/), function (a) {
        var c = g(a, b[a]);c && n.push(c);
      }), o || c.each(b, function (a, b) {
        a.context !== _k || h(n, b) || ("before" === a.separator && n.push(f()), a.prependToContext ? n.unshift(g(b, a)) : n.push(g(b, a)), "after" === a.separator && n.push(f()));
      }), l.menu = a.map(j(n, i), function (a) {
        return a.item;
      }), !l.menu.length) ? null : l;
    },
        l = function l(a) {
      var b,
          c = [],
          f = d.getMenu(a);if (f) for (b in f) {
        c.push(b);
      } else for (b in e) {
        c.push(b);
      }return c;
    },
        m = function m(a) {
      for (var b = [], e = l(a), f = c.makeMap(d.getRemovedMenuItems(a).split(/[ ,]/)), g = d.getMenubar(a), h = "string" == typeof g ? g.split(/[ ,]/) : e, i = 0; i < h.length; i++) {
        var j = h[i],
            m = k(a.menuItems, d.getMenu(a), f, j);m && b.push(m);
      }return b;
    };return { createMenuButtons: m };
  }), g("8", ["2j", "2k", "2f"], function (a, b, c) {
    var d = a.DOM,
        e = function e(a) {
      return { width: a.clientWidth, height: a.clientHeight };
    },
        f = function f(a, _f, g) {
      var h, i, j, k;h = a.getContainer(), i = a.getContentAreaContainer().firstChild, j = e(h), k = e(i), null !== _f && (_f = Math.max(c.getMinWidth(a), _f), _f = Math.min(c.getMaxWidth(a), _f), d.setStyle(h, "width", _f + (j.width - k.width)), d.setStyle(i, "width", _f)), g = Math.max(c.getMinHeight(a), g), g = Math.min(c.getMaxHeight(a), g), d.setStyle(i, "height", g), b.fireResizeEditor(a);
    },
        g = function g(a, b, c) {
      var d = a.getContentAreaContainer();f(a, d.clientWidth + b, d.clientHeight + c);
    };return { resizeTo: f, resizeBy: g };
  }), g("14", ["6"], function (a) {
    return a("tinymce.Env");
  }), g("3a", ["14", "b", "c", "2k"], function (a, b, c, d) {
    var e = function e(a) {
      return { element: function element() {
          return a;
        } };
    },
        f = function f(a, b, c) {
      var d = a.settings[c];d && d(e(b.getEl("body")));
    },
        g = function g(a, b, d) {
      c.each(d, function (c) {
        var d = b.items().filter("#" + c.name)[0];d && d.visible() && c.name !== a && (f(c, d, "onhide"), d.visible(!1));
      });
    },
        h = function h(a) {
      a.items().each(function (a) {
        a.active(!1);
      });
    },
        i = function i(a, b) {
      return c.grep(a, function (a) {
        return a.name === b;
      })[0];
    },
        j = function j(a, c, e) {
      return function (j) {
        var k = j.control,
            l = k.parents().filter("panel")[0],
            m = l.find("#" + c)[0],
            n = i(e, c);g(c, l, e), h(k.parent()), m && m.visible() ? (f(n, m, "onhide"), m.hide(), k.active(!1)) : (m ? (m.show(), f(n, m, "onshow")) : (m = b.create({ type: "container", name: c, layout: "stack", classes: "sidebar-panel", html: "" }), l.prepend(m), f(n, m, "onrender"), f(n, m, "onshow")), k.active(!0)), d.fireResizeEditor(a);
      };
    },
        k = function k() {
      return !a.ie || a.ie >= 11;
    },
        l = function l(a) {
      return !(!k() || !a.sidebars) && a.sidebars.length > 0;
    },
        m = function m(a) {
      var b = c.map(a.sidebars, function (b) {
        var c = b.settings;return { type: "button", icon: c.icon, image: c.image, tooltip: c.tooltip, onclick: j(a, b.name, a.sidebars) };
      });return { type: "panel", name: "sidebar", layout: "stack", classes: "sidebar", items: [{ type: "toolbar", layout: "stack", classes: "sidebar-toolbar", items: b }] };
    };return { hasSidebar: l, createSidebar: m };
  }), g("3b", ["2k"], function (a) {
    var b = function b(_b3) {
      var c = function c() {
        _b3._skinLoaded = !0, a.fireSkinLoaded(_b3);
      };return function () {
        _b3.initialized ? c() : _b3.on("init", c);
      };
    };return { fireSkinLoaded: b };
  }), g("2g", ["2j", "b", "c", "2k", "2f", "37", "38", "39", "8", "3a", "3b", "3c"], function (a, b, c, d, e, f, g, h, i, j, k, l) {
    var m = a.DOM,
        n = function n(a) {
      return function (b) {
        a.find("*").disabled("readonly" === b.mode);
      };
    },
        o = function o(a) {
      return { type: "panel", name: "iframe", layout: "stack", classes: "edit-area", border: a, html: "" };
    },
        p = function p(a) {
      return { type: "panel", layout: "stack", classes: "edit-aria-container", border: "1 0 0 0", items: [o("0"), j.createSidebar(a)] };
    },
        q = function q(a, c, _q) {
      var r, s, t;if (_q.skinUiCss && m.styleSheetLoader.load(_q.skinUiCss, k.fireSkinLoaded(a)), r = c.panel = b.create({ type: "panel", role: "application", classes: "tinymce", style: "visibility: hidden", layout: "stack", border: 1, items: [{ type: "container", classes: "top-part", items: [e.hasMenubar(a) === !1 ? null : { type: "menubar", border: "0 0 1 0", items: h.createMenuButtons(a) }, l.createToolbars(a, e.getToolbarSize(a))] }, j.hasSidebar(a) ? p(a) : o("1 0 0 0")] }), "none" !== e.getResize(a) && (s = { type: "resizehandle", direction: e.getResize(a), onResizeStart: function onResizeStart() {
          var b = a.getContentAreaContainer().firstChild;t = { width: b.clientWidth, height: b.clientHeight };
        }, onResize: function onResize(b) {
          "both" === e.getResize(a) ? i.resizeTo(a, t.width + b.deltaX, t.height + b.deltaY) : i.resizeTo(a, null, t.height + b.deltaY);
        } }), e.hasStatusbar(a)) {
        var u = e.isBrandingEnabled(a) ? { type: "label", classes: "branding", html: ' powered by <a href="https://www.tinymce.com/?utm_campaign=editor_referral&utm_medium=poweredby&utm_source=tinymce" rel="noopener" target="_blank">tinymce</a>' } : null;r.add({ type: "panel", name: "statusbar", classes: "statusbar", layout: "flow", border: "1 0 0 0", ariaRoot: !0, items: [{ type: "elementpath", editor: a }, s, u] });
      }return d.fireBeforeRenderUI(a), a.on("SwitchMode", n(r)), r.renderBefore(_q.targetNode).reflow(), e.isReadOnly(a) && a.setMode("readonly"), _q.width && m.setStyle(r.getEl(), "width", _q.width), a.on("remove", function () {
        r.remove(), r = null;
      }), f.addKeys(a, r), g.addContextualToolbars(a), { iframeContainer: r.find("#iframe")[0].getEl(), editorContainer: r.getEl() };
    };return { render: q };
  }), g("2o", ["6"], function (a) {
    return a("tinymce.dom.DomQuery");
  }), g("2n", ["12", "2j", "14", "c"], function (a, b, c, d) {
    "use strict";
    var e = 0,
        f = { id: function id() {
        return "mceu_" + e++;
      }, create: function create(c, e, f) {
        var g = a.createElement(c);return b.DOM.setAttribs(g, e), "string" == typeof f ? g.innerHTML = f : d.each(f, function (a) {
          a.nodeType && g.appendChild(a);
        }), g;
      }, createFragment: function createFragment(a) {
        return b.DOM.createFragment(a);
      }, getWindowSize: function getWindowSize() {
        return b.DOM.getViewPort();
      }, getSize: function getSize(a) {
        var b, c;if (a.getBoundingClientRect) {
          var d = a.getBoundingClientRect();b = Math.max(d.width || d.right - d.left, a.offsetWidth), c = Math.max(d.height || d.bottom - d.bottom, a.offsetHeight);
        } else b = a.offsetWidth, c = a.offsetHeight;return { width: b, height: c };
      }, getPos: function getPos(a, c) {
        return b.DOM.getPos(a, c || f.getContainer());
      }, getContainer: function getContainer() {
        return c.container ? c.container : a.body;
      }, getViewPort: function getViewPort(a) {
        return b.DOM.getViewPort(a);
      }, get: function get(b) {
        return a.getElementById(b);
      }, addClass: function addClass(a, c) {
        return b.DOM.addClass(a, c);
      }, removeClass: function removeClass(a, c) {
        return b.DOM.removeClass(a, c);
      }, hasClass: function hasClass(a, c) {
        return b.DOM.hasClass(a, c);
      }, toggleClass: function toggleClass(a, c, d) {
        return b.DOM.toggleClass(a, c, d);
      }, css: function css(a, c, d) {
        return b.DOM.setStyle(a, c, d);
      }, getRuntimeStyle: function getRuntimeStyle(a, c) {
        return b.DOM.getStyle(a, c, !0);
      }, on: function on(a, c, d, e) {
        return b.DOM.bind(a, c, d, e);
      }, off: function off(a, c, d) {
        return b.DOM.unbind(a, c, d);
      }, fire: function fire(a, c, d) {
        return b.DOM.fire(a, c, d);
      }, innerHtml: function innerHtml(a, c) {
        b.DOM.setHTML(a, c);
      } };return f;
  }), g("1s", ["12", "1", "2n"], function (a, b, c) {
    "use strict";
    function d(b, d, e) {
      var f, g, h, i, j, k, l, m, n, o;return n = c.getViewPort(), g = c.getPos(d), h = g.x, i = g.y, b.state.get("fixed") && "static" == c.getRuntimeStyle(a.body, "position") && (h -= n.x, i -= n.y), f = b.getEl(), o = c.getSize(f), j = o.width, k = o.height, o = c.getSize(d), l = o.width, m = o.height, e = (e || "").split(""), "b" === e[0] && (i += m), "r" === e[1] && (h += l), "c" === e[0] && (i += Math.round(m / 2)), "c" === e[1] && (h += Math.round(l / 2)), "b" === e[3] && (i -= k), "r" === e[4] && (h -= j), "c" === e[3] && (i -= Math.round(k / 2)), "c" === e[4] && (h -= Math.round(j / 2)), { x: h, y: i, w: j, h: k };
    }return { testMoveRel: function testMoveRel(a, b) {
        for (var e = c.getViewPort(), f = 0; f < b.length; f++) {
          var g = d(this, a, b[f]);if (this.state.get("fixed")) {
            if (g.x > 0 && g.x + g.w < e.w && g.y > 0 && g.y + g.h < e.h) return b[f];
          } else if (g.x > e.x && g.x + g.w < e.w + e.x && g.y > e.y && g.y + g.h < e.h + e.y) return b[f];
        }return b[0];
      }, moveRel: function moveRel(a, b) {
        "string" != typeof b && (b = this.testMoveRel(a, b));var c = d(this, a, b);return this.moveTo(c.x, c.y);
      }, moveBy: function moveBy(a, b) {
        var c = this,
            d = c.layoutRect();return c.moveTo(d.x + a, d.y + b), c;
      }, moveTo: function moveTo(a, d) {
        function e(a, b, c) {
          return a < 0 ? 0 : a + c > b ? (a = b - c, a < 0 ? 0 : a) : a;
        }var f = this;if (f.settings.constrainToViewport) {
          var g = c.getViewPort(b),
              h = f.layoutRect();a = e(a, g.w + g.x, h.w), d = e(d, g.h + g.y, h.h);
        }return f.state.get("rendered") ? f.layoutRect({ x: a, y: d }).repaint() : (f.settings.x = a, f.settings.y = d), f.fire("move", { x: a, y: d }), f;
      } };
  }), g("2p", ["6"], function (a) {
    return a("tinymce.util.Class");
  }), g("2q", ["6"], function (a) {
    return a("tinymce.util.EventDispatcher");
  }), g("2r", ["12"], function (a) {
    "use strict";
    return { parseBox: function parseBox(a) {
        var b,
            c = 10;if (a) return "number" == typeof a ? (a = a || 0, { top: a, left: a, bottom: a, right: a }) : (a = a.split(" "), b = a.length, 1 === b ? a[1] = a[2] = a[3] = a[0] : 2 === b ? (a[2] = a[0], a[3] = a[1]) : 3 === b && (a[3] = a[1]), { top: parseInt(a[0], c) || 0, right: parseInt(a[1], c) || 0, bottom: parseInt(a[2], c) || 0, left: parseInt(a[3], c) || 0 });
      }, measureBox: function measureBox(b, c) {
        function d(c) {
          var d = a.defaultView;return d ? (c = c.replace(/[A-Z]/g, function (a) {
            return "-" + a;
          }), d.getComputedStyle(b, null).getPropertyValue(c)) : b.currentStyle[c];
        }function e(a) {
          var b = parseFloat(d(a), 10);return isNaN(b) ? 0 : b;
        }return { top: e(c + "TopWidth"), right: e(c + "RightWidth"), bottom: e(c + "BottomWidth"), left: e(c + "LeftWidth") };
      } };
  }), g("2s", ["c"], function (a) {
    "use strict";
    function b() {}function c(a) {
      this.cls = [], this.cls._map = {}, this.onchange = a || b, this.prefix = "";
    }return a.extend(c.prototype, { add: function add(a) {
        return a && !this.contains(a) && (this.cls._map[a] = !0, this.cls.push(a), this._change()), this;
      }, remove: function remove(a) {
        if (this.contains(a)) {
          for (var b = 0; b < this.cls.length && this.cls[b] !== a; b++) {}this.cls.splice(b, 1), delete this.cls._map[a], this._change();
        }return this;
      }, toggle: function toggle(a, b) {
        var c = this.contains(a);return c !== b && (c ? this.remove(a) : this.add(a), this._change()), this;
      }, contains: function contains(a) {
        return !!this.cls._map[a];
      }, _change: function _change() {
        delete this.clsValue, this.onchange.call(this);
      } }), c.prototype.toString = function () {
      var a;if (this.clsValue) return this.clsValue;a = "";for (var b = 0; b < this.cls.length; b++) {
        b > 0 && (a += " "), a += this.prefix + this.cls[b];
      }return a;
    }, c;
  }), g("24", ["2p"], function (a) {
    "use strict";
    function b(a) {
      for (var b, c = [], d = a.length; d--;) {
        b = a[d], b.__checked || (c.push(b), b.__checked = 1);
      }for (d = c.length; d--;) {
        delete c[d].__checked;
      }return c;
    }var c,
        d = /^([\w\\*]+)?(?:#([\w\-\\]+))?(?:\.([\w\\\.]+))?(?:\[\@?([\w\\]+)([\^\$\*!~]?=)([\w\\]+)\])?(?:\:(.+))?/i,
        e = /((?:\((?:\([^()]+\)|[^()]+)+\)|\[(?:\[[^\[\]]*\]|['"][^'"]*['"]|[^\[\]'"]+)+\]|\\.|[^ >+~,(\[\\]+)+|[>+~])(\s*,\s*)?((?:.|\r|\n)*)/g,
        f = /^\s*|\s*$/g,
        g = a.extend({ init: function init(a) {
        function b(a) {
          if (a) return a = a.toLowerCase(), function (b) {
            return "*" === a || b.type === a;
          };
        }function c(a) {
          if (a) return function (b) {
            return b._name === a;
          };
        }function g(a) {
          if (a) return a = a.split("."), function (b) {
            for (var c = a.length; c--;) {
              if (!b.classes.contains(a[c])) return !1;
            }return !0;
          };
        }function h(a, b, c) {
          if (a) return function (d) {
            var e = d[a] ? d[a]() : "";return b ? "=" === b ? e === c : "*=" === b ? e.indexOf(c) >= 0 : "~=" === b ? (" " + e + " ").indexOf(" " + c + " ") >= 0 : "!=" === b ? e != c : "^=" === b ? 0 === e.indexOf(c) : "$=" === b && e.substr(e.length - c.length) === c : !!c;
          };
        }function i(a) {
          var b;if (a) return a = /(?:not\((.+)\))|(.+)/i.exec(a), a[1] ? (b = k(a[1], []), function (a) {
            return !l(a, b);
          }) : (a = a[2], function (b, c, d) {
            return "first" === a ? 0 === c : "last" === a ? c === d - 1 : "even" === a ? c % 2 === 0 : "odd" === a ? c % 2 === 1 : !!b[a] && b[a]();
          });
        }function j(a, e, j) {
          function k(a) {
            a && e.push(a);
          }var l;return l = d.exec(a.replace(f, "")), k(b(l[1])), k(c(l[2])), k(g(l[3])), k(h(l[4], l[5], l[6])), k(i(l[7])), e.pseudo = !!l[7], e.direct = j, e;
        }function k(a, b) {
          var c,
              d,
              f,
              g = [];do {
            if (e.exec(""), d = e.exec(a), d && (a = d[3], g.push(d[1]), d[2])) {
              c = d[3];break;
            }
          } while (d);for (c && k(c, b), a = [], f = 0; f < g.length; f++) {
            ">" != g[f] && a.push(j(g[f], [], ">" === g[f - 1]));
          }return b.push(a), b;
        }var l = this.match;this._selectors = k(a, []);
      }, match: function match(a, b) {
        var c, d, e, f, g, h, i, j, k, l, m, n, o;for (b = b || this._selectors, c = 0, d = b.length; c < d; c++) {
          for (g = b[c], f = g.length, o = a, n = 0, e = f - 1; e >= 0; e--) {
            for (j = g[e]; o;) {
              if (j.pseudo) for (m = o.parent().items(), k = l = m.length; k-- && m[k] !== o;) {}for (h = 0, i = j.length; h < i; h++) {
                if (!j[h](o, k, l)) {
                  h = i + 1;break;
                }
              }if (h === i) {
                n++;break;
              }if (e === f - 1) break;o = o.parent();
            }
          }if (n === f) return !0;
        }return !1;
      }, find: function find(a) {
        function d(a, b, c) {
          var e,
              f,
              g,
              i,
              j,
              k = b[c];for (e = 0, f = a.length; e < f; e++) {
            for (j = a[e], g = 0, i = k.length; g < i; g++) {
              if (!k[g](j, e, f)) {
                g = i + 1;break;
              }
            }if (g === i) c == b.length - 1 ? h.push(j) : j.items && d(j.items(), b, c + 1);else if (k.direct) return;j.items && d(j.items(), b, c);
          }
        }var e,
            f,
            h = [],
            i = this._selectors;if (a.items) {
          for (e = 0, f = i.length; e < f; e++) {
            d(a.items(), i[e], 0);
          }f > 1 && (h = b(h));
        }return c || (c = g.Collection), new c(h);
      } });return g;
  }), g("i", ["c", "24", "2p"], function (a, b, c) {
    "use strict";
    var d,
        e,
        f = Array.prototype.push,
        g = Array.prototype.slice;return e = { length: 0, init: function init(a) {
        a && this.add(a);
      }, add: function add(b) {
        var c = this;return a.isArray(b) ? f.apply(c, b) : b instanceof d ? c.add(b.toArray()) : f.call(c, b), c;
      }, set: function set(a) {
        var b,
            c = this,
            d = c.length;for (c.length = 0, c.add(a), b = c.length; b < d; b++) {
          delete c[b];
        }return c;
      }, filter: function filter(a) {
        var c,
            e,
            f,
            g,
            h = this,
            i = [];for ("string" == typeof a ? (a = new b(a), g = function g(b) {
          return a.match(b);
        }) : g = a, c = 0, e = h.length; c < e; c++) {
          f = h[c], g(f) && i.push(f);
        }return new d(i);
      }, slice: function slice() {
        return new d(g.apply(this, arguments));
      }, eq: function eq(a) {
        return a === -1 ? this.slice(a) : this.slice(a, +a + 1);
      }, each: function each(b) {
        return a.each(this, b), this;
      }, toArray: function toArray() {
        return a.toArray(this);
      }, indexOf: function indexOf(a) {
        for (var b = this, c = b.length; c-- && b[c] !== a;) {}return c;
      }, reverse: function reverse() {
        return new d(a.toArray(this).reverse());
      }, hasClass: function hasClass(a) {
        return !!this[0] && this[0].classes.contains(a);
      }, prop: function prop(a, b) {
        var c,
            d,
            e = this;return b !== c ? (e.each(function (c) {
          c[a] && c[a](b);
        }), e) : (d = e[0], d && d[a] ? d[a]() : void 0);
      }, exec: function exec(b) {
        var c = this,
            d = a.toArray(arguments).slice(1);return c.each(function (a) {
          a[b] && a[b].apply(a, d);
        }), c;
      }, remove: function remove() {
        for (var a = this.length; a--;) {
          this[a].remove();
        }return this;
      }, addClass: function addClass(a) {
        return this.each(function (b) {
          b.classes.add(a);
        });
      }, removeClass: function removeClass(a) {
        return this.each(function (b) {
          b.classes.remove(a);
        });
      } }, a.each("fire on off show hide append prepend before after reflow".split(" "), function (b) {
      e[b] = function () {
        var c = a.toArray(arguments);return this.each(function (a) {
          b in a && a[b].apply(a, c);
        }), this;
      };
    }), a.each("text name disabled active selected checked visible parent value data".split(" "), function (a) {
      e[a] = function (b) {
        return this.prop(a, b);
      };
    }), d = c.extend(e), b.Collection = d, d;
  }), g("3d", [], function () {
    function a(a) {
      this.create = a.create;
    }return a.create = function (b, c) {
      return new a({ create: function create(a, d) {
          function e(b) {
            a.set(d, b.value);
          }function f(a) {
            b.set(c, a.value);
          }var g;return a.on("change:" + d, f), b.on("change:" + c, e), g = a._bindings, g || (g = a._bindings = [], a.on("destroy", function () {
            for (var a = g.length; a--;) {
              g[a]();
            }
          })), g.push(function () {
            b.off("change:" + c, e);
          }), b.get(c);
        } });
    }, a;
  }), g("3e", ["6"], function (a) {
    return a("tinymce.util.Observable");
  }), g("2t", ["3d", "2p", "3e", "c"], function (a, b, c, d) {
    function e(a) {
      return a.nodeType > 0;
    }function f(a, b) {
      var c, g;if (a === b) return !0;if (null === a || null === b) return a === b;if ("object" != (typeof a === "undefined" ? "undefined" : _typeof(a)) || "object" != (typeof b === "undefined" ? "undefined" : _typeof(b))) return a === b;if (d.isArray(b)) {
        if (a.length !== b.length) return !1;for (c = a.length; c--;) {
          if (!f(a[c], b[c])) return !1;
        }
      }if (e(a) || e(b)) return a === b;g = {};for (c in b) {
        if (!f(a[c], b[c])) return !1;g[c] = !0;
      }for (c in a) {
        if (!g[c] && !f(a[c], b[c])) return !1;
      }return !0;
    }return b.extend({ Mixins: [c], init: function init(b) {
        var c, d;b = b || {};for (c in b) {
          d = b[c], d instanceof a && (b[c] = d.create(this, c));
        }this.data = b;
      }, set: function set(b, c) {
        var d,
            e,
            g = this.data[b];if (c instanceof a && (c = c.create(this, b)), "object" == (typeof b === "undefined" ? "undefined" : _typeof(b))) {
          for (d in b) {
            this.set(d, b[d]);
          }return this;
        }return f(g, c) || (this.data[b] = c, e = { target: this, name: b, value: c, oldValue: g }, this.fire("change:" + b, e), this.fire("change", e)), this;
      }, get: function get(a) {
        return this.data[a];
      }, has: function has(a) {
        return a in this.data;
      }, bind: function bind(b) {
        return a.create(this, b);
      }, destroy: function destroy() {
        this.fire("destroy");
      } });
  }), g("1z", ["12", "2u"], function (a, b) {
    var c,
        d = {};return { add: function add(e) {
        var f = e.parent();if (f) {
          if (!f._layout || f._layout.isNative()) return;d[f._id] || (d[f._id] = f), c || (c = !0, b.requestAnimationFrame(function () {
            var a, b;c = !1;for (a in d) {
              b = d[a], b.state.get("rendered") && b.reflow();
            }d = {};
          }, a.body));
        }
      }, remove: function remove(a) {
        d[a._id] && delete d[a._id];
      } };
  }), g("o", ["12", "2o", "2p", "2q", "c", "2r", "2s", "i", "2t", "2n", "1z"], function (a, b, c, d, e, f, g, h, i, j, k) {
    "use strict";
    function l(a) {
      return a._eventDispatcher || (a._eventDispatcher = new d({ scope: a, toggleEvent: function toggleEvent(b, c) {
          c && d.isNative(b) && (a._nativeEvents || (a._nativeEvents = {}), a._nativeEvents[b] = !0, a.state.get("rendered") && m(a));
        } })), a._eventDispatcher;
    }function m(a) {
      function c(b) {
        var c = a.getParentCtrl(b.target);c && c.fire(b.type, b);
      }function d() {
        var a = j._lastHoverCtrl;a && (a.fire("mouseleave", { target: a.getEl() }), a.parents().each(function (a) {
          a.fire("mouseleave", { target: a.getEl() });
        }), j._lastHoverCtrl = null);
      }function e(b) {
        var c,
            d,
            e,
            f = a.getParentCtrl(b.target),
            g = j._lastHoverCtrl,
            h = 0;if (f !== g) {
          if (j._lastHoverCtrl = f, d = f.parents().toArray().reverse(), d.push(f), g) {
            for (e = g.parents().toArray().reverse(), e.push(g), h = 0; h < e.length && d[h] === e[h]; h++) {}for (c = e.length - 1; c >= h; c--) {
              g = e[c], g.fire("mouseleave", { target: g.getEl() });
            }
          }for (c = h; c < d.length; c++) {
            f = d[c], f.fire("mouseenter", { target: f.getEl() });
          }
        }
      }function f(b) {
        b.preventDefault(), "mousewheel" == b.type ? (b.deltaY = -.025 * b.wheelDelta, b.wheelDeltaX && (b.deltaX = -.025 * b.wheelDeltaX)) : (b.deltaX = 0, b.deltaY = b.detail), b = a.fire("wheel", b);
      }var g, h, i, j, k, l;if (k = a._nativeEvents) {
        for (i = a.parents().toArray(), i.unshift(a), g = 0, h = i.length; !j && g < h; g++) {
          j = i[g]._eventsRoot;
        }for (j || (j = i[i.length - 1] || a), a._eventsRoot = j, h = g, g = 0; g < h; g++) {
          i[g]._eventsRoot = j;
        }var m = j._delegates;m || (m = j._delegates = {});for (l in k) {
          if (!k) return !1;"wheel" !== l || p ? ("mouseenter" === l || "mouseleave" === l ? j._hasMouseEnter || (b(j.getEl()).on("mouseleave", d).on("mouseover", e), j._hasMouseEnter = 1) : m[l] || (b(j.getEl()).on(l, c), m[l] = !0), k[l] = !1) : o ? b(a.getEl()).on("mousewheel", f) : b(a.getEl()).on("DOMMouseScroll", f);
        }
      }
    }var n,
        o = "onmousewheel" in a,
        p = !1,
        q = "mce-",
        r = 0,
        s = { Statics: { classPrefix: q }, isRtl: function isRtl() {
        return n.rtl;
      }, classPrefix: q, init: function init(a) {
        function c(a) {
          var b;for (a = a.split(" "), b = 0; b < a.length; b++) {
            j.classes.add(a[b]);
          }
        }var d,
            h,
            j = this;j.settings = a = e.extend({}, j.Defaults, a), j._id = a.id || "mceu_" + r++, j._aria = { role: a.role }, j._elmCache = {}, j.$ = b, j.state = new i({ visible: !0, active: !1, disabled: !1, value: "" }), j.data = new i(a.data), j.classes = new g(function () {
          j.state.get("rendered") && (j.getEl().className = this.toString());
        }), j.classes.prefix = j.classPrefix, d = a.classes, d && (j.Defaults && (h = j.Defaults.classes, h && d != h && c(h)), c(d)), e.each("title text name visible disabled active value".split(" "), function (b) {
          b in a && j[b](a[b]);
        }), j.on("click", function () {
          if (j.disabled()) return !1;
        }), j.settings = a, j.borderBox = f.parseBox(a.border), j.paddingBox = f.parseBox(a.padding), j.marginBox = f.parseBox(a.margin), a.hidden && j.hide();
      }, Properties: "parent,name", getContainerElm: function getContainerElm() {
        return j.getContainer();
      }, getParentCtrl: function getParentCtrl(a) {
        for (var b, c = this.getRoot().controlIdLookup; a && c && !(b = c[a.id]);) {
          a = a.parentNode;
        }return b;
      }, initLayoutRect: function initLayoutRect() {
        var a,
            b,
            c,
            d,
            e,
            g,
            h,
            i,
            k,
            l,
            m = this,
            n = m.settings,
            o = m.getEl();a = m.borderBox = m.borderBox || f.measureBox(o, "border"), m.paddingBox = m.paddingBox || f.measureBox(o, "padding"), m.marginBox = m.marginBox || f.measureBox(o, "margin"), l = j.getSize(o), i = n.minWidth, k = n.minHeight, e = i || l.width, g = k || l.height, c = n.width, d = n.height, h = n.autoResize, h = "undefined" != typeof h ? h : !c && !d, c = c || e, d = d || g;var p = a.left + a.right,
            q = a.top + a.bottom,
            r = n.maxWidth || 65535,
            s = n.maxHeight || 65535;
        return m._layoutRect = b = { x: n.x || 0, y: n.y || 0, w: c, h: d, deltaW: p, deltaH: q, contentW: c - p, contentH: d - q, innerW: c - p, innerH: d - q, startMinWidth: i || 0, startMinHeight: k || 0, minW: Math.min(e, r), minH: Math.min(g, s), maxW: r, maxH: s, autoResize: h, scrollW: 0 }, m._lastLayoutRect = {}, b;
      }, layoutRect: function layoutRect(a) {
        var b,
            c,
            d,
            e,
            f,
            g,
            h = this,
            i = h._layoutRect;return i || (i = h.initLayoutRect()), a ? (d = i.deltaW, e = i.deltaH, a.x !== f && (i.x = a.x), a.y !== f && (i.y = a.y), a.minW !== f && (i.minW = a.minW), a.minH !== f && (i.minH = a.minH), c = a.w, c !== f && (c = c < i.minW ? i.minW : c, c = c > i.maxW ? i.maxW : c, i.w = c, i.innerW = c - d), c = a.h, c !== f && (c = c < i.minH ? i.minH : c, c = c > i.maxH ? i.maxH : c, i.h = c, i.innerH = c - e), c = a.innerW, c !== f && (c = c < i.minW - d ? i.minW - d : c, c = c > i.maxW - d ? i.maxW - d : c, i.innerW = c, i.w = c + d), c = a.innerH, c !== f && (c = c < i.minH - e ? i.minH - e : c, c = c > i.maxH - e ? i.maxH - e : c, i.innerH = c, i.h = c + e), a.contentW !== f && (i.contentW = a.contentW), a.contentH !== f && (i.contentH = a.contentH), b = h._lastLayoutRect, b.x === i.x && b.y === i.y && b.w === i.w && b.h === i.h || (g = n.repaintControls, g && g.map && !g.map[h._id] && (g.push(h), g.map[h._id] = !0), b.x = i.x, b.y = i.y, b.w = i.w, b.h = i.h), h) : i;
      }, repaint: function repaint() {
        var b,
            c,
            d,
            e,
            f,
            g,
            h,
            i,
            j,
            k,
            l = this;j = a.createRange ? function (a) {
          return a;
        } : Math.round, b = l.getEl().style, e = l._layoutRect, i = l._lastRepaintRect || {}, f = l.borderBox, g = f.left + f.right, h = f.top + f.bottom, e.x !== i.x && (b.left = j(e.x) + "px", i.x = e.x), e.y !== i.y && (b.top = j(e.y) + "px", i.y = e.y), e.w !== i.w && (k = j(e.w - g), b.width = (k >= 0 ? k : 0) + "px", i.w = e.w), e.h !== i.h && (k = j(e.h - h), b.height = (k >= 0 ? k : 0) + "px", i.h = e.h), l._hasBody && e.innerW !== i.innerW && (k = j(e.innerW), d = l.getEl("body"), d && (c = d.style, c.width = (k >= 0 ? k : 0) + "px"), i.innerW = e.innerW), l._hasBody && e.innerH !== i.innerH && (k = j(e.innerH), d = d || l.getEl("body"), d && (c = c || d.style, c.height = (k >= 0 ? k : 0) + "px"), i.innerH = e.innerH), l._lastRepaintRect = i, l.fire("repaint", {}, !1);
      }, updateLayoutRect: function updateLayoutRect() {
        var a = this;a.parent()._lastRect = null, j.css(a.getEl(), { width: "", height: "" }), a._layoutRect = a._lastRepaintRect = a._lastLayoutRect = null, a.initLayoutRect();
      }, on: function on(a, b) {
        function c(a) {
          var b, c;return "string" != typeof a ? a : function (e) {
            return b || d.parentsAndSelf().each(function (d) {
              var e = d.settings.callbacks;if (e && (b = e[a])) return c = d, !1;
            }), b ? b.call(c, e) : (e.action = a, void this.fire("execute", e));
          };
        }var d = this;return l(d).on(a, c(b)), d;
      }, off: function off(a, b) {
        return l(this).off(a, b), this;
      }, fire: function fire(a, b, c) {
        var d = this;if (b = b || {}, b.control || (b.control = d), b = l(d).fire(a, b), c !== !1 && d.parent) for (var e = d.parent(); e && !b.isPropagationStopped();) {
          e.fire(a, b, !1), e = e.parent();
        }return b;
      }, hasEventListeners: function hasEventListeners(a) {
        return l(this).has(a);
      }, parents: function parents(a) {
        var b,
            c = this,
            d = new h();for (b = c.parent(); b; b = b.parent()) {
          d.add(b);
        }return a && (d = d.filter(a)), d;
      }, parentsAndSelf: function parentsAndSelf(a) {
        return new h(this).add(this.parents(a));
      }, next: function next() {
        var a = this.parent().items();return a[a.indexOf(this) + 1];
      }, prev: function prev() {
        var a = this.parent().items();return a[a.indexOf(this) - 1];
      }, innerHtml: function innerHtml(a) {
        return this.$el.html(a), this;
      }, getEl: function getEl(a) {
        var c = a ? this._id + "-" + a : this._id;return this._elmCache[c] || (this._elmCache[c] = b("#" + c)[0]), this._elmCache[c];
      }, show: function show() {
        return this.visible(!0);
      }, hide: function hide() {
        return this.visible(!1);
      }, focus: function focus() {
        try {
          this.getEl().focus();
        } catch (a) {}return this;
      }, blur: function blur() {
        return this.getEl().blur(), this;
      }, aria: function aria(a, b) {
        var c = this,
            d = c.getEl(c.ariaTarget);return "undefined" == typeof b ? c._aria[a] : (c._aria[a] = b, c.state.get("rendered") && d.setAttribute("role" == a ? a : "aria-" + a, b), c);
      }, encode: function encode(a, b) {
        return b !== !1 && (a = this.translate(a)), (a || "").replace(/[&<>"]/g, function (a) {
          return "&#" + a.charCodeAt(0) + ";";
        });
      }, translate: function translate(a) {
        return n.translate ? n.translate(a) : a;
      }, before: function before(a) {
        var b = this,
            c = b.parent();return c && c.insert(a, c.items().indexOf(b), !0), b;
      }, after: function after(a) {
        var b = this,
            c = b.parent();return c && c.insert(a, c.items().indexOf(b)), b;
      }, remove: function remove() {
        var a,
            c,
            d = this,
            e = d.getEl(),
            f = d.parent();if (d.items) {
          var g = d.items().toArray();for (c = g.length; c--;) {
            g[c].remove();
          }
        }f && f.items && (a = [], f.items().each(function (b) {
          b !== d && a.push(b);
        }), f.items().set(a), f._lastRect = null), d._eventsRoot && d._eventsRoot == d && b(e).off();var h = d.getRoot().controlIdLookup;return h && delete h[d._id], e && e.parentNode && e.parentNode.removeChild(e), d.state.set("rendered", !1), d.state.destroy(), d.fire("remove"), d;
      }, renderBefore: function renderBefore(a) {
        return b(a).before(this.renderHtml()), this.postRender(), this;
      }, renderTo: function renderTo(a) {
        return b(a || this.getContainerElm()).append(this.renderHtml()), this.postRender(), this;
      }, preRender: function preRender() {}, render: function render() {}, renderHtml: function renderHtml() {
        return '<div id="' + this._id + '" class="' + this.classes + '"></div>';
      }, postRender: function postRender() {
        var a,
            c,
            d,
            e,
            f,
            g = this,
            h = g.settings;g.$el = b(g.getEl()), g.state.set("rendered", !0);for (e in h) {
          0 === e.indexOf("on") && g.on(e.substr(2), h[e]);
        }if (g._eventsRoot) {
          for (d = g.parent(); !f && d; d = d.parent()) {
            f = d._eventsRoot;
          }if (f) for (e in f._nativeEvents) {
            g._nativeEvents[e] = !0;
          }
        }m(g), h.style && (a = g.getEl(), a && (a.setAttribute("style", h.style), a.style.cssText = h.style)), g.settings.border && (c = g.borderBox, g.$el.css({ "border-top-width": c.top, "border-right-width": c.right, "border-bottom-width": c.bottom, "border-left-width": c.left }));var i = g.getRoot();i.controlIdLookup || (i.controlIdLookup = {}), i.controlIdLookup[g._id] = g;for (var j in g._aria) {
          g.aria(j, g._aria[j]);
        }g.state.get("visible") === !1 && (g.getEl().style.display = "none"), g.bindStates(), g.state.on("change:visible", function (a) {
          var b,
              c = a.value;g.state.get("rendered") && (g.getEl().style.display = c === !1 ? "none" : "", g.getEl().getBoundingClientRect()), b = g.parent(), b && (b._lastRect = null), g.fire(c ? "show" : "hide"), k.add(g);
        }), g.fire("postrender", {}, !1);
      }, bindStates: function bindStates() {}, scrollIntoView: function scrollIntoView(a) {
        function b(a, b) {
          var c,
              d,
              e = a;for (c = d = 0; e && e != b && e.nodeType;) {
            c += e.offsetLeft || 0, d += e.offsetTop || 0, e = e.offsetParent;
          }return { x: c, y: d };
        }var c,
            d,
            e,
            f,
            g,
            h,
            i = this.getEl(),
            j = i.parentNode,
            k = b(i, j);return c = k.x, d = k.y, e = i.offsetWidth, f = i.offsetHeight, g = j.clientWidth, h = j.clientHeight, "end" == a ? (c -= g - e, d -= h - f) : "center" == a && (c -= g / 2 - e / 2, d -= h / 2 - f / 2), j.scrollLeft = c, j.scrollTop = d, this;
      }, getRoot: function getRoot() {
        for (var a, b = this, c = []; b;) {
          if (b.rootControl) {
            a = b.rootControl;break;
          }c.push(b), a = b, b = b.parent();
        }a || (a = this);for (var d = c.length; d--;) {
          c[d].rootControl = a;
        }return a;
      }, reflow: function reflow() {
        k.remove(this);var a = this.parent();return a && a._layout && !a._layout.isNative() && a.reflow(), this;
      } };return e.each("text title visible disabled active value".split(" "), function (a) {
      s[a] = function (b) {
        return 0 === arguments.length ? this.state.get(a) : ("undefined" != typeof b && this.state.set(a, b), this);
      };
    }), n = c.extend(s);
  }), g("1j", ["12"], function (a) {
    "use strict";
    var b = function b(a) {
      return !!a.getAttribute("data-mce-tabstop");
    };return function (c) {
      function d(a) {
        return a && 1 === a.nodeType;
      }function e(a) {
        return a = a || v, d(a) ? a.getAttribute("role") : null;
      }function f(a) {
        for (var b, c = a || v; c = c.parentNode;) {
          if (b = e(c)) return b;
        }
      }function g(a) {
        var b = v;if (d(b)) return b.getAttribute("aria-" + a);
      }function h(a) {
        var b = a.tagName.toUpperCase();return "INPUT" == b || "TEXTAREA" == b || "SELECT" == b;
      }function i(a) {
        return !(!h(a) || a.hidden) || !!b(a) || !!/^(button|menuitem|checkbox|tab|menuitemcheckbox|option|gridcell|slider)$/.test(e(a));
      }function j(a) {
        function b(a) {
          if (1 == a.nodeType && "none" != a.style.display && !a.disabled) {
            i(a) && c.push(a);for (var d = 0; d < a.childNodes.length; d++) {
              b(a.childNodes[d]);
            }
          }
        }var c = [];return b(a || x.getEl()), c;
      }function k(a) {
        var b, c;a = a || w, c = a.parents().toArray(), c.unshift(a);for (var d = 0; d < c.length && (b = c[d], !b.settings.ariaRoot); d++) {}return b;
      }function l(a) {
        var b = k(a),
            c = j(b.getEl());b.settings.ariaRemember && "lastAriaIndex" in b ? m(b.lastAriaIndex, c) : m(0, c);
      }function m(a, b) {
        return a < 0 ? a = b.length - 1 : a >= b.length && (a = 0), b[a] && b[a].focus(), a;
      }function n(a, b) {
        var c = -1,
            d = k();b = b || j(d.getEl());for (var e = 0; e < b.length; e++) {
          b[e] === v && (c = e);
        }c += a, d.lastAriaIndex = m(c, b);
      }function o() {
        var a = f();"tablist" == a ? n(-1, j(v.parentNode)) : w.parent().submenu ? t() : n(-1);
      }function p() {
        var a = e(),
            b = f();"tablist" == b ? n(1, j(v.parentNode)) : "menuitem" == a && "menu" == b && g("haspopup") ? u() : n(1);
      }function q() {
        n(-1);
      }function r() {
        var a = e(),
            b = f();"menuitem" == a && "menubar" == b ? u() : "button" == a && g("haspopup") ? u({ key: "down" }) : n(1);
      }function s(a) {
        var b = f();if ("tablist" == b) {
          var c = j(w.getEl("body"))[0];c && c.focus();
        } else n(a.shiftKey ? -1 : 1);
      }function t() {
        w.fire("cancel");
      }function u(a) {
        a = a || {}, w.fire("click", { target: v, aria: a });
      }var v,
          w,
          x = c.root;try {
        v = a.activeElement;
      } catch (b) {
        v = a.body;
      }return w = x.getParentCtrl(v), x.on("keydown", function (a) {
        function c(a, c) {
          h(v) || b(v) || "slider" !== e(v) && c(a) !== !1 && a.preventDefault();
        }if (!a.isDefaultPrevented()) switch (a.keyCode) {case 37:
            c(a, o);break;case 39:
            c(a, p);break;case 38:
            c(a, q);break;case 40:
            c(a, r);break;case 27:
            t();break;case 14:case 13:case 32:
            c(a, u);break;case 9:
            s(a) !== !1 && a.preventDefault();}
      }), x.on("focusin", function (a) {
        v = a.target, w = a.control;
      }), { focusFirst: l };
    };
  }), g("n", ["o", "i", "24", "b", "1j", "c", "2o", "2s", "1z"], function (a, b, c, d, e, f, g, h, i) {
    "use strict";
    var j = {};return a.extend({ init: function init(a) {
        var c = this;c._super(a), a = c.settings, a.fixed && c.state.set("fixed", !0), c._items = new b(), c.isRtl() && c.classes.add("rtl"), c.bodyClasses = new h(function () {
          c.state.get("rendered") && (c.getEl("body").className = this.toString());
        }), c.bodyClasses.prefix = c.classPrefix, c.classes.add("container"), c.bodyClasses.add("container-body"), a.containerCls && c.classes.add(a.containerCls), c._layout = d.create((a.layout || "") + "layout"), c.settings.items ? c.add(c.settings.items) : c.add(c.render()), c._hasBody = !0;
      }, items: function items() {
        return this._items;
      }, find: function find(a) {
        return a = j[a] = j[a] || new c(a), a.find(this);
      }, add: function add(a) {
        var b = this;return b.items().add(b.create(a)).parent(b), b;
      }, focus: function focus(a) {
        var b,
            c,
            d,
            e = this;return a && (c = e.keyboardNav || e.parents().eq(-1)[0].keyboardNav) ? void c.focusFirst(e) : (d = e.find("*"), e.statusbar && d.add(e.statusbar.items()), d.each(function (a) {
          return a.settings.autofocus ? (b = null, !1) : void (a.canFocus && (b = b || a));
        }), b && b.focus(), e);
      }, replace: function replace(a, b) {
        for (var c, d = this.items(), e = d.length; e--;) {
          if (d[e] === a) {
            d[e] = b;break;
          }
        }e >= 0 && (c = b.getEl(), c && c.parentNode.removeChild(c), c = a.getEl(), c && c.parentNode.removeChild(c)), b.parent(this);
      }, create: function create(b) {
        var c,
            e = this,
            g = [];return f.isArray(b) || (b = [b]), f.each(b, function (b) {
          b && (b instanceof a || ("string" == typeof b && (b = { type: b }), c = f.extend({}, e.settings.defaults, b), b.type = c.type = c.type || b.type || e.settings.defaultType || (c.defaults ? c.defaults.type : null), b = d.create(c)), g.push(b));
        }), g;
      }, renderNew: function renderNew() {
        var a = this;return a.items().each(function (b, c) {
          var d;b.parent(a), b.state.get("rendered") || (d = a.getEl("body"), d.hasChildNodes() && c <= d.childNodes.length - 1 ? g(d.childNodes[c]).before(b.renderHtml()) : g(d).append(b.renderHtml()), b.postRender(), i.add(b));
        }), a._layout.applyClasses(a.items().filter(":visible")), a._lastRect = null, a;
      }, append: function append(a) {
        return this.add(a).renderNew();
      }, prepend: function prepend(a) {
        var b = this;return b.items().set(b.create(a).concat(b.items().toArray())), b.renderNew();
      }, insert: function insert(a, b, c) {
        var d,
            e,
            f,
            g = this;return a = g.create(a), d = g.items(), !c && b < d.length - 1 && (b += 1), b >= 0 && b < d.length && (e = d.slice(0, b).toArray(), f = d.slice(b).toArray(), d.set(e.concat(a, f))), g.renderNew();
      }, fromJSON: function fromJSON(a) {
        var b = this;for (var c in a) {
          b.find("#" + c).value(a[c]);
        }return b;
      }, toJSON: function toJSON() {
        var a = this,
            b = {};return a.find("*").each(function (a) {
          var c = a.name(),
              d = a.value();c && "undefined" != typeof d && (b[c] = d);
        }), b;
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a._layout,
            c = this.settings.role;return a.preRender(), b.preRender(a), '<div id="' + a._id + '" class="' + a.classes + '"' + (c ? ' role="' + this.settings.role + '"' : "") + '><div id="' + a._id + '-body" class="' + a.bodyClasses + '">' + (a.settings.html || "") + b.renderHtml(a) + "</div></div>";
      }, postRender: function postRender() {
        var a,
            b = this;return b.items().exec("postRender"), b._super(), b._layout.postRender(b), b.state.set("rendered", !0), b.settings.style && b.$el.css(b.settings.style), b.settings.border && (a = b.borderBox, b.$el.css({ "border-top-width": a.top, "border-right-width": a.right, "border-bottom-width": a.bottom, "border-left-width": a.left })), b.parent() || (b.keyboardNav = new e({ root: b })), b;
      }, initLayoutRect: function initLayoutRect() {
        var a = this,
            b = a._super();return a._layout.recalc(a), b;
      }, recalc: function recalc() {
        var a = this,
            b = a._layoutRect,
            c = a._lastRect;if (!c || c.w != b.w || c.h != b.h) return a._layout.recalc(a), b = a.layoutRect(), a._lastRect = { x: b.x, y: b.y, w: b.w, h: b.h }, !0;
      }, reflow: function reflow() {
        var b;if (i.remove(this), this.visible()) {
          for (a.repaintControls = [], a.repaintControls.map = {}, this.recalc(), b = a.repaintControls.length; b--;) {
            a.repaintControls[b].repaint();
          }"flow" !== this.settings.layout && "stack" !== this.settings.layout && this.repaint(), a.repaintControls = [];
        }return this;
      } });
  }), g("p", ["12", "1", "2o"], function (a, b, c) {
    "use strict";
    function d(a) {
      var b,
          c,
          d,
          e,
          f,
          g,
          h,
          i,
          j = Math.max;return b = a.documentElement, c = a.body, d = j(b.scrollWidth, c.scrollWidth), e = j(b.clientWidth, c.clientWidth), f = j(b.offsetWidth, c.offsetWidth), g = j(b.scrollHeight, c.scrollHeight), h = j(b.clientHeight, c.clientHeight), i = j(b.offsetHeight, c.offsetHeight), { width: d < f ? e : d, height: g < i ? h : g };
    }function e(a) {
      var b, c;if (a.changedTouches) for (b = "screenX screenY pageX pageY clientX clientY".split(" "), c = 0; c < b.length; c++) {
        a[b[c]] = a.changedTouches[0][b[c]];
      }
    }return function (f, g) {
      function h() {
        return p.getElementById(g.handle || f);
      }var i,
          j,
          k,
          _l,
          m,
          n,
          o,
          p = g.document || a;g = g || {}, k = function k(a) {
        var f,
            k,
            q = d(p);e(a), a.preventDefault(), j = a.button, f = h(), n = a.screenX, o = a.screenY, k = b.getComputedStyle ? b.getComputedStyle(f, null).getPropertyValue("cursor") : f.runtimeStyle.cursor, i = c("<div></div>").css({ position: "absolute", top: 0, left: 0, width: q.width, height: q.height, zIndex: 2147483647, opacity: 1e-4, cursor: k }).appendTo(p.body), c(p).on("mousemove touchmove", m).on("mouseup touchend", _l), g.start(a);
      }, m = function m(a) {
        return e(a), a.button !== j ? _l(a) : (a.deltaX = a.screenX - n, a.deltaY = a.screenY - o, a.preventDefault(), void g.drag(a));
      }, _l = function l(a) {
        e(a), c(p).off("mousemove touchmove", m).off("mouseup touchend", _l), i.remove(), g.stop && g.stop(a);
      }, this.destroy = function () {
        c(h()).off();
      }, c(h()).on("mousedown touchstart", k);
    };
  }), g("22", ["2o", "p"], function (a, b) {
    "use strict";
    return { init: function init() {
        var a = this;a.on("repaint", a.renderScroll);
      }, renderScroll: function renderScroll() {
        function c() {
          function b(b, g, h, i, j, k) {
            var l, m, n, o, p, q, r, s, t;if (m = e.getEl("scroll" + b)) {
              if (s = g.toLowerCase(), t = h.toLowerCase(), a(e.getEl("absend")).css(s, e.layoutRect()[i] - 1), !j) return void a(m).css("display", "none");a(m).css("display", "block"), l = e.getEl("body"), n = e.getEl("scroll" + b + "t"), o = l["client" + h] - 2 * f, o -= c && d ? m["client" + k] : 0, p = l["scroll" + h], q = o / p, r = {}, r[s] = l["offset" + g] + f, r[t] = o, a(m).css(r), r = {}, r[s] = l["scroll" + g] * q, r[t] = o * q, a(n).css(r);
            }
          }var c, d, g;g = e.getEl("body"), c = g.scrollWidth > g.clientWidth, d = g.scrollHeight > g.clientHeight, b("h", "Left", "Width", "contentW", c, "Height"), b("v", "Top", "Height", "contentH", d, "Width");
        }function d() {
          function c(c, d, g, h, i) {
            var j,
                k = e._id + "-scroll" + c,
                l = e.classPrefix;a(e.getEl()).append('<div id="' + k + '" class="' + l + "scrollbar " + l + "scrollbar-" + c + '"><div id="' + k + 't" class="' + l + 'scrollbar-thumb"></div></div>'), e.draghelper = new b(k + "t", { start: function start() {
                j = e.getEl("body")["scroll" + d], a("#" + k).addClass(l + "active");
              }, drag: function drag(a) {
                var b,
                    k,
                    l,
                    m,
                    n = e.layoutRect();k = n.contentW > n.innerW, l = n.contentH > n.innerH, m = e.getEl("body")["client" + g] - 2 * f, m -= k && l ? e.getEl("scroll" + c)["client" + i] : 0, b = m / e.getEl("body")["scroll" + g], e.getEl("body")["scroll" + d] = j + a["delta" + h] / b;
              }, stop: function stop() {
                a("#" + k).removeClass(l + "active");
              } });
          }e.classes.add("scroll"), c("v", "Top", "Height", "Y", "Width"), c("h", "Left", "Width", "X", "Height");
        }var e = this,
            f = 2;e.settings.autoScroll && (e._hasScroll || (e._hasScroll = !0, d(), e.on("wheel", function (a) {
          var b = e.getEl("body");b.scrollLeft += 10 * (a.deltaX || 0), b.scrollTop += 10 * a.deltaY, c();
        }), a(e.getEl("body")).on("scroll", c)), c());
      } };
  }), g("1u", ["n", "22"], function (a, b) {
    "use strict";
    return a.extend({ Defaults: { layout: "fit", containerCls: "panel" }, Mixins: [b], renderHtml: function renderHtml() {
        var a = this,
            b = a._layout,
            c = a.settings.html;return a.preRender(), b.preRender(a), "undefined" == typeof c ? c = '<div id="' + a._id + '-body" class="' + a.bodyClasses + '">' + b.renderHtml(a) + "</div>" : ("function" == typeof c && (c = c.call(a)), a._hasBody = !1), '<div id="' + a._id + '" class="' + a.classes + '" hidefocus="1" tabindex="-1" role="group">' + (a._preBodyHtml || "") + c + "</div>";
      } });
  }), g("20", ["2n"], function (a) {
    "use strict";
    return { resizeToContent: function resizeToContent() {
        this._layoutRect.autoResize = !0, this._lastRect = null, this.reflow();
      }, resizeTo: function resizeTo(b, c) {
        if (b <= 1 || c <= 1) {
          var d = a.getWindowSize();b = b <= 1 ? b * d.w : b, c = c <= 1 ? c * d.h : c;
        }return this._layoutRect.autoResize = !1, this.layoutRect({ minW: b, minH: c, w: b, h: c }).reflow();
      }, resizeBy: function resizeBy(a, b) {
        var c = this,
            d = c.layoutRect();return c.resizeTo(d.w + a, d.h + b);
      } };
  }), g("w", ["12", "1", "2o", "2u", "2n", "1s", "1u", "20"], function (a, b, c, d, e, f, g, h) {
    "use strict";
    function i(a, b) {
      for (; a;) {
        if (a == b) return !0;a = a.parent();
      }
    }function j(a) {
      for (var b = u.length; b--;) {
        var c = u[b],
            d = c.getParentCtrl(a.target);if (c.settings.autohide) {
          if (d && (i(d, c) || c.parent() === d)) continue;a = c.fire("autohide", { target: a.target }), a.isDefaultPrevented() || c.hide();
        }
      }
    }function k() {
      q || (q = function q(a) {
        2 != a.button && j(a);
      }, c(a).on("click touchstart", q));
    }function l() {
      r || (r = function r() {
        var a;for (a = u.length; a--;) {
          n(u[a]);
        }
      }, c(b).on("scroll", r));
    }function m() {
      if (!s) {
        var d = a.documentElement,
            e = d.clientWidth,
            f = d.clientHeight;s = function s() {
          a.all && e == d.clientWidth && f == d.clientHeight || (e = d.clientWidth, f = d.clientHeight, w.hideAll());
        }, c(b).on("resize", s);
      }
    }function n(a) {
      function b(b, c) {
        for (var d, e = 0; e < u.length; e++) {
          if (u[e] != a) for (d = u[e].parent(); d && (d = d.parent());) {
            d == a && u[e].fixed(b).moveBy(0, c).repaint();
          }
        }
      }var c = e.getViewPort().y;a.settings.autofix && (a.state.get("fixed") ? a._autoFixY > c && (a.fixed(!1).layoutRect({ y: a._autoFixY }).repaint(), b(!1, a._autoFixY - c)) : (a._autoFixY = a.layoutRect().y, a._autoFixY < c && (a.fixed(!0).layoutRect({ y: 0 }).repaint(), b(!0, c - a._autoFixY))));
    }function o(a, b) {
      var d,
          e,
          f = w.zIndex || 65535;if (a) v.push(b);else for (d = v.length; d--;) {
        v[d] === b && v.splice(d, 1);
      }if (v.length) for (d = 0; d < v.length; d++) {
        v[d].modal && (f++, e = v[d]), v[d].getEl().style.zIndex = f, v[d].zIndex = f, f++;
      }var g = c("#" + b.classPrefix + "modal-block", b.getContainerElm())[0];e ? c(g).css("z-index", e.zIndex - 1) : g && (g.parentNode.removeChild(g), t = !1), w.currentZIndex = f;
    }function p(a) {
      var b;for (b = u.length; b--;) {
        u[b] === a && u.splice(b, 1);
      }for (b = v.length; b--;) {
        v[b] === a && v.splice(b, 1);
      }
    }var q,
        r,
        s,
        t,
        u = [],
        v = [],
        w = g.extend({ Mixins: [f, h], init: function init(a) {
        var b = this;b._super(a), b._eventsRoot = b, b.classes.add("floatpanel"), a.autohide && (k(), m(), u.push(b)), a.autofix && (l(), b.on("move", function () {
          n(this);
        })), b.on("postrender show", function (a) {
          if (a.control == b) {
            var e,
                f = b.classPrefix;b.modal && !t && (e = c("#" + f + "modal-block", b.getContainerElm()), e[0] || (e = c('<div id="' + f + 'modal-block" class="' + f + "reset " + f + 'fade"></div>').appendTo(b.getContainerElm())), d.setTimeout(function () {
              e.addClass(f + "in"), c(b.getEl()).addClass(f + "in");
            }), t = !0), o(!0, b);
          }
        }), b.on("show", function () {
          b.parents().each(function (a) {
            if (a.state.get("fixed")) return b.fixed(!0), !1;
          });
        }), a.popover && (b._preBodyHtml = '<div class="' + b.classPrefix + 'arrow"></div>', b.classes.add("popover").add("bottom").add(b.isRtl() ? "end" : "start")), b.aria("label", a.ariaLabel), b.aria("labelledby", b._id), b.aria("describedby", b.describedBy || b._id + "-none");
      }, fixed: function fixed(a) {
        var b = this;if (b.state.get("fixed") != a) {
          if (b.state.get("rendered")) {
            var c = e.getViewPort();a ? b.layoutRect().y -= c.y : b.layoutRect().y += c.y;
          }b.classes.toggle("fixed", a), b.state.set("fixed", a);
        }return b;
      }, show: function show() {
        var a,
            b = this,
            c = b._super();for (a = u.length; a-- && u[a] !== b;) {}return a === -1 && u.push(b), c;
      }, hide: function hide() {
        return p(this), o(!1, this), this._super();
      }, hideAll: function hideAll() {
        w.hideAll();
      }, close: function close() {
        var a = this;return a.fire("close").isDefaultPrevented() || (a.remove(), o(!1, a)), a;
      }, remove: function remove() {
        p(this), this._super();
      }, postRender: function postRender() {
        var a = this;return a.settings.bodyRole && this.getEl("body").setAttribute("role", a.settings.bodyRole), a._super();
      } });return w.hideAll = function () {
      for (var a = u.length; a--;) {
        var b = u[a];b && b.settings.autohide && (b.hide(), u.splice(a, 1));
      }
    }, w;
  }), g("2h", ["12", "2j", "b", "2k", "2f", "37", "38", "39", "3b", "3c", "w"], function (a, b, c, d, e, f, g, h, i, j, k) {
    var l = function l(_l2, m, n) {
      var o,
          p,
          q = b.DOM,
          r = e.getFixedToolbarContainer(_l2);r && (p = q.select(r)[0]);var s = function s() {
        if (o && o.moveRel && o.visible() && !o._fixed) {
          var a = _l2.selection.getScrollContainer(),
              b = _l2.getBody(),
              c = 0,
              d = 0;if (a) {
            var e = q.getPos(b),
                f = q.getPos(a);c = Math.max(0, f.x - e.x), d = Math.max(0, f.y - e.y);
          }o.fixed(!1).moveRel(b, _l2.rtl ? ["tr-br", "br-tr"] : ["tl-bl", "bl-tl", "tr-br"]).moveBy(c, d);
        }
      },
          t = function t() {
        o && (o.show(), s(), q.addClass(_l2.getBody(), "mce-edit-focus"));
      },
          u = function u() {
        o && (o.hide(), k.hideAll(), q.removeClass(_l2.getBody(), "mce-edit-focus"));
      },
          v = function v() {
        return o ? void (o.visible() || t()) : (o = m.panel = c.create({ type: p ? "panel" : "floatpanel", role: "application", classes: "tinymce tinymce-inline", layout: "flex", direction: "column", align: "stretch", autohide: !1, autofix: !0, fixed: !!p, border: 1, items: [e.hasMenubar(_l2) === !1 ? null : { type: "menubar", border: "0 0 1 0", items: h.createMenuButtons(_l2) }, j.createToolbars(_l2, e.getToolbarSize(_l2))] }), d.fireBeforeRenderUI(_l2), o.renderTo(p || a.body).reflow(), f.addKeys(_l2, o), t(), g.addContextualToolbars(_l2), _l2.on("nodeChange", s), _l2.on("activate", t), _l2.on("deactivate", u), void _l2.nodeChanged());
      };return _l2.settings.content_editable = !0, _l2.on("focus", function () {
        n.skinUiCss ? q.styleSheetLoader.load(n.skinUiCss, v, v) : v();
      }), _l2.on("blur hide", u), _l2.on("remove", function () {
        o && (o.remove(), o = null);
      }), n.skinUiCss && q.styleSheetLoader.load(n.skinUiCss, i.fireSkinLoaded(_l2)), {};
    };return { render: l };
  }), g("2b", ["2o", "o", "2u"], function (a, b, c) {
    "use strict";
    return function (d, e) {
      var f,
          g,
          h = this,
          i = b.classPrefix;h.show = function (b, j) {
        function k() {
          f && (a(d).append('<div class="' + i + "throbber" + (e ? " " + i + "throbber-inline" : "") + '"></div>'), j && j());
        }return h.hide(), f = !0, b ? g = c.setTimeout(k, b) : k(), h;
      }, h.hide = function () {
        var a = d.lastChild;return c.clearTimeout(g), a && a.className.indexOf("throbber") != -1 && a.parentNode.removeChild(a), f = !1, h;
      };
    };
  }), g("2i", ["2b"], function (a) {
    var b = function b(_b4, c) {
      var d;_b4.on("ProgressState", function (b) {
        d = d || new a(c.panel.getEl("body")), b.state ? d.show(b.time) : d.hide();
      });
    };return { setup: b };
  }), g("7", ["13", "2f", "2g", "2h", "2i"], function (a, b, c, d, e) {
    var f = function f(a, _f2, g) {
      var h = b.getSkinUrl(a);return h && (g.skinUiCss = h + "/skin.min.css", a.contentCSS.push(h + "/content" + (a.inline ? ".inline" : "") + ".min.css")), e.setup(a, _f2), b.isInline(a) ? d.render(a, _f2, g) : c.render(a, _f2, g);
    };return { renderUI: f };
  }), h("2m", setTimeout), g("2d", ["o", "1s"], function (a, b) {
    return a.extend({ Mixins: [b], Defaults: { classes: "widget tooltip tooltip-n" }, renderHtml: function renderHtml() {
        var a = this,
            b = a.classPrefix;return '<div id="' + a._id + '" class="' + a.classes + '" role="presentation"><div class="' + b + 'tooltip-arrow"></div><div class="' + b + 'tooltip-inner">' + a.encode(a.state.get("text")) + "</div></div>";
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:text", function (b) {
          a.getEl().lastChild.innerHTML = a.encode(b.value);
        }), a._super();
      }, repaint: function repaint() {
        var a,
            b,
            c = this;a = c.getEl().style, b = c._layoutRect, a.left = b.x + "px", a.top = b.y + "px", a.zIndex = 131070;
      } });
  }), g("15", ["o", "2d"], function (a, b) {
    "use strict";
    var c,
        d = a.extend({ init: function init(a) {
        var b = this;b._super(a), a = b.settings, b.canFocus = !0, a.tooltip && d.tooltips !== !1 && (b.on("mouseenter", function (c) {
          var d = b.tooltip().moveTo(-65535);if (c.control == b) {
            var e = d.text(a.tooltip).show().testMoveRel(b.getEl(), ["bc-tc", "bc-tl", "bc-tr"]);d.classes.toggle("tooltip-n", "bc-tc" == e), d.classes.toggle("tooltip-nw", "bc-tl" == e), d.classes.toggle("tooltip-ne", "bc-tr" == e), d.moveRel(b.getEl(), e);
          } else d.hide();
        }), b.on("mouseleave mousedown click", function () {
          b.tooltip().hide();
        })), b.aria("label", a.ariaLabel || a.tooltip);
      }, tooltip: function tooltip() {
        return c || (c = new b({ type: "tooltip" }), c.renderTo()), c;
      }, postRender: function postRender() {
        var a = this,
            b = a.settings;a._super(), a.parent() || !b.width && !b.height || (a.initLayoutRect(), a.repaint()), b.autofocus && a.focus();
      }, bindStates: function bindStates() {
        function a(a) {
          c.aria("disabled", a), c.classes.toggle("disabled", a);
        }function b(a) {
          c.aria("pressed", a), c.classes.toggle("active", a);
        }var c = this;return c.state.on("change:disabled", function (b) {
          a(b.value);
        }), c.state.on("change:active", function (a) {
          b(a.value);
        }), c.state.get("disabled") && a(!0), c.state.get("active") && b(!0), c._super();
      }, remove: function remove() {
        this._super(), c && (c.remove(), c = null);
      } });return d;
  }), g("1x", ["15"], function (a) {
    "use strict";
    return a.extend({ Defaults: { value: 0 }, init: function init(a) {
        var b = this;b._super(a), b.classes.add("progress"), b.settings.filter || (b.settings.filter = function (a) {
          return Math.round(a);
        });
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a._id,
            c = this.classPrefix;return '<div id="' + b + '" class="' + a.classes + '"><div class="' + c + 'bar-container"><div class="' + c + 'bar"></div></div><div class="' + c + 'text">0%</div></div>';
      }, postRender: function postRender() {
        var a = this;return a._super(), a.value(a.settings.value), a;
      }, bindStates: function bindStates() {
        function a(a) {
          a = b.settings.filter(a), b.getEl().lastChild.innerHTML = a + "%", b.getEl().firstChild.firstChild.style.width = a + "%";
        }var b = this;return b.state.on("change:value", function (b) {
          a(b.value);
        }), a(b.state.get("value")), b._super();
      } });
  }), g("1t", ["o", "1s", "1x", "2u"], function (a, b, c, d) {
    var e = function e(a, b) {
      a.getEl().lastChild.textContent = b + (a.progressBar ? " " + a.progressBar.value() + "%" : "");
    };return a.extend({ Mixins: [b], Defaults: { classes: "widget notification" }, init: function init(a) {
        var b = this;b._super(a), b.maxWidth = a.maxWidth, a.text && b.text(a.text), a.icon && (b.icon = a.icon), a.color && (b.color = a.color), a.type && b.classes.add("notification-" + a.type), a.timeout && (a.timeout < 0 || a.timeout > 0) && !a.closeButton ? b.closeButton = !1 : (b.classes.add("has-close"), b.closeButton = !0), a.progressBar && (b.progressBar = new c()), b.on("click", function (a) {
          a.target.className.indexOf(b.classPrefix + "close") != -1 && b.close();
        });
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a.classPrefix,
            c = "",
            d = "",
            e = "",
            f = "";return a.icon && (c = '<i class="' + b + "ico " + b + "i-" + a.icon + '"></i>'), f = ' style="max-width: ' + a.maxWidth + "px;" + (a.color ? "background-color: " + a.color + ';"' : '"'), a.closeButton && (d = '<button type="button" class="' + b + 'close" aria-hidden="true">\xd7</button>'), a.progressBar && (e = a.progressBar.renderHtml()), '<div id="' + a._id + '" class="' + a.classes + '"' + f + ' role="presentation">' + c + '<div class="' + b + 'notification-inner">' + a.state.get("text") + "</div>" + e + d + '<div style="clip: rect(1px, 1px, 1px, 1px);height: 1px;overflow: hidden;position: absolute;width: 1px;" aria-live="assertive" aria-relevant="additions" aria-atomic="true"></div></div>';
      }, postRender: function postRender() {
        var a = this;return d.setTimeout(function () {
          a.$el.addClass(a.classPrefix + "in"), e(a, a.state.get("text"));
        }, 100), a._super();
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:text", function (b) {
          a.getEl().firstChild.innerHTML = b.value, e(a, b.value);
        }), a.progressBar && (a.progressBar.bindStates(), a.progressBar.state.on("change:value", function (b) {
          e(a, a.state.get("text"));
        })), a._super();
      }, close: function close() {
        var a = this;return a.fire("close").isDefaultPrevented() || a.remove(), a;
      }, repaint: function repaint() {
        var a,
            b,
            c = this;a = c.getEl().style, b = c._layoutRect, a.left = b.x + "px", a.top = b.y + "px", a.zIndex = 65534;
      } });
  }), g("9", ["2l", "2m", "c", "2n", "1t"], function (a, b, c, d, e) {
    return function (f) {
      var g = function g(a) {
        return a.inline ? a.getElement() : a.getContentAreaContainer();
      },
          h = function h() {
        var a = g(f);return d.getSize(a).width;
      },
          i = function i(b) {
        a.each(b, function (a) {
          a.moveTo(0, 0);
        });
      },
          j = function j(b) {
        if (b.length > 0) {
          var c = b.slice(0, 1)[0],
              d = g(f);c.moveRel(d, "tc-tc"), a.each(b, function (a, c) {
            c > 0 && a.moveRel(b[c - 1].getEl(), "bc-tc");
          });
        }
      },
          k = function k(a) {
        i(a), j(a);
      },
          l = function l(a, d) {
        var f = c.extend(a, { maxWidth: h() }),
            g = new e(f);return g.args = f, f.timeout > 0 && (g.timer = b(function () {
          g.close(), d();
        }, f.timeout)), g.on("close", function () {
          d();
        }), g.renderTo(), g;
      },
          m = function m(a) {
        a.close();
      },
          n = function n(a) {
        return a.args;
      };return { open: l, close: m, reposition: k, getArgs: n };
    };
  }), g("2e", ["12", "2m", "1", "2o", "14", "2u", "2r", "2n", "p", "w", "1u"], function (a, b, c, d, e, f, g, h, i, j, k) {
    "use strict";
    function l(b) {
      var c,
          f = "width=device-width,initial-scale=1.0,user-scalable=0,minimum-scale=1.0,maximum-scale=1.0",
          g = d("meta[name=viewport]")[0];e.overrideViewPort !== !1 && (g || (g = a.createElement("meta"), g.setAttribute("name", "viewport"), a.getElementsByTagName("head")[0].appendChild(g)), c = g.getAttribute("content"), c && "undefined" != typeof q && (q = c), g.setAttribute("content", b ? f : q));
    }function m(b, c) {
      n() && c === !1 && d([a.documentElement, a.body]).removeClass(b + "fullscreen");
    }function n() {
      for (var a = 0; a < p.length; a++) {
        if (p[a]._fullscreen) return !0;
      }return !1;
    }function o() {
      function a() {
        var a,
            b,
            c = h.getWindowSize();for (a = 0; a < p.length; a++) {
          b = p[a].layoutRect(), p[a].moveTo(p[a].settings.x || Math.max(0, c.w / 2 - b.w / 2), p[a].settings.y || Math.max(0, c.h / 2 - b.h / 2));
        }
      }if (!e.desktop) {
        var b = { w: c.innerWidth, h: c.innerHeight };f.setInterval(function () {
          var a = c.innerWidth,
              e = c.innerHeight;b.w == a && b.h == e || (b = { w: a, h: e }, d(c).trigger("resize"));
        }, 100);
      }d(c).on("resize", a);
    }var p = [],
        q = "",
        r = j.extend({ modal: !0, Defaults: { border: 1, layout: "flex", containerCls: "panel", role: "dialog", callbacks: { submit: function submit() {
            this.fire("submit", { data: this.toJSON() });
          }, close: function close() {
            this.close();
          } } }, init: function init(a) {
        var b = this;b._super(a), b.isRtl() && b.classes.add("rtl"), b.classes.add("window"), b.bodyClasses.add("window-body"), b.state.set("fixed", !0), a.buttons && (b.statusbar = new k({ layout: "flex", border: "1 0 0 0", spacing: 3, padding: 10, align: "center", pack: b.isRtl() ? "start" : "end", defaults: { type: "button" }, items: a.buttons }), b.statusbar.classes.add("foot"), b.statusbar.parent(b)), b.on("click", function (a) {
          var c = b.classPrefix + "close";(h.hasClass(a.target, c) || h.hasClass(a.target.parentNode, c)) && b.close();
        }), b.on("cancel", function () {
          b.close();
        }), b.aria("describedby", b.describedBy || b._id + "-none"), b.aria("label", a.title), b._fullscreen = !1;
      }, recalc: function recalc() {
        var a,
            b,
            c,
            d,
            e = this,
            f = e.statusbar;e._fullscreen && (e.layoutRect(h.getWindowSize()), e.layoutRect().contentH = e.layoutRect().innerH), e._super(), a = e.layoutRect(), e.settings.title && !e._fullscreen && (b = a.headerW, b > a.w && (c = a.x - Math.max(0, b / 2), e.layoutRect({ w: b, x: c }), d = !0)), f && (f.layoutRect({ w: e.layoutRect().innerW }).recalc(), b = f.layoutRect().minW + a.deltaW, b > a.w && (c = a.x - Math.max(0, b - a.w), e.layoutRect({ w: b, x: c }), d = !0)), d && e.recalc();
      }, initLayoutRect: function initLayoutRect() {
        var a,
            b = this,
            c = b._super(),
            d = 0;if (b.settings.title && !b._fullscreen) {
          a = b.getEl("head");var e = h.getSize(a);c.headerW = e.width, c.headerH = e.height, d += c.headerH;
        }b.statusbar && (d += b.statusbar.layoutRect().h), c.deltaH += d, c.minH += d, c.h += d;var f = h.getWindowSize();return c.x = b.settings.x || Math.max(0, f.w / 2 - c.w / 2), c.y = b.settings.y || Math.max(0, f.h / 2 - c.h / 2), c;
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a._layout,
            c = a._id,
            d = a.classPrefix,
            e = a.settings,
            f = "",
            g = "",
            h = e.html;return a.preRender(), b.preRender(a), e.title && (f = '<div id="' + c + '-head" class="' + d + 'window-head"><div id="' + c + '-title" class="' + d + 'title">' + a.encode(e.title) + '</div><div id="' + c + '-dragh" class="' + d + 'dragh"></div><button type="button" class="' + d + 'close" aria-hidden="true"><i class="mce-ico mce-i-remove"></i></button></div>'), e.url && (h = '<iframe src="' + e.url + '" tabindex="-1"></iframe>'), "undefined" == typeof h && (h = b.renderHtml(a)), a.statusbar && (g = a.statusbar.renderHtml()), '<div id="' + c + '" class="' + a.classes + '" hidefocus="1"><div class="' + a.classPrefix + 'reset" role="application">' + f + '<div id="' + c + '-body" class="' + a.bodyClasses + '">' + h + "</div>" + g + "</div></div>";
      }, fullscreen: function fullscreen(b) {
        var e,
            i,
            j = this,
            k = a.documentElement,
            l = j.classPrefix;if (b != j._fullscreen) if (d(c).on("resize", function () {
          var a;if (j._fullscreen) if (e) j._timer || (j._timer = f.setTimeout(function () {
            var a = h.getWindowSize();j.moveTo(0, 0).resizeTo(a.w, a.h), j._timer = 0;
          }, 50));else {
            a = new Date().getTime();var b = h.getWindowSize();j.moveTo(0, 0).resizeTo(b.w, b.h), new Date().getTime() - a > 50 && (e = !0);
          }
        }), i = j.layoutRect(), j._fullscreen = b, b) {
          j._initial = { x: i.x, y: i.y, w: i.w, h: i.h }, j.borderBox = g.parseBox("0"), j.getEl("head").style.display = "none", i.deltaH -= i.headerH + 2, d([k, a.body]).addClass(l + "fullscreen"), j.classes.add("fullscreen");var m = h.getWindowSize();j.moveTo(0, 0).resizeTo(m.w, m.h);
        } else j.borderBox = g.parseBox(j.settings.border), j.getEl("head").style.display = "", i.deltaH += i.headerH, d([k, a.body]).removeClass(l + "fullscreen"), j.classes.remove("fullscreen"), j.moveTo(j._initial.x, j._initial.y).resizeTo(j._initial.w, j._initial.h);return j.reflow();
      }, postRender: function postRender() {
        var a,
            c = this;b(function () {
          c.classes.add("in"), c.fire("open");
        }, 0), c._super(), c.statusbar && c.statusbar.postRender(), c.focus(), this.dragHelper = new i(c._id + "-dragh", { start: function start() {
            a = { x: c.layoutRect().x, y: c.layoutRect().y };
          }, drag: function drag(b) {
            c.moveTo(a.x + b.deltaX, a.y + b.deltaY);
          } }), c.on("submit", function (a) {
          a.isDefaultPrevented() || c.close();
        }), p.push(c), l(!0);
      }, submit: function submit() {
        return this.fire("submit", { data: this.toJSON() });
      }, remove: function remove() {
        var a,
            b = this;for (b.dragHelper.destroy(), b._super(), b.statusbar && this.statusbar.remove(), m(b.classPrefix, !1), a = p.length; a--;) {
          p[a] === b && p.splice(a, 1);
        }l(p.length > 0);
      }, getContentWindow: function getContentWindow() {
        var a = this.getEl().getElementsByTagName("iframe")[0];return a ? a.contentWindow : null;
      } });return o(), r;
  }), g("1r", ["12", "2e"], function (a, b) {
    "use strict";
    var c = b.extend({ init: function init(a) {
        a = { border: 1, padding: 20, layout: "flex", pack: "center", align: "center", containerCls: "panel", autoScroll: !0, buttons: { type: "button", text: "Ok", action: "ok" }, items: { type: "label", multiline: !0, maxWidth: 500, maxHeight: 200 } }, this._super(a);
      }, Statics: { OK: 1, OK_CANCEL: 2, YES_NO: 3, YES_NO_CANCEL: 4, msgBox: function msgBox(d) {
          function e(a, b, c) {
            return { type: "button", text: a, subtype: c ? "primary" : "", onClick: function onClick(a) {
                a.control.parents()[1].close(), g(b);
              } };
          }var f,
              g = d.callback || function () {};switch (d.buttons) {case c.OK_CANCEL:
              f = [e("Ok", !0, !0), e("Cancel", !1)];break;case c.YES_NO:case c.YES_NO_CANCEL:
              f = [e("Yes", 1, !0), e("No", 0)], d.buttons == c.YES_NO_CANCEL && f.push(e("Cancel", -1));break;default:
              f = [e("Ok", !0, !0)];}return new b({ padding: 20, x: d.x, y: d.y, minWidth: 300, minHeight: 100, layout: "flex", pack: "center", align: "center", buttons: f, title: d.title, role: "alertdialog", items: { type: "label", multiline: !0, maxWidth: 500, maxHeight: 200, text: d.text }, onPostRender: function onPostRender() {
              this.aria("describedby", this.items()[0]._id);
            }, onClose: d.onClose, onCancel: function onCancel() {
              g(!1);
            } }).renderTo(a.body).reflow();
        }, alert: function alert(a, b) {
          return "string" == typeof a && (a = { text: a }), a.callback = b, c.msgBox(a);
        }, confirm: function confirm(a, b) {
          return "string" == typeof a && (a = { text: a }), a.callback = b, a.buttons = c.OK_CANCEL, c.msgBox(a);
        } } });return c;
  }), g("a", ["2e", "1r"], function (a, b) {
    return function (c) {
      var d = function d(b, c, _d2) {
        var e;return b.title = b.title || " ", b.url = b.url || b.file, b.url && (b.width = parseInt(b.width || 320, 10), b.height = parseInt(b.height || 240, 10)), b.body && (b.items = { defaults: b.defaults, type: b.bodyType || "form", items: b.body, data: b.data, callbacks: b.commands }), b.url || b.buttons || (b.buttons = [{ text: "Ok", subtype: "primary", onclick: function onclick() {
            e.find("form")[0].submit();
          } }, { text: "Cancel", onclick: function onclick() {
            e.close();
          } }]), e = new a(b), e.on("close", function () {
          _d2(e);
        }), b.data && e.on("postRender", function () {
          this.find("*").each(function (a) {
            var c = a.name();c in b.data && a.value(b.data[c]);
          });
        }), e.features = b || {}, e.params = c || {}, e = e.renderTo().reflow();
      },
          e = function e(a, c, d) {
        var e;return e = b.alert(a, function () {
          c();
        }), e.on("close", function () {
          d(e);
        }), e;
      },
          f = function f(a, c, d) {
        var e;return e = b.confirm(a, function (a) {
          c(a);
        }), e.on("close", function () {
          d(e);
        }), e;
      },
          g = function g(a) {
        a.close();
      },
          h = function h(a) {
        return a.params;
      },
          i = function i(a, b) {
        a.params = b;
      };return { open: d, alert: e, confirm: f, close: g, getParams: h, setParams: i };
    };
  }), g("3", ["7", "8", "9", "a"], function (a, b, c, d) {
    var e = function e(_e2) {
      var f = function f(b) {
        return a.renderUI(_e2, this, b);
      },
          g = function g(a, c) {
        return b.resizeTo(_e2, a, c);
      },
          h = function h(a, c) {
        return b.resizeBy(_e2, a, c);
      },
          i = function i() {
        return c(_e2);
      },
          j = function j() {
        return d(_e2);
      };return { renderUI: f, resizeTo: g, resizeBy: h, getNotificationManagerImpl: i, getWindowManagerImpl: j };
    };return { get: e };
  }), g("1l", ["2p", "c"], function (a, b) {
    "use strict";
    return a.extend({ Defaults: { firstControlClass: "first", lastControlClass: "last" }, init: function init(a) {
        this.settings = b.extend({}, this.Defaults, a);
      }, preRender: function preRender(a) {
        a.bodyClasses.add(this.settings.containerClass);
      }, applyClasses: function applyClasses(a) {
        var b,
            c,
            d,
            e,
            f = this,
            g = f.settings;b = g.firstControlClass, c = g.lastControlClass, a.each(function (a) {
          a.classes.remove(b).remove(c).add(g.controlClass), a.visible() && (d || (d = a), e = a);
        }), d && d.classes.add(b), e && e.classes.add(c);
      }, renderHtml: function renderHtml(a) {
        var b = this,
            c = "";return b.applyClasses(a.items()), a.items().each(function (a) {
          c += a.renderHtml();
        }), c;
      }, recalc: function recalc() {}, postRender: function postRender() {}, isNative: function isNative() {
        return !1;
      } });
  }), g("d", ["1l"], function (a) {
    "use strict";
    return a.extend({ Defaults: { containerClass: "abs-layout", controlClass: "abs-layout-item" }, recalc: function recalc(a) {
        a.items().filter(":visible").each(function (a) {
          var b = a.settings;a.layoutRect({ x: b.x, y: b.y, w: b.w, h: b.h }), a.recalc && a.recalc();
        });
      }, renderHtml: function renderHtml(a) {
        return '<div id="' + a._id + '-absend" class="' + a.classPrefix + 'abs-end"></div>' + this._super(a);
      } });
  }), g("f", ["12", "1", "15"], function (a, b, c) {
    "use strict";
    return c.extend({ Defaults: { classes: "widget btn", role: "button" }, init: function init(a) {
        var b,
            c = this;c._super(a), a = c.settings, b = c.settings.size, c.on("click mousedown", function (a) {
          a.preventDefault();
        }), c.on("touchstart", function (a) {
          c.fire("click", a), a.preventDefault();
        }), a.subtype && c.classes.add(a.subtype), b && c.classes.add("btn-" + b), a.icon && c.icon(a.icon);
      }, icon: function icon(a) {
        return arguments.length ? (this.state.set("icon", a), this) : this.state.get("icon");
      }, repaint: function repaint() {
        var a,
            b = this.getEl().firstChild;b && (a = b.style, a.width = a.height = "100%"), this._super();
      }, renderHtml: function renderHtml() {
        var a,
            c = this,
            d = c._id,
            e = c.classPrefix,
            f = c.state.get("icon"),
            g = c.state.get("text"),
            h = "";return a = c.settings.image, a ? (f = "none", "string" != typeof a && (a = b.getSelection ? a[0] : a[1]), a = " style=\"background-image: url('" + a + "')\"") : a = "", g && (c.classes.add("btn-has-text"), h = '<span class="' + e + 'txt">' + c.encode(g) + "</span>"), f = f ? e + "ico " + e + "i-" + f : "", '<div id="' + d + '" class="' + c.classes + '" tabindex="-1"><button id="' + d + '-button" role="presentation" type="button" tabindex="-1">' + (f ? '<i class="' + f + '"' + a + "></i>" : "") + h + "</button></div>";
      }, bindStates: function bindStates() {
        function b(a) {
          var b = d("span." + e, c.getEl());a ? (b[0] || (d("button:first", c.getEl()).append('<span class="' + e + '"></span>'), b = d("span." + e, c.getEl())), b.html(c.encode(a))) : b.remove(), c.classes.toggle("btn-has-text", !!a);
        }var c = this,
            d = c.$,
            e = c.classPrefix + "txt";return c.state.on("change:text", function (a) {
          b(a.value);
        }), c.state.on("change:icon", function (d) {
          var e = d.value,
              f = c.classPrefix;c.settings.icon = e, e = e ? f + "ico " + f + "i-" + c.settings.icon : "";var g = c.getEl().firstChild,
              h = g.getElementsByTagName("i")[0];e ? (h && h == g.firstChild || (h = a.createElement("i"), g.insertBefore(h, g.firstChild)), h.className = e) : h && g.removeChild(h), b(c.state.get("text"));
        }), c._super();
      } });
  }), h("2v", RegExp), g("e", ["f", "c", "2n", "2o", "2v"], function (a, b, c, d, e) {
    return a.extend({ init: function init(a) {
        var c = this;a = b.extend({ text: "Browse...", multiple: !1, accept: null }, a), c._super(a), c.classes.add("browsebutton"), a.multiple && c.classes.add("multiple");
      }, postRender: function postRender() {
        var a = this,
            b = c.create("input", { type: "file", id: a._id + "-browse", accept: a.settings.accept });a._super(), d(b).on("change", function (b) {
          var c = b.target.files;a.value = function () {
            return c.length ? a.settings.multiple ? c : c[0] : null;
          }, b.preventDefault(), c.length && a.fire("change", b);
        }), d(b).on("click", function (a) {
          a.stopPropagation();
        }), d(a.getEl("button")).on("click", function (a) {
          a.stopPropagation(), b.click();
        }), a.getEl().appendChild(b);
      }, remove: function remove() {
        d(this.getEl("button")).off(), d(this.getEl("input")).off(), this._super();
      } });
  }), g("g", ["n"], function (a) {
    "use strict";
    return a.extend({ Defaults: { defaultType: "button", role: "group" }, renderHtml: function renderHtml() {
        var a = this,
            b = a._layout;return a.classes.add("btn-group"), a.preRender(), b.preRender(a), '<div id="' + a._id + '" class="' + a.classes + '"><div id="' + a._id + '-body">' + (a.settings.html || "") + b.renderHtml(a) + "</div></div>";
      } });
  }), g("h", ["12", "15"], function (a, b) {
    "use strict";
    return b.extend({ Defaults: { classes: "checkbox", role: "checkbox", checked: !1 }, init: function init(a) {
        var b = this;b._super(a), b.on("click mousedown", function (a) {
          a.preventDefault();
        }), b.on("click", function (a) {
          a.preventDefault(), b.disabled() || b.checked(!b.checked());
        }), b.checked(b.settings.checked);
      }, checked: function checked(a) {
        return arguments.length ? (this.state.set("checked", a), this) : this.state.get("checked");
      }, value: function value(a) {
        return arguments.length ? this.checked(a) : this.checked();
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a._id,
            c = a.classPrefix;return '<div id="' + b + '" class="' + a.classes + '" unselectable="on" aria-labelledby="' + b + '-al" tabindex="-1"><i class="' + c + "ico " + c + 'i-checkbox"></i><span id="' + b + '-al" class="' + c + 'label">' + a.encode(a.state.get("text")) + "</span></div>";
      }, bindStates: function bindStates() {
        function b(a) {
          c.classes.toggle("checked", a), c.aria("checked", a);
        }var c = this;return c.state.on("change:text", function (a) {
          c.getEl("al").firstChild.data = c.translate(a.value);
        }), c.state.on("change:checked change:value", function (a) {
          c.fire("change"), b(a.value);
        }), c.state.on("change:icon", function (b) {
          var d = b.value,
              e = c.classPrefix;if ("undefined" == typeof d) return c.settings.icon;c.settings.icon = d, d = d ? e + "ico " + e + "i-" + c.settings.icon : "";var f = c.getEl().firstChild,
              g = f.getElementsByTagName("i")[0];d ? (g && g == f.firstChild || (g = a.createElement("i"), f.insertBefore(g, f.firstChild)), g.className = d) : g && f.removeChild(g);
        }), c.state.get("checked") && b(!0), c._super();
      } });
  }), g("2w", ["6"], function (a) {
    return a("tinymce.util.VK");
  }), g("m", ["12", "2o", "b", "c", "2w", "2n", "15"], function (a, b, c, d, e, f, g) {
    "use strict";
    return g.extend({ init: function init(a) {
        var c = this;c._super(a), a = c.settings, c.classes.add("combobox"), c.subinput = !0, c.ariaTarget = "inp", a.menu = a.menu || a.values, a.menu && (a.icon = "caret"), c.on("click", function (d) {
          var e = d.target,
              f = c.getEl();if (b.contains(f, e) || e == f) for (; e && e != f;) {
            e.id && e.id.indexOf("-open") != -1 && (c.fire("action"), a.menu && (c.showMenu(), d.aria && c.menu.items()[0].focus())), e = e.parentNode;
          }
        }), c.on("keydown", function (a) {
          var b;13 == a.keyCode && "INPUT" === a.target.nodeName && (a.preventDefault(), c.parents().reverse().each(function (a) {
            if (a.toJSON) return b = a, !1;
          }), c.fire("submit", { data: b.toJSON() }));
        }), c.on("keyup", function (a) {
          if ("INPUT" == a.target.nodeName) {
            var b = c.state.get("value"),
                d = a.target.value;d !== b && (c.state.set("value", d), c.fire("autocomplete", a));
          }
        }), c.on("mouseover", function (a) {
          var b = c.tooltip().moveTo(-65535);if (c.statusLevel() && a.target.className.indexOf(c.classPrefix + "status") !== -1) {
            var d = c.statusMessage() || "Ok",
                e = b.text(d).show().testMoveRel(a.target, ["bc-tc", "bc-tl", "bc-tr"]);b.classes.toggle("tooltip-n", "bc-tc" == e), b.classes.toggle("tooltip-nw", "bc-tl" == e), b.classes.toggle("tooltip-ne", "bc-tr" == e), b.moveRel(a.target, e);
          }
        });
      }, statusLevel: function statusLevel(a) {
        return arguments.length > 0 && this.state.set("statusLevel", a), this.state.get("statusLevel");
      }, statusMessage: function statusMessage(a) {
        return arguments.length > 0 && this.state.set("statusMessage", a), this.state.get("statusMessage");
      }, showMenu: function showMenu() {
        var a,
            b = this,
            d = b.settings;b.menu || (a = d.menu || [], a.length ? a = { type: "menu", items: a } : a.type = a.type || "menu", b.menu = c.create(a).parent(b).renderTo(b.getContainerElm()), b.fire("createmenu"), b.menu.reflow(), b.menu.on("cancel", function (a) {
          a.control === b.menu && b.focus();
        }), b.menu.on("show hide", function (a) {
          a.control.items().each(function (a) {
            a.active(a.value() == b.value());
          });
        }).fire("show"), b.menu.on("select", function (a) {
          b.value(a.control.value());
        }), b.on("focusin", function (a) {
          "INPUT" == a.target.tagName.toUpperCase() && b.menu.hide();
        }), b.aria("expanded", !0)), b.menu.show(), b.menu.layoutRect({ w: b.layoutRect().w }), b.menu.moveRel(b.getEl(), b.isRtl() ? ["br-tr", "tr-br"] : ["bl-tl", "tl-bl"]);
      }, focus: function focus() {
        this.getEl("inp").focus();
      }, repaint: function repaint() {
        var c,
            d,
            e = this,
            g = e.getEl(),
            h = e.getEl("open"),
            i = e.layoutRect(),
            j = 0,
            k = g.firstChild;e.statusLevel() && "none" !== e.statusLevel() && (j = parseInt(f.getRuntimeStyle(k, "padding-right"), 10) - parseInt(f.getRuntimeStyle(k, "padding-left"), 10)), c = h ? i.w - f.getSize(h).width - 10 : i.w - 10;var l = a;return l.all && (!l.documentMode || l.documentMode <= 8) && (d = e.layoutRect().h - 2 + "px"), b(k).css({ width: c - j, lineHeight: d }), e._super(), e;
      }, postRender: function postRender() {
        var a = this;return b(this.getEl("inp")).on("change", function (b) {
          a.state.set("value", b.target.value), a.fire("change", b);
        }), a._super();
      }, renderHtml: function renderHtml() {
        var a,
            b,
            c = this,
            d = c._id,
            e = c.settings,
            f = c.classPrefix,
            g = c.state.get("value") || "",
            h = "",
            i = "",
            j = "";return "spellcheck" in e && (i += ' spellcheck="' + e.spellcheck + '"'), e.maxLength && (i += ' maxlength="' + e.maxLength + '"'), e.size && (i += ' size="' + e.size + '"'), e.subtype && (i += ' type="' + e.subtype + '"'), j = '<i id="' + d + '-status" class="mce-status mce-ico" style="display: none"></i>', c.disabled() && (i += ' disabled="disabled"'), a = e.icon, a && "caret" != a && (a = f + "ico " + f + "i-" + e.icon), b = c.state.get("text"), (a || b) && (h = '<div id="' + d + '-open" class="' + f + "btn " + f + 'open" tabIndex="-1" role="button"><button id="' + d + '-action" type="button" hidefocus="1" tabindex="-1">' + ("caret" != a ? '<i class="' + a + '"></i>' : '<i class="' + f + 'caret"></i>') + (b ? (a ? " " : "") + b : "") + "</button></div>", c.classes.add("has-open")), '<div id="' + d + '" class="' + c.classes + '"><input id="' + d + '-inp" class="' + f + 'textbox" value="' + c.encode(g, !1) + '" hidefocus="1"' + i + ' placeholder="' + c.encode(e.placeholder) + '" />' + j + h + "</div>";
      }, value: function value(a) {
        return arguments.length ? (this.state.set("value", a), this) : (this.state.get("rendered") && this.state.set("value", this.getEl("inp").value), this.state.get("value"));
      }, showAutoComplete: function showAutoComplete(a, b) {
        var e = this;if (0 === a.length) return void e.hideMenu();var f = function f(a, b) {
          return function () {
            e.fire("selectitem", { title: b, value: a });
          };
        };e.menu ? e.menu.items().remove() : e.menu = c.create({ type: "menu", classes: "combobox-menu", layout: "flow" }).parent(e).renderTo(), d.each(a, function (a) {
          e.menu.add({ text: a.title, url: a.previewUrl, match: b, classes: "menu-item-ellipsis", onclick: f(a.value, a.title) });
        }), e.menu.renderNew(), e.hideMenu(), e.menu.on("cancel", function (a) {
          a.control.parent() === e.menu && (a.stopPropagation(), e.focus(), e.hideMenu());
        }), e.menu.on("select", function () {
          e.focus();
        });var g = e.layoutRect().w;e.menu.layoutRect({ w: g, minW: 0, maxW: g }), e.menu.reflow(), e.menu.show(), e.menu.moveRel(e.getEl(), e.isRtl() ? ["br-tr", "tr-br"] : ["bl-tl", "tl-bl"]);
      }, hideMenu: function hideMenu() {
        this.menu && this.menu.hide();
      }, bindStates: function bindStates() {
        var a = this;a.state.on("change:value", function (b) {
          a.getEl("inp").value != b.value && (a.getEl("inp").value = b.value);
        }), a.state.on("change:disabled", function (b) {
          a.getEl("inp").disabled = b.value;
        }), a.state.on("change:statusLevel", function (b) {
          var c = a.getEl("status"),
              d = a.classPrefix,
              e = b.value;f.css(c, "display", "none" === e ? "none" : ""), f.toggleClass(c, d + "i-checkmark", "ok" === e), f.toggleClass(c, d + "i-warning", "warn" === e), f.toggleClass(c, d + "i-error", "error" === e), a.classes.toggle("has-status", "none" !== e), a.repaint();
        }), f.on(a.getEl("status"), "mouseleave", function () {
          a.tooltip().hide();
        }), a.on("cancel", function (b) {
          a.menu && a.menu.visible() && (b.stopPropagation(), a.hideMenu());
        });var b = function b(a, _b5) {
          _b5 && _b5.items().length > 0 && _b5.items().eq(a)[0].focus();
        };return a.on("keydown", function (c) {
          var d = c.keyCode;"INPUT" === c.target.nodeName && (d === e.DOWN ? (c.preventDefault(), a.fire("autocomplete"), b(0, a.menu)) : d === e.UP && (c.preventDefault(), b(-1, a.menu)));
        }), a._super();
      }, remove: function remove() {
        b(this.getEl("inp")).off(), this.menu && this.menu.remove(), this._super();
      } });
  }), g("j", ["m"], function (a) {
    "use strict";
    return a.extend({ init: function init(a) {
        var b = this;a.spellcheck = !1, a.onaction && (a.icon = "none"), b._super(a), b.classes.add("colorbox"), b.on("change keyup postrender", function () {
          b.repaintColor(b.value());
        });
      }, repaintColor: function repaintColor(a) {
        var b = this.getEl("open"),
            c = b ? b.getElementsByTagName("i")[0] : null;if (c) try {
          c.style.background = a;
        } catch (a) {}
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:value", function (b) {
          a.state.get("rendered") && a.repaintColor(b.value);
        }), a._super();
      } });
  }), g("1v", ["f", "w"], function (a, b) {
    "use strict";
    return a.extend({ showPanel: function showPanel() {
        var a = this,
            c = a.settings;if (a.classes.add("opened"), a.panel) a.panel.show();else {
          var d = c.panel;d.type && (d = { layout: "grid", items: d }), d.role = d.role || "dialog", d.popover = !0, d.autohide = !0, d.ariaRoot = !0, a.panel = new b(d).on("hide", function () {
            a.classes.remove("opened");
          }).on("cancel", function (b) {
            b.stopPropagation(), a.focus(), a.hidePanel();
          }).parent(a).renderTo(a.getContainerElm()), a.panel.fire("show"), a.panel.reflow();
        }var e = a.panel.testMoveRel(a.getEl(), c.popoverAlign || (a.isRtl() ? ["bc-tc", "bc-tl", "bc-tr"] : ["bc-tc", "bc-tr", "bc-tl"]));a.panel.classes.toggle("start", "bc-tl" === e), a.panel.classes.toggle("end", "bc-tr" === e), a.panel.moveRel(a.getEl(), e);
      }, hidePanel: function hidePanel() {
        var a = this;a.panel && a.panel.hide();
      }, postRender: function postRender() {
        var a = this;return a.aria("haspopup", !0), a.on("click", function (b) {
          b.control === a && (a.panel && a.panel.visible() ? a.hidePanel() : (a.showPanel(), a.panel.focus(!!b.aria)));
        }), a._super();
      }, remove: function remove() {
        return this.panel && (this.panel.remove(), this.panel = null), this._super();
      } });
  }), g("k", ["1v", "2j"], function (a, b) {
    "use strict";
    var c = b.DOM;return a.extend({ init: function init(a) {
        this._super(a), this.classes.add("splitbtn"), this.classes.add("colorbutton");
      }, color: function color(a) {
        return a ? (this._color = a, this.getEl("preview").style.backgroundColor = a, this) : this._color;
      }, resetColor: function resetColor() {
        return this._color = null, this.getEl("preview").style.backgroundColor = null, this;
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a._id,
            c = a.classPrefix,
            d = a.state.get("text"),
            e = a.settings.icon ? c + "ico " + c + "i-" + a.settings.icon : "",
            f = a.settings.image ? " style=\"background-image: url('" + a.settings.image + "')\"" : "",
            g = "";return d && (a.classes.add("btn-has-text"), g = '<span class="' + c + 'txt">' + a.encode(d) + "</span>"), '<div id="' + b + '" class="' + a.classes + '" role="button" tabindex="-1" aria-haspopup="true"><button role="presentation" hidefocus="1" type="button" tabindex="-1">' + (e ? '<i class="' + e + '"' + f + "></i>" : "") + '<span id="' + b + '-preview" class="' + c + 'preview"></span>' + g + '</button><button type="button" class="' + c + 'open" hidefocus="1" tabindex="-1"> <i class="' + c + 'caret"></i></button></div>';
      }, postRender: function postRender() {
        var a = this,
            b = a.settings.onclick;return a.on("click", function (d) {
          d.aria && "down" === d.aria.key || d.control != a || c.getParent(d.target, "." + a.classPrefix + "open") || (d.stopImmediatePropagation(), b.call(a, d));
        }), delete a.settings.onclick, a._super();
      } });
  }), g("2x", ["6"], function (a) {
    return a("tinymce.util.Color");
  }), g("l", ["15", "p", "2n", "2x"], function (a, b, c, d) {
    "use strict";
    return a.extend({ Defaults: { classes: "widget colorpicker" }, init: function init(a) {
        this._super(a);
      }, postRender: function postRender() {
        function a(a, b) {
          var d,
              e,
              f = c.getPos(a);return d = b.pageX - f.x, e = b.pageY - f.y, d = Math.max(0, Math.min(d / a.clientWidth, 1)), e = Math.max(0, Math.min(e / a.clientHeight, 1)), { x: d, y: e };
        }function e(a, b) {
          var e = (360 - a.h) / 360;c.css(j, { top: 100 * e + "%" }), b || c.css(l, { left: a.s + "%", top: 100 - a.v + "%" }), k.style.background = new d({ s: 100, v: 100, h: a.h }).toHex(), m.color().parse({ s: a.s, v: a.v, h: a.h });
        }function f(b) {
          var c;c = a(k, b), h.s = 100 * c.x, h.v = 100 * (1 - c.y), e(h), m.fire("change");
        }function g(b) {
          var c;c = a(i, b), h = n.toHsv(), h.h = 360 * (1 - c.y), e(h, !0), m.fire("change");
        }var h,
            i,
            j,
            k,
            l,
            m = this,
            n = m.color();i = m.getEl("h"), j = m.getEl("hp"), k = m.getEl("sv"), l = m.getEl("svp"), m._repaint = function () {
          h = n.toHsv(), e(h);
        }, m._super(), m._svdraghelper = new b(m._id + "-sv", { start: f, drag: f }), m._hdraghelper = new b(m._id + "-h", { start: g, drag: g }), m._repaint();
      }, rgb: function rgb() {
        return this.color().toRgb();
      }, value: function value(a) {
        var b = this;return arguments.length ? (b.color().parse(a), void (b._rendered && b._repaint())) : b.color().toHex();
      }, color: function color() {
        return this._color || (this._color = new d()), this._color;
      }, renderHtml: function renderHtml() {
        function a() {
          var a,
              b,
              c,
              d,
              g = "";for (c = "filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=", d = f.split(","), a = 0, b = d.length - 1; a < b; a++) {
            g += '<div class="' + e + 'colorpicker-h-chunk" style="height:' + 100 / b + "%;" + c + d[a] + ",endColorstr=" + d[a + 1] + ");-ms-" + c + d[a] + ",endColorstr=" + d[a + 1] + ')"></div>';
          }return g;
        }var b,
            c = this,
            d = c._id,
            e = c.classPrefix,
            f = "#ff0000,#ff0080,#ff00ff,#8000ff,#0000ff,#0080ff,#00ffff,#00ff80,#00ff00,#80ff00,#ffff00,#ff8000,#ff0000",
            g = "background: -ms-linear-gradient(top," + f + ");background: linear-gradient(to bottom," + f + ");";return b = '<div id="' + d + '-h" class="' + e + 'colorpicker-h" style="' + g + '">' + a() + '<div id="' + d + '-hp" class="' + e + 'colorpicker-h-marker"></div></div>', '<div id="' + d + '" class="' + c.classes + '"><div id="' + d + '-sv" class="' + e + 'colorpicker-sv"><div class="' + e + 'colorpicker-overlay1"><div class="' + e + 'colorpicker-overlay2"><div id="' + d + '-svp" class="' + e + 'colorpicker-selector1"><div class="' + e + 'colorpicker-selector2"></div></div></div></div></div>' + b + "</div>";
      } });
  }), g("q", ["15", "c", "2n", "2v"], function (a, b, c, d) {
    return a.extend({ init: function init(a) {
        var c = this;a = b.extend({ height: 100, text: "Drop an image here", multiple: !1, accept: null }, a), c._super(a), c.classes.add("dropzone"), a.multiple && c.classes.add("multiple");
      }, renderHtml: function renderHtml() {
        var a,
            b,
            d = this,
            e = d.settings;return a = { id: d._id, hidefocus: "1" }, b = c.create("div", a, "<span>" + this.translate(e.text) + "</span>"), e.height && c.css(b, "height", e.height + "px"), e.width && c.css(b, "width", e.width + "px"), b.className = d.classes, b.outerHTML;
      }, postRender: function postRender() {
        var a = this,
            c = function c(b) {
          b.preventDefault(), a.classes.toggle("dragenter"), a.getEl().className = a.classes;
        },
            e = function e(c) {
          var e = a.settings.accept;if ("string" != typeof e) return c;var f = new d("(" + e.split(/\s*,\s*/).join("|") + ")$", "i");return b.grep(c, function (a) {
            return f.test(a.name);
          });
        };a._super(), a.$el.on("dragover", function (a) {
          a.preventDefault();
        }), a.$el.on("dragenter", c), a.$el.on("dragleave", c), a.$el.on("drop", function (b) {
          if (b.preventDefault(), !a.state.get("disabled")) {
            var c = e(b.dataTransfer.files);a.value = function () {
              return c.length ? a.settings.multiple ? c : c[0] : null;
            }, c.length && a.fire("change", b);
          }
        });
      }, remove: function remove() {
        this.$el.off(), this._super();
      } });
  }), g("1w", ["15"], function (a) {
    "use strict";
    return a.extend({ init: function init(a) {
        var b = this;a.delimiter || (a.delimiter = "\xbb"), b._super(a), b.classes.add("path"), b.canFocus = !0, b.on("click", function (a) {
          var c,
              d = a.target;(c = d.getAttribute("data-index")) && b.fire("select", { value: b.row()[c], index: c });
        }), b.row(b.settings.row);
      }, focus: function focus() {
        var a = this;return a.getEl().firstChild.focus(), a;
      }, row: function row(a) {
        return arguments.length ? (this.state.set("row", a), this) : this.state.get("row");
      }, renderHtml: function renderHtml() {
        var a = this;return '<div id="' + a._id + '" class="' + a.classes + '">' + a._getDataPathHtml(a.state.get("row")) + "</div>";
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:row", function (b) {
          a.innerHtml(a._getDataPathHtml(b.value));
        }), a._super();
      }, _getDataPathHtml: function _getDataPathHtml(a) {
        var b,
            c,
            d = this,
            e = a || [],
            f = "",
            g = d.classPrefix;for (b = 0, c = e.length; b < c; b++) {
          f += (b > 0 ? '<div class="' + g + 'divider" aria-hidden="true"> ' + d.settings.delimiter + " </div>" : "") + '<div role="button" class="' + g + "path-item" + (b == c - 1 ? " " + g + "last" : "") + '" data-index="' + b + '" tabindex="-1" id="' + d._id + "-" + b + '" aria-level="' + (b + 1) + '">' + e[b].name + "</div>";
        }return f || (f = '<div class="' + g + 'path-item">\xa0</div>'), f;
      } });
  }), g("r", ["1w"], function (a) {
    return a.extend({ postRender: function postRender() {
        function a(a) {
          if (1 === a.nodeType) {
            if ("BR" == a.nodeName || a.getAttribute("data-mce-bogus")) return !0;if ("bookmark" === a.getAttribute("data-mce-type")) return !0;
          }return !1;
        }var b = this,
            c = b.settings.editor;return c.settings.elementpath !== !1 && (b.on("select", function (a) {
          c.focus(), c.selection.select(this.row()[a.index].element), c.nodeChanged();
        }), c.on("nodeChange", function (d) {
          for (var e = [], f = d.parents, g = f.length; g--;) {
            if (1 == f[g].nodeType && !a(f[g])) {
              var h = c.fire("ResolveName", { name: f[g].nodeName.toLowerCase(), target: f[g] });if (h.isDefaultPrevented() || e.push({ name: h.name, element: f[g] }), h.isPropagationStopped()) break;
            }
          }b.row(e);
        })), b._super();
      } });
  }), g("1f", ["n"], function (a) {
    "use strict";
    return a.extend({ Defaults: { layout: "flex", align: "center", defaults: { flex: 1 } }, renderHtml: function renderHtml() {
        var a = this,
            b = a._layout,
            c = a.classPrefix;return a.classes.add("formitem"), b.preRender(a), '<div id="' + a._id + '" class="' + a.classes + '" hidefocus="1" tabindex="-1">' + (a.settings.title ? '<div id="' + a._id + '-title" class="' + c + 'title">' + a.settings.title + "</div>" : "") + '<div id="' + a._id + '-body" class="' + a.bodyClasses + '">' + (a.settings.html || "") + b.renderHtml(a) + "</div></div>";
      } });
  }), g("y", ["n", "1f", "c"], function (a, b, c) {
    "use strict";
    return a.extend({ Defaults: { containerCls: "form", layout: "flex", direction: "column", align: "stretch", flex: 1, padding: 15, labelGap: 30, spacing: 10, callbacks: { submit: function submit() {
            this.submit();
          } } }, preRender: function preRender() {
        var a = this,
            d = a.items();a.settings.formItemDefaults || (a.settings.formItemDefaults = { layout: "flex", autoResize: "overflow", defaults: { flex: 1 } }), d.each(function (d) {
          var e,
              f = d.settings.label;f && (e = new b(c.extend({ items: { type: "label", id: d._id + "-l", text: f, flex: 0, forId: d._id, disabled: d.disabled() } }, a.settings.formItemDefaults)), e.type = "formitem", d.aria("labelledby", d._id + "-l"), "undefined" == typeof d.settings.flex && (d.settings.flex = 1), a.replace(d, e), e.add(d));
        });
      }, submit: function submit() {
        return this.fire("submit", { data: this.toJSON() });
      }, postRender: function postRender() {
        var a = this;a._super(), a.fromJSON(a.settings.data);
      }, bindStates: function bindStates() {
        function a() {
          var a,
              c,
              d,
              e = 0,
              f = [];if (b.settings.labelGapCalc !== !1) for (d = "children" == b.settings.labelGapCalc ? b.find("formitem") : b.items(), d.filter("formitem").each(function (a) {
            var b = a.items()[0],
                c = b.getEl().clientWidth;e = c > e ? c : e, f.push(b);
          }), c = b.settings.labelGap || 0, a = f.length; a--;) {
            f[a].settings.minWidth = e + c;
          }
        }var b = this;b._super(), b.on("show", a), a();
      } });
  }), g("s", ["y"], function (a) {
    "use strict";
    return a.extend({ Defaults: { containerCls: "fieldset", layout: "flex", direction: "column", align: "stretch", flex: 1, padding: "25 15 5 15", labelGap: 30, spacing: 10, border: 1 }, renderHtml: function renderHtml() {
        var a = this,
            b = a._layout,
            c = a.classPrefix;return a.preRender(), b.preRender(a), '<fieldset id="' + a._id + '" class="' + a.classes + '" hidefocus="1" tabindex="-1">' + (a.settings.title ? '<legend id="' + a._id + '-title" class="' + c + 'fieldset-title">' + a.settings.title + "</legend>" : "") + '<div id="' + a._id + '-body" class="' + a.bodyClasses + '">' + (a.settings.html || "") + b.renderHtml(a) + "</div></fieldset>";
      } });
  }), h("3q", Date), h("3r", Math), g("3h", ["3q", "3r", "3g"], function (a, b, c) {
    var d = 0,
        e = function e(_e3) {
      var f = new a(),
          g = f.getTime(),
          h = b.floor(1e9 * b.random());return d++, _e3 + "_" + h + d + c(g);
    };return { generate: e };
  }), g("31", [], function () {
    return "undefined" == typeof console && (console = { log: function log() {} }), console;
  }), g("10", ["z", "2z", "31", "12"], function (a, b, c, d) {
    var e = function e(a, b) {
      var e = b || d,
          f = e.createElement("div");if (f.innerHTML = a, !f.hasChildNodes() || f.childNodes.length > 1) throw c.error("HTML does not have a single root node", a), "HTML must have a single root node";return h(f.childNodes[0]);
    },
        f = function f(a, b) {
      var c = b || d,
          e = c.createElement(a);return h(e);
    },
        g = function g(a, b) {
      var c = b || d,
          e = c.createTextNode(a);return h(e);
    },
        h = function h(c) {
      if (null === c || void 0 === c) throw new b("Node cannot be null or undefined");return { dom: a.constant(c) };
    };return { fromHtml: e, fromTag: f, fromText: g, fromDom: h };
  }), g("3t", [], function () {
    var a = function a(_a3) {
      var b,
          c = !1;return function () {
        return c || (c = !0, b = _a3.apply(null, arguments)), b;
      };
    };return { cached: a };
  }), g("3m", [], function () {
    return { ATTRIBUTE: 2, CDATA_SECTION: 4, COMMENT: 8, DOCUMENT: 9, DOCUMENT_TYPE: 10, DOCUMENT_FRAGMENT: 11, ELEMENT: 1, TEXT: 3, PROCESSING_INSTRUCTION: 7, ENTITY_REFERENCE: 5, ENTITY: 6, NOTATION: 12 };
  }), g("3n", ["3m"], function (a) {
    var b = function b(a) {
      var b = a.dom().nodeName;return b.toLowerCase();
    },
        c = function c(a) {
      return a.dom().nodeType;
    },
        d = function d(a) {
      return a.dom().nodeValue;
    },
        e = function e(a) {
      return function (b) {
        return c(b) === a;
      };
    },
        f = function f(d) {
      return c(d) === a.COMMENT || "#comment" === b(d);
    },
        g = e(a.ELEMENT),
        h = e(a.TEXT),
        i = e(a.DOCUMENT);return { name: b, type: c, value: d, isElement: g, isText: h, isDocument: i, isComment: f };
  }), g("3k", ["3t", "10", "3n", "12"], function (a, b, c, d) {
    var e = function e(a) {
      var b = c.isText(a) ? a.dom().parentNode : a.dom();return void 0 !== b && null !== b && b.ownerDocument.body.contains(b);
    },
        f = a.cached(function () {
      return g(b.fromDom(d));
    }),
        g = function g(a) {
      var c = a.dom().body;if (null === c || void 0 === c) throw "Body is not available yet";return b.fromDom(c);
    };return { body: f, getBody: g, inBody: e };
  }), g("3j", ["2y", "3g"], function (a, b) {
    var c = function c(_c3) {
      if (null === _c3) return "null";var d = typeof _c3 === "undefined" ? "undefined" : _typeof(_c3);return "object" === d && a.prototype.isPrototypeOf(_c3) ? "array" : "object" === d && b.prototype.isPrototypeOf(_c3) ? "string" : d;
    },
        d = function d(a) {
      return function (b) {
        return c(b) === a;
      };
    };return { isString: d("string"), isObject: d("object"), isArray: d("array"), isNull: d("null"), isBoolean: d("boolean"), isUndefined: d("undefined"), isFunction: d("function"), isNumber: d("number") };
  }), g("48", ["2l", "z", "2y", "2z"], function (a, b, c, d) {
    return function () {
      var e = arguments;return function () {
        for (var f = new c(arguments.length), g = 0; g < f.length; g++) {
          f[g] = arguments[g];
        }if (e.length !== f.length) throw new d('Wrong number of arguments to struct. Expected "[' + e.length + ']", got ' + f.length + " arguments");var h = {};return a.each(e, function (a, c) {
          h[a] = b.constant(f[c]);
        }), h;
      };
    };
  }), g("4d", ["3f", "3p"], function (a, b) {
    var c = function () {
      var a = b.keys,
          c = function c(a) {
        var b = [];for (var c in a) {
          a.hasOwnProperty(c) && b.push(c);
        }return b;
      };return void 0 === a ? c : a;
    }(),
        d = function d(a, b) {
      for (var d = c(a), e = 0, f = d.length; e < f; e++) {
        var g = d[e],
            h = a[g];b(h, g, a);
      }
    },
        e = function e(a, b) {
      return f(a, function (a, c, d) {
        return { k: c, v: b(a, c, d) };
      });
    },
        f = function f(a, b) {
      var c = {};return d(a, function (d, e) {
        var f = b(d, e, a);c[f.k] = f.v;
      }), c;
    },
        g = function g(a, b) {
      var c = {},
          e = {};return d(a, function (a, d) {
        var f = b(a, d) ? c : e;f[d] = a;
      }), { t: c, f: e };
    },
        h = function h(a, b) {
      var c = [];return d(a, function (a, d) {
        c.push(b(a, d));
      }), c;
    },
        i = function i(b, d) {
      for (var e = c(b), f = 0, g = e.length; f < g; f++) {
        var h = e[f],
            i = b[h];if (d(i, h, b)) return a.some(i);
      }return a.none();
    },
        j = function j(a) {
      return h(a, function (a) {
        return a;
      });
    },
        k = function k(a) {
      return j(a).length;
    };return { bifilter: g, each: d, map: e, mapToArray: h, tupleMap: f, find: i, keys: c, values: j, size: k };
  }), g("4e", ["2l", "3j", "2z"], function (a, b, c) {
    var d = function d(a) {
      return a.slice(0).sort();
    },
        e = function e(a, b) {
      throw new c("All required keys (" + d(a).join(", ") + ") were not specified. Specified keys were: " + d(b).join(", ") + ".");
    },
        f = function f(a) {
      throw new c("Unsupported keys for object: " + d(a).join(", "));
    },
        g = function g(d, e) {
      if (!b.isArray(e)) throw new c("The " + d + " fields must be an array. Was: " + e + ".");a.each(e, function (a) {
        if (!b.isString(a)) throw new c("The value " + a + " in the " + d + " fields was not a string.");
      });
    },
        h = function h(a, b) {
      throw new c("All values need to be of type: " + b + ". Keys (" + d(a).join(", ") + ") were not.");
    },
        i = function i(b) {
      var e = d(b),
          f = a.find(e, function (a, b) {
        return b < e.length - 1 && a === e[b + 1];
      });f.each(function (a) {
        throw new c("The field: " + a + " occurs more than once in the combined fields: [" + e.join(", ") + "].");
      });
    };return { sort: d, reqMessage: e, unsuppMessage: f, validateStrArr: g, invalidTypeMessage: h, checkDupes: i };
  }), g("49", ["2l", "z", "4d", "3f", "4e", "2z", "3p"], function (a, b, c, d, e, f, g) {
    return function (h, i) {
      var j = h.concat(i);if (0 === j.length) throw new f("You must specify at least one required or optional field.");return e.validateStrArr("required", h), e.validateStrArr("optional", i), e.checkDupes(j), function (f) {
        var k = c.keys(f),
            l = a.forall(h, function (b) {
          return a.contains(k, b);
        });l || e.reqMessage(h, k);var m = a.filter(k, function (b) {
          return !a.contains(j, b);
        });m.length > 0 && e.unsuppMessage(m);var n = {};return a.each(h, function (a) {
          n[a] = b.constant(f[a]);
        }), a.each(i, function (a) {
          n[a] = b.constant(g.prototype.hasOwnProperty.call(f, a) ? d.some(f[a]) : d.none());
        }), n;
      };
    };
  }), g("40", ["48", "49"], function (a, b) {
    return { immutable: a, immutableBag: b };
  }), g("41", [], function () {
    var a = function a(_a4, b) {
      var c = [],
          d = function d(a) {
        return c.push(a), b(a);
      },
          e = b(_a4);do {
        e = e.bind(d);
      } while (e.isSome());return c;
    };return { toArray: a };
  }), g("4a", [], function () {
    return "undefined" != typeof window ? window : Function("return this;")();
  }), g("42", ["4a"], function (a) {
    var b = function b(_b6, c) {
      for (var d = void 0 !== c ? c : a, e = 0; e < _b6.length && void 0 !== d && null !== d; ++e) {
        d = d[_b6[e]];
      }return d;
    },
        c = function c(a, _c4) {
      var d = a.split(".");return b(d, _c4);
    },
        d = function d(a, b) {
      return void 0 !== a[b] && null !== a[b] || (a[b] = {}), a[b];
    },
        e = function e(b, c) {
      for (var e = void 0 !== c ? c : a, f = 0; f < b.length; ++f) {
        e = d(e, b[f]);
      }return e;
    },
        f = function f(a, b) {
      var c = a.split(".");return e(c, b);
    };return { path: b, resolve: c, forge: e, namespace: f };
  }), g("3x", ["42"], function (a) {
    var b = function b(_b7, c) {
      return a.resolve(_b7, c);
    },
        c = function c(a, _c5) {
      var d = b(a, _c5);if (void 0 === d) throw a + " not available on this browser";return d;
    };return { getOrDie: c };
  }), g("3u", ["3x"], function (a) {
    var b = function b() {
      var b = a.getOrDie("Node");return b;
    },
        c = function c(a, b, _c6) {
      return 0 !== (a.compareDocumentPosition(b) & _c6);
    },
        d = function d(a, _d3) {
      return c(a, _d3, b().DOCUMENT_POSITION_PRECEDING);
    },
        e = function e(a, d) {
      return c(a, d, b().DOCUMENT_POSITION_CONTAINED_BY);
    };return { documentPositionPreceding: d, documentPositionContainedBy: e };
  }), h("4f", Number), g("4b", ["2l", "4f", "3g"], function (a, b, c) {
    var d = function d(a, b) {
      for (var c = 0; c < a.length; c++) {
        var d = a[c];if (d.test(b)) return d;
      }
    },
        e = function e(a, c) {
      var e = d(a, c);if (!e) return { major: 0, minor: 0 };var f = function f(a) {
        return b(c.replace(e, "$" + a));
      };return h(f(1), f(2));
    },
        f = function f(a, b) {
      var d = c(b).toLowerCase();return 0 === a.length ? g() : e(a, d);
    },
        g = function g() {
      return h(0, 0);
    },
        h = function h(a, b) {
      return { major: a, minor: b };
    };return { nu: h, detect: f, unknown: g };
  }), g("43", ["z", "4b"], function (a, b) {
    var c = "Edge",
        d = "Chrome",
        e = "IE",
        f = "Opera",
        g = "Firefox",
        h = "Safari",
        i = function i(a, b) {
      return function () {
        return b === a;
      };
    },
        j = function j() {
      return k({ current: void 0, version: b.unknown()
      });
    },
        k = function k(a) {
      var b = a.current,
          j = a.version;return { current: b, version: j, isEdge: i(c, b), isChrome: i(d, b), isIE: i(e, b), isOpera: i(f, b), isFirefox: i(g, b), isSafari: i(h, b) };
    };return { unknown: j, nu: k, edge: a.constant(c), chrome: a.constant(d), ie: a.constant(e), opera: a.constant(f), firefox: a.constant(g), safari: a.constant(h) };
  }), g("44", ["z", "4b"], function (a, b) {
    var c = "Windows",
        d = "iOS",
        e = "Android",
        f = "Linux",
        g = "OSX",
        h = "Solaris",
        i = "FreeBSD",
        j = function j(a, b) {
      return function () {
        return b === a;
      };
    },
        k = function k() {
      return l({ current: void 0, version: b.unknown() });
    },
        l = function l(a) {
      var b = a.current,
          k = a.version;return { current: b, version: k, isWindows: j(c, b), isiOS: j(d, b), isAndroid: j(e, b), isOSX: j(g, b), isLinux: j(f, b), isSolaris: j(h, b), isFreeBSD: j(i, b) };
    };return { unknown: k, nu: l, windows: a.constant(c), ios: a.constant(d), android: a.constant(e), linux: a.constant(f), osx: a.constant(g), solaris: a.constant(h), freebsd: a.constant(i) };
  }), g("45", ["z"], function (a) {
    return function (b, c, d) {
      var e = b.isiOS() && /ipad/i.test(d) === !0,
          f = b.isiOS() && !e,
          g = b.isAndroid() && 3 === b.version.major,
          h = b.isAndroid() && 4 === b.version.major,
          i = e || g || h && /mobile/i.test(d) === !0,
          j = b.isiOS() || b.isAndroid(),
          k = j && !i,
          l = c.isSafari() && b.isiOS() && /safari/i.test(d) === !1;return { isiPad: a.constant(e), isiPhone: a.constant(f), isTablet: a.constant(i), isPhone: a.constant(k), isTouch: a.constant(j), isAndroid: b.isAndroid, isiOS: b.isiOS, isWebView: a.constant(l) };
    };
  }), g("46", ["2l", "4b", "3g"], function (a, b, c) {
    var d = function d(b, _d4) {
      var e = c(_d4).toLowerCase();return a.find(b, function (a) {
        return a.search(e);
      });
    },
        e = function e(a, c) {
      return d(a, c).map(function (a) {
        var d = b.detect(a.versionRegexes, c);return { current: a.name, version: d };
      });
    },
        f = function f(a, c) {
      return d(a, c).map(function (a) {
        var d = b.detect(a.versionRegexes, c);return { current: a.name, version: d };
      });
    };return { detectBrowser: e, detectOs: f };
  }), g("4g", [], function () {
    var a = function a(_a5, b) {
      return b + _a5;
    },
        b = function b(a, _b8) {
      return a + _b8;
    },
        c = function c(a, b) {
      return a.substring(b);
    },
        d = function d(a, b) {
      return a.substring(0, a.length - b);
    };return { addToStart: a, addToEnd: b, removeFromStart: c, removeFromEnd: d };
  }), g("4h", ["3f", "2z"], function (a, b) {
    var c = function c(a, b) {
      return a.substr(0, b);
    },
        d = function d(a, b) {
      return a.substr(a.length - b, a.length);
    },
        e = function e(b) {
      return "" === b ? a.none() : a.some(b.substr(0, 1));
    },
        f = function f(b) {
      return "" === b ? a.none() : a.some(b.substring(1));
    };return { first: c, last: d, head: e, tail: f };
  }), g("4c", ["4g", "4h", "2z"], function (a, b, c) {
    var d = function d(a, b, c) {
      if ("" === b) return !0;if (a.length < b.length) return !1;var d = a.substr(c, c + b.length);return d === b;
    },
        e = function e(a, b) {
      var c = function c(a) {
        var b = typeof a === "undefined" ? "undefined" : _typeof(a);return "string" === b || "number" === b;
      };return a.replace(/\${([^{}]*)}/g, function (a, d) {
        var e = b[d];return c(e) ? e : a;
      });
    },
        f = function f(b, c) {
      return l(b, c) ? a.removeFromStart(b, c.length) : b;
    },
        g = function g(b, c) {
      return m(b, c) ? a.removeFromEnd(b, c.length) : b;
    },
        h = function h(b, c) {
      return l(b, c) ? b : a.addToStart(b, c);
    },
        i = function i(b, c) {
      return m(b, c) ? b : a.addToEnd(b, c);
    },
        j = function j(a, b) {
      return a.indexOf(b) !== -1;
    },
        k = function k(a) {
      return b.head(a).bind(function (c) {
        return b.tail(a).map(function (a) {
          return c.toUpperCase() + a;
        });
      }).getOr(a);
    },
        l = function l(a, b) {
      return d(a, b, 0);
    },
        m = function m(a, b) {
      return d(a, b, a.length - b.length);
    },
        n = function n(a) {
      return a.replace(/^\s+|\s+$/g, "");
    },
        o = function o(a) {
      return a.replace(/^\s+/g, "");
    },
        p = function p(a) {
      return a.replace(/\s+$/g, "");
    };return { supplant: e, startsWith: l, removeLeading: f, removeTrailing: g, ensureLeading: h, ensureTrailing: i, endsWith: m, contains: j, trim: n, lTrim: o, rTrim: p, capitalize: k };
  }), g("47", ["z", "4c"], function (a, b) {
    var c = /.*?version\/\ ?([0-9]+)\.([0-9]+).*/,
        d = function d(a) {
      return function (c) {
        return b.contains(c, a);
      };
    },
        e = [{ name: "Edge", versionRegexes: [/.*?edge\/ ?([0-9]+)\.([0-9]+)$/], search: function search(a) {
        var c = b.contains(a, "edge/") && b.contains(a, "chrome") && b.contains(a, "safari") && b.contains(a, "applewebkit");return c;
      } }, { name: "Chrome", versionRegexes: [/.*?chrome\/([0-9]+)\.([0-9]+).*/, c], search: function search(a) {
        return b.contains(a, "chrome") && !b.contains(a, "chromeframe");
      } }, { name: "IE", versionRegexes: [/.*?msie\ ?([0-9]+)\.([0-9]+).*/, /.*?rv:([0-9]+)\.([0-9]+).*/], search: function search(a) {
        return b.contains(a, "msie") || b.contains(a, "trident");
      } }, { name: "Opera", versionRegexes: [c, /.*?opera\/([0-9]+)\.([0-9]+).*/], search: d("opera") }, { name: "Firefox", versionRegexes: [/.*?firefox\/\ ?([0-9]+)\.([0-9]+).*/], search: d("firefox") }, { name: "Safari", versionRegexes: [c, /.*?cpu os ([0-9]+)_([0-9]+).*/], search: function search(a) {
        return (b.contains(a, "safari") || b.contains(a, "mobile/")) && b.contains(a, "applewebkit");
      } }],
        f = [{ name: "Windows", search: d("win"), versionRegexes: [/.*?windows\ nt\ ?([0-9]+)\.([0-9]+).*/] }, { name: "iOS", search: function search(a) {
        return b.contains(a, "iphone") || b.contains(a, "ipad");
      }, versionRegexes: [/.*?version\/\ ?([0-9]+)\.([0-9]+).*/, /.*cpu os ([0-9]+)_([0-9]+).*/, /.*cpu iphone os ([0-9]+)_([0-9]+).*/] }, { name: "Android", search: d("android"), versionRegexes: [/.*?android\ ?([0-9]+)\.([0-9]+).*/] }, { name: "OSX", search: d("os x"), versionRegexes: [/.*?os\ x\ ?([0-9]+)_([0-9]+).*/] }, { name: "Linux", search: d("linux"), versionRegexes: [] }, { name: "Solaris", search: d("sunos"), versionRegexes: [] }, { name: "FreeBSD", search: d("freebsd"), versionRegexes: [] }];return { browsers: a.constant(e), oses: a.constant(f) };
  }), g("3y", ["43", "44", "45", "46", "47"], function (a, b, c, d, e) {
    var f = function f(_f3) {
      var g = e.browsers(),
          h = e.oses(),
          i = d.detectBrowser(g, _f3).fold(a.unknown, a.nu),
          j = d.detectOs(h, _f3).fold(b.unknown, b.nu),
          k = c(j, i, _f3);return { browser: i, os: j, deviceType: k };
    };return { detect: f };
  }), h("3z", navigator), g("3v", ["3t", "3y", "3z"], function (a, b, c) {
    var d = a.cached(function () {
      var a = c.userAgent;return b.detect(a);
    });return { detect: d };
  }), g("33", ["2l", "3f", "10", "3m", "2z", "12"], function (a, b, c, d, e, f) {
    var g = 0,
        h = 1,
        i = 2,
        j = 3,
        k = function () {
      var a = f.createElement("span");return void 0 !== a.matches ? g : void 0 !== a.msMatchesSelector ? h : void 0 !== a.webkitMatchesSelector ? i : void 0 !== a.mozMatchesSelector ? j : -1;
    }(),
        l = d.ELEMENT,
        m = d.DOCUMENT,
        n = function n(a, b) {
      var c = a.dom();if (c.nodeType !== l) return !1;if (k === g) return c.matches(b);if (k === h) return c.msMatchesSelector(b);if (k === i) return c.webkitMatchesSelector(b);if (k === j) return c.mozMatchesSelector(b);throw new e("Browser lacks native selectors");
    },
        o = function o(a) {
      return a.nodeType !== l && a.nodeType !== m || 0 === a.childElementCount;
    },
        p = function p(b, d) {
      var e = void 0 === d ? f : d.dom();return o(e) ? [] : a.map(e.querySelectorAll(b), c.fromDom);
    },
        q = function q(a, d) {
      var e = void 0 === d ? f : d.dom();return o(e) ? b.none() : b.from(e.querySelector(a)).map(c.fromDom);
    };return { all: p, is: n, one: q };
  }), g("3l", ["2l", "z", "3u", "3v", "33"], function (a, b, c, d, e) {
    var f = function f(a, b) {
      return a.dom() === b.dom();
    },
        g = function g(a, b) {
      return a.dom().isEqualNode(b.dom());
    },
        h = function h(c, d) {
      return a.exists(d, b.curry(f, c));
    },
        i = function i(a, b) {
      var c = a.dom(),
          d = b.dom();return c !== d && c.contains(d);
    },
        j = function j(a, b) {
      return c.documentPositionContainedBy(a.dom(), b.dom());
    },
        k = d.detect().browser,
        l = k.isIE() ? j : i;return { eq: f, isEqualNode: g, member: h, contains: l, is: e.is };
  }), g("3w", ["3j", "2l", "z", "3f", "40", "41", "3l", "10"], function (a, b, c, d, e, f, g, h) {
    var i = function i(a) {
      return h.fromDom(a.dom().ownerDocument);
    },
        j = function j(a) {
      var b = i(a);return h.fromDom(b.dom().documentElement);
    },
        k = function k(a) {
      var b = a.dom(),
          c = b.ownerDocument.defaultView;return h.fromDom(c);
    },
        l = function l(a) {
      var b = a.dom();return d.from(b.parentNode).map(h.fromDom);
    },
        m = function m(a) {
      return l(a).bind(function (c) {
        var d = u(c);return b.findIndex(d, function (b) {
          return g.eq(a, b);
        });
      });
    },
        n = function n(b, d) {
      for (var e = a.isFunction(d) ? d : c.constant(!1), f = b.dom(), g = []; null !== f.parentNode && void 0 !== f.parentNode;) {
        var i = f.parentNode,
            j = h.fromDom(i);if (g.push(j), e(j) === !0) break;f = i;
      }return g;
    },
        o = function o(a) {
      var c = function c(_c7) {
        return b.filter(_c7, function (b) {
          return !g.eq(a, b);
        });
      };return l(a).map(u).map(c).getOr([]);
    },
        p = function p(a) {
      var b = a.dom();return d.from(b.offsetParent).map(h.fromDom);
    },
        q = function q(a) {
      var b = a.dom();return d.from(b.previousSibling).map(h.fromDom);
    },
        r = function r(a) {
      var b = a.dom();return d.from(b.nextSibling).map(h.fromDom);
    },
        s = function s(a) {
      return b.reverse(f.toArray(a, q));
    },
        t = function t(a) {
      return f.toArray(a, r);
    },
        u = function u(a) {
      var c = a.dom();return b.map(c.childNodes, h.fromDom);
    },
        v = function v(a, b) {
      var c = a.dom().childNodes;return d.from(c[b]).map(h.fromDom);
    },
        w = function w(a) {
      return v(a, 0);
    },
        x = function x(a) {
      return v(a, a.dom().childNodes.length - 1);
    },
        y = function y(a, b) {
      return a.dom().childNodes.length;
    },
        z = e.immutable("element", "offset"),
        A = function A(a, b) {
      var c = u(a);return c.length > 0 && b < c.length ? z(c[b], 0) : z(a, b);
    };return { owner: i, defaultView: k, documentElement: j, parent: l, findIndex: m, parents: n, siblings: o, prevSibling: q, offsetParent: p, prevSiblings: s, nextSibling: r, nextSiblings: t, children: u, child: v, firstChild: w, lastChild: x, childNodesCount: y, leaf: A };
  }), g("3s", ["2l", "3k", "3w"], function (a, b, c) {
    var d = function d(a) {
      return h(b.body(), a);
    },
        e = function e(b, d, _e4) {
      return a.filter(c.parents(b, _e4), d);
    },
        f = function f(b, d) {
      return a.filter(c.siblings(b), d);
    },
        g = function g(b, d) {
      return a.filter(c.children(b), d);
    },
        h = function h(b, d) {
      var e = [];return a.each(c.children(b), function (a) {
        d(a) && (e = e.concat([a])), e = e.concat(h(a, d));
      }), e;
    };return { all: d, ancestors: e, siblings: f, children: g, descendants: h };
  }), g("3i", ["3s", "33"], function (a, b) {
    var c = function c(a) {
      return b.all(a);
    },
        d = function d(c, _d5, e) {
      return a.ancestors(c, function (a) {
        return b.is(a, _d5);
      }, e);
    },
        e = function e(c, d) {
      return a.siblings(c, function (a) {
        return b.is(a, d);
      });
    },
        f = function f(c, d) {
      return a.children(c, function (a) {
        return b.is(a, d);
      });
    },
        g = function g(a, c) {
      return b.all(c, a);
    };return { all: c, ancestors: d, siblings: e, children: f, descendants: g };
  }), g("30", ["2l", "z", "3h", "10", "3i", "2j", "c"], function (a, b, c, d, e, f, g) {
    var h = g.trim,
        i = function i(a) {
      return function (b) {
        if (b && 1 === b.nodeType) {
          if (b.contentEditable === a) return !0;if (b.getAttribute("data-mce-contenteditable") === a) return !0;
        }return !1;
      };
    },
        j = i("true"),
        k = i("false"),
        l = function l(a, b, c, d, e) {
      return { type: a, title: b, url: c, level: d, attach: e };
    },
        m = function m(a) {
      for (; a = a.parentNode;) {
        var b = a.contentEditable;if (b && "inherit" !== b) return j(a);
      }return !1;
    },
        n = function n(b, c) {
      return a.map(e.descendants(d.fromDom(c), b), function (a) {
        return a.dom();
      });
    },
        o = function o(a) {
      return a.innerText || a.textContent;
    },
        p = function p(a) {
      return a.id ? a.id : c.generate("h");
    },
        q = function q(a) {
      return a && "A" === a.nodeName && (a.id || a.name);
    },
        r = function r(a) {
      return q(a) && t(a);
    },
        s = function s(a) {
      return a && /^(H[1-6])$/.test(a.nodeName);
    },
        t = function t(a) {
      return m(a) && !k(a);
    },
        u = function u(a) {
      return s(a) && t(a);
    },
        v = function v(a) {
      return s(a) ? parseInt(a.nodeName.substr(1), 10) : 0;
    },
        w = function w(a) {
      var b = p(a),
          c = function c() {
        a.id = b;
      };return l("header", o(a), "#" + b, v(a), c);
    },
        x = function x(a) {
      var c = a.id || a.name,
          d = o(a);return l("anchor", d ? d : "#" + c, "#" + c, 0, b.noop);
    },
        y = function y(b) {
      return a.map(a.filter(b, u), w);
    },
        z = function z(b) {
      return a.map(a.filter(b, r), x);
    },
        A = function A(a) {
      var b = n("h1,h2,h3,h4,h5,h6,a:not([href])", a);return b;
    },
        B = function B(a) {
      return h(a.title).length > 0;
    },
        C = function C(b) {
      var c = A(b);return a.filter(y(c).concat(z(c)), B);
    };return { find: C };
  }), g("t", ["2l", "z", "1", "30", "13", "m", "c"], function (a, b, c, d, e, f, g) {
    "use strict";
    var h = function h() {
      return c.tinymce ? c.tinymce.activeEditor : e.activeEditor;
    },
        i = {},
        j = 5,
        k = function k() {
      i = {};
    },
        l = function l(a) {
      return { title: a.title, value: { title: { raw: a.title }, url: a.url, attach: a.attach } };
    },
        m = function m(a) {
      return g.map(a, l);
    },
        n = function n(a, c) {
      return { title: a, value: { title: a, url: c, attach: b.noop } };
    },
        o = function o(b, c) {
      var d = a.exists(c, function (a) {
        return a.url === b;
      });return !d;
    },
        p = function p(a, b, c) {
      var d = b in a ? a[b] : c;return d === !1 ? null : d;
    },
        q = function q(c, d, e, f) {
      var h = { title: "-" },
          j = function j(c) {
        var f = c.hasOwnProperty(e) ? c[e] : [],
            h = a.filter(f, function (a) {
          return o(a, d);
        });return g.map(h, function (a) {
          return { title: a, value: { title: a, url: a, attach: b.noop } };
        });
      },
          k = function k(b) {
        var c = a.filter(d, function (a) {
          return a.type === b;
        });return m(c);
      },
          l = function l() {
        var a = k("anchor"),
            b = p(f, "anchor_top", "#top"),
            c = p(f, "anchor_bottom", "#bottom");return null !== b && a.unshift(n("<top>", b)), null !== c && a.push(n("<bottom>", c)), a;
      },
          q = function q(b) {
        return a.foldl(b, function (a, b) {
          var c = 0 === a.length || 0 === b.length;return c ? a.concat(b) : a.concat(h, b);
        }, []);
      };return f.typeahead_urls === !1 ? [] : "file" === e ? q([s(c, j(i)), s(c, k("header")), s(c, l())]) : s(c, j(i));
    },
        r = function r(b, c) {
      var d = i[c];/^https?/.test(b) && (d ? a.indexOf(d, b) === -1 && (i[c] = d.slice(0, j).concat(b)) : i[c] = [b]);
    },
        s = function s(a, b) {
      var c = a.toLowerCase(),
          d = g.grep(b, function (a) {
        return a.title.toLowerCase().indexOf(c) !== -1;
      });return 1 === d.length && d[0].title === a ? [] : d;
    },
        t = function t(a) {
      var b = a.title;return b.raw ? b.raw : b;
    },
        u = function u(a, b, c, e) {
      var f = function f(_f4) {
        var g = d.find(c),
            h = q(_f4, g, e, b);a.showAutoComplete(h, _f4);
      };a.on("autocomplete", function () {
        f(a.value());
      }), a.on("selectitem", function (b) {
        var c = b.value;a.value(c.url);var d = t(c);"image" === e ? a.fire("change", { meta: { alt: d, attach: c.attach } }) : a.fire("change", { meta: { text: d, attach: c.attach } }), a.focus();
      }), a.on("click", function (b) {
        0 === a.value().length && "INPUT" === b.target.nodeName && f("");
      }), a.on("PostRender", function () {
        a.getRoot().on("submit", function (b) {
          b.isDefaultPrevented() || r(a.value(), e);
        });
      });
    },
        v = function v(a) {
      var b = a.status,
          c = a.message;return "valid" === b ? { status: "ok", message: c } : "unknown" === b ? { status: "warn", message: c } : "invalid" === b ? { status: "warn", message: c } : { status: "none", message: "" };
    },
        w = function w(a, b, c) {
      var d = b.filepicker_validator_handler;if (d) {
        var e = function e(b) {
          return 0 === b.length ? void a.statusLevel("none") : void d({ url: b, type: c }, function (b) {
            var c = v(b);a.statusMessage(c.message), a.statusLevel(c.status);
          });
        };a.state.on("change:value", function (a) {
          e(a.value);
        });
      }
    };return f.extend({ Statics: { clearHistory: k }, init: function init(a) {
        var b,
            d,
            e,
            f = this,
            i = h(),
            j = i.settings,
            k = a.filetype;a.spellcheck = !1, e = j.file_picker_types || j.file_browser_callback_types, e && (e = g.makeMap(e, /[, ]/)), e && !e[k] || (d = j.file_picker_callback, !d || e && !e[k] ? (d = j.file_browser_callback, !d || e && !e[k] || (b = function b() {
          d(f.getEl("inp").id, f.value(), k, c);
        })) : b = function b() {
          var a = f.fire("beforecall").meta;a = g.extend({ filetype: k }, a), d.call(i, function (a, b) {
            f.value(a).fire("change", { meta: b });
          }, f.value(), a);
        }), b && (a.icon = "browse", a.onaction = b), f._super(a), u(f, j, i.getBody(), k), w(f, j, k);
      } });
  }), g("u", ["d"], function (a) {
    "use strict";
    return a.extend({ recalc: function recalc(a) {
        var b = a.layoutRect(),
            c = a.paddingBox;a.items().filter(":visible").each(function (a) {
          a.layoutRect({ x: c.left, y: c.top, w: b.innerW - c.right - c.left, h: b.innerH - c.top - c.bottom }), a.recalc && a.recalc();
        });
      } });
  }), g("v", ["d"], function (a) {
    "use strict";
    return a.extend({ recalc: function recalc(a) {
        var b,
            c,
            d,
            e,
            f,
            g,
            h,
            i,
            j,
            k,
            l,
            m,
            n,
            o,
            p,
            q,
            r,
            s,
            t,
            u,
            v,
            w,
            x,
            y,
            z,
            A,
            B,
            C,
            D,
            E,
            F,
            G,
            H,
            I,
            J,
            K,
            L,
            M,
            N = [],
            O = Math.max,
            P = Math.min;for (d = a.items().filter(":visible"), e = a.layoutRect(), f = a.paddingBox, g = a.settings, m = a.isRtl() ? g.direction || "row-reversed" : g.direction, h = g.align, i = a.isRtl() ? g.pack || "end" : g.pack, j = g.spacing || 0, "row-reversed" != m && "column-reverse" != m || (d = d.set(d.toArray().reverse()), m = m.split("-")[0]), "column" == m ? (z = "y", x = "h", y = "minH", A = "maxH", C = "innerH", B = "top", D = "deltaH", E = "contentH", J = "left", H = "w", F = "x", G = "innerW", I = "minW", K = "right", L = "deltaW", M = "contentW") : (z = "x", x = "w", y = "minW", A = "maxW", C = "innerW", B = "left", D = "deltaW", E = "contentW", J = "top", H = "h", F = "y", G = "innerH", I = "minH", K = "bottom", L = "deltaH", M = "contentH"), l = e[C] - f[B] - f[B], w = k = 0, b = 0, c = d.length; b < c; b++) {
          n = d[b], o = n.layoutRect(), p = n.settings, q = p.flex, l -= b < c - 1 ? j : 0, q > 0 && (k += q, o[A] && N.push(n), o.flex = q), l -= o[y], r = f[J] + o[I] + f[K], r > w && (w = r);
        }if (u = {}, l < 0 ? u[y] = e[y] - l + e[D] : u[y] = e[C] - l + e[D], u[I] = w + e[L], u[E] = e[C] - l, u[M] = w, u.minW = P(u.minW, e.maxW), u.minH = P(u.minH, e.maxH), u.minW = O(u.minW, e.startMinWidth), u.minH = O(u.minH, e.startMinHeight), !e.autoResize || u.minW == e.minW && u.minH == e.minH) {
          for (t = l / k, b = 0, c = N.length; b < c; b++) {
            n = N[b], o = n.layoutRect(), s = o[A], r = o[y] + o.flex * t, r > s ? (l -= o[A] - o[y], k -= o.flex, o.flex = 0, o.maxFlexSize = s) : o.maxFlexSize = 0;
          }for (t = l / k, v = f[B], u = {}, 0 === k && ("end" == i ? v = l + f[B] : "center" == i ? (v = Math.round(e[C] / 2 - (e[C] - l) / 2) + f[B], v < 0 && (v = f[B])) : "justify" == i && (v = f[B], j = Math.floor(l / (d.length - 1)))), u[F] = f[J], b = 0, c = d.length; b < c; b++) {
            n = d[b], o = n.layoutRect(), r = o.maxFlexSize || o[y], "center" === h ? u[F] = Math.round(e[G] / 2 - o[H] / 2) : "stretch" === h ? (u[H] = O(o[I] || 0, e[G] - f[J] - f[K]), u[F] = f[J]) : "end" === h && (u[F] = e[G] - o[H] - f.top), o.flex > 0 && (r += o.flex * t), u[x] = r, u[z] = v, n.layoutRect(u), n.recalc && n.recalc(), v += r + j;
          }
        } else if (u.w = u.minW, u.h = u.minH, a.layoutRect(u), this.recalc(a), null === a._lastRect) {
          var Q = a.parent();Q && (Q._lastRect = null, Q.recalc());
        }
      } });
  }), g("x", ["1l"], function (a) {
    return a.extend({ Defaults: { containerClass: "flow-layout", controlClass: "flow-layout-item", endClass: "break" }, recalc: function recalc(a) {
        a.items().filter(":visible").each(function (a) {
          a.recalc && a.recalc();
        });
      }, isNative: function isNative() {
        return !0;
      } });
  }), g("34", ["3j", "3f"], function (a, b) {
    return function (c, d, e, f, g) {
      return c(e, f) ? b.some(e) : a.isFunction(g) && g(e) ? b.none() : d(e, f, g);
    };
  }), g("32", ["3j", "2l", "z", "3f", "3k", "3l", "10", "34"], function (a, b, c, d, e, f, g, h) {
    var i = function i(a) {
      return n(e.body(), a);
    },
        j = function j(b, e, f) {
      for (var h = b.dom(), i = a.isFunction(f) ? f : c.constant(!1); h.parentNode;) {
        h = h.parentNode;var j = g.fromDom(h);if (e(j)) return d.some(j);if (i(j)) break;
      }return d.none();
    },
        k = function k(a, b, c) {
      var d = function d(a) {
        return b(a);
      };return h(d, j, a, b, c);
    },
        l = function l(a, b) {
      var c = a.dom();return c.parentNode ? m(g.fromDom(c.parentNode), function (c) {
        return !f.eq(a, c) && b(c);
      }) : d.none();
    },
        m = function m(a, d) {
      var e = b.find(a.dom().childNodes, c.compose(d, g.fromDom));return e.map(g.fromDom);
    },
        n = function n(a, b) {
      var c = function c(a) {
        for (var e = 0; e < a.childNodes.length; e++) {
          if (b(g.fromDom(a.childNodes[e]))) return d.some(g.fromDom(a.childNodes[e]));var f = c(a.childNodes[e]);if (f.isSome()) return f;
        }return d.none();
      };return c(a.dom());
    };return { first: i, ancestor: j, closest: k, sibling: l, child: m, descendant: n };
  }), g("11", ["32", "33", "34"], function (a, b, c) {
    var d = function d(a) {
      return b.one(a);
    },
        e = function e(c, d, _e5) {
      return a.ancestor(c, function (a) {
        return b.is(a, d);
      }, _e5);
    },
        f = function f(c, d) {
      return a.sibling(c, function (a) {
        return b.is(a, d);
      });
    },
        g = function g(c, d) {
      return a.child(c, function (a) {
        return b.is(a, d);
      });
    },
        h = function h(a, c) {
      return b.one(c, a);
    },
        i = function i(a, d, f) {
      return c(b.is, e, a, d, f);
    };return { first: d, ancestor: e, sibling: f, child: g, descendant: h, closest: i };
  }), g("35", [], function () {
    var a = function a(_a6, b) {
      return function () {
        _a6.execCommand("mceToggleFormat", !1, b);
      };
    },
        b = function b(a, _b9) {
      return function () {
        var c = this;a.formatter ? a.formatter.formatChanged(_b9, function (a) {
          c.active(a);
        }) : a.on("init", function () {
          a.formatter.formatChanged(_b9, function (a) {
            c.active(a);
          });
        });
      };
    };return { toggleFormat: a, postRenderFormat: b };
  }), g("16", ["c", "35"], function (a, b) {
    var c = function c(_c8) {
      _c8.addMenuItem("align", { text: "Align", menu: [{ text: "Left", icon: "alignleft", onclick: b.toggleFormat(_c8, "alignleft") }, { text: "Center", icon: "aligncenter", onclick: b.toggleFormat(_c8, "aligncenter") }, { text: "Right", icon: "alignright", onclick: b.toggleFormat(_c8, "alignright") }, { text: "Justify", icon: "alignjustify", onclick: b.toggleFormat(_c8, "alignjustify") }] }), a.each({ alignleft: ["Align left", "JustifyLeft"], aligncenter: ["Align center", "JustifyCenter"], alignright: ["Align right", "JustifyRight"], alignjustify: ["Justify", "JustifyFull"], alignnone: ["No alignment", "JustifyNone"] }, function (a, d) {
        _c8.addButton(d, { tooltip: a[0], cmd: a[1], onPostRender: b.postRenderFormat(_c8, d) });
      });
    };return { register: c };
  }), g("36", ["z", "3f", "10", "3n", "2j"], function (a, b, c, d, e) {
    var f = function f(a, c, d) {
      for (; d !== c;) {
        if (d.style[a]) {
          var e = d.style[a];return "" !== e ? b.some(e) : b.none();
        }d = d.parentNode;
      }return b.none();
    },
        g = function g(a) {
      return (/[0-9.]+px$/.test(a) ? Math.round(72 * parseInt(a, 10) / 96) + "pt" : a
      );
    },
        h = function h(a) {
      return a.replace(/[\'\"]/g, "").replace(/,\s+/g, ",");
    },
        i = function i(a, c) {
      return b.from(e.DOM.getStyle(c, a, !0));
    },
        j = function j(a) {
      return function (e, g) {
        return b.from(g).map(c.fromDom).filter(d.isElement).bind(function (b) {
          return f(a, e, b.dom()).or(i(a, b.dom()));
        }).getOr("");
      };
    };return { getFontSize: j("fontSize"), getFontFamily: a.compose(h, j("fontFamily")), toPt: g };
  }), g("17", ["c", "36"], function (a, b) {
    var c = function c(a) {
      return a ? a.split(",")[0] : "";
    },
        d = function d(b, _d6) {
      var e;return a.each(b, function (a) {
        a.value.toLowerCase() === _d6.toLowerCase() && (e = a.value);
      }), a.each(b, function (a) {
        e || c(a.value).toLowerCase() !== c(_d6).toLowerCase() || (e = a.value);
      }), e;
    },
        e = function e(a, _e6) {
      return function () {
        var f = this;a.on("init nodeChange", function (g) {
          var h = b.getFontFamily(a.getBody(), g.element),
              i = d(_e6, h);f.value(i ? i : null), !i && h && f.text(c(h));
        });
      };
    },
        f = function f(a) {
      a = a.replace(/;$/, "").split(";");for (var b = a.length; b--;) {
        a[b] = a[b].split("=");
      }return a;
    },
        g = function g(b) {
      var c = "Andale Mono=andale mono,monospace;Arial=arial,helvetica,sans-serif;Arial Black=arial black,sans-serif;Book Antiqua=book antiqua,palatino,serif;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,palatino,serif;Helvetica=helvetica,arial,sans-serif;Impact=impact,sans-serif;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco,monospace;Times New Roman=times new roman,times,serif;Trebuchet MS=trebuchet ms,geneva,sans-serif;Verdana=verdana,geneva,sans-serif;Webdings=webdings;Wingdings=wingdings,zapf dingbats",
          d = f(b.settings.font_formats || c);return a.map(d, function (a) {
        return { text: { raw: a[0] }, value: a[1], textStyle: a[1].indexOf("dings") === -1 ? "font-family:" + a[1] : "" };
      });
    },
        h = function h(a) {
      a.addButton("fontselect", function () {
        var b = g(a);return { type: "listbox", text: "Font Family", tooltip: "Font Family", values: b, fixedWidth: !0, onPostRender: e(a, b), onselect: function onselect(b) {
            b.control.settings.value && a.execCommand("FontName", !1, b.control.settings.value);
          } };
      });
    },
        i = function i(a) {
      h(a);
    };return { register: i };
  }), g("18", ["c", "36"], function (a, b) {
    var c = function c(b, _c9, d) {
      var e;return a.each(b, function (a) {
        a.value === d ? e = d : a.value === _c9 && (e = _c9);
      }), e;
    },
        d = function d(a, _d7) {
      return function () {
        var e = this;a.on("init nodeChange", function (f) {
          var g, h;g = b.getFontSize(a.getBody(), f.element), h = b.toPt(g);var i = c(_d7, h, g);e.value(i ? i : null), i || e.text(h);
        });
      };
    },
        e = function e(b) {
      var c = "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
          d = b.settings.fontsize_formats || c;return a.map(d.split(" "), function (a) {
        var b = a,
            c = a,
            d = a.split("=");return d.length > 1 && (b = d[0], c = d[1]), { text: b, value: c };
      });
    },
        f = function f(a) {
      a.addButton("fontsizeselect", function () {
        var b = e(a);return { type: "listbox", text: "Font Sizes", tooltip: "Font Sizes", values: b, fixedWidth: !0, onPostRender: d(a, b), onclick: function onclick(b) {
            b.control.settings.value && a.execCommand("FontSize", !1, b.control.settings.value);
          } };
      });
    },
        g = function g(a) {
      f(a);
    };return { register: g };
  }), g("19", ["c", "35"], function (a, b) {
    var c = "Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;Preformatted=pre",
        d = function d(a) {
      a = a.replace(/;$/, "").split(";");for (var b = a.length; b--;) {
        a[b] = a[b].split("=");
      }return a;
    },
        e = function e(b, c, d) {
      return function () {
        var e = this;b.on("nodeChange", function (f) {
          var g = b.formatter,
              h = null;a.each(f.parents, function (b) {
            if (a.each(c, function (a) {
              if (d ? g.matchNode(b, d, { value: a.value }) && (h = a.value) : g.matchNode(b, a.value) && (h = a.value), h) return !1;
            }), h) return !1;
          }), e.value(h);
        });
      };
    },
        f = function f(c, d) {
      return function () {
        var f = [];return a.each(d, function (a) {
          f.push({ text: a[0], value: a[1], textStyle: function textStyle() {
              return c.formatter.getCssText(a[1]);
            } });
        }), { type: "listbox", text: d[0][0], values: f, fixedWidth: !0, onselect: function onselect(a) {
            if (a.control) {
              var d = a.control.value();b.toggleFormat(c, d)();
            }
          }, onPostRender: e(c, f) };
      };
    },
        g = function g(c, d) {
      return a.map(d, function (a) {
        return { text: a[0], onclick: b.toggleFormat(c, a[1]), textStyle: function textStyle() {
            return c.formatter.getCssText(a[1]);
          } };
      });
    },
        h = function h(a) {
      var b = d(a.settings.block_formats || c);a.addMenuItem("blockformats", { text: "Blocks", menu: g(a, b) }), a.addButton("formatselect", f(a, b));
    };return { register: h };
  }), g("1a", ["c", "35"], function (a, b) {
    var c = function c(b, d) {
      var e = d.length;return a.each(d, function (a) {
        a.menu && (a.hidden = 0 === c(b, a.menu));var d = a.format;d && (a.hidden = !b.formatter.canApply(d)), a.hidden && e--;
      }), e;
    },
        d = function d(a, b) {
      var e = b.items().length;return b.items().each(function (b) {
        b.menu && b.visible(d(a, b.menu) > 0), !b.menu && b.settings.menu && b.visible(c(a, b.settings.menu) > 0);var f = b.settings.format;f && b.visible(a.formatter.canApply(f)), b.visible() || e--;
      }), e;
    },
        e = function e(c) {
      var d = 0,
          e = [],
          f = [{ title: "Headings", items: [{ title: "Heading 1", format: "h1" }, { title: "Heading 2", format: "h2" }, { title: "Heading 3", format: "h3" }, { title: "Heading 4", format: "h4" }, { title: "Heading 5", format: "h5" }, { title: "Heading 6", format: "h6" }] }, { title: "Inline", items: [{ title: "Bold", icon: "bold", format: "bold" }, { title: "Italic", icon: "italic", format: "italic" }, { title: "Underline", icon: "underline", format: "underline" }, { title: "Strikethrough", icon: "strikethrough", format: "strikethrough" }, { title: "Superscript", icon: "superscript", format: "superscript" }, { title: "Subscript", icon: "subscript", format: "subscript" }, { title: "Code", icon: "code", format: "code" }] }, { title: "Blocks", items: [{ title: "Paragraph", format: "p" }, { title: "Blockquote", format: "blockquote" }, { title: "Div", format: "div" }, { title: "Pre", format: "pre" }] }, { title: "Alignment", items: [{ title: "Left", icon: "alignleft", format: "alignleft" }, { title: "Center", icon: "aligncenter", format: "aligncenter" }, { title: "Right", icon: "alignright", format: "alignright" }, { title: "Justify", icon: "alignjustify", format: "alignjustify" }] }],
          g = function g(b) {
        var c = [];if (b) return a.each(b, function (a) {
          var b = { text: a.title, icon: a.icon };if (a.items) b.menu = g(a.items);else {
            var f = a.format || "custom" + d++;a.format || (a.name = f, e.push(a)), b.format = f, b.cmd = a.cmd;
          }c.push(b);
        }), c;
      },
          h = function h() {
        var a;return a = g(c.settings.style_formats_merge ? c.settings.style_formats ? f.concat(c.settings.style_formats) : f : c.settings.style_formats || f);
      };return c.on("init", function () {
        a.each(e, function (a) {
          c.formatter.register(a.name, a);
        });
      }), { type: "menu", items: h(), onPostRender: function onPostRender(a) {
          c.fire("renderFormatsMenu", { control: a.control });
        }, itemDefaults: { preview: !0, textStyle: function textStyle() {
            if (this.settings.format) return c.formatter.getCssText(this.settings.format);
          }, onPostRender: function onPostRender() {
            var a = this;a.parent().on("show", function () {
              var b, d;b = a.settings.format, b && (a.disabled(!c.formatter.canApply(b)), a.active(c.formatter.match(b))), d = a.settings.cmd, d && a.active(c.queryCommandState(d));
            });
          }, onclick: function onclick() {
            this.settings.format && b.toggleFormat(c, this.settings.format)(), this.settings.cmd && c.execCommand(this.settings.cmd);
          } } };
    },
        f = function f(a, b) {
      a.addMenuItem("formats", { text: "Formats", menu: b });
    },
        g = function g(a, b) {
      a.addButton("styleselect", { type: "menubutton", text: "Formats", menu: b, onShowMenu: function onShowMenu() {
          a.settings.style_formats_autohide && d(a, this.menu);
        } });
    },
        h = function h(a) {
      var b = e(a);f(a, b), g(a, b);
    };return { register: h };
  }), g("1b", ["2l", "c"], function (a, b) {
    var c = function c(d, e) {
      var f, g;if ("string" == typeof e) g = e.split(" ");else if (b.isArray(e)) return a.flatten(b.map(e, function (a) {
        return c(d, a);
      }));return f = b.grep(g, function (a) {
        return "|" === a || a in d.menuItems;
      }), b.map(f, function (a) {
        return "|" === a ? { text: "-" } : d.menuItems[a];
      });
    },
        d = function d(a) {
      return a && "-" === a.text;
    },
        e = function e(b) {
      var c = a.filter(b, function (a, b, c) {
        return !d(a) || !d(c[b - 1]);
      });return a.filter(c, function (a, b, c) {
        return !d(a) || b > 0 && b < c.length - 1;
      });
    },
        f = function f(a, c) {
      var d = [{ text: "-" }],
          e = b.grep(a.menuItems, function (a) {
        return a.context === c;
      });return b.each(e, function (a) {
        "before" === a.separator && d.push({ text: "|" }), a.prependToContext ? d.unshift(a) : d.push(a), "after" === a.separator && d.push({ text: "|" });
      }), d;
    },
        g = function g(a) {
      var b = a.settings.insert_button_items;return e(b ? c(a, b) : f(a, "insert"));
    },
        h = function h(a) {
      a.addButton("insert", { type: "menubutton", icon: "insert", menu: [], oncreatemenu: function oncreatemenu() {
          this.menu.add(g(a)), this.menu.renderNew();
        } });
    },
        i = function i(a) {
      h(a);
    };return { register: i };
  }), g("1c", ["c", "35"], function (a, b) {
    var c = function c(_c10) {
      a.each({ bold: "Bold", italic: "Italic", underline: "Underline", strikethrough: "Strikethrough", subscript: "Subscript", superscript: "Superscript" }, function (a, d) {
        _c10.addButton(d, { tooltip: a, onPostRender: b.postRenderFormat(_c10, d), onclick: b.toggleFormat(_c10, d) });
      });
    },
        d = function d(b) {
      a.each({ outdent: ["Decrease indent", "Outdent"], indent: ["Increase indent", "Indent"], cut: ["Cut", "Cut"], copy: ["Copy", "Copy"], paste: ["Paste", "Paste"], help: ["Help", "mceHelp"], selectall: ["Select all", "SelectAll"], visualaid: ["Visual aids", "mceToggleVisualAid"], newdocument: ["New document", "mceNewDocument"], removeformat: ["Clear formatting", "RemoveFormat"], remove: ["Remove", "Delete"] }, function (a, c) {
        b.addButton(c, { tooltip: a[0], cmd: a[1] });
      });
    },
        e = function e(c) {
      a.each({ blockquote: ["Blockquote", "mceBlockQuote"], subscript: ["Subscript", "Subscript"], superscript: ["Superscript", "Superscript"] }, function (a, d) {
        c.addButton(d, { tooltip: a[0], cmd: a[1], onPostRender: b.postRenderFormat(c, d) });
      });
    },
        f = function f(a) {
      c(a), d(a), e(a);
    },
        g = function g(c) {
      a.each({ bold: ["Bold", "Bold", "Meta+B"], italic: ["Italic", "Italic", "Meta+I"], underline: ["Underline", "Underline", "Meta+U"], strikethrough: ["Strikethrough", "Strikethrough"], subscript: ["Subscript", "Subscript"], superscript: ["Superscript", "Superscript"], removeformat: ["Clear formatting", "RemoveFormat"], newdocument: ["New document", "mceNewDocument"], cut: ["Cut", "Cut", "Meta+X"], copy: ["Copy", "Copy", "Meta+C"], paste: ["Paste", "Paste", "Meta+V"], selectall: ["Select all", "SelectAll", "Meta+A"] }, function (a, b) {
        c.addMenuItem(b, { text: a[0], icon: b, shortcut: a[2], cmd: a[1] });
      }), c.addMenuItem("codeformat", { text: "Code", icon: "code", onclick: b.toggleFormat(c, "code") });
    },
        h = function h(a) {
      f(a), g(a);
    };return { register: h };
  }), g("1d", [], function () {
    var a = function a(_a7, b) {
      return function () {
        var c = this,
            d = function d() {
          var c = "redo" === b ? "hasRedo" : "hasUndo";return !!_a7.undoManager && _a7.undoManager[c]();
        };c.disabled(!d()), _a7.on("Undo Redo AddUndo TypingUndo ClearUndos SwitchMode", function () {
          c.disabled(_a7.readonly || !d());
        });
      };
    },
        b = function b(_b10) {
      _b10.addMenuItem("undo", { text: "Undo", icon: "undo", shortcut: "Meta+Z", onPostRender: a(_b10, "undo"), cmd: "undo" }), _b10.addMenuItem("redo", { text: "Redo", icon: "redo", shortcut: "Meta+Y", onPostRender: a(_b10, "redo"), cmd: "redo" });
    },
        c = function c(b) {
      b.addButton("undo", { tooltip: "Undo", onPostRender: a(b, "undo"), cmd: "undo" }), b.addButton("redo", { tooltip: "Redo", onPostRender: a(b, "redo"), cmd: "redo" });
    },
        d = function d(a) {
      b(a), c(a);
    };return { register: d };
  }), g("1e", [], function () {
    var a = function a(_a8) {
      return function () {
        var b = this;_a8.on("VisualAid", function (a) {
          b.active(a.hasVisual);
        }), b.active(_a8.hasVisual);
      };
    },
        b = function b(_b11) {
      _b11.addMenuItem("visualaid", { text: "Visual aids", selectable: !0, onPostRender: a(_b11), cmd: "mceToggleVisualAid" });
    },
        c = function c(a) {
      b(a);
    };return { register: c };
  }), g("5", ["z", "10", "11", "12", "13", "14", "o", "w", "15", "16", "17", "18", "19", "1a", "1b", "1c", "1d", "1e"], function (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r) {
    var s = function s() {
      i.tooltips = !f.iOS, g.translate = function (a) {
        return e.translate(a);
      };
    },
        t = function t(e) {
      e.settings.ui_container && (f.container = c.descendant(b.fromDom(d.body), e.settings.ui_container).fold(a.constant(null), function (a) {
        return a.dom();
      }));
    },
        u = function u(a) {
      a.rtl && (g.rtl = !0);
    },
        v = function v(a) {
      a.on("mousedown", function () {
        h.hideAll();
      });
    },
        w = function w(a) {
      u(a), v(a), t(a), s(a), m.register(a), j.register(a), p.register(a), q.register(a), l.register(a), k.register(a), n.register(a), r.register(a), o.register(a);
    };return { setup: w };
  }), g("1g", ["d"], function (a) {
    "use strict";
    return a.extend({ recalc: function recalc(a) {
        var b,
            c,
            d,
            e,
            f,
            g,
            h,
            i,
            j,
            k,
            l,
            m,
            n,
            o,
            p,
            q,
            r,
            s,
            t,
            u,
            v,
            w,
            x,
            y,
            z,
            A,
            B,
            C,
            D,
            E = [],
            F = [];b = a.settings, e = a.items().filter(":visible"), f = a.layoutRect(), d = b.columns || Math.ceil(Math.sqrt(e.length)), c = Math.ceil(e.length / d), s = b.spacingH || b.spacing || 0, t = b.spacingV || b.spacing || 0, u = b.alignH || b.align, v = b.alignV || b.align, q = a.paddingBox, C = "reverseRows" in b ? b.reverseRows : a.isRtl(), u && "string" == typeof u && (u = [u]), v && "string" == typeof v && (v = [v]);for (l = 0; l < d; l++) {
          E.push(0);
        }for (m = 0; m < c; m++) {
          F.push(0);
        }for (m = 0; m < c; m++) {
          for (l = 0; l < d && (k = e[m * d + l], k); l++) {
            j = k.layoutRect(), y = j.minW, z = j.minH, E[l] = y > E[l] ? y : E[l], F[m] = z > F[m] ? z : F[m];
          }
        }for (A = f.innerW - q.left - q.right, w = 0, l = 0; l < d; l++) {
          w += E[l] + (l > 0 ? s : 0), A -= (l > 0 ? s : 0) + E[l];
        }for (B = f.innerH - q.top - q.bottom, x = 0, m = 0; m < c; m++) {
          x += F[m] + (m > 0 ? t : 0), B -= (m > 0 ? t : 0) + F[m];
        }if (w += q.left + q.right, x += q.top + q.bottom, i = {}, i.minW = w + (f.w - f.innerW), i.minH = x + (f.h - f.innerH), i.contentW = i.minW - f.deltaW, i.contentH = i.minH - f.deltaH, i.minW = Math.min(i.minW, f.maxW), i.minH = Math.min(i.minH, f.maxH), i.minW = Math.max(i.minW, f.startMinWidth), i.minH = Math.max(i.minH, f.startMinHeight), !f.autoResize || i.minW == f.minW && i.minH == f.minH) {
          f.autoResize && (i = a.layoutRect(i), i.contentW = i.minW - f.deltaW, i.contentH = i.minH - f.deltaH);var G;G = "start" == b.packV ? 0 : B > 0 ? Math.floor(B / c) : 0;var H = 0,
              I = b.flexWidths;if (I) for (l = 0; l < I.length; l++) {
            H += I[l];
          } else H = d;var J = A / H;for (l = 0; l < d; l++) {
            E[l] += I ? I[l] * J : J;
          }for (o = q.top, m = 0; m < c; m++) {
            for (n = q.left, h = F[m] + G, l = 0; l < d && (D = C ? m * d + d - 1 - l : m * d + l, k = e[D], k); l++) {
              p = k.settings, j = k.layoutRect(), g = Math.max(E[l], j.startMinWidth), j.x = n, j.y = o, r = p.alignH || (u ? u[l] || u[0] : null), "center" == r ? j.x = n + g / 2 - j.w / 2 : "right" == r ? j.x = n + g - j.w : "stretch" == r && (j.w = g), r = p.alignV || (v ? v[l] || v[0] : null), "center" == r ? j.y = o + h / 2 - j.h / 2 : "bottom" == r ? j.y = o + h - j.h : "stretch" == r && (j.h = h), k.layoutRect(j), n += g + s, k.recalc && k.recalc();
            }o += h + t;
          }
        } else if (i.w = i.minW, i.h = i.minH, a.layoutRect(i), this.recalc(a), null === a._lastRect) {
          var K = a.parent();K && (K._lastRect = null, K.recalc());
        }
      } });
  }), g("1h", ["15", "2u"], function (a, b) {
    "use strict";
    return a.extend({ renderHtml: function renderHtml() {
        var a = this;return a.classes.add("iframe"), a.canFocus = !1, '<iframe id="' + a._id + '" class="' + a.classes + '" tabindex="-1" src="' + (a.settings.url || "javascript:''") + '" frameborder="0"></iframe>';
      }, src: function src(a) {
        this.getEl().src = a;
      }, html: function html(a, c) {
        var d = this,
            e = this.getEl().contentWindow.document.body;return e ? (e.innerHTML = a, c && c()) : b.setTimeout(function () {
          d.html(a);
        }), this;
      } });
  }), g("1i", ["15"], function (a) {
    "use strict";
    return a.extend({ init: function init(a) {
        var b = this;b._super(a), b.classes.add("widget").add("infobox"), b.canFocus = !1;
      }, severity: function severity(a) {
        this.classes.remove("error"), this.classes.remove("warning"), this.classes.remove("success"), this.classes.add(a);
      }, help: function help(a) {
        this.state.set("help", a);
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a.classPrefix;return '<div id="' + a._id + '" class="' + a.classes + '"><div id="' + a._id + '-body">' + a.encode(a.state.get("text")) + '<button role="button" tabindex="-1"><i class="' + b + "ico " + b + 'i-help"></i></button></div></div>';
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:text", function (b) {
          a.getEl("body").firstChild.data = a.encode(b.value), a.state.get("rendered") && a.updateLayoutRect();
        }), a.state.on("change:help", function (b) {
          a.classes.toggle("has-help", b.value), a.state.get("rendered") && a.updateLayoutRect();
        }), a._super();
      } });
  }), g("1k", ["15", "2n"], function (a, b) {
    "use strict";
    return a.extend({ init: function init(a) {
        var b = this;b._super(a), b.classes.add("widget").add("label"), b.canFocus = !1, a.multiline && b.classes.add("autoscroll"), a.strong && b.classes.add("strong");
      }, initLayoutRect: function initLayoutRect() {
        var a = this,
            c = a._super();if (a.settings.multiline) {
          var d = b.getSize(a.getEl());d.width > c.maxW && (c.minW = c.maxW, a.classes.add("multiline")), a.getEl().style.width = c.minW + "px", c.startMinH = c.h = c.minH = Math.min(c.maxH, b.getSize(a.getEl()).height);
        }return c;
      }, repaint: function repaint() {
        var a = this;return a.settings.multiline || (a.getEl().style.lineHeight = a.layoutRect().h + "px"), a._super();
      }, severity: function severity(a) {
        this.classes.remove("error"), this.classes.remove("warning"), this.classes.remove("success"), this.classes.add(a);
      }, renderHtml: function renderHtml() {
        var a,
            b,
            c = this,
            d = c.settings.forId,
            e = c.settings.html ? c.settings.html : c.encode(c.state.get("text"));return !d && (b = c.settings.forName) && (a = c.getRoot().find("#" + b)[0], a && (d = a._id)), d ? '<label id="' + c._id + '" class="' + c.classes + '"' + (d ? ' for="' + d + '"' : "") + ">" + e + "</label>" : '<span id="' + c._id + '" class="' + c.classes + '">' + e + "</span>";
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:text", function (b) {
          a.innerHtml(a.encode(b.value)), a.state.get("rendered") && a.updateLayoutRect();
        }), a._super();
      } });
  }), g("2c", ["n"], function (a) {
    "use strict";
    return a.extend({ Defaults: { role: "toolbar", layout: "flow" }, init: function init(a) {
        var b = this;b._super(a), b.classes.add("toolbar");
      }, postRender: function postRender() {
        var a = this;return a.items().each(function (a) {
          a.classes.add("toolbar-item");
        }), a._super();
      } });
  }), g("1o", ["2c"], function (a) {
    "use strict";
    return a.extend({ Defaults: { role: "menubar", containerCls: "menubar", ariaRoot: !0, defaults: { type: "menubutton" } } });
  }), g("1p", ["1", "b", "f", "1o"], function (a, b, c, d) {
    "use strict";
    function e(a, b) {
      for (; a;) {
        if (b === a) return !0;a = a.parentNode;
      }return !1;
    }var f = c.extend({ init: function init(a) {
        var b = this;b._renderOpen = !0, b._super(a), a = b.settings, b.classes.add("menubtn"), a.fixedWidth && b.classes.add("fixed-width"), b.aria("haspopup", !0), b.state.set("menu", a.menu || b.render());
      }, showMenu: function showMenu(a) {
        var c,
            d = this;return d.menu && d.menu.visible() && a !== !1 ? d.hideMenu() : (d.menu || (c = d.state.get("menu") || [], d.classes.add("opened"), c.length ? c = { type: "menu", animate: !0, items: c } : (c.type = c.type || "menu", c.animate = !0), c.renderTo ? d.menu = c.parent(d).show().renderTo() : d.menu = b.create(c).parent(d).renderTo(), d.fire("createmenu"), d.menu.reflow(), d.menu.on("cancel", function (a) {
          a.control.parent() === d.menu && (a.stopPropagation(), d.focus(), d.hideMenu());
        }), d.menu.on("select", function () {
          d.focus();
        }), d.menu.on("show hide", function (a) {
          a.control === d.menu && (d.activeMenu("show" == a.type), d.classes.toggle("opened", "show" == a.type)), d.aria("expanded", "show" == a.type);
        }).fire("show")), d.menu.show(), d.menu.layoutRect({ w: d.layoutRect().w }), d.menu.moveRel(d.getEl(), d.isRtl() ? ["br-tr", "tr-br"] : ["bl-tl", "tl-bl"]), void d.fire("showmenu"));
      }, hideMenu: function hideMenu() {
        var a = this;a.menu && (a.menu.items().each(function (a) {
          a.hideMenu && a.hideMenu();
        }), a.menu.hide());
      }, activeMenu: function activeMenu(a) {
        this.classes.toggle("active", a);
      }, renderHtml: function renderHtml() {
        var b,
            c = this,
            e = c._id,
            f = c.classPrefix,
            g = c.settings.icon,
            h = c.state.get("text"),
            i = "";return b = c.settings.image, b ? (g = "none", "string" != typeof b && (b = a.getSelection ? b[0] : b[1]), b = " style=\"background-image: url('" + b + "')\"") : b = "", h && (c.classes.add("btn-has-text"), i = '<span class="' + f + 'txt">' + c.encode(h) + "</span>"), g = c.settings.icon ? f + "ico " + f + "i-" + g : "", c.aria("role", c.parent() instanceof d ? "menuitem" : "button"), '<div id="' + e + '" class="' + c.classes + '" tabindex="-1" aria-labelledby="' + e + '"><button id="' + e + '-open" role="presentation" type="button" tabindex="-1">' + (g ? '<i class="' + g + '"' + b + "></i>" : "") + i + ' <i class="' + f + 'caret"></i></button></div>';
      }, postRender: function postRender() {
        var a = this;return a.on("click", function (b) {
          b.control === a && e(b.target, a.getEl()) && (a.focus(), a.showMenu(!b.aria), b.aria && a.menu.items().filter(":visible")[0].focus());
        }), a.on("mouseenter", function (b) {
          var c,
              d = b.control,
              e = a.parent();d && e && d instanceof f && d.parent() == e && (e.items().filter("MenuButton").each(function (a) {
            a.hideMenu && a != d && (a.menu && a.menu.visible() && (c = !0), a.hideMenu());
          }), c && (d.focus(), d.showMenu()));
        }), a._super();
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:menu", function () {
          a.menu && a.menu.remove(), a.menu = null;
        }), a._super();
      }, remove: function remove() {
        this._super(), this.menu && this.menu.remove();
      } });return f;
  }), g("1q", ["15", "b", "14", "2u"], function (a, b, c, d) {
    "use strict";
    var e = function e(a, b) {
      var c = a._textStyle;if (c) {
        var d = a.getEl("text");d.setAttribute("style", c), b && (d.style.color = "", d.style.backgroundColor = "");
      }
    };return a.extend({ Defaults: { border: 0, role: "menuitem" }, init: function init(a) {
        var b,
            c = this;c._super(a), a = c.settings, c.classes.add("menu-item"), a.menu && c.classes.add("menu-item-expand"), a.preview && c.classes.add("menu-item-preview"), b = c.state.get("text"), "-" !== b && "|" !== b || (c.classes.add("menu-item-sep"), c.aria("role", "separator"), c.state.set("text", "-")), a.selectable && (c.aria("role", "menuitemcheckbox"), c.classes.add("menu-item-checkbox"), a.icon = "selected"), a.preview || a.selectable || c.classes.add("menu-item-normal"), c.on("mousedown", function (a) {
          a.preventDefault();
        }), a.menu && !a.ariaHideMenu && c.aria("haspopup", !0);
      }, hasMenus: function hasMenus() {
        return !!this.settings.menu;
      }, showMenu: function showMenu() {
        var a,
            c = this,
            d = c.settings,
            e = c.parent();if (e.items().each(function (a) {
          a !== c && a.hideMenu();
        }), d.menu) {
          a = c.menu, a ? a.show() : (a = d.menu, a.length ? a = { type: "menu", animate: !0, items: a } : (a.type = a.type || "menu", a.animate = !0), e.settings.itemDefaults && (a.itemDefaults = e.settings.itemDefaults), a = c.menu = b.create(a).parent(c).renderTo(), a.reflow(), a.on("cancel", function (b) {
            b.stopPropagation(), c.focus(), a.hide();
          }), a.on("show hide", function (a) {
            a.control.items && a.control.items().each(function (a) {
              a.active(a.settings.selected);
            });
          }).fire("show"), a.on("hide", function (b) {
            b.control === a && c.classes.remove("selected");
          }), a.submenu = !0), a._parentMenu = e, a.classes.add("menu-sub");var f = a.testMoveRel(c.getEl(), c.isRtl() ? ["tl-tr", "bl-br", "tr-tl", "br-bl"] : ["tr-tl", "br-bl", "tl-tr", "bl-br"]);a.moveRel(c.getEl(), f), a.rel = f, f = "menu-sub-" + f, a.classes.remove(a._lastRel).add(f), a._lastRel = f, c.classes.add("selected"), c.aria("expanded", !0);
        }
      }, hideMenu: function hideMenu() {
        var a = this;return a.menu && (a.menu.items().each(function (a) {
          a.hideMenu && a.hideMenu();
        }), a.menu.hide(), a.aria("expanded", !1)), a;
      }, renderHtml: function renderHtml() {
        function a(a) {
          var b,
              d,
              e = {};for (e = c.mac ? { alt: "&#x2325;", ctrl: "&#x2318;", shift: "&#x21E7;", meta: "&#x2318;" } : { meta: "Ctrl" }, a = a.split("+"), b = 0; b < a.length; b++) {
            d = e[a[b].toLowerCase()], d && (a[b] = d);
          }return a.join("+");
        }function b(a) {
          return a.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        }function d(a) {
          var c = h.match || "";return c ? a.replace(new RegExp(b(c), "gi"), function (a) {
            return "!mce~match[" + a + "]mce~match!";
          }) : a;
        }function e(a) {
          return a.replace(new RegExp(b("!mce~match["), "g"), "<b>").replace(new RegExp(b("]mce~match!"), "g"), "</b>");
        }var f = this,
            g = f._id,
            h = f.settings,
            i = f.classPrefix,
            j = f.state.get("text"),
            k = f.settings.icon,
            l = "",
            m = h.shortcut,
            n = f.encode(h.url),
            o = "";return k && f.parent().classes.add("menu-has-icons"), h.image && (l = " style=\"background-image: url('" + h.image + "')\""), m && (m = a(m)), k = i + "ico " + i + "i-" + (f.settings.icon || "none"), o = "-" !== j ? '<i class="' + k + '"' + l + "></i>\xa0" : "", j = e(f.encode(d(j))), n = e(f.encode(d(n))), '<div id="' + g + '" class="' + f.classes + '" tabindex="-1">' + o + ("-" !== j ? '<span id="' + g + '-text" class="' + i + 'text">' + j + "</span>" : "") + (m ? '<div id="' + g + '-shortcut" class="' + i + 'menu-shortcut">' + m + "</div>" : "") + (h.menu ? '<div class="' + i + 'caret"></div>' : "") + (n ? '<div class="' + i + 'menu-item-link">' + n + "</div>" : "") + "</div>";
      }, postRender: function postRender() {
        var a = this,
            b = a.settings,
            c = b.textStyle;if ("function" == typeof c && (c = c.call(this)), c) {
          var e = a.getEl("text");e && (e.setAttribute("style", c), a._textStyle = c);
        }return a.on("mouseenter click", function (c) {
          c.control === a && (b.menu || "click" !== c.type ? (a.showMenu(), c.aria && a.menu.focus(!0)) : (a.fire("select"), d.requestAnimationFrame(function () {
            a.parent().hideAll();
          })));
        }), a._super(), a;
      }, hover: function hover() {
        var a = this;return a.parent().items().each(function (a) {
          a.classes.remove("selected");
        }), a.classes.toggle("selected", !0), a;
      }, active: function active(a) {
        return e(this, a), "undefined" != typeof a && this.aria("checked", a), this._super(a);
      }, remove: function remove() {
        this._super(), this.menu && this.menu.remove();
      } });
  }), g("1n", ["14", "2u", "c", "w", "1q", "2b"], function (a, b, c, d, e, f) {
    "use strict";
    return d.extend({ Defaults: { defaultType: "menuitem", border: 1, layout: "stack", role: "application", bodyRole: "menu", ariaRoot: !0 }, init: function init(b) {
        var d = this;if (b.autohide = !0, b.constrainToViewport = !0, "function" == typeof b.items && (b.itemsFactory = b.items, b.items = []), b.itemDefaults) for (var e = b.items, f = e.length; f--;) {
          e[f] = c.extend({}, b.itemDefaults, e[f]);
        }d._super(b), d.classes.add("menu"), b.animate && 11 !== a.ie && d.classes.add("animate");
      }, repaint: function repaint() {
        return this.classes.toggle("menu-align", !0), this._super(), this.getEl().style.height = "", this.getEl("body").style.height = "", this;
      }, cancel: function cancel() {
        var a = this;a.hideAll(), a.fire("select");
      }, load: function load() {
        function a() {
          d.throbber && (d.throbber.hide(), d.throbber = null);
        }var b,
            c,
            d = this;c = d.settings.itemsFactory, c && (d.throbber || (d.throbber = new f(d.getEl("body"), !0), 0 === d.items().length ? (d.throbber.show(), d.fire("loading")) : d.throbber.show(100, function () {
          d.items().remove(), d.fire("loading");
        }), d.on("hide close", a)), d.requestTime = b = new Date().getTime(), d.settings.itemsFactory(function (c) {
          return 0 === c.length ? void d.hide() : void (d.requestTime === b && (d.getEl().style.width = "", d.getEl("body").style.width = "", a(), d.items().remove(), d.getEl("body").innerHTML = "", d.add(c), d.renderNew(), d.fire("loaded")));
        }));
      }, hideAll: function hideAll() {
        var a = this;return this.find("menuitem").exec("hideMenu"), a._super();
      }, preRender: function preRender() {
        var a = this;return a.items().each(function (b) {
          var c = b.settings;if (c.icon || c.image || c.selectable) return a._hasIcons = !0, !1;
        }), a.settings.itemsFactory && a.on("postrender", function () {
          a.settings.itemsFactory && a.load();
        }), a.on("show hide", function (c) {
          c.control === a && ("show" === c.type ? b.setTimeout(function () {
            a.classes.add("in");
          }, 0) : a.classes.remove("in"));
        }), a._super();
      } });
  }), g("1m", ["1p", "1n"], function (a, b) {
    "use strict";
    return a.extend({ init: function init(a) {
        function b(c) {
          for (var f = 0; f < c.length; f++) {
            if (d = c[f].selected || a.value === c[f].value) return e = e || c[f].text, g.state.set("value", c[f].value), !0;if (c[f].menu && b(c[f].menu)) return !0;
          }
        }var c,
            d,
            e,
            f,
            g = this;g._super(a), a = g.settings, g._values = c = a.values, c && ("undefined" != typeof a.value && b(c), !d && c.length > 0 && (e = c[0].text, g.state.set("value", c[0].value)), g.state.set("menu", c)), g.state.set("text", a.text || e), g.classes.add("listbox"), g.on("select", function (b) {
          var c = b.control;f && (b.lastControl = f), a.multiple ? c.active(!c.active()) : g.value(b.control.value()), f = c;
        });
      }, bindStates: function bindStates() {
        function a(a, c) {
          a instanceof b && a.items().each(function (a) {
            a.hasMenus() || a.active(a.value() === c);
          });
        }function c(a, b) {
          var d;if (a) for (var e = 0; e < a.length; e++) {
            if (a[e].value === b) return a[e];if (a[e].menu && (d = c(a[e].menu, b))) return d;
          }
        }var d = this;return d.on("show", function (b) {
          a(b.control, d.value());
        }), d.state.on("change:value", function (a) {
          var b = c(d.state.get("menu"), a.value);b ? d.text(b.text) : d.text(d.settings.text);
        }), d._super();
      } });
  }), g("1y", ["h"], function (a) {
    "use strict";
    return a.extend({ Defaults: { classes: "radio", role: "radio" } });
  }), g("21", ["15", "p"], function (a, b) {
    "use strict";
    return a.extend({ renderHtml: function renderHtml() {
        var a = this,
            b = a.classPrefix;return a.classes.add("resizehandle"), "both" == a.settings.direction && a.classes.add("resizehandle-both"), a.canFocus = !1, '<div id="' + a._id + '" class="' + a.classes + '"><i class="' + b + "ico " + b + 'i-resize"></i></div>';
      }, postRender: function postRender() {
        var a = this;a._super(), a.resizeDragHelper = new b(this._id, { start: function start() {
            a.fire("ResizeStart");
          }, drag: function drag(b) {
            "both" != a.settings.direction && (b.deltaX = 0), a.fire("Resize", b);
          }, stop: function stop() {
            a.fire("ResizeEnd");
          } });
      }, remove: function remove() {
        return this.resizeDragHelper && this.resizeDragHelper.destroy(), this._super();
      } });
  }), g("23", ["15"], function (a) {
    "use strict";
    function b(a) {
      var b = "";if (a) for (var c = 0; c < a.length; c++) {
        b += '<option value="' + a[c] + '">' + a[c] + "</option>";
      }return b;
    }return a.extend({ Defaults: { classes: "selectbox", role: "selectbox", options: [] }, init: function init(a) {
        var b = this;b._super(a), b.settings.size && (b.size = b.settings.size), b.settings.options && (b._options = b.settings.options), b.on("keydown", function (a) {
          var c;13 == a.keyCode && (a.preventDefault(), b.parents().reverse().each(function (a) {
            if (a.toJSON) return c = a, !1;
          }), b.fire("submit", { data: c.toJSON() }));
        });
      }, options: function options(a) {
        return arguments.length ? (this.state.set("options", a), this) : this.state.get("options");
      }, renderHtml: function renderHtml() {
        var a,
            c = this,
            d = "";return a = b(c._options), c.size && (d = ' size = "' + c.size + '"'), '<select id="' + c._id + '" class="' + c.classes + '"' + d + ">" + a + "</select>";
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:options", function (c) {
          a.getEl().innerHTML = b(c.value);
        }), a._super();
      } });
  }), g("25", ["15", "p", "2n"], function (a, b, c) {
    "use strict";
    function d(a, b, c) {
      return a < b && (a = b), a > c && (a = c), a;
    }function e(a, b, c) {
      a.setAttribute("aria-" + b, c);
    }function f(a, b) {
      var d, f, g, h, i, j;"v" == a.settings.orientation ? (h = "top", g = "height", f = "h") : (h = "left", g = "width", f = "w"), j = a.getEl("handle"), d = (a.layoutRect()[f] || 100) - c.getSize(j)[g], i = d * ((b - a._minValue) / (a._maxValue - a._minValue)) + "px", j.style[h] = i, j.style.height = a.layoutRect().h + "px", e(j, "valuenow", b), e(j, "valuetext", "" + a.settings.previewFilter(b)), e(j, "valuemin", a._minValue), e(j, "valuemax", a._maxValue);
    }return a.extend({ init: function init(a) {
        var b = this;a.previewFilter || (a.previewFilter = function (a) {
          return Math.round(100 * a) / 100;
        }), b._super(a), b.classes.add("slider"), "v" == a.orientation && b.classes.add("vertical"), b._minValue = a.minValue || 0, b._maxValue = a.maxValue || 100, b._initValue = b.state.get("value");
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a._id,
            c = a.classPrefix;return '<div id="' + b + '" class="' + a.classes + '"><div id="' + b + '-handle" class="' + c + 'slider-handle" role="slider" tabindex="-1"></div></div>';
      }, reset: function reset() {
        this.value(this._initValue).repaint();
      }, postRender: function postRender() {
        function a(a, b, c) {
          return (c + a) / (b - a);
        }function e(a, b, c) {
          return c * (b - a) - a;
        }function f(b, c) {
          function f(f) {
            var g;g = n.value(), g = e(b, c, a(b, c, g) + .05 * f), g = d(g, b, c), n.value(g), n.fire("dragstart", { value: g }), n.fire("drag", { value: g }), n.fire("dragend", { value: g });
          }n.on("keydown", function (a) {
            switch (a.keyCode) {case 37:case 38:
                f(-1);break;case 39:case 40:
                f(1);}
          });
        }function g(a, e, f) {
          var g, h, i, o, p;n._dragHelper = new b(n._id, { handle: n._id + "-handle", start: function start(a) {
              g = a[j], h = parseInt(n.getEl("handle").style[k], 10), i = (n.layoutRect()[m] || 100) - c.getSize(f)[l], n.fire("dragstart", { value: p });
            }, drag: function drag(b) {
              var c = b[j] - g;o = d(h + c, 0, i), f.style[k] = o + "px", p = a + o / i * (e - a), n.value(p), n.tooltip().text("" + n.settings.previewFilter(p)).show().moveRel(f, "bc tc"), n.fire("drag", { value: p });
            }, stop: function stop() {
              n.tooltip().hide(), n.fire("dragend", { value: p });
            } });
        }var h,
            i,
            j,
            k,
            l,
            m,
            n = this;h = n._minValue, i = n._maxValue, "v" == n.settings.orientation ? (j = "screenY", k = "top", l = "height", m = "h") : (j = "screenX", k = "left", l = "width", m = "w"), n._super(), f(h, i, n.getEl("handle")), g(h, i, n.getEl("handle"));
      }, repaint: function repaint() {
        this._super(), f(this, this.value());
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:value", function (b) {
          f(a, b.value);
        }), a._super();
      } });
  }), g("26", ["15"], function (a) {
    "use strict";
    return a.extend({ renderHtml: function renderHtml() {
        var a = this;return a.classes.add("spacer"), a.canFocus = !1, '<div id="' + a._id + '" class="' + a.classes + '"></div>';
      } });
  }), g("27", ["1", "2o", "2n", "1p"], function (a, b, c, d) {
    return d.extend({ Defaults: { classes: "widget btn splitbtn", role: "button" }, repaint: function repaint() {
        var a,
            d,
            e = this,
            f = e.getEl(),
            g = e.layoutRect();return e._super(), a = f.firstChild, d = f.lastChild, b(a).css({ width: g.w - c.getSize(d).width, height: g.h - 2 }), b(d).css({ height: g.h - 2 }), e;
      }, activeMenu: function activeMenu(a) {
        var c = this;b(c.getEl().lastChild).toggleClass(c.classPrefix + "active", a);
      }, renderHtml: function renderHtml() {
        var b,
            c = this,
            d = c._id,
            e = c.classPrefix,
            f = c.state.get("icon"),
            g = c.state.get("text"),
            h = "";return b = c.settings.image, b ? (f = "none", "string" != typeof b && (b = a.getSelection ? b[0] : b[1]), b = " style=\"background-image: url('" + b + "')\"") : b = "", f = c.settings.icon ? e + "ico " + e + "i-" + f : "", g && (c.classes.add("btn-has-text"), h = '<span class="' + e + 'txt">' + c.encode(g) + "</span>"), '<div id="' + d + '" class="' + c.classes + '" role="button" tabindex="-1"><button type="button" hidefocus="1" tabindex="-1">' + (f ? '<i class="' + f + '"' + b + "></i>" : "") + h + '</button><button type="button" class="' + e + 'open" hidefocus="1" tabindex="-1">' + (c._menuBtnText ? (f ? "\xa0" : "") + c._menuBtnText : "") + ' <i class="' + e + 'caret"></i></button></div>';
      }, postRender: function postRender() {
        var a = this,
            b = a.settings.onclick;return a.on("click", function (a) {
          var c = a.target;if (a.control == this) for (; c;) {
            if (a.aria && "down" != a.aria.key || "BUTTON" == c.nodeName && c.className.indexOf("open") == -1) return a.stopImmediatePropagation(), void (b && b.call(this, a));c = c.parentNode;
          }
        }), delete a.settings.onclick, a._super();
      } });
  }), g("28", ["x"], function (a) {
    "use strict";
    return a.extend({ Defaults: { containerClass: "stack-layout", controlClass: "stack-layout-item", endClass: "break" }, isNative: function isNative() {
        return !0;
      } });
  }), g("29", ["1u", "2o", "2n"], function (a, b, c) {
    "use strict";
    return a.extend({ Defaults: { layout: "absolute", defaults: { type: "panel" } }, activateTab: function activateTab(a) {
        var c;this.activeTabId && (c = this.getEl(this.activeTabId), b(c).removeClass(this.classPrefix + "active"), c.setAttribute("aria-selected", "false")), this.activeTabId = "t" + a, c = this.getEl("t" + a), c.setAttribute("aria-selected", "true"), b(c).addClass(this.classPrefix + "active"), this.items()[a].show().fire("showtab"), this.reflow(), this.items().each(function (b, c) {
          a != c && b.hide();
        });
      }, renderHtml: function renderHtml() {
        var a = this,
            b = a._layout,
            c = "",
            d = a.classPrefix;return a.preRender(), b.preRender(a), a.items().each(function (b, e) {
          var f = a._id + "-t" + e;b.aria("role", "tabpanel"), b.aria("labelledby", f), c += '<div id="' + f + '" class="' + d + 'tab" unselectable="on" role="tab" aria-controls="' + b._id + '" aria-selected="false" tabIndex="-1">' + a.encode(b.settings.title) + "</div>";
        }), '<div id="' + a._id + '" class="' + a.classes + '" hidefocus="1" tabindex="-1"><div id="' + a._id + '-head" class="' + d + 'tabs" role="tablist">' + c + '</div><div id="' + a._id + '-body" class="' + a.bodyClasses + '">' + b.renderHtml(a) + "</div></div>";
      }, postRender: function postRender() {
        var a = this;a._super(), a.settings.activeTab = a.settings.activeTab || 0, a.activateTab(a.settings.activeTab), this.on("click", function (b) {
          var c = b.target.parentNode;if (c && c.id == a._id + "-head") for (var d = c.childNodes.length; d--;) {
            c.childNodes[d] == b.target && a.activateTab(d);
          }
        });
      }, initLayoutRect: function initLayoutRect() {
        var a,
            b,
            d,
            e = this;b = c.getSize(e.getEl("head")).width, b = b < 0 ? 0 : b, d = 0, e.items().each(function (a) {
          b = Math.max(b, a.layoutRect().minW), d = Math.max(d, a.layoutRect().minH);
        }), e.items().each(function (a) {
          a.settings.x = 0, a.settings.y = 0, a.settings.w = b, a.settings.h = d, a.layoutRect({ x: 0, y: 0, w: b, h: d });
        });var f = c.getSize(e.getEl("head")).height;return e.settings.minWidth = b, e.settings.minHeight = d + f, a = e._super(), a.deltaH += f, a.innerH = a.h - a.deltaH, a;
      } });
  }), g("2a", ["12", "c", "2n", "15"], function (a, b, c, d) {
    return d.extend({ init: function init(a) {
        var b = this;b._super(a), b.classes.add("textbox"), a.multiline ? b.classes.add("multiline") : (b.on("keydown", function (a) {
          var c;13 == a.keyCode && (a.preventDefault(), b.parents().reverse().each(function (a) {
            if (a.toJSON) return c = a, !1;
          }), b.fire("submit", { data: c.toJSON() }));
        }), b.on("keyup", function (a) {
          b.state.set("value", a.target.value);
        }));
      }, repaint: function repaint() {
        var b,
            c,
            d,
            e,
            f,
            g = this,
            h = 0;b = g.getEl().style, c = g._layoutRect, f = g._lastRepaintRect || {};var i = a;return !g.settings.multiline && i.all && (!i.documentMode || i.documentMode <= 8) && (b.lineHeight = c.h - h + "px"), d = g.borderBox, e = d.left + d.right + 8, h = d.top + d.bottom + (g.settings.multiline ? 8 : 0), c.x !== f.x && (b.left = c.x + "px", f.x = c.x), c.y !== f.y && (b.top = c.y + "px", f.y = c.y), c.w !== f.w && (b.width = c.w - e + "px", f.w = c.w), c.h !== f.h && (b.height = c.h - h + "px", f.h = c.h), g._lastRepaintRect = f, g.fire("repaint", {}, !1), g;
      }, renderHtml: function renderHtml() {
        var a,
            d,
            e = this,
            f = e.settings;return a = { id: e._id, hidefocus: "1" }, b.each(["rows", "spellcheck", "maxLength", "size", "readonly", "min", "max", "step", "list", "pattern", "placeholder", "required", "multiple"], function (b) {
          a[b] = f[b];
        }), e.disabled() && (a.disabled = "disabled"), f.subtype && (a.type = f.subtype), d = c.create(f.multiline ? "textarea" : "input", a), d.value = e.state.get("value"), d.className = e.classes, d.outerHTML;
      }, value: function value(a) {
        return arguments.length ? (this.state.set("value", a), this) : (this.state.get("rendered") && this.state.set("value", this.getEl().value), this.state.get("value"));
      }, postRender: function postRender() {
        var a = this;a.getEl().value = a.state.get("value"), a._super(), a.$el.on("change", function (b) {
          a.state.set("value", b.target.value), a.fire("change", b);
        });
      }, bindStates: function bindStates() {
        var a = this;return a.state.on("change:value", function (b) {
          a.getEl().value != b.value && (a.getEl().value = b.value);
        }), a.state.on("change:disabled", function (b) {
          a.getEl().disabled = b.value;
        }), a._super();
      }, remove: function remove() {
        this.$el.off(), this._super();
      } });
  }), g("4", ["b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "5", "1f", "1g", "1h", "1i", "1j", "1k", "1l", "1m", "1n", "1o", "1p", "1q", "1r", "1s", "1t", "1u", "1v", "1w", "1x", "1y", "1z", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "2a", "2b", "2c", "2d", "15", "2e"], function (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z, A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Y, Z, $, _, aa, ba, ca, da, ea, fa, ga, ha) {
    var ia = function ia() {
      return { Selector: Y, Collection: h, ReflowQueue: T, Control: n, Factory: a, KeyboardNavigation: D, Container: m, DragHelper: o, Scrollable: W, Panel: O, Movable: M, Resizable: U, FloatPanel: v, Window: ha, MessageBox: L, Tooltip: fa, Widget: ga, Progress: R, Notification: N, Layout: F, AbsoluteLayout: c, Button: e, ButtonGroup: f, Checkbox: g, ComboBox: l, ColorBox: i, PanelButton: P, ColorButton: j, ColorPicker: k, Path: Q, ElementPath: q, FormItem: z, Form: x, FieldSet: r, FilePicker: s, FitLayout: t, FlexLayout: u, FlowLayout: w, FormatControls: y, GridLayout: A, Iframe: B, InfoBox: C, Label: E, Toolbar: ea, MenuBar: I, MenuButton: J, MenuItem: K, Throbber: da, Menu: H, ListBox: G, Radio: S, ResizeHandle: V, SelectBox: X, Slider: Z, Spacer: $, SplitButton: _, StackLayout: aa, TabPanel: ba, TextBox: ca, DropZone: p, BrowseButton: d };
    },
        ja = function ja(a) {
      a.ui ? b.each(ia(), function (b, c) {
        a.ui[c] = b;
      }) : a.ui = ia();
    },
        ka = function ka() {
      b.each(ia(), function (b, c) {
        a.add(c, b);
      });
    },
        la = { appendTo: ja, registerToFactory: ka };return la;
  }), g("0", ["1", "2", "3", "4", "5"], function (a, b, c, d, e) {
    return d.registerToFactory(), d.appendTo(a.tinymce ? a.tinymce : {}), b.add("modern", function (a) {
      return e.setup(a), c.get(a);
    }), function () {};
  }), d("0")();
}();