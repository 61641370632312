;(function () {
    'use strict';

    var successCodes = [200, 201, 202, 204, 206];

    var AuthRequest = {
        getBaseUrl: function getBaseUrl() {
            if (window.location.href.indexOf('localhost:5123') !== -1) {
                return 'http://localhost:9090';
            } else if (window.location.href.indexOf('stage') !== -1) {
                return 'https://account-api-stage.geckoengage.com';
            } else {
                return 'https://account-api.geckoengage.com';
            }
        },

        getAuthRedirectUrl: function getAuthRedirectUrl() {
            if (window.location.href.indexOf('localhost:5123') !== -1) {
                return 'http://localhost:4000/logout';
            } else if (window.location.href.indexOf('stage') !== -1) {
                return 'https://account-stage.geckoengage.com/logout';
            } else {
                return 'https://account.geckoengage.com/logout';
            }
        },

        getAuthUrl: function getAuthUrl() {
            if (window.location.href.indexOf('localhost:5123') !== -1) {
                return 'http://localhost:4000/';
            } else if (window.location.href.indexOf('stage') !== -1) {
                return 'https://account-stage.geckoengage.com/';
            } else {
                return 'https://account.geckoengage.com/';
            }
        },

        prepareUrl: function prepareUrl() {
            var endpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

            if (endpoint.length && endpoint[0] === '/') {
                return '' + AuthRequest.getBaseUrl() + endpoint;
            }
            return endpoint;
        },

        sanatizeParams: function sanatizeParams() {
            var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            // Check params dont use undefined
            for (var prop in params) {
                if (params[prop] === undefined) params[prop] = null;
            }
            return params;
        },

        post: function post(endpoint) {
            var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            var headers = {};
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';

            if (options.Authorization) {
                headers['Authorization'] = options.Authorization;
            }

            if (!options.dont_refresh_tokens) {
                AuthRequest.refreshToken();
            }

            return new Promise(function (resolve, reject) {
                fetch(AuthRequest.prepareUrl(endpoint), {
                    method: 'POST',
                    body: JSON.stringify(AuthRequest.sanatizeParams(params)),
                    headers: headers
                }).then(function (response) {
                    var resolveFunc = successCodes.includes(response.status) ? resolve : reject;

                    if (response.status === 204) {
                        resolveFunc(true);
                    } else if (!options.raw) {
                        response.json().then(resolveFunc);
                    } else {
                        resolveFunc(response);
                    }
                }).catch(reject);
            });
        },
        get: function get(endpoint, params) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            var headers = {};

            if (options.Authorization) {
                headers['Authorization'] = options.Authorization;
            }

            return new Promise(function (resolve, reject) {
                fetch(AuthRequest.prepareUrl(endpoint), {
                    method: 'GET',
                    headers: headers
                }).then(function (response) {
                    var resolveFunc = successCodes.includes(response.status) ? resolve : reject;

                    if (response.status === 204) {
                        resolveFunc(true);
                    } else if (!options.raw) {
                        response.json().then(resolveFunc);
                    } else {
                        resolveFunc(response);
                    }
                }).catch(reject);
            });
        },
        delete: function _delete(endpoint, params) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            var headers = {};

            if (options.Authorization) {
                headers['Authorization'] = options.Authorization;
            }

            return new Promise(function (resolve, reject) {
                fetch(AuthRequest.prepareUrl(endpoint), {
                    method: 'DELETE',
                    headers: headers
                }).then(function (response) {
                    var resolveFunc = successCodes.includes(response.status) ? resolve : reject;

                    if (response.status === 204) {
                        resolveFunc(true);
                    } else if (!options.raw) {
                        response.json().then(resolveFunc);
                    } else {
                        resolveFunc(response);
                    }
                }).catch(reject);
            });
        },
        refreshTokens: function refreshTokens() {
            if (window.TokenManager) {
                var TokenManager = window.TokenManager;

                return TokenManager.refreshToken();
            } else {
                throw new Error('TokenManager not defined on window');
            }
        }
    };

    window.AuthRequest = AuthRequest;
})();