(function () {
    'use strict';

    function contrastColor() {
        return {
            restrict: 'A',

            link: function link(scope, iElement) {

                scope.$watch(function () {
                    return iElement.css('background-color');
                }, function (newColor) {
                    newColor = tinycolor(newColor).toHexString();
                    iElement.css('color', GeckoUI.getContrastColor(newColor));
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('contrastColor', contrastColor);
})();