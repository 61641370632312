(function () {
    'use strict';

    function CallsNumbersCtrl($scope, $geckoSocket, Gecko, numbers, NumberStats, munge) {
        var ctrl = this;
        ctrl.numbers = numbers.toArray();

        var numStats = NumberStats.init(ctrl.numbers);

        var status = function status(number) {

            if (number.queue_length <= number.available_incoming_users.length) {
                return 'good';
            } else if (number.queue_length <= number.available_incoming_users.length * 2) {
                return 'busy';
            } else {
                return 'very-busy';
            }
        };

        var updateRows = function updateRows() {

            ctrl.numbers.forEach(function (number) {
                number.campaigns_string = munge(number.campaigns).byKey('title').done().join(', ');

                number.status = status(number);
            });
        };
        updateRows();

        $geckoSocket.registerEvent('voip:queue', function (data) {

            numStats = NumberStats.update(numStats, data);
            updateRows();

            $scope.$digest();
        });

        ctrl.cols = [{
            key: 'status',
            status_title_none: true,
            expandable: true,
            status_styles: [{
                id: 'good',
                title: 'Good',
                label: 'success'
            }, {
                id: 'busy',
                title: 'Busy',
                label: 'warning'
            }, {
                id: 'very-busy',
                title: 'Very Busy',
                label: 'danger'
            }]
        }, {
            key: 'tel',
            title: 'Number',
            colClass: 'col-xs-2',
            render: function render(value, col, number) {
                var name = number.name;
                var tel = number.tel;
                return (name ? name : 'Unknown') + ' (' + tel + ')';
            }
        }, {
            key: 'campaigns_string',
            title: 'Related Campaigns',
            colClass: 'col-xs-10'
        }, {
            key: 'available_incoming_users.length',
            title: 'Agents Available',
            data_type: Gecko.Field.DATA_TYPE_INTEGER
        }, {
            key: 'queue_length',
            title: 'Calls in Queue',
            data_type: Gecko.Field.DATA_TYPE_INTEGER
        }];
    }

    angular.module('GeckoEngage').controller('CallsNumbersCtrl', CallsNumbersCtrl);
})();