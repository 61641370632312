(function () {
    'use strict';

    // https://audiojungle.net/item/error-04/12798907?s_rank=47
    // https://audiojungle.net/item/phone-ringtone/15648551?s_rank=10

    function $geckoSounds(ngAudio) {

        var _this = {

            sounds: null,

            init: function init() {
                _this.sounds = {
                    ringing: ngAudio.load('https://audio.geckoform.com/phone-ringing.wav'),
                    incoming: ngAudio.load(Gecko.account && Gecko.account.ringtone_url ? Gecko.account.ringtone_url : ''),
                    error: ngAudio.load('https://audio.geckoform.com/phone-error.mp3')
                };
            },

            play: function play(sound, loop) {
                if (_this.sounds[sound]) {
                    _this.sounds[sound].loop = loop;
                    _this.sounds[sound].play();
                }
            },

            stop: function stop(sound) {
                if (_this.sounds[sound]) {
                    _this.sounds[sound].stop();
                }
            },

            toggle: function toggle(sound, play) {
                if (_this.sounds[sound]) {
                    if (play) {
                        _this.play(sound, true);
                    } else {
                        _this.stop(sound);
                    }
                }
            },

            stopAll: function stopAll() {
                for (var sound in _this.sounds) {
                    _this.sounds[sound].stop();
                }
            }

        };

        // Refresh sounds (on login)
        Gecko.on('available', _this.init);
        // Refresh sounds (on logout)
        Gecko.on('unavailable', _this.init);

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoSounds', $geckoSounds);
})();