/* global Gecko GeckoUI */
(function () {
    'use strict';

    function geckoCallCompleteCard($geckoCall, $geckoVoip, $timeout, asyncOptions) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-complete-card.html',

            require: '^geckoCallPane',

            link: function link(scope, iElement, iAttrs, ctrl) {

                // Make sure if the call pane gets this far, the call has been disconnected
                // If not prompt a dialog asking it the connection should be destroyed
                if ($geckoVoip.connectionExists()) {
                    $timeout(function () {
                        GeckoUI.dialog.confirm('Looks like something went wrong. Do you want to hangup the current call?', function (ok) {
                            if (ok) return $geckoVoip.destroy();
                        });
                    }, 500);
                }

                // Render call length
                ctrl.getCallLength = function () {
                    return $geckoCall.call.call_time;
                };

                // Set rescheduled at time
                if (ctrl.campaign) {
                    var getRescheduleAtTime = function getRescheduleAtTime() {
                        if (ctrl.campaign && ctrl.campaign.dnc_period) {
                            return Math.floor(new Date(Number(new Date()) + ctrl.campaign.dnc_period * 1000).getTime() / 1000);
                        }
                        return null;
                    };
                    ctrl.result.call_at = getRescheduleAtTime();
                }

                // Outcomes setup
                var outcomes,
                    outcomeTypes,
                    outcomeActions,
                    outcomeField = {
                    label: 'Outcome',
                    key: 'outcome',
                    optionsKey: 'id',
                    optionsLabelKey: 'name',
                    type: Gecko.Field.TYPE_SELECT,
                    colClass: 'none',
                    required: true
                };

                if (ctrl.campaign && ctrl.campaign.related_outcomes) {
                    // Outcome dropdown field
                    outcomeField.options = $geckoCall.prepareOutcomes(ctrl.campaign.related_outcomes, $geckoCall.call.type, true, ctrl.campaign.ignore_consents);
                } else {
                    // Outcome types
                    outcomeTypes = [$geckoCall.call.type, Gecko.Outcome.CALL_TYPE_BOTH];
                    // Outcome actions
                    outcomeActions = [Gecko.Call.ACTION_COMPLETE, Gecko.Call.ACTION_CALLBACK, Gecko.Call.ACTION_WAIT, Gecko.Call.ACTION_DO_NOT_CALL];

                    // Only include DO NOT CALL if consents is enabled
                    //if (Gecko.able(Gecko.ABILITY_CONSENTS_VIEW)) outcomeActions.push(Gecko.Call.ACTION_DO_NOT_CALL);

                    outcomes = new Gecko.Outcome().where('group', Gecko.Outcome.GROUP_GLOBAL).where('call_type', outcomeTypes).where('action', outcomeActions).orderBy('name').perPage('all');
                    // Outcome dropdown field
                    outcomeField.getOptions = asyncOptions.create(outcomes.orderBy('name').rfields({ outcome: ['name', 'action'] }), null, {});
                }

                // Card fields
                ctrl.outcomeCardFields = [outcomeField, {
                    label: 'Reschedule for',
                    key: 'call_at',
                    required: true,
                    type: Gecko.Field.TYPE_DATETIME,
                    colClass: 'none',
                    hideWhen: function hideWhen() {
                        return GeckoUI.gobk(ctrl.outcomes, 'id', ctrl.result.outcome).action != Gecko.Outcome.ACTION_RESCHEDULE;
                    }
                }, {
                    label: 'Remove call consent',
                    key: 'do_not_call',
                    type: Gecko.Field.TYPE_TOGGLE,
                    colClass: 'none',
                    hideWhen: function hideWhen() {
                        return GeckoUI.gobk(ctrl.outcomes, 'id', ctrl.result.outcome).action == Gecko.Outcome.ACTION_RESCHEDULE; // !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || (ctrl.campaign && ctrl.campaign.ignore_consents) || GeckoUI.gobk(ctrl.outcomes, 'id', ctrl.result.outcome).action == Gecko.Outcome.ACTION_RESCHEDULE;
                    },
                    disabledWhen: function disabledWhen() {
                        return GeckoUI.gobk(ctrl.outcomes, 'id', ctrl.result.outcome).action == Gecko.Call.ACTION_DO_NOT_CALL;
                    },
                    checkedWhen: function checkedWhen() {
                        return GeckoUI.gobk(ctrl.outcomes, 'id', ctrl.result.outcome).action == Gecko.Call.ACTION_DO_NOT_CALL;
                    }
                }, {
                    label: 'Note',
                    key: 'note',
                    type: Gecko.Field.TYPE_TEXTAREA,
                    colClass: 'none'
                }];

                // Card buttons
                ctrl.outcomeCardBtns = [{
                    safeMode: true,
                    preset: 'check',
                    title: 'Complete Call',
                    action: ctrl.completeCall,
                    hideWhen: function hideWhen() {
                        return $geckoCall.isReconnecting();
                    }
                }, {
                    safeMode: true,
                    position: 'secondary',
                    btnClass: 'btn-danger',
                    icon: 'fa-times',
                    title: 'Abort',
                    action: ctrl.abortCall,
                    hideWhen: function hideWhen() {
                        return $geckoCall.isReconnecting();
                    }
                }];

                // Reconnect call
                ctrl.reconnect = $geckoCall.reconnectVoip;
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallCompleteCard', geckoCallCompleteCard);
})();