(function () {

    'use strict';

    function SessionRoute($stateProvider) {
        $stateProvider.state('session', {
            url: '/events/{event_id}/session/{session_id}?{sync_status}&{status}',
            templateUrl: "/modules/event/views/session.9aed0db7.html",
            controller: 'SessionCtrl',
            controllerAs: 'ctrl',
            page_title: 'Session',
            resolve: {
                event: function event(Gecko, $stateParams) {
                    return new Gecko.Event().rfields({
                        event: ['start_datetime', 'end_datetime', 'start_date', 'end_date', 'timezone', 'nice_title', 'available_tags', 'maximum_guests', 'delivery_method']
                    }).include('workflows:all').includeCount('attendances').get($stateParams.event_id);
                },
                sessionRfields: function sessionRfields() {
                    return ['tags', 'title', 'type', 'start_datetime', 'end_datetime', 'full', 'maximum', 'auto_waitlist', 'cost', 'location', 'total_attendances', 'parent_id', 'checkin_start', 'self_checkin'];
                },
                session: function session(Gecko, $stateParams, sessionRfields) {
                    if ($stateParams.session_id != 'new') {
                        return new Gecko.Event().include('children:all').includeCount('children.attendances').rfields({ event: sessionRfields }).get($stateParams.session_id);
                    } else {
                        return new Gecko.Event().create({
                            parent_id: $stateParams.event_id
                        });
                    }
                },
                data: function data(Gecko) {
                    var _query = {};
                    // Hosts
                    if (Gecko.able(Gecko.ABILITY_HOSTS_VIEW)) _query.hosts = new Gecko.Host().rfields({ host: ['name', 'email'] }).orderBy('name').perPage('all');
                    // Locations
                    if (Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW)) _query.locations = new Gecko.Location().rfields({ location: ['title'] }).orderBy('title').perPage('all');

                    return Gecko.multi(_query);
                },
                locations: function locations(data) {
                    return data.locations ? data.locations.toArray() : [];
                },
                hosts: function hosts(data) {
                    return data.hosts ? data.hosts.toArray() : [];
                },
                integrations: function integrations(Gecko) {
                    return false;
                }
            }
        }).state('session.overview', {
            url: '/overview?{page}&{session}&{search}&{order}&{sort}&{act_page}&{include_guests}&{from}&{to}',
            templateUrl: '/modules/event/views/session_overview.ef802fea.html',
            controller: 'EventOverviewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Session Overview',
            requires: [Gecko.ABILITY_EVENTS_VIEW],
            resolve: {
                attendancesRfields: function attendancesRfields() {
                    return {
                        attendance: ['sync_status', 'created_at', 'status', 'contact_id', 'rsvp', 'event_id', 'guest_count'],
                        contact: ['full_name', 'qr_url', 'email'],
                        event: ['title']
                    };
                },
                attendancesQuery: function attendancesQuery(Gecko, $stateParams, attendancesRfields) {
                    return function () {
                        var attendances = new Gecko.Attendance().include('contact', 'event');
                        var order = $stateParams.order !== undefined ? $stateParams.order : 'created_at',
                            sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC';

                        if ($stateParams.status) attendances.where('attendance_status', $stateParams.status);

                        if ($stateParams.sync_status) {
                            attendances.where('attendance_sync_status', $stateParams.sync_status);
                        }

                        if ($stateParams.session) {
                            attendances.where('event_id', $stateParams.session);
                        } else {
                            attendances.where('parent_event_id', $stateParams.session_id);
                        }

                        if ($stateParams.search) attendances.search($stateParams.search);

                        // Date Filtering
                        attendances.dates($stateParams.from, $stateParams.to);

                        return attendances.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page).rfields(attendancesRfields);
                    }.bind(this);
                },
                statConfig: function statConfig($stateParams) {
                    var statObj = {
                        filter: { event_id: $stateParams.session || $stateParams.session_id }
                        // Include guests by default
                    };if ($stateParams.include_guests == undefined || $stateParams.include_guests == 'true') {
                        statObj.type = { model: 'attendance', sum: 'attendances_count', group_by: 'status' };
                        return statObj;
                    } else {
                        statObj.type = { model: 'attendance', count: '*', group_by: 'status' };
                        return statObj;
                    }
                },
                stat: function stat(data) {
                    return data.stat;
                },
                data: function data(Gecko, $stateParams, attendancesQuery, statConfig) {
                    var _query = {};

                    // Stats
                    _query.stat = new Gecko.Stat().multiConfig({
                        method: 'POST',
                        model: statConfig.type.model,
                        sum: statConfig.type.sum,
                        count: statConfig.type.count,
                        group_by: statConfig.type.group_by,
                        filter: statConfig.filter,
                        no_cache: 1
                    }).path('generate');

                    // Attendances
                    _query.attendances = attendancesQuery();

                    // Sessions
                    _query.sessions = new Gecko.Event().rfields({ event: ['title'] }).where('parent_id', $stateParams.session_id).perPage('all');

                    return Gecko.multi(_query);
                },
                attendances: function attendances(data) {
                    return data.attendances;
                },
                history: function history(Gecko, $stateParams) {
                    return [];
                    // Var pagination_act = {
                    //     Per_page : Gecko.DEFAULT_PER_PAGE,
                    //     Page     : $stateParams.act_page || 1,
                    // };

                    // Return new Gecko.MixedObjects().call('/events/' + $stateParams.event_id + '/history', 'GET', pagination_act, true);
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(SessionRoute);
})();