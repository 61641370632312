(function () {
    'use strict';

    function CampaignsRoute($stateProvider) {
        $stateProvider.state('campaigns', {
            url: "/campaigns/{module:call|sms}?{page}&{search}&{type}&{status}&{order}&{sort}",
            templateUrl: "/modules/campaigns/views/index.20bbae04.html",
            controller: 'CampaignsListCtrl',
            controllerAs: 'ctrl',
            page_title: 'Campaigns',
            requires: function requires($stateParams) {
                return [Gecko.ABILITY_CALL_CAMPAIGNS_VIEW];
            },
            resolve: {
                camapignsQuery: function camapignsQuery($stateParams, Gecko) {
                    var campaigns = new Gecko.Campaign().include('user', 'users', 'form').includeCount('subscribers', 'subscribers_scheduled_due').where('module', $stateParams.module);

                    if ($stateParams.status === 'current') {
                        if ($stateParams.module === Gecko.Campaign.MODULE_SMS) {
                            var status = [Gecko.Campaign.PENDING, Gecko.Campaign.ACTIVE, Gecko.Campaign.PAUSED, Gecko.Campaign.INVALID];
                        } else {
                            var status = [Gecko.Campaign.PENDING, Gecko.Campaign.ACTIVE, Gecko.Campaign.INVALID];
                        }
                    } else {
                        var status = $stateParams.status;
                    }

                    campaigns.where('campaign_status', status);

                    if ($stateParams.search) campaigns.where('campaign_keyword', $stateParams.search);

                    if ($stateParams.order) campaigns.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');

                    return campaigns.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ campaign: ['status', 'created_at', 'title', 'start_date', 'end_date', 'form_id'], user: ['full_name', 'color'], form: ['name', 'internal_name'] });
                },
                data: function data($stateParams, Gecko, camapignsQuery) {
                    var _query = {};

                    // Campaigns
                    _query.campaigns = camapignsQuery;

                    return Gecko.multi(_query);
                },
                campaigns: function campaigns(data) {
                    return data.campaigns;
                }
            }
        });

        var campaignCreateUpdateRequires = function campaignCreateUpdateRequires($stateParams) {
            if ($stateParams.campaign_id === 'new') {
                return [Gecko.ABILITY_CALL_CAMPAIGNS_CREATE];
            }
            return [Gecko.ABILITY_CALL_CAMPAIGNS_UPDATE];
        };

        var campaignUpdateRequires = function campaignUpdateRequires($stateParams) {
            return [Gecko.ABILITY_CALL_CAMPAIGNS_UPDATE];
        };

        $stateProvider.state('campaignsedit', {
            url: "/campaigns/{module:call|sms}/edit/{campaign_id}",
            templateUrl: '/modules/campaigns/views/edit.ef6c54ca.html',
            controller: 'CampaignsEditCtrl',
            controllerAs: 'ctrl',
            page_title: 'Campaign Edit',
            requires: campaignCreateUpdateRequires,
            resolve: {
                campaign: function campaign($stateParams) {
                    if ($stateParams.campaign_id != 'new') {
                        return new Gecko.Campaign().include('outcomes:all', 'campaign_fields:all', 'campaign_fields.field', 'users:all', 'consents:all', 'filter').rfields({
                            consent: ['title'],
                            outcome: ['name', 'group'],
                            field: ['label', 'system', 'is_calculated'],
                            user: ['full_name', 'color'],
                            filter: ['refreshed_at']
                        }).get($stateParams.campaign_id);
                    } else {
                        return new Gecko.Campaign().create({
                            call_sender_id: Gecko.account.call_sender_id,
                            sms_sender_id: Gecko.account.sms_sender_id,
                            sort_field_id: Gecko.account.contact_sort_field_id,
                            sort_direction: angular.lowercase(Gecko.account.contact_sort_direction),
                            module: $stateParams.module
                        });
                    }
                }
            }
        }).state('campaignsedit.view', {
            url: "/view?{page}&{page_scheduled}&{status}&{order}&{sort}&{search}",
            templateUrl: "/modules/campaigns/views/view.32e3f66a.html",
            controllerAs: 'ctrl',
            page_title: 'View Campaign',
            requires: function requires($stateParams) {
                return [Gecko.ABILITY_CALL_CAMPAIGNS_VIEW];
            },
            controller: 'CampaignCtrl',
            resolve: {
                subscribersQuery: function subscribersQuery($stateParams) {
                    var order = $stateParams.order !== undefined ? $stateParams.order : 'sort_value',
                        sort = $stateParams.sort !== undefined ? $stateParams.sort : null;

                    var subscribers = new Gecko.Subscriber().include('contact', 'campaign').rfields({ subscriber: ['status', 'field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6', 'contact_id', 'call_at'] }).where('campaign_id', $stateParams.campaign_id);
                    // Limit statuses for agents
                    if (Gecko.User.isGroup(Gecko.Group.AGENT) && $stateParams.status === "all") {
                        subscribers.where('status', [Gecko.Subscriber.AVAILABLE, Gecko.Subscriber.IN_PROGRESS, Gecko.Subscriber.SCHEDULED]);
                    }
                    if ($stateParams.status !== "all") subscribers.where('status', $stateParams.status !== undefined ? $stateParams.status : Gecko.Subscriber.AVAILABLE);
                    if ($stateParams.search) subscribers.search($stateParams.search);
                    return subscribers.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1);
                },
                scheduledSubscribersQuery: function scheduledSubscribersQuery($stateParams) {
                    var order = $stateParams.order !== undefined ? $stateParams.order : 'call_at',
                        sort = $stateParams.sort !== undefined ? $stateParams.sort : null;

                    if (!$stateParams.status || Gecko.Subscriber.AVAILABLE == $stateParams.status || Gecko.Subscriber.PENDING == $stateParams.status) {
                        var scheduledSubscribers = new Gecko.Subscriber().include('contact', 'campaign').rfields({ subscriber: ['status', 'field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6', 'contact_id', 'call_at'] }).where({ 'campaign_id': $stateParams.campaign_id, 'status': Gecko.Subscriber.SCHEDULED });
                        return scheduledSubscribers.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page_scheduled ? $stateParams.page_scheduled : 1);
                    }
                    return false;
                },
                data: function data($stateParams, subscribersQuery, scheduledSubscribersQuery) {
                    var _query = {};

                    // Campaign
                    _query.campaign = new Gecko.Campaign().include('campaign_fields:300', 'campaign_fields.field', 'user_segments').path($stateParams.campaign_id);

                    // Subscriber
                    _query.subscribers = subscribersQuery;

                    //Scheduled Subscriber
                    if (scheduledSubscribersQuery) _query.scheduledSubscribers = scheduledSubscribersQuery;

                    // Stats (cant be used because of the segment stuff for now)
                    var statConfig = {
                        method: 'POST',
                        model: 'subscriber',
                        count: '*',
                        group_by: 'status',
                        filter: { campaign_id: $stateParams.campaign_id },
                        no_cache: 1
                    };
                    if ($stateParams.module === 'call') {
                        statConfig.filter.filter = '{user_segments_cid_' + $stateParams.campaign_id + '}';
                    } // only filter by segments if call campaign
                    _query.stat = new Gecko.Stat().multiConfig(statConfig).path('generate');

                    return Gecko.multi(_query);
                },
                campaign: function campaign(data) {
                    return data.campaign;
                },
                subscribers: function subscribers(data) {
                    return data.subscribers;
                },
                scheduledSubscribers: function scheduledSubscribers(data) {
                    return data.scheduledSubscribers ? data.scheduledSubscribers : false;
                },
                stat: function stat(data) {
                    return data.stat;
                }
            }
        }).state('campaignsedit.settings', {
            url: "/settings",
            templateUrl: '/modules/campaigns/views/settings_campaigns.e791c335.html',
            controllerAs: 'ctrl',
            page_title: 'Campaign Settings',
            controller: 'CampaignsSettingsCtrl',
            requires: campaignCreateUpdateRequires,
            resolve: {
                data: function data() {

                    var _query = {};

                    // Fields
                    _query.fields = new Gecko.Field().rfields({ field: ['label', 'system', 'is_calculated'] }).where('field_type', 'contact').perPage('all');

                    // Outcomes
                    _query.outcomes = new Gecko.Outcome().rfields({ outcome: ['name', 'group', 'action'] }).where('group', [Gecko.Outcome.GROUP_SYSTEM, Gecko.Outcome.GROUP_GLOBAL, Gecko.Outcome.GROUP_CAMPAIGN]).perPage('all');

                    // Users
                    _query.users = new Gecko.User().rfields({ user: ['full_name'] }).perPage('all');

                    // Consents
                    if (Gecko.able(Gecko.ABILITY_CONSENTS_VIEW)) _query.consents = new Gecko.Consent().rfields({ consent: ['title', 'group_call', 'group_general'] }).orderBy('title').perPage('all');

                    return Gecko.multi(_query);
                },
                fields: function fields(data) {
                    return data.fields;
                },
                outcomes: function outcomes(data) {
                    return data.outcomes;
                },
                consents: function consents(data) {
                    return data.consents;
                },
                users: function users(data) {
                    return data.users;
                },
                selectedData: function selectedData(asyncOptions, campaign) {
                    return asyncOptions.getSelectedValues({
                        forms: { model: 'Form', id: campaign.form_id, fields: { form: ['name', 'internal_name'] } },
                        fields: { model: 'Field', id: campaign.sort_field_id, fields: { field: ['label'] } },
                        templates: { model: 'Template', id: campaign.template_id, fields: { template: ['name'] } },
                        senders: { model: 'Sender', id: campaign.call_sender_id, fields: { sender: ['name', 'tel'] } },
                        sms_senders: { model: 'Sender', id: campaign.sms_sender_id, fields: { sender: ['name', 'tel'] } },
                        tel_fields: { model: 'Field', id: campaign.contact_field_id, fields: { field: ['label'] } }
                    });
                }
            }
        }).state('campaignsedit.filter', {
            url: "/filter?{page}&{status}&{order}&{sort}",
            templateUrl: '/modules/campaigns/views/filter.e221c14e.html',
            controller: 'CampaignsFilterCtrl',
            controllerAs: 'ctrl',
            page_title: 'Select Contacts',
            requires: campaignUpdateRequires,
            resolve: {
                campaign: function campaign($stateParams) {
                    return new Gecko.Campaign().include('filter.conditions').get($stateParams.campaign_id);
                },
                fieldList: function fieldList(geckoDataService) {
                    return geckoDataService.fetch(['field_list_contacts']).then(function (data) {
                        return data.field_list_contacts;
                    });
                },
                contactFields: function contactFields(geckoDataService) {
                    return geckoDataService.fetch(['contact_fields']).then(function (data) {
                        return data.contact_fields;
                    });
                },
                contactsRfields: function contactsRfields() {
                    return {
                        contact: ['field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6']
                    };
                },
                contacts: function contacts($stateParams, contactsRfields, campaign, contactFields, fieldList, getContactsService) {
                    var conditions = campaign.related_filter && campaign.related_filter.related_conditions ? Gecko.convertInstancesToObjects(campaign.related_filter.related_conditions) : [];
                    var requirement = campaign.related_filter && campaign.related_filter.requirement ? campaign.related_filter.requirement : 'ANY';
                    var order = $stateParams.order !== undefined ? $stateParams.order : 'field_1';
                    var sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC';

                    var page = $stateParams.page > 0 ? $stateParams.page : 1;
                    var perPage = GeckoUI.getPageLimit(this.self.name);

                    // Avoid loading all contacts for no reason
                    if (!conditions || !conditions.length) {
                        return Promise.resolve({
                            data: [],
                            pagination: {}
                        });
                    }

                    var forceEngageSource = Gecko.has(Gecko.Package.FEATURE_NEW_SELECT_CONTACTS) === false;
                    return getContactsService.getContacts({
                        order: order,
                        sort: sort,
                        page: page,
                        perPage: perPage,
                        // filterId: null,
                        conditions: conditions,
                        // search: null,
                        conditionOperator: requirement,
                        includes: [],
                        rFields: contactsRfields,
                        number: $stateParams.number,
                        // searchLabels: null,
                        // labelOperator: null,
                        contactFields: contactFields,
                        fieldList: fieldList
                        // labelList: null
                    }, forceEngageSource);
                }
            }
        }).state('campaignsedit.segments', {
            url: '/segments',
            templateUrl: "/modules/campaigns/views/segments.2ae61ce4.html",
            controller: 'CampaignsSegmentsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Campaign Segments',
            requires: campaignUpdateRequires,
            resolve: {
                campaign: function campaign($stateParams) {
                    return new Gecko.Campaign().include('filter.children:all.conditions:all', 'filter.children.users:all', 'filter.conditions', 'filter.contacts', 'users:all').get($stateParams.campaign_id);
                },
                multiData: function multiData(geckoDataService) {
                    return geckoDataService.fetch(['contact_fields', 'imports', 'labels', 'filters', 'forms', 'users', 'outcomes', 'campaigns', 'senders']);
                }
            }
        }).state('campaignsedit.workflows', {
            url: "/workflows",
            templateUrl: "/modules/campaigns/views/workflows.76384381.html",
            controller: 'CampaignsWorkflowsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Campaign Workflows',
            requires: campaignUpdateRequires,
            resolve: {
                campaign: function campaign($stateParams) {
                    return new Gecko.Campaign().include('outcomes:all', 'workflows:all').get($stateParams.campaign_id);
                },
                multiData: function multiData(geckoDataService) {
                    return geckoDataService.fetch(['fields', 'campaigns', 'consents', 'labels', 'users', 'senders', 'templates', 'integrations', 'outcomes', 'tasks']);
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(CampaignsRoute);
})();