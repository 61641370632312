;(function () {
    'use strict';

    var prepareRedirectToReactAppUrl = function prepareRedirectToReactAppUrl(url, params) {
        return Object.keys(params).reduce(function (result, param) {
            return result.replace('{' + param + '}', params[param]);
        }, url);
    };

    function redirectToReactApp() {
        return {
            check: function check(redirectKey) {
                try {
                    return window.appConfig[redirectKey].app === 'react';
                } catch (err) {}

                return false;
            },
            url: function url(redirectKey, params) {
                try {
                    var redirectMap = window.appConfig[redirectKey];
                    return prepareRedirectToReactAppUrl(redirectMap[redirectMap.app], params);
                } catch (err) {}
                return false;
            }
        };
    }

    angular.module('GeckoEngage').factory('redirectToReactApp', redirectToReactApp);
})();