(function () {
    'use strict';

    function VisitAppTestRoute($stateProvider) {
        $stateProvider.state('visit-app-test', {
            url: "/visit-app-test?{event_id}",
            templateUrl: "/modules/visit-app-test/index.6ca36552.html",
            controller: 'VisitAppTestCtrl',
            controllerAs: 'ctrl',
            page_title: 'Visit App Test',
            requires: [Gecko.ABILITY_SUPER]
        });
    }

    angular.module('GeckoEngage.routes').config(VisitAppTestRoute);
})();