(function () {
    'use strict';

    function UserSettingsCtrl($scope, Gecko, $filter, $stateParams, $state, LoadingIndicator, asyncOptions, unsavedAlert, user, selectedData) {
        var ctrl = this;
        ctrl.user = user;
        ctrl.security_user = angular.copy(user);
        ctrl.user.call_method = ctrl.user.call_method || Gecko.CALL_METHOD_NONE;
        ctrl.security = {};
        var currentCallMethod = ctrl.user.call_method;

        ctrl.fields = [{
            label: 'Personal',
            type: Gecko.Field.TYPE_TITLE
        }, {
            label: 'Full name',
            id: 'full_name',
            colClass: 'col-xs-12',
            description: 'Identifies the user against their actions in the system, e.g. forms created, responses captured, etc.',
            required: true
        }, {
            label: 'Email',
            id: 'email',
            break: true,
            required: true,
            description: 'Used to log into the system.',
            disabledWhen: function disabledWhen() {
                return Gecko.useNewAuth() && $stateParams.user_id != '0';
            }
        }, {
            label: 'Mobile Telephone',
            id: 'telephone',
            type: Gecko.Field.TYPE_TEL,
            description: 'Can be used for testing SMS campaigns.'
        }, {
            label: 'Colour',
            id: 'color',
            type: Gecko.Field.TYPE_COLOR,
            description: 'Choose a background colour for this user\'s avatar.'
        }, {
            label: 'Receive Daily Summary Email',
            id: 'receive_digest',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: 'daily',
            falseValue: null,
            description: 'Opt to receive an email each morning summarising account activity over the previous 24 hours.'
        }, {
            label: 'Organisation',
            type: Gecko.Field.TYPE_TITLE
        }, {
            label: 'User group',
            id: 'group_id',
            required: true,
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            options: asyncOptions.pick(selectedData, 'group'),
            getOptions: asyncOptions.create(new Gecko.Group().orderBy('name').rfields({ group: ['name', 'type'] }), function () {
                var isTypeAdmin = function isTypeAdmin(option) {
                    return option.type !== Gecko.Group.ADMIN;
                };
                var isCallAgent = function isCallAgent(option) {
                    return option.name !== 'Call Agents';
                };

                if (!Gecko.User.isGroup([Gecko.Group.ADMIN, Gecko.Group.SUPER])) {
                    this.options = this.options.filter(isTypeAdmin);
                }
                if (!Gecko.has(Gecko.Package.FEATURE_CALL)) {
                    this.options = this.options.filter(isCallAgent);
                }
            }),
            description: 'Select which user group this user can access. Click <a ui-sref="usersgroups">here</a> to view/ edit user groups.'
        }, {
            label: 'Position',
            id: 'position',
            description: 'e.g. Recruitment Officer. This value can be included in emails by using <a href="https://academy.geckoengage.com/en/articles/6715486-template-tags#h_eae63f1a21" title="Email/SMS Template Tags">template tags</a>.',
            placeholder: '-'
        }, {
            label: 'Gecko Call',
            type: Gecko.Field.TYPE_TITLE,
            hideWhen: function hideWhen() {
                return !Gecko.User.hasOutboundOrInbound();
            }
        }, {
            label: 'Enable VoIP',
            id: 'call_method',
            colClass: 'col-xs-6',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                return !Gecko.User.hasOutboundOrInbound();
            },
            trueValue: Gecko.CALL_METHOD_VOIP,
            falseValue: Gecko.CALL_METHOD_NONE,
            description: 'Enables the user to make/receive calls using GeckoTalk.'
        }, {
            type: Gecko.Field.TYPE_TOGGLE,
            key: 'record_calls',
            label: 'Record Calls',
            colClass: 'col-xs-6',
            description: 'Toggles whether calls made by this user are automatically recorded. Note: this may be overridden by <a ui-sref="campaigns">Call Campaign</a> or <a ui-sref="voipnumbers">VoIP Number</a> settings.',
            hideWhen: function hideWhen() {
                return !Gecko.User.hasOutboundOrInbound() || !Gecko.User.isGroup([Gecko.Group.ADMIN, Gecko.Group.SUPER]) || ctrl.user.call_method == Gecko.CALL_METHOD_NONE;
            }
        }, {
            label: 'Default caller id',
            id: 'call_sender_id',
            type: Gecko.Field.TYPE_SELECT,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            options: asyncOptions.pick(selectedData, 'sender'),
            getOptions: asyncOptions.create(new Gecko.Sender().where('outgoing_voice', 1).orderBy('name').rfields({ sender: ['name', 'type', 'tel'] }), null, { search: 'keywordSearch' }),
            choiceTemplate: 'sender-choice',
            matchTemplate: 'sender-match',
            orderBy: 'name',
            colClass: 'col-xs-6',
            description: 'The default phone number this user will make calls from. To view available VoIP numbers click <a ui-sref="voipnumbers">here</a>.',
            hideWhen: function hideWhen() {
                return !Gecko.User.hasOutbound() || !Gecko.User.isGroup([Gecko.Group.ADMIN, Gecko.Group.SUPER]) || !ctrl.user.call_method || ctrl.user.call_method == Gecko.CALL_METHOD_NONE;
            },
            optionsGroup: function optionsGroup(item) {
                return item && item.type && item.type == Gecko.Sender.GECKOCHAT ? "GeckoChat" : undefined;
            }
        }];

        ctrl.showSecurity = function () {
            return Gecko.user && Gecko.user.id == ctrl.user.id && !Gecko.useNewAuth();
        };

        ctrl.securityFields = [{
            label: 'Password',
            id: 'password',
            type: Gecko.Field.TYPE_PASSWORD,
            colClass: 'col-xs-6',
            description: 'Must be at least 6 characters long and should contain a mixture of symbols.',
            hideWhen: function hideWhen() {
                return Gecko.user && Gecko.user.id != ctrl.user.id;
            }
        }, {
            label: 'Password confirmation',
            id: 'password_confirmation',
            type: Gecko.Field.TYPE_PASSWORD,
            colClass: 'col-xs-6',
            description: "Please re-enter your password.",
            hideWhen: function hideWhen() {
                return Gecko.user && Gecko.user.id != ctrl.user.id;
            }
        }];

        ctrl.securityBtns = [{
            title: 'Update password',
            icon: 'fa-key',
            action: function action() {
                return ctrl.updatePassword();
            }
        }];

        ctrl.updatePassword = function () {
            if (ctrl.security.password && ctrl.security.password != ctrl.security.password_confirmation) {
                GeckoUI.messenger.error('Your password confirmation does not match');
                return;
            }
            ctrl.security_user.password = ctrl.security.password;
            var savePromise = ctrl.security_user.save();
            LoadingIndicator.resolve(savePromise).then(function (user) {
                GeckoUI.messenger.success('Your password has been updated');
                ctrl.security = {};
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        };

        ctrl.saveUser = function () {

            if (ctrl.user.id) {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_USERS_UPDATE)) return;
            } else {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_USERS_CREATE)) return;
            }

            if (ctrl.user.telephone && typeof ctrl.user.telephone != 'string') {
                ctrl.user.telephone = ctrl.user.telephone.cleaned ? ctrl.user.telephone.cleaned : typeof ctrl.user.telephone.tel == 'string' ? ctrl.user.telephone.tel : null;
            }

            // Ensure that receive_digest is nulled if a falsey value is selected
            if (!ctrl.user.receive_digest) ctrl.user.receive_digest = null;

            var savePromise = ctrl.user.include('abilities', 'group.permissions').save();

            LoadingIndicator.resolve(savePromise).then(function (user) {

                GeckoUI.messenger.success('Your changes have been saved');
                unsavedAlert.clear();
                if ($stateParams.user_id == '0') {
                    // If this is a new user then go to their user page
                    return $state.go('user', { user_id: user.id });
                }
                // Detect changes in VOIP settings if the user has updated their own settings.
                if (user.id === Gecko.user.id) {
                    // Clear app data cache (as user data has changed)
                    Gecko.Token.appData.clearCache();
                    // Assign updated user. May want to do a `Gecko.user.refresh()` but currently figure it's better to avoid the second request
                    Gecko.user = user;
                    // Reload to init Twilio
                    if (currentCallMethod != user.call_method) window.location.reload();
                }

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('User Save Success', {
                    'user id': user.id,
                    'user title': user.full_name
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('User Save Error', {
                    'user id': ctrl.user.id || 'New',
                    'user name': ctrl.user.full_name,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        $scope.$on('saveUser', ctrl.saveUser);
    }

    angular.module('GeckoEngage').controller('UserSettingsCtrl', UserSettingsCtrl);
})();