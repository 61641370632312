(function () {
    'use strict';

    function geckoCallStartDialCard($geckoCall, $timeout) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-start-dial-card.html',

            require: '^geckoCallPanePrepare',

            link: function link(scope, iElement, iAttrs, ctrl) {

                // Init with number string
                if ($geckoCall.numberString) {
                    ctrl.number = angular.copy($geckoCall.numberString);
                    $geckoCall.numberString = null;
                }

                // Card fields
                ctrl.startDialCardFields = [{
                    colClass: 'none',
                    label: 'Number',
                    id: 'number',
                    type: Gecko.Field.TYPE_TEL
                }];

                // Format string to object on number init
                var refreshInput = function refreshInput() {
                    if (typeof ctrl.number == 'string') {
                        $timeout(function () {
                            return $(iElement).find('#field_number').keyup();
                        });
                    }
                };
                refreshInput();
                scope.$watch(refreshInput);

                ctrl.startCall = function () {
                    // Check for right parts of number
                    if (!ctrl.number || !ctrl.number.original || !ctrl.number.country_code) return false;

                    ctrl.doNotCall = false;
                    // Show loading indicator
                    $geckoCall.loadingIndicator(true);

                    // Create (or get number)
                    Gecko.Number.check(ctrl.number).then(function (number) {
                        // Start call
                        $geckoCall.dial(number).then(function (call) {
                            // Reset call preparing
                            $geckoCall.reset();
                            // Open call pane
                            $geckoCall.restore(call);
                            $geckoCall.activate();
                            // Connect to VOIP
                            $geckoCall.startVoip();
                            // Hide loading indicator
                            $geckoCall.loadingIndicator(false);
                            // Fire Intercom Event
                            GeckoUI.triggerIntercomEvent('Call Started Success: Dialed Number', {
                                'call id': call.id,
                                'call number': number.cleaned || number.original
                            });
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                            // Hide loading indicator
                            $geckoCall.loadingIndicator(false);
                            if (Array.isArray(err.errors) && err.errors.indexOf('The requested number has been marked as do not call.') !== -1) {
                                ctrl.doNotCall = true;
                            }
                            // Fire Intercom Event
                            GeckoUI.triggerIntercomEvent('Call Started Error: Dialed Number', {
                                'call number': number.cleaned || number.original,
                                'error': GeckoUI.renderError(err.errors)
                            });
                        });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Call Started Error: Dialed Number', {
                            'call number': ctrl.number.cleaned || ctrl.number.original,
                            'error': GeckoUI.renderError(err.errors)
                        });
                    });
                };

                // Card buttons
                ctrl.startDialCardBtns = [{
                    safeMode: true,
                    preset: 'save',
                    title: 'Start Call',
                    icon: 'fa-phone',
                    action: ctrl.startCall
                }];
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallStartDialCard', geckoCallStartDialCard);
})();