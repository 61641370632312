(function () {
    'use strict';

    angular.module('GeckoEngage').directive('geckoCallIndentifyCard', geckoCallIndentifyCard).factory('geckoCallIdentifyCardLinkFn', geckoCallIdentifyCardLinkFn);

    function geckoCallIndentifyCard(geckoCallIdentifyCardLinkFn) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-identify-card.html',

            require: '^geckoCallPane',

            link: geckoCallIdentifyCardLinkFn
        };
    }

    function geckoCallIdentifyCardLinkFn($geckoCall, asyncOptions) {
        return function (scope, iElement, iAttrs, ctrl) {
            scope.Gecko = Gecko;

            var resetNewContactObj = function resetNewContactObj() {
                if (ctrl.contactNew) {
                    if (ctrl.contactNew.name) {
                        ctrl.contactNew.name.first_name = null;
                        ctrl.contactNew.name.last_name = null;
                    }
                    if (ctrl.contactNew.email) ctrl.contactNew.email = null;
                }
            };

            // Build /reset values function
            ctrl.setValues = function (contactId, method) {
                if (contactId || method) {
                    // Set contact id
                    ctrl.contactId = contactId;
                    // Reset other method values
                    if (method == 'create') {
                        ctrl.contactIdSuggested = null;
                        ctrl.contactNameSearched = null;
                        resetNewContactObj();
                    } else if (method == 'search') {
                        ctrl.contactIdSuggested = null;
                    } else if (method === 'none') {
                        ctrl.contactIdSuggested = null;
                        ctrl.contactNameSearched = null;
                        resetNewContactObj();
                    } else {
                        ctrl.contactNameSearched = null;
                        ctrl.contactIdMethod = null;
                        resetNewContactObj();
                    }
                } else {
                    ctrl.contactId = null;
                    ctrl.contactIdMethod = 'none';
                    ctrl.contactIdSuggested = null;
                    ctrl.contactNameSearched = null;
                    if (!ctrl.contactNew) ctrl.contactNew = {};
                    ctrl.campaignId = null;
                }
            };

            // Set inital values
            ctrl.setValues();

            ctrl.getContactIdentifier = function (contact) {
                if (contact.full_name && contact.email) {
                    return contact.full_name + ' (' + contact.email + ')';
                }
                if (contact.full_name && !contact.email) {
                    return contact.full_name;
                }
                return contact.email;
            };
            ctrl.isValidContact = function (contact) {
                return !!(contact.full_name || contact.email);
            };

            ctrl.normaliseContactOptions = function () {
                this.options = this.options.filter(ctrl.isValidContact).map(function (contact) {
                    return {
                        id: contact.id,
                        identifier: ctrl.getContactIdentifier(contact)
                    };
                });
            };

            // Card fields
            ctrl.identifyCardFields = [{
                id: 'contactIdMethod',
                options: [{
                    value: 'none',
                    label: 'No contact selected'
                }],
                optionsKey: 'value',
                optionsLabelKey: 'label',
                type: Gecko.Field.TYPE_RADIO,
                colClass: 'none search-create-contact',
                hideWhen: function hideWhen() {
                    return ctrl.contact;
                },
                action: function action() {
                    ctrl.setValues(null, ctrl.contactIdMethod);
                }
            }, {
                id: 'contactIdSuggested',
                options: ctrl.possibleContacts,
                optionsLabelKey: 'full_name',
                optionsKey: 'id',
                label: 'Suggested contacts',
                type: Gecko.Field.TYPE_RADIO,
                colClass: 'none suggested-list',
                hideWhen: function hideWhen() {
                    return Gecko.Number.isAnonymous(ctrl.number) || !ctrl.possibleContacts || !ctrl.possibleContacts.length || ctrl.contact;
                },
                action: function action() {
                    ctrl.setValues(ctrl.contactIdSuggested);
                }
            }, {
                id: 'contactIdMethod',
                options: [{
                    value: 'search',
                    label: 'Search for a contact'
                }, {
                    value: 'create',
                    label: 'Create a new contact'
                }],
                optionsKey: 'value',
                optionsLabelKey: 'label',
                type: Gecko.Field.TYPE_RADIO,
                colClass: 'none search-create-contact',
                hideWhen: function hideWhen() {
                    return ctrl.contact;
                },
                action: function action() {
                    ctrl.setValues(null, ctrl.contactIdMethod);
                }
            }, {
                colClass: 'none',
                label: 'Search contacts',
                id: 'contactNameSearched',
                optionsKey: 'id',
                optionsLabelKey: 'identifier',
                type: Gecko.Field.TYPE_SELECT,
                getOptions: asyncOptions.create(new Gecko.Contact().orderBy('full_name').rfields({ contact: ['full_name', 'email'] }).perPage(15), ctrl.normaliseContactOptions, { search: 'keywordSearch' }),
                hideWhen: function hideWhen() {
                    return ctrl.contactIdMethod != 'search' || ctrl.contact;
                }
            }, {
                id: 'name',
                obj: ctrl.contactNew,
                type: Gecko.Field.TYPE_NAME,
                label: 'Name',
                required: true,
                colClass: 'none',
                hideWhen: function hideWhen() {
                    return ctrl.contactIdMethod != 'create' || ctrl.contact;
                }
            }, {
                id: 'email',
                obj: ctrl.contactNew,
                label: 'Email',
                type: Gecko.Field.TYPE_EMAIL,
                colClass: 'none',
                required: true,
                hideWhen: function hideWhen() {
                    return ctrl.contactIdMethod != 'create' || ctrl.contact;
                }
            }, {
                id: 'campaignId',
                label: 'Suggested campaigns',
                type: Gecko.Field.TYPE_SELECT,
                colClass: 'none',
                options: ctrl.possibleCampaigns,
                optionsLabelKey: 'title',
                hideWhen: function hideWhen() {
                    return !$geckoCall.isInbound() || ctrl.possibleCampaigns && !ctrl.possibleCampaigns.length || ctrl.campaign;
                }
            }];

            // Check contact has number
            scope.$watch('ctrl.contactNameSearched', function (oldVal, newVal) {
                if (oldVal != newVal) ctrl.setValues(ctrl.contactNameSearched, ctrl.contactIdMethod);
            });

            // Card buttons
            ctrl.identifyCardBtns = [{
                safeMode: true,
                preset: 'save',
                action: function action() {

                    var hasContact;

                    // Set contact id
                    if (!ctrl.contact) {
                        if (ctrl.contactIdMethod == 'search' && ctrl.contactNameSearched) {
                            $geckoCall.call.contact_id = ctrl.contactId;
                            $geckoCall.call.new_contact = {};
                            hasContact = true;
                        } else if (ctrl.contactId) {
                            $geckoCall.call.contact_id = ctrl.contactId;
                            $geckoCall.call.new_contact = {};
                            hasContact = true;
                            // Set data to create new contact
                        } else if (ctrl.contactIdMethod == 'create' && ctrl.contactNew && ctrl.contactNew.name && ctrl.contactNew.email) {
                            $geckoCall.call.contact_id = null;
                            $geckoCall.call.new_contact = angular.copy(ctrl.contactNew);
                            hasContact = true;
                        } else {
                            $geckoCall.call.contact_id = null;
                            $geckoCall.call.new_contact = {};
                            hasContact = false;
                        }
                    } else {
                        hasContact = true;
                    }

                    // Check contact data before saving campaign
                    if (!hasContact) {
                        GeckoUI.messenger.error('Please provide contact information before saving.');
                        return false;
                    }

                    // Set campaign id
                    if (ctrl.campaignId) {
                        $geckoCall.call.campaign_id = ctrl.campaignId;
                    }

                    // Show loading indicator
                    $geckoCall.loadingIndicator(true);
                    // This can be used to refresh data in other cards (ngIf style)
                    ctrl.updatingCallInfo = true;

                    // Save call
                    $geckoCall.update().then(function (call) {
                        // Update call
                        $geckoCall.restore(call);

                        // Success message
                        GeckoUI.messenger.success('Call contact information updated.');
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        // This can be used to refresh data in other cards (ngIf style)
                        ctrl.updatingCallInfo = false;
                        scope.$digest();
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        // This can be used to refresh data in other cards (ngIf style)
                        ctrl.updatingCallInfo = false;
                    });
                }
            }];
        };
    }
})();