(function () {
    'use strict';

    function ErrorsRoutes($stateProvider) {

        $stateProvider.state('404', {
            url: '/404?{url}',
            templateUrl: '/modules/errors/404.1988e4c3.html',
            controller: '404Ctrl',
            controllerAs: 'ctrl'
        });
    }

    angular.module('GeckoEngage.routes').config(ErrorsRoutes);
})();