(function () {
    'use strict';

    function geckoCallSidebar() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-sidebar.html',
            transclude: true,
            scope: {
                paneTitle: '@',
                paneIcon: '@',
                paneClasses: '@',

                incoming: '=',
                outgoing: '=',

                new: '=',
                active: '=',
                ended: '=',
                completed: '=',

                expand: '=',
                collapse: '=',

                toggleOverwrite: '=',
                noCollapse: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($geckoCall, $geckoCallSidebar) {
                var ctrl = this;

                // Opening and closing transfer pane
                ctrl.isOpen = $geckoCallSidebar.isOpen;

                ctrl.toggle = function (reset) {
                    if (ctrl.toggleOverwrite) {
                        return ctrl.toggleOverwrite();
                    } else {
                        if (reset) {
                            return $geckoCall.reset(true);
                        }
                        return $geckoCallSidebar.toggle();
                    }
                };

                ctrl.renderClasses = function () {
                    var _classes = {
                        incoming: ctrl.incoming,
                        outgoing: ctrl.outgoing,
                        new: ctrl.new,
                        active: ctrl.active,
                        ended: ctrl.ended,
                        completed: ctrl.completed
                    };
                    // Add custom classes
                    if (ctrl.paneClasses) _classes[ctrl.paneClasses] = true;

                    return _classes;
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallSidebar', geckoCallSidebar);
})();