(function () {
    'use strict';

    function geckoDateRange() {

        return {
            restrict: 'E',
            scope: {
                value: '=',
                field: '='
            },
            templateUrl: '/components/gecko-date-range/gecko-date-range.html',

            link: function link(scope, iElement, iAttrs, ctrl) {},

            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {}
        };
    }

    angular.module('GeckoEngage').directive('geckoDateRange', geckoDateRange);
})();