(function () {
    'use strict';

    function FormWorkflowsCtrl($scope, $state, $formBuilderService, form, multiData, assignableChatAgents) {
        var ctrl = this;
        ctrl.form = form;
        ctrl.multiData = multiData;
        ctrl.assignableChatAgents = assignableChatAgents;

        var moduleTitle = $formBuilderService.getModuleTitle(ctrl.form.module);

        //Save form (function)
        ctrl.saveWorkflows = function () {

            var _form = angular.copy(ctrl.form);

            //Set workflows on form
            _form.workflows = ctrl.form.related_workflows;

            //Save form
            _form.save().then(function (form) {
                GeckoUI.messenger.success((ctrl.form.module === Gecko.Form.MODULE_CALL ? 'Script' : 'Form') + ' workflows updated');
                // Fire Intercom Event
                $formBuilderService.triggerIntercomEvent(ctrl.form, 'New ' + moduleTitle + ' Workflows Save Success');
                $scope.$digest();
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                $formBuilderService.triggerIntercomEvent(ctrl.form, 'New ' + moduleTitle + ' Workflows Save Error', GeckoUI.renderError(err.errors));
            });
        };

        //Save workflows (listener)
        $scope.$on('saveForm:' + $state.$current.name, ctrl.saveWorkflows);
    }

    angular.module('GeckoEngage').controller('FormWorkflowsCtrl', FormWorkflowsCtrl);
})();