;(function () {
    'use strict';

    function portalRequestService(env) {
        var _arguments = arguments;


        var successCodes = [200, 201, 202, 204, 206];

        var _this = {
            base: function base() {
                // Test AccessToken
                try {
                    return window.jwt_decode(window.TokenManager.fromLocalStorage().AccessToken).api;
                } catch (err) {}

                // Test env service
                try {
                    var _env = env();
                    if (_env === 'local') {
                        return '';
                    } else if (_env === 'stage') {
                        return 'https://' + Gecko.account.short_name + '.api-stage.geckoengage.com';
                    }
                    return 'https://' + Gecko.account.short_name + '.api.geckoengage.com';
                } catch (err) {}

                return '';
            },
            headers: function headers() {
                var headers = {
                    'Content-Type': 'application/json'
                };

                try {
                    headers['Authorization'] = 'Bearer ' + window.TokenManager.fromLocalStorage().AccessToken;
                } catch (err) {}

                return headers;
            },
            serialize: function serialize() {
                var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

                var str = '?';
                for (var param in params) {
                    str += param + '=' + params[param] + '&';
                }
                return str;
            },
            request: function request(url) {
                var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

                var options = _arguments.length > 2 && _arguments[2] !== undefined ? _arguments[2] : {};
                return new Promise(function (resolve, reject) {
                    var requestPromise = fetch(url, Object.assign({
                        headers: _this.headers()
                    }, config));

                    return requestPromise.then(function (response) {
                        var resolveFunc = successCodes.includes(response.status) ? resolve : reject;
                        if (response.status === 204) {
                            resolveFunc(true);
                        } else if (!options.raw) {
                            response.json().then(resolveFunc);
                        } else {
                            resolveFunc(response);
                        }
                    }).catch(reject);
                });
            },
            get: function get(endpoint, params) {
                var url = _this.base() + endpoint;
                if (params) url += _this.serialize(params);
                return _this.request(url, {
                    method: 'GET'
                });
            },
            post: function post(endpoint, params) {
                var url = _this.base() + endpoint;
                return _this.request(url, {
                    method: 'POST',
                    body: JSON.stringify(params || {})
                });
            }
        };
        return _this;
    }

    angular.module('GeckoEngage').factory('portalRequestService', portalRequestService);
})();