(function () {
    'use strict';

    function geckoTileTable() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-tile-table/gecko-tile-table.html',
            scope: {
                // gecko-card-header
                title: '@title',
                icon: '@icon',
                noHeaderTransclude: '@noHeaderTransclude',
                hideCardHeader: '@',
                // gecko-tiles
                tiles: '=',
                rowAction: '=rowAction',
                // gecko-pagination
                pagination: '=pagination'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                // Defaults - gecko-card-header
                if (ctrl.title === undefined) ctrl.title = '';
                if (ctrl.icon === undefined) ctrl.icon = '';
                if (ctrl.noHeaderTransclude === undefined) ctrl.noHeaderTransclude = null;
                if (ctrl.hideCardHeader === undefined) ctrl.hideCardHeader = false;
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoTileTable', geckoTileTable);
})();