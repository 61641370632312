(function () {
    'use strict';

    function geckoFormFieldConditions($formBuilderService) {
        return {
            restrict: 'E',
            scope: {
                form: '=',
                field: '='
            },
            templateUrl: '/modules/form/views/_modals/field_conditions.714ce37e.html',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            require: ['geckoFormFieldConditions', '^form'],
            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];
                // Get field total (exclude those without ids)
                ctrl.fieldTotal = $formBuilderService.getFieldsArray(ctrl.form.pages).filter(function (field) {
                    return field.id;
                }).length;

                //Add new field condition
                ctrl.addCondition = function () {
                    ctrl.form.conditions.push({
                        id: GeckoUI.generateUuid(),
                        field_id: ctrl.field.id
                        //type: null
                    });
                    formCtrl.$setDirty();
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFormFieldConditions', geckoFormFieldConditions);
})();