(function () {

    'use strict';

    function EventRoute($stateProvider) {
        $stateProvider.state('event', {
            url: '/event/{event_id}?{status}',
            templateUrl: "/modules/event/views/event.84d121e6.html",
            controller: 'EventCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event',
            resolve: {
                eventRfields: function eventRfields() {
                    return ['account_id', 'allow_clashing_sessions', 'attendances_count', 'available_tags', 'checkin_start', 'children_count', 'chat_channel_id', 'closing_datetime', 'contacts', 'connect_id', 'connect_title', 'color', 'cost', 'country', 'created_at', 'crm', 'dynamics_id', 'dynamics_title', 'description', 'deleted_at', 'disable_video_chat',
                    //'end_date',
                    'end_datetime', 'full', 'id', 'image', 'integration_ids', 'integration_syncs', 'internal_title', 'location_title_legacy', 'location_id', 'parent_id', 'radius_id', 'radius_title', 'registration_type', 'responses', 'responses_count', 'rsvp_link_type', 'salesforce_id', 'salesforce_title',
                    //'start_date',
                    'self_checkin', 'start_datetime', 'syncs', 'title', 'timezone', 'type', 'updated_at', 'users', 'maximum', 'maximum_guests', 'nice_title', 'auto_waitlist', 'synced', 'sync_status', 'sync_progress', 'delivery_method', 'webinar_url', 'webinar_description'];
                },
                data: function data($stateParams, eventRfields) {
                    var _query = {};

                    // Event
                    if ($stateParams.event_id !== 'new') {
                        _query.event = new Gecko.Event().include('categories', 'hosts', 'video', 'workflows').includeCount('attendances').rfields({ event: eventRfields, category: ['title', 'type'], workflow: ['schedule'] }).path($stateParams.event_id);
                    }

                    // Integrations
                    _query.integrations = new Gecko.Integration().where('sync_responses', 1);

                    return Gecko.multi(_query);
                },
                event: function event(data) {
                    return data.event ? data.event : new Gecko.Event();
                },
                session: function session() {
                    return false;
                },
                integrations: function integrations(data) {
                    return data.integrations;
                }
            }
        }).state('event.overview', {
            url: '/overview?{page}&{session}&{session_time}&{sync_status}&{search}&{order}&{sort}&{act_page}&{include_guests}&{from}&{to}',
            templateUrl: '/modules/event/views/event_overview.ead761fd.html',
            controller: 'EventOverviewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Overview',
            requires: [Gecko.ABILITY_EVENTS_VIEW],
            resolve: {
                attendancesQuery: function attendancesQuery(Gecko, $stateParams, geckoTableFilterService) {
                    return function () {
                        var attendances = new Gecko.Attendance().include('contact');
                        var order = $stateParams.order !== undefined ? $stateParams.order : 'created_at',
                            sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC';

                        if ($stateParams.status) attendances.where('status', $stateParams.status);

                        // Filter by session(s)
                        if ($stateParams.session_time) {
                            attendances.where('event_id', $stateParams.session_time);
                        } else if ($stateParams.session) {
                            attendances.where('event_id', $stateParams.session);
                        } else {
                            attendances.where('event_id', $stateParams.event_id);
                        }

                        if ($stateParams.search) attendances.search($stateParams.search);

                        // Integration sync status
                        geckoTableFilterService.prepareSyncQuery(attendances, $stateParams.sync_status);

                        // Date Filtering
                        attendances.dates($stateParams.from, $stateParams.to);

                        return attendances.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page).rfields({ attendance: ['sync_status', 'event_id', 'created_at', 'status', 'contact_id', 'rsvp', 'guest_count'], contact: ['full_name', 'qr_url', 'email'] });
                    }.bind(this);
                },
                attendances: function attendances(data) {
                    return data.attendances;
                },
                statConfig: function statConfig($stateParams) {
                    var statObj = {
                        filter: {}
                        // Get event, session or session time id
                    };if ($stateParams.session_time) {
                        statObj.filter.event_id = $stateParams.session_time;
                    } else if ($stateParams.session) {
                        statObj.filter.event_id = $stateParams.session;
                    } else {
                        statObj.filter.event_id = $stateParams.event_id;
                    }
                    // Include guests by default
                    if ($stateParams.include_guests == undefined || $stateParams.include_guests == 'true') {
                        statObj.type = { model: 'attendance', sum: 'attendances_count', group_by: 'status' };
                        return statObj;
                    } else {
                        statObj.type = { model: 'attendance', count: '*', group_by: 'status' };
                        return statObj;
                    }
                },
                stat: function stat(data) {
                    return data.stat;
                },
                data: function data(Gecko, $stateParams, attendancesQuery, statConfig) {
                    var _query = {};

                    // Stats
                    _query.stat = new Gecko.Stat().multiConfig({
                        method: 'POST',
                        model: statConfig.type.model,
                        sum: statConfig.type.sum,
                        count: statConfig.type.count,
                        group_by: statConfig.type.group_by,
                        filter: statConfig.filter,
                        no_cache: 1
                    }).path('generate');

                    // Attendances
                    _query.attendances = attendancesQuery();

                    // Sessions
                    _query.sessions = new Gecko.Event().rfields({ event: ['title'] }).where('parent_id', $stateParams.event_id).perPage('all');

                    // Session times (ONLY do this is single session is set)
                    if ($stateParams.session && !angular.isArray($stateParams.session) && $stateParams.session) {
                        _query.session_times = new Gecko.Event().rfields({ event: ['title'] }).where('parent_id', $stateParams.session).perPage('all');
                    }

                    return Gecko.multi(_query);
                },
                history: function history(Gecko, $stateParams) {
                    var pagination_act = {
                        per_page: Gecko.DEFAULT_PER_PAGE,
                        page: $stateParams.act_page || 1
                    };

                    return new Gecko.MixedObjects().call('/events/' + $stateParams.event_id + '/history', 'GET', pagination_act, true);

                    // return new Gecko.MixedObjects().perPage(Gecko.DEFAULT_PER_PAGE).page($stateParams.act_page || 1).path('events/' + $stateParams.event_id + '/history').get().then(function(data) {
                    //     //console.log(data);
                    //     return data;
                    // });
                }
            }
        }).state('event.settings', {
            url: '/edit?{tab}',
            templateUrl: '/modules/event/views/settings.6321a7ab.html',
            controller: 'EventSettingsCtrl',
            controllerAs: 'ctrl',
            ignorePageScroll: true,
            requires: [function ($stateParams) {
                return $stateParams.event_id === 'new' ? Gecko.ABILITY_EVENTS_CREATE : Gecko.ABILITY_EVENTS_UPDATE;
            }],
            page_title: 'Event Edit',
            resolve: {
                data: function data(geckoDataService) {
                    var models = ['categories', 'integrations', 'chat_channels'];
                    // Hosts
                    if (Gecko.able(Gecko.ABILITY_HOSTS_VIEW)) models.push('hosts');
                    // Locations
                    if (Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW)) models.push('locations');

                    return geckoDataService.fetch(models);
                },
                categories: function categories(data) {
                    return (data.categories || []).filter(function (c) {
                        return c.type === Gecko.Category.TYPE_EVENT;
                    });
                },
                hosts: function hosts(data) {
                    return data.hosts || [];
                },
                locations: function locations(data) {
                    return data.locations || [];
                },
                integrations: function integrations(data) {
                    return (data.integrations || []).filter(function (i) {
                        return [Gecko.Integration.TYPE_ENGAGE_DYNAMICS, Gecko.Integration.TYPE_SALEFORCE].includes(i.type);
                    });
                },
                channels: function channels(data) {
                    return data.chat_channels || [];
                }
            }
        }).state('event.sessions', {
            url: '/sessions?{page}&{filter}&{search}&{order}&{sort}&{exclude_guests}',
            templateUrl: '/modules/event/views/sessions.8ea3b234.html',
            controller: 'EventSessionsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Sessions',
            requires: [Gecko.ABILITY_EVENTS_SESSIONS_TAB],
            resolve: {
                data: function data(geckoDataService) {
                    var models = [];
                    // Hosts
                    if (Gecko.able(Gecko.ABILITY_HOSTS_VIEW)) models.push('hosts');
                    // Locations
                    if (Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW)) models.push('locations');

                    return geckoDataService.fetch(models);
                },
                hosts: function hosts(data) {
                    return data.hosts || [];
                },
                locations: function locations(data) {
                    return data.locations || [];
                },
                sessionsRfields: function sessionsRfields($stateParams) {
                    var eventArray = ['title', 'parent_id', 'start_datetime', 'end_datetime', 'maximum', 'maximum_guests', 'session_maximum', 'child_events_count', 'delivery_method', 'closed'];

                    // Count (including guests)
                    if ($stateParams.exclude_guests) {
                        eventArray.push('total_single_attendances');
                    }
                    eventArray.push('total_attendances');

                    return {
                        event: eventArray
                    };
                },
                sessions: function sessions(Gecko, sessionsRfields, $stateParams) {
                    var events = new Gecko.Event().rfields(sessionsRfields).where('event_parent_id', $stateParams.event_id).page($stateParams.page).perPage(GeckoUI.getPageLimit(this.self.name));
                    // Ordering
                    if ($stateParams.order) {
                        var order = {};
                        order[$stateParams.order] = $stateParams.sort ? $stateParams.sort : 'ASC';
                        if (!order['title']) order['title'] = 'ASC';
                    } else {
                        var order = { start_datetime: 'ASC', title: 'ASC' };
                    }
                    events.orderBy(order);
                    // Search term
                    if ($stateParams.search) events.search($stateParams.search);
                    return events.get();
                }
            }
        }).state('event.workflows', {
            url: '/workflows',
            templateUrl: '/modules/event/views/workflows.62bd64d0.html',
            controller: 'EventWorkflowsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Workflows',
            requires: [Gecko.ABILITY_EVENTS_WORKFLOWS_TAB],
            resolve: {
                workflows: function workflows($stateParams) {
                    return new Gecko.Event().include('workflows:all').rfields({ event: ['id'] }).get($stateParams.event_id).then(function (event) {
                        return event.related_workflows;
                    });
                },
                storedData: function storedData(geckoDataService) {
                    var models = ['fields', 'campaigns', 'consents', 'labels', 'users', 'senders', 'templates', 'integrations', 'tasks'];

                    return geckoDataService.fetch(models);
                }
            }
        }).state('event.stream', {
            url: '/stream?{page}',
            templateUrl: '/modules/event/views/stream.9d92052b.html',
            controller: 'EventStreamCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Stream',
            requires: [Gecko.ABILITY_EVENTS_WORKFLOWS_TAB],
            resolve: {
                sessions: function sessions(Gecko, $stateParams, event) {
                    var query = new Gecko.Event().rfields({ event: ['id', 'title'] }).where('event_parent_id', $stateParams.event_id);

                    // Online show ONLINE sessions when HYBRID event
                    if (event.delivery_method === Gecko.Event.DELIVERY_METHOD_HYBRID) {
                        query.where('delivery_method', Gecko.Event.DELIVERY_METHOD_ONLINE);
                    }

                    return query.orderBy({ start_datetime: 'ASC', title: 'ASC' }).perPage(1000).get();
                },
                times: function times(Gecko, $stateParams, sessions) {
                    if (sessions.data.length) {
                        return new Gecko.Event().rfields({ event: ['id', 'title', 'start_datetime', 'parent_id'] }).include('video').where('event_parent_id', sessions.data.map(function (s) {
                            return s.id;
                        })).orderBy({ start_datetime: 'ASC' }).page($stateParams.page).perPage(GeckoUI.getPageLimit(this.self.name)).get();
                    }
                    return new Gecko.Event();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(EventRoute);
})();