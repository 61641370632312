(function () {
    'use strict';

    function $geckoPresence($geckoSocket, $geckoVoip, $timeout) {

        var _this = {

            isOnline: function isOnline() {
                return Gecko.user && Gecko.user.presence == Gecko.User.PRESENCE_AVAILABLE;
            },

            isAway: function isAway() {
                return Gecko.user && Gecko.user.presence == Gecko.User.PRESENCE_MANUALLY_AWAY;
            },

            getOnlineUsers: function getOnlineUsers() {
                return new Gecko.User().include('all_users', 'group').where('presence', Gecko.User.PRESENCE_AVAILABLE).where('status', Gecko.User.STATUS_IDLE).where('call_method', Gecko.CALL_METHOD_VOIP).orderBy('full_name', 'ASC').rfields({ user: ['full_name'] });
            },

            // Activate: function() {
            //     If (Gecko.user && Gecko.user.id == user.id){
            //         //Update status value
            //         Gecko.user.status = user.status;
            //         //Update user presence value
            //         Gecko.user.presence = user.presence;
            //         //Get next call in the queue if presence is available
            //         If (_this.isOnline()) $geckoVoip.accept = true;
            //         //Cancel call if presence is unavailable
            //         If (!_this.isOnline() && $geckoVoip.isIncoming) $geckoVoip.accept = false;
            //     }
            // },

            available: function available() {
                if (!Gecko.User.voipInboundEnabled()) return false;

                $geckoSocket.registerEvent('user:updated', function (user) {
                    $timeout(function () {
                        if (Gecko.user && Gecko.user.id == user.id) {
                            // Update status value
                            Gecko.user.status = user.status;
                            // Update user presence value
                            Gecko.user.presence = user.presence;
                            // Get next call in the queue if presence is available
                            if (_this.isOnline()) $geckoVoip.accept = true;
                            // Cancel call if presence is unavailable
                            if (!_this.isOnline() && $geckoVoip.isIncoming) $geckoVoip.accept = false;
                        }
                    });
                }, true);
            },

            init: function init() {
                Gecko.on('available', _this.available);
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoPresence', $geckoPresence);
})();