(function () {
    'use strict';

    function loadingIndicator(LoadingIndicator) {

        return {
            restrict: 'A',
            templateUrl: '/components/loading-indicator/loading-indicator.html',
            scope: {},
            link: function postLink(scope, iElement, iAttrs) {

                if (iAttrs.backdrop !== 'none') iElement.addClass('cover');

                function listener(show) {
                    iElement.toggleClass('active', show);
                }

                LoadingIndicator.register(listener, iAttrs.loadingIndicatorName);

                iElement.on('$destroy', function () {
                    LoadingIndicator.deRegister(listener);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('loadingIndicator', loadingIndicator);
})();