(function () {
    'use strict';

    function geckoDates() {
        var _this = {

            //
            dateFormatConfigOptions: [{ id: 'd/m/Y', label: 'dd/mm/yyyy' }, { id: 'm/d/Y', label: 'mm/dd/yyyy' }],

            timeFormatConfigOptions: [{ id: 'g:i A', label: '12hr (am/pm)' }, { id: 'H:i', label: '24hr' }],

            is12HrTimeFormat: function is12HrTimeFormat() {
                return (Gecko || {}).account && (Gecko.account || {}).time_format === 'g:i A';
            },

            timeFormat: function timeFormat() {
                return _this.is12HrTimeFormat() ? 'h:mm A' : 'H:mm';
            },

            prepare12HrOptionLabel: function prepare12HrOptionLabel(hour) {
                var value = Number(hour);
                var label = value;

                // Convert display hour
                if (value === 0) {
                    label = '12';
                } else if (value > 12) {
                    label = value - 12;
                }
                // Add am/pm
                if (value < 12) {
                    label = label + ' AM';
                } else {
                    label = label + ' PM';
                }

                return label;
            },

            convert: moment.utc,

            stringFormat: 'YYYY-MM-DD HH:mm:ss',

            // 1502787600 -> 2017-08-15 09:00:00
            toString: function toString(date) {
                if (!date) return null;
                return _this.convert(date, 'X').format(_this.stringFormat);
            },

            // 2017-08-15 09:00:00 -> 1502787600
            toEpoch: function toEpoch(date) {
                if (!date) return null;
                return _this.convert(date).format('X');
            },

            // 2017-08-15 09:00:00 -> {year: 2017, month: 8, day: 15, hour: 9, minutes:0}
            // toObj: (date) => {
            //     var _date = _this.convert(date);
            //     return {
            //         year    : _date.get('year'),
            //         month   : _date.get('month') + 1,
            //         day     : _date.get('date'),
            //         hour    : _date.get('hours'),
            //         minutes : _date.get('minute')
            //     };
            // },

            // 1502755200, 2, 30 -> 2017-08-15 02:30:00
            // combine: (epoch, hour, minutes) => {
            //     var epoch = epoch || moment.utc().format('X'),
            //         year = _this.convert(epoch, 'X').get('year'),
            //         month = _this.convert(epoch, 'X').get('month'),
            //         day = _this.convert(epoch, 'X').get('date'),
            //         hour = hour || 0,
            //         minutes = minutes || 0;

            //     return moment.utc([year, month, day, hour, minutes]).format(_this.stringFormat);
            // },

            // date = string to be formatted to account timezone
            printDateString: function printDateString(date, format) {
                if (!date) return '';
                var _format = format || Gecko.dateFormat.longTime;
                return _this.convert(date, _this.stringFormat).format(_format);
            },

            // date = epoch to be formatted to account timezone
            printDateEpoch: function printDateEpoch(date, format) {
                if (!date) return '';
                var _format = format || Gecko.dateFormat.longTime;
                return _this.convert(date, 'X').format(_format);
            },

            printStartTimeEndTime: function printStartTimeEndTime(obj) {
                var start = _this.printDateString(obj.start_datetime, _this.timeFormat());
                var end = _this.printDateString(obj.end_datetime, _this.timeFormat());
                return start + ' - ' + end;
            },

            // Etc/GMT+1 -> Etc/GMT-1, Etc/GMT-1 -> Etc/GMT+1, Europe/London -> Europe/London
            fixEtcTimezone: function fixEtcTimezone(timezone) {
                if (timezone.indexOf('Etc/GMT+') !== -1) {
                    return timezone.replace('+', '-');
                } else if (timezone.indexOf('Etc/GMT-') !== -1) {
                    return timezone.replace('-', '+');
                }
                return timezone;
            },

            // Build getters and setters
            buildSetter: function buildSetter(tempObj, obj, getProp, setProp, related, defaultTime) {
                //if (!Object.getOwnPropertyDescriptor(obj, getProp)) {
                Object.defineProperty(obj, getProp, {
                    get: function get() {
                        return tempObj[getProp];
                    },
                    set: function set(value) {

                        var isNew = !tempObj[getProp];
                        var date = value;
                        var dateString = _this.toString(value);

                        // Set default time
                        if (isNew && defaultTime && defaultTime.length === 8) {
                            dateString = dateString.slice(0, -8) + defaultTime;
                            date = _this.toEpoch(dateString);
                        }

                        tempObj[getProp] = date;

                        // Related
                        if (related && tempObj[related] !== undefined && tempObj[related] === null) {
                            obj[related] = date;
                        }

                        // Set date string
                        obj[setProp] = dateString;

                        console.log(obj[setProp]);
                    }
                });
                //}
            },

            initSetters: function initSetters(tempObj, obj, props) {
                props.forEach(function (prop) {
                    return _this.buildSetter(tempObj, obj, prop.get, prop.set, prop.related, prop.defaultTime);
                });
                return;
            }

        };

        return _this;
    }

    angular.module('GeckoEngage').factory('geckoDates', geckoDates);
})();