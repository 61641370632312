(function () {
    'use strict';

    function BroadcastsCtrl($state, $stateParams, $filter, Gecko, broadcasts, senders, templates) {
        var ctrl = this;
        ctrl.broadcasts = broadcasts.toInstances();
        ctrl.pagination = broadcasts.pagination;
        ctrl.senders = senders;
        ctrl.templates = templates;

        // Table structure
        ctrl.cols = [{
            key: 'status',
            expandable: true,
            status_title_none: true,
            status_styles: Gecko.Campaign.status_titles
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at'
        }, {
            title: 'Title',
            key: 'title',
            sortKey: 'title',
            colClass: 'col-md-3'
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Start date',
            key: 'start_date',
            sortKey: 'start_date',
            colClass: 'col-md-3'
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'End date',
            key: 'end_date',
            sortKey: 'end_date',
            colClass: 'col-md-3'
        }, {
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Available Contacts',
            key: 'subscribers_count',
            render: function render(value, col, row) {
                return row.subscribers_count;
            }
        }];

        ctrl.showNumberError = function () {
            return ctrl.senders.pagination.total_items < 1;
        };

        ctrl.showTemplateError = function () {
            return ctrl.templates.pagination.total_items < 1;
        };

        // Table row action
        ctrl.rowAction = {
            action: function action(campaign) {
                return $state.go('broadcast.overview', { broadcast_id: campaign.id });
            }
        };

        var filterOptions = [{
            label: 'Active',
            value: Gecko.Campaign.ACTIVE
        }, {
            label: 'Pending',
            value: Gecko.Campaign.PENDING
        }, {
            label: 'Completed',
            value: Gecko.Campaign.COMPLETE
        }, {
            label: 'Invalid',
            value: Gecko.Campaign.INVALID
        }, {
            label: 'Paused',
            value: Gecko.Campaign.PAUSED
        }];

        // if ($stateParams.status && ($stateParams.status === 'current' || $stateParams.status.indexOf('status') !== -1)) {
        //     filterOptions.unshift({
        //         label : 'Current',
        //         value : 'current'
        //     });
        // }

        ctrl.tableFilter = [{
            title: 'Broadcast Type',
            type: 'checkbox',
            stateParam: 'module',
            options: [{
                label: 'Email',
                value: Gecko.Campaign.MODULE_EMAIL
            }, {
                label: 'SMS',
                value: Gecko.Campaign.MODULE_SMS
            }]
        }, {
            title: 'Broadcast Status',
            type: 'checkbox',
            options: filterOptions,
            stateParam: 'status'
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Export broadcast',
            action: function action(broadcast) {
                return $state.go('exportnew', { export_id: 'new', broadcast_id: broadcast.id });
            }
        }, {
            title: 'Clone broadcast',
            action: function action(broadcast) {
                angular.copy(broadcast).clone().then(function () {
                    GeckoUI.messenger.success('Broadcast successfully cloned');
                    // Redirect to broadcasts
                    return $state.go($state.$current.name, $stateParams, { reload: true });
                });
            }
        }, {
            title: 'Delete broadcast',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_BROADCASTS_DELETE);
            },
            action: function action(broadcast, index) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this broadcast?', function (value) {
                    if (value) {
                        broadcast.remove().then(function () {
                            GeckoUI.messenger.success('Broadcast removed');
                            // Redirect to broadcasts
                            return $state.go($state.$current.name, $stateParams, { reload: true });
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Broadcasts',
            click: function click() {
                return $state.go('broadcasts');
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('BroadcastsCtrl', BroadcastsCtrl);
})();