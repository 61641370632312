(function () {
    'use strict';

    function UsageSmsCtrl($state, $stateParams, sms, stats) {
        var ctrl = this;
        if (sms) {
            ctrl.sms = sms.toArray();
            ctrl.pagination = sms.pagination;
        } else {
            ctrl.sms = false;
            ctrl.pagination = {};
        }
        ctrl.stats = stats;

        // Date filter
        ctrl.dateFrom = $stateParams.from;
        ctrl.dateTo = $stateParams.to;

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { label: object[labelKey], value: object.id };
                });
            };
        };
        ctrl.tableFilters = [{
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            }
        }, {
            title: 'Broadcasts',
            stateParam: 'campaign',
            type: 'dropdown',
            optionsQuery: new Gecko.Campaign().rfields({ campaign: ['title'] }).where('module', Gecko.Campaign.MODULE_SMS),
            optionsKey: 'id',
            optionsLabelKey: 'title'
        }];

        // Table structure
        ctrl.cols = [{
            title: 'Recipient',
            key: 'recipients',
            sortKey: 'recipients'
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Created at',
            key: 'created_at',
            sortKey: 'created_at'
        }, {
            data_type: Gecko.Field.DATA_TYPE_CURRENCY,
            title: 'Cost',
            key: 'cost',
            sortKey: 'cost',
            colClass: 'col-xs-3'
        }];

        // Stats card
        ctrl.stats = [];
        angular.forEach(stats, function (stat) {
            ctrl.stats.push({
                title: stat.title,
                aggregate: stat.unit.toLowerCase() == 'currency' ? Gecko.account.currency_symbol + Number(stat.result).toFixed(2) : stat.result
            });
        });
    }

    angular.module('GeckoEngage').controller('UsageSmsCtrl', UsageSmsCtrl);
})();