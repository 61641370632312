(function () {
    'use strict';

    function filePickerAlert() {
        return {
            restrict: 'E',
            template: '\n                <div role="alert" ng-if="ctrl.isDangerous" ng-class="ctrl.dangerClasses">\n                    <i class="fa fa-exclamation-triangle"></i>\n                    Warning, this file is probably malicious.\n                </div>\n                <div role="alert" ng-if="ctrl.isPending" ng-class="ctrl.pendingClasses">\n                    <i class="fa fa-exclamation-triangle"></i>\n                    Warning, this file has not yet been scanned and could be malicious.\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                fileUrl: '=',
                inline: '@',
                cache: '@',
                pending: '@'
            },
            controller: function controller($scope, $attrs, fileAvService) {
                var ctrl = this;
                var fileUrl = ctrl.fileUrl;
                var cacheAvStatus = $attrs.hasOwnProperty('cache');
                ctrl.isPending = $attrs.hasOwnProperty('pending');
                ctrl.isDangerous = false;
                ctrl.pendingClasses = $attrs.hasOwnProperty('inline') ? 'file-picker-alert-inline text-warning' : 'file-picker-alert alert alert-warning';
                ctrl.dangerClasses = $attrs.hasOwnProperty('inline') ? 'file-picker-alert-inline text-danger' : 'file-picker-alert alert alert-danger';

                if (fileUrl) {
                    fileAvService.isDangerous(fileUrl, cacheAvStatus).then(function (isDangerous) {
                        ctrl.isPending = false;
                        ctrl.isDangerous = isDangerous;
                        $scope.$digest();
                    }).catch(console.log);
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('filePickerAlert', filePickerAlert);
})();