var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/*! messenger 1.4.1 */
(function () {
  var e,
      t = window.Messenger;e = window.Messenger = function () {
    return e._call.apply(this, arguments);
  }, window.Messenger.noConflict = function () {
    return window.Messenger = t, e;
  };
})(), window.Messenger._ = function () {
  if (window._) return window._;var e = Array.prototype,
      t = Object.prototype,
      n = Function.prototype,
      s = (e.push, e.slice),
      r = (e.concat, t.toString);t.hasOwnProperty;var o = e.forEach,
      i = (e.map, e.reduce, e.reduceRight, e.filter),
      a = (e.every, e.some, e.indexOf, e.lastIndexOf, Array.isArray, Object.keys),
      l = n.bind,
      u = {},
      c = {},
      h = u.each = u.forEach = function (e, t, n) {
    if (null != e) if (o && e.forEach === o) e.forEach(t, n);else if (e.length === +e.length) {
      for (var s = 0, r = e.length; r > s; s++) {
        if (t.call(n, e[s], s, e) === c) return;
      }
    } else for (var i in e) {
      if (u.has(e, i) && t.call(n, e[i], i, e) === c) return;
    }
  };u.result = function (e, t) {
    if (null == e) return null;var n = e[t];return u.isFunction(n) ? n.call(e) : n;
  }, u.once = function (e) {
    var t,
        n = !1;return function () {
      return n ? t : (n = !0, t = e.apply(this, arguments), e = null, t);
    };
  };var p = 0;return u.uniqueId = function (e) {
    var t = ++p + "";return e ? e + t : t;
  }, u.filter = u.select = function (e, t, n) {
    var s = [];return null == e ? s : i && e.filter === i ? e.filter(t, n) : (h(e, function (e, r, o) {
      t.call(n, e, r, o) && (s[s.length] = e);
    }), s);
  }, h(["Arguments", "Function", "String", "Number", "Date", "RegExp"], function (e) {
    u["is" + e] = function (t) {
      return r.call(t) == "[object " + e + "]";
    };
  }), u.defaults = function (e) {
    return h(s.call(arguments, 1), function (t) {
      if (t) for (var n in t) {
        null == e[n] && (e[n] = t[n]);
      }
    }), e;
  }, u.extend = function (e) {
    return h(s.call(arguments, 1), function (t) {
      if (t) for (var n in t) {
        e[n] = t[n];
      }
    }), e;
  }, u.keys = a || function (e) {
    if (e !== Object(e)) throw new TypeError("Invalid object");var t = [];for (var n in e) {
      u.has(e, n) && (t[t.length] = n);
    }return t;
  }, u.bind = function (e, t) {
    if (e.bind === l && l) return l.apply(e, s.call(arguments, 1));var n = s.call(arguments, 2);return function () {
      return e.apply(t, n.concat(s.call(arguments)));
    };
  }, u.isObject = function (e) {
    return e === Object(e);
  }, u;
}(), window.Messenger.Events = function () {
  if (window.Backbone && Backbone.Events) return Backbone.Events;var e = function e() {
    var e = /\s+/,
        t = function t(_t, n, s, r) {
      if (!s) return !0;if ("object" == (typeof s === "undefined" ? "undefined" : _typeof(s))) for (var o in s) {
        _t[n].apply(_t, [o, s[o]].concat(r));
      } else {
        if (!e.test(s)) return !0;for (var i = s.split(e), a = 0, l = i.length; l > a; a++) {
          _t[n].apply(_t, [i[a]].concat(r));
        }
      }
    },
        n = function n(e, t) {
      var n,
          s = -1,
          r = e.length;switch (t.length) {case 0:
          for (; r > ++s;) {
            (n = e[s]).callback.call(n.ctx);
          }return;case 1:
          for (; r > ++s;) {
            (n = e[s]).callback.call(n.ctx, t[0]);
          }return;case 2:
          for (; r > ++s;) {
            (n = e[s]).callback.call(n.ctx, t[0], t[1]);
          }return;case 3:
          for (; r > ++s;) {
            (n = e[s]).callback.call(n.ctx, t[0], t[1], t[2]);
          }return;default:
          for (; r > ++s;) {
            (n = e[s]).callback.apply(n.ctx, t);
          }}
    },
        s = { on: function on(e, n, s) {
        if (!t(this, "on", e, [n, s]) || !n) return this;this._events || (this._events = {});var r = this._events[e] || (this._events[e] = []);return r.push({ callback: n, context: s, ctx: s || this }), this;
      }, once: function once(e, n, s) {
        if (!t(this, "once", e, [n, s]) || !n) return this;var r = this,
            o = _.once(function () {
          r.off(e, o), n.apply(this, arguments);
        });return o._callback = n, this.on(e, o, s), this;
      }, off: function off(e, n, s) {
        var r, o, i, a, l, u, c, h;if (!this._events || !t(this, "off", e, [n, s])) return this;if (!e && !n && !s) return this._events = {}, this;for (a = e ? [e] : _.keys(this._events), l = 0, u = a.length; u > l; l++) {
          if (e = a[l], r = this._events[e]) {
            if (i = [], n || s) for (c = 0, h = r.length; h > c; c++) {
              o = r[c], (n && n !== o.callback && n !== o.callback._callback || s && s !== o.context) && i.push(o);
            }this._events[e] = i;
          }
        }return this;
      }, trigger: function trigger(e) {
        if (!this._events) return this;var s = Array.prototype.slice.call(arguments, 1);if (!t(this, "trigger", e, s)) return this;var r = this._events[e],
            o = this._events.all;return r && n(r, s), o && n(o, arguments), this;
      }, listenTo: function listenTo(e, t, n) {
        var s = this._listeners || (this._listeners = {}),
            r = e._listenerId || (e._listenerId = _.uniqueId("l"));return s[r] = e, e.on(t, "object" == (typeof t === "undefined" ? "undefined" : _typeof(t)) ? this : n, this), this;
      }, stopListening: function stopListening(e, t, n) {
        var s = this._listeners;if (s) {
          if (e) e.off(t, "object" == (typeof t === "undefined" ? "undefined" : _typeof(t)) ? this : n, this), t || n || delete s[e._listenerId];else {
            "object" == (typeof t === "undefined" ? "undefined" : _typeof(t)) && (n = this);for (var r in s) {
              s[r].off(t, n, this);
            }this._listeners = {};
          }return this;
        }
      } };return s.bind = s.on, s.unbind = s.off, s;
  };return e();
}(), function () {
  var e,
      t,
      n,
      s,
      r,
      o,
      i,
      a,
      l,
      u,
      c,
      h = {}.hasOwnProperty,
      p = function p(e, t) {
    function n() {
      this.constructor = e;
    }for (var s in t) {
      h.call(t, s) && (e[s] = t[s]);
    }return n.prototype = t.prototype, e.prototype = new n(), e.__super__ = t.prototype, e;
  },
      d = [].slice,
      f = [].indexOf || function (e) {
    for (var t = 0, n = this.length; n > t; t++) {
      if (t in this && this[t] === e) return t;
    }return -1;
  };e = jQuery, o = null != (l = window._) ? l : window.Messenger._, s = null != (u = "undefined" != typeof Backbone && null !== Backbone ? Backbone.Events : void 0) ? u : window.Messenger.Events, n = function () {
    function t(t) {
      e.extend(this, s), o.isObject(t) && (t.el && this.setElement(t.el), this.model = t.model), this.initialize.apply(this, arguments);
    }return t.prototype.setElement = function (t) {
      return this.$el = e(t), this.el = this.$el[0];
    }, t.prototype.delegateEvents = function (e) {
      var t, n, s, r, i, a, l;if (e || (e = o.result(this, "events"))) {
        this.undelegateEvents(), t = /^(\S+)\s*(.*)$/, l = [];for (s in e) {
          if (i = e[s], o.isFunction(i) || (i = this[e[s]]), !i) throw Error('Method "' + e[s] + '" does not exist');r = s.match(t), n = r[1], a = r[2], i = o.bind(i, this), n += ".delegateEvents" + this.cid, "" === a ? l.push(this.jqon(n, i)) : l.push(this.jqon(n, a, i));
        }return l;
      }
    }, t.prototype.jqon = function (e, t, n) {
      var s;return null != this.$el.on ? (s = this.$el).on.apply(s, arguments) : (null == n && (n = t, t = void 0), null != t ? this.$el.delegate(t, e, n) : this.$el.bind(e, n));
    }, t.prototype.jqoff = function (e) {
      var t;return null != this.$el.off ? (t = this.$el).off.apply(t, arguments) : (this.$el.undelegate(), this.$el.unbind(e));
    }, t.prototype.undelegateEvents = function () {
      return this.jqoff(".delegateEvents" + this.cid);
    }, t.prototype.remove = function () {
      return this.undelegateEvents(), this.$el.remove();
    }, t;
  }(), i = function (t) {
    function n() {
      return n.__super__.constructor.apply(this, arguments);
    }return p(n, t), n.prototype.defaults = { hideAfter: 10, scroll: !0, closeButtonText: "&times;" }, n.prototype.initialize = function (t) {
      return null == t && (t = {}), this.shown = !1, this.rendered = !1, this.messenger = t.messenger, this.options = e.extend({}, this.options, t, this.defaults);
    }, n.prototype.show = function () {
      var e;return this.rendered || this.render(), this.$message.removeClass("messenger-hidden"), e = this.shown, this.shown = !0, e ? void 0 : this.trigger("show");
    }, n.prototype.hide = function () {
      var e;if (this.rendered) return this.$message.addClass("messenger-hidden"), e = this.shown, this.shown = !1, e ? this.trigger("hide") : void 0;
    }, n.prototype.cancel = function () {
      return this.hide();
    }, n.prototype.update = function (t) {
      var n,
          s = this;return o.isString(t) && (t = { message: t }), e.extend(this.options, t), this.lastUpdate = new Date(), this.rendered = !1, this.events = null != (n = this.options.events) ? n : {}, this.render(), this.actionsToEvents(), this.delegateEvents(), this.checkClickable(), this.options.hideAfter ? (this.$message.addClass("messenger-will-hide-after"), null != this._hideTimeout && clearTimeout(this._hideTimeout), this._hideTimeout = setTimeout(function () {
        return s.hide();
      }, 1e3 * this.options.hideAfter)) : this.$message.removeClass("messenger-will-hide-after"), this.options.hideOnNavigate ? (this.$message.addClass("messenger-will-hide-on-navigate"), null != ("undefined" != typeof Backbone && null !== Backbone ? Backbone.history : void 0) && Backbone.history.on("route", function () {
        return s.hide();
      })) : this.$message.removeClass("messenger-will-hide-on-navigate"), this.trigger("update", this);
    }, n.prototype.scrollTo = function () {
      return this.options.scroll ? e.scrollTo(this.$el, { duration: 400, offset: { left: 0, top: -20 } }) : void 0;
    }, n.prototype.timeSinceUpdate = function () {
      return this.lastUpdate ? new Date() - this.lastUpdate : null;
    }, n.prototype.actionsToEvents = function () {
      var e,
          t,
          n,
          s,
          r = this;n = this.options.actions, s = [];for (t in n) {
        e = n[t], s.push(this.events['click [data-action="' + t + '"] a'] = function (e) {
          return function (n) {
            return n.preventDefault(), n.stopPropagation(), r.trigger("action:" + t, e, n), e.action.call(r, n, r);
          };
        }(e));
      }return s;
    }, n.prototype.checkClickable = function () {
      var e, t, n, s;n = this.events, s = [];for (t in n) {
        e = n[t], "click" === t ? s.push(this.$message.addClass("messenger-clickable")) : s.push(void 0);
      }return s;
    }, n.prototype.undelegateEvents = function () {
      var e;return n.__super__.undelegateEvents.apply(this, arguments), null != (e = this.$message) ? e.removeClass("messenger-clickable") : void 0;
    }, n.prototype.parseActions = function () {
      var t, n, s, r, o, i;n = [], o = this.options.actions;for (r in o) {
        t = o[r], s = e.extend({}, t), s.name = r, null == (i = s.label) && (s.label = r), n.push(s);
      }return n;
    }, n.prototype.template = function (t) {
      var n,
          s,
          r,
          o,
          i,
          a,
          l,
          u,
          c,
          h,
          p = this;for (i = e("<div class='messenger-message message alert " + t.type + " message-" + t.type + " alert-" + t.type + "'>"), t.showCloseButton && (r = e('<button type="button" class="messenger-close" data-dismiss="alert">'), r.html(t.closeButtonText), r.click(function () {
        return p.cancel(), !0;
      }), i.append(r)), a = e('<div class="messenger-message-inner">' + t.message + "</div>"), i.append(a), t.actions.length && (s = e('<div class="messenger-actions">')), h = t.actions, u = 0, c = h.length; c > u; u++) {
        l = h[u], n = e("<span>"), n.attr("data-action", "" + l.name), o = e("<a>"), o.html(l.label), n.append(e('<span class="messenger-phrase">')), n.append(o), s.append(n);
      }return i.append(s), i;
    }, n.prototype.render = function () {
      var t;if (!this.rendered) return this._hasSlot || (this.setElement(this.messenger._reserveMessageSlot(this)), this._hasSlot = !0), t = e.extend({}, this.options, { actions: this.parseActions() }), this.$message = e(this.template(t)), this.$el.html(this.$message), this.shown = !0, this.rendered = !0, this.trigger("render");
    }, n;
  }(n), r = function (e) {
    function t() {
      return t.__super__.constructor.apply(this, arguments);
    }return p(t, e), t.prototype.initialize = function () {
      return t.__super__.initialize.apply(this, arguments), this._timers = {};
    }, t.prototype.cancel = function () {
      return this.clearTimers(), this.hide(), null != this._actionInstance && null != this._actionInstance.abort ? this._actionInstance.abort() : void 0;
    }, t.prototype.clearTimers = function () {
      var e, t, n, s;n = this._timers;for (e in n) {
        t = n[e], clearTimeout(t);
      }return this._timers = {}, null != (s = this.$message) ? s.removeClass("messenger-retry-soon messenger-retry-later") : void 0;
    }, t.prototype.render = function () {
      var e, n, s, r;t.__super__.render.apply(this, arguments), this.clearTimers(), s = this.options.actions, r = [];for (n in s) {
        e = s[n], e.auto ? r.push(this.startCountdown(n, e)) : r.push(void 0);
      }return r;
    }, t.prototype.renderPhrase = function (e, t) {
      var n;return n = e.phrase.replace("TIME", this.formatTime(t));
    }, t.prototype.formatTime = function (e) {
      var t;return t = function t(e, _t2) {
        return e = Math.floor(e), 1 !== e && (_t2 += "s"), "in " + e + " " + _t2;
      }, 0 === Math.floor(e) ? "now..." : 60 > e ? t(e, "second") : (e /= 60, 60 > e ? t(e, "minute") : (e /= 60, t(e, "hour")));
    }, t.prototype.startCountdown = function (e, t) {
      var n,
          s,
          _r,
          o,
          i = this;if (null == this._timers[e]) return n = this.$message.find("[data-action='" + e + "'] .messenger-phrase"), s = null != (o = t.delay) ? o : 3, 10 >= s ? (this.$message.removeClass("messenger-retry-later"), this.$message.addClass("messenger-retry-soon")) : (this.$message.removeClass("messenger-retry-soon"), this.$message.addClass("messenger-retry-later")), _r = function r() {
        var o;return n.text(i.renderPhrase(t, s)), s > 0 ? (o = Math.min(s, 1), s -= o, i._timers[e] = setTimeout(_r, 1e3 * o)) : (i.$message.removeClass("messenger-retry-soon messenger-retry-later"), delete i._timers[e], t.action());
      }, _r();
    }, t;
  }(i), a = function (t) {
    function n() {
      return n.__super__.constructor.apply(this, arguments);
    }return p(n, t), n.prototype.tagName = "ul", n.prototype.className = "messenger", n.prototype.messageDefaults = { type: "info" }, n.prototype.initialize = function (t) {
      return this.options = null != t ? t : {}, this.history = [], this.messageDefaults = e.extend({}, this.messageDefaults, this.options.messageDefaults);
    }, n.prototype.render = function () {
      return this.updateMessageSlotClasses();
    }, n.prototype.findById = function (e) {
      return o.filter(this.history, function (t) {
        return t.msg.options.id === e;
      });
    }, n.prototype._reserveMessageSlot = function (t) {
      var n,
          s,
          r = this;for (n = e("<li>"), n.addClass("messenger-message-slot"), this.$el.prepend(n), this.history.push({ msg: t, $slot: n }), this._enforceIdConstraint(t), t.on("update", function () {
        return r._enforceIdConstraint(t);
      }); this.options.maxMessages && this.history.length > this.options.maxMessages;) {
        s = this.history.shift(), s.msg.remove(), s.$slot.remove();
      }return n;
    }, n.prototype._enforceIdConstraint = function (e) {
      var t, n, s, r, o;if (null != e.options.id) for (o = this.history, n = 0, s = o.length; s > n; n++) {
        if (t = o[n], r = t.msg, null != r.options.id && r.options.id === e.options.id && e !== r) {
          if (e.options.singleton) return e.hide(), void 0;r.hide();
        }
      }
    }, n.prototype.newMessage = function (e) {
      var t,
          n,
          s,
          o,
          a = this;return null == e && (e = {}), e.messenger = this, i = null != (n = null != (s = Messenger.themes[null != (o = e.theme) ? o : this.options.theme]) ? s.Message : void 0) ? n : r, t = new i(e), t.on("show", function () {
        return e.scrollTo && "fixed" !== a.$el.css("position") ? t.scrollTo() : void 0;
      }), t.on("hide show render", this.updateMessageSlotClasses, this), t;
    }, n.prototype.updateMessageSlotClasses = function () {
      var e, t, n, s, r, o, i;for (s = !0, t = null, e = !1, i = this.history, r = 0, o = i.length; o > r; r++) {
        n = i[r], n.$slot.removeClass("messenger-first messenger-last messenger-shown"), n.msg.shown && n.msg.rendered && (n.$slot.addClass("messenger-shown"), e = !0, t = n, s && (s = !1, n.$slot.addClass("messenger-first")));
      }return null != t && t.$slot.addClass("messenger-last"), this.$el["" + (e ? "remove" : "add") + "Class"]("messenger-empty");
    }, n.prototype.hideAll = function () {
      var e, t, n, s, r;for (s = this.history, r = [], t = 0, n = s.length; n > t; t++) {
        e = s[t], r.push(e.msg.hide());
      }return r;
    }, n.prototype.post = function (t) {
      var n;return o.isString(t) && (t = { message: t }), t = e.extend(!0, {}, this.messageDefaults, t), n = this.newMessage(t), n.update(t), n;
    }, n;
  }(n), t = function (t) {
    function n() {
      return n.__super__.constructor.apply(this, arguments);
    }return p(n, t), n.prototype.doDefaults = { progressMessage: null, successMessage: null, errorMessage: "Error connecting to the server.", showSuccessWithoutError: !0, retry: { auto: !0, allow: !0 }, action: e.ajax }, n.prototype.hookBackboneAjax = function (t) {
      var n,
          s = this;if (null == t && (t = {}), null == window.Backbone) throw "Expected Backbone to be defined";return t = o.defaults(t, { id: "BACKBONE_ACTION", errorMessage: !1, successMessage: "Request completed successfully.", showSuccessWithoutError: !1 }), n = function n(e) {
        var n;return n = o.extend({}, t, e.messenger), s["do"](n, e);
      }, null != Backbone.ajax ? (Backbone.ajax._withoutMessenger && (Backbone.ajax = Backbone.ajax._withoutMessenger), (null == t.action || t.action === this.doDefaults.action) && (t.action = Backbone.ajax), n._withoutMessenger = Backbone.ajax, Backbone.ajax = n) : Backbone.sync = o.wrap(Backbone.sync, function () {
        var t, s, r;return r = arguments[0], t = arguments.length >= 2 ? d.call(arguments, 1) : [], s = e.ajax, e.ajax = n, r.call.apply(r, [this].concat(d.call(t))), e.ajax = s;
      });
    }, n.prototype._getHandlerResponse = function (e) {
      return e === !1 ? !1 : e === !0 || null == e ? !0 : e;
    }, n.prototype._parseEvents = function (e) {
      var t, n, s, r, o, i, a;null == e && (e = {}), o = {};for (r in e) {
        s = e[r], n = r.indexOf(" "), i = r.substring(0, n), t = r.substring(n + 1), null == (a = o[i]) && (o[i] = {}), o[i][t] = s;
      }return o;
    }, n.prototype._normalizeResponse = function () {
      var e, t, n, s, r, i, a;for (n = arguments.length >= 1 ? d.call(arguments, 0) : [], s = null, r = null, e = null, i = 0, a = n.length; a > i; i++) {
        t = n[i], "success" === t || "timeout" === t || "abort" === t ? s = t : null != (null != t ? t.readyState : void 0) && null != (null != t ? t.responseText : void 0) ? r = t : o.isObject(t) && (e = t);
      }return [s, e, r];
    }, n.prototype.run = function () {
      var t,
          n,
          s,
          r,
          i,
          a,
          l,
          u,
          c,
          h,
          p,
          g = this;if (a = arguments[0], c = arguments[1], t = arguments.length >= 3 ? d.call(arguments, 2) : [], null == c && (c = {}), a = e.extend(!0, {}, this.messageDefaults, this.doDefaults, null != a ? a : {}), n = this._parseEvents(a.events), s = function s(e, t) {
        var n;return n = a[e + "Message"], o.isFunction(n) ? n.call(g, e, t) : n;
      }, l = null != (p = a.messageInstance) ? p : this.newMessage(a), null != a.id && (l.options.id = a.id), null != a.progressMessage && l.update(e.extend({}, a, { message: s("progress", null), type: "info" })), i = {}, o.each(["error", "success"], function (r) {
        var u;return u = c[r], i[r] = function () {
          var i, h, p, m, y, v, _, w, b, x, M, C, k, $, E;return v = arguments.length >= 1 ? d.call(arguments, 0) : [], b = g._normalizeResponse.apply(g, v), y = b[0], i = b[1], w = b[2], "success" === r && null == l.errorCount && a.showSuccessWithoutError === !1 && (a.successMessage = null), "error" === r && (null == (x = a.errorCount) && (a.errorCount = 0), a.errorCount += 1), p = a.returnsPromise ? v[0] : "function" == typeof u ? u.apply(null, v) : void 0, _ = g._getHandlerResponse(p), o.isString(_) && (_ = { message: _ }), "error" !== r || 0 !== (null != w ? w.status : void 0) && "abort" !== y ? "error" === r && null != a.ignoredErrorCodes && (M = null != w ? w.status : void 0, f.call(a.ignoredErrorCodes, M) >= 0) ? (l.hide(), void 0) : (h = { message: s(r, w), type: r, events: null != (C = n[r]) ? C : {}, hideOnNavigate: "success" === r }, m = e.extend({}, a, h, _), "number" == typeof (null != (k = m.retry) ? k.allow : void 0) && m.retry.allow--, "error" === r && (null != w ? w.status : void 0) >= 500 && (null != ($ = m.retry) ? $.allow : void 0) ? (null == m.retry.delay && (m.retry.delay = 4 > m.errorCount ? 10 : 300), m.hideAfter && (null == (E = m._hideAfter) && (m._hideAfter = m.hideAfter), m.hideAfter = m._hideAfter + m.retry.delay), m._retryActions = !0, m.actions = { retry: { label: "retry now", phrase: "Retrying TIME", auto: m.retry.auto, delay: m.retry.delay, action: function action() {
                return m.messageInstance = l, setTimeout(function () {
                  return g["do"].apply(g, [m, c].concat(d.call(t)));
                }, 0);
              } }, cancel: { action: function action() {
                return l.cancel();
              } } }) : m._retryActions && (delete m.actions.retry, delete m.actions.cancel, delete a._retryActions), l.update(m), _ && m.message ? (Messenger(o.extend({}, g.options, { instance: g })), l.show()) : l.hide()) : (l.hide(), void 0);
        };
      }), !a.returnsPromise) for (h in i) {
        r = i[h], u = c[h], c[h] = r;
      }return l._actionInstance = a.action.apply(a, [c].concat(d.call(t))), a.returnsPromise && l._actionInstance.then(i.success, i.error), l;
    }, n.prototype["do"] = n.prototype.run, n.prototype.ajax = function () {
      var t, n;return n = arguments[0], t = arguments.length >= 2 ? d.call(arguments, 1) : [], n.action = e.ajax, this.run.apply(this, [n].concat(d.call(t)));
    }, n.prototype.expectPromise = function (e, t) {
      return t = o.extend({}, t, { action: e, returnsPromise: !0 }), this.run(t);
    }, n.prototype.error = function (e) {
      return null == e && (e = {}), "string" == typeof e && (e = { message: e }), e.type = "error", this.post(e);
    }, n.prototype.info = function (e) {
      return null == e && (e = {}), "string" == typeof e && (e = { message: e }), e.type = "info", this.post(e);
    }, n.prototype.success = function (e) {
      return null == e && (e = {}), "string" == typeof e && (e = { message: e }), e.type = "success", this.post(e);
    }, n;
  }(a), e.fn.messenger = function () {
    var n, s, r, i, l, u, c, h;return r = arguments[0], s = arguments.length >= 2 ? d.call(arguments, 1) : [], null == r && (r = {}), n = this, null != r && o.isString(r) ? (h = n.data("messenger"))[r].apply(h, s) : (l = r, null == n.data("messenger") && (a = null != (u = null != (c = Messenger.themes[l.theme]) ? c.Messenger : void 0) ? u : t, n.data("messenger", i = new a(e.extend({ el: n }, l))), i.render()), n.data("messenger"));
  }, window.Messenger._call = function (t) {
    var n, s, r, o, i, a, l, u, c, h, p;if (a = { extraClasses: "messenger-fixed messenger-on-bottom messenger-on-right", theme: "future", maxMessages: 9, parentLocations: ["body"] }, t = e.extend(a, e._messengerDefaults, Messenger.options, t), null != t.theme && (t.extraClasses += " messenger-theme-" + t.theme), l = t.instance || Messenger.instance, null == t.instance) {
      for (c = t.parentLocations, s = null, r = null, h = 0, p = c.length; p > h; h++) {
        if (u = c[h], s = e(u), s.length) {
          o = u;break;
        }
      }l ? e(l._location).is(e(o)) || (l.$el.detach(), s.prepend(l.$el)) : (n = e("<ul>"), s.prepend(n), l = n.messenger(t), l._location = o, Messenger.instance = l);
    }return null != l._addedClasses && l.$el.removeClass(l._addedClasses), l.$el.addClass(i = "" + l.className + " " + t.extraClasses), l._addedClasses = i, l;
  }, e.extend(Messenger, { Message: r, Messenger: t, themes: null != (c = Messenger.themes) ? c : {} }), e.globalMessenger = window.Messenger = Messenger;
}.call(this);