(function () {
    'use strict';

    function UsersCtrl(Gecko, $state, $stateParams, $geckoModal, usersQuery, users, handleMassAction, geckoDates) {
        var ctrl = this;
        ctrl.users = users.toInstances();

        ctrl.pagination = users.pagination;

        ctrl.userSelects = [];

        ctrl.actionsBtn = {
            title: "Actions on selected",
            icon: "fa-cogs",
            hideWhen: function hideWhen() {
                return !ctrl.userSelects.length;
            }
        };

        ctrl.removeUsers = function (user_ids) {
            if (!user_ids || Array.isArray(user_ids) && !user_ids.length) {
                return;
            }
            var conditions = { id: user_ids };
            var action = { type: Gecko.Workflow.ACTION_DELETE };
            new Gecko.User().massAction(conditions, action).then(function (data) {
                // Handle mass action
                handleMassAction(data, $state.$current.name);
                // Reset selected row
                ctrl.userSelects.length = 0;
            }).catch(GeckoUI.messenger.error);
        };

        ctrl.emailUsers = function (user_ids, message) {
            if (!user_ids || Array.isArray(user_ids) && !user_ids.length) {
                return;
            }
            if (!message) {
                return;
            }
            var conditions = { id: user_ids };
            var action = {
                type: Gecko.Workflow.ACTION_EMAIL,
                body: message.message,
                sender_id: message.sender_id,
                subject: message.subject
            };
            new Gecko.User().massAction(conditions, action).then(function (data) {
                // Handle mass action
                handleMassAction(data, $state.$current.name);
                // Reset selected row
                ctrl.userSelects.length = 0;
            }).catch(GeckoUI.messenger.error);
        };

        ctrl.changeUsersGroup = function (user_ids, group_id) {
            if (!user_ids || Array.isArray(user_ids) && !user_ids.length) {
                return;
            }
            if (!group_id) {
                return;
            }
            var conditions = { id: user_ids };
            var action = { type: Gecko.Workflow.ACTION_ATTRIBUTES, field: 'group_id', value: group_id };
            new Gecko.User().massAction(conditions, action).then(function (data) {
                // Handle mass action
                handleMassAction(data, $state.$current.name);
                // Reset selected row
                ctrl.userSelects.length = 0;
            }).catch(GeckoUI.messenger.error);
        };

        ctrl.actionsBtn.items = [{
            title: 'Email users',
            action: function action() {
                var templateTagModels = [Gecko.Template.TAG_USERS, Gecko.Template.TAG_SENDER];
                $geckoModal.buildEmailModal(undefined, templateTagModels).result.then(function (msg) {
                    GeckoUI.dialog.confirm("Are you sure you want to email " + ctrl.userSelects.length + " Users?", function (confirmed) {
                        if (confirmed) {
                            ctrl.emailUsers(ctrl.userSelects, msg);
                        }
                    });
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_USERS_UPDATE);
            }
        }, {
            title: 'Change user group',
            action: function action() {
                var modalConfig = {
                    title: 'Change to Group',
                    model: 'group',
                    optionsKey: 'id',
                    optionsLabel: 'name'
                };
                $geckoModal.selectorModal(modalConfig).result.then(function (group_id) {
                    GeckoUI.dialog.confirm("Are you sure you want to add " + ctrl.userSelects.length + " Users to this group?", function (confirmed) {
                        if (confirmed) {
                            ctrl.changeUsersGroup(ctrl.userSelects, group_id);
                        }
                    });
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_USERS_UPDATE);
            }
        }, {
            title: 'Archive users',
            action: function action() {
                GeckoUI.dialog.confirm("Are you sure you want to archive " + ctrl.userSelects.length + " Users?", function (confirmed) {
                    if (confirmed) {
                        ctrl.removeUsers(ctrl.userSelects);
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_USERS_DELETE);
            }
        }];

        ctrl.restoreUser = function (user) {
            user.restore().then(function () {
                GeckoUI.messenger.success("User has been restored");
                $state.go($state.$current.name, $stateParams, { reload: true });
            }).catch(GeckoUI.messenger.error);
        };

        ctrl.activateUser = function (user) {
            if (!GeckoUI.ableWithReason(Gecko.ABILITY_USERS_CREATE)) return;
            user.presence = Gecko.User.PRESENCE_OFFLINE;
            user.save().then(function () {
                $state.transitionTo('users');
                GeckoUI.messenger.success('User has been re-activated');
            }).catch(GeckoUI.messenger.error);
        };

        ctrl.filters = [{
            title: 'Status',
            type: 'radio',
            options: [{ label: 'Active', value: 'active' }, { label: 'Pending', value: 'invited' }, { label: 'Deactivated', value: 'deactivated' }, { label: 'Archived', value: 'archived' }],
            stateParam: 'status'
        }, {
            title: 'User Groups',
            type: 'dropdown',
            optionsQuery: new Gecko.Group().rfields({ group: ['name'] }).orderBy('name'),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            stateParam: 'group'
        }];

        // Table structure
        ctrl.cols = [{
            key: 'system_status',
            colClass: 'text-center',
            expandable: true,
            status_title_none: true,
            status_styles: [{
                id: 'active',
                label: 'success',
                title: 'Active'
            }, {
                id: 'invited',
                label: 'warning',
                title: 'Pending'
            }, {
                id: 'archived',
                label: 'default',
                title: 'Archived'
            }, {
                id: 'deactivated',
                label: 'danger',
                title: 'Deactivated'
            }]
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            sortKey: 'created_at'
            //ColClass: 'col-xs-3'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Name',
            key: 'full_name',
            sortKey: 'full_name',
            colClass: 'col-xs-4',
            renderHtml: true,
            render: function render(name, value, user) {
                if (user.deleted_at) {
                    return user.full_name + " <span class=\"badge-archived\">Archived</span>";
                }
                return user.full_name;
            }
        }, {
            title: 'Email',
            key: 'email',
            sortKey: 'email',
            colClass: 'col-xs-4'
        }, {
            title: 'Group',
            key: 'related_group.name',
            colClass: 'col-xs-4'
        }, {
            data_type: Gecko.Field.TYPE_DATETIME,
            title: 'Last active',
            key: 'last_active',
            sortKey: 'last_active'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'user.settings',
            params: { user_id: 'id' }
        };

        // Cog drop down
        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen(user, index) {
                for (var i = 0; i < this.items.length; i++) {
                    if (!this.items[i].hideWhen(user, index)) {
                        return false;
                    }
                }
                return true;
            }
        };
        ctrl.rowOptionsBtn.items = [{
            title: 'Restore user',
            action: function action(user) {
                ctrl.restoreUser(user);
            },
            hideWhen: function hideWhen(user) {
                return !Gecko.able(Gecko.ABILITY_USERS_CREATE) || !user.deleted_at;
            }
        }, {
            title: 'Re-Activate',
            action: function action(user) {
                ctrl.activateUser(user);
            },
            hideWhen: function hideWhen(user) {
                return user.presence !== Gecko.User.PRESENCE_DEACTIVATED;
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Users',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('UsersCtrl', UsersCtrl);
})();