(function () {
    'use strict';

    function AttachmentsCtrl(files, $filter, $scope, $state, $stateParams, entityData, $geckoModal, fileAvService) {
        var ctrl = this;

        ctrl.files = files.toInstances();
        ctrl.pagination = files.pagination;

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'User',
            key: 'related_user.full_name',
            colClass: 'col-xs-3'
        }, {
            title: 'Title',
            key: 'title',
            colClass: !+$stateParams.include_contacts ? 'col-xs-7' : 'col-xs-4'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Contact',
            key: 'related_contact.full_name',
            colClass: 'col-xs-3',
            hideWhen: function hideWhen() {
                return !+$stateParams.include_contacts;
            }
        }, {
            title: 'File type',
            key: 'mime',
            colClass: 'col-xs-1',
            render: function render(value) {
                if (value.indexOf('video') != -1) {
                    return 'Video';
                } else if (value.indexOf('document') != -1) {
                    return 'Document';
                } else if (value.indexOf('pdf') != -1) {
                    return 'PDF';
                } else if (value.indexOf('image') != -1) {
                    return 'Image';
                } else if (value.indexOf('audio') != -1) {
                    return 'Audio';
                }
                return 'n/a';
            }
        }];

        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Rename',
            action: function action(file) {

                if (!GeckoUI.ableWithReason($state.current.editRequires)) return;

                $geckoModal.inputModal('Rename attachment', file.title).result.then(function (title) {
                    if (title !== file.title) {
                        file.title = title;
                        file.include('user', 'contact').save().then(function () {
                            GeckoUI.messenger.success('Attachment successfully renamed');
                        }).catch(function (error) {
                            GeckoUI.messenger.error(error.errors);
                        });
                    }
                });
            }
        }, {
            title: 'View attachment',
            action: function action(file) {
                return $geckoModal.fileView([file.download_url]);
            }
        }, {
            title: 'Download attachment',
            action: function action(file) {
                return fileAvService.downloadFileWithAvStatusCheck(file.download_url);
            },
            hideWhen: function hideWhen(file) {
                return file.status !== Gecko.File.ACTIVE;
            }
        }, {
            title: 'Remove attachment',
            action: function action(file, index) {

                if (!GeckoUI.ableWithReason($state.current.editRequires)) return;

                GeckoUI.dialog.confirm('Are you sure you want to delete this attachment.', function (ok) {
                    if (!ok) return;

                    file.remove().then(function () {
                        GeckoUI.messenger.success('Attachment successfully removed');
                        ctrl.files.splice(index, 1);
                        $scope.$digest();
                    });
                });
            }
        }];

        // Add response
        ctrl.headerBtns = [{
            title: 'Attach file',
            icon: 'fa-paperclip',
            action: function action() {

                if (!GeckoUI.ableWithReason($state.current.editRequires)) return;

                return $geckoModal.attachment(entityData.type, entityData.value).result.then(function (file) {
                    //ctrl.files.unshift(file);
                    $state.go($state.$current.name, $stateParams, { reload: true });
                });
            }
        }];

        if (entityData.type === 'organisation') {
            ctrl.headerBtns.push({
                title: 'Include contacts',
                icon: 'fa-users',
                action: function action() {
                    $state.go($state.current.name, { include_contacts: +!+$stateParams.include_contacts });
                },
                activeWhen: function activeWhen() {
                    return +$stateParams.include_contacts;
                },
                btnClass: (+$stateParams.include_contacts ? 'active' : '') + ' btn-default'
            });
        }
    }

    angular.module('GeckoEngage').controller('AttachmentsCtrl', AttachmentsCtrl);
})();