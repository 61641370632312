(function () {
    'use strict';

    function UsageRoute($stateProvider) {
        $stateProvider.state('usageandcosts', {
            url: "/settings/usage-and-costs",
            templateUrl: "/modules/usage_and_costs/views/index.45474965.html",
            controller: 'SettingsUsageCtrl',
            controllerAs: 'ctrl',
            page_title: 'Usage & Costs',
            requires: [Gecko.ABILITY_TRANSACTIONS_VIEW],
            resolve: {}

        }).state('usageandcosts.transactions', {
            url: "/transactions?{page}&{sort}&{order}&{to}&{from}",
            templateUrl: "/modules/usage_and_costs/views/transactions.5d347f99.html",
            controller: 'UsageTransactionsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Usage & Costs',
            redirectKey: 'module_usage_transactions',
            requires: [Gecko.ABILITY_TRANSACTIONS_VIEW],
            resolve: {
                transactions: function transactions($stateParams) {
                    var transactions = new Gecko.Transaction();
                    if ($stateParams.order) transactions.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    if ($stateParams.from && $stateParams.to) transactions.dates($stateParams.from, $stateParams.to);
                    return transactions.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ transaction: ['status', 'created_at', 'type', 'type_title', 'value', 'items', 'download_url'] }).get();
                }
            }

        }).state('usageandcosts.voipusage', {
            url: "/calls?{page}&{sort}&{order}&{user}&{campaign}&{type}&{filter_id}{to}&{from}",
            templateUrl: "/modules/usage_and_costs/views/voip.ff4cec8c.html",
            controller: 'UsageVoipCtrl',
            controllerAs: 'ctrl',
            page_title: 'Usage & Costs',
            redirectKey: 'module_usage_call',
            requires: [Gecko.ABILITY_TRANSACTIONS_VIEW],
            resolve: {
                stats: function stats($stateParams) {
                    var filter = $stateParams;
                    if ($stateParams.from && $stateParams.to) {
                        filter.override_date = $stateParams.from + ':' + $stateParams.to;
                    }

                    return new Gecko.Stat().group('voip', filter);
                },
                calls: function calls($stateParams) {
                    if (!Gecko.User.hasOutboundOrInbound()) {
                        return;
                    }
                    var calls = new Gecko.Call().include('user', 'contact', 'number', 'campaign').where('billing_status', [Gecko.Call.BILLING_AVAILABLE, Gecko.Call.BILLING_PROCESSED]);

                    if ($stateParams.user) {
                        calls.where('user_id', $stateParams.user);
                    }
                    if ($stateParams.campaign) {
                        calls.where('campaign_id', $stateParams.campaign);
                    }
                    if ($stateParams.type) {
                        calls.where('type', $stateParams.type);
                    }
                    if ($stateParams.filter_id) {
                        calls.where('contact_filter_id', $stateParams.filter_id);
                    }

                    if ($stateParams.from && $stateParams.to) calls.dates($stateParams.from, $stateParams.to);

                    if ($stateParams.order) calls.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');

                    return calls.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ user: ['full_name'], contact: ['full_name'], call: ['billing_status', 'started_at', 'type', 'number', 'campaign', 'call_time', 'cost'], campaign: ['title'] }).get();
                }
            }

        }).state('usageandcosts.sms', {
            url: "/sms?{page}&{sort}&{order}&{to}&{from}&{campaign}",
            templateUrl: "/modules/usage_and_costs/views/sms.92059dee.html",
            controller: 'UsageSmsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Usage & Costs',
            redirectKey: 'module_usage_sms',
            requires: [Gecko.ABILITY_TRANSACTIONS_VIEW],
            resolve: {
                stats: function stats($stateParams) {
                    var filter = $stateParams;
                    if ($stateParams.from && $stateParams.to) {
                        filter.override_date = $stateParams.from + ':' + $stateParams.to;
                    }

                    return new Gecko.Stat().group('sms', filter);
                },
                sms: function sms($stateParams) {
                    var sms = new Gecko.Message();
                    if ($stateParams.order) sms.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');

                    if ($stateParams.from && $stateParams.to) sms.dates($stateParams.from, $stateParams.to);

                    if ($stateParams.campaign) {
                        sms.where('message_campaign_id', $stateParams.campaign);
                    }
                    return sms.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).where('type', 'sms').where('direction', Gecko.Message.TYPE_OUTBOUND).rfields({ message: ['recipients', 'body', 'contact_id', 'created_at', 'cost'] }).get();
                }
            }

        });
    }

    angular.module('GeckoEngage.routes').config(UsageRoute);
})();