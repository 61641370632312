(function () {
    'use strict';

    function geckoValue($templateRequest, $compile, $filter) {

        return {
            restrict: 'E',
            templateUrl: '/components/gecko-value/gecko-value.html',
            scope: {
                label: '@',
                value: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop
        };
    }

    angular.module('GeckoEngage').directive('geckoValue', geckoValue);
})();