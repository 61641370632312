(function () {
    'use strict';

    function LabelsRoute($stateProvider) {
        $stateProvider.state('settingslabels', {
            url: "/settings/labels?{page}&{sort}&{order}&{search}",
            templateUrl: "/modules/labels/views/index.5a1e1a82.html",
            controller: 'SettingsLabelsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Labels',
            redirectKey: 'module_labels',
            resolve: {
                labels: function labels($stateParams) {
                    var labels = new Gecko.Label();
                    if ($stateParams.search) labels.where('keyword', $stateParams.search);
                    if ($stateParams.order) {
                        labels.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    } else {
                        labels.orderBy('name');
                    }
                    return labels.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ label: ['name', 'color'] }).get();
                }
            }
        }).state('settingslabel', {
            url: "/settings/labels/{label_id}",
            templateUrl: "/modules/labels/views/edit.d49cad86.html",
            controller: 'SettingsLabelCtrl',
            controllerAs: 'ctrl',
            page_title: 'Label',
            requires: [function ($stateParams) {
                return $stateParams.label_id === 'new' ? Gecko.ABILITY_LABELS_CREATE : Gecko.ABILITY_LABELS_UPDATE;
            }],
            redirectKey: 'module_label',
            resolve: {
                label: function label($stateParams) {
                    if ($stateParams.label_id != 'new') {
                        return new Gecko.Label().get($stateParams.label_id);
                    } else {
                        return new Gecko.Label();
                    }
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(LabelsRoute);
})();