(function () {
    'use strict';

    angular.module('GeckoEngage.data').factory('asyncCache', asyncCache);

    //////////////////////////////////////////////////

    function asyncCache($timeout, $q) {

        // Defines key for the cache from a getter and an id
        function cacheKey(getter, id) {
            var key = '';

            key += getter.base_slug;

            Object.keys(getter.params).sort().forEach(function (k) {
                var params = getter.params[k];
                if (params instanceof Array) {
                    params.sort();
                    params = params.join(',');
                }

                key += '/' + k + '=' + params;
            });

            if (getter.includes && getter.includes.length) {
                key += '/include=' + getter.includes.join(',');
            }

            if (getter._path) {
                key += getter._path;
            }

            if (id) {
                key += '/' + id;
            }

            return key;
        }

        function Bundle() {
            this.content = {};
            this.defereds = {};
            this.timeout;
        }

        Bundle.prototype.add = function (key, getter) {
            var self = this;

            // Set a timeout if we don't have one in action.
            if (!this.timeout) {
                this.timeout = $timeout(function () {
                    var defereds = self.defereds;
                    // Send multi request
                    Gecko.multi(self.content).then(function (results) {
                        angular.forEach(results, function (val, key) {
                            defereds[key].resolve(val);
                        });
                    });
                    // Clear data
                    self.content = {};
                    self.defereds = {};
                    self.timeout = null;
                }, 500);
            }
            this.content[key] = getter;
            this.defereds[key] = $q.defer();
            return this.defereds[key].promise;
        };

        var cache = {};
        var bundle = new Bundle();

        return {
            get: function get(getter, id) {
                getter = angular.copy(getter);
                var key = cacheKey(getter, id);

                // Query is in cache
                if (key in cache && moment().isBefore(cache[key].expires)) {
                    return cache[key].promise;
                }

                // Query not in cache add to Bundle
                if (id) getter.path(id);
                var promise = bundle.add(key, getter);
                cache[key] = {
                    promise: promise,
                    expires: moment().add(30, 'seconds')
                };

                return promise;
            }
        };
    }
})();