(function () {
    'use strict';

    function EventsDeletedCtrl($state, deleted, LoadingIndicator, geckoDates) {
        var ctrl = this;
        ctrl.deleted = deleted.toArray();
        ctrl.pagination = deleted.pagination;

        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Restore Event',
            action: function action(event) {
                GeckoUI.dialog.confirm('Are you sure you want to restore this event?', function (ok) {
                    if (ok) {
                        LoadingIndicator.resolve(new Gecko.Event().call('/events/' + event.id + '/restore', 'POST')).then(function () {
                            GeckoUI.messenger.success('Your event has been restored');
                            $state.go('events.list');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        }];

        ctrl.cols = [{
            title: 'Name',
            key: 'nice_title',
            sortKey: 'title',
            colClass: 'col-xs-3'
        }, {
            title: 'Start date',
            key: 'start_datetime',
            sortKey: 'start_datetime',
            colClass: 'col-xs-3',
            render: function render(value) {
                return geckoDates.printDateString(value);
            }

        }, {
            title: 'Timezone',
            key: 'timezone',
            colClass: 'col-xs-3'
        }, {
            title: 'Attendees',
            key: 'deleted_attendances_count',
            colClass: 'col-xs-3'
        }, {
            title: 'Days Remaining',
            key: 'days_remaining',
            colClass: 'col-xs-3'
        }];
    }

    angular.module('GeckoEngage').controller('EventsDeletedCtrl', EventsDeletedCtrl);
})();