(function () {

    function TransactionModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('transactionModal', function (_transaction) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/modules/usage_and_costs/views/_modals/transaction_view.8d134de7.html',
                controller: 'TransactionModalCtrl',
                resolve: {
                    transaction: function transaction() {
                        return _transaction;
                    }
                }
            };
        });
    }

    function TransactionModalCtrl($scope, $state, $stateParams, $modalInstance, transaction) {
        var ctrl = this;
        ctrl.transaction = transaction;

        // Table structure
        ctrl.cols = [{
            title: 'Title',
            key: 'title',
            colClass: 'col-xs-4'
        }, {
            title: 'Quantity',
            key: 'quantity',
            colClass: 'col-xs-4'
        }, {
            data_type: Gecko.Field.DATA_TYPE_CURRENCY,
            title: 'Amount',
            key: 'total',
            colClass: 'col-xs-4'
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            preset: 'close',
            action: function action() {
                $modalInstance.dismiss();
            }
        }];
    }

    angular.module('GeckoEngage').config(TransactionModals).controller('TransactionModalCtrl', TransactionModalCtrl);
})();