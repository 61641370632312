(function () {
    'use strict';

    var comparisons = {
        '>': function _(a, b) {
            return a > b;
        },
        '<': function _(a, b) {
            return a < b;
        }
    };

    function affix($document, $window, affix) {

        var affixParse = /(top|bottom)\s+of\s+(\S+)\s?(>|<)\s?(top|bottom)\s+of\s+(\S+)/;

        function copyMargin(from, to) {

            to.css('margin', from.css('margin'));
            to.css('margin-top', from.css('margin-top'));
            to.css('margin-left', from.css('margin-left'));
            to.css('margin-right', from.css('margin-right'));
            to.css('margin-bottom', from.css('margin-bottom'));
        }

        return {
            restrict: 'A',
            controller: function affixController() {},
            link: function link(scope, iElement, iAttrs, ctrl) {

                var optionsParse = affixParse.exec(iAttrs.affix);
                if (!optionsParse) return;

                var options = {
                    markerSide: optionsParse[1],
                    marker: optionsParse[2],
                    comparison: optionsParse[3],
                    targetSide: optionsParse[4],
                    target: optionsParse[5]
                };

                ctrl.spaceHolder = angular.element('<div>');
                ctrl.spaceHolder.css('display', 'none');
                iElement.after(ctrl.spaceHolder);

                ctrl.isAffixed = false;
                function updatePosition() {
                    if (!affix.has(options.marker) || !affix.has(options.target)) return;
                    var markerPosition = affix.get(options.marker)[options.markerSide];
                    var otherPosition = affix.get(options.target)[options.targetSide];

                    var nowAffixed = comparisons[options.comparison](markerPosition, otherPosition);

                    var hasAffixed = !ctrl.isAffixed && nowAffixed;

                    if (hasAffixed) {
                        var myPosition = iElement[0].getBoundingClientRect();
                        copyMargin(iElement, ctrl.spaceHolder);
                        ctrl.spaceHolder.css('display', 'block');
                        iElement.addClass('affix');
                        iElement.css('width', ctrl.spaceHolder.css('width'));
                        ctrl.spaceHolder.height(myPosition.height);
                    }

                    var nowUnAffixed = !nowAffixed;

                    var hasUnAffixed = ctrl.isAffixed && nowUnAffixed;

                    if (hasUnAffixed) {
                        ctrl.spaceHolder.css('display', 'none');
                        iElement.removeClass('affix');
                        iElement.css('width', '');
                    }

                    ctrl.isAffixed = nowAffixed;
                }

                updatePosition();

                $document.on('scroll', updatePosition);

                function resizeHandler() {
                    if (!ctrl.isAffixed) return;
                    var myPosition = iElement[0].getBoundingClientRect();
                    iElement.css('width', ctrl.spaceHolder.css('width'));
                    ctrl.spaceHolder.height(myPosition.height);
                }

                angular.element($window).on('resize', resizeHandler);

                scope.$on('$destroy', function () {
                    $document.off('scroll', updatePosition);
                    angular.element($window).off('resize', resizeHandler);
                    ctrl.spaceHolder.remove();
                });

                ctrl.calculateOffset = function (child) {
                    var position = iElement[0].getBoundingClientRect();
                    var childPosition = child[0].getBoundingClientRect();

                    var spaceHolderPosition = ctrl.spaceHolder[0].getBoundingClientRect();

                    return {
                        top: spaceHolderPosition.top + (childPosition.top - position.top),
                        left: spaceHolderPosition.left + (childPosition.left - position.left),
                        right: spaceHolderPosition.right + (childPosition.right - position.right),
                        bottom: spaceHolderPosition.bottom + (childPosition.bottom - position.bottom)
                    };
                };
            }
        };
    }

    function affixMarker($document, affix) {

        return {
            restrict: 'A',
            require: '?^affix',
            link: function link(scope, iElement, iAttrs, affixController) {
                var updatePosition;
                if (affixController) {
                    updatePosition = function updatePosition() {
                        if (affixController.isAffixed) {
                            var offset = affixController.calculateOffset(iElement);
                            affix.set(iAttrs.affixMarker, offset);
                        } else {
                            affix.set(iAttrs.affixMarker, iElement[0].getBoundingClientRect());
                        }
                    };
                } else {
                    updatePosition = function updatePosition() {
                        affix.set(iAttrs.affixMarker, iElement[0].getBoundingClientRect());
                    };
                }

                affix.register(iAttrs.affixMarker, updatePosition);
                updatePosition();

                scope.$on('$destroy', function () {
                    affix.clear(iAttrs.affixMarker);
                });
            }
        };
    }

    function affixService() {

        var markers = {};
        return {
            register: function register(name, func) {
                markers[name] = {
                    update: func,
                    position: null
                };
            },
            has: function has(name) {
                return markers[name];
            },
            set: function set(name, position) {
                markers[name].position = position;
            },
            get: function get(name) {
                markers[name].update();
                return markers[name].position;
            },
            clear: function clear(name) {
                delete markers[name];
            }
        };
    }

    angular.module('GeckoEngage').directive('affix', affix).directive('affixMarker', affixMarker).factory('affix', affixService);
})();