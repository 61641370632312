(function () {
    'use strict';

    function ContactCallsCtrl($geckoModal, $geckoChannel, $state, contact, calls) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.calls = calls.toInstances();
        ctrl.pagination = calls.pagination;

        // Sync data
        $geckoChannel.register('contact.calls', ['calls'], ctrl);
        $geckoChannel.set('calls', ctrl.calls);

        // Check if any recordings available
        var hasRecordings = function hasRecordings() {
            var _has = false;
            angular.forEach(ctrl.calls, function (call) {
                if (call.recording_url_mp3) _has = true;
            });
            return _has;
        };

        // Table structure
        ctrl.cols = [{
            key: 'type',
            colClass: 'text-center',
            icon_styles: [{
                id: Gecko.Call.TYPE_OUTBOUND,
                icon: 'fa-arrow-right',
                title: 'Outbound'
            }, {
                id: Gecko.Call.TYPE_INBOUND,
                icon: 'fa-arrow-left',
                title: 'Inbound'
            }]
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Started at',
            key: 'started_at',
            sortKey: 'created_at',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'User',
            key: 'related_user.full_name',
            colClass: 'col-xs-3',
            action: function action(row) {
                return $state.go('user', { user_id: row.related_user.id });
            }
        }, {
            title: 'Campaign',
            key: 'related_campaign.title',
            colClass: 'col-xs-3',
            action: function action(row) {
                if (!row.related_campaign) return false;
                return $state.go('campaignsedit.view', { module: Gecko.Campaign.MODULE_CALL, campaign_id: row.related_campaign.id });
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            humanize: true,
            title: 'Duration',
            key: 'call_time',
            sortKey: 'call_time',
            colClass: 'col-xs-3'
        }, {
            title: 'Call recordings',
            key: 'recording_url_mp3',
            colClass: 'text-center col-xs-3',
            type: Gecko.Field.TYPE_AUDIO,
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CALLS_LISTEN) || !hasRecordings();
            }
        }, {
            key: 'related_outcome.name',
            status_title_none: true,
            status_key: 'related_outcome.type',
            expandable: true,
            status_styles: [{
                id: 0,
                label: 'label-danger'
            }, {
                id: 1,
                label: 'label-success'
            }],
            render: function render(value, col, call) {
                if (!call.outcome_id && call.status == Gecko.Call.ABORTED) return 'Aborted';
                return value;
            },
            hideStatusWhen: function hideStatusWhen(col, call) {
                return !call.outcome_id;
            }
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Edit call',
            icon: 'fa-pencil',
            action: function action(call, index) {
                $geckoModal.CallOutcomeModal(call).result.then(function (call) {
                    ctrl.calls[index] = call;
                });
            }
        }];

        // Add response
        ctrl.headerBtns = [{
            title: 'Add call',
            icon: 'fa-plus',
            action: function action() {
                $geckoModal.CallOutcomeModal(false, ctrl.contact).result.then(function (call) {
                    ctrl.calls.unshift(call);
                });
            }
        }];
    }

    angular.module('GeckoEngage').controller('ContactCallsCtrl', ContactCallsCtrl);
})();