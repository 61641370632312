(function () {
    'use strict';

    /** @typedef {"ANY"|"ALL"|null} ConditionOperator
      /**
     @typedef getContactsRequest
     * @type {object}
     * @property {Number} order
     * @property {"ASC"|"DESC"|undefined} sort
     * @property {Number|undefined} page
     * @property {Number|undefined} perPage
     * @property {Number|undefined} filterId
     * @property {array|undefined} conditions
     * @property {string|undefined} search
     * @property {ConditionOperator} conditionOperator
     * @property {Number[]|undefined} searchLabels
     * @property {ConditionOperator} labelOperator
     * @property {string|string[]|undefined} includes
     * @property {string|string[]|undefined} rFields
     * @property {Object[]|undefined} contactFields- An array of Gecko Field Objects
     * @property {Object[]|undefined} fieldList - An array of Gecko Field Objects
     * @property {Object[]|undefined} labelList - An array of Gecko Label Objects
     * @property {bool} convertToResult
     **/

    function getContactsService(contactSearchService, geckoDataService) {

        var setPaging = function setPaging(page, perPage) {
            page = page ? page : 1;
            perPage = perPage ? perPage : Gecko.DEFAULT_PER_PAGE;
            return { page: page, perPage: perPage };
        };

        var shouldUseSearchService = function shouldUseSearchService(conditions, search, searchLabels, filterId) {
            var hasConditions = conditions && conditions.length;
            var params = hasConditions ? {
                search: null,
                label: null
            } : {
                search: search,
                label: searchLabels
            };
            // use the filter ULID for the check else canUse may pass, but the search itself fail
            return contactSearchService.canUse(params, conditions, getFilterUlid(filterId));
        };

        var getFilterUlid = function getFilterUlid(filterId) {
            var filter = void 0;

            if (filterId) {
                var fromStore = geckoDataService.fetchFromStoreSync('filters');
                if (fromStore.filters && fromStore.filters.length) {
                    filter = fromStore.filters.find(function (filter) {
                        return filter.id === parseInt(filterId);
                    });
                }
                if (!filter) {
                    console.error('No filter in store with id ' + filterId);
                }
            }
            return (filter || {}).ulid;
        };

        var _this = {
            /** @jsdoc
             * @param {getContactsRequest} getContactsRequest
             * @returns Promise<any>
             * @param {boolean} forceEngage - set to true to force Engage to be used
             */
            getContacts: function getContacts(_ref) {
                var order = _ref.order,
                    sort = _ref.sort,
                    page = _ref.page,
                    perPage = _ref.perPage,
                    filterId = _ref.filterId,
                    conditions = _ref.conditions,
                    search = _ref.search,
                    conditionOperator = _ref.conditionOperator,
                    includes = _ref.includes,
                    rFields = _ref.rFields,
                    numberId = _ref.numberId,
                    searchLabels = _ref.searchLabels,
                    labelOperator = _ref.labelOperator,
                    contactFields = _ref.contactFields,
                    fieldList = _ref.fieldList,
                    labelList = _ref.labelList,
                    _ref$convertToResult = _ref.convertToResult,
                    convertToResult = _ref$convertToResult === undefined ? true : _ref$convertToResult;
                var forceEngage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;


                var paging = setPaging(page, perPage);

                if (conditions && conditions.length && !conditionOperator) {
                    conditionOperator = 'ANY';
                }

                if (!forceEngage && shouldUseSearchService(conditions, search, searchLabels, filterId)) {
                    return buildSearchRequest({
                        contactSearchService: contactSearchService,
                        order: order,
                        sort: sort,
                        page: paging.page,
                        perPage: paging.perPage,
                        filterId: getFilterUlid(filterId),
                        conditions: conditions,
                        conditionOperator: conditionOperator,
                        search: search,
                        searchLabels: searchLabels,
                        labelOperator: labelOperator,
                        contactFields: contactFields,
                        fieldList: fieldList,
                        labelList: labelList || [],
                        convertToResult: convertToResult
                    });
                } else {
                    return buildEngageRequest({
                        order: order,
                        sort: sort,
                        page: paging.page,
                        perPage: paging.perPage,
                        filterId: filterId,
                        conditions: conditions,
                        conditionOperator: conditionOperator,
                        search: search,
                        searchLabels: searchLabels,
                        includes: includes,
                        labelOperator: labelOperator,
                        rFields: rFields,
                        numberId: numberId
                    });
                }
            },
            checkConditionsValid: function checkConditionsValid(conditions) {
                if (!conditions || !conditions.length) {
                    return false;
                }
                var hasMalformedConditions = !!conditions.filter(function (condition) {
                    var hasNoMatchType = !condition.type;
                    var hasNoModel = !condition.model && !condition.property;
                    var valuelessMatchTypes = ['--', '!--'];

                    var hasNoValue = void 0;

                    if (valuelessMatchTypes.indexOf(condition.type) > -1) {
                        hasNoValue = false;
                    } else {
                        hasNoValue = Array.isArray(condition.value) ? !condition.value.length : !condition.value && !condition.in_filter && !condition.import_id;
                    }

                    return hasNoMatchType || hasNoModel || hasNoValue;
                }).length;

                return !hasMalformedConditions;
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').factory('getContactsService', getContactsService);
})();

var buildEngageRequest = function buildEngageRequest(_ref2) {
    var order = _ref2.order,
        sort = _ref2.sort,
        page = _ref2.page,
        perPage = _ref2.perPage,
        filterId = _ref2.filterId,
        search = _ref2.search,
        conditionOperator = _ref2.conditionOperator,
        includes = _ref2.includes,
        conditions = _ref2.conditions,
        rFields = _ref2.rFields,
        numberId = _ref2.numberId,
        searchLabels = _ref2.searchLabels,
        labelOperator = _ref2.labelOperator;

    var contactQuery = new Gecko.Contact().rfields(rFields).orderBy(order, sort).perPage(perPage).page(page);

    if (searchLabels) {
        contactQuery.where('contact_label_id', searchLabels, labelOperator === 'all');
    }
    if (numberId) {
        contactQuery.where('number_id', numberId);
    }
    if (search) {
        contactQuery.where('contact_keyword', search);
    }
    if (filterId) {
        contactQuery.where('filter_id', filterId);
    }
    if (includes) {
        contactQuery.include(includes);
    }

    if (conditions && conditions.length) {
        contacts = contactQuery.search(conditions, conditionOperator);
    } else {
        contacts = contactQuery.getCollection();
    }

    return contacts.then(function (res) {
        return {
            data: res.data,
            pagination: res.pagination
        };
    });
};

var buildSearchRequest = function buildSearchRequest(_ref3) {
    var contactSearchService = _ref3.contactSearchService,
        order = _ref3.order,
        sort = _ref3.sort,
        page = _ref3.page,
        perPage = _ref3.perPage,
        filterId = _ref3.filterId,
        conditions = _ref3.conditions,
        conditionOperator = _ref3.conditionOperator,
        search = _ref3.search,
        searchLabels = _ref3.searchLabels,
        labelOperator = _ref3.labelOperator,
        contactFields = _ref3.contactFields,
        fieldList = _ref3.fieldList,
        labelList = _ref3.labelList,
        convertToResult = _ref3.convertToResult;


    return contactSearchService.search({
        keyword: search,
        page: page,
        perPage: perPage,
        direction: sort,
        orderBy: contactSearchService.getOrder(order, fieldList),
        labels: searchLabels,
        labelOperator: labelOperator,
        conditions: (conditions || []).map(function (condition) {
            if (!condition.contact_field_id) {
                return condition;
            }
            condition.fieldType = (contactFields.find(function (field) {
                return field.id === condition.contact_field_id;
            }) || {}).data_type;
            return condition;
        }),
        conditionOperator: conditionOperator,
        savedSearchId: filterId
    }).then(function (res) {
        var contacts = convertToResult ? contactSearchService.contactsToResult(res.data, labelList, fieldList) : contactSearchService.parseContacts(res.data);
        return {
            data: contacts,
            pagination: res.pagination
        };
    });
};