(function () {
    'use strict';

    function SearchConditionsModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('searchConditions', function (_conditions, _hideLogicOperator) {
            return {
                size: 'lg',
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/search-conditions/search-conditions-modal.html',
                controller: 'searchConditionsModalCtrl',
                resolve: {
                    conditions: function conditions() {
                        return _conditions;
                    },
                    hideLogicOperator: function hideLogicOperator() {
                        return _hideLogicOperator;
                    }
                }
            };
        });
    }

    function searchConditionsModalCtrl($modalInstance, conditions, hideLogicOperator, $scope) {

        var ctrl = this;

        $scope.$watch('form.$dirty', function (newVal) {
            $modalInstance.conditionsDirty = newVal;
        });

        ctrl.conditions = conditions || [];
        ctrl.hideLogicOperator = hideLogicOperator;

        ctrl.footerBtns = [{
            preset: 'save',
            title: 'Done',
            action: function action() {
                $modalInstance.close();
            }
        }];
    }

    angular.module('GeckoEngage').config(SearchConditionsModal).controller('searchConditionsModalCtrl', searchConditionsModalCtrl);
})();