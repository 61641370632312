(function () {
    'use strict';

    function NumberStats() {

        function set(dst, array) {
            dst = dst || Object.create(null);

            array.forEach(function (val) {
                dst[val] = true;
            });

            return dst;
        }

        return {
            init: function NumberStatsInit(numbers) {

                var numberMap = {};
                var agentReadyMap = Object.create(null);
                var agentOnlineMap = Object.create(null);

                var totalQueue = 0;

                numbers.forEach(function (number) {
                    numberMap[number.id] = number;

                    totalQueue += number.queue_length;

                    number.available_incoming_users.forEach(function (userId) {
                        agentReadyMap[userId] = true;
                    });

                    number.online_incoming_users.forEach(function (userId) {
                        agentOnlineMap[userId] = true;
                    });
                });

                return {
                    map: numberMap,
                    ready: agentReadyMap,
                    online: agentOnlineMap,
                    totalQueue: totalQueue
                };
            },
            update: function NumberStatsUpdate(numStats, data) {

                if (!numStats.map[data.sender_id]) return numStats;

                // Update callers in queue total
                numStats.totalQueue = numStats.totalQueue - numStats.map[data.sender_id].queue_length + data.length;
                numStats.map[data.sender_id].queue_length = data.length;
                numStats.map[data.sender_id].online_incoming_users = data.online_ids;
                numStats.map[data.sender_id].available_incoming_users = data.user_ids;

                numStats.online = Object.create(null);
                numStats.ready = Object.create(null);

                angular.forEach(numStats.map, function (number) {

                    set(numStats.online, number.online_incoming_users);
                    set(numStats.ready, number.available_incoming_users);
                });

                return numStats;
            }
        };
    }

    angular.module('GeckoEngage').factory('NumberStats', NumberStats);
})();