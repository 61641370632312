(function () {
    'use strict';

    function eventSessionModalAttendees() {

        return {
            restrict: 'E',
            scope: {
                session: '='
            },
            template: '<gecko-fields fields="ctrl.fields" values="ctrl.session"></gecko-fields>',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: eventSessionModalAttendeesCtrl
        };
    }

    function eventSessionModalAttendeesCtrl() {
        var ctrl = this;

        ctrl.fields = [{
            id: 'auto_waitlist',
            label: 'Auto Waitlist',
            colClass: 'col-md-6 col-xs-12',
            type: Gecko.Field.TYPE_TOGGLE,
            description: 'This setting toggles waitlisting for all times within this session.',
            trueValue: true,
            falseValue: false
        }, {
            id: 'maximum',
            label: 'Maximum Attendees',
            colClass: 'col-md-6 col-xs-12',
            description: 'This setting controls the maximum attendees (including guests) for all Session Times within this session. ',
            type: Gecko.Field.TYPE_NUMBER
        }];
    }

    angular.module('GeckoEngage').directive('eventSessionModalAttendees', eventSessionModalAttendees).controller('eventSessionModalAttendeesCtrl', eventSessionModalAttendeesCtrl);
})();