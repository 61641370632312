(function () {
    'use strict';

    function SettingsLabelsCtrl($state, labels) {
        var ctrl = this;
        ctrl.labels = labels.toArray();
        ctrl.pagination = labels.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'name',
            sortKey: 'name',
            colClass: 'col-xs-6'
        }, {
            title: 'Color',
            key: 'color',
            colClass: 'col-xs-6',
            status_styles: []
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'settingslabel',
            params: { label_id: 'id' }
        };

        // Table row option buttons
        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_LABELS_DELETE);
            }
        };
        ctrl.rowOptionsBtn.items = [{
            title: 'Remove label',
            action: function action(label) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this label?', function (value) {
                    if (value) {
                        new Gecko.Label().get(label.id).then(function (label) {
                            label.remove().then(function () {
                                GeckoUI.messenger.success('Label has been deleted');
                                $state.reload();
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Labels',
            active: true
        }];
    }

    function SettingsLabelCtrl($state, $stateParams, unsavedAlert, label, geckoDataService) {
        var ctrl = this;
        ctrl.label = label;

        // Field list
        ctrl.fields = [{
            id: 'name',
            label: 'Name',
            required: true
        }, {
            id: 'color',
            label: 'Color',
            type: Gecko.Field.TYPE_COLOR,
            required: true
        }];

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                ctrl.label.save().then(function (label) {
                    GeckoUI.messenger.success('Label has been saved');
                    unsavedAlert.clear();
                    if ($stateParams.label_id == 'new') {
                        $state.go('settingslabel', {
                            label_id: label.id
                        });
                    }

                    // Update geckoDataService store
                    geckoDataService.updateItem('labels', {
                        id: label.id,
                        name: label.name,
                        color: label.color
                    });

                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Label Save Success', {
                        'label id': label.id,
                        'label title': label.name
                    });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Label Save Error', {
                        'label id': ctrl.label.id || 'New',
                        'label name': ctrl.label.name,
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            }
        }, {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.label_id == 'new';
            },
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_LABELS_DELETE)) {
                    return false;
                }
                GeckoUI.dialog.confirm('Are you sure you want to delete this label?', function (value) {
                    if (value) {
                        unsavedAlert.clear();
                        ctrl.label.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Label has been deleted');
                            $state.go('settingslabels');
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Labels',
            click: function click() {
                $state.go('settingslabels');
            }
        }, {
            label: ctrl.label.name ? ctrl.label.name : 'Add Label',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('SettingsLabelsCtrl', SettingsLabelsCtrl).controller('SettingsLabelCtrl', SettingsLabelCtrl);
})();