// https://github.com/khizarnaeem/userAvatar
(function () {
    'use strict';

    function userAvatar() {
        return {
            restrict: 'C',
            scope: {
                User: '=user',
                watchUser: '&'
            },
            templateUrl: '/components/user-avatar/user-avatar.html',
            controller: function controller($scope, avatarService) {
                $scope.ImageAvailable = false;
                $scope.GenericAvatar = avatarService.getAvatar($scope.User);
                $scope.imgStyle = {
                    backgroundImage: 'url(' + $scope.src + ')'
                };

                $scope.$watch('User', function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        $scope.GenericAvatar = avatarService.getAvatar($scope.User);
                    }
                });
            }
        };
    }

    var colorCodes = ['#6D9FE4', '#BB6BE5', '#6BC9E4', '#E46E6D', '#72E469', '#E3AA6A'];

    var seed = 3109 * 4733;

    function fnv32a(str) {
        var FNV1_32A_INIT = 0x811c9dc5;
        var hval = FNV1_32A_INIT;
        for (var i = 0; i < str.length; ++i) {
            hval ^= str.charCodeAt(i);
            hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
        }
        return hval >>> 0;
    }

    function avatarService() {
        return {
            getAvatar: function getAvatar(user) {

                var i1 = "",
                    i2 = "",
                    nameArray = [];
                if (angular.isDefined(user.Name)) {
                    user.Name = String(user.Name);
                    i1 = user.Name ? angular.uppercase(user.Name.charAt(0)) : "";
                    nameArray = user.Name ? user.Name.split(" ") : ["", ""];
                    if (nameArray.length > 2) {
                        i2 = angular.uppercase(nameArray[nameArray.length - 1].charAt(0));
                    } else {
                        i2 = nameArray[1] ? angular.uppercase(nameArray[1].charAt(0)) : "";
                    }
                }
                var initials = i1 + i2;
                if (!initials.trim()) initials = "<i class='fa fa-user'></i>";
                var charCode = initials.charCodeAt(0) + initials.charCodeAt(1);
                charCode = fnv32a(user.Name) % colorCodes.length;
                var background = user.Color && user.Color != '#ADADAD' ? user.Color : colorCodes[charCode];
                return { "Initials": initials, "Background": background };
            }
        };
    }

    angular.module('GeckoEngage').factory('avatarService', avatarService).directive('useravatar', userAvatar);
})();