(function () {
    'use strict';

    function thumbnailSrc() {

        return {
            restrict: 'A',
            controller: function controller($scope, $element, $attrs, $transclude, $parse) {

                $attrs.$observe('thumbnailSrc', function (file) {

                    file = $parse(file)($scope);
                    var size = $attrs.thumbnailSize || 'default';
                    var url = GeckoUI.getObjectByKey(file.thumbnails, 'size', size).url;

                    if (!url) {
                        url = '/images/placeholder-thumbnail.a5ebb054.png';
                    }

                    $attrs.$set('src', url);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('thumbnailSrc', thumbnailSrc);
})();