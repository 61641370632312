(function () {
    'use strict';

    function FormAddResponseCtrl(form, $scope, $state, $stateParams, $timeout, contact, formFields, contactValues, webShareUrl) {
        var ctrl = this;

        ctrl.form = form.toObject();

        var buildScript = function buildScript() {
            var url = webShareUrl() + '/gecko-embed/form.js';
            var element = document.createElement('script');

            var params = ['uuid=' + $stateParams.uuid];
            if (Gecko.user.id) params.push('user_id=' + Gecko.user.id);
            if ($stateParams.contact_id) params.push('contact_id=' + $stateParams.contact_id);
            // Pre-populate form fields with contact data already stored
            if (formFields && contactValues) {
                contactValues.forEach(function (contactValue) {
                    var match = formFields.data.find(function (_ref) {
                        var contact_field_id = _ref.contact_field_id;
                        return contact_field_id === contactValue.contact_field_id;
                    });
                    if (match && match.contact_field_id !== null) {
                        if (match.type === Gecko.Field.TYPE_NAME) {
                            var firstNameValue = contactValue.value.first_name;
                            var lastNameValue = contactValue.value.last_name;
                            return params.push(match.tag + '=' + firstNameValue + '|' + lastNameValue);
                        } else if (match.type === Gecko.Field.TYPE_TEL) {
                            return params.push(match.tag + '=' + encodeURIComponent(contactValue.value.cleaned));
                        } else {
                            return params.push(match.tag + '=' + encodeURIComponent(contactValue.safe));
                        }
                    }
                });
            }

            //http://localhost:5123/public/#/iframe/2yFO007tqitdj9001uw0zk2xmd?origin=http://localhost:5123&user_id=2068&contact_id=46236&telephone=+447751299987&email_address=msellaedwards@safetymail.info&full_name=Ella|Edwards

            element.id = 'gecko-form-embed-script';
            element.src = url + '?' + params.join('&');
            return element;
        };

        $timeout(function () {
            angular.element('#add-a-response-container').html(buildScript());
        }, 300);

        if (contact) {
            ctrl.breadcrumbs = [{
                label: 'Responses',
                click: function click() {
                    return $state.go('responses');
                }
            }, {
                label: contact.full_name ? contact.full_name : 'No Name',
                click: function click() {
                    return $state.go('contact.responses', { contact_id: contact.id });
                }
            }, {
                label: 'Add response',
                active: true
            }];
        } else {
            ctrl.breadcrumbs = [{
                label: 'Responses',
                click: function click() {
                    return $state.go('responses');
                }
            }, {
                label: ctrl.form.internal_name || ctrl.form.name,
                click: function click() {
                    return $state.go('form.designer', { form_id: ctrl.form.id });
                }
            }, {
                label: 'Add response',
                active: true
            }];
        }
    }

    angular.module('GeckoEngage').controller('FormAddResponseCtrl', FormAddResponseCtrl);
})();