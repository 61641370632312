(function () {
    'use strict';

    function geckoFileView() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-file/gecko-file.html',
            scope: {
                files: '=',
                format: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($geckoModal) {
                var ctrl = this;

                ctrl.openFilesModal = function (event) {
                    event.stopPropagation();
                    $geckoModal.fileView(ctrl.files);
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFileView', geckoFileView);
})();