;(function () {
    'use strict';

    function FilePickerModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('filePicker', function (options) {
            return {
                templateUrl: '/components/file-picker/file-picker-modal.html',
                controllerAs: 'ctrl',
                controller: 'filePickerModalCtrl',
                resolve: {
                    mimetype: function mimetype() {
                        return options.mimetype || '';
                    }
                }
            };
        });
    }

    function filePickerModalCtrl($scope, $modalInstance, mimetype, geckoFileUploadService) {
        var ctrl = this;
        ctrl.mimetype = mimetype;
        ctrl.maximum = 1;
        ctrl.getIcon = geckoFileUploadService.getIcon;
        ctrl.uploading = false;
        $scope.uploadErrors = [];
        $scope.filesToUpload = [];

        ctrl.stopEvent = function (event) {
            event.stopPropagation();
            event.preventDefault();
        };

        ctrl.truncatedFileName = function (name) {
            if (name.length < 30) return name;
            return name.slice(0, 13) + '...' + name.slice(-13);
        };

        ctrl.removeFile = function (file) {
            $scope.filesToUpload = $scope.filesToUpload.filter(function (item) {
                return item.name !== file.name;
            });
        };

        var onChange = function onChange(files) {
            var keys = Object.keys(files) || [];

            keys.forEach(function (key) {
                if ($scope.filesToUpload.length < ctrl.maximum) {
                    var file = files[key];
                    $scope.filesToUpload.push(file);
                }
            });

            // Reset errors array
            $scope.uploadErrors = [];

            $scope.$digest();
        };

        $scope.handleFileChange = function (event) {
            ctrl.stopEvent(event);
            onChange(event.target.files);
        };

        $scope.fileDrop = function (event) {
            $scope.fileDragEnd(event);
            onChange(event.dataTransfer.files);
        };

        $scope.fileDragOver = function (event) {
            return ctrl.stopEvent(event);
        };

        $scope.fileDragStart = function (event) {
            $(event.target).addClass("dragging-over");
            ctrl.stopEvent(event);
        };

        $scope.fileDragEnd = function (event) {
            $(event.target).removeClass("dragging-over");
            ctrl.stopEvent(event);
        };

        ctrl.allowMoreFiles = function () {
            return $scope.filesToUpload.length < ctrl.maximum;
        };

        ctrl.uploadFiles = function () {
            $scope.uploadErrors = [];
            $scope.value = [];

            // Check user has uploaded file (show error if not)
            if (!$scope.filesToUpload || !$scope.filesToUpload.length) {
                $scope.uploadErrors.push('Please select a file to upload.');
                return;
            }

            // Check EVERY uploaded file(s) has a valid file type
            if (!geckoFileUploadService.isFileTypesValid($scope.filesToUpload, ctrl.mimetype)) {
                $scope.uploadErrors.push('The selected file(s) are an invalid type, please select another suitable file.');
                return;
            }

            ctrl.isUploading = true;

            return geckoFileUploadService.uploadFiles($scope.filesToUpload).then(function (files) {
                $modalInstance.close({ files: files });
                GeckoUI.messenger.success('File upload successful');
            }).catch(function (err) {
                $scope.uploadErrors.push(err);
            }).finally(function () {
                ctrl.isUploading = false;
                $scope.$digest();
            });
        };
    }

    angular.module('GeckoEngage').config(FilePickerModal).controller('filePickerModalCtrl', filePickerModalCtrl);
})();