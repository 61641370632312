(function () {

    function EventTicketModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('eventTicketModal', function (_attendance, _isSession, _guestCount) {
            return {
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal\n                            data-title="Ticket Information"\n                            footer-btns="ctrl.footerBtns">\n\n                            <div class="row">\n\n                                <div class="col-xs-6" ng-if="Gecko.able(Gecko.ABILITY_EVENTS_ATTENDEE_SCAN)">\n                                    <img style="width:100%" ng-src="{{ctrl.contact.qr_url}}/png/300" />\n                                </div>\n\n                                <div class="col-xs-6">\n                                    <p ng-if="ctrl.contact.full_name">\n                                        <i class="fa fa-user"></i>\n                                        &nbsp;\n                                        {{ctrl.contact.full_name}}\n                                    </p>\n\n                                    <p ng-if="ctrl.contact.email">\n                                        <i class="fa fa-envelope"></i>\n                                        &nbsp;\n                                        {{ctrl.contact.email}}\n                                    </p>\n\n                                    <gecko-fields fields="ctrl.fields" values="ctrl.attendance"></gecko-fields>\n\n                                </div>\n\n                            </div>\n\n                        </gecko-modal>\n                    ',
                controller: 'EventTicketModalCtrl',
                resolve: {
                    attendance: function attendance() {
                        return _attendance;
                    },
                    contact: function contact() {
                        return new Gecko.Contact().rfields({ contact: ['full_name', 'qr_url', 'email'] }).get(_attendance.related_contact.id);
                    },
                    isSession: function isSession() {
                        return _isSession;
                    },
                    guestCount: function guestCount() {
                        return _guestCount;
                    }
                }
            };
        });
    }

    function EventTicketModalCtrl($scope, $modalInstance, attendance, contact, isSession, guestCount) {
        var ctrl = this;
        ctrl.attendance = angular.copy(attendance);
        ctrl.contact = contact;

        // Inital attendance prop values
        var _status = ctrl.attendance.status;
        var _guests = ctrl.attendance.guest_count;

        ctrl.fields = [{
            id: 'status',
            label: 'Status',
            colClass: 'none',
            type: Gecko.Field.TYPE_SELECT,
            options: Gecko.Attendance.status_titles,
            optionsKey: 'id',
            optionsLabelKey: 'title'
        }, {
            id: 'guest_count',
            label: 'Number of guests',
            colClass: 'none',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            options: Array.apply(null, { length: (guestCount || 0) + 1 }).map(function (item, i) {
                return { key: i, label: String(i) };
            }),
            optionsKey: 'key',
            optionsLabelKey: 'label',
            hideWhen: function hideWhen() {
                return isSession || !guestCount;
            }
        }];

        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                // Save status
                if (_status !== ctrl.attendance.status) ctrl.attendance.include('contact').save().then(function () {
                    GeckoUI.messenger.success('Attendee status updated');
                    attendance.status = ctrl.attendance.status;
                    $scope.$digest();
                }).catch(function (err) {
                    return GeckoUI.messenger.error(err);
                });
                // Save guests
                if (!isSession && _guests !== ctrl.attendance.guest_count) ctrl.attendance.include('contact').changeGuests(ctrl.attendance.id, Number(ctrl.attendance.guest_count)).then(function () {
                    return GeckoUI.messenger.success('Attendee guests updated');
                }).catch(function (err) {
                    return GeckoUI.messenger.error(err);
                });

                return $modalInstance.dismiss();
            }
        }, {
            title: 'Close',
            icon: 'fa-times',
            btnClass: 'btn-default',
            position: 'secondary',
            action: function action() {
                return $modalInstance.dismiss();
            }
        }];
    }

    angular.module('GeckoEngage').config(EventTicketModal).controller('EventTicketModalCtrl', EventTicketModalCtrl);
})();