(function () {
    'use strict';

    function CampaignsListCtrl($state, $stateParams, $filter, Gecko, campaigns) {
        var ctrl = this;
        ctrl.campaigns = campaigns.toInstances();
        ctrl.pagination = campaigns.pagination;
        ctrl.module = $stateParams.module;

        // Table structure
        if (ctrl.module == Gecko.Campaign.MODULE_SMS) {
            ctrl.cols = [{
                key: 'status',
                expandable: true,
                status_title_none: true,
                status_styles: Gecko.Campaign.status_titles
            }, {
                data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
                title: 'Date created',
                key: 'created_at'
            }, {
                title: 'Title',
                key: 'title',
                sortKey: 'title',
                colClass: 'col-md-3'
            }, {
                data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
                title: 'Start date',
                key: 'start_date',
                sortKey: 'start_date',
                colClass: 'col-md-3'
            }, {
                data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
                title: 'End date',
                key: 'end_date',
                sortKey: 'end_date',
                colClass: 'col-md-3'
            }];
        } else {
            ctrl.cols = [{
                key: 'status',
                status_title_none: true,
                status_styles: Gecko.Campaign.status_titles,
                expandable: true
            }, {
                data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
                title: 'Date created',
                key: 'created_at'
            }, {
                title: 'Title',
                key: 'title',
                colClass: 'col-md-3',
                sortKey: 'title',
                helpTextKey: 'description'
            }, {
                title: 'Script',
                key: 'form.name',
                render: function render(value, col, row) {
                    if (row.form && row.form.internal_name) return row.form.internal_name;
                    return value;
                },
                colClass: 'col-md-3',
                action: function action(campaign) {
                    if (!campaign.form_id) return false;
                    return $state.go('scriptsview', { form_id: campaign.form_id });
                }
            }, {
                data_type: Gecko.Field.DATA_TYPE_INTEGER,
                title: 'Callable Contacts',
                key: 'subscribers_count',
                colClass: 'col-md-3',
                render: function render(value, col, row) {
                    return row.subscribers_count + row.subscribers_scheduled_due_count;
                }
            }];
        }

        // Table row action
        ctrl.rowAction = {
            action: function action(campaign) {
                return $state.go('campaignsedit.view', { module: ctrl.module, campaign_id: campaign.id });
            }
        };

        var filterOptions = [{
            label: 'Active',
            value: Gecko.Campaign.ACTIVE
        }, {
            label: 'Pending',
            value: Gecko.Campaign.PENDING
        }, {
            label: 'Completed',
            value: Gecko.Campaign.COMPLETE
        }, {
            label: 'Invalid',
            value: Gecko.Campaign.INVALID
        }];

        if ($stateParams.status && ($stateParams.status === 'current' || $stateParams.status.indexOf('status') !== -1)) {
            filterOptions.unshift({
                label: 'Current',
                value: 'current'
            });
        }

        if (ctrl.module === Gecko.Campaign.MODULE_SMS) {
            filterOptions.push({
                label: 'Paused',
                value: Gecko.Campaign.PAUSED
            });
        }

        ctrl.tableFilter = [{
            title: 'Status',
            type: 'checkbox',
            options: filterOptions,
            stateParam: 'status'
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Export campaign',
            action: function action(campaign) {
                return $state.go('exportnew', { export_id: 'new', campaign_id: campaign.id });
            }
        }, {
            title: 'Clone campaign',
            action: function action(campaign) {
                angular.copy(campaign).clone().then(function () {
                    GeckoUI.messenger.success('Campaign successfully cloned');
                    // Redirect to broadcasts
                    return $state.go($state.$current.name, $stateParams, { reload: true });
                });
            }
        }, {
            title: 'Delete campaign',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CALL_CAMPAIGNS_DELETE);
            },
            action: function action(broadcast, index) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this campaign?', function (value) {
                    if (value) {
                        broadcast.remove().then(function () {
                            GeckoUI.messenger.success('Campaign removed');
                            // Redirect to broadcasts
                            return $state.go($state.$current.name, $stateParams, { reload: true });
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: ctrl.module === Gecko.Campaign.MODULE_CALL ? 'Campaigns' : 'Broadcasts',
            click: function click() {
                return $state.go('campaigns', { module: ctrl.module });
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('CampaignsListCtrl', CampaignsListCtrl);
})();