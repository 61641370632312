(function () {
    'use strict';

    function geckoCallPaneIncomingTransfer() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-pane-incoming-transfer.html',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {},
            controller: function controller($scope, $timeout, $geckoCall, $geckoVoip, $geckoSocket, $geckoTransfer) {
                var ctrl = this;

                // Get incoming transfer title
                ctrl.getPaneTitle = function () {
                    return 'Transferred call from ' + ctrl.incomingUser.full_name;
                };

                // Get call number title
                ctrl.getNumberTitle = function () {
                    var callerTitle = ctrl.incomingContact && ctrl.incomingContact.full_name ? ctrl.incomingContact.full_name : 'Unknown';
                    var numberTitle = $geckoCall.getNumberTitle(ctrl.incomingNumber);
                    return '<strong>' + callerTitle + '</strong> calling from <strong>' + numberTitle + '</strong>';
                };

                // Get incoming call
                ctrl.getCall = function () {
                    $geckoCall.get($geckoVoip.incomingCallId).then(function (call) {
                        ctrl.incomingCall = call;
                        ctrl.incomingSender = call.related_sender;
                        ctrl.incomingNumber = call.related_number;
                        ctrl.incomingUser = call.related_user;
                        ctrl.incomingContact = call.related_contact;
                        ctrl.incomingContacts = ctrl.incomingNumber.related_contacts || [];
                        ctrl.open = false;
                        $scope.$digest();
                    });
                };

                // Answer call
                ctrl.answerCall = function () {

                    // Show loading indicator
                    $geckoCall.loadingIndicator(true);

                    // Accept VOIP connection
                    $geckoCall.join($geckoVoip.incomingCallId).then(function (call) {
                        // Trigger client answered event
                        $geckoTransfer.sendFeedback(ctrl.incomingCall.id, 'accept');
                        // Restore call
                        $geckoCall.restore(call);
                        // Check if contact and campaign data exsists
                        var data = {};
                        if (call.related_contact) data.contact = call.related_contact;
                        if (call.related_campaign) data.campaign = call.related_campaign;
                        // Open call pane
                        $geckoCall.activate(data);

                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        // Refresh scope
                        $scope.$digest();
                    }).catch(function (err) {
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        GeckoUI.messenger.error(err.errors);
                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Call Incoming Transfer Error', {
                            'error': GeckoUI.renderError(err.errors)
                        });
                    });
                };

                // Ignore incoming call
                ctrl.rejectCall = function () {
                    // Trigger client reject event
                    $geckoTransfer.sendFeedback(ctrl.incomingCall.id, 'reject');
                    return $geckoVoip.accept = false;
                };

                // Card buttons
                ctrl.cardFooterBtns = [{
                    icon: 'fa-times',
                    action: ctrl.rejectCall,
                    position: 'secondary',
                    btnClass: 'btn-danger',
                    title: 'Decline'
                }, {
                    icon: 'fa-phone',
                    title: 'Accept',
                    btnClass: 'btn-primary',
                    safeMode: true,
                    action: ctrl.answerCall
                }];

                // Set incoming call
                ctrl.getCall();

                // Only ring for 20 seconds
                $geckoTransfer.timeout = $timeout(function () {
                    if ($geckoVoip.incomingCallId) {
                        // Trigger client reject event
                        $geckoTransfer.sendFeedback(ctrl.incomingCall.id, 'timeout');
                        return $geckoVoip.accept = false;
                    }
                }, 25000);
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallPaneIncomingTransfer', geckoCallPaneIncomingTransfer);
})();