
(function () {
    'use strict';

    function geckoUserVoipControl() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-user-voip-control/gecko-user-voip-control.html',
            scope: false,
            controllerAs: 'ctrl',
            controller: function controller($scope, $geckoCall, $geckoSocket, $geckoVoip, $geckoQueue, $geckoPresence, $state, $stateParams, $geckoWatch, $geckoSounds) {
                $scope.Gecko = Gecko;
                $scope.$geckoCall;
                var ctrl = this;

                ctrl.isQueue = function () {
                    ctrl._isQueue = $geckoQueue.length && $geckoPresence.isOnline();
                    return $geckoQueue.length && $geckoPresence.isOnline();
                };

                ctrl.hasInbound = function () {
                    return Gecko.User.voipInboundEnabled();
                };

                ctrl.hasOutbound = function () {
                    return Gecko.User.hasOutbound();
                };

                ctrl.canPrepare = function () {
                    return ctrl.hasOutbound() && !$geckoCall.onCall && !$geckoCall.isIncoming() && !$geckoCall.isActive() && !$geckoCall.isListening();
                };

                ctrl.canConnect = function () {
                    return ctrl.hasInbound() && $geckoQueue.length && !$geckoCall.onCall && !$geckoCall.isIncoming() && $geckoPresence.isOnline();
                };

                // Get classes
                ctrl.getClasses = function () {
                    return {
                        online: $geckoPresence.isOnline(),
                        away: $geckoPresence.isAway(),
                        light: Gecko.account && GeckoUI.getContrastColor(Gecko.account.color) == 'white',
                        dark: Gecko.account && GeckoUI.getContrastColor(Gecko.account.color) == 'black',
                        queue: ctrl.isQueue()
                    };
                };

                // Toggle call settings
                ctrl.toogle = {
                    label: 'Availability for calls',
                    id: 'presence',
                    type: Gecko.Field.TYPE_TOGGLE,
                    trueValue: Gecko.User.PRESENCE_AVAILABLE,
                    falseValue: Gecko.User.PRESENCE_MANUALLY_AWAY,
                    action: function action() {
                        if (Gecko.user) {
                            Gecko.user.toInstance().save().then(function () {
                                GeckoUI.messenger.success('User settings updated.');
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        }
                    }
                };

                // This is a TEMP solution (devised by Scott and Adrian)
                // ctrl.callIsPreparing = function() {
                //     return $geckoCall.call && $geckoCall.call.campaign_id && $geckoCall.call.status === Gecko.Call.PREPARING && $state.$current.name === 'contact.subscriber';
                // };

                ctrl.goToCampaignOverview = function () {
                    // Go to specific campaign
                    if ($geckoCall.call && $geckoCall.call.campaign_id) {
                        return $state.go('campaignsedit.view', { module: Gecko.Campaign.MODULE_CALL, campaign_id: $geckoCall.call.campaign_id });
                    }
                    // Go to campaigns overview
                    return $state.go('campaigns', { module: Gecko.Campaign.MODULE_CALL });
                };

                // Reset incomingCallId on isIncoming
                // $geckoWatch(ctrl, '_isQueue', function(value) {
                //     // Play/stop incoming sound
                //     if (value && ctrl.callIsPreparing()) {
                //         $geckoSounds.play('incoming');
                //     } else {
                //         $geckoSounds.stop('incoming');
                //     }

                // });

            }
        };
    }

    angular.module('GeckoEngage').directive('geckoUserVoipControl', geckoUserVoipControl);
})();