(function () {
    'use strict';

    function geckoCardScript() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card-script/gecko-card-script.html',
            scope: {
                contact: '=contact',
                form: '=script'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $stateParams) {

                var ctrl = this;
                ctrl.form = ctrl.form.toObject();
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCardScript', geckoCardScript);
})();