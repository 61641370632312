(function () {
    'use strict';

    function OrganisationTypesListCtrl(organisationTypes, $state, $geckoModal) {
        var ctrl = this;

        ctrl.organisationTypes = organisationTypes.toInstances();
        ctrl.pagination = organisationTypes.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'title',
            colClass: 'col-xs-6'
        }, {
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Organisations',
            key: 'organisations_count',
            colClass: 'col-xs-6'
        }];

        // Add a new organisation type
        ctrl.addOrganisationType = function () {
            if (GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_CREATE)) {
                $geckoModal.inputModal('Add organisation type', '', 'Organisation types can be used to help differentiate between institutions you work with. e.g. High Schools, Colleges, Universities etc.').result.then(function (val) {

                    new Gecko.OrganisationType().create({ title: val }).save().then(function () {
                        GeckoUI.messenger.success('Organisation type has been saved');
                        $state.go('organisationtypes', {}, { reload: true });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.error);
                    });
                });
            }
        };

        // Table row action
        ctrl.rowAction = {
            action: function action(organisation_type) {

                if (GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_UPDATE)) {
                    $geckoModal.inputModal('Rename organisation type', organisation_type.title).result.then(function (val) {

                        organisation_type.title = val;

                        organisation_type.save().then(function () {
                            GeckoUI.messenger.success('Organisation type has been saved');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.error);
                        });
                    });
                }
            }
        };

        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View organisations',
            action: function action(organisation_type_data) {
                $state.go('organisations', { organisation_type_id: organisation_type_data.id });
            }
        }, {
            title: 'Rename type',
            action: function action(organisation_type) {
                // Just call the click action to rename it
                ctrl.rowAction.action(organisation_type);
            }
        }, {
            title: 'Remove type',
            action: function action(organisation_type) {
                if (GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_DELETE)) {
                    GeckoUI.dialog.confirm('Are you sure you want to delete this organisation type?', function (value) {
                        if (value) {
                            organisation_type.remove().then(function () {
                                $state.reload();
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        }
                    });
                }
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                return $state.go('settings');
            }
        }, {
            label: 'Organisation types',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('OrganisationTypesListCtrl', OrganisationTypesListCtrl);
})();