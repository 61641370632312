(function () {
    'use strict';

    function $unsaved() {
        var watchers = [],
            dirty = false;
        return {
            add: function add(model, $scope) {
                if ($scope && model) {
                    var watcher = $scope.$watch(model, function (oldVal, newVal) {
                        if (oldVal != newVal) {
                            dirty = true;
                        }
                    }, true);
                    watchers.push(watcher);
                }
            },
            clear: function clear() {
                // De-register watchers
                angular.forEach(watchers, function (watcher) {
                    watcher();
                });
                watchers = [];
                dirty = false;
            },
            check: function check() {
                return dirty;
            }
        };
    }

    angular.module('GeckoEngage').service('$unsaved', $unsaved);
})();