(function () {
    'use strict';

    function geckoPageHeader() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-page-header/gecko-page-header.html',
            transclude: true,
            scope: {
                breadcrumbs: '=breadcrumbs'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($attrs) {
                var ctrl = this;
            },
            link: function link(scope, element, attrs, ctrl, transclude) {
                transclude(scope.$parent, function (clone, scope) {
                    element.find('.transclude-placeholder').append(clone);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoPageHeader', geckoPageHeader);
})();