(function () {
    'use strict';

    function UserFormsCtrl($scope, Gecko, $filter, $stateParams, $state, user, forms) {
        var ctrl = this;
        ctrl.user_forms = [];
        ctrl.user = user;
        ctrl.forms = forms.toArray();

        // Loop through all forms available
        forms = ctrl.user.relationIDs('forms');

        angular.forEach(ctrl.forms, function (form) {
            // Check if this user is currently got the form available to them
            ctrl.user_forms[form.id] = forms.indexOf(form.id) == -1 ? false : true;
        });

        // Filters
        ctrl.filterActive = function (form) {
            return form.deleted_at <= 0 ? true : false;
        };
        ctrl.filterDeleted = function (form) {
            return form.deleted_at > 0 ? true : false;
        };

        // Select/deselect all forms
        ctrl.selectAll = true;
        ctrl.formSelectHelper = function (all) {
            if (all) {
                angular.forEach(ctrl.forms, function (form) {
                    ctrl.user_forms[form.id] = true;
                });
            } else {
                angular.forEach(ctrl.forms, function (form) {
                    ctrl.user_forms[form.id] = false;
                });
            }
            ctrl.selectAll = all ? false : true;
        };

        ctrl.saveUser = function () {

            if (ctrl.user.id) {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_USERS_UPDATE)) return;
            } else {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_USERS_CREATE)) return;
            }

            var forms = [];
            ctrl.user_forms.forEach(function (form, index) {
                if (form) forms.push(index);
            });
            ctrl.user.forms = forms;

            ctrl.user.save().then(function (user) {
                GeckoUI.messenger.success('Your changes have been saved');

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('User Save Success', {
                    'user id': user.id,
                    'user title': user.full_name
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('User Save Error', {
                    'user id': ctrl.user.id || 'New',
                    'user name': ctrl.user.full_name,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        $scope.$on('saveUser', ctrl.saveUser);
    }

    angular.module('GeckoEngage').controller('UserFormsCtrl', UserFormsCtrl);
})();