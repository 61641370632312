(function () {
    'use strict';

    function BroadcastWorkflowsCtrl(campaign, multiData, unsavedAlert, $scope, $state, $stateParams) {
        var ctrl = this;
        ctrl.broadcast = campaign;
        ctrl.multiData = multiData;

        //generateWorkflowsController($scope, unsavedAlert, ctrl, {});
        ctrl.workflows = ctrl.broadcast.related_workflows;
        ctrl.broadcastSave = function () {
            // Dont allow save if broadcast is COMPLETE
            if (ctrl.broadcast.status === Gecko.Campaign.COMPLETE) {
                return GeckoUI.messenger.error('This broadcast is now complete and can\'t be changed.');
            }

            ctrl.broadcast.workflows = ctrl.workflows;
            ctrl.broadcast.include('outcomes', 'workflows').save().then(function (campaign) {
                GeckoUI.messenger.success('Your broadcast has been saved.');
                // Update the scope variable to the new one
                ctrl.broadcast = campaign;
                // Clear unsaved changes alert
                unsavedAlert.clear();

                try {

                    // Navigate to launch (if in wizard mode)
                    if ($stateParams.wizard) $state.go('broadcast.launch', $stateParams);
                } catch (e) {
                    console.log(e);
                }
            }).catch(function (campaign) {
                GeckoUI.messenger.error(campaign.errors);
            });
        };

        $scope.$on('saveBroadcast', function () {
            return ctrl.broadcastSave();
        });
    }

    angular.module('GeckoEngage').controller('BroadcastWorkflowsCtrl', BroadcastWorkflowsCtrl);
})();