(function () {
    'use strict';

    function geckoMapDirective() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-map/gecko-map.html',
            scope: {
                lat: '@',
                lng: '@',
                title: '@',
                address: '@',
                draggable: '=',
                zoom: '=',
                scrollwheel: '=',
                doubleclickzoom: '=',
                defaultui: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($attrs, $element) {
                var ctrl = this;

                ctrl.loadMap = function () {
                    // Checks to see if the Google Maps API JS has been loaded
                    if (!document.getElementById('google-maps-api')) {
                        // Load the google maps API
                        var script = document.createElement('script');
                        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAS9QXHFdfVsUrKQ-PSKPqn7GIe2PKGV3o";
                        script.onload = buildMap;
                        script.id = 'google-maps-api';
                        document.body.appendChild(script);
                    } else {
                        if (typeof google === 'undefined') {
                            setTimeout(function () {
                                ctrl.loadMap();
                            }, 500); // Defers trying to build the map until the google API is ready
                        } else {
                            buildMap(); // Build the map if the API is ready
                        }
                    }
                };

                var buildMap = function buildMap() {
                    var latlng = { lat: Number(ctrl.lat), lng: Number(ctrl.lng) };
                    var infowindow = new google.maps.InfoWindow({
                        content: getInfoText()
                    });

                    if (!ctrl.initialised) {
                        buildNewMap(latlng, infowindow);
                    } else {
                        recenterMap(latlng, infowindow);
                    }
                };

                var getInfoText = function getInfoText() {
                    var infoText = '';
                    if (ctrl.title) {
                        infoText += '<h2>' + ctrl.title + '</h2>';
                    }
                    if (ctrl.address) {
                        // Wraps each line in the address in <span> and <br> tags
                        infoText += ctrl.address.split('\n').map(function (line) {
                            return '<span>' + line + '</span><br/>';
                        }).join('');
                    }
                    return infoText;
                };

                var buildNewMap = function buildNewMap(latlng, infowindow) {
                    var mapElement = $element.context.childNodes[0];
                    ctrl.map = new google.maps.Map(mapElement, {
                        center: latlng,
                        zoom: 12
                    });
                    ctrl.map.setOptions(getMapOptions());
                    ctrl.marker = new google.maps.Marker({
                        position: latlng,
                        map: ctrl.map,
                        title: ctrl.title
                    });
                    addMarkerListener(ctrl.marker, infowindow, ctrl.map);
                    ctrl.initialised = true;
                };

                var recenterMap = function recenterMap(latlng, infowindow) {
                    ctrl.map.setCenter(latlng);
                    ctrl.marker.setMap(null);
                    ctrl.marker = new google.maps.Marker({
                        position: latlng,
                        map: ctrl.map,
                        title: ctrl.title
                    });
                    addMarkerListener(ctrl.marker, infowindow, ctrl.map);
                };

                var getMapOptions = function getMapOptions() {
                    var draggable = ctrl.draggable;
                    var zoomControl = ctrl.zoom;
                    var scrollwheel = ctrl.scrollwheel;
                    var doubleClickZoom = ctrl.doubleclickzoom;
                    var disableDefaultUI = ctrl.defaultui;

                    // Set attributes to true if they haven't been assigned as false
                    if (draggable !== false) {
                        draggable = true;
                    }
                    if (zoomControl !== false) {
                        zoomControl = true;
                    }
                    if (scrollwheel !== false) {
                        scrollwheel = true;
                    }
                    if (doubleClickZoom !== false) {
                        doubleClickZoom = true;
                    }
                    if (disableDefaultUI !== false) {
                        disableDefaultUI = true;
                    }

                    var options = {
                        draggable: draggable,
                        zoomControl: zoomControl,
                        scrollwheel: scrollwheel,
                        disableDoubleClickZoom: !doubleClickZoom,
                        disableDefaultUI: !disableDefaultUI
                    };
                    return options;
                };

                var addMarkerListener = function addMarkerListener(marker, infowindow, map) {
                    marker.addListener('click', function () {
                        infowindow.open(map, marker);
                    });
                };

                $attrs.$observe('lat', function () {
                    ctrl.loadMap();
                });
                $attrs.$observe('lng', function () {
                    ctrl.loadMap();
                });
                $attrs.$observe('title', function () {
                    ctrl.loadMap();
                });
                $attrs.$observe('address', function () {
                    ctrl.loadMap();
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoMap', geckoMapDirective);
})();