(function () {
    'use strict';

    function UsageVoipCtrl($state, $stateParams, calls, stats) {
        var ctrl = this;
        if (calls) {
            ctrl.calls = calls.toArray();
            ctrl.pagination = calls.pagination;
        } else {
            ctrl.calls = false;
            ctrl.pagination = {};
        }
        ctrl.stats = stats;
        // Date filter
        ctrl.dateFrom = $stateParams.from;
        ctrl.dateTo = $stateParams.to;

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { label: object[labelKey], value: object.id };
                });
            };
        };
        ctrl.tableFilters = [{
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            }
        }, {
            title: 'Filter',
            stateParam: 'filter_id',
            type: 'multi',
            optionsQuery: new Gecko.Filter().rfields({ filter: ['name'] }).where('saved', 1),
            optionsKey: 'id',
            optionsLabelKey: 'name'
        }, {
            title: 'Type',
            type: 'checkbox',
            options: [{ label: 'Outbound', value: Gecko.Call.TYPE_OUTBOUND }, { label: 'Inbound', value: Gecko.Call.TYPE_INBOUND }],
            stateParam: 'type'
        }, {
            title: 'Users',
            stateParam: 'user',
            type: 'multi',
            optionsQuery: new Gecko.User().rfields({ user: ['full_name'] }),
            optionsKey: 'id',
            optionsLabelKey: 'full_name'
        }, {
            title: 'Campaigns',
            stateParam: 'campaign',
            type: 'multi',
            optionsQuery: new Gecko.Campaign().rfields({ campaign: ['title'] }).where('module', Gecko.Campaign.MODULE_CALL),
            optionsKey: 'id',
            optionsLabelKey: 'title'
        }];

        // Table structure
        ctrl.cols = [{
            key: 'billing_status',
            expandable: true,
            status_title_none: true,
            status_styles: Gecko.Call.billing_titles
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Started at',
            key: 'started_at',
            sortKey: 'started_at'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'User',
            key: 'user.full_name',
            colClass: 'col-xs-3',
            render: function render(value, col, call) {
                if (call.user_id) {
                    return value;
                }
                return 'System';
            },
            hideAvatar: function hideAvatar(col, call) {
                return !call.user_id;
            }
        }, {
            key: 'type',
            colClass: 'text-center',
            icon_styles: [{
                id: Gecko.Call.TYPE_OUTBOUND,
                icon: 'fa-arrow-right',
                title: 'Outbound'
            }, {
                id: Gecko.Call.TYPE_INBOUND,
                icon: 'fa-arrow-left',
                title: 'Inbound'
            }]
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Contact',
            key: 'contact.full_name',
            colClass: 'col-xs-3',
            action: function action(call) {
                if (call.contact) return $state.go('contact.overview', { contact_id: call.contact.id });
            },
            render: function render(value, col, call) {
                if (call.contact) {
                    return value;
                } else if (call.number) {
                    if (call.number.type == Gecko.Number.TYPE_ANONYMOUS) return 'Unknown Number';
                    return call.number.cleaned || call.number.original;
                }
                return 'Unknown';
            },
            hideAvatar: function hideAvatar(col, call) {
                return !call.contact;
            },
            hideAction: function hideAction(col, call) {
                return !call.contact;
            }
        }, {
            title: 'Campaign',
            key: 'campaign.title',
            action: function action(call) {
                if (call.campaign) return $state.go('campaignsedit.view', { campaign_id: call.campaign_id });
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            humanize: true,
            title: 'Duration',
            key: 'call_time',
            sortKey: 'call_time',
            colClass: 'col-xs-3'
        }, {
            data_type: Gecko.Field.DATA_TYPE_CURRENCY,
            title: 'Cost',
            key: 'cost',
            sortKey: 'cost',
            colClass: 'col-xs-3'
        }];

        // Stats card
        ctrl.stats = [];
        angular.forEach(stats, function (stat) {
            ctrl.stats.push({
                title: stat.title,
                aggregate: stat.unit.toLowerCase() == 'currency' ? Gecko.account.currency_symbol + Number(stat.result).toFixed(2) : stat.result
            });
        });

        // Stat card options
        ctrl.optionsBtn = {
            iconRight: 'fa-cogs',
            btnTooltip: 'Actions'
        };
        ctrl.optionsBtn.items = [{
            title: 'View VoIP dashboard',
            action: function action() {
                return $state.go('dashboardview', { module: 'voip', override_date: 'month' });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'VoIP Usage',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('UsageVoipCtrl', UsageVoipCtrl);
})();