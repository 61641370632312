(function () {
    'use strict';

    function geckoNotificationsAlert() {

        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-notifications-alert/gecko-notifications-alert.html',
            scope: {},
            controllerAs: 'ctrl',
            controller: function controller() {
                var ctrl = this;
                ctrl.borderStyle = {
                    border: '1px solid ' + Gecko.account.color
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoNotificationsAlert', geckoNotificationsAlert);
})();