(function () {
    'use strict';

    /**
     * @returns {SavedSearchProvider}
     */

    function searchServiceProvider(portalRequestService, geckoDataService) {
        var defaultPageSize = 10000;
        return {
            saturate: function saturate() {
                return portalRequestService.get('/search/saved-searches?page=1&perPage=' + defaultPageSize + '&hidden=false').then(function (res) {
                    return (res.data || []).map(function (savedSearch) {
                        var serviceData = savedSearch.serviceData || {};
                        return {
                            ulid: savedSearch.savedSearchId,
                            id: serviceData.id,
                            name: savedSearch.name,
                            requirement: serviceData.requirement
                        };
                    });
                });
            },
            getById: function getById(id) {
                var fromStore = geckoDataService.fetchFromStoreSync('filters');
                var filter = fromStore.filters.find(function (filter) {
                    return filter.id === parseInt(id);
                });

                if (!filter || !filter.ulid) {
                    console.error('No filters with id ' + id + ' (parsed as ' + parseInt(id) + ') with appropriate ulid');
                    return Promise.resolve();
                }

                return portalRequestService.get('/search/saved-searches/' + filter.ulid).then(function (res) {
                    if (!res.successful || !res.data) {
                        console.error('Could not retrieve saved search ' + id);
                        return;
                    }

                    var _res$data = res.data,
                        legacyConditions = _res$data.legacyConditions,
                        name = _res$data.name,
                        meta = _res$data.meta,
                        savedSearchId = _res$data.savedSearchId;

                    return {
                        name: name,
                        conditions: legacyConditions,
                        related_conditions: legacyConditions,
                        id: savedSearchId,
                        requirement: meta.serviceData.requirement ? meta.serviceData.requirement : 'ALL'
                    };
                });
            }
        };
    }

    angular.module('GeckoEngage').factory('searchServiceProvider', searchServiceProvider);
})();