(function () {

    'use strict';

    function UsersRoute($stateProvider) {
        $stateProvider.state('users', {
            url: "/settings/users?{page}&{search}&{order}&{sort}&{status}&{group}",
            templateUrl: "/modules/users/views/index.973d48cf.html",
            controller: 'UsersCtrl',
            controllerAs: 'ctrl',
            page_title: 'Users',
            redirectKey: 'module_users',
            requires: [Gecko.ABILITY_USERS_VIEW],
            resolve: {
                usersQuery: function usersQuery($stateParams, Gecko) {
                    return function () {
                        var users = new Gecko.User().include('group').perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ user: ['created_at', 'full_name', 'email', 'last_active', 'color', 'deleted_at', 'presence', 'system_status', 'receive_digest'], group: ['name'] });
                        if ($stateParams.search) users.where('user_keyword', $stateParams.search);
                        if ($stateParams.group) users.where('group_id', $stateParams.group);
                        if ($stateParams.order) users.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                        if ($stateParams.status) {
                            if ($stateParams.status === 'archived') {
                                users.where('trashed', 'only');
                            } else {
                                users.where($stateParams.status, 'true'); // why does true not send semnd a value?!
                            }
                        } else {
                            users.where('trashed', 1);
                        }
                        return users;
                    }.bind(this);
                },
                users: function users(usersQuery) {
                    return usersQuery().get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(UsersRoute);
})();