
(function () {
    'use strict';

    function FormCtrl($state, $scope, $stateParams, $formBuilderService, unsavedAlert, module, form, $dialogIntegrationSelect) {
        var ctrl = this;
        ctrl.form = form;

        var moduleTitle = $formBuilderService.getModuleTitle(ctrl.form.module);
        var moduleRouteBase = $formBuilderService.getModuleRouteBase(ctrl.form.module);
        ctrl.form.moduleLink = $formBuilderService.buildShareURL(ctrl.form.uuid, 'modern', null, false);

        // Redirect to designer tab if no tab found
        if ($state.$current.name == 'form') $state.go('form.designer');
        if ($state.$current.name == 'script') $state.go('script.designer');

        // Tabs
        ctrl.tabs = [{
            title: 'Designer',
            state: moduleRouteBase + '.designer'
        }, {
            title: 'Workflows',
            state: moduleRouteBase + '.workflows',
            hideWhen: function hideWhen() {
                return $stateParams.form_id == 'new';
            }
        }, {
            title: 'Settings',
            state: moduleRouteBase + '.settings',
            hideWhen: function hideWhen() {
                return $stateParams.form_id == 'new';
            }
        }, {
            title: 'Permissions',
            state: moduleRouteBase + '.permissions',
            hideWhen: function hideWhen() {
                return $stateParams.form_id == 'new';
            }
        }, {
            title: 'Share',
            state: moduleRouteBase + '.share',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_FORMS_SHARE_PAGE) || $stateParams.form_id == 'new' || module === Gecko.Form.MODULE_CALL;
            }
        }];

        // Cog drop down
        ctrl.optionsBtn = {
            title: 'Actions',
            iconRight: 'fa-angle-down',
            iconLeft: 'fa-cogs',
            btnClass: 'btn-default'
        };

        ctrl.optionsBtn.items = [{
            title: moduleTitle + ' dashboard',
            //hideWhen: () => module == Gecko.Form.MODULE_CALL,
            action: function action() {
                var params = {
                    override_date: 'month'
                };
                params[module == Gecko.Form.MODULE_CALL ? 'script_id' : 'form_id'] = $stateParams.form_id;
                return $state.go('dashboardview', params);
            }
        }, {
            title: 'Export responses',
            hideWhen: function hideWhen() {
                return module == Gecko.Form.MODULE_CALL;
            },
            action: function action() {
                return $state.go('exportnew', { export_id: 'new', form_id: $stateParams.form_id });
            }
        }, {
            title: 'Import responses',
            hideWhen: function hideWhen() {
                return module == Gecko.Form.MODULE_CALL;
            },
            action: function action() {
                return $state.go('settingsimportfile', { form_id: ctrl.form.id, import_to: 'responses' });
            }
        }, {
            title: 'Add a response',
            hideWhen: function hideWhen() {
                return module == Gecko.Form.MODULE_CALL;
            },
            action: function action() {
                return $state.go('responseadd', { uuid: ctrl.form.uuid });
            }
        }, {
            title: 'Show responses',
            action: function action() {
                return $state.go('responses', { form_type: module, form: ctrl.form.id });
            }
        }, {
            title: 'Sync responses',
            action: function action() {
                return ctrl.syncForm();
            },
            hideWhen: function hideWhen() {
                return !$formBuilderService.data.integrations || $formBuilderService.data.integrations && !$formBuilderService.data.integrations.length;
            }
        }, {
            title: 'Sync failures',
            hideWhen: function hideWhen() {
                return module == Gecko.Form.MODULE_CALL;
            },
            action: function action() {
                return $dialogIntegrationSelect(false, [Gecko.Integration.TYPE_SALEFORCE, Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_EMS, Gecko.Integration.TYPE_DOTMAILER, Gecko.Integration.TYPE_NEW_DYNAMICS, Gecko.Integration.TYPE_WEBHOOK, Gecko.Integration.TYPE_ENGAGE_DYNAMICS], function (integrationId) {
                    return $state.go('responses', {
                        form: ctrl.form.id,
                        sync_status: 'has_not_synced_' + integrationId
                    });
                });
            }
        }, {
            title: 'Clone ' + (moduleTitle ? moduleTitle.toLowerCase() : ''),
            action: function action() {

                new Gecko.Form().include('workflows').rfields({ form: ['id'], workflow: ['schedule'] }).get(ctrl.form.id).then(function (form) {
                    var num_scheduled = form.related_workflows.filter(function (workflow) {
                        return workflow.schedule !== null;
                    }).length;
                    var entity = module == Gecko.Form.MODULE_FORM ? 'Form' : 'Script';
                    var s = num_scheduled === 1 ? '' : 's';
                    var message = num_scheduled > 0 ? '<h3>Workflow' + s + ' Requiring Attention</h3>\n                        <p>You are attempting to clone a <b>' + entity + '</b> that has workflows containing actions with set dates, the workflows affected will be reset to run immediately.</p>\n\n                        <p>To avoid any unintended consequences, we strongly recommend updating the dates on the affected workflows before publishing the ' + entity + '.</p>' : 'Are you sure you would like to clone this ' + entity + '?';

                    GeckoUI.dialog.confirm(message, function (confirm) {
                        if (confirm) {
                            ctrl.form.include('user').clone().then(function (clone) {
                                $state.go($state.$current.name, { form_id: clone.id });
                                GeckoUI.messenger.success(entity + ' was cloned successfully');
                            }).catch(function (error) {
                                GeckoUI.messenger.error(Gecko.errorsAsArray(error.errors)[0]);
                            });
                        }
                    });
                }).catch(function (err) {
                    return console.log(err);
                });
            }
        }, {
            title: 'Archive ' + (moduleTitle ? moduleTitle.toLowerCase() : ''),
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_FORMS_DELETE)) return;
                GeckoUI.dialog.confirm('Are you sure you want to archive this ' + (moduleTitle ? moduleTitle.toLowerCase() : '') + '?', function (value) {
                    if (value) ctrl.deleteForm();
                });
            }
        }];

        ctrl.deleteForm = function () {
            new Gecko.Form().call('/forms/' + (ctrl.form.id !== undefined ? ctrl.form.id : $stateParams.form_id), 'DELETE').then(function () {
                $state.go(moduleRouteBase + 's');
                var deleteMessage = moduleTitle + ' has been archived.';
                GeckoUI.messenger.success(deleteMessage);
            }).catch(function (err) {
                return GeckoUI.messenger.error(err.errors);
            });
        };

        ctrl.syncForm = function (integration) {
            var integrations = $formBuilderService.data.integrations;
            if (!integrations || !integrations.length) {
                return false;
            }

            GeckoUI.dialog.confirm('Do you wish to sync all responses captured by this form?', function (ok) {
                if (ok) {
                    // Pick an integration first (if more than one integration)
                    if (integrations.length > 1 && integration == undefined) {

                        var callback = function callback(id) {
                            if (id) {
                                var integration = integrations.find(function (int) {
                                    return int.id == id;
                                });
                                ctrl.syncForm(integration);
                            }
                        };
                        return $dialogIntegrationSelect('What integration would you like to sync?', integrations.map(function (int) {
                            return int.type;
                        }), callback);
                    }

                    return ctrl.sendSyncRequest(integrations.length === 1 ? integrations[0].id : integration.id);
                }
            });
        };

        ctrl.sendSyncRequest = function (integrationId) {
            // Build a call to the sync endpoint
            new Gecko.Form().call('/forms/' + ctrl.form.id + '/sync', 'POST', { integration_id: integrationId }, true).then(function (data) {
                return GeckoUI.messenger.success(data.message);
            }).catch(function (err) {
                return GeckoUI.messenger.error('Sync failed, check integration settings.');
            });
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: moduleTitle + 's',
            click: function click() {
                return $state.go(moduleRouteBase + 's');
            }
        }, {
            label: ctrl.form.id ? ctrl.form.internal_name ? ctrl.form.name + ' - ' + ctrl.form.internal_name : ctrl.form.name : 'Add ' + moduleTitle,
            active: true
        }];

        // Show save button when not the share page
        ctrl.showSaveBtn = function () {
            return $state.$current.name != 'form.share';
        };

        // Fire save event
        ctrl.saveForm = function () {
            unsavedAlert.clear();
            if (!ctrl.form.id) {
                if (!GeckoUI.ableWithReason(module === Gecko.Form.MODULE_CALL ? Gecko.ABILITY_SCRIPTS_CREATE : Gecko.ABILITY_FORMS_CREATE)) {
                    return false;
                }
            } else {
                if (!GeckoUI.ableWithReason(module === Gecko.Form.MODULE_CALL ? Gecko.ABILITY_SCRIPTS_UPDATE : Gecko.ABILITY_FORMS_UPDATE)) {
                    return false;
                }
            }
            $scope.$broadcast('saveForm:' + $state.$current.name);
        };
        // Listen for CMD + S event
        $scope.$on('quickSave:form', ctrl.saveForm);
    }

    angular.module('GeckoEngage').controller('FormCtrl', FormCtrl);
})();