(function () {
    'use strict';

    var getValueOrDefault = function getValueOrDefault(valueFunc, defaultValue) {
        try {
            return valueFunc();
        } catch (err) {
            return defaultValue;
        }
    };

    function CallsReportCtrl($stateParams, Gecko, asyncOptions, performance, outcomes, hour_of_call, campaigns, agents, senders) {
        var ctrl = this;
        ctrl.performance = performance.related_widgets;
        ctrl.outcomes = outcomes.related_widgets;
        ctrl.hour_of_call = hour_of_call.related_widgets;
        ctrl.campaigns = campaigns.toArray();
        ctrl.agents = agents.toArray();
        ctrl.senders = senders.toArray();

        // Get earliest refreshed_at
        ctrl.refreshedAt = [performance.refreshed_at, outcomes.refreshed_at, hour_of_call.refreshed_at].sort().shift();

        var months = function months() {
            return getValueOrDefault(function () {
                var i = ctrl.performance[0].related_stats.length ? ctrl.performance[0].related_stats.length - 1 : 0;
                return ctrl.performance[0].related_stats[i].result.map(function (result) {
                    return result.title;
                });
            }, []);
        };
        var monthsCols = function monthsCols() {
            return months().map(function (result) {
                var resultTitle = $stateParams.increment === 'week' ? weekToDateString(result) : result;
                return { title: resultTitle, key: result };
            });
        };
        var weekToDateString = function weekToDateString(week) {
            var weekNumber = Number(week.split(' ')[0]);
            var weekYear = week.split(' ')[1];
            var startOfWeek = moment(weekYear, 'YY').day('Sunday').week(weekNumber);
            var endOfWeek = moment(weekYear, 'YY').day('Sunday').week(weekNumber + 1);
            return startOfWeek.format('DD/MM') + ' - ' + endOfWeek.format('DD/MM');
        };

        // ==[ Result to Item functions ]==
        // Takes a unit, a custom render function to modify the stat before displaying it, a title and a list of
        // Results and returns an object that can be used by the gecko-report directive
        var resultToItem = function resultToItem(unit) {
            return function (renderFunc) {
                return function (title) {
                    return function (results) {
                        if (!results) {
                            results = [];
                        }
                        return results.reduce(function (item, result) {
                            item[result.title] = result.aggregate;
                            return item;
                        }, { title: title, unit: unit, render: renderFunc });
                    };
                };
            };
        };
        // Creates a basic report object that will just show the number/text as is
        var resultToPlainItem = resultToItem('')(undefined);
        // Creates a report object that converts the value to hours, minutes and seconds.
        var resultToMinuteSecondsItem = resultToItem('')(function (value) {
            var hours = Math.floor(value / 3600);
            var mins = Math.floor(value % 3600 / 60);
            var secs = value % 3600 % 60;
            if (hours) {
                return hours + 'h ' + mins + 'm ' + secs + 's';
            }
            if (mins) {
                return mins + 'm ' + secs + 's';
            }
            return secs + 's';
        });
        // =================================

        // ==[ Performance stuff ]==
        // Finds a performance result by it's title
        var findPerformanceResult = function findPerformanceResult(title) {
            if (ctrl.performance && angular.isArray(ctrl.performance)) {
                var widget = ctrl.performance.find(function (widget) {
                    return widget.name === title;
                });
            }
            var i = widget.related_stats.length ? widget.related_stats.length - 1 : 0;
            return widget && widget.related_stats && widget.related_stats[i] ? widget.related_stats[i].result : [];
        };
        // =========================

        // ==[ Outcomes stuff ]==
        // Removes some uncessary keys from an outcome result
        var trimOutcomeResult = function trimOutcomeResult(result) {
            return Object.keys(result).reduce(function (newRes, key) {
                if (key != 0 && key != 'title') {
                    newRes[key] = result[key];
                }
                return newRes;
            }, {});
        };
        // Finds and returns an outcome result by it's title
        var findRawOutcomeResult = function findRawOutcomeResult(title) {
            var result = getValueOrDefault(function () {
                return ctrl.outcomes[0].related_stats[0].result.find(function (result) {
                    return result.title === title;
                });
            }, []);
            return trimOutcomeResult(result);
        };
        // Takes an outcome result and outcome labels and maps the labels to the results
        var labelfyResult = function labelfyResult(labels) {
            return function (result) {
                return Object.keys(result).map(function (key) {
                    return {
                        aggregate: result[key],
                        month: labels[key],
                        title: labels[key]
                    };
                });
            };
        };
        // Builds an object containing the outcome labels to be used as column headers e.g. "Jul 16"
        var getOutcomeLabels = function getOutcomeLabels() {
            switch ($stateParams.increment) {
                case 'week':
                    return findRawOutcomeResult('Outcome / Week');
                case 'date':
                    return findRawOutcomeResult('Outcome / Date');
                case 'day':
                    return findRawOutcomeResult('Outcome / Day');
                default:
                    return findRawOutcomeResult('Outcome / Month');
            }
        };
        // Finds an outcome result by title and maps it to the necessary labels
        var findOutcomeResult = function findOutcomeResult(title) {
            var labels = getOutcomeLabels();
            return labelfyResult(labels)(findRawOutcomeResult(title));
        };
        // =======================

        // ==[ Hour of Call stuff ]==
        var findHourOfCallResult = function findHourOfCallResult(title) {
            var results = getValueOrDefault(function () {
                return angular.copy(ctrl.hour_of_call[0].related_stats[0].result['hour-of-call']);
            }, []);
            return results.map(function (result) {
                result.aggregate = result.aggregate.find(function (stat) {
                    return stat.title == title;
                }).aggregate;
                return result;
            });
        };
        // ==========================

        var performanceItems = [resultToPlainItem('Calls')(findPerformanceResult('Calls')), resultToPlainItem('Calls Answered')(findPerformanceResult('Calls answered')), resultToPlainItem('Calls Abandoned')(findPerformanceResult('Calls abandoned')), resultToMinuteSecondsItem('Avg Wait Duration')(findPerformanceResult('Avg wait duration')), resultToMinuteSecondsItem('Avg Wrap-up Duration')(findPerformanceResult('Avg wrap up duration')), resultToMinuteSecondsItem('Avg Call Duration')(findPerformanceResult('Avg call duration')), resultToMinuteSecondsItem('Avg Idle Duration')(findPerformanceResult('Avg idle duration'))];

        var outcomeLabels = getValueOrDefault(function () {
            var labels = ctrl.outcomes[0].related_stats[0].labels;
            labels.splice(0, 1); // Remove the column header label
            return labels;
        }, []);
        var outcomeItems = outcomeLabels.map(function (outcome) {
            return resultToPlainItem(outcome)(findOutcomeResult(outcome));
        });

        var hourOfCallItems = [resultToPlainItem('00:00 - 01:00')(findHourOfCallResult('0')), resultToPlainItem('01:00 - 02:00')(findHourOfCallResult('1')), resultToPlainItem('02:00 - 03:00')(findHourOfCallResult('2')), resultToPlainItem('03:00 - 04:00')(findHourOfCallResult('3')), resultToPlainItem('04:00 - 05:00')(findHourOfCallResult('4')), resultToPlainItem('05:00 - 06:00')(findHourOfCallResult('5')), resultToPlainItem('06:00 - 07:00')(findHourOfCallResult('6')), resultToPlainItem('07:00 - 08:00')(findHourOfCallResult('7')), resultToPlainItem('08:00 - 09:00')(findHourOfCallResult('8')), resultToPlainItem('09:00 - 10:00')(findHourOfCallResult('9')), resultToPlainItem('10:00 - 11:00')(findHourOfCallResult('10')), resultToPlainItem('11:00 - 12:00')(findHourOfCallResult('11')), resultToPlainItem('12:00 - 13:00')(findHourOfCallResult('12')), resultToPlainItem('13:00 - 14:00')(findHourOfCallResult('13')), resultToPlainItem('14:00 - 15:00')(findHourOfCallResult('14')), resultToPlainItem('15:00 - 16:00')(findHourOfCallResult('15')), resultToPlainItem('16:00 - 17:00')(findHourOfCallResult('16')), resultToPlainItem('17:00 - 18:00')(findHourOfCallResult('17')), resultToPlainItem('18:00 - 19:00')(findHourOfCallResult('18')), resultToPlainItem('19:00 - 20:00')(findHourOfCallResult('19')), resultToPlainItem('20:00 - 21:00')(findHourOfCallResult('20')), resultToPlainItem('21:00 - 22:00')(findHourOfCallResult('21')), resultToPlainItem('22:00 - 23:00')(findHourOfCallResult('22')), resultToPlainItem('23:00 - 00:00')(findHourOfCallResult('23'))];

        ctrl.cols = [{
            key: 'title',
            static: true,
            staticWidth: '220px',
            ignoreUnit: true,
            ignoreRender: true
        }];
        // Add the month columns
        ctrl.cols = ctrl.cols.concat(monthsCols());
        // Add the totals column
        ctrl.cols.push({
            title: 'Total',
            aggregate: function aggregate(item) {
                if (!months().length) {
                    return 0;
                }
                var sum = months().reduce(function (sum, month) {
                    return sum + item[month];
                }, 0);
                return item.title.indexOf('Avg') !== -1 ? Math.floor(sum / months().length) : sum;
            },
            static: true,
            staticWidth: '110px'
        });

        ctrl.groups = [{
            title: 'Performance Metrics',
            items: performanceItems
        }, {
            title: 'Outcomes',
            items: outcomeItems
        }, {
            title: 'Hour of Call UTC',
            items: hourOfCallItems
        }];

        // Takes an array of objects and the label key and maps them to options by id
        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (obj) {
                    return { label: obj[labelKey], value: obj['id'] };
                });
            };
        };

        ctrl.filters = [{
            title: 'Calls',
            type: 'dropdown',
            options: objectsToOptions(Gecko.Call.type_titles)('title'),
            stateParam: 'type'
        }, {
            title: 'Outcome',
            type: 'dropdown',
            options: [],
            optionsQuery: new Gecko.Outcome().rfields({ outcome: ['name'] }),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            stateParam: 'outcome'
        }, {
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'date_from',
                dateTo: 'date_to'
            }
        }, {
            title: 'Increments',
            type: 'dropdown',
            options: [{ label: 'Months', value: 'month' }, { label: 'Weeks(mon-fri)', value: 'week' }, { label: 'Date', value: 'date' }, { label: 'Days', value: 'day' }],
            stateParam: 'increment'
        }, {
            title: 'Agent',
            type: 'dropdown',
            options: objectsToOptions(ctrl.agents)('full_name'),
            stateParam: 'agent'
        }, {
            title: 'Phone Number',
            type: 'dropdown',
            options: objectsToOptions(ctrl.senders)('name'),
            stateParam: 'phoneline'
        }, {
            title: 'Campaign',
            type: 'checkbox',
            options: objectsToOptions(ctrl.campaigns)('title'),
            stateParam: 'campaign'
        }];
    }

    angular.module('GeckoEngage').controller('CallsReportCtrl', CallsReportCtrl);
})();