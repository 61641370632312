(function () {
    'use strict';

    function ContactResponsesCtrl($state, $stateParams, $geckoModal, contact, responses) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.responses = responses.toArray();
        ctrl.pagination = responses.pagination;

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            colClass: 'col-xs-2'
        }, {
            title: 'Form',
            key: 'form.name',
            render: function render(value, col, row) {
                if (row.form && row.form.internal_name) return row.form.internal_name;
                return value;
            },
            colClass: 'col-xs-2'
        }, {
            title: 'Event',
            key: 'event.title',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Assigned to',
            key: 'assigned.full_name',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Captured by',
            key: 'user.full_name',
            colClass: 'col-xs-2'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'contact.response',
            params: { response_id: 'id' }
        };

        // Add response
        ctrl.headerBtns = [{
            title: 'Add response',
            icon: 'fa-plus',
            action: function action() {
                $geckoModal.selectorModal({
                    title: 'Select a Form',
                    model: 'form',
                    optionsKey: 'uuid',
                    optionsLabel: 'name'
                }).result.then(function (uuid) {
                    $state.go('responseadd', { uuid: uuid, contact_id: ctrl.contact.id });
                });
            }
        }];
    }

    angular.module('GeckoEngage').controller('ContactResponsesCtrl', ContactResponsesCtrl);
})();