/* global Gecko GeckoUI */
(function () {
    'use strict';

    function $geckoCall($rootScope, $state, $q, $stateParams, $timeout, $filter, $geckoVoip, $geckoConf, LoadingIndicator) {
        var _this = {

            callIncludes: ['contact', 'number', 'number.contacts:all', 'subscriber', 'outcome', 'user', 'sender', 'sender.inbound_campaigns', 'campaign', 'campaign.outcomes:all'],

            activeCallIncludes: ['active_call', 'active_call.contact', 'active_call.number', 'active_call.number.contacts', 'active_call.subscriber', 'active_call.outcome', 'active_call.user', 'active_call.sender', 'active_call.sender.inbound_campaigns', 'active_call.campaign'],

            call: null,

            onCall: false,

            is: function is(type, value) {
                return _this.call && _this.call[type] == value;
            },

            isInbound: function isInbound() {
                return _this.is('type', Gecko.Call.TYPE_INBOUND);
            },

            isOutbound: function isOutbound() {
                return _this.is('type', Gecko.Call.TYPE_OUTBOUND);
            },

            isOnHold: function isOnHold() {
                return _this.is('status', Gecko.Call.HOLD);
            },

            hasPrepared: function hasPrepared() {
                return _this.is('status', Gecko.Call.PREPARING);
            },

            hasStarted: function hasStarted() {
                return _this.is('status', Gecko.Call.STARTED) || _this.is('status', Gecko.Call.HOLD);
            },

            hasEnded: function hasEnded() {
                return _this.is('status', Gecko.Call.ENDED);
            },

            hasCompleted: function hasCompleted() {
                return _this.is('status', Gecko.Call.COMPLETE);
            },

            belongsToOtherUser: function belongsToOtherUser() {
                return _this.call && Gecko.user && _this.call.user_id !== Gecko.user.id;
            },

            // Get the number title text (if exsists and not Anonymous)
            getNumberTitle: function getNumberTitle(number) {
                // Use passed in number
                if (number !== undefined) {
                    if (Gecko.Number.isAnonymous(number)) {
                        return 'Unknown number';
                    }
                    return number.cleaned || number.original;
                }

                // Use current call / or listening in number
                var call = _this.isListening() ? $geckoConf.call : _this.call;

                if (call && call.related_number) {
                    if (Gecko.Number.isAnonymous(call.related_number)) {
                        return 'Unknown number';
                    }
                    return call.related_number.cleaned || call.related_number.original;
                }

                return '';
            },

            data: {},

            reset: function reset(hide) {
                _this.call = null;
                _this.onCall = false;
                _this.isPreparing = false;
                _this.prepareType = null;

                if (hide) {
                    $timeout(_this.close);
                }
            },

            restore: function restore(call) {
                // Check call package is enabled
                if (!Gecko.User.hasOutboundOrInbound() || !call || !call.id) return false;
                // Set call on service and set onCall property
                _this.call = call;
                _this.onCall = [Gecko.Call.STARTED, Gecko.Call.ENDED, Gecko.Call.HOLD].indexOf(_this.call.status) != -1 ? true : false;
            },

            initSubscriber: function initSubscriber(subscriber) {
                return new Gecko.Call().include(_this.callIncludes).getPrepared(subscriber);
            },

            // Get initial call data
            getData: function getData(contact_id, campaign_id) {
                // Get initial data
                if (contact_id || campaign_id) {
                    var _query = {};
                    // Contact
                    var contactRfields = {
                        number: ['id', 'cleaned', 'country_code', 'original', 'type', 'valid'],
                        value: ['id', 'number_id', 'safe'],
                        field: ['id', 'label', 'type', 'contact_field_id']
                    };
                    if (contact_id) {
                        _query.contact = new Gecko.Contact().rfields(contactRfields).include('numbers', 'current_values:all', 'current_values.field').where('contact_id', contact_id);
                        _query.fields = new Gecko.Field().rfields({ field: ['id', 'label'] }).where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).where('type', Gecko.Field.TYPE_TEL);
                    }

                    // Campaign
                    if (campaign_id) _query.campaign = new Gecko.Campaign().include('outcomes:all').where('campaign_id', campaign_id).perPage(1);

                    return Gecko.multi(_query);
                }
                // Return a promise if there is no data to get
                var q = $q.defer();
                q.resolve({});
                return q.promise;
            },

            // Get data, set data and open call pane
            _init: function _init(contact_id, campaign_id) {
                return _this.getData(contact_id, campaign_id).then(function (data) {
                    $timeout(function () {
                        _this.activate(data);
                    });
                });
            },

            // Set data and open call pane
            activate: function activate(data) {
                _this.data = data ? data : {};
                _this.isOpen = true;
                // $geckoCallSidebar.open = true;
                // Only digest if no data has been queried
                if (!data) $rootScope.$digest();
            },

            isOpen: false,

            isIncoming: function isIncoming() {
                return Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL) && $geckoVoip.isIncoming;
            },

            isTransfering: function isTransfering() {
                return Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL) && $geckoVoip.isTransfering;
            },

            isActive: function isActive() {
                return Gecko.has(Gecko.Package.FEATURE_CALL) && _this.onCall && !_this.isOpen;
            },

            isListening: function isListening() {
                return Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL) && $geckoConf.isConnected;
            },

            isReconnecting: function isReconnecting() {
                return _this.hasEnded() && $geckoVoip.hasStarted;
            },

            canListen: function canListen() {
                return !this.onCall && !this.isListening();
            },

            isPreparing: false,
            prepareType: null,

            prepare: function prepare(type, numberString) {

                // Reset previous prepare
                _this.reset();
                // Type is dial, contact or subscriber
                $timeout(function () {
                    _this.prepareType = type;
                    _this.isPreparing = true;
                    if (numberString) _this.numberString = numberString;
                });
            },

            open: function open(contact_id, campaign_id, numberString) {

                // Possible number to init
                if (numberString) _this.numberString = numberString;

                // Check call package is enabled
                if (!Gecko.has(Gecko.Package.FEATURE_CALL)) return false;

                // Determine values to query
                contact_id = _this.call && _this.call.contact_id ? _this.call.contact_id : contact_id;
                campaign_id = _this.call && _this.call.campaign_id ? _this.call.campaign_id : campaign_id;

                // Get data and open pane
                return _this._init(contact_id, campaign_id);
            },

            close: function close() {
                _this.isOpen = false;
            },

            get: function get(callId) {
                return new Gecko.Call().ignoreSession().include(_this.callIncludes).get(callId);
            },

            refresh: function refresh() {
                return angular.copy(_this.call).include(_this.callIncludes).get(_this.call.id);
            },

            update: function update(includes) {
                return _this.call.include(includes || _this.callIncludes).save();
            },

            start: function start(contact, number) {
                // Start prepared call
                if (_this.call && _this.call.status == Gecko.Call.PREPARING) return _this.call.include(_this.callIncludes).start(number);
                // Prepare and start call
                return new Gecko.Call().include(_this.callIncludes).prepare(contact, number, 1);
            },

            dial: function dial(number) {
                return new Gecko.Call().include(_this.callIncludes).create({ number_id: number.id }).prepare(null, number, 1);
            },

            end: function end() {
                return _this.call.include(_this.callIncludes).end();
            },

            complete: function complete(outcome_id, action, call_from, note) {
                return _this.call.include(_this.callIncludes).complete(outcome_id, action, call_from, note);
            },

            connect: function connect() {
                // If (callId) {
                //     Return new Gecko.Call().include(_this.callIncludes).connect(callId);
                // }
                return new Gecko.Call().include(_this.callIncludes).inboundNext(true);
            },

            join: function join(callId) {
                return new Gecko.Call().include(_this.callIncludes).join(callId);
            },

            cancel: function cancel() {
                return _this.call.cancel();
            },

            hold: function hold() {
                // Show loading indicator
                _this.loadingIndicator(true);
                // Prepare hold action
                var holdAction;
                if (!_this.isOnHold()) {
                    holdAction = _this.call.include(_this.callIncludes).callHold();
                } else {
                    holdAction = _this.call.include(_this.callIncludes).callResume();
                }
                // Wait for hold action to complete
                holdAction.then(function (call) {
                    _this.restore(call);
                    // Hide indicator
                    _this.loadingIndicator(false);
                    $rootScope.$digest();
                }).catch(function (err) {
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Call Hold/Unhold Error', {
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            },

            transfer: function transfer(type, typeId) {
                var _params = {
                    hold: 1
                };
                if (type === 'number') {
                    _params.number_id = typeId;
                } else if (type === 'user') {
                    _params.user_id = typeId;
                }
                return _this.call.include(_this.callIncludes).transfer(_params);
            },

            // Save note
            note: function note(_note) {
                return new Gecko.Note().create({ call_id: _this.call.id, note: _note }).save();
            },

            returnTo: function returnTo() {
                // Only redirect if there is a call
                if (!_this.call) return false;
                if (_this.call.subscriber_id) {
                    return $state.go('contact.subscriber', { contact_id: _this.call.contact_id, subscriber_id: _this.call.subscriber_id }, { reload: true });
                } else {
                    return $state.go('contact.overview', { contact_id: _this.call.contact_id, subscriber_id: null }, { reload: true });
                }
            },

            next: function next(campaign, subscriber_id) {
                if (!campaign) return false;
                campaign.nextSubscriber().then(function (subscriber) {
                    // Close the call pane
                    _this.close();
                    // Navigate
                    if (subscriber_id == subscriber.id) {
                        // Next call is this subscriber
                        return $state.go('campaignsedit.view', { campaign_id: campaign.id, module: campaign.module });
                    } else if (subscriber.contact_id) {
                        // There's another person to call
                        return $state.go('contact.subscriber', { contact_id: subscriber.contact_id, subscriber_id: subscriber.id });
                    } else {
                        // Think we're all done here
                        return $state.go('campaignsedit.view', { campaign_id: campaign.id, module: campaign.module });
                    }
                }).catch(function (err) {
                    // Close the call pane
                    _this.close();
                    // Navigate
                    return $state.go('campaignsedit.view', { campaign_id: campaign.id, module: campaign.module });
                });
            },

            // VOIP
            startVoip: function startVoip() {
                // Check VOIP is enabled
                if (!$geckoVoip.enabled) return false;
                // We need to find out if sender is from GeckoChat
                var useChatDevice = _this.call.related_sender.type === Gecko.Sender.GECKOCHAT;

                // Start VOIP call
                if (_this.call.twilio_id && !$geckoVoip.hasStarted) {
                    GeckoUI.dialog.confirm("You may have been disconnected, do you wish to redial?", function (value) {
                        if (value) {
                            return $geckoVoip.start(_this.call.id, useChatDevice);
                        }
                    });
                } else {
                    if ($geckoVoip.isReady) {
                        return $geckoVoip.start(_this.call.id, useChatDevice);
                    }
                }
            },

            // End VOIP call
            endVoip: function endVoip() {
                if ($geckoVoip.enabled) return $geckoVoip.end();
            },

            // Reconnect Voip call
            reconnectVoip: function reconnectVoip() {
                if ($geckoVoip.enabled) {
                    var useChatDevice = _this.call.related_sender.type === Gecko.Sender.GECKOCHAT;
                    return $geckoVoip.reconnect(_this.call.id, useChatDevice);
                };
            },

            // VOIP muting
            isMute: function isMute() {
                return $geckoVoip.isMuted();
            },

            toggleMute: function toggleMute() {
                return $geckoVoip.toggleMute();
            },

            press: function press(digit) {
                return $geckoVoip.pressDigit(digit);
            },

            getActiveCall: function getActiveCall() {
                return new Gecko.User().include(_this.activeCallIncludes).get(Gecko.user.id).then(function (user) {
                    return user.related_active_call;
                });
            },

            init: function init() {
                Gecko.on('available', function () {
                    _this.getActiveCall().then(function (activeCall) {
                        // Set current/active call
                        _this.restore(activeCall);

                        // Get any queued calls
                        $timeout(function () {
                            $geckoVoip.accept = true;
                        }, 3000);
                    });
                });

                // Reset call service when logged out
                Gecko.on('unavailable', function () {
                    _this.reset(true);
                });
            },

            prepareOutcomes: function prepareOutcomes(outcomes, type, campaign, ignoreConsents) {

                var _outcomes = outcomes;

                if (type === Gecko.Call.TYPE_OUTBOUND) {
                    // Remove inbound types
                    _outcomes = $filter('filter')(_outcomes, { 'call_type': '!' + Gecko.Outcome.CALL_TYPE_INBOUND });
                    // Remove reschedule options if part of campaign
                    if (!campaign) {
                        _outcomes = $filter('filter')(_outcomes, { 'action': '!' + Gecko.Outcome.ACTION_RESCHEDULE });
                    }
                } else if (type === Gecko.Call.TYPE_INBOUND) {
                    // Remove outbound types
                    _outcomes = $filter('filter')(_outcomes, { 'call_type': '!' + Gecko.Outcome.CALL_TYPE_OUTBOUND });
                }

                // Remove DO NOT CALL outcomes (when ignoring consents)
                /*
                if (!Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || (campaign && ignoreConsents)) {
                    _outcomes = _outcomes.filter(o => o.action !== Gecko.Call.ACTION_DO_NOT_CALL);
                }
                */

                // Order by name
                return $filter('orderBy')(_outcomes, 'name');
            },

            prepareNumber: function prepareNumber(value, number) {
                // Return full number object (if found in the list)
                if (number) {
                    if (value.related_field) {
                        number.contact_field_id = value.related_field.contact_field_id || value.related_field.id;
                        number.contact_field_label = _this.getNumberLabel(value);
                    }
                    return number;
                }
                // Fallback number obj
                return {
                    id: value.number_id,
                    contact_field_id: (value.related_field || {}).id,
                    contact_field_label: (value.related_field || {}).label,
                    original: value.safe
                };
            },

            prepareNumbers: function prepareNumbers(numbers) {
                var preparedNumbers = [];
                var contactValues = (_this.data.contact || {}).related_current_values || [];
                numbers = numbers || [];

                // Get numbers from TELEPHONE contact values
                preparedNumbers = contactValues.filter(function (v) {
                    return v.number_id && (v.related_field || {}).type === 'tel';
                    // Replace with number and add references for field label and id
                }).map(function (v) {
                    return _this.prepareNumber(v, numbers.find(function (n) {
                        return n.id === v.number_id;
                    }));
                });

                // Add any missing numbers to the list
                numbers.forEach(function (number) {
                    if (!preparedNumbers.find(function (n) {
                        return n.id === number.id;
                    })) {
                        preparedNumbers.push(number);
                    }
                });

                return preparedNumbers;
            },

            loadingIndicator: function loadingIndicator(show) {
                if (show) {
                    return LoadingIndicator.show('loading-indicator-call', 0);
                }
                // Hide by default
                return LoadingIndicator.hide('loading-indicator-call');
            },

            clickToCall: function clickToCall(number, target) {
                // Do nothing if there is no number
                if (!number || _this.isOpen) return false;

                // Reset prepare data
                _this.reset(true);

                // Open call pane with specified contact
                if (target && (target.contact_id || target.object_key === 'contact')) {
                    return _this.open(target.contact_id || target.id, target.campaign_id, number);
                }

                return _this.prepare('dial', number);
            },

            getNumberLabel: function getNumberLabel(value) {
                var field = _this.data.fields.data.find(function (field) {
                    return field.id === value.related_field.contact_field_id;
                });
                return field && field.label ? field.label : value.related_field.label;
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoCall', $geckoCall);
})();