(function () {
    'use strict';

    function geckoCallEndCard($geckoCall, $geckoConf, $geckoPresence, $geckoVoip, $geckoTransfer, $geckoWatch) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-end-card.html',

            require: ['?^geckoCallPane', '?^geckoCallPaneListen'],

            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0] || ctrls[1];

                // Call has started
                if ($geckoCall.hasStarted() || $geckoCall.isListening() || $geckoCall.isReconnecting()) ctrl.timerSetup();

                // Card buttons
                if ($geckoCall.isListening()) {
                    ctrl.endCardBtns = [{
                        icon: 'fa-stop',
                        title: 'Stop',
                        btnClass: 'btn-danger',
                        safeMode: true,
                        action: $geckoConf.end
                    }];
                } else if ($geckoCall.hasStarted()) {
                    ctrl.endCardBtns = [{
                        safeMode: true,
                        title: 'Take ownership',
                        btnClass: 'btn-primary',
                        hideWhen: function hideWhen() {
                            return !$geckoCall.belongsToOtherUser();
                        },
                        action: function action() {
                            // Show loading indicator
                            $geckoCall.loadingIndicator(true);

                            // Update user id on call
                            $geckoCall.call.user_id = Gecko.user.id;
                            // Take the caller off hold
                            $geckoCall.call.hold = 0;

                            // Save Call
                            $geckoCall.update().then(function (call) {
                                $geckoCall.restore(call);
                                // Notify other users on call of change
                                $geckoTransfer.sendFeedback($geckoCall.call.id, 'take');
                                // Hide loading indicator
                                $geckoCall.loadingIndicator(false);
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                                // Hide loading indicator
                                $geckoCall.loadingIndicator(false);
                            });
                        }
                    }, {
                        safeMode: true,
                        title: 'Leave',
                        btnClass: 'btn-danger',
                        hideWhen: function hideWhen() {
                            return !$geckoCall.belongsToOtherUser();
                        },
                        action: function action() {
                            GeckoUI.dialog.confirm('Are you sure you want to leave this call?', function (ok) {
                                if (ok) {
                                    // Notify other users on call of change
                                    if ($geckoCall.call) $geckoTransfer.sendFeedback($geckoCall.call.id, 'leave');
                                    return $geckoVoip.end();
                                }
                            });
                        }
                    }, {
                        safeMode: true,
                        btnTooltip: 'End call',
                        btnClass: 'btn-danger',
                        icon: 'fa-phone fa-rotate-90',
                        hideWhen: function hideWhen() {
                            return $geckoCall.belongsToOtherUser();
                        },
                        action: ctrl.endCall
                    }, {
                        position: 'secondary',
                        icon: 'fa-pause',
                        btnTooltip: 'Hold',
                        action: $geckoCall.hold,
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || $geckoCall.isOnHold() || $geckoCall.isOutbound() || $geckoCall.belongsToOtherUser();
                        }
                    }, {
                        position: 'secondary',
                        icon: 'fa-pause',
                        btnClass: 'btn-info',
                        btnTooltip: 'Resume',
                        action: $geckoCall.hold,
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || !$geckoCall.isOnHold() || $geckoCall.isOutbound() || $geckoCall.belongsToOtherUser();
                        }
                    }, {
                        position: 'secondary',
                        icon: 'fa-microphone-slash',
                        btnClass: 'btn-info',
                        btnTooltip: 'Un-mute',
                        action: $geckoCall.toggleMute,
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || !$geckoCall.isMute() || $geckoCall.belongsToOtherUser();
                        }
                    }, {
                        position: 'secondary',
                        icon: 'fa-microphone',
                        btnTooltip: 'Mute',
                        action: $geckoCall.toggleMute,
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || $geckoCall.isMute() || $geckoCall.belongsToOtherUser();
                        }
                    }, {
                        btnId: 'keypad',
                        position: 'secondary',
                        icon: 'fa-tty',
                        btnTooltip: 'Number Pad',
                        action: function action() {
                            if (ctrl.showKeypad) {
                                ctrl.showKeypad = false;
                            } else {
                                ctrl.showKeypad = true;
                            }
                        },
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || $geckoCall.belongsToOtherUser();
                        }
                    }, {
                        btnId: 'transfer',
                        position: 'secondary',
                        icon: 'fa-random',
                        btnTooltip: 'Transfer',
                        action: ctrl.toggleTransfer,
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || $geckoCall.isOutbound() || Gecko.User.isGroup(Gecko.Group.SUPER) || $geckoCall.belongsToOtherUser();
                        }
                    }];

                    $geckoWatch(ctrl, 'showKeypad', function (value) {
                        GeckoUI.gobk(ctrl.endCardBtns, 'btnId', 'keypad').btnClass = value ? 'btn-info' : 'btn-default';
                    });
                    $geckoWatch(ctrl, 'transerOpen', function (value) {
                        GeckoUI.gobk(ctrl.endCardBtns, 'btnId', 'transfer').btnClass = value ? 'btn-info' : 'btn-default';
                    });
                } else if ($geckoCall.isReconnecting()) {
                    ctrl.endCardBtns = [{
                        safeMode: true,
                        btnTooltip: 'End call',
                        btnClass: 'btn-danger',
                        icon: 'fa-phone fa-rotate-90',
                        action: ctrl.endCall.bind(this, true)
                    }, {
                        position: 'secondary',
                        icon: 'fa-microphone-slash',
                        btnClass: 'btn-info',
                        btnTooltip: 'Un-mute',
                        action: $geckoCall.toggleMute,
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || !$geckoCall.isMute() || $geckoCall.belongsToOtherUser();
                        }
                    }, {
                        position: 'secondary',
                        icon: 'fa-microphone',
                        btnTooltip: 'Mute',
                        action: $geckoCall.toggleMute,
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || $geckoCall.isMute() || $geckoCall.belongsToOtherUser();
                        }
                    }, {
                        btnId: 'keypad',
                        position: 'secondary',
                        icon: 'fa-tty',
                        btnTooltip: 'Number Pad',
                        action: function action() {
                            if (ctrl.showKeypad) {
                                ctrl.showKeypad = false;
                            } else {
                                ctrl.showKeypad = true;
                            }
                        },
                        hideWhen: function hideWhen() {
                            return !$geckoVoip.enabled || $geckoCall.belongsToOtherUser();
                        }
                    }];
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallEndCard', geckoCallEndCard);
})();