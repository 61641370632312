(function () {
    'use strict';

    function SessionCtrl($state, $scope, $stateParams, $geckoModal, event, session, integrations, locations, hosts, $filter) {
        var ctrl = this;
        ctrl.event = event;
        ctrl.session = session;
        ctrl.integrations = integrations;
        ctrl.locations = locations;
        ctrl.hosts = hosts;

        if (ctrl.session.type === Gecko.Event.TYPE_SESSION_LEGACY) {
            $state.go('legacy-event.session', { event_id: ctrl.event.id, session_id: ctrl.session.id });
        }

        // Redirect to overview page (if no sub-route is found)
        if ($state.$current.name == 'session') $state.go('session.overview');

        ctrl.getPrettyTimezone = function (event) {
            return (event.timezone || Gecko.account.timezone).replace('Etc/', '').replace('/', ' - ').replace('_', ' ');
        };

        // Tabs
        ctrl.tabs = [{
            id: 'overview',
            title: 'Overview',
            state: 'event.overview',
            params: '{event_id: ' + $stateParams.event_id + '}'
        }, {
            id: 'edit',
            title: 'Edit',
            state: 'event.settings',
            params: '{event_id: ' + $stateParams.event_id + '}'
        }, {
            id: 'sessions',
            title: 'Sessions',
            state: 'event.sessions',
            params: '{event_id: ' + $stateParams.event_id + '}',
            activeWhen: function activeWhen() {
                return true;
            }
        }, {
            id: 'workflows',
            title: 'Workflows',
            state: 'event.workflows',
            params: '{event_id: ' + $stateParams.event_id + '}',
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_WORKFLOWS_TAB);
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Events',
            click: function click() {
                return $state.go('events');
            }
        }, {
            label: ctrl.event.nice_title,
            click: function click() {
                return $state.go('event.overview', $stateParams);
            }
        }, {
            label: 'Sessions',
            click: function click() {
                return $state.go('event.sessions', $stateParams);
            }
        }, {
            label: ctrl.session.id ? ctrl.session.title : 'Add Session',
            active: true
        }];

        // Action button
        ctrl.actionBtn = {
            title: 'Actions',
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary'
        };
        ctrl.actionBtn.items = [{
            title: 'Edit session',
            action: function action() {
                return $geckoModal.eventSessionModal(ctrl.event, ctrl.session, { locations: ctrl.locations, hosts: ctrl.hosts });
            }
        }, {
            title: 'Email session attendees',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE) || !GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_UPDATE)) return;

                var statuses = $stateParams.status || [Gecko.Event.STATUS_REGISTERED, Gecko.Event.STATUS_INVITED, Gecko.Event.STATUS_ATTENDED, Gecko.Event.STATUS_ENGAGED];

                var conditions = [{ model: Gecko.Condition.MODEL_ATTENDANCE, event_id: ctrl.session.id, value: statuses }];

                if ($stateParams.search) {
                    conditions.push({ model: Gecko.Condition.MODEL_KEYWORD, value: $stateParams.search });
                }

                $geckoModal.messageSendModal(conditions, { event_id: ctrl.session.id });
            }
        }, {
            title: 'Export session attendees',
            action: function action() {
                $state.go('exportnew', { export_id: 'new', event_id: ctrl.session.id });
            }
        }, {
            title: 'View session dashboard',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_DASHBOARD)) {
                    return;
                }
                return $state.go('dashboardview', {
                    event_id: ctrl.session.id,
                    override_date: 'month'
                });
            },
            hideWhen: function hideWhen() {
                if (!Gecko.able(Gecko.ABILITY_EVENTS_DASHBOARD)) {
                    return true;
                }
                return !Gecko.has(Gecko.Package.FEATURE_EVENT);
            }
        }, {
            render: function render() {
                return 'Remove this session';
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_DELETE);
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to remove <b>' + ctrl.session.title + '</b>?', function (value) {
                    if (value) {
                        ctrl.session.remove().then(function () {
                            GeckoUI.messenger.success('Event removed');
                            return $state.go('event.sessions', $stateParams);
                        }).catch(function (err) {
                            return GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        }];

        // Sessions dropdown button (moved out od resolve for performance reasons)
        new Gecko.Event().where('parent_id', $stateParams.event_id).rfields({ event: ['title'] }).perPage('all').get().then(function (events) {
            // Set sessions as event children
            ctrl.event.children = events.toArray();

            // Only create button if other sessions exsist
            if (ctrl.event.children && ctrl.event.children.length) {
                // Dont show current session in dropdown
                ctrl.event.children = (ctrl.event.children || []).filter(function (session) {
                    return session.id !== ctrl.session.id;
                });
                ctrl.sessionsBtn = {
                    title: 'Switch Sessions',
                    iconRight: 'fa-angle-down',
                    items: []
                };
                ctrl.sessionsBtn.items.push({
                    title: 'View All Sessions',
                    action: function action() {
                        return $state.go('event.sessions', { event_id: ctrl.event.id });
                    }
                }, {
                    title: "Switch to Session",
                    heading: true
                });
                ctrl.event.children.forEach(function (session) {
                    ctrl.sessionsBtn.items.push({
                        title: session.title,
                        action: function action() {
                            return $state.go($state.$current.name, { event_id: ctrl.event.id, session_id: session.id, session: null });
                        }
                    });
                });
                $scope.$digest();
            }
        });

        ctrl.showSaveBtn = function () {
            return ['session.times', 'session.settings'].indexOf($state.$current.name) != -1;
        };

        ctrl.saveEvent = function () {
            $scope.$broadcast('saveEvent', ctrl.event);
        };
        // Listen for CMD + S event
        $scope.$on('quickSave:event', ctrl.saveEvent);
    }

    angular.module('GeckoEngage').controller('SessionCtrl', SessionCtrl);
})();