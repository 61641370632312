(function () {
    'use strict';

    var notSupportedPrepopulateTypes = [Gecko.Field.TYPE_CONSENT, Gecko.Field.TYPE_EVENT, Gecko.Field.TYPE_FILE, Gecko.Field.TYPE_FILEPICKER, Gecko.Field.TYPE_MATRIX, Gecko.Field.TYPE_ORGANISATION, Gecko.Field.TYPE_MEDIA, Gecko.Field.TYPE_REPEATABLE, Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_SECTION];

    function geckoFormSharePopulateFields($formBuilderService) {
        return {
            restrict: 'E',
            scope: {
                formFields: '=',
                shareUrlData: '='
            },
            template: '\n                <div>\n                    <div ng-repeat="pair in ctrl.values track by $index">\n                        <gecko-form-share-populate-fields-pair\n                            values="ctrl.values"\n                            form-fields="ctrl.formFields"\n                            pair="pair"\n                            index="$index">\n                        </gecko-form-share-populate-fields-pair>\n                    </div>\n                </div>\n\n                <div class="text-left clearfix">\n                    <gecko-btn\n                        btn-title="Add Field"\n                        btn-class="pull-left btn-sm btn-primary"\n                        icon="fa-plus"\n                        ng-click="ctrl.addValuePair()">\n                    </gecko-btn>\n                    &nbsp;\n                    <gecko-btn\n                        btn-title="Update Link"\n                        icon="fa-sync"\n                        btn-class="pull-right btn-sm btn-default"\n                        ng-click="ctrl.updateShareUrl()">\n                    </gecko-btn>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            link: function link(scope, element, attributes, ctrl) {

                var cachedValues = $formBuilderService.getPrepopulatePairs();

                //Remove unsupported field types (child fields, from parent/child fields are not supported)
                ctrl.formFields = ctrl.formFields.filter(function (field) {
                    return notSupportedPrepopulateTypes.indexOf(field.type) === -1 && !field.parent_id;
                });
                // Remove cached pairs if field has been removed from the form
                if (cachedValues && cachedValues.length) {
                    cachedValues = cachedValues.filter(function (value) {
                        return GeckoUI.gobk(ctrl.formFields, 'tag', value.key);
                    });
                }

                var newPair = {
                    key: null,
                    value: ''
                };

                //Add new value pair
                ctrl.addValuePair = function () {
                    //Check last item has values
                    if (!ctrl.values.length || ctrl.values[ctrl.values.length - 1] && ctrl.values[ctrl.values.length - 1].key && ctrl.values[ctrl.values.length - 1].value) {
                        // Add new key/value pair
                        ctrl.values.push(angular.copy(newPair));
                    }
                };

                //Generate query string
                ctrl.updateShareUrl = function () {
                    //Cache values
                    $formBuilderService.setPrepopulatePairs(ctrl.values);
                    //Update share links
                    ctrl.shareUrlData.link = $formBuilderService.buildShareURL(ctrl.shareUrlData.uuid, ctrl.shareUrlData.style, ctrl.shareUrlData.event_id, false, ctrl.shareUrlData.captured_by_id);
                    ctrl.shareUrlData.safeLink = $formBuilderService.buildShareURL(ctrl.shareUrlData.uuid, ctrl.shareUrlData.style, ctrl.shareUrlData.event_id, true, ctrl.shareUrlData.captured_by_id);
                    ctrl.shareUrlData.iframeLegacy = $formBuilderService.buildShareUrlWrapper($formBuilderService.buildShareURL(ctrl.shareUrlData.uuid, 'iframe', ctrl.shareUrlData.event_id, false, ctrl.shareUrlData.captured_by_id), true);
                    ctrl.shareUrlData.iframe = $formBuilderService.buildShareUrlWrapper($formBuilderService.buildIframeShareURL(ctrl.shareUrlData.uuid, ctrl.shareUrlData.event_id, ctrl.shareUrlData.captured_by_id));
                };

                //Load cached values
                if (cachedValues && cachedValues.length) {
                    ctrl.values = cachedValues;
                } else {
                    ctrl.values = [angular.copy(newPair)];
                }
            }
        };
    }

    function geckoFormSharePopulateFieldsPair(asyncOptions, $formBuilderService, geckoDataService) {
        return {
            restrict: 'E',
            scope: {
                formFields: '=',
                values: '=',
                pair: '=',
                index: '='
            },
            template: '\n                <div class="clearfix">\n                    <gecko-fields\n                        fields="ctrl.fields"\n                        values="ctrl.pair">\n                    </gecko-fields>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            link: function link(scope, element, attributes, ctrl) {
                var getField = function getField() {
                    return (ctrl.formFields || []).find(function (f) {
                        return f.tag === ctrl.pair.key;
                    }) || {};
                };

                var fieldType = function fieldType() {
                    return getField().type;
                };

                var isStringValue = function isStringValue() {
                    return [Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_MULTI, Gecko.Field.TYPE_NAME, Gecko.Field.TYPE_DATE, Gecko.Field.TYPE_PREFERRED_LANGUAGE].indexOf(fieldType()) === -1;
                };

                var isDateValue = function isDateValue() {
                    return [Gecko.Field.TYPE_DATE].indexOf(fieldType()) !== -1;
                };

                var isNameValue = function isNameValue() {
                    return [Gecko.Field.TYPE_NAME].indexOf(fieldType()) !== -1;
                };

                var isSingleOptionValue = function isSingleOptionValue() {
                    return [Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_PREFERRED_LANGUAGE].indexOf(fieldType()) !== -1;
                };

                var isMultiOptionValue = function isMultiOptionValue() {
                    return [Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_MULTI].indexOf(fieldType()) !== -1;
                };

                var hasOptionList = function hasOptionList() {
                    return getField().option_id;
                };

                ctrl.fields = [{
                    type: Gecko.Field.TYPE_TITLE,
                    label: 'Prepopulate Form Fields',
                    description: 'Specify default values to be entered into fields within your form. Find out more on <a href="https://academy.geckoengage.com/en/articles/6967274-pre-filled-forms" target="_blank">Gecko Academy</a>.',
                    colClass: 'no-class',
                    hideWhen: function hideWhen() {
                        return ctrl.index;
                    }
                }, {
                    id: 'key',
                    type: Gecko.Field.TYPE_SELECT,
                    optionsKey: 'tag',
                    optionsLabelKey: 'label',
                    options: ctrl.formFields,
                    colClass: 'gecko-form-share-populate-input'
                }, {
                    id: 'value',
                    colClass: 'gecko-form-share-populate-input',
                    hideWhen: function hideWhen() {
                        return !isStringValue();
                    }
                }, {
                    id: 'value',
                    type: Gecko.Field.TYPE_DATE,
                    colClass: 'gecko-form-share-populate-input',
                    hideWhen: function hideWhen() {
                        return !isDateValue();
                    }
                }, {
                    id: 'value',
                    type: Gecko.Field.TYPE_SELECT,
                    colClass: 'gecko-form-share-populate-input',
                    optionsKey: 'value',
                    optionsLabelKey: 'label',
                    hideWhen: function hideWhen() {
                        return !ctrl.pair.key || !isSingleOptionValue();
                    }
                }, {
                    id: 'value',
                    type: Gecko.Field.TYPE_MULTI,
                    colClass: 'gecko-form-share-populate-input',
                    optionsKey: 'value',
                    optionsLabelKey: 'label',
                    hideWhen: function hideWhen() {
                        return !ctrl.pair.key || !isMultiOptionValue();
                    }
                }, {
                    id: 'value_first_name',
                    colClass: 'gecko-form-share-populate-input gecko-form-share-populate-input-name',
                    hideWhen: function hideWhen() {
                        return !ctrl.pair.key || !isNameValue();
                    }
                }, {
                    id: 'value_last_name',
                    colClass: 'gecko-form-share-populate-input gecko-form-share-populate-input-name',
                    hideWhen: function hideWhen() {
                        return !ctrl.pair.key || !isNameValue();
                    }
                }, {
                    type: Gecko.Field.TYPE_BUTTON,
                    colClass: 'gecko-form-share-populate-button',
                    icon: 'fa-trash-alt',
                    btnClass: 'btn-default',
                    action: function action() {
                        if (ctrl.values.length > 1) {
                            // Only remove if there is more than 1 pair
                            ctrl.values.splice(ctrl.index, 1);
                        } else {
                            ctrl.values[ctrl.values.length - 1].key = null;
                            ctrl.values[ctrl.values.length - 1].value = '';
                        }
                    }

                }];

                var setOptions = function setOptions(options) {
                    var singleOptionsField = ctrl.fields.find(function (f) {
                        return f.id === 'value' && f.type === Gecko.Field.TYPE_SELECT;
                    });
                    var multiOptionsField = ctrl.fields.find(function (f) {
                        return f.id === 'value' && f.type === Gecko.Field.TYPE_MULTI;
                    });

                    if (options) {
                        options = $formBuilderService.prepareOptions(getField(), options);
                        singleOptionsField.options = options;
                        multiOptionsField.options = options;
                    } else {
                        singleOptionsField.options = [];
                        multiOptionsField.options = [];
                    }
                };

                var initOptionLists = function initOptionLists() {
                    if (isSingleOptionValue() || isMultiOptionValue()) {
                        if (hasOptionList()) {
                            geckoDataService.fetchFromStore(['options']).then(function (data) {
                                setOptions(data.options.find(function (o) {
                                    return o.id === getField().option_id;
                                }).values || []);
                            });
                        } else {
                            setOptions(getField().values);
                        }
                    }
                };

                initOptionLists();

                var buildJsonValues = function buildJsonValues(values) {
                    return values.filter(function (value) {
                        if (value) return value;
                    }).join('|');
                };

                var buildNameValue = function buildNameValue(index) {
                    // Better handle value if array
                    if (angular.isArray(ctrl.pair.value) && ctrl.pair.value[index]) {
                        return ctrl.pair.value[index];
                    }
                    // Expecting value to be a string
                    if (typeof ctrl.pair.value === 'string') {
                        return ctrl.pair.value.split('|').length && ctrl.pair.value.split('|')[index] ? ctrl.pair.value.split('|')[index] : '';
                    }
                    return '';
                };

                var _tempValues = {
                    key: ctrl.pair.key,
                    value_first_name: buildNameValue(0),
                    value_last_name: buildNameValue(1)
                };

                Object.defineProperties(ctrl.pair, {
                    'key': {
                        get: function get() {
                            return _tempValues['key'];
                        },
                        set: function set(value) {
                            _tempValues['key'] = value;
                            //Reset value on change
                            ctrl.pair.value = '';
                            //Only get options if correct field
                            initOptionLists();
                        }
                    },
                    'value_first_name': {
                        get: function get() {
                            return _tempValues['value_first_name'];
                        },
                        set: function set(value) {
                            _tempValues['value_first_name'] = value;

                            ctrl.pair.value = buildJsonValues([_tempValues['value_first_name'], _tempValues['value_last_name']]);
                        }
                    },
                    'value_last_name': {
                        get: function get() {
                            return _tempValues['value_last_name'];
                        },
                        set: function set(value) {
                            _tempValues['value_last_name'] = value;

                            ctrl.pair.value = buildJsonValues([_tempValues['value_first_name'], _tempValues['value_last_name']]);
                        }
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFormSharePopulateFields', geckoFormSharePopulateFields).directive('geckoFormSharePopulateFieldsPair', geckoFormSharePopulateFieldsPair);
})();