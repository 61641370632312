(function () {
    'use strict';

    function asyncValueDirective(asyncCache) {

        var asyncValueParse = /(\w+)\((.*)\)(?:\s+as\s+(.*))?/;

        return {
            restrict: 'A',

            scope: true,

            compile: function asyncValueCompile(tElement, tAttrs) {

                var match = asyncValueParse.exec(tAttrs.asyncValue);

                var model = match[1];
                var idExp = match[2];
                var alias = match[3] || 'value';

                return function asyncValueLink(scope, iElement, iAttrs) {

                    scope.$watch(idExp, function (idVal) {

                        // Return and set value blank if no id
                        if (!idVal) {
                            scope[alias] = undefined;
                            return;
                        }

                        var getter = new Gecko[model]();

                        if (iAttrs.include) getter.include(iAttrs.include.split(','));

                        if (iAttrs.fields) {
                            var fields = {};
                            fields[model.toLowerCase()] = iAttrs.fields.split(',');
                            getter.rfields(fields);
                        }

                        // Get multiple values if we have an array, needs 'id' to be a valid where.
                        if (idVal instanceof Array) {

                            // Return and set Value blank if array is empty.
                            if (!idVal.length) {
                                scope[alias] = undefined;
                                return;
                            }

                            asyncCache.get(getter.where('id', idVal)).then(function (item) {

                                scope[alias] = item.toArray();
                            });
                        } else {
                            asyncCache.get(getter, idVal).then(function (item) {

                                scope[alias] = item.toObject();
                            });
                        }
                    });
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('asyncValue', asyncValueDirective);
})();