(function () {
    'use strict';

    function geckoFeed($templateRequest, $compile) {
        return {
            restrict: 'E',
            scope: true,

            compile: function compile(tElement) {

                tElement.addClass('gecko-feed card');

                var contents = tElement.contents();

                return function (scope, iElement, iAttrs) {

                    $templateRequest('/components/gecko-feed/gecko-feed.html').then(function (template) {

                        iElement.html(template);

                        if (iAttrs.items) {

                            var match = iAttrs.items.match(/^\s*(\S+)\s+in/);

                            iElement.find('.feed-item-container').attr('ng-repeat', iAttrs.items).attr('ng-class', '{expanded: ' + match[1] + '.feedExpanded}');
                        }

                        iElement.find('.feed-item-container').append(contents);
                        $compile(iElement.contents())(scope);
                    });

                    // Card title
                    scope.title = iAttrs.title || 'Feed';
                    // Pagination
                    if (iAttrs.pagination) scope.pagination = JSON.parse(iAttrs.pagination);
                    // Quick search
                    if (iAttrs.searchableState) scope.searchableState = iAttrs.searchableState;
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFeed', geckoFeed);
})();