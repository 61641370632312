(function () {
    'use strict';

    function geckoStatWidgetLine() {
        return {
            restrict: 'EA',
            template: '\n                <canvas \n                    ng-if="ctrl.show"\n                    class="chart chart-line" \n                    options="ctrl.options" \n                    height="80"     \n                    data="ctrl.widget.data" \n                    labels="ctrl.widget.related_stats[0].labels" \n                    legend="true" \n                    series="ctrl.widget.series">\n                </canvas>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                widget: '='
            },
            controller: function controller($timeout) {
                var ctrl = this;
                ctrl.options = {
                    animation: false,
                    legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].strokeColor%> !important\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>"
                };

                // Tooltips dont display correctly on first load, sigh
                $timeout(function () {
                    return ctrl.show = true;
                }, 300);
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoStatWidgetLine', geckoStatWidgetLine);
})();