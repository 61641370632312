var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function () {
    'use strict';

    function ResponseCtrl($scope, $state, $stateParams, $filter, $geckoModal, asyncOptions, response, history, OptionsPresets, handleMassAction, $formBuilderService, geckoEventPickerService, devices, forms, integrations, users) {
        var ctrl = this;
        ctrl.response = response;
        ctrl.history = history.data;
        ctrl.devices = devices;
        ctrl.forms = forms;
        ctrl.integrations = $filter('orderBy')(integrations, 'type');
        ctrl.users = users;
        //ctrl.values       = values;
        ctrl.pagination = {
            current_page: history.current_page,
            total_pages: history.last_page,
            total_items: history.total,
            per_page: history.per_page,
            last_page: history.last_page,
            hideLimit: true
        };

        ctrl.setDisplayValues = function () {
            // Add device refs to response obj
            if (ctrl.response.device_id) ctrl.response.related_device = ctrl.devices.find(function (u) {
                return u.id === ctrl.response.device_id;
            }) || {};
            // Add form red to response obj
            if (ctrl.response.form_id) ctrl.response.related_form = ctrl.forms.find(function (f) {
                return f.id === ctrl.response.form_id;
            }) || {};
            // Add user refs to response obj
            if (ctrl.response.user_id) ctrl.response.related_user = ctrl.users.find(function (u) {
                return u.id === ctrl.response.user_id;
            }) || {};
            if (ctrl.response.assigned_id) ctrl.response.related_assigned = ctrl.users.find(function (u) {
                return u.id === ctrl.response.assigned_id;
            }) || {};
        };

        ctrl.setDisplayValues();

        // Add further includes to response instance (for saving values)
        ctrl.response.includes = ctrl.response.includes.concat(['assigned', 'user', 'form', 'device']);

        ctrl.prepareValueFields = function () {
            // Filter response fields
            ctrl.checkFields = function () {
                return function (field) {
                    //Display date values in utc
                    if (field.type === Gecko.Field.TYPE_DATE) field.utc = true;
                    if (field && [Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_SECTION].indexOf(field.type) == -1) return true;
                };
            };
            ctrl.fields = $filter('filter')(ctrl.response.related_form.related_fields, ctrl.checkFields());
            ctrl.fields = ctrl.fields.map(function (field) {
                field.changeResponseValue = true;
                return field;
            });

            // Check for values (with field now removed)
            var fieldIds = ctrl.fields.map(function (field) {
                return field.id;
            });
            ctrl.valueFields = ctrl.values.filter(function (value) {
                return value.related_field;
            }).map(function (value) {
                return value.related_field;
            }).filter(function (field) {
                return fieldIds.indexOf(field.id) === -1;
            }).map(function (field) {
                field.label = field.label + ' (Archived)';
                // Disable missing field
                field.is_uneditable = true;
                return field;
            });
            // Merge missing fields with fields array
            if (ctrl.valueFields.length) ctrl.fields = ctrl.fields.concat(ctrl.valueFields);

            var flattenChildren = function flattenChildren(objects) {
                return objects.reduce(function (objects, object) {
                    if (!object.children) {
                        return objects.concat(object);
                    }
                    return objects.concat(object, flattenChildren(object.children));
                }, []);
            };
            ctrl.eventFields = ctrl.fields.filter(function (field) {
                return field.type === 'event';
            });
            var eventFieldIds = ctrl.eventFields.map(function (field) {
                return field.id;
            });
            // Remove these event fields from the normal fields array
            ctrl.fields = ctrl.fields.filter(function (field) {
                return eventFieldIds.indexOf(field.id) === -1;
            });
            ctrl.eventValues = ctrl.values.filter(function (value) {
                return value.related_field;
            }).reduce(function (values, value) {
                if (eventFieldIds.indexOf(value.related_field.id) !== -1) {
                    values[value.related_field.id] = value;
                }
                return values;
            }, {});
            ctrl.eventIds = ctrl.values.filter(function (value) {
                return value.related_field;
            }).reduce(function (ids, value) {
                if (eventFieldIds.indexOf(value.related_field.id) !== -1) {
                    return ids.concat(value.value && value.value.event_ids ? Object.keys(value.value.event_ids) : []);
                }
                return ids;
            }, []);
            ctrl.events = [];

            // TEMP (this is to stop querying with MASSIVE id arrays)
            if (Gecko.able(Gecko.ABILITY_EVENTS_VIEW) && Array.isArray(ctrl.eventIds) && ctrl.eventIds.length < 10) {
                new Gecko.Event().rfields({ event: ['title', 'type', 'parent_id'] }) // To fix include parent.parent issue
                .perPage(10000).get().then(function (events) {
                    ctrl.events = events.toArray();
                    $scope.$digest();
                }).catch(GeckoUI.messenger.error);
            }

            var getEvent = function getEvent(event_id, events) {
                if (!events) {
                    return new Gecko.Event();
                }
                return events.find(function (event) {
                    return event.id == event_id;
                }) || new Gecko.Event();
            };
            ctrl.buildEventStructure = function (event_id, events) {
                var parent,
                    session,
                    event = getEvent(event_id, events);
                if (event.type === Gecko.Event.TYPE_EVENT || event.type === Gecko.Event.TYPE_EVENT_LEGACY) {
                    return [event, {}, {}];
                }
                if (event.type === Gecko.Event.TYPE_SESSION || event.type === Gecko.Event.TYPE_SESSION_LEGACY) {
                    var eventLevel = ctrl.events.find(function (e) {
                        return e.id === event.parent_id;
                    }) || {};
                    return [eventLevel, event, {}];
                }
                if (event.type === Gecko.Event.TYPE_SESSION_TIME) {
                    var sessionLevel = ctrl.events.find(function (e) {
                        return e.id === event.parent_id;
                    }) || {};
                    var eventLevel = ctrl.events.find(function (e) {
                        return e.id === sessionLevel.parent_id;
                    }) || {};
                    return [eventLevel, sessionLevel, event];
                }
                return [{}, {}, {}];
            };

            $scope.$digest();
        };

        ctrl.prepareResponseSwitcher = function (responses) {
            var responseSwitcher = {
                title: 'Switch Response',
                iconRight: 'fa-angle-down',
                items: [],
                hideWhen: function hideWhen() {
                    return responseSwitcher.items.length < 1;
                }
            };

            responses.map(function (r) {
                r.form = ctrl.forms.find(function (f) {
                    return f.id === r.form_id;
                });
                return r;
            }).forEach(function (r) {
                if (ctrl.response.id === r.id) return; // Don't put this response in list
                responseSwitcher.items.push({
                    title: (r.form.internal_name || r.form.name) + ' — ' + $filter('formatDate')(r.created_at, Gecko.dateFormat.shortTime),
                    action: function action() {
                        return $state.go($state.$current.name, { response_id: r.id });
                    }
                });
            });

            ctrl.optionsBtn.push(responseSwitcher);
            $scope.$digest();
        };

        // Lazy load response data
        var query = {};
        query.form = new Gecko.Form().include('fields:1000').path(ctrl.response.form_id);
        query.values = new Gecko.Value().include('media', 'field').perPage('all').where('response_id', $stateParams.response_id).path('current');
        if ($stateParams.contact_id) query.otherResponses = new Gecko.Response().where('contact_id', $stateParams.contact_id).rfields({ response: ['created_at', 'form_id'] });

        new Gecko.multi(query).then(function (data) {
            ctrl.response.related_form = data.form;
            ctrl.values = data.values.toInstances();
            ctrl.otherResponses = data.otherResponses ? data.otherResponses.toArray() : [];
            ctrl.prepareValueFields();
            // Build response switcher (if contact has other responses)
            if (ctrl.otherResponses.length) ctrl.prepareResponseSwitcher(ctrl.otherResponses);
            $scope.$digest();
        });

        // ctrl.removeEvent = function(event_id, value) {
        //     var index = ctrl.eventIds.indexOf(event_id);
        //     GeckoUI.removeItem(ctrl.eventIds, index);
        //     delete value.value.event_ids[event_id];

        //     value.replace(value.value)
        //         .then(function() {
        //             // We are casting this empty array to an empty object because of PHP's confusion between Objects & Arrays
        //             if (Array.isArray(value.value.event_ids) && value.value.event_ids.length === 0) {
        //                 value.value.event_ids = {};
        //             }
        //             GeckoUI.messenger.success('Event registration removed');
        //         })
        //         .catch(function(err) {
        //             GeckoUI.messenger.error(err);
        //             ctrl.eventIds.push(event_id);
        //             value.value.event_ids[event_id] = null;
        //         });
        // }

        ctrl.openNewEventModal = function (field) {

            var value = ctrl.eventValues[field.id];

            $geckoModal.eventAddToModal().result.then(function (id) {
                ctrl.eventIds.push(id);

                if (value) {
                    if (!value.value) value.value = { event_ids: {}, default_status: 10 };
                    value.value.event_ids[id] = null;

                    value.replace(value.value).then(function () {
                        new Gecko.Event().rfields({ event: ['title', 'parent', 'type'] }).get(id).then(function (event) {
                            ctrl.events = angular.copy(ctrl.events.concat(event.toObject()));
                            ctrl.eventValues[field.id] = value;
                        }).catch(function () {
                            GeckoUI.messenger.error("Failed to display related Event");
                        });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                        delete value.value.event_ids[id];
                        var index = ctrl.eventIds.indexOf(id);
                        GeckoUI.removeItem(ctrl.eventIds, index);
                    });
                } else {
                    value = new Gecko.Value();
                    value.response_id = ctrl.response.id;
                    value.field_id = field.id;
                    value.data = { event_ids: _defineProperty({}, id, null), default_status: 10 };
                    Promise.all([value.save(), new Gecko.Event().rfields({ event: ['title', 'parent', 'type'] }).get(id)]).then(function (_ref) {
                        var _ref2 = _slicedToArray(_ref, 2),
                            _value = _ref2[0],
                            event = _ref2[1];

                        ctrl.events = angular.copy(ctrl.events.concat(event.toObject()));
                        ctrl.eventValues[field.id] = _value;
                    }).catch(function () {
                        GeckoUI.messenger.error("Failed to save the Event registration");
                    });
                }
            });
        };

        // Get form link (new or old form builder etc.)
        var getFormLink = function getFormLink() {
            return (ctrl.response.related_form && ctrl.response.related_form.module === Gecko.Form.MODULE_FORM ? 'form.designer' : 'script.designer') + '({form_id: ctrl.value})';
        };

        // System Info
        ctrl.infoFields = [{
            label: 'Received Date',
            key: 'created_at',
            type: Gecko.Field.TYPE_DATETIME,
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            noEdit: true
        }, {
            label: ctrl.response.related_form && ctrl.response.related_form.module == Gecko.Form.MODULE_CALL ? 'Script' : 'Form',
            key: 'form_id',
            display: ['related_form.internal_name', 'related_form.name'],
            options: ctrl.forms,
            type: Gecko.Field.TYPE_SELECT,
            noEdit: true,
            sref: getFormLink()
        }, geckoEventPickerService.prepareField({
            label: 'Event captured at',
            key: 'event_id',
            display: 'related_event.title',
            optionsInit: OptionsPresets.events.init,
            options: [[], []],
            optionsKey: 'id',
            optionsLabelKey: 'title',
            getOptions: OptionsPresets.events.get,
            placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
            type: Gecko.Field.TYPE_TIERED_SELECT,
            saveMessage: 'Response added to event.',
            sref: function sref() {
                if (!ctrl.response.related_event) {
                    return false;
                }
                if (ctrl.response.related_event.related_parent) {
                    return ctrl.response.related_event.id ? 'event.session({event_id: ' + ctrl.response.related_event.related_parent.id + ', session_id: ' + ctrl.response.related_event.id + '})' : false;
                } else {
                    return ctrl.response.related_event.id ? 'event.overview({event_id: ' + ctrl.response.related_event.id + '})' : false;
                }
            }
        }), {
            label: 'Assigned to',
            key: 'assigned_id',
            display: 'related_assigned.full_name',
            options: ctrl.users,
            optionsKey: 'id',
            optionsLabelKey: 'full_name',
            type: Gecko.Field.TYPE_SELECT,
            saveMessage: 'Assigned user has been updated',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_RESPONSES_ASSIGN_TO_COLLEAGUES);
            }
        }, {
            label: 'Captured by',
            key: 'user_id',
            display: 'related_user.full_name',
            options: ctrl.users,
            optionsKey: 'id',
            optionsLabelKey: 'full_name',
            type: Gecko.Field.TYPE_SELECT,
            saveMessage: 'Captured by user has been updated'
        }, {
            label: 'Device',
            key: 'device_id',
            display: ['related_device.label', 'related_device.udid'],
            noEdit: true
        }];

        // Prepare synchronisations data fields and values
        GeckoUI.prepareSyncsData(ctrl.response.related_synchronisations, ctrl.infoFields, ctrl.response);

        // This is plebby (but exsists, sigh)
        angular.forEach(ctrl.infoFields, function (f) {
            f.obj = ctrl.response;
        });

        ctrl.optionsBtn = [{
            iconRight: 'fa-cogs',
            btnTooltip: 'Actions',
            items: [{
                title: 'Create new contact',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_RESPONSES_UPDATE);
                },
                action: function action() {
                    ctrl.response.unlink().then(function (response) {
                        GeckoUI.messenger.success('New contact created');
                        $state.go('contact.response', {
                            contact_id: response.contact_id,
                            response_id: response.id
                        });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                    });
                }
            }, {
                title: $stateParams.contact_id ? 'Manage response label(s)' : 'Manage label(s)',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_RESPONSES_UPDATE);
                },
                action: function action() {
                    return ctrl.headerLabelsAction();
                }
            }, {
                title: $stateParams.contact_id ? 'Add a note to response' : 'Add a note',
                action: function action() {
                    $geckoModal.noteAddModal(ctrl.response).result.then(function (note) {
                        ctrl.response.related_notes.push(note);
                        GeckoUI.messenger.success('Note added to response');
                    });
                }
            }, {
                title: 'Remove response',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_RESPONSES_DELETE);
                },
                action: function action() {
                    GeckoUI.dialog.confirm('Are you sure you want to remove this response?', function (value) {
                        if (value === true) {
                            ctrl.response.remove().then(function () {
                                if (responseSwitcher.items.length < 1) {
                                    $state.go('responses');
                                } else {
                                    $state.go('contact.responses', $stateParams, { reload: true });
                                }
                                GeckoUI.messenger.success('Response removed');
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors[0]);
                            });
                        }
                    });
                }
            }, {
                title: 'Integrations',
                heading: true,
                hideWhen: function hideWhen() {
                    return !ctrl.integrations.length;
                }
            }]
        }];

        // Prepare integration options
        if (ctrl.integrations.length) {
            ctrl.integrationOptions = angular.copy(ctrl.integrations);

            angular.forEach(ctrl.integrationOptions, function (integration) {
                integration.title = 'Sync to ' + integration.title + ' (' + $filter('formatDate')(integration.created_at, 'MMM Do YYYY') + ')';
                integration.action = function (data, index, integration) {
                    ctrl.response.sync(integration.id).then(handleMassAction).catch(GeckoUI.messenger.error);
                };
                ctrl.optionsBtn[0].items.push(integration);
            });
        }

        // Label action
        ctrl.headerLabelsAction = function () {
            return $geckoModal.labelModal(ctrl.response, 'Response').result.then(function () {
                GeckoUI.messenger.success('Response labels updated.');
            });
        };

        // Notes table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-3'
        }, {
            title: 'Note',
            key: 'note',
            colClass: 'col-xs-6'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Created by',
            key: 'related_user.full_name',
            colClass: 'col-xs-3'
        }];

        // Collapse/expand field sections
        ctrl.showSystemInfo = true;
        ctrl.showCapturedInfo = true;
        ctrl.showUtmInfo = true;
        ctrl.showResponseActivity = true;
        ctrl.showResponseNotes = true;
        ctrl.showResponseLocation = true;
        ctrl.toggleCollapse = function (section) {
            ctrl[section] = !ctrl[section];
        };

        // Breadcrumbs (if no contact)
        if (!ctrl.response.contact_id) {
            ctrl.breadcrumbs = [{
                label: 'Responses',
                click: function click() {
                    $state.go('responses');
                }
            }, {
                label: 'Response',
                active: true
            }];
        }

        // UTM Info
        ctrl.hasUtmData = function () {
            return ctrl.response.utm_campaign || ctrl.response.utm_medium || ctrl.response.utm_source || ctrl.response.utm_content || ctrl.response.utm_term;
        };

        if (ctrl.hasUtmData()) {
            ctrl.utmFields = [{
                label: 'UTM Campaign',
                key: 'utm_campaign',
                type: Gecko.Field.TYPE_TEXT,
                noEdit: true
            }, {
                label: 'UTM Medium',
                key: 'utm_medium',
                noEdit: true
            }, {
                label: 'UTM Source',
                key: 'utm_source',
                noEdit: true
            }, {
                label: 'UTM Content',
                key: 'utm_content',
                noEdit: true
            }, {
                label: 'UTM Term',
                key: 'utm_term',
                noEdit: true
            }];

            // This is plebby (but exsists, sigh)
            angular.forEach(ctrl.utmFields, function (f) {
                f.obj = ctrl.response;
            });
        }

        ctrl.noEventsMessageDisplay = function (field) {
            return Gecko.able(Gecko.ABILITY_EVENTS_VIEW) && !ctrl.eventValues[field.id] || ctrl.eventValues[field.id] && !Object.keys(ctrl.eventValues[field.id].value.event_ids).length;
        };
    }

    angular.module('GeckoEngage').controller('ResponseCtrl', ResponseCtrl);
})();