(function () {
    'use strict';

    function geckoFormPaging($parse) {
        return {

            restrict: 'E',
            templateUrl: '/components/gecko-form/gecko-form-paging.html',

            require: ['^?geckoForm', 'geckoFormPaging'],

            controller: function geckoFormPagingCtrl() {},
            controllerAs: 'pagingCtrl',

            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var pagingCtrl = ctrls[1];

                pagingCtrl.current = 0;
                pagingCtrl.last = $parse(iAttrs.pages)(scope).length - 1;

                pagingCtrl.changePage = function (amount) {
                    pagingCtrl.current += amount;

                    // Clamp current page
                    if (pagingCtrl.current < 0) {
                        pagingCtrl.current = 0;
                    }
                    if (pagingCtrl.current > pagingCtrl.last) {
                        pagingCtrl.current = pagingCtrl.last;
                    }
                };
            }

        };
    }

    angular.module('GeckoEngage').directive('geckoFormPaging', geckoFormPaging);
})();