(function () {
    'use strict';

    function ContactsRoute($stateProvider) {
        $stateProvider.state('contact', {
            url: '/contact/{contact_id}?{subscriber_id}',
            templateUrl: '/modules/contact/views/view.fdeae474.html',
            controller: 'ContactCtrl',
            controllerAs: 'ctrl',
            page_title: 'View Contact',
            requires: [Gecko.ABILITY_CONTACTS_VIEW],
            resolve: {
                clearCallQueue: function clearCallQueue($stateParams, $geckoVoip) {
                    if ($stateParams.subscriber_id) {
                        return $geckoVoip.isIncoming = false;
                    }
                    return;
                },
                contactIncludes: function contactIncludes() {
                    return ['numbers', 'labels', 'synchronisations.integration'];
                },
                contactRfields: function contactRfields() {
                    return {
                        'label': ['name', 'color'],
                        'number': ['cleaned', 'country_code', 'do_not_call', 'do_not_sms', 'original', 'type', 'valid'],
                        'synchronisation': ['third_party_id', 'related_type'],
                        'integration': ['title_with_date', 'type']
                    };
                },
                subscriberIncludes: function subscriberIncludes() {
                    return ['all_campaigns', 'assigned', 'contact', 'calls', 'calls.number', 'calls.user', 'campaign.outcomes', 'campaign.campaign_fields:all', 'campaign.campaign_fields.field', 'campaign.campaign_fields.field.option', 'campaign.users:all'];
                },
                subscriberRfields: function subscriberRfields() {
                    return {
                        'number': ['cleaned', 'country_code', 'do_not_call', 'do_not_sms', 'original', 'type', 'valid'],
                        'outcome': ['name', 'action', 'group', 'call_type', 'require_note']
                    };
                },
                data: function data($stateParams, contactIncludes, contactRfields, subscriberIncludes, subscriberRfields) {
                    var _query = {};

                    // Contact
                    _query.contact = new Gecko.Contact().include(contactIncludes).rfields(contactRfields).path($stateParams.contact_id);

                    // Subscriber (if there is a subscriber id)
                    if ($stateParams.subscriber_id) {
                        _query.subscriber = new Gecko.Subscriber().include(subscriberIncludes).rfields(subscriberRfields).path($stateParams.subscriber_id);
                    }

                    // Outcomes (only if there is not a subscriber id)
                    if (!$stateParams.subscriber_id) {
                        _query.outcomes = new Gecko.Outcome().perPage('all');
                    }

                    return Gecko.multi(_query);
                },
                contact: function contact(data) {
                    return data.contact;
                },
                subscriber: function subscriber(data) {
                    return data.subscriber ? data.subscriber : false;
                },
                campaign: function campaign(subscriber) {
                    return subscriber && subscriber.related_campaign ? subscriber.related_campaign : false;
                },
                outcomes: function outcomes(data, $filter, campaign) {
                    // Get outcomes from subscriber's campaign
                    if (campaign) return campaign.related_outcomes;
                    // Filter out the outcomes we dont want
                    if (data.outcomes) return $filter('filter')(data.outcomes.toArray(), { action: '!' + Gecko.Contact.ACTION_RESCHEDULE, group: '!' + Gecko.Outcome.GROUP_SMS });
                    // Fallback empty array
                    return [];
                },
                call: function call(clearCallQueue, campaign, subscriber, $geckoCall) {
                    if (Gecko.able(Gecko.ABILITY_CALLS_CREATE) && campaign.status == Gecko.Campaign.ACTIVE) {
                        if (campaign && campaign.module == Gecko.Campaign.MODULE_CALL) {
                            if ($geckoCall.call && $geckoCall.call.subscriber_id == subscriber.id) {
                                return $geckoCall.call;
                            } else if (subscriber.status == Gecko.Subscriber.ASSIGNED && subscriber.user_id == Gecko.user.id) {
                                return $geckoCall.initSubscriber(subscriber);
                            } else if ([Gecko.Subscriber.AVAILABLE].indexOf(subscriber.status) !== -1) {
                                return $geckoCall.initSubscriber(subscriber);
                            } else if ([Gecko.Subscriber.SCHEDULED].indexOf(subscriber.status) !== -1) {
                                if (subscriber.can_call) return $geckoCall.initSubscriber(subscriber);
                                return false;
                            }
                            return false;
                        }
                    }
                    return false;
                }
            }
        }).state('contact.overview', {
            url: '/overview?{page}&{hide_system_alerts}',
            templateUrl: '/modules/contact/views/overview.2fc37072.html',
            controller: 'ContactOverviewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Overview',
            requires: [Gecko.ABILITY_CONTACTS_VIEW],
            editRequires: [Gecko.ABILITY_CONTACTS_UPDATE],
            resolve: {
                data: function data(geckoDataService) {
                    return geckoDataService.fetch(['fields', 'integrations']);
                },
                values: function values($stateParams) {
                    return new Gecko.Contact().include(['current_values:1000', 'current_values.field']).rfields({ contact: ['id'] }).get($stateParams.contact_id).then(function (data) {
                        return data.related_current_values;
                    });
                },
                fields: function fields(data) {
                    return data.fields;
                },
                integrations: function integrations(data) {
                    return data.integrations;
                },
                history: function history($stateParams) {
                    var params = {
                        per_page: Gecko.DEFAULT_PER_PAGE,
                        page: $stateParams.page || 1,
                        order_by: 'created_at|DESC'
                    };

                    if ($stateParams.hide_system_alerts) {
                        params.hide_system_alerts = $stateParams.hide_system_alerts;
                    } else {
                        params.hide_system_alerts = Gecko.user.config.hide_system_alerts ? true : false;
                    }

                    return new Gecko.MixedObjects().call('/contacts/' + $stateParams.contact_id + '/history', 'GET', params, true);
                }
            }
        }).state('contact.subscriber', {
            url: '/campaign?{page}&{activity}',
            templateUrl: '/modules/contact/views/subscriber.14239872.html',
            controller: 'ContactSubscriberCtrl',
            controllerAs: 'ctrl',
            page_title: 'Subscriber',
            requires: [Gecko.ABILITY_CONTACTS_VIEW],
            resolve: {
                subscribersRfields: function subscribersRfields() {
                    return {
                        'campaign': ['title'],
                        'subscriber': ['campaign_id']
                    };
                },
                subscribers: function subscribers($stateParams, contact, subscribersRfields) {
                    return new Gecko.Subscriber().include('campaign').rfields(subscribersRfields).where('contact_id', contact.id).where('module', Gecko.Campaign.MODULE_CALL).perPage('all').get();
                },
                campaign_fields: function campaign_fields(campaign) {
                    return campaign.related_campaign_fields;
                },
                history: function history($stateParams) {
                    if ($stateParams.activity === 'all') {
                        var params = {
                            per_page: Gecko.DEFAULT_PER_PAGE,
                            page: $stateParams.page || 1,
                            order_by: 'created_at|DESC',
                            hide_system_alerts: 1
                        };
                        return new Gecko.MixedObjects().call('/contacts/' + $stateParams.contact_id + '/history', 'GET', params, true);
                    } else {
                        var pagination = {
                            per_page: Gecko.DEFAULT_PER_PAGE,
                            page: $stateParams.page || 1
                        };
                        return new Gecko.MixedObjects().call('/subscribers/' + $stateParams.subscriber_id + '/history', 'GET', pagination, true);
                    }
                },
                values: function values($stateParams) {
                    return new Gecko.Contact().include(['current_values:1000', 'current_values.field']).rfields({ contact: ['id'] }).get($stateParams.contact_id).then(function (data) {
                        return data.related_current_values;
                    });
                }
            }
        }).state('contact.organisations', {
            url: '/organisations?{page}&{order}&{sort}',
            templateUrl: '/modules/contact/views/organisations.d5e957e1.html',
            controller: 'ContactOrganisationsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisations',
            requires: [Gecko.ABILITY_CONTACTS_VIEW, Gecko.ABILITY_ORGANISATIONS_VIEW],
            resolve: {
                data: function data(geckoDataService) {
                    return geckoDataService.fetch(['organisation_fields', 'organisation_types']);
                },
                fields: function fields(data) {
                    return data.organisation_fields;
                },
                organisationTypes: function organisationTypes(data) {
                    return data.organisation_types;
                },
                enrolments: function enrolments($stateParams) {
                    return new Gecko.Contact().call('/contacts/' + $stateParams.contact_id, 'GET', {
                        contact_rfields: 'id',
                        enrolment: 'id',
                        value_rfields: 'id,safe,text,value,varchar,field_id',
                        include: 'enrolments.organisation,enrolments.organisation.organisation_type,enrolments.organisation.current_values'
                    }, true).then(function (data) {
                        return data.contact.enrolments;
                    });
                }
            }
        }).state('contact.notes', {
            url: '/notes?{page}&{order}&{sort}&{exclude}',
            templateUrl: '/modules/contact/views/notes.5c0fceaf.html',
            controller: 'ContactNotesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Notes',
            requires: [Gecko.ABILITY_CONTACTS_VIEW],
            resolve: {
                notesRfields: function notesRfields() {
                    return {
                        // 'note': ['created_at', 'note', 'contact_id', 'call_id', 'response_id'],
                        'user': ['full_name']
                    };
                },
                notes: function notes($stateParams, notesRfields) {
                    var note = new Gecko.Note().include('user').rfields(notesRfields).where('contact_id', $stateParams.contact_id).where('show_responses', $stateParams.includeResponses).where('show_calls', $stateParams.includeCalls);

                    var excludes = GeckoUI.wrapArray($stateParams.exclude);

                    if (excludes.indexOf('responses') === -1) {
                        note.include('responses');
                    }

                    if (excludes.indexOf('calls') === -1) {
                        note.include('calls');
                    }

                    return note.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                }
            }
        }).state('contact.responses', {
            url: '/responses?{page}',
            templateUrl: '/modules/contact/views/responses.a684f5b1.html',
            controller: 'ContactResponsesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Responses',
            requires: [Gecko.ABILITY_RESPONSES_VIEW],
            resolve: {
                responsesRfields: function responsesRfields() {
                    return {
                        'response': ['created_at', 'contact_id'],
                        'form': ['name', 'internal_name'],
                        'event': ['title'],
                        'user': ['full_name'],
                        'assigned': ['full_name']
                    };
                },
                responses: function responses($stateParams, responsesRfields) {
                    return new Gecko.Response().include('form', 'event', 'user', 'assigned').rfields(responsesRfields).where('contact_id', $stateParams.contact_id).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                }
            }
        })

        // State contact.response in responses/route.js file.
        .state('contact.calls', {
            url: '/calls?{page}&{order}&{sort}',
            templateUrl: '/modules/contact/views/calls.48063fb8.html',
            controller: 'ContactCallsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Calls',
            requires: [Gecko.ABILITY_CONTACTS_VIEW, Gecko.ABILITY_CALLS_VIEW],
            resolve: {
                callsRfields: function callsRfields() {
                    return {
                        'call': ['type', 'started_at', 'call_time', 'recording_url_mp3', 'outcome_id', 'user_id', 'campaign_id'],
                        'campaign': ['title'],
                        'user': ['full_name'],
                        'outcome': ['name', 'type']
                    };
                },
                calls: function calls($stateParams, callsRfields) {
                    var calls = new Gecko.Call().include('contact', 'user', 'outcome', 'campaign', 'all_campaigns').rfields(callsRfields).where('call_contact_id', $stateParams.contact_id).where('call_status', Gecko.Call.COMPLETE);
                    // Only show outbound if inbound call package is disabled
                    if (!Gecko.User.hasInbound()) calls.where('type', Gecko.Call.TYPE_OUTBOUND);
                    // Ordering
                    calls.orderBy($stateParams.order ? $stateParams.order : 'created_at', $stateParams.sort ? $stateParams.sort : 'desc');
                    return calls.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                }
            }
        }).state('contact.messages', {
            url: '/messages?{page}&{order}&{sort}',
            templateUrl: '/modules/contact/views/messages.3fe06559.html',
            controller: 'ContactMessagesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Messages',
            requires: [Gecko.ABILITY_CONTACTS_VIEW, Gecko.ABILITY_MESSAGES_VIEW],
            resolve: {
                messagesRfields: function messagesRfields() {
                    return {
                        'message': ['direction', 'created_at', 'recipients', 'type', 'status', 'subject', 'delivered', 'clicked', 'opened', 'failed', 'body', 'template_id', 'sender_id', 'view_url'],
                        'messageevent': Gecko.MessageEvent.prototype.model_fields,
                        'sender': ['name'],
                        'user': ['full_name'],
                        'template': ['name', 'parse_errors']
                    };
                },
                messages: function messages($stateParams, messagesRfields) {
                    var messages = new Gecko.Message().include('sender', 'user', 'message_events', 'template').rfields(messagesRfields).where('contact_id', $stateParams.contact_id).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1);
                    if ($stateParams.order) messages.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    return messages.get();
                }
            }
        }).state('contact.campaigns', {
            url: '/campaigns?{page}&{order}&{sort}',
            templateUrl: '/modules/contact/views/campaigns.08efe43e.html',
            controller: 'ContactCampaignsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Campaigns',
            requires: [Gecko.ABILITY_CONTACTS_VIEW, Gecko.ABILITY_CALL_CAMPAIGNS_VIEW],
            resolve: {
                subscribersRfeilds: function subscribersRfeilds() {
                    return {
                        'subscriber': ['created_at', 'contact_id', 'status'],
                        'campaign': ['title', 'module']
                    };
                },
                subscribers: function subscribers($stateParams, contact, subscribersRfeilds) {
                    return new Gecko.Subscriber().perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).include('campaign').rfields(subscribersRfeilds).where('contact_id', contact.id).where('module', Gecko.Campaign.MODULE_CALL).orderBy($stateParams.order ? $stateParams.order : 'created_at', $stateParams.sort ? $stateParams.sort : 'desc').get();
                },
                module: function module() {
                    return Gecko.Campaign.MODULE_CALL;
                }
            }
        }).state('contact.broadcasts', {
            url: '/broadcasts?{page}&{order}&{sort}',
            templateUrl: '/modules/contact/views/campaigns.08efe43e.html',
            controller: 'ContactCampaignsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Broadcasts',
            requires: [Gecko.ABILITY_CONTACTS_VIEW, Gecko.ABILITY_BROADCASTS_VIEW],
            resolve: {
                subscribersRfeilds: function subscribersRfeilds() {
                    return {
                        'subscriber': ['created_at', 'contact_id', 'status'],
                        'campaign': ['title', 'module']
                    };
                },
                subscribers: function subscribers($stateParams, contact, subscribersRfeilds) {
                    return new Gecko.Subscriber().perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).include('campaign').rfields(subscribersRfeilds).where('contact_id', contact.id).where('module', [Gecko.Campaign.MODULE_EMAIL, Gecko.Campaign.MODULE_SMS]).orderBy($stateParams.order ? $stateParams.order : 'created_at', $stateParams.sort ? $stateParams.sort : 'asc').get();
                },
                module: function module() {
                    return Gecko.Campaign.MODULE_EMAIL;
                }
            }
        }).state('contact.events', {
            url: '/events?{page}&{order}&{sort}&{sessions}',
            templateUrl: '/modules/contact/views/events.352e8764.html',
            controller: 'ContactEventsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Events',
            requires: [Gecko.ABILITY_CONTACTS_VIEW, Gecko.ABILITY_EVENTS_VIEW],
            resolve: {
                attendancesRfields: function attendancesRfields() {
                    return {
                        'attendance': ['status', 'created_at', 'contact_id', 'event_id', 'rsvp'],
                        'event': ['title', 'nice_title', 'parent_id', 'parent', 'type']
                    };
                },
                attendances: function attendances(contact, $stateParams, attendancesRfields) {
                    var attendances = new Gecko.Attendance().include('event').rfields(attendancesRfields);

                    attendances.where('contact_id', contact.id);

                    return attendances.orderBy('event.start_datetime', 'asc').perPage(1000).page(1).get();
                }
            }
        }).state('contact.attachments', {
            url: '/attachments?{page}&{order}&{sort}',
            templateUrl: '/modules/attachments/attachments.3e44d212.html',
            controller: 'AttachmentsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Contact Attachments',
            requires: [Gecko.ABILITY_CONTACTS_VIEW, Gecko.ABILITY_ATTACHMENTS_VIEW],
            editRequires: [Gecko.ABILITY_CONTACTS_UPDATE],
            resolve: {
                files: function files($stateParams) {
                    return new Gecko.File().include('user').page($stateParams.page).perPage(GeckoUI.getPageLimit(this.self.name)).orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc').where('contact_id', $stateParams.contact_id).get();
                },
                entityData: function entityData($stateParams) {
                    return {
                        type: Gecko.File.MODEL_CONTACT,
                        param: 'contact_id',
                        value: $stateParams.contact_id
                    };
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(ContactsRoute);
})();