(function () {
    'use strict';

    function Error404Ctrl($stateParams) {
        var ctrl = this;

        ctrl.url = $stateParams.url;
    }

    angular.module('GeckoEngage').controller('404Ctrl', Error404Ctrl);
})();