(function () {

    function UserModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('userModal', function (_config) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/user/user-modal.html',
                controller: 'UserModalCtrl',
                resolve: {
                    config: function config() {
                        return _config;
                    }
                }
            };
        });
    }

    function UserModalCtrl($scope, $modalInstance, asyncOptions, config) {
        var ctrl = this;
        ctrl.values = {};
        ctrl.modalTitle = 'Response ' + (config.title || "Settings");

        // Field list
        ctrl.fields = [{
            id: 'id',
            label: 'User',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'none',
            options: [],
            getOptions: asyncOptions.create(new Gecko.User().orderBy('full_name').rfields({ user: ['full_name'] })),
            optionsLabelKey: 'full_name',
            description: config.description || ''
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Save',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            action: function action() {
                if (ctrl.values.id) {
                    $modalInstance.close(ctrl.values.id);
                } else {
                    $modalInstance.dismiss();
                }
            }
        }];
    }

    angular.module('GeckoEngage').config(UserModals).controller('UserModalCtrl', UserModalCtrl);
})();