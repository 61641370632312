(function () {
    'use strict';

    function IntegrationEventsCtrl($scope, $state, $stateParams, integration, integration_events, events, geckoStore, $geckoSocket, Gecko, geckoDates) {
        $scope.tabsCtrl = false;
        var ctrl = this;

        ctrl.getEventIntegrationId = function (event) {
            if (event.radius_id) {
                return event.radius_id;
            }
            if (event.connect_id) {
                return event.connect_id;
            }
            if (event.dynamics_id) {
                return event.dynamics_id;
            }
            if (event.external_id) {
                return event.external_id;
            }
            return null;
        };

        ctrl.isEventIgnoreStored = function (event) {
            return !!(geckoStore.get('gecko_event_sync_ignore') && geckoStore.get('gecko_event_sync_ignore').indexOf(ctrl.getEventIntegrationId(event)) !== -1);
        };

        var mapEventToObject = function mapEventToObject(obj, event) {
            obj[ctrl.getEventIntegrationId(event)] = event.id || 'new';
            return obj;
        };

        var eventInstances = events.toInstances();
        var isEventIgnored = function isEventIgnored(event) {
            return event.ignore;
        }; // Integration events
        var isEventToBeSynced = function isEventToBeSynced(event) {
            return !event.ignore && !event.synced;
        }; // Integration events
        var isEventUnsynced = function isEventUnsynced(event) {
            return !ctrl.getEventIntegrationId(event);
        }; // Gecko events
        var getEventById = function getEventById(id) {
            return eventInstances.find(function (event) {
                return event.id === id;
            });
        }; // Gecko events
        var isEvenHour = function isEvenHour(momentDate) {
            return momentDate.hour() % 2 === 0;
        };

        var getNextSyncDate = function getNextSyncDate() {
            var currentTime = moment.utc();
            // If it is currently an even hour then the next sync will be in 2 hours, if not it will be on the next hour
            var nextSync = isEvenHour(currentTime) ? moment.utc().add(2, 'hours') : moment.utc().add(1, 'hours');
            nextSync.minutes(0).seconds(0);
            // Get the difference from the current time till the next sync in minutes
            var difference = moment.duration(nextSync.diff(currentTime)).asMinutes();
            if (difference > 60) {
                return '> 2 hours';
            } else if (difference <= 60 && difference > 30) {
                return '> 1 hour';
            } else if (difference <= 30 && difference > 5) {
                return '> 30 minutes';
            } else if (difference <= 5) {
                return 'Soon';
            }
        };

        ctrl.integration = integration;
        ctrl.integration_title = GeckoUI.getObjectByKey(Gecko.Integration.type_titles, 'id', ctrl.integration.type).title;
        if (!ctrl.integration.config) {
            ctrl.integration.config = {};
        }

        ctrl.lastSyncDate = moment(ctrl.integration.event_last_sync, "X").format(Gecko.dateFormat.shortTime);
        if (ctrl.lastSyncDate == 'Invalid date') {
            ctrl.lastSyncDate = 'Never';
        }
        ctrl.nextSyncEstimate = getNextSyncDate();
        ctrl.events_ref = angular.copy(events.toArray());
        ctrl.events = eventInstances.filter(isEventUnsynced);
        ctrl.map_events = [];
        ctrl.selectOptions = {
            checkedWhen: function checkedWhen(external_event) {
                return external_event.create;
            },
            onChange: function onChange(external_event) {
                external_event.create = external_event.create ? 0 : 1;
                external_event.ignore = external_event.create ? 0 : 1;
            },
            disabledWhen: function disabledWhen(external_event) {
                return external_event.synced;
            },
            uniqueKey: 'external_id',
            noPersist: true
        };

        ctrl.pagination = {
            current_page: integration_events.current_page || 1,
            total_pages: integration_events.last_page || 1,
            hideLimit: true
        };

        ctrl.integration_events = integration_events.data;
        ctrl.eventsLoading = !!integration_events.queue_id;

        ctrl.reloadPage = function () {
            $state.go($state.$current.name, $stateParams, { reload: true });
        };

        ctrl.helpText = "Import events manually from " + ctrl.integration_title + ". Update these mappings to have new data about each event appear within " + ctrl.integration_title + " (sync times vary).";
        ctrl.autoSyncText = "Import new events automatically from " + ctrl.integration_title + ". These events will appear within GeckoEngage, and new data about each will appear within " + ctrl.integration_title + ". Sync times vary depending upon the status of each event.";

        var loading_sf_event_filters = true;
        if (integration.type === Gecko.Integration.TYPE_SALEFORCE) {
            integration.getFilters().then(function (filters) {
                var filtersFilter = integration.config.event_module || 'Campaign';
                ctrl.autoSyncListView[0].options = filters[filtersFilter];
                loading_sf_event_filters = false;
                $scope.$digest();
            }).catch(function (err) {
                console.error(err);
                GeckoUI.messenger.error('Unable to load campaign list views from Salesforce');
            });
        }

        ctrl.autoSyncToggle = [{
            type: Gecko.Field.TYPE_TOGGLE,
            label: 'Auto import',
            id: 'event_auto_sync',
            description: ctrl.autoSyncText,
            colClass: 'col-xs-6'
        }];

        ctrl.autoSyncListView = [{
            id: 'events_list_view',
            obj: ctrl.integration.config,
            type: Gecko.Field.TYPE_SELECT,
            label: 'Choose Events To Auto Sync',
            options: [],
            optionsKey: 'id',
            optionsLabelKey: 'label',
            hideWhen: function hideWhen() {
                return integration.type !== Gecko.Integration.TYPE_SALEFORCE || !integration.event_auto_sync;
            },
            disabledWhen: function disabledWhen() {
                return loading_sf_event_filters;
            },
            colClass: 'col-xs-6'
        }];

        ctrl.footerBtns = [{
            btnClass: 'btn-primary',
            title: 'Save Settings',
            btnTooltip: 'Save the settings for your how Events are handled with ' + ctrl.integration_title + '.',
            action: function action() {
                if (ctrl.integration.config.constructor === Object && Object.keys(ctrl.integration.config).length === 0) {
                    ctrl.integration.config = null;
                }
                ctrl.integration.save().then(function () {
                    GeckoUI.messenger.success(ctrl.integration_title + ' event settings updated');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }];

        ctrl.cols = [{
            title: ctrl.integration_title + ' Event',
            key: 'external_title',
            colClass: 'col-xs-4',
            sortKey: 'external_title'
        }, {
            title: 'Event Start Date',
            key: 'start_datetime',
            colClass: 'col-xs-2',
            sortKey: 'start_datetime',
            render: function render(start_datetime, col, event) {
                return geckoDates.printDateString(start_datetime) + ' (' + (event.timezone || Gecko.account.timezone) + ')';
            }
        }, {
            title: 'Gecko Events',
            colClass: 'col-xs-6 integeration-event-select',
            key: 'id',
            selectOptions: ctrl.events.map(function (event) {
                return Object.assign(event, { title: event.title + ' - ' + geckoDates.printDateString(event.start_datetime) });
            }),
            selectOptionsKey: 'id',
            selectOptionsLabel: 'title',
            selectOptionsDefault: function selectOptionsDefault(external_event) {
                if (external_event.id) {
                    var event = getEventById(external_event.id);
                    if (event) {
                        return event.title;
                    }
                }
                return 'Create: ' + external_event.external_title;
            },
            onChange: function onChange(external_event) {
                external_event.create = 1;
                external_event.ignore = 0;
            },
            disabledWhen: function disabledWhen(external_event) {
                return external_event.synced;
            }
        }];

        if (!ctrl.eventsLoading) {
            ctrl.mappingButtonAction = function () {
                var _events = ctrl.integration_events.filter(isEventToBeSynced).reduce(mapEventToObject, {});
                var _ignores = ctrl.integration_events.filter(isEventIgnored).map(ctrl.getEventIntegrationId);

                // Remember ignored integrations
                geckoStore.set('gecko_event_sync_ignore', _ignores);

                new Gecko.BaseModel().call('/integrations/' + ctrl.integration.id + '/merge_events', 'POST', { events: _events }, true).then(function () {
                    var num_events = Object.keys(_events).length;
                    var s = num_events === 1 ? '' : 's';
                    var have = num_events === 1 ? 'has' : 'have';
                    var a = num_events === 1 ? ' a' : '';
                    GeckoUI.messenger.success(num_events + ' event' + s + ' ' + have + ' been mapped to' + a + ' Gecko event' + s);
                    // Due to a delay(I think with the queue) refreshing the state doesn't immediately reflect the changes.
                    Object.keys(_events).forEach(function (eventId) {
                        var event = ctrl.integration_events.find(function (event) {
                            return ctrl.getEventIntegrationId(event) == eventId;
                        });
                        event.synced = 1;
                        event.create = 0;
                    });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err);
                });
            };

            ctrl.headerBtns = [{
                title: 'Map Selected Events',
                btnClass: 'btn-primary',
                icon: 'fa-sync',
                safeMode: true,
                btnTooltip: 'Connect with ' + ctrl.integration_title + ' and sync your Events',
                hideWhen: function hideWhen() {
                    return showSynced;
                },
                disabledWhen: function disabledWhen() {
                    var len = ctrl.integration_events.filter(isEventToBeSynced).length;
                    if (len === 0) {
                        this.btnTitle = 'Map Selected Events';
                    } else if (len === 1) {
                        this.btnTitle = 'Map ' + len + ' Event';
                    } else {
                        this.btnTitle = 'Map ' + len + ' Events';
                    }
                    return len === 0;
                },
                action: ctrl.mappingButtonAction
            }, {
                title: 'Refresh Events',
                btnClass: 'btn-default',
                btnTooltip: 'Refresh the list of current Events in your integration',
                hideWhen: function hideWhen() {
                    return ctrl.eventsLoading;
                },
                action: function action() {
                    ctrl.eventsLoading = true;
                    ctrl.integration.refreshEvents().then(function (response) {
                        $geckoSocket.registerEvent('queue:job', function (event) {
                            if (event.queue_id == response.queue_id && event.status == 'complete') {
                                ctrl.reloadPage();
                            }
                        });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                        ctrl.eventsLoading = false;
                    });
                }
            }];

            // Only show unsynced contacts
            var showSynced = 0;

            // Prepare integration events
            ctrl.prepareIntegrationEvents = function (integration_event) {
                if (!integration_event.id) {

                    if (ctrl.isEventIgnoreStored(integration_event)) {
                        integration_event.ignore = 1;
                        integration_event.create = 0;
                    } else {
                        integration_event.ignore = 0;
                        integration_event.create = 1;
                    }

                    integration_event.synced = 0;
                } else {
                    integration_event.synced = 1;
                }

                return integration_event;
            };
            ctrl.integration_events = ctrl.integration_events.map(ctrl.prepareIntegrationEvents);
        } else {
            $geckoSocket.registerEvent('queue:job', function (event) {
                if (event.queue_id == integration_events.queue_id && event.status == 'complete') {
                    ctrl.reloadPage();
                }
            });
        }
    }

    angular.module('GeckoEngage').controller('IntegrationEventsCtrl', IntegrationEventsCtrl);
})();