;(function () {
    'use strict';

    function IntegrationConsentsCtrl($scope, $state, $stateParams, integration, integration_fields, consents, consent_import) {
        var ctrl = this;
        ctrl.integration = integration;
        ctrl.consents = consents.toArray();
        ctrl.integration_fields = integration_fields.toArray();
        ctrl.consent_import = consent_import;

        // This will loop round all the current consent reasons that have been mapped and
        // check if they all map to the same salesforce module [Contact, Lead]
        ctrl.checkSalesforceMappingsMatch = function () {
            if (!ctrl.integration.type == Gecko.Integration.TYPE_SALEFORCE) {
                return false;
            }

            var stuff = ctrl.getModulesForEachConsentReason();

            // Check the module for each is the same
            return stuff.every(function (v) {
                return v === stuff[0];
            });
        };

        ctrl.getModulesForEachConsentReason = function () {
            if (!ctrl.integration.type == Gecko.Integration.TYPE_SALEFORCE) {
                return false;
            }

            return ctrl.consents.map(function (consent, consentKey) {
                if (consent.integration_field_id) {
                    var field = ctrl.integration_fields.find(function (if_val, if_key) {
                        return if_val.id === consent.integration_field_id;
                    });

                    if (field) {
                        return field.module;
                    }
                }
                return null;
            }).filter(function (n) {
                return n;
            }); // Remove nulls
        };

        ctrl.checkNightlyListModuleMatches = function () {
            if (!ctrl.consent_import.config || !ctrl.consent_import.config.module) {
                return true;
            }

            var modules = ctrl.getModulesForEachConsentReason();

            if (modules.length === 0) {
                return true;
            }

            modules.push(ctrl.consent_import.config.module);

            return modules.every(function (v) {
                return v === modules[0];
            });
        };

        ctrl.salesforceMappingsMatch = ctrl.checkSalesforceMappingsMatch();
        ctrl.nightlyListModulesMatch = ctrl.checkNightlyListModuleMatches();

        // Map fields to consents
        ctrl.buildConsentsArray = function (integrationFields, consents) {
            return (consents || []).map(function (c) {
                var field = (integrationFields || []).find(function (f) {
                    return f.consent_id === c.id;
                });
                c.integration_field_id = field ? field.id : null;
                c.consent_given_value = field ? field.consent_given_value : null;
                c.consent_withdrawn_value = field ? field.consent_withdrawn_value : null;
                return c;
            });
        };

        // Map consents to fields
        ctrl.buildValuesArray = function (integrationFields, consents) {
            return (integrationFields || []).map(function (f) {
                var consent = consents.find(function (c) {
                    return c.integration_field_id === f.id;
                });
                return {
                    id: f.id,
                    consent_id: consent ? consent.id : null,
                    consent_given_value: consent ? consent.consent_given_value : null,
                    consent_withdrawn_value: consent ? consent.consent_withdrawn_value : null
                };
            });
        };

        ctrl.consents = ctrl.buildConsentsArray(ctrl.integration_fields, ctrl.consents);

        ctrl.fields = [];

        ctrl.buildConsentFieldOptions = function (consent) {
            var values = ctrl.consents.filter(function (c) {
                return c.integration_field_id;
            }).map(function (c) {
                return c.integration_field_id;
            });

            ctrl.fields.forEach(function (f) {
                f.options = ctrl.integration_fields.filter(function (intF) {
                    // Include if option is set as value on this field
                    if ((ctrl.consents.find(function (c) {
                        return c.title === f.key;
                    }) || {}).integration_field_id === intF.id) return true;
                    // Check if used in other dropdown
                    return values.indexOf(intF.id) === -1;
                });
            });

            ctrl.salesforceMappingsMatch = ctrl.checkSalesforceMappingsMatch();
        };

        ctrl.consentFields = [];

        ctrl.consents.forEach(function (c, i) {
            // Title
            ctrl.consentFields.push({
                type: Gecko.Field.TYPE_TITLE,
                label: c.title
            });
            // Field dropdown
            ctrl.consentFields.push({
                label: 'Field',
                key: c.title,
                id: 'integration_field_id',
                obj: ctrl.consents[i],
                type: Gecko.Field.TYPE_SELECT,
                optionsKey: 'id',
                optionsLabelKey: 'label',
                options: ctrl.integration_fields,
                colClass: 'col-xs-12',
                onChange: function onChange() {
                    return ctrl.buildConsentFieldOptions();
                }
            });
            // Given / Withrawn Values
            ctrl.consentFields.push({
                label: 'Given Value',
                id: 'consent_given_value',
                obj: ctrl.consents[i],
                colClass: 'col-xs-6'
            });
            ctrl.consentFields.push({
                label: 'Withdrawn Value',
                id: 'consent_withdrawn_value',
                obj: ctrl.consents[i],
                colClass: 'col-xs-6'
            });
        });

        // Merge consentFields into main fields array
        ctrl.fields = ctrl.fields.concat(ctrl.consentFields);
        ctrl.buildConsentFieldOptions();

        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                var values = ctrl.buildValuesArray(ctrl.integration_fields, ctrl.consents);

                if (!ctrl.checkSalesforceMappingsMatch()) {
                    GeckoUI.messenger.error('Consent reasons are not mapped correctly');
                    return;
                }

                new Gecko.IntegrationField().saveConsents(ctrl.integration.id, values).then(function (_) {
                    return GeckoUI.messenger.success('Your Consent mappings have been saved');
                }).catch(GeckoUI.messenger.error);
            }
        }];

        ctrl.importFooterBtns = [{
            preset: 'save',
            action: function action() {
                // Delete the import
                if (ctrl.consent_import.config == null || ctrl.integration.type == Gecko.Integration.TYPE_RADIUS && ctrl.consent_import.config.target == null || ctrl.integration.type == Gecko.Integration.TYPE_SALEFORCE && ctrl.consent_import.config.list == null || ctrl.integration.type == Gecko.Integration.TYPE_CONNECT && ctrl.consent_import.config.config == null) {
                    ctrl.consent_import.remove().then(function () {
                        GeckoUI.messenger.success("Consent Import Deleted");
                        $state.go($state.$current.name, $stateParams, { reload: true });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                    });
                } else {

                    if (!ctrl.checkNightlyListModuleMatches()) {
                        GeckoUI.messenger.error('Consent reasons do not match Consent Syncing Salesforce module');
                        return;
                    }

                    ctrl.consent_import.save().then(function (import_data) {
                        GeckoUI.messenger.success("Consent Import saved");
                        $state.go($state.$current.name, $stateParams, { reload: true });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                    });
                }
            }
        }];

        /* ============================
             Consent Continuous Syncing
         ============================ */

        ctrl.getConnectFilterOptions = function () {
            return ((ctrl.integration_filters || {})[ctrl.consent_import.config.config_folder] || []).map(function (f) {
                return { id: Number(f.id), name: f.name };
            });
        };

        ctrl.getSalesforceModuleOptions = function () {
            return ((ctrl.integration_filters || {})[ctrl.consent_import.config.module] || []).map(function (f) {
                return { id: f.id, label: f.label };
            });
        };

        // Deal with hobsons stuff
        var loading = true;
        integration.getFilters().then(function (filters) {
            try {
                // Set filters
                ctrl.integration_filters = filters;

                // Connect
                if (ctrl.integration.type == Gecko.Integration.TYPE_CONNECT) {
                    // Build groups dropdown options
                    ctrl.integration_filters_folders = [];
                    for (var prop in ctrl.integration_filters) {
                        ctrl.integration_filters_folders.push({
                            id: prop,
                            name: prop
                        });
                    }

                    // Set Folder dropdown
                    ctrl.consent_syncing_fields.find(function (f) {
                        return f.id === 'config_folder';
                    }).options = ctrl.integration_filters_folders;
                    // Set Filter dropdown (if folder is set)
                    if (ctrl.consent_import.config.config_folder) ctrl.consent_syncing_fields.find(function (f) {
                        return f.id === 'config';
                    }).options = ctrl.getConnectFilterOptions();
                }

                // Radius
                if (ctrl.integration.type == Gecko.Integration.TYPE_RADIUS) {
                    GeckoUI.gobk(ctrl.consent_syncing_fields, 'id', 'target').options = ctrl.integration_filters;
                }

                // Salesforce
                if (ctrl.integration.type == Gecko.Integration.TYPE_SALEFORCE) {
                    ctrl.consent_syncing_fields.find(function (f) {
                        return f.key === Gecko.Integration.TYPE_SALEFORCE;
                    }).options = ctrl.integration_filters[ctrl.integration.config.root_module || 'Contact'];
                }

                loading = false;
                $scope.$digest();
            } catch (e) {
                console.error(e);
            }
        });

        // The config cant be filled if its null so give it a default
        if ([Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_SALEFORCE, Gecko.Integration.TYPE_CONNECT].indexOf(ctrl.integration.type) != -1 && ctrl.consent_import.config == null) {
            ctrl.consent_import.config = {};
        }

        ctrl.consent_syncing_fields = [
        // RADIUS
        {
            label: 'Radius Target',
            id: 'target',
            obj: ctrl.consent_import.config,
            description: "Import consent preferences for matched contacts using this list from your CRM.",
            colClass: 'no-padding',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.integration_filters || [{ id: 'loading', name: 'Loading...' }],
            optionsKey: 'id',
            optionsLabelKey: 'name',
            disabledWhen: function disabledWhen() {
                return loading;
            },
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_RADIUS;
            }
        },

        // CONNECT
        {
            label: 'Connect Folder',
            id: 'config_folder',
            obj: ctrl.consent_import.config,
            description: 'Choose which Connect Folder you want to import data from.',
            colClass: 'no-padding',
            type: Gecko.Field.TYPE_SELECT,
            options: [],
            optionsKey: 'id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_CONNECT || !(ctrl.integration_filters_folders || []).length;
            },
            action: function action() {
                if (ctrl.consent_import.config.config_folder) {
                    ctrl.consent_syncing_fields.find(function (f) {
                        return f.id === 'config';
                    }).options = ctrl.getConnectFilterOptions();
                } else {
                    ctrl.consent_syncing_fields.find(function (f) {
                        return f.id === 'config';
                    }).options = [];
                    ctrl.consent_import.config.config = null;
                }
            }
        }, {
            label: 'Connect Filter',
            id: 'config',
            obj: ctrl.consent_import.config,
            colClass: 'no-padding',
            description: "Import consent preferences for matched contacts using this list from your CRM.",
            type: Gecko.Field.TYPE_SELECT,
            options: [],
            optionsKey: 'id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_CONNECT;
            },
            disabledWhen: function disabledWhen() {
                return !ctrl.consent_import.config.config_folder;
            }
        },

        // SALESFORCE
        {
            key: Gecko.Integration.TYPE_SALEFORCE,
            label: 'Module Lists',
            obj: ctrl.consent_import.config,
            id: 'list',
            colClass: 'no-padding',
            type: Gecko.Field.TYPE_SELECT,
            options: [],
            optionsKey: 'id',
            optionsLabelKey: 'label',
            disabledWhen: function disabledWhen() {
                return loading;
            },
            hideWhen: function hideWhen() {
                return ctrl.integration.type !== Gecko.Integration.TYPE_SALEFORCE;
            }
        }];
    }

    angular.module('GeckoEngage').controller('IntegrationConsentsCtrl', IntegrationConsentsCtrl);
})();