(function () {
    'use strict';

    // National

    var allNumbers = ['au', 'at', 'bh', 'be', 'br', 'bg', 'ca', 'cy', 'cz', 'dk', 'do', 'sv', 'fl', 'fr', 'ee', 'gr', 'hk', 'ie', 'il', 'it', 'jp', 'lv', 'lt', 'lu', 'mt', 'mx', 'nz', 'no', 'pe', 'pl', 'pr', 'pt', 'ro', 'sk', 'za', 'es', 'se', 'ch', 'nl', 'gb', 'us'];

    // Local
    // Var localNumbers = [
    //     'au',
    //     'bh',
    //     'be',
    //     'br',
    //     'bg',
    //     'ca',
    //     'cy',
    //     'cz',
    //     'dk',
    //     'do',
    //     'sv',
    //     'fl',
    //     'gr',
    //     'il',
    //     'it',
    //     'jp',
    //     'lv',
    //     'lt',
    //     'lu',
    //     'mx',
    //     'nz',
    //     'pe',
    //     'pl',
    //     'pr',
    //     'ro',
    //     'sk',
    //     'es',
    //     'se',
    //     'ch',
    //     'gb',
    //     'us'
    // ];


    // //Mobile
    // Var mobileNumbers = [
    //     'au',
    //     'at',
    //     'be',
    //     'ee',
    //     'fl',
    //     'hk',
    //     'ie',
    //     'lt',
    //     'mx',
    //     'no',
    //     'pl',
    //     'se',
    //     'ch',
    //     'gb'
    // ];

    // //National
    // Var nationalNumbers = [
    //     'at',
    //     'be',
    //     'ee',
    //     'fl',
    //     'fr',
    //     'hk',
    //     'ie',
    //     'mt',
    //     'pt',
    //     'za',
    //     'es',
    //     'se',
    //     'nl',
    //     'gb'
    // ];

    // //Toll Free
    // Var nationalNumbers = [
    //     'ca',
    //     'gb',
    //     'us'
    // ];

    function SettingsVoipNumbersCtrl($state, $filter, senders) {
        var ctrl = this;
        ctrl.senders = senders.toArray();
        ctrl.pagination = senders.pagination;

        // Add a number dialog choice (purchase or verify)
        ctrl.addNumber = function () {
            var message = 'Would you like to verify or purchase a number?',
                array = [{ value: 'verify', label: 'Verify a Number' }, { value: 'purchase', label: 'Purchase a Number' }];

            var callback = function callback(id) {
                if (id == "verify") {
                    return $state.go('voipnumber', { sender_id: 'new' });
                } else if (id == "purchase") {
                    return $state.go('voipnumberpurchase');
                }
            };
            return GeckoUI.dialog.prompt(message, callback, array);
        };

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            colClass: 'col-xs-3'
        }, {
            title: 'Name',
            key: 'name',
            colClass: 'col-xs-3'
        }, {
            title: 'Number',
            key: 'tel',
            colClass: 'col-xs-3',
            render: function render(value, col, row) {
                return value + (row.ext ? ' - ' + row.ext : '');
            }
        }, {
            title: 'Type / Status',
            key: 'type',
            colClass: 'col-xs-3',
            status_key: 'verified',
            status_styles: [{
                id: null,
                label: 'label-default'
            }, {
                id: 0,
                label: 'label-warning'
            }, {
                id: 1,
                label: 'label-success'
            }],
            render: function render(value, col, row) {
                if (value == Gecko.Sender.TEL_VERIFIED) {
                    return row.verified ? 'Verifed' : 'Not Verified';
                } else if (value == Gecko.Sender.GECKOCHAT) {
                    return 'GeckoChat';
                } else {
                    return 'Purchased';
                }
            }
        }, {
            title: 'Voice (outgoing)',
            key: 'outgoing_voice',
            colClass: 'text-center gecko-table-icon-success-danger',
            icon_styles: [{
                id: 0,
                icon: 'fa-times',
                title: 'Outbound'
            }, {
                id: 1,
                icon: 'fa-check',
                title: 'Inbound'
            }]
        }, {
            title: 'Voice (incoming)',
            key: 'incoming_voice',
            colClass: 'text-center gecko-table-icon-success-danger',
            icon_styles: [{
                id: 0,
                icon: 'fa-times',
                title: 'Outbound'
            }, {
                id: 1,
                icon: 'fa-check',
                title: 'Inbound'
            }],
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL);
            }
        }, {
            title: 'SMS (outgoing)',
            key: 'outgoing_sms',
            colClass: 'text-center gecko-table-icon-success-danger',
            icon_styles: [{
                id: 0,
                icon: 'fa-times',
                title: 'Outbound'
            }, {
                id: 1,
                icon: 'fa-check',
                title: 'Inbound'
            }]
        }, {
            title: 'SMS (incoming)',
            key: 'incoming_sms',
            colClass: 'text-center gecko-table-icon-success-danger',
            icon_styles: [{
                id: 0,
                icon: 'fa-times',
                title: 'Outbound'
            }, {
                id: 1,
                icon: 'fa-check',
                title: 'Inbound'
            }],
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL);
            }
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'voipnumber',
            params: { sender_id: 'id' }
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'VoIP Numbers',
            active: true
        }];
    }

    function SettingsVerifyVoipNumberCtrl($scope, $state, $stateParams, $timeout, sender, campaigns, munge, $geckoWatch, asyncOptions, $geckoSocket) {
        var ctrl = this;
        ctrl.sender = sender;

        campaigns = campaigns.map(function (campaign) {
            campaign.label = campaign.title;
            return campaign;
        });
        ctrl.campaigns = campaigns;

        if ([Gecko.Sender.MESSAGE_TYPE_OFF, Gecko.Sender.MESSAGE_TYPE_TEXT, Gecko.Sender.MESSAGE_TYPE_FILE].indexOf(ctrl.sender.queue_welcome_type) === -1) ctrl.sender.queue_welcome_type = Gecko.Sender.MESSAGE_TYPE_OFF;
        if ([Gecko.Sender.MESSAGE_TYPE_OFF, Gecko.Sender.MESSAGE_TYPE_TEXT, Gecko.Sender.MESSAGE_TYPE_FILE].indexOf(ctrl.sender.queue_unavailable_type) === -1) ctrl.sender.queue_unavailable_type = Gecko.Sender.MESSAGE_TYPE_OFF;
        if ([Gecko.Sender.MESSAGE_TYPE_OFF, Gecko.Sender.MESSAGE_TYPE_TEXT, Gecko.Sender.MESSAGE_TYPE_FILE].indexOf(ctrl.sender.queue_update_type) === -1) ctrl.sender.queue_update_type = Gecko.Sender.MESSAGE_TYPE_OFF;
        if ([Gecko.Sender.MESSAGE_TYPE_OFF, Gecko.Sender.MESSAGE_TYPE_TEXT, Gecko.Sender.MESSAGE_TYPE_FILE].indexOf(ctrl.sender.queue_join_type) === -1) ctrl.sender.queue_join_type = Gecko.Sender.MESSAGE_TYPE_OFF;

        // Set the initial campaign state
        var campaignList = {};

        var initialiseCampaignLists = function initialiseCampaignLists(sender) {
            // Initialise the campaigns property on the sender
            sender.campaigns = [];
            sender.related_inbound_campaigns = ctrl.sender.related_inbound_campaigns || [];
            sender.related_users = ctrl.sender.related_users || [];

            campaignList.inbound = sender.related_inbound_campaigns.map(function (campaign) {
                return { id: campaign.id, type: 'inbound' };
            });
            campaignList.outbound = ctrl.campaigns.filter(function (campaign) {
                return campaign.call_sender_id === sender.id;
            }).map(function (campaign) {
                return { id: campaign.id, type: 'outbound' };
            });
            campaignList.both = campaignList.inbound.filter(function (inboundCampaign) {
                return campaignList.outbound.find(function (outboundCampaign, indexOutbound) {
                    // Return all inbound campaigns that have the same id as an outbound campaign
                    if (inboundCampaign.id === outboundCampaign.id) {
                        campaignList.outbound.splice(indexOutbound, 1); // Remove the duplicate entry from outbound
                        inboundCampaign.type = 'both'; // Set type to both
                        return true;
                    }
                });
            });

            // Add all the mapped campaigns to the campaigns property on the sender(both is included in inbound)
            sender.campaigns = sender.campaigns.concat(campaignList.inbound, campaignList.outbound);
            sender.campaigns.push({ id: null });
            return sender;
        };
        initialiseCampaignLists(ctrl.sender);

        // Initialise sender users
        ctrl.sender.users = munge(ctrl.sender.related_users).byKey('id').done();

        if (!ctrl.sender.type) ctrl.sender.type = Gecko.Sender.TEL_VERIFIED;

        // Watch the sender to see if it's verification code goes away
        if (sender.verification_code) {
            $geckoSocket.watch('sender', ctrl.sender, ['verification_code']);
        }

        ctrl.saveSender = function (reverify) {
            // Get cleaned number
            if ($stateParams.sender_id == 'new') {
                if (ctrl.sender.type != Gecko.Sender.TEL_PURCHASED && angular.isObject(ctrl.sender.tel)) {
                    ctrl.sender.tel = ctrl.sender.tel && ctrl.sender.tel.cleaned ? ctrl.sender.tel.cleaned : null;
                }
            }

            // Sanatize an optional forwarding number
            if (ctrl.sender.type == Gecko.Sender.TEL_PURCHASED && ctrl.sender.forwarding_tel && ctrl.sender.forwarding_tel.cleaned) {
                ctrl.sender.forwarding_tel = ctrl.sender.forwarding_tel.cleaned;
            }

            // Blank off messages and ensure non blank messages are strings
            if (ctrl.sender.queue_welcome_type === Gecko.Sender.MESSAGE_TYPE_OFF) {
                ctrl.sender.queue_welcome_message = null;
            } else {
                ctrl.sender.queue_welcome_message = ctrl.sender.queue_welcome_message.toString();
            }
            if (ctrl.sender.queue_unavailable_type === Gecko.Sender.MESSAGE_TYPE_OFF) {
                ctrl.sender.queue_unavailable_message = null;
            } else {
                ctrl.sender.queue_unavailable_message = ctrl.sender.queue_unavailable_message.toString();
            }
            if (ctrl.sender.queue_update_type === Gecko.Sender.MESSAGE_TYPE_OFF) {
                ctrl.sender.queue_update_message = null;
            } else {
                ctrl.sender.queue_update_message = ctrl.sender.queue_update_message.toString();
            }
            if (ctrl.sender.queue_join_type === Gecko.Sender.MESSAGE_TYPE_OFF) {
                ctrl.sender.queue_join_message = null;
            } else {
                ctrl.sender.queue_join_message = ctrl.sender.queue_join_message.toString();
            }

            if ([Gecko.Sender.MESSAGE_TYPE_OFF, Gecko.Sender.MESSAGE_TYPE_TEXT, Gecko.Sender.MESSAGE_TYPE_FILE].indexOf(ctrl.sender.queue_welcome_type) === -1) ctrl.sender.queue_welcome_type = Gecko.Sender.MESSAGE_TYPE_OFF;
            if ([Gecko.Sender.MESSAGE_TYPE_OFF, Gecko.Sender.MESSAGE_TYPE_TEXT, Gecko.Sender.MESSAGE_TYPE_FILE].indexOf(ctrl.sender.queue_unavailable_type) === -1) ctrl.sender.queue_unavailable_type = Gecko.Sender.MESSAGE_TYPE_OFF;
            if ([Gecko.Sender.MESSAGE_TYPE_OFF, Gecko.Sender.MESSAGE_TYPE_TEXT, Gecko.Sender.MESSAGE_TYPE_FILE].indexOf(ctrl.sender.queue_update_type) === -1) ctrl.sender.queue_update_type = Gecko.Sender.MESSAGE_TYPE_OFF;
            if ([Gecko.Sender.MESSAGE_TYPE_OFF, Gecko.Sender.MESSAGE_TYPE_TEXT, Gecko.Sender.MESSAGE_TYPE_FILE].indexOf(ctrl.sender.queue_join_type) === -1) ctrl.sender.queue_join_type = Gecko.Sender.MESSAGE_TYPE_OFF;

            // Update campaigns
            // Map the inbound campaign ids to the sender property
            ctrl.sender.campaign_ids = campaignList.inbound.concat(campaignList.both).filter(function (campaign) {
                return campaign.id !== null;
            }).map(function (campaign) {
                return campaign.id;
            });

            // Update the outbound campaigns with the senders id
            campaignList.outbound.concat(campaignList.both).forEach(function (campaign) {
                var campaignObj = ctrl.campaigns.find(isSameId(campaign));
                if (campaignObj && campaignObj.call_sender_id !== ctrl.sender.id) {
                    campaignObj.call_sender_id = ctrl.sender.id;
                    campaignObj.save();
                }
            });

            // Get all the campaigns that have the sender id but are no longer marked as outbound
            var notOutboundCampaigns = ctrl.campaigns.filter(function (campaign) {
                return campaign.call_sender_id === ctrl.sender.id // Campaigns that have this sender linked to them
                && !campaignList.outbound.concat(campaignList.both).find(isSameId(campaign)); // The campaign is no longer mapped as outbound
            });
            // Remove the sender id from the campaigns that are no longer outbound
            notOutboundCampaigns.forEach(function (campaign) {
                var campaignObj = ctrl.campaigns.find(isSameId(campaign));
                if (campaignObj) {
                    campaignObj.call_sender_id = null;
                    campaignObj.save();
                }
            });

            ctrl.sender.save().then(function (sender) {
                GeckoUI.messenger.success(reverify ? 'You will receive a verification call shorty.' : 'VoIP number saved');
                if ($stateParams.sender_id == 'new') {
                    $state.go('voipnumber', {
                        sender_id: sender.id
                    });
                } else {
                    ctrl.sender = initialiseCampaignLists(sender);
                }
                $scope.$digest();
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'VoIP Numbers',
            click: function click() {
                $state.go('voipnumbers');
            }
        }, {
            label: ctrl.sender.name ? ctrl.sender.name : 'Add VoIP Number',
            active: true
        }];

        ctrl.shouldDisplayCampaignsTable = function () {
            // Always show if outbound is selected, if inbound is selected only show when campaigns is selected
            return (ctrl.sender.outbound_on || ctrl.sender.inbound_on && ctrl.sender.action === Gecko.Sender.ACTION_QUEUE) && ctrl.sender.type !== Gecko.Sender.GECKOCHAT;
        };
        ctrl.shouldHideCampaignsTable = function () {
            return !ctrl.shouldDisplayCampaignsTable();
        };

        ctrl.shouldDisplayMessagesSection = function () {
            // Show if inbound is selected and either messages or campaigns are selected
            return ctrl.sender.inbound_on && ctrl.sender.action === Gecko.Sender.ACTION_QUEUE;
        };
        ctrl.shouldHideMessagesSection = function () {
            return !ctrl.shouldDisplayMessagesSection();
        };

        ctrl.shouldDisplayMessageBox = function (toggleValue) {
            // Show if the messages section should be showing and the toggleValue is truthy and the phone number is verified
            return ctrl.shouldDisplayMessagesSection() && toggleValue !== 'off'; // && ctrl.sender.type == Gecko.Sender.TEL_VERIFIED;
        };
        ctrl.shouldHideMessageBox = function (toggleValue) {
            return !ctrl.shouldDisplayMessageBox(toggleValue);
        };

        ctrl.shouldDisplayForwardingSection = function () {
            // Show if inbound is selected and either forward or campaigns are selected and the phone number is verified
            return ctrl.sender.inbound_on && ctrl.sender.action === Gecko.Sender.ACTION_REDIRECT;
        };
        ctrl.shouldHideForwardingSection = function () {
            return !ctrl.shouldDisplayForwardingSection();
        };

        var updateCampaignRadioButtons = function updateCampaignRadioButtons(campaign) {
            if (ctrl.sender.inbound_on && !ctrl.sender.outbound_on) {
                campaign.type = 'inbound';
            }
            if (ctrl.sender.outbound_on && !ctrl.sender.inbound_on) {
                campaign.type = 'outbound';
            }
            if (ctrl.sender.outbound_on && ctrl.sender.inbound_on) {
                campaign.type = 'both';
            }
            updateCampaignList(campaign);
        };

        var removeFromList = function removeFromList(item) {
            return function (list) {
                var index = indexOf(list)(function (test) {
                    return test.id === item.id;
                });
                if (index !== -1) {
                    list.splice(index, 1);
                }
                return list;
            };
        };
        var updateCampaignList = function updateCampaignList(campaign) {
            var removeCampaignFromList = removeFromList(campaign);
            // Remove the campaign from a current list if it is in one
            campaignList.inbound = removeCampaignFromList(campaignList.inbound);
            campaignList.outbound = removeCampaignFromList(campaignList.outbound);
            campaignList.both = removeCampaignFromList(campaignList.both);

            // Add the campaign to the necessary list
            campaignList[campaign.type].push(campaign);

            var campaignObj = ctrl.campaigns.find(isSameId(campaign));
            if ((campaign.type === 'outbound' || campaign.type === 'both') && campaignObj.call_sender_id != ctrl.sender.id) {
                ctrl.addOverwriteWarning(campaignObj);
            } else {
                ctrl.removeOverwriteWarning(campaignObj);
            }
        };

        ctrl.overwriteWarnings = [];
        ctrl.addOverwriteWarning = function (campaign) {
            if (!ctrl.overwriteWarnings.find(isSameId(campaign))) {
                ctrl.overwriteWarnings = ctrl.overwriteWarnings.concat({
                    id: campaign.id,
                    title: campaign.title
                });
            }
        };
        ctrl.removeOverwriteWarning = function (campaign) {
            removeFromList(campaign)(ctrl.overwriteWarnings);
        };

        if (!sender.queue_music) {
            sender.queue_music = 'default';
        }
        ctrl.fields = [
        // ==[ General fields ]==
        {
            id: 'name',
            label: 'Name',
            colClass: 'col-xs-6',
            description: 'This name is used to identify your number throughout Gecko.',
            required: true,
            disabledWhen: function disabledWhen() {
                return ctrl.sender.type == Gecko.Sender.GECKOCHAT;
            }
        }, {
            id: 'tel',
            label: 'Number',
            colClass: 'col-xs-6',
            description: 'Enter the number you’d like to to connect to the VoIP service.',
            required: true,
            type: Gecko.Field.TYPE_TEL,
            disabledWhen: function disabledWhen() {
                return $stateParams.sender_id != 'new';
            }
        }, {
            id: 'from_name',
            colClass: 'col-xs-6',
            label: 'Custom SMS From Name',
            hideWhen: function hideWhen() {
                return !ctrl.sender.outgoing_sms || !Gecko.able(Gecko.ABILITY_SMS_CUST_FROM_NAME) || ctrl.sender.type == Gecko.Sender.GECKOCHAT;
            },
            description: 'Set a custom name your SMS\'s should appear as coming from (should be alphanumeric). Custom names are supported in <a href="https://support.twilio.com/hc/en-us/articles/223133767-International-support-for-Alphanumeric-Sender-ID" target="_blank">these countries</a>.'
        }, {
            type: Gecko.Field.TYPE_TOGGLE,
            break: true,
            key: 'outbound_on',
            colClass: 'col-xs-6',
            label: 'Available for Outbound Call',
            description: 'Allow this number to be used for outbound calls.',
            hideWhen: function hideWhen() {
                return !(Gecko.able(Gecko.ABILITY_SENDERS_VOIP) && ctrl.sender.outgoing_voice) || ctrl.sender.type === Gecko.Sender.GECKOCHAT;
            }
        }, {
            type: Gecko.Field.TYPE_TOGGLE,
            id: 'inbound_on',
            colClass: 'col-xs-6',
            label: 'Available for Incoming Call',
            description: 'Allow this number to receive incoming calls.',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL) || !ctrl.sender.incoming_voice || ctrl.sender.type === Gecko.Sender.GECKOCHAT;
            }
        }, {
            type: Gecko.Field.TYPE_TOGGLE,
            id: 'record_calls',
            colClass: 'col-xs-6',
            label: 'Record Calls',
            description: 'All calls from this number will be recorded - this overrides <a ui-sref="campaigns({module:\'call\'})">Call Campaign</a> and individual <a ui-sref="users">User</a> settings.',
            hideWhen: function hideWhen() {
                return !(Gecko.able(Gecko.ABILITY_SENDERS_VOIP) && (ctrl.sender.outgoing_voice || ctrl.sender.incoming_voice)) || ctrl.sender.type === Gecko.Sender.GECKOCHAT;
            }
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'queue_music',
            label: 'Queue Music',
            colClass: 'col-xs-6',
            description: 'The music that will be played to inbound callers who are in a queue.',
            noBlank: true,
            options: Gecko.Sender.QUEUE_MUSIC_TITLES,
            optionsKey: 'value',
            optionsLabelKey: 'label',
            hideWhen: ctrl.shouldHideMessagesSection
        },

        // ==[ Number Options ]==
        {
            label: 'Inbound Call Options',
            type: Gecko.Field.TYPE_TITLE,
            hideWhen: function hideWhen() {
                return !ctrl.sender.inbound_on || ctrl.sender.type === Gecko.Sender.GECKOCHAT;
            }
        }, {
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_RADIO,
            key: 'action',
            options: [{
                value: Gecko.Sender.ACTION_QUEUE,
                label: 'Standard - all incoming calls will ring.'
            }, {
                value: Gecko.Sender.ACTION_MESSAGE,
                label: 'Immediately redirect all incoming calls to your Unavailable Message.'
            }, {
                value: Gecko.Sender.ACTION_REDIRECT,
                label: 'Immediately forward all incoming calls to your Forwarding Number.'
            }],
            optionsKey: 'value',
            optionsLabelKey: 'label',
            hideWhen: function hideWhen() {
                return !ctrl.sender.inbound_on || ctrl.sender.type === Gecko.Sender.GECKOCHAT;
            }
        }, {
            label: 'Available Users',
            type: Gecko.Field.TYPE_MULTI,
            key: 'users',
            colClass: 'col-xs-12',
            options: ctrl.sender.related_users.map(function (v) {
                return v.toObject();
            }),
            getOptions: asyncOptions.create(new Gecko.User().orderBy('full_name').rfields({ user: ['full_name'] })),
            optionsKey: 'id',
            optionsLabelKey: 'full_name',
            hideWhen: function hideWhen() {
                return !ctrl.sender.inbound_on || ctrl.sender.type === Gecko.Sender.GECKOCHAT;
            }
        }, {
            type: Gecko.Field.TYPE_SCRIPT,
            break: true,
            colClass: 'alert alert-info',
            description: '<p>Campaigns associated with this number as <b>Inbound</b> or <b>Both</b>: Users will be able to log inbound calls against this number.</p>' + '<p>Campaigns associated with this number as <b>Outbound</b> or <b>Both</b>: Users will use this number by default when making calls as part of a specified campaign.</p>',
            hideWhen: function hideWhen() {
                return ctrl.shouldHideCampaignsTable() || ctrl.sender.type == Gecko.Sender.GECKOCHAT;
            }
        }];

        // Campaigns
        // (array) => (function => boolean) => integer
        var indexOf = function indexOf(array) {
            return function (booleanCallback) {
                for (var i = 0; i < array.length; i++) {
                    if (booleanCallback(array[i], array)) {
                        return i;
                    }
                }
                return -1;
            };
        };
        var indexOfCampaign = function indexOfCampaign(booleanCallback) {
            return indexOf(ctrl.sender.campaigns)(booleanCallback);
        };
        var isIdNull = function isIdNull(item) {
            return item.id === null;
        };
        var isSameId = function isSameId(item) {
            return function (item2) {
                return item.id == item2.id;
            };
        };
        ctrl.campaignCols = [{
            data_type: Gecko.Field.DROPDOWN,
            title: 'Campaign',
            key: 'id',
            colClass: 'col-xs-9',
            selectOptions: function selectOptions(selectedCampaign) {
                var thisCampaign = ctrl.campaigns.find(isSameId(selectedCampaign)); // Include this selects selected campaign in it's options
                var unselectedCampaigns = ctrl.campaigns.filter(function (campaign) {
                    return indexOfCampaign(isSameId(campaign)) == -1;
                });
                return [thisCampaign].concat(unselectedCampaigns);
            },
            selectOptionsKey: 'id',
            selectOptionsLabel: 'title',
            onChange: function onChange(campaign) {
                if (!isIdNull(campaign)) {
                    if (indexOfCampaign(isIdNull) === -1) {
                        ctrl.sender.campaigns.push({ id: null });
                        updateCampaignRadioButtons(campaign);
                    }
                } else {
                    var index = indexOfCampaign(isIdNull);
                    ctrl.sender.campaigns.splice(index, 1);
                }
            }
        }, {
            title: 'Inbound',
            key: 'type',
            colClass: 'col-xs-1',
            input_type: Gecko.Field.TYPE_RADIO,
            value: 'inbound',
            disabledWhen: function disabledWhen(campaign) {
                return !campaign.id;
            },
            hideWhen: function hideWhen() {
                return !ctrl.sender.inbound_on || ctrl.sender.action !== Gecko.Sender.ACTION_QUEUE;
            },
            onChange: function onChange(campaign) {
                updateCampaignList(campaign);
            }
        }, {
            title: 'Outbound',
            key: 'type',
            colClass: 'col-xs-1',
            input_type: Gecko.Field.TYPE_RADIO,
            value: 'outbound',
            disabledWhen: function disabledWhen(campaign) {
                return !campaign.id;
            },
            hideWhen: function hideWhen() {
                return !ctrl.sender.outbound_on;
            },
            onChange: function onChange(campaign) {
                updateCampaignList(campaign);
            }
        }, {
            title: 'Both',
            key: 'type',
            colClass: 'col-xs-1',
            input_type: Gecko.Field.TYPE_RADIO,
            value: 'both',
            disabledWhen: function disabledWhen(campaign) {
                return !campaign.id;
            },
            hideWhen: function hideWhen() {
                return !ctrl.sender.inbound_on || !ctrl.sender.outbound_on || ctrl.sender.action !== Gecko.Sender.ACTION_QUEUE;
            },
            onChange: function onChange(campaign) {
                updateCampaignList(campaign);
            }
        }];

        var prepareMessageTypeDropdown = function prepareMessageTypeDropdown(key, label, desc) {
            return {
                break: true,
                type: Gecko.Field.TYPE_SELECT,
                description: desc,
                key: key,
                colClass: 'col-xs-4',
                label: label,
                optionsKey: 'id',
                optionsLabelKey: 'title',
                options: [{
                    id: Gecko.Sender.MESSAGE_TYPE_OFF,
                    title: 'Off'
                }, {
                    id: Gecko.Sender.MESSAGE_TYPE_TEXT,
                    title: 'Text'
                }, {
                    id: Gecko.Sender.MESSAGE_TYPE_FILE,
                    title: 'Sound File URL'
                }],
                hideWhen: ctrl.shouldHideMessagesSection,
                noBlank: true
            };
        };

        // Messages
        ctrl.fields2 = [{
            id: 'message',
            label: 'Unavailable Message',
            colClass: 'col-xs-12',
            description: 'Message that will play if no forwarding number is provided e.g "We\'re sorry, this number is unavailable."',
            type: Gecko.Field.TYPE_TEXTAREA,
            hideWhen: function hideWhen() {
                return ctrl.sender.action !== Gecko.Sender.ACTION_MESSAGE || ctrl.sender.type === Gecko.Sender.GECKOCHAT;
            }
        }, {
            type: Gecko.Field.TYPE_SCRIPT,
            break: true,
            colClass: 'alert alert-info',
            description: '<h5>Voice Messages for Incoming Calls</h5><p>Messages will play only when your number is linked to a campaign. Only incoming calls within that campaign will trigger voice messages.</p>',
            hideWhen: ctrl.shouldHideMessagesSection
        },
        // Unavailable Message
        prepareMessageTypeDropdown('queue_unavailable_type', 'Unavailable Message Type', 'The ability to play an unavailable message.'), {
            id: 'queue_unavailable_message',
            label: 'Unavailable Message',
            colClass: 'col-xs-8',
            description: 'Message that will play if no agents are online to take the call e.g. "We can\'t take your call right now. Please try again later."',
            type: Gecko.Field.TYPE_TEXTAREA,
            hideWhen: function hideWhen() {
                return ctrl.shouldHideMessageBox(ctrl.sender.queue_unavailable_type);
            }
        },
        // Welcome Message
        prepareMessageTypeDropdown('queue_welcome_type', 'Welcome Message', 'The ability to play a welcome message.'), {
            id: 'queue_welcome_message',
            label: 'Welcome Message',
            colClass: 'col-xs-8',
            description: 'Message that will play when the caller connects to the number e.g. "Thanks for calling GeckoLabs"',
            type: Gecko.Field.TYPE_TEXTAREA,
            hideWhen: function hideWhen() {
                return ctrl.shouldHideMessageBox(ctrl.sender.queue_welcome_type);
            }
        },
        // Queue Join Message
        prepareMessageTypeDropdown('queue_join_type', 'Queue Join Message', 'The ability to play a message when a caller joins the queue.'), {
            id: 'queue_join_message',
            label: 'Queue Message',
            colClass: 'col-xs-8',
            description: 'Message that will play when the user is in the queue e.g. "Thanks for waiting, your call is important to us and we\'ll be with you soon". By inserting the following tags within your message, you can include dynamic values: <code ng-non-bindable>{{queue.position}}</code>, <code ng-non-bindable>{{queue.length}}</code>, <code ng-non-bindable>{{queue.time}}</code>, <code ng-non-bindable>{{queue.average_time}}</code>.',
            type: Gecko.Field.TYPE_TEXTAREA,
            hideWhen: function hideWhen() {
                return ctrl.shouldHideMessageBox(ctrl.sender.queue_join_type);
            }
        },
        // Queue Update Message
        prepareMessageTypeDropdown('queue_update_type', 'Queue Update Message', 'The ability to play a message updating the caller their queue status.'), {
            id: 'queue_update_message',
            label: 'Queue Update Message',
            colClass: 'col-xs-8',
            description: 'Message that will play as the user\'s position in the queue is updated e.g. "There are 3 callers waiting, we\'ll be with you soon". By inserting the following tags within your message, you can include dynamic values: <code ng-non-bindable>{{queue.position}}</code>, <code ng-non-bindable>{{queue.length}}</code>, <code ng-non-bindable>{{queue.time}}</code>, <code ng-non-bindable>{{queue.average_time}}</code>',
            type: Gecko.Field.TYPE_TEXTAREA,
            hideWhen: function hideWhen() {
                return ctrl.shouldHideMessageBox(ctrl.sender.queue_update_type);
            }
        },
        // Forwarding
        {
            id: 'forwarding_tel',
            label: 'Forwarding Number',
            colClass: 'col-xs-12',
            description: 'Input the telephone number any incoming calls will be redirected to.',
            type: Gecko.Field.TYPE_TEL,
            hideWhen: ctrl.shouldHideForwardingSection
        }];

        // Live validate the from_name field to alphanumeric
        var alphaNumeric = new RegExp(/^[a-z\d\s]+$/, 'ui');
        $scope.$watch(function () {
            return ctrl.sender.from_name;
        }, function (newVal, oldVal) {
            if (!alphaNumeric.test(newVal) && newVal !== '') {
                ctrl.sender.from_name = oldVal;
            }
        });

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: ctrl.saveSender,
            hideWhen: function hideWhen() {
                return ctrl.sender.type == Gecko.Sender.GECKOCHAT;
            }
        }, {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.sender_id == 'new';
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this sender?', function (value) {
                    if (value) {
                        ctrl.sender.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Sender has been deleted');
                            $state.go('voipnumbers');
                        }).catch(function (error) {
                            // Show error
                            GeckoUI.messenger.error(error.errors);
                        });
                    }
                });
            }
        }];

        // Check 'number' type
        ctrl.isInboundReady = function () {
            return ctrl.sender.type == Gecko.Sender.TEL_VERIFIED || !ctrl.sender.incoming_voice || !Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL);
        };

        // Reset camapign types on support changes
        $geckoWatch(ctrl.sender, 'outbound_on', function (value) {
            if (!value && ctrl.sender.campaigns && ctrl.sender.campaigns.length) {
                angular.forEach(ctrl.sender.campaigns, function (campaign) {
                    if (campaign.type === 'both') {
                        campaign.type = 'inbound';
                    } else {
                        campaign.type = null;
                    }
                });
            }
        });
        $geckoWatch(ctrl.sender, 'inbound_on', function (value) {
            if (!value && ctrl.sender.campaigns && ctrl.sender.campaigns.length) {
                angular.forEach(ctrl.sender.campaigns, function (campaign) {
                    if (campaign.type === 'both') {
                        campaign.type = 'outbound';
                    } else {
                        campaign.type = null;
                    }
                });
            }
        });
    }

    function SettingsPurchaseVoipNumberCtrl($scope, $state, $stateParams, $filter, $timeout, sender, _supportedCountries, LoadingIndicator) {
        var ctrl = this;
        ctrl.sender = sender;
        ctrl.allNumbers = allNumbers;
        ctrl.sender.type = Gecko.Sender.TEL_PURCHASED;
        ctrl.address = Gecko.account.address;

        ctrl.initSearch = function (searchAgain) {
            if (searchAgain) ctrl.sender.tel = null;
            ctrl.calling_code = '+44';
            ctrl.country_code = 'GB';
            ctrl.area_code = '';
            ctrl.search_capability = 'voice';
            ctrl.numberResults = [];
            ctrl.noNumberResults = false;
        };

        ctrl.searchNumbers = function () {
            if (!ctrl.address) {
                return GeckoUI.messenger.error('Account address required in order to acquire a SMS number');
            }
            // Show indicator
            LoadingIndicator.show();

            var voice = ctrl.search_capability != 'sms' ? true : false;
            var sms = ctrl.search_capability != 'voice' ? true : false;

            var search = ctrl.calling_code + ctrl.area_code;
            var pattern = search && search.length > 2 ? search : undefined;

            ctrl.sender.search(ctrl.country_code, ctrl.search_type, voice, sms, pattern).then(function (data) {

                ctrl.numberResults = data;
                if (!ctrl.numberResults.length) ctrl.noNumberResults = true;
                $scope.$digest();

                // Hide indicator
                LoadingIndicator.hide();
            }).catch(function (error) {
                // Show error
                GeckoUI.messenger.error(error.message);
                // Hide indicator
                LoadingIndicator.hide();
            });
        };

        ctrl.headerBtns = [{
            icon: 'fa-times',
            btnTooltip: 'Reset',
            action: ctrl.initSearch
        }];

        // Table structure
        ctrl.cols = [{
            title: 'Number',
            key: 'number',
            colClass: 'col-xs-3'
        }, {
            title: 'Region',
            key: 'region',
            colClass: 'col-xs-3'
        }, {
            title: 'Voice Support',
            key: 'voice_support',
            colClass: 'col-xs-3',
            render: function render(value) {
                return $filter('capitalize')(value);
            }
        }, {
            title: 'SMS Support',
            key: 'sms_support',
            colClass: 'col-xs-3',
            render: function render(value) {
                return $filter('capitalize')(value);
            }
        }, {
            title: 'Voice (outgoing)',
            key: 'outgoing_voice',
            colClass: 'text-center gecko-table-icon-success-danger',
            icon_styles: [{
                id: 0,
                icon: 'fa-times',
                title: 'Outbound'
            }, {
                id: 1,
                icon: 'fa-check',
                title: 'Inbound'
            }]
        }, {
            title: 'Voice (incoming)',
            key: 'incoming_voice',
            colClass: 'text-center gecko-table-icon-success-danger',
            icon_styles: [{
                id: 0,
                icon: 'fa-times',
                title: 'Outbound'
            }, {
                id: 1,
                icon: 'fa-check',
                title: 'Inbound'
            }],
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL);
            }
        }, {
            title: 'SMS (outgoing)',
            key: 'outgoing_sms',
            colClass: 'text-center gecko-table-icon-success-danger',
            icon_styles: [{
                id: 0,
                icon: 'fa-times',
                title: 'Outbound'
            }, {
                id: 1,
                icon: 'fa-check',
                title: 'Inbound'
            }]
        }, {
            title: 'SMS (incoming)',
            key: 'incoming_sms',
            colClass: 'text-center gecko-table-icon-success-danger',
            icon_styles: [{
                id: 0,
                icon: 'fa-times',
                title: 'Outbound'
            }, {
                id: 1,
                icon: 'fa-check',
                title: 'Inbound'
            }],
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_INBOUND_CALL);
            }
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(number) {
                GeckoUI.dialog.confirm('Are you sure you wish to purchase this number? <code>Purchasing a number may incur a £2 monthly charge.</code>', function (value) {
                    if (value) {
                        ctrl.sender.name = ctrl.name ? ctrl.name : number.number;
                        ctrl.sender.tel = number.number;

                        // Set number
                        ctrl.sender.tel = number.number;
                        // Save number
                        ctrl.sender.save().then(function () {
                            GeckoUI.messenger.success('Number purchased.');
                            $state.go('voipnumbers');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        };

        // Setup
        ctrl.initSearch();

        // Prepare supported countries list
        var capitalise = function capitalise(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
        _supportedCountries = _supportedCountries.data;
        var supportedCountries = _supportedCountries.reduce(function (countries, country) {
            var foundCountry = countries.find(function (c) {
                return c.country_code === country.country_code;
            });
            if (!foundCountry) {
                return countries.concat(angular.extend({}, country, { types: [capitalise(country.type)] }));
            } else {
                foundCountry.types.push(capitalise(country.type));
                return countries;
            }
        }, []);

        // Update dial code is search pattern when country code changes
        $scope.$watch('ctrl.country_code', function (oldVal, newVal) {
            if (oldVal !== newVal) {
                return ctrl.calling_code = '+' + GeckoUI.gobk(supportedCountries, 'country_code', ctrl.country_code).dial_code;
            }
        });

        ctrl.fields = [{
            id: 'name',
            label: 'Name',
            colClass: 'col-xs-12',
            description: 'This name is used to identify your number throughout Gecko.',
            required: true
        }, {
            id: 'country_code',
            label: 'Country',
            type: Gecko.Field.TYPE_SELECT,
            options: supportedCountries,
            optionsKey: 'country_code',
            optionsLabelKey: 'country_name',
            noBlank: true,
            colClass: 'col-xs-4',
            onChange: function onChange() {
                ctrl.fields.find(function (field) {
                    return field.id === 'search_type';
                }).getOptions();
            }
        }, {
            id: 'calling_code',
            label: 'Country Code',
            colClass: 'col-xs-4',
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'area_code',
            label: 'Area Code (optional)',
            description: 'Narrow down searches by adding a suitable area code.',
            colClass: 'col-xs-4',
            disabledWhen: function disabledWhen() {
                return ctrl.search_type === 'National';
            }
        }, {
            id: 'search_type',
            label: 'Type',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            colClass: 'col-xs-12',
            description: 'Choose from one of four unique types of numbers available for you.',
            choiceTemplate: 'number-type-choice',
            options: [],
            getOptions: function getOptions() {
                // https://support.twilio.com/hc/en-us/articles/223135367-Phone-Number-types-Twilio-offers-and-how-they-work
                var typeMap = {
                    'Local': 'Local numbers are telephone numbers which are assigned to a specific region. These numbers are typically used by individuals, local businesses and can be considered the most general type of numbers.',
                    'Mobile': 'In most countries, mobile numbers are assigned to a particular range within the country’s telephone numbering plan so they can be easily distinguished from local numbers.  They are often the only type of number in the given country that can be used for sending and receiving messages.',
                    'National': 'National numbers are telephone numbers which are not region-specific and are designed to be reachable from an entire country at the same cost.'
                    //'Toll free': 'Toll free numbers are telephone numbers that are free of charge for the calling party.',
                };
                var types = supportedCountries.find(function (country) {
                    return country.country_code === ctrl.country_code;
                }).types.filter(function (type) {
                    return type !== 'Toll free';
                });
                this.options = (types || []).map(function (type) {
                    return {
                        id: type,
                        name: type,
                        description: typeMap[type] // Used by the number-type-choice template
                    };
                });
                if (this.options.length) {
                    ctrl.search_type = this.options[0].id;
                }
            }
        }, {
            id: 'search_capability',
            label: 'Capability',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            colClass: 'col-xs-12',
            options: [{
                id: 'voice',
                name: 'Voice Only'
            }, {
                id: 'sms',
                name: 'Voice & SMS'
            }]
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'VoIP Numbers',
            click: function click() {
                $state.go('voipnumbers');
            }
        }, {
            label: ctrl.sender.name ? ctrl.sender.name : 'Purchase a VoIP Number',
            active: true
        }];

        ctrl.footerBtns = [{
            title: 'Search',
            icon: 'fa-search',
            position: 'secondary',
            btnClass: 'btn-info pull-left',
            action: ctrl.searchNumbers
        }];
    }

    angular.module('GeckoEngage').controller('SettingsVoipNumbersCtrl', SettingsVoipNumbersCtrl).controller('SettingsVerifyVoipNumberCtrl', SettingsVerifyVoipNumberCtrl).controller('SettingsPurchaseVoipNumberCtrl', SettingsPurchaseVoipNumberCtrl);
})();