(function () {
    'use strict';

    function geckoHistory() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-history/gecko-history.html',
            scope: {
                history: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                $scope.Gecko = Gecko;
                var ctrl = this;
            }
        };
    }

    var templateBase = '/components/gecko-history/items/',
        itemTemplates = {
        activity: 'activity.html',
        attendanceactivity: 'attendanceactivity.html',
        call: 'call.html',
        message: 'message.html',
        note: 'note.html',
        response: 'response.html',
        subscriber: 'subscriber.html',
        changelog: 'changelog.html',
        chat_conversation: 'chat_conversation.html'
    };

    function geckoHistoryItem($state, $templateRequest, $compile) {
        return {
            restrict: 'A',
            scope: {
                item: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            link: function link(scope, iElement, iAttrs, ctrl) {
                scope.Gecko = Gecko;

                if (ctrl.item.model_name === 'activity' && ctrl.item.actionable_model_name === 'attendance') {
                    ctrl.item.model_name = 'attendanceactivity';
                }

                var templateUrl = templateBase + itemTemplates[ctrl.item.model_name];

                $templateRequest(templateUrl, true).then(function (template) {
                    // Set the template as the content of the element.
                    iElement.html(template);
                    // Compile it amd link it to the isolated scope.
                    $compile(iElement.contents())(scope);
                });

                ctrl.hasOccurrences = function (item) {
                    return item.occurrences && Number(item.occurrences) > 1;
                };

                ctrl.checkOccurrences = function (item) {
                    return item.updated_at !== item.created_at;
                };

                ctrl.itemAction = function (item) {
                    if (item.conversation_id && Gecko.able(Gecko.ABILITY_CHAT_CONVERSATIONS_VIEW)) {
                        window.location.href = '/admin/conversations/' + item.conversation_id;
                    } else if (item.event.type === Gecko.Event.TYPE_EVENT || item.event.type === Gecko.Event.TYPE_EVENT_LEGACY) {
                        $state.go('event.overview', { event_id: item.event.id });
                    } else if (item.event.type === Gecko.Event.TYPE_SESSION) {
                        return $state.go('session.overview', { event_id: item.event.parent_id, session_id: item.event.id }, { reload: true });
                    } else if (item.event.type === Gecko.Event.TYPE_SESSION_TIME) {
                        return $state.go('session.overview', { event_id: item.event.parent.parent_id, session_id: item.event.parent_id, session: item.event.id }, { reload: true });
                    }
                };
            }
        };
    }

    function geckoHistoryMeta() {
        return {
            restrict: 'E',
            scope: {
                date: '=',
                prefix: '@',
                user: '=',
                defaultUser: '@'
            },
            controller: function controller($scope) {
                if ($scope.user) {
                    $scope.user_words = 'by ' + $scope.user;
                } else if ($scope.defaultUser == 'anonymous') {
                    $scope.user_words = 'by Anonymous User';
                } else if ($scope.defaultUser == 'none') {
                    $scope.user_words = '';
                } else if ($scope.defaultUser) {
                    $scope.user_words = 'by System User';
                }
            },
            template: '<div class="gecko-history-list-meta text-muted">{{prefix}}{{date | formatDate}} {{user_words}}</div>'
        };
    }

    angular.module('GeckoEngage').directive('geckoHistory', geckoHistory).directive('geckoHistoryItem', geckoHistoryItem).directive('geckoHistoryMeta', geckoHistoryMeta);
})();