(function () {
    'use strict';

    function ContactEventsCtrl($scope, $state, $stateParams, $geckoModal, $dialogEventChangeStatus, $geckoChannel, $q, contact, attendances) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.attendances = attendances.toInstances();

        // Register channel
        $geckoChannel.register($state.$current.name, ['attendances'], ctrl);
        $geckoChannel.set('attendances', ctrl.attendances);

        var groupAttendances = function groupAttendances() {
            // Start a promise chain
            var startChain = function startChain(attendances) {
                var mapped = attendances.map(function (a) {
                    return angular.copy(a);
                });
                return $q(function (resolve) {
                    resolve(mapped);
                });
            };
            // Order by top level parent
            var orderByTopParent = function orderByTopParent(attendances) {
                var isParentEvent = function isParentEvent(attendance) {
                    return attendance.related_event.type === Gecko.Event.TYPE_EVENT || attendance.related_event.type === Gecko.Event.TYPE_EVENT_LEGACY;
                };
                var isChildOf = function isChildOf(parent) {
                    return function (attendance) {
                        return parent.related_event.id == attendance.related_event.parent_id;
                    };
                };

                return attendances.filter(isParentEvent).reduce(function (ordered, attendance) {
                    var sessions = attendances.filter(isChildOf(attendance));
                    var groupedSessions = sessions.reduce(function (ordered, sessionAttendance) {
                        var sessionTimes = attendances.filter(isChildOf(sessionAttendance));
                        return ordered.concat(sessionAttendance, sessionTimes);
                    }, []);
                    return ordered.concat(attendance, groupedSessions);
                }, []);
            };
            // Modify each Session Time title to include it's Sessions title
            var applySessionTitle = function applySessionTitle(attendances) {
                return attendances.map(function (attendance) {
                    var parent = attendances.find(function (a) {
                        return a.related_event.id == attendance.related_event.parent_id;
                    });
                    if (parent && parent.related_event.type === Gecko.Event.TYPE_SESSION) {
                        attendance.related_event.title = parent.related_event.title + ': ' + attendance.related_event.title;
                    }
                    return attendance;
                });
            };
            // Filter out new type sessions
            var removeNewSessions = function removeNewSessions(attendances) {
                return attendances.filter(function (a) {
                    return a.related_event.type !== Gecko.Event.TYPE_SESSION;
                });
            };
            // Remove any duplicates sometimes introduced by the Ordering
            var removeDuplicates = function removeDuplicates(attendances) {
                return GeckoUI.dedupeBy(attendances, 'related_event.id');
            };

            startChain(angular.copy(ctrl.attendances)) // Grab the attendances
            .then(orderByTopParent) // Order the attendances so that Parent Events are followed by each Session and each Session is followed by it's Session Times
            .then(applySessionTitle) // Add Session Titles to any Session Times
            .then(removeNewSessions) // Remove new type Sessions so they don't appear on the display
            .then(removeDuplicates) // Remove any duplicates which might occur
            .then(function (attendances) {
                ctrl.attendances = [].concat(attendances);
            }) // Update the attendances to display
            .catch(function (err) {
                throw err;
            });
        };
        groupAttendances();

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'related_event.nice_title',
            colClass: 'col-xs-4',
            render: function render(value, col, attendance) {
                if (attendance.related_event.type === Gecko.Event.TYPE_EVENT) {
                    return attendance.related_event.nice_title;
                }
                return attendance.related_event.title;
            },
            isNested: function isNested(_title, _col, attendance) {
                return attendance.related_event.parent_id;
            }
        }, {
            title: 'Status',
            key: 'status',
            colClass: 'col-xs-4',
            status_styles: Gecko.Attendance.status_titles
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date added',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-4'
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(row) {
                if (!row.related_event.parent_id) {
                    return $state.go('event.overview', { event_id: row.related_event.id });
                }
                return;
            }

            // Table row dropdown
        };ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Change status',
            action: function action(attendance) {
                $dialogEventChangeStatus(attendance);
            }
        }, {
            title: 'View RSVP page',
            action: function action(attendance) {
                return window.open(attendance.rsvp, '_blank');
            },
            hideWhen: function hideWhen(attendance) {
                var isEnabled = GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_BOOKING_SELF_SERVICE),
                    isParentEvent = (attendance.related_event || {}).type === Gecko.Event.TYPE_EVENT;
                return !isEnabled || !isParentEvent;
            }
        }, {
            title: 'Add to Session',
            hideWhen: function hideWhen(attendance) {
                return !Gecko.able(Gecko.ABILITY_ATTENDANCES_CREATE) || attendance.related_event && attendance.related_event.parent_id;
            },
            action: function action(attendance) {
                $geckoModal.eventAddContactModal(attendance.contact_id, false, attendance.event_id).result.then(function () {
                    GeckoUI.messenger.success('Contact added to session');
                    $state.go($state.$current.name, {}, { reload: true });
                });
            }
        }, {
            render: function render(cols, attendance) {
                return 'Remove from ' + (attendance.related_event && attendance.related_event.parent_id ? 'session' : 'event');
            },
            action: function action(attendance, index) {
                GeckoUI.dialog.confirm('Are you sure you want to remove this contact from this event?', function (value) {
                    if (value) {
                        var hasSessions = attendance.related_session_attendances && attendance.related_session_attendances.length;

                        var _attendance = angular.copy(attendance);
                        // Change attendance status
                        _attendance.status = Gecko.Attendance.REMOVED;

                        _attendance.save().then(function () {
                            if (hasSessions) {
                                $state.go($state.$current.name, $stateParams, { reload: true });
                            } else {
                                ctrl.attendances.splice(index, 1);
                            }
                            GeckoUI.messenger.success('Contact removed from event');
                            $scope.$digest();
                        });
                    }
                });
            },
            hideWhen: function hideWhen(attendance) {
                return attendance.status === Gecko.Attendance.REMOVED;
            }
        }];

        // Add to event
        ctrl.headerBtns = [{
            title: 'Add to event',
            icon: 'fa-plus',
            action: function action() {
                $geckoModal.eventAddContactModal(ctrl.contact, ctrl.attendances).result.then(function (attendance) {
                    //ctrl.attendances.unshift(attendance);
                    GeckoUI.messenger.success('Contact added to event');
                    $state.go($state.$current.name, $stateParams, { reload: true });
                });
            }
        }];
    }

    angular.module('GeckoEngage').controller('ContactEventsCtrl', ContactEventsCtrl);
})();