(function () {

    function OrganisationModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('organisationAddModal', function (_target, _isMassAction) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/organisation/modal.html',
                controller: 'OrganisationAddModalCtrl',
                resolve: {
                    target: function target() {
                        return _target;
                    },
                    isMassAction: function isMassAction() {
                        return _isMassAction || false;
                    },
                    data: function data(geckoDataService) {
                        return geckoDataService.fetch(['organisation_types']);
                    }
                }
            };
        });
    }

    function OrganisationAddModalCtrl($modalInstance, $state, $stateParams, $geckoSocket, asyncOptions, target, isMassAction, data, handleMassAction) {
        var ctrl = this;
        ctrl.target = target;
        ctrl.organisationTypes = data.organisation_types;
        ctrl.types = [{
            key: 'new',
            title: 'New Organisation'
        }, {
            key: 'existing',
            title: 'Existing Organisation'
        }];
        ctrl.values = {};
        ctrl.values.type = 'existing';

        // Field list
        ctrl.fields = [{
            id: 'type',
            label: 'Type',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.types,
            optionsKey: 'key',
            optionsLabelKey: 'title',
            colClass: 'col-xs-12',
            noBlank: true
        }, {
            id: 'organisation',
            label: 'Organisation',
            description: 'Add contacts to new or existing organisations. You can manage your organisations <a ui-sref="organisations">here</a>.',
            type: Gecko.Field.TYPE_SELECT,
            options: [],
            getOptions: asyncOptions.create(new Gecko.Organisation().orderBy('title').rfields({ organisation: ['title', 'address'] })),
            orderBy: 'title',
            optionsKey: 'id',
            optionsLabelKey: 'title',
            colClass: 'col-xs-12',
            choiceTemplate: 'organisation-choice',
            hideWhen: function hideWhen() {
                return ctrl.values.type !== 'existing';
            }
        }, {
            id: 'organisation_type',
            label: 'Organisation Type',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'col-xs-12',
            options: ctrl.organisationTypes,
            orderBy: 'title',
            optionsKey: 'id',
            optionsLabelKey: 'title',
            hideWhen: function hideWhen() {
                return ctrl.values.type !== 'new';
            }
        }, {
            id: 'organisation_title',
            label: 'Organisation Name',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return ctrl.values.type !== 'new';
            }
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Add',
            icon: 'fa-plus',
            btnClass: 'btn-primary',
            action: function action() {

                // If this is a new entry we need to go this route
                if (ctrl.values.type === 'new') {
                    // Lets see if both a name and a type is set
                    if (ctrl.values.organisation_title && ctrl.values.organisation_type) {
                        var organisation = new Gecko.Organisation();
                        organisation.title = ctrl.values.organisation_title;
                        organisation.organisation_type_id = ctrl.values.organisation_type;

                        // Then we save it up. If it suceeds we need to add it to the contact
                        organisation.save().then(function (response) {
                            ctrl.addContactsToOrganisation(response.id);
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });

                        // Otherwise we just want to error and keep the modal open
                    } else {
                        return GeckoUI.messenger.error('Please select an Organisation Type and enter an Organisation Name.');
                    }

                    // Otherwise we need to just add the existing organisation to a contact
                } else {
                    ctrl.addContactsToOrganisation(ctrl.values.organisation);
                }
            }
        }];

        ctrl.addContactsToOrganisation = function (organisation_id) {

            // Add single contact to event
            new Gecko.Contact().massAction({ model: 'contact_ids', contact_ids: ctrl.target }, Gecko.Workflow.Actions.enrolment(organisation_id)).then(function (data) {
                // Handle mass actions
                if (isMassAction) handleMassAction(data);
                // Close the modal
                $modalInstance.close();
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        };
    }

    angular.module('GeckoEngage').config(OrganisationModals).controller('OrganisationAddModalCtrl', OrganisationAddModalCtrl);
})();