/* global Twilio Gecko GeckoUI */
(function () {
    'use strict';

    function $geckoVoip($rootScope, $timeout, $geckoSocket, $geckoQueue, $geckoSounds, $geckoWatch) {

        var _this = {

            enabled: false,

            isReady: false,
            hasStarted: false,
            hasEnded: false,

            chat_conn: null,
            customer_conn: null,

            incomingCallId: null,
            isIncoming: false,

            isReconnecting: false,

            chat_device: null,
            customer_device: null,

            // Check config and init VOIP
            activate: function activate() {
                if (Twilio && (Gecko.User.voipOutboundEnabled() || Gecko.User.voipInboundEnabled())) {
                    _this.enabled = true;
                } else {
                    _this.enabled = false;
                }
            },

            // VOIP token setup
            refresh: function refresh(prefixes) {
                if (!_this.enabled) return false;
                return new Gecko.BaseModel().call('/twilio/session', null, null, true).then(function (data) {
                    if (!Array.isArray(prefixes)) prefixes = [prefixes];

                    prefixes.forEach(function (prefix) {
                        var tokenKey = prefix + '_token';
                        if (data.hasOwnProperty(tokenKey) && data[tokenKey]) {
                            _this[prefix + '_device'] = _this.initDevice(data[tokenKey], prefix);
                        }
                    });
                })
                // eslint-disable-next-line no-console
                .catch(console.error);
            },

            destroy: function destroy() {
                if (!_this.enabled) return false;
                // Destroy device
                if (_this.chat_device) _this.chat_device.destroy();
                if (_this.customer_device) _this.customer_device.destroy();

                // Disconnect device
                if (_this.chat_conn) _this.chat_device.disconnectAll();
                if (_this.customer_conn) _this.customer_device.disconnectAll();
            },

            // Start VOIP call
            start: function start(callId, useChatDevice) {
                if (!_this.enabled) return false;

                var deviceName = (useChatDevice ? 'chat' : 'customer') + '_device';

                return _this[deviceName].connect({
                    'account_id': Gecko.account.routing_id,
                    'call_id': callId
                });
            },

            // End VOIP call
            end: function end() {
                if (!_this.enabled) return false;
                if (_this.chat_device) _this.chat_device.disconnectAll();
                if (_this.customer_device) _this.customer_device.disconnectAll();
            },

            reconnect: function reconnect(callId, useChatDevice) {
                // Reconnect to VOIP call
                return _this.start(callId, useChatDevice);
            },

            incoming: function incoming(call, isTransfering) {
                // Dont allow WELCOME calls to be processed
                if (call && call.status && call.status === Gecko.Call.WELCOME) return false;
                if (call && call.call_status && call.call_status === Gecko.Call.WELCOME) return false;

                // Check if user can recieve incoming call
                if (_this.isIncoming || !Gecko.user.isAvailableForCall()) {
                    return false;
                }

                console.log('--- Incoming connection ---'); // eslint-disable-line no-console

                // Set incoming properties
                _this.isIncoming = true;

                _this.incomingCallId = call.call_id || call.id;

                _this.isTransfering = isTransfering ? true : false;

                // Fire VOIP incoming event
                $rootScope.$broadcast('voip:incoming');
                // Bind event for call being abandoned
                _this._cancelled = $geckoSocket.registerEvent('call:updated', _this.cancelled, true);
            },

            cancel: function cancel(recieveIncoming) {
                console.log('--- Call connection was cancelled ---'); // eslint-disable-line no-console
                // Reset VOIP connection object
                _this.chat_conn = null;
                _this.customer_conn = null;
                // Reset incoming properties
                _this.isIncoming = false;
                // Fire VOIP cancelled event
                $rootScope.$broadcast('voip:cancelled');
                // End Twilio session
                if (_this.chat_device) _this.chat_device.destroy();
                if (_this.customer_device) _this.customer_device.destroy();
                // Connect to next in the queue (if required)
                if (recieveIncoming) _this.accept = true;
                // Unbind for call being abandonned
                _this._cancelled();
            },

            cancelled: function cancelled(call) {
                // If pusher id does not match incoming voip id just ignore
                // If (call.id !== _this.incomingCallId) return false;

                if (call) {
                    // Call was abandoned by caller
                    var callAbandonedByCaller = _this.incomingCallId === call.id && call.status === Gecko.Call.ABANDONED;
                    // Call was answered by another user
                    var callAnsweredByOtherUser = _this.incomingCallId === call.id && [Gecko.Call.QUEUED, Gecko.Call.HOLD].indexOf(call.status) === -1 && call.user_id && call.user_id !== Gecko.user.id;
                    // Call is an incomin transfer
                    var callIsBeingTransfered = _this.isTransfering && call && _this.incomingCallId === call.id && [Gecko.Call.HOLD, Gecko.Call.STARTED].indexOf(call.status) !== -1;
                }

                if (!callIsBeingTransfered && (call === undefined || callAbandonedByCaller || callAnsweredByOtherUser)) {

                    // Run the cancel function
                    _this.cancel(callAbandonedByCaller || callAnsweredByOtherUser);

                    // User feedback messages (only fire if call queue has is empty)
                    if (callAnsweredByOtherUser || callAbandonedByCaller) {
                        $timeout(function () {
                            if ($geckoQueue.getLength() === 0) {
                                if (callAnsweredByOtherUser) GeckoUI.messenger.error('Call answered by other user.');
                                if (callAbandonedByCaller) GeckoUI.messenger.error('Caller has ended their call.');
                            }
                        }, 100);
                    }
                }

                // DO NOT REMOVE, THIS IS POSSIBLE IDEA GOING FORWARD WITH PHASE2
                // $timeout(function(){
                //     If ($geckoQueue.getLength() === 0){
                //         //Access the situation
                //         If (call){
                //             Var callAbandonedByCaller = _this.incomingCallId === call.id && call.status === Gecko.Call.ABANDONED;
                //             Var callAnsweredByOtherUser = _this.incomingCallId === call.id && [Gecko.Call.QUEUED, Gecko.Call.HOLD].indexOf(call.status) === -1 && call.user_id && call.user_id !== Gecko.user.id;
                //             Var callIsBeingTransfered = _this.isTransfering && call && _this.incomingCallId === call.id && [Gecko.Call.HOLD, Gecko.Call.STARTED].indexOf(call.status) !== -1;
                //             Var callIsEndOfQueue = call.id !== _this.incomingCallId;
                //         }

                //         If (!callIsBeingTransfered && (call === undefined || callAbandonedByCaller || callAnsweredByOtherUser || callIsEndOfQueue) ){

                //             //Cancel incoming config/setup
                //             _this.cancel(callAbandonedByCaller || callAnsweredByOtherUser);

                //             //User feedback messages
                //             If (callAnsweredByOtherUser) GeckoUI.messenger.error('Call answered by other user.');
                //             If (callAbandonedByCaller)   GeckoUI.messenger.error('Caller has ended their call.');

                //         }
                //     }
                // }, 100);
            },

            isMuted: function isMuted() {
                if (_this.chat_conn) return this.chat_conn.isMuted();
                if (_this.customer_conn) return this.customer_conn.isMuted();
                return false;
            },

            toggleMute: function toggleMute() {
                if (_this.chat_conn) this.chat_conn.mute(!this.chat_conn.isMuted());
                if (_this.customer_conn) this.customer_conn.mute(!this.customer_conn.isMuted());
                return false;
            },

            pressDigit: function pressDigit(digit) {
                if (_this.chat_conn) return this.chat_conn.sendDigits(digit);
                if (_this.customer_conn) return this.customer_conn.sendDigits(digit);
                return false;
            },

            connectionExists: function connectionExists() {
                return _this.chat_conn || _this.customer_conn;
            },

            nextInTheQueue: function nextInTheQueue() {
                // Check package
                if (!Gecko.User.voipInboundEnabled()) return false;

                try {
                    if ($geckoQueue.length && !_this.isIncoming) {
                        return new Gecko.Call().inboundNext().then(function (call) {
                            _this.incoming(call);
                        });
                    }
                } catch (e) {} // eslint-disable-line no-empty
            },

            // Voip setup init
            initDevice: function initDevice(token, prefix) {
                var device = new Twilio.Device();
                device.setup(token, { disableAudioContextSounds: true, debug: true });

                // Check VOIP service is present
                if (Twilio == undefined || !Twilio) console.log('--- VOIP service not found ---'); // eslint-disable-line no-console
                if (Twilio == undefined || !Twilio) return;

                // Ready
                device.on('ready', function (device) {
                    if (!_this.enabled) return false;
                    console.log('--- ' + prefix + ' Device ready ---'); // eslint-disable-line no-console
                    // Set VOIP status(es)
                    _this.isReady = true;
                    // Fire VOIP ready event
                    $rootScope.$broadcast('voip:ready');
                    // Sounds
                    device.audio.outgoing(true);
                    device.audio.incoming(true);
                    device.audio.disconnect(true);
                });

                // Error
                device.on('error', function (error) {
                    if (!_this.enabled) return false;
                    console.log('--- ' + prefix + ' Device Error: ' + error.message + ' ---'); // eslint-disable-line no-console
                    // Fire VOIP error event
                    $rootScope.$broadcast('voip:error');
                });

                // Connect
                device.on('connect', function (connection) {
                    if (!_this.enabled) return false;
                    console.log('--- Successfully established call [' + prefix + ' Device] ---'); // eslint-disable-line no-console
                    // Set VOIP connection object
                    _this[prefix + '_conn'] = connection;
                    // Set VOIP status(es)
                    _this.hasStarted = true;
                    _this.isIncoming = false;
                    // Fire VOIP started event
                    $rootScope.$broadcast('voip:started');
                });

                // Disconnect
                device.on('disconnect', function () {
                    if (!_this.enabled) return false;
                    if (_this[prefix + '_conn']) {
                        console.log('--- Disconnected from call [' + prefix + ' Device] ---'); // eslint-disable-line no-console
                        // Reset VOIP connection object
                        _this[prefix + '_conn'] = null;
                        // Set VOIP status(es)
                        _this.hasStarted = false;
                        _this.hasEnded = true;
                        // _this.isReconnecting = false;
                        // Fire VOIP ended event
                        $rootScope.$broadcast('voip:ended');
                        // End Twilio session
                        _this[prefix + '_device'].destroy();
                    }
                });

                // Accept incoming right away
                device.on('incoming', function (connection) {
                    if (!Gecko.User.voipInboundEnabled() || !_this.enabled) return false;
                    if (_this.isIncoming) {
                        // Set VOIP connection object
                        _this[prefix + '_conn'] = connection;
                        // Accept VOIP connection
                        _this[prefix + '_conn'].accept();
                    }
                });

                // Offline (connection lost)
                device.on('offline', function () {
                    console.log('--- Call connection destroyed [' + prefix + ' Device] ---'); // eslint-disable-line no-console
                    _this.isReady = false;
                    // Re-initialise VOIP
                    _this.refresh(prefix);
                });

                return device;
            },

            init: function init() {
                // First load
                if (Gecko.user) {
                    // Enable/Disable VOIP
                    _this.activate();
                    // Refresh VOIP setup
                    _this.refresh(['chat', 'customer']);

                    // Incoming call (from the queue)
                    $geckoSocket.registerEvent('voip:call', function (data) {
                        if (!Gecko.User.voipInboundEnabled() || !_this.enabled || _this.chat_conn || _this.customer_conn) return false;
                        // Check current user is available to take call
                        if (data.user_ids && data.user_ids.indexOf(Gecko.user.id) !== -1) {
                            _this.incoming(data);
                        }
                    }, true);

                    // Reset everything if VOIP client is not Ready
                    $geckoWatch(_this, 'isReady', function (value) {
                        // Reset everything if false
                        if (!value) {
                            _this.hasStarted = false;
                            _this.hasEnded = false;
                            _this.isIncoming = false;
                            // Stop all sounds
                            $geckoSounds.stopAll();
                        }
                    });
                    // Reset incomingCallId on isIncoming
                    $geckoWatch(_this, 'isIncoming', function (value) {
                        // Reset call if if not incoming
                        if (!value) {
                            _this.incomingCallId = null;
                            _this.isTransfering = null;
                        }
                        // Play/stop incoming sound
                        $geckoSounds.toggle('incoming', value);
                    });
                    // Handle incoming calls after doing other call stuff
                    $geckoWatch(_this, 'accept', function (value) {
                        // Cancel incoming or get next one
                        if (value) {
                            _this.nextInTheQueue();
                        } else {
                            _this.cancelled();
                        }
                    });

                    // Dont allow user navigate away from the app when VoiP call is active
                    window.onbeforeunload = function (event) {
                        if (_this.hasStarted && !_this.hasEnded) {
                            event.preventDefault();
                            return 'Leaving this app will end your VoiP call, do you want to proceed?';
                        }
                    };
                }

                Gecko.on('available', function () {
                    // Enable/Disable VOIP
                    _this.activate();
                    // Refresh VOIP setup
                    _this.refresh(['chat', 'customer']);
                });
                // Deactivate when unavailable
                Gecko.on('unavailable', _this.destroy);
            }

        };

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoVoip', $geckoVoip);
})();