(function () {
  function RefreshTokenModal($geckoModalProvider) {
    $geckoModalProvider.modalAdd('refreshTokenModal', function (_data) {
      return {
        controllerAs: 'ctrl',
        templateUrl: '/components/_modals/integrations/refresh-token-modal.html',
        controller: 'RefreshTokenModalCtrl',
        resolve: {
          data: function data() {
            return _data;
          }
        }
      };
    });
  }

  function RefreshTokenModalCtrl($state, $stateParams, $modalInstance, data) {
    var ctrl = this;
    ctrl.data = data;
    ctrl.alertMessage = '';

    ctrl.fields = [{
      id: 'client_id',
      label: 'App ID',
      type: Gecko.Field.TYPE_TEXT,
      colClass: 'col-md-12',
      required: true
    }, {
      id: 'client_secret',
      label: 'Password key',
      type: Gecko.Field.TYPE_PASSWORD,
      colClass: 'col-md-12',
      required: true
    }, {
      id: 'application_user',
      label: 'Connect as application user?',
      type: Gecko.Field.TYPE_RADIO,
      colClass: 'col-md-12',
      options: [{
        value: true,
        label: 'Yes'
      }, {
        value: false,
        label: 'No'
      }],
      optionsKey: 'value',
      optionsLabelKey: 'label'
    }, {
      id: 'tenant_id',
      label: 'Tenant ID',
      type: Gecko.Field.TYPE_TEXT,
      colClass: 'col-md-12',
      required: true,
      hideWhen: function hideWhen() {
        return !ctrl.data.application_user;
      },
      showWhen: function showWhen() {
        return ctrl.data.application_user;
      }
    }];

    ctrl.footerBtns = [{
      title: 'Connect with Dynamics',
      icon: 'fa-check',
      btnClass: 'btn-primary',
      hideWhen: function hideWhen() {
        return ctrl.data.application_user;
      },
      showWhen: function showWhen() {
        return !ctrl.data.application_user;
      },
      disabledWhen: function disabledWhen() {
        return !ctrl.data.client_id || !ctrl.data.client_secret;
      },
      action: function action() {
        $modalInstance.close(data);
      }
    }, {
      title: 'Save',
      icon: 'fa-check',
      btnClass: 'btn-primary',
      hideWhen: function hideWhen() {
        return !ctrl.data.application_user;
      },
      showWhen: function showWhen() {
        return ctrl.data.application_user;
      },
      disabledWhen: function disabledWhen() {
        return !ctrl.data.client_id || !ctrl.data.client_secret || !ctrl.data.tenant_id;
      },
      action: function action() {
        $modalInstance.close(data);
      }
    }];
  }

  angular.module('GeckoEngage').config(RefreshTokenModal).controller('RefreshTokenModalCtrl', RefreshTokenModalCtrl);
})();