(function () {
    'use strict';

    function asyncLoadingIndicator($compile) {

        return {
            restict: 'A',
            require: '^uiSelect',

            link: function link(scope, iElement) {
                var loading = angular.element('<li class="select-loading" ng-if="$select.loadingOptions"><img src="https://images.geckoform.com/logo/gecko-5E9E0A.svg"></li>');
                iElement.prepend(loading);

                $compile(loading)(scope);
            }
        };
    }

    angular.module('GeckoEngage').directive('asyncLoadingIndicator', asyncLoadingIndicator);
})();