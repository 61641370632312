(function () {
    'use strict';

    function geckoAccountSwitcher() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-account-switcher/gecko-account-switcher.html',
            scope: {},
            controllerAs: 'ctrl',
            controller: function controller(Gecko) {
                if (Gecko.user.related_group.type != Gecko.Group.SUPER || !Gecko.account) return false;
                var ctrl = this;

                // Set account id
                ctrl.routing_account_id = Gecko.account.routing_id;

                ctrl.switchAccount = function () {
                    // Check for account id
                    if (!ctrl.routing_account_id) return false;

                    // Clear app data cache
                    Gecko.Token.appData.clearCache();

                    ctrl.beforeSwitchAccount().then(function () {
                        new Gecko.BaseModel().call('/admin/switch_account/' + ctrl.routing_account_id, 'GET', null, true).then(function (tokenData) {
                            var tm = TokenManager.fromLocalStorage();
                            tm.switchToSupportToken(tokenData);

                            new Promise(function (resolve) {
                                // Refresh accounts across multiple tabs/windows
                                window.localStorage.setItem('gecko_account_switched', ctrl.routing_account_id);
                                resolve();
                            }).then(function () {
                                window.location.reload();
                            });
                        }).catch(GeckoUI.messenger.error);
                    }).catch(function (err) {
                        GeckoUI.messenger.error('There was an error refreshing your current token');
                    });
                };

                ctrl.beforeSwitchAccount = function () {
                    return new Promise(function (resolve, reject) {
                        // Restore the master user so the audit trail doesnt
                        // show "support user switched"
                        if (TokenManager.hasPreviousAccountToken()) {
                            var tm = TokenManager.fromLocalStorage();
                            tm.restorePreviousAccount();

                            // Keep the master user alive otherwise they may get logged
                            // out unexpectedly when switching accounts again
                            TokenManager.refreshToken().then(function () {
                                return resolve();
                            }).catch(function () {
                                return reject();
                            });
                        } else {
                            resolve();
                        }
                    });
                };

                ctrl.switchBack = function () {
                    // Check for account id
                    if (!ctrl.routing_account_id) return false;

                    // Clear app data cache
                    Gecko.Token.appData.clearCache();

                    // Switch account
                    var tm = TokenManager.fromLocalStorage();
                    tm.restorePreviousAccount();

                    new Promise(function (resolve) {
                        // Refresh accounts across multiple tabs/windows
                        window.localStorage.removeItem('gecko_account_switched');
                        resolve();
                    }).then(function () {
                        window.location.reload();
                    });
                };

                ctrl.back = !!localStorage.old_token || TokenManager.hasPreviousAccountToken();

                // Get initial account
                ctrl.accounts = [{
                    id: Gecko.account.routing_id,
                    name: Gecko.account.name
                }];

                ctrl.loadAccounts = function () {
                    // Dont bother if already loaded
                    if (ctrl.accounts.length > 1) return;
                    // Get all accounts
                    new Gecko.Account().rfields({ 'account': ['name'] }).orderBy('name').perPage(1000).get().then(function (accounts) {
                        ctrl.accounts = accounts.toArray();
                    });
                };
                // Load accounts
                ctrl.loadAccounts();
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoAccountSwitcher', geckoAccountSwitcher);
})();