(function () {
    'use strict';

    function AccountRoute($stateProvider) {
        $stateProvider.state('settingsaccount', {
            url: "/settings/account",
            templateUrl: "/modules/account/views/view.a6435dd0.html",
            controller: 'SettingsAccountCtrl',
            controllerAs: 'ctrl',
            page_title: 'Settings',
            requires: [Gecko.ABILITY_ADMIN],
            redirectKey: 'module_account',
            resolve: {
                account: function account() {
                    return new Gecko.Account().include('sender', 'sms_sender', 'call_sender').rfields({ sender: ['name', 'email', 'verified'] }).get(Gecko.account.id);
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(AccountRoute);
})();