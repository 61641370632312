(function () {
    'use strict';

    function geckoCallStartCard($geckoVoip) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-start-card.html',

            require: '^geckoCallPane',

            link: function link(scope, iElement, iAttrs, ctrl) {

                ctrl.numbers.forEach(function (number) {
                    // Check number formatting
                    if (!number.cleaned && number.original) number.cleaned = number.original;
                    // Set pretty label (if number is from a contact field)
                    if (number.contact_field_label) {
                        number.original = number.contact_field_label + ' <' + number.cleaned + '>';
                    }
                });

                var flattenNumber = function flattenNumber(numberString) {
                    if (!numberString) return '';
                    return String(numberString).replace(/[\(\)\s\-\+]/g, '');
                };

                // Initalise number from string
                if (ctrl.numberString && ctrl.numbers && ctrl.numbers.length) {
                    angular.forEach(ctrl.numbers, function (number) {
                        if (flattenNumber(ctrl.numberString) == flattenNumber(number.original)) {
                            return ctrl.numberId = number.id;
                        }
                    });
                    // Check for campaign and a specified contact field
                } else if (ctrl.campaign && ctrl.campaign.contact_field_id && ctrl.numbers.find(function (n) {
                    return n.contact_field_id === ctrl.campaign.contact_field_id;
                })) {
                    ctrl.numberId = ctrl.numbers.find(function (n) {
                        return n.contact_field_id === ctrl.campaign.contact_field_id;
                    }).id;
                    // Check for prefered number
                } else if (!ctrl.numberId && ctrl.numbers && ctrl.numbers.length) {

                    if (ctrl.contact.preferred_call && GeckoUI.gobk(ctrl.numbers, 'id', ctrl.contact.preferred_call)) {
                        ctrl.numberId = ctrl.contact.preferred_call;
                    } else {
                        ctrl.numberId = ctrl.numbers[0].id;
                    }
                }

                // Card fields
                ctrl.startCardFields = [{
                    label: 'Number',
                    key: 'numberId',
                    options: ctrl.numbers,
                    optionsKey: 'id',
                    optionsLabelKey: 'original',
                    type: Gecko.Field.TYPE_SELECT,
                    noBlank: true,
                    colClass: 'none',
                    disabledWhen: function disabledWhen() {
                        return ctrl.numbers.length < 2;
                    },
                    description: Gecko.User.hasOutboundOrInbound() ? 'Experiencing issues? <a ui-sref="voiptest">Test your VoIP connection</a>.' : ''
                }];

                // Card buttons
                ctrl.startCardBtns = [{
                    safeMode: true,
                    preset: 'save',
                    title: 'Start Call',
                    icon: 'fa-play',
                    action: ctrl.startCall
                }, {
                    title: 'Set Default',
                    icon: 'fa-phone',
                    position: 'secondary',
                    safeMode: true,
                    action: function action() {
                        ctrl.contact.preferred_call = ctrl.numberId;
                        ctrl.contact.save().then(function (contact) {
                            GeckoUI.messenger.success('Preferred number updated.');
                            ctrl.contact.preferred_call = contact.preferred_call;
                        }, function () {
                            GeckoUI.messenger.error('Preferred number could not be updated.');
                        });
                    },
                    hideWhen: function hideWhen() {
                        if ($geckoVoip.enabled) {
                            return !$geckoVoip.isReady || ctrl.numbers.length < 2 || ctrl.contact.preferred_call == ctrl.numberId;
                        }
                        return ctrl.numbers.length < 2 || ctrl.contact.preferred_call == ctrl.numberId;
                    }
                }];
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallStartCard', geckoCallStartCard);
})();