
(function () {
    'use strict';

    function geckoAddress(geckoOptions) {
        return {
            restrict: 'A',
            templateUrl: '/components/gecko-address/gecko-address.html',

            scope: {
                field: '=',
                value: '=',
                onChange: '&',
                displayMode: '='
            },

            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {

                var ctrl = this;

                if (ctrl.field.settings && Number(ctrl.field.settings.auto_populate) === 1 && !ctrl.displayMode) {
                    ctrl.addressSearchEnabled = true;
                } else {
                    ctrl.addressSearchEnabled = false;
                }

                function convertOptions(options) {

                    if (options == '[]') return;
                    // Pre-process options
                    var _options = [];

                    angular.forEach(options, function (value) {
                        _options.push(value.value);
                    });

                    return _options;
                }

                var countryOverwriteOptions = [];

                ctrl.pickCountry = function (options, changed) {
                    // Only do, if overwrite list is provided
                    if (!options || !options.length) {
                        ctrl.addressRegions = false;
                        if (changed) (ctrl.value || {})['state'] = '';
                        return;
                    }

                    var value = (ctrl.value || {})['country'];

                    if (value) {
                        var regions = options.find(function (v) {
                            return v.value == value;
                        });
                        if (regions.options && regions.options.length) {
                            ctrl.addressRegions = convertOptions(regions.options);
                            if (changed) (ctrl.value || {})['state'] = '';
                        } else {
                            ctrl.addressRegions = false;
                            if (changed) (ctrl.value || {})['state'] = '';
                        }
                    } else {
                        ctrl.addressRegions = false;
                        if (changed) (ctrl.value || {})['state'] = '';
                    }
                };

                ctrl.addressCountries = [];

                setTimeout(function () {
                    if (ctrl.field.option_id !== 0 && ctrl.field.option_id !== null && ctrl.field.option_id !== undefined) {
                        geckoOptions.get(ctrl.field.option_id).then(function (option) {
                            countryOverwriteOptions = option;
                            // Set countries options
                            ctrl.addressCountries = convertOptions(countryOverwriteOptions.values);
                            // Set regions dropdown
                            ctrl.pickCountry(countryOverwriteOptions.values);
                        });
                    } else if (ctrl.field.options) {
                        ctrl.addressCountries = ctrl.field.options;
                    } else {
                        ctrl.countryFallbackList = true;
                        ctrl.addressCountries = countries;
                        ctrl.pickCountry();
                    }
                });

                ctrl._onChange = function () {
                    if (ctrl.onChange) ctrl.onChange();

                    if (!ctrl.countryFallbackList) {
                        ctrl.pickCountry(countryOverwriteOptions.values, true);
                    }
                };

                // Check country value is in dropdown list
                ctrl.countryIsInDropdown = function (country) {
                    return ctrl.addressCountries.indexOf(country) !== -1;
                };

                // Check state value is in dropdown list
                ctrl.regionIsInDropdown = function (region) {
                    return (ctrl.addressRegions || []).indexOf(region) !== -1;
                };

                ctrl.showRegionDropdown = function () {
                    return ctrl.value && !ctrl.value['state'] && ctrl.addressRegions && ctrl.addressRegions.length || ctrl.value && ctrl.value['state'] && ctrl.regionIsInDropdown(ctrl.value['state']);
                };

                // Hide (unrequested fields)
                ctrl.showField = {
                    street_address: !ctrl.field.format || angular.isArray(ctrl.field.format) && ctrl.field.format.indexOf('street_address') !== -1,
                    address_line_2: !ctrl.field.format || angular.isArray(ctrl.field.format) && ctrl.field.format.indexOf('address_line_2') !== -1,
                    city: !ctrl.field.format || angular.isArray(ctrl.field.format) && ctrl.field.format.indexOf('city') !== -1,
                    state: !ctrl.field.format || angular.isArray(ctrl.field.format) && ctrl.field.format.indexOf('state') !== -1,
                    postal_code: !ctrl.field.format || angular.isArray(ctrl.field.format) && ctrl.field.format.indexOf('postal_code') !== -1,
                    country: !ctrl.field.format || angular.isArray(ctrl.field.format) && ctrl.field.format.indexOf('country') !== -1
                };

                if (ctrl.addressSearchEnabled) {

                    var script = document.createElement("script");
                    script.src = "https://services.postcodeanywhere.co.uk/js/captureplus-2.30.min.js?key=" + ctrl.field.settings.auto_populate_key;
                    script.type = "text/javascript";
                    document.getElementsByTagName("head")[0].appendChild(script);

                    var link = document.createElement("link");
                    link.href = "https://services.postcodeanywhere.co.uk/css/captureplus-2.30.min.css?key=" + ctrl.field.settings.auto_populate_key;
                    link.type = "text/css";
                    link.rel = "stylesheet";
                    document.getElementsByTagName("head")[0].appendChild(link);

                    setTimeout(function () {
                        var fields = [{ element: "_address_search", field: "", mode: pca.fieldMode.SEARCH }];

                        try {
                            var control = new pca.Address(fields, { key: ctrl.field.settings.auto_populate_key });
                            control.load();

                            control.listen("populate", function (address) {

                                if (!ctrl.value) ctrl.value = {};
                                ctrl.value.street_address = address.Line1;
                                ctrl.value.address_line_2 = address.Line2;
                                ctrl.value.city = address.City;
                                ctrl.value.state = address.Province;
                                ctrl.value.postal_code = address.PostalCode;
                                ctrl.value.country = address.CountryName;

                                if (ctrl.onChange) {
                                    ctrl.onChange();
                                }

                                ctrl.$digest();
                            });
                        } catch (err) {}
                    }, 2000);
                }
            }
        };
    }

    angular.module('GeckoEngage').directive("geckoAddress", geckoAddress);
})();