(function () {

    function InputModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('inputModal', function (_title, _value, _message) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/gecko-input-modal/gecko-input-modal.html',
                controller: 'inputModalCtrl',
                resolve: {
                    title: function title() {
                        return _title;
                    },
                    value: function value() {
                        return _value;
                    },
                    message: function message() {
                        return _message;
                    }
                }
            };
        });
    }

    function inputModalCtrl($state, $stateParams, $modalInstance, title, value, message) {
        var ctrl = this;
        ctrl.title = title;
        ctrl.value = value;
        ctrl.message = message;

        setTimeout(function () {
            $('#input-modal-input').focus();
        }, 500);

        // Modal buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                $modalInstance.close(ctrl.value);
            }
        }];
    }

    angular.module('GeckoEngage').config(InputModal).controller('inputModalCtrl', inputModalCtrl);
})();