(function () {
    'use strict';

    function geckoFieldInline($templateRequest, $compile, $q, $filter, $timeout, $state, $geckoCall, $stateParams, geckoTranslateService) {

        var templateBase = '/components/gecko-field/fields/';

        // Templates used to display fields with non trivial safe values.
        var inlineDisplayTemplates = {
            file: 'file_display.html',
            matrix: 'matrix_display.html',
            media: 'media_display.html',
            repeatable: 'repeatable_display.html',
            url: 'url_display.html'
        };

        var noEditFields = {
            file: true,
            matrix: true,
            media: true,
            repeatable: true,

            twitter_username: true,
            twitter_followers: true,
            twitter_following: true,

            facebook_username: true,
            facebook_followers: true,
            facebook_following: true,

            pinterest_username: true,
            pinterest_followers: true,
            pinterest_following: true,

            instagram_username: true,
            instagram_followers: true,
            instagram_following: true,

            youtube_username: true,
            youtube_followers: true,
            youtube_following: true
        };

        var dataTypeFilters = {
            timestamp: 'formatDate'
        };

        return {
            restrict: 'E',
            templateUrl: '/components/gecko-field/gecko-field-inline.html',

            scope: {
                field: '=',
                value: '=',
                entityId: '@'
            },

            controllerAs: 'ctrl',
            bindToController: true,

            link: function link(scope, iElement, iAttrs, ctrl) {
                scope.Gecko = Gecko;
                var form = iElement.find('form');
                var staticValue = iElement.find('p');

                // Overwrite popover field label with field inline label if it exists.
                ctrl.displayLabel = ctrl.field.displayLabel || ctrl.field.label;

                ctrl.staticValue = function (ctrlValue) {

                    ctrlValue = ctrlValue || ctrl.value;

                    var displayValue;

                    if (ctrl.field.display && ctrl.field.obj) {

                        if (angular.isArray(ctrl.field.display)) {
                            for (var i = 0; i < ctrl.field.display.length; i++) {
                                displayValue = GeckoUI.getNestedObjectValue(ctrl.field.obj, ctrl.field.display[i]);
                                if (displayValue) break;
                            }
                        } else {
                            displayValue = GeckoUI.getNestedObjectValue(ctrl.field.obj, ctrl.field.display);
                        }
                    }

                    var value = displayValue || ctrl.field.obj && ctrl.field.obj[ctrl.field.id] || ctrlValue && ctrlValue.safe;

                    // Check display value length (DB will cut off at 512 chars)
                    if (value && value.length > 400) value += '...<p>...<u>See More</ul></p>';

                    // Don't use the safe value from a Gecko.Value if it is a timestamp so that we can display filter it properly.
                    if (!ctrl.field.obj && ctrl.field.data_type === Gecko.Field.DATA_TYPE_TIMESTAMP && ctrlValue && ctrlValue.value) {
                        value = ctrlValue.value;
                    }

                    if (ctrl.field.is_calculated && !value) {
                        if (ctrl.field.data_type === Gecko.Field.DATA_TYPE_TIMESTAMP) {
                            return 'Never';
                        }
                        if (ctrl.field.data_type === Gecko.Field.DATA_TYPE_INTEGER) {
                            return 0;
                        }
                    }

                    // Format textarea value
                    if (ctrl.field.type === Gecko.Field.TYPE_TEXTAREA && value) {
                        return $filter('nl2br')(value);
                    }

                    // Format date value
                    if (ctrl.field.type === Gecko.Field.TYPE_DATE) {
                        return $filter('formatDate')(value, Gecko.dateFormat.short, ctrl.field.utc) || '—';
                    }

                    // Format preferred_language value
                    if (ctrl.field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
                        return geckoTranslateService.renderLanguageValue(value) || '-';
                    }

                    return $filter(ctrl.filter)(value, Gecko.dateFormat.short) || '—';
                };

                // Check route for the ability used to determine edit rights
                ctrl.ableEdit = true;
                angular.forEach($state.current.editRequires, function (ability) {
                    if (!Gecko.able(ability)) {
                        ctrl.ableEdit = false;
                    }
                });

                // Add no-edit class when user doesn't have permission.
                if (!ctrl.ableEdit) {
                    iElement.addClass('no-edit');
                }

                var templatePromise;

                var templateUrl;
                // Use custom templates for values where we need complex formatting.
                if (inlineDisplayTemplates[ctrl.field.type]) {

                    templateUrl = templateBase + inlineDisplayTemplates[ctrl.field.type];
                }

                if (templateUrl) {

                    templatePromise = $templateRequest(templateUrl, true).then(function (template) {

                        // Replace the static value with the new template
                        var newTemplate = angular.element(template);
                        staticValue.after(newTemplate);
                        staticValue.remove();
                        staticValue = newTemplate;

                        // Compile it amd link it to the isolated scope.
                        $compile(iElement.contents())(scope);
                    });
                } else {

                    templatePromise = $q.when(1);
                }

                // Add a link button if the field requires it
                var compileLink = function compileLink() {
                    var linkButton, linkIcon, sref, href;
                    staticValue.find('.link').remove(); // Remove previous links if they exists
                    if (typeof ctrl.field.sref === 'function') {
                        sref = ctrl.field.sref(ctrl.value);
                    } else {
                        sref = ctrl.field.sref;
                    }
                    if (typeof ctrl.field.href === 'function') {
                        href = ctrl.field.href(ctrl.value);
                    } else {
                        href = ctrl.field.href;
                    }
                    if (sref || href) {
                        templatePromise.then(function () {
                            if (sref) {
                                linkIcon = ctrl.field.linkIcon || 'fa-arrow-right';
                                linkButton = angular.element('<a class="link" ui-sref="' + sref + '"><i class="fa ' + linkIcon + '"></i></a>');
                            } else if (href) {
                                linkIcon = ctrl.field.linkIcon || 'fa-external-link';
                                linkButton = angular.element('<a class="link" ng-href="' + $filter('httpify')(href) + '"><i class="fa ' + linkIcon + '"></i></a>');
                            }

                            linkButton.on('mousedown', function (event) {
                                event.stopImmediatePropagation();
                                event.stopPropagation();
                                return true;
                            });

                            staticValue.prepend(linkButton);
                            $compile(linkButton)(scope);
                        });
                    }
                };
                scope.$watch(function () {
                    return ctrl.value;
                }, function () {
                    // Re-compile the link on value change to reflect the new value
                    compileLink();
                });

                // Calculated fields are not editable so we ignore them.
                // We also disable edit for some fields that need work. (Matrix/Repeatable)
                if (!(ctrl.field.is_calculated || ctrl.field.noEdit || noEditFields[ctrl.field.type])) {

                    form.hide();

                    templatePromise.then(function () {

                        var inThisField = function inThisField(element) {
                            return angular.element(element).parents().index(iElement) !== -1;
                        };

                        ctrl.showPopover = function (focus) {

                            // Prevent the popover contents initialising until it is opened for the first time.
                            if (!ctrl.opened) {
                                ctrl.opened = true;
                            }
                            if (typeof focus === 'undefined') focus = true;

                            form.show();
                            if (focus) form.find('input').first().focus();

                            // Popover dismissed on document click.
                            angular.element(document).click(ctrl.dismissPopover);
                        };

                        ctrl.closePopover = function () {
                            angular.element(document).off('click', ctrl.dismissPopover);
                            form.hide();
                        };

                        ctrl.dismissPopover = function (event) {

                            // If the field element is not in the parent chain of the target element
                            if (!inThisField(event.target)) {
                                event.preventDefault();
                                event.stopPropagation();
                                ctrl.setupValue(ctrl.value);
                                ctrl.closePopover();
                                return false;
                            }
                        };

                        // Only show edit on click if user is able to edit
                        if (ctrl.ableEdit) {

                            // Keep the screen position on mousedown
                            var startPosition = { x: 0, y: 0 };
                            staticValue.on('mousedown', function (event) {
                                startPosition = {
                                    x: event.screenX,
                                    y: event.screenY
                                };
                            });

                            // Calculate the difference in event location and only trigger edit if the movement is less than 3 pixels
                            staticValue.on('mouseup', function (event) {
                                var xDelta = event.screenX - startPosition.x;
                                var yDelta = event.screenY - startPosition.y;
                                var deltaSquared = xDelta * xDelta + yDelta * yDelta;
                                if (deltaSquared > 9) {
                                    return false;
                                }

                                ctrl.showPopover();
                            });
                        }

                        // Clean up body click handlers
                        iElement.on('$destroy', function () {
                            angular.element(document).off('click', ctrl.dismissPopover);
                        });

                        var handleValue = function handleValue(value) {
                            ctrl.setupValue(value);
                            ctrl.closePopover();
                            GeckoUI.messenger.success('Value successfully updated');
                            scope.$digest();
                        };

                        var handleFail = function handleFail(error) {

                            GeckoUI.messenger.error(error.errors);
                        };

                        ctrl.saveValue = function (event) {
                            /*  If(ctrl.field.requires) {
                                  if(Array.isArray(ctrl.field.requires)){
                                      for (var ability of ctrl.field.requires) {
                                          if (!GeckoUI.ableWithReason(ability)) { return }
                                      }
                                  } else {
                                      if (!GeckoUI.ableWithReason(ctrl.field.requires)) { return }
                                  }
                              } */
                            event.preventDefault();
                            event.stopImmediatePropagation();

                            ctrl.saving = true;

                            // Custom save function 
                            if (typeof ctrl.field.saveFn === 'function') {
                                ctrl.field.saveFn(ctrl.editValue[ctrl.field.id]).then(handleValue).catch(handleFail).finally(function () {
                                    ctrl.saving = false;
                                });
                                return false;
                            }

                            if (ctrl.field.obj) {

                                ctrl.field.obj[ctrl.field.id] = ctrl.editValue[ctrl.field.id];

                                ctrl.field.obj.save().then(function (obj) {

                                    ctrl.field.obj.fill(obj.toObject());
                                    ctrl.closePopover();
                                    GeckoUI.messenger.success(ctrl.field.saveMessage || 'Value successfully updated');
                                    scope.$digest();
                                }).catch(handleFail).finally(function () {
                                    ctrl.saving = false;
                                });
                                return false;
                            }

                            if (ctrl.value) {
                                ctrl.value.replace(ctrl.editValue[ctrl.field.id], ctrl.field.changeResponseValue).then(handleValue).catch(handleFail).finally(function () {
                                    ctrl.saving = false;
                                });

                                // Add current value to shown past values.
                                if (ctrl.showValues && !angular.equals(ctrl.editValue[ctrl.field.id], ctrl.value.value) && ctrl.pastValues) {
                                    ctrl.pastValues.unshift(Gecko.clone(ctrl.value));
                                    ctrl.pastValues[0].updated_at = moment().format('X');
                                }
                            } else {
                                var value = new Gecko.Value();

                                if (ctrl.entityId) {
                                    switch (ctrl.field.field_type) {

                                        case Gecko.Field.FIELD_TYPE_CONTACT:
                                            value.contact_id = ctrl.entityId;
                                            break;

                                        case Gecko.Field.FIELD_TYPE_ORGANISATION:
                                            value.organisation_id = ctrl.entityId;
                                            break;

                                        case Gecko.Field.FIELD_TYPE_FORM:
                                            value.contact_id = ctrl.callTarget.contact_id;
                                            value.response_id = ctrl.entityId;
                                            break;
                                    }
                                }

                                value.field_id = ctrl.field.id;
                                value.data = ctrl.editValue[ctrl.field.id];

                                value.save().then(handleValue).catch(handleFail).finally(function () {
                                    ctrl.saving = false;
                                });
                            }

                            // Do not perform form default action.
                            return false;
                        };
                    });
                }
                // When the field is calculated we remove the form element
                else {
                        // This timeout prevents the form being removed before its contents are compiled which is causing an error.
                        $timeout(function () {
                            form.remove();
                        });
                        iElement.addClass('no-edit');
                    }

                // Add a class based on the field type to help with styling.
                if (ctrl.field.type) {
                    iElement.addClass('gecko-field-inline-' + ctrl.field.type);
                }

                // Add a class for required fields.
                if (ctrl.field.required) {
                    iElement.addClass('field-required');
                }
            },

            controller: function controller() {
                var ctrl = this;

                ctrl.saving = false;

                // If id is not specified on field we check for key and copy it over so that objects read a little better.
                if (!ctrl.field.id && ctrl.field.key) {
                    ctrl.field.id = ctrl.field.key;
                }

                ctrl.setupValue = function (value) {

                    ctrl.value = value;

                    ctrl.editValue = {};

                    if (ctrl.field.obj) {
                        ctrl.field.noOverride = true;
                        ctrl.editValue[ctrl.field.id] = ctrl.field.obj[ctrl.field.id];
                    } else {
                        ctrl.editValue[ctrl.field.id] = ctrl.value ? angular.copy(ctrl.value.value) : null;
                    }
                };

                ctrl.setupValue(ctrl.field.obj || ctrl.value);

                // Try to get a filter function for the data type. Used for display in some fields.
                ctrl.filter = dataTypeFilters[ctrl.field.data_type];

                // Default filter function to do nothing.
                ctrl.filter = ctrl.filter || 'identity';

                // Can click to call
                ctrl.canClickToCall = function () {
                    if (!(Gecko.user && Gecko.account && Gecko.user.call_sender_id && Gecko.account.call_sender_id)) {
                        return false;
                    }
                    return Gecko.User.voipOutboundEnabled() && ctrl.field.type === Gecko.Field.TYPE_TEL && ctrl.value && !$stateParams.subscriber_id;
                };

                // Click to call function
                ctrl.clickToCall = $geckoCall.clickToCall;

                // Build call target
                ctrl.callTarget = {};
                if ($stateParams.contact_id) ctrl.callTarget.contact_id = $stateParams.contact_id;
                // If ($stateParams.campaign_id) ctrl.callTarget.campaign_id = $stateParams.campaign_id;

                // Hopefully this can be removed one day! Issue being matrix values wrongly set as an object
                if (ctrl.field.type === Gecko.Field.TYPE_MATRIX && ctrl.value) {
                    if (ctrl.value.value && !Array.isArray(ctrl.value.value.data) && ctrl.value.value.structure && ctrl.value.value.structure.fields) {
                        var _data = [],
                            _dataObj = angular.copy(ctrl.value.value.data),
                            _fields = angular.copy(ctrl.value.value.structure.fields);

                        // Re-map data from object to array
                        angular.forEach(_fields, function (f, i) {
                            // Only map value if key matches index
                            if (_dataObj[i]) {
                                _data.push(_dataObj[i]);
                            } else {
                                _data.push(null);
                            }
                        });

                        // Set sanatized data back to value
                        ctrl.value.value.data = _data;
                    }
                }

                // We need to exclude the field types that set help_text as html
                ctrl.showTooltip = function () {
                    return ctrl.field.help_text && [Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_CONSENT].indexOf(ctrl.field.type) === -1;
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFieldInline', geckoFieldInline);
})();