(function () {
    'use strict';

    function editButton() {
        return {
            restrict: 'A',
            scope: {
                value: '=',
                options: '='
            },
            controller: function controller($scope, $element) {
                // Setup the default list of countries
                $scope.countries = countries;
                $scope.GeckoUI = GeckoUI;

                // Setup custom array if it's a checkbox
                if ($scope.value.related_field.type == 'checkbox') {

                    // Setup our return array
                    var checkboxReturn = [];
                    // Setup the array of everything available
                    var allCheckboxes = $scope.value.related_field.values;
                    // Setup the array of everything already checked
                    var checkedCheckboxes = $scope.value.value;

                    // Loop through every checkbox that exists
                    angular.forEach(allCheckboxes, function (value, key) {
                        // Create a new checkbox var for each
                        var newCheckbox = {
                            // Set only the title by default
                            title: value.value
                        };

                        // The jQuery $.inArray is garbage so we have to do it this hacky way
                        // May be a bit slow on huge arrays but that should be rare

                        // Set the variable to false by default
                        newCheckbox.checked = false;

                        // Loop through all of the checked boxes for comparison
                        angular.forEach(checkedCheckboxes, function (value, key) {
                            // See if their value is the same as our title
                            if (value == newCheckbox.title) {
                                // If it is than check it off
                                newCheckbox.checked = true;
                            }
                        });

                        // Then add it to the checkbox parent array
                        checkboxReturn.push(newCheckbox);
                    });

                    // Create a brand new entry in the $scope object to be used in edit-value.html
                    $scope.value.display = checkboxReturn;
                }

                if ($scope.value.related_field.type == 'address') {
                    if ($scope.value.related_field.option_id !== 0 && $scope.value.related_field.option_id !== null) {
                        $scope.addressCountries = convertOptions($scope.value.related_field.option.values);
                    } else {
                        $scope.addressCountries = countries;
                    }
                }

                function convertOptions(options) {

                    if (options == '[]') return;
                    // Pre-process options
                    var _options = [];

                    angular.forEach(options, function (value, index) {
                        _options.push(value.value);
                    });

                    return _options;
                }

                // Save the value back to the server
                $scope.saveValue = function ($event) {

                    // If it's a date field, we need it to be in DD/MM/YYYY
                    // If ($scope.value.related_field.type == 'date') {
                    //     $scope.value.value = moment($scope.value.value * 1000).format("DD/MM/YYYY");
                    // }

                    // If it's a checkbox, we need to pull in the display values and convert them
                    if ($scope.value.related_field.type == 'checkbox') {

                        // We need to empty out the actual settings and we will reset it one by one
                        $scope.value.value = [];

                        // Loop through all of the elements that are displayed
                        angular.forEach($scope.value.display, function (value, key) {

                            // See if it's been checked
                            if (value.checked) {
                                // If it is checked we push in just the title
                                $scope.value.value.push(value.title);
                            }
                        });
                    }

                    $scope.value.include('field').replace($scope.value.value).then(function () {
                        $scope.value.editing = false;
                        $scope.$apply();
                    });
                };
            },
            link: function link(scope, element, attrs) {

                var isTypeEditable = function isTypeEditable() {
                    if (scope.value.related_field.type == 'email' || scope.value.related_field.type == 'text' || scope.value.related_field.type == 'tel' || scope.value.related_field.type == 'url' || scope.value.related_field.type == 'hidden' || scope.value.related_field.type == 'date' || scope.value.related_field.type == 'textarea' || scope.value.related_field.type == 'name' || scope.value.related_field.type == 'address' || scope.value.related_field.type == 'country' || scope.value.related_field.type == 'select' || scope.value.related_field.type == 'checkbox' || scope.value.related_field.type == 'radio') {
                        return true;
                    }

                    return false;
                };

                // Clicking on the edit icon sets editing to true
                $(element).click(function () {
                    if (isTypeEditable()) {
                        scope.value.editing = true;
                        scope.$apply();
                    }
                });
            },
            templateUrl: '/views/_partials/edit-value.e0de0a7b.html'
        };
    }

    angular.module('GeckoEngage').directive('editButton', editButton);
})();