(function () {
    'use strict';

    var generateEditContoller = function generateEditContoller($scope, $state, $stateParams, $geckoSocket, ctrl, vars, LoadingIndicator) {
        // Tabs
        ctrl.tabs = [{
            title: 'Overview',
            state: 'broadcast.overview'
        }, {
            title: 'Stats',
            state: 'broadcast.stats',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_MESSAGE_STATS);
            }
        }, {
            title: 'Settings',
            state: 'broadcast.settings'
        }, {
            title: 'Select Contacts',
            state: 'broadcast.filter'
        }, {
            title: 'Workflows',
            state: 'broadcast.workflows'
        }, {
            title: 'Launch',
            state: 'broadcast.launch',
            hideWhen: function hideWhen() {
                return [Gecko.Campaign.ACTIVE, Gecko.Campaign.RUNNING, Gecko.Campaign.COMPLETE].indexOf(ctrl.broadcast.status) !== -1;
            }
        }];

        ctrl.refreshFn = function () {
            ctrl.broadcast.refreshing = 1;
            ctrl.broadcast.related_filter.refreshed_at = moment.utc().format('X');
            return angular.copy(ctrl.broadcast).post(ctrl.broadcast.id + '/refresh', { hard: true }).catch(GeckoUI.messenger.error);
        };

        // Stat card options
        ctrl.optionsBtn = {
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_USER);
            }
        };
        ctrl.optionsBtn.items = [{
            title: 'Refresh Broadcast',
            action: function action() {
                return ctrl.refreshFn();
            },
            hideWhen: function hideWhen() {
                return !(!ctrl.broadcast.refreshing && (ctrl.broadcast.related_filter || {}).refreshed_at);
            }
        }, {
            title: 'Export broadcast',
            action: function action() {
                return $state.go('exportnew', { export_id: 'new', broadcast_id: $stateParams.broadcast_id });
            }
        }, {
            title: 'Clone broadcast',
            action: function action() {
                ctrl.broadcast.clone().then(function (cloned_campaign) {
                    $state.go('broadcast.overview', { broadcast_id: cloned_campaign.id });
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_BROADCASTS_CREATE);
            }
        }, {
            title: 'Remove broadcast',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_BROADCASTS_DELETE)) {
                    return;
                }
                GeckoUI.dialog.confirm('Are you sure you want to remove this broadcast?', function (ok) {
                    if (ok) {
                        LoadingIndicator.resolve(ctrl.broadcast.remove()).then(function () {
                            GeckoUI.messenger.success('Broadcast removed');
                            // Redirect to broadcasts
                            return $state.go('broadcasts');
                        }).catch(function (err) {
                            return GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_BROADCASTS_DELETE);
            }
        }];

        ctrl.showTabs = function () {
            return ctrl.broadcast.id;
        };

        // Save campaign
        ctrl.saveBroadcast = function () {
            $scope.$broadcast('saveBroadcast');
        };
        // Listen for CMD + S event
        $scope.$on('quickSave:broadcast', ctrl.saveBroadcast);

        ctrl.saveBtn = function () {
            return {
                preset: $stateParams.wizard ? 'next' : 'save',
                title: $stateParams.wizard ? 'Next' : 'Save changes'
            };
        };

        ctrl.showSaveBroadcastBtn = function () {
            return ['broadcast.overview', 'broadcast.stats', 'broadcast.launch'].indexOf($state.$current.name) === -1 && $stateParams.broadcast_id !== 'new';
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Broadcasts',
            click: function click() {
                return $state.go('broadcasts', $stateParams);
            }
        }, {
            label: ctrl.broadcast.id ? ctrl.broadcast.title : 'Add Broadcast',
            active: true
        }];

        ctrl.broadcast.refreshingMessage = 'Refreshing';
        $geckoSocket.registerEvent('campaign:refreshing', function (event) {
            if (event.id === ctrl.broadcast.id) {
                ctrl.broadcast.refreshing = event.refreshing;
                ctrl.broadcast.refreshingMessage = event.message || 'Refreshing';
                $scope.$digest();
            }
        });
    };

    function BroadcastCtrl($scope, $state, $stateParams, $geckoSocket, campaign, LoadingIndicator) {
        var ctrl = this;
        ctrl.broadcast = campaign;

        // Go to overview
        if ($state.$current.name == 'broadcast') $state.go('broadcast.overview');

        generateEditContoller($scope, $state, $stateParams, $geckoSocket, ctrl, {}, LoadingIndicator);
    }

    angular.module('GeckoEngage').controller('BroadcastCtrl', BroadcastCtrl);
})();