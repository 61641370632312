;(function () {
    'use strict';

    var appId = function () {
        var isLocal = window.location.href.includes('localhost:');
        var isStage = window.location.href.includes('app-stage.') && window.location.href.includes('.com');

        var PRODUCTION_APP_ID = 'xnmxp5mp';
        var STAGE_APP_ID = 'wdmvag27';
        var LOCAL_APP_ID = '7pxyjapx';

        if (isStage) {
            return STAGE_APP_ID;
        } else if (isLocal) {
            return LOCAL_APP_ID;
        }
        // Default to PROD
        return PRODUCTION_APP_ID;
    }();

    var getUserHash = function getUserHash() {
        var hash = (Gecko.token || {}).intercom_hash || null;
        return hash ? Promise.resolve(hash) : Promise.reject('No Intercom User Hash Found.');
    };

    var buildData = function buildData(userHash) {
        var account = Gecko.getCurrentAccount() || {};
        var user = Gecko.getCurrentUser() || {};
        var data = {
            app_id: appId,
            email: user.email,
            created_at: user.created_at,
            name: user.full_name,
            user_id: account.routing_id + '_' + user.id,
            user_hash: userHash,
            company: {
                id: account.routing_id,
                name: account.name,
                created_at: account.created_at
            },
            widget: {
                activator: "#Intercom"
            },
            region: GeckoUI.getRegion()
        };
        if (user.telephone) data['telephone'] = user.telephone;
        if (user.position) data['position'] = user.position;
        if (user.related_group) data['user group'] = user.related_group.name;
        if (account.related_package) data['plan'] = account.related_package.title;

        return data;
    };

    var refreshFn = function refreshFn() {
        window.Intercom('update');
        window.Intercom('reattach_activator');
    };

    window.bootIntercom = function () {
        try {
            return getUserHash().then(function (userHash) {
                window.Intercom('boot', buildData(userHash));
                return Promise.resolve(refreshFn).finally(refreshFn);
            });
        } catch (err) {
            // Pass
            return Promise.reject(err);
        }
    };

    // Add Intercom script (with correct appId)
    ;(function () {
        var w = window;var ic = w.Intercom;if (typeof ic === "function") {
            ic('reattach_activator');ic('update', intercomSettings);
        } else {
            var l = function l() {
                var s = d.createElement('script');s.type = 'text/javascript';s.async = true;s.src = 'https://widget.intercom.io/widget/' + appId;var x = d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);
            };

            var d = document;var i = function i() {
                i.c(arguments);
            };i.q = [];i.c = function (args) {
                i.q.push(args);
            };w.Intercom = i;if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();
})();