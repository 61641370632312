(function () {
    'use strict';

    var eventOrderByObject = {
        internal_title: 'asc',
        title: 'asc'
    };

    var OptionsPresets = {
        events: {
            init: function init(value) {
                if (!value) return [];
                return new Gecko.Event().include('parent', 'parent.parent').rfields({ event: ['nice_title', 'type'] }).get(value).then(function (event) {
                    if (event.related_parent && event.related_parent.related_parent) {
                        return [event.related_parent.related_parent, event.related_parent, event];
                    }
                    if (event.related_parent) {
                        return [event.related_parent, event];
                    }
                    return [event];
                });
            },
            get: [function (select, scope) {
                var self = this;
                var onDataLoaded = function onDataLoaded(data) {
                    self.options[0] = data.toArray();
                    select.loadingOptions = false;
                    select.moreToLoad = self.options[0].length != data.pagination.total_items;
                    scope.$digest();
                };
                var onFail = function onFail(data) {
                    // When we get unauthorised disable the field since we don't have access to the data.
                    if (data.status_code === 403) {
                        self.disabledWhen = function () {
                            return true;
                        };
                    }
                };
                select.loadMore = function () {
                    select.loadingOptions = true;
                    new Gecko.Event().where('type', [Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_EVENT_LEGACY]).orderBy(eventOrderByObject).rfields({ event: ['nice_title', 'type', 'start_datetime'] }).perPage('all').search(select.search).get().then(onDataLoaded).catch(onFail);
                };

                new Gecko.Event().where('type', [Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_EVENT_LEGACY]).orderBy(eventOrderByObject).rfields({ event: ['nice_title', 'type', 'start_datetime'] }).limit(10).search(select.search).get().then(onDataLoaded).catch(onFail);

                select.loadingOptions = true;
                select.allLoaded = false;
            }, function (select, scope) {
                var self = this;
                if (scope.ctrl.values[0]) {
                    var onDataLoaded = function onDataLoaded(data) {
                        self.options[1] = data.toArray();
                        select.loadingOptions = false;
                        select.moreToLoad = self.options[1].length != data.pagination.total_items;
                        scope.$digest();
                    };
                    var onFail = function onFail(data) {
                        // When we get unauthorised disable the field since we don't have access to the data.
                        if (data.status_code === 403) {
                            self.disabledWhen = function () {
                                return true;
                            };
                        }
                    };
                    select.loadMore = function () {
                        select.loadingOptions = true;
                        new Gecko.Event().where('parent_id', scope.ctrl.values[0]).orderBy(eventOrderByObject).rfields({ event: ['nice_title', 'type', 'start_datetime'] }).perPage('all').search(select.search).get().then(onDataLoaded).catch(onFail);
                    };

                    new Gecko.Event().where('parent_id', scope.ctrl.values[0]).orderBy(eventOrderByObject).rfields({ event: ['nice_title', 'type', 'start_datetime'] }).limit(10).search(select.search).get().then(onDataLoaded).catch(onFail);

                    select.loadingOptions = true;
                    select.allLoaded = false;
                } else {
                    self.options[1] = [];
                }
            }, function (select, scope) {
                var self = this;
                if (scope.ctrl.values[1]) {
                    var pickedSession = this.options[1].find(function (session) {
                        return session.id == scope.ctrl.values[1];
                    });
                    if (pickedSession.type !== Gecko.Event.TYPE_SESSION_LEGACY) {
                        // When there is 1 time, fill as value
                        var prefill = function prefill() {
                            // Horrible setTimeout solution
                            setTimeout(function () {
                                if (!scope.ctrl.values[2] && self.options[2].length === 1) {
                                    scope.ctrl.values[2] = self.options[2][0].id;
                                    scope.$digest();
                                }
                            }, 0);
                        };

                        var onDataLoaded = function onDataLoaded(data) {
                            self.options[2] = data.toArray();
                            select.loadingOptions = false;
                            select.moreToLoad = self.options[2].length != data.pagination.total_items;
                            scope.$digest();
                        };
                        var onFail = function onFail(data) {
                            // When we get unauthorised disable the field since we don't have access to the data.
                            if (data.status_code === 403) {
                                self.disabledWhen = function () {
                                    return true;
                                };
                            }
                        };
                        select.loadMore = function () {
                            select.loadingOptions = true;
                            new Gecko.Event().where('parent_id', scope.ctrl.values[1]).orderBy('title').rfields({ event: ['nice_title'] }).perPage('all').search(select.search).get().then(onDataLoaded).catch(onFail);
                        };

                        new Gecko.Event().where('parent_id', scope.ctrl.values[1]).orderBy('title').rfields({ event: ['nice_title'] }).limit(10).search(select.search).get().then(onDataLoaded).then(prefill).catch(onFail);

                        select.loadingOptions = true;
                        select.allLoaded = false;
                    } else {
                        self.options[2] = [];
                    }
                } else {
                    self.options[2] = [];
                }
            }]
        },
        openEvents: {
            init: function init(value) {
                if (!value) return [];
                return new Gecko.Event().include('parent', 'parent.parent').rfields({ event: ['nice_title'] }).get(value).then(function (event) {
                    if (event.related_parent && event.related_parent.related_parent) {
                        return [event.related_parent.related_parent, event.related_parent, event];
                    }
                    if (event.related_parent) {
                        return [event.related_parent, event];
                    }
                    return [event];
                });
            },
            get: [function (select, scope) {
                var self = this;
                var onDataLoaded = function onDataLoaded(data) {
                    self.options[0] = data.toArray();
                    select.loadingOptions = false;
                    select.moreToLoad = self.options[0].length != data.pagination.total_items;
                    scope.$digest();
                };
                var onFail = function onFail(data) {
                    // When we get unauthorised disable the field since we don't have access to the data.
                    if (data.status_code === 403) {
                        self.disabledWhen = function () {
                            return true;
                        };
                    }
                };
                select.loadMore = function () {
                    select.loadingOptions = true;
                    new Gecko.Event().where('type', [Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_EVENT_LEGACY]).where('event_closed', 0).orderBy(eventOrderByObject).rfields({ event: ['nice_title', 'type', 'start_datetime'] }).perPage('all').search(select.search).get().then(onDataLoaded).catch(onFail);
                };

                new Gecko.Event().where('type', [Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_EVENT_LEGACY]).where('event_closed', 0).orderBy(eventOrderByObject).rfields({ event: ['nice_title', 'type', 'start_datetime'] }).limit(10).search(select.search).get().then(onDataLoaded).catch(onFail);

                select.loadingOptions = true;
                select.allLoaded = false;
            }, function (select, scope) {
                var self = this;
                if (scope.ctrl.values[0]) {
                    var onDataLoaded = function onDataLoaded(data) {
                        self.options[1] = data.toArray();
                        select.loadingOptions = false;
                        select.moreToLoad = self.options[1].length != data.pagination.total_items;
                        scope.$digest();
                    };
                    var onFail = function onFail(data) {
                        // When we get unauthorised disable the field since we don't have access to the data.
                        if (data.status_code === 403) {
                            self.disabledWhen = function () {
                                return true;
                            };
                        }
                    };
                    select.loadMore = function () {
                        select.loadingOptions = true;
                        new Gecko.Event().where('parent_id', scope.ctrl.values[0]).where('event_closed', 0).orderBy(eventOrderByObject).rfields({ event: ['nice_title', 'type', 'start_datetime'] }).perPage('all').search(select.search).get().then(onDataLoaded).catch(onFail);
                    };

                    new Gecko.Event().where('parent_id', scope.ctrl.values[0]).where('event_closed', 0).orderBy(eventOrderByObject).rfields({ event: ['nice_title', 'type', 'start_datetime'] }).limit(10).search(select.search).get().then(onDataLoaded).catch(onFail);

                    select.loadingOptions = true;
                    select.allLoaded = false;
                } else {
                    self.options[1] = [];
                }
            }, function (select, scope) {
                var self = this;
                if (scope.ctrl.values[1]) {
                    var pickedSession = this.options[1].find(function (session) {
                        return session.id == scope.ctrl.values[1];
                    });
                    if (pickedSession.type !== Gecko.Event.TYPE_SESSION_LEGACY) {
                        // When there is 1 time, fill as value
                        var prefill = function prefill() {
                            // Horrible setTimeout solution
                            setTimeout(function () {
                                if (!scope.ctrl.values[2] && self.options[2].length === 1) {
                                    scope.ctrl.values[2] = self.options[2][0].id;
                                    scope.$digest();
                                }
                            }, 0);
                        };

                        var onDataLoaded = function onDataLoaded(data) {
                            self.options[2] = data.toArray();
                            select.loadingOptions = false;
                            select.moreToLoad = self.options[2].length != data.pagination.total_items;
                            scope.$digest();
                        };
                        var onFail = function onFail(data) {
                            // When we get unauthorised disable the field since we don't have access to the data.
                            if (data.status_code === 403) {
                                self.disabledWhen = function () {
                                    return true;
                                };
                            }
                        };
                        select.loadMore = function () {
                            select.loadingOptions = true;
                            new Gecko.Event().where('parent_id', scope.ctrl.values[1]).where('event_closed', 0).orderBy(eventOrderByObject).rfields({ event: ['nice_title'] }).perPage('all').search(select.search).get().then(onDataLoaded).catch(onFail);
                        };

                        new Gecko.Event().where('parent_id', scope.ctrl.values[1]).where('event_closed', 0).orderBy(eventOrderByObject).rfields({ event: ['nice_title'] }).limit(10).search(select.search).get().then(onDataLoaded).then(prefill).catch(onFail);

                        select.loadingOptions = true;
                        select.allLoaded = false;
                    } else {
                        self.options[2] = [];
                    }
                } else {
                    self.options[2] = [];
                }
            }]
        }
    };

    angular.module('GeckoEngage').value('OptionsPresets', OptionsPresets);
})();