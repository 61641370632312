(function () {
    'use strict';

    function MessagesRoute($stateProvider) {
        $stateProvider.state('messages', {
            url: "/messages?{page}&{sort}&{order}&{search}&{filter_id}&{status}&{from}&{to}&{type}&{direction}&{template_id}&{campaign_id}",
            templateUrl: "/modules/messages/views/index.cf378dbf.html",
            controller: 'MessagesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Messages',
            requires: [Gecko.ABILITY_MESSAGES_VIEW],
            resolve: {
                messages: function messages($state, $stateParams) {
                    var messages = new Gecko.Message().include('sender', 'contact', 'user', 'message_events').where('user_trashed', 1);

                    if ($stateParams.from && $stateParams.to) messages.dates($stateParams.from, $stateParams.to);
                    if ($stateParams.filter_id) messages.where('contact_filter_id', $stateParams.filter_id);

                    if ($stateParams.status) {
                        var status = typeof $stateParams.status === 'string' ? [$stateParams.status] : $stateParams.status;
                        // Include SENDING status when type SENT is passed
                        if (status.indexOf(Gecko.Message.STATUS_SENT) !== -1) {
                            status = status.concat([Gecko.Message.STATUS_SENDING]);
                        }
                        messages.where('message_status', status);
                    }

                    if ($stateParams.type) messages.where('message_type', $stateParams.type);
                    if ($stateParams.direction) messages.where('message_direction', $stateParams.direction);
                    if ($stateParams.search) messages.where('contact_keyword', $stateParams.search);
                    if ($stateParams.template_id) messages.where('template_id', $stateParams.template_id);
                    if ($stateParams.campaign_id) messages.where('campaign_id', $stateParams.campaign_id);
                    if ($stateParams.order) {
                        messages.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    } else {
                        var default_order_by_field = $stateParams.from || $stateParams.to ? 'created_at' : 'id';
                        messages.orderBy(default_order_by_field, 'desc');
                    }

                    return messages.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({
                        message: ['direction', 'created_at', 'type', 'subject', 'status', 'view_url', 'template_id', 'workflow_id'],
                        messageevent: Gecko.MessageEvent.prototype.model_fields,
                        sender: ['name'],
                        contact: ['full_name', 'email']
                    }).get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(MessagesRoute);
})();