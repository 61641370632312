(function () {

    function BlockModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('editBlockModal', function (_block, type) {
            return {
                size: 'lg',
                controllerAs: 'ctrl',
                templateUrl: '/modules/landing_pages/views/_modals/modal_edit_block.410684ae.html',
                controller: 'BlockEditModalCtrl',
                resolve: {
                    block: function block($stateParams) {
                        if (_block !== 'new') {
                            return _block;
                        } else {
                            return new Gecko.Block().create({
                                title: 'New ' + GeckoUI.gobk(Gecko.Block.type_titles, 'id', type).title + ' Block',
                                type: type || Gecko.Block.TYPE_TEXT,
                                landing_page_id: $stateParams.landing_page_id,
                                order: 0
                            });
                        }
                    },
                    landing_page: function landing_page($stateParams) {
                        if (_block == 'new') return new Gecko.LandingPage().get($stateParams.landing_page_id);
                        return false;
                    }
                }
            };
        });
    }

    function BlockEditModalCtrl($scope, $filter, $modal, $modalInstance, block, landing_page, LoadingIndicator, asyncOptions) {
        var ctrl = this;
        ctrl.block = angular.copy(block.toInstance());
        ctrl.landing_page = landing_page ? landing_page : ctrl.block.related_landing_page;
        ctrl.latlongLocked = true;

        // Set default data object
        if (!ctrl.block.config) ctrl.block.config = {};
        // Set default styles object
        if (!ctrl.block.styles) ctrl.block.styles = {};

        // Tabs
        ctrl.tabs = [{
            title: 'Content',
            activeWhen: function activeWhen() {
                return true;
            }
        }, {
            title: 'Appearance'
        }];

        // Fields
        ctrl.fields = [{
            id: 'title',
            label: 'Title',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0);
            }
        }, {
            id: 'sub_title',
            label: 'Subtitle',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0);
            }
        }, {
            id: 'text',
            label: 'Text',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_RICHTEXT,
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0);
            }
        }, {
            id: 'content_image',
            label: 'Content Image',
            type: Gecko.Field.TYPE_FILE,
            mimetype: 'image/*',
            colClass: 'col-xs-12',
            help_text: '550px by 350px (recommended)',
            hideWhen: function hideWhen() {
                return ctrl.block.type != Gecko.Block.TYPE_TEXT || !$modal.isActiveTab(0);
            }
            /* Form Block */
        }, {
            id: 'form_id',
            label: 'Form',
            colClass: 'col-xs-12',
            help_text: 'Select the form that you’d like to display in this block of your landing page.',
            type: Gecko.Field.TYPE_SELECT,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            options: [ctrl.block.related_form],
            getOptions: asyncOptions.create(new Gecko.Form().orderBy('name').orderBy('internal_name').rfields({ form: ['internal_name', 'name'] })),
            choiceTemplate: 'form-choice',
            matchTemplate: 'form-match',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_FORM;
            }
            /* Event Block */
        }, {
            id: 'event_id',
            label: 'Event',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_SELECT,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            options: [ctrl.block.related_event],
            getOptions: asyncOptions.create(new Gecko.Event().where('type', [Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_EVENT_LEGACY]).orderBy('title').rfields({ events: ['title'] })),
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || [Gecko.Block.TYPE_EVENT, Gecko.Block.TYPE_FORM].indexOf(ctrl.block.type) == -1 || !ctrl.block.form_id;
            },
            description: 'This will overwrite any event categories that have been set for individual fields on the form.'
            /* Map Block */
        }, {
            id: 'location_postcode',
            obj: ctrl.block.config,
            label: 'Postcode/Address Lookup',
            help_text: 'Find the address or postcode below and it will auto-populate the co-ordinate and address fields for you',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_MAP;
            }
        }, {
            id: 'location_latitude',
            obj: ctrl.block.config,
            required: ctrl.latlongLocked,
            label: 'Latitude',
            help_text: 'The latitude co-ordinate of where you want the map to center',
            colClass: 'col-xs-4',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_MAP;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.latlongLocked;
            }
        }, {
            id: 'location_longitude',
            obj: ctrl.block.config,
            required: ctrl.latlongLocked,
            label: 'Longitude',
            help_text: 'The longitude co-ordinate of where you want the map to center',
            colClass: 'col-xs-4',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_MAP;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.latlongLocked;
            }
        }, {
            id: 'hide_button',
            label: 'Edit co-ordinates',
            type: Gecko.Field.TYPE_BUTTON,
            safeMode: true,
            colClass: 'col-xs-4',
            action: function action() {
                ctrl.latlongLocked = !ctrl.latlongLocked;
            },
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_MAP || !ctrl.latlongLocked;
            }
        }, {
            id: 'hide_button',
            label: 'Lock co-ordinates',
            type: Gecko.Field.TYPE_BUTTON,
            safeMode: true,
            colClass: 'col-xs-4',
            action: function action() {
                ctrl.latlongLocked = !ctrl.latlongLocked;
            },
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_MAP || ctrl.latlongLocked;
            }
        }, {
            id: 'location_title',
            obj: ctrl.block.config,
            label: 'Location Title',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_MAP;
            }
        }, {
            id: 'location_address',
            type: Gecko.Field.TYPE_TEXTAREA,
            obj: ctrl.block.config,
            label: 'Location Address',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_MAP;
            }
            /* Header / Footer Block */
        }, {
            id: 'show_facebook',
            obj: ctrl.block.config,
            label: 'Show Facebook Icon',
            colClass: 'col-xs-4',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || [Gecko.Block.TYPE_HEADER, Gecko.Block.TYPE_FOOTER].indexOf(ctrl.block.type) == -1 || ctrl.landing_page.config && !ctrl.landing_page.config.facebook_url;
            }
        }, {
            id: 'show_twitter',
            obj: ctrl.block.config,
            label: 'Show Twitter Icon',
            colClass: 'col-xs-4',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || [Gecko.Block.TYPE_HEADER, Gecko.Block.TYPE_FOOTER].indexOf(ctrl.block.type) == -1 || ctrl.landing_page.config && !ctrl.landing_page.config.twitter_url;
            }
        }, {
            id: 'show_linkedin',
            obj: ctrl.block.config,
            label: 'Show LinkedIn Icon',
            colClass: 'col-xs-4',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || [Gecko.Block.TYPE_HEADER, Gecko.Block.TYPE_FOOTER].indexOf(ctrl.block.type) == -1 || ctrl.landing_page.config && !ctrl.landing_page.config.linkedin_url;
            }
        }, {
            id: 'show_social_media',
            description: 'For social media icons to display on your landing page, you must add a URL to your Facebook/Twitter/LinkedIn account on the Edit Page menu.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_SECTION,
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || [Gecko.Block.TYPE_HEADER, Gecko.Block.TYPE_FOOTER].indexOf(ctrl.block.type) == -1 || ctrl.landing_page.config && !ctrl.landing_page.config.linkedin_url;
            }
        }, {
            id: 'copyright_text',
            obj: ctrl.block.config,
            label: 'Copyright Text',
            colClass: 'col-xs-12',
            help_text: 'Add/ edit the copyright text that appears at the bottom of your landing page. By default this will be your account name and the current calendar year.',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(0) || ctrl.block.type != Gecko.Block.TYPE_FOOTER;
            }
            /* Styling */
        }, {
            id: 'layout',
            label: 'Layout',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'col-xs-12',
            help_text: 'This changes the alignment of the content of your Text box.',
            noBlank: true,
            break: true,
            options: $filter('filter')(Gecko.Block.layout_titles, function (item) {
                if (item[ctrl.block.type]) return true;
            }),
            optionsLabelKey: 'title',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(1) || [Gecko.Block.TYPE_TEXT, Gecko.Block.TYPE_HEADER].indexOf(ctrl.block.type) == -1;
            }
        }, {

            id: 'primary_color',
            obj: ctrl.block.styles,
            label: 'Primary Color (including social icons)',
            type: Gecko.Field.TYPE_COLOR,
            colClass: 'col-xs-12',
            break: true,
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(1);
            }
        }, {
            id: 'text_color',
            obj: ctrl.block.styles,
            label: 'Text Color',
            type: Gecko.Field.TYPE_COLOR,
            colClass: 'col-xs-6',
            break: true,
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(1);
            }
        }, {
            id: 'title_color',
            obj: ctrl.block.styles,
            label: 'Title Color',
            type: Gecko.Field.TYPE_COLOR,
            colClass: 'col-xs-6',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(1);
            }
        }, {
            id: 'subtitle_color',
            obj: ctrl.block.styles,
            label: 'Subtitle Color',
            type: Gecko.Field.TYPE_COLOR,
            colClass: 'col-xs-6',
            break: true,
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(1);
            }
        }, {
            id: 'background_color',
            obj: ctrl.block.styles,
            label: 'Background Color',
            type: Gecko.Field.TYPE_COLOR,
            colClass: 'col-xs-6',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(1);
            }
        }, {
            id: 'logo_image',
            label: 'Logo Image',
            type: Gecko.Field.TYPE_FILE,
            mimetype: 'image/*',
            colClass: 'col-xs-12',
            break: true,
            help_text: '200px by 50px (recommended)',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(1) || ctrl.block.type != Gecko.Block.TYPE_HEADER;
            }
        }, {
            id: 'background_image',
            label: 'Background Image',
            type: Gecko.Field.TYPE_FILE,
            mimetype: 'image/*',
            colClass: 'col-xs-12',
            break: true,
            help_text: '2000px by 666px (recommended)',
            hideWhen: function hideWhen() {
                return !$modal.isActiveTab(1) || ctrl.block.type != Gecko.Block.TYPE_HEADER;
            }
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Cancel',
            icon: 'fa-times',
            btnTooltip: 'All changes will be lost',
            btnTooltipPlacement: 'right',
            position: 'secondary',
            action: function action() {
                $modalInstance.dismiss();
            }
        }, {
            title: ctrl.block.id ? 'Save Changes' : 'Add',
            preset: 'check',
            action: function action() {

                var block = angular.copy(ctrl.block);

                // Map validation issues
                if (block.type == Gecko.Block.TYPE_MAP) {
                    if (!block.config.location_latitude || !block.config.location_longitude) {
                        ctrl.alertMessage = 'Latitude and Longitude are required fields';
                        $scope.$digest();
                        return;
                    }
                }

                // Reset alert message
                ctrl.alertMessage = '';

                // Set block styles
                // Block.styles = block._styles;

                // Check and reset json fields
                if (!Object.keys(ctrl.block.config).length) block.config = null;
                if (!Object.keys(ctrl.block.styles).length) block.styles = null;

                // Save block
                block.include('landing_page').save().then(function (block) {
                    // Close modal
                    $modalInstance.close(block);
                }).catch(function (err) {
                    // Set validation alert message
                    ctrl.alertMessage = GeckoUI.renderError(err.errors);
                    $scope.$digest();
                });
            }
        }];

        // Address lookup API for map block
        var setupAddressLookup = function setupAddressLookup() {
            // Include the script file
            var script = document.createElement("script");
            script.src = "https://services.postcodeanywhere.co.uk/js/captureplus-2.30.min.js?key=" + Gecko.postcode_key;
            script.type = "text/javascript";
            document.getElementsByTagName("head")[0].appendChild(script);

            // Include the css file
            var link = document.createElement("link");
            link.href = "https://services.postcodeanywhere.co.uk/css/captureplus-2.30.min.css?key=" + Gecko.postcode_key;
            link.type = "text/css";
            link.rel = "stylesheet";
            document.getElementsByTagName("head")[0].appendChild(link);

            // After the script file has loaded
            script.onload = function () {
                var fields = [{ element: "field_location_postcode", field: "", mode: pca.fieldMode.SEARCH }];

                try {
                    var control = new pca.Address(fields, { key: Gecko.postcode_key });
                    control.load();

                    control.listen("populate", function (address) {
                        // Callback once address has been selected
                        ctrl.block.config.location_address = address.Line1 + "\n";
                        ctrl.block.config.location_address += address.Line2 + "\n";
                        ctrl.block.config.location_address += address.City + "\n";
                        ctrl.block.config.location_address += address.Province + "\n";
                        ctrl.block.config.location_address += address.PostalCode + "\n";
                        ctrl.block.config.location_address += address.CountryName;

                        $scope.$digest();

                        // Get the latlng from the address
                        getAddressLatLng(ctrl.block.config.location_address).then(function (latlng) {
                            ctrl.block.config.location_latitude = latlng.lat;
                            ctrl.block.config.location_longitude = latlng.lng;
                            $scope.$digest();
                        }, function (err) {
                            console.log(err); // eslint-disable-line no-console
                        });
                    });
                } catch (err) {}
            };
        };
        setupAddressLookup();
        // Address to co-ordinates for map block
        var getAddressLatLng = function getAddressLatLng(address) {
            return new Promise(function (resolve, reject) {
                if (!address) {
                    reject('blank address');
                }

                var urlSafe = ctrl.block.config.location_address.split(' ').join('+').split('\n').join('+');
                var url = 'https://maps.google.com/maps/api/geocode/json?address=' + urlSafe + '&sensor=false&key=AIzaSyAS9QXHFdfVsUrKQ-PSKPqn7GIe2PKGV3o';

                var req = new XMLHttpRequest();
                req.onload = function () {
                    if (req.status === 200) {
                        var results = JSON.parse(req.responseText).results;
                        if (results.length > 0) {
                            resolve(results[0].geometry.location);
                        } else {
                            reject('not found');
                        }
                    } else {
                        reject('web error');
                    }
                };
                req.open("GET", url, true);
                req.send();
            });
        };

        // Intercept modal close if not being saved
        var closeAnyway = true;
        // Only fire if a change has been made
        $scope.$watch('ctrl.block', function (newVal, oldVal) {
            if (newVal != oldVal) {
                closeAnyway = false;
            }
        }, true);
        $scope.$on('modal.closing', function ($event, reason) {
            if (!closeAnyway) {
                if (!(reason instanceof Gecko)) {
                    $event.preventDefault();
                    GeckoUI.dialog.confirm('Are you sure you want to discard these changes?', function (ok) {
                        if (ok) {
                            closeAnyway = true;
                            $modalInstance.dismiss();
                        }
                    });
                }
            }
        });
    }

    angular.module('GeckoEngage').config(BlockModals).controller('BlockEditModalCtrl', BlockEditModalCtrl);
})();