(function () {

    function AddLocationModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('addLocationModal', function () {
            return {
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal\n                            data-title="Add a New Location"\n                            footer-btns="ctrl.footerBtns"\n                            icon="fa-users">\n\n                                <location-fields location="ctrl.location"></location-fields>\n\n                        </gecko-modal>\n                    ',
                controller: 'AddLocationModalCtrl',
                requires: [function ($stateParams) {
                    return Gecko.ABILITY_LOCATIONS_CREATE;
                }],
                resolve: {
                    location: function location($stateParams) {
                        return new Gecko.Location().create({});
                    }
                }
            };
        });
    }

    function AddLocationModalCtrl($modalInstance, $state, $stateParams, unsavedAlert, location) {
        var ctrl = this;
        ctrl.location = location;
        ctrl.disableSave = false;

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                ctrl.disableSave = true;
                ctrl.location.save().then(function (location) {
                    GeckoUI.messenger.success('Location has been saved');
                    unsavedAlert.clear();
                    // Close modal and reload the page
                    $modalInstance.close(location);
                    ctrl.disableSave = false;
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Location Save Success', {
                        'location id': location.id,
                        'location title': location.name
                    });
                }).catch(function (err) {
                    ctrl.disableSave = false;
                    GeckoUI.messenger.error(err.errors);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Location Save Error', {
                        'location id': ctrl.location.id || 'New',
                        'location name': ctrl.location.name,
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            },
            disabledWhen: function disabledWhen() {
                return ctrl.disableSave;
            }
        }];
    }

    angular.module('GeckoEngage').config(AddLocationModal).controller('AddLocationModalCtrl', AddLocationModalCtrl);
})();