(function () {
    'use strict';

    function SettingsRoute($stateProvider) {
        $stateProvider.state('settings', {
            url: "/settings",
            templateUrl: "/modules/settings/views/index.63860a76.html",
            controller: 'SettingsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Settings',
            requires: [Gecko.ABILITY_USER],
            redirectKey: 'module_settings'
        });
    }

    angular.module('GeckoEngage.routes').config(SettingsRoute);
})();