(function () {
    'use strict';

    function geckoTile() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-tile/gecko-tile.html',
            transclude: true,
            scope: {
                index: '@index',
                img: '@tileImg',
                alt: '@tileAlt',
                title: '@tileTitle',
                color: '@tileColor',
                helpText: '@tileHelpText'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                // Defaults
                if (ctrl.index === undefined) ctrl.index = null;
                if (ctrl.img === undefined) ctrl.img = '';
                if (ctrl.alt === undefined) ctrl.alt = '';
                if (ctrl.title === undefined) ctrl.title = '';
                if (ctrl.color === undefined) ctrl.color = '#304b55';
                if (ctrl.helpText === undefined) ctrl.helpText = '';
            }
        };
    }

    function geckoTiles() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-tile/gecko-tiles.html',
            transclude: true,
            scope: {
                value: '=?',
                tiles: '=?',
                rowAction: '=rowAction'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                // Defaults
                if (ctrl.tiles === undefined) ctrl.tiles = [];

                // Open row
                ctrl.openRow = function (tile, index) {
                    if (!ctrl.rowAction) return false;
                    return ctrl.rowAction(tile);
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoTile', geckoTile).directive('geckoTiles', geckoTiles);
})();