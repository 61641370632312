(function () {
    'use strict';

    function $geckoAudioPlayer() {
        var _this = {
            current: null,
            open: null,
            index: null
        };
        return _this;
    }

    function geckoAudioPlayer($rootScope) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-audio-player/gecko-audio-player.html',
            scope: {
                audio: '=',
                index: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $sce, $element, $geckoAudioPlayer) {
                var ctrl = this;
                var audio;

                // Ctrl.toggleAudio = function() {
                //     If (!ctrl.player) {
                //         Ctrl.player = ngAudio.load(ctrl.audio);
                //         Ctrl.player.paused = true;
                //     };
                //     If (ctrl.player.paused) {
                //         Ctrl.player.play();
                //     } else {
                //         Ctrl.player.pause();
                //     };
                // };

                ctrl.openPlayer = function () {
                    // For use with the audio player with controls
                    // Var dropdown = $('.gecko-audio-player-' + ctrl.index).parent().parent();
                    // $('.gecko-audio-player-' + ctrl.index).parent().parent().toggle();

                    // Set opened audio object
                    $geckoAudioPlayer.open = document.getElementsByClassName('gecko-audio-player-' + ctrl.index)[0];
                    if ($geckoAudioPlayer.current === $geckoAudioPlayer.open) {
                        // Play audio
                        if ($geckoAudioPlayer.current.paused) {
                            $geckoAudioPlayer.current.play();
                        } else {
                            $geckoAudioPlayer.current.pause();
                        }
                        ctrl.player = $geckoAudioPlayer.current;
                    } else {
                        if ($geckoAudioPlayer.current) {
                            $geckoAudioPlayer.current.pause();
                            $geckoAudioPlayer.current.currentTime = 0; // Reset the time to simulate a stop
                        }
                        // Get current audio object
                        $geckoAudioPlayer.current = $geckoAudioPlayer.open;
                        // Play audio
                        $geckoAudioPlayer.current.play();
                        ctrl.player = $geckoAudioPlayer.current;
                    }
                };

                ctrl.getAudioUrl = function () {
                    return $sce.trustAsResourceUrl(ctrl.audio);
                };
            }
        };
    }

    angular.module('GeckoEngage').service('$geckoAudioPlayer', $geckoAudioPlayer).directive('geckoAudioPlayer', geckoAudioPlayer);
})();