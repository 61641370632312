(function () {
    'use strict';

    function WorkflowActionModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('workflowAction', function (_action, _campaigns, _fields, _contact_fields, _labels, _senders, _templates, _type, _users, _organisations, _module, _consents, _integrations, _assignableChatAgents, _tasks) {
            return {
                size: 'md',
                controllerAs: 'ctrl',
                templateUrl: '/components/gecko-workflow/_modals/action.html',
                controller: 'workflowActionsModalCtrl',
                resolve: {
                    action: function action() {
                        return _action;
                    },
                    campaigns: function campaigns() {
                        return _campaigns;
                    },
                    fields: function fields() {
                        return _fields || [];
                    },
                    contact_fields: function contact_fields() {
                        return _contact_fields;
                    },
                    labels: function labels() {
                        return _labels;
                    },
                    senders: function senders() {
                        return _senders;
                    },
                    templates: function templates() {
                        return _templates;
                    },
                    type: function type() {
                        return _type;
                    },
                    module: function module() {
                        return _module;
                    },
                    users: function users() {
                        return _users;
                    },
                    organisations: function organisations() {
                        return _organisations;
                    },
                    consents: function consents() {
                        return _consents;
                    },
                    integrations: function integrations() {
                        return _integrations;
                    },
                    assignableChatAgents: function assignableChatAgents() {
                        return _assignableChatAgents;
                    },
                    tasks: function tasks() {
                        return _tasks;
                    }
                }
            };
        });
    }

    function workflowActionsModalCtrl($scope, $state, action, campaigns, labels, senders, templates, users, organisations, consents, type, fields, contact_fields, integrations, $filter, $modalInstance, OptionsPresets, geckoEventPickerService, module, asyncOptions, assignableChatAgents, $formBuilderService, openTemplatePreviewModal, isWorkflowFieldActionBroken, inlineLabelFieldService, geckoDataService, tasks) {
        var ctrl = this;

        // Either setup the condition from one being passed or create a new one
        if (action) {
            ctrl.action = action;
        } else {
            ctrl.action = { to: 0 };
        }

        if (!ctrl.action.format) {
            ctrl.action.format = 'json';
        }

        // Setup all of the scope variables
        ctrl.campaigns = campaigns.filter(function (campaign) {
            return campaign.module === Gecko.Campaign.MODULE_CALL;
        });
        ctrl.broadcasts = campaigns.filter(function (campaign) {
            return [Gecko.Campaign.MODULE_EMAIL, Gecko.Campaign.MODULE_SMS].indexOf(campaign.module) !== -1;
        });
        ctrl.labels = labels;
        ctrl.senders = senders;
        ctrl.templates = templates;
        ctrl.users = users;
        ctrl.organisations = organisations;
        ctrl.consents = consents;
        ctrl.type = type;
        ctrl.module = module;
        ctrl.integrations = integrations;
        ctrl.assignableChatAgents = assignableChatAgents;
        ctrl.tasks = tasks;

        // Remove unsuitable field types
        var excludeFileTypes = [Gecko.Field.TYPE_NAME, Gecko.Field.TYPE_ADDRESS, Gecko.Field.TYPE_REPEATABLE, Gecko.Field.TYPE_MATRIX, Gecko.Field.TYPE_MEDIA, Gecko.Field.TYPE_ORGANISATION, Gecko.Field.TYPE_EVENT, Gecko.Field.TYPE_FILE, Gecko.Field.TYPE_CONSENT, Gecko.Field.TYPE_SECTION, Gecko.Field.TYPE_SCRIPT];
        ctrl.fields = fields.filter(function (f) {
            return excludeFileTypes.indexOf(f.type) === -1;
        });
        ctrl.contact_fields = contact_fields.filter(function (f) {
            return !f.is_uneditable && !f.is_social && excludeFileTypes.indexOf(f.type) === -1;
        });
        ctrl.isBrokenFieldAction = isWorkflowFieldActionBroken(action, [].concat(ctrl.fields, ctrl.contact_fields));

        // Modify the users to be formatted how we need them
        ctrl.selectUsers = ctrl.users.map(function (user) {
            return {
                id: user.id,
                email: user.email,
                telephone: user.telephone,
                title: user.full_name,
                type: 'Users',
                isTelephone: user.telephone ? true : false,
                isEmail: user.email ? true : false
            };
        });

        var dynamicUsers = [];
        if (ctrl.type === 'form' && ctrl.module === 'form') {
            dynamicUsers = [{
                id: 'captured',
                title: 'Captured By user',
                email: 'captured',
                telephone: 'captured',
                type: 'Dynamic Users',
                isTelephone: false,
                isEmail: true
            }, {
                id: 'assigned',
                title: 'Assigned To user',
                email: 'assigned',
                telephone: 'assigned',
                type: 'Dynamic Users',
                isTelephone: false,
                isEmail: true
            }];
        }
        var contactFields = [];
        // Add contact fields to users array
        angular.forEach(ctrl.fields, function (field) {
            // If this field is of type email or tel, then it can be used in workflows
            if (!GeckoUI.getObjectByKey(ctrl.selectUsers, 'id', "field:" + field.id) && (field.type == 'email' || field.type == 'tel' || field.type == 'hidden')) {
                contactFields.push({
                    id: "field:" + field.id,
                    title: field.label,
                    email: "field:" + field.id,
                    telephone: "field:" + field.id,
                    type: ctrl.type == 'form' ? 'Fields' : 'Contact Fields',
                    field_type: field.type,
                    isTelephone: field.type == 'tel' || field.type == 'hidden',
                    isEmail: field.type == 'email' || field.type == 'hidden'
                });
            }
            if (field.values === null) field.values = [];
            if (field.sub_fields === null) field.sub_fields = [];
        });

        // Sigh, this is so fuckin' plebby :(
        if (ctrl.type == 'form' && ctrl.module === 'call' && ctrl.contact_fields && ctrl.contact_fields.length) {
            angular.forEach(ctrl.contact_fields, function (field) {
                // If this field is of type email or tel, then it can be used in workflows
                if (!GeckoUI.getObjectByKey(ctrl.selectUsers, 'id', "field:" + field.id) && (field.type == 'email' || field.type == 'tel' || field.type == 'hidden')) {
                    contactFields.push({
                        id: "field:" + field.id,
                        title: field.label,
                        email: "field:" + field.id,
                        telephone: "field:" + field.id,
                        type: 'Contact Fields',
                        field_type: field.type,
                        isTelephone: field.type == 'tel' || field.type == 'hidden',
                        isEmail: field.type == 'email' || field.type == 'hidden'
                    });
                }
                if (field.values === null) field.values = [];
                if (field.sub_fields === null) field.sub_fields = [];
            });
        }

        ctrl.selectUsers = contactFields.concat(dynamicUsers, ctrl.selectUsers);

        // Email to_manual array, (emails that are not in users array etc)
        ctrl.action.to_manual = [];
        if (angular.isArray(ctrl.action.to) && [Gecko.Workflow.ACTION_EMAIL].indexOf(ctrl.action.type) !== -1) {
            ctrl.action.to = (ctrl.action.to || []).filter(function (a) {
                if (ctrl.selectUsers.find(function (u) {
                    return u.email === a;
                })) {
                    return a;
                }
                // Drop option into to_manual array
                if (ctrl.action.to_manual.indexOf(a) === -1) ctrl.action.to_manual.push(a);
            });
        }

        // Reformat templates to templates usable
        ctrl.templateUseable = {};
        angular.forEach(ctrl.templates, function (template) {

            var responseRE = /{{\s*response\s*\./;

            ctrl.templateUseable[template.id] = !GeckoUI.extractMustacheFields(template.body).some(function (tag) {
                return responseRE.test(tag);
            });
        });

        // Prepare senders arrays
        ctrl.senders_sms = ctrl.senders.filter(function (s) {
            return s.type !== Gecko.Sender.EMAIL && s.outgoing_sms;
        });
        ctrl.senders_email = ctrl.senders.filter(function (s) {
            return s.type === Gecko.Sender.EMAIL;
        });

        // Add GeckoChat groupby property to sms senders
        ctrl.senders_sms = ctrl.senders_sms.map(function (s) {
            if (s.type === Gecko.Sender.GECKOCHAT) s.groupby = 'GeckoChat';
            return s;
        });

        var verifiedStr = function verifiedStr(isVerified) {
            return isVerified ? 'Verified' : 'Not Verified';
        };

        // Sort email senders by verified / not verified
        ctrl.senders_email = ctrl.senders_email.map(function (sender) {
            sender.groupby = verifiedStr(sender.verified);
            return sender;
        });

        if (ctrl.type === 'form') {
            var addDynamicSenders = function addDynamicSenders(isVerified) {
                var dynamicSenders = [{
                    id: 'captured',
                    name: 'The User capturing the Response'
                }, {
                    id: 'assigned',
                    name: 'The User the Response is assigned to'
                }];
                dynamicSenders = dynamicSenders.map(function (s) {
                    s.groupby = verifiedStr(isVerified);
                    return s;
                });
                ctrl.senders_email = dynamicSenders.concat(ctrl.senders_email);
            };

            // Check account has at least ONE verified domain
            new Gecko.VerifiedDomain().rfields({ verifieddomain: ['verified'] }).where('verified', 1).perPage(1).get().then(function (domains) {
                addDynamicSenders(domains.toArray().length);
                $scope.$digest();
            });
        }
        ctrl.senders_sms.unshift({
            id: 'default',
            name: 'Default'
        });

        // Prepare sender option labels
        ctrl.senders_email = ctrl.senders_email.map(function (s) {
            if (s.email) s.optionName = s.name + ' <' + s.email + '> ' + (!s.verified ? '(Not Verified)' : '');
            return s;
        });
        ctrl.senders_sms = ctrl.senders_sms.map(function (s) {
            s.optionName = s.name + ' <' + s.tel + '>';
            return s;
        });

        // Action types that are available
        if (Gecko.has('feature_sms')) {
            ctrl.actionTypes = [{
                value: 'email',
                label: 'Email'
            }, {
                value: 'sms',
                label: 'SMS'
            }, {
                value: 'campaign',
                label: 'Add to campaign',
                requires: Gecko.ABILITY_CALL_CAMPAIGNS_UPDATE
            }, {
                value: 'broadcast',
                label: 'Add to broadcast',
                requires: $state.current.name === 'event.workflows' ? Gecko.ABILITY_EVENTS_AUTO_MARKETING_MSG : Gecko.ABILITY_BROADCASTS_UPDATE
            }, {
                value: 'assign_contact_label',
                label: 'Add label to contact'
            }, {
                value: 'remove_contact_label',
                label: 'Remove label from contact'
            }, {
                value: 'attendance',
                label: 'Add contact to event'
            }, {
                value: 'enrolment',
                label: 'Add contact to organisation'
            }, {
                value: 'engagement_score',
                label: 'Update a contact\'s engagement score',
                requires: Gecko.ABILITY_ENGAGEMENT_SCORE
            }, {
                value: 'contact_task',
                label: 'Update task and objective',
                requires: Gecko.ABILITY_TASKS_VIEW
            }];
        } else {
            ctrl.actionTypes = [{
                value: 'email',
                label: 'Email'
            }, {
                value: 'sms',
                label: 'SMS'
            }, {
                value: 'campaign',
                label: 'Add to campaign',
                requires: Gecko.ABILITY_CALL_CAMPAIGNS_UPDATE
            }, {
                value: 'assign_contact_label',
                label: 'Add label to contact'
            }, {
                value: 'remove_contact_label',
                label: 'Remove label from contact'
            }, {
                value: 'attendance',
                label: 'Add contact to event'
            }, {
                value: 'enrolment',
                label: 'Add contact to organisation'
            }, {
                value: 'engagement_score',
                label: 'Update a contact\'s engagement score',
                requires: Gecko.ABILITY_ENGAGEMENT_SCORE
            }, {
                value: 'contact_task',
                label: 'Update task and objective',
                requires: Gecko.ABILITY_TASKS_VIEW
            }];
        }

        if (ctrl.type === 'form') {
            ctrl.actionTypes = ctrl.actionTypes.concat([{
                value: 'assign_user',
                label: 'Assign to user'
            }, {
                value: 'assign_label',
                label: 'Add label to response'
            }, {
                value: 'remove_label',
                label: 'Remove label from response'
            }, {
                value: 'webhook',
                label: 'Send a webhook'
            }, {
                value: 'set_response_value',
                label: 'Update response field contents'
            }]);

            // will be null if no integration is set up
            if (ctrl.assignableChatAgents) {
                ctrl.actionTypes = ctrl.actionTypes.concat([{
                    value: 'new_chat_system_information_message',
                    label: 'Start Conversation In GeckoChat'
                }]);
            }
        }

        if (ctrl.type === 'event') {
            ctrl.actionTypes = ctrl.actionTypes.concat([{
                value: 'webhook',
                label: 'Send a webhook'
            }]);
        }

        if (ctrl.type === 'recurring_import') {
            ctrl.actionTypes = [{
                value: 'assign_contact_label',
                label: 'Add label to contact'
            }, {
                value: 'remove_contact_label',
                label: 'Remove label from contact'
            }, {
                value: 'attendance',
                label: 'Add contact to event'
            }, {
                value: 'enrolment',
                label: 'Add contact to organisation'
            }, {
                value: 'contact_task',
                label: 'Update task and objective',
                requires: Gecko.ABILITY_TASKS_VIEW
            }];
        }

        ctrl.actionTypes = ctrl.actionTypes.concat([{
            value: 'set_contact_value',
            label: 'Update contact field contents'
        }, {
            value: 'sync_crm',
            label: 'Sync To CRM'
        }]);

        // SMS a contact field
        ctrl.numberToSmsOptions = ctrl.contact_fields.filter(function (f) {
            return f.type === Gecko.Field.TYPE_TEL;
        }).map(function (f) {
            return {
                value: f.id,
                label: f.label
            };
        });

        if (Gecko.able(Gecko.ABILITY_CONSENTS_VIEW)) {
            ctrl.actionTypes = ctrl.actionTypes.concat([{
                value: Gecko.Workflow.ACTION_MANAGE_CONSENT,
                label: 'Assign consent value(s)'
            }]);
        }

        ctrl.actionConsentOperatorOptions = [{
            id: Gecko.Workflow.ACTION_MANGE_CONSENT_OPERATOR_ADD,
            label: 'Give consent to'
        }, {
            id: Gecko.Workflow.ACTION_MANGE_CONSENT_OPERATOR_REMOVE,
            label: 'Withdraw consent from'
        }];

        var broadcastById = function broadcastById(id) {
            return function (broadcast) {
                return broadcast.id === id;
            };
        };
        // Set up actionType.  Used as a proxy for ctrl.action.type to handle campaigns and broadcasts
        if (ctrl.action.type === 'campaign' && ctrl.action.to && ctrl.broadcasts.find(broadcastById(action.to))) {
            // If it's a campaign and the campaign is a broadcast
            ctrl.actionType = 'broadcast';
        } else {
            ctrl.actionType = ctrl.action.type;
        }
        // Watch for changes to ctrl.actionType if it gets set to broadcast set ctrl.action.type to campaign otherwise
        // Just pass the value straight through to ctrl.action.type
        $scope.$watch(function () {
            return ctrl.actionType;
        }, function (newVal, prevVal) {
            if (newVal !== prevVal) {
                ctrl.action.to = undefined;
                // Reset false label field when action type changes :(
                ctrl.labelAsyncFieldValue.labels = [];
            }
            if (newVal == 'email' && ctrl.type == 'event' && !GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_AUTO_MARKETING_MSG) || newVal == 'sms' && ctrl.type == 'form' && !GeckoUI.ableWithReason(Gecko.ABILITY_FORMS_INSTANT_SMS) || newVal == 'sms' && ctrl.type == 'event' && !GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_AUTO_MARKETING_MSG)) {
                return ctrl.actionType = prevVal;
            }
            ctrl.action.type = newVal === 'broadcast' ? 'campaign' : newVal;
        });

        Object.defineProperty(ctrl.action, 'senderProxy', {
            get: function get() {
                return ctrl.action.sender || ctrl.action.sender_id;
            },
            set: function set(newVal) {
                if (newVal === 'captured' || newVal === 'assigned') {
                    ctrl.action.sender = newVal;
                    ctrl.action.sender_id = undefined;
                } else {
                    ctrl.action.sender_id = newVal;
                    ctrl.action.sender = undefined;
                }
            }
        });

        $scope.$watch(function () {
            return ctrl.action.type;
        }, function (newVal, prevVal) {
            // Set default template when action type is email
            if (newVal !== prevVal && newVal === 'email') {
                if (ctrl.action.template === undefined) ctrl.action.template = 0;
            }
            // Set to to 'automatic' by default
            if (newVal !== prevVal && newVal === 'sms') {
                if (ctrl.action.field_id === undefined) ctrl.action.field_id = null;
            }
        });

        var isBlank = function isBlank(value) {
            return value === null || value === undefined || value === '' || Array.isArray(value) && value.length === 0;
        };

        var isIn = function isIn(value, isInOptions) {
            return isInOptions.options.find(function (option) {
                return option[isInOptions.key] == value;
            });
        };
        // Takes an action and a list of validations, returns an object where the validated property tells
        // You if any validations failed and the messages property is an array of errors
        var validateAction = function validateAction(action, validations) {
            var defaultValidation = { validated: true, messages: [] };
            if (!validations) {
                return defaultValidation;
            }
            return validations.reduce(function (validation, property) {
                if (property.required && isBlank(action[property.model]) || property.isIn && !isIn(action[property.model], property.isIn)) {
                    return { validated: false, messages: validation.messages.concat([property.message]) };
                }
                return validation;
            }, defaultValidation);
        };

        var isTemplateType = function isTemplateType(type) {
            return function (template) {
                return template.type === type;
            };
        };
        var isEmailTemplate = isTemplateType('email');
        var isSmsTemplate = isTemplateType('sms');

        var validations = {
            email: [{ model: 'to', required: true, message: "Recipients is required." }, { model: 'template', required: true, message: "The 'Template' field is required." }, { model: 'template', isIn: { key: 'id', options: ctrl.templates.filter(isEmailTemplate) }, message: "You must select an Email template for the 'Template' field." }, { model: 'sender_id', required: true, message: "The 'From' field is required." }],
            assign_user: [{ model: 'to', required: true, message: "The 'To' field is required." }],
            sms: [{ model: 'template', required: true, message: "The 'Template' field is required." }, { model: 'template', isIn: { key: 'id', options: ctrl.templates.filter(isSmsTemplate) }, message: "You must select an SMS template for the 'Template' field." }, { model: 'sender_id', required: true, message: "The 'From' field is required." }, { model: 'sender_id', isIn: { key: 'id', options: ctrl.senders_sms }, message: "You must select a valid SMS sender for the 'From' field." }],
            campaign: [{ model: 'to', required: true, message: "The 'Campaign' field is required." }, { model: 'to', isIn: { key: 'id', options: ctrl.campaigns }, message: "You must select a Campaign for the 'Campaign' field." }],
            broadcast: [{ model: 'to', required: true, message: "The 'Broadcast' field is required." }, { model: 'to', isIn: { key: 'id', options: ctrl.broadcasts }, message: "You must select a Broadcast for the 'Broadcast' field." }],
            assign_label: [{ model: 'to', required: true, message: "The 'Label' field is required." }],
            remove_label: [{ model: 'to', required: true, message: "The 'Label' field is required." }],
            assign_contact_label: [{ model: 'to', required: true, message: "The 'Label' field is required." }],
            remove_contact_label: [{ model: 'to', required: true, message: "The 'Label' field is required." }],
            webhook: [{ model: 'url', required: true, message: "The 'URL' field is required." }],
            attendance: [{ model: 'to', required: true, message: "The 'Event' field is required." }, { model: 'status', required: true, message: "The 'Status' field is required." }],
            enrolment: [{ model: 'to', required: true, message: "The 'Organisation' field is required." }],
            sync_crm: [{ model: 'integration_id', required: true, message: "The 'Integration' field is required." }],
            set_response_value: [{ model: 'field_id', required: true, message: "The 'Field' field is required." }],
            set_contact_value: [{ model: 'field_id', required: true, message: "The 'Field' field is required." }],
            manage_consent: [{ model: 'operator', required: true, message: "The 'Operator' field is required." }, { model: 'to', required: true, message: "The 'Consent Reasons' field is required." }],
            contact_task: [{ model: 'to', required: true, message: "The 'Task' field is required." }, { model: 'status', required: true, message: "The 'Status' field is required." }]

            // Footer Save/Add button
        };ctrl.footerBtns = [];

        ctrl.footerBtns.push({
            id: 'save',
            title: ctrl.action.type ? 'Save Action' : 'Add Action',
            preset: ctrl.action.type ? 'save' : 'add',
            btnClass: 'btn-primary',
            action: function action() {
                if (!ctrl.actionType) {
                    return ctrl.alertMessage = 'You must select an action Type.';
                }

                // If to_manual are set, add them to main to array (and remove duplicates)
                if (ctrl.action.type === 'email') {
                    var toManual = (ctrl.action.to_manual || []).filter(function (e) {
                        return (ctrl.action.to || []).indexOf(e) === -1;
                    });
                    ctrl.action.to = (ctrl.action.to || []).concat(toManual);
                }

                if (ctrl.action.type !== 'webhook') {
                    ctrl.action.format = null;
                }

                var validation = validateAction(ctrl.action, validations[ctrl.actionType]);
                if (!validation.validated) {
                    return ctrl.alertMessage = GeckoUI.renderError(validation.messages);
                }

                // Check if event session is picked, a session time is included also.
                if (ctrl.actionType == 'attendance' && !geckoEventPickerService.sessionCheck(ctrl.eventField, ctrl.action.to)) return;

                var response = {};
                response.action = ctrl.action;
                response.method = 'save';
                $modalInstance.close(response);
            }
        });

        if (ctrl.action.type) {
            ctrl.footerBtns.push({
                id: 'remove',
                title: 'Remove Action',
                preset: 'remove',
                position: 'secondary',
                action: function action() {
                    if (!ctrl.action.type) {
                        return false;
                    }
                    var response = {};
                    response.action = ctrl.action;
                    response.method = 'delete';
                    $modalInstance.close(response);
                }
            });
        }

        ctrl.resetAttendanceAction = function () {
            if (ctrl.action.to) {
                delete ctrl.action.to;
            }
        };

        ctrl.availableActionTypes = function () {

            var filteredActionTypes = ctrl.actionTypes.filter(function (action) {
                if (action.requires) {
                    return Gecko.able(action.requires);
                }
                return true;
            });

            // Sort them by label, alphabetically
            // that random action list gave me serious UX anxiety
            return filteredActionTypes.sort(function (a, b) {
                return a.label.localeCompare(b.label);
            });
        };

        // Label field
        ctrl.labelAsyncFieldValue = {
            labels: []
        };

        var labelAsyncFieldInit = false;

        ctrl.labelAsyncField = inlineLabelFieldService.buildField({
            obj: ctrl.labelAsyncFieldValue,
            id: 'labels',
            label: 'Label',
            required: true,
            options: [],
            maxLimit: 1,
            getOptions: function getOptions($select) {
                inlineLabelFieldService.buildLabelFieldOptions($select, function (fetchedOptions) {
                    // Build initial field value
                    if (!labelAsyncFieldInit) {
                        ctrl.labelAsyncFieldValue.labels = ctrl.action.to ? [fetchedOptions.find(function (l) {
                            return l.id === Number(ctrl.action.to);
                        })] : [];
                        labelAsyncFieldInit = true;
                    }
                }, function (newOptions) {
                    // Update field options
                    ctrl.labelAsyncField.options = newOptions;
                });
            },
            newItem: function newItem(labelName) {
                // ONLY allow 1 label to be created
                if (ctrl.labelAsyncFieldValue.labels.length) return;

                return inlineLabelFieldService.newLabel(labelName, ctrl.labelAsyncField.options);
            },
            onSelect: function onSelect(label, $select) {
                return inlineLabelFieldService.selectLabel(label).then(function (label) {
                    return geckoDataService.fetch(['labels']).then(function (data) {
                        ctrl.labelAsyncField.options = data.labels;
                        // Update labels data in main conditions UI
                        ctrl.labels = data.labels;
                        $scope.$digest();
                    }).then(function () {
                        return label;
                    });
                }).then(function (label) {
                    ctrl.action.to = label.id;
                    ctrl.labelAsyncFieldValue.labels = [label];
                });
            },
            onRemove: function onRemove() {
                // Reset label id on action object
                ctrl.action.to = null;
                ctrl.labelAsyncFieldValue.labels = [];
            }
        });

        // Event field
        ctrl.eventField = geckoEventPickerService.prepareField({
            options: [null, null],
            optionsKey: 'id',
            optionsLabelKey: 'nice_title',
            optionsInit: OptionsPresets.openEvents.init,
            getOptions: OptionsPresets.openEvents.get,
            placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –']
        });

        // Organisation field
        var buildOrganisationField = function buildOrganisationField(options) {
            return ctrl.organisationField = {
                id: 'to',
                label: 'Organisation',
                type: Gecko.Field.TYPE_SELECT,
                options: options || [],
                getOptions: asyncOptions.create(new Gecko.Organisation().orderBy('title').rfields({ organisation: ['title', 'address'] })),
                choiceTemplate: 'organisation-choice',
                orderBy: 'title',
                optionsKey: 'id',
                optionsLabelKey: 'title',
                required: true
            };
        };

        if (action && action.type === 'enrolment' && action.to) {
            new Gecko.Organisation().get(action.to).then(function (org) {
                buildOrganisationField([org]);
                $scope.$digest();
            }).catch(function (err) {
                return buildOrganisationField();
            });
        } else {
            buildOrganisationField();
        }

        // Tasks field
        ctrl.taskField = {
            id: 'to',
            label: 'Task',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.tasks,
            orderBy: 'order',
            optionsKey: 'id',
            optionsLabelKey: 'name',
            required: true
        };

        // Task status field
        ctrl.taskStatusField = {
            id: 'status',
            label: 'Status',
            type: Gecko.Field.TYPE_SELECT,
            options: Object.keys(Gecko.Task.task_statuses).map(function (status) {
                return { id: status, label: Gecko.Task.task_statuses[status] };
            }),
            orderBy: 'order',
            optionsKey: 'id',
            optionsLabelKey: 'label',
            required: true
        };

        ctrl.ignoreConsentField = {
            id: 'ignore_consents',
            label: 'Ignore Consent Preferences',
            type: Gecko.Field.TYPE_TOGGLE,
            showWhen: function showWhen() {
                var primaryCheck = Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) && !Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK) && [Gecko.Workflow.ACTION_EMAIL, Gecko.Workflow.ACTION_SMS].indexOf(ctrl.action.type) !== -1;

                if (!primaryCheck) return false;

                return primaryCheck;
            }
        };

        ctrl.attachIcsFile = {
            id: 'attach_ics',
            label: 'Attach ICS File',
            type: Gecko.Field.TYPE_TOGGLE,
            showWhen: function showWhen() {
                var emailContactFieldSelected = function emailContactFieldSelected() {
                    var emailContactFields = ctrl.selectUsers.filter(function (user) {
                        return user.type === 'Contact Fields';
                    }).map(function (user) {
                        return user.email;
                    });

                    return emailContactFields.some(function (field) {
                        return ((ctrl.action || {}).to || []).includes(field);
                    });
                };

                var primaryCheck = ctrl.type === 'event' && ctrl.action.type === Gecko.Workflow.ACTION_EMAIL && emailContactFieldSelected();

                if (!primaryCheck) return false;

                return primaryCheck;
            }
        };

        ctrl.multiCreateOptions = {
            create: true,
            maxItems: Infinity,
            placeholder: 'Email Addresses'
        };

        ctrl.currentValueField = function () {
            var findFunc = function findFunc(field) {
                return field.id === ctrl.action.field_id;
            };
            if (ctrl.action.type === 'set_contact_value') {
                return ctrl.contact_fields.find(findFunc);
            } else if (ctrl.action.type === 'set_response_value') {
                return ctrl.fields.find(findFunc);
            }
        };

        var optionsMap = {};

        ctrl.prepareOptions = function () {
            var field = ctrl.currentValueField();
            if (field && (field || {}).values) {
                // Plebby performance fix :(
                if (!optionsMap[field.id]) optionsMap[field.id] = $formBuilderService.prepareOptions(field, field.values);
                return optionsMap[field.id];
            }
            return [];
        };

        ctrl.openTemplatePreviewModal = openTemplatePreviewModal;
    }

    angular.module('GeckoEngage').config(WorkflowActionModal).controller('workflowActionsModalCtrl', workflowActionsModalCtrl);
})();