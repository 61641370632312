(function () {
    'use strict';

    var generateCampaignBroadcast = function generateCampaignBroadcast($state, $stateParams, $filter, ctrl, campaign, vars) {
        // Status Titles
        var _statTitles = {};
        _statTitles[Gecko.Subscriber.SCHEDULED] = 'Scheduled';
        _statTitles[Gecko.Subscriber.AVAILABLE] = vars.availableTitle;
        _statTitles[Gecko.Subscriber.IN_PROGRESS] = 'In Progress';
        _statTitles[Gecko.Subscriber.SUCCESS] = 'Succeeded';
        _statTitles[Gecko.Subscriber.FAIL] = 'Failed';
        _statTitles[Gecko.Subscriber.DATA_PROBLEM] = _statTitles[Gecko.Subscriber.DATA_PROBLEM] = Gecko.Subscriber.status_titles.find(function (s) {
            return s.id === Gecko.Subscriber.DATA_PROBLEM;
        }).title;

        var excludedStatusTypes = [Gecko.Subscriber.DO_NOT_CALL, Gecko.Subscriber.DATA_PROBLEM, Gecko.Subscriber.REMOVED, Gecko.Subscriber.NO_CONSENT, Gecko.Subscriber.NO_PHONE_NUMBER, Gecko.Subscriber.UNUSEABLE_NUMBER];

        // Build Stats
        ctrl.stats = vars.statusTypes.map(function (status, index) {
            // Build overall 'Excluded' total
            if (status === Gecko.Subscriber.DATA_PROBLEM) {
                _stat = {
                    aggregate: 0,
                    status: status
                };

                // Find macthing stat result and add to overall aggregate
                var results = Array.isArray(ctrl.stat.result) ? ctrl.stat.result : [];
                excludedStatusTypes.forEach(function (s) {
                    var excludedStat = results.find(function (r) {
                        return r.status === s;
                    });
                    if (excludedStat && excludedStat.aggregate) _stat.aggregate += excludedStat.aggregate || 0;
                });

                // Add stat action
                _stat.action = function () {
                    return $state.go('campaignsedit.view', { campaign_id: ctrl.campaign.id, status: excludedStatusTypes });
                };
                // Only show active if ALL status are in the excludedStatuses array
                _stat.activeWhen = function (stat) {
                    if (Array.isArray($stateParams.status)) {
                        return $stateParams.status.filter(function (s) {
                            return excludedStatusTypes.indexOf(Number(s)) != -1;
                        }).length === excludedStatusTypes.length;
                    }
                    return false;
                };
            } else {
                // Get stat
                var _stat = GeckoUI.getObjectByKey(ctrl.stat.result, 'status', status) || { aggregate: 0, status: status };
                if (!_stat) {
                    _stat = {
                        aggregate: 0,
                        status: status
                    };
                }
                _stat.action = function () {
                    return $state.go('campaignsedit.view', { campaign_id: ctrl.campaign.id, status: _stat.status });
                };
                _stat.activeWhen = function (stat) {
                    return stat.status == $stateParams.status;
                };
            }
            // Add data
            _stat.order = index;
            _stat.title = _statTitles[status];
            // Push to the return array
            return _stat;
        });

        // Status title
        ctrl.statusTitle = function (subscriber) {
            if (subscriber.status == Gecko.Subscriber.AVAILABLE && ctrl.campaign.module == Gecko.Campaign.MODULE_SMS) {
                return 'Scheduled';
            } else if (subscriber.status == Gecko.Subscriber.SCHEDULED) {
                return GeckoUI.getObjectByKey(Gecko.Subscriber.status_titles, 'id', subscriber.status).title + ' for ' + $filter('formatDate')(subscriber.call_at, Gecko.dateFormat.shortTime);
            }
            return GeckoUI.getObjectByKey(Gecko.Subscriber.status_titles, 'id', subscriber.status).title;
        };

        // Table structure
        var _campaign_fields = $filter('filter')(ctrl.campaign.related_campaign_fields, { list_view: 1 });
        ctrl.cols = [{
            key: 'status',
            expandable: true,
            status_title_none: true,
            status_styles: Gecko.Subscriber.status_titles,
            colClass: 'text-center',
            render: function render(value, col, subscriber) {
                if (subscriber && subscriber.status == Gecko.Subscriber.SCHEDULED && subscriber.call_at) {
                    return 'Call from ' + $filter('formatDate')(subscriber.call_at, Gecko.dateFormat.shortTime);
                }
                return false;
            }
        }];
        // Add list view fields as columns
        angular.forEach(_campaign_fields, function (col, index) {
            ctrl.cols.push({
                type: col.related_field.type,
                data_type: col.related_field.data_type,
                default_value: col.related_field.label.indexOf('Last') > -1 && col.related_field.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP ? 'Never' : '',
                title: col.related_field.label,
                key: 'field_' + (index + 1),
                sortKey: 'contact.' + col.field_id,
                colClass: 12 % _campaign_fields.length == 0 ? 'col-md-' + 12 / _campaign_fields.length : '',
                utc: !col.related_field.system && col.related_field.type == Gecko.Field.TYPE_DATE,
                date_format: !col.related_field.system && col.related_field.type == Gecko.Field.TYPE_DATE ? Gecko.dateFormat.short : undefined
            });
        });

        ctrl.rowAction = {
            state: 'contact.subscriber',
            params: { contact_id: 'contact_id', subscriber_id: 'id' }
        };

        // Table filters
        var objectsToOptions = function objectsToOptions(labelKey) {
            return function (objects) {
                return objects.map(function (o) {
                    return { value: o.id, label: o[labelKey] };
                });
            };
        };

        var statuses = angular.copy(Gecko.Subscriber.status_titles).filter(function (s) {
            return s[ctrl.campaign.module] === 1;
        });
        // Rename 'excluded' again...to make it less confusing
        statuses.find(function (s) {
            return s.id === Gecko.Subscriber.DATA_PROBLEM;
        }).title = 'Data Problem';
        ctrl.filters = [{
            title: 'Status',
            type: 'checkbox',
            options: objectsToOptions('title')(statuses),
            stateParam: 'status'
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: vars.breadcrumbLabel,
            click: function click() {
                $state.go('campaigns', { module: $stateParams.module });
            }
        }, {
            label: ctrl.campaign.title,
            active: true
        }];
    };

    function CampaignCtrl($rootScope, $filter, $state, $stateParams, campaign, subscribers, scheduledSubscribers, stat) {
        var ctrl = this;
        ctrl.campaign = campaign;
        ctrl.subscribers = subscribers.toArray();
        ctrl.pagination = subscribers.pagination;
        ctrl.stat = stat;
        ctrl.module = $stateParams.module;

        if (scheduledSubscribers) {
            ctrl.scheduledSubscribers = scheduledSubscribers.toArray();
            ctrl.scheduledPagination = scheduledSubscribers.pagination;
            ctrl.scheduledPagination.param = 'page_scheduled';
        }

        // Module specific variables
        generateCampaignBroadcast($state, $stateParams, $filter, ctrl, campaign, {
            statusTypes: [Gecko.Subscriber.AVAILABLE, Gecko.Subscriber.IN_PROGRESS, Gecko.Subscriber.SCHEDULED, Gecko.Subscriber.SUCCESS, Gecko.Subscriber.DATA_PROBLEM],
            availableTitle: 'Available',
            breadcrumbLabel: 'Campaigns'
        });
    }

    angular.module('GeckoEngage').controller('CampaignCtrl', CampaignCtrl);
})();