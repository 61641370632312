function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function () {
    'use strict';

    var _mimetypeGroups;

    var defaultAllowedMimetypes = ['image/*', 'audio/*', 'video/*', 'application/pdf',
    //'image/jpeg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //'image/png',
    //'video/mp4',
    'application/msword', 'application/zip',
    //'video/quicktime',
    'application/octet-stream',
    //'unknown',
    //'audio/x-m4a',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'text/plain', 'application/vnd.oasis.opendocument.text',
    //'image/heic',
    'text/html',
    //'image/tiff',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.documentapplication/vnd.openxmlformats-officedocument.wordprocessingml.document', 'message/rfc822', 'text/rtf', 'application/CDFV2-unknown', 'inode/x-empty',
    //'video/3gpp',
    //'image/gif',
    'application/vnd.ms-outlook',
    //'image/webp',
    'application/x-zip-compressed',
    //'audio/mpeg',
    //'image/x-ms-bmp',
    //'image/bmp',
    'application/x-rar', 'application/vnd.ms-excel',
    /* The following file types have been collected > 100 times */
    //'application/CDFV2-corrupt',
    //'application/CDFV2',
    //'audio/x-wav',
    //'audio/x-hx-aac-adts',
    'application/vnd.oasis.opendocument.presentation', 'application/vnd.ms-office',
    //'application/x-empty',
    //'video/x-m4v',
    //'application/dicom',
    'application/wps-office.docx',
    //'application/x-webarchive',
    //'image/x-eps',
    //'application/postscript',
    //'image/psd',
    //'image/jp2',
    'application/vnd.ms-powerpoint', 'text/xml',
    //'video/x-msvideo',
    //'application/xml',
    //'image/svg+xml',
    //'image/vnd.wap.wbmp',
    //'application/x-extension-pdf',
    //'multipart/related',
    //'pdf reader',
    //'video/x-ms-asf',
    //'application/x-7z-compressed',
    //'application/encrypted',
    //'audio/aac',
    //'audio/ogg',
    //'application/x-iwork-pages-sffpages',
    //'video/mpeg',
    //'application/vnd.google-apps.document',
    //'audio/x-aiff',
    //'audio/mp4',
    //'pdf',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    //'video/x-matroska',
    //'text/calendar',
    //'audio/wav',
    //'application/rtf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    //'binary/octet-stream',
    //'video/webm',
    'application/vnd.ms-xpsdocument',
    //'application/x-msdownload',
    //'image/svg',
    //'application/CDFV2-encrypted',
    //'application/x-dosexec',
    //'application/x-iwork-keynote-sffkey',
    //'text/x-Algol68',
    //'text/vcard',
    //'application/x-gzip',
    //'audio/vnd.dlna.adts',
    //'application/vnd.apple.pkpass',
    //'application/x-hwp',
    'application/vnd.oasis.opendocument.graphics', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.ms-powerpoint.presentation.macroEnabled.12', 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    //'application/x-x509-ca-cert',
    //'text/css',
    //'application/x-shockwave-flash',
    //'application/kswps',
    'application/vnd.oasis.opendocument.text-template', 'application/wps-office.doc', 'application/vnd.ms-word.document.macroEnabled.12', 'text/csv',
    //'application/wps-writer',
    'application/vnd.ms-excel.sheet.macroenabled.12',
    //'text/x-c',
    //'video/3gpp2',
    //'audio/midi',
    //'application/x-musescore',
    //'text/x-php',
    'application/vnd.ms-visio.drawing'].join(',');

    var defaultAllowedCsvMimetypes = ['.csv', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].join(',');

    var MIMETYPE_GROUP_AUDIO = 'audio';
    var MIMETYPE_GROUP_DOCUMENT = 'document';
    var MIMETYPE_GROUP_IMAGE_ALL = 'image_all';
    var MIMETYPE_GROUP_IMAGE_BASIC = 'image_basic';
    var MIMETYPE_GROUP_VIDEO = 'video';
    var MIMETYPE_GROUP_ZIP = 'zip';

    var mimetypeGroupsOptions = [{
        id: MIMETYPE_GROUP_AUDIO,
        name: 'Audio'
    }, {
        id: MIMETYPE_GROUP_DOCUMENT,
        name: 'Document'
    }, {
        id: MIMETYPE_GROUP_IMAGE_ALL,
        name: 'Image (all)'
    }, {
        id: MIMETYPE_GROUP_IMAGE_BASIC,
        name: 'Image (jpg, png and gif only)'
    }, {
        id: MIMETYPE_GROUP_VIDEO,
        name: 'Video'
    }, {
        id: MIMETYPE_GROUP_ZIP,
        name: 'Zip'
    }];

    var mimetypeGroups = (_mimetypeGroups = {}, _defineProperty(_mimetypeGroups, MIMETYPE_GROUP_AUDIO, ['audio/*']), _defineProperty(_mimetypeGroups, MIMETYPE_GROUP_DOCUMENT, ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'text/plain', 'application/vnd.oasis.opendocument.text', 'text/html', 'application/vnd.openxmlformats-officedocument.wordprocessingml.documentapplication/vnd.openxmlformats-officedocument.wordprocessingml.document', 'message/rfc822', 'text/rtf', 'application/CDFV2-unknown', 'inode/x-empty', 'application/vnd.ms-outlook', 'application/vnd.ms-excel',
    /* The following file types have been collected > 100 times */
    'application/vnd.oasis.opendocument.presentation', 'application/vnd.ms-office', 'application/wps-office.docx', 'application/vnd.ms-powerpoint', 'text/xml', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', 'application/vnd.ms-xpsdocument', 'application/vnd.oasis.opendocument.graphics', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.ms-powerpoint.presentation.macroEnabled.12', 'application/vnd.ms-powerpoint.presentation.macroenabled.12', 'application/vnd.oasis.opendocument.text-template', 'application/wps-office.doc', 'application/vnd.ms-word.document.macroEnabled.12', 'text/csv', 'application/vnd.ms-excel.sheet.macroenabled.12', 'application/vnd.ms-visio.drawing']), _defineProperty(_mimetypeGroups, MIMETYPE_GROUP_IMAGE_ALL, ['image/*']), _defineProperty(_mimetypeGroups, MIMETYPE_GROUP_IMAGE_BASIC, ['image/jpeg', 'image/png', 'image/gif']), _defineProperty(_mimetypeGroups, MIMETYPE_GROUP_VIDEO, ['video/*']), _defineProperty(_mimetypeGroups, MIMETYPE_GROUP_ZIP, ['application/zip', 'application/x-zip-compressed', 'application/x-rar']), _mimetypeGroups);

    function geckoFileUploadService(geckoTranslateService, portalRequestService) {

        var _this = {

            random: function random(length) {
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                var str = '';
                for (var i = 0; i < length; i++) {
                    str += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return str;
            },

            sanatizeFileName: function sanatizeFileName(str) {
                return str.toLowerCase().replace(/\s/gi, '_').replace(/[^a-z0-9\-\_\.]/gi, '') || _this.random(10);
            },

            uploadFiles: function uploadFiles(files) {
                return Promise.all(files.map(_this.uploadFile)).catch(function (err) {
                    throw err;
                });
            },

            getUploadUrl: function getUploadUrl(file) {
                return portalRequestService.post('/assets/request', {
                    filename: _this.sanatizeFileName(file.name)
                }).then(function (res) {
                    return res.data.uploadUrl;
                });
            },

            uploadFile: function uploadFile(file) {
                return _this.getUploadUrl(file).then(function (uploadUrl) {
                    return fetch(uploadUrl, {
                        method: 'PUT',
                        body: file
                    }).then(function () {
                        // Remove query string from url
                        return uploadUrl.replace(/\?.+$/, '');
                    }).catch(function (err) {
                        throw err;
                    });
                }).catch(function (err) {
                    console.log('FILE UPLOAD ERR:', file, err);
                    throw file.name + ' ' + (geckoTranslateService.get(geckoTranslateService.keys.FILE_UPLOAD_FIELD.ERROR_1) || 'could not be uploaded.');
                });
            },

            getIcon: function getIcon(file) {
                if (!file) return;

                var type = file.type.split('/')[0];
                var subType = file.type.split('/')[1];

                var extensionMap = {
                    docx: 'word',
                    doc: 'word',
                    xls: 'excel',
                    xlsx: 'excel',
                    ppt: 'powerpoint',
                    pptx: 'powerpoint',
                    pdf: 'pdf',
                    zip: 'archive'
                };

                var iconName;

                if (['image', 'video', 'audio'].indexOf(type) !== -1) {
                    iconName = type;
                } else {
                    iconName = extensionMap[file.name.split('.')[file.name.split('.').length - 1]];
                }

                var iconClass = iconName ? "fa fa-file-" + iconName + "-o" : "fa fa-file";

                return iconClass;
            },

            defaultAllowedMimetypes: defaultAllowedMimetypes,

            defaultAllowedCsvMimetypes: defaultAllowedCsvMimetypes,

            mimetypeGroupsOptions: mimetypeGroupsOptions,

            mimetypeGroups: mimetypeGroups,

            isFileTypesValid: function isFileTypesValid(files, allowedMimeTypes) {
                // Prepare mimetypes array from comma sperated string e.g. "image/jpg,image/png"
                allowedMimeTypes = (allowedMimeTypes || '').split(',').filter(function (mimeType) {
                    return mimeType;
                }).map(function (mimeType) {
                    return mimeType.trim();
                });

                var isFileTypeValid = function isFileTypeValid(fileType) {
                    return allowedMimeTypes.some(function (mimeType) {
                        // mimeType may be a wildcard "image/*"
                        if (mimeType.includes('/*')) {
                            return fileType.includes(mimeType.replace('/*', '/'));
                        }
                        return fileType === mimeType;
                    });
                };

                // If any of the files has an INVALID mimeType return FALSE
                if (allowedMimeTypes.length) {
                    var invalidFileTypes = (files || []).filter(function (file) {
                        return !isFileTypeValid(file.type);
                    });
                    if (invalidFileTypes.length) return false;
                }

                return true;
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').service('geckoFileUploadService', geckoFileUploadService);
})();