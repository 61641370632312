(function () {

    function AuthMobileModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('authMobileModal', function (_message) {
            return {
                templateUrl: '/components/_modals/mobile_user/modal_mobile_user.html',
                controller: 'AuthMobileModalCtrl',
                controllerAs: 'ctrl',
                resolve: {
                    message: function message() {
                        return _message;
                    }
                }
            };
        });
    }

    function AuthMobileModalCtrl($modalInstance, message) {
        var ctrl = this;
        ctrl.message = message;

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Close',
            icon: 'fa-times',
            action: function action() {
                $modalInstance.dismiss();
            }
        }];
    }

    angular.module('GeckoEngage').config(AuthMobileModals).controller('AuthMobileModalCtrl', AuthMobileModalCtrl);
})();