;(function () {
    'use strict';

    function quickSave($state) {

        return {
            restrict: 'A',
            link: function link($scope, $element, $attrs) {

                var quickSave = function quickSave(e) {
                    if ((event.which === 83 || event.which === 115) && (event.ctrlKey || event.metaKey)) {
                        event.preventDefault();

                        var eventToTrigger = 'quickSave:' + ($attrs.quickSave || $state.$current.name);
                        // Emit (upwards and downwards)
                        $scope.$emit(eventToTrigger);
                        return false;
                    }
                    return true;
                };

                // Add/remove event listener
                document.addEventListener('keydown', quickSave);
                $scope.$on('$destroy', function () {
                    return document.removeEventListener('keydown', quickSave);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('quickSave', quickSave);
})();