(function () {
    'use strict';

    function TemplateEditorCtrl($scope, $state, $stateParams, unsavedAlert, template, asyncOptions, messageService, templateService, $filter, mergeFields) {
        var ctrl = this;
        ctrl.template = template;

        // Returns Editor Type based on type & disabled_editor or existing editor_type
        ctrl.template.editor_type = templateService.getEditorType(ctrl.template.type, ctrl.template.disable_editor, ctrl.template.editor_type);

        // If editor type dragdrop
        if (ctrl.template.editor_type === Gecko.Template.EDITOR_TYPE_DRAGDROP) {
            var init = function init(_template, mergeTags) {
                try {
                    var userInput = function userInput(message, sample) {
                        return function handler(resolve, reject) {
                            var data = prompt(message, JSON.stringify(sample));
                            return data == null || data == '' ? reject() : resolve(JSON.parse(data));
                        };
                    };

                    var request = function request(method, url, data, type, callback) {
                        var req = new XMLHttpRequest();
                        req.onreadystatechange = function () {
                            if (req.readyState === 4 && req.status === 200) {
                                var response = JSON.parse(req.responseText);
                                callback(response);
                            } else if (req.readyState === 4 && req.status !== 200) {
                                console.error('Access denied, invalid credentials. Please check you entered a valid client_id and client_secret.');
                            }
                        };
                        req.open(method, url, true);

                        if (data && type) {
                            if (type === 'multipart/form-data') {
                                var formData = new FormData();
                                for (var key in data) {
                                    formData.append(key, data[key]);
                                }
                                data = formData;
                            } else {
                                req.setRequestHeader('Content-type', type);
                            }
                        }
                        req.send(data);
                    };

                    var save = function save(filename, content) {
                        saveAs(new Blob([content], { type: 'text/plain;charset=utf-8' }), filename);
                    };

                    var mergeTags = mergeTags;

                    var beeConfig = {
                        uid: 'gecko_' + Gecko.account.routing_id,
                        container: 'bee-plugin-container',
                        autosave: 15,
                        trackChanges: true,
                        language: 'en-US',
                        mergeTags: mergeTags,
                        contentDialog: {
                            rowDisplayConditions: {
                                label: 'Open builder',
                                handler: userInput('Enter the row display condition:', {
                                    type: 'People',
                                    label: 'Person is a developer',
                                    description: 'Check if a person is a developer',
                                    before: "{if job == 'developer'}",
                                    after: '{endif}'
                                })
                            }
                        },

                        onSave: function onSave(jsonFile, htmlFile) {
                            _template.body = htmlFile;
                            _template.body_json = jsonFile;
                            // Always disable template wrapper
                            _template.include_wrapper = 0;
                            if (ctrl.allowDownload === true) {
                                var blob = new Blob([htmlFile], { type: "text/plain;charset=utf-8" });
                                saveAs(blob, "template.html");
                            }
                            ctrl.allowDownload = false;

                            _template.save().then(function () {
                                GeckoUI.messenger.success('Template has been saved');

                                // Clear unsaved alert
                                unsavedAlert.clear();

                                // Fire Intercom Event
                                GeckoUI.triggerIntercomEvent('Template Save Success', {
                                    'template id': _template.id || 'New',
                                    'template title': _template.name
                                });
                            }).catch(bee.error);
                        },
                        onSaveAsTemplate: function onSaveAsTemplate(jsonFile) {
                            // + thumbnail? 
                            save('newsletter-template.json', jsonFile);
                        },
                        onAutoSave: function onAutoSave(jsonFile) {// + thumbnail? 
                            //console.log(new Date().toISOString() + ' autosaving...');
                            //window.localStorage.setItem('newsletter.autosave', jsonFile);
                        },
                        onSend: function onSend(htmlFile) {
                            //write your send test function here
                        },
                        onChange: function onChange(jsonFile, response) {
                            // Set as true to prompt user about changes made to editor
                            ctrl.templateFormControl.$setDirty();
                        },
                        onError: function onError(errorMessage) {
                            console.log('onError ', errorMessage);

                            GeckoUI.messenger.error(errorMessage);

                            // Fire Intercom Event
                            GeckoUI.triggerIntercomEvent('Template Save Error', {
                                'template id': ctrl.template.id,
                                'template title': ctrl.template.name,
                                'error': GeckoUI.renderError(errorMessage)
                            });
                        }
                    };
                    var bee = null;
                    // var loadTemplate = function (e) {
                    //   var templateFile = e.target.files[0];
                    //   var reader = new FileReader();
                    //   reader.onload = function () {
                    //     var templateString = reader.result;
                    //     var template = JSON.parse(templateString);
                    //     bee.load(template);
                    //   };
                    //   reader.readAsText(templateFile);
                    // };
                    // document.getElementById('choose-template').addEventListener('change', loadTemplate, false);


                    setTimeout(function () {
                        request('POST', 'https://auth.getbee.io/apiauth', 'grant_type=password&client_id=a3b9db12-561f-45f0-a050-c093052cfe7b&client_secret=Ubco2oSqRJLKD3pqT66WajZqvbyL4IzMXHp89HI8EKScWmyXD2U', 'application/x-www-form-urlencoded', function (token) {
                            BeePlugin.create(token, beeConfig, function (beePluginInstance) {
                                bee = beePluginInstance;
                                ctrl.bee = bee;
                                // Load template json
                                if (_template.body_json) {
                                    bee.start(JSON.parse(_template.body_json));
                                    // Default template when creating a new template
                                } else {
                                    request('GET', '/js/3rdparty/beeplugin/default_template.json', null, null, function (template) {
                                        return bee.start(template);
                                    });
                                }
                            });
                        });
                    }, 1000);
                } catch (e) {
                    console.log(e);
                }
            };

            // retrieves custom contact mergeFields


            var download = function download() {
                ctrl.allowDownload = true;
                ctrl.bee.save();
            };

            ctrl.mergeFields = mergeFields.toInstances();
            // Only show editable and social contact mergeFields
            ctrl.mergeFields = angular.copy($filter('filter')(ctrl.mergeFields, function (field) {
                return !field.is_uneditable || field.is_social;
            }));

            // Build custom template tags
            ctrl.buildMergeTags = function () {
                var tags = [];

                // inserts basic tags into tags list
                (Gecko.Template.Tags || []).map(function (obj) {
                    return (obj.tags || []).filter(function (_ref) {
                        var abilities = _ref.abilities;
                        return Array.isArray(abilities) ? abilities.every(function (ability) {
                            return Gecko.able(ability);
                        }) : true;
                    }).map(function (t) {
                        return { name: obj.model + ' | ' + t.name, value: t.tag };
                    });
                }).forEach(function (arr) {
                    tags = tags.concat(arr);
                });

                // insert sub tags of the basic full_name tag
                var index = tags.findIndex(function (x) {
                    return x.name === "Contact | full_name";
                }) + 1;
                var first_name = { name: 'Contact | first_name', value: '{{contact.full_name.first_name}}' };
                var last_name = { name: 'Contact | last_name', value: '{{contact.full_name.last_name}}' };
                tags.splice(index, 0, first_name, last_name);

                // inserts sub tags of the custom address tag into ctrl.mergeFields
                (ctrl.mergeFields || []).map(function (obj) {
                    if (obj.type === 'address') {
                        var _index = ctrl.mergeFields.indexOf(obj);
                        ctrl.mergeFields.splice(_index + 1, 0, { clean: { label: obj.clean.label + ' - Street', tag: obj.clean.tag + '.street_address' } });
                        ctrl.mergeFields.splice(_index + 2, 0, { clean: { label: obj.clean.label + ' - City', tag: obj.clean.tag + '.city' } });
                        ctrl.mergeFields.splice(_index + 3, 0, { clean: { label: obj.clean.label + ' - Postal Code', tag: obj.clean.tag + '.postal_code' } });
                        ctrl.mergeFields.splice(_index + 4, 0, { clean: { label: obj.clean.label + ' - State', tag: obj.clean.tag + '.state' } });
                        ctrl.mergeFields.splice(_index + 5, 0, { clean: { label: obj.clean.label + ' - Country', tag: obj.clean.tag + '.country' } });
                    }
                });

                // inserts custom tags into tags list
                (ctrl.mergeFields || []).map(function (obj) {
                    return ([obj.clean] || []).map(function (t) {
                        return { name: 'Contact Fields | ' + t.label, value: '{{contact.' + t.tag + '}}' };
                    });
                }).forEach(function (arr) {
                    tags = tags.concat(arr);
                });

                // Insert sub tags of the basic account address tag
                var accountAddressIndex = tags.findIndex(function (x) {
                    return x.name === 'Account | address';
                }) + 1;
                var accountName = { name: 'Account | name', value: '{{account.address.name}}' };
                var accountStreetAddress = { name: 'Account | street_address', value: '{{account.address.street_address}}' };
                var accountCity = { name: 'Account | city', value: '{{account.address.city}}' };
                var accountState = { name: 'Account | state', value: '{{account.address.state}}' };
                var accountPostalCode = { name: 'Account | postal_code', value: '{{account.address.postal_code}}' };
                var accountCountry = { name: 'Account | country', value: '{{account.address.country}}' };
                tags.splice(accountAddressIndex, 0, accountName, accountStreetAddress, accountCity, accountState, accountPostalCode, accountCountry);

                return tags;
            };

            // Init builder
            init(template, ctrl.buildMergeTags());

            // Save the drag drop template
            $scope.$on('saveTemplate', function () {
                return ctrl.bee.save();
            });

            // Drag Drop Btns for Preview dropdown
            $scope.$on('toggleStructure', function () {
                return ctrl.bee.toggleStructure();
            });

            $scope.$on('preview', function () {
                return ctrl.bee.preview();
            });

            $scope.$on('send', function () {
                return ctrl.bee.send();
            });

            $scope.$on('download', function () {
                return download();
            });
        }

        // If editor type is anything else other than dragdrop
        else {

                // Disable template wrapper
                var disableWrapper = function disableWrapper() {
                    // Control toggle ONLY for TYPE_WYSIWYG 
                    if (ctrl.template.editor_type === Gecko.Template.EDITOR_TYPE_WYSIWYG) {
                        // Invert setting (for UI display)
                        ctrl.template.disable_wrapper = Number(!ctrl.template.include_wrapper);
                    } else {
                        ctrl.template.disable_wrapper = 1;
                    }
                };

                disableWrapper();

                // Gets title for Gecko Card data-title
                var getTitle = function getTitle() {
                    if (ctrl.template.editor_type === Gecko.Template.EDITOR_TYPE_SMS || ctrl.template.type == Gecko.Template.TYPE_SMS) {
                        ctrl.title = 'SMS Template Editor';
                        ctrl.icon = 'fa-comment-dots';
                    } else if (ctrl.template.editor_type === Gecko.Template.EDITOR_TYPE_WYSIWYG) {
                        ctrl.title = 'Basic Template Editor';
                        ctrl.icon = 'fa-font';
                    } else if (ctrl.template.editor_type === Gecko.Template.EDITOR_TYPE_BASIC) {
                        ctrl.title = 'HTML Template Editor';
                        ctrl.icon = 'fa-code';
                    }
                    // else if (ctrl.template.editor_type === Gecko.Template.EDITOR_TYPE_DRAGDROP) {
                    //      ctrl.title = 'Drag & Drop Editor';
                    //      ctrl.icon = 'fa-mouse-pointer';
                    // }
                    else {
                            ctrl.title = 'Template Editor';
                            ctrl.icon = 'fa-file-alt';
                        }
                };

                getTitle();

                var checkBodyGSM = function checkBodyGSM(value) {
                    var non_gsm_words = messageService.nonGSMCharacters(value);
                    ctrl.non_gsm_words = non_gsm_words ? non_gsm_words.join(', ') : null;
                };

                if (ctrl.template.type === Gecko.Template.TYPE_SMS) {
                    checkBodyGSM(ctrl.template.body);
                }

                ctrl.fields = [{
                    type: Gecko.Field.TYPE_RICHTEXT,
                    format: 'fullpage',
                    colClass: 'col-xs-12',
                    id: 'body',
                    label: 'Body',
                    required: true,
                    templateTagBuilderRef: 'body_email',
                    hideWhen: function hideWhen() {
                        return ctrl.template.type == Gecko.Template.TYPE_SMS || ctrl.template.editor_type !== Gecko.Template.EDITOR_TYPE_WYSIWYG;
                    }
                }, {
                    type: Gecko.Field.TYPE_TEXTAREA,
                    colClass: 'col-xs-12',
                    id: 'body',
                    label: 'Body',
                    required: true,
                    templateTagBuilderRef: 'body_email',
                    hideWhen: function hideWhen() {
                        return ctrl.template.type === Gecko.Template.TYPE_SMS || ctrl.template.editor_type !== Gecko.Template.EDITOR_TYPE_BASIC;
                    }
                }, {
                    type: Gecko.Field.TYPE_TEXTAREA,
                    colClass: 'col-xs-12',
                    id: 'body',
                    label: 'Message',
                    required: true,
                    templateTagBuilderRef: 'body_sms',
                    description: 'It\'s best practice to allow your contacts to opt out of broadcasts. Any contact that replies to an SMS with<code>STOP</code>, <code>STOPALL</code>, <code>UNSUBSCRIBE</code>, <code>CANCEL</code>, <code>END</code>, or <code>QUIT</code> will be unsubscribed - it\'s a good idea to mention this in your template but the ability will be implemented regardless. Please note using template tags in your SMS template may cause your message to be significantly longer than the currently indicated character count. This will result in your messages being sent across multiple texts.',
                    hideWhen: function hideWhen() {
                        return ctrl.template.type !== Gecko.Template.TYPE_SMS;
                    },
                    onChange: function onChange(obj) {
                        return checkBodyGSM(obj.value);
                    },
                    counter: 160
                }, {
                    type: Gecko.Field.TYPE_TOGGLE,
                    id: 'disable_wrapper',
                    label: 'Disable template wrapper',
                    colClass: 'col-xs-12',
                    description: 'Keep template wrapper enabled to ensure consistency of style across all platforms for text emails. When using HTML disable this wrapper firstly, and ensure this is kept disabled at all times.',
                    hideWhen: function hideWhen() {
                        return ctrl.template.type === Gecko.Template.TYPE_SMS || ctrl.template.editor_type !== Gecko.Template.EDITOR_TYPE_WYSIWYG;
                    }
                }];

                ctrl.saveTemplate = function () {
                    // Include template wrapper
                    var includeWrapper = Number(!ctrl.template.disable_wrapper);
                    ctrl.template.include_wrapper = templateService.setIncludeWrapper(ctrl.template.editor_type, includeWrapper);

                    // Check URL/href tag encoding
                    ctrl.template.body = ctrl.template.body.replace(/href=["'].+(%7B%7B)([\w.]+)(%7D%7D)/g, 'href=\"{{$2}}');

                    // Save the template
                    ctrl.template.save().then(function (data) {
                        GeckoUI.messenger.success('Template has been saved');

                        // Clear unsaved alert
                        unsavedAlert.clear();

                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Template Save Success', {
                            'template id': data.id || 'New',
                            'template title': data.name
                        });

                        ctrl.template = data;

                        // Disable template wrapper
                        disableWrapper();

                        if (ctrl.template.type === Gecko.Template.TYPE_SMS) {
                            checkBodyGSM(ctrl.template.body);
                        }
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);

                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Template Save Error', {
                            'template id': ctrl.template.id,
                            'template title': ctrl.template.name,
                            'error': GeckoUI.renderError(err.errors)
                        });
                    });
                };

                $scope.$on('saveTemplate', function () {
                    return ctrl.saveTemplate();
                });

                // Allowed model for template tag builder
                ctrl.allowedModels = [Gecko.Template.TAG_FORMS, Gecko.Template.TAG_RESPONSES, Gecko.Template.TAG_ASSIGNED_USER, Gecko.Template.TAG_CAPTURED_USER, Gecko.Template.TAG_EVENTS, Gecko.Template.TAG_CONTACT, Gecko.Template.TAG_SENDER, Gecko.Template.TAG_CAMPAIGNS, Gecko.Template.TAG_CALLS, Gecko.Template.TAG_OUTCOMES, Gecko.Template.TAG_ACCOUNT];

                ctrl.templateTagBuilderInputRef = ctrl.template.type === Gecko.Template.TYPE_SMS ? 'body_sms' : 'body_email';
            }
    }
    angular.module('GeckoEngage').controller('TemplateEditorCtrl', TemplateEditorCtrl);
})();