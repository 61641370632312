(function () {
    'use strict';

    function onceVisible($document, $window, $timeout) {

        return {
            restrict: 'A',
            link: function link(scope, iElement, iAttrs) {

                var timeout;

                var checkVisibility = function checkVisibility() {

                    var bounds = iElement[0].getBoundingClientRect();

                    var visible = (bounds.top >= 0 && bounds.top <= $window.innerHeight || bounds.bottom >= 0 && bounds.bottom <= $window.innerHeight || bounds.top <= 0 && bounds.bottom >= $window.innerHeight) && (bounds.left >= 0 && bounds.left <= $window.innerWidth || bounds.right >= 0 && bounds.right <= $window.innerWidth || bounds.left <= 0 && bounds.right >= $window.innerHeight);

                    // Run the expression after 1 second of maintained visibility
                    if (visible && !timeout) {
                        timeout = $timeout(function () {
                            scope.$eval(iAttrs.onceVisible);
                            $document.off('scroll', checkVisibility);
                        }, 1000);
                    }

                    // Cancel timeout if element scrolls out of view
                    if (!visible && timeout) {
                        $timeout.cancel(timeout);
                        timeout = null;
                    }
                };
                checkVisibility();

                $document.on('scroll', checkVisibility);

                scope.$on('$destroy', function () {
                    $document.off('scroll', checkVisibility);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('onceVisible', onceVisible);
})();