(function () {
    'use strict';

    function templateService() {
        var _this = {

            getEditorType: function getEditorType(type, disableEditor, editorType) {
                if (type === Gecko.Template.TYPE_SMS) {
                    return Gecko.Template.EDITOR_TYPE_SMS;
                } else if (editorType) {
                    return editorType;
                } else if (type === Gecko.Template.TYPE_EMAIL && disableEditor === 0) {
                    return Gecko.Template.EDITOR_TYPE_WYSIWYG;
                } else if (type === Gecko.Template.TYPE_EMAIL && disableEditor === 1) {
                    return Gecko.Template.EDITOR_TYPE_BASIC;
                } else {
                    return Gecko.Template.EDITOR_TYPE_WYSIWYG;
                }
            },

            setIncludeWrapper: function setIncludeWrapper(editorType) {
                var includeWrapper = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

                if (editorType === Gecko.Template.EDITOR_TYPE_WYSIWYG) {
                    return includeWrapper;
                }
                return 0;
            }
        };
        return _this;
    }

    angular.module('GeckoEngage').factory('templateService', templateService);
})();