(function () {
    'use strict';

    function ImportsRoute($stateProvider) {
        $stateProvider.state('settingsimports', {
            page_title: 'Imports',
            url: "/settings/imports?{page}&{to}&{from}&{search}&{source}&{data}&{type}&{status}&{frequency}&{progress}",
            templateUrl: "/modules/imports/views/index.94630a97.html",
            controller: 'SettingsImportsCtrl',
            controllerAs: 'ctrl',
            requires: [Gecko.ABILITY_IMPORTS],
            redirectKey: 'module_imports',
            resolve: {
                imports: function imports($stateParams) {
                    var imports = new Gecko.Import().perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).include('schedule', 'user').orderBy('updated_at', 'DESC');

                    if ($stateParams.to && $stateParams.from) {
                        imports.dates($stateParams.from, $stateParams.to);
                    }
                    if ($stateParams.type) {
                        imports.where('import_to', $stateParams.type);
                    }
                    if ($stateParams.source) {
                        imports.where('type', $stateParams.source);
                    }
                    if ($stateParams.progress) {
                        imports.where('status', $stateParams.progress);
                    }

                    // Frequency
                    if (angular.isDefined($stateParams.frequency)) {
                        if (['recurring', 'once'].indexOf($stateParams.frequency) !== -1) {
                            if ($stateParams.frequency === 'recurring') {
                                imports.base_slug = imports.base_slug += '/has_schedule';
                            } else {
                                imports.base_slug = imports.base_slug += '/single';
                            }
                        } else {
                            imports.where('schedule', $stateParams.frequency);
                        }
                    }

                    // Keyword search
                    if (angular.isDefined($stateParams.search)) {
                        imports.search($stateParams.search);
                    }

                    return imports.rfields({
                        import: ['created_at', 'title', 'import_to', 'type', 'progress', 'status', 'schedule', 'total'],
                        schedule: ['last_run_at', 'next_run_at', 'title', 'import_to', 'cron_details', 'cron'],
                        user: ['full_name']
                    }).get();
                }
            }
        })
        // CRM Import
        .state('settingsimport', {
            url: "/settings/import/{import_id}?{integration_id}",
            templateUrl: "/modules/imports/views/edit.b0c5518a.html",
            controller: 'SettingsImportCtrl',
            controllerAs: 'ctrl',
            page_title: 'Import',
            requires: [Gecko.ABILITY_IMPORTS],
            resolve: {
                import_data: function import_data($stateParams) {
                    if ($stateParams.import_id != 'new') {
                        return new Gecko.Import().include('schedule').get($stateParams.import_id);
                    } else {
                        return new Gecko.Import();
                    }
                },
                integration: function integration($stateParams) {
                    return new Gecko.Integration().get($stateParams.integration_id);
                },
                integration_fields: function integration_fields($stateParams, integration) {
                    if (integration.type == 'radius') {
                        return new Gecko.IntegrationField().perPage('all').where('integration_id', $stateParams.integration_id).get();
                    }
                    return false;
                }
                // Integration_filters: function(integration){
                //     If (integration.type == 'connect' || integration.type == 'radius'){
                //         Return integration.getFilters();
                //     }
                // }
            }
        })
        // CSV Recurring Import
        .state('importrecurringedit', {
            url: '/recurring-import/{import_id}',
            templateUrl: '/modules/imports/views/recurring/edit.2d279b27.html',
            controller: 'RecurringImportEditCtrl',
            controllerAs: 'ctrl',
            page_title: 'Recurring Import',
            requires: [Gecko.ABILITY_IMPORTS, Gecko.ABILITY_FTP_IMPORT],
            resolve: {
                csv_import: function csv_import($stateParams) {
                    return new Gecko.Import().include('workflows:all', 'form.fields:all', 'activity').include('schedule').rfields({
                        import: ['title', 'ftp', 'selected_fields', 'field_sample', 'skip_rows', 'validation', 'processing_option', 'import_to'],
                        schedule: ['cron']
                    }).get($stateParams.import_id);
                }
            }
        }).state('importrecurringedit.options', {
            url: '/options',
            templateUrl: '/modules/imports/views/recurring/options.f8fffc91.html',
            controller: 'RecurringImportEditOptionsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Recurring Import',
            requires: [Gecko.ABILITY_IMPORTS, Gecko.ABILITY_FTP_IMPORT],
            resolve: {
                import_fields: function import_fields(csv_import) {
                    return new Gecko.Field().where('field_type', ['contact', 'organisation']).perPage('all')
                    // The is_uneditable rfield doesn't work at the moment
                    // .rfields({
                    //     field: ['label', 'is_uneditable']
                    // })
                    .get();
                }
            }
        }).state('importrecurringedit.workflows', {
            url: '/workflows',
            templateUrl: '/modules/imports/views/recurring/workflows.a8cdac91.html',
            controller: 'RecurringImportEditWorkflowsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Recurring Import',
            requires: [Gecko.ABILITY_IMPORTS, Gecko.ABILITY_FTP_IMPORT],
            resolve: {
                multi_data: function multi_data(geckoDataService) {
                    var models = ['fields', 'campaigns', 'consents', 'labels', 'users', 'senders', 'templates', 'integrations', 'tasks'];
                    return geckoDataService.fetch(models);
                }
            }
        })
        // CSV Import Config
        .state('importconfig', {
            url: "/import/{import_id}/config",
            templateUrl: "/modules/imports/views/config.21aff3d4.html",
            controller: 'SettingsCSVImportConfigCtrl',
            page_title: 'Import Contacts',
            requires: [Gecko.ABILITY_IMPORTS],
            resolve: {
                csv_import: function csv_import($stateParams) {
                    return new Gecko.Import().include('form.fields:all').get($stateParams.import_id);
                },
                import_fields: function import_fields(csv_import) {
                    if (csv_import.import_to === 'contacts') {
                        return new Gecko.Field().where('field_type', 'contact').perPage('all').get();
                    } else if (csv_import.import_to === 'organisations') {
                        return new Gecko.Field().where('field_type', 'organisation').perPage('all').get();
                    }
                    return null;
                },
                consents: function consents() {
                    return new Gecko.Consent().perPage('all').getCollection();
                }
            }
        })
        // View Import
        .state('importsview', {
            url: "/imports/{import_id}?{page}&{order}&{sort}&{search}",
            templateUrl: "/modules/imports/views/view.c7c4516c.html",
            controller: 'SettingsImportViewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Import Summary',
            requires: [Gecko.ABILITY_IMPORTS],
            resolve: {
                import_data: function import_data($stateParams) {
                    return new Gecko.Import().include('form', 'user', 'activity', 'schedule').get($stateParams.import_id);
                },
                import_results: function import_results($stateParams, import_data) {
                    if (import_data.import_to == 'contacts') {
                        var contacts = new Gecko.Contact().rfields({ 'contact': ['field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6'] });
                        contacts.where('import_id', $stateParams.import_id);
                        // Ordering / Paging
                        contacts.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                        // keyword search
                        if ($stateParams.search) contacts.where('contact_keyword', $stateParams.search);
                        return contacts.get();
                    } else if (import_data.import_to == 'organisations') {
                        return new Gecko.Organisation().where('import_id', $stateParams.import_id).include('values').perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                    } else {
                        var responses = new Gecko.Response().include('contact', 'form').where('import_id', $stateParams.import_id);

                        if (import_data.last_run) {
                            responses = responses.where('date_from', import_data.last_run);
                        }

                        if ($stateParams.search) {
                            responses.search($stateParams.search);
                        }

                        return responses.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc').get();
                    }
                },
                fieldList: function fieldList(import_data) {
                    if (import_data.import_to == 'contacts') {
                        return new Gecko.Field().listView();
                    } else if (import_data.import_to == 'organisations') {
                        return new Gecko.Field().where('field_type', 'organisation').listView();
                    }
                    return false;
                },
                activity: function activity(import_data, $stateParams) {
                    if (import_data.related_schedule && import_data.related_schedule.id) {
                        return new Gecko.Activity().where('type', Gecko.Activity.TYPE_IMPORT).where('model', 'schedule').where('schedule_id', import_data.related_schedule.id).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                    }
                    return false;
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(ImportsRoute);
})();