(function () {
    'use strict';

    function CallsOverviewCtrl($scope, calls, callIncludes, $interval, $geckoSocket, $geckoCall, $geckoConf, Gecko, NumberStats, serviceStatConfig, serviceStat, numbers) {

        var ctrl = this;

        // Only do this for inbound
        if (serviceStat) {
            var numStats = NumberStats.init(numbers.toArray());

            ctrl.stats = [{ title: 'Agents Online', result: Object.keys(numStats.online).length }, { title: 'Agents Ready', result: Object.keys(numStats.ready).length }, { title: 'Callers in Queue', result: numStats.totalQueue }];

            // Check service value result formatting
            if (angular.isObject(serviceStat.result)) serviceStat.result = serviceStat.result['call-service-quality'];

            ctrl.stats.push(serviceStat);
        }

        ctrl.callsMap = Object.create(null);
        ctrl.calls = calls.toInstances();

        ctrl.calls.forEach(function (call) {
            // Copy includes over to each call instance
            ctrl.callsMap[call.id] = call;
            call.includes = calls.includes;
        });

        var handleCallUpdate = function handleCallUpdate(call) {
            if (!ctrl.callsMap[call.id] && call.status === Gecko.Call.STARTED) {
                ctrl.callsMap[call.id] = true;
                new Gecko.Call().include(callIncludes).get(call.id).then(function (call) {
                    ctrl.callsMap[call.id] = call;
                    ctrl.calls.unshift(call);
                });
            } else if (ctrl.callsMap[call.id] && ctrl.callsMap[call.id].refresh) {
                ctrl.callsMap[call.id].refresh();
            }
        };
        $geckoSocket.registerEvent('call:new', handleCallUpdate);
        $geckoSocket.registerEvent('call:updated', handleCallUpdate);

        var fetchingStat = false;
        // Only do this for inbound
        if (serviceStat) {
            $geckoSocket.registerEvent('voip:queue', function (data) {
                if (numStats.map[data.sender_id]) {

                    numStats = NumberStats.update(numStats, data);

                    ctrl.stats[0].result = Object.keys(numStats.online).length;
                    ctrl.stats[1].result = Object.keys(numStats.ready).length;
                    ctrl.stats[2].result = numStats.totalQueue;

                    $scope.$digest();

                    if (!fetchingStat) {

                        fetchingStat = true;
                        new Gecko.Stat().generate(serviceStatConfig).then(function (stat) {
                            if (angular.isObject(stat.result)) stat.result = stat.result['call-service-quality'];
                            ctrl.stats[3] = stat;

                            fetchingStat = false;
                        });
                    }
                }
            });
        }

        ctrl.callIcon = function (call) {
            if (!call) return;

            switch (call.type) {
                case Gecko.Call.TYPE_INBOUND:
                    return '/images/icons/call-inbound.054133b8.svg';
                case Gecko.Call.TYPE_OUTBOUND:
                    return '/images/icons/call-outbound.f5443e4a.svg';
            }
        };

        // Updates the scope every second. Makes the duration timers increase.
        $interval(angular.noop, 1000);

        ctrl.callDuration = function (call) {
            if (!call) return 0;

            if (call.call_time) return call.call_time;

            var duration = +new Date() / 1000 - call.started_at;

            return duration;
        };

        ctrl.callStatusClass = function (call) {
            if (!call) return '';

            switch (call.status) {
                case Gecko.Call.QUEUED:
                case Gecko.Call.PREPARING:
                case Gecko.Call.STARTED:
                    return 'on-call';

                case Gecko.Call.ENDED:
                case Gecko.Call.COMPLETE:
                case Gecko.Call.ABORTED:
                case Gecko.Call.ABANDONED:
                case Gecko.Call.CANCELLED:
                default:
                    return '';
            }
        };

        ctrl.callString = function (call) {
            if (!call) return '';

            if (call.type === Gecko.Call.TYPE_INBOUND) {
                switch (call.status) {
                    case Gecko.Call.PREPARING:
                    case Gecko.Call.STARTED:
                        return 'currently called by';

                    case Gecko.Call.ENDED:
                        return 'wrapping up a call with';

                    case Gecko.Call.QUEUED:
                        return 'QUEUED';

                    case Gecko.Call.ABORTED:
                    case Gecko.Call.ABANDONED:
                    case Gecko.Call.CANCELLED:
                    case Gecko.Call.COMPLETE:
                    default:
                        return 'called by';
                }
            } else if (call.type === Gecko.Call.TYPE_OUTBOUND) {
                switch (call.status) {
                    case Gecko.Call.PREPARING:
                    case Gecko.Call.STARTED:
                        return 'currently on call with';

                    case Gecko.Call.ENDED:
                        return 'wrapping up a call with';

                    case Gecko.Call.QUEUED:
                        return 'QUEUED';

                    case Gecko.Call.ABORTED:
                    case Gecko.Call.ABANDONED:
                    case Gecko.Call.CANCELLED:
                    case Gecko.Call.COMPLETE:
                    default:
                        return 'called';
                }
            }
        };

        ctrl.userString = function (call) {
            if (!call) return;
            return call.related_user && call.related_user.full_name ? call.related_user.full_name : 'Unknown';
        };

        ctrl.contactString = function (call) {
            if (!call) return;
            if (call.related_contact && call.related_contact.full_name) {
                return call.related_contact.full_name;
            } else if (call.related_number) {
                if (call.related_number.type == Gecko.Number.TYPE_ANONYMOUS) return 'Unknown Number';
                return call.related_number.cleaned || call.related_number.original;
            }
            return 'Unknown';
        };

        // Show listen button
        ctrl.canListen = function (call) {
            if (!call) return false;
            return Gecko.able(Gecko.ABILITY_CALLS_LISTEN) && call && call.status === Gecko.Call.STARTED && call.type === Gecko.Call.TYPE_INBOUND && $geckoCall.canListen();
        };

        // Listen in to call
        ctrl.listenIn = function (call) {
            if (!call) return false;
            if (ctrl.canListen(call)) return $geckoConf.listen(call.id);
            return false;
        };
    }

    angular.module('GeckoEngage').controller('CallsOverviewCtrl', CallsOverviewCtrl);
})();