(function () {
    'use strict';

    function LocationsRoute($stateProvider) {
        $stateProvider.state('events.locations', {
            url: "/locations?{page}&{sort}&{order}&{search}",
            templateUrl: "/modules/locations/views/index.40e0b9e9.html",
            controller: 'EventsLocationsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Locations',
            resolve: {
                locations: function locations($stateParams) {
                    var locations = new Gecko.Location();
                    if ($stateParams.search) locations.where('location_search', $stateParams.search);
                    if ($stateParams.order) {
                        locations.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    } else {
                        locations.orderBy('title');
                    }
                    return locations.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ location: ['title'] }).get();
                }
            }
        }).state('events.location', {
            url: "/locations/{location_id}",
            templateUrl: "/modules/locations/views/edit.755be1f4.html",
            controller: 'EventsLocationCtrl',
            controllerAs: 'ctrl',
            page_title: 'Location',
            requires: [function ($stateParams) {
                return $stateParams.location_id === 'new' ? Gecko.ABILITY_LOCATIONS_CREATE : Gecko.ABILITY_LOCATIONS_UPDATE;
            }],
            resolve: {
                location: function location($stateParams) {
                    if ($stateParams.location_id != 'new') {
                        return new Gecko.Location().get($stateParams.location_id);
                    } else {
                        return new Gecko.Location().create({});
                    }
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(LocationsRoute);
})();