(function () {
    'use strict';

    function LoadingIndicator($timeout) {

        var listeners = [],
            _listeners = {};

        var startLoader;
        var startIdLoader = {};

        return {
            show: function show(id, timeout) {
                if (id) {
                    startIdLoader[id] = $timeout(function () {
                        _listeners[id]();
                    }, typeof timeout === 'number' ? timeout : 600);
                } else {
                    if (startLoader) return;
                    startLoader = $timeout(function () {
                        angular.forEach(listeners, function (listener) {
                            listener(true);
                        });
                    }, typeof timeout === 'number' ? timeout : 600);
                }
                // Remove rogue active modals
                angular.element('.modal').modal('hide');
            },
            hide: function hide(id) {
                if (id) {
                    $timeout.cancel(startIdLoader[id]);
                    startIdLoader[id] = null;
                    _listeners[id](false);
                } else {
                    $timeout.cancel(startLoader);
                    startLoader = null;
                    angular.forEach(listeners, function (listener) {
                        listener(false);
                    });
                }
            },
            register: function register(listener, id) {
                if (id) {
                    _listeners[id] = listener;
                } else {
                    listeners.push(listener);
                }
            },
            deRegister: function deRegister(listener) {
                var index = listeners.indexOf(listener);
                if (index !== -1) {
                    listeners.splice(index, 1);
                }
            },
            resolve: function resolve(promise) {
                this.show();
                promise.finally(this.hide);
                return promise;
            }
        };
    }

    angular.module('GeckoEngage').factory('LoadingIndicator', LoadingIndicator);
})();