(function () {
    'use strict';

    function filterDropdown($rootScope) {
        return {
            restrict: 'E',
            templateUrl: '/components/filter-dropdown/filter-dropdown.html',
            scope: {
                items: '&items',
                input: '=selected',
                extraParams: '&',
                filter: '@?filter'
            },
            controller: function controller($scope, $state, $stateParams, $attrs, $parse, $timeout) {

                $attrs.$observe('includeAll', function (includeAll) {
                    var includeAll = $parse(includeAll)();
                    $scope.includeAllOption = typeof includeAll === 'undefined' ? true : includeAll;
                });

                $attrs.$observe('icon', function (icon) {
                    $scope.icon = icon;
                });

                $attrs.$observe('title', function (title) {
                    $scope.title = title;
                });

                $attrs.$observe('btnTooltip', function (btnTooltip) {
                    $scope.btnTooltip = btnTooltip;
                });

                $attrs.$observe('key', function (key) {
                    $scope.key = key || 'title';
                });

                var itemIds = GeckoUI.getIdArray($scope.items());
                $scope.allSelected = itemIds.length === $scope.input.length;

                // Operator (any/all)
                $scope.operator = $stateParams.operator ? { type: $stateParams.operator } : { type: 'any' };

                $attrs.$observe('showoperator', function (showoperator) {
                    $scope.showoperator = showoperator || false;
                });

                $attrs.$observe('operatortitle', function (operatortitle) {
                    $scope.operatortitle = operatortitle || false;
                });

                $attrs.$observe('activeWhen', function (activeWhen) {
                    $scope.activeWhen = activeWhen;
                });

                reset();

                var changed = false;

                $scope.setValue = function (key) {
                    if (key === 'all') {
                        return function (value) {
                            if (arguments.length) {
                                changed = true;
                                $scope.selected = GeckoUI.arrayToObjectKeys(itemIds, value);
                                $scope.allSelected = value;
                            }
                            return $scope.allSelected;
                        };
                    } else {
                        return function (value) {
                            if (arguments.length) {
                                changed = true;
                                $scope.allSelected = false;
                                $scope.selected[key] = value;
                            }
                            return $scope.selected[key];
                        };
                    }
                };

                var wasOpen = false;

                $scope.onToggle = function (open) {

                    if (!open && wasOpen) {
                        if (changed || $stateParams.operator && $stateParams.operator != $scope.operator.type) {
                            if ($scope.input instanceof Array) {
                                if ($attrs.queryParam) {
                                    $scope.input = GeckoUI.objectKeysToArray($scope.selected);
                                } else {
                                    $timeout(function () {
                                        $scope.input = GeckoUI.objectKeysToArray($scope.selected);
                                    });
                                }
                            } else {
                                $timeout(function () {
                                    $scope.input = $scope.selected;
                                });
                            }

                            // If we have a queryParam attribute then we set that param and reload the current state.
                            if ($attrs.queryParam) {
                                // By default we clear the page
                                var queryParams = {
                                    page: null,
                                    operator: $scope.showoperator ? $scope.operator.type : null
                                };

                                // Get and merge extra parameters
                                queryParams = Gecko.mergeObjects(queryParams, $scope.extraParams());

                                // Set the chosen filter values to the query parameter
                                queryParams[$attrs.queryParam] = $scope.input;

                                // Scott bug fix, label=all is clashing with any/all stuff
                                angular.forEach(queryParams.label, function (label, key) {
                                    if (label == 'all') delete queryParams.label[key];
                                });

                                $state.go($state.current.name, queryParams, { reload: true });
                            }
                        }
                        wasOpen = false;
                    } else {
                        changed = false;
                        wasOpen = true;
                    }

                    return true;
                };

                function reset() {

                    // Reset the filter string to empty
                    $scope.filterString = '';

                    if ($scope.input instanceof Array) $scope.selected = GeckoUI.arrayToObjectKeys($scope.input);else $scope.selected = $scope.input || { all: true };
                }

                $scope.reset = reset;
            }
        };
    }

    angular.module('GeckoEngage').directive('filterDropdown', filterDropdown);
})();