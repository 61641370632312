(function () {
    'use strict';

    var generateWorkflowsController = function generateWorkflowsController($scope, unsavedAlert, ctrl, vars) {
        ctrl.workflows = ctrl.campaign.related_workflows;

        var campaignSave = function campaignSave() {
            ctrl.campaign.workflows = ctrl.workflows;
            ctrl.campaign.include('outcomes', 'workflows').save().then(function (campaign) {
                GeckoUI.messenger.success(vars.saveSuccessMessage);
                // Update the scope variable to the new one
                ctrl.campaign = campaign;
                // Clear unsaved changes alert
                unsavedAlert.clear();
            }).catch(function (campaign) {
                GeckoUI.messenger.error(campaign.errors);
            });
        };

        $scope.$on('saveCampaign', function () {
            return campaignSave();
        });
    };

    function CampaignsWorkflowsCtrl(campaign, multiData, unsavedAlert, $scope) {
        var ctrl = this;
        ctrl.campaign = campaign;
        ctrl.multiData = multiData;

        generateWorkflowsController($scope, unsavedAlert, ctrl, {
            saveSuccessMessage: 'Campaign saved'
        });
    }

    angular.module('GeckoEngage').controller('CampaignsWorkflowsCtrl', CampaignsWorkflowsCtrl);
})();