(function () {

    function NoteModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('noteAddModal', function (_target, _note) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/note/modal.html',
                controller: 'NoteAddModalCtrl',
                resolve: {
                    target: function target() {
                        return _target;
                    },
                    note: function note() {
                        return _note;
                    }
                }
            };
        });
    }

    function NoteAddModalCtrl($scope, $modalInstance, $state, $stateParams, target, note) {
        var ctrl = this;
        ctrl.target = target;
        ctrl.editing = note ? true : false;
        ctrl.alertMessage = '';
        ctrl.saving = false;

        if (note) {
            ctrl.note = note;
        } else {
            ctrl.note = new Gecko.Note().create({
                note: ''
            });
            if (ctrl.target instanceof Gecko.Response) ctrl.note.response_id = ctrl.target.id;
            if (ctrl.target instanceof Gecko.Contact) ctrl.note.contact_id = ctrl.target.id;
        }

        // Field list
        ctrl.fields = [{
            id: 'note',
            label: 'Note',
            type: Gecko.Field.TYPE_TEXTAREA,
            colClass: 'none',
            description: 'Additional information on a contact can be stored via notes and shared internally between teams. All notes are viewable in a contact’s Notes tab.'
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: !ctrl.editing ? 'Add' : 'Save',
            icon: ctrl.editing ? 'fa-plus' : 'fa-check',
            btnClass: 'btn-primary',
            disabledWhen: function disabledWhen() {
                return ctrl.saving;
            },
            action: function action() {
                ctrl.saving = true;
                ctrl.note.include('user').save().then(function (note) {
                    // Close modal
                    $modalInstance.close(note);
                }).catch(function (err) {
                    ctrl.alertMessage = GeckoUI.renderError(err.errors);
                    $scope.$digest();
                }).finally(function () {
                    ctrl.saving = false;
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(NoteModals).controller('NoteAddModalCtrl', NoteAddModalCtrl);
})();