(function () {
    'use strict';

    function OrganiationFieldsRoute($stateProvider) {
        $stateProvider.state('organisationfields', {
            url: '/settings/organisationfields?{page}',
            templateUrl: "/modules/organisations_fields/views/index.4b960bbf.html",
            controller: 'OrganisationFieldsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisation Fields',
            requires: [Gecko.ABILITY_ORGANISATIONS_UPDATE],
            redirectKey: 'module_organisation_fields',
            resolve: {
                fields: function fields() {
                    return new Gecko.Field().where('field_type', Gecko.Field.FIELD_TYPE_ORGANISATION).where('editable', 1).orderBy('order', 'asc').perPage('all').rfields({ field: ['label', 'type', 'created_at', 'system', 'order'] }).get();
                }
            }
        }).state('organisationfield', {
            url: '/settings/organisationfields/{organisation_field_id}',
            templateUrl: "/modules/organisations_fields/views/edit.3410546e.html",
            controller: 'OrganisationFieldEditCtrl',
            controllerAs: 'ctrl',
            page_title: 'Edit Organisation Field',
            requires: [Gecko.ABILITY_ORGANISATIONS_UPDATE],
            redirectKey: 'module_organisation_field',
            resolve: {
                field: function field($stateParams) {
                    if ($stateParams.organisation_field_id === '0') {
                        var field = new Gecko.Field();
                        field.field_type = Gecko.Field.FIELD_TYPE_ORGANISATION;
                        return field;
                    } else {
                        return new Gecko.Field().where('field_type', Gecko.Field.FIELD_TYPE_ORGANISATION).get($stateParams.organisation_field_id);
                    }
                },
                option: function option($stateParams, asyncOptions, field) {
                    return asyncOptions.getSelectedValues({
                        option: { model: 'Option', id: field.option_id, fields: { option: ['name'] } }
                    });
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(OrganiationFieldsRoute);
})();