(function () {
    'use strict';

    function geckoCallStartSearchCard($geckoCall, asyncOptions) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-start-search-card.html',

            require: '^geckoCallPanePrepare',

            link: function link(scope, iElement, iAttrs, ctrl) {

                ctrl.contact = null;
                ctrl.numbers = [];

                // Build /reset values function
                ctrl.setValues = function (contactId) {
                    if (!contactId) return false;
                    // Show loading indicator
                    $geckoCall.loadingIndicator(true);
                    // Get selected contact (and numbers);
                    new Gecko.Contact().include('numbers').get(contactId).then(function (contact) {
                        ctrl.contact = contact;
                        ctrl.contactId = ctrl.contact.id;
                        ctrl.numbers = contact.related_numbers;
                        // Set numbers in dropdown
                        if (ctrl.numbers.length) {
                            ctrl.noNumbers = false;
                        } else {
                            ctrl.noNumbers = true;
                        }
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        scope.digest();
                    }).catch(function () {
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                    });
                };

                // Reset search values
                ctrl.resetValues = function () {
                    ctrl.noNumbers = false;
                    ctrl._contactId = null;
                    ctrl.contact = null;
                    ctrl.contactId = null;
                    ctrl.numbers = null;
                };

                var getContactIdentifier = function getContactIdentifier(contact) {
                    if (contact.full_name && contact.email) {
                        return contact.full_name + ' (' + contact.email + ')';
                    }
                    if (contact.full_name && !contact.email) {
                        return contact.full_name;
                    }
                    return contact.email;
                };
                var isValidContact = function isValidContact(contact) {
                    return contact.full_name || contact.email;
                };

                // Card fields
                ctrl.startSearchCardFields = [{
                    label: 'Contact',
                    key: '_contactId',
                    optionsKey: 'id',
                    optionsLabelKey: 'identifier',
                    type: Gecko.Field.TYPE_SELECT,
                    getOptions: asyncOptions.create(new Gecko.Contact().orderBy('full_name').rfields({ contact: ['full_name', 'email'] }).perPage(15), function () {
                        this.options = this.options.filter(isValidContact).map(function (contact) {
                            return {
                                id: contact.id,
                                identifier: getContactIdentifier(contact)
                            };
                        });
                    }, { search: 'keywordSearch' }),
                    colClass: 'none',
                    required: true
                }];

                // Check contact has number
                scope.$watch('ctrl._contactId', function (oldVal, newVal) {
                    if (oldVal != newVal) {
                        ctrl.setValues(ctrl._contactId);
                    }
                });

                // Card buttons
                ctrl.startSearchCardBtns = [{
                    safeMode: true,
                    preset: 'save',
                    title: 'Next',
                    icon: 'fa-check',
                    hideWhen: function hideWhen() {
                        return ctrl.noNumbers;
                    },
                    action: function action() {
                        // Validate
                        if (!ctrl.contactId) {
                            return GeckoUI.messenger.error('Select a contact to call.');
                        }
                        // Reset prepare data
                        $geckoCall.reset(true);
                        // Open call pane
                        $geckoCall.open(ctrl.contactId);
                    }
                }, {
                    title: 'Reset',
                    icon: 'fa-times',
                    position: 'secondary',
                    action: ctrl.resetValues,
                    hideWhen: function hideWhen() {
                        return !ctrl.noNumbers;
                    }
                }];
            }

        };
    }

    angular.module('GeckoEngage').directive('geckoCallStartSearchCard', geckoCallStartSearchCard);
})();