(function () {
    'use strict';

    function SettingsDeletedTemplatesCtrl($scope, $state, $stateParams, templates, categories, Gecko, LoadingIndicator, $geckoModal, handleMassAction) {
        var ctrl = this;
        ctrl.templates = templates.toInstances();
        ctrl.categories = categories.toArray();
        ctrl.pagination = templates.pagination;

        ctrl.tabs = [{
            id: 'templates',
            title: 'Templates',
            state: 'settingstemplates'
        }, {
            id: 'deleted_templates',
            title: 'Deleted Templates',
            state: 'deletedtemplates'
        }];

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'name',
            sortKey: 'name',
            colClass: 'col-xs-4'
        }, {
            title: 'Type',
            key: 'type',
            sortKey: 'type',
            colClass: 'col-xs-4',
            render: function render(type) {
                return GeckoUI.getObjectByKey(Gecko.Template.types, 'id', type).title;
            }
        }, {
            title: 'Subject',
            key: 'subject',
            sortKey: 'subject',
            colClass: 'col-xs-4'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'settingstemplate.editor',
            params: { template_id: 'id' }
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Restore template',
            action: function action(template, index) {
                GeckoUI.dialog.confirm('Are you sure you want to restore this template?', function (value) {
                    if (value) {
                        return new Gecko.BaseModel().call('/templates/' + template.id + '/restore', 'GET').then(function () {
                            ctrl.templates.splice(index, 1);
                            GeckoUI.messenger.success('Template has been restored');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        }];

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { label: object[labelKey], value: object.id };
                });
            };
        };

        ctrl.filters = [{
            title: 'Type',
            type: 'radio',
            options: objectsToOptions(Gecko.Template.types)('title'),
            stateParam: 'type'
        }, {
            title: 'Category',
            type: 'dropdown',
            options: objectsToOptions(ctrl.categories)('title'),
            stateParam: 'category_id'
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Deleted Templates',
            active: true
        }];

        // Mass action
        ctrl.templatesSelects = [];
        ctrl.selectOptions = {
            noPersist: true
        };

        ctrl.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !ctrl.templatesSelects.length;
            }
        };

        ctrl.actionsBtn.items = [{
            title: 'Restore',
            action: function action() {

                GeckoUI.dialog.confirm('Restore ' + ctrl.templatesSelects.length + ' templates?', function (confirm) {
                    if (confirm && ctrl.templatesSelects.length) {
                        return Promise.all(ctrl.templatesSelects.map(function (templateId) {
                            return new Gecko.BaseModel().call('/templates/' + templateId + '/restore', 'GET');
                        })).then(function () {
                            $state.go($state.$current.name, $stateParams, { reload: true });
                            GeckoUI.messenger.success('Your action has been applied to ' + ctrl.templatesSelects.length + ' template' + (ctrl.templatesSelects.length > 1 ? 's' : ''));
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_DELETE);
            }
        }];
    }

    angular.module('GeckoEngage').controller('SettingsDeletedTemplatesCtrl', SettingsDeletedTemplatesCtrl);
})();