(function () {
    'use strict';

    function DevicesCtrl($state, devices, $geckoModal) {
        var ctrl = this;
        ctrl.devices = devices.toInstances();
        ctrl.pagination = devices.pagination;
        ctrl.currentDevice = null;

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'label',
            colClass: 'col-xs-4',
            render: function render(value, col, device) {
                return value ? value : device.udid;
            }
        }, {
            title: 'Type',
            key: 'type',
            colClass: 'col-xs-2'
        }, {
            title: 'App version',
            key: 'app_version',
            colClass: 'col-xs-2'
        }, {
            title: 'OS version',
            key: 'os_version',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Last login',
            key: 'related_user.full_name',
            colClass: 'col-xs-3',
            action: function action(device) {
                return $state.go('user', { user_id: device.related_user.id });
            }
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(device) {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_DEVICES_UPDATE)) return;

                ctrl.currentDevice = device;

                $geckoModal.inputModal('Rename device', ctrl.currentDevice.label, 'It\'s best practise to give your device a memorable name to make it easier to track usage.').result.then(function (val) {

                    ctrl.currentDevice.label = val;
                    ctrl.currentDevice.save().then(function () {
                        GeckoUI.messenger.success('Your device name has been saved');
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.error);
                    });
                });
            }
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Devices',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('DevicesCtrl', DevicesCtrl);
})();