(function () {
    'use strict';

    function FormShareCtrl($scope, $state, $formBuilderService, form, formFields, asyncOptions, OptionsPresets, geckoEventPickerService) {
        var ctrl = this;
        ctrl.form = form;
        ctrl.formFields = formFields.toArray();

        //Fields
        ctrl.shareFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Webform Link',
            description: 'Use this URL to access your form on a Gecko hosted landing page. This URL will change when you configure the \'Captured at event\' or \'Prepopulate\' options. Find out more on <a href="https://academy.geckoengage.com/en/articles/6956130-sharing-your-form" target="_blank">Gecko Academy</a>.',
            colClass: 'no-class'
        }, {
            id: 'link',
            type: Gecko.Field.TYPE_LINK,
            colClass: 'no-class gecko-field-code'
        }];

        ctrl.eventFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Captured At Event',
            description: 'Responses submitted through this form via the above URL will be marked as \'captured at\' the event chosen below. (Note: updating the event will update the URL above as well)',
            colClass: 'no-class'
        }, geckoEventPickerService.prepareField({
            id: 'event_id',
            //label: 'Event & Session Assignment',
            type: Gecko.Field.TYPE_TIERED_SELECT,
            optionsInit: OptionsPresets.events.init,
            getOptions: OptionsPresets.events.get,
            optionsKey: 'id',
            optionsLabelKey: 'nice_title',
            colClass: 'no-class'
        })];

        ctrl.captureByFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Captured By User',
            description: 'Responses submitted through this form via the above URL will be marked as \'captured by\' the user chosen below. (Note: updating the user will update the URL above with their user ID as well)',
            colClass: 'no-class'
        }, {
            id: 'captured_by_id',
            type: Gecko.Field.TYPE_SELECT,
            getOptions: asyncOptions.create(new Gecko.User().orderBy('full_name').rfields({ user: ['id', 'full_name', 'email'] })),
            options: [],
            optionsKey: 'id',
            choiceTemplate: 'name-title-email-subtitle',
            optionsLabelKey: 'full_name',
            colClass: 'no-class'
        }];

        ctrl.embedFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Embed Your Form on a Website',
            description: 'Paste the following code into your website\'s HTML. If you experience issues with your embed, please click on the "Get Alternative Code" option and re-attempt with the updated code.  Find out more on <a href="https://academy.geckoengage.com/en/articles/6956130-sharing-your-form#h_7eba339d02" target="_blank">Gecko Academy</a>.',
            colClass: 'no-class'
        }, {
            id: 'iframe',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'no-class gecko-field-code',
            readonly: true,
            hideWhen: function hideWhen() {
                return ctrl.showLegacyEmbedCode;
            }
        }, {
            id: 'iframeLegacy',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'no-class gecko-field-code',
            readonly: true,
            hideWhen: function hideWhen() {
                return !ctrl.showLegacyEmbedCode;
            }
        }];

        //Values
        ctrl.values = {
            uuid: ctrl.form.uuid,
            style: 'modern',
            event_id: null,
            captured_by_id: null,
            link: $formBuilderService.buildShareURL(ctrl.form.uuid, 'modern', null, false),
            iframe: $formBuilderService.buildShareUrlWrapper($formBuilderService.buildIframeShareURL(ctrl.form.uuid)),
            iframeLegacy: $formBuilderService.buildShareUrlWrapper($formBuilderService.buildShareURL(ctrl.form.uuid, 'iframe', null, false), true),
            safeLink: $formBuilderService.buildShareURL(ctrl.form.uuid, 'modern', null, true),
            gecko_base_url: Gecko.base_url + '/qr?url='
        };

        // Cog drop down
        ctrl.optionsBtn = {
            title: 'Get QR Code',
            iconRight: 'fa-angle-down',
            iconLeft: 'fa-qrcode',
            btnClass: 'gecko-ui-material btn-sm btn-default'
        };

        ctrl.optionsBtn.items = [{
            title: 'Standard',
            action: function action() {
                return ctrl.getQr('plain');
            }
        }, {
            title: 'Snapchat',
            action: function action() {
                return ctrl.getQr('snapchat');
            }
        }, {
            title: 'Gecko',
            action: function action() {
                return ctrl.getQr('gecko');
            }
        }, {
            title: 'Wechat',
            action: function action() {
                return ctrl.getQr('wechat');
            }
        }];

        ctrl.getQr = function (style) {
            //Open new window (to get around popup blocker)
            var qrWindow = window.open('', '_blank');
            return $formBuilderService.getShortUrl(ctrl.values.safeLink).then(function (data) {
                var _url = Gecko.base_url + '/qr?url=' + data.shortURL + '&style=' + style;
                // Open QR in the new window (to get around popup blocker)
                qrWindow.location.href = _url;
            }).catch(function (err) {
                return GeckoUI.messenger.error(err);
            });
        };

        // Copy embed code
        ctrl.copy = function (element) {
            try {
                var copyText = document.getElementById(element);

                // Text area copy
                if (copyText.select) {
                    copyText.select();
                    var result = document.execCommand('copy');
                    GeckoUI.messenger.info('Copied to your clipboard');
                    // Div copy
                } else {
                    if (document.selection) {
                        var range = document.body.createTextRange();
                        range.moveToElementText(document.getElementById(element));
                        range.select().createTextRange();
                        document.execCommand("Copy");
                    } else if (window.getSelection) {
                        var range = document.createRange();
                        document.getElementById(element).style.display = "block";
                        range.selectNode(document.getElementById(element));
                        window.getSelection().addRange(range);
                        document.execCommand("Copy");
                    }
                    GeckoUI.messenger.info('Copied to your clipboard');
                }
            } catch (e) {}
        };

        ctrl.shortUrlAvailable = function () {
            return ctrl.values.link.includes('{{') ? false : true;
        };

        ctrl.getShortUrl = function () {
            $formBuilderService.getShortUrl(ctrl.values.safeLink).then(function (data) {
                ctrl.values.link = data.shortURL;
                ctrl.values.safeLink = data.shortURL;
            }).catch(function (err) {
                return GeckoUI.messenger.error(err);
            });
        };

        //Watch for param changes
        var _tempValues = {
            style: ctrl.values.style,
            event_id: ctrl.values.event_id,
            captured_by_id: ctrl.values.captured_by_id
        };
        Object.defineProperties(ctrl.values, {
            'style': {
                get: function get() {
                    return _tempValues['style'];
                },
                set: function set(value) {
                    _tempValues['style'] = value;

                    ctrl.values.link = $formBuilderService.buildShareURL(ctrl.values.uuid, ctrl.values.style, ctrl.values.event_id, false, ctrl.values.captured_by_id);
                    ctrl.values.safeLink = $formBuilderService.buildShareURL(ctrl.values.uuid, ctrl.values.style, ctrl.values.event_id, true, ctrl.values.captured_by_id);
                }
            },
            'event_id': {
                get: function get() {
                    return _tempValues['event_id'];
                },
                set: function set(value) {
                    _tempValues['event_id'] = value;

                    ctrl.values.link = $formBuilderService.buildShareURL(ctrl.values.uuid, ctrl.values.style, ctrl.values.event_id, false, ctrl.values.captured_by_id);
                    ctrl.values.safeLink = $formBuilderService.buildShareURL(ctrl.values.uuid, ctrl.values.style, ctrl.values.event_id, true, ctrl.values.captured_by_id);
                    ctrl.values.iframeLegacy = $formBuilderService.buildShareUrlWrapper($formBuilderService.buildShareURL(ctrl.form.uuid, 'iframe', ctrl.values.event_id, false, ctrl.values.captured_by_id), true);
                    ctrl.values.iframe = $formBuilderService.buildShareUrlWrapper($formBuilderService.buildIframeShareURL(ctrl.form.uuid, ctrl.values.event_id, ctrl.values.captured_by_id));
                }
            },
            'captured_by_id': {
                get: function get() {
                    return _tempValues['captured_by_id'];
                },
                set: function set(value) {
                    _tempValues['captured_by_id'] = value;

                    ctrl.values.link = $formBuilderService.buildShareURL(ctrl.values.uuid, ctrl.values.style, ctrl.values.event_id, false, ctrl.values.captured_by_id);
                    ctrl.values.safeLink = $formBuilderService.buildShareURL(ctrl.values.uuid, ctrl.values.style, ctrl.values.event_id, true, ctrl.values.captured_by_id);
                    ctrl.values.iframeLegacy = $formBuilderService.buildShareUrlWrapper($formBuilderService.buildShareURL(ctrl.form.uuid, 'iframe', ctrl.values.event_id, false, ctrl.values.captured_by_id), true);
                    ctrl.values.iframe = $formBuilderService.buildShareUrlWrapper($formBuilderService.buildIframeShareURL(ctrl.form.uuid, ctrl.values.event_id, ctrl.values.captured_by_id));
                }
            }
        });
    }

    angular.module('GeckoEngage').controller('FormShareCtrl', FormShareCtrl);
})();