;(function () {

    function TemplateModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('templateSendTestModal', function () {
            return {
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal\n                            data-title="Send a test email"\n                            alert-message="{{ctrl.errorMessage}}"\n                            fields="ctrl.fields"\n                            values="ctrl.values"\n                            footer-btns="ctrl.footerBtns">\n                        </gecko-modal>\n                    ',
                controller: 'TemplateSendTestModalCtrl'
            };
        });
    }

    function TemplateSendTestModalCtrl(Gecko, $modalInstance) {
        var ctrl = this;
        ctrl.values = {
            email: Gecko.user.email || null
        };
        ctrl.errorMessage = '';
        ctrl.fields = [{
            id: 'email',
            label: 'Email Address',
            description: 'A test email using the template will be sent to the email provided.',
            colClass: 'none'
        }];
        ctrl.footerBtns = [{
            title: 'Send',
            icon: 'fa-sign-in',
            btnClass: 'btn-primary',
            action: function action() {
                return $modalInstance.close(ctrl.values.email);
            }
        }];
    }

    angular.module('GeckoEngage').config(TemplateModals).controller('TemplateSendTestModalCtrl', TemplateSendTestModalCtrl);
})();