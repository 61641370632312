(function () {
    'use strict';

    function videoStreamDetails() {
        return {
            restrict: 'E',
            template: '\n                <div class="video-stream-details">\n                    <div ng-click="ctrl.copy(\'.video-stream-details-url\')">\n                        <div class="video-stream-detail-label">Stream URL:</div>\n                        <div class="video-stream-detail-value">\n                            {{ctrl.streamUrl}}\n                            <i class="fa fa-copy" />\n                        </div>\n                        <input \n                            class="hidden-field video-stream-details-url" \n                            ng-model="ctrl.streamUrl" />\n                    </div>\n\n                    <div ng-click="ctrl.copy(\'.video-stream-details-key\')">\n                        <div class="video-stream-detail-label">Stream Key:</div>\n                        <div class="video-stream-detail-value">\n                            {{ctrl.streamKey}}\n                            <i class="fa fa-copy" />\n                        </div>                        \n                        <input\n                            class="hidden-field video-stream-details-key"\n                            ng-model="ctrl.streamKey" />\n                    </div>\n                </div>\n            ',
            scope: {
                streamUrl: '=',
                streamKey: '='
            },
            bindToController: true,
            controllerAs: 'ctrl',
            controller: videoStreamDetailsCtrl
        };
    }

    function videoStreamDetailsCtrl($element) {
        var ctrl = this;

        // Copy 
        ctrl.copy = function (field) {
            try {
                var copyText = $element.find(field);
                // Text area copy
                if (copyText.select) {
                    copyText.select();
                    var result = document.execCommand('copy');
                    GeckoUI.messenger.info('Copied to your clipboard');
                }
            } catch (e) {
                console.log(e);
            }
        };
    }

    angular.module('GeckoEngage').controller('videoStreamDetailsCtrl', videoStreamDetailsCtrl).directive('videoStreamDetails', videoStreamDetails);
})();