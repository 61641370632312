(function () {
    'use strict';

    function sessionStatusBadge() {
        return {
            restrict: 'E',
            template: '\n                <span class="session-status-badge label gecko-tooltip-enabled" ng-class="ctrl.class" title="{{ ctrl.count }} attendees" data-placement="top">\n                    {{ ctrl.str }}\n                </div>\n            ',
            scope: {
                maximum: '=',
                countExcGuests: '=',
                countIncGuests: '='
            },
            bindToController: true,
            controllerAs: 'ctrl',
            controller: sessionStatusBadgeCtrl
        };
    }

    function sessionStatusBadgeCtrl() {
        var ctrl = this;

        ctrl.getClass = function () {
            var count = Number(ctrl.countIncGuests || ctrl.count) || 0;

            if (!ctrl.maximum) return 'default';

            if (ctrl.maximum === count) return 'danger';

            if (count >= ctrl.maximum - 3) return 'warning';

            return 'default';
        };

        ctrl.count = (ctrl.countExcGuests ? Number(ctrl.countExcGuests) : Number(ctrl.countIncGuests)) || 0;
        ctrl.maximum = Number(ctrl.maximum); // || '∞'; // Infinity symbol
        ctrl.str = '' + ctrl.count + (ctrl.maximum ? ' / ' + ctrl.maximum : '');
        ctrl.class = 'label-' + ctrl.getClass();
    }

    angular.module('GeckoEngage').controller('sessionStatusBadgeCtrl', sessionStatusBadgeCtrl).directive('sessionStatusBadge', sessionStatusBadge);
})();