var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/*! vex.dialog.js 2.3.0 */
(function () {
  var a;a = function a(_a, b) {
    var c, d;return null == b ? _a.error("Vex is required to use vex.dialog") : (c = function c(b) {
      var c;return c = {}, _a.each(b.serializeArray(), function () {
        return c[this.name] ? (c[this.name].push || (c[this.name] = [c[this.name]]), c[this.name].push(this.value || "")) : c[this.name] = this.value || "";
      }), c;
    }, d = {}, d.buttons = { YES: { text: "OK", type: "submit", className: "vex-dialog-button-primary" }, NO: { text: "Cancel", type: "button", className: "vex-dialog-button-secondary", click: function click(a) {
          return a.data().vex.value = !1, b.close(a.data().vex.id);
        } } }, d.defaultOptions = { callback: function callback() {}, afterOpen: function afterOpen() {}, message: "Message", input: '<input name="vex" type="hidden" value="_vex-empty-value" />', value: !1, buttons: [d.buttons.YES, d.buttons.NO], showCloseButton: !1, onSubmit: function onSubmit(e) {
        var f, g;return f = _a(this), g = f.parent(), e.preventDefault(), e.stopPropagation(), g.data().vex.value = d.getFormValueOnSubmit(c(f)), b.close(g.data().vex.id);
      }, focusFirstInput: !0 }, d.defaultAlertOptions = { message: "Alert", buttons: [d.buttons.YES] }, d.defaultConfirmOptions = { message: "Confirm" }, d.open = function (c) {
      var e;return c = _a.extend({}, b.defaultOptions, d.defaultOptions, c), c.content = d.buildDialogForm(c), c.beforeClose = function (a) {
        return c.callback(a.data().vex.value);
      }, e = b.open(c), c.focusFirstInput && e.find('button[type="submit"], button[type="button"], input[type="submit"], input[type="button"], textarea, input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"]').first().focus(), e;
    }, d.alert = function (b) {
      return "string" == typeof b && (b = { message: b }), b = _a.extend({}, d.defaultAlertOptions, b), d.open(b);
    }, d.confirm = function (b) {
      return "string" == typeof b ? _a.error("dialog.confirm(options) requires options.callback.") : (b = _a.extend({}, d.defaultConfirmOptions, b), d.open(b));
    }, d.prompt = function (b) {
      var c;return "string" == typeof b ? _a.error("dialog.prompt(options) requires options.callback.") : (c = { message: '<label for="vex">' + (b.label || "Prompt:") + "</label>", input: '<input name="vex" type="text" class="vex-dialog-prompt-input" placeholder="' + (b.placeholder || "") + '"  value="' + (b.value || "") + '" />' }, b = _a.extend({}, c, b), d.open(b));
    }, d.buildDialogForm = function (b) {
      var c, e, f;return c = _a('<form class="vex-dialog-form" />'), f = _a('<div class="vex-dialog-message" />'), e = _a('<div class="vex-dialog-input" />'), c.append(f.append(b.message)).append(e.append(b.input)).append(d.buttonsToDOM(b.buttons)).bind("submit.vex", b.onSubmit), c;
    }, d.getFormValueOnSubmit = function (a) {
      return a.vex || "" === a.vex ? "_vex-empty-value" === a.vex ? !0 : a.vex : a;
    }, d.buttonsToDOM = function (c) {
      var d;return d = _a('<div class="vex-dialog-buttons" />'), _a.each(c, function (e, f) {
        var g;return g = _a('<button type="' + f.type + '"></button>').text(f.text).addClass(f.className + " vex-dialog-button " + (0 === e ? "vex-first " : "") + (e === c.length - 1 ? "vex-last " : "")).bind("click.vex", function (c) {
          return f.click ? f.click(_a(this).parents(b.getSelectorFromBaseClass(b.baseClassNames.content)), c) : void 0;
        }), g.appendTo(d);
      }), d;
    }, d);
  }, "function" == typeof define && define.amd ? define(["jquery", "vex"], a) : "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) ? module.exports = a(require("jquery"), require("./vex.js")) : window.vex.dialog = a(window.jQuery, window.vex);
}).call(this);