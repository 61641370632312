(function () {

    'use strict';

    function DownloadRoute($stateProvider) {
        $stateProvider.state('download', {
            url: "/download",
            templateUrl: "/modules/download/views/index.73886b4f.html",
            controller: 'DownloadCtrl',
            controllerAs: 'ctrl',
            page_title: 'Download Apps'
        });
    }

    angular.module('GeckoEngage.routes').config(DownloadRoute);
})();