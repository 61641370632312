var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
    'use strict';

    function $geckoSocket($rootScope, $state) {

        var _this = {};

        // Remote push events handling.
        var _stateEvents = {},
            _globalEvents = {};

        // Build channel name and event name
        _this.prepareConfig = function (eventName) {

            // call:updated
            // channelName = call
            // eventName = updated

            var parts = eventName.split(':');
            var channelName = 'presence-' + Gecko.account.routing_id + '-' + parts[0];
            eventName = parts[1] ? parts[1] : parts[0]; // Fallback to channelName when no ":" is found

            return [channelName, eventName];
        };

        _this.trigger = function (eventName, data) {
            var config = _this.prepareConfig(eventName);
            var channelName = config[0];
            eventName = config[1];

            // Get / open channel
            var channel = Gecko.pusher.open(channelName);
            // Trigger event
            channel.trigger('client-' + eventName, data);
        };

        // Wrapper function for dealing with client side triggered events
        _this.registerClientEvent = function (eventName, callback, persist) {
            return _this.registerEvent('client-' + eventName, callback, persist);
        };

        _this.registerEvent = function (eventName, callback, persist) {

            // Check for account id
            if (!Gecko.account) return false;

            // This is messy but is ONLY used for call transfering
            if (eventName.includes('client-')) {
                var config = _this.prepareConfig(eventName);
                var channelName = config[0].replace('-client', '');
                eventName = 'client-' + config[1];
            } else {
                var config = _this.prepareConfig(eventName);
                var channelName = config[0];
                eventName = config[1];
            }

            // Determine if state or gloab level
            var _events;
            if (persist) {
                _events = _globalEvents;
            } else {
                _events = _stateEvents;
            }

            var event = {};
            // Open pusher channel
            event.channel = Gecko.pusher.open(channelName);
            // Bind event
            event.channel.bind(eventName, callback, event);
            // Store callback for unbinding
            event.callback = callback;

            // Store watch
            if (persist) {
                // Set a refrence on events object
                if (!_events[eventName]) {
                    _events[eventName] = [];
                }
                _events[eventName].push(event);
            } else {
                // Set a refrence on events object
                if (!_events[$state.current.name]) {
                    _events[$state.current.name] = {};
                }
                _events[$state.current.name][eventName] = event;
            }

            // Return unwatcher
            return function () {
                event.channel.unbind(eventName, event.callback);
            };
        };

        _this.unregisterEvent = function (eventName, stateOverride) {
            if (!_stateEvents[stateOverride || $state.current.name]) return;
            var event = _stateEvents[stateOverride || $state.current.name][eventName];
            event.channel.unbind(eventName, event.callback);
        };

        _this.watch = function (model, arr, props, cb) {
            return _this.registerEvent(model + ':updated', function (data) {
                try {
                    // Array
                    if (Array.isArray(arr)) {
                        var item = (arr || []).find(function (i) {
                            return i.id === Number(data.id);
                        });

                        if (item) {
                            // Only update item when in array and prop has changed
                            props.forEach(function (p) {
                                if (item[p] !== undefined && item[p] !== data[p]) {
                                    item[p] = data[p];
                                }
                            });
                        }
                        // Object
                    } else if ((typeof arr === 'undefined' ? 'undefined' : _typeof(arr)) === 'object') {
                        var item = arr;
                        props.forEach(function (p) {
                            if (item[p] !== undefined && item[p] !== data[p]) {
                                item[p] = data[p];
                            }
                        });
                    }

                    // Always fire the cb
                    if (cb) cb(data);
                } catch (e) {
                    console.log(e);
                }
            });
        },

        // Unbind (state) events when state changes
        $rootScope.$on('$stateChangeSuccess', function (e, toState, toParams, fromState) {
            if (_stateEvents[fromState.name]) {
                angular.forEach(_stateEvents[fromState.name], function (key, event) {
                    _this.unregisterEvent(event, fromState.name);
                });
            }
        });

        // Close pusher when logged out
        Gecko.on('unavailable', function () {
            // Close connection
            Gecko.pusher.close();
            // Reset events
            _stateEvents = {};
            _globalEvents = {};
        });

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoSocket', $geckoSocket);
})();