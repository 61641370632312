(function () {
    'use strict';

    function OrganiationTypesRoute($stateProvider) {
        $stateProvider.state('organisationtypes', {
            url: "/settings/organisation_types?{page}&{order}&{sort}&{search}",
            templateUrl: "/modules/organisation_types/views/index.8f2c01df.html",
            controller: 'OrganisationTypesListCtrl',
            controllerAs: 'ctrl',
            page_title: 'Organisation types',
            requires: [Gecko.ABILITY_ORGANISATIONS_VIEW],
            redirectKey: 'module_organisation_types',
            resolve: {
                organisationTypes: function organisationTypes($stateParams) {
                    var organisation_types = new Gecko.OrganisationType().includeCount('organisations');

                    if ($stateParams.search) organisation_types.search($stateParams.search);

                    return organisation_types.page($stateParams.page).perPage(GeckoUI.getPageLimit(this.self.name)).orderBy('title').rfields({ organisationType: ['title'] }).get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(OrganiationTypesRoute);
})();