(function () {
    'use strict';

    function SettingsTemplatesCtrl($scope, $state, $stateParams, templates, categories, Gecko, LoadingIndicator, $geckoModal, handleMassAction) {
        var ctrl = this;
        ctrl.templates = templates.toInstances();
        ctrl.categories = categories.toArray();
        ctrl.pagination = templates.pagination;

        ctrl.tabs = [{
            id: 'templates',
            title: 'Templates',
            state: 'settingstemplates'
        }, {
            id: 'deleted_templates',
            title: 'Deleted Templates',
            state: 'deletedtemplates'
        }];

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'name',
            sortKey: 'name',
            colClass: 'col-xs-4'
        }, {
            title: 'Type',
            key: 'type',
            sortKey: 'type',
            colClass: 'col-xs-4',
            render: function render(type) {
                return GeckoUI.getObjectByKey(Gecko.Template.types, 'id', type).title;
            }
        }, {
            title: 'Subject',
            key: 'subject',
            sortKey: 'subject',
            colClass: 'col-xs-4'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'settingstemplate.editor',
            params: { template_id: 'id' }
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Send test email',
            action: function action(template) {
                $geckoModal.templateSendTestModal().result.then(function (email) {
                    if (email) {
                        LoadingIndicator.resolve(template.test(email)).then(function (template) {
                            GeckoUI.messenger.success('Test email was sent successfully');
                            $state.go($state.$current.name, $stateParams);
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            },
            hideWhen: function hideWhen(template) {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_VIEW) || template.type !== Gecko.Template.TYPE_EMAIL;
            }
        }, {
            title: 'Clone template',
            action: function action(template) {
                new Gecko.Template().get(template.id).then(function (template) {
                    template.clone().then(function (clone) {
                        ctrl.templates.unshift(clone);
                        GeckoUI.messenger.success('Template was cloned successfully');
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                    });
                });
            }
        }, {
            title: 'Delete template',
            action: function action(template, index) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this template?', function (value) {
                    if (value) {
                        template.remove().then(function () {
                            ctrl.templates.splice(index, 1);
                            GeckoUI.messenger.success('Template deleted');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        }];

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { label: object[labelKey], value: object.id };
                });
            };
        };

        ctrl.filters = [{
            title: 'Type',
            type: 'radio',
            options: objectsToOptions(Gecko.Template.types)('title'),
            stateParam: 'type'
        }, {
            title: 'Category',
            type: 'dropdown',
            options: objectsToOptions(ctrl.categories)('title'),
            stateParam: 'category_id'
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Templates',
            active: true
        }];

        // Mass action
        ctrl.templatesSelects = [];
        ctrl.selectOptions = {
            noPersist: true
        };

        ctrl.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !ctrl.templatesSelects.length;
            }
        };

        ctrl.actionsBtn.items = [{
            title: 'Add to Category',
            action: function action() {
                $geckoModal.eventCategoryModal(ctrl.categories, Gecko.Category.TYPE_TEMPLATE).result.then(function () {
                    var categoryIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

                    new Gecko.Template().massAction({ template_ids: ctrl.templatesSelects }, categoryIds).then(function (data) {
                        // Handle mass action
                        handleMassAction(data, $state.$current.name);
                        // Reset selected row
                        ctrl.templatesSelects.length = 0;
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                    });
                });
            }
        }, {
            title: 'Delete',
            action: function action() {
                GeckoUI.dialog.confirm('Delete ' + ctrl.templatesSelects.length + ' templates?', function (confirm) {
                    if (confirm) {
                        new Gecko.Template().massAction({ template_ids: ctrl.templatesSelects }, { type: 'delete' }).then(function (data) {
                            handleMassAction(data, $state.$current.name);
                            ctrl.templatesSelects.length = 0;
                        }).catch(function (err) {
                            GeckoUi.messenger.error(err);
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_DELETE);
            }
        }];
    }

    angular.module('GeckoEngage').controller('SettingsTemplatesCtrl', SettingsTemplatesCtrl);
})();