(function () {
    'use strict';

    var RUN_TYPE_NOW = 'now';
    var RUN_TYPE_LATER = 'later';
    var RUN_TYPE_CAMPAIGN = 'campaign';

    function BroadcastSettingsCtrl($scope, $state, $stateParams, $filter, Gecko, $geckoModal, campaign, unsavedAlert, email_senders, email_templates, sms_senders, sms_templates, tel_fields, openTemplatePreviewModal) {
        var ctrl = this;
        ctrl.campaign = campaign;
        ctrl.consents = [];
        ctrl.email_senders = email_senders;
        ctrl.email_templates = email_templates;
        ctrl.sms_senders = sms_senders;
        ctrl.sms_templates = sms_templates;
        ctrl.tel_fields = tel_fields;

        ctrl.getRunType = function () {
            if (ctrl.campaign.start_date && ctrl.campaign.end_date) {
                return RUN_TYPE_CAMPAIGN;
            } else if (ctrl.campaign.start_date && !ctrl.campaign.end_date) {
                return RUN_TYPE_LATER;
            }
            return RUN_TYPE_NOW;
        };

        ctrl.campaign.run_type = ctrl.getRunType();

        // Alias template id
        if (ctrl.campaign.module === Gecko.Campaign.MODULE_EMAIL) {
            ctrl.campaign.email_template_id = ctrl.campaign.template_id;
            ctrl.campaign.sms_template_id = null;
        } else if (ctrl.campaign.module === Gecko.Campaign.MODULE_SMS) {
            ctrl.campaign.email_template_id = null;
            ctrl.campaign.sms_template_id = ctrl.campaign.template_id;
        }

        ctrl.describeFields = [{
            id: 'title',
            type: Gecko.Field.TYPE_TEXT,
            label: "Title",
            description: "The title for this broadcast, will be used to identify the broadcast throughout Gecko.",
            colClass: "col-xs-12",
            required: true
        }, {
            id: 'description',
            type: Gecko.Field.TYPE_TEXTAREA,
            label: "Description",
            description: "An overview of the broadcast",
            colClass: "col-xs-12"
        }];

        ctrl.typeFields = [{
            id: 'module',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            disabled: $stateParams.broadcast_id !== 'new',
            options: [{
                value: Gecko.Campaign.MODULE_EMAIL,
                icon: 'fa-envelope',
                title: 'Email',
                disabledWhen: function disabledWhen() {
                    return !Gecko.able(Gecko.ABILITY_EMAIL_BROADCASTS_CREATE);
                }
            }, {
                value: Gecko.Campaign.MODULE_SMS,
                icon: 'fa-comment-dots',
                title: 'SMS',
                disabledWhen: function disabledWhen() {
                    return !Gecko.able(Gecko.ABILITY_SMS_BROADCASTS_CREATE);
                }
            }]
        }, {
            label: 'Email Sender',
            id: 'email_sender_id',
            type: Gecko.Field.TYPE_SELECT,
            getOptions: function getOptions() {},
            options: ctrl.email_senders,
            choiceTemplate: 'name-email-choice',
            matchTemplate: 'name-email-match',
            optionsKey: 'id',
            optionsLabelKey: 'name',
            required: true,
            colClass: "col-xs-12",
            description: 'Choose the email address to send this broadcast. View / edit your email senders <a ui-sref="verifiedsenders.senders()">here.</a>',
            hideWhen: function hideWhen() {
                return ctrl.campaign.module !== Gecko.Campaign.MODULE_EMAIL;
            },
            optionsGroup: function optionsGroup(item) {
                return item.verified ? 'Verified' : 'Not Verified';
            }
        }, {
            label: 'Email Template',
            id: 'email_template_id',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.email_templates,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            required: true,
            colClass: "col-xs-12",
            description: 'Select the template to be sent by this broadcast. Don\'t see a suitable template? Create a <a target="_blank" href="/#/settings/templates/new/settings">new template</a>.',
            hideWhen: function hideWhen() {
                return ctrl.campaign.module !== Gecko.Campaign.MODULE_EMAIL;
            }
        }, {
            label: 'SMS Sender',
            id: 'sms_sender_id',
            type: Gecko.Field.TYPE_SELECT,
            getOptions: function getOptions() {},
            options: ctrl.sms_senders,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            choiceTemplate: 'sender-choice',
            matchTemplate: 'sender-match',
            colClass: "col-xs-12",
            required: true,
            description: 'Choose the SMS number that will send this broadcast. View / edit your SMS senders <a ui-sref="voipnumbers()">here.</a>',
            hideWhen: function hideWhen() {
                return ctrl.campaign.module !== Gecko.Campaign.MODULE_SMS;
            },
            optionsGroup: function optionsGroup(item) {
                return item && item.type && item.type == Gecko.Sender.GECKOCHAT ? "GeckoChat" : undefined;
            }
            //disabledWhen    : () => ctrl.disableEditing()
        }, {
            label: 'SMS Template',
            id: 'sms_template_id',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.sms_templates,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            colClass: "col-xs-12",
            required: true,
            description: 'Select the template to be sent by this broadcast. Don\'t see a suitable template? Create a <a target="_blank" href="/#/settings/templates/new/settings">new template</a>.',
            hideWhen: function hideWhen() {
                return ctrl.campaign.module !== Gecko.Campaign.MODULE_SMS;
            }
            //disabledWhen    : () => ctrl.disableEditing()
        }, {
            type: Gecko.Field.TYPE_BUTTON,
            colClass: 'col-xs-12 gecko-field-template-preview-btn-container',
            btnLabel: 'Preview Template',
            icon: 'fa-eye',
            hideWhen: function hideWhen() {
                return ctrl.campaign.module === Gecko.Campaign.MODULE_SMS ? !ctrl.campaign.sms_template_id : !ctrl.campaign.email_template_id;
            },
            action: function action() {
                var templateId = ctrl.campaign.module === Gecko.Campaign.MODULE_SMS ? ctrl.campaign.sms_template_id : ctrl.campaign.email_template_id;
                return templateId ? openTemplatePreviewModal(templateId) : null;
            }
        }, {
            label: 'Ignore Consent Preferences',
            id: 'ignore_consents',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: "col-xs-12",
            description: 'When enabled, contacts will be added to the broadcasts without checking their consent preferences.',
            hideWhen: function hideWhen() {
                return !ctrl.showConsentReasons();
            }
        }, {
            label: 'Number to SMS',
            id: 'contact_field_id',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.tel_fields,
            optionsKey: 'id',
            optionsLabelKey: 'label',
            colClass: "col-xs-12",
            description: 'The preferred contact field to SMS.',
            placeholder: 'Automatically choose number',
            hideWhen: function hideWhen() {
                return ctrl.campaign.module !== Gecko.Campaign.MODULE_SMS;
            }
        }];

        ctrl.showConsentReasons = function () {
            return Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) && !Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK) && ctrl.consents && ctrl.consents.length;
        };

        ctrl.getConsentReasons = function (prop) {
            ctrl.consents = [];

            if (prop === '') {
                ctrl.campaign.module === Gecko.Campaign.MODULE_EMAIL ? prop = 'email_template_id' : prop = 'sms_template_id';
            }

            // Input is cleared
            if (!ctrl.campaign[prop]) return;

            new Gecko.Template().rfields({ template: ['name'], consent: ['title'] }).include('current_granted_consents').get(ctrl.campaign[prop]).then(function (template) {
                ctrl.consents = template.related_current_granted_consents;
            });
        };

        if (Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) && !Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK)) {
            // Load consents (from set template)
            if ($stateParams.broadcast_id !== 'new') ctrl.getConsentReasons(ctrl.campaign.module === Gecko.Campaign.MODULE_EMAIL ? 'email_template_id' : 'sms_template_id');
            ctrl.typeFields.find(function (f) {
                return f.id === 'email_template_id';
            }).onChange = ctrl.getConsentReasons.bind(this, 'email_template_id');
            ctrl.typeFields.find(function (f) {
                return f.id === 'sms_template_id';
            }).onChange = ctrl.getConsentReasons.bind(this, 'sms_template_id');
            ctrl.typeFields.find(function (f) {
                return f.id === 'module';
            }).onChange = ctrl.getConsentReasons.bind(this, '');
            // Load consents (from related template)
            if ($stateParams.broadcast_id !== 'new') {
                if (ctrl.campaign.module === Gecko.Campaign.MODULE_EMAIL) ctrl.getConsentReasons('email_template_id');
                if (ctrl.campaign.module === Gecko.Campaign.MODULE_SMS) ctrl.getConsentReasons('sms_template_id');
            }
        }

        var buildAlertField = function buildAlertField(field) {
            return {
                id: field.id,
                type: Gecko.Field.TYPE_SCRIPT,
                break: true,
                help_text: '<div class="alert alert-info" style="margin-bottom:0;">' + field.description + '</div>',
                hideWhen: field.hideWhen
            };
        };

        ctrl.runFields = [{
            id: 'run_type',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            disabled: [Gecko.Campaign.ACTIVE, Gecko.Campaign.RUNNING, Gecko.Campaign.COMPLETE].indexOf(ctrl.campaign.status) !== -1,
            options: [{
                value: RUN_TYPE_NOW,
                icon: 'fa-rocket',
                title: 'Run Now'
            }, {
                value: RUN_TYPE_LATER,
                icon: 'fa-clock',
                title: 'Run Later'
            }, {
                value: RUN_TYPE_CAMPAIGN,
                icon: 'fa-paper-plane',
                title: 'Run As Campaign'
            }]
        }, {
            id: 'start_date',
            type: Gecko.Field.TYPE_DATETIME,
            label: "Start Date",
            description: "The date and time you want this broadcast to start.",
            colClass: "col-md-6",
            hideWhen: function hideWhen() {
                return ctrl.campaign.run_type === RUN_TYPE_NOW;
            }
            //disabledWhen : () => ctrl.disableEditing()
        }, {
            id: 'end_date',
            type: Gecko.Field.TYPE_DATETIME,
            label: "End Date",
            description: "The date and time you want this broadcast to end.",
            colClass: "col-md-6",
            hideWhen: function hideWhen() {
                return ctrl.campaign.run_type === RUN_TYPE_NOW || ctrl.campaign.run_type === RUN_TYPE_LATER;
            }
            //disabledWhen : () => ctrl.disableEditing()
        }, buildAlertField({
            id: 'run_alert_now',
            description: 'This broadcast will launch as soon as you finish the setup process and confirm your preferences in the Launch tab. Click Next to select the contacts you’d like to message.',
            hideWhen: function hideWhen() {
                return ctrl.campaign.run_type !== RUN_TYPE_NOW;
            }
        }), buildAlertField({
            id: 'run_alert_later',
            description: 'This broadcast will start sending messages on the date/time specified above. The broadcast filter will remain active until the start time, meaning that new contacts meeting the conditions set in the Select Contacts tab will be added to the broadcast. Once all the scheduled contacts are messaged, this broadcast will become inactive and new contacts will cease to be added to it.',
            hideWhen: function hideWhen() {
                return ctrl.campaign.run_type !== RUN_TYPE_LATER;
            }
        }), buildAlertField({
            id: 'run_alert_campaign',
            description: 'This broadcast will start sending messages at the time/date specified in the Start Date field. Whilst the broadcast is active, any new contacts meeting the criteria of your search conditions will be added to your campaign and messaged. The broadcast will become inactive at the specified End Date.',
            hideWhen: function hideWhen() {
                return ctrl.campaign.run_type !== RUN_TYPE_CAMPAIGN;
            }
        })];

        if ($stateParams.broadcast_id === 'new') {
            ctrl.footerBtns = [{
                preset: 'next',
                action: function action() {
                    return ctrl.saveBroadcast();
                }
            }];
        } else {
            ctrl.footerBtns = [];
        }

        // Build default campaign fields
        if (!ctrl.campaign.related_campaign_fields) {
            new Gecko.Field().where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).rfields({ 'field': ['system', 'is_uneditable'] }).where('system', 1).orderBy('order').get().then(function (fields) {

                fields = fields.toArray().filter(function (field) {
                    return field.is_uneditable === false;
                });

                ctrl.campaign.related_campaign_fields = fields.map(function (field, index) {
                    return {
                        field_id: field.id,
                        list_view: index < 6 ? 1 : 0,
                        read_only: 0,
                        system: field.system
                        //related_field : field,
                    };
                });
            });
        };

        ctrl.saveBroadcast = function () {
            // Dont allow save if broadcast is COMPLETE
            if (ctrl.campaign.status === Gecko.Campaign.COMPLETE) {
                return GeckoUI.messenger.error('This broadcast is now complete and can\'t be changed.');
            }

            ctrl.campaign.dnc_period = 0;
            ctrl.campaign.campaign_fields = ctrl.campaign.related_campaign_fields;

            // Unset module sender id
            if (ctrl.campaign.module === Gecko.Campaign.MODULE_EMAIL && ctrl.campaign.sms_sender_id) {
                ctrl.campaign.sms_sender_id = null;
            } else if (ctrl.campaign.module === Gecko.Campaign.MODULE_SMS && ctrl.campaign.email_sender_id) {
                ctrl.campaign.email_sender_id = null;
            }

            // Unset date values if not suitable run type
            if (ctrl.campaign.run_type === RUN_TYPE_NOW) {
                ctrl.campaign.start_date = null;
                ctrl.campaign.end_date = null;
            } else if (ctrl.campaign.run_type === RUN_TYPE_LATER) {
                ctrl.campaign.end_date = null;
            } else if (ctrl.campaign.run_type === RUN_TYPE_CAMPAIGN) {}
            // Do nothing to the dates


            // Set template_if from alias
            if (ctrl.campaign.module === Gecko.Campaign.MODULE_EMAIL) {
                ctrl.campaign.template_id = ctrl.campaign.email_template_id;
            } else if (ctrl.campaign.module === Gecko.Campaign.MODULE_SMS) {
                ctrl.campaign.template_id = ctrl.campaign.sms_template_id;
            }

            ctrl.campaign.save().then(function (campaign) {
                ctrl.campaign = campaign;
                ctrl.campaign.run_type = ctrl.getRunType();

                GeckoUI.messenger.success('Your broadcast has been saved.');

                if ($stateParams.broadcast_id == 'new') {
                    unsavedAlert.clear();
                    $state.go('broadcast.filter', { broadcast_id: campaign.id, wizard: 1 });
                }

                unsavedAlert.clear();

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Broadcast Save Success', {
                    'broadcast id': campaign.id || 'New',
                    'broadcast title': campaign.title
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Broadcast Save Error', {
                    'broadcast id': ctrl.campaign.id || 'New',
                    'broadcast title': ctrl.campaign.title,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        $scope.$on('saveBroadcast', ctrl.saveBroadcast);
    }

    angular.module('GeckoEngage').controller('BroadcastSettingsCtrl', BroadcastSettingsCtrl);
})();