(function () {
    'use strict';

    function geckoCardProfileDirective() {

        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card-profile/gecko-card-profile.html',
            scope: {
                title: '@title',
                icon: '@icon',
                collapseFilter: '=',
                headerBtns: '=headerBtns',
                optionsBtn: '=optionsBtn',
                fields: '=',
                values: '=',
                entityId: '=',
                cardType: '@',

                options: '=',
                headerLabels: '=headerLabels',
                headerLabelsAction: '=headerLabelsAction',
                footerBtns: '=footerBtns'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            transclude: true,
            controller: function controller($attrs) {
                var ctrl = this;

                if (ctrl.optionsBtn && !angular.isArray(ctrl.optionsBtn)) {
                    ctrl.optionsBtn = [ctrl.optionsBtn];
                }

                // Shortcuts to set obj as value on all fields to trigger object save
                if (ctrl.options && ctrl.options.notValues) {
                    angular.forEach(ctrl.fields, function (field) {
                        field.obj = field.obj || ctrl.values;
                    });
                }

                // Shortcuts to set all values to noEdit
                if (ctrl.options && typeof ctrl.options.edit !== 'undefined' && !ctrl.options.edit) {
                    angular.forEach(ctrl.fields, function (field) {
                        field.noEdit = true;
                    });
                }

                // Collapse fields
                ctrl.collapsed = true;
                ctrl.collapse = function () {
                    return ctrl.collapsed = !ctrl.collapsed ? true : false;
                };

                ctrl.filter = function (field, index, array) {

                    if (ctrl.collapsed && ctrl.collapseFilter) {
                        return ctrl.collapseFilter(field, index, array);
                    }

                    return true;
                };

                // Sort the fields by is_calculated then by order param
                ctrl.fields.sort(function (a, b) {

                    var isCalculatedSort = 0;
                    if (a.is_calculated < b.is_calculated) {
                        isCalculatedSort = -10;
                    }
                    if (a.is_calculated > b.is_calculated) {
                        isCalculatedSort = 10;
                    }

                    return isCalculatedSort + Math.sign(a.order - b.order);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCardProfile', geckoCardProfileDirective);
})();