(function () {
    'use strict';

    function UsageTransactionsCtrl($state, $stateParams, $geckoModal, transactions) {
        var ctrl = this;
        ctrl.transactions = transactions.toArray();
        ctrl.pagination = transactions.pagination;

        // Date filter
        ctrl.dateFrom = $stateParams.from;
        ctrl.dateTo = $stateParams.to;

        // Table structure
        ctrl.cols = [{
            expandable: true,
            status_title_none: true,
            key: 'status',
            status_styles: Gecko.Transaction.status_titles
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Created at',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-4'
        }, {
            title: 'Type',
            key: 'type_title',
            colClass: 'col-xs-4'
        }, {
            data_type: Gecko.Field.DATA_TYPE_CURRENCY,
            title: 'Amount',
            key: 'value',
            sortKey: 'value',
            colClass: 'col-xs-4'
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View summary',
            hideWhen: function hideWhen(transaction) {
                return transaction.items && !transaction.items.length;
            },
            action: function action(transaction) {
                $geckoModal.transactionModal(transaction);
            }
        }, {
            title: 'Download PDF',
            action: function action(transaction) {
                window.open(transaction.download_url, '_blank');
            },
            hideWhen: function hideWhen(transaction) {
                return !transaction.download_url;
            }
        }];

        ctrl.processItems = function (items) {
            var _array = [];
            for (var prop in items) {
                items[prop].type = Number(prop);
                _array.push(items[prop]);
            }
            return _array;
        };

        angular.forEach(ctrl.transactions, function (transaction) {
            transaction.items = ctrl.processItems(transaction.items);
        });

        ctrl.filters = [{
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Transactions',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('UsageTransactionsCtrl', UsageTransactionsCtrl);
})();