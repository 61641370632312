(function () {
    'use strict';

    function FormsRoute($stateProvider) {

        var formsStateBase = {
            url: "/forms?{page}&{group}&{search}&{order}&{sort}",
            requires: [Gecko.ABILITY_FORMS_VIEW],
            templateUrl: "/modules/forms/views/index.263a39ee.html",
            controller: 'FormsListCtrl',
            resolve: {}
        };

        var formsStateResolveBase = {
            forms: function forms($stateParams, module) {
                var forms = new Gecko.Form().include('user', 'workflows').includeCount('responses').perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1);
                forms.where('module', module).where('user_trashed', 1);
                if ($stateParams.group) forms.where('group', $stateParams.group);
                if ($stateParams.published) {
                    // Archived forms
                    if ($stateParams.published === 'archived') {
                        forms.include('all_forms').where('form_trashed', 'only');
                    } else {
                        forms.where('published', $stateParams.published);
                    }
                }
                if ($stateParams.search) forms.search($stateParams.search);
                if ($stateParams.order) {
                    if ($stateParams.order === 'name') {
                        forms.orderBy({
                            internal_name: $stateParams.sort ? $stateParams.sort : 'asc',
                            name: $stateParams.sort ? $stateParams.sort : 'asc'
                        });
                    } else {
                        forms.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    }
                }
                return forms.rfields({ form: ['published', 'created_at', 'name', 'internal_name', 'uuid', 'module'], user: ['full_name', 'color'], workflow: ['schedule'] }).get();
            },
            groups: function groups() {
                return new Gecko.Form().groups();
            }
        };

        $stateProvider.state('forms', angular.extend({}, formsStateBase, {
            page_title: 'Forms',
            url: "/forms?{page}&{group}&{published}{search}&{order}&{sort}",
            resolve: angular.extend({}, formsStateResolveBase, {
                module: function module() {
                    return Gecko.Form.MODULE_FORM;
                }
            })
        })).state('scripts', angular.extend({}, formsStateBase, {
            page_title: 'Scripts',
            url: "/scripts?{page}&{group}&{published}&{search}&{order}&{sort}",
            requires: [Gecko.ABILITY_SCRIPTS_VIEW],
            resolve: angular.extend({}, formsStateResolveBase, {
                module: function module() {
                    return Gecko.Form.MODULE_CALL;
                }
            })
        }));
    }

    angular.module('GeckoEngage.routes').config(FormsRoute);
})();