(function () {
    'use strict';

    function geckoPastValues() {

        return {
            restrict: 'E',
            templateUrl: '/components/gecko-field/gecko-past-values.html',

            require: '?^geckoFieldInline',

            controllerAs: 'ctrl',
            bindToController: true,

            link: function link(scope, iElement, iAttrs, ctrl) {

                if (!ctrl || ctrl.field.is_uneditable) return;

                ctrl.showValues = false;
                ctrl.showPastValues = function (event) {

                    if (!ctrl.pastValues) {
                        new Gecko.Value().where('past_values', ctrl.value.id).get().then(function (values) {
                            ctrl.pastValues = values.toArray();
                        });
                    }

                    ctrl.showValues = true;

                    if (ctrl.showValues) {
                        ctrl.showPopover(false);
                    }
                };
            }

        };
    }

    angular.module('GeckoEngage').directive('geckoPastValues', geckoPastValues);
})();