(function () {
    'use strict';

    function SettingsCategoriesCtrl($state, categories) {
        var ctrl = this;
        ctrl.categories = categories.toArray();
        ctrl.pagination = categories.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Title',
            key: 'title'
        }, {
            title: 'Type',
            key: 'type'
        }, {
            title: 'Created by',
            key: 'user.full_name'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'settingscategory',
            params: { category_id: 'id' }
        };

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { label: object[labelKey], value: object.id };
                });
            };
        };
        ctrl.filters = [{
            title: 'Type',
            type: 'dropdown',
            options: objectsToOptions(Gecko.Category.type_titles)('title'),
            //optionsKey      : 'id',
            //optionsLabelKey : 'title',
            stateParam: 'type'
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Categories',
            active: true
        }];
    }

    function SettingsCategoryCtrl($state, $stateParams, Gecko, category, asyncOptions) {
        var ctrl = this;
        ctrl.category = category;
        ctrl.category.events = ctrl.category.related_events ? ctrl.category.related_events.map(function (e) {
            return e.toObject();
        }) : [];

        // //Field list
        ctrl.fields = [{
            id: 'title',
            label: 'Title',
            colClass: 'col-xs-12',
            description: 'The title will be used to refer to this Category throughout Gecko.',
            required: true
        }, {
            id: 'type',
            label: 'Type',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_SELECT,
            optionsLabelKey: 'title',
            options: Gecko.Category.type_titles,
            noBlank: true,
            required: true,
            description: 'Choose which type of data you want to contain within this Category.'
        }, {
            id: 'events',
            label: 'Events',
            type: Gecko.Field.TYPE_MULTI,
            options: ctrl.category.related_events,
            getOptions: asyncOptions.create(new Gecko.Event().where('type', [Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_EVENT_LEGACY]).rfields({ event: ['title'] })),
            optionsKey: 'id',
            optionsLabelKey: 'title',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return ctrl.category.type !== Gecko.Category.TYPE_EVENT;
            }
        }];

        //console.log(Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_LEGACY_EVENT);

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {

                //if (!ctrl.category.events || !ctrl.category.events.length) delete ctrl.category.events;

                ctrl.category.save().then(function (data) {
                    GeckoUI.messenger.success('Category has been saved');
                    if ($stateParams.category_id == 'new') {
                        $state.go('settingscategory', {
                            category_id: data.id
                        });
                    }
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }, {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.category_id == 'new';
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this category?', function (value) {
                    if (value) {
                        ctrl.category.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Category has been deleted');
                            $state.go('settingscategories');
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Categories',
            click: function click() {
                $state.go('settingscategories');
            }
        }, {
            label: ctrl.category.title ? ctrl.category.title : 'Add Category',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('SettingsCategoriesCtrl', SettingsCategoriesCtrl).controller('SettingsCategoryCtrl', SettingsCategoryCtrl);
})();