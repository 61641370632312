(function () {
    'use strict';

    function geckoCallNoteCard($state, $geckoCall) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-note-card.html',

            require: '^geckoCallPane',

            link: function link(scope, iElement, iAttrs, ctrl) {

                ctrl.note = '';
                // Save note
                var saveNote = function saveNote() {
                    if (!ctrl.note) return GeckoUI.messenger.error('The note field is required');
                    // Save the note
                    $geckoCall.note(ctrl.note).then(function () {
                        // Success message
                        GeckoUI.messenger.success('Note successfully added');
                        // Hide note card
                        ctrl.toggleNote();
                        // Reset note value
                        ctrl.note = '';
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                    });
                };

                // Card fields
                ctrl.noteCardFields = [{
                    label: 'Note',
                    required: true,
                    key: 'note',
                    type: Gecko.Field.TYPE_TEXTAREA,
                    colClass: 'none',
                    hideWhen: function hideWhen() {
                        return !ctrl.addingNote;
                    }
                }];

                // Card buttons
                ctrl.noteCardBtns = [{
                    position: 'primary',
                    title: 'Save Note',
                    preset: 'save',
                    action: saveNote
                }, {
                    position: 'secondary',
                    title: 'Discard Note',
                    preset: 'close',
                    action: ctrl.toggleNote
                }];
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallNoteCard', geckoCallNoteCard);
})();