(function () {
    'use strict';

    function TemplateStatsCtrl($scope, $state, $stateParams, dashboard) {
        var ctrl = this;
        ctrl.refreshedAt = dashboard.refreshed_at;
        ctrl.widgets = dashboard.related_widgets;

        ctrl.date_from = $stateParams.date_from || null;
        ctrl.date_to = $stateParams.date_to || null;

        ctrl.setCustomRange = function () {
            $state.go($state.$current.name, { date_from: ctrl.date_from, date_to: ctrl.date_to }, { reload: true });
        };

        ctrl.clearCustomRange = function () {
            if ($stateParams.date_from || $stateParams.date_to) {
                $state.go($state.$current.name, { date_from: null, date_to: null }, { reload: true });
            }
        };
    }

    angular.module('GeckoEngage').controller('TemplateStatsCtrl', TemplateStatsCtrl);
})();