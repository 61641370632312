(function () {
    'use strict';

    function CallsCtrl($scope, $timeout, $state, $filter, $injector, $stateParams, $geckoModal, $geckoSocket, Gecko, defaultStatuses, fieldList, calls, callIncludes, user, geckoStore) {

        // Ended state map for testing if in final state

        var endedStates = {};
        endedStates[Gecko.Call.COMPLETE] = true;
        endedStates[Gecko.Call.CANCELLED] = true;
        endedStates[Gecko.Call.ABORTED] = true;

        // Map for testing if the campaign id in an event is in the current filter. false if no filter.

        var filterCampaigns = filterCampaigns === undefined ? false : GeckoUI.arrayToObjectKeys(GeckoUI.wrapArray($stateParams.campaign));

        // Initialse main data

        var ctrl = this;
        ctrl.fieldList = fieldList;
        ctrl.calls = calls.toInstances();
        ctrl.pagination = calls.pagination;
        ctrl.pagination.hideLimit = true;
        ctrl.state = $state.current.name;

        // Initialise filtering

        ctrl.filteredStatues = GeckoUI.wrapArray($stateParams.status);
        if (!ctrl.filteredStatues.length) ctrl.filteredStatues = defaultStatuses;

        ctrl.hasFilter = $stateParams.user || $stateParams.campaign || $stateParams.outcome || $stateParams.from || $stateParams.to;

        ctrl.clearFilter = function () {
            $state.go($state.current.name, { to: null, from: null, user: null, campaign: null, outcome: null, page: null });
        };

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { label: object[labelKey], value: object.id };
                });
            };
        };
        ctrl.filters = [{
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            }
        }, {
            title: 'Status',
            type: 'checkbox',
            options: objectsToOptions(Gecko.Call.statuses)('title'),
            stateParam: 'status'
        }, {
            title: 'Types',
            type: 'checkbox',
            options: objectsToOptions(Gecko.Call.type_titles)('title'),
            stateParam: 'type'
        }, {
            title: 'Users',
            type: 'dropdown',
            optionsQuery: new Gecko.User().rfields({ user: ['full_name'] }).orderBy('full_name'),
            optionsKey: 'id',
            optionsLabelKey: 'full_name',
            stateParam: 'user'
        }, {
            title: 'Campaigns',
            type: 'multi',
            optionsQuery: new Gecko.Campaign().rfields({ campaign: ['title'] }).where('module', Gecko.Campaign.MODULE_CALL).orderBy('title').perPage('all'),
            optionsKey: 'id',
            optionsLabelKey: 'title',
            stateParam: 'campaign'
        }, {
            title: 'Outcomes',
            type: 'checkbox',
            optionsQuery: new Gecko.Outcome().rfields({ outcome: ['name'] }).orderBy('name'),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            stateParam: 'outcome'
        }];

        // Check if any recordings available
        var hasRecordings = function hasRecordings() {
            var _has = false;
            angular.forEach(ctrl.calls, function (call) {
                if (call.recording_url_mp3) _has = true;
            });
            return _has;
        };

        // Auto updating functionality

        ctrl.toggleAutoUpdate = function () {
            ctrl.autoUpdate = !ctrl.autoUpdate;
            if (ctrl.autoUpdate) {
                $state.go($state.current.name, { autoUpdate: 1, page: null, to: null, from: null, outcome: null });
            } else {
                $state.go($state.current.name, { autoUpdate: null });
            }
        };

        ctrl.autoUpdate = !!$stateParams.autoUpdate;

        // Add auto update functionality
        if (ctrl.autoUpdate) {
            $geckoSocket.watch('call', ctrl.calls, ['status', 'started_at', 'queued_at']);
            // Reload the page for new calls
            $geckoSocket.registerEvent('call:new', function () {
                $state.go($state.$current.name, $stateParams, { reload: true });
            });
        }

        $geckoSocket.registerEvent('voip:call', function () {
            var calls = new Gecko.Call().include(['contact', 'user', 'outcome', 'campaign', 'number', 'all_campaigns']).perPage(GeckoUI.getPageLimit()).page($stateParams.page ? $stateParams.page : 1);

            // Status filter
            if ($stateParams.status) {
                calls.where('status', $stateParams.status);
            } else {
                calls.where('status', [Gecko.Call.PREPARING, Gecko.Call.CANCELLED, Gecko.Call.STARTED, Gecko.Call.ENDED, Gecko.Call.COMPLETE, Gecko.Call.ABORTED, Gecko.Call.QUEUED, Gecko.Call.ABANDONED]);
            }
            // User filter
            if ($stateParams.user) {
                calls.where('user_id', $stateParams.user);
            }
            // Campign filter
            if ($stateParams.campaign) {
                calls.where('campaign_id', $stateParams.campaign);
            }
            // Outcome filter
            if ($stateParams.outcome) {
                calls.where('outcome_id', $stateParams.outcome);
            }
            // Only show outbound if inbound call package is disabled
            if (!Gecko.User.hasInbound()) {
                calls.where('type', Gecko.Call.TYPE_OUTBOUND);
            } else {
                if ($stateParams.type) {
                    calls.where('type', $stateParams.type);
                }
            }
            // Date filter
            calls.dates($stateParams.from, $stateParams.to);

            return calls.get().then(function (calls) {
                ctrl.calls = calls.toInstances();
            });
        }, false);

        // Breadcrumbs
        // Overview pages setup
        if ($stateParams.overview) {

            // User overview
            if (user) {
                ctrl.user = user;
                ctrl.title = 'Calls by ' + ctrl.user.full_name;

                // Breadcrumbs
                ctrl.breadcrumbs = [{
                    label: 'Users',
                    click: function click() {
                        $state.go('users');
                    }
                }, {
                    label: ctrl.user.full_name ? ctrl.user.full_name : 'User',
                    click: function click() {
                        $state.go('user', { user_id: ctrl.user.id });
                    }
                }, {
                    label: 'Calls',
                    active: true
                }];
            }

            // TODO: Campaign overview
        } else {
            ctrl.breadcrumbs = [{
                label: 'Calls',
                click: function click() {
                    return $state.go('calls', { module: ctrl.module }, { reload: true });
                }
            }, {
                label: 'List',
                active: true
            }];
        }

        // Fallback title
        if (!ctrl.title) {
            ctrl.title = 'Calls';
        }

        ctrl.cols = [{
            key: 'type',
            colClass: 'text-center',
            headerIcon: 'fa-exchange',
            data_type: 'image',
            renderSrc: function renderSrc(call) {
                if (call.type === Gecko.Call.TYPE_INBOUND) {
                    return '/images/icons/call-inbound.054133b8.svg';
                }
                if (call.type === Gecko.Call.TYPE_OUTBOUND) {
                    return '/images/icons/call-outbound.f5443e4a.svg';
                }
            },
            renderTitle: function renderTitle(x, call) {
                if (call.type === Gecko.Call.TYPE_INBOUND) {
                    return 'Inbound';
                }
                if (call.type === Gecko.Call.TYPE_OUTBOUND) {
                    return 'Outbound';
                }
            }
        }, {
            title: 'Status',
            key: 'status',
            status_styles: Gecko.Call.statuses
        }, {
            title: 'Queued at',
            key: 'queued_at',
            render: function render(value) {
                if (!value) return '-';
                return $filter('formatDate')(value);
            },
            hideWhen: function hideWhen(col) {
                return ctrl.colHideWhen(col.key) || !Gecko.User.hasInbound();
            }
        }, {
            // Data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Started at',
            key: 'started_at',
            render: function render(value) {
                if (!value) return '-';
                return $filter('formatDate')(value);
            },
            hideWhen: function hideWhen(col) {
                return ctrl.colHideWhen(col.key);
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            humanize: true,
            title: 'Duration',
            key: 'call_time',
            hideWhen: function hideWhen(col) {
                return ctrl.colHideWhen(col.key);
            }
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'User',
            key: 'related_user.full_name',
            render: function render(value, col, call) {
                if (call.related_user) {
                    return value;
                }
                // If inbound, show sender name
                if (call.type === Gecko.Call.TYPE_INBOUND && call.related_sender && call.related_sender.name) {
                    return 'System (' + call.related_sender.name + ')';
                }

                return 'System';
            },
            hideWhen: function hideWhen(col) {
                return ctrl.colHideWhen(col.key);
            },
            hideAvatar: function hideAvatar() {
                return true;
            }
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Contact Name',
            key: 'related_contact.full_name',
            hideWhen: function hideWhen(col) {
                return ctrl.colHideWhen(col.key);
            },
            action: function action(call) {
                if (call.related_contact) return $state.go('contact.overview', { contact_id: call.related_contact.id });
            },
            render: function render(value, col, call) {
                if (call.related_contact) {
                    return value;
                } else if (call.related_number) {
                    if (call.related_number.type == Gecko.Number.TYPE_ANONYMOUS) return 'Unknown Number';
                    return call.related_number.cleaned || call.related_number.original;
                }
                return 'Unknown';
            },
            hideAvatar: function hideAvatar() {
                return true;
            },
            hideAction: function hideAction(col, call) {
                return !call.related_contact;
            }
        }, {
            title: 'Campaign',
            key: 'related_campaign.title',
            hideWhen: function hideWhen(col) {
                return ctrl.colHideWhen(col.key);
            },
            action: function action(row) {
                if (!row.related_campaign) return false;
                return $state.go('campaignsedit.view', { module: Gecko.Campaign.MODULE_CALL, campaign_id: row.related_campaign.id });
            }
        }, {
            title: 'Outcome',
            key: 'related_outcome.name',
            status_key: 'related_outcome.type',
            status_styles: [{
                id: 0,
                label: 'label-danger'
            }, {
                id: 1,
                label: 'label-success'
            }],
            hideStatusWhen: function hideStatusWhen(col, call) {
                return !call.related_outcome || !call.related_outcome.id;
            },
            hideWhen: function hideWhen(col) {
                return ctrl.colHideWhen(col.key);
            }
        }];

        // Add contact list view fields
        angular.forEach(ctrl.fieldList, function (field, i) {
            ctrl.cols.push({
                title: field.label,
                key: 'related_contact.field_' + (i + 1),
                data_type: field.data_type,
                hideWhen: function hideWhen() {
                    return ctrl.colHideWhen('related_contact.field_' + (i + 1));
                }
            });
        });

        // Ctrl.cols.push({
        //     Title    : 'Recordings',
        //     Key      : 'recording_url_mp3',
        //     ColClass : 'text-center',
        //     Type     : Gecko.Field.TYPE_AUDIO,
        //     HideWhen : function() {
        //         Return ctrl.colHideWhen('recording_url_mp3') || !Gecko.User.isGroup([Gecko.Group.ADMIN, Gecko.Group.SUPER]) || !hasRecordings();
        //     },
        // })

        // Build default cols display array
        var colsConfigKey = 'gecko_calls_list_config_' + Gecko.account.routing_id;
        if (geckoStore.get(colsConfigKey)) {
            ctrl.colsConfigFilter = geckoStore.get(colsConfigKey);
        } else {
            ctrl.colsConfigFilter = GeckoUI.getIdArray(ctrl.cols, 'key');
            ctrl.colsConfigFilter.splice(ctrl.colsConfigFilter.indexOf('related_contact.field_1'), 4);
        }

        // Table col config
        ctrl.colsConfig = (GeckoUI.mapObjectArray(ctrl.cols, { id: 'key', title: 'title' }) || []).filter(function (c) {
            return c.title;
        });

        $scope.$watch('ctrl.colsConfigFilter', function (newVal, oldVal) {
            if (newVal != oldVal) {
                // Update saved config
                geckoStore.set(colsConfigKey, ctrl.colsConfigFilter);
            }
        }, true);

        ctrl.colHideWhen = function (key) {
            return ctrl.colsConfigFilter.indexOf(key) == -1;
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Edit call',
            action: function action(call, index) {
                // Check the users permissions
                // Update permissions
                if (!Gecko.able(Gecko.ABILITY_CALLS_UPDATE)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_CALLS_UPDATE));
                }
                $geckoModal.CallOutcomeModal(call).result.then(function (call) {
                    ctrl.calls[index] = call;
                });
            }
        }, {
            title: 'Add Contact',
            hideWhen: function hideWhen(call) {
                return call.related_contact;
            },
            action: function action(call, index) {
                $geckoModal.contactFind(1).result.then(function (contact_id) {
                    if (contact_id) {
                        // Set contact id
                        call.contact_id = contact_id;
                        // Save call
                        call.include(callIncludes).save().then(function (call) {
                            ctrl.calls[index] = call;
                            // Call update message
                            GeckoUI.messenger.success('Call updated');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }, {
            title: 'Possible Contacts',
            action: function action(call) {
                if (call.number_id) return $state.go('contacts', { number: call.number_id });
            },
            hideWhen: function hideWhen(call) {
                return call.contact_id;
            }
        }];

        ctrl.rowAction = {
            action: function action(call) {
                $geckoModal.callInfoModal(call);
            }
        };
    }

    angular.module('GeckoEngage').controller('CallsCtrl', CallsCtrl);
})();