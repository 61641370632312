(function () {
    'use strict';

    function filePicker() {
        return {
            restrict: 'EA',
            template: '\n                <div>\n                    <gecko-btn\n                        id="field_{{ctrl.field.id}}_upload"\n                        preset="upload"\n                        ng-click="ctrl.fileUpload()">\n                    </gecko-btn>\n                    <span ng-if="ctrl.value">\n                        &nbsp;\n                        <a id="field_{{ctrl.field.id}}_view" ng-click="ctrl.openFilesModal($event); $event.preventDefault()" ng-href="{{ctrl.field.format !== \'blob\' ? ctrl.value : ctrl.value.url}}">\n                            View file\n                        </a>\n                        &nbsp;\n                        |\n                        &nbsp;\n                        <a id="field_{{ctrl.field.id}}_remove" ng-click="ctrl.fileRemove()">\n                            Remove file\n                        </a>\n                    </span>\n                    <file-picker-alert file-url="ctrl.value" inline cache></file-picker-alert>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                field: '=',
                value: '=',
                setDirty: '&'
            },
            controller: filePickerCtrl
        };
    }

    function filePickerCtrl($geckoModal, fileAvService, fileAvCacheService) {
        var ctrl = this;

        ctrl.fileUpload = function () {
            var initialValue = ctrl.value;

            return $geckoModal.filePicker({ mimetype: ctrl.field.mimetype }).result.then(function (result) {
                ctrl.value = result.files.length === 1 ? result.files[0] : result.files;

                // Clear previous files cached avStatus
                if (ctrl.value !== initialValue) {
                    fileAvService.removeCachedStatus(initialValue);
                }

                // Dont allow user to navigate without save
                if (ctrl.setDirty && ctrl.value) ctrl.setDirty();
            });
        };

        // Remove file
        ctrl.fileRemove = function () {
            var initialValue = ctrl.value;
            // Clear previous files cached avStatus
            fileAvService.removeCachedStatus(initialValue);

            ctrl.value = null;
            // Dont allow user to navigate without save
            if (ctrl.setDirty) ctrl.setDirty();
        };

        ctrl.openFilesModal = function (event) {
            event.stopPropagation();
            event.preventDefault();

            $geckoModal.fileView([ctrl.value]);
        };
    }

    angular.module('GeckoEngage').controller('filePickerCtrl', filePickerCtrl).directive('filePicker', filePicker);
})();