
(function () {
    'use strict';

    function geckoMapField() {
        return {
            restrict: 'E',
            template: '\n            \t<input \n                    id="pac-input" \n                    class="gecko-map-field-search" \n                    placeholder="Lookup Location"\n                    onfocus="" />\n                <button \n                    class="gecko-field-map-btn btn btn-default"\n                    ng-if="!ctrl.valueIsValid() && !ctrl.accountAddressIsArray()" \n                    ng-click="ctrl.getAccountAddress($event)"\n                    tooltip="Use account address">\n                        <i class="fa fa-home"></i>\n                </button>\n                <button \n                    class="gecko-field-map-btn btn btn-default"\n                    ng-if="ctrl.valueIsValid()" \n                    ng-click="ctrl.reset($event)">\n                        <i class="fa fa-times"></i>\n                        Reset\n                </button>\n\n            \t<div class="gecko-map-field"></div>\n            ',
            scope: {
                field: '=',
                value: '='
                //displayMode: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($element) {
                var ctrl = this;

                ctrl.setValue = function (lat, lng, place) {
                    ctrl.value = ctrl.buildValue(lat, lng, place);
                };
                ctrl.buildValue = function (lat, lng, place) {
                    return {
                        latitude: lat || null,
                        longitude: lng || null,
                        place: ctrl.cleanPlace(place || {}),
                        place_id: (place || {}).place_id || null
                    };
                };

                ctrl.cleanPlace = function (place) {
                    return {
                        formatted_address: place.formatted_address,
                        plus_code: place.plus_code,
                        place_id: place.place_id,
                        types: place.types
                    };
                };

                ctrl.valueIsValid = function () {
                    return ctrl.value && (ctrl.value || {}).latitude && (ctrl.value || {}).longitude;
                };

                ctrl.accountAddressIsArray = function () {
                    return Gecko.account.address instanceof Array;
                };

                ctrl.reset = function (event) {
                    // Weird thing where pressing enter is clicking this button?
                    if (event.clientY === 0 && event.clientX === 0) return;

                    // Remove marker and value
                    setMarker();
                    // Reset map
                    ctrl.map.setOptions(getMapOptions());
                    // Center map on lat/lng
                    ctrl.map.panTo(ctrl.getDefaultPosition());
                    var input = $element.find('.gecko-map-field-search')[0];
                    input.value = '';
                };

                ctrl.getDefaultPosition = function () {
                    var lat = (Gecko.geo || {}).latitude;
                    var lng = (Gecko.geo || {}).longitude;
                    return new google.maps.LatLng(lat, lng);
                };

                var getMapOptions = function getMapOptions() {
                    var options = {
                        zoom: ctrl.valueIsValid() ? 18 : 9,
                        draggableCursor: 'crosshair',
                        draggable: true,
                        zoomControl: true,
                        scrollwheel: true,
                        //disableDoubleClickZoom : !doubleClickZoom,
                        disableDefaultUI: true,
                        clickableIcons: false
                    };
                    return options;
                };

                ctrl.getAccountAddress = function (event) {
                    // Weird thing where pressing enter is clicking this button?
                    if (event.clientY === 0 && event.clientX === 0) return;

                    var a = Gecko.account.address;

                    // builds address string
                    var addressString = Gecko.account.name + ', ' + a.street_address + ', ' + a.city + ', ' + a.state + ', ' + a.postal_code;

                    var input = document.getElementById('pac-input');
                    // fills input with address
                    input.value = addressString;
                    // opens address dropdown
                    input.focus();
                    input.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 13 }));
                };

                var init = function init() {
                    var mapElement = $element.find('.gecko-map-field')[0];
                    var position = ctrl.valueIsValid() ? new google.maps.LatLng(ctrl.value.latitude, ctrl.value.longitude) : ctrl.getDefaultPosition();

                    ctrl.map = new google.maps.Map(mapElement, {
                        center: position
                    });

                    ctrl.map.setOptions(getMapOptions());

                    // Init Search
                    searchInit();

                    // Add marker on map click 
                    google.maps.event.addListener(ctrl.map, 'click', function (location) {
                        setMarker(location.latLng.lat(), location.latLng.lng());
                    });

                    if (ctrl.valueIsValid()) {
                        setMarker(ctrl.value.latitude, ctrl.value.longitude);
                    }
                };

                var searchInit = function searchInit() {
                    // Create the search box and link it to the UI element.
                    var input = $element.find('.gecko-map-field-search')[0];;
                    var searchBox = new google.maps.places.SearchBox(input);
                    ctrl.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

                    // Bias the SearchBox results towards current map's viewport.
                    ctrl.map.addListener('bounds_changed', function () {
                        searchBox.setBounds(ctrl.map.getBounds());
                    });

                    var markers = [];
                    // Listen for the event fired when the user selects a prediction and retrieve
                    // more details for that place.
                    searchBox.addListener('places_changed', function () {
                        var places = searchBox.getPlaces();

                        if (!places.length) return;

                        // Clear out the old markers.
                        markers.forEach(function (marker) {
                            marker.setMap(null);
                        });
                        markers = [];

                        // For each place, get the icon, name and location.
                        var bounds = new google.maps.LatLngBounds();

                        if (places.length === 1) {
                            if (places[0].geometry.viewport) {
                                // Only geocodes have viewport.
                                bounds.union(places[0].geometry.viewport);
                            } else {
                                bounds.extend(places[0].geometry.location);
                            }

                            // Set marker on map
                            setMarker(places[0].geometry.location.lat(), places[0].geometry.location.lng(), places[0]);
                        } else {
                            places.forEach(function (place) {
                                if (!place.geometry) {
                                    console.log("Returned place contains no geometry");
                                    return;
                                }
                                var icon = {
                                    url: place.icon,
                                    size: new google.maps.Size(71, 71),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(17, 34),
                                    scaledSize: new google.maps.Size(25, 25)
                                };

                                // Create a marker for each place.
                                markers.push(new google.maps.Marker({
                                    map: ctrl.map,
                                    icon: icon,
                                    title: place.name,
                                    position: place.geometry.location
                                }));

                                if (place.geometry.viewport) {
                                    // Only geocodes have viewport.
                                    bounds.union(place.geometry.viewport);
                                } else {
                                    bounds.extend(place.geometry.location);
                                }
                            });
                        }

                        ctrl.map.fitBounds(bounds);
                    });
                };

                var markers = [];
                var setMarker = function setMarker(lat, lng, place) {
                    // Reset marker ref(s)
                    markers.forEach(function (m) {
                        return m.setMap(null);
                    });

                    if (lat && lng) {
                        var positionObj = new google.maps.LatLng(lat, lng);

                        // Set marker on map
                        var marker = new google.maps.Marker({
                            position: positionObj,
                            map: ctrl.map
                        });

                        // Store marker ref
                        markers.push(marker);

                        // Center map on lat/lng
                        ctrl.map.panTo(positionObj);

                        // Set value
                        if (place) {
                            ctrl.setValue(lat, lng, place);
                        } else {
                            // Get placeId and set value
                            new google.maps.Geocoder().geocode({ 'location': positionObj }, function (places) {
                                if ((places || []).length) {
                                    ctrl.setValue(lat, lng, places[0]);
                                } else {
                                    ctrl.setValue(lat, lng, null);
                                }
                            });
                        }
                    } else {
                        ctrl.setValue();
                    }
                };

                // Load Google Maps API and init map
                if (!(window.google || {}).maps) {
                    GeckoUI.loadExternalScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyAS9QXHFdfVsUrKQ-PSKPqn7GIe2PKGV3o&libraries=places', init);
                } else {
                    init();
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoMapField', geckoMapField);
})();