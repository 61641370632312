var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
    'use strict';

    function geckoTableFilterService($filter) {
        var _this = {

            prepareSyncQuery: function prepareSyncQuery(query, status) {
                if (status) {
                    if (status.indexOf('has_synced_') !== -1) {
                        query.where('has_synced', Number(status.replace('has_synced_', '')));
                    }
                    if (status.indexOf('has_sync_error_') !== -1) {
                        query.where('has_sync_error', Number(status.replace('has_sync_error_', '')));
                    }
                    if (status.indexOf('has_not_synced_') !== -1) {
                        query.where('has_not_synced', Number(status.replace('has_not_synced_', '')));
                    }
                }
                // Preserve chaining
                return query;
            },

            prepareSyncOptions: function prepareSyncOptions(integrations, filter) {

                var filter = angular.extend({}, {
                    title: 'Sync Status',
                    type: 'radio',
                    options: [],
                    stateParam: 'sync_status'
                }, filter || {});

                // Build out options for different integrations
                if (integrations && integrations.length) {
                    angular.forEach(integrations, function (integration) {
                        var name = $filter('capitalize')(integration.title || integration.type);

                        if (name === 'Engage-dynamics') {
                            name = 'Dynamics';
                        }

                        filter.options.push({
                            label: name + ' synced',
                            value: 'has_synced_' + integration.id
                        });
                        filter.options.push({
                            label: name + ' has sync error',
                            value: 'has_sync_error_' + integration.id
                        });
                        filter.options.push({
                            label: name + ' not synced',
                            value: 'has_not_synced_' + integration.id
                        });
                    });
                }

                return filter;
            }

        };
        return _this;
    }

    var getStateName = function getStateName(state, stateParams) {
        var stateName = state.name;
        if (stateName === 'campaignsedit.view') {
            stateName = stateName + '-' + stateParams.module;
        }
        if (stateName === 'legacy-event.overview') {
            stateName = stateName + '-' + stateParams.event_id;
        }
        if (stateName === 'event.overview') {
            stateName = stateName + '-' + stateParams.event_id;
        }
        if (stateName === 'session.overview') {
            stateName = stateName + '-' + stateParams.session_id;
        }
        return stateName;
    };

    function geckoTableFilter($parse, $state, $stateParams, geckoStore) {

        return {
            restrict: 'A',

            controller: function controller() {},
            controllerAs: 'tableFilter',

            link: function link(scope, iElement, iAttrs, tableFilter) {

                iElement.addClass('table-filter');

                tableFilter.filters = function () {

                    var filters = $parse(iAttrs.geckoTableFilter);

                    return function (locals) {
                        return filters(scope, locals);
                    };
                }();

                var filters = tableFilter.filters();
                if (filters && filters.length) {
                    tableFilter.isLastDropdown = filters[filters.length - 1].type === 'dropdown' || filters[filters.length - 1].type === 'async-dropdown';
                } else {
                    tableFilter.isLastDropdown = false;
                }

                if (!(tableFilter.filters() && tableFilter.filters().length)) {
                    iElement.addClass('none');
                    return;
                }

                tableFilter.activeFilters = 0;

                tableFilter.values = {};

                var isEmptyValue = function isEmptyValue(val) {
                    if (typeof val === 'number') {
                        return !(val || val === 0);
                    } // If it's a number allow 0 but not NaN
                    if (angular.isArray(val)) {
                        return !(val.length !== 0);
                    } // If it's an array make sure it has elements'
                    if ((typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object' && val !== null) {
                        return !(Object.keys(val).length !== 0);
                    } // If it's an object make sure it has keys
                    return !val; // If none of the above try a simple truthy/falsey return
                };
                var isFilterMapFilled = function isFilterMapFilled(filter) {
                    return Object.keys(filter.filterMap).reduce(function (filled, param) {
                        if (isEmptyValue(tableFilter.values[filter.filterMap[param]])) {
                            filled = false;
                        }
                        return filled;
                    }, true);
                };
                tableFilter.filledFilters = function () {
                    return tableFilter.filters().reduce(function (sum, filter) {
                        var filled = false;
                        if (filter.filterMap) {
                            filled = isFilterMapFilled(filter);
                        }
                        if (filter.stateParam) {
                            filled = !isEmptyValue(tableFilter.values[filter.stateParam]);
                        }

                        if (filled) {
                            sum += 1;
                        }
                        return sum;
                    }, 0);
                };

                scope.$watch(function () {
                    return tableFilter.expanded;
                }, function (newVal) {
                    if (newVal === true) {
                        iElement.addClass('expanded', newVal);
                    } else {
                        iElement.removeClass('expanded', newVal);
                    }
                });

                tableFilter.reset = function () {
                    Object.keys(tableFilter.values).forEach(function (key) {
                        tableFilter.values[key] = undefined;
                    });
                    tableFilter.apply();
                };
                tableFilter.apply = function () {
                    var stateName = getStateName($state.current, $stateParams);
                    geckoStore.set('params-' + stateName, tableFilter.values);
                    var params = angular.extend({}, { page: null }, tableFilter.values);
                    $state.go($state.current.name, params);
                };
            }

        };
    }

    function geckoTableFilterContent($state, $stateParams, $timeout, geckoStore) {

        return {
            restrict: 'E',

            templateUrl: '/components/gecko-table-filter/gecko-table-filter-content.html',

            require: '^geckoTableFilter',
            link: function link(scope, iElement, iAttrs, ctrl) {
                scope.GeckoUI = GeckoUI;
                iElement.addClass('table-filter-content');

                var stateName = getStateName($state.current, $stateParams);
                // Put this in a timeout so it runs after render to stop a UI bug
                $timeout(function () {
                    ctrl.expanded = geckoStore.get('expanded-filter-' + stateName);
                    if (ctrl.expanded === undefined || ctrl.expanded === null) {
                        ctrl.expanded = false;
                    } // Unset values should default to not expanded
                });
                ctrl.toggleFilters = function () {
                    ctrl.expanded = !ctrl.expanded;
                    geckoStore.set('expanded-filter-' + stateName, ctrl.expanded);
                };

                //Build selected filter total
                $timeout(function () {
                    var filters = angular.copy(scope.tableFilter.filters());
                    // Initial total is 0
                    scope.tableFilter.activeFilters = 0;
                    // Add filter totals to overall total
                    angular.forEach(filters, function (filter) {
                        if (filter.selectedCount > 0) {
                            scope.tableFilter.activeFilters = scope.tableFilter.activeFilters + filter.selectedCount;
                        }
                    });

                    // Section classes
                    scope.sectionClasses = (filters || []).map(function (section, i) {
                        return GeckoUI.prepareIndexClass('gecko-table-filter-section', i) + ' ' + GeckoUI.prepareNameClass('gecko-table-filter-section', section.title);
                    });
                });
            }

        };
    }

    var templates = {
        'checkbox': 'checkbox-filter.html',
        'dropdown': 'dropdown-filter.html',
        'gecko-field': 'gecko-field.html',
        'multi': 'multi-filter.html',
        'radio': 'radio-filter.html',
        'quicksearch': 'quicksearch-filter.html',
        'daterange': 'daterange-filter.html',
        'operator-checkbox': 'operator-checkbox-filter.html'
    };

    var extras = {
        'operator': { file: 'operator-extra.html', default: 'any' }
    };

    function geckoTableFilterWidget($stateParams, $templateRequest, $compile, munge, asyncOptions, OptionsPresets, $q) {
        return {
            restrict: 'A',

            controller: function tableFilterWidgetController() {},
            controllerAs: 'ctrl',
            scope: true, // Should be using the same scope as the ng-repeat but define this just to make sure.

            require: ['^geckoTableFilter', 'geckoTableFilterWidget'],
            link: function link(scope, iElement, iAttrs, ctrls) {
                var tableFilter = ctrls[0];
                var ctrl = ctrls[1];
                var changeTemplate;

                ctrl.inputName = function (option) {

                    if (scope.section.type === 'radio') {
                        return scope.section.title;
                    }
                    return scope.section.title + '-' + option.value;
                };

                ctrl.inputId = function (option) {
                    if (option) return (scope.section.stateParam || JSON.stringify(scope.section.filterMap)) + '-' + option.value;
                    return scope.section.stateParam || JSON.stringify(scope.section.filterMap);
                };

                ctrl.inputClass = function () {
                    if (scope.section.type === 'checkbox') return 'check';

                    return scope.section.type;
                };

                var convertToID = function convertToID(v) {
                    return parseInt(v, 10) ? parseInt(v, 10) : v;
                };

                // When we define a filter map we have multiple
                if (scope.section.filterMap) {

                    angular.forEach(scope.section.filterMap, function (val, key) {
                        Object.defineProperty(ctrl, key, {
                            get: function get() {
                                return tableFilter.values[val];
                            },
                            set: function set(value) {
                                tableFilter.values[val] = value;
                            }
                        });

                        ctrl[key] = $stateParams[val];
                    });

                    Object.defineProperty(ctrl, 'value', {
                        get: function get() {
                            if (scope.section.stateParam) return tableFilter.values[scope.section.stateParam];
                            if (scope.section.filterMap.default) return tableFilter.values[scope.section.filterMap.default];
                        },
                        set: function set(value) {
                            if (value) {
                                scope.section.selectedCount = Array.isArray(value) ? value.length : 1;
                            } else {
                                scope.section.selectedCount = 0;
                            }
                            if (scope.section.stateParam) return tableFilter.values[scope.section.stateParam] = value;
                            if (scope.section.filterMap.default) return tableFilter.values[scope.section.filterMap.default] = value;
                        }
                    });

                    if (scope.section.stateParam) ctrl.value = Array.isArray($stateParams[scope.section.stateParam]) ? $stateParams[scope.section.stateParam].map(function (v) {
                        return convertToID(v);
                    }) : convertToID($stateParams[scope.section.stateParam]);

                    if (scope.section.filterMap.default) ctrl.value = Array.isArray($stateParams[scope.section.filterMap.default]) ? $stateParams[scope.section.filterMap.default].map(function (v) {
                        return convertToID(v);
                    }) : convertToID($stateParams[scope.section.filterMap.default]);
                } else {
                    Object.defineProperty(ctrl, 'value', {
                        get: function get() {
                            return tableFilter.values[scope.section.stateParam];
                        },
                        set: function set(value) {
                            if (value) {
                                scope.section.selectedCount = Array.isArray(value) ? value.length : 1;
                            } else {
                                scope.section.selectedCount = 0;
                            }
                            tableFilter.values[scope.section.stateParam] = value;
                        }
                    });

                    // Check if value is an array, convert array items to IDs
                    if (angular.isArray($stateParams[scope.section.stateParam])) {
                        ctrl.value = $stateParams[scope.section.stateParam].map(function (item) {
                            return convertToID(item);
                        });
                    } else {
                        ctrl.value = convertToID($stateParams[scope.section.stateParam]);
                    }
                }

                var optionsPromise = $q.when();

                var grabValue = function grabValue(val) {
                    if (val && ctrl.asyncField.optionsQuery) {
                        var thenFunc = function thenFunc(data) {
                            var a = GeckoUI.wrapArray(data.toObject());
                            ctrl.asyncField.options = data.data || a;
                        };

                        var catchFunc = function catchFunc(err) {
                            console.error(err); // eslint-disable-line no-console
                        };

                        if (val && Array.isArray(val)) {
                            val = val.map(function (v) {
                                return parseInt(v, 10);
                            });
                            optionsPromise = $q.when(angular.copy(ctrl.asyncField.optionsQuery).where('id', val).get().then(thenFunc).catch(catchFunc));
                        } else {
                            optionsPromise = $q.when(angular.copy(ctrl.asyncField.optionsQuery).get(parseInt(val, 10)).then(thenFunc).catch(catchFunc));
                        }
                    }
                };

                if (scope.section.type === 'dropdown' && scope.section.optionsQuery || scope.section.getOptions) {
                    changeTemplate = 'gecko-field';
                    ctrl.asyncField = angular.extend({ options: [] }, scope.section, { obj: ctrl, id: 'value', type: Gecko.Field.TYPE_SELECT });
                    if (!ctrl.asyncField.getOptions) {
                        ctrl.asyncField.getOptions = asyncOptions.create(ctrl.asyncField.optionsQuery);
                    }
                    grabValue(ctrl.value);
                } else if (scope.section.type === 'checkbox' && scope.section.optionsQuery) {
                    changeTemplate = 'gecko-field';
                    ctrl.asyncField = angular.extend({ options: [] }, scope.section, { obj: ctrl, id: 'value', type: Gecko.Field.TYPE_SELECT });
                    ctrl.asyncField.getOptions = asyncOptions.create(ctrl.asyncField.optionsQuery);
                    grabValue(ctrl.value);
                } else if (scope.section.type === 'radio' && scope.section.optionsQuery) {
                    changeTemplate = 'gecko-field';
                    ctrl.asyncField = angular.extend({ options: [] }, scope.section, { obj: ctrl, id: 'value', type: Gecko.Field.TYPE_RADIO });
                    ctrl.asyncField.getOptions = asyncOptions.create(ctrl.asyncField.optionsQuery, ctrl.asyncField.optionsQueryCallback);
                    grabValue(ctrl.value);
                } else if (scope.section.type === 'event') {
                    changeTemplate = 'gecko-field';
                    ctrl.asyncField = angular.extend({ options: [] }, scope.section, {
                        obj: ctrl,
                        id: 'value',
                        type: Gecko.Field.TYPE_TIERED_SELECT,
                        optionsInit: OptionsPresets.events.init,
                        getOptions: OptionsPresets.events.get,
                        placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
                        optionsKey: 'id',
                        optionsLabelKey: 'nice_title'
                    });
                } else if (scope.section.type === 'multi') {
                    changeTemplate = 'gecko-field';
                    ctrl.asyncField = angular.extend({ options: [] }, scope.section, {
                        obj: ctrl,
                        id: 'value',
                        type: Gecko.Field.TYPE_MULTI,
                        optionsKey: scope.section.optionsKey || 'id',
                        optionsLabelKey: scope.section.optionsLabelKey || 'name'
                    });
                    ctrl.asyncField.getOptions = asyncOptions.create(ctrl.asyncField.optionsQuery);
                    grabValue(ctrl.value);
                }

                var removeUndefinedString = function removeUndefinedString(arr) {
                    return arr.reduce(function (newArr, item) {
                        if (item && item !== 'undefined') {
                            return newArr.concat([item]);
                        }
                        return newArr;
                    }, []);
                };

                // Keep a map of multiple values in a way that's easier to set from angular ng-model
                var multiValue = {};

                var setMultiValue = function setMultiValue() {
                    if (angular.isArray(ctrl.value)) {
                        multiValue = munge(removeUndefinedString(ctrl.value)).toObjectKeys().done();
                    } else if (scope.section.extras && scope.section.filterMap && Object.keys(scope.section.filterMap).indexOf('default') !== -1) {
                        multiValue = munge(removeUndefinedString([].concat(ctrl.value))).toObjectKeys().done();
                    } else if (typeof ctrl.value !== 'undefined') {
                        multiValue[ctrl.value] = true;
                    }
                };
                scope.$watch(function () {
                    return tableFilter.values;
                }, function () {
                    setMultiValue();
                    angular.forEach(scope.section.filterMap, function (val, key) {
                        if (key === 'default') {
                            ctrl.value = tableFilter.values[val];
                        } else {
                            ctrl[key] = tableFilter.values[val];
                        }
                    });
                }, true);

                ctrl.multiValue = function (key, filterKey) {
                    if (typeof key === 'undefined') {
                        return angular.noop;
                    }
                    return function (value) {
                        if (arguments.length) {
                            multiValue[key] = value;

                            var valueArray = munge(multiValue).objectKeysToArray().done();
                            valueArray = valueArray.length === 0 ? undefined : valueArray;

                            if (scope.section.filterMap && filterKey) {
                                ctrl[filterKey] = valueArray;
                            } else {
                                ctrl.value = valueArray;
                            }
                        }

                        scope.section.selectedCount = Object.keys(multiValue).reduce(function (sum, key) {
                            if (multiValue[key]) {
                                sum += 1;
                            }
                            return sum;
                        }, 0);

                        return multiValue[key];
                    };
                };

                var validExtras = [];
                if (scope.section.extras && scope.section.extras.length > 0) {
                    angular.forEach(scope.section.extras, function (extra) {
                        if (extras[extra]) {
                            if (!ctrl[extra]) ctrl[extra] = extras[extra].default;
                            validExtras.push(extras[extra].file);
                        }
                    });
                }

                if (!templates[changeTemplate] && !templates[scope.section.type]) return;
                // If we are loading the initial option wait until it has loaded before loading the template(s)
                optionsPromise.then(function () {
                    var fileTemplates = [];
                    fileTemplates.push($templateRequest('/components/gecko-table-filter/filter-templates/' + (templates[changeTemplate] || templates[scope.section.type])));

                    angular.forEach(validExtras, function (extraFile) {
                        fileTemplates.push($templateRequest('/components/gecko-table-filter/extra-templates/' + extraFile));
                    });

                    $q.all(fileTemplates).then(function (templateContents) {
                        iElement.html(templateContents.join(''));
                        $compile(iElement.contents())(scope);
                    });
                });
            }
        };
    }

    angular.module('GeckoEngage').service('geckoTableFilterService', geckoTableFilterService).directive('geckoTableFilter', geckoTableFilter).directive('geckoTableFilterContent', geckoTableFilterContent).directive('geckoTableFilterWidget', geckoTableFilterWidget);

    var reduceParams = function reduceParams(paramObject) {
        return function (newParams, key) {
            if (paramObject[key]) {
                newParams[key] = paramObject[key];
            }
            return newParams;
        };
    };
    angular.module("GeckoEngage").run(function ($rootScope, geckoStore) {
        // Try setting a previously saved filter on the page
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
            var stateName = getStateName(toState, toParams);
            var prevParams = geckoStore.get('params-' + stateName);

            if (prevParams) {
                // Move any filled in previous state params into a new object.
                var newParams = !angular.isArray(prevParams) ? Object.keys(prevParams).reduce(reduceParams(prevParams), {}) : {};
                // Move any new params into a the object replacing any previous ones that have been overwritten
                newParams = !angular.isArray(newParams) ? Object.keys(toParams).reduce(reduceParams(toParams), newParams) : {};
                // Finally set the toParams to the new calculated params
                angular.extend(toParams, newParams);
            }
        });
    });
})();