(function () {
    'use strict';

    function geckoCallPaneActive() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-pane-active.html',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {},
            controller: function controller($scope, $geckoCall) {
                var ctrl = this;
                $scope.$geckoCall = $geckoCall;

                // Open call pane
                ctrl.toggle = function (reset) {
                    $geckoCall.open();
                };

                // Get active call title
                ctrl.getPaneTitle = function () {
                    if ($geckoCall.hasStarted() || $geckoCall.hasEnded()) {
                        var title = '',
                            name = $geckoCall.call.related_contact && $geckoCall.call.related_contact.full_name ? $geckoCall.call.related_contact.full_name : false;

                        // Add name
                        title += name || 'Unknown ' + ($geckoCall.isInbound() ? 'caller' : 'contact');

                        // Add number
                        return title += ' - ' + $geckoCall.getNumberTitle();
                    }
                    return '';
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallPaneActive', geckoCallPaneActive);
})();