(function () {
    'use strict';

    function geckoOptions($q, geckoFieldValueLink, $filter) {

        function orderOptions(field) {

            if (field.options && field.hasOwnProperty('orderBy')) {
                field.options = $filter('orderBy')(field.options, field.orderBy);
            }
        }

        var optionCache = new Object(null);

        return {

            util: {
                filterOptionsToLevel: function filterOptionsToLevel(options, level) {
                    var returnValue = {};
                    var queue = [1];

                    var node = { options: options };
                    var currentLevel;
                    while (queue.length) {
                        queue = queue.concat(node.options);

                        if (currentLevel === level) {
                            returnValue[node.value] = node.options;
                        }

                        node = queue.shift();
                        if (typeof node === 'number') {
                            currentLevel = node;
                            queue.push(node + 1);
                            node = queue.shift();
                        }

                        if (currentLevel > level) {
                            return returnValue;
                        }
                    }
                }
            },

            get: function get(id) {

                if (optionCache[id] && moment().isBefore(optionCache[id].expires)) {
                    return optionCache[id].promise;
                } else {
                    var expire = moment();
                    expire.add(10, 'seconds'); // 10 seconds is plenty long to avoid requesting the same options multiple
                    optionCache[id] = { // Times in one page load. And short enough to prevent users getting stale data.
                        expires: expire,
                        promise: $q.when(new Gecko.Option().get(id))
                    };
                    return optionCache[id].promise;
                }
            },

            configureField: function configureField(field) {

                var self = this;

                if (field.option_id || field.parent_id || field.values) {
                    // Default the keys to value value to support the use for Gecko.Fields
                    field.optionsKey = field.optionsKey || 'value';
                    field.optionsLabelKey = field.optionsLabelKey || 'value';
                }

                // Some fields use values, others come from options, prefer options
                if (field.option_id) {
                    self.get(field.option_id).then(function (option) {

                        field.options = option.values;
                        orderOptions(field);
                    });
                }

                // When we have a parent_id we need to get the option list from the parent and display the correct sub list.
                else if (field.parent_option_id) {
                        self.get(field.parent_option_id).then(function (option) {

                            var optionLevel = self.util.filterOptionsToLevel(option.values, field.option_level);

                            Object.defineProperty(field, 'options', {
                                get: function get() {
                                    try {
                                        return optionLevel[geckoFieldValueLink.values[field.parent_id]];
                                    } catch (e) {
                                        return;
                                    }
                                }
                            });
                        });
                    } else if (field.values) {
                        field.options = field.values;
                    }

                    // Defaults for custom fields. gecko-card-edit
                    else {
                            // Change keys to default to id when using dropdown without option or values.
                            field.optionsKey = field.optionsKey || 'id';
                            field.optionsLabelKey = field.optionsLabelKey || 'name';
                        }

                orderOptions(field);
            }
        };
    }

    angular.module('GeckoEngage').factory('geckoOptions', geckoOptions);
})();