var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/*! vex.js 2.3.0 */
(function () {
  var a;a = function a(_a) {
    var b, c;return b = !1, _a(function () {
      var d;return d = (document.body || document.documentElement).style, b = void 0 !== d.animation || void 0 !== d.WebkitAnimation || void 0 !== d.MozAnimation || void 0 !== d.MsAnimation || void 0 !== d.OAnimation, _a(window).bind("keyup.vex", function (a) {
        return 27 === a.keyCode ? c.closeByEscape() : void 0;
      });
    }), c = { globalID: 1, animationEndEvent: "animationend webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend", baseClassNames: { vex: "vex", content: "vex-content", overlay: "vex-overlay", close: "vex-close", closing: "vex-closing", open: "vex-open" }, defaultOptions: { content: "", showCloseButton: !0, escapeButtonCloses: !0, overlayClosesOnClick: !0, appendLocation: "body", className: "", css: {}, overlayClassName: "", overlayCSS: {}, contentClassName: "", contentCSS: {}, closeClassName: "", closeCSS: {} }, open: function open(b) {
        return b = _a.extend({}, c.defaultOptions, b), b.id = c.globalID, c.globalID += 1, b.$vex = _a("<div>").addClass(c.baseClassNames.vex).addClass(b.className).css(b.css).data({ vex: b }), b.$vexOverlay = _a("<div>").addClass(c.baseClassNames.overlay).addClass(b.overlayClassName).css(b.overlayCSS).data({ vex: b }), b.overlayClosesOnClick && b.$vexOverlay.bind("click.vex", function (b) {
          return b.target === this ? c.close(_a(this).data().vex.id) : void 0;
        }), b.$vex.append(b.$vexOverlay), b.$vexContent = _a("<div>").addClass(c.baseClassNames.content).addClass(b.contentClassName).css(b.contentCSS).append(b.content).data({ vex: b }), b.$vex.append(b.$vexContent), b.showCloseButton && (b.$closeButton = _a("<div>").addClass(c.baseClassNames.close).addClass(b.closeClassName).css(b.closeCSS).data({ vex: b }).bind("click.vex", function () {
          return c.close(_a(this).data().vex.id);
        }), b.$vexContent.append(b.$closeButton)), _a(b.appendLocation).append(b.$vex), c.setupBodyClassName(b.$vex), b.afterOpen && b.afterOpen(b.$vexContent, b), setTimeout(function () {
          return b.$vexContent.trigger("vexOpen", b);
        }, 0), b.$vexContent;
      }, getSelectorFromBaseClass: function getSelectorFromBaseClass(a) {
        return "." + a.split(" ").join(".");
      }, getAllVexes: function getAllVexes() {
        return _a("." + c.baseClassNames.vex + ':not(".' + c.baseClassNames.closing + '") ' + c.getSelectorFromBaseClass(c.baseClassNames.content));
      }, getVexByID: function getVexByID(b) {
        return c.getAllVexes().filter(function () {
          return _a(this).data().vex.id === b;
        });
      }, close: function close(a) {
        var b;if (!a) {
          if (b = c.getAllVexes().last(), !b.length) return !1;a = b.data().vex.id;
        }return c.closeByID(a);
      }, closeAll: function closeAll() {
        var b;return b = c.getAllVexes().map(function () {
          return _a(this).data().vex.id;
        }).toArray(), (null != b ? b.length : void 0) ? (_a.each(b.reverse(), function (a, b) {
          return c.closeByID(b);
        }), !0) : !1;
      }, closeByID: function closeByID(d) {
        var e, f, g, h, i;return f = c.getVexByID(d), f.length ? (e = f.data().vex.$vex, i = _a.extend({}, f.data().vex), g = function g() {
          return i.beforeClose ? i.beforeClose(f, i) : void 0;
        }, h = function h() {
          return f.trigger("vexClose", i), e.remove(), _a("body").trigger("vexAfterClose", i), i.afterClose ? i.afterClose(f, i) : void 0;
        }, b ? (g(), e.unbind(c.animationEndEvent).bind(c.animationEndEvent, function () {
          return h();
        }).addClass(c.baseClassNames.closing)) : (g(), h()), !0) : void 0;
      }, closeByEscape: function closeByEscape() {
        var b, d, e;return e = c.getAllVexes().map(function () {
          return _a(this).data().vex.id;
        }).toArray(), (null != e ? e.length : void 0) ? (d = Math.max.apply(Math, e), b = c.getVexByID(d), b.data().vex.escapeButtonCloses !== !0 ? !1 : c.closeByID(d)) : !1;
      }, setupBodyClassName: function setupBodyClassName() {
        return _a("body").bind("vexOpen.vex", function () {
          return _a("body").addClass(c.baseClassNames.open);
        }).bind("vexAfterClose.vex", function () {
          return c.getAllVexes().length ? void 0 : _a("body").removeClass(c.baseClassNames.open);
        });
      }, hideLoading: function hideLoading() {
        return _a(".vex-loading-spinner").remove();
      }, showLoading: function showLoading() {
        return c.hideLoading(), _a("body").append('<div class="vex-loading-spinner ' + c.defaultOptions.className + '"></div>');
      } };
  }, "function" == typeof define && define.amd ? define(["jquery"], a) : "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) ? module.exports = a(require("jquery")) : window.vex = a(jQuery);
}).call(this);