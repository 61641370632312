(function () {

    'use strict';

    function ConversationsRoute($stateProvider) {
        $stateProvider.state('conversations', {
            url: "/conversations",
            redirectKey: 'module_conversations',
            template: '<div>Redirect to <a href="/admin/conversations">/admin/conversations</a>.</div>'
        });
    }

    angular.module('GeckoEngage.routes').config(ConversationsRoute);
})();