;(function () {
    'use strict';

    function contactOrganisationList() {
        return {
            restrict: 'E',
            template: '\n                <div ng-if="ctrl.organisations.length">\n                    <span ng-repeat="organisation in ctrl.organisations track by $index">\n                        <a ui-sref="organisation.overview({organisation_id: organisation.id})">\n                            {{organisation.title}}\n                        </a>{{$last ? \'\' : \', \'}}\n                    </span>\n                </div>\n            ',
            scope: {
                contactId: '='
            },
            bindToController: true,
            controllerAs: 'ctrl',
            controller: contactOrganisationListCtrl
        };
    }

    function contactOrganisationListCtrl() {
        var ctrl = this;
        ctrl.organisations = [];

        if (!ctrl.contactId) return;

        var getEnrolments = function getEnrolments() {
            return new Gecko.Enrolment().include(['organisation']).rfields({ enrolment: ['organisation_id'], organisation: ['title'] }).where('contact_id', ctrl.contactId).get();
        };

        getEnrolments().then(function (enrolments) {
            ctrl.organisations = (enrolments.data || []).map(function (enrolment) {
                return enrolment.organisation;
            });
        });
    }

    angular.module('GeckoEngage').controller('contactOrganisationListCtrl', contactOrganisationListCtrl).directive('contactOrganisationList', contactOrganisationList);
})();