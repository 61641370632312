(function () {
    'use strict';

    var preSelects = [];

    function geckoTable($state, $stateParams, $filter, LoadingIndicator, geckoStore, $geckoCall) {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-table/gecko-table.html',
            transclude: true,
            scope: {
                rows: '=rows',
                cols: '=cols',
                select: '=select',
                selectOptions: '=selectOptions',
                selectSingle: '=?selectSingle',
                selectReadOnly: '&?',
                selectTitle: '@?selectTitle',
                sortable: '=sortable',
                reorderOptions: '=?reorderOptions',
                rowAction: '=rowAction',
                rowBtns: '=rowBtns',
                rowOptionsBtn: '=rowOptionsBtn',
                rowRemoveBtn: '=rowRemoveBtn',
                rowNestedKey: '@?rowNestedKey',
                hideTableHeader: '@',
                noOverflow: '@',
                isCardTable: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,

            require: ['geckoTable', '?^form'],
            link: function link($scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];

                $scope.Gecko = Gecko;
                $scope.GeckoUI = GeckoUI;
                $scope.$stateParams = $stateParams;
                ctrl.selectSingle = ctrl.selectSingle ? ctrl.selectSingle : false;
                ctrl.useMassAction = angular.isArray(ctrl.select) ? true : false;

                ctrl.noPersist = true;
                if (ctrl.selectOptions) {
                    ctrl.selectQuery = ctrl.selectOptions.selectQuery;
                    ctrl.initialTotalOptions = ctrl.selectOptions.initialTotalOptions;
                    ctrl.noPersist = ctrl.selectOptions.noPersist;
                }

                if (ctrl.selectReadOnly()) {
                    ctrl.useMassAction = false;
                }

                ctrl.getLabel = function (col, row, key) {
                    if (key) return GeckoUI.gobk(col.label_styles, 'id', key);
                    return GeckoUI.gobk(col.label_styles, 'id', ctrl.getValue(row, col.label_key ? col.label_key : col.key));
                };

                ctrl.getStatus = function (col, row, key) {
                    if (key) return GeckoUI.gobk(col.status_styles, 'id', key);
                    return GeckoUI.gobk(col.status_styles, 'id', ctrl.getValue(row, col.status_key ? col.status_key : col.key));
                };

                ctrl.getIcon = function (col, row, key) {
                    if (key) return GeckoUI.gobk(col.icon_styles, 'id', key);
                    return GeckoUI.gobk(col.icon_styles, 'id', ctrl.getValue(row, col.icon_key ? col.icon_key : col.key));
                };

                ctrl.getValue = function (row, key) {
                    if (row === undefined || key === undefined) return '';

                    // Get raw value
                    if (key.indexOf('.') != -1) {
                        return GeckoUI.getNestedObjectValue(row, key);
                    } else {
                        return row[key];
                    }
                };

                ctrl.render = function (col, row) {
                    var value;
                    if (ctrl.getValue(row, col.key) === 0) {
                        value = 0;
                    } else {
                        value = ctrl.getValue(row, col.key) || '';
                    }

                    // Apply custom render
                    if (col.render) {
                        value = col.render(value, col, row);
                    }

                    // Get processed value
                    if (col.data_type == Gecko.Field.DATA_TYPE_CURRENCY) {
                        return Number(value).toFixed(2);
                    } else if (col.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP && !col.humanize) {

                        if (col.utc) {
                            value = $filter('formatDateUTC')(value, col.date_format);
                            // } else if (col.timezone) {
                            //     var timezone = typeof col.timezone === 'string' ? col.timezone : Gecko.account.timezone;
                            //     value = $filter('formatDateTimezone')(value, timezone, col.date_format);
                        } else {
                            value = $filter('formatDate')(value, col.date_format);
                        }

                        return value;
                    } else if (col.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP && col.humanize) {
                        value = $filter('humanize')(value);
                    }

                    // Add fallback
                    if (!value) {
                        if (col.default_value) {
                            value = col.default_value;
                        } else if (col.data_type == Gecko.Field.DATA_TYPE_INTEGER) {
                            value = 0;
                        }
                    }
                    return value;
                };

                // Render title
                ctrl.renderTitle = function (col, row) {
                    if (col.renderTitle) {
                        return col.renderTitle(col, row);
                    }
                    if (col.label_styles !== undefined) {
                        if (col.render && ctrl.render(col, row) && !GeckoUI.gobk(col.label_styles, 'id', ctrl.render(col, row))) return ctrl.render(col, row);
                        return ctrl.getLabel(col, row).title || ctrl.render(col, row);
                    } else if (col.status_styles !== undefined) {
                        if (col.render && ctrl.render(col, row) && !GeckoUI.gobk(col.status_styles, 'id', ctrl.render(col, row))) return ctrl.render(col, row);
                        return ctrl.getStatus(col, row).title || ctrl.getStatus(col, row, ctrl.render(col, row)).title || ctrl.render(col, row);
                    } else {
                        if (col.type == Gecko.Field.TYPE_LABEL) return GeckoUI.getIdArray(ctrl.getValue(row, col.key), 'name').join(', ');
                        if (col.renderHtml) return $filter('stripTags')(ctrl.render(col, row));
                        return ctrl.render(col, row);
                    }
                };

                // Render tooltip
                ctrl.renderTooltip = function (col, row) {
                    if (col.renderHelpText) return col.renderHelpText(col, row);
                    if (col.helpTextKey && row[col.helpTextKey]) return row[col.helpTextKey];
                    return '';
                };

                // Open row
                ctrl.openRow = function (row, index) {
                    if (!ctrl.rowAction) return false;
                    // Return action function
                    if (ctrl.rowAction.action) return ctrl.rowAction.action(row, index);
                    // State dot go
                    var _params = GeckoUI.mapObjectArray([row], ctrl.rowAction.params);
                    return $state.go(ctrl.rowAction.state, _params[0]);
                };

                // Open cell
                ctrl.openCell = function (action, row, col, index, event) {
                    if (action) {
                        event.stopPropagation();
                        return action(row, col, index, ctrl.openRow.bind.apply(ctrl.openRow, [null, row, index]));
                    }
                    return false;
                };

                ctrl.removeRow = function (row, index) {
                    if (formCtrl) formCtrl.$setDirty(); // Since removing a row in this case seems to be mainly for changing data then dirty the form.
                    ctrl.rows.splice(index, 1);
                    if (ctrl.rowRemoveBtn && ctrl.rowRemoveBtn.action) return ctrl.rowRemoveBtn.action(row, index);
                };

                ctrl.getSelectVal = function (item) {
                    if (ctrl.selectOptions && ctrl.selectOptions.uniqueKey) {
                        return item[ctrl.selectOptions.uniqueKey];
                    } else {
                        return item.id;
                    }
                };

                var isSelected = function isSelected(item) {
                    return ctrl.select && ctrl.select.indexOf(item) !== -1;
                };

                ctrl.selectAdd = function (value) {
                    var row = ctrl.rows.find(function (row) {
                        return ctrl.getSelectVal(row) === value;
                    });

                    if (ctrl.selectOptions && ctrl.selectOptions.disabledWhen && ctrl.selectOptions.disabledWhen(row)) return;

                    if (ctrl.select.indexOf(value) === -1) ctrl.select.push(value);else ctrl.select.splice(ctrl.select.indexOf(value), 1);

                    if (ctrl.selectOptions && ctrl.selectOptions.onChange) {
                        ctrl.selectOptions.onChange(row, value);
                    }
                };

                ctrl.selectOptionsDefault = function (col, row, id) {
                    var optionDefault = '—';
                    if (col.selectOptionsDefault) {
                        optionDefault = col.selectOptionsDefault(row);
                    }

                    setTimeout(function () {
                        var labelArray = [];
                        $('#' + id + ' option').each(function () {
                            labelArray.push($(this).text());
                        });
                        if (labelArray.indexOf(optionDefault) === -1) {
                            $('#' + id).trigger('chosen:updated');
                        }
                    }, 10);

                    return optionDefault;
                };

                ctrl.selectChecked = function (item) {
                    // Check it if it should be checked
                    if (ctrl.selectOptions && ctrl.selectOptions.checkedWhen && ctrl.selectOptions.checkedWhen(item) && !isSelected(ctrl.getSelectVal(item))) {
                        ctrl.select.push(ctrl.getSelectVal(item));
                    }
                    return isSelected(ctrl.getSelectVal(item));
                };

                ctrl.selectDisabled = function (item) {
                    if (ctrl.selectOptions && ctrl.selectOptions.disabledWhen) {
                        return ctrl.selectOptions.disabledWhen(item);
                    } else {
                        return false;
                    }
                };

                ctrl.selectAllChecked = function () {
                    return arrayContainsArray(ctrl.select, visibleSelects());
                };

                var arrayContainsArray = function arrayContainsArray(array1, array2) {
                    if (!Array.isArray(array1) || !Array.isArray(array2)) {
                        return false;
                    }
                    if (array1.length === 0 && array2.length === 0) {
                        return false;
                    }
                    if ((array1.length === 0 || array2.length === 0) && array1.length !== array2.length) {
                        return false;
                    }
                    for (var index = 0; index < array2.length; index++) {
                        if (array1.indexOf(array2[index]) === -1) {
                            return false;
                        }
                    }
                    return true;
                };

                var visibleSelects = function visibleSelects() {
                    return ctrl.rows.filter(function (row) {
                        return ctrl.selectOptions && ctrl.selectOptions.disabledWhen ? !ctrl.selectOptions.disabledWhen(row) : true;
                    }).map(function (row) {
                        return ctrl.getSelectVal(row);
                    });
                };

                var fireOnChange = function fireOnChange(value) {
                    if (ctrl.selectOptions && ctrl.selectOptions.onChange) {
                        var row = ctrl.rows.find(function (row) {
                            return ctrl.getSelectVal(row) === value;
                        });
                        ctrl.selectOptions.onChange(row, value);
                    }
                };

                var addSelectItem = function addSelectItem(value) {
                    if (ctrl.select.indexOf(value) === -1) {
                        ctrl.select.push(value);
                    }
                    fireOnChange(value);
                };

                var removeSelectItem = function removeSelectItem(value) {
                    var index = ctrl.select.indexOf(value);
                    if (index !== -1) {
                        ctrl.select.splice(index, 1);
                    }
                    fireOnChange(value);
                };

                ctrl.selectAll = function () {
                    var visible = visibleSelects();
                    if (arrayContainsArray(ctrl.select, visible)) {
                        visible.forEach(removeSelectItem);
                    } else {
                        visible.forEach(addSelectItem);
                    }
                };

                ctrl.hardSelectAll = function () {
                    var actionFunction = ctrl.select.length !== ctrl.selectCount ? addSelectItem : removeSelectItem;
                    if (ctrl.query) {
                        LoadingIndicator.show();
                        visibleSelects().forEach(actionFunction);
                        ctrl.query.then(function () {
                            LoadingIndicator.hide();
                            setTimeout(function () {
                                ctrl.selectValues.forEach(actionFunction);
                                $scope.$digest();
                            }, 10);
                        });
                    } else {
                        ctrl.selectValues.forEach(actionFunction);
                    }
                };

                ctrl.getColSelectOptions = function (col, row) {
                    if (Array.isArray(col.selectOptions)) {
                        return col.selectOptions;
                    }
                    if (typeof col.selectOptions === 'function') {
                        return col.selectOptions(row);
                    }
                    return [];
                };

                // Table reorder / UI sortable options
                ctrl.reorder = ctrl.reorderOptions != undefined ? true : false;
                ctrl.reorderDefaultOptions = {
                    handle: '.reorder-handle',
                    items: 'tr:not(.gecko-table-reorder-disabled)'
                };

                if (!ctrl.reorder) {
                    ctrl.reorderOptionsMerged = {
                        disabled: true
                    };
                } else if (ctrl.reorder && !ctrl.reorderOptions) {
                    ctrl.reorderOptionsMerged = ctrl.reorderDefaultOptions;
                } else {
                    ctrl.reorderOptionsMerged = angular.extend(ctrl.reorderDefaultOptions, ctrl.reorderOptions);
                }

                // When we have a parent form then we set it dirty when we reorder the table rows.
                if (formCtrl) {
                    (function () {
                        'use strict';

                        var stop = ctrl.reorderOptionsMerged.stop; // Move the stop function we are about to overrite
                        ctrl.reorderOptionsMerged.stop = function () {
                            formCtrl.$setDirty();
                            if (typeof stop === 'function') stop.apply(this, arguments); // Call stop if it's a function.
                        };
                    })();
                }

                // Table Sorter
                ctrl.sorter = function (order) {
                    if (!order) return false;
                    var sort = !$stateParams.sort || $stateParams.order != order || $stateParams.sort == 'DESC' ? 'ASC' : 'DESC',
                        params = angular.extend({}, $stateParams, { order: order, sort: sort });
                    return $state.go($state.$current.name, params, { replace: true });
                };

                ctrl.getClass = function (index) {
                    var style = {};
                    if (index > 0) {
                        style.marginLeft = '10px';
                    }
                    return style;
                };

                ctrl.getAvatarColor = function (col, row) {

                    if (row.object_key == 'user' || row.full_name && row.color) {
                        return row.color;
                    }

                    if ((col.key == 'user_id' || col.key.indexOf('user.full_name') !== -1) && (row.related_user || row.user)) {
                        return (row.related_user || row.user).color;
                    }

                    return null;
                };

                // Expand status columns
                ctrl.expand = function (col) {
                    if (col.expandable && col.status_styles !== undefined) {
                        col.status_title_none = col.status_title_none === false ? true : false;
                    }
                    return false;
                };

                // Check for nested key setting
                if (ctrl.rowNestedKey) {
                    // Want to copy out our rows
                    var entries = angular.copy(ctrl.rows);
                    // Then wipe the existing rows to make life easier
                    ctrl.rows = [];
                    // Loop through every entry
                    angular.forEach(entries, function (row) {
                        // Add it in
                        ctrl.rows.push(row);
                        // And check to see if there is any nested rows available for it
                        if (row[ctrl.rowNestedKey] && row[ctrl.rowNestedKey].length > 0) {
                            // Loop through them all so we can add the rowClass and push
                            angular.forEach(row[ctrl.rowNestedKey], function (nestedRow) {
                                nestedRow.nestedRow = true;
                                ctrl.rows.push(nestedRow);
                            });
                        }
                    });
                }

                ctrl.selectValues = ctrl.selectOptions && ctrl.selectOptions.optionsArray ? ctrl.selectOptions.optionsArray : ctrl.rows.map(ctrl.getSelectVal);
                if (ctrl.selectQuery) {
                    var query = ctrl.selectQuery();
                    var key = ctrl.selectOptions && ctrl.selectOptions.uniqueKey ? ctrl.selectOptions.uniqueKey : 'id';
                    var fields = {};
                    fields[query.object_key] = [key];
                    query.includes = [];
                    ctrl.query = query.perPage('10000').rfields(fields).get();
                    ctrl.query.then(function (response) {
                        ctrl.selectValues = response.data.map(function (thing) {
                            return thing[key];
                        });
                        ctrl.selectCount = ctrl.selectValues.length;
                        ctrl.query = undefined;
                        $scope.$digest();
                    });
                }
                ctrl.selectCount = ctrl.initialTotalOptions || ctrl.selectValues.length;

                var persistSelect = function persistSelect(selectOptions) {
                    var stateString = $state.current.name + JSON.stringify($state.params) + '-selected';
                    geckoStore.set(stateString, ctrl.noPersist ? [] : selectOptions);
                };

                if (ctrl.select && !ctrl.noPersist) {
                    $scope.$watch(function () {
                        return preSelects;
                    }, function (newVal) {
                        ctrl.select = newVal;
                    });
                    $scope.$watch(function () {
                        return ctrl.select ? ctrl.select.length : 0;
                    }, function () {
                        persistSelect(ctrl.select);
                    });
                }
                // Can click to call
                ctrl.canClickToCall = function (col, row) {
                    if (!(Gecko.user && Gecko.user.call_sender_id) && !(Gecko.account && Gecko.account.call_sender_id)) {
                        return false;
                    }
                    return Gecko.User.voipOutboundEnabled() && col.type === Gecko.Field.TYPE_TEL && ctrl.render(col, row) && col.clickToCall;
                };
                // Open click to call
                ctrl.clickToCall = $geckoCall.clickToCall;

                // Header cell classes
                ctrl.renderHeaderCellClasses = function (col, index) {
                    var classes = {
                        //'b-left' : ctrl.useMassAction || ctrl.selectReadOnly() || ctrl.reorder || index > 0,
                        'active': ctrl.sortable && $stateParams.order == col.sortKey, 'gecko-table-sortable-col': col.sortKey
                    };
                    // Cell custom class
                    if (col.colClass) classes[col.colClass] = true;
                    // Cell helper classes
                    classes[GeckoUI.prepareIndexClass('gecko-table-cell-header', index)] = true;
                    classes[GeckoUI.prepareNameClass('gecko-table-cell-header', col.key)] = true;

                    return classes;
                };

                // Cell classes
                ctrl.renderCellClasses = function (col, row, index) {
                    var classes = {
                        'has-action': col.action && (col.hideAction ? !col.hideAction(col, row) : true),
                        'gecko-table-nested-entry': row.nestedRow && col.nestedCol
                    };
                    // Cell custom class
                    if (col.colClass) classes[col.colClass] = true;
                    // Cell helper classes
                    classes[GeckoUI.prepareIndexClass('gecko-table-cell', index)] = true;
                    classes[GeckoUI.prepareNameClass('gecko-table-cell', col.key)] = true;

                    return classes;
                };

                // Row classes
                ctrl.tableRowClasses = (ctrl.rows || []).map(function (r, index) {
                    return GeckoUI.prepareIndexClass('gecko-table-row', index);
                });

                // Header cell classes
                ctrl.tableHeaderCellClasses = (ctrl.cols || []).map(function (c, i) {
                    return ctrl.renderHeaderCellClasses(c, i);
                });

                // Cell classes
                ctrl.tableCellClasses = (ctrl.rows || []).map(function (r, ri) {
                    return (ctrl.cols || []).map(function (c, ci) {
                        return ctrl.renderCellClasses(c, r, ci);
                    });
                });

                // Cell titles
                ctrl.tableCellTitles = (ctrl.rows || []).map(function (r) {
                    return (ctrl.cols || []).map(function (c) {
                        return ctrl.renderTitle(c, r);
                    });
                });

                // Can click to call map
                ctrl.canClickToCallMap = (ctrl.rows || []).map(function (r, i) {
                    return (ctrl.cols || []).map(function (c) {
                        return ctrl.canClickToCall(c, r);
                    });
                });

                // Row is nested
                ctrl.tableRowNestedMap = (ctrl.rows || []).map(function (r) {
                    return (ctrl.cols || []).map(function (c) {
                        return r.nestedRow && c.nestedCol || c.isNested && c.isNested(ctrl.render(c, r), c, r);
                    });
                });

                // Cell tooltip map
                ctrl.tableCellTooltipMap = (ctrl.rows || []).map(function (r) {
                    return (ctrl.cols || []).map(function (c) {
                        return ctrl.renderTooltip(c, r);
                    });
                });

                // Cell avatar map
                ctrl.tableCellAvatarMap = (ctrl.rows || []).map(function (r) {
                    return (ctrl.cols || []).map(function (c) {
                        return c.type == Gecko.Field.TYPE_NAME && ctrl.render(c, r) && !(c.hideAvatar && c.hideAvatar(c, r));
                    });
                });

                // Cell show other
                ctrl.tableCellShowOtherMap = (ctrl.rows || []).map(function (r) {
                    return (ctrl.cols || []).map(function (c) {
                        return !c.label_styles && !c.status_styles && !c.icon_styles && c.input_type != Gecko.Field.TYPE_CHECKBOX && c.type != Gecko.Field.TYPE_AUDIO && c.type != Gecko.Field.TYPE_LABEL && !c.selectOptions && c.data_type != Gecko.Field.DATA_TYPE_CURRENCY && c.input_type != Gecko.Field.TYPE_RADIO && !c.src && !c.renderSrc;
                    });
                });

                //Cell rendered Value
                // ctrl.tableCellRenderedValue = (ctrl.rows || []).map(r => {
                //     return (ctrl.cols || []).map(c => ctrl.render(c, r));
                // });
            }
        };
    }

    var prefillSelects = function prefillSelects($rootScope, geckoStore) {
        var lastStateString = undefined;
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams) {
            if (toParams.page) {
                toParams.page = undefined;
            }
            var stateString = toState.name + JSON.stringify(toParams) + '-selected';
            if (lastStateString === stateString) {
                preSelects = geckoStore.get(stateString);
            } else {
                preSelects = [];
                geckoStore.remove(stateString);
            }
            lastStateString = stateString;
        });
    };

    angular.module('GeckoEngage').directive('geckoTable', geckoTable).run(prefillSelects);
})();