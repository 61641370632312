(function () {
    'use strict';

    var permissionCheck = function permissionCheck($stateParams) {
        if (Gecko.group.type === Gecko.Group.MOBILE) {
            return false;
        } else if (Number($stateParams.user_id) === 0) {
            return Gecko.able(Gecko.ABILITY_USERS_CREATE);
        } else {
            return Gecko.able(Gecko.ABILITY_USERS_VIEW);
        }
    };

    function UserRoute($stateProvider) {
        $stateProvider.state('user', {
            url: "/settings/users/{user_id}",
            templateUrl: "/modules/user/views/user.8d04fc02.html",
            controller: 'UserCtrl',
            controllerAs: 'ctrl',
            page_title: 'Edit User',
            permissionCheck: permissionCheck,
            resolve: {
                formRfields: function formRfields() {
                    return ['name', 'internal_name', 'deleted_at'];
                },
                data: function data(Gecko, $stateParams, formRfields) {
                    var _query = {};

                    // User
                    if ($stateParams.user_id != 0) _query.user = new Gecko.User().where('trashed', 1).include('forms:10000').rfields({ form: formRfields }).path($stateParams.user_id);

                    return Gecko.multi(_query);
                },
                user: function user(data) {
                    return data.user ? data.user : new Gecko.User();
                },

                selectedData: function selectedData(asyncOptions, user) {
                    return asyncOptions.getSelectedValues({
                        sender: { model: 'Sender', id: user.call_sender_id, fields: { sender: ['name'] } },
                        group: { model: 'Group', id: user.group_id, fields: { group: ['name'] } }
                    });
                }
            }
        }).state('user.settings', {
            url: "/settings",
            templateUrl: "/modules/user/views/settings.ac4a3e8d.html",
            controller: 'UserSettingsCtrl',
            controllerAs: 'ctrl',
            page_title: 'User Settings',
            redirectKey: 'module_user_settings',
            permissionCheck: permissionCheck
        }).state('user.chat-settings', {
            url: "/chat-settings",
            redirectKey: 'module_user_chat_settings',
            template: '<div>Redirect to <a href="/admin/settings/users/' + window.Gecko.user.id + '/chat-settings">/admin/settings/users/' + window.Gecko.user.id + '/chat-settings</a>.</div>'
        }).state('user.sessions', {
            url: "/sessions?{page}&{order}&{sort}",
            templateUrl: "/modules/user/views/sessions.b3b1fadb.html",
            controller: 'UserSessionsCtrl',
            controllerAs: 'ctrl',
            page_title: 'User Sessions',
            redirectKey: 'module_user_sessions',
            permissionCheck: permissionCheck,
            resolve: {
                tokens: function tokens($stateParams) {
                    var tokens = new Gecko.Token().rfields({ token: ['created_at', 'updated_at', 'revoked', 'type', 'device_id'], device: ['udid', 'type', 'app_version', 'os_version'] }).include('device').where('token_user_id', $stateParams.user_id);

                    var types = [Gecko.Token.TYPE_MOBILE, Gecko.Token.TYPE_CAPTURE, Gecko.Token.TYPE_SCAN,
                    // LEGACY STUFF
                    'android', 'iphone', 'ipad'];
                    tokens.where('token_type', types);

                    if ($stateParams.order) tokens.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');

                    return tokens.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                }
            }
        }).state('user.forms', {
            url: "/forms",
            templateUrl: "/modules/user/views/forms.7659ab62.html",
            controller: 'UserFormsCtrl',
            controllerAs: 'ctrl',
            page_title: 'User Forms',
            redirectKey: 'module_user_forms',
            permissionCheck: permissionCheck,
            resolve: {
                forms: function forms(formRfields) {
                    return new Gecko.Form().include('all_forms', 'deleted').where('trashed', 1).perPage(10000).rfields({ form: formRfields }).get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(UserRoute);
})();