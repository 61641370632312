(function () {
    'use strict';

    function geckoWorkflow($modal, $filter, $geckoModal, geckoTranslateService, isWorkflowFieldActionBroken) {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-workflow/gecko-workflow.html',
            scope: {
                workflows: '=',
                campaign: '=',
                multiData: '=',
                assignableChatAgents: '=',
                type: '@',
                module: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            require: ['geckoWorkflow', '?^form'],
            link: function geckoWorkflowLink(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];
                ctrl.GeckoUI = GeckoUI;
                ctrl.Gecko = Gecko;

                ctrl.rowOptionsBtn = {};
                ctrl.rowOptionsBtn.items = [{
                    title: 'Remove Workflow'
                }, {
                    title: 'Enable Test Mode'
                }];

                ctrl.requirementOptions = [{ value: 'ALL', label: 'all' }, { value: 'ANY', label: 'any' }];

                ctrl.sortOptions = {
                    handle: '.fa-bars',
                    placeholder: 'gecko-drag-placeholder',
                    axis: 'y',
                    stop: function stop() {
                        angular.forEach(ctrl.workflows, function (workflow, index) {
                            workflow.order = index;
                        });
                        scope.$digest();
                    }
                };

                // Parse through the multi data and create ctrl entries for each
                if (!ctrl.multiData.contact_fields) ctrl.multiData.contact_fields = ctrl.multiData.fields;

                angular.forEach(ctrl.multiData, function (value, key) {
                    ctrl[key] = value.toArray ? value.toArray() : value;
                });

                // Flatten all field types into single array for reference
                var allFormAndContactFields = [].concat(ctrl.fields || [], ctrl.contact_fields || []);

                // Add default email template to array
                if (ctrl.templates && angular.isArray(ctrl.templates) && ctrl.type === 'form') {
                    ctrl.templates = [{
                        id: 0,
                        name: 'Default Email Template',
                        type: Gecko.Template.TYPE_EMAIL
                    }].concat(ctrl.templates);
                }

                function reloadWorkflows() {
                    // Parse through every workflow and set a pretty title for their requirment
                    angular.forEach(ctrl.workflows, function (workflow, key) {
                        if (workflow.requirement == 'ALL') {
                            workflow.requirement_title = 'AND';
                        } else {
                            workflow.requirement_title = 'OR';
                        }

                        // If there is no name set we need to manually set one
                        if (!workflow.name) {
                            workflow.name = 'Workflow ' + (key + 1);
                        }

                        // Open ALL workflows by default
                        workflow.expanded = true;

                        // Loop through every action and reformat Attendance actions
                        angular.forEach(workflow.actions, function (action) {
                            if (action.type == 'attendance') {
                                // We don't want to support both 'event_id' and 'to'. 'to' takes precedence in the API
                                action.to = action.to || action.event_id;
                                delete action.event_id;
                            }
                        });
                    });
                }

                iAttrs.$observe('workflows', function () {
                    reloadWorkflows();
                });

                ctrl.integrationTitleWithDate = function (integration_id) {
                    for (var i = 0; i < ctrl.integrations.length; ++i) {
                        if (integration_id === ctrl.integrations[i].id) {
                            return ctrl.integrations[i].title_with_date;
                        }
                    }

                    return '';
                };

                ctrl.getTaskName = function (taskId) {
                    try {
                        return ctrl.tasks.find(function (task) {
                            return task.id === taskId;
                        }).name;
                    } catch (e) {
                        return '';
                    }
                };

                /**
                 * Convert an array of assignable GeckoChat entity ids
                 * into a human friendly comma separated list with the
                 * entity name.
                 *
                 * @param array assignableKeys - of assignable GeckoChat ids e.g ['agent_1', 'team_20']
                 * @return string - comma separated list
                 */
                ctrl.cacheAssignableKeys = null;
                ctrl.assignableChatAgentsByName = function (assignableKeys) {
                    if (typeof assignableKeys === 'string') {
                        assignableKeys = [assignableKeys];
                    }

                    // Cache the value so this only updates when assignableKeys changes
                    if (ctrl.assignedChatAgentName && ctrl.cacheAssignableKeys === JSON.stringify(assignableKeys)) {
                        return ctrl.assignedChatAgentName;
                    }
                    ctrl.cacheAssignableKeys = JSON.stringify(assignableKeys);

                    ctrl.assignedChatAgentName = assignableKeys.map(function (key, index) {
                        var assignableFullData = ctrl.assignableChatAgents.find(function (assignable, index) {
                            return assignable.key === key;
                        });

                        if (assignableFullData) {
                            return assignableFullData.value;
                        } else {
                            return 'Unknown Assignee';
                        }
                    }).filter(function (item) {
                        return item;
                    }).join(', ');

                    return ctrl.assignedChatAgentName;
                };

                /**
                 * Open an action in a model
                 * @param  {object} workflow The parent workflow for the action
                 * @param  {object} action Action that you are editing in the modal
                 * @return {null}
                 */
                ctrl.openAction = function (workflow, action) {
                    // Copy the object so that you can't edit it without saving
                    var modalAction;
                    if (action) {
                        modalAction = angular.copy(action);
                    }

                    $geckoModal.workflowAction(modalAction, ctrl.campaigns, ctrl.fields, ctrl.contact_fields, ctrl.labels, ctrl.senders, ctrl.templates, ctrl.type, ctrl.users, ctrl.organisations, ctrl.module, ctrl.consents, ctrl.integrations, ctrl.assignableChatAgents, ctrl.tasks).result.then(function (response) {
                        if (formCtrl) formCtrl.$setDirty();
                        // If there was already an existing condition we just want to update it
                        if (action) {
                            // If this has a method of save set
                            if (response.method == 'save') {
                                // We want to use an angular.copy and replace the existing one
                                angular.copy(response.action, action);

                                // Otherwise this is a delete method
                            } else {
                                // Find the index of it in workflow actions
                                var index = workflow.actions.indexOf(action);
                                if (index !== -1) {
                                    // And then splice it out
                                    workflow.actions.splice(index, 1);
                                }
                            }
                            // Otherwise we want to push this new one into the array of conditions
                        } else {
                            workflow.actions.push(response.action);
                        }
                    });
                };

                /**
                 * is this workflow scheduled and has it started and/or finished running?
                 */
                ctrl.hasScheduledRun = function (workflow) {
                    return workflow.schedule && workflow.schedule_run > 0;
                };

                /**
                 * Open the Delay modal
                 * @param  {object} workflow The workflow that we are modifying the delay for
                 * @return {null}
                 */
                ctrl.openDelay = function (workflow) {
                    // Copy the object so that you can't edit it without saving
                    var modalWorkflow = angular.copy(workflow);

                    $geckoModal.workflowDelay(modalWorkflow, ctrl.type).result.then(function () {
                        if (formCtrl) formCtrl.$setDirty();
                        angular.copy(modalWorkflow, workflow);
                    });
                };

                /**
                 * Remove a workflow from the workflows array
                 * @param  {object} workflow The workflow that you want removed
                 * @return {null}
                 */
                ctrl.removeWorkflow = function (workflow) {
                    var index = ctrl.workflows.indexOf(workflow);
                    if (index !== -1) {
                        if (formCtrl) formCtrl.$setDirty();
                        ctrl.workflows.splice(index, 1);
                    }
                };

                /**
                 * Add a new workflow to the workflows array
                 * @return {null}
                 */
                ctrl.addWorkflow = function () {

                    // Check to make sure there isn't already an empty workflow
                    if (ctrl.campaign.related_workflows[ctrl.campaign.related_workflows.length - 1] !== undefined && !ctrl.campaign.related_workflows[ctrl.campaign.related_workflows.length - 1].conditions.length && !ctrl.campaign.related_workflows[ctrl.campaign.related_workflows.length - 1].actions.length) {
                        return GeckoUI.messenger.error('Please complete the previous workflow before adding another.');
                    }

                    // Lets create a new Workflow object
                    var new_workflow = new Gecko.Workflow().fill({
                        requirement: 'ALL',
                        name: 'New Workflow',
                        conditions: [],
                        actions: [],
                        delay: 0,
                        before: null,
                        after: null,
                        order: ctrl.campaign.related_workflows.length,
                        evaluate_after_delay: 1
                    });

                    new_workflow.expanded = true;

                    // Add system event condition if event workflow
                    if (ctrl.campaign instanceof Gecko.Event) {
                        new_workflow.conditions.push({
                            type: Gecko.Condition.MODEL_EVENT,
                            value: ctrl.campaign.id,
                            match: '=',
                            system: true
                        });
                    }

                    // And then push it out to the world
                    ctrl.campaign.related_workflows.push(new_workflow);
                    if (formCtrl) formCtrl.$setDirty();
                };

                /**
                 * Clone a workflow to the workflows array
                 * @return {null}
                 */
                ctrl.cloneWorkflow = function (workflow) {

                    var modalWorkflow;
                    if (workflow) {
                        modalWorkflow = angular.copy(workflow);
                    }

                    // Lets create a new Workflow object
                    var new_workflow = new Gecko.Workflow().fill({
                        requirement: modalWorkflow.requirement,
                        name: 'Clone of ' + modalWorkflow.name,
                        conditions: modalWorkflow.conditions,
                        actions: modalWorkflow.actions,
                        delay: modalWorkflow.delay,
                        order: ctrl.campaign.related_workflows.length,
                        delayType: modalWorkflow.delayType,
                        schedule: modalWorkflow.schedule,
                        test_mode: modalWorkflow.test_mode,
                        after: modalWorkflow.after || null,
                        before: modalWorkflow.before || null,
                        stop_at: modalWorkflow.stop_at,
                        evaluate_after_delay: modalWorkflow.evaluate_after_delay
                    });

                    new_workflow.expanded = true;

                    // And then push it out to the world
                    ctrl.campaign.related_workflows.push(new_workflow);
                    if (formCtrl) formCtrl.$setDirty();
                };

                /**
                 * Get the to field description
                 * @param  {array} to The array of to options
                 * @return {array}    Array of return objects
                 */
                ctrl.getTo = function (to) {
                    var _to = [];
                    try {
                        _to = [];
                        angular.forEach(to, function (t) {
                            if (t.includes('field:')) {
                                var fieldId = Number(t.replace('field:', ''));
                                _to.push(ctrl.renderFieldString(fieldId));
                            } else if (t.includes('assigned')) {
                                _to.push('Assigned to User');
                            } else if (t.includes('captured')) {
                                _to.push('Captured by User');
                            } else {
                                var email = GeckoUI.getSingle(t, ctrl.users, 'email');
                                var telephone = GeckoUI.getSingle(t, ctrl.users, 'telephone');

                                if (!jQuery.isEmptyObject(email)) {
                                    _to.push(GeckoUI.getSingle(t, ctrl.users, 'email').full_name);
                                } else if (!jQuery.isEmptyObject(telephone)) {
                                    _to.push(GeckoUI.getSingle(t, ctrl.users, 'telephone').full_name);
                                } else {
                                    _to.push(t);
                                }
                            }
                        });
                    } catch (e) {
                        // Pass
                    }
                    return _to;
                };

                /**
                 * Format the delay amount to a string
                 * @param  {integer} delay The integer amount to delay
                 * @return {string}        The string description of how long the delay is
                 */
                ctrl.delayString = function (delay, schedule, after, before, stop_at) {
                    var format = (Gecko.account || {}).short_date_format === 'm/d/Y' ? 'MM/dd/yyyy @ HH:mm' : 'dd/MM/yyyy @ HH:mm';

                    if (stop_at) {
                        var stopStr = ' until ' + moment(stop_at, 'X').format(Gecko.dateFormat.shortTime);
                    } else {
                        var stopStr = '';
                    }

                    // If the Delay is 0 then we want to return the string Immediately, plus any stop at date
                    if (delay === 0 && (schedule === 0 || schedule === null || schedule === undefined) && (after === 0 || after === null || after === undefined) && (before === 0 || before === null || before === undefined)) {
                        return 'Immediately' + stopStr;
                    }

                    // If there is a schedule going on
                    if (schedule) {
                        var date = new Date(schedule * 1000);
                        return 'after ' + $filter('date')(date, format) + stopStr;
                    }

                    // If there is an explicit delay
                    if (delay) {
                        // Build up the array by decompose the seconds
                        var delayArray = GeckoUI.decomposeSeconds(delay);
                        // Start off the string to return
                        var delayString = 'After ';

                        if (delayArray.days) {
                            delayString += delayArray.days + ' Day';
                            delayString += delayArray.days === 1 ? ' ' : 's ';
                        }

                        // If there is hours, show hours
                        if (delayArray.hours) {
                            delayString += delayArray.hours + ' Hour';
                            delayString += delayArray.hours === 1 ? ' ' : 's ';
                        }

                        // If there is minutes, show minutes
                        if (delayArray.minutes) {
                            delayString += delayArray.minutes + ' Minute';
                            delayString += delayArray.minutes === 1 ? ' ' : 's ';
                        }

                        // If there is seconds, show seconds
                        if (delayArray.seconds) {
                            delayString += delayArray.seconds + ' Second';
                            delayString += delayArray.seconds === 1 ? ' ' : 's ';
                        }

                        // And Delay, and any stop date, to the end
                        delayString += ' Delay' + stopStr;
                        // And return it
                        return delayString;
                    }

                    if (before || after) {

                        // if there is a relative time delta...
                        var relative = { type: after ? 'After ' : 'Before ', value: after ? after : before };

                        var deltaArray = GeckoUI.decomposeSeconds(relative.value);

                        var deltaString = '';

                        if (deltaArray.days) {
                            deltaString += deltaArray.days + ' Day';
                            deltaString += deltaArray.days === 1 ? ' ' : 's ';
                        }

                        if (deltaArray.hours) {
                            deltaString += deltaArray.hours + ' Hour';
                            deltaString += deltaArray.hours === 1 ? ' ' : 's ';
                        }

                        if (deltaArray.minutes) {
                            deltaString += deltaArray.minutes + ' Minute';
                            deltaString += deltaArray.minutes === 1 ? ' ' : 's ';
                        }

                        if (deltaArray.seconds) {
                            deltaString += deltaArray.seconds + ' Second';
                            deltaString += deltaArray.seconds === 1 ? ' ' : 's ';
                        }

                        deltaString += relative.type;

                        return deltaString + stopStr;
                    }
                };

                ctrl.consentActionString = function (value) {
                    if (value === Gecko.Workflow.ACTION_MANGE_CONSENT_OPERATOR_REMOVE) {
                        return 'Withdraw';
                    }
                    return 'Give';
                };

                ctrl.consentValueString = function (value) {
                    return (value || []).reduce(function (arr, v) {
                        var consent = ctrl.consents.find(function (c) {
                            return c.id == v;
                        });
                        if (consent) arr.push(consent.title || v);
                        return arr;
                    }, []).join(', ');
                };

                ctrl.showConsentAlert = function (type, ignoreConsents) {
                    // SMS Action
                    if (type === Gecko.Workflow.ACTION_SMS) return !ignoreConsents;

                    // Email Action
                    if (ignoreConsents) return false;

                    return true;
                };

                ctrl.requiredConsentsString = function (template, type) {
                    if (template && template.current_granted_consents && template.current_granted_consents.length) {
                        return 'Will only be sent if the contact(s) have consented to one of the following: <strong>' + template.current_granted_consents.map(function (c) {
                            return c.title;
                        }).join(', ') + '</strong>.';
                    }
                    return 'Will only be sent if the contact(s) have consented to recieve ' + type + ' messages.';
                };

                ctrl.renderFieldString = function (fieldId) {
                    var field = allFormAndContactFields.find(function (field) {
                        return field.id === fieldId;
                    });
                    if (field) {
                        var isFormResponseField = field.field_type === 'form';
                        return '<span style="font-weight:500;">' + (isFormResponseField ? 'response field' : 'contact field') + ':</span> ' + field.label;
                    }
                    return '&lt;???&gt;';
                };

                ctrl.renderValueString = function (value, type) {
                    if (!value) return '';

                    if (type === Gecko.Field.TYPE_DATE) {
                        return $filter('formatDate')(value, Gecko.dateFormat.short);
                    } else if (type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
                        return geckoTranslateService.renderLanguageValue(value);
                    } else if (Array.isArray(value)) {
                        return value.join(', ');
                    }

                    return value;
                };

                ctrl.toggleExpandCollapseAll = function () {
                    var expand = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

                    ctrl.workflows.forEach(function (workflow) {
                        workflow.expanded = expand;
                    });
                };

                ctrl.isBrokenFieldAction = function (action) {
                    return isWorkflowFieldActionBroken(action, allFormAndContactFields);
                };
            }
        };
    }

    function geckoBadWorkflowAlert() {
        return {
            restrict: 'E',
            template: '\n                <div class="text-danger">\n                    <i class="fa fa-exclamation-triangle"></i>\n                    <span>{{ctrl.alertText}}</span>\n                </div>\n            ',
            scope: {
                customText: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;
                ctrl.alertText = ctrl.customText || 'Something looks broken, please review.';
            }
        };
    }

    function isWorkflowFieldActionBroken() {
        return function (action, fields) {
            var actionType = (action || {}).type || null;
            if (['set_response_value', 'set_contact_value', 'email'].includes(actionType)) {
                var fieldIds = void 0;
                if (actionType === 'email') {
                    fieldIds = (action.to || []).filter(function (item) {
                        return item.includes('field:');
                    }).map(function (fieldId) {
                        return Number(fieldId.replace('field:', ''));
                    });
                } else if (action.field_id) {
                    fieldIds = [action.field_id];
                } else {
                    fieldIds = [];
                }
                return fieldIds.some(function (fieldId) {
                    return !fields.find(function (field) {
                        return field.id === fieldId;
                    });
                });
            }
            return false;
        };
    }

    angular.module('GeckoEngage').directive('geckoWorkflow', geckoWorkflow).directive('geckoBadWorkflowAlert', geckoBadWorkflowAlert).factory('isWorkflowFieldActionBroken', isWorkflowFieldActionBroken);
})();