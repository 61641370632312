(function () {
    'use strict';

    function geckoCardHistory() {
        return {
            restrict: 'E',
            template: '<gecko-card><gecko-card-header data-title="{{ctrl.title || \'Contact Activity\'}}" icon="fa-bolt"></gecko-card-header><gecko-card-alert type="warning" ng-if="!ctrl.history.length">There is no history to show.</gecko-card-alert><gecko-card-body padded="true" ng-if="ctrl.history.length"><gecko-history history="ctrl.history"></gecko-history></gecko-card-body><gecko-pagination ng-if="ctrl.pagination && ctrl.history.length" data="ctrl.pagination"></gecko-pagination></gecko-card>',
            scope: {
                history: '=',
                title: '@',
                pagination: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                $scope.Gecko = Gecko;
                var ctrl = this;
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCardHistory', geckoCardHistory);
})();