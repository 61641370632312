(function () {
    'use strict';

    function EventsListCtrl($scope, $state, $stateParams, $filter, $geckoModal, $geckoSocket, events, integrations, categories, handleMassAction, geckoDates) {
        var ctrl = this;
        ctrl.eventsToDelete = [];
        ctrl.events = events.toInstances();
        ctrl.pagination = events.pagination;
        ctrl.integrations = integrations;
        ctrl.selectOptions = {
            noPersist: true
            /*SelectQuery: eventsQuery,
            initialTotalOptions: events.pagination.total_items*/
        };
        ctrl.categories = categories.toArray();

        $geckoSocket.watch('event', ctrl.events, ['sync_status']);

        // Table structure
        ctrl.cols = [{
            key: 'sync_status',
            renderHtml: true,
            status_title_none: true,
            expandable: false,
            icon_styles: [{
                id: 10,
                title: 'Synced',
                icon: 'fa-check-circle fa-fw synced'
            }, {
                id: 30,
                title: 'Sync error',
                icon: 'fa-exclamation-triangle fa-fw sync-error'
            }, {
                id: 40,
                title: 'Sync scheduled',
                icon: 'fa-history fa-fw syncing'
            }, {
                id: 50,
                title: 'Syncing',
                icon: 'fa-sync fa-spin fa-fw syncing'
            }, {
                id: 20,
                title: 'Can\'t sync',
                icon: 'fa-times-circle  fa-fw cant-sync'
            }, {
                id: 0,
                title: 'Not synced',
                icon: 'fa-sync fa-fw not-synced'
            }, {
                id: null,
                title: 'No integration set',
                icon: 'fa-minus-circle cant-sync fa-fw'
            }]
        }, {
            title: 'Name',
            key: 'nice_title',
            sortKey: 'title',
            colClass: 'col-xs-3'
        }, {
            title: 'Start date',
            key: 'start_datetime',
            sortKey: 'start_datetime',
            colClass: 'col-xs-3',
            render: function render(value) {
                return geckoDates.printDateString(value);
            }
        }, {
            title: 'Timezone',
            key: 'timezone'
        }, {
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Attendees',
            key: 'attendances_count'
        }];

        // Only show if has synced events
        if (ctrl.integrations.length) {
            ctrl.cols.push({
                title: 'Syncs with',
                key: 'syncs',
                sortKey: 'syncs'
            });

            // Add integration title to event rows
            ctrl.events = ctrl.events.map(function (e) {
                if (e.syncs) {
                    if (e.radius_title) {
                        e.syncs = 'Radius: ' + e.radius_title;
                    } else if (e.connect_title) {
                        e.syncs = 'Connect: ' + e.connect_title;
                    } else if (e.dynamics_title) {
                        e.syncs = 'Dynamics: ' + e.dynamics_title;
                    } else if (e.salesforce_title) {
                        e.syncs = 'Salesforce: ' + e.salesforce_title;
                    } else {
                        e.syncs = '-';
                    }
                } else {
                    e.syncs = '-';
                }
                return e;
            });
        }

        // Table row action
        ctrl.rowAction = {
            action: function action(event) {
                if (event.type === Gecko.Event.TYPE_EVENT_LEGACY) {
                    $state.go('legacy-event.overview', { event_id: event.id });
                } else {
                    $state.go('event.overview', { event_id: event.id });
                }
            }
        };

        // Import drop down
        ctrl.dropdown = {
            btnTitle: 'Import Events',
            iconRight: 'fa-angle-down'
        };
        ctrl.dropdown.items = [{
            title: 'Import from CSV',
            action: function action() {
                return $state.go('settingsimportfile', { import_to: 'events' });
            }
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View Dashboard',
            action: function action(event) {
                return $state.go('dashboardview', { event_id: event.id, override_date: 'month' });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_DASHBOARD) || !Gecko.has(Gecko.Package.FEATURE_EVENT);
            }
        }, {
            title: 'Export Attendees',
            action: function action(event) {
                $state.go('exportnew', { export_id: 'new', event_id: event.id });
            }
        }, {
            title: 'Clone Event',
            hideWhen: function hideWhen(event) {
                return event.type === Gecko.Event.TYPE_EVENT_LEGACY;
            },
            action: function action(event) {
                // Check the users permissions
                if (!Gecko.able(Gecko.ABILITY_EVENTS_CREATE)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_EVENTS_CREATE));
                }

                var num_scheduled = event.related_workflows.filter(function (workflow) {
                    return workflow.schedule !== null;
                }).length;
                var s = num_scheduled === 1 ? '' : 's';
                var message = num_scheduled > 0 ? '<h3>Workflow' + s + ' Requiring Attention</h3>\n                        <p>You are attempting to clone an <b>Event</b> that has workflows containing actions with set dates, the workflows affected will be reset to run immediately.</p>\n\n                        <p>To avoid any unintended consequences, we strongly recommend updating the dates on the affected workflows before adding attendances.</p>' : 'Are you sure you would like to clone this event?';

                GeckoUI.dialog.confirm(message, function (confirm) {
                    if (confirm) {
                        angular.copy(event).include('responses', 'attendances').clone().then(function () {
                            $state.go('events', {}, { reload: true });
                            GeckoUI.messenger.success('Event was cloned successfully');
                        }).catch(function (error) {
                            GeckoUI.messenger.error(Gecko.errorsAsArray(error.errors)[0]);
                        });
                    }
                });
            }
        }, {
            title: 'Repeat this event',
            action: function action(event) {
                // Check the users permissions
                if (!Gecko.able(Gecko.ABILITY_REPEATING_EVENT)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_REPEATING_EVENT));
                }

                var num_scheduled = event.related_workflows.filter(function (workflow) {
                    return workflow.schedule !== null;
                }).length;
                var s = num_scheduled === 1 ? '' : 's';
                var message = num_scheduled > 0 ? '<h3>Workflow' + s + ' Requiring Attention</h3>\n                        <p>You are attempting to repeat an <b>Event</b> that has workflows containing actions with set dates, the workflows affected will be reset to run immediately.</p>\n\n                        <p>To avoid any unintended consequences, we strongly recommend updating the dates on the affected workflows before adding attendances.</p>' : 'Are you sure you would like to repeat this event?';

                GeckoUI.dialog.confirm(message, function (confirm) {
                    if (confirm) {
                        $geckoModal.repeatingEventModal(event).result.then(function () {
                            return $state.go('events', {}, { reload: true });
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_REPEATING_EVENT);
            }
        }, {
            title: 'Timetravel Event',
            action: function action(event) {
                // Check the users permissions
                if (!Gecko.able(Gecko.ABILITY_EVENTS_UPDATE)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_EVENTS_UPDATE));
                }
                $geckoModal.eventTimeTravelModal(event).result.then(function (new_event) {
                    event.start_datetime = new_event.event.start_datetime;
                    event.end_datetime = new_event.event.end_datetime;
                });
            }
        }, {
            title: 'Delete Event',
            action: function action(event) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this event?', function (value) {
                    if (value) {
                        event.remove().then(function () {
                            GeckoUI.messenger.success('Your event was deleted');
                            return $state.go('events.list', {}, { reload: true });
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_DELETE);
            }
        }];

        // Add radius import option
        if (ctrl.integrations.length) {
            angular.forEach(ctrl.integrations, function (integration) {
                if (integration.sync_attendances) {
                    ctrl.dropdown.items.push({
                        title: $filter('capitalize')(integration.type) + ' Event Configuration',
                        action: function action() {
                            return $state.go('integration.events', { id: integration.id, page: 1 });
                        }
                    });
                }
            });
        }

        ctrl.filters = [{
            title: 'Event Chronology',
            type: 'checkbox',
            options: [{ label: 'Active Events', value: 'active' }, { label: 'Past Events', value: 'past' }],
            stateParam: 'filter'
        }, {
            title: 'Start Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            },
            stateParam: 'from'
        }, {
            title: 'Syncs with',
            type: 'checkbox',
            options: [{ label: 'Integration', value: true }, { label: 'Nothing', value: '0' }],
            stateParam: 'syncs'
        }, {
            title: 'Event Category',
            type: 'multi',
            optionsQuery: new Gecko.Category().rfields({ category: ['id', 'title'] }).where('type', Gecko.Category.TYPE_EVENT),
            optionsLabelKey: 'title',
            stateParam: 'category_id'
        }];

        // Actions button
        ctrl.eventsSelects = [];
        ctrl.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !ctrl.eventsSelects.length;
            }
        };

        ctrl.actionsBtn.items = [{
            title: 'Add to Category',
            action: function action() {
                return ctrl.addToCategory();
            }
        }, {
            title: 'Delete Events',
            action: function action() {
                return ctrl.deleteEvents();
            }
        }];

        ctrl.addToCategory = function () {
            $geckoModal.eventCategoryModal(ctrl.categories, Gecko.Category.TYPE_EVENT).result.then(function () {
                var categoryIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

                new Gecko.Event().massAction({ event_ids: ctrl.eventsSelects }, categoryIds).then(function (data) {
                    // Handle mass action
                    handleMassAction(data, $state.$current.name);
                    // Reset selected row
                    ctrl.eventsSelects.length = 0;
                }).catch(function (err) {
                    GeckoUI.messenger.error(err);
                });
            });
        };

        // Remove events mass action
        ctrl.deleteEvents = function () {

            if (!GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_DELETE)) return;

            if (!ctrl.eventsSelects.length) return false;
            GeckoUI.dialog.confirm('Are you sure you want to delete the selected events?', function (ok) {
                if (ok) {
                    new Gecko.Event().massAction({ event_ids: ctrl.eventsSelects }, { type: 'delete' }).then(function (data) {
                        // Handle mass action
                        handleMassAction(data, $state.$current.name);
                        // Reset selected row
                        ctrl.eventsSelects.length = 0;
                    }).catch(GeckoUI.messenger.error);
                }
            });
        };
    }

    angular.module('GeckoEngage').controller('EventsListCtrl', EventsListCtrl);
})();