(function () {
    'use strict';

    function FormSettingsCtrl($scope, $state, $formBuilderService, form, integrations, groups, geckoTranslateService) {
        var ctrl = this;
        ctrl.integrations = integrations;

        ctrl.syncable_columns = {};
        ctrl.syncable_columns[Gecko.Integration.TYPE_CONNECT] = form.connect_sync_type;
        ctrl.syncable_columns[Gecko.Integration.TYPE_SALEFORCE] = form.salesforce_sync_type;
        ctrl.syncable_columns[Gecko.Integration.TYPE_EMS] = form.ems_sync_type;
        ctrl.syncable_columns[Gecko.Integration.TYPE_NEW_DYNAMICS] = form.newdynamics_sync_type;
        ctrl.syncable_columns[Gecko.Integration.TYPE_RADIUS] = form.radius_sync_type;
        ctrl.syncable_columns[Gecko.Integration.TYPE_ENGAGE_DYNAMICS] = form.engage_dynamics_sync_type;

        var configureIntegrationSyncs = function configureIntegrationSyncs(integrations, form) {
            for (var i = 0; i < integrations.length; i++) {
                if (ctrl.syncable_columns[integrations[i].type] == 2) {
                    form.integration_syncs[integrations[i].id] = true;
                }
            }

            Object.keys(form.integration_syncs).forEach(function (key) {
                form["_integration_syncs_" + key] = form.integration_syncs[key];
            });
            return form;
        };

        ctrl.geckoChatIntegrationExists = null;

        // Get GeckoChat Channels
        ctrl.getGeckoChatChannels = function () {
            return new Gecko.BaseModel().call('/geckochat/channels', 'GET', {}, true).then(function (data) {
                ctrl.geckoChatIntegrationExists = true;

                return data;
            }).catch(function (err) {
                ctrl.geckoChatIntegrationExists = false;
            });
        };

        ctrl.getGeckoChatChannels().then(function (data) {
            ctrl.integrationFields.find(function (f) {
                return f.id === 'chat_channel_id';
            }).options = data;
        });

        ctrl.form = configureIntegrationSyncs(ctrl.integrations, form);

        // Set form group
        ctrl.form.group = ctrl.form.group ? [ctrl.form.group] : null;

        var moduleTitle = $formBuilderService.getModuleTitle(ctrl.form.module);

        var sortedRelatedFields = ctrl.form.related_fields.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });

        //Settings fields
        ctrl.settingsFields = [{
            id: 'published',
            label: 'Published Status',
            colClass: 'col-xs-12',
            description: 'An unpublished form cannot be seen by the public.',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            id: 'internal_name',
            label: 'Internal Name',
            colClass: 'col-xs-12',
            description: 'To be used if you’d like your form to have different internal/ public names.'
        }, {
            id: 'group',
            label: 'Group',
            colClass: 'col-xs-12',
            description: 'Group your forms together. For more information, click <a href="https://academy.geckoengage.com/en/articles/6884461-filtering-and-searching#h_51855c79e5">here</a>.',
            type: Gecko.Field.TYPE_MULTI,
            options: groups,
            config: {
                create: true
            },
            maxItems: 1
        }, {
            id: 'max_responses',
            label: 'Maximum Responses',
            colClass: 'col-xs-12',
            description: 'Leave blank for unlimited responses.',
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            id: 'expiry_date',
            label: 'Expiry Date',
            colClass: 'col-xs-12',
            description: 'Leave blank to keep the form open forever.',
            type: Gecko.Field.TYPE_DATETIME,
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            id: 'expiry_message',
            label: 'Expiry Message',
            colClass: 'col-xs-12',
            description: 'This message will be displayed when the form has expired. If no message is entered, the form will display the default. There is a character limit of 1000 in place.',
            type: Gecko.Field.TYPE_RICHTEXT,
            config: {
                toolbar: 'simple'
            },
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            id: 'first_field_id',
            label: 'Primary Field',
            colClass: 'col-xs-12',
            description: 'Shows on the response tab of the Capture app. We recommend setting this as full name.',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            options: sortedRelatedFields,
            optionsLabelKey: 'label',
            hideWhen: function hideWhen() {
                return !ctrl.form.uuid || ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            id: 'second_field_id',
            label: 'Secondary Field',
            colClass: 'col-xs-12',
            description: 'Shows on the response tab of the Capture app. We recommend setting this as email address.',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            options: sortedRelatedFields,
            optionsLabelKey: 'label',
            hideWhen: function hideWhen() {
                return !ctrl.form.uuid || ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            id: 'protected',
            label: 'Protect From Spam',
            colClass: 'col-xs-12',
            description: 'Use Google\'s (invisible) reCAPTCHA to prevent form submissions from web crawlers and spam bots.',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: false,
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL || !Gecko.able(Gecko.ABILITY_CAPTCHA);
            }
        }, {
            id: 'allow_draft_responses',
            label: 'Allow Draft Responses',
            colClass: 'col-xs-12',
            description: 'Allow draft responses to be saved and loaded later for completion and submission.',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: false,
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL || !Gecko.able(Gecko.ABILITY_RESPONSES_DRAFT);
            }
        }, {
            id: 'form_activity_visible_in_portal',
            label: 'Display on Portal activity feed',
            colClass: 'col-xs-12',
            description: 'When enabled form response activity will be visible on the Portal',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: false,
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL || !Gecko.able(Gecko.ABILITY_STUDENT_PORTAL);
            }
        }];

        ctrl.displayFields = [{
            id: 'success_message',
            label: 'Success Message',
            colClass: 'col-xs-12',
            description: 'The message shown when your form is completed. <code>Your request has been received, and will be dealt with as soon as possible.</code> will be displayed if this field is left blank.',
            type: Gecko.Field.TYPE_TEXTAREA
        }, {
            id: 'full_message',
            label: 'Max Responses Message',
            colClass: 'col-xs-12',
            description: 'The message displayed to a user once the form has passed it\'s maximum responses limit.',
            type: Gecko.Field.TYPE_TEXTAREA
        }, {
            id: 'show_continue_button',
            label: 'Continue Button',
            colClass: 'col-xs-12',
            description: 'Show continue button after form submission (web only).',
            type: Gecko.Field.TYPE_TOGGLE
        }, {
            id: 'language',
            label: 'Language',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            options: geckoTranslateService.languages,
            description: 'Text alerts and buttons and will be displayed in this language. This won\'t affect field labels and placeholders.'
        }];

        ctrl.designFields = [{
            id: 'header_image',
            label: 'Header Image',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_FILE,
            description: 'The header image to be used on this form - 1536px(w) x 500px(h) in <code>JPG</code>.',
            mimetype: 'image/*',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_FORMS_BRANDING_PER_PAGE);
            }
        }, {
            id: 'logo_image',
            label: 'Logo Image',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_FILE,
            description: 'The logo you wish to be used on this form - 670px(w) x 180px(h) in <code>JPG</code> or <code>PNG</code>',
            mimetype: 'image/*',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_FORMS_BRANDING_PER_PAGE);
            }
        }, {
            id: 'color',
            label: 'Background Colour',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_COLOR,
            description: 'The background colour to be used on this form.',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_FORMS_BRANDING_PER_PAGE);
            }
        }, {
            id: 'custom_css',
            label: 'Custom CSS',
            colClass: 'col-xs-12',
            description: 'For <strong>embedded</strong> web forms only. Only use if you know what you\'re doing.',
            type: Gecko.Field.TYPE_TEXTAREA
        }, {
            id: 'ignore_global_css',
            label: 'Ignore Global CSS',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_TOGGLE,
            description: 'Ignore the global CSS for this form.',
            hideWhen: function hideWhen() {
                return (Gecko.account.custom_css || '').trim() == '';
            }
        }];

        var mapped_fields = ctrl.integrations.map(function (int) {
            var listObj = {};
            listObj.id = "_integration_syncs_" + int.id;
            listObj.label = int.title;
            listObj.help_text = new moment(int.created_at * 1000).format("MMM Do YYYY");
            listObj.colClass = 'col-xs-12';
            listObj.type = Gecko.Field.TYPE_SELECT;
            listObj.noBlank = true, listObj.options = [{
                id: false,
                name: 'Do not sync automatically'
            }, {
                id: true,
                name: 'Sync automatically'
            }];
            var firstChange = true;
            listObj.onChange = function (change) {
                if (firstChange) {
                    firstChange = false;
                    return;
                }
                ctrl.form.integration_syncs[int.id] = change.value;
                ctrl.form["_integration_syncs_" + int.id];
            };
            listObj.hideWhen = function () {
                return !GeckoUI.gobk(ctrl.integrations, 'type', int.type);
            };
            return listObj;
        });

        // Append the GeckoChat integration fields
        var chat_fields = [{
            label: 'GeckoChat Integration',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12'
        }, {
            id: 'chat_channel_id',
            label: 'Channel',
            type: Gecko.Field.TYPE_SELECT,
            description: 'Select a live chat widget from your instance of Gecko Chat to be embedded onto the self hosted web form.',
            options: [],
            optionsKey: 'widget_id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return ctrl.geckoChatIntegrationExists === false;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.geckoChatIntegrationExists === null;
            },
            colClass: 'col-xs-12'
        }, {
            type: Gecko.Field.TYPE_SCRIPT,
            break: true,
            colClass: 'alert alert-info',
            description: 'GeckoEngage integration has not been set up on GeckoChat. <a href="https://app.geckochat.io/#/settings/integrations" target="_blank">Create a GeckoChat Integration</a>',
            hideWhen: function hideWhen() {
                return ctrl.geckoChatIntegrationExists === true || ctrl.geckoChatIntegrationExists === null;
            }
        }];

        // Merge all the integration fields
        ctrl.integrationFields = mapped_fields.concat(chat_fields);

        ctrl.analyticFields = [{
            id: 'gtm_id',
            label: 'Google Tag Manager ID',
            colClass: 'col-xs-12',
            description: 'For example <code ng-non-bindable>GTM-XXXXXX</code>. For more information, click <a href="https://academy.geckoengage.com/en/articles/6967267-form-analytics-tracking#h_c3009f6719">here</a>.'
        }, {
            id: 'ignore_global_gtm_id',
            label: 'Ignore Global Google Tag Manager ID',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_TOGGLE,
            description: 'Ignore the global Google Tag Manager ID for this form.',
            hideWhen: function hideWhen() {
                return ctrl.form.gtm_id || !Gecko.account.gtm_id;
            }
        }];

        //Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                ctrl.form.save().then(function () {
                    GeckoUI.messenger.success('Form saved');
                }).catch(function (err) {
                    return GeckoUI.messenger.error(err.errors);
                });
            }
        }];

        ctrl.currentTab = 'Basic Settings';
        //Tabs
        ctrl.tabs = [{
            label: 'Basic Settings',
            fields: ctrl.settingsFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            }
        }, {
            label: 'Display',
            fields: ctrl.displayFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            },
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            label: 'Redirect Rules',
            fields: [],
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            },
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            label: 'Design',
            fields: ctrl.designFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            },
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }, {
            label: 'Integrations',
            fields: ctrl.integrationFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            }
        }, {
            label: 'Analytics',
            fields: ctrl.analyticFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            },
            hideWhen: function hideWhen() {
                return ctrl.form.module === Gecko.Form.MODULE_CALL;
            }
        }];

        ctrl.setFormSyncableCols = function (form) {
            Object.keys(form).forEach(function (key) {
                if (key.indexOf('_integration_syncs_') === 0) {
                    var id = parseInt(key.replace('_integration_syncs_', ''));
                    form.integration_syncs[id] = form[key];
                }
            });
            var setSyncableCols = function setSyncableCols(type, val) {
                switch (type) {
                    case Gecko.Integration.TYPE_CONNECT:
                        form.connect_sync_type = val;
                        break;
                    case Gecko.Integration.TYPE_SALEFORCE:
                        form.salesforce_sync_type = val;
                        break;
                    case Gecko.Integration.TYPE_EMS:
                        form.ems_sync_type = val;
                        break;
                    case Gecko.Integration.TYPE_NEW_DYNAMICS:
                        form.newdynamics_sync_type = val;
                        break;
                    case Gecko.Integration.TYPE_RADIUS:
                        form.radius_sync_type = val;
                        break;
                    case Gecko.Integration.TYPE_ENGAGE_DYNAMICS:
                        form.engage_dynamics_sync_type = val;
                        break;
                    default:
                        console.log('No syncable column found for integration type ' + type);
                };
            };

            for (var i = 0; i < ctrl.integrations.length; i++) {
                if (form.integration_syncs[ctrl.integrations[i].id] === true && ctrl.integrations[i].type !== Gecko.Integration.TYPE_WEBHOOK) {
                    setSyncableCols(ctrl.integrations[i].type, 2);
                } else if (ctrl.integrations[i].type !== Gecko.Integration.TYPE_WEBHOOK) {
                    setSyncableCols(ctrl.integrations[i].type, 0);
                }
            }
            return form;
        };

        //Save form (function)
        ctrl.saveForm = function () {

            var form = angular.copy(ctrl.form);

            // Make sure form group is type string
            if (angular.isArray(form.group)) {
                form.group = form.group.length ? form.group[0] : null;
            } else if (!form.group) {
                form.group = null;
            }
            form = ctrl.setFormSyncableCols(form);

            // Check All Redirect Rules
            var redirectRules = form.success_url_conditions || [];
            var hasBrokenRedirectRules = !!redirectRules.filter(function (c) {
                return !$formBuilderService.successUrlConditionIsValid(c);
            }).length;
            if (hasBrokenRedirectRules) return GeckoUI.messenger.error('Please ensure all redirect rules have been completed.');

            form.save().then(function (form) {
                // Set uploaded image urls back to form on scope (stop double upload on multiple saves)
                ctrl.form.header_image = form.header_image;
                ctrl.form.logo_image = form.logo_image;

                GeckoUI.messenger.success(moduleTitle + ' settings updated');
                // Fire Intercom Event
                $formBuilderService.triggerIntercomEvent(ctrl.form, 'New ' + moduleTitle + ' Settings Save Success');
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                $formBuilderService.triggerIntercomEvent(ctrl.form, 'New ' + moduleTitle + ' Settings Save Error', GeckoUI.renderError(err.errors));
            });
        };

        //Save form (listener)
        $scope.$on('saveForm:' + $state.$current.name, ctrl.saveForm);
    }

    angular.module('GeckoEngage').controller('FormSettingsCtrl', FormSettingsCtrl);
})();