(function () {
    'use strict';

    function geckoCardTable() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-card-table/gecko-card-table.html',
            scope: {
                title: '@title',
                icon: '@icon',
                noHeaderTransclude: '@noHeaderTransclude',

                rows: '=rows',
                cols: '=cols',
                rowAction: '=rowAction',
                select: '=select',
                selectOptions: '=selectOptions',
                pagination: '=pagination',
                onPageChange: '=onPageChange',
                sortable: '@?',
                reorderOptions: '=?reorderOptions',
                rowOptionsBtn: '=rowOptionsBtn',
                rowNestedKey: '@?rowNestedKey',
                rowBtns: '=rowBtns',
                rowRemoveBtn: '=rowRemoveBtn',
                hideTableHeader: '@',
                hideCardHeader: '@',
                noOverflow: '@',
                helpText: '@',

                optionsBtn: '=optionsBtn',
                headerBtns: '=?headerBtns',
                footerBtns: '=footerBtns',
                actionsBtn: '=actionsBtn',

                filters: '=',

                filterBtns: '=',

                labelBtn: '=labelBtn',

                searchable: '@?',
                searchableState: '@?',
                filterBtn: '=',
                filterName: '=',
                filterActive: '=',
                filterOpen: '=',
                filterReset: '=',

                filterDate: '@?',
                filterDateFrom: '=',
                filterDateTo: '=',

                // Filterable: '@?',

                noResults: '@?'

                // FilterOptions: '@'
            },
            controller: function controller() {},
            controllerAs: 'ctrl',
            bindToController: true,
            link: function link(scope, elem, attrs, ctrl) {
                if (attrs.sortable === undefined) attrs.sortable = false;
                if (attrs.searchable === undefined) attrs.searchable = false;

                ctrl.uniqueId = scope.$id;

                attrs.$observe('title', function () {
                    if (attrs.noResults == undefined) ctrl.noResults = "There are no " + attrs.title.toLowerCase() + " to show.";
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCardTable', geckoCardTable);
})();