/*! jQuery UI - v1.10.2 - 2013-03-14
* http://jqueryui.com
* Copyright 2013 jQuery Foundation and other contributors; Licensed MIT */
(function (e, t) {
  var i = 0,
      s = Array.prototype.slice,
      n = e.cleanData;e.cleanData = function (t) {
    for (var i, s = 0; null != (i = t[s]); s++) {
      try {
        e(i).triggerHandler("remove");
      } catch (a) {}
    }n(t);
  }, e.widget = function (i, s, n) {
    var a,
        r,
        o,
        h,
        l = {},
        u = i.split(".")[0];i = i.split(".")[1], a = u + "-" + i, n || (n = s, s = e.Widget), e.expr[":"][a.toLowerCase()] = function (t) {
      return !!e.data(t, a);
    }, e[u] = e[u] || {}, r = e[u][i], o = e[u][i] = function (e, i) {
      return this._createWidget ? (arguments.length && this._createWidget(e, i), t) : new o(e, i);
    }, e.extend(o, r, { version: n.version, _proto: e.extend({}, n), _childConstructors: [] }), h = new s(), h.options = e.widget.extend({}, h.options), e.each(n, function (i, n) {
      return e.isFunction(n) ? (l[i] = function () {
        var e = function e() {
          return s.prototype[i].apply(this, arguments);
        },
            t = function t(e) {
          return s.prototype[i].apply(this, e);
        };return function () {
          var i,
              s = this._super,
              a = this._superApply;return this._super = e, this._superApply = t, i = n.apply(this, arguments), this._super = s, this._superApply = a, i;
        };
      }(), t) : (l[i] = n, t);
    }), o.prototype = e.widget.extend(h, { widgetEventPrefix: r ? h.widgetEventPrefix : i }, l, { constructor: o, namespace: u, widgetName: i, widgetFullName: a }), r ? (e.each(r._childConstructors, function (t, i) {
      var s = i.prototype;e.widget(s.namespace + "." + s.widgetName, o, i._proto);
    }), delete r._childConstructors) : s._childConstructors.push(o), e.widget.bridge(i, o);
  }, e.widget.extend = function (i) {
    for (var n, a, r = s.call(arguments, 1), o = 0, h = r.length; h > o; o++) {
      for (n in r[o]) {
        a = r[o][n], r[o].hasOwnProperty(n) && a !== t && (i[n] = e.isPlainObject(a) ? e.isPlainObject(i[n]) ? e.widget.extend({}, i[n], a) : e.widget.extend({}, a) : a);
      }
    }return i;
  }, e.widget.bridge = function (i, n) {
    var a = n.prototype.widgetFullName || i;e.fn[i] = function (r) {
      var o = "string" == typeof r,
          h = s.call(arguments, 1),
          l = this;return r = !o && h.length ? e.widget.extend.apply(null, [r].concat(h)) : r, o ? this.each(function () {
        var s,
            n = e.data(this, a);return n ? e.isFunction(n[r]) && "_" !== r.charAt(0) ? (s = n[r].apply(n, h), s !== n && s !== t ? (l = s && s.jquery ? l.pushStack(s.get()) : s, !1) : t) : e.error("no such method '" + r + "' for " + i + " widget instance") : e.error("cannot call methods on " + i + " prior to initialization; " + "attempted to call method '" + r + "'");
      }) : this.each(function () {
        var t = e.data(this, a);t ? t.option(r || {})._init() : e.data(this, a, new n(r, this));
      }), l;
    };
  }, e.Widget = function () {}, e.Widget._childConstructors = [], e.Widget.prototype = { widgetName: "widget", widgetEventPrefix: "", defaultElement: "<div>", options: { disabled: !1, create: null }, _createWidget: function _createWidget(t, s) {
      s = e(s || this.defaultElement || this)[0], this.element = e(s), this.uuid = i++, this.eventNamespace = "." + this.widgetName + this.uuid, this.options = e.widget.extend({}, this.options, this._getCreateOptions(), t), this.bindings = e(), this.hoverable = e(), this.focusable = e(), s !== this && (e.data(s, this.widgetFullName, this), this._on(!0, this.element, { remove: function remove(e) {
          e.target === s && this.destroy();
        } }), this.document = e(s.style ? s.ownerDocument : s.document || s), this.window = e(this.document[0].defaultView || this.document[0].parentWindow)), this._create(), this._trigger("create", null, this._getCreateEventData()), this._init();
    }, _getCreateOptions: e.noop, _getCreateEventData: e.noop, _create: e.noop, _init: e.noop, destroy: function destroy() {
      this._destroy(), this.element.unbind(this.eventNamespace).removeData(this.widgetName).removeData(this.widgetFullName).removeData(e.camelCase(this.widgetFullName)), this.widget().unbind(this.eventNamespace).removeAttr("aria-disabled").removeClass(this.widgetFullName + "-disabled " + "ui-state-disabled"), this.bindings.unbind(this.eventNamespace), this.hoverable.removeClass("ui-state-hover"), this.focusable.removeClass("ui-state-focus");
    }, _destroy: e.noop, widget: function widget() {
      return this.element;
    }, option: function option(i, s) {
      var n,
          a,
          r,
          o = i;if (0 === arguments.length) return e.widget.extend({}, this.options);if ("string" == typeof i) if (o = {}, n = i.split("."), i = n.shift(), n.length) {
        for (a = o[i] = e.widget.extend({}, this.options[i]), r = 0; n.length - 1 > r; r++) {
          a[n[r]] = a[n[r]] || {}, a = a[n[r]];
        }if (i = n.pop(), s === t) return a[i] === t ? null : a[i];a[i] = s;
      } else {
        if (s === t) return this.options[i] === t ? null : this.options[i];o[i] = s;
      }return this._setOptions(o), this;
    }, _setOptions: function _setOptions(e) {
      var t;for (t in e) {
        this._setOption(t, e[t]);
      }return this;
    }, _setOption: function _setOption(e, t) {
      return this.options[e] = t, "disabled" === e && (this.widget().toggleClass(this.widgetFullName + "-disabled ui-state-disabled", !!t).attr("aria-disabled", t), this.hoverable.removeClass("ui-state-hover"), this.focusable.removeClass("ui-state-focus")), this;
    }, enable: function enable() {
      return this._setOption("disabled", !1);
    }, disable: function disable() {
      return this._setOption("disabled", !0);
    }, _on: function _on(i, s, n) {
      var a,
          r = this;"boolean" != typeof i && (n = s, s = i, i = !1), n ? (s = a = e(s), this.bindings = this.bindings.add(s)) : (n = s, s = this.element, a = this.widget()), e.each(n, function (n, o) {
        function h() {
          return i || r.options.disabled !== !0 && !e(this).hasClass("ui-state-disabled") ? ("string" == typeof o ? r[o] : o).apply(r, arguments) : t;
        }"string" != typeof o && (h.guid = o.guid = o.guid || h.guid || e.guid++);var l = n.match(/^(\w+)\s*(.*)$/),
            u = l[1] + r.eventNamespace,
            c = l[2];c ? a.delegate(c, u, h) : s.bind(u, h);
      });
    }, _off: function _off(e, t) {
      t = (t || "").split(" ").join(this.eventNamespace + " ") + this.eventNamespace, e.unbind(t).undelegate(t);
    }, _delay: function _delay(e, t) {
      function i() {
        return ("string" == typeof e ? s[e] : e).apply(s, arguments);
      }var s = this;return setTimeout(i, t || 0);
    }, _hoverable: function _hoverable(t) {
      this.hoverable = this.hoverable.add(t), this._on(t, { mouseenter: function mouseenter(t) {
          e(t.currentTarget).addClass("ui-state-hover");
        }, mouseleave: function mouseleave(t) {
          e(t.currentTarget).removeClass("ui-state-hover");
        } });
    }, _focusable: function _focusable(t) {
      this.focusable = this.focusable.add(t), this._on(t, { focusin: function focusin(t) {
          e(t.currentTarget).addClass("ui-state-focus");
        }, focusout: function focusout(t) {
          e(t.currentTarget).removeClass("ui-state-focus");
        } });
    }, _trigger: function _trigger(t, i, s) {
      var n,
          a,
          r = this.options[t];if (s = s || {}, i = e.Event(i), i.type = (t === this.widgetEventPrefix ? t : this.widgetEventPrefix + t).toLowerCase(), i.target = this.element[0], a = i.originalEvent) for (n in a) {
        n in i || (i[n] = a[n]);
      }return this.element.trigger(i, s), !(e.isFunction(r) && r.apply(this.element[0], [i].concat(s)) === !1 || i.isDefaultPrevented());
    } }, e.each({ show: "fadeIn", hide: "fadeOut" }, function (t, i) {
    e.Widget.prototype["_" + t] = function (s, n, a) {
      "string" == typeof n && (n = { effect: n });var r,
          o = n ? n === !0 || "number" == typeof n ? i : n.effect || i : t;n = n || {}, "number" == typeof n && (n = { duration: n }), r = !e.isEmptyObject(n), n.complete = a, n.delay && s.delay(n.delay), r && e.effects && e.effects.effect[o] ? s[t](n) : o !== t && s[o] ? s[o](n.duration, n.easing, a) : s.queue(function (i) {
        e(this)[t](), a && a.call(s[0]), i();
      });
    };
  });
})(jQuery);