(function () {
    'use strict';

    function UserCtrl(Gecko, $scope, $state, $stateParams, user) {
        var ctrl = this;
        ctrl.user = user;

        // Go to overview
        if ($state.$current.name == 'user') $state.go('user.settings');

        // Tabs
        ctrl.tabs = [{
            title: 'Settings',
            state: 'user.settings'
        }, {
            title: 'Chat Settings',
            state: 'user.chat-settings',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_NEW_ENGAGE_WEB_APP) || !Gecko.has(Gecko.Package.FEATURE_CHAT);
            }
        }, {
            title: 'Forms',
            state: 'user.forms'
        }, {
            title: 'Device Logins',
            state: 'user.sessions',
            hideWhen: function hideWhen() {
                return !(Gecko.user && Gecko.user.id === ctrl.user.id) || Gecko.useNewAuth();
            }
        }];

        // Stat card options
        ctrl.optionsBtn = {
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_USER);
            }
        };
        ctrl.optionsBtn.items = [{
            title: 'Restore user',
            action: function action() {
                return ctrl.restoreUser();
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_USERS_CREATE) || !ctrl.user.deleted_at;
            }
        }, {
            title: 'Archive user',
            action: function action() {
                return ctrl.removeUser();
            },
            hideWhen: function hideWhen() {
                return ctrl.user.id && ctrl.user.id == Gecko.user.id || ctrl.user.deleted_at;
            }
        }, {
            title: 'Re-activate',
            action: function action() {
                return ctrl.activateUser();
            },
            hideWhen: function hideWhen() {
                return ctrl.user.id && ctrl.user.id == Gecko.user.id || ctrl.user.presence !== Gecko.User.PRESENCE_DEACTIVATED;
            }
        }];

        // Save campaign
        ctrl.saveUser = function () {
            $scope.$broadcast('saveUser');
        };

        ctrl.saveBtn = function () {
            return {
                preset: 'save',
                title: 'Save changes'
            };
        };

        ctrl.activateUser = function () {
            if (!GeckoUI.ableWithReason(Gecko.ABILITY_USERS_CREATE)) return;
            ctrl.user.presence = Gecko.User.PRESENCE_OFFLINE;
            ctrl.user.save().then(function () {
                $state.transitionTo('users');
                GeckoUI.messenger.success('User has been re-activated');
            }).catch(GeckoUI.messenger.error);
        };

        ctrl.resendInvite = function () {
            ctrl.user.resendInvitation().then(function () {
                // Success message
                GeckoUI.messenger.success('The invitation has been resent to this user');
                // Refresh the page
                return $state.go($state.$current.name, $stateParams, { reload: true });
            }).catch(function (err) {
                return GeckoUI.messenger.error(err);
            });
        };

        ctrl.restoreUser = function () {
            ctrl.user.restore().then(function () {
                GeckoUI.messenger.success('User has been restored');
                return $state.go($state.$current.name, $stateParams, { reload: true });
            }).catch(GeckoUI.messenger.error);
        };

        ctrl.removeUser = function () {
            if (!GeckoUI.ableWithReason(Gecko.ABILITY_USERS_DELETE)) return;
            GeckoUI.dialog.confirm('Are you sure you want to archive this user?', function (value) {
                if (value === true) {
                    ctrl.user.remove().then(function () {
                        $state.transitionTo('users');
                        GeckoUI.messenger.success('User has been archived');
                    }).catch(GeckoUI.messenger.error);
                }
            });
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                return $state.go('settings');
            }
        }, {
            label: 'Users',
            click: function click() {
                return $state.go('users');
            }
        }, {
            label: ctrl.user.id ? ctrl.user.full_name : 'Add User',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('UserCtrl', UserCtrl);
})();