(function () {
    'use strict';

    // Default modal config

    angular.module("GeckoEngage").config(function ($modalProvider) {
        $modalProvider.options = {
            backdrop: true,
            keyboard: true,
            size: 'md',
            animation: true
        };
    });

    function $geckoModal() {

        var _modals = {};

        this.modalAdd = function (key, config) {
            _modals[key] = config;
            return this;
        };

        this.$get = function ($modal, $rootScope) {

            // Process all the modal configurations wrapping them in a call to open the modal.
            return Object.keys(_modals).reduce(function (newObj, key) {
                newObj[key] = function () {
                    //Open modal
                    var _modalConfig = _modals[key].apply(_modals, arguments);
                    var _modal = $modal.open(_modalConfig);
                    // Fallback for when not using '.result'
                    _modal.result.then(function (value) {
                        return value;
                    });

                    // Dismiss modal when state changes
                    if (!_modalConfig.disableStateChangeDismiss) {
                        var closeOnStateChangeFn = $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                            if (toState !== fromState) _modal.dismiss();
                            closeOnStateChangeFn();
                        });
                    }

                    return _modal;
                };

                return newObj;
            }, {});
        };
    }

    angular.module('GeckoEngage').provider('$geckoModal', $geckoModal);
})();