(function () {

    'use strict';

    function EventsRoute($stateProvider) {
        $stateProvider.state('events', {
            url: '/events',
            templateUrl: "/modules/events/views/events.8f2ac5c6.html",
            controller: 'EventsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Events'
        }).state('events.list', {
            url: "/list?{page}&{filter}&{search}&{order}&{sort}&{synced}&{syncs}&{category_id}&{from}&{to}",
            templateUrl: "/modules/events/views/index.e4d10128.html",
            controller: 'EventsListCtrl',
            controllerAs: 'ctrl',
            page_title: 'Events List',
            requires: [Gecko.ABILITY_EVENTS_VIEW],
            resolve: {
                eventsQuery: function eventsQuery($stateParams, Gecko) {
                    return function () {
                        var events = new Gecko.Event().includeCount('responses', 'attendances').include('categories', 'workflows').where('type', [Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_EVENT_LEGACY]);

                        if ($stateParams.order) {
                            if ($stateParams.order === 'title') {
                                events.orderBy({
                                    internal_title: $stateParams.sort ? $stateParams.sort : 'asc',
                                    title: $stateParams.sort ? $stateParams.sort : 'asc'
                                });
                            } else {
                                events.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                            }
                        } else {
                            events.orderBy('created_at');
                        }

                        // Filter by event_status
                        if ($stateParams.filter) events.where('event_status', $stateParams.filter);

                        // Filter by synced
                        if ($stateParams.synced) events.where('synced', $stateParams.synced);

                        // Filter by syncs
                        if ($stateParams.syncs) events.where('syncs', $stateParams.syncs);

                        // Filter by category_id
                        if ($stateParams.category_id) events.where('category_id', $stateParams.category_id);

                        // Date Filtering
                        if ($stateParams.from || $stateParams.to) {
                            events.where('start_after', $stateParams.from).where('start_before', $stateParams.to);
                        }

                        // Search by keyword
                        if ($stateParams.search) events.search($stateParams.search);
                        return events.page($stateParams.page).perPage(GeckoUI.getPageLimit(this.self.name)).rfields({ event: ['sync_status', 'type', 'title', 'start_datetime', 'end_datetime', 'timezone', 'syncs', 'radius_title', 'connect_title', 'dynamics_title', 'salesforce_title', 'nice_title'], workflow: ['schedule'] });
                    }.bind(this);
                },
                data: function data(Gecko, eventsQuery) {
                    var _query = {};

                    // Events
                    _query.events = eventsQuery();

                    _query.categories = new Gecko.Category().rfields({ category: ['title'] }).where('type', 'event').perPage('all');

                    return Gecko.multi(_query);
                },
                storedData: function storedData(geckoDataService) {
                    return geckoDataService.fetch(['integrations']);
                },
                events: function events(data) {
                    return data.events;
                },
                integrations: function integrations(storedData) {
                    return storedData.integrations;
                },
                categories: function categories(data) {
                    return data.categories;
                }
            }
        }).state('events.share', {
            url: "/share",
            templateUrl: "/modules/events/views/share.18c6239b.html",
            controller: 'EventsShareCtrl',
            controllerAs: 'ctrl',
            page_title: 'Share an Event',
            requires: [Gecko.ABILITY_EVENTS_SHARE_TAB],
            resolve: {
                data: function data(Gecko) {
                    var _query = {};

                    // Events
                    _query.events = new Gecko.Event().rfields({ event: ['nice_title', 'start_datetime', 'nice_title'] }).where('type', [Gecko.Event.TYPE_EVENT]).orderBy('start_datetime').where('event_status', 'active').perPage(1000);

                    // Categories
                    _query.categories = new Gecko.Category().rfields({ category: ['title'] }).where('type', 'event').perPage(10000);

                    return Gecko.multi(_query);
                },
                forms: function forms() {
                    return new Gecko.Form().rfields({ form: ['name', 'internal_name', 'uuid'] }).perPage('all').call('/forms', 'GET', { contains_fields: 'event' }, false);
                },
                events: function events(data) {
                    return data.events;
                },
                categories: function categories(data) {
                    return data.categories;
                }
            }
        }).state('events.deleted', {
            url: '/deleted?{page}&{order}&{sort}',
            templateUrl: "/modules/events/views/deleted.c294bf13.html",
            controller: 'EventsDeletedCtrl',
            controllerAs: 'ctrl',
            resolve: {
                deleted: function deleted($stateParams, Gecko) {
                    var orderBy;
                    var sort = $stateParams.sort ? $stateParams.sort : 'desc';

                    if ($stateParams.order) {
                        orderBy = $stateParams.order === 'title' ? 'internal_title|' + sort + ',title|' + sort : $stateParams.order + '|' + sort;
                    } else {
                        orderBy = 'deleted_at|' + sort;
                    }

                    return new Gecko.Event().call('/events', 'GET', {
                        event_rfields: ['id', 'nice_title', 'deleted_at', 'timezone', 'start_datetime', 'days_remaining'],
                        trashed: 'only',
                        counts: 'deleted_attendances',
                        per_page: 15, // GeckoUI.getPageLimit(this.self.name),
                        page: $stateParams.page ? $stateParams.page : 1,
                        order_by: orderBy,
                        scope: 'recently_deleted'
                    });
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(EventsRoute);
})();