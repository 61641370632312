(function () {
    'use strict';

    function DataSecurityDeletedContactsCtrl(contacts, $state, $stateParams, handleMassAction) {
        var ctrl = this;
        ctrl.contacts = contacts.toInstances();
        ctrl.pagination = contacts.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Contact',
            key: 'full_name',
            sortKey: 'full_name',
            colClass: 'col-xs-6'
        }, {
            title: 'Email',
            key: 'email',
            sortKey: 'email',
            colClass: 'col-xs-6'
        }, {
            title: 'Type',
            key: 'delete_reason',
            type: Gecko.Field.TYPE_STATUS,
            render: function render(value) {
                return (Gecko.deleted_titles.find(function (t) {
                    return t.id === value;
                }) || {}).title;
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Deleted at',
            key: 'deleted_at',
            sortKey: 'deleted_at'
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Restore contact',
            action: function action(contact) {
                contact.restore().then(function () {
                    $state.go($state.$current.name, $stateParams, { reload: true });
                    GeckoUI.messenger.success('Contact restored');
                }).catch(function (err) {
                    return GeckoUI.messenger.error(err);
                });
            }
        }];

        ctrl.filters = [{
            title: 'Type',
            type: 'checkbox',
            options: Gecko.deleted_titles.map(function (t) {
                return { label: t.title, value: t.id };
            }),
            stateParam: 'type'
        }];

        // Mass action
        ctrl.contactSelects = [];
        ctrl.selectOptions = {
            noPersist: true
        };
        ctrl.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !ctrl.contactSelects || ctrl.contactSelects && !ctrl.contactSelects.length;
            }
        };
        ctrl.actionsBtn.items = [{
            title: 'Restore contact(s)',
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return;

                if (!ctrl.contactSelects.length) return false;

                return new Gecko.Contact().massAction({ model: 'contact_ids', contact_ids: ctrl.contactSelects }, { type: 'restore' }).then(function (response) {
                    // Handle the mass action
                    handleMassAction(response, $state.$current.name);
                    // Clear the mass action checkboxes
                    ctrl.contactSelects.length = 0;
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.message);
                });
            }
        }];
    }

    angular.module('GeckoEngage').controller('DataSecurityDeletedContactsCtrl', DataSecurityDeletedContactsCtrl);
})();