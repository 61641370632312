(function () {
    'use strict';

    function geckoIcon($templateRequest, $parse) {
        return {
            restrict: 'E',

            link: function link(scope, iElement, iAttrs) {

                var name = $parse(iAttrs.name)(scope);

                $templateRequest(name).then(function (template) {

                    iElement.html(template);

                    var titleElement = iElement.find('title');
                    if (iAttrs.desc && titleElement.length) {
                        titleElement.text(iAttrs.desc);
                        iAttrs.$observe('desc', function (value) {

                            titleElement.text(value);
                        });
                    }
                }).catch(function (err) {
                    console.log(err); // eslint-disable-line no-console
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoIcon', geckoIcon);
})();