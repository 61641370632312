(function () {

    function NotificationModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('notificationAddModal', function () {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/modules/notifications/views/add-modal.html',
                controller: 'notificationModalCtrl'
            };
        });
    }

    function notificationModalCtrl($modalInstance, $state) {
        var ctrl = this;
        ctrl.notification = new Gecko.Campaign();
        ctrl.notification.module = Gecko.Campaign.MODULE_READER;
        ctrl.notification.dnc_period = 0;
        ctrl.notification.title = "Push Notification";

        ctrl.fields = [{
            id: 'message',
            label: 'Message',
            type: Gecko.Field.TYPE_TEXTAREA,
            colClass: 'none'
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Send',
            icon: 'fa-paper-plane',
            btnClass: 'btn-primary',
            action: function action() {
                ctrl.notification.save().then(function () {
                    $state.go($state.current, {}, { reload: true });
                    GeckoUI.messenger.success('Notification sent.');
                    $modalInstance.dismiss();
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    $modalInstance.dismiss();
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(NotificationModals).controller('notificationModalCtrl', notificationModalCtrl);
})();