(function () {
    'use strict';

    function CategoriesRoute($stateProvider) {
        $stateProvider.state('settingscategories', {
            url: "/settings/categories?{page}&{sort}&{order}&{search}&{type}",
            templateUrl: "/modules/categories/views/index.fb13ad82.html",
            controller: 'SettingsCategoriesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Categories',
            redirectKey: 'module_categories',
            resolve: {
                categories: function categories($stateParams, Gecko) {
                    var categories = new Gecko.Category().include('user');
                    if ($stateParams.search) categories.where('keyword', $stateParams.search);
                    if ($stateParams.type) categories.where('type', $stateParams.type);
                    if ($stateParams.order) {
                        categories.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    } else {
                        categories.orderBy('title');
                    }
                    return categories.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                }
            }
        }).state('settingscategory', {
            url: "/settings/categories/{category_id}",
            templateUrl: "/modules/categories/views/edit.4e51f218.html",
            controller: 'SettingsCategoryCtrl',
            controllerAs: 'ctrl',
            page_title: 'Category',
            redirectKey: 'module_category',
            resolve: {
                category: function category($stateParams, Gecko) {
                    if ($stateParams.category_id != 'new') {
                        return new Gecko.Category().include('events:10000').rfields({ event: ['title'] }).get($stateParams.category_id);
                    } else {
                        return new Gecko.Category();
                    }
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(CategoriesRoute);
})();