(function () {
    'use strict';

    function TemplateCtrl($scope, $state, $stateParams, LoadingIndicator, unsavedAlert, template, templateService, $geckoModal) {
        var ctrl = this;
        ctrl.template = template;

        // Go to settings
        if ($state.$current.name === 'settingstemplate') $state.go('settingstemplate.settings');

        // Tabs
        ctrl.tabs = [{
            title: 'Editor',
            state: 'settingstemplate.editor',
            hideWhen: function hideWhen() {
                return !ctrl.template.id;
            }
        }, {
            title: 'Settings',
            state: 'settingstemplate.settings'
        }, {
            title: 'Stats',
            state: 'settingstemplate.stats',
            hideWhen: function hideWhen() {
                if (!Gecko.able(Gecko.ABILITY_MESSAGE_STATS)) return true;
                return !ctrl.template.id;
            }
        }, {
            title: 'Usage',
            state: 'settingstemplate.usage',
            hideWhen: function hideWhen() {
                return !ctrl.template.id;
            }
        }];

        // Stat card options
        ctrl.optionsBtn = {
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary'
        };
        ctrl.optionsBtn.items = [{
            title: 'New template',
            action: function action() {
                $state.go('settingstemplate.settings', { template_id: 'new' });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_CREATE);
            }
        }, {
            title: 'Send test email',
            action: function action() {
                $geckoModal.templateSendTestModal().result.then(function (email) {
                    if (email) {
                        LoadingIndicator.resolve(ctrl.template.test(email)).then(function (template) {
                            GeckoUI.messenger.success('Test email was sent successfully');
                            $state.go($state.$current.name, $stateParams);
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_VIEW) || ctrl.template.type !== Gecko.Template.TYPE_EMAIL;
            }
        }, {
            title: 'Clone template',
            action: function action() {
                unsavedAlert.clear();
                ctrl.template.clone().then(function (template) {
                    GeckoUI.messenger.success('Template was cloned successfully');
                    $state.go('settingstemplate', { template_id: template.id });
                }).catch(GeckoUI.messenger.error);
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_CREATE);
            }
        }, {
            title: 'Download template',
            action: function action() {
                ctrl.downloadBtn();
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_DRAGDROP) || ctrl.template.editor_type != Gecko.Template.EDITOR_TYPE_DRAGDROP;
            }
        }, {
            title: 'Delete template',
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this template?', function (value) {
                    if (value) {
                        unsavedAlert.clear();
                        ctrl.template.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Template has been deleted');
                            $state.go('settingstemplates');
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_DELETE);
            }
        }];

        // Drag Drop Btns for Options dropdown
        ctrl.downloadBtn = function () {
            $scope.$broadcast('download');
        };

        // Save template
        ctrl.saveTemplate = function () {
            $scope.$broadcast('saveTemplate');
        };
        // Listen for CMD + S event
        $scope.$on('quickSave:template', ctrl.saveTemplate);

        ctrl.showSaveTemplateBtn = function () {
            return ['settingstemplate.stats'].indexOf($state.$current.name) === -1;
        };

        // Drag Drop Btns for Preview dropdown
        ctrl.toggleStructureBtn = function () {
            $scope.$broadcast('toggleStructure');
            // Defines if structure is toggled on or off to display alternative hide/show text
            if (ctrl.template.structureSet === false || ctrl.template.structureSet === undefined) {
                ctrl.template.structureSet = true;
                ctrl.previewTemplateBtn.items.find(function (item) {
                    return item.id === 'toggleStructure';
                }).title = "Hide structure";
            } else {
                ctrl.template.structureSet = false;
                ctrl.previewTemplateBtn.items.find(function (item) {
                    return item.id === 'toggleStructure';
                }).title = "Show structure";
            }
        };
        ctrl.previewBtn = function () {
            $scope.$broadcast('preview');
        };
        ctrl.sendBtn = function () {
            $scope.$broadcast('send');
        };

        // Drag Drop Btns Preview dropdown card options
        ctrl.previewTemplateBtn = {
            iconLeft: 'fa-eye',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-default',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_TEMPLATES_DRAGDROP) || ctrl.template.editor_type != Gecko.Template.EDITOR_TYPE_DRAGDROP;
            }
        };
        ctrl.previewTemplateBtn.items = [{
            id: 'toggleStructure',
            title: 'Show structure',
            action: function action() {
                ctrl.toggleStructureBtn();
            }
        }, {
            title: 'Preview mode',
            action: function action() {
                ctrl.previewBtn();
            }
            // hidden until funcationality finished
            // }, {
            //     title   : 'Send Test',
            //     action  : function() {
            //         ctrl.sendBtn();
            //     },
        }];

        ctrl.showPreviewTemplateBtn = function () {
            return ['settingstemplate.editor'].indexOf($state.$current.name) === 0;
        };

        // Returns false if DragDrop editor and on Editor page
        ctrl.showHeaderDetails = function () {
            var editorPage = ['settingstemplate.editor'].indexOf($state.$current.name) === 0 ? true : false;
            var editorType = ctrl.template.editor_type === Gecko.Template.EDITOR_TYPE_DRAGDROP ? true : false;
            if (editorPage && editorType) {
                return false;
            } else {
                return true;
            }
            return true;
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Templates',
            click: function click() {
                $state.go('settingstemplates');
            }
        }, {
            label: ctrl.template.name ? ctrl.template.name : 'Add Template',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('TemplateCtrl', TemplateCtrl);
})();