(function () {
    'use strict';

    var generateCampaignBroadcast = function generateCampaignBroadcast($state, $stateParams, ctrl, campaign, vars) {
        // Status Titles
        var _statTitles = {};
        _statTitles[Gecko.Subscriber.SCHEDULED] = 'Scheduled';
        _statTitles[Gecko.Subscriber.AVAILABLE] = 'Scheduled';
        _statTitles[Gecko.Subscriber.IN_PROGRESS] = 'In Progress';
        _statTitles[Gecko.Subscriber.SUCCESS] = 'Succeeded';
        _statTitles[Gecko.Subscriber.FAIL] = 'Failed';
        _statTitles[Gecko.Subscriber.DATA_PROBLEM] = Gecko.Subscriber.status_titles.find(function (s) {
            return s.id === Gecko.Subscriber.DATA_PROBLEM;
        }).title;

        // Include these statuses in overview stat block
        var statusTypes = [Gecko.Subscriber.AVAILABLE, Gecko.Subscriber.IN_PROGRESS, Gecko.Subscriber.SUCCESS, Gecko.Subscriber.FAIL, Gecko.Subscriber.DATA_PROBLEM];

        var excludedStatusTypes = [Gecko.Subscriber.DATA_PROBLEM, Gecko.Subscriber.REMOVED, Gecko.Subscriber.NO_CONSENT];
        // Module specific exluded statuses
        if (ctrl.broadcast.module === Gecko.Campaign.MODULE_EMAIL) {
            excludedStatusTypes.push(Gecko.Subscriber.NO_EMAIL);
        } else if (ctrl.broadcast.module === Gecko.Campaign.MODULE_SMS) {
            excludedStatusTypes.push(Gecko.Subscriber.NO_PHONE_NUMBER);
            excludedStatusTypes.push(Gecko.Subscriber.UNUSEABLE_NUMBER);
        }

        // Build Stats
        ctrl.stats = statusTypes.map(function (status, index) {
            var _stat;

            // Build overall 'Excluded' total
            if (status === Gecko.Subscriber.DATA_PROBLEM) {
                _stat = {
                    aggregate: 0,
                    status: status
                };

                // Find macthing stat result and add to overall aggregate
                var results = Array.isArray(ctrl.stat.result) ? ctrl.stat.result : [];
                excludedStatusTypes.forEach(function (s) {
                    var excludedStat = results.find(function (r) {
                        return r.status === s;
                    });
                    if (excludedStat && excludedStat.aggregate) _stat.aggregate += excludedStat.aggregate || 0;
                });

                // Add stat action
                _stat.action = function () {
                    return $state.go('broadcast.overview', { broadcast_id: ctrl.broadcast.id, status: excludedStatusTypes });
                };
                // Only show active if ALL status are in the excludedStatuses array
                _stat.activeWhen = function (stat) {
                    if (Array.isArray($stateParams.status)) {
                        return $stateParams.status.filter(function (s) {
                            return excludedStatusTypes.indexOf(Number(s)) != -1;
                        }).length === excludedStatusTypes.length;
                    }
                    return false;
                };
            } else {
                // Get stat
                _stat = GeckoUI.getObjectByKey(ctrl.stat.result, 'status', status) || { aggregate: 0, status: status };
                if (!_stat) {
                    _stat = {
                        aggregate: 0,
                        status: status
                    };
                }

                _stat.action = function () {
                    return $state.go('broadcast.overview', { broadcast_id: ctrl.broadcast.id, status: _stat.status });
                };
                _stat.activeWhen = function (stat) {
                    return stat.status == $stateParams.status;
                };
            }

            // Add data
            _stat.order = index;
            _stat.title = _statTitles[status];
            // Push to the return array
            return _stat;
        });

        // Table structure
        ctrl.cols = [{
            key: 'status',
            expandable: true,
            status_title_none: true,
            status_styles: Gecko.Subscriber.status_titles,
            colClass: 'text-center'
            // render            : function(value, col, subscriber) {
            //     if (subscriber && subscriber.status == Gecko.Subscriber.SCHEDULED && subscriber.call_at) {
            //         return 'Scheduled for ' + $filter('formatDate')(subscriber.call_at, Gecko.dateFormat.shortTime);
            //     }
            //     return false;
            // },
        }];

        // Add list view fields as columns
        // ctrl.cols = ctrl.fieldList.map(function(field, index) {
        //     return {
        //         type          : field.type,
        //         data_type     : field.data_type,
        //         default_value : field.label.indexOf('Last') !== -1 && field.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP ? 'Never' : '',
        //         title         : field.label,
        //         key           : 'field_' + (index + 1),
        //         sortKey       : 'field_' + (index + 1),
        //         colClass      : 'col-md-3',
        //     }
        // });
        var _campaign_fields = ctrl.broadcast.related_campaign_fields;
        angular.forEach(_campaign_fields, function (field, index) {
            ctrl.cols.push({
                type: field.related_field.type,
                data_type: field.related_field.data_type,
                default_value: field.related_field.label.indexOf('Last') > -1 && field.related_field.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP ? 'Never' : '',
                title: field.related_field.label,
                key: 'field_' + (index + 1),
                sortKey: 'contact.' + field.related_field.id,
                colClass: 12 % _campaign_fields.length == 0 ? 'col-md-' + 12 / _campaign_fields.length : '',
                utc: !field.system && field.type == Gecko.Field.TYPE_DATE,
                date_format: !field.system && field.type == Gecko.Field.TYPE_DATE ? Gecko.dateFormat.short : undefined
            });
        });

        ctrl.rowAction = {
            state: 'contact.subscriber',
            params: { contact_id: 'contact_id', subscriber_id: 'id' }
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen(subscriber) {
                return [Gecko.Subscriber.SUCCESS, Gecko.Subscriber.IN_PROGRESS, Gecko.Subscriber.FAIL, Gecko.Subscriber.REMOVED].indexOf(subscriber.status) === -1 || [Gecko.Campaign.RUNNING, Gecko.Campaign.COMPLETE, Gecko.Campaign.CANCELLED].indexOf(ctrl.broadcast.status) === -1;
            }
        };

        ctrl.rowOptionsBtn.items = [{
            title: 'Remove from Broadcast',
            action: function action(subscriber) {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_BROADCASTS_UPDATE)) {
                    return;
                }
                GeckoUI.dialog.confirm('Are you sure you want to remove this contact from this broadcast?', function (value) {
                    if (value) {
                        angular.copy(subscriber).remove().then(function () {
                            // Mark subscriber as removed
                            GeckoUI.messenger.success('Contact removed from broadcast');
                            $state.go($state.$current.name, $stateParams, { reload: true });
                        });
                    }
                });
            }
        }];

        // Table filters
        var objectsToOptions = function objectsToOptions(labelKey) {
            return function (objects) {
                return objects.map(function (o) {
                    return { value: o.id, label: o[labelKey] };
                });
            };
        };
        var statuses = angular.copy(Gecko.Subscriber.status_titles).filter(function (s) {
            return s[ctrl.broadcast.module] === 1;
        });
        // Rename 'excluded' again...to make it less confusing
        statuses.find(function (s) {
            return s.id === Gecko.Subscriber.DATA_PROBLEM;
        }).title = 'Data Problem';
        ctrl.filters = [{
            title: 'Status',
            type: 'checkbox',
            options: objectsToOptions('title')(statuses),
            stateParam: 'status'
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Broadcasts',
            click: function click() {
                $state.go('broadcasts');
            }
        }, {
            label: ctrl.broadcast.title,
            active: true
        }];
    };

    function BroadcastOverviewCtrl($rootScope, $state, $stateParams, broadcast, subscribers, stat) {
        var ctrl = this;
        ctrl.broadcast = broadcast;
        //ctrl.fieldList   = fieldList.toArray();
        //ctrl.subscribers = subscribers.toArray();
        ctrl.subscribers = subscribers.toInstances();
        ctrl.pagination = subscribers.pagination;
        ctrl.stat = stat;

        // Module specific variables
        generateCampaignBroadcast($state, $stateParams, ctrl, broadcast, {});
    }

    angular.module('GeckoEngage').controller('BroadcastOverviewCtrl', BroadcastOverviewCtrl);
})();