(function () {

    function CategoryModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('categoryCreateModal', function () {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/category/category_create_modal.html',
                controller: 'CategoryCreateModalCtrl',
                resolve: {}
            };
        });
    }

    function CategoryCreateModalCtrl($scope, $filter, $modalInstance, $state, $stateParams) {
        var ctrl = this;
        ctrl.category = new Gecko.Category();

        if (!ctrl.category.type) {
            ctrl.category.type = 'event';
        }

        ctrl.fields = [{
            id: 'title',
            label: 'Title',
            required: true,
            colClass: 'none',
            description: 'Create a title for this Category which will be used throughout the app.'
        }, {
            id: 'type',
            label: 'Type',
            type: Gecko.Field.TYPE_SELECT,
            options: [{ name: 'Event', id: 'event' }],
            noBlank: true,
            required: true,
            colClass: 'none',
            description: 'Choose what type of data you wish to categorise.'
        }];

        ctrl.footerBtns = [{
            title: 'Create Category',
            icon: 'fa-plus',
            btnClass: 'btn-primary',
            action: function action() {
                ctrl.category.save().then(function (category) {
                    GeckoUI.messenger.success('Category has been created');
                    $modalInstance.close(category);
                }).catch(function (error) {
                    GeckoUI.messenger.error(error.errors);
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(CategoryModals).controller('CategoryCreateModalCtrl', CategoryCreateModalCtrl);
})();