(function () {
    'use strict';

    function ImportRoute($stateProvider) {
        $stateProvider

        // File Import
        .state('settingsimportfile', {
            url: "/settings/import-file?{form_id}&{import_to}&{import_id}",
            templateUrl: "/modules/import/views/file-import.c4998d0a.html",
            controller: 'SettingsFileImportCtrl',
            page_title: 'Import File',
            requires: [Gecko.ABILITY_IMPORTS],
            resolve: {
                forms: function forms() {
                    return new Gecko.Form().rfields({ form: ['id', 'name', 'internal_name', 'module'] }).where('module', Gecko.Form.MODULE_FORM).perPage('all').get();
                },
                existing_import: function existing_import($stateParams) {
                    if ($stateParams.import_id) {
                        return new Gecko.Import().include('form').get($stateParams.import_id);
                    }
                    return null;
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(ImportRoute);
})();