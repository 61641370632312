(function () {
    'use strict';

    function VerifiedDomainsCtrl($state, verified_domains) {
        var ctrl = this;
        ctrl.domains = verified_domains.toArray();
        ctrl.pagination = verified_domains.pagination;

        // Table structure
        ctrl.cols = [{
            type: Gecko.Field.TYPE_NAME,
            title: 'Name',
            key: 'domain_name',
            sortKey: 'name',
            colClass: 'col-xs-4'
        }, {
            title: 'Verified',
            key: 'verified',
            sortKey: 'verified',
            colClass: 'col-xs-4',
            render: function render(value) {
                switch (value) {
                    case true:
                        return 'Verified';

                    case false:
                        return 'Failed';

                    case null:
                    default:
                        return 'Pending';
                }
            }
        }, {
            title: 'Date Created',
            key: 'created_at',
            data_type: 'timestamp',
            date_format: Gecko.dateFormat.short,
            type: Gecko.Field.TYPE_DATE,
            colClass: 'col-xs-4'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'verifiedsenders.domain',
            params: { verified_domain_id: 'id' }
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Verified Domains',
            active: true
        }];
    }

    function VerifiedDomainCtrl($scope, $state, $stateParams, unsavedAlert, verified_domain) {
        var ctrl = this;
        ctrl.domain = verified_domain;

        ctrl.seperator = function () {
            return '\n\n-----\n\n';
        };
        ctrl.section = function (title, description) {
            return title + ':\n' + description + ':\n\n';
        };
        ctrl.block = function (type, name, value) {
            var str = '';
            if (type) str += 'Type: ' + type + '\n';
            if (name) str += 'Name/Host: ' + name + '\n';
            if (value) str += 'Value: ' + value;
            return str;
        };

        ctrl.buildStr = function (data) {
            var str = '';

            // Domain Verification
            if (data.verification && data.verification.dns) {
                str += ctrl.section('Domain Verification', 'You are required add this to your domain\'s DNS for us to be able to send emails from your domain');
                str += ctrl.block(data.verification.dns['Type'], data.verification.dns['Name'], data.verification.dns['Value']);
            }

            str += ctrl.seperator();
            // DKIM Verification
            if (data.dkim && data.dkim.dns) {
                str += ctrl.section('DKIM Verification', 'Adding this to your domain will help secure the emails that we send from your domain');
                str += data.dkim.dns.map(function (obj) {
                    return ctrl.block(obj['Type'], obj['Name'], obj['Value']);
                }).join('\n\n');
            }

            str += ctrl.seperator();

            // Mail From Verification
            if (data.mail_from && data.mail_from.dns) {
                str += ctrl.section('Mail From Verification', 'Adding this your you DNS allows us to send emails that appear to be sent from your servers');
                str += data.mail_from.dns.map(function (obj) {
                    return ctrl.block(obj['Type'], obj['Name'], obj['Value']);
                }).join('\n\n');
            }

            str += ctrl.seperator();

            var spf_name = data.mail_from && data.mail_from.dns ? data.mail_from.dns[0].Name : 'gecko.' + verified_domain.domain_name;
            str += ctrl.section('SPF Verification', 'Adding this helps the receiver trust that our servers are allowed to send emails from your domain. You may need to adjust this to include existing details');
            str += ctrl.block('TXT', spf_name, 'v=spf1 include:mail-' + GeckoUI.getRegion() + '.geckoform.com ~all');

            return str;
        };

        // Using this function to get around angular not liking refreshing things within the object override on fields
        var getValue = function getValue(key, func) {
            return function (val) {
                var returnVal = key.split('.').reduce(function (v, k) {
                    return v[k];
                }, val);
                return func ? func(returnVal) : returnVal;
            };
        };

        var renderStatus = function renderStatus(status) {
            switch (status) {
                case true:
                    return '<i class="fa fa-check-circle text-success"></i> Verified';

                case false:
                    return '<i class="fa fa-times text-danger"></i> Failed';

                case null:
                default:
                    return '<i class="fa fa-hourglass text-warning"></i> Pending';
            }
        };

        if (!ctrl.domain.id) {
            ctrl.fields = [{
                id: 'domain_name',
                label: 'Domain Name',
                colClass: 'col-xs-12'
            }];
        } else {

            if (ctrl.domain.dns) {
                ctrl.domain.configuration = ctrl.buildStr(ctrl.domain.dns);
            } else {
                ctrl.domain.configuration = '';
            }

            // Fields
            ctrl.fields = [{
                id: 'verification_section',
                label: 'Overall Verification Statuses',
                type: Gecko.Field.TYPE_TITLE,
                colClass: 'col-xs-12'
            }, {
                id: 'dns',
                label: 'Domain Verification',
                colClass: 'col-xs-4',
                type: Gecko.Field.TYPE_NOEDIT,
                render: getValue('verification.status', renderStatus)
            }, {
                id: 'dns',
                label: 'DKIM Verification',
                colClass: 'col-xs-4',
                type: Gecko.Field.TYPE_NOEDIT,
                render: getValue('dkim.status', renderStatus)
            }, {
                id: 'dns',
                label: 'Mail From Verification',
                colClass: 'col-xs-4',
                type: Gecko.Field.TYPE_NOEDIT,
                render: getValue('mail_from.status', renderStatus)
            }, {
                type: Gecko.Field.TYPE_SCRIPT,
                break: true,
                colClass: 'col-xs-12',
                description: '<div style="margin:0;" class="alert alert-info"><h5 style="margin-top:0; padding-top:0;">Configuration Details</h5><p>You will have to set up the records indicated below within your domain\'s DNS settings. You will likely have to pass this to the domain admin or IT services for your organisation. You can find out more details about how to configure your domain <a href="https://academy.geckoengage.com/en/articles/6715429-verified-senders-domains" target="_blank">here</a>.</p></div>'
            }, {
                id: 'configuration',
                label: 'Configuration Details',
                colClass: 'col-xs-12',
                type: Gecko.Field.TYPE_TEXTAREA,
                readonly: true,
                rows: 20
            }];
        }

        var VERIFICATION_TYPE_EMBED = 'form_embed';
        var VERIFICATION_TYPE_META = 'meta_tag';

        if (ctrl.domain.id) {
            ctrl.geckoVerificationFields = [{
                type: Gecko.Field.TYPE_SCRIPT,
                break: true,
                help_text: '<div class="alert alert-info" style="margin-bottom:0;">We must first verify that you own the domain that you are trying to verify</div>'
            }, {
                id: 'verificationType',
                type: Gecko.Field.TYPE_PANEL_OPTIONS,
                label: 'Choose your preferred method',
                options: [{
                    value: VERIFICATION_TYPE_EMBED,
                    icon: 'fa-file',
                    title: 'Form Embed'
                }, {
                    value: VERIFICATION_TYPE_META,
                    icon: 'fa-code',
                    title: 'Meta Tag'
                }]
            }, {
                key: 'path',
                label: 'Website URL',
                type: Gecko.Field.TYPE_INPUT,
                description: 'Paste a url from your website that contains an embedded Gecko Form via a &lt;script&gt; tag. Note: iFrame embeds are not supported at this time.',
                colClass: 'col-xs-12',
                hideWhen: function hideWhen() {
                    return ctrl.verificationValues.verificationType !== VERIFICATION_TYPE_EMBED;
                }
            }, {
                id: 'metatag',
                type: Gecko.Field.TYPE_TEXT,
                colClass: 'no-class gecko-field-code col-xs-12',
                readonly: true,
                description: 'Copy this meta tag into the head of your website then use the verify button',
                hideWhen: function hideWhen() {
                    return ctrl.verificationValues.verificationType !== VERIFICATION_TYPE_META;
                }
            }, {
                key: 'path',
                label: 'Website URL',
                type: Gecko.Field.TYPE_INPUT,
                description: 'Paste the url of your website',
                colClass: 'col-xs-12',
                hideWhen: function hideWhen() {
                    return ctrl.verificationValues.verificationType !== VERIFICATION_TYPE_META;
                }
            }];

            var verificationCode = ctrl.domain.meta && ctrl.domain.meta.verificationCode ? ctrl.domain.meta.verificationCode : '';
            ctrl.verificationValues = {
                verificationType: VERIFICATION_TYPE_EMBED,
                metatag: '<meta name="gecko_domain_verification" content="' + verificationCode + '"/>',
                path: 'https://' + ctrl.domain.domain_name
            };
        }

        var REMOVE_BUTTON = {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.verified_domain_id == 'new';
            },
            requires: [Gecko.ABILITY_SENDERS_DELETE],
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this verified domain? Warning: you will have to reconfigure any DNS if you wish to set this domain up again.', function (value) {
                    if (value) {
                        unsavedAlert.clear();
                        ctrl.domain.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Verified Domain has been deleted');
                            $state.go('verifiedsenders.domains');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        };

        ctrl.footerBtns = [{
            preset: 'save',
            title: 'Save',
            requires: [Gecko.ABILITY_SENDERS_CREATE],
            hideWhen: function hideWhen() {
                return $stateParams.verified_domain_id !== 'new';
            },
            action: function action() {
                ctrl.domain.save().then(function (domain) {
                    ctrl.domain = domain;
                    GeckoUI.messenger.success('Verified Domain has been saved');
                    unsavedAlert.clear();
                    if ($stateParams.verified_domain_id == 'new') {
                        $state.go('verifiedsenders.domain', {
                            verified_domain_id: domain.id
                        });
                    }
                    $scope.$digest();
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }, {
            title: 'Refresh Statuses',
            btnClass: 'btn-default',
            icon: 'fa-sync',
            requires: [Gecko.ABILITY_SENDERS_UPDATE],
            hideWhen: function hideWhen() {
                return $stateParams.verified_domain_id == 'new';
            },
            action: function action() {
                ctrl.domain.refresh().then(function (dns) {
                    ctrl.domain.dns = dns;
                    $scope.$apply();
                }).catch(GeckoUI.messenger.error);
            }
        }, REMOVE_BUTTON];

        /**
         * Check if the url contains the domain_name the user is verifying
         *
         * @return Bool
         */
        ctrl.isValidURL = function (url, domain_name) {
            if (!url.includes(domain_name)) {
                return false;
            }

            return true;
        };

        ctrl.disableButtonWhileInFlight = false;

        ctrl.verificationFooterBtns = [{
            title: 'Verify Embedded Form',
            btnClass: 'btn-default',
            hideWhen: function hideWhen() {
                return ctrl.verificationValues.verificationType !== VERIFICATION_TYPE_EMBED;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.disableButtonWhileInFlight;
            },
            action: function action() {
                ctrl.disableButtonWhileInFlight = true;

                var url = ctrl.verificationValues.path;

                if (!ctrl.isValidURL(url, ctrl.domain.domain_name)) {
                    ctrl.disableButtonWhileInFlight = false;
                    GeckoUI.messenger.error('URL doesn\'t match ' + ctrl.domain.domain_name);
                    return;
                }

                return new Gecko().call('/verified_domains/' + ctrl.domain.id + '/verify-by-embed', 'POST', { url: url }, true).then(function (res) {
                    ctrl.disableButtonWhileInFlight = false;
                    if (res.verified === true) {
                        GeckoUI.messenger.success('Verification Successful');

                        setTimeout(function () {
                            location.reload();
                        }, 1000);
                    } else {
                        GeckoUI.messenger.error('Embedded form not found on ' + url);
                    }
                }).catch(function (err) {
                    ctrl.disableButtonWhileInFlight = false;
                    GeckoUI.messenger.error(err);
                });
            }
        }, {
            title: 'Verify Meta Tag',
            btnClass: 'btn-default',
            hideWhen: function hideWhen() {
                return ctrl.verificationValues.verificationType !== VERIFICATION_TYPE_META;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.disableButtonWhileInFlight;
            },
            action: function action() {
                ctrl.disableButtonWhileInFlight = true;

                var url = ctrl.verificationValues.path;

                if (!ctrl.isValidURL(url, ctrl.domain.domain_name)) {
                    ctrl.disableButtonWhileInFlight = false;
                    GeckoUI.messenger.error('URL doesn\'t match ' + ctrl.domain.domain_name);
                    return;
                }

                return new Gecko().call('/verified_domains/' + ctrl.domain.id + '/verify-by-meta', 'POST', { url: url }, true).then(function (res) {
                    ctrl.disableButtonWhileInFlight = false;

                    if (res.verified === true) {
                        GeckoUI.messenger.success('Verification Successful');

                        setTimeout(function () {
                            location.reload();
                        }, 1000);
                    } else {
                        GeckoUI.messenger.error('Meta tag not found on ' + ctrl.domain.domain_name);
                    }
                }).catch(function (err) {
                    ctrl.disableButtonWhileInFlight = false;
                    GeckoUI.messenger.error(err);
                });
            }
        }, REMOVE_BUTTON];
    }

    angular.module('GeckoEngage').controller('VerifiedDomainsCtrl', VerifiedDomainsCtrl).controller('VerifiedDomainCtrl', VerifiedDomainCtrl);
})();