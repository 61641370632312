(function () {
    'use strict';

    function FormsListCtrl($scope, $rootScope, $state, $stateParams, $filter, $timeout, forms, groups, module, $formBuilderService, $dialogIntegrationSelect) {
        $scope.forms = forms.toInstances();
        $scope.groups = groups;
        $scope.pagination = forms.pagination;
        $scope.form_index = null;
        $scope.start_date = null;
        $scope.end_date = null;
        $scope.assigned_id = null;
        $scope.event_id = null;
        $scope.module = module;
        var archiveMode = $stateParams.published === 'archived';
        $scope.listtitle = '' + (archiveMode ? 'Archived ' : '') + (module == Gecko.Form.MODULE_FORM ? 'Forms' : 'Scripts');

        // Table structure
        $scope.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            sortKey: 'created_at'
        }, {
            title: 'Name',
            key: 'name',
            colClass: 'col-xs-6',
            sortKey: 'name',
            render: function render(value, col, form) {
                if (form.internal_name) return form.internal_name;
                return value;
            },
            helpTextKey: "description"
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Created by',
            key: 'related_user.full_name',
            colClass: 'col-xs-3'
        }, {
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Responses',
            key: 'responses_count',
            colClass: 'col-xs-3'
        }];

        // Dont show status column when archive mode
        if (!archiveMode) {
            $scope.cols = [{
                key: 'published',
                status_title_none: true,
                expandable: true,
                status_styles: [{
                    id: 0,
                    label: 'label-warning',
                    title: 'Unpublished'
                }, {
                    id: 1,
                    label: 'label-success',
                    title: 'Published'
                }]
            }].concat($scope.cols);
        }

        // Table row action
        $scope.rowAction = {
            action: function action(form) {
                return $formBuilderService.goTo(form);
            }
        };

        // Add a form/script
        $scope.addForm = $formBuilderService.goTo.bind(this, false, $scope.module);

        // Table row dropdown
        $scope.rowOptionsBtn = {};
        if (archiveMode) {
            $scope.rowOptionsBtn.items = [{
                title: 'Restore ' + ($scope.module == Gecko.Form.MODULE_FORM ? 'form' : 'script'),
                action: function action(form) {
                    new Gecko.Form().fill(form).include('all_forms').where('trashed', 1).restore().then(function (_form) {
                        GeckoUI.messenger.success((form.internal_name || form.name) + ' was successfully restored');
                        // Reload page
                        return $state.go($state.$current.name, $stateParams, { reload: true });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err);
                    });
                }
            }];
        } else {
            $scope.rowOptionsBtn.items = [{
                title: 'Preview ' + ($scope.module == Gecko.Form.MODULE_FORM ? 'form' : 'script'),
                action: function action(form) {
                    var link = $formBuilderService.buildShareURL(form.uuid, 'modern', null, false);
                    return window.open(link, "_blank");
                }
            }, {
                title: 'View ' + ($scope.module == Gecko.Form.MODULE_CALL ? 'script' : 'form') + ' dashboard',
                action: function action(form) {
                    var opts = {
                        override_date: 'month'
                    };
                    if ($scope.module == Gecko.Form.MODULE_CALL) {
                        opts.script_id = form.id;
                    } else {
                        opts.form_id = form.id;
                    }
                    return $state.go('dashboardview', opts);
                }
            }, {
                title: 'Export responses',
                hideWhen: function hideWhen() {
                    return $scope.module == Gecko.Form.MODULE_CALL;
                },
                action: function action(form) {
                    return $state.go('exportnew', {
                        export_id: 'new',
                        form_id: form.id
                    });
                }

            }, {
                title: 'Import responses',
                hideWhen: function hideWhen() {
                    return $scope.module == Gecko.Form.MODULE_CALL;
                },
                action: function action(form) {
                    return $state.go('settingsimportfile', {
                        form_id: form.id,
                        import_to: 'responses'
                    });
                }
            }, {
                title: 'Add a response',
                hideWhen: function hideWhen() {
                    return $scope.module == Gecko.Form.MODULE_CALL;
                },
                action: function action(form) {
                    return $state.go('responseadd', {
                        uuid: form.uuid
                    });
                }
            }, {
                title: 'Show responses',
                action: function action(form) {
                    return $state.go('responses', { form_type: $scope.module == Gecko.Form.MODULE_CALL ? 'call' : 'form', form: form.id });
                }
            }, {
                title: 'Sync failures',
                hideWhen: function hideWhen() {
                    return $scope.module == Gecko.Form.MODULE_CALL;
                },
                action: function action(form) {
                    return $dialogIntegrationSelect(false, [Gecko.Integration.TYPE_SALEFORCE, Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_EMS, Gecko.Integration.TYPE_DOTMAILER, Gecko.Integration.TYPE_NEW_DYNAMICS, Gecko.Integration.TYPE_WEBHOOK], function (integrationId) {
                        return $state.go('responses', {
                            form: form.id,
                            sync_status: 'has_not_synced_' + integrationId
                        });
                    });
                }
            }, {
                title: 'Clone ' + ($scope.module == Gecko.Form.MODULE_FORM ? 'form' : 'script'),
                action: function action(form) {
                    var entity = $scope.module == Gecko.Form.MODULE_FORM ? 'Form' : 'Script';
                    var num_scheduled = form.related_workflows.filter(function (workflow) {
                        return workflow.schedule !== null;
                    }).length;
                    var s = num_scheduled === 1 ? '' : 's';
                    var message = num_scheduled > 0 ? '<h3>Workflow' + s + ' Requiring Attention</h3>\n                        <p>You are attempting to clone a <b>' + entity + '</b> that has workflows containing actions with set dates, the workflows affected will be reset to run immediately.</p>\n\n                        <p>To avoid any unintended consequences, we strongly recommend updating the dates on the affected workflows before publishing the ' + entity + '.</p>' : 'Are you sure you would like to clone this ' + entity + '?';

                    GeckoUI.dialog.confirm(message, function (confirm) {
                        if (confirm) {
                            angular.copy(form).include('user').clone().then(function (clone) {
                                GeckoUI.messenger.success(entity + ' was cloned successfully');
                            }).then(function () {
                                $state.go($state.$current.name, $stateParams, { reload: true });
                            }).catch(function (error) {
                                GeckoUI.messenger.error(Gecko.errorsAsArray(error.errors)[0]);
                            });
                        }
                    });
                }
            }, {
                title: 'Archive ' + ($scope.module == Gecko.Form.MODULE_FORM ? 'form' : 'script'),
                action: function action(form, index) {
                    if (!GeckoUI.ableWithReason(Gecko.ABILITY_FORMS_DELETE)) return;
                    GeckoUI.dialog.confirm('Are you sure you want to archive this ' + ($scope.module == Gecko.Form.MODULE_FORM ? 'form' : 'script') + '?', function (value) {
                        if (value) {
                            form.remove().then(function () {
                                $scope.forms.splice(index, 1);
                                GeckoUI.messenger.success(($scope.module == Gecko.Form.MODULE_FORM ? 'Form' : 'Script') + ' has been archived.');
                                $scope.$apply();
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        }
                    });
                }
            }];
        }

        var getStatusFilterOptions = function getStatusFilterOptions() {
            var filter_options = [{ label: 'Published ' + ($scope.module == Gecko.Form.MODULE_FORM ? 'Forms' : 'Scripts'), value: 1 }];

            if ($scope.module == Gecko.Form.MODULE_FORM) {
                filter_options.push({ label: 'Unpublished Forms', value: 0 });
            }

            filter_options.push({ label: 'Archived ' + ($scope.module == Gecko.Form.MODULE_FORM ? 'Forms' : 'Scripts'), value: 'archived' });

            return filter_options;
        };

        $scope.filters = [{
            title: ($scope.module == Gecko.Form.MODULE_FORM ? 'Form' : 'Script') + ' Status',
            type: 'radio',
            options: getStatusFilterOptions(),
            stateParam: 'published'
        }, {
            title: 'Groups',
            type: 'radio',
            options: $scope.groups.map(function (group) {
                return { label: group, value: group };
            }),
            stateParam: 'group'
        }];

        // Breadcrumbs
        $scope.breadcrumbs = [{
            label: $scope.module == Gecko.Form.MODULE_FORM ? 'Forms' : 'Scripts',
            click: function click() {
                return $state.go($scope.module == Gecko.Form.MODULE_FORM ? 'forms' : 'scripts');
            }
        }, {
            label: 'List',
            active: true
        }];

        var archiveEntityTitle = module == Gecko.Form.MODULE_FORM ? 'forms' : 'scripts';
        $scope.formSelects = [];
        var archiveForms = function archiveForms() {
            if (!GeckoUI.ableWithReason(Gecko.ABILITY_FORMS_DELETE) || !$scope.formSelects.length) return;

            GeckoUI.dialog.confirm('Are you sure you want to archive the selected ' + archiveEntityTitle + '?', function (ok) {
                if (ok) {
                    var endpoint = Gecko.base_url + '/forms';
                    var body = JSON.stringify({ ids: $scope.formSelects });
                    var headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.TokenManager.fromLocalStorage().AccessToken
                    };
                    return fetch(endpoint, {
                        method: 'DELETE',
                        body: body,
                        headers: headers
                    }).then(function () {
                        GeckoUI.messenger.success('Successfully archived ' + $scope.formSelects.length + ' ' + archiveEntityTitle + '.');
                    }).then(function () {
                        // Reset selected rows
                        $scope.formSelects.length = 0;
                    }).then(function () {
                        $state.go($state.$current.name, $stateParams, { reload: true });
                    }).catch(GeckoUI.messenger.error);
                }
            });
        };

        $scope.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !$scope.formSelects.length;
            },
            items: [{
                title: 'Archive ' + archiveEntityTitle,
                action: archiveForms
            }]
        };
    }

    angular.module('GeckoEngage').controller('FormsListCtrl', FormsListCtrl);
})();