(function () {
    'use strict';

    /**
     * Adds ids to key chosen elements to aid end to end testing
     */

    function chosenId() {

        return {
            restrict: 'A',
            priority: 2,
            link: function link(scope, iElement, iAttrs) {
                iAttrs.$observe('id', function (value) {
                    if (value) iElement.data('chosen').container.attr('id', value + '_chosen');
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('chosenId', chosenId);
})();