(function () {
    'use strict';

    function $geckoWatch() {

        var __objects = {};
        var __callbacks = {};

        var generateId = function generateId() {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 5; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }return text;
        };

        return function (obj, property, callback, $scope) {

            if (!obj || !property || !callback) return false;

            // Handle some getter / setter stuff
            var __temp = {};

            // Set initial value?
            __temp[property] = obj[property];

            if (!obj._$geckoWatchRef) {
                obj._$geckoWatchRef = generateId();
                __callbacks[obj._$geckoWatchRef] = {};
            }
            // Map callbacks
            if (!__callbacks[obj._$geckoWatchRef][property]) {
                __callbacks[obj._$geckoWatchRef][property] = [];
            }
            // Push callback
            __callbacks[obj._$geckoWatchRef][property].push(callback);

            // Only get possible user transfers if open
            Object.defineProperty(obj, property, {
                configurable: true,
                get: function get() {
                    return __temp[property];
                },
                set: function set(value) {
                    // Set value
                    __temp[property] = value;
                    // Do something
                    if (__callbacks[obj._$geckoWatchRef][property]) {
                        for (var i = 0; i < __callbacks[obj._$geckoWatchRef][property].length; i++) {
                            try {
                                // __callbacks[property][i](value);
                                __callbacks[obj._$geckoWatchRef][property][i](value);
                            } catch (e) {}
                        }
                    }
                }
            });

            // If ($scope) {
            //     $scope.on('$destroy', function(){
            //         Delete obj[property];
            //     });
            // }

            return function () {
                // Delete obj[property];
                delete __callbacks[obj._$geckoWatchRef][property];
            };
        };
    }

    angular.module('GeckoEngage').service('$geckoWatch', $geckoWatch);
})();