var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/**
 * Angular Selectize2
 * https://github.com/machineboy2045/angular-selectize
 * */

angular.module('selectize', []).value('selectizeConfig', {}).directive("selectize", ['selectizeConfig', function (selectizeConfig) {
    return {
        restrict: 'EA',
        require: '^ngModel',
        scope: { ngModel: '=', config: '=?', options: '=?', ngDisabled: '=', ngRequired: '&' },
        link: function link(scope, element, attrs, modelCtrl) {

            modelCtrl.$formatters.push(function (value) {
                if (value instanceof Array) {
                    return value.map(function (item) {
                        return String(item);
                    });
                }
            });

            Selectize.defaults.maxItems = null; // Default to tag editor

            var selectize,
                config = angular.extend({}, Selectize.defaults, selectizeConfig, scope.config);

            modelCtrl.$isEmpty = function (val) {
                return val === undefined || val === null || !val.length; // Override to support checking empty arrays
            };

            function createItem(input) {
                var data = {};
                data[config.labelField] = input;
                data[config.valueField] = input;
                return data;
            }

            function createGroup(input) {
                var data = {};
                data[config.optgroupLabelField] = input;
                data[config.optgroupValueField] = input;
                return data;
            }

            function toggle(disabled) {
                disabled ? selectize.disable() : selectize.enable();
            }

            var validate = function validate() {
                var isInvalid = (scope.ngRequired() || attrs.required || config.required) && modelCtrl.$isEmpty(modelCtrl.$viewValue);
                modelCtrl.$setValidity('required', !isInvalid);
            };

            function generateOptions(data) {
                if (!data) return [];

                data = angular.isArray(data) ? data : [data];

                return $.map(data, function (opt) {
                    return (typeof opt === 'undefined' ? 'undefined' : _typeof(opt)) !== 'object' ? createItem(opt) : opt;
                });
            }

            function generateOptgroups(data) {
                if (!data) return [];

                var groups = {};

                data.forEach(function (value) {
                    if (value[config.optgroupField]) groups[value[config.optgroupField]] = true;
                });

                return $.map(Object.keys(groups), function (group) {
                    return typeof group === 'string' ? createGroup(group) : group;
                });
            }

            function updateSelectize() {
                validate();

                selectize.$control.toggleClass('ng-valid', modelCtrl.$valid);
                selectize.$control.toggleClass('ng-invalid', modelCtrl.$invalid);
                selectize.$control.toggleClass('ng-dirty', modelCtrl.$dirty);
                selectize.$control.toggleClass('ng-pristine', modelCtrl.$pristine);

                if (!angular.equals(selectize.items, modelCtrl.$viewValue)) {
                    selectize.addOption(generateOptions(modelCtrl.$viewValue));
                    selectize.setValue(modelCtrl.$viewValue);
                }
            }

            config.onChange = function () {
                if (!angular.equals(selectize.items, modelCtrl.$viewValue)) scope.$evalAsync(function () {
                    var value = angular.copy(selectize.items);
                    if (config.maxItems == 1) {
                        value = value[0];
                    }
                    modelCtrl.$setViewValue(value);
                });
            };

            config.onOptionAdd = function (value, data) {
                if (scope.options.indexOf(data) === -1) scope.options.push(data);
            };

            // NgModel (ie selected items) is included in this because if no options are specified, we
            // Need to create the corresponding options for the items to be visible
            scope.options = generateOptions(angular.copy(scope.options || config.options || scope.ngModel));
            scope.optgroups = generateOptgroups(scope.options);

            var angularCallback = config.onInitialize;

            config.onInitialize = function () {
                selectize = element[0].selectize;
                selectize.addOption(scope.options);
                scope.optgroups.forEach(function (group) {
                    selectize.addOptionGroup(group[config.optgroupValueField], group);
                });

                // Provides a way to access the selectize element from an
                // Angular controller
                if (angularCallback) {
                    angularCallback(selectize);
                }

                modelCtrl.$render = function () {
                    selectize.setValue(modelCtrl.$viewValue);
                };

                scope.$watchCollection('options', selectize.addOption.bind(selectize));
                modelCtrl.$viewChangeListeners.push(updateSelectize);
                scope.$watch('ngDisabled', toggle);
            };

            element.selectize(config);

            element.on('$destroy', function () {
                if (selectize) {
                    selectize.destroy();
                    element = null;
                }
            });
        }
    };
}]);

$(document).ready(function () {
    /*
    https://github.com/brianreavis/selectize.js/issues/470
    Selectize doesn't display anything to let the user know there are no results.
    This plugin allows us to render a no results message when there are no
    results are found to select for.
    */
    Selectize.define("no_results", function (t) {
        var e,
            s = this;t = $.extend({ message: "No results found.", html: function html(t) {
                return '<div class="selectize-dropdown ' + t.classNames + '"><div class="selectize-dropdown-content"><div class="no-results">' + t.message + "</div></div></div>";
            } }, t), s.displayEmptyResultsMessage = function () {
            this.$empty_results_container.css("top", this.$control.outerHeight()), this.$empty_results_container.css("width", this.$control.outerWidth()), this.$empty_results_container.show(), this.$control.addClass("dropdown-active");
        }, s.refreshOptions = (e = s.refreshOptions, function () {
            e.apply(s, arguments), this.hasOptions || !this.lastQuery ? this.$empty_results_container.hide() : this.displayEmptyResultsMessage();
        }), s.onKeyDown = function () {
            var t = s.onKeyDown;return function (e) {
                t.apply(s, arguments), 27 === e.keyCode && this.$empty_results_container.hide();
            };
        }(), s.onBlur = function () {
            var t = s.onBlur;return function () {
                t.apply(s, arguments), this.$empty_results_container.hide(), this.$control.removeClass("dropdown-active");
            };
        }(), s.setup = function () {
            var e = s.setup;return function () {
                e.apply(s, arguments), s.$empty_results_container = $(t.html($.extend({ classNames: s.$input.attr("class") }, t))), s.$empty_results_container.insertBefore(s.$dropdown), s.$empty_results_container.hide();
            };
        }();
    });
    Selectize.define("accessibility", function (e) {
        var i,
            t = this;void 0 === this.accessibility && (this.accessibility = {}), this.accessibility.helpers = { randomId: function randomId(e) {
                var i = "",
                    t = { base62: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", base36: "abcdefghijklmnopqrstuvwxyz0123456789", base10: "0123456789" };(e = e || {}).length = e.length || 10, e.strBase = t[e.base] || t.base36, e.baseLength = e.strBase.length;for (var s = 0; s < e.length; s++) {
                    i += e.strBase[Math.floor(e.baseLength * Math.random())];
                }return i;
            } }, this.accessibility.liveRegion = { $region: "", announce: function announce(e, i) {
                var s = $("<div>" + e + "</div>");t.accessibility.liveRegion.$region.append(s), setTimeout(function () {
                    t.accessibility.liveRegion.$region.empty();
                }, i || 3e3);
            }, setAttributes: function setAttributes() {
                t.accessibility.liveRegion.$region.attr({ "aria-live": "assertive", role: "log", "aria-relevant": "additions", "aria-atomic": "false" });
            }, setStyle: function setStyle() {
                t.accessibility.liveRegion.$region.css({ position: "absolute", width: "1px", height: "1px", "margin-top": "-1px", clip: "rect(1px, 1px, 1px, 1px)", overflow: "hidden" });
            }, eventsHandler: function eventsHandler() {
                new MutationObserver(function (e) {
                    e.forEach(function (e) {
                        var i = $(e.target);i.hasClass("active") && t.accessibility.liveRegion.announce(i.text(), 500), i.hasClass("selected") && !e.oldValue.match("selected") && (t.$dropdown_content.find("[aria-selected]").attr("aria-selected", "false"), setTimeout(function () {
                            i.attr("aria-selected", !0);
                        }, 0));
                    });
                }).observe(t.$dropdown[0], { attributeFilter: ["class"], subtree: !0, attributeOldValue: !0 });
            }, init: function init(e) {
                t.accessibility.liveRegion.$region = $("<div>"), t.accessibility.liveRegion.setAttributes(), t.accessibility.liveRegion.setStyle(), t.accessibility.liveRegion.eventsHandler(), $("body").append(t.accessibility.liveRegion.$region);
            } }, this.setup = (i = t.setup, function () {
            i.apply(this, arguments);var e = t.accessibility.helpers.randomId();t.$control_input.attr({ role: "combobox", "aria-owns": e, "aria-autocomplete": "list", "aria-expanded": "false" }), t.$dropdown_content.attr({ role: "listbox", id: e }), t.accessibility.liveRegion.init();
        });
    });

    Selectize.define("accessibility-2", function (options) {
        var self = this;
        var KEY_RETURN = 13;

        if (typeof self.accessibility === "undefined") {
            self.accessibility = {};
        }

        self.accessibility.helpers = {
            randomId: function randomId(len) {
                var str = "",
                    strLength = len || 10,
                    base = "abcdefghijklmnopqrstuvwxyz0123456789",
                    baseLength = base.length;

                for (var i = 0; i < strLength; i++) {
                    str += base[Math.floor(baseLength * Math.random())];
                }

                return str;
            }
        };

        self.accessibility.listeners = function () {

            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    var $target = $(mutation.target);
                    if ($target.hasClass("items")) {
                        if ($target.hasClass("dropdown-active")) {
                            // open
                            self.$wrapper.attr('aria-expanded', true);

                            // Add aria-activedescendant to input
                            var options = self.$dropdown_content.find('> div');
                            var activeOption = self.$dropdown_content.find('> div.active');
                            var activeDescendant;

                            if (activeOption.length) {
                                activeDescendant = activeOption;
                            } else if (options.length) {
                                activeDescendant = options.first();
                            }

                            if (activeDescendant) {
                                self.$control_input.attr({
                                    'aria-activedescendant': activeDescendant.attr('id')
                                });
                            }
                        } else {
                            // close
                            self.$wrapper.attr('aria-expanded', false);
                            self.$control_input.removeAttr('aria-activedescendant');

                            if (!self.getValue()) {
                                self.$dropdown_content.find('> div').removeAttr('aria-selected');
                            }
                        }
                    } else {
                        // option change
                        if ($target.hasClass("active")) {
                            if (!!$target.attr("data-value")) {
                                self.$control_input.attr({
                                    'aria-activedescendant': $target.attr('id')
                                });
                                self.$dropdown_content.find('> div').removeAttr('aria-selected');
                                $target.attr('aria-selected', true);
                            }
                        }
                    }
                });
            });

            observer.observe(self.$dropdown[0], {
                attributes: true,
                attributeFilter: ["class"],
                subtree: true,
                attributeOldValue: true
            });

            observer.observe(self.$control[0], {
                attributes: true,
                attributeFilter: ["class"]
            });

            observer.observe(self.$control_input[0], {
                attributes: true,
                attributeFilter: ["value"]
            });
        };

        this.setup = function () {
            var original = self.setup;
            return function () {
                original.apply(this, arguments);
                var inputId = self.accessibility.helpers.randomId(),
                    listboxId = self.accessibility.helpers.randomId();

                self.$control.on('keydown', function (e) {
                    if (e.keyCode === KEY_RETURN) {
                        $(this).click();
                    }
                });

                self.$wrapper.attr({
                    role: 'combobox',
                    'aria-haspopup': 'listbox',
                    'aria-expanded': false,
                    'aria-owns': listboxId
                });

                // Add aria-labelledby (timeout to allow dynamic attrs to render)
                setTimeout(function () {
                    var labelledby = self.$wrapper.prev('selectize').attr('aria-labelledby');
                    if (labelledby) {
                        self.$wrapper.attr('aria-labelledby', labelledby);
                        self.$control_input.attr('aria-labelledby', labelledby);
                        self.$dropdown_content.attr('aria-labelledby', labelledby);
                    }
                });

                self.$control_input.attr({
                    id: inputId,
                    role: 'searchbox',
                    'aria-controls': listboxId,
                    'aria-autocomplete': 'both'
                });

                self.$dropdown_content.attr({
                    role: 'listbox',
                    id: listboxId
                });

                // Add listeners
                self.accessibility.listeners();
            };
        }();

        this.destroy = function () {
            var original = self.destroy;
            return function () {
                return original.apply(this, arguments);
            };
        }();
    });
});