(function () {
    'use strict';

    function EventsCtrl($state, $stateParams) {
        var ctrl = this;
        //Redirect to events list
        if ($state.$current.name === 'events') $state.go('events.list', $stateParams);

        // Tabs
        ctrl.tabs = [{
            id: 'list',
            title: 'Events',
            state: 'events.list'
        }, {
            id: 'hosts',
            title: 'Hosts',
            state: 'events.hosts',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_HOSTS_VIEW);
            }
        }, {
            id: 'locations',
            title: 'Locations',
            state: 'events.locations',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW);
            }
        }, {
            id: 'share',
            title: 'Share',
            state: 'events.share',
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_SHARE_TAB);
            }
        }, {
            id: 'deleted',
            title: 'Deleted Events',
            state: 'events.deleted',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_UPDATE);
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Events',
            click: function click() {
                return $state.go('events.list');
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('EventsCtrl', EventsCtrl);
})();