(function () {
    'use strict';

    function OrganisationFieldsCtrl(fields, $state, munge, $filter) {

        var ctrl = this;
        ctrl.fields = fields.toArray();

        var typeLabels = munge(field_types).toObjectByKey('type').done();

        // Table structure
        ctrl.cols = [{
            title: 'Label',
            key: 'label',
            colClass: 'col-md-4'
        }, {
            title: 'Type',
            key: 'type',
            colClass: 'col-md-4',
            render: function render(value) {
                var type = typeLabels[value];
                if (type) return type.label;
                return $filter('titlecase')(value.replace('_', ' '));
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date',
            key: 'created_at',
            colClass: 'col-md-4'
        }, {
            key: 'system',
            icon_styles: [{
                id: 1,
                icon: 'fa-lock'
            }]
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(organisation_field) {
                return $state.go('organisationfield', { 'organisation_field_id': organisation_field.id });
            }
        };

        ctrl.reorderOptions = {
            stop: function stop() {
                // Refresh order values
                GeckoUI.refreshOrder(ctrl.fields);

                // Map order values
                var _fields = GeckoUI.mapObjectArray(angular.copy(ctrl.fields), { id: 'id', order: 'order' });

                // API update order
                new Gecko.Field().post('', {
                    'fields': _fields
                }).then(function () {
                    GeckoUI.messenger.success('Contact field order updated.');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }

            // Breadcrumbs
        };ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                return $state.go('settings');
            }
        }, {
            label: 'Organisation Fields',
            click: function click() {
                return $state.go('organisationfields');
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    var field_types = [{
        icon: 'fa fa-building',
        type: Gecko.Field.TYPE_SINGLE_NAME,
        label: 'Single Name'
    }, {
        icon: 'fa fa-envelope',
        type: 'email',
        label: 'Email'
    }, {
        icon: 'fa fa-phone',
        type: 'tel',
        label: 'Telephone'
    }, {
        icon: 'fa fa-font',
        type: 'text',
        label: 'Text (single line)'
    }, {
        icon: 'fa fa-align-left',
        type: 'textarea',
        label: 'Text (multiple lines)'
    }, {
        icon: 'fa fa-angle-down',
        type: 'select',
        label: 'Dropdown'
    }, {
        icon: 'fa fa-list',
        type: 'radio',
        label: 'Radio Choices'
    }, {
        icon: 'fa fa-check',
        type: 'checkbox',
        label: 'Checkboxes'
    }, {
        icon: 'fa fa-home',
        type: 'address',
        label: 'Address'
    }, {
        icon: 'fa fa-calendar-alt',
        type: 'date',
        label: 'Date'
    }, {
        icon: 'fa fa-money',
        type: 'number',
        label: 'Number'
    }, {
        icon: 'fa fa-upload',
        type: 'file',
        label: 'File'
    }, {
        icon: 'fa fa-link',
        type: 'url',
        label: 'Website Address'
    }];

    function OrganisationFieldEditCtrl(field, $state, $stateParams, asyncOptions, unsavedAlert, option) {

        var ctrl = this;

        ctrl.field = field;

        ctrl.field_types = field_types;

        ctrl.isTagInvalid = function () {
            return (ctrl.field.tag || '').includes(' ');
        };

        // Map for Checking Option field types, values with keys in the map are valid for dropdowns/ checkboxes/etc.
        ctrl.multipleChoiceTypes = {};

        ctrl.multipleChoiceTypes[Gecko.Field.TYPE_SELECT] = true;
        ctrl.multipleChoiceTypes[Gecko.Field.TYPE_CHECKBOX] = true;
        ctrl.multipleChoiceTypes[Gecko.Field.TYPE_RADIO] = true;

        ctrl.fields = [{
            label: 'Label',
            key: 'label',
            required: true,
            colClass: 'no-padding',
            description: "The label you want for this field throughout Gecko."
        }, {
            label: 'Type',
            key: 'type',
            description: "Choose which type you want to use for this field.",
            noBlank: true,
            required: true,
            colClass: 'no-padding',
            options: ctrl.field_types,
            optionsKey: 'type',
            optionsLabelKey: 'label',
            type: Gecko.Field.TYPE_SELECT,
            disabledWhen: function disabledWhen() {
                if (ctrl.field.system === 1) {
                    return true;
                }
                return false;
            }
        }, {
            label: 'Description',
            key: 'help_text',
            description: "A short description of this field.",
            colClass: 'no-padding'
        }, {
            label: 'Template tag',
            key: 'tag',
            description: 'For use in email templates e.g. full_name or email_address',
            colClass: 'no-padding'
        }, {
            label: 'Option Template',
            description: 'Choose which options you wish to show for this field.',
            colClass: 'no-padding',
            key: 'option_id',
            options: asyncOptions.pick(option, 'option'),
            getOptions: asyncOptions.create(new Gecko.Option().orderBy('name').rfields({ option: ['name'] })),
            type: Gecko.Field.TYPE_SELECT,
            hideWhen: function hideWhen() {
                return !ctrl.multipleChoiceTypes[ctrl.field.type];
            }
        }, {
            label: 'Required',
            description: "Choose whether this field is required before saving.",
            colClass: 'no-padding',
            key: 'required',
            type: Gecko.Field.TYPE_TOGGLE
        }];

        // Button setup
        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                ctrl.field.save().then(function (data) {
                    GeckoUI.messenger.success('Organisation Field has been saved');
                    unsavedAlert.clear();
                    $state.go('organisationfields');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }, {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.organisation_field_id == 0 || ctrl.field.system;
            },
            action: function action() {
                if (GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_DELETE)) {
                    GeckoUI.dialog.confirm('Are you sure you want to delete this organisation field?', function (value) {
                        if (value) {
                            unsavedAlert.clear();
                            ctrl.field.remove().then(function () {
                                // Remove it from the array
                                GeckoUI.messenger.success('Field has been deleted');
                                $state.go('organisationfields');
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        }
                    });
                }
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Organisation Fields',
            click: function click() {
                $state.go('organisationfields');
            }
        }, {
            label: ctrl.field.label ? ctrl.field.label : 'Add Organisation Field',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('OrganisationFieldsCtrl', OrganisationFieldsCtrl).controller('OrganisationFieldEditCtrl', OrganisationFieldEditCtrl);
})();