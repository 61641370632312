(function () {
    'use strict';

    function eventSessionModalTimes() {

        return {
            restrict: 'E',
            scope: {
                event: '=',
                session: '='
            },
            template: '\n                <div>\n                    <session-time-edit\n                        errors="[]" \n                        session="ctrl.session" \n                        event="ctrl.event">\n                    </session-time-edit>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: eventSessionModalTimesCtrl
        };
    }

    function eventSessionModalTimesCtrl(geckoDates) {
        var ctrl = this;
    }

    angular.module('GeckoEngage').directive('eventSessionModalTimes', eventSessionModalTimes).controller('eventSessionModalTimesCtrl', eventSessionModalTimesCtrl);
})();