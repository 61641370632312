(function () {
    'use strict';

    function sessionTimeList() {
        return {
            restrict: 'E',
            template: '\n            \t<div>\n\t            \t<div class="gecko-feed-item-list" ng-if="ctrl.session.times">\n\t\t            \t<a ui-sref="session.overview({event_id: ctrl.session.parent_id, session_id: ctrl.session.id, session: time.id})" class="clearfix" ng-repeat="time in ctrl.session.times track by $index">\n\t\t\t                \n                            {{ctrl.title(time)}} {{ctrl.time(time)}}\n\n                            <session-status-badge \n                                class="pull-right"\n                                maximum="ctrl.session.maximum"\n                                count-exc-guests="time.total_single_attendances || 0"\n                                count-inc-guests="time.total_attendances || 0">\n                            </session-status-badge>\n                            \n\t\t\t            </a>\n\t\t\t        </div>\n\n\t\t\t        <div ng-if="!ctrl.session.times">\n\t\t            \t<div class="alert alert-warning">\n\t\t\t                Loading times...\n\t\t\t            </div>\n\t\t\t        </div>\n\t            </div>\n            ',
            scope: {
                session: '='
            },
            bindToController: true,
            controllerAs: 'ctrl',
            controller: sessionTimeListCtrl
        };
    }

    function sessionTimeListCtrl(geckoDates) {
        var ctrl = this;

        ctrl.title = function (time) {
            return (time.title || '').split(' ').slice(0, 3).join(' ');
        };
        ctrl.time = geckoDates.printStartTimeEndTime;
    }

    angular.module('GeckoEngage').controller('sessionTimeListCtrl', sessionTimeListCtrl).directive('sessionTimeList', sessionTimeList);
})();