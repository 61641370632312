var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/*! sifter.js | https://github.com/brianreavis/sifter.js | Apache License (v2) */
(function (r, t) {
  if (typeof define === "function" && define.amd) {
    define(t);
  } else if ((typeof exports === "undefined" ? "undefined" : _typeof(exports)) === "object") {
    module.exports = t();
  } else {
    r.Sifter = t();
  }
})(this, function () {
  var r = function r(_r, t) {
    this.items = _r;this.settings = t || { diacritics: true };
  };r.prototype.tokenize = function (r) {
    r = n(String(r || "").toLowerCase());if (!r || !r.length) return [];var t, e, o, s;var u = [];var a = r.split(/ +/);for (t = 0, e = a.length; t < e; t++) {
      o = i(a[t]);if (this.settings.diacritics) {
        for (s in f) {
          if (f.hasOwnProperty(s)) {
            o = o.replace(new RegExp(s, "g"), f[s]);
          }
        }
      }u.push({ string: a[t], regex: new RegExp(o, "i") });
    }return u;
  };r.prototype.iterator = function (r, t) {
    var e;if (o(r)) {
      e = Array.prototype.forEach || function (r) {
        for (var t = 0, e = this.length; t < e; t++) {
          r(this[t], t, this);
        }
      };
    } else {
      e = function e(r) {
        for (var t in this) {
          if (this.hasOwnProperty(t)) {
            r(this[t], t, this);
          }
        }
      };
    }e.apply(r, [t]);
  };r.prototype.getScoreFunction = function (r, t) {
    var e, n, i, o;e = this;r = e.prepareSearch(r, t);i = r.tokens;n = r.options.fields;o = i.length;var f = function f(r, t) {
      var e, n;if (!r) return 0;r = String(r || "");n = r.search(t.regex);if (n === -1) return 0;e = t.string.length / r.length;if (n === 0) e += .5;return e;
    };var s = function () {
      var r = n.length;if (!r) {
        return function () {
          return 0;
        };
      }if (r === 1) {
        return function (r, t) {
          return f(t[n[0]], r);
        };
      }return function (t, e) {
        for (var i = 0, o = 0; i < r; i++) {
          o += f(e[n[i]], t);
        }return o / r;
      };
    }();if (!o) {
      return function () {
        return 0;
      };
    }if (o === 1) {
      return function (r) {
        return s(i[0], r);
      };
    }if (r.options.conjunction === "and") {
      return function (r) {
        var t;for (var e = 0, n = 0; e < o; e++) {
          t = s(i[e], r);if (t <= 0) return 0;n += t;
        }return n / o;
      };
    } else {
      return function (r) {
        for (var t = 0, e = 0; t < o; t++) {
          e += s(i[t], r);
        }return e / o;
      };
    }
  };r.prototype.getSortFunction = function (r, e) {
    var n, i, o, f, s, u, a, c, p, l, h;o = this;r = o.prepareSearch(r, e);h = !r.query && e.sort_empty || e.sort;p = function p(r, t) {
      if (r === "$score") return t.score;return o.items[t.id][r];
    };s = [];if (h) {
      for (n = 0, i = h.length; n < i; n++) {
        if (r.query || h[n].field !== "$score") {
          s.push(h[n]);
        }
      }
    }if (r.query) {
      l = true;for (n = 0, i = s.length; n < i; n++) {
        if (s[n].field === "$score") {
          l = false;break;
        }
      }if (l) {
        s.unshift({ field: "$score", direction: "desc" });
      }
    } else {
      for (n = 0, i = s.length; n < i; n++) {
        if (s[n].field === "$score") {
          s.splice(n, 1);break;
        }
      }
    }c = [];for (n = 0, i = s.length; n < i; n++) {
      c.push(s[n].direction === "desc" ? -1 : 1);
    }u = s.length;if (!u) {
      return null;
    } else if (u === 1) {
      f = s[0].field;a = c[0];return function (r, e) {
        return a * t(p(f, r), p(f, e));
      };
    } else {
      return function (r, e) {
        var n, i, o, f, a;for (n = 0; n < u; n++) {
          a = s[n].field;i = c[n] * t(p(a, r), p(a, e));if (i) return i;
        }return 0;
      };
    }
  };r.prototype.prepareSearch = function (r, t) {
    if ((typeof r === "undefined" ? "undefined" : _typeof(r)) === "object") return r;t = e({}, t);var n = t.fields;var i = t.sort;var f = t.sort_empty;if (n && !o(n)) t.fields = [n];if (i && !o(i)) t.sort = [i];if (f && !o(f)) t.sort_empty = [f];return { options: t, query: String(r || "").toLowerCase(), tokens: this.tokenize(r), total: 0, items: [] };
  };r.prototype.search = function (r, t) {
    var e = this,
        n,
        i,
        o,
        f;var s;var u;o = this.prepareSearch(r, t);t = o.options;r = o.query;u = t.score || e.getScoreFunction(o);if (r.length) {
      e.iterator(e.items, function (r, e) {
        i = u(r);if (t.filter === false || i > 0) {
          o.items.push({ score: i, id: e });
        }
      });
    } else {
      e.iterator(e.items, function (r, t) {
        o.items.push({ score: 1, id: t });
      });
    }s = e.getSortFunction(o, t);if (s) o.items.sort(s);o.total = o.items.length;if (typeof t.limit === "number") {
      o.items = o.items.slice(0, t.limit);
    }return o;
  };var t = function t(r, _t) {
    if (typeof r === "number" && typeof _t === "number") {
      return r > _t ? 1 : r < _t ? -1 : 0;
    }r = String(r || "").toLowerCase();_t = String(_t || "").toLowerCase();if (r > _t) return 1;if (_t > r) return -1;return 0;
  };var e = function e(r, t) {
    var e, n, i, o;for (e = 1, n = arguments.length; e < n; e++) {
      o = arguments[e];if (!o) continue;for (i in o) {
        if (o.hasOwnProperty(i)) {
          r[i] = o[i];
        }
      }
    }return r;
  };var n = function n(r) {
    return (r + "").replace(/^\s+|\s+$|/g, "");
  };var i = function i(r) {
    return (r + "").replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
  };var o = Array.isArray || $ && $.isArray || function (r) {
    return Object.prototype.toString.call(r) === "[object Array]";
  };var f = { a: "[a\xC0\xC1\xC2\xC3\xC4\xC5\xE0\xE1\xE2\xE3\xE4\xE5\u0100\u0101]", c: "[c\xC7\xE7\u0107\u0106\u010D\u010C]", d: "[d\u0111\u0110\u010F\u010E]", e: "[e\xC8\xC9\xCA\xCB\xE8\xE9\xEA\xEB\u011B\u011A\u0112\u0113]", i: "[i\xCC\xCD\xCE\xCF\xEC\xED\xEE\xEF\u012A\u012B]", n: "[n\xD1\xF1\u0148\u0147]", o: "[o\xD2\xD3\xD4\xD5\xD5\xD6\xD8\xF2\xF3\xF4\xF5\xF6\xF8\u014C\u014D]", r: "[r\u0159\u0158]", s: "[s\u0160\u0161]", t: "[t\u0165\u0164]", u: "[u\xD9\xDA\xDB\xDC\xF9\xFA\xFB\xFC\u016F\u016E\u016A\u016B]", y: "[y\u0178\xFF\xFD\xDD]", z: "[z\u017D\u017E]" };return r;
});