(function () {
    'use strict';

    function LandingPagesRoute($stateProvider) {
        $stateProvider.state('landingpages', {
            url: "/landing_pages?{page}&{search}&{order}&{sort}",
            templateUrl: "/modules/landing_pages/views/index.e87d6c6f.html",
            controller: 'LandingPagesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Landing Pages',
            requires: [Gecko.ABILITY_LANDING_PAGES_VIEW],
            permissionCheck: function permissionCheck() {
                return Gecko.has(Gecko.Package.FEATURE_LANDING_PAGE);
            },
            resolve: {
                landing_pages: function landing_pages($stateParams) {
                    var landingPages = new Gecko.LandingPage().include('user').where('user_trashed', 1);
                    if ($stateParams.search) landingPages.search($stateParams.search);
                    if ($stateParams.order) landingPages.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    return landingPages.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ landingpage: ['published', 'created_at', 'title', 'url'], user: ['full_name', 'color'] }).get();
                }
            }
        }).state('landingpageadd', {
            url: "/landing_page_add",
            templateUrl: "/modules/landing_pages/views/add.e934a5c9.html",
            controller: 'LandingPageAddCtrl',
            controllerAs: 'ctrl',
            page_title: 'Add Landing Page',
            requires: [Gecko.ABILITY_LANDING_PAGES_CREATE],
            resolve: {
                landing_page: function landing_page() {
                    return new Gecko.LandingPage().create({
                        styles: {
                            primary_color: '#2C3E50',
                            text_color: '#2C3E50',
                            title_color: '#2C3E50',
                            subtitle_color: '#2C3E50',
                            background_color: '#ffffff'
                        }
                    });
                }
            }
        }).state('landingpage', {
            url: "/landing_pages/{landing_page_id}",
            templateUrl: "/modules/landing_pages/views/edit.86d0b461.html",
            controller: 'LandingPageCtrl',
            controllerAs: 'ctrl',
            page_title: 'Landing Page',
            requires: [Gecko.ABILITY_LANDING_PAGES_UPDATE],
            resolve: {
                landing_page: function landing_page($stateParams) {
                    return new Gecko.LandingPage().get($stateParams.landing_page_id).then(function (data) {
                        return data;
                    });
                },
                blocks: function blocks($stateParams) {
                    var includes = ['landing_page', 'event', 'form'];
                    var rFields = { event: ['title'], form: ['uuid', 'name', 'internal_name'] };

                    return new Gecko.Block().include(includes).rfields(rFields).where('landing_page_id', $stateParams.landing_page_id).get();
                },
                gecko_chat_channels: function gecko_chat_channels($stateParams) {
                    return new Gecko.BaseModel().call('/geckochat/channels', 'GET', {}, true).then(function (data) {
                        return {
                            geckoChatIntegrationExists: true,
                            data: data
                        };
                    }).catch(function (err) {
                        return {
                            geckoChatIntegrationExists: false,
                            data: []
                        };
                    });
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(LandingPagesRoute);
})();