(function () {
    'use strict';

    /*
        Examples
         // Basic passing in a string
        <gecko-tooltip content="'Hello world!'"></gecko-tooltip>
         // Bottom positioned and opened on click
        <gecko-tooltip content="'Bottom'" placement="bottom" trigger="click"></gecko-tooltip>
         // Using a predefined template
        <gecko-tooltip template="'example'"></gecko-tooltip>
    */

    function geckoTooltip() {
        return {
            restrict: 'E',
            template: '<i class="fa fa-question-circle" tooltip="{{ctrl.tooltip}}" tooltip-trigger="{{ctrl.trigger}}" tooltip-placement="{{ctrl.placement}}" ng-if="ctrl.tooltip" tooltip-is-open="true"></i>',
            controllerAs: 'ctrl',
            scope: {
                content: '=',
                template: '=',
                placement: '@',
                trigger: '@'
            },
            controller: function controller($scope, $timeout) {
                var ctrl = this;

                /*
                 *  Any reusable tooltips should be defined here, until we can put these in a proper language file
                 */
                var templates = {
                    example: 'This is an example tooltip template'
                };

                if ($scope.template) {
                    ctrl.tooltip = templates[$scope.template];
                } else {
                    ctrl.tooltip = $scope.content;
                }

                ctrl.trigger = $scope.trigger || 'mouseenter';
                ctrl.placement = $scope.placement || 'top';
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoTooltip', geckoTooltip);
})();