(function () {
    'use strict';

    function SettingsUsageCtrl($state, $stateParams, $geckoModal) {
        var ctrl = this;

        if ($state.$current.name === 'usageandcosts') return $state.go('usageandcosts.transactions', $stateParams, { reload: true });

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Usage & Costs',
            active: true
        }];

        ctrl.tabs = [{
            title: 'Transactions',
            action: function action() {
                return $state.go('usageandcosts.transactions', {});
            },
            activeWhen: function activeWhen() {
                return $state.$current.name === 'usageandcosts.transactions';
            }
        }, {
            title: 'Call Usage',
            action: function action() {
                return $state.go('usageandcosts.voipusage', {});
            },
            activeWhen: function activeWhen() {
                return $state.$current.name === 'usageandcosts.voipusage';
            }
        }, {
            title: 'SMS Usage',
            action: function action() {
                return $state.go('usageandcosts.sms', {});
            },
            activeWhen: function activeWhen() {
                return $state.$current.name === 'usageandcosts.sms';
            }
        }];
    }

    angular.module('GeckoEngage').controller('SettingsUsageCtrl', SettingsUsageCtrl);
})();