(function () {
    'use strict';

    function FormPermissionsCtrl($scope, $state, form, user_groups, users) {
        var ctrl = this;
        ctrl.form = form;
        ctrl.users = users;
        ctrl.user_groups = user_groups;

        // Users - Prepare checkbox array
        var _form_users = GeckoUI.getIdArray(ctrl.form.related_users, 'id');
        ctrl.form_users = [];
        //Add NULL placeholders to checkbox array
        angular.forEach(ctrl.users, function (user) {
            if (_form_users.indexOf(user.id) != -1) {
                ctrl.form_users.push(user.id);
            } else {
                ctrl.form_users.push(null);
            };
        });

        // User Groups - Prepare checkbox array
        var _form_user_groups = GeckoUI.getIdArray(ctrl.form.related_user_groups, 'id');
        ctrl.form_user_groups = [];
        //Add NULL placeholders to checkbox array
        angular.forEach(ctrl.user_groups, function (user_group) {
            if (_form_user_groups.indexOf(user_group.id) != -1) {
                ctrl.form_user_groups.push(user_group.id);
            } else {
                ctrl.form_user_groups.push(null);
            };
        });

        var userSelect = function userSelect(remove) {
            ctrl.form_users.length = 0;
            angular.forEach(ctrl.users, function (user) {
                if (!remove) {
                    ctrl.form_users.push(user.id);
                } else {
                    ctrl.form_users.push(null);
                };
            });
        };

        var userGroupSelect = function userGroupSelect(remove) {
            ctrl.form_user_groups.length = 0;
            angular.forEach(ctrl.user_groups, function (user_group) {
                if (!remove) {
                    ctrl.form_user_groups.push(user_group.id);
                } else {
                    ctrl.form_user_groups.push(null);
                };
            });
        };

        //Header buttons
        ctrl.usersHeaderBtns = [{
            render: function render() {
                return (ctrl.form_users.indexOf(null) == -1 ? 'Remove' : 'Select') + ' All';
            },
            action: function action() {
                if (ctrl.form_users.indexOf(null) == -1) {
                    userSelect(true);
                } else {
                    userSelect();
                };
            }
        }];

        ctrl.userGroupsHeaderBtns = [{
            render: function render() {
                return (ctrl.form_user_groups.indexOf(null) == -1 ? 'Remove' : 'Select') + ' All';
            },
            action: function action() {
                if (ctrl.form_user_groups.indexOf(null) == -1) {
                    userGroupSelect(true);
                } else {
                    userGroupSelect();
                };
            }
        }];

        //Save form permissions (function)
        ctrl.saveForm = function () {

            var _form = angular.copy(ctrl.form);

            //Prepare users array
            _form.users = [];
            angular.forEach(ctrl.form_users, function (user) {
                if (user) {
                    _form.users.push(user);
                };
            });
            // Should not be able to remove yourself from form
            if (_form.users.indexOf(Gecko.user.id) === -1) _form.users.push(Gecko.user.id);

            //Prepare user groups array
            _form.user_groups = [];
            angular.forEach(ctrl.form_user_groups, function (user_group) {
                if (user_group) {
                    _form.user_groups.push(user_group);
                };
            });

            //Save form
            _form.save().then(function () {
                GeckoUI.messenger.success('Form permissions updated');
            }).catch(function (err) {
                return GeckoUI.messenger.error(err.errors);
            });
        };

        //Save form permissions (listener)
        $scope.$on('saveForm:' + $state.$current.name, ctrl.saveForm);
    }

    angular.module('GeckoEngage').controller('FormPermissionsCtrl', FormPermissionsCtrl);
})();