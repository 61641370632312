(function () {
    'use strict';

    function compileHtml($compile) {
        return {
            restrict: 'A',
            scope: {
                compileHtml: '='
            },
            link: function link(scope, element, attrs) {
                element.html(scope.compileHtml);
                $compile(element.contents())(scope);
            }
        };
    }

    angular.module('GeckoEngage').directive('compileHtml', compileHtml);
})();