(function () {
    'use strict';

    function broadcastConsentAlert() {
        return {
            restrict: 'E',
            template: '\n            \t<gecko-card>\n\t            \t<gecko-card-body padded="true">\n\t            \t\t<div class="alert alert-danger">\n\n\t            \t\t\tContacts will recieve {{ctrl.broadcast.module}} messages from this broadcast if they have consented to the following:\n\n\t                        <ul style="padding-left:20px; margin:10px 0;">\n\t                        \t<li ng-repeat="c in ctrl.consents track by $index">\n\t                        \t\t<strong>{{c.title}}</strong>\n\t                        \t</li>\n\t                        </ul>\n\n\t                        Contacts that have not consented will be <strong>excluded</strong> from the broadcast. For more information on consent, click <a href="https://academy.geckoengage.com/en/articles/6884211-consent#h_7a502db609">here</a>.\n\n\t            \t\t</div>\n\t            \t</gecko-card-body>\n            \t</gecko-card>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                broadcast: '=',
                consents: '='
            },
            controller: function controller() {
                var ctrl = this;
            }
        };
    }

    angular.module('GeckoEngage').directive('broadcastConsentAlert', broadcastConsentAlert);
})();