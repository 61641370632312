
(function () {
    'use strict';

    function MessagesCtrl($state, $stateParams, $filter, $geckoModal, messages) {
        var ctrl = this;
        ctrl.messages = messages.toArray();
        ctrl.pagination = messages.pagination;

        ctrl.dateFrom = $stateParams.from;
        ctrl.dateTo = $stateParams.to;

        // Table structure
        ctrl.cols = [{
            key: 'direction',
            data_type: 'image',
            render: function render(value) {
                if (value == Gecko.Message.TYPE_INBOUND) {
                    return 'Inbound Message';
                }
                if (value == Gecko.Message.TYPE_OUTBOUND) {
                    return 'Outbound Message';
                }
            },
            renderSrc: function renderSrc(message) {
                if (message.direction == Gecko.Message.TYPE_INBOUND) {
                    return '/images/icons/direction-inbound.c27978f6.svg';
                }
                if (message.direction == Gecko.Message.TYPE_OUTBOUND) {
                    return '/images/icons/direction-outbound.fa535824.svg';
                }
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Sent',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-2'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'User',
            key: 'sender.name',
            colClass: 'col-xs-2',
            render: function render(value, col, message) {
                if (message.sender) {
                    return message.sender.name;
                } else if (message.user) {
                    return message.user.full_name;
                }
            }
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Contact Name',
            key: 'contact.full_name',
            colClass: 'col-xs-2',
            action: function action(row) {
                return $state.go('contact.overview', { contact_id: row.contact.id });
            }
        }, {
            title: 'Type',
            key: 'type',
            sortKey: 'type',
            colClass: 'col-xs-2',
            render: function render(value) {
                if (value === Gecko.Message.TYPE_SMS) return value.toUpperCase();
                return $filter('capitalize')(value);
            }
        }, {
            title: 'Subject',
            key: 'subject',
            sortKey: 'subject',
            colClass: 'col-xs-3'
        }, {
            title: 'Status',
            key: 'status',
            status_styles: Gecko.Message.status_titles,
            colClass: 'col-xs-1',
            render: function render(val) {
                return val || 'unknown';
            }
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            id: 'view_message',
            title: 'View message',
            action: function action(message) {
                new Gecko.Message().rfields({ message: ['body'] }).get(message.id).then(function (msg_with_body) {
                    message.body = msg_with_body.body;
                    $geckoModal.messageViewModal(message);
                });
            }
        }, {
            id: 'view_activity',
            title: 'View message activity',
            action: function action(message) {
                if (message.workflow_id) {
                    new Gecko.Workflow().include('form', 'event', 'campaign').get(message.workflow_id).then(function (workflow) {
                        var workflowInfo;
                        workflowInfo = {
                            name: workflow.name,
                            aName: "",
                            aType: ""
                        };
                        if (workflow.campaign_id) {
                            workflowInfo.aName = workflow.related_campaign ? workflow.related_campaign.title : 'Unknown Campaign';
                            workflowInfo.aType = "campaign";
                        } else if (workflow.event_id) {
                            workflowInfo.aName = workflow.related_event ? workflow.related_event.title : 'Unknown Event';
                            workflowInfo.aType = "event";
                        } else if (workflow.import_id) {
                            workflowInfo.aName = workflow.related_import ? workflow.related_import.name : 'Unknown Import';
                            workflowInfo.aType = "import";
                        } else if (workflow.form_id) {
                            workflowInfo.aName = workflow.related_form ? workflow.related_form.internal_name ? workflow.related_form.internal_name : workflow.related_form.name : 'Unknown Form';
                            workflowInfo.aType = "form";
                        }

                        $geckoModal.messageEventsViewModal(message.message_events, workflowInfo);
                    });
                } else {
                    $geckoModal.messageEventsViewModal(message.message_events);
                }
            }
        }, {
            title: 'View message template',
            action: function action(message) {
                $state.go('settingstemplate.editor', { template_id: message.template_id });
            },
            hideWhen: function hideWhen(message) {
                return !message.template_id;
            }
        }, {
            id: 'send_email',
            title: 'Send an email',
            hideWhen: function hideWhen() {
                // Turn this off for people not in Gecko Labs account (for beta)
                return Gecko.account && [281, 594].indexOf(Gecko.account.routing_id) == -1;
            },
            action: function action(message) {
                // Check for contact email
                if (message.contact.email) {
                    var contact = angular.extend(new Gecko.Contact(), message.contact); // So the contact has the sendEmail method used in the modal
                    return $geckoModal.messageSendModal(contact);
                } else {
                    return new Gecko.Contact().get(message.contact.id).then(function (contact) {
                        if (contact.email) {
                            $geckoModal.messageSendModal(contact);
                        } else {
                            GeckoUI.dialog.alert('Add an email address to this contact to send a message.');
                        }
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                    });
                }
            }
        }];

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { label: object[labelKey], value: object.id };
                });
            };
        };

        ctrl.filters = [{
            title: 'Type',
            type: 'checkbox',
            options: objectsToOptions(Gecko.Message.type_titles)('title'),
            stateParam: 'type'
        }, {
            title: 'Templates sent',
            type: 'dropdown',
            optionsQuery: new Gecko.Template().rfields({ filter: ['name'] }).orderBy('name'),
            stateParam: 'template_id'
        }, {
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            }
        }, {
            title: 'Status',
            type: 'checkbox',
            options: objectsToOptions(Gecko.Message.status_titles.filter(function (s) {
                return s.id !== Gecko.Message.STATUS_SENDING;
            }))('title'),
            stateParam: 'status'
        }, {
            title: 'Direction',
            type: 'checkbox',
            options: objectsToOptions(Gecko.Message.direction_titles.filter(function (t) {
                return t.id !== null;
            }))('title'),
            stateParam: 'direction'
        }, {
            title: 'Saved Filters',
            type: 'dropdown',
            optionsQuery: new Gecko.Filter().where('saved', 1).rfields({ filter: ['name'] }).orderBy('name'),
            stateParam: 'filter_id'
        }];

        if (Gecko.able(Gecko.ABILITY_BROADCASTS_VIEW)) {
            ctrl.filters.push({
                title: 'Broadcasts',
                type: 'dropdown',
                optionsQuery: new Gecko.Campaign().rfields({ filter: ['title'] }).orderBy('title'),
                stateParam: 'campaign_id',
                optionsKey: 'id',
                optionsLabelKey: 'title'
            });
        }

        ctrl.filteredStatus = GeckoUI.wrapArray($stateParams.status);
        ctrl.filteredTypes = GeckoUI.wrapArray($stateParams.type);
        ctrl.filteredDirection = GeckoUI.wrapArray($stateParams.direction);

        // Reset filter
        ctrl.filterReset = function () {
            return $state.go('messages', { filter_id: null }, { reload: true });
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Messages',
            click: function click() {
                $state.go('messages');
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('MessagesCtrl', MessagesCtrl);
})();