(function () {

    'use strict';

    function NumbersRoutes($stateProvider) {
        $stateProvider.state('settingsnumbers', {
            url: "/settings/numbers?{page}&{order}&{sort}&{search}&{status}&{sms_status}",
            templateUrl: "/modules/numbers/views/index.a977aadf.html",
            controller: 'SettingsNumbersCtrl',
            controllerAs: 'ctrl',
            page_title: 'Numbers',
            requires: [Gecko.ABILITY_NUMBERS_VIEW],
            redirectKey: 'module_numbers',
            resolve: {
                numbers: function numbers($stateParams, Gecko) {
                    var order = $stateParams.order !== undefined ? $stateParams.order : 'original',
                        sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC';

                    var numbers = new Gecko.Number().include('contacts:all');

                    if ($stateParams.search) numbers.search($stateParams.search);
                    if ($stateParams.status) numbers.where('number_do_not_call', $stateParams.status);
                    if ($stateParams.sms_status) {
                        if ($stateParams.sms_status == 0) {
                            numbers.where('number_do_not_sms', $stateParams.sms_status);
                            numbers.where('number_do_not_sms', 'null');
                        } else {
                            numbers.where('number_do_not_sms', $stateParams.sms_status);
                        }
                    }

                    return numbers.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ number: ['do_not_call', 'do_not_sms', 'original', 'cleaned', 'national'], contact: ['full_name'] }).get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(NumbersRoutes);
})();