(function () {

    function ContactModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('contactFind', function (_limit) {
            return {
                size: 'lg',
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/contact-find/contact-find-modal.html',
                controller: 'contactFindCtrl',
                backdrop: 'static',
                resolve: {
                    limit: function limit() {
                        return _limit;
                    },
                    savedSearches: function savedSearches() {
                        return new Gecko.Filter().include('conditions').where('saved', 1).orderBy('name').perPage('all').get();
                    }
                }
            };
        });
    }

    function contactFindCtrl($scope, $modalInstance, limit, savedSearches) {

        var ctrl = this;
        ctrl.selected = [];
        ctrl.contacts = [];
        ctrl.search = {};
        ctrl.limit = limit;
        ctrl.selectSingle = limit === 1;
        ctrl.savedSearches = savedSearches.toArray();

        if (limit) {
            $scope.$watch(function () {
                return ctrl.selected.length;
            }, function (newValue, oldValue) {
                if (newValue !== oldValue && newValue > limit) {
                    ctrl.selected.splice(0, newValue - limit);
                }
            });
        }

        ctrl.cols = [{
            title: 'ID',
            key: 'id'
        }, {
            title: 'Full Name',
            key: 'full_name',
            type: Gecko.Field.TYPE_NAME
        }, {
            title: 'Email',
            key: 'email',
            type: Gecko.Field.TYPE_EMAIL
        }];

        ctrl.selectOptions = {
            noPersist: true
        };

        ctrl.footerBtns = [{
            title: 'Done',
            preset: 'check',
            action: function action() {
                if (ctrl.selected.length) {
                    if (ctrl.limit === 1) {
                        $modalInstance.close(ctrl.selected[0]);
                    } else {
                        $modalInstance.close([{ model: 'contact_ids', contact_ids: ctrl.selected }]);
                    }
                } else {
                    $modalInstance.dismiss();
                }
            }

        }, {
            title: 'Add all matching this saved search',
            action: function action() {
                $modalInstance.close({ conditions: ctrl.savedSearch.conditions, requirement: ctrl.savedSearch.requirement });
            },
            hideWhen: function hideWhen() {
                return !ctrl.savedSearch || ctrl.limit === 1;
            }
        }];

        var setContacts = function setContacts(contacts) {
            ctrl.contacts = contacts.toInstances();
            ctrl.pagination = contacts.pagination;
            ctrl.pagination.hideLimit = true;
            $scope.$digest();
        };

        // Search contacts
        ctrl.searchContacts = function (page) {
            page = page ? page.$page : 1;

            if (!ctrl.savedSearch) {
                new Gecko.Contact().where('keyword', ctrl.search.keyword).page(page).perPage(Gecko.DEFAULT_PER_PAGE).get().then(setContacts);
            } else {
                new Gecko.Contact().page(page).perPage(Gecko.DEFAULT_PER_PAGE).search(ctrl.savedSearch.conditions, ctrl.savedSearch.requirement).then(setContacts);
            }
        };

        ctrl.pickSavedSearch = function () {
            ctrl.searchContacts();
        };

        ctrl.submitKeyword = function () {
            ctrl.savedSearch = undefined;
            ctrl.searchContacts();
        };

        ctrl.searchContacts();
    }

    angular.module('GeckoEngage').config(ContactModals).controller('contactFindCtrl', contactFindCtrl);
})();