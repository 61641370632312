(function () {
    'use strict';

    function SettingsOutcomesCtrl($state, outcomes) {
        var ctrl = this;
        ctrl.outcomes = outcomes.toInstances();
        ctrl.pagination = outcomes.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'name',
            colClass: 'col-xs-4'

        }, {
            title: 'Action',
            key: 'action',
            colClass: 'col-xs-4',
            render: function render(action) {
                return GeckoUI.getObjectByKey(Gecko.Call.actions, 'id', action).title;
            }
        }, {
            title: 'Call Type',
            key: 'call_type',
            colClass: 'col-xs-4',
            render: function render(value) {
                if (value) {
                    return GeckoUI.gobk(Gecko.Outcome.call_type_titles, 'id', value).title;
                }
                return 'Both';
            }
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'settingsoutcome',
            params: { outcome_id: 'id' }
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Outcomes',
            active: true
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_OUTCOMES_DELETE);
            }
        };
        ctrl.rowOptionsBtn.items = [{
            title: 'Remove outcome',
            action: function action(outcome) {
                GeckoUI.dialog.confirm('Are you sure you want to delete this outcome?', function (value) {
                    if (value) {
                        outcome.remove().then(function () {
                            GeckoUI.messenger.success('Outcome has been deleted');
                            $state.reload();
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }];
    }

    function SettingsOutcomeCtrl($filter, $state, $stateParams, Gecko, unsavedAlert, outcome) {
        var ctrl = this;
        ctrl.outcome = outcome;
        if (ctrl.outcome.type == undefined) ctrl.outcome.type = 1;

        // Fields
        ctrl.fields = [{
            id: 'name',
            label: 'Name',
            required: true
        }, {
            id: 'call_type',
            label: 'Call Type',
            blankLabel: 'Both',
            required: true,
            type: Gecko.Field.TYPE_SELECT,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            options: Gecko.Outcome.call_type_titles
        }, {
            id: 'action',
            label: 'Action',
            description: 'Stipulate what should happen when this outcome is used. For more information on Actions, click <a href="https://academy.geckoengage.com/en/articles/6930194-outcomes" title="Call Outcomes">here</a>.',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            required: true,
            options: $filter('filter')(Gecko.Call.actions, { id: '!' + Gecko.Call.ACTION_DO_NOT_SMS }),
            optionsKey: 'id',
            optionsLabelKey: 'title'
        }, {
            id: 'require_note',
            label: 'Require note',
            type: Gecko.Field.TYPE_TOGGLE
        }, {
            type: Gecko.Field.TYPE_SECTION,
            colClass: 'col-xs-12',
            description: 'Enabling this will force call agents to add a text note when this outcome is picked.'
        }];

        // Save outcome
        ctrl.saveOutcome = function () {
            if (!ctrl.outcome.group) ctrl.outcome.group = Gecko.Outcome.GROUP_GLOBAL;
            if (!GeckoUI.ableWithReason(ctrl.outcome.id != 'new' ? Gecko.ABILITY_OUTCOMES_UPDATE : Gecko.ABILITY_OUTCOMES_CREATE)) {
                return;
            }
            ctrl.outcome.save().then(function (data) {
                GeckoUI.messenger.success('Outcome has been saved');
                unsavedAlert.clear();
                if ($stateParams.outcome_id == 'new') {
                    $state.go('settingsoutcome', {
                        outcome_id: data.id
                    });
                }
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        };

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            safeMode: true,
            action: ctrl.saveOutcome,
            hideWhen: function hideWhen() {
                return !Gecko.able(ctrl.outcome.id != 'new' ? Gecko.ABILITY_OUTCOMES_UPDATE : Gecko.ABILITY_OUTCOMES_CREATE);
            }
        }, {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.outcome_id == 'new' || !Gecko.able(Gecko.ABILITY_OUTCOMES_DELETE);
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this outcome?', function (value) {
                    if (value) {
                        unsavedAlert.clear();
                        ctrl.outcome.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Outcome has been deleted');
                            $state.go('settingsoutcomes');
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Outcomes',
            click: function click() {
                $state.go('settingsoutcomes');
            }
        }, {
            label: ctrl.outcome.name ? ctrl.outcome.name : 'Add Outcome',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('SettingsOutcomesCtrl', SettingsOutcomesCtrl).controller('SettingsOutcomeCtrl', SettingsOutcomeCtrl);
})();