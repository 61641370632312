(function () {
    'use strict';

    function capitalize() {
        return function (input) {
            return input.substring(0, 1).toUpperCase() + input.substring(1);
        };
    }

    function nl2br() {
        return function (text, isParagraph) {
            var is_xhtml = is_xhtml || true;
            var breakTag = is_xhtml ? '<br />' : '<br>';
            var returnText = (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');

            if (returnText == 'null') {
                returnText = '';
            }

            // Prepare in paragraph format
            if (isParagraph && returnText.indexOf('<p>') === -1) {
                returnText = "<p>" + returnText.replace(/\r\n|\n\r|\r|\n|<br>|\<br\>|\<br \/\>|\<br\>\<\/br\>/g, '</p><p>') + '</p>';
            }

            return returnText;
        };
    }

    function characters() {
        return function (input, chars, breakOnWord) {
            if (isNaN(chars)) return input;
            if (chars <= 0) return '';
            if (input && input.length >= chars) {
                input = input.substring(0, chars);

                if (!breakOnWord) {
                    var lastspace = input.lastIndexOf(' ');
                    // Get last space
                    if (lastspace !== -1) {
                        input = input.substr(0, lastspace);
                    }
                } else {
                    while (input.charAt(input.length - 1) == ' ') {
                        input = input.substr(0, input.length - 1);
                    }
                }
                return input + '...';
            }
            return input;
        };
    }

    function words() {
        return function (input, words) {
            if (isNaN(words)) return input;
            if (words <= 0) return '';
            if (input) {
                var inputWords = input.split(/\s+/);
                if (inputWords.length > words) {
                    input = inputWords.slice(0, words).join(' ') + '...';
                }
            }
            return input;
        };
    }

    function reverse() {
        return function (items) {
            if (items !== undefined) {
                return items.slice().reverse();
            }
        };
    }

    function titlecase() {
        return function (s) {
            s = s === undefined || s === null ? '' : s;
            s = s.replace(/[\_\-]/g, ' ');
            return s.toString().toLowerCase().replace(/\b([a-z])/g, function (ch) {
                return ch.toUpperCase();
            });
        };
    }

    function idify() {
        return function (s, replace) {
            s = s === undefined || s === null ? '' : s;
            s = s.replace(/\s+/g, replace || '-');
            return s.toString().toLowerCase();
        };
    }

    function makeArray() {
        return function (object, wrap) {
            if (angular.isArray(object)) return object;
            if (typeof object == 'string' || typeof object == 'number' || wrap != undefined) return [object];
            var _array = [];
            angular.forEach(object, function (item) {
                _array.push(item);
            });
            return _array;
        };
    }

    function greaterThan() {
        return function (array, key, val) {
            var _array = [];
            for (var i = 0; i < array.length; i++) {
                if (array[i][key] > val) _array.push(array[i]);
            }
            return _array;
        };
    }

    function lessThan() {
        return function (array, key, val) {
            var _array = [];
            for (var i = 0; i < array.length; i++) {
                if (array[i][key] < val) _array.push(array[i]);
            }
            return _array;
        };
    }

    function highlightFields() {
        var templateRE = /([(?:.|\n)]*?)(\{\{[(?:.|\n)]*?\}\})([(?:.|\n)]*)/m;

        return function (text) {
            var split = templateRE.exec(text);
            var output = '';

            while (split) {
                if (split[1]) output += split[1];
                output += '<strong>';
                if (split[2]) output += split[2];
                output += '</strong>';

                text = split[3];
                split = templateRE.exec(text);
            }

            output += text;

            return output;
        };
    }

    function joinArray() {
        return function (array, sep, limit, fallback) {
            if (!angular.isArray(array)) return array;
            if (typeof limit === 'undefined') limit = 10;
            limit = limit <= 1 ? 1 : limit;
            var total = array.length;
            if (array.length > limit) {
                array = array.slice(0, limit);
                array.push(' and ' + (total - limit) + ' more');
            }

            if (fallback) array = array.map(function (i) {
                return !i ? fallback : i;
            });

            return array.join(sep);
        };
    }

    function round() {
        return function (number) {
            return Math.round(number);
        };
    }

    function sanatizePhoneNumber() {
        return function (number, testNum) {
            var _number = "";
            _number = number.replace(new RegExp(/\+|\s/, "g"), "");
            if (testNum) return _number[0] && _number[0] != "0";
            return _number;
        };
    }

    function linkify() {
        function linkify(_str) {
            if (!_str) return;

            var _text = _str.replace(/(?:https?\:\/\/|www\.)+(?![^\s]*?")([\w.,@?!^=%&amp;:\/~+#-]*[\w@?!^=%&amp;\/~+#-])?/ig, function (url) {
                var wrap = document.createElement('div');
                var anch = document.createElement('a');
                anch.href = url;
                anch.target = "_blank";
                anch.innerHTML = url;
                wrap.appendChild(anch);
                return wrap.innerHTML;
            });

            // Bugfix
            if (!_text) return '';

            return _text;
        }

        return function (text, type) {
            return linkify(text, type);
        };
    }

    function httpify() {
        var testProtocol = /^.*:\/\//;
        return function (url) {
            if (testProtocol.test(url)) return url;
            return 'http://' + url;
        };
    }

    function stripTags() {
        return function (text) {
            return text ? String(text).replace(/<[^>]+>/gm, '') : '';
        };
    }

    // Return the value given. Useful as a default filter.
    function identity() {
        return function (value) {
            return value;
        };
    }

    function apply($filter) {
        return function (value, filterName) {
            if (filterName) {
                return $filter(filterName)(value);
            }
            return value;
        };
    }

    function escapeString() {
        return function (string) {
            if (typeof string === 'string') return string.replace("'", '\\\'');
            return string;
        };
    }

    function humanizeBytes() {
        return function (value, precision) {
            return GeckoUI.humanizeBytes(value, precision);
        };
    }

    function call() {
        return function (value, args) {
            if (typeof value === 'function') {
                if (args && args instanceof Array) {
                    return value.apply(value, args);
                } else {
                    return value();
                }
            }
            return value;
        };
    }

    function constExpr() {
        return function (value) {
            if (typeof value === 'string') return "'" + value + "'";
            return value;
        };
    }

    function explode() {
        return function (input, splitChar, splitIndex, defaultResponse) {
            if (!input) {
                return defaultResponse;
            }
            return input.split ? input.split(splitChar)[splitIndex] : '';
        };
    }

    angular.module('GeckoEngage').filter('capitalize', capitalize).filter('nl2br', nl2br).filter('characters', characters).filter('words', words).filter('reverse', reverse).filter('titlecase', titlecase).filter('idify', idify).filter('makeArray', makeArray).filter('greaterThan', greaterThan).filter('lessThan', lessThan).filter('highlightFields', highlightFields).filter('joinArray', joinArray).filter('round', round).filter('sanatizePhoneNumber', sanatizePhoneNumber).filter('linkify', linkify).filter('httpify', httpify).filter('stripTags', stripTags).filter('identity', identity).filter('apply', apply).filter('escapeString', escapeString).filter('humanizeBytes', humanizeBytes).filter('call', call).filter('constExpr', constExpr).filter('explode', explode);
})();