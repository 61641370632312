(function () {
    'use strict';

    function DashboardCtrl($scope, $state, data, form_dashboard, call_dashboard, $geckoModal) {
        var vm = this;
        vm.canViewDashboards = Gecko.able(Gecko.ABILITY_DASHBOARDS_VIEW);
        vm.campaigns = data.campaigns ? data.campaigns.toInstances() : null;
        vm.responses = data.responses ? data.responses.toInstances() : null;
        vm.form_dashboard = form_dashboard ? form_dashboard.toInstance() : null;
        vm.call_dashboard = call_dashboard ? call_dashboard.toInstance() : null;
        vm.forms = data.forms ? data.forms.toInstances() : null;
        vm.calls = data.calls ? data.calls.toInstances() : null;
        vm.widget = null;
        vm.currentWidget = null;
        vm.activeWidget = null;

        // Show alert modal for mobile ONLY users
        if (Gecko.group.type === Gecko.Group.MOBILE) $geckoModal.authMobileModal();

        // Allow switching between dashboards
        vm.current_dashboard = 'form';
        // If they don't have form, but do have call, then show that as default
        if (!Gecko.has(Gecko.Package.FEATURE_FORM) && Gecko.has(Gecko.Package.FEATURE_CALL)) {
            vm.current_dashboard = 'call';
        }

        vm.showOverviewToggle = function () {
            return Gecko.has(Gecko.Package.FEATURE_FORM) && Gecko.has(Gecko.Package.FEATURE_CALL) && (vm.form_dashboard || vm.forms && vm.forms.length || vm.responses && vm.responses.length) && (vm.call_dashboard || vm.calls && vm.calls.length || vm.campaigns && vm.campaigns.length);
        };

        vm.chartOptions = {
            animation: false,
            responsive: true,
            maintainAspectRatio: true,
            dummyProp: false

            // Force chart re-render on window resize to stop a weird Canvas width issue
        };$(window).on('resize', function () {
            if ($state.current.name !== 'dashboard') {
                return;
            } // Only fire on the dashboard view
            vm.chartOptions.animation = false;
            vm.chartOptions.dummyProp = !vm.chartOptions.dummyProp;
            $scope.$digest();
        });

        Gecko.has(Gecko.Package.FEATURE_FORM) && Gecko.has(Gecko.Package.FEATURE_CALL);

        // Function to change between call, and form dasboards
        vm.changeDashboard = function (dashboard) {
            vm.current_dashboard = dashboard;
        };

        vm.getWidgetType = function (widget) {
            return widget.type === 'auto' ? widget.auto_type : widget.type;
        };

        // Form overview
        if (vm.form_dashboard) {
            angular.forEach(vm.form_dashboard.related_widgets, function (form_widget) {
                form_widget.data = [];
                form_widget.series = [];

                if (vm.getWidgetType(form_widget) === 'pie') {
                    form_widget.data = form_widget.related_stats[0].aggregates;
                } else {
                    angular.forEach(form_widget.related_stats, function (stat) {
                        form_widget.series.push(stat.title);
                        form_widget.data.push(stat.aggregates);
                    });
                }
            });
        }

        // Call overview
        if (vm.call_dashboard) {
            angular.forEach(vm.call_dashboard.related_widgets, function (call_widget) {
                call_widget.data = [];
                call_widget.series = [];

                if (vm.getWidgetType(call_widget) === 'pie') {
                    call_widget.data = call_widget.related_stats[0].aggregates;
                } else {
                    angular.forEach(call_widget.related_stats, function (stat) {
                        call_widget.series.push(stat.title);
                        call_widget.data.push(stat.aggregates);
                    });
                }
            });
        }
    }

    function DashboardsCtrl($state, $filter, dashboards, $geckoModal) {
        var ctrl = this;
        ctrl.dashboards = dashboards.toInstances();
        ctrl.pagination = dashboards.pagination;

        ctrl.addDashboard = function () {
            if (!GeckoUI.ableWithReason(Gecko.ABILITY_DASHBOARDS_CREATE)) return;
            $geckoModal.inputModal('Create a new dashboard', '', 'Enter a name for the new dashboard').result.then(function (val) {
                new Gecko.Dashboard().fill({ name: val }).save().then(function (dashboard) {
                    $state.go('dashboardview', { dashboard_id: dashboard.id });
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Dashboard Save Success', {
                        'dashboard id': dashboard.id,
                        'dashboard name': dashboard.name
                    });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            });
        };

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            colClass: 'col-md-3'
        }, {
            title: 'Name',
            key: 'name',
            colClass: 'col-md-3'
        }, {
            title: 'Type',
            key: 'type',
            colClass: 'col-md-3',
            render: function render(value) {
                if (!value) return 'Custom';
                return $filter('capitalize')(value);
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Widgets',
            key: 'widgets_count',
            colClass: 'col-md-3'
        }, {
            key: 'type',
            renderHtml: true,
            render: function render(type) {
                if (type != Gecko.Dashboard.TYPE_CUSTOM) return '<i class="fa fa-lock"></i>';
            }
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(dashboard) {
                return $state.go('dashboardview', { dashboard_id: dashboard.id, override_date: 'month' });
            }
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Dashboards',
            click: function click() {
                $state.go('dashboards');
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    function DashboardViewCtrl($scope, $state, $stateParams, $filter, dashboard, model, forms, scripts, campaigns, filters, snapshot, $timeout, LoadingIndicator, $geckoModal, OptionsPresets, geckoEventPickerService) {
        var ctrl = this;
        ctrl.dashboard = dashboard;
        ctrl.dashboard.related_widgets = ctrl.dashboard.related_widgets ? ctrl.dashboard.related_widgets : [];
        // DONT SHOW GEO WIDGETS (FOR NOW)
        ctrl.dashboard.related_widgets = ctrl.dashboard.related_widgets.filter(function (widget) {
            return widget.type !== Gecko.Widget.TYPE_GEO;
        });
        ctrl.snapshot = snapshot;
        ctrl.selectedSnapshotId = Number($stateParams.snapshot_id);
        ctrl.snapshots = ctrl.dashboard.related_snapshots ? ctrl.dashboard.related_snapshots : [];
        ctrl.forms = forms ? forms.toArray() : forms;
        ctrl.scripts = scripts ? scripts.toArray() : scripts;
        ctrl.campaigns = campaigns ? campaigns.toArray() : campaigns;
        ctrl.filters = filters ? filters.toArray() : false;
        ctrl.widget = null;
        ctrl.showCharts = false;
        ctrl.editing = false;
        ctrl.currentCategoryIndex = -1;
        ctrl.currentCategory = null;
        ctrl.callTypes = [{ id: null, title: 'Both' }].concat(Gecko.Call.type_titles);

        // Fire Google Analytics and Intercom Events
        if (ctrl.dashboard.type === 'contact') {
            GeckoUI.triggerIntercomEvent('Viewed Contact Dashboard', {
                'dashboard id': ctrl.dashboard.id,
                'dashboard title': ctrl.dashboard.name
            });
        }

        ctrl.getWidgetType = function (widget) {
            return widget.type === 'auto' ? widget.auto_type : widget.type;
        };

        ctrl.eventField = geckoEventPickerService.prepareField({
            optionsInit: OptionsPresets.events.init,
            options: [[], []],
            getOptions: OptionsPresets.events.get,
            placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
            optionsKey: 'id',
            optionsLabelKey: 'title',
            noBlank: [false, false]
        });

        /*
         * Options for charts
         * Templates are added to allow the legends to print in color (!important on background-color).
         */
        ctrl.pieOptions = { animation: false, segmentShowStroke: false, legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%> !important\"></span><%if(segments[i].label){%><%=segments[i].label + ' <strong>(' + segments[i].value + ')</strong>'%><%}%></li><%}%></ul>" };
        ctrl.barOptions = { animation: false, legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].fillColor%> !important\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>" };
        ctrl.lineOptions = { animation: false, legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].strokeColor%> !important\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>" };

        // Pre-defined date range
        ctrl.override_date = $stateParams.override_date ? $stateParams.override_date : '';
        ctrl.changeDate = function () {
            if (ctrl.override_date !== 'custom') {
                $state.go('dashboardview', { override_date: ctrl.override_date, date_from: null, date_to: null });
            }
        };

        // Custom date range
        if ($stateParams.date_from || $stateParams.date_to) {
            ctrl.date_from = $stateParams.date_from ? $stateParams.date_from : '';
            ctrl.date_to = $stateParams.date_to ? $stateParams.date_to : '';
            ctrl.override_date = 'custom';
        }

        ctrl.setCustomRange = function () {
            $state.go('dashboardview', { date_from: ctrl.date_from, date_to: ctrl.date_to, override_date: null }, { reload: true });
        };

        ctrl.clearCustomRange = function () {
            if ($stateParams.date_from || $stateParams.date_to) {
                $state.go('dashboardview', { override_date: '', date_from: '', date_to: '' }, { reload: true });
            } else {
                ctrl.override_date = '';
                ctrl.date_from = '';
                ctrl.date_to = '';
            }
        };

        // Form id
        ctrl.form_id = parseInt($stateParams.form_id) || undefined;
        ctrl.changeForm = function () {
            $state.go('dashboardview', {
                dashboard_id: ctrl.form_id ? null : ctrl.dashboard.id,
                form_id: ctrl.form_id,
                event_id: ctrl.form_id ? ctrl.event_id || null : null
            });
        };

        // Script id
        ctrl.script_id = parseInt($stateParams.script_id) || undefined;
        ctrl.changeScript = function () {
            $state.go('dashboardview', {
                dashboard_id: ctrl.script_id ? null : ctrl.dashboard.id,
                script_id: ctrl.script_id
            });
        };

        // Campaign id
        ctrl.campaign_id = parseInt($stateParams.campaign_id) || undefined;
        ctrl.changeCampaign = function () {
            var params = { dashboard_id: ctrl.campaign_id ? null : ctrl.dashboard.id, campaign_id: ctrl.campaign_id };
            // Set the correct module
            if (GeckoUI.getObjectByKey(ctrl.campaigns, 'id', ctrl.campaign_id).module) params.module = GeckoUI.getObjectByKey(ctrl.campaigns, 'id', ctrl.campaign_id).module;
            $state.go('dashboardview', params);
        };

        ctrl.call_type = parseInt($stateParams.call_type) || null;
        ctrl.changeCallType = function () {
            $state.go('dashboardview', { dashboard_id: ctrl.dashboard.id, call_type: ctrl.call_type });
        };

        // Event id
        ctrl.event_id = parseInt($stateParams.event_id) || undefined;
        ctrl.changeEvent = function () {
            $state.go('dashboardview', { dashboard_id: ctrl.event_id ? null : ctrl.dashboard.id, event_id: ctrl.event_id });
        };

        // Filter id
        ctrl.filter_id = parseInt($stateParams.filter_id) || null;
        ctrl.changeFilter = function () {
            $state.go('dashboardview', { filter_id: ctrl.filter_id });
        };

        var resetWidgets = function resetWidgets(show) {
            var _resetArray = ctrl.dashboard.related_widgets;

            $timeout(function () {
                if (show !== undefined) {
                    angular.forEach(_resetArray, function (item, i) {
                        ctrl.showCharts[i] = true;
                    });
                } else {
                    ctrl.showCharts = [];
                    angular.forEach(_resetArray, function (item, i) {
                        ctrl.showCharts[i] = false;
                    });
                }
            }, 300);
        };
        resetWidgets();
        resetWidgets(true);

        var buildStats = function buildStats(widgets) {
            if (widgets == undefined) widgets = ctrl.dashboard.related_widget;
            angular.forEach(widgets, function (widget) {
                // Check widget has data
                if (widget.related_stats && widget.related_stats[0] && widget.related_stats[0].aggregates // check it has related stats
                && (widget.related_stats[0].aggregates == null || widget.related_stats[0].aggregates.length > 0) // check the aggergates isn't null and has values
                && !(widget.related_stats[0].aggregates.length === 1 && widget.related_stats[0].aggregates[0] == null) // check that the aggregates doesn't just have a null value
                ) {
                        widget.has_data = true;
                    } else if (widget.related_stats && widget.type === 'integer' && widget.related_stats[0] && Number.isInteger(widget.related_stats[0].result) && widget.related_stats[0].result >= 0) {
                    widget.has_data = true;
                } else {
                    widget.has_data = false;
                }
                widget.data = [];
                widget.series = [];

                if (!widget.related_stats) return;

                if (ctrl.getWidgetType(widget) === 'pie') {
                    widget.data = widget.related_stats[0].aggregates;
                } else if (ctrl.getWidgetType(widget) === 'column_table') {
                    var _head = {};
                    var _data = {};
                    var _foot = {};

                    angular.forEach(widget.related_stats, function (stat, i) {
                        // Build Head
                        _head['stat_' + i] = {
                            id: 'stat_' + i,
                            title: stat.title,
                            unit: stat.unit

                            // Build data
                        };angular.forEach(stat.result, function (result) {
                            if (_data['user_' + result.user_id] == undefined) _data['user_' + result.user_id] = {};
                            if (_data['user_' + result.user_id].name == undefined) _data['user_' + result.user_id].name = result.title;
                            if (_data['user_' + result.user_id].stats == undefined) _data['user_' + result.user_id].stats = {};
                            _data['user_' + result.user_id].stats['stat_' + i] = {
                                title: stat.title,
                                aggregate: result.aggregate,
                                unit: stat.unit
                            };
                        });

                        _foot['stat_' + i] = {
                            id: 'stat_' + i,
                            title: stat.title,
                            unit: stat.unit,
                            total: 0
                        };
                    });

                    widget.data = {};
                    widget.data.head = [];
                    widget.data.foot = [];
                    angular.forEach(_head, function (col) {
                        widget.data.head.push(_head[col.id]);
                        widget.data.foot.push(_foot[col.id]);
                    });

                    widget.data.data = [];
                    angular.forEach(_data, function (row) {
                        // Get row total time
                        var col, c;
                        row.total_time = 0;
                        for (c = 0; c < widget.data.head.length; ++c) {
                            col = widget.data.head[c];
                            // Skip the row if the total calls column is 0/undefined
                            if (col.unit === 'Calls' && !row.stats[col.id]) return;
                            if (row.stats[col.id] && ['Idle (total)', 'Prep (total)', 'On Call (total)', 'Wrap (total)'].indexOf(row.stats[col.id].title) != -1) {
                                row.total_time += Number(row.stats[col.id].aggregate);
                            }
                        }

                        for (c = 0; c < widget.data.head.length; ++c) {
                            col = widget.data.head[c];

                            // Skip the row if the total calls column is 0/undefined
                            if (col.unit === 'Calls' && !row.stats[col.id]) return;

                            if (row.stats[col.id] == undefined) row.stats[col.id] = {
                                aggregate: 0,
                                unit: _head[col.id].unit,
                                title: col.title
                            };
                            if (row.stats[col.id] != undefined && row.stats[col.id].title == 'Time (total)') row.stats[col.id].aggregate = row.total_time;
                            row.stats[col.id].aggregate = Number(row.stats[col.id].aggregate);

                            // Calculate percentages for the totals columns
                            if (row.stats[col.id] && ['Idle (total)', 'Prep (total)', 'On Call (total)', 'Wrap (total)'].indexOf(row.stats[col.id].title) != -1) {
                                row.stats[col.id].percentage = (row.stats[col.id].aggregate / row.total_time * 100).toFixed(1);
                                // Account for the dodgy cases where the total somehow equals 0
                                if (isNaN(row.stats[col.id].percentage)) row.stats[col.id].percentage = 0;
                                if (!isFinite(row.stats[col.id].percentage)) row.stats[col.id].percentage = 100;
                            }

                            // Add to col total and stat user count
                            widget.data.foot[c].total = widget.data.foot[c].total + Number(row.stats[col.id].aggregate);
                            if (!widget.data.foot[c].users) widget.data.foot[c].users = 0;
                            if (Number(row.stats[col.id].aggregate)) widget.data.foot[c].users++;
                        }
                        widget.data.data.push(row);
                    });

                    // Check combined totals and averages
                    angular.forEach(widget.data.foot, function (col) {
                        if (col.title.indexOf('avg') != -1) {
                            col.total = Math.round(col.total / col.users);
                        }
                    });
                } else {
                    angular.forEach(widget.related_stats, function (stat) {
                        if (stat.labels === "[Unknown]") {
                            stat.labels = ["No recorded data"];
                        }
                        widget.series.push(stat.title);
                        widget.data.push(stat.aggregates);
                    });
                }
            });
        };
        buildStats(ctrl.dashboard.related_widgets);
        buildStats(ctrl.dashboard.related_dynamic_widgets);

        var templateQuery = function templateQuery(search, category, page) {
            var templates = new Gecko.WidgetTemplate();
            if (search) templates.search(search);
            if (category) templates.where('category', category);
            if (page) templates.page(page);
            return templates.perPage(Gecko.DEFAULT_PER_PAGE).get();
        };

        ctrl.widgetTemplatesOpen = function (replace, searchTerm) {

            // Reset indexes
            ctrl.currentCategoryIndex = -1;
            ctrl.currentCategory = null;
            ctrl.widgetSearchTerm = searchTerm || "";
            ctrl.isSearching = false;

            if (replace == undefined) {
                ctrl.currentWidget = null;
                ctrl.activeWidget = null;
            }

            // Get categories
            ctrl.widgetCategories = Gecko.WidgetTemplate.categories;

            // Get templates
            templateQuery(searchTerm).then(function (templates) {
                ctrl.templates = templates.toArray();
                ctrl.templatesPagination = templates.pagination;
                $scope.$digest();
                $('#widget_template').modal('show');
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors[0]);
            });
        };

        ctrl.widgetSearch = function () {
            if (!ctrl.widgetSearchTerm) return false;
            ctrl.isSearching = true;
            templateQuery(ctrl.widgetSearchTerm).then(function (templates) {
                ctrl.currentCategoryIndex = -1;
                ctrl.currentCategory = null;
                ctrl.templates = templates.toArray();
                ctrl.templatesPagination = templates.pagination;
                $scope.$digest();
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors[0]);
            });
        };

        ctrl.changeTemplatesCategory = function (category, index) {
            templateQuery(category).then(function (templates) {
                ctrl.widgetSearchTerm = null;
                ctrl.isSearching = false;
                ctrl.currentCategoryIndex = index;
                ctrl.currentCategory = category;
                ctrl.templates = templates.toArray();
                ctrl.templatesPagination = templates.pagination;
                $scope.$digest();
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors[0]);
            });
        };

        ctrl.changeTemplatesPage = function (page) {
            if (page < 1 || page > ctrl.templatesPagination.last_page) return false;
            templateQuery(ctrl.isSearching && ctrl.widgetSearchTerm ? ctrl.widgetSearchTerm : ctrl.currentCategory, null, page).then(function (templates) {
                ctrl.templates = templates.toArray();
                ctrl.templatesPagination = templates.pagination;
                $scope.$digest();
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors[0]);
            });
        };

        ctrl.widgetTemplateSelect = function (template) {
            if (!template) return false;
            if (ctrl.currentWidget) {
                ctrl.widgetSettingsOpen(ctrl.currentWidget, template.id);
                $('#widget_template').modal('hide');
            } else {
                ctrl.widget = template;
                $('#widget_template').modal('hide');
                $('#widget_settings').modal('show');
            }
        };

        var coerseToArray = function coerseToArray(thing) {
            if (angular.isArray(thing)) {
                return thing;
            }
            return Object.keys(thing).map(function (key) {
                return thing[key];
            });
        };

        ctrl.widgetSettingsOpen = function (widget, templateId, index) {
            if (!widget || !widget.related_stats) return false;

            ctrl.activeWidget = widget;
            ctrl.currentWidget = angular.copy(widget);
            if (index !== undefined) ctrl.activeWidgetIndex = index;

            // Get widget template
            var template = new Gecko.WidgetTemplate();

            var templateAvailable = function templateAvailable(template) {

                // Get available widget template options
                ctrl.widget = ctrl.currentWidget;
                // This is plebby i know, brought back as a string and breaking the dropdown
                ctrl.widget['option_id'] = Number(ctrl.widget['option_id']);
                ctrl.widget.options = coerseToArray(template.options);
                ctrl.widget.options = ctrl.widget.options.map(function (option) {
                    if (option.key === 'filter_null') {
                        option.options = option.options.map(function (opt) {
                            opt.value = Number(opt.value);
                            return opt;
                        });
                    }
                    return option;
                });

                // Update widget with new template values
                if (ctrl.currentWidget && templateId) {
                    ctrl.widget.widget_template_id = template.id;
                    ctrl.widget.name = template.name;
                    ctrl.widget.type = template.type;
                    ctrl.widget.size = template.size;
                    // Ctrl.widget.date = ctrl.widget.related_stats[0].date;
                }

                $('#widget_settings').modal('show');

                $scope.$digest();
            };

            var templateError = function templateError() {
                ctrl.widgetTemplatesOpen(true, ctrl.currentWidget.related_stats[0].model);
            };

            if (templateId) {
                template.get(templateId).then(templateAvailable).catch(templateError);
            } else {
                template.findByWidgetId(widget.id).then(templateAvailable).catch(templateError);
            }
        };

        // Save widget
        ctrl.widgetSave = function () {

            if (ctrl.activeWidgetIndex != undefined) {
                ctrl.showCharts[ctrl.activeWidgetIndex] = false;
            } else {
                // Ctrl.showCharts.push(false);
                ctrl.showCharts.splice(ctrl.dashboard.related_widgets.length, 0, false);
            }
            if (ctrl.currentWidget) {
                ctrl.widget.include('stats').hardSave().then(function (widget) {
                    if ($stateParams.form_id || $stateParams.campaign_id) {
                        $state.go('dashboardview', $stateParams, { reload: true });
                    } else {
                        ctrl.activeWidget = widget;
                        // Add widget to page
                        ctrl.dashboard.related_widgets[ctrl.activeWidgetIndex] = widget;
                        // Hide modal
                        $('#widget_settings').modal('hide');
                        // Show stats / reset
                        buildStats(ctrl.dashboard.related_widgets);
                        $timeout(function () {
                            ctrl.currentWidget = null;
                            ctrl.showCharts[ctrl.activeWidgetIndex] = true;
                            ctrl.activeWidgetIndex = undefined;
                        });
                        // Success message
                        GeckoUI.messenger.success('Widget saved.');
                        $scope.$digest();
                    }
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Dashboard Save Success', {
                        'dashboard id': ctrl.dashboard.id,
                        'dashboard name': ctrl.dashboard.name
                    });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);

                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Dashboard Save Error', {
                        'dashboard id': ctrl.dashboard.id || 'New',
                        'dashboard name': ctrl.dashboard.name,
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
                // Create Widget
            } else {
                ctrl.widget.dashboard_id = ctrl.dashboard.id;
                // Set widget order
                ctrl.widget.order = ctrl.dashboard.related_widgets.length;
                new Gecko.Widget().fromTemplate(ctrl.widget.id, ctrl.widget).then(function (widget) {
                    if ($stateParams.form_id || $stateParams.campaign_id) {
                        $state.go('dashboardview', $stateParams, { reload: true });
                    } else {
                        // Add widget to page
                        ctrl.dashboard.related_widgets.push(widget);
                        // Hide modal
                        $('#widget_settings').modal('hide');
                        $timeout(function () {
                            // Show stats
                            buildStats(ctrl.dashboard.related_widgets);
                            ctrl.showCharts[ctrl.dashboard.related_widgets.length - 1] = true;
                        });
                    }
                    // Success message
                    GeckoUI.messenger.success('Widget saved.');
                    $scope.$digest();
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Dashboard Save Success', {
                        'dashboard id': ctrl.dashboard.id,
                        'dashboard name': ctrl.dashboard.name
                    });
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Dashboard Save Error', {
                        'dashboard id': ctrl.dashboard.id || 'New',
                        'dashboard name': ctrl.dashboard.name,
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            }
        };

        // Remove widget
        ctrl.widgetRemove = function (widget, index) {
            GeckoUI.dialog.confirm('Are you sure you want to delete this Widget?', function (value) {
                if (value) {
                    ctrl.dashboard.related_widgets.splice(index, 1);
                    resetWidgets();
                    widget.remove().then(function () {
                        resetWidgets(true);
                        GeckoUI.messenger.success('Widget removed.');
                        $scope.$digest();
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors[0]);
                        resetWidgets(true);
                    });
                }
            });
        };

        // Reorder widget
        $scope.sortableOptions = {
            handle: '.widget-handle',
            items: "> div",
            placeholder: 'pull-left widget-placeholder',
            tolerance: 'pointer',
            start: function start(event, ui) {
                // Get and apply widget width
                $('.widget-placeholder').css('width', $(ui.item).innerWidth() - 31 + "px");
                resetWidgets();
            },
            stop: function stop() {

                // Apply new Order
                GeckoUI.refreshOrder(ctrl.dashboard.related_widgets, 0, 'order');
                var widgetOrder = GeckoUI.getIdArray($filter('orderBy')(ctrl.dashboard.related_widgets, 'order'));

                // Save new dashboard order
                var config = {};
                var _reorderQuery;
                if ($stateParams.form_id) {
                    config.form_id = $stateParams.form_id;
                    _reorderQuery = ctrl.dashboard.where({ date: 'month', form_id: $stateParams.form_id });
                } else if ($stateParams.campaign_id) {
                    config.campaign_id = $stateParams.campaign_id;
                    _reorderQuery = ctrl.dashboard.where({ date: 'month', campaign_id: $stateParams.campaign_id });
                } else {
                    _reorderQuery = ctrl.dashboard;
                }

                var reorderQuery = new Gecko.Dashboard().fill(_reorderQuery.toObject());
                reorderQuery.reorder(widgetOrder, config).then(function (dashboard) {

                    resetWidgets(true);

                    GeckoUI.messenger.success('Widget order updated.');
                    ctrl.dashboard = dashboard;
                    buildStats(ctrl.dashboard.related_widgets);
                    buildStats(ctrl.dashboard.related_dynamic_widgets);
                    $scope.$digest();
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors[0]);
                });
            }
        };

        // Column Table reOrder
        ctrl.reOrder = function (widget, key) {
            widget.sort = widget.orderingBy != key || !widget.sort ? true : false;
            widget.orderBy = 'stats.' + key + '.aggregate';
            widget.orderingBy = key;
        };

        /*
         * Snapshots
         */

        // Select Snapshot
        ctrl.selectSnapshot = function () {
            $state.go('dashboardsnapshotview', { dashboard_id: ctrl.dashboard.id, snapshot_id: ctrl.selectedSnapshotId });
        };

        ctrl.backToDashboard = function () {
            var queryParams = { dashboard_id: ctrl.dashboard.id };

            // TODO: need to be able to get the form or campaign that a
            //       Snapshot is made from to go back to that properly
            $state.go('dashboardview', queryParams);
        };

        /*
         *  Action buttons
         */

        ctrl.actionBtn = {
            title: 'Actions',
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary'
        };
        ctrl.actionBtn.items = [{
            title: 'Create a snapshot',
            hideWhen: function hideWhen() {
                return !!snapshot || !Gecko.able(Gecko.ABILITY_DASHBOARDS_UPDATE) || !Gecko.able(Gecko.ABILITY_DASHBOARDS_CREATE);
            },
            action: function action() {
                return $geckoModal.snapshotModal();
            }
        }, {
            title: 'Add widget',
            hideWhen: function hideWhen() {
                return !!snapshot;
            },
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_DASHBOARDS_UPDATE)) return;

                return ctrl.widgetTemplatesOpen();
            }
        }, {
            title: 'Rename dashboard',
            hideWhen: function hideWhen() {
                return !!snapshot;
            },
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_DASHBOARDS_UPDATE)) return;

                $geckoModal.inputModal('Rename dashboard', dashboard.name).result.then(function (val) {
                    dashboard.name = val;

                    new Gecko.Dashboard().fill(dashboard.toObject()).save().then(function () {
                        GeckoUI.messenger.success('Your dashboard has been renamed');
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.error);
                    });
                });
            }
        }];

        if (snapshot || dashboard.type == 'custom') {
            ctrl.actionBtn.items.push({
                title: 'Remove ' + (snapshot ? 'snapshot' : 'dashboard'),
                action: function action() {
                    if (!GeckoUI.ableWithReason(Gecko.ABILITY_DASHBOARDS_DELETE)) return;
                    var word = snapshot ? 'Snapshot' : 'Dashboard';
                    GeckoUI.dialog.confirm('Are you sure you want to remove this ' + word + '?', function (value) {
                        if (value) {
                            (snapshot || ctrl.dashboard).remove().then(function () {
                                if (snapshot) {
                                    if (ctrl.dashboard.type == 'form') {
                                        $state.go('dashboardview', { form_id: snapshot.form_id });
                                    } else {
                                        $state.go('dashboardview', { dashboard_id: ctrl.dashboard.id });
                                    }
                                } else {
                                    $state.go('dashboards');
                                }
                                GeckoUI.messenger.success(word + ' has been removed.');
                            });
                        }
                    });
                }
            });
        }

        // Breadcrumbs
        if (snapshot) {
            if (model && ($stateParams.campaign_id || $stateParams.form_id)) {
                $scope.breadcrumbs = [{
                    label: 'Report',
                    click: null
                }, {
                    label: $stateParams.campaign_id ? model.title : model.name,
                    click: function click() {
                        if ($stateParams.campaign_id) {
                            $state.go('dashboardview', { dashboard_id: ctrl.dashboard.id, campaign_id: $stateParams.campaign_id });
                        } else {
                            $state.go('dashboardview', { dashboard_id: ctrl.dashboard.id, form_id: parseInt($stateParams.form_id) });
                        }
                    }
                }, {
                    label: 'Snapshot: ' + ctrl.snapshot.name,
                    active: true
                }];
            } else {
                $scope.breadcrumbs = [{
                    label: 'Dashboards',
                    click: function click() {
                        $state.go('dashboards');
                    }
                }, {
                    label: ctrl.dashboard.name,
                    click: function click() {
                        $state.go('dashboardview', { dashboard_id: ctrl.dashboard.id });
                    }
                }, {
                    label: 'Snapshot: ' + ctrl.snapshot.name,
                    active: true
                }];
            }
        } else {
            if (model && ($stateParams.campaign_id || $stateParams.form_id)) {
                $scope.breadcrumbs = [{
                    label: 'Report',
                    click: null
                }, {
                    label: $stateParams.campaign_id ? model.title : model.name,
                    click: function click() {
                        if ($stateParams.campaign_id) {
                            $state.go('campaignsedit.view', { campaign_id: $stateParams.campaign_id, module: $stateParams.module });
                        } else {
                            $state.go('form.designer', { form_id: $stateParams.form_id });
                        }
                    },
                    active: true
                }];
            } else {
                $scope.breadcrumbs = [{
                    label: 'Dashboards',
                    click: function click() {
                        $state.go('dashboards');
                    }
                }, {
                    label: ctrl.dashboard.name ? ctrl.dashboard.name : 'Add Dashboard',
                    active: true
                }];
            }
        }
    }

    angular.module('GeckoEngage').controller('DashboardsCtrl', DashboardsCtrl).controller('DashboardViewCtrl', DashboardViewCtrl).controller('DashboardCtrl', DashboardCtrl);
})();