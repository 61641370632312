(function () {
    'use strict';

    function CallsAgentsCtrl($filter, Gecko, agents, campaigns) {
        var ctrl = this;
        ctrl.agents = !Array.isArray(agents) ? agents.toArray() : agents;

        ctrl.campaigns = campaigns.toArray();

        ctrl.cols = [{
            title: 'Agent Presence',
            status_styles: Gecko.User.presences,
            key: 'presence',
            sortKey: 'presence'
        }, {
            title: 'Name',
            key: 'full_name',
            sortKey: 'full_name'
        }, {
            title: 'Email',
            key: 'email',
            sortKey: 'email'
        }, {
            title: 'Last Active',
            key: 'last_active',
            sortKey: 'last_active',
            data_type: Gecko.Field.TYPE_DATETIME
        }, {
            title: 'Agent Status',
            key: 'status',
            render: function render(status) {
                return Gecko.User.statuses.find(function (statusLabels) {
                    return statusLabels.id === status;
                }).title;
            },
            sortKey: 'status'
        }, {
            title: 'Numbers Agent is Linked To',
            key: 'inbound_senders',
            render: function render(senders) {
                return senders.map(function (sender) {
                    return sender.name;
                }).join(', ');
            }
        }];

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey) {
                return objects.map(function (object) {
                    return { value: object.id, label: object[labelKey] };
                });
            };
        };
        ctrl.filters = [{
            title: 'Presence',
            type: 'checkbox',
            options: objectsToOptions(Gecko.User.presences)('title'),
            stateParam: 'presence'
        }, {
            title: 'Status',
            type: 'checkbox',
            options: objectsToOptions(Gecko.User.statuses)('title'),
            stateParam: 'status'
        }, {
            title: 'Campaign',
            type: 'multi',
            options: objectsToOptions(ctrl.campaigns)('title'),
            stateParam: 'campaign'
        }];
    }

    angular.module('GeckoEngage').controller('CallsAgentsCtrl', CallsAgentsCtrl);
})();