(function () {
    'use strict';

    function BroadcastLaunchCtrl($state, $stateParams, broadcast, selectedData, $filter) {
        var ctrl = this;
        ctrl.broadcast = broadcast;
        ctrl.template = selectedData.template && selectedData.template.name ? selectedData.template.name : 'Not Selected';
        ctrl.sender = selectedData.sender && selectedData.sender.name ? selectedData.sender.name : 'Not Selected';
        ctrl.count = ctrl.broadcast.subscribers_count || 0;

        if (ctrl.broadcast.start_date) {
            var start_message = [];
            start_message.push(ctrl.broadcast.end_date ? 'between' : 'on');
            start_message.push('<b>' + $filter('formatDate')(ctrl.broadcast.start_date) + '</b>');
            start_message.push(ctrl.broadcast.end_date ? 'and <b>' + $filter('formatDate')(ctrl.broadcast.end_date) + '</b>' : '');
            start_message = start_message.join(' ').trim();
        } else {
            var start_message = ' immediately';
        }

        if (ctrl.broadcast.refreshing) {
            ctrl.summaryMessage = 'The system is currently adding contacts into the broadcast (' + ctrl.broadcast.refreshing + '%). Please allow a few more minutes for it to complete.';
        } else {
            ctrl.summaryMessage = 'This broadcast will ' + ctrl.broadcast.module + ' <b>' + ctrl.count + '</b> contacts using the <b>' + ctrl.template + '</b> template from <b>' + ctrl.sender + '</b> ' + start_message + '.';
        }
        //ctrl.summaryTitle = `Workflow Summary: After the initial ${ctrl.broadcast.module} is sent out the following workflows will be triggered`;

        var buildConfirmMessage = function buildConfirmMessage() {
            return '\n                <div class="text-center">\n                    ' + (broadcast.start_date ? 'When the broadcast begins, you will ' : 'Careful: you are about to ') + ' ' + ctrl.broadcast.module + ' <b>' + ctrl.count + '</b> contacts.\n                    <br /><br />\n                    This action is not reversible.\n                </div>\n            ';
        };

        ctrl.launchBroadcast = function () {
            GeckoUI.dialog.confirm(buildConfirmMessage(), function (ok) {
                if (ok) {
                    ctrl.broadcast.status = Gecko.Campaign.ACTIVE;

                    ctrl.broadcast.save().then(function (broadcast) {
                        GeckoUI.messenger.success('Your broadcast has been launched.');

                        // Navigate to broadcast overview
                        return $state.go('broadcast.overview', { broadcast_id: broadcast.id, wizard: null }, { reload: true });
                    }).catch(function (broadcast) {
                        GeckoUI.messenger.error(broadcast.errors);
                    });
                }
            });
        };
    }

    angular.module('GeckoEngage').controller('BroadcastLaunchCtrl', BroadcastLaunchCtrl);
})();