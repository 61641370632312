(function () {
    'use strict';

    function RecurringImportEditOptionsCtrl($scope, $state, csv_import, import_fields, prepareImportMappingFieldOptions) {
        var ctrl = this;

        ctrl.csv_import = csv_import.toInstance();
        // Build errors array
        ctrl.errors = (ctrl.csv_import.related_activity || []).filter(function (a) {
            return a.type === 'error';
        });

        ctrl.csv_import.ftp = ctrl.csv_import.ftp || {};
        ctrl.csv_import.config = ctrl.csv_import.config || {};
        if (!ctrl.csv_import.config.processing_option) {
            ctrl.csv_import.config.processing_option = 'all';
        }
        ctrl.csv_import.schedule = ctrl.csv_import.schedule || new Gecko.Schedule();
        if (!ctrl.csv_import.schedule.cron) {
            ctrl.csv_import.schedule.cron = '0 0 * * 0';
        }
        ctrl.csv_import.selected_fields = ctrl.csv_import.selected_fields || [];

        ctrl.import_fields = [];
        ctrl.header_rows = true;
        ctrl.cols = [true, true, true, false, false];
        // Ensure we have a full-length list of selected fields to pass up
        if (typeof ctrl.csv_import.selected_fields[ctrl.csv_import.field_sample[0].length - 1] === 'undefined') {
            ctrl.csv_import.selected_fields[ctrl.csv_import.field_sample[0].length - 1] = null;
        }

        ctrl.getProcessingInfoText = function () {
            if (ctrl.csv_import.config.processing_option === 'all') {
                return "Gecko will scan the folder you point us to and we'll pick up any new files that haven't been imported yet. Once we pick up the CSV file, we will process every single row in the spreadsheet, creating contacts or merging contacts as needed.";
            }
            if (ctrl.csv_import.config.processing_option === 'newest') {
                return "Gecko will scan the folder you point us to and we'll pick up any new files that haven't been imported yet. Once we pick up the CSV file, we will ignore the amount of rows that have been processed in earlier imports and we will only process the newest rows in the spreadsheet. Use this option if your system exports out an incremented list of contact data in the same order.";
            }
            return null;
        };

        ctrl.optionFields = [{
            label: 'Title',
            id: 'title',
            colClass: 'col-xs-12',
            required: true
        }, {
            label: 'Frequency',
            id: 'cron',
            obj: ctrl.csv_import.schedule,
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            optionsKey: 'value',
            optionsLabelKey: 'label',
            options: [{
                label: 'every hour',
                value: '0 * * * *'
            }, {
                label: 'every 3 hours',
                value: '0 */3 * * *'
            }, {
                label: 'every 12 hours',
                value: '0 */12 * * *'
            }, {
                label: 'every 24 hours',
                value: '0 0 * * *'
            }, {
                label: 'every 7 days',
                value: '0 0 * * 0' // Every 7 days (sunday)
            }],
            colClass: 'col-xs-12',
            required: true
        }];

        ctrl.toggleFields = [{
            label: 'Ignore first row',
            id: 'skip_rows',
            colClass: 'col-xs-6',
            type: Gecko.Field.TYPE_TOGGLE,
            description: 'Data from the first row of the spreadsheet will be ignored. Use this if the columns in the spreadsheet have titles in the first row.'
        }, {
            label: 'Enforce validation rules',
            id: 'validation',
            colClass: 'col-xs-6',
            type: Gecko.Field.TYPE_TOGGLE,
            description: "Enable this option to prevent contacts that don't fit the validation or rules (e.g. email or date of birth not being valid) from being imported into Gecko"
        }];

        ctrl.ftpFields = [{
            label: 'Hostname',
            id: 'hostname',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'col-xs-12'
        }, {
            label: 'Port',
            id: 'port',
            type: Gecko.Field.TYPE_NUMBER,
            colClass: 'col-xs-12'
        }, {
            label: 'Path',
            id: 'path',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'col-xs-12'
        }, {
            label: 'Username',
            id: 'username',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'col-xs-12'
        }, {
            label: 'Password',
            id: 'password',
            type: Gecko.Field.TYPE_PASSWORD,
            colClass: 'col-xs-12'
        }];

        // Prepare field options
        if (ctrl.csv_import.import_to === 'responses') {
            // Make sure there is form fields!
            if (ctrl.csv_import.related_form && ctrl.csv_import.related_form.related_fields) {
                ctrl.import_fields = prepareImportMappingFieldOptions('responses', ctrl.csv_import.related_form.related_fields);
            } else {
                ctrl.import_fields = prepareImportMappingFieldOptions('responses', []);
            }
        } else {
            ctrl.import_fields = prepareImportMappingFieldOptions(ctrl.csv_import.import_to, import_fields ? import_fields.toArray() : []);
        }

        ctrl.saveChanges = function () {
            return ctrl.csv_import.save().then(function (csv_import) {
                ctrl.csv_import = csv_import;
                GeckoUI.messenger.success('Import options saved');
                $state.reload();
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                GeckoUI.triggerIntercomEvent('Recurring Import Options failed to save', {
                    'import id': ctrl.csv_import.id,
                    'import name': ctrl.csv_import.title,
                    'error': err.errors
                });
            });
        };

        // When we recieve the save event from the top level edit route save the Import
        $scope.$on('saveImport', ctrl.saveChanges);
    }

    angular.module('GeckoEngage').controller('RecurringImportEditOptionsCtrl', RecurringImportEditOptionsCtrl);
})();