(function () {
    'use strict';

    function geckoAddModalSearch() {
        return {
            restrict: 'E',
            template: '\n                <div class="col-md-12">\n                    <form style="width:auto; max-width:100%; margin-bottom:25px;" class="gecko-quicksearch gecko-ui-material input-group" ng-submit="ctrl.submitKeyword()">\n                        <span\n                            class="gecko-quicksearch-btn input-group-addon" >\n                            <i class="fa fa-{{ searching ? \'refresh\' : \'search\'}}"></i>\n                        </span>\n                        <input\n                            type="text"\n                            class="form-control pull-right"\n                            placeholder="Search for a contact..."\n                            ng-model="ctrl.search.keyword">\n                        <span class="input-group-btn">\n                            <button class="btn btn-default" type="submit">\n                                Search\n                            </button>\n                        </span>\n                    </form>\n\n                    <gecko-card-table\n                        select="ctrl.selectedContacts"\n                        select-options="ctrl.selectOptions"\n                        cols="ctrl.cols"\n                        rows="ctrl.contacts"\n                        row-action="ctrl.chooseContact"\n                        pagination="ctrl.pagination"\n                        on-page-change="ctrl.searchContacts"\n                        hide-card-header="true"\n                        no-header-transclude="true"\n                        no-results="Fetching contacts...">\n                    </gecko-card-table>\n                </div>\n            ',
            scope: {
                selectedContacts: '=',
                totalIsRefreshing: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, getContactsService) {
                var ctrl = this;
                ctrl.selectedContacts = ctrl.selectedContacts || [];
                ctrl.contacts = [];
                ctrl.search = {};
                ctrl.totalIsRefreshing = ctrl.totalIsRefreshing || false;
                ctrl.getContactsService = getContactsService;

                ctrl.cols = [{
                    title: 'Full Name',
                    key: 'full_name',
                    type: Gecko.Field.TYPE_NAME
                }, {
                    title: 'Email',
                    key: 'email',
                    type: Gecko.Field.TYPE_EMAIL
                }];

                ctrl.selectOptions = {
                    noPersist: true
                };

                var setContacts = function setContacts(contacts) {
                    ctrl.totalIsRefreshing = false;
                    ctrl.contacts = contacts.data;;
                    ctrl.pagination = contacts.pagination;
                    ctrl.pagination.hideLimit = true;
                    $scope.$digest();
                };

                // Search contacts
                ctrl.searchContacts = function (page, stopRefresh) {

                    if (!stopRefresh) ctrl.totalIsRefreshing = true;

                    page = page ? page.$page : 1;

                    ctrl.getContactsService.getContacts({
                        search: ctrl.search.keyword,
                        page: page,
                        perPage: Gecko.DEFAULT_PER_PAGE,
                        convertToResult: false
                    }).then(setContacts).catch(function (err) {
                        return ctrl.totalIsRefreshing = false;
                    });
                };

                ctrl.submitKeyword = function () {
                    ctrl.searchContacts();
                };

                ctrl.searchContacts(1, true);
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoAddModalSearch', geckoAddModalSearch);
})();