(function () {
    'use strict';

    function $geckoConf($geckoVoip, $geckoSocket, $timeout, $geckoWatch) {

        var _this = {

            enabled: false,

            callIncludes: ['contact', 'number', 'user', 'sender', 'campaign'],

            isConnected: false,
            callId: null,
            call: null,
            conn: null,

            is: function is(type, value) {
                return _this.call && _this.call[type] == value;
            },

            isInbound: function isInbound() {
                return _this.is('type', Gecko.Call.TYPE_INBOUND);
            },

            isOutbound: function isOutbound() {
                return _this.is('type', Gecko.Call.TYPE_OUTBOUND);
            },

            refresh: function refresh() {
                return _this.call.include(_this.callIncludes).get(_this.call.id);
            },

            prepareCall: function prepareCall(callId, type) {
                // Set call id
                _this.callId = callId;
                // Prepare call instance
                var call = new Gecko.Call().include(_this.callIncludes);
                // Return relevenat call promise
                if (type == 'join') {
                    return call.join(_this.callId);
                } else {
                    return call.listen(_this.callId);
                }
            },

            excecute: function excecute(call) {
                _this.isConnected = true;
                _this.call = call;
            },

            listen: function listen(callId) {
                // Listen to conf
                _this.prepareCall(callId, 'listen').then(function (call) {
                    // Handle conf
                    _this.excecute(call);
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Call Listen Error', {
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            },

            join: function join(callId) {
                // Join conf
                _this.prepareCall(callId, 'join').then(function (call) {
                    // Handle conf
                    _this.excecute(call);
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    // Fire Intercom Event
                    GeckoUI.triggerIntercomEvent('Call Join Error', {
                        'error': GeckoUI.renderError(err.errors)
                    });
                });
            },

            end: function end() {
                return $geckoVoip.end();
            },

            // Check VOIP config
            activate: function activate() {
                if (Gecko.User.voipInboundEnabled()) {
                    _this.enabled = true;
                } else {
                    _this.enabled = false;
                }
            },

            destroy: function destroy() {
                if (_this.enabled) {
                    // Destroy device
                    Twilio.Device.destroy();
                    // Disconnect device
                    if (_this.conn) Twilio.Device.disconnectAll();
                }
                return false;
            },

            _init: function _init() {

                Twilio.Device.incoming(function (conn) {
                    if (!_this.enabled) return false;
                    if (_this.callId) {
                        console.log('--- Connect to conference ---');
                        // Set VOIP connection object
                        _this.conn = conn;
                        // Accept VOIP connection
                        $timeout(function () {
                            _this.conn.accept();
                        }, 300);
                    }
                });

                Twilio.Device.disconnect(function () {
                    if (!_this.enabled) return false;
                    if (_this.conn) {
                        console.log('--- Disconnected from conference ---');
                        // Reset VOIP connection object
                        _this.conn = null;
                        // Reset connection status
                        _this.isConnected = false;
                        // End Twilio session
                        Twilio.Device.destroy();
                    }
                });

                // User or Contact has ended the call
                $geckoSocket.registerEvent('call:updated', function (call) {
                    if (!_this.enabled) return false;
                    if (_this.call && _this.call.id == call.id && call.status == Gecko.Call.ENDED) {
                        console.log('--- Conference connection has been ended ---');
                        _this.end();
                    }
                }, true);

                // Offline (connection lost)
                Twilio.Device.offline(function () {
                    if (!_this.enabled) return false;
                    console.log('--- Conference connection destroyed ---');
                    _this.isConnected = false;
                });

                // Update call if contact or campaign changes
                $geckoSocket.registerEvent('call:updated', function (call) {
                    if (_this.call && _this.call.id === call.id) {
                        // Check contact_id and campaign_id has actually changed
                        if (_this.call.contact_id !== call.contact_id || _this.call.campaign_id !== call.campaign_id) {
                            _this.refresh().then(function (call) {
                                // Update conf call object
                                _this.call = call;
                            }).catch(function (err) {});
                        }
                    }
                }, true);
            },

            init: function init() {
                // Add VOIP listeners once
                _this._init();

                // First load
                if (Gecko.user) _this.activate();

                // Activate when available
                Gecko.on('available', _this.activate);
                // Deactivate when unavailable
                Gecko.on('unavailable', _this.destroy);

                // Reset everything if false
                $geckoWatch(_this, 'isConnected', function (value) {
                    if (!value) {
                        _this.callId = null;
                        _this.conn = null;
                        _this.call = null;
                    }
                });
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoConf', $geckoConf);
})();