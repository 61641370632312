var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
    'use strict';

    function formConditions(munge) {

        var conditionFunction = {
            '=': function _(dependencyValue, conditionValue) {
                return conditionValue.indexOf(dependencyValue) > -1;
            },

            '!=': function _(dependencyValue, conditionValue) {
                return conditionValue.indexOf(dependencyValue) == -1;
            },

            '>': function _(dependencyValue, conditionValue) {
                return dependencyValue > conditionValue;
            },

            '<': function _(dependencyValue, conditionValue) {
                return dependencyValue < conditionValue;
            },

            'empty': function empty(dependencyValue) {
                return dependencyValue == "";
            },

            '!empty': function empty(dependencyValue) {
                return dependencyValue != "";
            },

            '%': function _(dependencyValue, conditionValue) {
                for (var i = 0; i < conditionValue.length; i++) {
                    if (dependencyValue.indexOf(conditionValue[i]) > -1) {
                        return true;
                    }
                }
                return false;
            },

            '!%': function _(dependencyValue, conditionValue) {
                for (var i = 0; i < conditionValue.length; i++) {
                    if (dependencyValue.indexOf(conditionValue[i]) > -1) {
                        return false;
                    }
                }
                return true;
            }
        };

        var FormConditions = function FormConditions() {

            this.fields = Object.create(null);
            this.visible = Object.create(null);
            this.dependants = Object.create(null); // Map of field.id to fields that need to be updated when the main field changes.
        };

        FormConditions.prototype.init = function (fields, values) {

            this.values = values;
            angular.forEach(fields, function (field) {

                // Init
                this.fields[field.id] = field;
                this.visible[field.id] = true;

                // Construct dependants map
                angular.forEach(field.conditions, function (condition) {

                    if (!this.dependants[condition.dependency_id]) {
                        this.dependants[condition.dependency_id] = [];
                    }
                    this.dependants[condition.dependency_id].push(field.id);
                }, this);
            }, this);

            angular.forEach(fields, function (field) {
                // Init visibility
                this.calculateVisibility(field.id);
            }, this);
        };

        FormConditions.prototype.updateVisibility = function (changedFieldId) {
            if (this.dependants[changedFieldId]) {

                var changedVisibility = [];

                // Recalculate conditional dependency
                angular.forEach(this.dependants[changedFieldId], function (fieldId) {
                    var prevVisibility = this.visible[fieldId];
                    this.calculateVisibility(fieldId);

                    // When a fields visibility changes any children it has may need updated.
                    if (prevVisibility !== this.visible[fieldId]) {
                        changedVisibility.push(fieldId);
                    }
                }, this);

                angular.forEach(changedVisibility, function (fieldId) {
                    this.updateVisibility(fieldId);
                }, this);
            }
        };

        FormConditions.prototype.calculateVisibility = function (fieldId) {

            // Visible if no conditions
            if (this.fields[fieldId].conditions.length === 0) {
                this.visible[fieldId] = true;
                return;
            }

            var conditionRequirement = this.fields[fieldId].conditions_requirement ? this.fields[fieldId].conditions_requirement : 'ANY';
            var passes = true;

            for (var i = 0; i < this.fields[fieldId].conditions.length; i++) {
                var condition = this.fields[fieldId].conditions[i];
                var dependencyValue = this.values[condition.dependency_id] == null ? "" : this.values[condition.dependency_id];
                var conditionType = condition.type == '>' || condition.type == '<' ? 'numeric' : 'string';

                // Check id and type are set
                if (!condition.dependency_id || !condition.type) {
                    passes = true;
                    break;
                }

                // When we need all conditions and dependency is hidden we are too.
                if (!this.visible[condition.dependency_id]) {
                    passes = false;
                    if (conditionRequirement == 'ALL') break;
                    continue;
                }

                // Implode object
                condition.value = condition.value === null ? [] : condition.value; // Empty conditions gives us null turn this to an empty array.
                if ((typeof dependencyValue === 'undefined' ? 'undefined' : _typeof(dependencyValue)) === "object") {
                    dependencyValue = munge(dependencyValue).objValues().done().join(" ").trim();
                }

                // Condition + value prep
                if (conditionType == 'numeric') {
                    dependencyValue = parseInt(dependencyValue);
                    condition.value = parseInt(condition.value);
                } else {
                    angular.forEach(condition.value, function (item, i) {
                        condition.value[i] = String(item).toLowerCase();
                    });
                    dependencyValue = String(dependencyValue).toLowerCase();
                }

                if (conditionFunction[condition.type](dependencyValue, condition.value)) {
                    passes = true;
                    if (conditionRequirement === 'ANY') break;
                } else {
                    passes = false;
                    if (conditionRequirement === 'ALL') break;
                }
            }

            this.visible[fieldId] = passes;
        };

        FormConditions.prototype.getVisibleValues = function () {

            var visible = {};
            angular.forEach(this.values, function (value, key) {
                if (this.visible[key]) {
                    visible[key] = value;
                }
            }, this);

            return visible;
        };

        return function (fields, values) {
            var conditions = new FormConditions();
            conditions.init(fields, values);
            return conditions;
        };
    }

    angular.module('GeckoEngage').factory('formConditions', formConditions);
})();