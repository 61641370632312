(function () {
    'use strict';

    function exportAddCustomField() {
        return {
            restrict: 'E',
            template: '\n                <div class="row">\n                    <div class="col-xs-6">\n                        <selectize \n                            config="ctrl.selectizeConfig" \n                            options="ctrl.options" \n                            ng-model="ctrl.fieldToBeAdded"\n                            ng-change="ctrl.chooseFromSelect()">\n                        </selectize>\n                    </div>\n\n                    <div class="col-xs-6">\n                        <gecko-btn \n                            btn-title="Add All Fields"\n                            icon="fa-plus"\n                            btn-class="btn-default"\n                            ng-click="ctrl.addAll()"> \n                        </gecko-btn>\n                    </div>\n                </div>\n            ',
            scope: {
                module: '=',
                data: '=',
                options: '=',
                optgroupfield: '=',
                optgroups: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller(geckoExportService, $scope) {
                var ctrl = this;

                ctrl.selectizeConfig = {
                    valueField: 'id',
                    labelField: 'label',
                    maxItems: 1,
                    searchField: ['label'],
                    placeholder: 'Search and select fields',
                    optgroupField: ctrl.optgroupfield,
                    optgroups: ctrl.optgroups,
                    optgroupLabelField: 'label',
                    sortField: [{
                        field: 'label',
                        direction: 'asc'
                    }],
                    onItemAdd: function onItemAdd() {
                        this.blur();
                    }
                };

                ctrl.add = function (i) {
                    var item = geckoExportService.prepareColumnData(ctrl.module.type, geckoExportService.generateColumn(i.id, i.label), ctrl.data);
                    ctrl.module.columns.push(item);
                };

                /**
                 * Add every field
                 *
                 */
                ctrl.addAll = function () {
                    (ctrl.options || []).forEach(function (item) {
                        return ctrl.add(item);
                    });
                };

                /**
                 * Add the selected column when the dropdown changes and reset the selection
                 *
                 */
                ctrl.chooseFromSelect = function () {
                    var item_data = (ctrl.options || []).find(function (item, key) {
                        return item.id === parseInt(ctrl.fieldToBeAdded) || item.id === ctrl.fieldToBeAdded;
                    });

                    ctrl.add(item_data);

                    ctrl.fieldToBeAdded = null;
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('exportAddCustomField', exportAddCustomField);
})();