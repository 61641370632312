(function () {
    'use strict';

    function geckoCallFixNumberCard($geckoCall) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-fix-number-card.html',

            require: '^geckoCallPane',

            link: function link(scope, iElement, iAttrs, ctrl) {

                // Card fields
                ctrl.fixNumberCardFields = [{
                    safeMode: true,
                    label: 'Fix number before calling',
                    key: 'number',
                    type: Gecko.Field.TYPE_TEL,
                    colClass: 'none'
                }];

                // Card buttons
                ctrl.fixNumberCardBtns = [{
                    preset: 'save',
                    action: function action() {

                        var number = new Gecko.Number().create({
                            id: ctrl.numberId,
                            original: typeof ctrl.numberFix.number !== 'string' && ctrl.numberFix.number.original ? ctrl.numberFix.number.original : ctrl.numberFix.number,
                            country_code: ctrl.numberFix.number.country_code
                        });

                        // Show loading indicator
                        $geckoCall.loadingIndicator(true);

                        // Save ammended number
                        number.correct().then(function (number) {
                            if (number.valid) {
                                // Update the chosen number id
                                ctrl.numberId = number.id;
                                // Reset number to fix
                                ctrl.numberFix = false;

                                scope.$digest();
                                // Fetch numbers
                                return new Gecko.Number().where('contact_id', ctrl.contact.id).get().then(function (numbers) {

                                    ctrl.numbers = $geckoCall.prepareNumbers(numbers.toInstances());
                                    scope.$digest();
                                    // Hide loading indicator
                                    $geckoCall.loadingIndicator(false);
                                });
                            } else {
                                GeckoUI.messenger.error('Number is not valid.');
                                // Hide loading indicator
                                $geckoCall.loadingIndicator(false);
                            }
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                            // Hide loading indicator
                            $geckoCall.loadingIndicator(false);
                        });
                    }
                }, {
                    position: 'secondary',
                    title: 'Cancel',
                    icon: 'fa-times',
                    action: function action() {
                        ctrl.numberFix = false;
                    }
                }];
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallFixNumberCard', geckoCallFixNumberCard);
})();