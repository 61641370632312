(function () {

    function EventCategoryModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('eventCategoryModal', function (_categories, _categoryType) {
            return {
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal\n                            data-title="Add to categories"\n                            footer-btns="ctrl.footerBtns">\n                                <div class="row">\n                                    <div class="col-xs-12">\n                                        <gecko-fields class="clearfix" fields="ctrl.fields" values="ctrl.selectedCategory"></gecko-fields>\n                                    </div>\n                                </div>\n                        </gecko-modal>\n                    ',
                controller: 'EventCategoryModalCtrl',
                resolve: {
                    categories: function categories() {
                        return _categories;
                    },
                    categoryType: function categoryType() {
                        return _categoryType;
                    }
                }
            };
        });
    }

    function EventCategoryModalCtrl($modalInstance, categories, categoryType) {
        var ctrl = this;
        ctrl.categories = categories;
        ctrl.selectedCategory = [];
        ctrl.savingCategory = false;

        ctrl.fields = [{
            label: 'Category',
            id: 'category-select',
            required: true,
            type: Gecko.Field.TYPE_MULTI,
            colClass: 'col-xs-12',
            options: ctrl.categories,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            description: 'Allows you to easily track and group your templates',
            config: {
                create: function create(input, callback) {
                    ctrl.savingCategory = true;
                    new Gecko.Category().create({
                        title: input,
                        type: categoryType
                    }).save().then(function (category) {
                        var cat = { id: category.id, title: category.title };
                        callback(cat);
                        ctrl.categories.push(cat);
                        ctrl.selectedCategory['category-select'].push(category.id);
                        ctrl.savingCategory = false;
                    });
                }
            },
            disabledWhen: function disabledWhen() {
                return ctrl.savingCategory;
            }
        }];

        ctrl.footerBtns = [{
            title: 'Add',
            icon: 'fa-check-circle',
            btnClass: 'btn-primary',
            action: function action() {
                var _action = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

                ctrl.selectedCategory['category-select'].forEach(function (id) {
                    _action.push({ type: 'assign_category', field: 'category_id', value: id });
                });
                $modalInstance.close(_action);
            }
        }];
    }

    angular.module('GeckoEngage').config(EventCategoryModal).controller('EventCategoryModalCtrl', EventCategoryModalCtrl);
})();