(function () {
    'use strict';

    function SettingsSendersCtrl($scope, $rootScope, $state, $geckoModal) {
        var ctrl = this;

        // Tabs
        ctrl.tabs = [{
            title: 'Senders',
            state: 'verifiedsenders.senders',
            activeWhen: function activeWhen() {
                return ['verifiedsenders.sender', 'verifiedsenders.senders'].indexOf($state.$current.name) !== -1;
            }
        }, {
            title: 'Domains',
            state: 'verifiedsenders.domains',
            activeWhen: function activeWhen() {
                return ['verifiedsenders.domain', 'verifiedsenders.domains'].indexOf($state.$current.name) !== -1;
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                return $state.go('settings');
            }
        }, {
            label: 'Verified Senders & Domains',
            click: function click() {
                return $state.go('verifiedsenders.senders');
            }
        }, {
            label: $state.$current.page_title,
            active: true
        }];
        // Update breadcrumb label during nav (remove when scope is destroyed)
        var unregisterFn = $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            ctrl.breadcrumbs[ctrl.breadcrumbs.length - 1].label = toState.page_title;
        });
        $scope.$on('$destroy', unregisterFn);

        ctrl.addSenderBtn = {
            title: 'New Sender',
            icon: 'fa-plus',
            btnClass: 'btn-primary',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_SENDERS_CREATE) || ['verifiedsenders.sender', 'verifiedsenders.senders'].indexOf($state.$current.name) === -1;
            },
            action: function action() {
                return $state.go('verifiedsenders.sender', {
                    sender_id: 'new'
                });
            }
        };

        ctrl.addDomainBtn = {
            title: 'New Domain',
            icon: 'fa-plus',
            btnClass: 'btn-primary',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_SENDERS_CREATE) || ['verifiedsenders.domain', 'verifiedsenders.domains'].indexOf($state.$current.name) === -1;
            },
            action: function action() {
                return $state.go('verifiedsenders.domain', {
                    verified_domain_id: 'new'
                });
            }
        };
    }

    angular.module('GeckoEngage').controller('SettingsSendersCtrl', SettingsSendersCtrl);
})();