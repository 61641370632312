(function () {
    'use strict';

    function GroupsCtrl($state, $filter, groups) {
        var ctrl = this;
        ctrl.groups = groups.toArray();
        ctrl.pagination = groups.pagination;

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            colClass: 'col-xs-3'
        }, {
            title: 'Name',
            key: 'name',
            colClass: 'col-xs-3'
        }, {
            title: 'Type',
            key: 'type',
            colClass: 'col-xs-3',
            render: function render(value) {
                return $filter('capitalize')(value);
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Users',
            key: 'user_count',
            colClass: 'col-xs-3'
        }, {
            key: 'type',
            renderHtml: true,
            render: function render(type) {
                if (!Gecko.able(type)) {
                    return '<i class="fa fa-lock"></i>';
                }
            }
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'usersgroup',
            params: { group_id: 'id' }
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Groups',
            active: true
        }];
    }

    function GroupCtrl($state, $stateParams, group, users) {
        var ctrl = this;
        ctrl.group = group;
        ctrl.users = users.toArray();
        ctrl.pagination = users.pagination;
        ctrl.locked = !!(ctrl.group && ctrl.group.type && ctrl.group.type != Gecko.Group.CUSTOM);

        // Check route for the ability used to determine edit rights
        ctrl.ableEdit = true;
        angular.forEach($state.current.editRequires, function (ability) {
            if (!Gecko.able(ability)) {
                ctrl.ableEdit = false;
            }
        });

        ctrl.permissionCols = [{
            title: 'View',
            key: 'view'
        }, {
            title: 'Create',
            key: 'create'
        }, {
            title: 'Update',
            key: 'update'
        }, {
            title: 'Delete',
            key: 'delete'
            // }, {
            //     title : 'Export',
            //     key   : 'export',
        }, {
            title: 'Listen',
            key: 'listen'
        }];

        if (Gecko.has(Gecko.Package.FEATURE_CHAT)) {
            ctrl.permissionCols = ctrl.permissionCols.concat([{
                title: 'Close',
                key: 'close'
            }]);
        }

        // Permission types
        ctrl.types = [{
            title: 'Broadcasts',
            key: 'broadcasts',
            permissions: ['view', 'create', 'update', 'delete']
            //feature: 'feature_broadcasts',
        }, {
            title: 'Call Campaigns',
            key: 'call_campaigns',
            permissions: ['view', 'create', 'update', 'delete'],
            feature: 'feature_call'
        }, {
            title: 'Calls',
            key: 'calls',
            permissions: ['view', 'create', 'update', 'delete', 'listen'],
            feature: 'feature_call'
        }, {
            title: 'Categories',
            key: 'categories',
            permissions: ['view', 'create', 'update', 'delete'],
            lockedPermissions: ['view']
        }, {
            title: 'Contacts',
            key: 'contacts',
            permissions: ['view', 'create', 'update', 'delete']
        }, {
            title: 'Dashboards',
            key: 'reports',
            permissions: ['view', 'create', 'delete']
        }, {
            title: 'Events',
            key: 'events',
            permissions: ['view', 'create', 'update', 'delete'],
            feature: 'feature_event'
        }, {
            title: 'Filters',
            key: 'filters',
            permissions: ['view', 'create', 'update', 'delete'],
            lockedPermissions: ['view']
        }, {
            title: 'Forms',
            key: 'forms',
            permissions: ['view', 'create', 'update', 'delete'],
            feature: 'feature_form'
        }, {
            title: 'Integrations',
            key: 'integrations',
            permissions: ['view', 'create', 'update', 'delete']
        }, {
            title: 'Labels',
            key: 'labels',
            permissions: ['view', 'create', 'update', 'delete'],
            lockedPermissions: ['view']
        }, {
            title: 'Landing Pages',
            key: 'landing_pages',
            permissions: ['view', 'create', 'update', 'delete'],
            feature: 'feature_landing_page'
        },
        // {
        //     title       : 'Messages',
        //     key         : 'messages',
        //     permissions : [
        //         'view',
        //         'create',
        //         'update',
        //         'delete',
        //     ],
        // },
        {
            title: 'Organisations',
            key: 'organisations',
            permissions: ['view', 'create', 'update', 'delete'],
            feature: 'feature_organisation'
        }, {
            title: 'Responses',
            key: 'responses',
            permissions: ['view', 'create', 'update', 'delete']
        }, {
            title: 'Scripts',
            key: 'scripts',
            permissions: ['view', 'create', 'update', 'delete']
        }, {
            title: 'Senders',
            key: 'senders',
            permissions: ['view', 'create', 'update', 'delete']
        }, {
            title: 'Users',
            key: 'users',
            permissions: ['view', 'create', 'update', 'delete']
        }, {
            title: 'User Groups',
            key: 'groups',
            permissions: ['view', 'create', 'update', 'delete']
        }, {
            title: 'Conversations',
            key: 'conversations',
            permissions: ['view', 'close', 'update', 'delete'],
            feature: Gecko.Package.FEATURE_CHAT
        }, {
            title: 'Chat Widgets',
            key: 'chat_widgets',
            permissions: ['view', 'create', 'update', 'delete'],
            feature: Gecko.Package.FEATURE_CHAT
        }, {
            title: 'Chat Bots',
            key: 'chat_bots',
            permissions: ['view', 'create', 'update', 'delete'],
            feature: Gecko.Package.FEATURE_CHAT
        }, {
            title: 'Chat Channels',
            key: 'chat_channels',
            permissions: ['view', 'create', 'update', 'delete'],
            feature: Gecko.Package.FEATURE_CHAT
        }];

        ctrl.singleTypes = [{
            label: 'Imports',
            key: 'imports',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: null,
            description: 'Allow users in this group to import data from an integration or file.',
            colClass: 'col-xs-4',
            disabledWhen: function disabledWhen() {
                return !ctrl.ableEdit || ctrl.locked;
            }
        }, {
            label: 'Exports',
            key: 'exports',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: null,
            description: 'Allow users in this group to export data from the app.',
            colClass: 'col-xs-4',
            disabledWhen: function disabledWhen() {
                return !ctrl.ableEdit || ctrl.locked;
            }
        }, {
            label: 'Send Messages',
            key: 'contacts_message',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: null,
            description: 'Allow users in this group to send email and SMS messages.',
            colClass: 'col-xs-4',
            disabledWhen: function disabledWhen() {
                return !ctrl.ableEdit || ctrl.locked;
            }
        }, {
            label: 'Advanced Settings',
            key: 'adv_settings',
            type: Gecko.Field.TYPE_TOGGLE,
            description: 'Allows the viewing of VoIP credit usage, as well as creating, editing and deleting of contact form fields.',
            trueValue: true,
            falseValue: null,
            colClass: 'col-xs-4',
            disabledWhen: function disabledWhen() {
                return !ctrl.ableEdit || ctrl.locked;
            }
        }];

        // Build permissions value object
        ctrl.permissionValues = {};
        angular.forEach(ctrl.types, function (type) {
            ctrl.permissionValues[type.key] = {};
            // Add permissions
            angular.forEach(type.permissions, function (perm) {
                // Check if permission is set
                var key = [type.key, perm].filter(angular.isDefined).join('_');
                if (GeckoUI.gobk(ctrl.group.related_permissions, 'key', key)) {
                    ctrl.permissionValues[type.key][perm] = true;
                } else {
                    ctrl.permissionValues[type.key][perm] = null;
                }
            });
        });

        ctrl.singlePermissions = {};
        angular.forEach(ctrl.singleTypes, function (type) {
            ctrl.singlePermissions[type.key] = GeckoUI.gobk(ctrl.group.related_permissions, 'key', type.key) ? true : null;
        });

        ctrl.fields = [{
            label: 'Group Name',
            id: 'name',
            required: true,
            disabledWhen: function disabledWhen() {
                return ctrl.locked;
            }
        }];

        // Check wether a permission type has all permissions set
        ctrl.toggleAllCheck = function (type) {
            var typeObj = ctrl.types.find(function (_type) {
                return _type.key === type;
            });
            var availablePermissions = Object.keys(ctrl.permissionValues[type]).filter(function (perm) {
                return !typeObj.lockedPErmissions || typeObj.lockedPermissions && typeObj.lockedPermissions.indexOf(perm) === -1;
            });
            var _perms = availablePermissions.map(function (perm) {
                return ctrl.permissionValues[type][perm];
            });

            if (_perms.indexOf(null) !== -1 && _perms.length !== 0) return false;
            return true;
        };

        // Toggle all values when
        ctrl.toggleAll = function (type) {
            if (ctrl.locked) {
                return false;
            } // Do nothing if the group isn't editable
            // Check current state of 'All'
            var lockedPermissions = ctrl.types.find(function (_type) {
                return _type.key === type;
            }).lockedPermissions;
            var allCheck = ctrl.toggleAllCheck(type);
            for (var perm in ctrl.permissionValues[type]) {
                if (angular.isArray(lockedPermissions) && lockedPermissions.indexOf(perm) !== -1) {
                    continue;
                }
                if (!allCheck) {
                    ctrl.permissionValues[type][perm] = true;
                } else {
                    ctrl.permissionValues[type][perm] = null;
                }
            }
        };

        // Set value when cell is clicked
        ctrl.togglePerm = function (type, permission) {
            if (ctrl.locked) {
                return false;
            } // Do nothing if the group isn't editable
            var lockedPermissions = ctrl.types.find(function (_type) {
                return _type.key === type;
            }).lockedPermissions;
            if (ctrl.permissionValues[type][permission] === undefined || lockedPermissions && lockedPermissions.indexOf(permission) !== -1) return false;
            return ctrl.permissionValues[type][permission] = ctrl.permissionValues[type][permission] ? null : true;
        };

        if (!ctrl.locked) {
            ctrl.footerBtns = [{
                preset: 'save',
                requires: [$stateParams.group_id == 0 ? Gecko.ABILITY_GROUPS_CREATE : Gecko.ABILITY_GROUPS_UPDATE],
                action: function action() {
                    // Flatten permission values
                    ctrl.group.permissions = []; // .push(val);
                    for (var type in ctrl.permissionValues) {
                        for (var perm in ctrl.permissionValues[type]) {
                            if (ctrl.permissionValues[type][perm]) {
                                ctrl.group.permissions.push(type + '_' + perm);
                            }
                        }
                    }
                    angular.forEach(ctrl.singlePermissions, function (value, key) {
                        if (value) {
                            ctrl.group.permissions.push(key);
                        }
                    });

                    // Save the group
                    ctrl.group.save().then(function (group) {
                        GeckoUI.messenger.success('Group was saved');
                        if ($stateParams.group_id == 0) {
                            $state.go('usersgroup', {
                                group_id: group.id
                            });
                        }

                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('User Group Save Success', {
                            'group id': group.id,
                            'group title': group.name
                        });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('User Group Save Error', {
                            'event id': ctrl.group.id || 'New',
                            'event title': ctrl.group.name,
                            'error': GeckoUI.renderError(err.errors)
                        });
                    });
                }
            }, {
                preset: 'remove',
                position: 'secondary',
                hideWhen: function hideWhen() {
                    return $stateParams.group_id == 0;
                },
                requires: [Gecko.ABILITY_GROUPS_DELETE],
                action: function action() {
                    GeckoUI.dialog.confirm('Are you sure you want to delete this group?', function (value) {
                        if (value) {
                            ctrl.group.remove().then(function () {
                                $state.go('usersgroups');
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        }
                    });
                }
            }];
        }

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            sortKey: 'created_at',
            colClass: 'col-xs-3'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Name',
            key: 'full_name',
            sortKey: 'full_name',
            colClass: 'col-xs-3'
        }, {
            title: 'Email',
            key: 'email',
            sortKey: 'email',
            colClass: 'col-xs-3'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'user',
            params: { user_id: 'id' }
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Groups',
            click: function click() {
                $state.go('usersgroups');
            }
        }, {
            label: ctrl.group.name ? ctrl.group.name : 'Add Group',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('GroupsCtrl', GroupsCtrl).controller('GroupCtrl', GroupCtrl);
})();