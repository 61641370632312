(function () {
    'use strict';

    function LegacyEventCtrl($state, $scope, $stateParams, $geckoModal, event, integrations, $filter) {
        var ctrl = this;
        ctrl.event = event;
        ctrl.integrations = integrations.toArray();

        if (ctrl.event.type !== Gecko.Event.TYPE_EVENT_LEGACY) {
            return $state.go('event.overview', $stateParams);
        }

        if ($state.$current.name == 'legacy-event') $state.go('legacy-event.overview', $stateParams);

        // Tabs
        ctrl.tabs = [{
            id: 'overview',
            title: 'Overview',
            state: 'legacy-event.overview'
        }, {
            id: 'edit',
            title: 'Edit',
            state: 'legacy-event.settings'
        }, {
            id: 'sessions',
            title: 'Sessions',
            state: 'legacy-event.sessions',
            activeWhen: function activeWhen() {
                return ['legacy-event.sessions', 'legacy-event.session'].indexOf($state.$current.name) !== -1;
            },
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_SESSIONS_TAB);
            }
        }, {
            id: 'workflows',
            title: 'Workflows',
            state: 'legacy-event.workflows',
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_WORKFLOWS_TAB);
            }
        }];

        // Breadcrumbs
        if (ctrl.event.id) {
            ctrl.breadcrumbs = [{
                label: 'Events',
                click: function click() {
                    return $state.go('events');
                }
            }, {
                label: ctrl.event.title,
                active: true
            }];
        } else {
            ctrl.breadcrumbs = [{
                label: 'Events',
                click: function click() {
                    $state.go('events');
                }
            }, {
                label: 'Add Event',
                active: true
            }];
        }

        // Action button
        ctrl.actionBtn = {
            title: 'Actions',
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary'
        };
        ctrl.actionBtn.items = [{
            title: 'Add Contacts',
            action: function action() {
                $geckoModal.contactFind().result.then(function (conditions) {
                    return new Gecko.Contact().massAction(conditions, Gecko.Workflow.Actions.attendance(ctrl.event.id)).catch(GeckoUI.messenger.error);
                }).then(function (response) {
                    $state.go($state.$current.name, $stateParams, { reload: true });
                    GeckoUI.messenger.success(response.message);
                });
            },
            hideWhen: function hideWhen() {
                return ctrl.event.attendances_count >= ctrl.event.maximum && !ctrl.event.auto_waitlist && ctrl.event.maximum != null;
            }
        }, {
            title: 'Edit Session',
            hideWhen: function hideWhen() {
                return !ctrl.event.parent_id;
            },
            action: function action() {
                $geckoModal.legacyEventSessionModal({ parent_id: ctrl.event.parent_id }, ctrl.event);
            }
        }, {
            title: 'Export attendees',
            action: function action() {
                $state.go('exportnew', { event_id: ctrl.event.id });
            }
        }, {
            title: 'Sync this Event',
            hideWhen: function hideWhen() {
                return !ctrl.event.syncs || !ctrl.integrations || !ctrl.integrations.length || !ctrl.integrations.filter(function (i) {
                    return ctrl.event[i.type + '_id'];
                }).length;
            },
            action: function action() {
                if (ctrl.integrations.length == 1) {
                    ctrl.syncEvent(ctrl.integrations[0].id);
                } else {
                    var message = 'What integration would you like to sync?',
                        array = GeckoUI.mapObjectArray(ctrl.integrations, { value: 'id', label: 'type', date: 'created_at' });
                    array = array.filter(function (integration) {
                        return ctrl.event[integration.label + '_id'];
                    });
                    if (array.length === 1) {
                        return ctrl.syncEvent(array[0].value);
                    }

                    var callback = function callback(id) {
                        if (id) ctrl.syncEvent(id);
                    };
                    // Prep title
                    angular.forEach(array, function (item) {
                        item.label = $filter('capitalize')(item.label) + ' (' + $filter('formatDate')(item.date, Gecko.dateFormat.short) + ')';
                    });
                    return GeckoUI.dialog.prompt(message, callback, array);
                }
            }
        }, {
            title: 'View Event dashboard',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_DASHBOARD)) {
                    return;
                }
                return $state.go('dashboardview', {
                    event_id: ctrl.event.id,
                    override_date: 'month'
                });
            },
            hideWhen: function hideWhen() {
                if (!Gecko.able(Gecko.ABILITY_EVENTS_DASHBOARD)) {
                    return true;
                }
                return !Gecko.has(Gecko.Package.FEATURE_EVENT);
            }
        }, {
            render: function render() {
                return 'Remove this ' + ($state.$current.name == 'legacy-event.session' ? 'Session' : 'Event');
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_DELETE);
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to remove this ' + ($state.$current.name == 'legacy-event.session' ? 'session' : 'event') + '?', function (value) {
                    if (value) {
                        ctrl.event.remove().then(function () {
                            GeckoUI.messenger.success('Event removed');
                            // Redirect to events
                            if ($state.$current.name == 'legacy-event.session') return $state.go('legacy-event.sessions', $stateParams);
                            return $state.go('legacy-events');
                        });
                    }
                });
            }
        }];

        ctrl.showSaveBtn = function () {
            return ['legacy-event.workflows', 'legacy-event.settings'].indexOf($state.$current.name) != -1;
        };

        ctrl.saveEvent = function () {
            $scope.$broadcast('saveEvent');
        };

        // Sync responses
        ctrl.syncEvent = function (int_id) {
            if (!int_id) return false;
            ctrl.event.sync(int_id).then(function () {
                GeckoUI.messenger.success('Sync successfully scheduled, please allow time for Sync to process.');
            }).catch(function (error) {
                GeckoUI.messenger.error(error);
                // GeckoUI.messenger.error('Sync failed, check integration settings.');
            });
        };
    }

    function LegacyEventSessionsCtrl($scope, event, sessions, $geckoModal, $stateParams, $state, geckoDates) {
        var ctrl = this;
        ctrl.event = event;
        ctrl.sessions = sessions.toInstances();
        ctrl.pagination = sessions.pagination;

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'title',
            colClass: 'col-xs-3',
            sortKey: 'title'
        }, {
            title: 'Start Date',
            key: 'start_datetime',
            colClass: 'col-xs-3',
            sortKey: 'start_datetime',
            render: function render(value) {
                return geckoDates.printDateString(value);
            }
        }, {
            title: 'End Date',
            key: 'end_datetime',
            colClass: 'col-xs-3',
            sortKey: 'end_datetime',
            render: function render(value) {
                return geckoDates.printDateString(value);
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_INTEGER,
            title: 'Attendees',
            key: 'attendances_count',
            colClass: 'col-xs-3'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'legacy-event.session',
            params: { session_id: 'id' }
        };

        // Filter button
        ctrl.filterBtn = {
            items: [{
                title: 'Active Events',
                action: function action() {
                    return $state.go('events', { 'filter': 'active' });
                }
            }, {
                title: 'Past Events',
                action: function action() {
                    return $state.go('events', { 'filter': 'past' });
                }
            }]
        };

        // Active filter
        ctrl.filterName = $stateParams.filter == 'past' ? 'Past Events' : '';
        ctrl.filterActive = $stateParams.filter == 'past' ? true : false;

        // Reset filter
        ctrl.filterReset = function () {
            return $state.go('events', { filter: null }, { reload: true });
        };

        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'Edit Session',
            action: function action(session) {
                $geckoModal.legacyEventSessionModal(event, session);
            }
        }, {
            title: 'Remove Session',
            action: function action(session, index) {
                GeckoUI.dialog.confirm('Are you sure you want to remove ' + session.title + '?', function (value) {
                    if (value) {
                        session.remove().then(function () {
                            GeckoUI.messenger.success('Session has been deleted.');
                            ctrl.sessions.splice(index, 1);
                            $scope.$digest();
                        });
                    }
                });
            }
        }];

        // Header buttons
        ctrl.headerBtns = [{
            title: 'Add Session',
            icon: 'fa-plus',
            action: function action() {
                // ADD MODAL HERE.
                $geckoModal.legacyEventSessionModal(event).result.then(function (event) {
                    ctrl.sessions.push(event);
                });
            }
        }];

        // Filter button
        ctrl.filterBtns = [{
            btnTooltip: 'Status',
            icon: 'fa-eye',
            queryParam: 'status',
            items: Gecko.Attendance.status_titles,
            includeAll: false,
            selected: $stateParams.status ? angular.isArray($stateParams.status) ? $stateParams.status : [$stateParams.status] : []
        }];
    }

    function LegacyEventWorkflowsCtrl($scope, $state, $stateParams, $filter, event, multiData, unsavedAlert) {
        $scope.event = event;
        $scope.multiData = multiData;

        // Save Campaign
        function eventSave() {

            $scope.event.workflows = Gecko.convertInstancesToObjects($scope.event.related_workflows);

            angular.copy($scope.event).include('workflows', 'workflows.condtions').save().then(function () {
                // Tell the user it was ok
                GeckoUI.messenger.success('Event workflows saved');
                unsavedAlert.clear();
            }).catch(function (errors) {
                GeckoUI.messenger.error(errors.errors);
            });
        }

        $scope.$on('saveEvent', function () {
            return eventSave();
        });
    }

    function LegacyEventSettingsCtrl($scope, $stateParams, $state, $filter, Gecko, unsavedAlert, event, geckoDates) {
        var ctrl = this;
        ctrl.event = event;

        var prepareEpochs = function prepareEpochs(event) {
            ctrl.event.start_date = geckoDates.toEpoch(event.start_datetime);
            ctrl.event.end_date = geckoDates.toEpoch(event.end_datetime);
            ctrl.event.closing_date = geckoDates.toEpoch(event.closing_datetime);
        };

        // Prepare event epochs (for date pickers)
        prepareEpochs(ctrl.event);

        if (!ctrl.event.type) {
            ctrl.event.type = Gecko.Event.TYPE_EVENT_LEGACY;
        }

        // Field list
        ctrl.fields = [{
            id: 'id',
            label: 'ID',
            disabledWhen: function disabledWhen() {
                return ctrl.event.id;
            },
            hideWhen: function hideWhen() {
                return !ctrl.event.id;
            }
        }, {
            id: 'title',
            label: 'Title',
            required: true
        }, {
            id: 'start_date',
            label: 'Start Date',
            type: Gecko.Field.TYPE_DATETIME,
            utc: true
        }, {
            id: 'end_date',
            label: 'End Date',
            type: Gecko.Field.TYPE_DATETIME,
            utc: true
        }, {
            id: 'location',
            label: 'Location'
        }, {
            id: 'country',
            label: 'Country'
        }, {
            id: 'cost',
            label: 'Cost'
        }, {
            id: 'auto_waitlist',
            label: 'Auto Waitlist',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: false,
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT);
            }
        }, {
            id: 'maximum',
            label: 'Maximum Attendees',
            type: Gecko.Field.TYPE_NUMBER,
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT);
            }
        }, {
            type: Gecko.Field.TYPE_RICHTEXT,
            colClass: 'col-md-12',
            id: 'description',
            label: 'Description'
        }];

        // Integration field list
        ctrl.integrationFields = [{
            id: 'crm',
            label: 'CRM Value (optional)'
        }, {
            id: 'radius_title',
            label: 'Radius Event',
            hideWhen: function hideWhen() {
                return !ctrl.event.radius_title;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'radius_id',
            label: 'Radius Event ID',
            hideWhen: function hideWhen() {
                return !ctrl.event.radius_id;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'dynamics_title',
            label: 'Dynamics Event',
            hideWhen: function hideWhen() {
                return !ctrl.event.dynamics_title;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'dynamics_id',
            label: 'Dynamics Event ID',
            hideWhen: function hideWhen() {
                return !ctrl.event.dynamics_id;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }];

        // Save event
        ctrl.saveEvent = function () {
            ctrl.event.save().then(function (event) {
                GeckoUI.messenger.success('Event changes have been saved');
                unsavedAlert.clear();
                // Send them to their new event
                if ($stateParams.event_id == 'new') $state.go('legacy-event.overview', { event_id: event.id });

                // Prepare event epochs (for date pickers)
                prepareEpochs(event);

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Event Save Success', {
                    'event id': event.id,
                    'event title': event.title
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Event Save Error', {
                    'event id': ctrl.event.id || 'New',
                    'event title': ctrl.event.title,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        $scope.$on('saveEvent', function () {
            return ctrl.saveEvent();
        });

        var _tempDates = {
            start_date: ctrl.event.start_date,
            end_date: ctrl.event.end_date,
            closing_date: ctrl.event.closing_date
        };
        geckoDates.initSetters(_tempDates, ctrl.event, [{ get: 'start_date', set: 'start_datetime' }, { get: 'end_date', set: 'end_datetime' }, { get: 'closing_date', set: 'closing_datetime' }]);
    }

    angular.module('GeckoEngage').controller('LegacyEventCtrl', LegacyEventCtrl).controller('LegacyEventSettingsCtrl', LegacyEventSettingsCtrl).controller('LegacyEventSessionsCtrl', LegacyEventSessionsCtrl).controller('LegacyEventWorkflowsCtrl', LegacyEventWorkflowsCtrl);
})();