(function () {

    'use strict';

    function SettingsOptionsRoute($stateProvider) {
        $stateProvider.state('settingsoptions', {
            url: "/settings/options?{page}&{search}&{order}&{sort}",
            templateUrl: "/modules/field_options/views/index.b89347af.html",
            controller: 'SettingsOptionsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Options',
            redirectKey: 'module_options',
            requires: [Gecko.ABILITY_ADV_SETTINGS],
            resolve: {
                options: function options($stateParams) {
                    var options = new Gecko.Option();
                    if ($stateParams.search) options.where('keyword', $stateParams.search);
                    if ($stateParams.order) options.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    return options.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ option: ['name', 'values_count'] }).get();
                }
            }
        }).state('settingsoption', {
            url: "/settings/options/{option_id}",
            templateUrl: "/modules/field_options/views/edit.70721f3e.html",
            controller: 'SettingsOptionCtrl',
            controllerAs: 'ctrl',
            page_title: 'Option',
            requires: [Gecko.ABILITY_ADV_SETTINGS],
            resolve: {
                option: function option($stateParams) {
                    if ($stateParams.option_id === 'new') {
                        return new Gecko.Option().fill({
                            name: 'New option',
                            values: [{
                                code: '',
                                value: 'New Option',
                                options: []
                            }]
                        });
                    } else {
                        return new Gecko.Option().include('fields:all', 'fields.form').rfields({ field: ['label', 'field_type', 'form_id'], form: ['name', 'deleted_at', 'internal_name'] }).where('form_trashed', 1).get($stateParams.option_id);
                    }
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(SettingsOptionsRoute);
})();