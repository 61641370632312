(function () {
    'use strict';

    function SettingsFileImportCtrl($scope, $state, $stateParams, LoadingIndicator, FileUploader, $timeout, forms, $filter, existing_import, geckoFileUploadService) {

        $scope.import_data = {};
        $scope.uploadedFileName = '';
        var importTitleWasBlank = true;

        if (existing_import) {
            importTitleWasBlank = false;
            $scope.import_data.import_to = existing_import.import_to;
            $scope.import_data.importTitle = existing_import.title;
            $scope.import_data.unlink = existing_import.unlink || 0;
            if (existing_import.import_to === 'responses') $scope.import_data.form_id = existing_import.form_id;
        } else {
            $scope.import_data.import_to = $stateParams.import_to || 'contacts';
            $scope.import_data.importTitle = '';
            $scope.import_data.unlink = 0;
        }

        $scope.import_data.import_type = 'one_off';
        if ($stateParams.form_id) $scope.import_data.form_id = parseInt($stateParams.form_id);
        $scope.form_options = forms.toArray();

        $scope.data_types = [];
        $scope.defaultAllowedCsvMimetypes = geckoFileUploadService.defaultAllowedCsvMimetypes;

        if (Gecko.able(Gecko.ABILITY_CONTACTS_BULK_IMPORT)) {
            $scope.data_types.push({
                name: 'Contacts',
                title: 'Contacts',
                value: 'contacts',
                icon: 'fa-users'
            });
        }
        if (Gecko.able(Gecko.ABILITY_RESPONSES_BULK_IMPORT)) {
            $scope.data_types.push({
                name: 'Responses',
                title: 'Responses',
                value: 'responses',
                icon: 'fa-comments'
            });
        }
        $scope.data_types.push({
            name: 'Events',
            title: 'Events',
            value: 'events',
            icon: 'fa-calendar-alt'
        });
        $scope.data_types.push({
            name: 'Organisations',
            title: 'Organisations',
            value: 'organisations',
            icon: 'fa-building'
        });

        $scope.titleField = [{
            id: 'importTitle',
            label: 'Title',
            description: "The name of this import will be used to identify it across Gecko - if left blank we'll generate a name for you.",
            type: Gecko.Field.TYPE_TEXT,
            onChange: function onChange() {
                return importTitleWasBlank = $scope.import_data.importTitle === '';
            },
            disabledWhen: function disabledWhen() {
                return !!existing_import;
            },
            colClass: "col-xl-12"
        }];

        // in preferences
        $scope.preferencesFields = [{
            id: 'import_type',
            label: 'Type of Import',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            disabledWhen: function disabledWhen() {
                return !!existing_import;
            },
            options: [{
                key: 'one_off',
                value: 'one_off',
                icon: 'fa-cloud-upload',
                title: 'One-off Import'
            }, {
                key: 'recurring',
                value: 'recurring',
                icon: 'fa-sync',
                title: 'Recurring Import'
            }]
        }];

        $scope.isOneOff = function () {
            return $scope.import_data.import_type === 'one_off';
        };
        $scope.isRecurring = function () {
            return $scope.import_data.import_type === 'recurring';
        };

        $scope.dataTypeField = [{
            id: 'import_to',
            label: 'Data Type',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            options: $scope.data_types,
            disabledWhen: function disabledWhen() {
                return !!existing_import;
            }
        }];

        // Allow dropdown to be search by name and internal name
        var prepareFormOptions = function prepareFormOptions(forms) {
            return forms.map(function (f) {
                if (f.internal_name) {
                    var name = f.internal_name + ' - ' + f.name;
                } else {
                    var name = '' + f.name;
                }
                return {
                    id: f.id,
                    name: name
                };
            });
        };

        $scope.csvFields = [{
            id: 'form_id',
            label: 'Form',
            type: Gecko.Field.TYPE_SELECT,
            hideWhen: function hideWhen() {
                return $scope.import_data.import_to !== 'responses';
            },
            disabledWhen: function disabledWhen() {
                return !!existing_import;
            },
            options: prepareFormOptions($scope.form_options),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            colClass: "col-xl-12",
            description: "Choose which form you're importing responses for.",
            onChange: updateTitle
        }, {
            id: 'unlink',
            label: 'Unlink Contacts',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: 1,
            falseValue: 0,
            hideWhen: function hideWhen() {
                return !existing_import;
            },
            colClass: "col-xl-12",
            description: 'If checked then Contacts who are no longer in the import will be unlinked from it and will no longer match conditions based on the import.'

        }];

        function updateTitle() {
            if (importTitleWasBlank && $scope.uploadedFileName && $scope.import_data.import_to) {

                $scope.import_data.importTitle = $scope.uploadedFileName + ' to ';

                if ($scope.import_data.import_to == 'responses') {
                    var form = GeckoUI.getObjectByKey($scope.form_options, 'id', $scope.import_data.form_id);
                    $scope.import_data.importTitle += form.internal_name || form.name || '';
                } else {
                    $scope.import_data.importTitle += $filter('capitalize')($scope.import_data.import_to);
                }

                $scope.import_data.importTitle += ' (' + $filter('date')(new Date(), 'dd/MM/yyyy') + ')';
            }
        }

        if (Gecko.useNewAuth()) {
            var headers = { 'Authorization': 'Bearer ' + window.TokenManager.fromLocalStorage().AccessToken };
        } else {
            var headers = {};
        }

        $scope.uploader = new FileUploader({
            url: Gecko.base_url + '/imports/upload' + (existing_import ? '/' + $stateParams.import_id : ''), // Add existing import ID if specified
            headers: headers,
            alias: 'csv',
            autoUpload: false,
            withCredentials: false,
            onAfterAddingFile: function onAfterAddingFile(item) {
                $scope.$apply(function () {
                    $scope.fileToUpload = item;
                    if (importTitleWasBlank) {
                        $scope.uploadedFileName = item.file.name;
                        updateTitle();
                    }
                });
            },
            onBeforeUploadItem: function onBeforeUploadItem(item) {
                var form_id;
                if ($scope.import_data.import_to == 'responses') {
                    form_id = $scope.import_data.form_id;
                } else {
                    form_id = null;
                }

                var data = {
                    import_to: $scope.import_data.import_to,
                    unlink: $scope.import_data.unlink
                };

                if (form_id) {
                    data.form_id = form_id;
                }
                if ($scope.import_data.importTitle) {
                    data.title = $scope.import_data.importTitle;
                }
                item.formData.push(data);

                $scope.uploading_msg = 'Please wait a moment while your CSV is uploading and validated.';

                $scope.$apply();
            },
            onCompleteItem: function onCompleteItem(item, response, status) {

                if (status === 400) {
                    GeckoUI.messenger.error(response.message);
                    return;
                }

                if (response.import) return $state.go('importconfig', { import_id: response.import.id });
            }
        });

        // Breadcrumbs
        $scope.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Imports',
            click: function click() {
                $state.go('settingsimports');
            }
        }, {
            label: 'Import File',
            active: true
        }];

        // Footer buttons
        $scope.footerBtns = [{
            title: 'Upload CSV',
            icon: 'fa-upload',
            btnClass: 'btn-primary',
            safeMode: true,
            action: function action() {
                $timeout(function () {

                    if (!$scope.fileToUpload) GeckoUI.messenger.error('Please select a file.');
                    if ($scope.import_data.import_to === 'responses' && !$scope.import_data.form_id) GeckoUI.messenger.error('Please select a form to import responses to.');

                    $scope.uploader.uploadAll();
                }, 500);
            }
        }];

        // Set up a new recurring import
        $scope.recurringImportData = new Gecko.Import();

        // Add an ftp_config object
        $scope.recurringImportData.ftp = {};

        // Add the fields for the ftp settings of the import
        $scope.ftpFields = [{
            label: 'Hostname',
            id: 'hostname',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'col-xl-12'
        }, {
            label: 'Port',
            id: 'port',
            type: Gecko.Field.TYPE_NUMBER,
            colClass: 'col-xl-12'
        }, {
            label: 'Path',
            id: 'path',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'col-xl-12'
        }, {
            label: 'Username',
            id: 'username',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'col-xl-12'
        }, {
            label: 'Password',
            id: 'password',
            type: Gecko.Field.TYPE_PASSWORD,
            colClass: 'col-xl-12'
        }, {
            label: 'Driver',
            id: 'driver',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'col-xl-12',
            options: [{
                id: 'ftp',
                name: 'FTP'
            }, {
                id: 'sftp',
                name: 'SFTP'
            }]
        }].concat([$scope.csvFields[0]]);

        $scope.saveRecurringImport = function () {
            $scope.recurringImportData.title = $scope.import_data.importTitle;
            // Set data type
            $scope.recurringImportData.import_to = $scope.import_data.import_to;
            // Set form id (responses only)
            $scope.recurringImportData.form_id = $scope.recurringImportData.ftp.form_id || null;

            return LoadingIndicator.resolve($scope.recurringImportData.call('/imports/upload', 'POST', $scope.recurringImportData, true)).then(function (response) {
                $state.go('importrecurringedit.options', { import_id: response.import.id });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors || err.message);
                GeckoUI.triggerIntercomEvent('New Recurring Import failed to save', {
                    'import name': $scope.import_data.importTitle,
                    'error': err.errors || err.message
                });
            });
        };

        $scope.ftpFooterBtns = [{
            title: 'Access FTP Folder',
            icon: 'fa-folder-open',
            btnClass: 'btn-primary',
            safeMode: true,
            action: function action() {
                if (importTitleWasBlank) {
                    updateTitle();
                }
                $scope.saveRecurringImport();
            }
        }];
    }

    angular.module('GeckoEngage').controller('SettingsFileImportCtrl', SettingsFileImportCtrl);
})();