(function () {
    'use strict';

    function geckoFieldsConditional(formConditions, munge, geckoResponse) {
        return {
            restrict: 'E',

            templateUrl: '/components/gecko-field/gecko-fields-conditional.html',

            scope: {
                pages: '=',
                paging: '=',
                fields: '=',
                values: '=',
                preFill: '&',
                contact_id: '=contactId'
            },

            bindToController: true,
            controllerAs: 'ctrl',
            controller: function fieldsConditionalCtrl() {},

            link: function link(scope, iElement, iAttrs, ctrl) {

                // Get changes on fields.
                ctrl.onChange = function (field) {
                    ctrl.conditions.updateVisibility(field.id, ctrl.values);

                    scope.$emit('geckoFieldUpdate', field, ctrl.values);
                };

                var fields;
                // Create map of field visibility
                if (ctrl.fields) {
                    fields = ctrl.fields;
                } else {
                    fields = munge(ctrl.pages).byKey('fields').flatten().done();
                }

                if (ctrl.preFill()) {

                    scope.$emit('geckoFieldsLoad', 'start');

                    geckoResponse.initValues(fields, ctrl.values, ctrl.contact_id).then(function () {
                        ctrl.conditions = formConditions(fields, ctrl.values);
                        scope.$emit('geckoFieldsLoad', 'complete');
                    });
                } else {

                    ctrl.conditions = formConditions(fields, ctrl.values);
                }

                ctrl.isHidden = function (field) {
                    return field.hidden || field.type === Gecko.Field.TYPE_HIDDEN;
                };
            }

        };
    }

    angular.module('GeckoEngage').directive('geckoFieldsConditional', geckoFieldsConditional);
})();