(function () {
    'use strict';

    /**
     * @returns {SavedSearchProvider}
     */

    function engageFilterProvider() {
        var defaultPageSize = 10000;

        return {
            saturate: function saturate() {
                return new Gecko.Filter().rfields({ filter: ['name', 'requirement', 'ulid'] }).orderBy('name').where('saved', 1).perPage(defaultPageSize).get().then(function (res) {
                    return res.data;
                });
            },
            getById: function getById(id) {
                return new Gecko.Filter().include('conditions', 'dependants.campaigns', 'dependants.parent.campaigns', 'campaigns').rfields({
                    filter: ['name', 'requirement', 'saved'],
                    campaign: ['title']
                }).get(id);
            }
        };
    }

    angular.module('GeckoEngage').factory('engageFilterProvider', engageFilterProvider);
})();