
GeckoUI.extractMustacheFields = function () {
    'use strict';

    var templateRE = /([^]*?)(\{\{[^]*?\}\})([^]*)/m;

    return function (text) {
        var split = templateRE.exec(text);
        var output = [];

        while (split) {
            if (split[2]) output.push(split[2]);
            text = split[3];
            split = templateRE.exec(text);
        }

        return output;
    };
}();