(function () {
    'use strict';

    function optionListParse() {

        var isOnlyWhitespace = /^\s*$/;

        return {

            countOptions: function countOptions(options) {
                if (!options) return 0;
                return options.length + options.reduce(function (count, option) {
                    return count + countOptions(option.options);
                }, 0);
            },

            countTextOptions: function countTextOptions(text) {
                return text.split('\n').filter(function (v) {
                    return v.trim() !== '';
                }).length;
            },

            createTextOptions: function createTextOptions(options, indent) {
                indent = indent || '';
                if (!options) return '';

                return options.reduce(function (s, option) {
                    var line = (option.value || '') + '|' + (option.code || '') + (option.hidden ? '|hidden' : '');
                    return s + indent + line + '\n' + createTextOptions(option.options, indent + '\t');
                }, '');
            },

            extractOptionFromString: function extractOptionFromString(option) {
                var optionString = String(option || ''),
                    tabRegex = /^(\t*)/,
                    indentLength = tabRegex.exec(optionString)[0].length,
                    parts = optionString.replace(tabRegex, '').split('|').map(function (part) {
                    return part.trim();
                });

                return {
                    indentLength: indentLength || 0,
                    value: parts.length > 0 ? parts[0] : '',
                    code: parts.length > 1 ? parts[1] : '',
                    hidden: parts.length > 2 ? parts[2].toLowerCase() === 'hidden' : false
                };
            },

            parseOption: function parseOption(string) {
                var option = this.extractOptionFromString(string);

                if (!option.value) return false;

                return {
                    indent: option.indentLength,
                    option: {
                        value: option.value,
                        code: option.code,
                        options: [],
                        hidden: option.hidden
                    }
                };
            },

            parseTextOptions: function parseTextOptions(s) {
                var optionLines = s.split('\n');

                var options = [];

                var currentOptions = [options];

                optionLines.forEach(function (line, i) {
                    if (isOnlyWhitespace.test(line)) return; // Skip whitespace only lines
                    var data = this.parseOption(line);

                    if (!data) {
                        var error = new Error('Could not parse option "' + line + '" on line ' + (i + 1));
                        error.parseLine = i;
                        throw error;
                    }

                    // When we indent start adding options to the latest option
                    if (data.indent > currentOptions.length - 1) {
                        var mostRecentOption = currentOptions[0][currentOptions[0].length - 1];
                        currentOptions.unshift(mostRecentOption.options);
                    } else {
                        while (data.indent < currentOptions.length - 1) {
                            currentOptions.shift();
                        }
                    }

                    currentOptions[0].push(data.option);
                }, this);

                return options;
            }

        };
    }

    angular.module('GeckoEngage').factory('optionListParse', optionListParse);
})();