(function () {
    'use strict';

    function DownloadCtrl() {

        var ctrl = this;

        ctrl.products = [{
            name: 'Capture',
            image: '../images/app-icons/capture.png',
            description: 'Capture data remotely across platforms.',
            feature: Gecko.Package.FEATURE_FORM,
            platforms: ['iOS', 'Android', 'Amazon'],
            salesPitch: "Increase your student numbers, improve engagement and eliminate time consuming manual tasks with our enquiry management solution."
        }, {
            name: 'Visit',
            image: '../images/app-icons/visit.png',
            description: 'Scan QR codes to track event attendance.',
            feature: Gecko.Package.FEATURE_EVENT_PRO,
            platforms: ['iOS', 'Android', 'Amazon'],
            salesPitch: "Effortlessly track attendance, analyse numbers and discover the interests of your student with our Visit app, enhancing your event performance."
        }];

        ctrl.platforms = {
            iOS: {
                image: '../images/app-icons/iOS.png',
                button: '../images/app-icons/iOS-button.svg',
                download: {
                    form: 'https://itunes.apple.com/us/app/gecko-form-offline-lead-capture/id1062665551?mt=8',
                    scan: 'https://apps.apple.com/gb/app/geckovisit/id1495660725'
                }
            },
            Android: {
                image: '../images/app-icons/Android.png',
                button: '../images/app-icons/Android-button.svg',
                download: {
                    form: 'https://play.google.com/store/apps/details?id=com.geckolabs.engage&hl=en_GB',
                    scan: 'https://play.google.com/store/apps/details?id=com.geckolabs.geckovisit'
                }
            },
            Amazon: {
                image: '../images/app-icons/Amazon.png',
                button: '../images/app-icons/Amazon-button.svg',
                download: {
                    form: 'https://www.amazon.com/gp/product/B075DG6X5J',
                    scan: 'https://www.amazon.com/gp/product/B09NC3G6RH'
                }
            }
        };

        ctrl.showInstructions = {
            form: {
                iOS: false,
                Android: false,
                Amazon: false
            },
            scan: {
                iOS: false,
                Android: false,
                Amazon: false
            }
        };

        // Defaults radio selection to the first app they have access to or just the first one
        ctrl.product = ctrl.products.find(function (product) {
            return Gecko.has(product.feature);
        }) || ctrl.products[0];
        ctrl.platform = 'iOS';
    }

    function downloadInstructionsMessage() {
        return {
            restrict: 'E',
            controller: angular.noop,
            scope: {
                type: '=',
                button: '@',
                download: '@'
            },
            template: '\n                <a class="platform-download-btn" ng-if="button" href="{{download}}">\n                    <img height="72" ng-src="{{button}}" alt="App Download">\n                </a>\n            '
        };
    }

    angular.module('GeckoEngage').controller('DownloadCtrl', DownloadCtrl).directive('downloadInstructionsMessage', downloadInstructionsMessage);
})();