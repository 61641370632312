;(function () {
    'use strict';

    function webAppUrl() {
        return function () {
            return '' + window.location.origin + window.location.pathname.replace(/\/$/g, '');
        };
    };

    var shareBaseUrls = {
        prod: 'https://app.geckoform.com',
        stage: 'https://app-stage.geckoform.com'
    };

    function webShareUrl(env) {
        if (['prod', 'stage'].includes(env())) {
            return function () {
                return '' + shareBaseUrls[env()] + window.location.pathname.replace(/\/$/g, '');
            };
        }
        return webAppUrl();
    };

    angular.module('GeckoEngage').factory('webAppUrl', webAppUrl).factory('webShareUrl', webShareUrl);
})();