(function () {
    'use strict';

    function DataSecurityConsentReasonsCtrl($geckoModal, $state, reasons, handleMassAction) {
        var ctrl = this;
        ctrl.reasons = reasons.toInstances();

        ctrl.addGroupProp = function () {
            ctrl.reasons = ctrl.reasons.map(function (reason) {
                var groups = [];
                if (reason.group_general) groups.push(Gecko.Consent.group_titles.find(function (group) {
                    return group.id === Gecko.Consent.GROUP_GENERAL;
                }).title);
                if (reason.group_call) groups.push(Gecko.Consent.group_titles.find(function (group) {
                    return group.id === Gecko.Consent.GROUP_CALL;
                }).title);
                if (reason.group_email) groups.push(Gecko.Consent.group_titles.find(function (group) {
                    return group.id === Gecko.Consent.GROUP_EMAIL;
                }).title);
                if (reason.group_sms) groups.push(Gecko.Consent.group_titles.find(function (group) {
                    return group.id === Gecko.Consent.GROUP_SMS;
                }).title);
                reason.group_titles = groups.join(', ');
                return reason;
            });
        };

        // Add 'group' string reference
        ctrl.addGroupProp();

        ctrl.helpText = 'This section allows you to configure <b>Consent Reasons</b> that will be used across the system. For further information, <a href="https://academy.geckoengage.com/en/articles/6884211-consent#h_c3db8419c6" title="Creating Consent Reasons in Gecko">click here</a>.';

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'title',
            colClass: 'col-xs-6'
        }, {
            title: 'Group',
            key: 'group_titles',
            colClass: 'col-xs-6'
        }, {
            key: 'system',
            icon_styles: [{
                id: 1,
                icon: 'fa-lock'
            }]
        }];

        ctrl.rowAction = {
            action: function action(reason) {
                return $geckoModal.consentReasonModal(reason).result.then(function (reason) {
                    // Add 'group' string reference
                    if (reason) ctrl.addGroupProp();
                });
            }
        };

        // Table row redorder callback
        // ctrl.reorderOptions = {
        //     stop: function() {
        //         // Refresh order values
        //         GeckoUI.refreshOrder(ctrl.reasons);
        //         // Map order values
        //         var _reasons = GeckoUI.mapObjectArray(angular.copy(ctrl.reasons), {id: 'id', order: 'order'});

        //         // // API update order
        //         // new Gecko.Consent().post('', {
        //         //     'fields': _fields,
        //         // }).then(function() {
        //         //     GeckoUI.messenger.success('Contact field order updated.');
        //         // }).catch(function(err) {
        //         //     GeckoUI.messenger.error(err.errors);
        //         // });
        //     },
        // };

        // Row remove button
        ctrl.rowOptionsBtn = {
            icon: 'fa-cog',
            items: [{
                title: 'Edit reason',
                action: function action(reason) {
                    return ctrl.rowAction.action(reason);
                }
            }, {
                title: 'Remove reason',
                hideWhen: function hideWhen(reason) {
                    return reason.system;
                },
                action: function action(reason) {
                    GeckoUI.dialog.confirm('Are you sure you wish to remove this consent reason?', function (ok) {
                        if (ok) {
                            reason.remove().then(function () {
                                GeckoUI.messenger.success('Consent reason removed');
                                $state.go($state.$current.name, {}, { reload: true });
                            });
                        }
                    });
                }
            }]
        };

        ctrl.headerBtns = [{
            title: 'Assign Consent',
            icon: 'fa-users',
            action: function action() {
                return $geckoModal.manageConsentModal().result.then(function (response) {
                    // Handle the mass action
                    handleMassAction(response, $state.$current.name);
                });
            }
        }];
    }

    angular.module('GeckoEngage').controller('DataSecurityConsentReasonsCtrl', DataSecurityConsentReasonsCtrl);
})();