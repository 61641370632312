(function () {
    'use strict';

    function $geckoCallSidebar($geckoWatch, $geckoCall, $geckoVoip) {
        var _this = {

            destroy: function destroy() {
                _this.open = false;
                _this.canOpen = true;
            },

            open: false,

            isOpen: function isOpen() {
                return _this.open;
            },

            toggle: function toggle() {
                return _this.open = !_this.open;
            },

            applyContainerPadding: function applyContainerPadding() {
                return ($geckoCall.onCall || $geckoCall.isIncoming()) && !_this.isOpen();
            }

        };

        // Close call pane
        $geckoWatch($geckoCall, 'isOpen', function (value) {
            // Dont overwrite same value
            if (value === _this.open) return false;

            if (value) _this.open = true;
            if (!value) _this.open = false;
        });
        $geckoWatch($geckoCall, 'isPreparing', function (value) {
            // Dont overwrite same value
            if (value === _this.open) return false;

            if (value) _this.open = true;
            if (!value) _this.open = false;
        });
        $geckoWatch($geckoVoip, 'isIncoming', function (value) {
            // Dont overwrite same value
            if (value === _this.open) return false;
            // Dont overwrite if these apply
            if ($geckoCall.isOpen || $geckoCall.isListening()) return false;

            if (!value) _this.open = false;
        });

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoCallSidebar', $geckoCallSidebar);
})();