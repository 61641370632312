(function () {
    'use strict';

    function fieldErrors() {
        var fields = {};
        return {
            setErrors: function setErrors(errors) {
                angular.forEach(fields, function (field) {
                    field(undefined);
                });
                angular.forEach(errors, function (val, key) {
                    console.debug("key, val", key, val);
                    if (typeof fields[key] === 'function') fields[key](val[0]);
                });
            },
            register: function register(field_id, callback) {
                fields['field' + field_id] = callback;
            },
            unRegister: function unRegister(field_id) {
                delete fields['field' + field_id];
            }
        };
    }

    angular.module('GeckoEngage').factory('fieldErrors', fieldErrors);
})();