(function () {
    'use strict';

    function $geckoTransfer($rootScope, $geckoVoip, $timeout, $geckoSocket, $geckoCall, $geckoSounds) {

        var _this = {

            inProgress: false,

            timeout: null,

            timeoutCancel: function timeoutCancel() {
                if (_this.timeout) {
                    $timeout.cancel(_this.timeout);
                    this.timeout = null;
                }
            },

            // Send transfer feedback
            sendFeedback: function sendFeedback(callId, reason) {
                // Cancel any timeouts
                _this.timeoutCancel();
                // Send feedback
                return $geckoSocket.trigger('voip:transfer', {
                    call_id: callId,
                    user_id: Gecko.user.id,
                    name: Gecko.user.full_name,
                    accept: ['accept', 'take'].indexOf(reason) !== -1 ? true : false,
                    reason: reason
                });
            },

            cancel: function cancel() {
                $geckoSounds.toggle('ringing');
                // Play error sounds
                $geckoSounds.play('error');
                // Transfer no longer in progress
                _this.inProgress = false;
                // Fire message to stop incoming transfers
                return $geckoSocket.trigger('voip:transfer-cancel', { id: $geckoCall.call.id });
            },

            init: function init() {
                Gecko.on('available', function () {

                    // Incoming transfer from another user
                    $geckoSocket.registerEvent('voip:invite', function (data) {
                        if (!Gecko.User.voipInboundEnabled() || !$geckoVoip.enabled || $geckoVoip.connectionExists() || $geckoCall.isListening()) return false;
                        // Check current user is available to take call
                        if (data.user_ids && data.user_ids.indexOf(Gecko.user.id) !== -1) {
                            // Also transfers to overwrite incoming
                            if ($geckoVoip.isIncoming && !$geckoVoip.isTransfering) $geckoVoip.isIncoming = false;
                            $geckoVoip.incoming(data, true);
                        }
                    }, true);

                    // Call has been updated
                    $geckoSocket.registerEvent('call:updated', function (call) {
                        if (Gecko.user && $geckoCall.call && $geckoCall.call.id === call.id) {

                            // Refresh call if call if the following is updated
                            var callEndedByOtherUser = call.status === Gecko.Call.ENDED && Gecko.user.id !== call.user_id;
                            // Var userIdHasChanged       = [Gecko.Call.STARTED, Gecko.Call.HOLD].indexOf(call.status) !== -1 && $geckoCall.call && $geckoCall.call.user_id !== call.user_id;
                            var userIdHasChanged = [Gecko.Call.STARTED, Gecko.Call.HOLD].indexOf(call.status) !== -1 && Gecko.user.id !== call.user_id;
                            // Var holdStatusHasChanged   = $geckoCall.call.status === Gecko.Call.STARTED && call.status === Gecko.Call.HOLD;
                            // Var resumeStatusHasChanged = $geckoCall.call.status === Gecko.Call.HOLD && call.status === Gecko.Call.STARTED;

                            // If call is ended by other user
                            if (callEndedByOtherUser || userIdHasChanged) {
                                // Close modal and call
                                $geckoCall.reset(true);
                                // End voip connection
                                $geckoVoip.end();
                                return;
                            }

                            // THIS HAS BEEN REMOVED BECAUSE WE DONT SHOW THIS FOR CALLS THAT DONT BELONG TO THE USER ANYMORE
                            // If (holdStatusHasChanged || resumeStatusHasChanged){

                            //     // Hide loading indicator
                            //     $geckoCall.loadingIndicator(true);

                            //     $geckoCall.refresh().then(function(call){

                            //         $geckoCall.restore(call);

                            //         // Hide loading indicator
                            //         $geckoCall.loadingIndicator(false);

                            //         $rootScope.$digest();

                            //     }).catch(function(){
                            //         // Hide loading indicator
                            //         $geckoCall.loadingIndicator(false);
                            //     });
                            // }
                        }
                    }, true);

                    // Transfer feedback to other users
                    $geckoSocket.registerClientEvent('voip:transfer', function (data) {
                        if ($geckoCall.call && $geckoCall.call.id === data.call_id) {
                            var message;
                            // Stop sounds
                            $geckoSounds.toggle('ringing');
                            // Success or error popup?
                            if (data.accept) {
                                if (data.reason === 'accept') {
                                    message = data.name + ' accepted your transfer.';
                                } else if (data.reason === 'take') {
                                    message = data.name + ' took ownership of the call.';
                                }
                                // Show success popup alert
                                GeckoUI.messenger.success(message);
                            } else {
                                if (data.reason === 'reject') {
                                    message = data.name + ' declined your transfer.';
                                } else if (data.reason === 'leave') {
                                    message = data.name + ' left the call.';
                                } else {
                                    message = data.name + ' did not pick up your transfer.';
                                }
                                // Show alert popup alert
                                GeckoUI.messenger.error(message);
                                // Play error sounds
                                $geckoSounds.play('error');
                            }
                            // Transfer no longer in progress
                            _this.inProgress = false;
                        }
                    }, true);

                    // Allow transfers to be cancelled by user who initiated it.
                    $geckoSocket.registerClientEvent('voip:transfer-cancel', function (call) {
                        if ($geckoVoip.incomingCallId === call.id) {
                            // Cancel any timeouts
                            _this.timeoutCancel();
                            return $geckoVoip.accept = false;
                        }
                    });
                });
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoTransfer', $geckoTransfer);
})();