(function () {
    'use strict';

    function CallsTabsCtrl($state, Gecko) {

        var ctrl = this;

        ctrl.tabs = [{
            title: 'Overview',
            state: 'calls.overview'
        }, {
            title: 'Calls',
            state: 'calls.calls'
        }, {
            title: 'Numbers',
            state: 'calls.numbers',
            hideWhen: function hideWhen() {
                return !Gecko.User.hasInbound();
            }
        }, {
            title: 'Reporting',
            state: 'calls.report'
        }, {
            title: 'Agents',
            state: 'calls.agents',
            hideWhen: function hideWhen() {
                return !Gecko.User.hasInbound();
            }
        }];

        // Redirect to overview
        if ($state.current.name === 'calls') {
            $state.go('calls.overview');
        }

        ctrl.breadcrumbs = [{
            label: 'Calls',
            click: function click() {
                $state.go('calls.overview');
            }
        }, {
            label: 'Overview'
        }];
    }

    angular.module('GeckoEngage').controller('CallsTabsCtrl', CallsTabsCtrl);
})();