(function () {
    'use strict';

    var generateEditContoller = function generateEditContoller($scope, $state, $stateParams, $geckoSocket, ctrl, vars) {
        // Tabs
        if (ctrl.campaign.id) {
            ctrl.tabs = [{
                title: 'Overview',
                state: 'campaignsedit.view'
            }, {
                title: 'Settings',
                state: 'campaignsedit.settings',
                hideWhen: function hideWhen() {
                    return Gecko.User.isGroup(Gecko.Group.AGENT);
                }
            }, {
                title: 'Select Contacts',
                state: 'campaignsedit.filter',
                hideWhen: function hideWhen() {
                    return Gecko.User.isGroup(Gecko.Group.AGENT);
                }
            }, {
                title: 'Segments',
                state: 'campaignsedit.segments',
                hideWhen: function hideWhen() {
                    return vars.hideSegments || Gecko.User.isGroup(Gecko.Group.AGENT);
                }
            }, {
                title: 'Workflows',
                state: 'campaignsedit.workflows',
                hideWhen: function hideWhen() {
                    return Gecko.User.isGroup(Gecko.Group.AGENT);
                }
            }];
        }

        ctrl.requires = function () {
            if ($stateParams.module === 'call') {
                return [$stateParams.campaign_id === 'new' ? Gecko.ABILITY_CALL_CAMPAIGNS_CREATE : Gecko.ABILITY_CALL_CAMPAIGNS_UPDATE];
            } else if ($stateParams.module === 'sms') {
                return [Gecko.ABILITY_BROADCASTS_VIEW];
            }
        };

        ctrl.refreshFn = function () {
            if (ctrl.campaign.end_date && ctrl.campaign.end_date < moment().unix()) {
                return GeckoUI.messenger.error('Unable to refresh, campaign has ended.');
            }
            ctrl.campaign.refreshing = 1;
            ctrl.campaign.related_filter.refreshed_at = moment.utc().format('X');
            return angular.copy(ctrl.campaign).post(ctrl.campaign.id + '/refresh', { hard: true }).catch(GeckoUI.messenger.error);
        };

        // Stat card options
        ctrl.optionsBtn = {
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_USER);
            }
        };

        ctrl.optionsBtn.items = [{
            title: 'Refresh Campaign',
            action: function action() {
                return ctrl.refreshFn();
            },
            hideWhen: function hideWhen() {
                return [Gecko.Campaign.COMPLETE, Gecko.Campaign.CANCELLED, Gecko.Campaign.INVALID].find(function (status) {
                    return status === (ctrl.campaign.status || 0);
                }) || !(!ctrl.campaign.refreshing && (ctrl.campaign.related_filter || {}).refreshed_at);
            }
        }, {
            title: 'View live report',
            action: function action() {
                return $state.go('dashboardview', { campaign_id: $stateParams.campaign_id, module: ctrl.campaign.module });
            }
        }, {
            title: ctrl.campaign.module === Gecko.Campaign.MODULE_CALL ? 'Export campaign' : 'Export broadcast',
            action: function action() {
                return $state.go('exportnew', { export_id: 'new', campaign_id: $stateParams.campaign_id });
            }
        }, {
            title: ctrl.campaign.module === Gecko.Campaign.MODULE_CALL ? 'Clone campaign' : 'Clone broadcast',
            action: function action() {
                ctrl.campaign.clone().then(function (cloned_campaign) {
                    $state.go('campaignsedit.view', { module: ctrl.module, campaign_id: cloned_campaign.id });
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CALL_CAMPAIGNS_CREATE);
            }
        }, {
            title: ctrl.campaign.module === Gecko.Campaign.MODULE_CALL ? 'Remove campaign' : 'Remove broadcast',
            action: function action() {
                if (!GeckoUI.ableWithReason(vars.deletePermissions)) {
                    return;
                }
                GeckoUI.dialog.confirm(vars.removeConfirmMessage, function (value) {
                    if (value) {
                        ctrl.campaign.remove().then(function () {
                            GeckoUI.messenger.success(vars.removeSuccessMessage);
                            // Redirect to campaigns
                            $state.go('campaigns', { module: ctrl.campaign.module });
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CALL_CAMPAIGNS_DELETE);
            }
        }];

        // Save campaign
        ctrl.saveCampaign = function () {
            $scope.$broadcast('saveCampaign');
        };
        // Listen for CMD + S event
        $scope.$on('quickSave:campaign', ctrl.saveCampaign);

        ctrl.showSaveCampaignBtn = function () {
            return ['campaignsedit.view'].indexOf($state.$current.name) === -1;
        };

        // Breadcrumbs
        if (ctrl.campaign.id) {
            ctrl.breadcrumbs = [{
                label: vars.breadcrumbLabel,
                click: function click() {
                    $state.go('campaigns', angular.extend({}, $stateParams, { status: 'current' }));
                }
            }, {
                label: ctrl.campaign.title,
                click: function click() {
                    $state.go('campaignsedit.view', $stateParams);
                }
            }, {
                label: 'Edit',
                active: true
            }];
        } else {
            ctrl.breadcrumbs = [{
                label: vars.breadcrumbLabel,
                click: function click() {
                    $state.go('campaigns', angular.extend({}, $stateParams, { status: 'current' }));
                }
            }, {
                label: 'New',
                active: true
            }];
        }

        ctrl.campaign.refreshingMessage = 'Refreshing';
        $geckoSocket.registerEvent('campaign:refreshing', function (event) {
            if (event.id === ctrl.campaign.id) {
                ctrl.campaign.refreshing = event.refreshing;
                ctrl.campaign.refreshingMessage = event.message || 'Refreshing';
                $scope.$digest();
            }
        });
    };

    function CampaignsEditCtrl($scope, $state, $stateParams, $geckoSocket, campaign) {
        var ctrl = this;
        ctrl.campaign = campaign;
        ctrl.module = $stateParams.module;

        // Go to overview
        if ($state.$current.name == 'campaignsedit') $state.go('campaignsedit.settings');

        generateEditContoller($scope, $state, $stateParams, $geckoSocket, ctrl, {
            hideSegments: false,
            breadcrumbLabel: 'Campaigns',
            deletePermissions: Gecko.ABILITY_CALL_CAMPAIGNS_DELETE,
            removeConfirmMessage: 'Are you sure you want to remove this Campaign?',
            removeSuccessMessage: 'Campaign removed'
        });
    }

    function BroadcastsEditCtrl($scope, $state, $stateParams, $geckoSocket, campaign) {
        var ctrl = this;
        ctrl.campaign = campaign;
        ctrl.module = $stateParams.module;

        // Go to overview
        if ($state.$current.name == 'campaignsedit') $state.go('campaignsedit.settings');

        generateEditContoller($scope, $state, $stateParams, $geckoSocket, ctrl, {
            hideSegments: true,
            breadcrumbLabel: 'Broadcasts',
            deletePermissions: Gecko.ABILITY_BROADCASTS_DELETE,
            removeConfirmMessage: 'Are you sure you want to remove this Broadcast?',
            removeSuccessMessage: 'Broadcast removed'
        });
    }

    angular.module('GeckoEngage').controller('CampaignsEditCtrl', CampaignsEditCtrl).controller('BroadcastsEditCtrl', BroadcastsEditCtrl);
})();