(function () {
    'use strict';

    function geckoFieldValueLink($rootScope, $timeout) {

        var service = {
            update: function update(field_id, value) {
                var self = this;
                if (!isNaN(Number(field_id))) {

                    // Ugh! Dodgy scoping fun times.
                    $timeout(function () {
                        self.values[field_id] = value;
                    }, 0);
                }
            },
            values: Object.create(null)
        };

        $rootScope.$on('$stateChangeSuccess', function () {
            service.values = Object.create(null);
        });

        return service;
    }

    angular.module('GeckoEngage').factory('geckoFieldValueLink', geckoFieldValueLink);
})();