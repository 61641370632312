(function () {

    function ContactNumbersModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('contactNumbersModal', function (_contact, _contactIncludes) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/modules/contact/views/_modals/numbers.c692e676.html',
                controller: 'contactNumbersModalCtrl',
                resolve: {
                    contact: function contact() {
                        return _contact;
                    },
                    contactIncludes: function contactIncludes() {
                        return _contactIncludes;
                    },
                    numbers: function numbers() {
                        return new Gecko.Contact().include('numbers').get(_contact.id).then(function (contact) {
                            return contact.related_numbers;
                        });
                    }
                }
            };
        });
    }

    function contactNumbersModalCtrl($modalInstance, $scope, contact, contactIncludes, numbers) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.contactIncludes = contactIncludes;
        ctrl.numbers = numbers;

        // Lets check to see if a preferred call setting is avaialble, or we just leave it empty
        if (ctrl.contact.preferred_call) {
            ctrl.selected = [ctrl.contact.preferred_call];
        } else if (ctrl.numbers.length > 1) {
            ctrl.selected = [];
        } else {
            ctrl.selected = false;
        }

        $scope.$watch(function () {
            return ctrl.selected.length;
        }, function (newValue, oldValue) {
            if (newValue !== oldValue && ctrl.selected.length > 1) {
                ctrl.selected.splice(0, 1);
            }
        });

        ctrl.cols = [{
            key: 'do_not_call',
            status_title_none: true,
            expandable: true,
            status_styles: [{
                id: 1,
                label: 'label-danger',
                title: 'Do Not Call'
            }, {
                id: 0,
                label: 'label-success',
                title: 'Callable'
            }]
        }, {
            key: 'do_not_sms',
            status_title_none: true,
            expandable: true,
            status_styles: [{
                id: 1,
                label: 'label-danger',
                title: 'Do Not SMS'
            }, {
                id: 0,
                label: 'label-success',
                title: 'SMS Usable'
            }, {
                id: null,
                label: 'label-success',
                title: 'SMS Usable'
            }]
        }, {
            title: 'Number',
            key: 'cleaned',
            render: function render(number, col, row) {
                if (!number) {
                    return row.original || '';
                }
                return number;
            },
            colClass: 'col-xs-12'
        }];

        if (Gecko.able(Gecko.ABILITY_CONTACTS_UPDATE)) {

            ctrl.rowBtns = [{
                icon: 'fa-unlink',
                btnTooltip: 'Unlink',
                btnClass: 'btn-warning',
                action: function action(number, index) {
                    ctrl.contact.include(ctrl.contactIncludes).removeNumber(number.id).then(function (contact) {
                        GeckoUI.messenger.success('Number dissociated from contact.');
                        ctrl.numbers = contact.related_numbers;
                        $scope.$digest();
                    }, function () {
                        GeckoUI.messenger.error('Number could not be dissociated from contact.');
                    });
                }
            }];

            // Modal buttons
            ctrl.footerBtns = [{
                preset: 'save',
                hideWhen: function hideWhen() {
                    return ctrl.numbers.length < 2;
                },
                action: function action() {
                    // Check to see if something is selected and update the contact if it is
                    if (ctrl.selected.length > 0) {
                        ctrl.contact.preferred_call = ctrl.selected[0];
                        ctrl.contact.include(ctrl.contactIncludes).save().then(function (contact) {
                            GeckoUI.messenger.success('Preferred number updated.');
                            $modalInstance.close(contact);
                        }, function () {
                            GeckoUI.messenger.error('Preferred number could not be updated.');
                        });
                    } else {
                        $modalInstance.close(ctrl.contact);
                    }
                }
            }, {
                title: 'Close',
                icon: 'fa-times',
                hideWhen: function hideWhen() {
                    return ctrl.numbers.length > 1;
                },
                action: function action() {
                    $modalInstance.dismiss();
                }
            }];
        }
    }

    angular.module('GeckoEngage').config(ContactNumbersModals).controller('contactNumbersModalCtrl', contactNumbersModalCtrl);
})();