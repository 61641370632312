(function () {

    function AddHostModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('addHostModal', function () {
            return {
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal\n                            data-title="Add Host"\n                            footer-btns="ctrl.footerBtns"\n                            icon="fa-users">\n\n                            <host-fields host="ctrl.host"></host-fields>\n\n                        </gecko-modal>\n                    ',
                controller: 'AddHostModalCtrl',
                requires: [function ($stateParams) {
                    return Gecko.ABILITY_HOSTS_CREATE;
                }],
                resolve: {
                    host: function host($stateParams) {
                        return new Gecko.Host();
                    }
                }
            };
        });
    }

    function AddHostModalCtrl($modalInstance, $state, $stateParams, unsavedAlert, host) {
        var _this = this;

        var ctrl = this;
        ctrl.host = host;
        ctrl.disableSave = false;

        ctrl.save = function () {
            ctrl.disableSave = true;
            _this.host.save().then(function (host) {
                GeckoUI.messenger.success('Host has been saved');
                unsavedAlert.clear();

                // Close modal and reload the page
                $modalInstance.close(host);
                ctrl.disableSave = false;

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Host Save Success', {
                    'host id': host.id,
                    'host title': host.name
                });
            }).catch(function (err) {
                ctrl.disableSave = false;
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Host Save Error', {
                    'host id': ctrl.host.id || 'New',
                    'host name': ctrl.host.name,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            action: ctrl.save.bind(ctrl),
            disabledWhen: function disabledWhen() {
                return ctrl.disableSave;
            }
        }];
    }

    angular.module('GeckoEngage').config(AddHostModal).controller('AddHostModalCtrl', AddHostModalCtrl);
})();