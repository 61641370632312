(function () {
    'use strict';

    function ConditionModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('condition', function (_condition, _campaign, _multiData, _type, _responseFields, _dataFields, _geoFields) {
            return {
                size: 'md',
                controllerAs: 'ctrl',
                templateUrl: '/components/gecko-conditions/_modals/condition.html',
                controller: 'ConditionsModalCtrl',
                resolve: {
                    condition: function condition() {
                        return _condition;
                    },
                    campaign: function campaign() {
                        return _campaign;
                    },
                    multiData: function multiData() {
                        return _multiData;
                    },
                    type: function type() {
                        return _type;
                    },
                    responseFields: function responseFields() {
                        return _responseFields;
                    },
                    dataFields: function dataFields() {
                        return _dataFields;
                    },
                    geoFields: function geoFields() {
                        return _geoFields;
                    }
                }
            };
        });
    }

    function ConditionsModalCtrl($modalInstance, $scope, campaign, condition, multiData, type, $matchTypes, responseFields, dataFields, geckoOptions, asyncOptions, OptionsPresets, $timeout, geckoEventPickerService, geoFields, $formBuilderService, inlineLabelFieldService, geckoDataService) {
        var ctrl = this;
        ctrl.type = type;

        $scope.GeckoUI = GeckoUI;
        $scope.Gecko = Gecko;

        // Setup all of the variables being passed through
        ctrl.campaign = campaign; // This is also an Event at times?!?! WTF!
        ctrl.condition = condition;
        ctrl.matchTypes = $matchTypes;

        ctrl.responseFields = responseFields;
        ctrl.dataFields = dataFields.filter(function (data) {
            return data.module === ctrl.campaign.module;
        });
        ctrl.geoFields = geoFields;

        // Parse through the multiData to get all the variables
        angular.forEach(multiData, function (value, key) {
            ctrl[key] = value.toArray ? value.toArray() : value;
        });

        // Either setup the condition from one being passed or create a new one
        if (condition) {
            ctrl.condition = condition;
        } else {
            ctrl.condition = {};
        }

        //Plebby solution to load up tiered event selects
        if (ctrl.condition.type === Gecko.Condition.MODEL_EVENT) {
            ctrl.condition.value = GeckoUI.wrapArray(ctrl.condition.value);
        }

        ctrl.eventSessionPicker = geckoEventPickerService.prepareField({
            label: 'Event/Session',
            optionsInit: OptionsPresets.events.init,
            options: [],
            noBlank: [true, false],
            getOptions: OptionsPresets.events.get,
            placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
            optionsKey: 'id',
            optionsLabelKey: 'nice_title'
        }, ctrl.condition.system ? 1 : 0);

        ctrl.eventSessionPickerC = geckoEventPickerService.prepareField({
            label: 'Event/Session',
            optionsInit: OptionsPresets.events.init,
            options: [],
            noBlank: [true, false],
            getOptions: angular.copy(OptionsPresets.events.get).slice(0, -1),
            placeholder: ['– Choose Event –', '– Choose Session –'],
            optionsKey: 'id',
            optionsLabelKey: 'nice_title'
        }, ctrl.condition.system ? 1 : 0);

        ctrl.eventsField = {
            optionsInit: OptionsPresets.events.init,
            options: [[ctrl.campaign.toObject()], []],
            noBlank: [true, false],
            getOptions: OptionsPresets.events.get,
            placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
            optionsKey: 'id',
            optionsLabelKey: 'nice_title'
        };

        var validate = {
            assigned: ['value'],
            call_outcome: ['campaign_id', 'value'],
            contact_field: ['contact_field_id', 'value'],
            event: ['value'],
            field: ['id'],
            filter: ['in_filter'],
            geo: ['field'],
            import: ['import_id'],
            label: ['value'],
            outcome: ['value'],
            sms_outcome: ['campaign_id', 'value'],
            status: ['value'],
            owner: ['value'],
            response: ['property'],
            data: ['id'],
            contact_task: ['value', 'status']
        };

        // Getter for match type
        ctrl.getMatchType = function () {

            switch (ctrl.condition.type) {
                case Gecko.Condition.MODEL_OWNER:
                case Gecko.Condition.MODEL_ASSIGNED:
                case Gecko.Condition.MODEL_CAPTURED_BY:
                case Gecko.Condition.MODEL_IMPORT:
                case Gecko.Condition.MODEL_RESPONSE:
                case Gecko.Condition.MODEL_OUTCOME:
                case Gecko.Condition.MODEL_STATUS:
                case Gecko.Condition.MODEL_GEO:
                case Gecko.Condition.MODEL_CONTACT_TASK:
                    return $matchTypes.basic;

                case Gecko.Condition.MODEL_CONTACT_FIELD:
                case Gecko.Condition.MODEL_FIELD:
                    var field = GeckoUI.getField(ctrl.fields, ctrl.condition.contact_field_id || ctrl.condition.id);
                    if (field) {
                        switch (field.data_type) {
                            case Gecko.Field.DATA_TYPE_TIMESTAMP:
                                return $matchTypes.date;
                            case Gecko.Field.DATA_TYPE_INTEGER:
                                return $matchTypes.numeric;
                        }
                        return $matchTypes.standard;
                    }
                    break;

                case Gecko.Condition.MODEL_EVENT:
                    return ctrl.type === 'event' ? $matchTypes.event : $matchTypes.basic;

                // Fall through to standard
                case Gecko.Condition.MODEL_FILTER:
                case Gecko.Condition.MODEL_SMS_OUTCOME:
                case Gecko.Condition.MODEL_CALL_OUTCOME:
                    return $matchTypes.standard;

                case Gecko.Condition.MODEL_CALL_COUNT:
                case Gecko.Condition.MODEL_DATA:
                case Gecko.Condition.MODEL_ENGAGEMENT_SCORE:
                    return $matchTypes.numeric;

                case Gecko.Condition.MODEL_LABEL:
                case Gecko.Condition.MODEL_CONTACT_LABEL:
                    if (ctrl.type == 'form') return $matchTypes.response_label;
                    return $matchTypes.label;
            }
        };

        ctrl.isOptionType = function (type) {
            return [Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI, Gecko.Field.TYPE_PREFERRED_LANGUAGE].indexOf(type) !== -1;
        };

        ctrl.updateConditionOptions = function (noClear) {

            var field = GeckoUI.getObjectByKey(ctrl.fields, 'id', ctrl.condition.id);

            // Dont do anything if not an option type
            if (!ctrl.isOptionType(field.type)) return;

            if (!noClear) {
                if (field.data_type === Gecko.Field.DATA_TYPE_TIMESTAMP) {
                    ctrl.condition.value = null;
                } else {
                    ctrl.condition.value = [];
                }
            }

            ctrl.conditionOptions = null;
            if (field && field.parent_id) {
                var level = 0;
                var getTopParent = function getTopParent(field) {
                    if (!field.parent_id) {
                        return field;
                    }
                    level++;
                    return getTopParent(GeckoUI.getObjectByKey(ctrl.fields, 'id', field.parent_id));
                };

                var values = [];

                var addOptions = function addOptions(parentOption, optgroup, currentLevel) {
                    if (parentOption.options && parentOption.options.length) {
                        parentOption.options.forEach(function (opt) {
                            // Add option list
                            if (level === currentLevel) {
                                opt.optgroup = optgroup;
                                values.push(opt);
                                return;
                            }

                            // Check for further options
                            if (opt.options && opt.options.length) {
                                addOptions(opt, opt.value, currentLevel + 1);
                            }
                        });
                    }
                };

                geckoOptions.get(getTopParent(field).option_id).then(function (options) {
                    // Build options
                    options.values.forEach(function (parentOption) {
                        return addOptions(parentOption, parentOption.value, 1);
                    });
                    // Set options
                    ctrl.conditionOptions = { values: values };
                });
            } else if (field && field.option_id) {
                geckoOptions.get(field.option_id).then(function (options) {
                    ctrl.conditionOptions = options;
                });
            } else if (field && field.values && field.values.length) {
                ctrl.conditionOptions = { values: $formBuilderService.prepareOptions(field, field.values) };
            } else {
                $timeout(function () {
                    ctrl.conditionOptions = { values: ctrl.condition.value };
                }, 0);
            }
        };

        ctrl.updateConditionOptions(true);

        // Footer Save/Add button
        ctrl.footerBtns = [];
        if (ctrl.condition.type && !ctrl.condition.system) {
            ctrl.footerBtns.push({
                title: 'Remove Condition',
                preset: 'remove',
                position: 'secondary',
                action: function action() {
                    var response = {};
                    response.condition = ctrl.condition;
                    response.method = 'delete';
                    $modalInstance.close(response);
                }
            });
        }
        ctrl.footerBtns.push({
            title: ctrl.condition.type ? 'Save Condition' : 'Add Condition',
            preset: ctrl.condition.type ? 'save' : 'add',
            btnClass: 'btn-primary',
            action: function action() {
                if (!ctrl.condition.type || !ctrl.condition.match) {
                    return false;
                }

                // Check if event session is picked, a session time is included also.
                if (ctrl.condition.type === 'event' && ctrl.condition.match !== 'C' && !geckoEventPickerService.sessionCheck(ctrl.eventSessionPicker, ctrl.condition.value)) return;

                if (ctrl.condition.type === 'event') {
                    if (ctrl.condition.match === 'C' && ctrl.eventSessionPickerC.allValues.length === 3) {
                        ctrl.condition.value = ctrl.eventSessionPickerC.allValues[1];
                    } else if (Array.isArray(ctrl.condition.value)) {
                        ctrl.condition.value = ctrl.condition.value[0];
                    }
                }

                var validated = true;
                if (validate[ctrl.condition.type]) {
                    angular.forEach(validate[ctrl.condition.type], function (value) {
                        if (!ctrl.condition[value] || ctrl.condition[value] === null || ctrl.condition[value] === '') {
                            validated = false;
                        }
                    });
                } else {
                    if (!ctrl.condition.value) {
                        validated = false;
                    }
                }

                if (!validated) {
                    return false;
                }

                var response = {};
                response.condition = ctrl.condition;
                response.method = 'save';
                return $modalInstance.close(response);
            }
        });

        if (type == 'segment') {
            ctrl.conditionTypes = [{
                value: Gecko.Condition.MODEL_CONTACT_FIELD,
                label: 'Field'
            }, {
                value: Gecko.Condition.MODEL_IMPORT,
                label: 'Import'
            }, {
                value: Gecko.Condition.MODEL_LABEL,
                label: 'Contact label'
            }, {
                value: Gecko.Condition.MODEL_RESPONSE,
                label: 'Response'
            }, {
                value: Gecko.Condition.MODEL_FILTER,
                label: 'Saved Search'
            }];

            if (Gecko.has(Gecko.Package.FEATURE_SMS)) {
                ctrl.conditionTypes.push({
                    value: Gecko.Condition.MODEL_SMS_OUTCOME,
                    label: 'SMS outcome'
                });
            }

            if (Gecko.User.hasOutboundOrInbound()) {
                ctrl.conditionTypes.push({
                    value: Gecko.Condition.MODEL_CALL_OUTCOME,
                    label: 'Call outcome'
                });
                ctrl.conditionTypes.push({
                    value: Gecko.Condition.MODEL_CALL_COUNT,
                    label: 'Call count'
                });
            }
        } else if (type == 'event') {
            ctrl.conditionTypes = [{
                value: Gecko.Condition.MODEL_FIELD,
                label: 'Contact field'
            }, {
                value: Gecko.Condition.MODEL_CONTACT_LABEL,
                label: 'Contact label'
            }, {
                value: Gecko.Condition.MODEL_STATUS,
                label: 'Status'
            }];

            // Only include this if default event condition (dont allows users to add themselves)
            if (ctrl.condition.system) {
                ctrl.conditionTypes.push({
                    value: Gecko.Condition.MODEL_EVENT,
                    label: 'Event'
                });
            }
        } else if (type == 'form') {
            ctrl.conditionTypes = [{
                value: Gecko.Condition.MODEL_GEO,
                label: 'Geo Location'
            }, {
                value: Gecko.Condition.MODEL_FIELD,
                label: 'Field'
            }, {
                value: Gecko.Condition.MODEL_LABEL,
                label: 'Response label'
            }, {
                value: Gecko.Condition.MODEL_CONTACT_LABEL,
                label: 'Contact label'
            }, {
                value: Gecko.Condition.MODEL_OWNER,
                label: 'Captured by'
            }, {
                value: Gecko.Condition.MODEL_ASSIGNED,
                label: 'Assigned user'
            }, {
                value: Gecko.Condition.MODEL_EVENT,
                label: 'Event the capture app was used'
            }];
        } else if (type == 'recurring_import') {
            ctrl.conditionTypes = [{
                value: Gecko.Condition.MODEL_FIELD,
                label: 'Contact field'
            }, {
                value: Gecko.Condition.MODEL_LABEL,
                label: 'Contact label'
            }];
        } else {
            // Base condition types
            ctrl.conditionTypes = [{
                value: Gecko.Condition.MODEL_OUTCOME,
                label: 'Outcome'
            }, {
                value: Gecko.Condition.MODEL_FIELD,
                label: 'Contact field'
            }, {
                value: Gecko.Condition.MODEL_CONTACT_LABEL,
                label: 'Contact label'
            }];

            // Only use assigned_user when call workflows
            if (ctrl.campaign && ctrl.campaign.module && ctrl.campaign.module == Gecko.Campaign.MODULE_CALL) {
                ctrl.conditionTypes.push({
                    value: Gecko.Condition.MODEL_DATA,
                    label: 'Data'
                });
                ctrl.conditionTypes.push({
                    value: Gecko.Condition.MODEL_ASSIGNED,
                    label: 'Assigned user'
                });
            }
        }

        if (Gecko.able(Gecko.ABILITY_ENGAGEMENT_SCORE)) {
            ctrl.conditionTypes.push({
                value: Gecko.Condition.MODEL_ENGAGEMENT_SCORE,
                label: 'Contact\'s engagement score'
            });
        }

        if (Gecko.able(Gecko.ABILITY_TASKS_VIEW)) {
            ctrl.conditionTypes.push({
                value: Gecko.Condition.MODEL_CONTACT_TASK,
                label: 'Contact task and objective'
            });
        }

        ctrl.buildFieldOptions = function (fields) {
            return fields.filter(function (field) {
                // Remove calculated fields
                if (field.is_calculated) return false;

                // Dont allow conditions for these field types
                return ![Gecko.Field.TYPE_REPEATABLE, Gecko.Field.TYPE_MATRIX, Gecko.Field.TYPE_MEDIA, Gecko.Field.TYPE_EVENT, Gecko.Field.TYPE_FILE, Gecko.Field.TYPE_CONSENT, Gecko.Field.TYPE_SECTION, Gecko.Field.TYPE_SCRIPT].includes(field.type);
            });
        };

        ctrl.fieldOptions = ctrl.buildFieldOptions(ctrl.fields);
        ctrl.contactFieldOptions = ctrl.buildFieldOptions(ctrl.contact_fields);
        ctrl.fieldIsMissing = ctrl.condition.id && !ctrl.fields.find(function (field) {
            return field.id === ctrl.condition.id;
        });
        ctrl.contactFieldIsMissing = ctrl.condition.contact_field_id && !ctrl.contact_fields.find(function (field) {
            return field.id === ctrl.condition.contact_field_id;
        });

        // Label fields
        ctrl.labelFieldValue = {
            labels: []
        };

        var buildLabelFieldValue = function buildLabelFieldValue(labels) {
            if (Array.isArray(ctrl.condition.value)) {
                return ctrl.condition.value.map(function (labelId) {
                    return labels.find(function (label) {
                        return label.id === Number(labelId);
                    });
                });
            }
            if (ctrl.condition.value) {
                return [labels.find(function (label) {
                    return label.id === Number(ctrl.condition.value);
                })];
            }
            return [];
        };

        var selectSaveNewLabelAndAddToOptions = function selectSaveNewLabelAndAddToOptions(label) {
            return inlineLabelFieldService.selectLabel(label).then(function (label) {
                // Refresh field options
                return geckoDataService.fetch(['labels']).then(function (data) {
                    ctrl.labelFieldSingle.options = data.labels;
                    ctrl.labelFieldMultiple.options = data.labels;
                    // Update labels data in main conditions UI
                    ctrl.labels = data.labels;
                    $scope.$digest();
                }).then(function () {
                    return label;
                });
            });
        };

        var labelFieldInit = false;

        var updateLabelFieldValue = function updateLabelFieldValue(fetchedOptions) {
            if (labelFieldInit) return;
            //
            ctrl.labelFieldValue.labels = buildLabelFieldValue(fetchedOptions);
            labelFieldInit = true;
        };

        var updateLabelFieldMultipleOptions = function updateLabelFieldMultipleOptions(newOptions) {
            ctrl.labelFieldMultiple.options = newOptions;
        };

        var updateLabelFieldSingleOptions = function updateLabelFieldSingleOptions(newOptions) {
            ctrl.labelFieldSingle.options = newOptions;
        };

        ctrl.labelFieldSingle = inlineLabelFieldService.buildField({
            obj: ctrl.labelFieldValue,
            id: 'labels',
            options: [],
            maxLimit: 1, // ONLY allow 1 label id to be set on a condition,
            newItem: function newItem(labelName) {
                // ONLY allow 1 label to be created
                if (ctrl.labelFieldValue.labels.length) return;

                return inlineLabelFieldService.newLabel(labelName, ctrl.labelFieldSingle.options);
            },
            getOptions: function getOptions($select) {
                return inlineLabelFieldService.buildLabelFieldOptions($select, updateLabelFieldValue, updateLabelFieldSingleOptions);
            },
            onSelect: function onSelect(label) {
                return selectSaveNewLabelAndAddToOptions(label).then(function (label) {
                    // Set label id on condition object
                    ctrl.condition.value = label.id;
                    ctrl.labelFieldValue.labels = buildLabelFieldValue(ctrl.labelFieldSingle.options);
                });
            },
            onRemove: function onRemove() {
                // Reset label id on condition object
                return Promise.resolve().then(function () {
                    ctrl.condition.value = null;
                    ctrl.labelFieldValue.labels = buildLabelFieldValue();
                });
            }
        });

        // Tasks field
        ctrl.taskField = {
            id: 'value',
            label: 'Task',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.tasks,
            orderBy: 'order',
            optionsKey: 'id',
            optionsLabelKey: 'name',
            required: true
        };

        // Task status field
        ctrl.taskStatusField = {
            id: 'status',
            label: 'Status',
            type: Gecko.Field.TYPE_SELECT,
            options: Object.keys(Gecko.Task.task_statuses).map(function (status) {
                return { id: status, label: Gecko.Task.task_statuses[status] };
            }),
            orderBy: 'order',
            optionsKey: 'id',
            optionsLabelKey: 'label',
            required: true
        };

        ctrl.labelFieldMultiple = inlineLabelFieldService.buildField({
            maxLimit: 999, // Allow many label ids to be set on a condition,
            obj: ctrl.labelFieldValue,
            id: 'labels',
            options: [],
            getOptions: function getOptions($select) {
                return inlineLabelFieldService.buildLabelFieldOptions($select, updateLabelFieldValue, updateLabelFieldMultipleOptions);
            },
            newItem: function newItem(labelName) {
                return inlineLabelFieldService.newLabel(labelName, ctrl.labelFieldMultiple.options);
            },
            onSelect: function onSelect(label, $select) {
                return selectSaveNewLabelAndAddToOptions(label).then(function (label) {
                    // Set label id on condition object
                    ctrl.condition.value = [].concat(ctrl.condition.value || [], [label.id]);
                    ctrl.labelFieldValue.labels = buildLabelFieldValue(ctrl.labelFieldMultiple.options);
                });
            },
            onRemove: function onRemove(label) {
                // Reset label id on condition object
                return Promise.resolve().then(function () {
                    ctrl.condition.value = ctrl.condition.value.filter(function (labelId) {
                        return Number(labelId) !== label.id;
                    });
                    ctrl.labelFieldValue.labels = buildLabelFieldValue(ctrl.labelFieldMultiple.options);
                });
            }
        });
    }

    angular.module('GeckoEngage').config(ConditionModal).controller('ConditionsModalCtrl', ConditionsModalCtrl);
})();