(function () {
    'use strict';

    function VoipTestCtrl($state) {
        var ctrl = this;

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                return $state.go('settings');
            }
        }, {
            label: 'VoIP Test',
            active: true
        }];

        // Fire Intercom event
        GeckoUI.triggerIntercomEvent('Visited VOIP Test Page', {});
    }

    angular.module('GeckoEngage').controller('VoipTestCtrl', VoipTestCtrl);
})();