(function () {

    function FieldOptionsModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('fieldOptionsImportModal', function (_option) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/modules/field_options/views/_modals/import.2ef36e50.html',
                controller: 'fieldOptionsImportModalCtrl',
                resolve: {
                    option: function option() {
                        return _option;
                    },
                    data: function data() {
                        var _query = {};
                        var integrationRfields = {
                            integration: ['title', 'type']
                        };
                        _query.integrations = new Gecko.Integration().rfields(integrationRfields).where('type', GeckoUI.getIdArray(GeckoUI.getObjectArrayByKey(Gecko.Integration.type_titles, 'configurable', 1), 'id')).perPage('all');
                        _query.option_templates = new Gecko.OptionTemplate().perPage('all');
                        return Gecko.multi(_query);
                    }
                }
            };
        });
    }

    function fieldOptionsImportModalCtrl($state, $stateParams, $scope, $modalInstance, data, option, optionListParse) {
        var ctrl = this;
        ctrl.activeTab = null;
        ctrl.option = option;
        ctrl.integrations = data.integrations.toArray();
        ctrl.option_templates = data.option_templates.toArray();
        ctrl.optionValues = cleanOptions(ctrl.option.values);
        ctrl.hasExampleList = $stateParams.option_id == 'new';
        ctrl.appendOptions = ctrl.option.values && ctrl.option.values.length && $stateParams.option_id != 'new' || $stateParams.option_id == 'new' && !ctrl.hasExampleList;

        ctrl.updateSelectedOptionTemplate = updateSelectedOptionTemplate;
        ctrl.importSelectedOptionTemplate = importSelectedOptionTemplate;
        ctrl.updateSelectedIntegration = updateSelectedIntegration;
        ctrl.createIntegrationOptions = createIntegrationOptions;
        ctrl.createTextOptions = createTextOptions;

        ctrl.coreDropdown = [{
            label: "Template",
            id: "template",
            value: "template"
        }, {
            label: "Text",
            value: "text",
            id: "text"
        }, {
            label: "Integration",
            value: "integration",
            id: "integration"
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Import options',
            icon: 'fa-download',
            btnClass: 'btn-primary',
            action: function action() {
                if (ctrl.activeTab == 'template') {
                    return ctrl.importSelectedOptionTemplate();
                } else if (ctrl.activeTab == 'text') {
                    return ctrl.createTextOptions();
                } else if (ctrl.activeTab == 'integration') {
                    return ctrl.createIntegrationOptions();
                }
            }
        }];

        /*
         *  Import Options
         */

        // Build an array of options from values
        function buildOptions(values) {
            var _options = [];
            angular.forEach(values, function (option) {
                _options.push({
                    value: option.value,
                    code: option.code,
                    hidden: option.hidden,
                    options: []
                });
            });
            return _options;
        }

        // Append options if options already there
        function insertOptions(options) {
            if (!ctrl.optionValues) {
                ctrl.optionValues = [];
            }
            // Clear the options if we are not appending
            if (!ctrl.appendOptions) {
                ctrl.optionValues.length = 0;
            }

            ctrl.optionValues = ctrl.optionValues.concat(options);

            // Toggle append to true after the first import
            ctrl.appendOptions = true;
        }

        // Reset import modal values
        function resetImportOptions() {
            // $scope.activeImportTab = 'template';
            ctrl.activeTab = 'template';
            ctrl.selectedIntegration = null;
            ctrl.importText = "";
            ctrl.hasExampleList = false;

            // Hide modal
            $modalInstance.close(ctrl.optionValues);
        }

        // Field options utility functions.
        function cleanOptions(options) {
            if (!options) {
                return;
            }
            return options.map(function (option) {
                if (option.options) {
                    option.options = cleanOptions(option.options);
                }

                return {
                    value: option.value,
                    code: option.code,
                    hidden: option.hidden,
                    options: option.options || []
                };
            });
        }

        /*
        *   Import Integration Options
        */
        function updateSelectedOptionTemplate() {
            if (ctrl.selectedOptionTemplateId) {

                // Get option template
                var option_template = GeckoUI.getObjectByKey(ctrl.option_templates, 'id', ctrl.selectedOptionTemplateId);

                // Get options
                ctrl.selectedOptionTemplateValues = option_template.values;
            }
        }

        function importSelectedOptionTemplate() {
            if (ctrl.selectedOptionTemplateValues) {

                // Build options
                var _options = buildOptions(ctrl.selectedOptionTemplateValues);

                // Set list values on option list
                insertOptions(_options);

                // Reset modal for next open
                resetImportOptions();
            } else {
                // Reset modal for next open
                resetImportOptions();
            }
        }

        /*
         *   Import Integration Options
         */

        // Only show integration fields that have options list
        function updateSelectedIntegration() {
            if (ctrl.selectedIntegration) {
                new Gecko.IntegrationField().rfields({ integrationfield: ['label', 'has_options'] }).where('integration_id', ctrl.selectedIntegration).perPage('all').get().then(function (fields) {
                    var integrationFields = fields.toArray();
                    ctrl.integrationFields = [];
                    angular.forEach(integrationFields, function (field) {
                        if (field.has_options) {
                            ctrl.integrationFields.push(field);
                        }
                    });
                    $scope.$digest();
                });
            }
        }

        // Build integration options
        function createIntegrationOptions() {

            if (!ctrl.selectedIntegrationField) {
                GeckoUI.messenger.error('Please select an integration field to save.');
                return;
            }

            new Gecko.IntegrationField().rfields({ integrationfield: ['label', 'optionset'] }).get(ctrl.selectedIntegrationField).then(function (selectedIntegrationFieldWithOptions) {

                var options = selectedIntegrationFieldWithOptions,
                    optionName = options.label,
                    integrationOptions = options.optionset;

                var type = GeckoUI.getObjectByKey(ctrl.integrations, 'id', ctrl.selectedIntegration).type;

                var _options = [];
                angular.forEach(integrationOptions, function (option) {
                    if (type == 'radius') {
                        _options.push({
                            value: option,
                            code: option,
                            options: []
                        });
                    } else if (type == 'salesforce') {
                        _options.push({
                            value: option.name,
                            code: option.value,
                            options: []
                        });
                    } else if (type == 'connect') {
                        if (option.value != '[object Object]') {
                            // This is a connect issue, where the first option is a stringified object
                            _options.push({
                                value: option.name,
                                code: option.value,
                                options: []
                            });
                        }
                    } else if (type == 'newdynamics') {
                        _options.push({
                            value: option.name,
                            code: option.value,
                            options: []
                        });
                    } else if (type == 'engage-dynamics') {
                        _options.push({
                            value: option.value,
                            code: option.code,
                            options: []
                        });
                    }
                });

                // Set list values on option list
                insertOptions(_options);

                if ($stateParams.option_id == 'new') ctrl.option.name = optionName;

                if (!integrationOptions) {
                    GeckoUI.messenger.error('Please select an integration field to save.');
                    return;
                }

                // Reset modal for next open
                resetImportOptions();
            }).catch(function (err) {
                GeckoUI.messenger.error('Something went wrong - please contact Gecko support.');
            });
        }

        /*
         *  Import Text Options
         */

        // Build text options
        function createTextOptions() {

            if (ctrl.importText) {
                // Build options

                var _options = optionListParse.parseTextOptions(ctrl.importText);

                // Set list values on option list
                insertOptions(_options);

                // Reset modal for next open
                resetImportOptions();
            }
        }
    }

    angular.module('GeckoEngage').config(FieldOptionsModals).controller('fieldOptionsImportModalCtrl', fieldOptionsImportModalCtrl);
})();