(function () {

    function EmailModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('buildEmailModal', function (_message, _templateTagModels) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/message/email_modal.html',
                controller: 'buildEmailModalCtrl',
                resolve: {
                    message: function message() {
                        return _message;
                    },
                    accountSender: function accountSender() {
                        return new Gecko.Sender().rfields({ sender: ['name', 'email'] }).get(Gecko.account.sender_id);
                    },
                    templateTagModels: function templateTagModels() {
                        return _templateTagModels;
                    }
                }
            };
        });
    }

    function buildEmailModalCtrl($modalInstance, asyncOptions, message, accountSender, templateTagModels) {
        var ctrl = this;
        if (!message) {
            ctrl.message = {
                subject: null,
                message: null,
                template_id: null,
                sender_id: accountSender.id
            };
        }

        // Field list
        ctrl.fields = [{
            id: 'sender_id',
            label: 'From',
            description: 'Choose the identity that this message will be sent from - <a ui-sref="verifiedsenders.senders">create or edit existing senders here</a>.',
            type: Gecko.Field.TYPE_SELECT,
            options: [accountSender],
            getOptions: asyncOptions.create(new Gecko.Sender().where('type', Gecko.Sender.EMAIL).orderBy('name').rfields({ sender: ['name', 'email'] })),
            choiceTemplate: 'name-email-choice',
            matchTemplate: 'name-email-match',
            noBlank: true,
            colClass: 'none'
        }, {
            id: 'template_id',
            label: 'Template',
            description: 'Choose a template for this message. Need to create a new template? <a ui-sref="settingstemplate.settings({ template_id: \'new\' })">Click here</a>.',
            type: Gecko.Field.TYPE_SELECT,
            options: [],
            getOptions: asyncOptions.create(new Gecko.Template().where('type', Gecko.Template.TYPE_EMAIL).orderBy('name').rfields({ template: ['name', 'subject', 'body', 'include_wrapper'] })),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            colClass: 'none',
            onChange: function onChange(change) {
                var template = GeckoUI.gobk(this.options, 'id', change.value);
                if (template) {
                    ctrl.message.subject = template.subject;
                    ctrl.message.message = template.body;
                } else {
                    ctrl.message.subject = "";
                    ctrl.message.message = "";
                }
            }
        }, {
            id: 'subject',
            label: 'Email Subject',
            description: 'This will be shown in the Subject area of the email client.',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'none',
            required: true
        }, {
            id: 'message',
            label: 'Message',
            type: Gecko.Field.TYPE_RICHTEXT,
            format: 'fullpage',
            colClass: 'none',
            required: true
        }, {
            id: 'message',
            label: 'Tag Builder',
            type: Gecko.Field.TYPE_TAG_BUILDER,
            models: templateTagModels,
            description: 'Select from each dropdown and then hit the <code>+</code> button to insert the tag in to the message.',
            colClass: 'none',
            required: false
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            id: 'send',
            title: 'Send',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            action: function action() {
                if (!ctrl.message.subject || !ctrl.message.message) {
                    return ctrl.errorMessage = 'A subject line and message are required.';
                } else {
                    ctrl.errorMessage = '';
                }
                $modalInstance.close(ctrl.message);
            }
        }];
    }

    angular.module('GeckoEngage').config(EmailModals).controller('buildEmailModalCtrl', buildEmailModalCtrl);
})();