(function () {
    'use strict';

    function geckoCallUserConfigAlert() {
        return {
            restrict: 'E',
            template: '\n                <div ng-if="ctrl.alertText">\n                    <div class="alert alert-warning" gecko-bind-html="ctrl.alertText"></div>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {},
            controller: function controller($geckoCall) {
                var ctrl = this;
                var isSupportUser = function isSupportUser() {
                    return Gecko.account && Gecko.user && Gecko.user.related_group && Gecko.user.related_group.type === Gecko.Group.SUPER;
                },
                    supportUserHasSwitched = isSupportUser && window.TokenManager.hasPreviousAccountToken(),
                    isVoipDisabled = (Gecko.user || {}).call_method !== 'twilio',
                    userHasNoCallerId = !(Gecko.user || {}).call_sender_id,
                    accountHasNoCallerId = !(Gecko.account || {}).call_sender_id,
                    callCampaignId = ($geckoCall.call || {}).campaign_id,
                    isCallCampaignWithNoCallerId = !!callCampaignId && !(($geckoCall.data || {}).campaign || {}).call_sender_id;

                ctrl.alertText = function () {
                    if (supportUserHasSwitched) {
                        return 'VoIP calling is NOT supported for CS users after account switching.';
                    }

                    if (isCallCampaignWithNoCallerId) {
                        return 'A <strong>Verified Caller ID</strong> is required for calling. Select one in your <a href="/#/campaigns/call/edit/' + callCampaignId + '/settings">campaign settings</a>.';
                    }

                    if (isVoipDisabled) {
                        return 'Calling is currently disabled. You can enable it in your <a href="/#/settings/users/' + Gecko.user.id + '/settings">user settings</a>.';
                    }

                    if (userHasNoCallerId) {
                        return 'A <strong>Default Caller ID</strong> is required for calling. Select one in your <a href="/#/settings/users/' + Gecko.user.id + '/settings">user settings</a>.';
                    }

                    if (accountHasNoCallerId) {
                        return 'To make a call you must first set a <strong>Default Caller ID</strong>. Select one in your <a href="/#/settings/account">account settings</a>.';
                    }
                    return false;
                }();
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallUserConfigAlert', geckoCallUserConfigAlert);
})();