(function () {
    'use strict';

    function geckoResponse($state, $stateParams, formConditions, munge, $q) {
        return {
            save: function save(fields, values, responseValues) {

                var conditions = formConditions(fields, values);

                responseValues = angular.extend({}, {
                    contact_id: $stateParams.contact_id ? $stateParams.contact_id : null,
                    uuid: $stateParams.uuid,
                    fields: {},
                    event_id: $stateParams.event_id ? $stateParams.event_id : null
                }, responseValues);

                var newResponse = new Gecko.Response().create(responseValues);

                angular.forEach(conditions.getVisibleValues(), function (value, key) {
                    newResponse.fields['field' + key] = value;
                });

                return newResponse.save();
            },

            /**
             * Initialises values with appropriate values from a contact
             * @param  {Array}   fields       list of form fields
             * @param  {Object}  values       object where response values will be prefilled
             * @param  {integer} [contact_id] optional contact_id to prefill from. defaults to $stateParams.contact_id
             * @return {Promise}
             */
            initValues: function initValues(fields, values, contact_id) {

                contact_id = contact_id || $stateParams.contact_id;

                if (contact_id) {
                    return $q.when(new Gecko.Contact().include('current_values:all.field').get(contact_id)).then(function (contact) {

                        var current_values = angular.extend({}, munge(contact.toObject().current_values).toObjectByKey('field.contact_field_id').done(), munge(contact.toObject().current_values).toObjectByKey('field_id').done());

                        angular.forEach(fields, function (field) {
                            if (current_values[field.contact_field_id]) {
                                values[field.id] = current_values[field.contact_field_id].value;
                            }
                        });
                        return;
                    });
                }
                return $q.when(); // Probably shouldn't rely on this Q since it's just a side effect of geckojs.
            }

        };
    }

    angular.module('GeckoEngage').factory('geckoResponse', geckoResponse);
})();