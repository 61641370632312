(function () {
    'use strict';

    function $geckoQueue($geckoSocket) {

        var _this = {

            length: 0,

            queues: {},

            getLength: function getLength() {
                var _length = 0;
                for (var queue in _this.queues) {
                    _length += _this.queues[queue].length;
                }
                return _this.length = _length;
            },

            update: function update(data) {
                // Check if data is not array
                if (!angular.isArray(data)) data = [data];
                // Store queues
                data.forEach(function (queue) {
                    if (queue && queue.sender_id) {
                        _this.queues['queue' + queue.sender_id] = queue;
                    }
                });
                // Update queue length
                _this.getLength();
            },

            available: function available() {
                if (!Gecko.User.voipInboundEnabled()) return false;
                // Update when queue moves
                $geckoSocket.registerEvent('voip:queue', function (data) {
                    // Fire if user in array, or queue is already set for user
                    if (data.user_ids && data.user_ids.indexOf(Gecko.user.id) !== -1 || data.sender_id && _this.queues['queue' + data.sender_id]) {
                        _this.update(data);
                    }
                }, true);
                // Set current queue status
                //_this.update(Gecko.user && Gecko.user.queue_statuses ? Gecko.user.queue_statuses : null);

                // Pusher event is triggered when queue status is calculated
                new Gecko.User().include('queue_statuses').get(Gecko.user.id);
            },

            init: function init() {
                Gecko.on('available', _this.available);
            }

        };

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoQueue', $geckoQueue);
})();