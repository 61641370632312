(function () {
    'use strict';

    function EventOverviewCtrl($scope, $state, $stateParams, $filter, $geckoChannel, $geckoModal, $dialogEventChangeStatus, $geckoSocket, event, session, attendances, statConfig, stat, data, $timeout, history, integrations, geckoTableFilterService, handleMassAction) {
        var ctrl = this;
        ctrl.event = session ? session : event;
        ctrl.attendances = attendances.toInstances();
        ctrl.pagination = attendances.pagination;
        ctrl.sessions = data.sessions.toArray();
        ctrl.stat = stat;
        ctrl.history = history.data;
        if (integrations) ctrl.integrations = integrations.toArray();
        ctrl.pagination_act = {
            current_page: history.current_page,
            total_pages: history.last_page,
            total_items: history.total,
            per_page: history.per_page,
            last_page: history.last_page,
            param: "act_page",
            hideLimit: true
        };
        ctrl.attendeeSelects = [];

        ctrl.moreThanTenDays = ctrl.event.end_date && moment(ctrl.event.end_date, 'X').diff(moment(), 'days') < -10;

        // Stats card
        var buildStats = function buildStats(stat) {
            return [{
                title: 'Invited',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Invited').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.INVITED });
                }
            }, {
                title: 'Registered',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Registered').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.REGISTERED });
                }
            }, {
                title: 'Attended',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Attended').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.ATTENDED });
                }
            }, {
                title: 'Engaged',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Engaged').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.ENGAGED });
                }
            }, {
                title: 'Waitlisted',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Waitlisted').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.WAITLISTED });
                }
            }, {
                title: 'Cancelled',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Cancelled').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.CANCELLED });
                }
            }, {
                title: 'Removed',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Removed').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.REMOVED });
                }
            }, {
                title: 'Did Not Attend',
                aggregate: GeckoUI.gobk(stat.result, 'title', 'Did Not Attend').aggregate || 0,
                action: function action() {
                    return $state.go($state.$current.name, { status: Gecko.Attendance.DID_NOT_ATTEND });
                }
            }];
        };

        ctrl.stats = buildStats(stat);

        ctrl.isIncludingGuests = $stateParams.include_guests == undefined || $stateParams.include_guests == 'true';

        // Only show button(s) if event max guests is set
        ctrl.headerBtns = [{
            title: 'Include Guests',
            icon: 'fa-users',
            action: function action() {
                var newParams = Object.assign({}, $stateParams, { include_guests: true });
                return $state.go($state.$current.name, newParams);
            },
            hideWhen: function hideWhen() {
                return ctrl.isIncludingGuests;
            }
        }, {
            title: 'Exclude Guests',
            icon: 'fa-user-slash',
            action: function action() {
                var newParams = Object.assign({}, $stateParams, { include_guests: false });
                return $state.go($state.$current.name, newParams);
            },
            hideWhen: function hideWhen() {
                return !ctrl.isIncludingGuests;
            }
        }];

        var objectsToOptions = function objectsToOptions(labelKey) {
            return function (objects) {
                return objects.map(function (item) {
                    return { label: item[labelKey], value: item.id };
                });
            };
        };

        ctrl.filters = [{
            title: 'Status',
            type: 'checkbox',
            options: objectsToOptions('title')(Gecko.Attendance.status_titles),
            stateParam: 'status'
        }, {
            title: 'Date Added',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            },
            stateParam: 'from'
        }];

        // Sync Filter (top level event ONLY)
        if (integrations) {
            var integrationFilterOptions = angular.copy(ctrl.integrations).filter(function (integration) {
                return ctrl.event.integration_ids.includes(integration.id);
            });
            if (integrationFilterOptions && integrationFilterOptions.length) ctrl.filters.push(geckoTableFilterService.prepareSyncOptions(integrationFilterOptions));
        }

        if (ctrl.sessions.length) {
            ctrl.filters = ctrl.filters.concat([{
                title: $state.$current.name === 'session.overview' ? 'Time' : 'Session',
                type: 'checkbox',
                options: objectsToOptions('title')(ctrl.sessions),
                stateParam: 'session',
                hideWhen: function hideWhen() {
                    return !ctrl.sessions.length;
                }
            }]);
        }

        // Table structure
        if ($stateParams.session_id) {
            ctrl.cols = [{
                key: 'sync_status',
                renderHtml: true,
                status_title_none: true,
                expandable: false,
                icon_styles: Gecko.Attendance.sync_status_styles
            }, {
                data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
                title: 'Date added',
                key: 'created_at',
                sortKey: 'created_at',
                colClass: 'col-xs-3'
            }, {
                type: Gecko.Field.TYPE_NAME,
                title: 'Name',
                key: 'related_contact.full_name',
                sortKey: 'contact.name',
                colClass: 'col-xs-3'
            }, {
                title: 'Time',
                key: 'related_event.title',
                sortKey: 'event.start_datetime',
                colClass: 'col-xs-3'
            }, {
                title: 'Status',
                key: 'status',
                colClass: 'col-xs-3',
                status_styles: Gecko.Attendance.status_titles
            }];
        } else {
            ctrl.cols = [{
                key: 'sync_status',
                renderHtml: true,
                status_title_none: true,
                expandable: false,
                icon_styles: Gecko.Attendance.sync_status_styles
            }, {
                data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
                title: 'Date added',
                key: 'created_at',
                sortKey: 'created_at',
                colClass: 'col-xs-4'
            }, {
                type: Gecko.Field.TYPE_NAME,
                title: 'Name',
                key: 'related_contact.full_name',
                sortKey: 'contact.name',
                colClass: 'col-xs-4'
                // }, {
                //     title  : 'Session',
                //     key    : 'event_id',
                //     render : function(session_id) {
                //         return ctrl.sessions.find(function(session) { return session.id == session_id; }).title;
                //     },
                //     colClass : 'col-xs-4',
                //     hideWhen : function() {
                //         return ctrl.event.type !== Gecko.Event.TYPE_EVENT || !$stateParams.session;
                //     },
            }, {
                title: 'Status',
                key: 'status',
                colClass: 'col-xs-4',
                status_styles: Gecko.Attendance.status_titles
            }];
        }

        // Only show guests if greater than 0
        if (event.maximum_guests) {
            ctrl.cols.push({
                title: 'Guests',
                key: 'guest_count',
                colClass: 'text-center'
            });
        }

        // Table row action
        ctrl.rowAction = {
            state: 'contact.overview',
            params: { contact_id: 'contact_id' }
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View RSVP page',
            action: function action(attendance) {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_BOOKING_SELF_SERVICE)) {
                    return;
                }
                window.open(attendance.rsvp, '_blank');
            },
            hideWhen: function hideWhen(attendance) {
                if (!Gecko.able(Gecko.ABILITY_EVENTS_BOOKING_SELF_SERVICE)) {
                    return true;
                }
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || attendance.status == Gecko.Attendance.REMOVED;
            }
        }, {
            title: 'Edit booking',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || !Gecko.able(Gecko.ABILITY_EVENTS_UPDATE) || !Gecko.able(Gecko.ABILITY_EVENTS_ATTENDEE_QRS);
            },
            action: function action(attendance) {
                return $geckoModal.eventTicketModal(attendance, ctrl.event.parent_id, ctrl.event.maximum_guests);
            }
        }, {
            title: $state.$current.name === 'session.overview' ? 'Change session time' : 'Add to session',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || !Gecko.able(Gecko.ABILITY_EVENTS_UPDATE);
            },
            action: function action(attendance) {
                if ($state.$current.name === 'session.overview') {
                    $geckoModal.eventAddContactModal(attendance.contact_id, false, attendance.event_id, { disabledLevels: 2 }).result.then(function () {
                        return GeckoUI.messenger.success('Contact\'s session time changed');
                    });
                } else {
                    $geckoModal.eventAddContactModal(attendance.contact_id, false, ctrl.event.id, { disabledLevels: 1 }).result.then(function () {
                        return GeckoUI.messenger.success('Contact added to session');
                    });
                }
            }
        }, {
            title: 'Remove from ' + ($state.$current.name == 'session.overview' ? 'session' : 'event'),
            hideWhen: function hideWhen(attendance) {
                return attendance.status == Gecko.Attendance.REMOVED || !Gecko.able(Gecko.ABILITY_EVENTS_UPDATE);
            },
            action: function action(attendance) {
                GeckoUI.dialog.confirm('Are you sure you want to remove this contact from this ' + ($state.$current.name == 'session.overview' ? 'session' : 'event') + '?', function (value) {
                    if (value) {
                        // Copy attendance ref
                        var _attendance = angular.copy(attendance);
                        // Change attendance status
                        _attendance.status = Gecko.Attendance.REMOVED;
                        // Save attendance
                        _attendance.save().then(function () {
                            attendance.status = Gecko.Attendance.REMOVED;
                            GeckoUI.messenger.success('Contact removed from ' + ($state.$current.name == 'session.overview' ? 'session' : 'event'));
                            // Refresh the page
                            return $state.go($state.$current.name, $stateParams, { reload: true });
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }];

        ctrl.selectOptions = {
            noPersist: true
            /*UniqueKey: 'contact_id',
            selectQuery: attendancesQuery,
            initialTotalOptions: ctrl.pagination.total_items*/
        };

        ctrl.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !ctrl.attendeeSelects.length;
            }
        };

        function getContactIDsForAttendances(attendanceIds) {
            return attendanceIds.map(function (attendeeID) {
                return ctrl.attendances.find(function (attendee) {
                    return attendee.id == attendeeID;
                }).contact_id;
            });
        }

        ctrl.actionsBtn.items = [{
            title: 'Add to ' + ($state.$current.name === 'session.overview' ? 'session time' : 'session'),
            action: function action() {
                // TODO: We need contact ids for changing status here GEC-1675
                var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                $geckoModal.eventAddContactModal(contactIds, false, ctrl.event.id, { disabledLevels: 1 }).result.then(function () {
                    // Reset attendee array
                    ctrl.attendeeSelects.length = 0;
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || ctrl.event.parent_id || $state.$current.name === 'session.overview';
            }
        }, {
            title: 'Change status',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT) || !Gecko.able(Gecko.ABILITY_EVENTS_ATTENDEE_QRS) || ctrl.event.parent_id && !$stateParams.session;
            },
            action: function action() {
                // TODO: We need contact ids for changing status here GEC-1675
                var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                $dialogEventChangeStatus(null, contactIds, $stateParams.session || ctrl.event.id, function () {
                    // Reset attendee array
                    ctrl.attendeeSelects.length = 0;
                });
            }
        }, {
            title: 'Email attendees',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_EMAIL)) return;

                if (!ctrl.attendeeSelects.length) return false;
                var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                $geckoModal.messageSendModal(contactIds, { event_id: ctrl.event.id }).result.then(function () {
                    // Reset attendee array
                    ctrl.attendeeSelects.length = 0;
                });
            }
        }, {
            title: 'SMS attendees',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_SMS)) return;

                if (!ctrl.attendeeSelects.length) return false;
                var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                var params = { event_id: ctrl.event.id, type: Gecko.Message.TYPE_SMS };

                $geckoModal.messageSendModal(contactIds, params).result.then(function () {
                    // Reset attendee array
                    ctrl.attendeeSelects.length = 0;
                });
            }
        }, {
            title: 'Remove from ' + ($state.$current.name == 'session.overview' ? 'session' : 'event'),
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_UPDATE)) return;

                GeckoUI.dialog.confirm('Are you sure you want to remove these contacts from this ' + ($state.$current.name == 'session.overview' ? 'session' : 'event') + '?', function (value) {
                    if (value) {
                        var contactIds = getContactIDsForAttendances(ctrl.attendeeSelects);
                        new Gecko.Contact().massAction({ model: 'contact_ids', contact_ids: contactIds }, Gecko.Workflow.Actions.attendance(ctrl.event.id, Gecko.Attendance.REMOVED)).then(function (data) {
                            // Handle mass action
                            handleMassAction(data, $state.$current.name);
                            // Reset attendee array
                            ctrl.attendeeSelects.length = 0;
                        });
                    }
                });
            }
        }];
    }

    angular.module('GeckoEngage').controller('EventOverviewCtrl', EventOverviewCtrl);
})();