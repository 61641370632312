(function () {
    'use strict';

    function $landingPageBuilder() {
        return {
            reorder: function reorder(blocks) {
                // Refresh order values
                GeckoUI.refreshOrder(blocks);

                // Prepare block order
                var order = {};
                angular.forEach(blocks, function (block, $index) {
                    order[block.id] = $index;
                });
                // Save block order
                return new Gecko.Block().reorder(order);
            },
            buildBlockStyles: function buildBlockStyles(blocks) {
                var styles = '',
                    styleColors = {
                    primary_color: 'color',
                    text_color: 'color',
                    title_color: 'color',
                    subtitle_color: 'color',
                    background_color: 'background-color'
                };

                // Add css rule
                angular.forEach(blocks, function (block) {
                    if (block.styles) {
                        var block_id = '#gecko-landing-page-block-' + block.id;
                        for (var prop in block.styles) {
                            if (styleColors[prop] && block.styles[prop]) {
                                styles += block_id + ' .gecko-landing-page-color-' + prop.replace('_color', '') + '{' + styleColors[prop] + ':' + block.styles[prop] + ';}';
                            }
                        }
                    }
                });

                return styles;
            }
        };
    }

    angular.module('GeckoEngage').factory('$landingPageBuilder', $landingPageBuilder);
})();