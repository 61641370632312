(function () {

    'use strict';

    function ResponsesRoute($stateProvider) {
        $stateProvider.state('responses', {
            url: "/responses?{page}&{filter}&{search}&{label}&{operator}&{order}&{sort}&{form_type}&{from}&{to}&{captured_by}&{assigned_to}&{form}&{event}&{import_id}&{sync_status}",
            templateUrl: "/modules/responses/views/index.e0c95bf2.html",
            controller: 'ResponsesListCtrl',
            page_title: 'Responses',
            requires: [Gecko.ABILITY_RESPONSES_VIEW],
            resolve: {
                responseIncludes: function responseIncludes() {
                    return ['labels:all', 'assigned', 'user', 'contact', 'form', 'device'];
                },
                responseRfields: function responseRfields() {
                    return {
                        response: ['created_at', 'contact_id'],
                        contact: ['full_name'],
                        form: ['name', 'internal_name'],
                        assigned: ['full_name'],
                        user: ['full_name'],
                        label: ['name', 'color']
                    };
                },

                responseQuery: function responseQuery($stateParams, responseIncludes, responseRfields, geckoTableFilterService) {
                    // Return a constructor function that builds the required query(for use on mass actions)
                    return function () {
                        var responses = new Gecko.Response().include(responseIncludes).rfields(responseRfields).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page > 0 ? $stateParams.page : 1);

                        // Filter responses based on what the user wanted
                        // Label filter
                        if ($stateParams.label) {
                            responses.where('response_label_id', $stateParams.label, $stateParams.operator == 'all' ? true : false);
                        }

                        if ($stateParams.captured_by) {
                            responses.where('response_user_id', $stateParams.captured_by);
                        }
                        if ($stateParams.assigned_to) {
                            responses.where('response_assigned_id', $stateParams.assigned_to);
                        }
                        if ($stateParams.form) {
                            responses.where('response_form_id', $stateParams.form);
                        }
                        if ($stateParams.event) {
                            responses.where('response_event_id', $stateParams.event);
                        }
                        if ($stateParams.import_id) {
                            responses.where('response_import_id', $stateParams.import_id);
                        }

                        // Responses captured by the user
                        if ($stateParams.filter === 'captured') {
                            responses.where('response_user_id', Gecko.user.id);
                        }

                        // Responses assigned to the user
                        if ($stateParams.filter === 'assigned') {
                            responses.where('response_assigned_id', Gecko.user.id);
                        }

                        // Quicksearch
                        if ($stateParams.search) {
                            responses.search($stateParams.search);
                        }

                        // Date Filtering
                        responses.dates($stateParams.from, $stateParams.to);

                        // Ordering
                        if ($stateParams.order) responses.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');

                        // Integration sync status
                        geckoTableFilterService.prepareSyncQuery(responses, $stateParams.sync_status);

                        return responses;
                    }.bind(this);
                },
                data: function data(responseQuery) {
                    var _query = {};

                    // Responses
                    _query.responses = responseQuery();

                    return Gecko.multi(_query);
                },
                responses: function responses(data) {
                    return data.responses;
                },
                integrations: function integrations(geckoDataService) {
                    return geckoDataService.fetch(['integrations']).then(function (data) {
                        return data.integrations;
                    });
                }
            }
        });

        var responseResolve = {
            responseQuery: function responseQuery() {
                var includes = ['activity.user', 'event.parent', 'geoip', 'labels:all', 'notes:all.user', 'synchronisations.integration'];
                var rfields = {
                    event: ['title'],
                    label: ['name', 'color'],
                    synchronisation: ['third_party_id'],
                    integration: ['title_with_date']
                };
                return new Gecko.Response().include(includes).rfields(rfields);
            },
            response: function response($stateParams, responseQuery) {
                return responseQuery.get($stateParams.response_id);
            },
            history: function history($stateParams) {
                var pagination = {
                    per_page: Gecko.DEFAULT_PER_PAGE,
                    page: $stateParams.page || 1
                };
                return new Gecko.MixedObjects().call('/responses/' + $stateParams.response_id + '/history', 'GET', pagination, true);
            },
            selectedData: function selectedData(asyncOptions, response) {
                return asyncOptions.getSelectedValues({
                    events: { model: 'Event', id: response.event_id, fields: { event: ['title'] } }
                });
            },
            data: function data(geckoDataService) {
                return geckoDataService.fetch(['devices', 'forms', 'integrations', 'users']);
            },
            devices: function devices(data) {
                return data.devices;
            },
            forms: function forms(data) {
                return data.forms;
            },
            integrations: function integrations(data) {
                return data.integrations;
            },
            users: function users(data) {
                return data.users;
            }
        };

        $stateProvider.state('responseview', {
            url: "/responses/view/{response_id}?{page}",
            templateUrl: '/modules/responses/views/response.72bff5a8.html',
            controller: 'ResponseCtrl',
            controllerAs: 'ctrl',
            page_title: 'Response',
            requires: [Gecko.ABILITY_RESPONSES_VIEW],
            editRequires: [Gecko.ABILITY_RESPONSES_UPDATE],
            resolve: responseResolve
        }).state('responseadd', {
            url: "/response/add/{uuid}?{contact_id}",
            templateUrl: "/modules/responses/views/add_response.9d9a38f8.html",
            controller: 'FormAddResponseCtrl',
            controllerAs: 'ctrl',
            page_title: false,
            requires: [Gecko.ABILITY_FORMS_VIEW, Gecko.ABILITY_RESPONSES_CREATE],
            resolve: {
                form: function form($stateParams) {
                    return new Gecko.Form().rfields({ form: ['name', 'internal_name'] }).where('uuid', $stateParams.uuid).get();
                },
                contact: function contact($stateParams) {
                    if ($stateParams.contact_id) {
                        return new Gecko.Contact().rfields({ contact: ['full_name'] }).get($stateParams.contact_id);
                    }
                },
                formFields: function formFields($stateParams, form) {
                    if ($stateParams.contact_id) {
                        return new Gecko.Field().where('form_id', form.id).rfields({ 'field': ['label', 'contact_field_id', 'tag', 'type'] }).where('type', ['name', 'email', 'tel', 'type']).get();
                    }
                },
                contactValues: function contactValues($stateParams) {
                    if ($stateParams.contact_id) {
                        var obj = {
                            contact: ['id'],
                            value: ['safe', 'value'],
                            field: ['contact_field_id'],
                            data: ['value']
                        };
                        return new Gecko.Contact().include(['current_values:1000', 'current_values.field']).rfields(obj).get($stateParams.contact_id).then(function (data) {
                            return data.related_current_values;
                        }).then(function (values) {
                            return values.map(function (v) {
                                return {
                                    contact_field_id: v.related_field.contact_field_id || v.related_field.id,
                                    safe: v.safe,
                                    value: v.value
                                };
                            });
                        });
                    }
                }
            }
        }).state('contact.response', {
            url: '/response/{response_id}?{page}',
            templateUrl: '/modules/responses/views/response.72bff5a8.html',
            controller: 'ResponseCtrl',
            controllerAs: 'ctrl',
            page_title: 'Response',
            requires: [Gecko.ABILITY_RESPONSES_VIEW],
            editRequires: [Gecko.ABILITY_RESPONSES_UPDATE],
            resolve: responseResolve
        });
    }

    angular.module('GeckoEngage.routes').config(ResponsesRoute);
})();