(function () {
    'use strict';

    var currencies = [{
        countries: "Australia, Christmas Island, Cocos (Keeling) Islands, Kiribati, Nauru, Tuvalu",
        name: "Australian Dollar",
        code: "AUD",
        main: true
    }, {
        countries: "Canada",
        name: "Canadian Dollar",
        code: "CAD",
        main: true
    }, {
        countries: "United Kingdom",
        name: "Pound Sterling",
        code: "GBP",
        main: true
    }, {
        countries: "American Samoa; Cambodia; Ecuador; Guam; Haiti; Marshall Islands; Micronesia, Federal States of; Northern Mariana Islands; Panama; Palau; Puerto Rico; Timor Leste; Turks and Caicos Islands; United States; Virgin Islands",
        name: "US Dollar",
        code: "USD",
        main: true
    }, {
        countries: "United Arab Emirates",
        name: "UAE Dirham",
        code: "AED"
    }, {
        countries: "Afghanistan",
        name: "Afghani",
        code: "AFN"
    }, {
        countries: "Albania",
        name: "Lek",
        code: "ALL"
    }, {
        countries: "Armenia",
        name: "Armenian Dram",
        code: "AMD"
    }, {
        countries: "Netherlands Antilles Curaçao, Sint Maarten",
        name: "Netherlands Antillan Guilder",
        code: "ANG"
    }, {
        countries: "Angola",
        name: "Kwanza",
        code: "AOA"
    }, {
        countries: "Argentina",
        name: "Argentine Peso",
        code: "ARS"
    }, {
        countries: "Aruba",
        name: "Aruban Guilder",
        code: "AWG"
    }, {
        countries: "Azerbaijan",
        name: "Azerbaijan Manat",
        code: "AZN"
    }, {
        countries: "Bosnia and Herzegovina",
        name: "Convertible Mark",
        code: "BAM"
    }, {
        countries: "Barbados",
        name: "Barbados Dollar",
        code: "BBD"
    }, {
        countries: "Bangladesh",
        name: "Taka",
        code: "BDT"
    }, {
        countries: "Bulgaria",
        name: "Bulgarian Lev",
        code: "BGN"
    }, {
        countries: "Bahrain",
        name: "Bahraini Dinar",
        code: "BHD"
    }, {
        countries: "Burundi",
        name: "Burundi Franc",
        code: "BIF"
    }, {
        countries: "Bermuda",
        name: "Bermudian Dollar",
        code: "BMD"
    }, {
        countries: "Brunei Darussalam",
        name: "Brunei Dollar",
        code: "BND"
    }, {
        countries: "Bolivia",
        name: "Boliviano",
        code: "BOB"
    }, {
        countries: "Brazil",
        name: "Brazilian Real",
        code: "BRL"
    }, {
        countries: "Bahamas",
        name: "Bahamian Dollar",
        code: "BSD"
    }, {
        countries: "",
        name: "Bitcoin",
        code: "BTC"
    }, {
        countries: "Bhutan",
        name: "Ngultrum",
        code: "BTN"
    }, {
        countries: "Botswana",
        name: "Pula",
        code: "BWP"
    }, {
        countries: "Belarus",
        name: "Belarusian Ruble",
        code: "BYR"
    }, {
        countries: "Belize",
        name: "Belize Dollar",
        code: "BZD"
    }, {
        countries: "Congo, Dem. Republic",
        name: "Franc Congolais",
        code: "CDF"
    }, {
        countries: "Liechtenstein, Switzerland",
        name: "Swiss Franc",
        code: "CHF"
    }, {
        countries: "Chile",
        name: "Chilean Peso",
        code: "CLP"
    }, {
        countries: "China",
        name: "Renminbi (Yuan)",
        code: "CNY"
    }, {
        countries: "Colombia",
        name: "Colombian Peso",
        code: "COP"
    }, {
        countries: "Costa Rica",
        name: "Costa Rican Colón",
        code: "CRC"
    }, {
        countries: "Cuba",
        name: "Cuban Convertable Peso",
        code: "CUC"
    }, {
        countries: "Cuba",
        name: "Cuban Peso",
        code: "CUP"
    }, {
        countries: "Cape Verde",
        name: "Cape Verde Escudo",
        code: "CVE"
    }, {
        countries: "Czech Republic",
        name: "Czech Koruna",
        code: "CZK"
    }, {
        countries: "Djibouti",
        name: "Djibouti Franc",
        code: "DJF"
    }, {
        countries: "Denmark, Greenland",
        name: "Danish Krone",
        code: "DKK"
    }, {
        countries: "Faroe Islands",
        name: "Faroese Króna",
        code: "DKK"
    }, {
        countries: "Dominican Republic",
        name: "Dominican Peso",
        code: "DOP"
    }, {
        countries: "Algeria",
        name: "Algerian Dinar",
        code: "DZD"
    }, {
        countries: "Estonia",
        name: "Estonian Kroon",
        code: "EEK"
    }, {
        countries: "Egypt, State of Palestine",
        name: "Egyptian Pound",
        code: "EGP"
    }, {
        countries: "Eritrea",
        name: "Nakfa",
        code: "ERN"
    }, {
        countries: "Ethiopia",
        name: "Ethiopian Birr",
        code: "ETB"
    }, {
        countries: "Andorra, Austria, Belgium, Cyprus, Estonia, Finland, France, French Guiana, French Southern Territories, Germany, Greece, Guadeloupe, Ireland, Italy, Latvia, Luxembourg, Malta, Martinique, Mayotte, Monaco, Montenegro, Netherlands, Portugal, Reunion Island, San Marino, Slovakia (Slovak Republic), Slovenia, Spain, Vatican City State (Holy See)",
        name: "Euro",
        code: "EUR"
    }, {
        countries: "Fiji",
        name: "Fiji Dollar",
        code: "FJD"
    }, {
        countries: "Falkland Islands (Malvinas)",
        name: "Falkland Islands Pound",
        code: "FKP"
    }, {
        countries: "Georgia",
        name: "Lari",
        code: "GEL"
    }, {
        countries: "Guernsey",
        name: "Guernsey Pound",
        code: "GGP"
    }, {
        countries: "Ghana",
        name: "Cedi",
        code: "GHS"
    }, {
        countries: "Gibraltar",
        name: "Gibraltar Pound",
        code: "GIP"
    }, {
        countries: "Gambia",
        name: "Dalasi",
        code: "GMD"
    }, {
        countries: "Guinea",
        name: "Guinea Franc",
        code: "GNF"
    }, {
        countries: "Guatemala",
        name: "Quetzal",
        code: "GTQ"
    }, {
        countries: "Guyana",
        name: "Guyana Dollar",
        code: "GYD"
    }, {
        countries: "Hong Kong",
        name: "Hong Kong Dollar",
        code: "HKD"
    }, {
        countries: "Honduras",
        name: "Lempira",
        code: "HNL"
    }, {
        countries: "Croatia/Hrvatska",
        name: "Croatian Kuna",
        code: "HRK"
    }, {
        countries: "Haiti",
        name: "Haitian Gourde",
        code: "HTG"
    }, {
        countries: "Hungary",
        name: "Forint",
        code: "HUF"
    }, {
        countries: "Indonesia",
        name: "Rupiah",
        code: "IDR"
    }, {
        countries: "Israel, State of Palestine",
        name: "Israeli New Shekel (Sheqel)",
        code: "ILS"
    }, {
        countries: "Isle of Man",
        name: "Isle of Man Pound",
        code: "IMP"
    }, {
        countries: "India, Bhutan",
        name: "Indian Rupee",
        code: "INR"
    }, {
        countries: "Iraq",
        name: "Iraqi Dinar",
        code: "IQD"
    }, {
        countries: "Iran",
        name: "Iranian Rial",
        code: "IRR"
    }, {
        countries: "Iceland",
        name: "Iceland Krona",
        code: "ISK"
    }, {
        countries: "Jersey",
        name: "Jersey Pound",
        code: "JEP"
    }, {
        countries: "Jamaica",
        name: "Jamaican Dollar",
        code: "JMD"
    }, {
        countries: "Jordan, State of Palestine",
        name: "Jordanian Dinar",
        code: "JOD"
    }, {
        countries: "Japan",
        name: "Yen",
        code: "JPY"
    }, {
        countries: "Kenya",
        name: "Kenyan Shilling",
        code: "KES"
    }, {
        countries: "Kyrgyzstan",
        name: "Som",
        code: "KGS"
    }, {
        countries: "Cambodia",
        name: "Riel",
        code: "KHR"
    }, {
        countries: "Comoros",
        name: "Comoro Franc",
        code: "KMF"
    }, {
        countries: "Korea (North Korea)",
        name: "North Korean Won",
        code: "KPW"
    }, {
        countries: "Korea (South Korea)",
        name: "Won",
        code: "KRW"
    }, {
        countries: "Kuwait",
        name: "Kuwaiti Dinar",
        code: "KWD"
    }, {
        countries: "Cayman Islands",
        name: "Cayman Islands Dollar",
        code: "KYD"
    }, {
        countries: "Kazakhstan",
        name: "Tenge",
        code: "KZT"
    }, {
        countries: "Lao",
        name: "Kip",
        code: "LAK"
    }, {
        countries: "Lebanon",
        name: "Lebanese Pound",
        code: "LBP"
    }, {
        countries: "Sri Lanka",
        name: "Sri Lanka Rupee",
        code: "LKR"
    }, {
        countries: "Liberia",
        name: "Liberian Dollar",
        code: "LRD"
    }, {
        countries: "Lesotho",
        name: "Loti",
        code: "LSL"
    }, {
        countries: "Lithuania",
        name: "Lithuanian Litas",
        code: "LTL"
    }, {
        countries: "Libya",
        name: "Lybian Dinar",
        code: "LYD"
    }, {
        countries: "Morocco, Western Sahara",
        name: "Moroccan Dirham",
        code: "MAD"
    }, {
        countries: "Moldova, Republic of",
        name: "Moldovan Leu",
        code: "MDL"
    }, {
        countries: "Madagascar",
        name: "Malagasy Franc",
        code: "MGF"
    }, {
        countries: "Macedonia, Rep. of",
        name: "Macedonian Denar",
        code: "MKD"
    }, {
        countries: "Myanmar",
        name: "Kyat",
        code: "MMK"
    }, {
        countries: "Mongolia",
        name: "Tugrik (Tugrug)",
        code: "MNT"
    }, {
        countries: "Macau",
        name: "Macanese Pataca",
        code: "MOP"
    }, {
        countries: "Mauritania",
        name: "Ouguiya",
        code: "MRO"
    }, {
        countries: "Mauritius",
        name: "Mauritius Rupee",
        code: "MUR"
    }, {
        countries: "Maldives",
        name: "Rufiyaa",
        code: "MVR"
    }, {
        countries: "Malawi",
        name: "Kwacha",
        code: "MWK"
    }, {
        countries: "Mexico",
        name: "Mexican Peso",
        code: "MXN"
    }, {
        countries: "Malaysia",
        name: "Malaysian Ringgit",
        code: "MYR"
    }, {
        countries: "Mozambique",
        name: "Metical",
        code: "MZM"
    }, {
        countries: "Nambia",
        name: "Namibia Dollar",
        code: "NAD"
    }, {
        countries: "Nigeria",
        name: "Naira",
        code: "NGN"
    }, {
        countries: "Nicaragua",
        name: "Cordoba Oro",
        code: "NIO"
    }, {
        countries: "Norway",
        name: "Norwegian Krone",
        code: "NOK"
    }, {
        countries: "Nepal",
        name: "Nepalese Rupee",
        code: "NPR"
    }, {
        countries: "Cook Islands, New Zealand, Niue, Pitcairn Island, Tokelau",
        name: "New Zealand Dollar",
        code: "NZD"
    }, {
        countries: "Oman",
        name: "Rial Omani",
        code: "OMR"
    }, {
        countries: "Panama",
        name: "Balboa",
        code: "PAB"
    }, {
        countries: "Peru",
        name: "Nuevo Sol",
        code: "PEN"
    }, {
        countries: "Papua New Guinea",
        name: "Kina",
        code: "PGK"
    }, {
        countries: "Philippines",
        name: "Philippine Peso",
        code: "PHP"
    }, {
        countries: "Pakistan",
        name: "Pakistan Rupee",
        code: "PKR"
    }, {
        countries: "Poland",
        name: "Zloty",
        code: "PLN"
    }, {
        countries: "Paraguay",
        name: "Guarani",
        code: "PYG"
    }, {
        countries: "Qatar",
        name: "Qatari Rial",
        code: "QAR"
    }, {
        countries: "Romania",
        name: "Leu",
        code: "RON"
    }, {
        countries: "Serbia",
        name: "Serbian Dinar",
        code: "RSD"
    }, {
        countries: "Russian Federation",
        name: "Russian Ruble",
        code: "RUB"
    }, {
        countries: "Rwanda",
        name: "Rwanda Franc",
        code: "RWF"
    }, {
        countries: "Saudi Arabia",
        name: "Saudi Riyal",
        code: "SAR"
    }, {
        countries: "Solomon Islands",
        name: "Solomon Islands Dollar",
        code: "SBD"
    }, {
        countries: "Seychelles",
        name: "Seychelles Rupee",
        code: "SCR"
    }, {
        countries: "Sudan",
        name: "Sudanese Pound",
        code: "SDG"
    }, {
        countries: "Sweden",
        name: "Swedish Krona",
        code: "SEK"
    }, {
        countries: "Singapore",
        name: "Singapore Dollar",
        code: "SGD"
    }, {
        countries: "Saint Helena",
        name: "Saint Helena Pound",
        code: "SHP"
    }, {
        countries: "Sierra Leone",
        name: "Leone",
        code: "SLL"
    }, {
        countries: "Somalia",
        name: "Somali Shilling",
        code: "SOS"
    }, {
        countries: "Suriname",
        name: "Suriname Guilder",
        code: "SRD"
    }, {
        countries: "Sao Tome and Principe",
        name: "Dobra",
        code: "STD"
    }, {
        countries: "El Salvador",
        name: "Salvadoran Colón",
        code: "SVC"
    }, {
        countries: "Syrian Arab Republic",
        name: "Syrian Pound",
        code: "SYP"
    }, {
        countries: "Swaziland",
        name: "Lilangeni",
        code: "SZL"
    }, {
        countries: "Thailand",
        name: "Thai Baht",
        code: "THB"
    }, {
        countries: "Tajikistan",
        name: "Somoni",
        code: "TJS"
    }, {
        countries: "Turkmenistan",
        name: "Manat",
        code: "TMM"
    }, {
        countries: "Tunisia",
        name: "Tunisian Dinar",
        code: "TND"
    }, {
        countries: "Tonga",
        name: "Paʻanga",
        code: "TOP"
    }, {
        countries: "Cyprus, Turkey",
        name: "Turkish Lira (Yeni Türk Liras)",
        code: "TRY"
    }, {
        countries: "Trinidad and Tobago",
        name: "Trinidad and Tobago Dollar",
        code: "TTD"
    }, {
        countries: "Taiwan, Republic of China",
        name: "New Taiwan Dollar",
        code: "TWD"
    }, {
        countries: "Tanzania",
        name: "Tanzanian Shilling",
        code: "TZS"
    }, {
        countries: "Ukraine",
        name: "Hryvnia",
        code: "UAH"
    }, {
        countries: "Uganda",
        name: "Uganda Shilling",
        code: "UGX"
    }, {
        countries: "Uruguay",
        name: "Peso Uruguayo",
        code: "UYU"
    }, {
        countries: "Uzbekistan",
        name: "Uzbekistan Sum",
        code: "UZS"
    }, {
        countries: "Venezuela",
        name: "Venezuelan Bolívar",
        code: "VEF"
    }, {
        countries: "Vietnam",
        name: "Vietnamese Dong",
        code: "VND"
    }, {
        countries: "Vanuatu",
        name: "Vatu",
        code: "VUV"
    }, {
        countries: "Samoa",
        name: "Tala",
        code: "WST"
    }, {
        countries: "Cameroon; Central African Republic; Chad; Congo, Republic of; Equatorial Guinea; Gabon",
        name: "CFA Franc BEAC",
        code: "XAF"
    }, {
        countries: "Anguilla, Antigua and Barbuda, Dominica, Grenada, Montserrat, Saint Kitts and Nevis, Saint Lucia, Saint Vincent and the Grenadines",
        name: "East Caribbean Dollar",
        code: "XCD"
    }, {
        countries: "Guinea-Bissau, Benin, Burkina Faso, Cote D'Ivoire, Mali, Niger, Senegal, Togo",
        name: "CFA Franc BCEAO",
        code: "XOF"
    }, {
        countries: "French Polynesia, New Caledonia, Wallis and Futuna Islands",
        name: "CFP Franc",
        code: "XPF"
    }, {
        countries: "Yemen",
        name: "Yemeni Rial",
        code: "YER"
    }, {
        countries: "South Africa, Namibia",
        name: "Rand",
        code: "ZAR"
    }, {
        countries: "Zambia",
        name: "Kwacha",
        code: "ZMW"
    }, {
        countries: "Zimbabwe",
        name: "Zimbabwe Dollar",
        code: "ZWL"
    }];

    angular.module('GeckoEngage').constant('currencies', currencies);
})();