(function () {
    'use strict';

    function geckoCardSocialDirective() {

        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card-social/gecko-card-social.html',
            scope: {
                title: '@title',
                icon: '@icon',
                social: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($attrs) {
                var ctrl = this;

                if (!ctrl.title) {
                    ctrl.title = 'Social';
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCardSocial', geckoCardSocialDirective);
})();