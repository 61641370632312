(function () {
    'use strict';

    function CallsRoute($stateProvider) {
        $stateProvider.state('calls', {
            url: "/calls",
            templateUrl: "/modules/calls/views/tabs.e772083f.html",
            controller: 'CallsTabsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Calls',
            requires: [Gecko.ABILITY_CALLS_VIEW]

        }).state('calls.overview', {
            url: "/overview",
            templateUrl: "/modules/calls/views/overview.dd54ff1c.html",
            controller: 'CallsOverviewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Calls',
            requires: [Gecko.ABILITY_CALLS_VIEW],
            resolve: {
                callIncludes: function callIncludes() {
                    return ['contact', 'campaign', 'user', 'number'];
                },
                calls: function calls(Gecko, callIncludes) {
                    return new Gecko.Call().include(callIncludes).where('call_status', [Gecko.Call.STARTED, Gecko.Call.ENDED, Gecko.Call.COMPLETE]).perPage(10).get();
                },
                serviceStatConfig: function serviceStatConfig() {
                    return { compound: 'call-service-quality', title: 'Service Level', 'date': 'today' };
                },
                serviceStat: function serviceStat(Gecko, serviceStatConfig) {
                    if (!Gecko.User.hasInbound()) return false;
                    return new Gecko.Stat().generate(serviceStatConfig);
                },
                numbers: function numbers(Gecko) {
                    return new Gecko.Sender().include('queue_length', 'online_incoming_users', 'available_incoming_users').where('type', Gecko.Sender.TEL_PURCHASED).where('inbound_on', 1).get();
                }
            }

        }).state('calls.calls', {
            url: "/calls?{page}&{user}&{campaign}&{outcome}&{type}&{status}&{from}&{to}&{autoUpdate}&{overview}",
            templateUrl: "/modules/calls/views/index.3be09d98.html",
            controller: 'CallsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Calls',
            requires: [Gecko.ABILITY_CALLS_VIEW],
            resolve: {
                defaultStatuses: function defaultStatuses(Gecko) {
                    return [Gecko.Call.PREPARING, Gecko.Call.CANCELLED, Gecko.Call.STARTED, Gecko.Call.ENDED, Gecko.Call.COMPLETE, Gecko.Call.ABORTED, Gecko.Call.QUEUED, Gecko.Call.ABANDONED];
                },
                callIncludes: function callIncludes() {
                    return ['contact', 'user', 'sender', 'outcome', 'campaign', 'number', 'all_campaigns'];
                },
                calls: function calls($stateParams, Gecko, defaultStatuses, callIncludes) {
                    var calls = new Gecko.Call().include(callIncludes).perPage(GeckoUI.getPageLimit()).page($stateParams.page ? $stateParams.page : 1);

                    // Status filter
                    if ($stateParams.status) {
                        calls.where('call_status', $stateParams.status);
                    } else {
                        calls.where('call_status', defaultStatuses);
                    }
                    // User filter

                    if ($stateParams.user) {
                        calls.where('call_user_id', $stateParams.user);
                    }
                    // Campign filter
                    if ($stateParams.campaign) {
                        calls.where('call_campaign_id', $stateParams.campaign);
                    }
                    // Outcome filter
                    if ($stateParams.outcome) {
                        calls.where('call_outcome_id', $stateParams.outcome);
                    }

                    // Only show outbound if inbound call package is disabled
                    if (!Gecko.User.hasInbound()) {
                        calls.where('type', Gecko.Call.TYPE_OUTBOUND);
                    } else {
                        if ($stateParams.type) {
                            calls.where('type', $stateParams.type);
                        }
                    }
                    // Date filter
                    calls.dates($stateParams.from, $stateParams.to);

                    return calls.rfields({
                        call: ['status', 'started_at', 'type', 'call_time', 'recording_url_mp3', 'queued_at', 'outcome_id', 'user_id', 'contact_id', 'number_id'],
                        user: ['full_name', 'color'],
                        contact: ['full_name', 'field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6'],
                        campaign: ['title'],
                        outcome: ['name', 'type']
                    }).get();
                },
                user: function user($stateParams, Gecko) {
                    if ($stateParams.overview && typeof $stateParams.user == 'string') {
                        return new Gecko.User().get($stateParams.user);
                    }
                    return false;
                },
                fieldList: function fieldList(geckoDataService) {
                    return geckoDataService.fetch(['field_list_contacts']).then(function (data) {
                        return data.field_list_contacts;
                    });
                }
            }
        }).state('calls.queue', {
            url: '/queue',
            templateUrl: "/modules/calls/views/queue.60c80f65.html",
            controller: 'CallsQueueCtrl',
            controllerAs: 'ctrl',
            page_title: 'Queue',
            requires: [Gecko.ABILITY_CALLS_VIEW],
            resolve: {
                _queuedCalls: function _queuedCalls(Gecko) {
                    return {
                        get: function get() {
                            var calls = new Gecko.Call().include('number').where('type', Gecko.Call.TYPE_INBOUND).where('status', Gecko.Call.QUEUED);
                            return calls.orderBy('created_by', 'ASC').get();
                        }
                    };
                },
                _activeCalls: function _activeCalls(Gecko) {
                    return {
                        get: function get() {
                            var calls = new Gecko.Call().include('number').where('type', Gecko.Call.TYPE_INBOUND).where('status', Gecko.Call.STARTED);
                            return calls.orderBy('created_by', 'ASC').get();
                        }
                    };
                },
                queuedCalls: function queuedCalls(_queuedCalls) {
                    return _queuedCalls.get();
                },
                activeCalls: function activeCalls(_activeCalls) {
                    return _activeCalls.get();
                }
            }
        }).state('calls.numbers', {
            url: "/numbers",
            templateUrl: "/modules/calls/views/numbers.1ca84827.html",
            controller: 'CallsNumbersCtrl',
            controllerAs: 'ctrl',
            page_title: 'Numbers',
            requires: [Gecko.ABILITY_CALLS_VIEW],
            resolve: {
                numbers: function numbers(Gecko) {
                    return new Gecko.Sender().include('queue_length', 'online_incoming_users', 'available_incoming_users', 'campaigns').where('type', Gecko.Sender.TEL_PURCHASED).where('inbound_on', 1).get();
                }
            }
        }).state('calls.settings', {
            url: "/settings",
            templateUrl: "/modules/calls/views/settings.d41d8cd9.html",
            controller: 'CallsSettingsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Monitor',
            requires: [Gecko.ABILITY_CALLS_VIEW]

        }).state('calls.report', {
            url: "/report?{date_from}{date_to}{campaign}{agent}{phoneline}{increment}{type}{outcome}",
            templateUrl: "/modules/calls/views/report.7b944d43.html",
            controller: 'CallsReportCtrl',
            controllerAs: 'ctrl',
            page_title: 'Report',
            requires: [Gecko.ABILITY_CALLS_VIEW],
            resolve: {
                config: function config($stateParams, Gecko) {
                    var config = { module: Gecko.Dashboard.MODULE_CALL };

                    if ($stateParams.date_from && $stateParams.date_to) {
                        config.override_date = $stateParams.date_from + ':' + $stateParams.date_to;
                    }
                    if ($stateParams.campaign) {
                        config.campaign_id = $stateParams.campaign;
                    }
                    if ($stateParams.agent) {
                        config.user_id = $stateParams.agent;
                    }
                    if ($stateParams.phoneline) {
                        config.sender_id = $stateParams.phoneline;
                    }
                    if ($stateParams.outcome) {
                        config.outcome_id = $stateParams.outcome;
                    }
                    if ($stateParams.increment) {
                        config.group_by = $stateParams.increment;
                    } else {
                        // Default to months for the table filter
                        config.group_by = 'month';
                        $stateParams.increment = 'month';
                    }
                    if ($stateParams.type) {
                        config.type = $stateParams.type;
                    } else {
                        config.type = [Gecko.Call.TYPE_INBOUND, Gecko.Call.TYPE_OUTBOUND];
                    }
                    // Disable cache
                    config.no_cache = 1;

                    return config;
                },
                performance: function performance(config, Gecko) {
                    config.date = 'quarter';
                    return new Gecko.Dashboard().display('performance_metrics', config);
                },
                outcomes: function outcomes(config, Gecko) {
                    config.date = undefined;
                    return new Gecko.Dashboard().display('outcomes', config);
                },
                hour_of_call: function hour_of_call(config, Gecko) {
                    config.date = 'quarter';
                    return new Gecko.Dashboard().display('hour_of_call_utc', config);
                },
                campaigns: function campaigns(Gecko) {
                    return new Gecko.Campaign().where('module', Gecko.Campaign.MODULE_CALL).perPage('all').get();
                },
                agents: function agents(Gecko) {
                    return new Gecko.User().rfields({ user: ['full_name'] }).perPage('all').get();
                },
                senders: function senders(Gecko) {
                    return new Gecko.Sender().where('type', [Gecko.Sender.TEL_VERIFIED, Gecko.Sender.TEL_PURCHASED]).perPage('all').get();
                }
            }
        }).state('calls.agents', {
            url: "/agents?{presence}{status}{order}{sort}{campaign}",
            templateUrl: "/modules/calls/views/agents.fefcacbf.html",
            controller: 'CallsAgentsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Agents',
            requires: [Gecko.ABILITY_CALLS_VIEW],
            resolve: {
                agents: function agents($q, $stateParams, Gecko) {
                    var agents = new Gecko.User().where('call_method', 'twilio').rfields({ user: ['presence', 'full_name', 'email', 'last_active', 'status', 'inbound_senders'] }).include('inbound_senders').rfields({ sender: ['name'] }).perPage('all');
                    if ($stateParams.presence) {
                        agents.where('presence', $stateParams.presence);
                    }
                    if ($stateParams.status) {
                        agents.where('status', $stateParams.status);
                    }
                    if ($stateParams.order) {
                        agents.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    } else {
                        agents.orderBy('presence');
                    }
                    if ($stateParams.campaign) {
                        // If there are campaign filters we need to grab the campaigns and filter out agents that aren't in them
                        return $q(function (resolve, reject) {
                            var promises = {
                                agents: agents.get(),
                                campaigns: new Gecko.Campaign().where('id', $stateParams.campaign).rfields({ campaign: ['users'] }).include('users').rfields({ user: ['id'] }) // We only need the user id's
                                .get()
                            };
                            $q.all(promises).then(function (data) {
                                var campaignAgentIds = data.campaigns.toArray().reduce(function (agentIds, campaign) {
                                    return campaign.users.map(function (user) {
                                        return user.id;
                                    }).concat(agentIds);
                                }, []);
                                var agents = data.agents.toArray().filter(function (agent) {
                                    return campaignAgentIds.indexOf(agent.id) !== -1;
                                });
                                resolve(agents);
                            }).catch(reject);
                        });
                    } else {
                        return agents.get();
                    }
                },
                campaigns: function campaigns(Gecko) {
                    return new Gecko.Campaign().rfields({ campaign: ['title'] }).where('module', Gecko.Campaign.MODULE_CALL).perPage('all').get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(CallsRoute);
})();