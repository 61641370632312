(function () {
    'use strict';

    function getSavedFilterId($stateParams, saved) {
        return $stateParams && $stateParams.filter_id ? $stateParams.filter_id : saved ? saved.filter_id : null;
    }

    function ContactsRoute($stateProvider) {
        $stateProvider.state('contacts', {
            url: "/contacts?{page}&{order}&{sort}&{search}&{label}&{number}&{filter_id}&{operator}",
            templateUrl: "/modules/contacts/views/index.a5582209.html",
            controller: 'ContactsListCtrl',
            page_title: 'Contacts',
            requires: [Gecko.ABILITY_CONTACTS_VIEW],
            resolve: {
                saved: function saved(geckoStore) {
                    return geckoStore.get('gecko_contact_search');
                },
                contactsRfields: function contactsRfields() {
                    return {
                        contact: ['field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6'],
                        label: ['name', 'color']
                    };
                },
                contactsQuery: function contactsQuery($stateParams, $state, geckoStore, contactsRfields, saved, getContactsService, labels, fieldList, contactFields) {
                    return function () {
                        // Get save search
                        var order = $stateParams.order !== undefined ? $stateParams.order : null;
                        var sort = $stateParams.sort !== undefined ? $stateParams.sort : null;

                        // Establish rules for search type interactions:
                        // Filters take precedence over conditions
                        // Freetext search cannot be used with filters or conditions
                        // Labels cannot be used with conditions

                        var filterId = getSavedFilterId($stateParams, saved);
                        var conditions = (saved || {}).conditions;
                        var hasConditions = conditions && conditions.length;
                        var searchLabels = hasConditions ? null : $stateParams.label && (Array.isArray($stateParams.label) ? $stateParams.label : [$stateParams.label]);
                        var labelOperator = hasConditions ? null : $stateParams.operator || 'any';
                        var search = hasConditions || filterId ? null : $stateParams.search;

                        var page = $stateParams.page ? $stateParams.page : 1;
                        var perPage = GeckoUI.getPageLimit(this.self.name);
                        return getContactsService.getContacts({
                            order: order,
                            sort: sort,
                            page: page,
                            perPage: perPage,
                            filterId: conditions ? undefined : filterId,
                            conditions: conditions,
                            search: search,
                            conditionOperator: (saved || {}).requirement,
                            includes: 'labels',
                            rFields: contactsRfields,
                            number: $stateParams.number,
                            searchLabels: searchLabels,
                            labelOperator: labelOperator,
                            contactFields: contactFields,
                            fieldList: fieldList,
                            labelList: labels
                        });
                    }.bind(this);
                },
                data: function data($stateParams, saved, contactsQuery, contactSearchService, geckoStore, geckoDataService, savedSearchService) {
                    var _query = {};

                    // Number
                    if ($stateParams.number) _query.number = new Gecko.Number().path($stateParams.number);

                    var usingSavedSearchService = savedSearchService.getProviderName() === 'searchService';

                    if (!usingSavedSearchService) {
                        // Filter
                        if ($stateParams.filter_id || saved && saved.filter_id) {
                            var filterRfields = {
                                filter: ['name', 'requirement'],
                                campaign: ['title']
                            };
                            _query.filter = new Gecko.Filter().include('conditions', 'dependants.campaigns', 'dependants.parent.campaigns', 'campaigns').rfields(filterRfields).path($stateParams.filter_id || saved.filter_id);
                        }
                    }

                    if (usingSavedSearchService) {
                        if (!$stateParams.filter_id && !(saved || {}).filter_id) {
                            return {
                                contacts: contactsQuery().then(function (results) {
                                    return results;
                                })
                            };
                        }

                        var fromStore = geckoDataService.fetchFromStoreSync('filters');
                        var filterId = getSavedFilterId($stateParams, saved);

                        var filter = void 0;

                        if (fromStore.filters && fromStore.filters.length) {
                            filter = fromStore.filters.find(function (filter) {
                                return filter.id === filterId;
                            });
                        }

                        return {
                            contacts: contactsQuery().then(function (results) {
                                return results;
                            }),
                            filter: filter
                        };
                    }

                    return Gecko.multi(_query).then(function (data) {
                        return {
                            filter: data.filter,
                            contacts: contactsQuery().then(function (results) {
                                return results;
                            })
                        };
                    }).catch(function (err) {
                        console.error(err);
                        geckoStore.set('gecko_contact_search', null);
                        throw { message: 'Sorry, this search timed out, try to be more specific or filter by specific fields.' };
                    });
                },
                contacts: function contacts(data) {
                    return data.contacts;
                },
                fieldList: function fieldList(labelsAndFields) {
                    return labelsAndFields.field_list_contacts;
                },
                labels: function labels(labelsAndFields) {
                    return labelsAndFields.labels;
                },
                contactFields: function contactFields(labelsAndFields) {
                    return labelsAndFields.contact_fields;
                },
                labelsAndFields: function labelsAndFields(geckoDataService) {
                    return geckoDataService.fetch(['field_list_contacts', 'contact_fields', 'labels']);
                },
                filter: function filter(data) {
                    return data.filter ? data.filter : false;
                },
                number: function number(data) {
                    return data.number ? data.number : false;
                },
                selectedData: function selectedData(filter) {
                    var selectedData = {};
                    if (filter) selectedData.filter = filter;
                    return selectedData;
                }
            }
        }).state('contactadd', {
            url: "/contacts/add",
            templateUrl: "/modules/contacts/views/add.aef3ea57.html",
            controller: 'ContactAddCtrl',
            controllerAs: 'ctrl',
            page_title: 'Add Contact',
            requires: [Gecko.ABILITY_CONTACTS_CREATE],
            resolve: {
                fields: function fields() {
                    return new Gecko.Field().include('option').where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).where('editable', 1).orderBy('order', 'ASC').perPage('all').get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(ContactsRoute);
})();