(function () {
    'use strict';

    function geckoPagination() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-pagination/gecko-pagination.html',
            scope: {
                pagination: '=data',
                extraParams: '&',
                onChange: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($state, $stateParams, geckoStore) {
                var ctrl = this;

                // Get and set limit config
                if (ctrl.pagination && !ctrl.pagination.hideLimit) {
                    var limit = geckoStore.get('gecko_pagination_limits_' + Gecko.account.routing_id) || {};
                    ctrl.paginationLimit = limit[$state.$current.name] ? limit[$state.$current.name] : Gecko.DEFAULT_PER_PAGE;
                }

                ctrl.limits = [15, 30, 50];
                ctrl.currentPage = ctrl.pagination && ctrl.pagination.current_page > 0 ? ctrl.pagination.current_page : 1;
                var skipPage = Number(ctrl.currentPage);

                ctrl.skipToPage = function (value) {
                    if (value === '') {
                        skipPage = undefined;
                        return skipPage;
                    }
                    value = Number(value);
                    if (arguments.length && !isNaN(value)) {
                        skipPage = value < 1 ? 1 : value > ctrl.pagination.total_pages ? ctrl.pagination.total_pages : value;
                    }
                    return skipPage;
                };

                ctrl.changePage = function (direction, event) {
                    if (event) {
                        if (event.keyCode == 13) {
                            ctrl.nextPage = direction;
                            if (ctrl.nextPage < 1 || ctrl.nextPage > ctrl.pagination.total_pages) return false;
                        } else return false; // Prevent fall through to page change code.
                    } else {
                        ctrl.nextPage = direction == '-' ? Number(ctrl.currentPage) - 1 : Number(ctrl.currentPage) + 1;
                        if (ctrl.currentPage == 1 && direction == '-') return false;
                        if (ctrl.currentPage == ctrl.pagination.total_pages && direction == '+') return false;
                    }

                    // If we have an onChange function call it with the page
                    if (ctrl.onChange && typeof ctrl.onChange === 'function') {
                        ctrl.onChange({ $page: ctrl.nextPage });
                        ctrl.currentPage = ctrl.nextPage; // Update our interal state as state reload is not guaranteed.
                        ctrl.pagination.current_page = ctrl.currentPage;
                        skipPage = ctrl.nextPage;
                    } else {
                        // Merge in extra params, allows us to override params when paging.
                        var page = {};
                        page[ctrl.pagination.param ? ctrl.pagination.param : 'page'] = ctrl.nextPage;
                        var queryParams = Gecko.mergeObjects(page, ctrl.extraParams());
                        $state.go($state.current.name, queryParams, { location: "replace" });
                    }
                };
                // Change limit
                ctrl.changeLimit = function () {
                    if (limit) {
                        // Update limit
                        limit[$state.$current.name] = ctrl.paginationLimit;
                        // Store new limit config
                        geckoStore.set('gecko_pagination_limits_' + Gecko.account.routing_id, limit);
                        // Refresh page & reset to page one
                        $state.go($state.$current.name, Object.assign($stateParams, { page: 1 }), { reload: true });
                    }
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoPagination', geckoPagination);
})();