(function () {
    'use strict';

    function geckoFormDesignerPagesMenu($formBuilderService) {
        return {
            restrict: 'E',
            template: '\n                <div class="text-center gecko-form-designer-pages-menu">\n\n                    <div ui-sortable="ctrl.sortableOptions" ng-model="ctrl.pages">\n                        <div ng-repeat="page in ctrl.pages | orderBy:\'order\' track by $index">\n                            <div class="gecko-page-drop-zone" data-page-id="{{$index}}">\n                                <gecko-card class="gecko-designer-pages-menu-item" ng-class="{active: $formBuilderService.activePage == $index}" ng-click="ctrl.selectPage($index)">\n                                    <span>Page {{$index + 1}}</span>\n                                    <div class="gecko-designer-pages-menu-item-icon">\n                                        <i class="fa fa-align-left"></i>\n                                    </div>\n                                    <i class="fa fa-trash-alt"\n                                        ng-if="ctrl.pages.length > 1"\n                                        ng-click="ctrl.removePage($index)">\n                                    </i>\n                                </gecko-card>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div class="card gecko-designer-pages-btn-add" ng-click="ctrl.addPage()">\n                        <i class="fa fa-plus"></i>\n                    </div>\n\n                </div>\n            ',
            scope: {
                form: '=form',
                pages: '=pages'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            require: ['geckoFormDesignerPagesMenu', '?^form'],
            link: function link(scope, iElement, iAttrs, ctrls) {
                scope.$formBuilderService = $formBuilderService;
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];
                //Re-order options
                ctrl.sortableOptions = {
                    stop: function stop(e, ui) {
                        if (formCtrl) formCtrl.$setDirty();
                        setTimeout(function () {
                            if (ui.item.sortable.dropindex !== undefined) {
                                //Refresh object order vals
                                GeckoUI.refreshOrder(ctrl.pages);
                            }
                        }, 100);
                    }
                };

                //Select page
                ctrl.selectPage = function (index) {
                    $formBuilderService.activePage = index;
                };

                //Add page
                ctrl.addPage = function () {
                    ctrl.pages.push({
                        title: 'Page ' + (ctrl.pages.length + 1),
                        order: ctrl.pages.length,
                        fields: []
                    });
                    if (formCtrl) formCtrl.$setDirty();
                };

                //Remove page
                ctrl.removePage = function (index) {
                    if (ctrl.pages[index].fields.length) {
                        GeckoUI.dialog.confirm('Are you sure you wish to remove this page?', function (ok) {
                            if (ok) {
                                // Clean conditions
                                ctrl.pages[index].fields.forEach(function (field) {
                                    return $formBuilderService.cleanConditions(ctrl.form.conditions, field);
                                });
                                // Remove page
                                ctrl.pages.splice(index, 1);
                                scope.$digest();
                                // Set new active page
                                ctrl.selectPage(ctrl.pages.length > 0 ? ctrl.pages.length - 1 : 0);
                                if (formCtrl) formCtrl.$setDirty();
                            }
                        });
                    } else {
                        ctrl.pages.splice(index, 1);
                        // Set new active page
                        ctrl.selectPage(ctrl.pages.length > 0 ? ctrl.pages.length - 1 : 0);
                        if (formCtrl) formCtrl.$setDirty();
                    }
                };

                // Set up an event listener on scroll to handle fixing the fields-menu
                window.addEventListener('scroll', $formBuilderService.handleMenusWindowScroll.bind(this, iElement, '.gecko-form-designer-pages-menu'));
                // Remove the event listener when we navigate away from the page
                scope.$on('$destroy', function () {
                    return window.removeEventListener('scroll', $formBuilderService.handleMenusWindowScroll);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFormDesignerPagesMenu', geckoFormDesignerPagesMenu);
})();