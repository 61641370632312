(function () {

    function GeckoLib($q, $window) {

        var Gecko = $window.initGecko($q);

        Object.defineProperties(Gecko, {
            base_url: {
                get: function get() {
                    return $window.Gecko.base_url;
                },
                set: function set(v) {
                    $window.Gecko.base_url = v;
                }
            },
            user: {
                get: function get() {
                    return $window.Gecko.user;
                },
                set: function set(v) {
                    $window.Gecko.user = v;
                }
            },
            geo: {
                get: function get() {
                    return $window.Gecko.geo;
                },
                set: function set(v) {
                    $window.Gecko.geo = v;
                }
            },
            group: {
                get: function get() {
                    return $window.Gecko.group;
                },
                set: function set(v) {
                    $window.Gecko.group = v;
                }
            },
            account: {
                get: function get() {
                    return $window.Gecko.account;
                },
                set: function set(v) {
                    $window.Gecko.account = v;
                }
            },
            dateFormat: {
                get: function get() {
                    return $window.Gecko.dateFormat;
                },
                set: function set(v) {
                    $window.Gecko.dateFormat = v;
                }
            },
            token: {
                get: function get() {
                    return $window.Gecko.token;
                },
                set: function set(v) {
                    $window.Gecko.token = v;
                }
            },
            package: {
                get: function get() {
                    return $window.Gecko.package;
                },
                set: function set(v) {
                    $window.Gecko.package = v;
                }
            },
            permissions: {
                get: function get() {
                    return $window.Gecko.permissions;
                },
                set: function set(v) {
                    $window.Gecko.permissions = v;
                }
            },

            pusher: {
                get: function get() {
                    return $window.Gecko.pusher;
                },
                set: function set(v) {
                    $window.Gecko.pusher = v;
                }
            },
            _pusher: {
                get: function get() {
                    return $window.Gecko._pusher;
                },
                set: function set(v) {
                    $window.Gecko._pusher = v;
                }
            },
            _pusher_channels: {
                get: function get() {
                    return $window.Gecko._pusher_channels;
                },
                set: function set(v) {
                    $window.Gecko._pusher_channels = v;
                }
            }
        });

        return Gecko;
    }

    angular.module('GeckoEngage.data').factory('Gecko', GeckoLib);
})();