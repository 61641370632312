(function () {
    'use strict';

    function VerifiedSendersCtrl($state, $stateParams, senders) {
        var ctrl = this;
        ctrl.senders = senders.toArray();
        ctrl.pagination = senders.pagination;

        // Table structure
        var cols = [{
            type: Gecko.Field.TYPE_NAME,
            title: 'Name',
            key: 'name',
            sortKey: 'name',
            colClass: 'col-xs-4'
        }, {
            title: 'Email',
            key: 'email',
            sortKey: 'email',
            colClass: 'col-xs-4'
        }, {
            title: 'Verified',
            key: 'verified',
            sortKey: 'verified',
            colClass: 'col-xs-4',
            render: function render(value) {
                switch (value) {
                    case true:
                    case 1:
                        return 'Verified';

                    case false:
                    case 0:
                        return 'Failed';

                    case null:
                    default:
                        return 'Pending';
                }
            }
        }];

        ctrl.cols = cols;

        // Table row action
        ctrl.rowAction = {
            state: 'verifiedsenders.sender',
            params: { sender_id: 'id' }
        };

        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen(sender) {
                return sender.verified || sender.verified_domain_id;
            },
            items: [{
                title: 'Send Verification Email',
                action: function action(sender) {
                    return new Gecko.Sender().fill(sender).resendVerification().then(function () {
                        return GeckoUI.messenger.success('Verification has been resent to the sender.');
                    }).catch(GeckoUI.messenger.error);
                }
            }]
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Senders',
            active: true
        }];
    }

    function VerifiedSenderCtrl($scope, $state, $stateParams, unsavedAlert, sender) {
        var ctrl = this;
        ctrl.sender = sender;

        // Fields
        ctrl.fields = [{
            id: 'name',
            label: 'Name',
            required: true
        }, {
            id: 'email',
            label: 'Email',
            required: true,
            disabledWhen: function disabledWhen() {
                return ctrl.sender.id;
            }
        }];

        ctrl.footerBtns = [{
            preset: 'save',
            requires: [ctrl.sender.id ? Gecko.ABILITY_SENDERS_UPDATE : Gecko.ABILITY_SENDERS_CREATE],
            action: function action() {
                ctrl.sender.save().then(function (sender) {
                    ctrl.sender = sender;
                    GeckoUI.messenger.success('Sender has been saved');
                    unsavedAlert.clear();
                    if ($stateParams.sender_id == 'new') {
                        $state.go('verifiedsenders.sender', {
                            sender_id: sender.id
                        });
                    }
                    $scope.$digest();
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }, {
            preset: 'remove',
            position: 'secondary',
            hideWhen: function hideWhen() {
                return $stateParams.sender_id == 'new';
            },
            requires: [Gecko.ABILITY_SENDERS_DELETE],
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this sender?', function (value) {
                    if (value) {
                        unsavedAlert.clear();
                        ctrl.sender.remove().then(function () {
                            // Remove it from the array
                            GeckoUI.messenger.success('Sender has been deleted');
                            $state.go('verifiedsenders.senders');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        }];
    }

    function VerifiedSenderVerifyCtrl($scope, $state, $stateParams) {
        var ctrl = this;
        new Gecko.Sender().verifySenders().then(function (results) {
            var count = results.length;
            if (count > 0) {
                GeckoUI.messenger.success(count + ' email address' + (count > 1 ? 'es were' : ' was') + ' marked as verified.');
            } else {
                GeckoUI.messenger.info('No email addresses had their status\'s updated');
            }
            $state.go('verifiedsenders.senders');
        }).catch(function (err) {
            GeckoUI.messenger.error('An error occurred while trying to verify.');
            console.error('Sender Verification Error:', err);
            $state.go('verifiedsenders.senders');
        });
    }

    angular.module('GeckoEngage').controller('VerifiedSendersCtrl', VerifiedSendersCtrl).controller('VerifiedSenderCtrl', VerifiedSenderCtrl).controller('VerifiedSenderVerifyCtrl', VerifiedSenderVerifyCtrl);
})();