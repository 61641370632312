(function () {
    'use strict';

    function ContactsListCtrl($scope, $rootScope, $state, $stateParams, $filter, $timeout, LoadingIndicator, contacts, contactsRfields, fieldList, number, $geckoModal, $geckoSocket, geckoStore, selectedData, asyncOptions, Gecko, handleMassAction, labels, contactFields, geckoDataService, savedSearchService, getContactsService) {
        $scope.search = {};
        $scope.searchDependants = [];
        $scope.newContact = {};
        $scope.contacts = (contacts || {}).data;
        $scope.fieldList = fieldList;
        $scope.contactFields = contactFields;
        $scope.pagination = (contacts || {}).pagination;
        if ($stateParams.number) $scope.number = number;

        $scope.selectOptions = {
            noPersist: true

            // Table structure
        };$scope.cols = [];
        angular.forEach($scope.fieldList, function (col, index) {
            $scope.cols.push({
                type: col.type,
                data_type: col.data_type,
                default_value: col.label.indexOf('Last') > -1 && col.data_type == Gecko.Field.DATA_TYPE_TIMESTAMP ? 'Never' : '',
                utc: !col.system && col.type == Gecko.Field.TYPE_DATE,
                date_format: !col.system && col.type == Gecko.Field.TYPE_DATE ? Gecko.dateFormat.short : undefined,
                title: col.label,
                key: 'field_' + (index + 1),
                sortKey: 'field_' + (index + 1),
                colClass: 12 % $scope.fieldList.length == 0 ? 'col-md-' + 12 / $scope.fieldList.length : '',
                clickToCall: true
            });
        });
        $scope.cols.push({
            type: Gecko.Field.TYPE_LABEL,
            title: 'Labels',
            key: 'labels'
        });

        // Row action
        $scope.rowAction = {
            state: 'contact.overview',
            params: { contact_id: 'id' }
        };

        // Cog drop down
        $scope.optionsBtn = {
            iconRight: 'fa-cog',
            btnTooltip: 'Options'
        };
        $scope.optionsBtn.items = [{
            title: 'Import contacts',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_BULK_IMPORT)) {
                    return;
                }
                return $state.go('settingsimportfile', { import_to: 'contacts' });
            }
        }, {
            title: 'Export contacts',
            action: function action() {
                return $state.go('exportnew', { export_id: 'new', contacts: true });
            }
        }, {
            title: 'Contact field options',
            action: function action() {
                $geckoModal.listViewFieldModal();
            }
        }];

        // Breadcrumbs
        $scope.breadcrumbs = [{
            label: 'Contacts',
            click: function click() {
                return $state.go('contacts');
            }
        }, {
            label: 'List',
            active: true
        }];

        // Contact Label filter
        $scope.contactLabel = $stateParams.label ? angular.isArray($stateParams.label) ? $stateParams.label : [$stateParams.label] : ['all'];

        $scope.tableFilters = [{
            title: 'Label',
            type: 'multi',
            extras: ['operator'],
            operatorTitle: 'Contacts with:',
            optionsQuery: new Gecko.Label().rfields({ label: ['name'] }).orderBy('name'),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            filterMap: {
                default: 'label',
                operator: 'operator'
            }
        }, {
            title: 'Saved Filters',
            type: 'dropdown',
            options: (geckoDataService.fetchFromStoreSync('filters').filters || []).map(function (filter) {
                return {
                    label: filter.name,
                    value: filter.id
                };
            }),
            stateParam: 'filter_id'
        }];

        // Advance Search

        // Get cached search
        var saved = geckoStore.get('gecko_contact_search');

        if ($stateParams.filter_id || saved && saved.filter_id) {
            $scope.search = savedSearchService.getById($stateParams.filter_id || saved && saved.filter_id).then(function (filter) {
                $scope.search = filter;
                $scope.conditions = $scope.search.related_conditions;
                $scope.logicOperator = $scope.search.requirement;
                $scope.$digest();
            });
        } else if (saved && saved.conditions.length) {
            $scope.search = {};
            $scope.conditions = saved.conditions;
            $scope.logicOperator = saved.requirement;
        } else {
            $scope.search = {};
            $scope.conditions = [];
            $scope.logicOperator = null;
        }

        $scope.isSearch = $stateParams.number || $stateParams.filter_id || saved && saved.filter_id || $scope.conditions.length > 0;
        $scope.currentFilter = $scope.search.id ? $scope.search.id : null;

        var searchModalOpen = false;

        // Search modal
        $scope.searchModal = function () {
            if (searchModalOpen) return;

            if ($stateParams.number) return false;

            var modalData = {
                conditions: $scope.conditions,
                requirement: $scope.logicOperator
            };

            if ($stateParams.filter_id || (saved || {}).filter_id) {
                modalData.filter_id = parseInt($stateParams.filter_id || (saved || {}).filter_id);
            }

            searchModalOpen = true;

            return $geckoModal.advancedSearchModal(modalData).result.then(function (data) {
                // Reset cached table filter
                geckoStore.set('params-contacts', null);

                // Reset filter
                if (data.action === 'filter_reset') {
                    return $scope.resetSearch();
                }

                // Reload page (with filter_id)
                if (['filter_change', 'filter_save'].indexOf(data.action) !== -1 && data.filter_id) {
                    $scope.currentFilter = data.filter_id;
                    $scope.loadSearch(data.action === 'filter_save');
                    // Refresh contacts (without page reload)
                } else {
                    $scope.conditions = data.conditions;
                    $scope.logicOperator = data.requirement;
                    $scope.refreshContacts(false, data.requirement);
                    // Remove filter_id param (when conditions have been edited)
                    if ($stateParams.filter_id) {
                        $state.go($state.$current.name, { filter_id: null, operator: null });
                    }
                }

                searchModalOpen = false;
            }).catch(function (err) {
                searchModalOpen = false;
            });
        };

        // Quicksearch filter btn
        $scope.searchFilterBtn = {
            action: function action() {
                return $scope.searchModal();
            },
            title: 'Filter'
        };

        // Reset search
        $scope.resetSearch = function () {

            // Remove cached search
            geckoStore.set('gecko_contact_search', null);

            if ($stateParams.number || $stateParams.filter_id || $stateParams.label) {
                geckoStore.set('params-contacts', null); // Clear the params otherwise tablefilter will just re-apply the filter on state change
            }

            $state.go('contacts', { label: null, filter_id: null, number: null }, { reload: true });
        };

        // Load search from filter dropdown
        $scope.loadSearch = function () {
            var reloadFilters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            geckoStore.set('gecko_contact_search', { filter_id: $scope.currentFilter });

            if (reloadFilters) {
                LoadingIndicator.show();
                savedSearchService.saturate().then(function () {
                    setTimeout(function () {
                        $state.go('contacts', { filter_id: $scope.currentFilter }, { reload: true });
                        LoadingIndicator.hide();
                    }, 1000);
                });
            } else {
                $state.go('contacts', { filter_id: $scope.currentFilter }, { reload: true });
            }
        };

        $scope.refreshContacts = function (reset, logicOperator) {

            // Cache search
            if (!reset) geckoStore.set('gecko_contact_search', { conditions: $scope.conditions, requirement: logicOperator });

            // Ordering vars
            var order = $stateParams.order !== undefined ? $stateParams.order : null,
                sort = $stateParams.sort !== undefined ? $stateParams.sort : null;

            var page = $stateParams.page ? $stateParams.page : 1;
            var perPage = GeckoUI.getPageLimit($state.$current.name);

            // Get refreshed contact list
            var contactsPromise = void 0;

            var filterId = $stateParams.filter_id ? $stateParams.filter_id : saved ? saved.filter_id : null;
            var conditions = reset ? [] : ($scope.conditions || []).map(function (condition) {
                if (!condition.contact_field_id) {
                    return condition;
                }
                condition.fieldType = ($scope.contactFields.find(function (field) {
                    return field.id === condition.contact_field_id;
                }) || {}).data_type;
                return condition;
            });

            contactsPromise = getContactsService.getContacts({
                order: order,
                sort: sort,
                page: page,
                perPage: perPage,
                // Where there are both conditions and a filter ID, prefer conditions.
                filterId: conditions ? undefined : filterId,
                conditions: conditions,
                search: null,
                conditionOperator: logicOperator,
                includes: 'labels',
                rFields: contactsRfields,
                numberId: number,
                searchLabels: null,
                labelOperator: null,
                contactFields: contactFields,
                fieldList: fieldList,
                labelList: labels
            });

            LoadingIndicator.resolve(contactsPromise).then(function (contacts) {
                // Reset search?
                if (reset) {
                    $scope.isSearch = false;
                    $scope.currentFilter = null;
                    $scope.search = {};
                    $scope.conditions = [];
                } else {
                    $scope.isSearch = true;
                }

                $scope.contacts = [];
                $scope.$digest();

                return contacts;
            }).then(function (contacts) {

                $scope.contacts = contacts.data;
                $scope.pagination = contacts.pagination;

                //searchModal('hide');
            }).catch(function (err) {
                GeckoUI.messenger.error(err);
            });

            // Fire Intercom Event
            try {
                var intercomData = {};
                intercomData['Page'] = $state.$current.page_title;
                intercomData['Search Requirement'] = $scope.logicOperator === 'ALL' ? 'AND' : 'OR';
                GeckoUI.triggerIntercomEvent('Advanced Search Run', intercomData);
            } catch (e) {} // eslint-disable-line no-empty
        };

        // Mass action
        $scope.contactSelects = [];

        $scope.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !$scope.contactSelects || $scope.contactSelects && !$scope.contactSelects.length;
            }
        };
        $scope.actionsBtn.items = [{
            title: 'Add label(s)',
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE) || !GeckoUI.ableWithReason(Gecko.ABILITY_LABELS_UPDATE)) return;

                if (!$scope.contactSelects.length) return false;
                return $geckoModal.labelModal($scope.contactSelects).result.then(function (response) {
                    // Handle the mass action
                    handleMassAction(response, $state.$current.name);

                    // Reset contact array
                    $scope.contactSelects.length = 0;
                });
            }
        }, {
            title: 'Manage consent(s)',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return;

                if (!$scope.contactSelects.length) return false;

                return $geckoModal.manageConsentModal($scope.contactSelects).result.then(function (response) {
                    // Handle the mass action
                    handleMassAction(response, $state.$current.name);

                    // Reset contact array
                    $scope.contactSelects.length = 0;
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW);
            }
        }, {
            title: 'Email contact(s)',
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return;

                if (!$scope.contactSelects.length) return false;
                $geckoModal.messageSendModal($scope.contactSelects).result.then(function () {
                    // Reset contact array
                    $scope.contactSelects.length = 0;
                });
            }
        }, {
            title: 'SMS contact(s)',
            hideWhen: function hideWhen() {
                return false;
            },
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return;

                if (!$scope.contactSelects.length) return false;

                $geckoModal.messageSendModal($scope.contactSelects, Gecko.Message.TYPE_SMS).result.then(function () {
                    // Reset contact array
                    $scope.contactSelects.length = 0;
                });
            }
        }, {
            title: 'Add to call campaign',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CALL_CAMPAIGNS_UPDATE);
            },
            action: function action() {
                return $geckoModal.selectorModal({
                    title: 'Select a Campaign',
                    model: 'campaign',
                    fieldLabel: 'Add to Campaign',
                    optionsKey: 'id',
                    optionsLabel: 'title',
                    description: 'Add your selected contacts to any active call campaign.',
                    wheres: [{
                        key: 'module',
                        value: [Gecko.Campaign.MODULE_CALL]
                    }]
                }).result.then(function (campaign_id) {
                    if (campaign_id === false) return false;

                    // Add to broadcast
                    new Gecko.Contact().massAction({ model: 'contact_ids', contact_ids: $scope.contactSelects }, Gecko.Workflow.Actions.subscriber(campaign_id)).then(function (response) {
                        // Handle the mass action
                        handleMassAction(response);
                        // Reset contact array
                        $scope.contactSelects.length = 0;
                    }).catch(GeckoUI.messenger.error);
                });
            }
        }, {
            title: 'Add to broadcast',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_BROADCASTS_UPDATE);
            },
            action: function action() {
                return $geckoModal.selectorModal({
                    title: 'Select a Broadcast',
                    model: 'campaign',
                    fieldLabel: 'Add to Broadcast',
                    optionsKey: 'id',
                    optionsLabel: 'title',
                    description: 'Add your selected contacts to any broadcast.',
                    wheres: [{
                        key: 'module',
                        value: [Gecko.Campaign.MODULE_EMAIL, Gecko.Campaign.MODULE_SMS]
                    }, {
                        key: 'status',
                        value: [Gecko.Campaign.PENDING, Gecko.Campaign.ACTIVE, Gecko.Campaign.RUNNING, Gecko.Campaign.PAUSED, Gecko.Campaign.INVALID]
                    }]
                }).result.then(function (campaign_id) {
                    if (campaign_id === false) return false;

                    // Add to broadcast
                    new Gecko.Contact().massAction({ model: 'contact_ids', contact_ids: $scope.contactSelects }, Gecko.Workflow.Actions.subscriber(campaign_id)).then(function (response) {
                        // Handle the mass action
                        handleMassAction(response);
                        // Reset contact array
                        $scope.contactSelects.length = 0;
                    }).catch(GeckoUI.messenger.error);
                });
            }
        }, {
            title: 'Add to organisation',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_ORGANISATION) || !Gecko.able(Gecko.ABILITY_ORGANISATIONS_UPDATE);
            },
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE) || !GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_UPDATE)) return;

                if (!$scope.contactSelects.length) return false;
                $geckoModal.organisationAddModal($scope.contactSelects, true).result.then(function () {
                    // Reset contact array
                    $scope.contactSelects.length = 0;
                });
            }
        }, {
            title: 'Add to event',
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE) || !GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_UPDATE)) return;

                if (!$scope.contactSelects.length) return false;
                $geckoModal.eventAddContactModal($scope.contactSelects).result.then(function () {
                    // Reset contact array
                    $scope.contactSelects.length = 0;
                });
            }
        }, {
            title: 'Delete contact(s)',
            action: function action() {
                if (!$scope.contactSelects.length) return false;

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_DELETE)) return;

                GeckoUI.dialog.confirm('Are you sure you want to delete the selected contacts?', function (ok) {
                    if (ok) {
                        new Gecko.Contact().massAction({ model: 'contact_ids', contact_ids: $scope.contactSelects }, { type: 'delete' }).then(function (response) {
                            // Handle the mass action
                            handleMassAction(response, $state.$current.name);
                            // Clear the mass action checkboxes
                            $scope.contactSelects.length = 0;
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.message);
                        });
                    }
                });
            }
        }];
    }

    function ContactAddCtrl($scope, $state, $stateParams, $filter, fields) {
        var ctrl = this;

        ctrl.fields = $filter('filter')(fields.toArray(), { is_calculated: false, is_social: false });

        // removes columns for purpose of data entry
        angular.forEach(ctrl.fields, function (field) {
            field.colClass = 'none';
        });

        // counters for fields to load
        var startPosition = 0;
        var endPosition = 10;
        var fieldsLength = ctrl.fields.length;

        // retrieves the next ten fields from the original field array
        var getMoreFields = function getMoreFields() {
            startPosition = endPosition;
            endPosition = endPosition + 10;
            return ctrl.fields.slice(startPosition, endPosition);
        };

        // adds the new fields into the display array
        ctrl.loadMoreFields = function () {
            var newFields = getMoreFields();
            ctrl.displayFields = ctrl.displayFields.concat(newFields);
        };

        // gets the first 10 fields
        ctrl.displayFields = ctrl.fields.slice(startPosition, endPosition);

        ctrl.values = {};

        ctrl.contact = new Gecko.Contact();
        ctrl.contact.fields = {};

        $scope.isContactFields = true;

        // Form field directive setup
        $scope.form = {};
        $scope.values = [];
        $scope.countries = countries;
        $scope.checkConditional = function () {
            return true;
        };
        $scope.related_conditions = true;
        $scope.fieldOptions = [];

        // Get a new form
        $scope.contact = new Gecko.Contact();
        $scope.contact.fields = {};
        $scope.fields = $filter('orderBy')(fields.toInstances(), 'order');

        var saveContact = function saveContact() {

            // Populate the fields relation
            ctrl.fields.forEach(function (field) {
                ctrl.contact.fields["field" + field.id] = ctrl.values[field.id];
            });

            ctrl.contact.save().then(function (contact) {

                GeckoUI.messenger.success('Contact added');
                $state.go('contact.overview', { contact_id: contact.id });
            }).catch(function (err) {

                if (!err.conflict_id) {

                    GeckoUI.messenger.error(err.errors);
                } else {

                    GeckoUI.dialog.confirm('The data you entered matching an existing contact, would you like to view the contact instead?', function (result) {

                        if (result) {
                            $state.go('contact.overview', {
                                contact_id: err.conflict_id
                            });
                        }
                    });
                }
            });
        };

        // Button setup
        ctrl.footerBtns = [{
            preset: 'save',
            title: 'Create Contact',
            icon: 'fa-check',
            action: saveContact
        }, {
            preset: 'load',
            title: 'Load More',
            icon: 'fa-plus',
            action: ctrl.loadMoreFields,
            hideWhen: function hideWhen() {
                return endPosition > fieldsLength ? true : false;
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Contacts',
            click: function click() {
                return $state.go('contacts');
            }
        }, {
            label: 'Add contact',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('ContactsListCtrl', ContactsListCtrl).controller('ContactAddCtrl', ContactAddCtrl);
})();