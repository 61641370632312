(function () {
    'use strict';

    function geckoCallProfileCard($rootScope, $stateParams, $geckoCall) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-profile-card.html',

            require: ['?^geckoCallPane', '?^geckoCallPaneListen'],

            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0] || ctrls[1];

                // Card buttons
                ctrl.profileCardBtns = [{
                    title: 'Next call',
                    btnClass: 'btn-primary',
                    icon: 'fa-angle-right',
                    action: function action() {
                        return $geckoCall.next(ctrl.campaign, $stateParams.subscriber_id, true);
                    }
                }];

                ctrl.hideProfileCardBtns = function () {
                    return !($geckoCall.isOutbound() && ctrl.campaign && $geckoCall.hasCompleted());
                };

                // Toggle note
                ctrl.toggleNote = function () {
                    return ctrl.addingNote = !ctrl.addingNote;
                };

                // Get call number title
                ctrl.getNumberTitle = $geckoCall.getNumberTitle;
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallProfileCard', geckoCallProfileCard);
})();