/* global angular GeckoUI countries $ */

(function () {
    'use strict';

    function EventCtrl($state, $scope, $stateParams, $geckoModal, event, integrations, $filter, Gecko, handleMassAction, env) {
        var ctrl = this;
        ctrl.event = event;
        ctrl.integrations = integrations.toArray();

        if (ctrl.event.type === Gecko.Event.TYPE_EVENT_LEGACY) {
            $state.go('legacy-event.overview', { event_id: event.id });
        }

        // Redirect to overview page (if no sub-route is found)
        if ($state.$current.name == 'event') $state.go('event.overview');

        if (!ctrl.event.integration_syncs) {
            ctrl.event.integration_syncs = {};
        }

        ctrl.getPrettyTimezone = function (event) {
            return (event.timezone || Gecko.account.timezone).replace('Etc/', '').replace('/', ' - ').replace('_', ' ');
        };

        var syncToIntegration = ctrl.event.syncs && ctrl.integrations.filter(function (i) {
            return i.type !== Gecko.Integration.TYPE_ENGAGE_DYNAMICS;
        }).filter(function (i) {
            return ctrl.event[i.type + '_id'];
        }).length;

        var syncsToEngageDynamicsOrTargetX = !!Object.values(ctrl.event.integration_syncs || {}).filter(function (i) {
            return i === true;
        }).length;

        // Tabs
        ctrl.tabs = [{
            id: 'overview',
            title: 'Overview',
            state: 'event.overview'
        }, {
            id: 'edit',
            title: 'Edit',
            state: 'event.settings'
        }, {
            id: 'sessions',
            title: 'Sessions',
            state: 'event.sessions',
            activeWhen: function activeWhen() {
                return ['event.sessions', 'event.session'].indexOf($state.$current.name) !== -1;
            },
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_SESSIONS_TAB);
            }
        }, {
            id: 'workflows',
            title: 'Workflows',
            state: 'event.workflows',
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_WORKFLOWS_TAB);
            }
        }, {
            id: 'stream',
            title: 'Event Streams',
            state: 'event.stream',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_VIDEO_STREAMING) || ![Gecko.Event.DELIVERY_METHOD_ONLINE, Gecko.Event.DELIVERY_METHOD_HYBRID].includes(ctrl.event.delivery_method) || ctrl.event.registration_type === Gecko.Event.TYPE_SINGLE_EVENT;
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Events',
            click: function click() {
                return $state.go('events.list');
            }
        }, {
            label: ctrl.event.id ? ctrl.event.nice_title : 'Add Event',
            active: true
        }];

        // Action button
        ctrl.actionBtn = {
            title: 'Actions',
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary'
        };
        ctrl.actionBtn.items = [{
            title: 'Add contacts',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_UPDATE)) return;

                return $geckoModal.contactAddModal(ctrl.event).result.then(function (data) {
                    // Handle the mass action
                    handleMassAction(data, $state.$current.name);
                    $state.go($state.$current.name, $stateParams, { reload: true });
                });
            },
            hideWhen: function hideWhen() {
                return ctrl.event.full && !ctrl.event.auto_waitlist;
            }
        }, {
            title: 'Email attendees',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_EMAIL)) return;

                var statuses = $stateParams.status || [Gecko.Event.STATUS_REGISTERED, Gecko.Event.STATUS_INVITED, Gecko.Event.STATUS_ATTENDED, Gecko.Event.STATUS_ENGAGED];

                var conditions = [{ model: Gecko.Condition.MODEL_ATTENDANCE, event_id: ctrl.event.id, value: statuses }];

                if ($stateParams.search) {
                    conditions.push({ model: Gecko.Condition.MODEL_KEYWORD, value: $stateParams.search });
                }

                $geckoModal.messageSendModal(conditions, { event_id: ctrl.event.id });
            }
        }, {
            title: 'Sync this event',
            hideWhen: function hideWhen() {
                return !syncToIntegration && !syncsToEngageDynamicsOrTargetX;
            },
            action: function action() {
                var filtered_integrations = ctrl.integrations.filter(function (int) {
                    return (ctrl.event.integration_ids || []).includes(int.id);
                }).map(function (item) {
                    item.name = item.name + ' (' + $filter('formatDate')(item.created_at, Gecko.dateFormat.short) + ')';
                    return item;
                });
                if (filtered_integrations.length === 1) {
                    ctrl.syncEvent(filtered_integrations[0].id);
                } else {
                    var message = 'What integration would you like to sync?';
                    var array = GeckoUI.mapObjectArray(filtered_integrations, { value: 'id', label: 'name', date: 'created_at', type: 'type' });

                    var callback = function callback(id) {
                        if (id) ctrl.syncEvent(id);
                    };

                    return GeckoUI.dialog.prompt(message, callback, array);
                }
            }
        }, {
            title: 'View event dashboard',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_DASHBOARD)) {
                    return;
                }
                return $state.go('dashboardview', {
                    event_id: ctrl.event.id,
                    override_date: 'month'
                });
            },
            hideWhen: function hideWhen() {
                if (!Gecko.able(Gecko.ABILITY_EVENTS_DASHBOARD)) {
                    return true;
                }
                return !Gecko.has(Gecko.Package.FEATURE_EVENT);
            }
        }, {
            title: 'Export attendees',
            action: function action() {
                $state.go('exportnew', { export_id: 'new', event_id: ctrl.event.id });
            }
        }, {
            title: 'Clone this event',
            action: function action(event) {
                // Check the users permissions
                if (!Gecko.able(Gecko.ABILITY_EVENTS_CREATE)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_EVENTS_CREATE));
                }

                var num_scheduled = ctrl.event.related_workflows.filter(function (workflow) {
                    return workflow.schedule !== null;
                }).length;
                var s = num_scheduled === 1 ? '' : 's';
                var message = num_scheduled > 0 ? '<h3>Workflow' + s + ' Requiring Attention</h3>\n                        <p>You are attempting to clone an <b>Event</b> that has workflows containing actions with set dates, the workflows affected will be reset to run immediately.</p>\n\n                        <p>To avoid any unintended consequences, we strongly recommend updating the dates on the affected workflows before adding attendances.</p>' : 'Are you sure you would like to clone this event?';
                GeckoUI.dialog.confirm(message, function (confirm) {
                    if (confirm) {
                        angular.copy(ctrl.event).include('responses', 'attendances').clone().then(function (event) {
                            $state.go('event.overview', { event_id: event.id }, { reload: true });
                            GeckoUI.messenger.success(ctrl.event.title + ' was cloned successfully');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        }, {
            title: 'Repeat this event',
            action: function action(event) {
                // Check the users permissions
                if (!Gecko.able(Gecko.ABILITY_REPEATING_EVENT)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_REPEATING_EVENT));
                }

                var num_scheduled = ctrl.event.related_workflows.filter(function (workflow) {
                    return workflow.schedule !== null;
                }).length;
                var s = num_scheduled === 1 ? '' : 's';
                var message = num_scheduled > 0 ? '<h3>Workflow' + s + ' Requiring Attention</h3>\n                        <p>You are attempting to repeat an <b>Event</b> that has workflows containing actions with set dates, the workflows affected will be reset to run immediately.</p>\n\n                        <p>To avoid any unintended consequences, we strongly recommend updating the dates on the affected workflows before adding attendances.</p>' : 'Are you sure you would like to repeat this event?';
                GeckoUI.dialog.confirm(message, function (confirm) {
                    if (confirm) {
                        $geckoModal.repeatingEventModal(ctrl.event).result.then(function () {
                            return $state.go('events.list');
                        });
                    }
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_REPEATING_EVENT);
            }
        }, {
            title: 'Timetravel event',
            action: function action(event) {
                // Check the users permissions
                if (!Gecko.able(Gecko.ABILITY_EVENTS_UPDATE)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_EVENTS_UPDATE));
                }
                $geckoModal.eventTimeTravelModal(ctrl.event).result.then(function () {
                    return $state.go($state.$current.name, $stateParams, { reload: true });
                });
            }
        }, {
            title: 'Generate video admin page',
            action: function action() {
                new Gecko.BaseModel().call('/events/' + ctrl.event.id + '/secrets', 'GET', { event_rfields: 'id' }, true).then(function (data) {
                    window.open(buildVideoPageUrl(data.event.admin_secret), '_blank');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err);
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_VIDEO_STREAMING) || ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_VENUE;
            }
        }, {
            title: 'Generate video host page',
            action: function action() {
                new Gecko.BaseModel().call('/events/' + ctrl.event.id + '/secrets', 'GET', { event_rfields: 'id' }, true).then(function (data) {
                    window.open(buildVideoPageUrl(data.event.host_secret), '_blank');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err);
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_VIDEO_STREAMING) || ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_VENUE;
            }
        }, {
            title: 'Remove this event',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_DELETE);
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to remove this event?', function (value) {
                    if (value) {
                        ctrl.event.remove().then(function () {
                            GeckoUI.messenger.success('Event removed');
                            // Redirect to events
                            return $state.go('events.list');
                        }).catch(function (err) {
                            return GeckoUI.messenger.error(err);
                        });
                    }
                });
            }
        }];

        var buildVideoPageUrl = function buildVideoPageUrl(secret) {
            var _env = env();
            var baseUrl;

            if (_env === 'stage') {
                baseUrl = 'https://rsvp-stage.geckoform.com';
            } else if (_env === 'local') {
                baseUrl = 'http://localhost:3000';
            } else {
                baseUrl = 'https://rsvp.geckoengage.com';
            }

            return baseUrl + '/#/stream/' + Gecko.account.uuid + '/' + secret + '/' + ctrl.event.id;
        };

        ctrl.showSaveBtn = function () {
            return ['event.workflows', 'event.settings'].indexOf($state.$current.name) != -1;
        };

        ctrl.saveEvent = function () {
            $scope.$broadcast('saveEvent');
        };
        // Listen for CMD + S event
        $scope.$on('quickSave:event', ctrl.saveEvent);

        // Sync responses
        ctrl.syncEvent = function (int_id) {
            if (!int_id) return false;
            ctrl.event.sync(int_id).then(function () {
                GeckoUI.messenger.success('Sync successfully scheduled, please allow time for Sync to process.');
            }).catch(function (error) {
                GeckoUI.messenger.error(error);
                // GeckoUI.messenger.error('Sync failed, check integration settings.');
            });
        };

        ctrl.showNewSessionBtn = function () {
            return ['event.sessions'].indexOf($state.$current.name) != -1;
        };

        ctrl.newSession = function () {
            return $scope.$broadcast('addSession', ctrl.event);
        };
    }

    angular.module('GeckoEngage').controller('EventCtrl', EventCtrl);
})();