(function () {

    function MessageEventsModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('messageEventsViewModal', function (_message_events, _workflowInfo) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/message_events/modal_view.html',
                controller: 'MessageEventsModalCtrl',
                resolve: {
                    message_events: function message_events() {
                        return _message_events;
                    },
                    workflowInfo: function workflowInfo() {
                        return _workflowInfo;
                    }
                }
            };
        });
    }

    function MessageEventsModalCtrl($modalInstance, $filter, message_events, workflowInfo) {
        var ctrl = this;
        ctrl.message_events = message_events;
        ctrl.workflowInfo = workflowInfo;
    }

    angular.module('GeckoEngage').config(MessageEventsModals).controller('MessageEventsModalCtrl', MessageEventsModalCtrl);
})();