var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
    'use strict';

    function geckoSideMenu() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-side-menu/gecko-side-menu.html',
            scope: true,
            controller: function controller($scope, $location, $state, $stateParams, geckoSideMenuService, geckoStore, $filter) {
                $scope.geckoSideMenuService = geckoSideMenuService;
                $scope.menu = geckoSideMenuService.get();

                // Build menu links
                $scope.menu = $scope.menu.map(function (item) {
                    if (item.state) {
                        item.uiSref = '' + item.state;
                        if (item.stateParams) item.uiSref = item.uiSref + ('(' + JSON.stringify(item.stateParams) + ')');
                    }
                    return item;
                });

                $scope.isActive = function (location) {
                    if (!location || location === undefined) return false;
                    if (typeof location === 'string') return $location.path().indexOf(location) === 0;
                    if ((typeof location === 'undefined' ? 'undefined' : _typeof(location)) === 'object' && !(location instanceof Array)) {
                        var _return = $state.current.name === location.state;

                        angular.forEach(location.params, function (value, key) {
                            _return = _return && $stateParams[key] === value;
                        });
                        return _return;
                    }
                    for (var i = 0; i < location.length; i++) {
                        var isActive = $scope.isActive(location[i]);
                        if (isActive) return true;
                    }
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoSideMenu', geckoSideMenu);
})();