(function () {
    'use strict';

    function messageService() {

        return {
            nonGSMCharacters: function nonGSMCharacters(value) {
                // Check if the value is GSM compliant, if not identify the words containing the invalid characters

                // https://stackoverflow.com/a/12673229
                var isGSMAlphabet = function isGSMAlphabet(text) {
                    return new RegExp('^[A-Za-z0-9 \\r\\n@\xA3$\xA5\xE8\xE9\xF9\xEC\xF2\xC7\xD8\xF8\xA4\xC5\xE5\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039E\xC6\xE6\xDF\xC9!"#$%&\'()*+,\\-./:;<=>?\xA1\xC4\xD6\xD1\xDC\xA7\xBF\xE4\xF6\xF1\xFC\xE0^{}\\\\\\[~\\]|\u20AC]*$').test(text);
                };

                if (isGSMAlphabet(value)) return null;

                var non_gsm_words = value.split(' ').reduce(function (arr, val) {
                    if (!isGSMAlphabet(val)) arr.push(val);
                    return arr;
                }, []);

                return non_gsm_words;
            }
        };
    }

    angular.module('GeckoEngage').service('messageService', messageService);
})();