(function () {
    'use strict';

    function DataSecurityPreferencesCtrl(account) {
        var ctrl = this;
        ctrl.account = account;

        // Set auto delete toggle value
        if (ctrl.account.auto_delete_contact_days) {
            ctrl.account.auto_delete_contacts = 1;
        } else {
            ctrl.account.auto_delete_contacts = 0;
        }

        var initDeleteContactDays = ctrl.account.auto_delete_contact_days;
        ctrl.account.skip_gdpr_block = Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK) ? 1 : 0;

        ctrl.fields = [{
            id: 'auto_delete_contacts',
            label: 'Automatically Archive Old Data',
            type: Gecko.Field.TYPE_TOGGLE,
            description: 'Automatically archive inactive contacts. For more information, click <a href="https://academy.geckoengage.com/en/articles/6895614-contact-archiving" title="Contact Archiving">here</a>.',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_AUTO_ARCHIVE);
            }
        }, {
            id: 'auto_delete_contact_days',
            label: 'Automatically archive contacts that haven\'t had any activity for:',
            type: Gecko.Field.TYPE_SELECT,
            description: 'Only contacts that haven\'t submitted any forms, been updated, messaged, or called within the specified amount of time will be archived.',
            noBlank: true,
            options: [{
                id: 180,
                name: '6 months'
            }, {
                id: 365,
                name: '1 year'
            }, {
                id: 548,
                name: '18 months'
            }, {
                id: 730,
                name: '2 years'
            }, {
                id: 1095,
                name: '3 years'
            }],
            hideWhen: function hideWhen() {
                return !ctrl.account.auto_delete_contacts || !Gecko.able(Gecko.ABILITY_AUTO_ARCHIVE);
            }
        }, {
            id: 'privacy_policy',
            label: 'Privacy Policy',
            help_text: 'This privacy policy will appear on your Consent Field and Contact Portal pages. Only available to GeckoProtect users.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_RICHTEXT,
            config: {
                toolbar: 'simple'
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW);
            }
        }, {
            id: 'skip_gdpr_block',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || [Gecko.Group.ADMIN, Gecko.Group.SUPER].indexOf(Gecko.group.type) === -1;
            },
            label: 'Ignore All Consent Preferences',
            description: 'Allow communication with contacts without checking their consent preferences.'
        }];

        ctrl.footerBtns = [{
            preset: 'save',
            action: function action() {
                return ctrl.saveAccount();
            }
        }];

        var saveFn = function saveFn() {
            // Set to null, if toggled off
            if (!ctrl.account.auto_delete_contacts) {
                ctrl.account.auto_delete_contact_days = null;
            }
            // Set skip gdpr block
            ctrl.account.features = { 'skip_gdpr_block': ctrl.account.skip_gdpr_block };
            // Update User's ability
            Gecko.user.abilities.skip_gdpr_block = {
                can: ctrl.account.skip_gdpr_block ? true : false,
                reason: ctrl.account.skip_gdpr_block ? null : "You cannot perform this action because your Account does not have the gdpr block skip enabled, please contact your Administrator."
            };

            return ctrl.account.save().then(function (account) {
                GeckoUI.messenger.success('Data security preferences updated');
            }).catch(function (err) {
                return GeckoUI.messenger.error(err);
            });
        };

        ctrl.saveAccount = function () {
            if (ctrl.account.auto_delete_contacts && initDeleteContactDays !== ctrl.account.auto_delete_contact_days) {
                GeckoUI.dialog.confirm('Are you sure you want to make changes to the auto archiving period? Contacts will be queued to be deleted once you save.', function (result) {
                    if (result) saveFn();
                });
            } else {
                saveFn();
            }
        };
    }

    angular.module('GeckoEngage').controller('DataSecurityPreferencesCtrl', DataSecurityPreferencesCtrl);
})();