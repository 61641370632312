(function () {

    function RepeatingEventModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('repeatingEventModal', function (_event) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/repeating_events/repeating_event_modal.html',
                controller: 'RepeatingEventModalCtrl',
                resolve: {
                    event: function event() {
                        return _event;
                    }
                }
            };
        });
    }

    function RepeatingEventModalCtrl($modalInstance, $scope, event, geckoDates, $timeout) {
        var ctrl = this;
        ctrl.event = event;
        ctrl.title = 'Repeat Event';
        ctrl.description = '<p>Select the dates on which you want your event to be repeated. We will clone and move your event onto the selected dates. Please don\'t close the browser tab whilst the system is creating the new events.</p>';
        if (ctrl.event.timezone.includes('Etc/') || ctrl.event.timezone.includes('UTC')) {
            ctrl.description += '<div class="alert alert-warning"><p>Please note that daylight savings deviations are not accounted for.</p></div>';
        }
        ctrl.description += '<label>Chosen Dates</label>';
        // applies scroll to the ctrl.fields
        ctrl.maxFieldHeight = 300;
        // users input start dates
        ctrl.userInputs = {};
        // list of repeating events objects
        ctrl.repeatingEvents = [];
        ctrl.nextIndex = 0;
        // initial stage
        ctrl.stage = 'edit';
        // get the start time of repeating parent event
        var eventStartTime = geckoDates.printDateString(ctrl.event.start_datetime, 'HH:mm:ss');
        // sets the first input to the parent events start date
        ctrl.userInputs['date_1'] = geckoDates.toEpoch(ctrl.event.start_datetime);

        // prevents modal from closing whilst running repeating process
        var closeAnyway = false;
        $scope.$on('modal.closing', function (event, reason) {
            if (ctrl.stage === "running" && !closeAnyway) {
                event.preventDefault();
                var confirmMessage = '<div class="alert alert-warning"><p>Are you sure you want to close the repeat modal?</p><p>Closing the modal will not roll back events that have been created already and new events will continue to be created in the background unless you close the browser tab.</p>';
                GeckoUI.dialog.confirm(confirmMessage, function (ok) {
                    if (ok) {
                        closeAnyway = true;
                        return $modalInstance.dismiss();
                    }
                });
            }
        });

        // initial fields before first input inserted
        ctrl.fields = [{
            id: 'add_date',
            icon: 'fa-plus',
            type: Gecko.Field.TYPE_BUTTON,
            colClass: 'offset-xs-10 col-xs-2 hide-label',
            btnClass: 'btn-default',
            action: function action() {
                ctrl.addDateFields();
            },
            hideWhen: function hideWhen() {
                return ctrl.stage !== 'edit';
            }
        }];

        // returns date entry input field
        ctrl.getDateField = function (index) {
            return {
                id: 'date_' + index,
                type: Gecko.Field.TYPE_DATE,
                colClass: (ctrl.fields.length < 3 ? 'col-xs-10' : 'col-xs-10') + ' hide-label',
                utc: true,
                hideWhen: function hideWhen() {
                    return ctrl.stage !== 'edit';
                }
            };
        };

        // returns delete button input field
        ctrl.getDeleteField = function (index) {
            return {
                id: 'delete_date_' + index,
                icon: 'fa-trash-alt',
                type: Gecko.Field.TYPE_BUTTON,
                colClass: 'col-xs-2 hide-label',
                btnClass: 'btn-default',
                action: function action() {
                    ctrl.fields = ctrl.fields.filter(function (field) {
                        return field.id !== 'date_' + index;
                    });
                    ctrl.fields = ctrl.fields.filter(function (field) {
                        return field.id !== 'delete_date_' + index;
                    });
                    delete ctrl.userInputs['date_' + index];
                },
                hideWhen: function hideWhen() {
                    return ctrl.stage !== 'edit' || ctrl.fields.length < 4;
                }
            };
        };

        // add another date input and delete button row to ctrl.fields
        ctrl.addDateFields = function () {
            ctrl.nextIndex++;
            // takes the last enter date and prefils the next input with a date value
            if (ctrl.nextIndex > 1) {
                ctrl.userInputs['date_' + ctrl.nextIndex] = ctrl.userInputs[Object.keys(ctrl.userInputs).pop()];
                updateScroll();
            }
            ctrl.fields.splice(ctrl.fields.length - 1, 0, ctrl.getDateField(ctrl.nextIndex));
            ctrl.fields.splice(ctrl.fields.length - 1, 0, ctrl.getDeleteField(ctrl.nextIndex));
        };

        // adds the initial date input and delete button to ctrl.fields
        ctrl.addDateFields();

        // ensure add button is still visible
        function updateScroll() {
            $timeout(function () {
                var element = document.getElementById("gecko-modal-fields");
                element.scrollTop = element.scrollHeight;
            }, 0);
        }

        function createAlerts(alertDatePassedString, alertDateRepeatedString) {
            if (alertDatePassedString || alertDateRepeatedString) {
                var alerts = '';
                if (alertDatePassedString) {
                    var alertDatePassedText = 'You have entered a date that falls before today\'s date:';
                    alerts = '<div class="alert alert-danger">' + alertDatePassedText + '<ul>' + alertDatePassedString + '</ul></div>';
                }
                if (alertDateRepeatedString) {
                    var alertDateRepeatedText = 'You have entered the following date multiple times:';
                    alerts = alerts + '<div class="alert alert-warning">' + alertDateRepeatedText + '<ul>' + alertDateRepeatedString + '</ul></div>';
                }
                return alerts;
            }
            return '';
        }

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Next',
            icon: 'fa-arrow-right',
            btnClass: 'btn-primary',
            action: function action() {
                ctrl.title = 'Confirmation';
                ctrl.stage = 'confirm';
                var alertDatePassedString = '';
                var alertDateRepeatedString = '';
                // iterates through each of the users input dates
                Object.values(ctrl.userInputs).forEach(function (date) {
                    // formats the date from UTC number to string and adds original event start time to date
                    var dateTimeString = geckoDates.toString(date).slice(0, -8) + eventStartTime;

                    // formats the date for display purposes
                    var displayDate = geckoDates.printDateEpoch(geckoDates.toEpoch(dateTimeString), 'llll');

                    // warning to user if they are about to repeat an event before todays date
                    if (moment(dateTimeString) < moment()) {
                        alertDatePassedString = alertDatePassedString + '<li>' + displayDate + '</li>';
                    }
                    // warning to user if they have enter two repeating dates
                    if (ctrl.repeatingEvents.find(function (repeat) {
                        return repeat.date === dateTimeString;
                    })) {
                        alertDateRepeatedString = alertDateRepeatedString + '<li>' + displayDate + '</li>';
                    }
                    // stores object in list of repeating events
                    ctrl.repeatingEvents.push({ date: dateTimeString, status: 'pending', title: ctrl.event.title + ' - ' + displayDate });
                });
                var alerts = createAlerts(alertDatePassedString, alertDateRepeatedString);
                ctrl.description = '<p>Select the dates on which you want your event to be repeated. We will clone and move your event onto the selected dates. Please don\'t close the browser tab whilst the system is creating the new events.</p>' + alerts + '<label>Chosen Dates</label>';
                ctrl.footerMessage = ctrl.repeatingEvents.length + ' event' + (ctrl.repeatingEvents.length > 1 ? 's' : '') + ' to repeat';
            },
            hideWhen: function hideWhen() {
                return ctrl.stage !== 'edit';
            },
            disabledWhen: function disabledWhen() {
                return Object.values(ctrl.userInputs).length === 0;
            }
        }, {
            title: 'Create Events',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            action: function action() {
                ctrl.title = 'Repeat Event';
                ctrl.alertMessage = "Please do not close this modal or the browser tab whilst we are creating your events.";
                ctrl.description = null;
                ctrl.footerMessage = 'Repeating event 1 of ' + ctrl.repeatingEvents.length;
                ctrl.stage = 'running';
                ctrl.showListProgress = true;
                repeatEvents(0).then(function () {
                    ctrl.stage = 'complete';
                    ctrl.alertMessage = null;
                    ctrl.footerMessage = null;
                    var totalSuccessful = ctrl.repeatingEvents.filter(function (event) {
                        return event.status === "success";
                    }).length;
                    var s = ctrl.repeatingEvents.length !== 1 ? 's' : '';
                    var was_were = ctrl.repeatingEvents.length !== 1 ? 'were' : 'was';
                    var successMessage = 'A total of ' + totalSuccessful + ' out ' + ctrl.repeatingEvents.length + ' event' + s + ' ' + was_were + ' created successfully.';
                    ctrl.description = '<div class="alert alert-success">' + successMessage + '</div>';
                    if (closeAnyway) GeckoUI.messenger.success(successMessage);
                }).catch(function (error) {
                    console.log(error);
                    ctrl.alertMessage = error;
                });
            },
            hideWhen: function hideWhen() {
                return ctrl.stage !== 'confirm';
            }
        }, {
            title: 'Edit',
            icon: 'fa-edit',
            btnClass: 'btn-default',
            action: function action() {
                ctrl.title = 'Repeat Event';
                ctrl.stage = 'edit';
                ctrl.footerMessage = null;
                ctrl.description = '<p>Select the dates on which you want your event to be repeated. We will clone and move your event onto the selected dates. Please don\'t close the browser tab whilst the system is creating the new events.</p><label>Chosen Dates</label>';
                ctrl.repeatingEvents = [];
            },
            hideWhen: function hideWhen() {
                return ctrl.stage !== 'confirm';
            }
        }, {
            title: 'Finish',
            icon: 'fa-save',
            btnClass: 'btn-primary',
            action: function action() {
                $modalInstance.close();
            },
            hideWhen: function hideWhen() {
                return ctrl.stage !== 'running' && ctrl.stage !== 'complete';
            },
            disabledWhen: function disabledWhen() {
                return ctrl.stage !== 'complete';
            }
        }];

        var cloneEvent = function cloneEvent() {
            return new Promise(function (resolve, reject) {
                // Check the users permissions
                if (!Gecko.able(Gecko.ABILITY_EVENTS_CREATE)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_EVENTS_CREATE));
                }

                angular.copy(ctrl.event).include('responses', 'attendances').clone(ctrl.event.title).then(function (event) {
                    return resolve(event);
                }).catch(function (error) {
                    GeckoUI.messenger.error(error);
                });
            });
        };

        var timeTravelEvent = function timeTravelEvent(event, newStartDate) {
            return new Promise(function (resolve, reject) {
                event.rfields({ event: ['title', 'start_datetime', 'end_datetime'] }).timetravel(newStartDate).then(function (data) {
                    return resolve(data['event']);
                }).catch(function (error) {
                    return reject(error);
                });
            });
        };

        var repeatEvents = function repeatEvents(index) {
            if (typeof ctrl.repeatingEvents[index] === 'undefined') return Promise.resolve();
            return cloneEvent().then(function (event) {
                return timeTravelEvent(event, ctrl.repeatingEvents[index].date);
            }).then(function (event) {
                ctrl.repeatingEvents[index].status = 'success';
                ctrl.footerMessage = 'Repeating event ' + (index + 1) + ' of ' + ctrl.repeatingEvents.length;
                return repeatEvents(++index);
            }).catch(function (error) {
                var status = error.message === 'The existing and desired dates match, no time travel was required.' ? 'success' : 'failed';
                ctrl.repeatingEvents[index].status = status;
                if (status === 'success') {
                    ctrl.footerMessage = 'Repeating event ' + (index + 1) + ' of ' + ctrl.repeatingEvents.length;
                } else {
                    GeckoUI.messenger.error(error);
                }
                return repeatEvents(++index);
            });
        };
    }

    angular.module('GeckoEngage').config(RepeatingEventModal).controller('RepeatingEventModalCtrl', RepeatingEventModalCtrl);
})();