(function () {
    'use strict';

    function geckoCallPanePrepare() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-pane-prepare.html',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {},
            controller: function controller($rootScope, $geckoCall) {
                var ctrl = this;

                // Prepare type
                ctrl.isType = function (type) {
                    return type == $geckoCall.prepareType;
                };

                // Minify pane
                ctrl.toggle = function (check) {
                    return $geckoCall.reset(true);
                };

                // Get pane title
                ctrl.getPaneTitle = function () {
                    if ($geckoCall.prepareType == 'dial') {
                        return 'Dial a Number';
                    } else if ($geckoCall.prepareType == 'contact') {
                        return 'New Contact Call';
                    } else if ($geckoCall.prepareType == 'subscriber') {
                        return 'New Campaign Call';
                    }
                };

                // Reset if incoming call is received
                $rootScope.$on('voip:incoming', function () {
                    return $geckoCall.reset();
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallPanePrepare', geckoCallPanePrepare);
})();