(function () {
    'use strict';

    function geckoEventPicker() {
        return {

            templateUrl: '/components/gecko-event-picker/gecko-event-picker.html',
            scope: {
                value: '=',
                field: '='
            },
            bindToController: true,
            controllerAs: 'ctrl',
            controller: function controller($scope, Gecko) {
                var ctrl = this;

                var getEventsSessionTimesWithTitle = function getEventsSessionTimesWithTitle(event) {
                    return event.children.reduce(function (sessionTimes, session) {
                        return sessionTimes.concat(getSessionsSessionTimesWithTitle(session));
                    }, []);
                };
                var getSessionsSessionTimesWithTitle = function getSessionsSessionTimesWithTitle(session) {
                    return session.children.map(function (sessionTime) {
                        sessionTime.nice_title = session.nice_title + ': ' + sessionTime.nice_title;
                        return sessionTime;
                    });
                };

                var setUpEvents = function setUpEvents(category) {
                    category = category.toObject();
                    ctrl.events = category.events.map(function (event) {
                        event.sessionTimes = getEventsSessionTimesWithTitle(event);
                        return event;
                    });
                    ctrl.value.event_ids = Object.keys(ctrl.value.event_ids).reduce(function (event_ids, event_id) {
                        event_ids[event_id] = ctrl.value.event_ids[event_id] || 'true';
                        return event_ids;
                    }, {});
                };

                var handleFail = function handleFail(errors) {
                    console.error(errors);
                };

                if (ctrl.value && ctrl.field.values.event_category_id) {
                    new Gecko.Category().include('events', 'events.children', 'events.children.children').rfields({ category: ['id'], event: ['nice_title'] }).get(ctrl.field.values.event_category_id).then(setUpEvents).catch(handleFail);
                }

                // Legacy support
                if (ctrl.value && !ctrl.field.values.event_category_id) {
                    // Ensure that event_ids is an object
                    if (ctrl.value.event_ids instanceof Array && ctrl.value.event_ids.length === 0) {
                        ctrl.value.event_ids = {};
                    }

                    new Gecko.Event().include('children:all').get(ctrl.value.parent_event_id).then(function (event) {

                        // Initialise events list
                        ctrl.events = [];

                        ctrl.events.push(event.toObject());

                        angular.forEach(event.related_children, function (child_event) {
                            ctrl.events.push(child_event.toObject());
                        });

                        ctrl.event = event.toObject();
                        Object.keys(ctrl.value.event_ids).forEach(function (key) {
                            ctrl.value.event_ids[key] = ctrl.value.event_ids[key] || 'true';
                        });
                    });
                }

                if (ctrl.value) {
                    // Map nulls to the string 'null' so that the view can mark them as checked properly
                    ctrl.value.event_ids = GeckoUI.mapObject(function (item) {
                        return item ? item : 'null';
                    })(ctrl.value.event_ids);
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoEventPicker', geckoEventPicker);
})();