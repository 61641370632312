(function () {
    'use strict';

    var newLabelId = 0;

    function inlineLabelFieldService(geckoDataService) {
        var _this = {

            fieldDefaults: {
                type: Gecko.Field.TYPE_TAGGER,
                placeholder: 'Search and Select',
                matchTemplate: 'm-label-match',
                getOptions: function getOptions() {},
                optionsKey: 'id',
                optionsLabelKey: 'name',
                xClass: function xClass(item) {
                    if (item && item.color) return GeckoUI.getContrastColor(item.color);
                    return 'white';
                }
            },

            buildField: function buildField(config) {
                return Object.assign({}, _this.fieldDefaults, config || {});
            },

            buildLabelFieldOptions: function buildLabelFieldOptions($select, updateFieldValue, updateFieldOptions) {
                return geckoDataService.fetch(['labels']).then(function (data) {
                    // Build initial field value
                    updateFieldValue(data.labels);

                    var search = $select.search;
                    var labels = data.labels;
                    var filteredLabels = search ? labels.filter(function (label) {
                        return String(label.name).toLowerCase().includes(String(search).toLowerCase());
                    }) : labels;

                    // Update field options
                    updateFieldOptions(filteredLabels);
                });
            },

            buildNewLabelObject: function buildNewLabelObject(name) {
                return { name: name, color: tinycolor.random().toHexString(), id: newLabelId-- };
            },

            newLabel: function newLabel(labelName, fieldOptions) {
                // Is the label already created?
                if ((fieldOptions || []).find(function (o) {
                    return String(o.name).toLowerCase() === String(labelName).toLowerCase();
                })) {
                    return;
                }
                return _this.buildNewLabelObject(labelName);
            },

            saveNewLabel: function saveNewLabel(name, color) {
                return new Gecko.Label().fill({ name: name, color: color }).save().then(function (label) {
                    // Update data service store
                    geckoDataService.updateItem('labels', label);
                    return label;
                });
            },

            selectLabel: function selectLabel(label) {
                // Create a new label
                if (label.id < 1) {
                    return _this.saveNewLabel(label.name, label.color);
                }
                // Label already exists
                return Promise.resolve(label);
            }

        };
        return _this;
    }

    angular.module('GeckoEngage').service('inlineLabelFieldService', inlineLabelFieldService);
})();