(function () {
    'use strict';

    function IntegrationsRoute($stateProvider) {
        $stateProvider.state('settingsintegrations', {
            url: "/settings/integrations?{page}",
            templateUrl: "/modules/integrations/views/index.f3baf643.html",
            controller: 'SettingsIntegrationsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Integrations',
            redirectKey: 'module_integrations',
            requires: [Gecko.ABILITY_INTEGRATIONS_VIEW],
            resolve: {
                integrations: function integrations($stateParams) {
                    return new Gecko.Integration().rfields({ integration: ['title', 'created_at', 'type', 'config'] }).where('type', GeckoUI.getIdArray(GeckoUI.getObjectArrayByKey(Gecko.Integration.type_titles, 'configurable', 1), 'id')).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                }
            }
        }).state('integration', {
            url: "/settings/integrations/{id}",
            templateUrl: "/modules/integrations/views/tabs.2a4454a7.html",
            controller: 'SettingsIntegrationTabsCtrl',
            controllerAs: 'tabsCtrl',
            resolve: {
                integration: function integration($stateParams) {
                    if ($stateParams.id == 'new') {
                        return new Gecko.Integration();
                    } else {
                        return new Gecko.Integration().rfields({ integration: ['title', 'account_id', 'config', 'created_at', 'deleted_at', 'event_auto_sync', 'event_external_id', 'attendee_guests_external_id', 'event_last_sync', 'field_mapping_warning', 'password', 'sync_attendances', 'sync_calls', 'sync_contacts', 'sync_notes', 'sync_responses', 'type', 'updated_at', 'url', 'username', 'address_book_id', 'structure'] }).get($stateParams.id);
                    }
                }
            }
        }).state('integration.fields', {
            url: "/fields?{page}&{contact_field}&{format}",
            templateUrl: "/modules/integrations/views/edit.a6afb637.html",
            controller: 'SettingsIntegrationCtrl',
            page_title: 'Integration',
            requires: [function ($stateParams) {
                if ($stateParams.id === 'new') return Gecko.ABILITY_INTEGRATIONS_CREATE;
                return Gecko.ABILITY_INTEGRATIONS_VIEW;
            }],
            resolve: {
                integration_fields: function integration_fields($stateParams) {
                    return new Gecko.IntegrationField().include('options').page($stateParams.page).where('integrationfield_contact_field_id', $stateParams.contact_field).where('integrationfield_format', $stateParams.format).perPage(GeckoUI.getPageLimit(this.self.name)).where('integration_id', $stateParams.id).rfields({ integrationfield: ['module', 'label', 'contact_field_id', 'external_id', 'format', 'options', 'type', 'can_push'] }).get();
                },
                contact_fields: function contact_fields() {
                    return new Gecko.Field().where('field_type', 'contact').perPage('all').rfields({ field: ['label', 'is_calculated', 'type'] }).getArray();
                }
            }

        }).state('integration.events', {
            url: "/events?{page}&{search}&{sort}&{order}",
            templateUrl: "/modules/integrations/views/events.adf135f7.html",
            controller: 'IntegrationEventsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Configuration',
            requires: [Gecko.ABILITY_IMPORTS],
            resolve: {
                integration: function integration($stateParams) {
                    return new Gecko.Integration().get($stateParams.id);
                },
                integration_events: function integration_events($stateParams) {
                    var _params = {
                        per_page: GeckoUI.getPageLimit(this.self.name)
                    };
                    _params.page = $stateParams.page || 1;
                    if ($stateParams.search) _params.keyword = $stateParams.search;
                    if ($stateParams.order) _params.order_by = $stateParams.order;
                    if ($stateParams.sort) _params.order_by += '|' + $stateParams.sort;

                    return new Gecko.BaseModel().call('/integrations/' + $stateParams.id + '/events', 'GET', _params, true);
                },
                events: function events() {
                    return new Gecko.Event().where('type', [Gecko.Event.TYPE_EVENT, Gecko.Event.TYPE_EVENT_LEGACY]).rfields({ event: ['title', 'start_datetime'] }).orderBy('created_at').perPage(10000).get();
                }
            }
        }).state('integration.consents', {
            url: "/consents",
            templateUrl: "/modules/integrations/views/consents.c76e6010.html",
            controller: 'IntegrationConsentsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Consent Configuration',
            requires: [Gecko.ABILITY_CONSENTS_VIEW],
            resolve: {
                data: function data($stateParams) {
                    var _query = {};
                    // Integration
                    _query.integration = new Gecko.Integration().path($stateParams.id).include('consent_import');
                    // Integration Fields
                    _query.integration_fields = new Gecko.IntegrationField()
                    //.include('options')
                    //.page($stateParams.page)
                    .where('no_contact_fields', 1)
                    //.where('integrationfield_format', $stateParams.format)
                    .perPage(1000).where('integration_id', $stateParams.id).rfields({ integrationfield: ['module', 'label', 'contact_field_id', 'external_id', 'format', 'options', 'consent_given_value', 'consent_withdrawn_value', 'consent_id'] });
                    // Consents
                    _query.consents = new Gecko.Consent().perPage('all');

                    return Gecko.multi(_query);
                },
                integration: function integration(data) {
                    return data.integration;
                },
                integration_fields: function integration_fields(data) {
                    return data.integration_fields;
                },
                consents: function consents(data) {
                    return data.consents;
                },
                consent_import: function consent_import(data, $stateParams) {
                    if (!data.integration.related_consent_import) {
                        return new Gecko.Import().fill({ integration_id: $stateParams.id, import_to: 'contacts', type: Gecko.Import.TYPE_CONSENT, field_sample: [] });
                    }

                    return data.integration.related_consent_import;
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(IntegrationsRoute);
})();