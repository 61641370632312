(function () {
    'use strict';

    function changeLogBadge() {

        return {
            restrict: 'EA',
            template: '<a href="" class="change-log-link" ng-class="{active:new_features}" ng-click="openModal()"><i ng-if="icon" class="fa fa-fw {{icon}}"></i> {{title}} <span class="badge pull-right" ng-if="new_changelog == true">1</span></a>',
            scope: {
                icon: '@',
                title: '@'
            },
            controller: function controller($scope, $rootScope, $timeout, $modal, changeLogService) {

                // Are there any new changelogs
                $scope.new_changelog = false;

                // Watch for new changelogs
                $rootScope.$watch('new_changelog', function (newVal, oldVal) {
                    $scope.new_changelog = newVal;
                });

                $scope.openModal = function () {
                    $modal.open({
                        size: 'lg',
                        templateUrl: '/components/change-log/change-log-modal.html',
                        controller: function controller(change_log, $scope) {
                            $scope.change_log = change_log.data;
                            $scope.change_log_pagination = change_log.pagination;

                            $scope.changePage = function (page) {
                                if (page) {
                                    return changeLogService.get({ perPage: 4, page: page }).then(function (data) {
                                        $scope.change_log = data.data;
                                        $scope.change_log_pagination = data.pagination;
                                    });
                                }
                            };
                        },
                        resolve: {
                            change_log: function change_log() {
                                return changeLogService.get({ perPage: 4 });
                            }
                        }
                    });

                    // Store this as the last time the user viewed the changelog
                    var epoch = moment(new Date().getTime()).format('X');

                    window.Gecko.user.setConfig('last_viewed_changelog', epoch);

                    // User has looked at the change log so remove the notification
                    $rootScope.new_changelog = false;
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('changeLogBadge', changeLogBadge);
})();