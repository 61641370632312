/*
 JQuery UI Sortable plugin wrapper

 @param [ui-sortable] {object} Options to pass to $.fn.sortable() merged onto ui.config
 */
angular.module('ui.sortable', []).value('uiSortableConfig', {}).directive('uiSortable', ['uiSortableConfig', '$timeout', '$log', function (uiSortableConfig, $timeout, $log) {
    return {
        require: '?ngModel',
        link: function link(scope, element, attrs, ngModel) {
            var savedNodes;

            function combineCallbacks(first, second) {
                if (second && typeof second === 'function') {
                    return function (e, ui) {
                        first(e, ui);
                        second(e, ui);
                    };
                }
                return first;
            }

            var opts = {};

            var callbacks = {
                receive: null,
                remove: null,
                start: null,
                stop: null,
                update: null
            };

            angular.extend(opts, uiSortableConfig);

            if (ngModel) {

                // When we add or remove elements, we need the sortable to 'refresh'
                // So it can find the new/removed elements.
                scope.$watch(attrs.ngModel + '.length', function () {
                    // Timeout to let ng-repeat modify the DOM
                    $timeout(function () {
                        element.sortable('refresh');
                    });
                });

                callbacks.start = function (e, ui) {
                    // Save the starting position of dragged item
                    ui.item.sortable = {
                        index: ui.item.index(),
                        cancel: function cancel() {
                            ui.item.sortable._isCanceled = true;
                        },
                        isCanceled: function isCanceled() {
                            return ui.item.sortable._isCanceled;
                        },
                        _isCanceled: false
                    };
                };

                callbacks.activate = function () /* E, ui */{
                    // We need to make a copy of the current element's contents so
                    // We can restore it after sortable has messed it up.
                    // This is inside activate (instead of start) in order to save
                    // Both lists when dragging between connected lists.
                    savedNodes = element.contents();

                    // If this list has a placeholder (the connected lists won't),
                    // Don't inlcude it in saved nodes.
                    var placeholder = element.sortable('option', 'placeholder');

                    // Placeholder.element will be a function if the placeholder, has
                    // Been created (placeholder will be an object).  If it hasn't
                    // Been created, either placeholder will be false if no
                    // Placeholder class was given or placeholder.element will be
                    // Undefined if a class was given (placeholder will be a string)
                    if (placeholder && placeholder.element && typeof placeholder.element === 'function') {
                        var phElement = placeholder.element();
                        // Workaround for jquery ui 1.9.x,
                        // Not returning jquery collection
                        if (!phElement.jquery) {
                            phElement = angular.element(phElement);
                        }

                        // Exact match with the placeholder's class attribute to handle
                        // The case that multiple connected sortables exist and
                        // The placehoilder option equals the class of sortable items
                        var excludes = element.find('[class="' + phElement.attr('class') + '"]');

                        savedNodes = savedNodes.not(excludes);
                    }
                };

                callbacks.update = function (e, ui) {
                    // Save current drop position but only if this is not a second
                    // Update that happens when moving between lists because then
                    // The value will be overwritten with the old value
                    if (!ui.item.sortable.received) {
                        ui.item.sortable.dropindex = ui.item.index();
                        ui.item.sortable.droptarget = ui.item.parent();

                        // Cancel the sort (let ng-repeat do the sort for us)
                        // Don't cancel if this is the received list because it has
                        // Already been canceled in the other list, and trying to cancel
                        // Here will mess up the DOM.
                        element.sortable('cancel');
                    }

                    // Put the nodes back exactly the way they started (this is very
                    // Important because ng-repeat uses comment elements to delineate
                    // The start and stop of repeat sections and sortable doesn't
                    // Respect their order (even if we cancel, the order of the
                    // Comments are still messed up).
                    if (element.sortable('option', 'helper') === 'clone') {
                        // Restore all the savedNodes except .ui-sortable-helper element
                        // (which is placed last). That way it will be garbage collected.
                        savedNodes = savedNodes.not(savedNodes.last());
                    }
                    savedNodes.appendTo(element);

                    // If received is true (an item was dropped in from another list)
                    // Then we add the new item to this list otherwise wait until the
                    // Stop event where we will know if it was a sort or item was
                    // Moved here from another list
                    if (ui.item.sortable.received && !ui.item.sortable.isCanceled()) {
                        scope.$apply(function () {
                            ngModel.$modelValue.splice(ui.item.sortable.dropindex, 0, ui.item.sortable.moved);
                        });
                    }
                };

                callbacks.stop = function (e, ui) {
                    // If the received flag hasn't be set on the item, this is a
                    // Normal sort, if dropindex is set, the item was moved, so move
                    // The items in the list.
                    if (!ui.item.sortable.received && 'dropindex' in ui.item.sortable && !ui.item.sortable.isCanceled()) {

                        scope.$apply(function () {
                            ngModel.$modelValue.splice(ui.item.sortable.dropindex, 0, ngModel.$modelValue.splice(ui.item.sortable.index, 1)[0]);
                        });
                    } else {
                        // If the item was not moved, then restore the elements
                        // So that the ngRepeat's comment are correct.
                        if ((!('dropindex' in ui.item.sortable) || ui.item.sortable.isCanceled()) && element.sortable('option', 'helper') !== 'clone') {
                            savedNodes.appendTo(element);
                        }
                    }
                };

                callbacks.receive = function (e, ui) {
                    // An item was dropped here from another list, set a flag on the
                    // Item.
                    ui.item.sortable.received = true;
                };

                callbacks.remove = function (e, ui) {
                    // Remove the item from this list's model and copy data into item,
                    // So the next list can retrive it
                    if (!ui.item.sortable.isCanceled()) {
                        scope.$apply(function () {
                            ui.item.sortable.moved = ngModel.$modelValue.splice(ui.item.sortable.index, 1)[0];
                        });
                    }
                };

                scope.$watch(attrs.uiSortable, function (newVal /* , oldVal */) {
                    angular.forEach(newVal, function (value, key) {
                        if (callbacks[key]) {
                            if (key === 'stop') {
                                // Call apply after stop
                                value = combineCallbacks(value, function () {
                                    scope.$apply();
                                });
                            }
                            // Wrap the callback
                            value = combineCallbacks(callbacks[key], value);
                        }
                        element.sortable('option', key, value);
                    });
                }, true);

                angular.forEach(callbacks, function (value, key) {
                    opts[key] = combineCallbacks(value, opts[key]);
                });
            } else {
                $log.info('ui.sortable: ngModel not provided!', element);
            }

            // Create sortable
            element.sortable(opts);
        }
    };
}]);