(function () {
    'use strict';

    function geckoTabs() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-tabs/gecko-tabs.html',
            scope: {
                tabs: '=tabs'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($rootScope, $scope, $state, $filter) {
                $scope.GeckoUI = GeckoUI;
                var ctrl = this;

                // Change tab
                ctrl.changeTab = function (tab) {
                    if (tab.disabledWhen && tab.disabledWhen()) return false;
                    if (tab.lockedWhen && tab.lockedWhen()) {
                        if (tab.lockedMessage) GeckoUI.messenger.error(tab.lockedMessage);
                        return false;
                    }
                    return $state.go(tab.state);
                };

                ctrl.checkAction = function (tab, $event) {
                    if (tab.disabledWhen && tab.disabledWhen()) {
                        $event.preventDefault();
                        return false;
                    }
                    if (tab.lockedWhen && tab.lockedWhen()) {
                        if (tab.lockedMessage) {
                            GeckoUI.messenger.error(tab.lockedMessage);
                            $event.preventDefault();
                        }
                        return false;
                    }
                };

                ctrl.buildState = function (tab) {
                    if (tab.state && tab.params) {
                        return tab.state + '(' + tab.params + ')';
                    }
                    return tab.state;
                };

                // Add active check to tabs and class func
                angular.forEach(ctrl.tabs, function (tab) {
                    // Fallback activeWhen (when not provided)
                    if (!tab.activeWhen) {
                        tab.activeWhen = function () {
                            return $state.$current.name == tab.state;
                        };
                    }
                    // Prep classes
                    tab.renderClass = function (index) {
                        var classes = {
                            active: tab.activeWhen()
                        };
                        return classes;
                    };

                    tab.stateCompiled = ctrl.buildState(tab);
                });

                // Tab classes
                ctrl.tabClasses = (ctrl.tabs || []).map(function (t, i) {
                    return GeckoUI.prepareIndexClass('gecko-tab', i) + ' ' + GeckoUI.prepareNameClass('gecko-tab', t.title);
                });
            }
        };
    }

    function geckoTabsHeader() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-tabs/gecko-tabs-header.html',
            transclude: true,
            scope: {
                tabs: '=tabs'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {},
            link: function link(scope, element, attrs, ctrl, transclude) {
                transclude(scope.$parent, function (clone) {
                    element.find('.transclude-gecko-tabs-header').append(clone);
                });
            }
        };
    }

    function geckoTabsContainer() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-tabs/gecko-tabs-container.html',
            transclude: true,
            scope: {},
            link: function link(scope, element, attrs, ctrl, transclude) {
                transclude(scope.$parent, function (clone) {
                    element.find('.transclude-gecko-tabs-container').append(clone);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoTabs', geckoTabs).directive('geckoTabsHeader', geckoTabsHeader).directive('geckoTabsContainer', geckoTabsContainer);
})();