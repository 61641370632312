(function () {
    'use strict';

    function geckoLandingPageBlockContainer($templateRequest, $compile, $landingPageBuilder, $geckoModal) {
        return {
            restrict: 'E',
            templateUrl: '/components/landing-page-blocks/views/block_container.html',
            scope: {
                landingPage: '=',
                blocks: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                $scope.Gecko = Gecko;
                var ctrl = this;

                // Build inline block styles (and apply z-index hack)
                ctrl.getStyles = function (block, index) {
                    var _styles = { 'z-index': ctrl.blocks.length - index };
                    // Block background image
                    if (block && block.background_image) {
                        _styles['background-image'] = 'url(' + encodeURI(block.background_image) + ')';
                    }
                    return _styles;
                };

                // Add block
                ctrl.addBlock = function (type, index) {
                    $geckoModal.editBlockModal('new', type).result.then(function (block) {
                        // Insert new block
                        ctrl.blocks.splice(index + 1, 0, block);
                        // Update block order
                        $landingPageBuilder.reorder(ctrl.blocks);
                        // Success message
                        GeckoUI.messenger.success('Landing page block saved');
                    });
                };

                // Edit block (open modal)
                ctrl.editBlock = function (block_id, type, index) {
                    $geckoModal.editBlockModal(block_id, type).result.then(function (block) {
                        // Update block
                        ctrl.blocks[index] = block;
                        // Success message
                        GeckoUI.messenger.success('Landing page block updated');
                    });
                };

                // Remove block
                ctrl.removeBlock = function (block_id, index) {
                    GeckoUI.dialog.confirm('Are you sure you want to remove this block?', function (ok) {
                        if (ok) {
                            new Gecko.Block().call('/blocks/' + block_id, 'DELETE').then(function () {
                                ctrl.blocks.splice(index, 1);
                                GeckoUI.messenger.success('Landing page block removed');
                                $scope.$digest();
                            }).then(function (err) {
                                GeckoUI.messenger.error(err.errors);
                            });
                        }
                    });
                };

                // Add block button
                ctrl.addBlockBtn = {
                    btnTooltip: 'Add',
                    btnIcon: 'fa-plus',
                    btnClass: 'btn-primary',
                    items: []
                };
                // Add types to add dropdown
                angular.forEach(Gecko.Block.type_titles, function (type) {
                    if ([Gecko.Block.TYPE_HEADER, Gecko.Block.TYPE_FOOTER, Gecko.Block.TYPE_EVENT, Gecko.Block.TYPE_GRID].indexOf(type.id) == -1) {
                        ctrl.addBlockBtn.items.push({
                            title: type.title,
                            action: function action(block, index) {
                                return ctrl.addBlock(type.id, index);
                            }
                        });
                    }
                });
            }
        };
    }

    function geckoLandingPageBlockTitle() {
        return {
            restrict: 'E',
            templateUrl: '/components/landing-page-blocks/views/block_container_title.html',
            scope: {
                block: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;
            }
        };
    }

    function geckoLandingPageBlockText() {
        return {
            restrict: 'E',
            templateUrl: '/components/landing-page-blocks/views/block_container_text.html',
            scope: {
                block: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;
            }
        };
    }

    function geckoLandingPageBlockMap() {
        return {
            restrict: 'E',
            templateUrl: '/components/landing-page-blocks/views/block_container_map.html',
            scope: {
                block: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;
            }
        };
    }

    function geckoLandingPageBlockSocial() {
        return {
            restrict: 'E',
            templateUrl: '/components/landing-page-blocks/views/block_container_social.html',
            scope: {
                landingPage: '=',
                block: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                // Social check (no param to check for all)
                ctrl.hasSocial = function (key) {
                    if (key == undefined) {
                        return ctrl.block.config && (ctrl.block.config.show_website && ctrl.landingPage.config.website_url || ctrl.block.config.show_facebook && ctrl.landingPage.config.facebook_url || ctrl.block.config.show_twitter && ctrl.landingPage.config.twitter_url || ctrl.block.config.show_linkedin && ctrl.landingPage.config.linkedin_url);
                    }
                    return ctrl.block.config && ctrl.block.config['show_' + key] && ctrl.landingPage.config[key + '_url'];
                };
            }
        };
    }

    function geckoLandingPageBlock($templateRequest, $compile) {

        var blockTemplates = {
            event: '/components/landing-page-blocks/views/block_event.html',
            form: '/components/landing-page-blocks/views/block_form.html',
            footer: '/components/landing-page-blocks/views/block_footer.html',
            header: '/components/landing-page-blocks/views/block_header.html',
            map: '/components/landing-page-blocks/views/block_map.html',
            text: '/components/landing-page-blocks/views/block_text.html'
        };

        return {
            restrict: 'E',
            scope: {
                landingPage: '=',
                block: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            compile: function compile() {
                return function (scope, iElement, iAttrs, ctrl) {

                    // Render template
                    var renderTemplate = function renderTemplate() {
                        var templateUrl = blockTemplates[ctrl.block.type];

                        $templateRequest(templateUrl, true).then(function (template) {
                            // Set the template as the content of the element.
                            iElement.html(template);
                            // Compile it amd link it to the isolated scope.
                            $compile(iElement.contents())(scope);
                        });
                    };
                    renderTemplate();

                    // Re-render if block changes
                    scope.$watch('ctrl.block', function (newVal, oldVal) {
                        // Watch for type change
                        if (newVal.type != oldVal.type) {
                            renderTemplate();
                        }
                    });
                };
            },
            controller: function controller($scope, $element) {
                $scope.Gecko = Gecko;
                var ctrl = this;

                // Image check
                ctrl.hasImage = function (key) {
                    return ctrl.block && ctrl.block[key];
                };

                // Render form
                var renderForm = function renderForm() {
                    var promise = void 0;
                    // Get form uuid
                    if (ctrl.block.form_id) {
                        if (ctrl.block.form_id && ctrl.block.related_form) {
                            promise = Promise.resolve().then(function () {
                                return ctrl.block.related_form.uuid || null;
                            });
                        } else {
                            promise = new Gecko.Form().rfields({ form: ['uuid'] }).get(ctrl.block.form_id).then(function (form) {
                                return form.uuid || null;
                            });
                        }
                    } else {
                        promise = Promise.resolve(null);
                    }

                    return promise.then(function (uuid) {
                        // Clear previous form display when form_id is unset
                        if (!uuid) {
                            return $element.find('.gecko-landing-page-block-form-container').html('');
                        }

                        var url = GeckoUI.getAppURL('app', '/gecko-embed/form.js', window.location.host.indexOf('stage') !== -1);
                        var element = document.createElement('script');
                        element.id = 'gecko-form-embed-script';
                        element.src = url + '?uuid=' + uuid;

                        $element.find('.gecko-landing-page-block-form-container').html(element);
                    }).catch(console.warn);
                };

                if (ctrl.block.type && (ctrl.block.form || ctrl.block.related_form)) renderForm();

                // Re-render if block changes
                $scope.$watch('ctrl.block', function (newVal, oldVal) {
                    // Watch for form change
                    if (newVal.form_id !== oldVal.form_id) {
                        renderForm();
                    }
                });
            }

        };
    }

    angular.module('GeckoEngage').directive('geckoLandingPageBlockContainer', geckoLandingPageBlockContainer).directive('geckoLandingPageBlockTitle', geckoLandingPageBlockTitle).directive('geckoLandingPageBlockText', geckoLandingPageBlockText).directive('geckoLandingPageBlockSocial', geckoLandingPageBlockSocial).directive('geckoLandingPageBlockMap', geckoLandingPageBlockMap).directive('geckoLandingPageBlock', geckoLandingPageBlock);
})();