(function () {
    'use strict';

    function geckoEventPickerService() {
        var _this = {

            // Function to check for event being legacy (or not)
            isLegacy: function isLegacy(options, event_id) {
                options = angular.copy(options);
                if (angular.isArray(options) && angular.isArray(options[0])) {
                    options = options[0].concat(options[1], options[2]);
                    // Get event
                    var event = options.find(function (opt) {
                        return opt.id === event_id;
                    });
                    // Is it legacy or not
                    return event && event.type && [Gecko.Event.TYPE_EVENT_LEGACY, Gecko.Event.TYPE_SESSION_LEGACY].indexOf(event.type) !== -1 ? true : false;
                }
                return false;
            },

            prepareField: function prepareField(field, disabledLevels) {
                // Add props 
                if (disabledLevels) field.disabledLevels = disabledLevels;
                field.allValues = [];
                field.foundEvents = [];
                field.onChange = function () {
                    return field.foundEvents = angular.copy(field.options);
                };
                field.choiceTemplate = 'event-choice';
                // Return altered field
                return field;
            },

            // 1 == top level event, 3 = session time
            sessionCheck: function sessionCheck(field, event_id) {
                if (!_this.isLegacy(field.foundEvents, event_id) && field.allValues) {
                    if (field.allValues.filter(function (id) {
                        return id;
                    }).length == 2) {
                        GeckoUI.messenger.error('Please select a session time.');
                        return false;
                    }
                    return true;
                }
                return true;
            }

        };

        return _this;
    }

    angular.module('GeckoEngage').service('geckoEventPickerService', geckoEventPickerService);
})();