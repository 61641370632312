(function () {
    'use strict';

    var presets = {
        save: {
            btnTitle: 'Save',
            iconLeft: 'fa-save',
            btnClass: 'btn-primary'
        },
        login: {
            btnTitle: 'Login',
            iconLeft: 'fa-sign-in',
            btnClass: 'btn-primary'
        },
        check: {
            iconLeft: 'fa-check',
            btnClass: 'btn-primary'
        },
        remove: {
            btnTitle: 'Remove',
            iconLeft: 'fa-trash-alt',
            btnClass: 'btn-danger'
        },
        add: {
            iconLeft: 'fa-plus',
            btnClass: 'btn-primary'
        },
        upload: {
            btnTitle: 'Upload',
            iconLeft: 'fa-upload',
            btnClass: 'btn-default'
        },
        view: {
            iconLeft: 'fa-eye',
            btnClass: 'btn-default'
        },
        close: {
            btnTitle: 'Close',
            iconLeft: 'fa-times',
            btnClass: 'btn-default'
        },
        next: {
            btnTitle: 'Next',
            iconLeft: 'fa-arrow-right',
            btnClass: 'btn-primary'
        },
        previous: {
            btnTitle: 'Previous',
            iconLeft: 'fa-arrow-left',
            btnClass: 'btn-default'
        }
    };

    function geckoBtn() {
        return {
            restrict: 'EA',
            template: '<button \n                            ng-click="ctrl.progress()"\n                            ng-disabled="ctrl.disabled || (ctrl.disabledWhen && ctrl.disabledWhen())"\n                            role="{{ctrl.submit?\'submit\':\'button\'}}"\n                            type="button"\n                            title="{{ctrl.btnTooltip || ctrl.btnTitle}}"\n                            class="{{ctrl.btnTooltip ? \'gecko-tooltip-enabled\': \'\'}}"\n                            ng-class="ctrl.btnClasses"\n                            data-placement="{{ (ctrl.btnTooltipPlacement || \'top\') }}">\n                                <i ng-if="ctrl.iconLeft" class="fa fa-fw {{ctrl.iconLeft}}"></i>\n                                <span ng-if="ctrl.btnTitle">{{ctrl.btnTitle}}</span>\n                                <i ng-if="ctrl.iconRight" class="fa {{ctrl.iconRight}}"></i>\n                        </button>',
            scope: {
                btnType: '@',
                safeMode: '@',
                btnTitle: '@',
                disabledWhen: '=',
                icon: '@',
                iconLeft: '@',
                iconRight: '@',
                btnClass: '@',
                btnSize: '@',
                btnTooltip: '@',
                btnTooltipPlacement: '@',
                preset: '@',
                action: '&',
                requires: '=',
                submit: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $attrs, $element, $timeout) {
                var ctrl = this,
                    _btnClasses = ['gecko-btn-icon-only', 'gecko-btn', 'gecko-ui-material', 'btn'];

                var checkPermissions = function checkPermissions() {
                    if (!ctrl.requires) {
                        return true;
                    }
                    var requires = typeof ctrl.requires === 'function' ? ctrl.requires() : ctrl.requires;
                    return requires.reduce(function (result, ability) {
                        if (!Gecko.able(ability) && result !== false) {
                            GeckoUI.messenger.error(Gecko.unableReason(ability));
                            result = false;
                        }
                        return result;
                    }, true);
                };

                var callAction = function callAction() {
                    // Check the users permissions
                    if (!checkPermissions()) {
                        return false;
                    }
                    var action = ctrl.action();

                    if (typeof action === 'function') {
                        action();
                    }
                };

                // Prevent double clicks
                if (ctrl.safeMode) {
                    ctrl.disabled = false;
                    ctrl.progress = function () {
                        if (ctrl.action && !ctrl.disabled) {
                            callAction();
                        }
                        ctrl.disabled = true;
                        $timeout(function () {
                            ctrl.disabled = false;
                        }, 1000);
                    };
                } else {
                    ctrl.progress = function () {
                        if (ctrl.action && !ctrl.disabled && !(ctrl.disabledWhen && ctrl.disabledWhen())) {
                            callAction();
                        }
                        return true;
                    };
                }

                // All the default classes for the button
                ctrl.btnClasses = _btnClasses;

                // Observing the btnTitle to set this if a button title is lazy about loading
                $attrs.$observe('btnTitle', function () {
                    // If there is no title we want to set it as icon only
                    if (ctrl.btnTitle && ctrl.btnClasses.indexOf('gecko-btn-icon-only') !== -1) {
                        ctrl.btnClasses.splice(ctrl.btnClasses.indexOf('gecko-btn-icon-only'), 1);
                    }
                });

                var buildBtn = function buildBtn() {

                    // If this has icon and no iconLeft, its an iconLeft
                    if (ctrl.icon && !ctrl.iconLeft) {
                        ctrl.iconLeft = ctrl.icon;
                    }

                    // If we have a btn class set, pass it through
                    if (ctrl.btnClass) {
                        ctrl.btnClasses.push(ctrl.btnClass);

                        // Otherwise lets add default
                    } else {
                        ctrl.btnClasses.push('btn-default');
                    }

                    // If there is a size set we want to add it too
                    if (ctrl.btnSize) {
                        ctrl.btnClasses.push('btn-' + ctrl.btnSize);
                    }

                    // Button type class
                    if (ctrl.btnType) {
                        if (ctrl.btnType == 'circle') ctrl.btnClasses.push('gecko-btn-circle');
                        ctrl.btnClasses.splice(ctrl.btnClasses.indexOf('gecko-btn-icon-only'), 1);
                    }

                    // Tidy class array
                    if (ctrl.btnClass && ctrl.btnClasses.indexOf('btn-default') !== -1 && ctrl.btnClass.indexOf('btn') !== -1 && ctrl.btnClass.indexOf('btn-default') == -1) {
                        ctrl.btnClasses.splice(ctrl.btnClasses.indexOf('btn-default'), 1);
                    }

                    if (ctrl.btnTitle && ctrl.btnClasses.indexOf('gecko-btn-icon-only') !== -1) {
                        ctrl.btnClasses.splice(ctrl.btnClasses.indexOf('gecko-btn-icon-only'), 1);
                    }

                    // Add button name class (for automation)
                    if (ctrl.btnTitle) {
                        ctrl.btnClasses.push(GeckoUI.prepareNameClass('gecko-btn', ctrl.btnTitle));
                    } else if (!ctrl.btnTitle && (ctrl.iconLeft || ctrl.iconRight)) {
                        ctrl.btnClasses.push(GeckoUI.prepareNameClass('gecko-btn', ctrl.iconLeft || ctrl.iconRight));
                    }
                };
                buildBtn();

                // Watch for class changes
                $scope.$watch('ctrl.btnClass', function (newVal, oldVal) {
                    if (newVal != oldVal) {
                        ctrl.btnClasses = _btnClasses;
                        var _ctrl = angular.copy(ctrl);
                        for (var prop in _ctrl) {
                            if (!_ctrl[prop]) delete _ctrl[prop];
                        }
                        ctrl = angular.extend(ctrl, _ctrl);
                        buildBtn();
                    }
                }, true);

                // Load preset
                if (ctrl.preset && presets[ctrl.preset]) {
                    $scope.$watch('ctrl.preset', function () {
                        var _ctrl = angular.copy(ctrl);
                        for (var prop in _ctrl) {
                            if (!_ctrl[prop]) delete _ctrl[prop];
                        }
                        ctrl = angular.extend(ctrl, presets[ctrl.preset], _ctrl);
                        buildBtn();
                    }, true);
                }
            }
        };
    }

    function geckoLabel() {
        return {
            restrict: 'EA',
            template: '<span class="gecko-label label" ng-class="ctrl.getClasses()" ng-style="ctrl.getColor(ctrl.labelColor)">\n                            <span ng-if="ctrl.labelTitle">{{ctrl.labelTitle}}</span>\n                        </span>',
            scope: {
                labelTitle: '@',
                labelClass: '@',
                labelColor: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;
                ctrl.getLabel = function (label) {
                    if (label) {
                        return 'label-' + label.replace('label-', '');
                    }
                    return '';
                };
                ctrl.getClasses = function () {
                    var classes = [];

                    if (ctrl.getLabel(ctrl.labelClass)) {
                        classes.push(ctrl.getLabel(ctrl.labelClass));
                    } else {
                        classes.push('label-default');
                    }

                    return classes;
                };
                ctrl.getColor = function (color) {
                    var response = {};
                    if (color && color.indexOf('#') > -1) {
                        response.background = color;
                        response.color = GeckoUI.getContrastColor(color);
                    }
                    return response;
                };
            }
        };
    }

    function geckoStatus() {
        return {
            restrict: 'EA',
            template: '<span class="gecko-status">\n                            <span \n                                title="{{ctrl.labelTooltip || ctrl.labelTitle}}"\n                                class="label {{ctrl.labelTooltip ? \'gecko-tooltip-enabled\': \'\'}}"\n                                ng-class="ctrl.getClasses()"\n                                data-placement="top"\n                                ng-style="ctrl.getStyle(ctrl.labelTitle)">\n                                    &nbsp;\n                            </span>\n                            <span ng-if="ctrl.labelTitle">\n                                {{ctrl.labelTitle}}\n                            </span>\n                        </span>',
            scope: {
                labelTitle: '@',
                labelClass: '@',
                labelColor: '@',
                labelTooltip: '@'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                ctrl.getLabel = function (label) {
                    if (label) {
                        return 'label-' + label.replace('label-', '');
                    }
                    return '';
                };
                ctrl.getClasses = function () {
                    var classes = [];

                    if (ctrl.getLabel(ctrl.labelClass)) {
                        classes.push(ctrl.getLabel(ctrl.labelClass));
                    } else {
                        classes.push('label-default');
                    }

                    return classes;
                };
                ctrl.getStyle = function (color) {
                    var style = {};

                    if (color && color.indexOf('#') != -1) {
                        style.background = ctrl.labelColor;
                    }

                    if (!ctrl.labelTitle) {
                        style.marginRight = 0;
                    }

                    return style;
                };
            }
        };
    }

    // This is a work around due to using an outdated version of UI-Bootstrap
    function geckoTooltipEnabled($timeout) {
        return {
            restrict: 'C',
            scope: {},
            link: function link(scope, element, attrs) {

                // What for title changes
                attrs.$observe('title', function () {
                    // Destroy if title is changing
                    if (element.hasClass('gecko-tooltip-initalised')) element.tooltip('destroy');
                    // Apply tooltip
                    $timeout(function () {
                        if (attrs.class.indexOf('gecko-tooltip-enabled') !== -1) {
                            element.addClass('gecko-tooltip-initalised').tooltip();
                        }
                    }, 300);
                });

                // Tidy up
                element.on('$destroy', function () {
                    if (attrs.class.indexOf('gecko-tooltip-enabled') !== -1) {
                        element.tooltip('destroy');
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoTooltipEnabled', geckoTooltipEnabled).directive('geckoBtn', geckoBtn).directive('geckoLabel', geckoLabel).directive('geckoStatus', geckoStatus);
})();