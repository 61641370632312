;(function () {
    'use strict';

    var ENV_LOCAL = 'local';
    var ENV_STAGE = 'stage';
    var ENV_PROD = 'prod';

    function getEnvParamFromUrl() {
        return function (url) {
            var vars = {};
            var parts = (url || '').replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                vars[key] = value;
            });
            return vars['env'];
        };
    }

    function env(getEnvParamFromUrl) {
        return function () {
            if (window) {
                // Overwrite env in query string
                var envParamFromUrl = getEnvParamFromUrl(window.location.href);
                if (envParamFromUrl && [ENV_PROD, ENV_STAGE, ENV_LOCAL].includes(envParamFromUrl)) return envParamFromUrl;

                // Determine env based on url
                var src = window.location.host;
                if (src.includes('app-stage.') && src.includes('.com')) {
                    return ENV_STAGE;
                } else if (src.includes('localhost:')) {
                    return ENV_LOCAL;
                }
            }
            return ENV_PROD;
        };
    }

    angular.module('GeckoEngage').factory('getEnvParamFromUrl', getEnvParamFromUrl).factory('env', env);
})();