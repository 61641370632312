(function () {
    'use strict';

    function ResponsesListCtrl($scope, $state, $stateParams, responseQuery, responses, integrations, $geckoModal, $location, $filter, geckoTableFilterService, handleMassAction) {
        $scope.integrations = integrations;
        $scope.responsesToDelete = [];

        $scope.selectOptions = {
            noPersist: true
            /*SelectQuery: responseQuery,
            initialTotalOptions: responses.pagination.total_items*/
        };

        $scope.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date created',
            key: 'created_at',
            sortKey: 'created_at'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Contact',
            key: 'related_contact.full_name',
            colClass: 'col-xs-3'
        }, {
            title: $stateParams.form_type == 'call' ? 'Script' : 'Form',
            key: 'related_form.name',
            render: function render(value, col, row) {
                if (row.related_form && row.related_form.internal_name) return row.related_form.internal_name;
                return value;
            },
            colClass: 'col-xs-3'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Assigned to',
            key: 'related_assigned.full_name',
            colClass: 'col-xs-3',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_RESPONSES_ASSIGN_TO_COLLEAGUES);
            }
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'Captured by',
            key: 'related_user.full_name',
            colClass: 'col-xs-3'
        }, {
            type: Gecko.Field.TYPE_LABEL,
            title: 'Labels',
            key: 'related_labels'
        }];

        $scope.responsesSelects = [];

        $scope.rowAction = {
            action: function action(response) {
                if (response.contact_id) {
                    $state.go('contact.response', {
                        contact_id: response.contact_id,
                        response_id: response.id
                    });
                } else {
                    $state.go('responseview', {
                        response_id: response.id
                    });
                }
            }
        };

        $scope.filters = [{
            title: 'Form',
            type: 'dropdown',
            optionsQuery: new Gecko.Form().rfields({ form: ['name', 'internal_name'] }).orderBy('internal_name'),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            choiceTemplate: 'form-choice',
            matchTemplate: 'form-match',
            stateParam: 'form'
        }, {
            title: 'Date',
            type: 'daterange',
            filterMap: {
                dateFrom: 'from',
                dateTo: 'to'
            },
            stateParam: 'from'
        }, {
            title: 'Label',
            type: 'multi',
            extras: ['operator'],
            operatorTitle: 'Responses with:',
            optionsQuery: new Gecko.Label().rfields({ label: ['name'] }).orderBy('name'),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            filterMap: {
                default: 'label',
                operator: 'operator'
            }
        }, {
            title: 'Saved Filters',
            type: 'dropdown',
            options: [{
                label: 'Assigned to me',
                value: 'assigned'
            }, {
                label: 'Captured by me',
                value: 'captured'
            }],
            stateParam: 'filter'
        }];

        // "Captured by" Table Filter
        if (Gecko.able(Gecko.ABILITY_USERS_VIEW)) {
            $scope.filters.push({
                title: 'Captured by',
                type: 'dropdown',
                optionsQuery: new Gecko.User().rfields({ user: ['full_name'] }).orderBy('full_name'),
                optionsKey: 'id',
                optionsLabelKey: 'full_name',
                stateParam: 'captured_by'
            });
        }
        // "Assigned user" Table Filter
        if (Gecko.able(Gecko.ABILITY_USERS_VIEW)) {
            $scope.filters.push({
                title: 'Assigned user',
                type: 'dropdown',
                optionsQuery: new Gecko.User().rfields({ user: ['full_name'] }).orderBy('full_name'),
                optionsKey: 'id',
                optionsLabelKey: 'full_name',
                stateParam: 'assigned_to'
            });
        }
        // "Event captured at" Table Filter
        if (Gecko.able(Gecko.ABILITY_EVENTS_VIEW)) {
            $scope.filters.push({
                title: 'Event captured at',
                type: 'event',
                stateParam: 'event'
            });
        }
        // "Import" Table Filter
        if (Gecko.able(Gecko.ABILITY_IMPORTS)) {
            $scope.filters.push({
                title: 'Import',
                type: 'dropdown',
                optionsQuery: new Gecko.Import().rfields({ import: ['title'] }).orderBy('title'),
                optionsKey: 'id',
                optionsLabelKey: 'title',
                stateParam: 'import_id'
            });
        }

        // Sync Filter
        $scope.filters.push(geckoTableFilterService.prepareSyncOptions($scope.integrations));

        // Delete response
        $scope.removeResponses = function () {
            if (!$scope.responsesSelects.length) return false;
            GeckoUI.dialog.confirm('Are you sure you want to remove the selected responses?', function (ok) {
                if (ok) {
                    new Gecko.Response().massAction({ response_ids: $scope.responsesSelects }, { type: 'delete' }).then(function (data) {
                        // Handle mass actions
                        handleMassAction(data, $state.$current.name);
                        // Reset response array
                        $scope.responsesSelects.length = 0;
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors[0]);
                    });
                }
            });
        };

        // Add a response
        $scope.addResponse = function () {
            // Create permissions
            if (!Gecko.able(Gecko.ABILITY_RESPONSES_CREATE)) {
                return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_RESPONSES_CREATE));
            }
            $geckoModal.selectorModal({
                title: 'Select a Form',
                model: 'form',
                optionsKey: 'uuid',
                optionsLabel: 'name'
            }).result.then(function (uuid) {
                $state.go('responseadd', { uuid: uuid });
            });
        };

        $scope.responsesToDeleteCheck = function () {
            for (var i = 0; i < $scope.responsesToDelete.length; i++) {
                if ($scope.responsesToDelete[i]) {
                    return true;
                }
            }
            return false;
        };

        // Get the responses
        $scope.responses = responses.toInstances();
        $scope.pagination = responses.pagination;

        // Contact Label filter
        $scope.responseLabel = $stateParams.label ? angular.isArray($stateParams.label) ? $stateParams.label : [$stateParams.label] : ['all'];

        var params = $location.search();

        if (params.contact_id) {
            $scope.filter.contact_id = params.contact_id;
        }

        // Header dropdown
        $scope.optionsBtn = {
            iconRight: 'fa-cogs',
            btnTooltip: 'Actions'
        };
        $scope.optionsBtn.items = [{
            title: 'Import Responses',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_RESPONSES_BULK_IMPORT)) {
                    return;
                }
                return $state.go('settingsimportfile', { import_to: 'responses' });
            }
        }, {
            title: 'Export Responses',
            action: function action() {
                return $state.go('exportnew', { export_id: 'new', responses: true });
            }
        }];

        $scope.actionsBtn = {
            title: 'Actions on selected',
            icon: 'fa-cogs',
            hideWhen: function hideWhen() {
                return !$scope.responsesSelects.length;
            }
        };
        $scope.actionsBtn.items = [{
            title: 'Add label(s)',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_RESPONSES_UPDATE) || !GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_UPDATE)) return;

                return $geckoModal.labelModal($scope.responsesSelects, 'Response').result.then(function (data) {
                    // Handle the mass action
                    handleMassAction(data, $state.$current.name);
                    // Reset contact array
                    $scope.responsesSelects.length = 0;
                });
            }
        }, {
            title: 'Response Captured by',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_RESPONSES_UPDATE)) return;
                return $geckoModal.userModal({ title: 'captured by', description: 'Add/ edit the Gecko user logged as capturing the selected response(s).' }).result.then(function (user_id) {
                    new Gecko.Response().massAction({ model: 'response_ids', response_ids: $scope.responsesSelects }, Gecko.Workflow.Actions.attribute('user_id', user_id)).then(function (data) {
                        // Handle the mass action
                        handleMassAction(data, $state.$current.name);
                        // Reset response array
                        $scope.responsesSelects.length = 0;
                    }).catch(GeckoUI.messenger.error);
                });
            }
        }, {
            title: 'Response Assigned to',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_RESPONSES_UPDATE)) return;
                return $geckoModal.userModal({ title: 'assigned to', description: 'Add/ edit the Gecko user who is assigned to selected response(s).' }).result.then(function (user_id) {
                    new Gecko.Response().massAction({ model: 'response_ids', response_ids: $scope.responsesSelects }, Gecko.Workflow.Actions.assignUser(user_id)).then(function (data) {
                        // Handle the mass action
                        handleMassAction(data, $state.$current.name);
                        // Reset response array
                        $scope.responsesSelects.length = 0;
                    }).catch(GeckoUI.messenger.error);
                });
            }
        }, {
            title: 'Update event captured at',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_RESPONSES_UPDATE) && !GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_UPDATE)) return;
                return $geckoModal.eventAddToModal().result.then(function (event_id) {
                    new Gecko.Response().massAction({ model: 'response_ids', response_ids: $scope.responsesSelects }, Gecko.Workflow.Actions.attribute('event_id', event_id)).then(function (data) {
                        // Handle the mass action
                        handleMassAction(data);
                        // Reset response array
                        $scope.responsesSelects.length = 0;
                    }).catch(GeckoUI.messenger.error);
                });
            }
        }, {
            title: 'Sync responses',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_RESPONSES_UPDATE) && !GeckoUI.ableWithReason(Gecko.ABILITY_EVENTS_UPDATE)) return;
                // TODO: SHOW selectorModal of integrations so the user can select which integration they want to sync to
                new Gecko.Integration().where('sync_responses', 1).rfields({ integration: ['id', 'type', 'title_with_date'] }).perPage(1).get().then(function (data) {
                    if (data.pagination.total_items > 1) {
                        return $geckoModal.selectorModal({
                            title: 'Select an Integration',
                            model: 'integration',
                            fieldLabel: 'Integration',
                            optionsKey: 'id',
                            optionsLabel: 'title_with_date',
                            wheres: {
                                key: 'sync_responses',
                                value: 1
                            },
                            rfields: ['title']
                        }).result.then(function (integration_id) {
                            resyncResponses(integration_id);
                        });
                    } else {
                        if (Array.isArray(data.data) && data.data.length) {
                            resyncResponses(data.data[0].id);
                        } else {
                            resyncResponses();
                        }
                    }
                });
            }
        }, {
            title: 'Remove responses',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_RESPONSES_DELETE)) return;
                return $scope.removeResponses();
            }
        }];

        function resyncResponses(int_id) {
            var conditions;
            if ($scope.responsesSelects.length) {
                conditions = { model: 'response_ids', response_ids: $scope.responsesSelects };
            } else {
                conditions = angular.extend({ model: 'response' }, responseQuery().params);
            }
            new Gecko.Response().massAction(conditions, Gecko.Workflow.Actions.resync(int_id)).then(function (data) {
                // Handle the mass action
                handleMassAction(data);
                // Reset response array
                $scope.responsesSelects.length = 0;
            }).catch(GeckoUI.messenger.error);
        }

        // Breadcrumbs
        $scope.breadcrumbs = [{
            label: 'Responses',
            click: function click() {
                return $state.go('responses');
            }
        }, {
            label: 'List',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('ResponsesListCtrl', ResponsesListCtrl);
})();