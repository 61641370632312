(function () {
    'use strict';

    function startOfDay() {
        return {
            restrict: 'A',
            priority: 1,

            require: 'ngModel',

            link: function link(scope, iElement, iAttrs, ctrl) {

                ctrl.$parsers.push(function (value) {

                    if (value == null || value === '') return undefined;

                    var date;
                    if (angular.isDefined(iAttrs.utc)) date = moment.utc(value, 'X');else date = moment(value, 'X');

                    date.startOf('day');

                    return date.format('X');
                });
            }
        };
    }

    function endOfDay() {
        return {
            restrict: 'A',
            priority: 1,

            require: 'ngModel',

            link: function link(scope, iElement, iAttrs, ctrl) {

                ctrl.$parsers.push(function (value) {

                    if (value == null || value === '') return undefined;

                    var date;
                    if (angular.isDefined(iAttrs.utc)) date = moment.utc(value, 'X');else date = moment(value, 'X');

                    date.endOf('day');

                    return date.format('X');
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('startOfDay', startOfDay).directive('endOfDay', endOfDay);
})();