(function () {
    'use strict';

    function geckoDateTime() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-datetime/gecko-datetime.html',
            scope: {
                utc: '=?',
                interval: '=',
                labeled: '='
            },
            require: "?ngModel",
            controllerAs: 'ctrl',
            // BindToController: true, // doesn't work with require.
            link: function link(scope, iElem, iAttrs, ctrl) {
                if (!ctrl) return;

                scope.ctrl = ctrl;

                var parseDate = function parseDate(timestamp) {
                    return scope.utc ? moment.utc(timestamp, 'X') : moment(timestamp, 'X');
                };

                ctrl.open = function () {
                    return iElem.find('[b-datepicker]').focus();
                };

                ctrl.dateChange = function () {

                    var date = parseDate(ctrl.$viewValue);

                    if (!ctrl.date) {
                        ctrl.$setViewValue(null);
                        return null;
                    }

                    var chosenDate = parseDate(ctrl.date); // Parse date that is picked.
                    var dateValues = {
                        year: chosenDate.year(),
                        month: chosenDate.month(),
                        date: chosenDate.date()
                    };

                    if (date.isValid()) {
                        date.set(dateValues); // Update date values if valid
                    } else {
                        date = scope.utc ? moment.utc(dateValues) : moment(dateValues); // Create a valid moment from scratch (updating an invalid moment results in an invalid moment)
                    }

                    ctrl.$setViewValue(Number(date.format('X')));
                };

                ctrl.$render = function () {
                    ctrl.date = ctrl.$viewValue;
                };

                Object.defineProperty(ctrl, 'time', {
                    get: function get() {
                        return ctrl.$viewValue;
                    },
                    set: function set(value) {
                        ctrl.$setViewValue(value);
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoDateTime', geckoDateTime);
})();