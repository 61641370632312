(function () {

    function ListViewFieldsModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('listViewFieldModal', function (_type) {

            var _field_rfields = { field: ['label', 'field_type'] };

            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/list-view-fields/list-view-fields-modal.html',
                controller: 'listViewFieldModal',
                size: 'lg',
                resolve: {
                    type: function type() {
                        return !_type ? Gecko.Field.FIELD_TYPE_CONTACT : _type;
                    },
                    types: function types() {
                        var types;
                        _type = !_type ? Gecko.Field.FIELD_TYPE_CONTACT : _type;

                        if (Gecko.has(Gecko.Package.FEATURE_ORGANISATION) && _type == Gecko.Field.FIELD_TYPE_CONTACT) {
                            types = [Gecko.Field.FIELD_TYPE_ORGANISATION, Gecko.Field.FIELD_TYPE_CONTACT];
                        } else {
                            types = _type;
                        }
                        return types;
                    },
                    field_rfields: function field_rfields() {
                        return _field_rfields;
                    },
                    fieldList: function fieldList() {
                        _type = !_type ? Gecko.Field.FIELD_TYPE_CONTACT : _type;
                        return new Gecko.Field().rfields(_field_rfields).listView(false, _type);
                    },
                    sortField: function sortField() {
                        return new Gecko.Field().rfields(_field_rfields).get(Gecko.account[_type + '_sort_field_id']);
                    }
                }
            };
        });
    }

    function listViewFieldModal($state, $stateParams, $modalInstance, asyncOptions, type, types, fieldList, field_rfields, sortField, $filter, handleMassAction) {
        var ctrl = this;
        ctrl.type = type;
        ctrl.types = types;
        // Ctrl.fieldOptions           = fieldOptions.toInstances();

        // Set values and ordering
        ctrl.values = {};
        ctrl.values[ctrl.type + '_sort_field_id'] = Gecko.account[ctrl.type + '_sort_field_id'];
        ctrl.values[ctrl.type + '_sort_direction'] = Gecko.account[ctrl.type + '_sort_direction'];

        // Filter field selects
        fieldList = fieldList.toArray();
        // Build placeholder array
        var i;
        if (!fieldList.length) {
            fieldList = [];
            for (i = 1; i < Gecko.DEFAULT_LIST_FIELD_TOTAL + 1; i++) {
                fieldList.push(null);
            }
        }

        angular.forEach(fieldList, function (value, key) {
            ctrl.values['field_' + (key + 1)] = value.id;
        });

        ctrl.fields = [{
            label: 'Fields to display',
            type: Gecko.Field.TYPE_TITLE,
            description: 'Customise your ' + type + ' list by choosing which information to display at a glance. These appear left-to-right Field 1-6.'
        }];

        // Add listview fields
        for (var i = 1; i <= Gecko.DEFAULT_LIST_FIELD_TOTAL; i++) {
            ctrl.fields.push({
                id: 'field_' + i,
                label: 'Field ' + i,
                options: [fieldList[i - 1]],
                getOptions: asyncOptions.create(new Gecko.Field().rfields(field_rfields).where('field_type', types).orderBy('label')),
                optionsKey: 'id',
                optionsLabelKey: 'label',
                colClass: 'col-xs-4',
                type: Gecko.Field.TYPE_SELECT,
                optionsGroup: function optionsGroup(option) {
                    if (!option) return;
                    if (option.field_type == 'contact' && !option.is_calculated) {
                        return 'Contact Fields';
                    } else if (option.field_type == 'organisation' && !option.is_calculated) {
                        return 'Organisation Fields';
                    } else {
                        return 'System Fields';
                    }
                },
                noBlank: i < 3 // No blank for first 2 inputs
            });
        }

        // Fields array
        ctrl.fields = ctrl.fields.concat([{
            label: 'Sorting',
            type: Gecko.Field.TYPE_TITLE,
            description: 'In this section you can choose the default field to sort results by, or opt to display results in either ascending (A-Z) or descending (Z-A) order.'
        }, {
            id: ctrl.type + '_sort_field_id',
            label: 'Sort Field',
            colClass: 'col-md-6',
            type: Gecko.Field.TYPE_SELECT,
            options: [sortField.toObject()],
            getOptions: asyncOptions.create(new Gecko.Field().rfields(field_rfields).where('field_type', types).orderBy('label')),
            optionsKey: 'id',
            optionsLabelKey: 'label',
            optionsGroup: function optionsGroup(option) {
                return !option.is_calculated ? 'Contact Fields' : 'System Fields';
            },
            noBlank: true
        }, {
            id: ctrl.type + '_sort_direction',
            label: 'Sort Direction',
            colClass: 'col-md-6',
            type: Gecko.Field.TYPE_SELECT,
            options: [{
                key: 'ASC',
                label: 'Ascending'
            }, {
                key: 'DESC',
                label: 'Descending'
            }],
            optionsKey: 'key',
            optionsLabelKey: 'label',
            noBlank: true
        }, {
            label: 'Contact Matching',
            type: Gecko.Field.TYPE_TITLE,
            hideWhen: function hideWhen() {
                return !Gecko.User.isGroup(Gecko.Group.SUPER);
            }
        }, {
            id: 'contact_matching_requirement',
            label: 'Matching rules',
            colClass: 'col-md-12',
            type: Gecko.Field.TYPE_SELECT,
            options: [{
                id: 'ANY',
                label: 'Any'
            }, {
                id: 'ALL',
                label: 'All'
            }],
            optionsKey: 'id',
            optionsLabelKey: 'label',
            obj: Gecko.account,
            description: 'Setting fields to be Matchable allows responses to be merged based on matching rules e.g if the field “Email” is set to Matchable then any responses with the same email will be assigned to a single Contact. Selecting *All* means that all Matchable fields must be identical for merging to occur, whilst *Any* will merge Contacts if any single matching field is identical.',
            hideWhen: function hideWhen() {
                return !Gecko.User.isGroup(Gecko.Group.SUPER);
            }
        }]);

        // Modal buttons
        ctrl.footerBtns = [{
            title: 'Save',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            action: function action() {

                // Build sort options
                var updatedSortOptions = {};
                updatedSortOptions[ctrl.type + '_sort_field_id'] = ctrl.values[ctrl.type + '_sort_field_id'];
                updatedSortOptions[ctrl.type + '_sort_direction'] = ctrl.values[ctrl.type + '_sort_direction'];

                // Build field array
                var updated_fields = [];
                for (var i = 1; i < Gecko.DEFAULT_LIST_FIELD_TOTAL + 1; i++) {
                    if (ctrl.values['field_' + i]) updated_fields.push(ctrl.values['field_' + i]);
                }

                // Check for at least 2 fields
                if (updated_fields.length < 2) {
                    return GeckoUI.messenger.error('You must select at least 2 contact fields.');
                }

                // Save list view fields
                new Gecko.Field().listView(updated_fields.splice(0, Gecko.DEFAULT_LIST_FIELD_TOTAL), ctrl.type, updatedSortOptions).then(function (data) {
                    // Organisation
                    if (ctrl.type === Gecko.Field.FIELD_TYPE_ORGANISATION) {
                        // Success message
                        GeckoUI.messenger.success($filter('capitalize')(ctrl.type) + ' field options updated');
                        // Reload list
                        $state.go($state.$current.name, $stateParams, { reload: true });
                    }

                    // Close modal
                    $modalInstance.dismiss();
                    // Refresh and reload
                    Gecko.account.refresh();

                    handleMassAction(data, $state.$current.name);
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(ListViewFieldsModals).controller('listViewFieldModal', listViewFieldModal);
})();