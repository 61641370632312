(function () {
    'use strict';

    function panelOptions() {
        return {
            restrict: 'EA',
            template: '\n            \t<div ng-if="ctrl.field.options && ctrl.field.options.length">            \t\t\n            \t\t<panel-option\n            \t\t\tng-repeat="option in ctrl.field.options track by $index"\n                        ng-if="!option.hideWhen()"\n                        field="ctrl.field"\n            \t\t\toption="option"\n            \t\t\tkey="ctrl.field.id"\n            \t\t\tvalue="ctrl.value">\n            \t\t</panel-option>\n            \t</div>\n            ',
            scope: {
                field: '=',
                value: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                // Defaults
                if (ctrl.field === undefined) ctrl.field = {};
                if (ctrl.field.id === undefined) ctrl.field.id = '';
                if (ctrl.value === undefined) ctrl.value = '';
                if (ctrl.field.options === undefined) ctrl.field.options = [];
            }
        };
    }

    function panelOption() {
        return {
            restrict: 'EA',
            template: '\n            \t<div \n                    class="panel-option gecko-ui-material" \n                    ng-class="ctrl.renderClasses()" \n                    ng-disabled="ctrl.disabledWhen()" \n                    ng-click="ctrl.handler()"\n                    tooltip="{{ctrl.option.helpText}}"\n                    tooltip-trigger="mouseenter"\n                    tooltip-placement="bottom"\n                    tooltip-is-open="true"\n                    >\n                \t\t<span class="panel-option-icon" ng-if="::(ctrl.option.icon)">\n                \t\t\t<i class="fa fa-fw" ng-class="::(ctrl.option.icon)"></i>\n                \t\t</span>\n                \t\t<span class="panel-option-title" ng-if="::(ctrl.option.title)">\n                \t\t\t{{ ::(ctrl.option.title) }}\n                \t\t</span>\n            \t</div>\n            ',
            scope: {
                key: '=',
                field: '=',
                value: '=',
                option: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                // Defaults
                if (ctrl.field === undefined) ctrl.field = {};
                if (ctrl.key === undefined) ctrl.key = '';
                if (ctrl.value === undefined) ctrl.value = null;
                if (ctrl.option === undefined) ctrl.option = {};

                ctrl.renderClasses = function () {
                    return {
                        'has-icon': ctrl.option.icon,
                        active: ctrl.value === ctrl.option.value
                    };
                };

                ctrl.handler = function () {
                    if (!ctrl.disabledWhen()) {
                        ctrl.value = ctrl.option.value;
                    }
                    return;
                };

                ctrl.disabledWhen = function () {
                    if (ctrl.option.disabled) return ctrl.option.disabled;
                    if (ctrl.option.disabledWhen) return ctrl.option.disabledWhen();
                    if (ctrl.field.disabled) return ctrl.field.disabled;
                    if (ctrl.field.disabledWhen) return ctrl.field.disabledWhen();
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('panelOptions', panelOptions).directive('panelOption', panelOption);
})();