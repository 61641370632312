(function () {
    'use strict';

    function SettingsAccountCtrl($scope, $rootScope, $filter, $state, account, asyncOptions, unsavedAlert, $geckoSounds, Gecko, geckoDates) {
        var ctrl = this;
        $scope.account = account;
        ctrl.originalDateFormat = account.short_date_format;

        if (!Object.getOwnPropertyDescriptor($scope.account, '_short_name')) {
            Object.defineProperty($scope.account, '_short_name', {
                get: function get() {
                    return $scope.account.short_name;
                },
                set: function set(value) {
                    $scope.account.short_name = value.replace(new RegExp(/\s/, 'g'), '-').replace(new RegExp(/[^a-zA-Z0-9_-]/, 'g'), '').toLowerCase();
                }
            });
        }

        // Fields
        ctrl.fields = [{
            label: 'Details',
            type: Gecko.Field.TYPE_TITLE
        }, {
            id: 'name',
            label: 'Name',
            required: true,
            description: 'Used throughout Gecko to identify this account.',
            colClass: 'col-sm-6 col-xs-12',
            autocomplete: false
        }, {
            id: '_short_name',
            label: 'Unique account identifier',
            description: 'For use in personalised weblinks (urls) such as Landing Pages.',
            required: true,
            colClass: 'col-sm-6 col-xs-12',
            autocomplete: false
        }, {
            id: 'address',
            label: 'Main Address',
            required: true,
            description: 'The address is used across the system for features such as event locations, VOIP number purchasing, emails and more.',
            type: Gecko.Field.TYPE_ADDRESS,
            format: ['street_address', 'city', 'state', 'postal_code', 'country'],
            colClass: 'col-sm-6 col-xs-12',
            options: Gecko.countryCodes,
            optionsKey: 'iso2',
            optionsName: 'name'
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'default_country',
            label: 'Default Country',
            choiceTemplate: 'country-code-choice',
            matchTemplate: 'country-code-match',
            options: Gecko.countryCodes,
            getOptions: function getOptions(select) {
                var filtered = $filter('filter')(Gecko.countryCodes, select.search);
                this.options = filtered.splice(0, 50); // Limit the results to 50 to improve dropdown performance
            },
            optionsKey: 'iso2',
            description: 'The default country code to be applied to all imported contacts with a mapped telephone number.',
            colClass: 'col-sm-6 col-xs-12'
        }, {
            label: 'Date & Time',
            type: Gecko.Field.TYPE_TITLE
        }, {
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            id: 'timezone',
            label: 'Timezone',
            options: GeckoUI.mapObjectArray(timezones, { id: null, title: null }, true),
            optionsKey: 'id',
            description: 'Used when scheduling events, calls or messages.',
            optionsLabelKey: 'title',
            required: true
        }, {
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            id: 'short_date_format',
            label: 'Date Format',
            options: geckoDates.dateFormatConfigOptions,
            optionsKey: 'id',
            optionsLabelKey: 'label',
            required: true
        }, {
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            id: 'time_format',
            label: 'Time Format',
            options: geckoDates.timeFormatConfigOptions,
            optionsKey: 'id',
            optionsLabelKey: 'label',
            required: true
        }, {
            label: 'Communication Defaults',
            type: Gecko.Field.TYPE_TITLE
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'sender_id',
            label: 'Default email sender',
            description: 'To create/ edit email senders, click <a ui-sref="verifiedsenders.senders">here</a>. Can be changed on a per message basis.',
            options: [account.related_sender],
            getOptions: asyncOptions.create(new Gecko.Sender().where('type', Gecko.Sender.EMAIL).orderBy('name').rfields({ sender: ['name', 'email', 'verified'] }), null, { search: 'keywordSearch' }),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            optionsGroup: function optionsGroup(item) {
                return item.verified ? 'Verified' : 'Not Verified';
            },
            choiceTemplate: 'name-email-choice',
            matchTemplate: 'name-email-match'
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'call_sender_id',
            label: 'Default caller ID',
            description: 'Can be changed on a per campaign or call basis.',
            options: [account.related_call_sender],
            getOptions: asyncOptions.create(new Gecko.Sender().where('outgoing_voice', 1).orderBy('name').rfields({ sender: ['name', 'type', 'tel'] }), null, { search: 'keywordSearch' }),
            choiceTemplate: 'sender-choice',
            matchTemplate: 'sender-match',
            optionsKey: 'id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return !Gecko.User.hasOutbound();
            },
            optionsGroup: function optionsGroup(item) {
                return item && item.type && item.type == Gecko.Sender.GECKOCHAT ? "GeckoChat" : undefined;
            }
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'sms_sender_id',
            label: 'Default SMS ID',
            description: 'To create/ edit SMS numbers, click <a ui-sref="voipnumbers">here</a>. Can be changed on a per broadcast basis.',
            options: [account.related_sms_sender],
            getOptions: asyncOptions.create(new Gecko.Sender().where('outgoing_sms', 1).orderBy('name').rfields({ sender: ['name', 'type', 'tel'] }), null, { search: 'keywordSearch' }),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_SMS);
            },
            optionsGroup: function optionsGroup(item) {
                return item && item.type && item.type == Gecko.Sender.GECKOCHAT ? "GeckoChat" : undefined;
            }
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'default_attendance_captured_status',
            label: 'Default Event Engagement status',
            description: 'The default status assigned to a contact captured at events. Works if the Current Event option in the Capture app is used.',
            options: Gecko.Account.CAPTURED_STATUS_TITLES,
            optionsKey: 'value',
            optionsLabelKey: 'label',
            noBlank: true
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'default_attendance_added_status',
            label: 'Default Event Registration Status',
            description: 'The default status assigned to attendees that are imported into an event or that register through a form that uses an \'Event\' field.',
            options: Gecko.Account.ADDED_STATUS_TITLES,
            optionsKey: 'value',
            optionsLabelKey: 'label',
            noBlank: true
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'ringtone',
            label: 'Incoming Call Tone',
            description: 'The ringtone that will be played when there is an incoming call.',
            options: Gecko.Account.RINGTONE_OPTIONS,
            optionsKey: 'value',
            optionsLabelKey: 'label',
            noBlank: true
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'auto_delete_call_recording_months',
            label: 'Call Recording Retention Period',
            description: 'How long should call recordings be kept before they are deleted?',
            options: Gecko.Account.RECORDING_RETENTIONS,
            optionsKey: 'value',
            optionsLabelKey: 'label',
            noBlank: true
        }, {
            label: 'Branding',
            type: Gecko.Field.TYPE_TITLE
        }, {
            id: 'color',
            label: 'Account colour',
            type: Gecko.Field.TYPE_COLOR,
            description: 'Choose the colour that is used in the header.'
        }, {
            id: 'custom_css',
            label: 'Custom CSS',
            description: 'Custom CSS to be used for all embedded forms - unless overwritten on an individual form',
            required: false,
            type: Gecko.Field.TYPE_TEXTAREA,
            colClass: 'col-sm-6 col-xs-12',
            autocomplete: false
        }, {
            label: 'Image Uploads',
            type: Gecko.Field.TYPE_TITLE
        }, {
            id: 'logo',
            label: 'Account Logo',
            type: Gecko.Field.TYPE_FILE,
            format: 'url',
            description: 'Upload your institutions logo for this account. This will appear in the header.',
            mimetype: 'image/*'
        }, {
            id: 'header_bg',
            label: 'Header Background (mobile)',
            description: 'The background image used on public forms - 1536px(w) x 500px(h) in <code>JPG</code> or <code>PNG</code>.',
            type: Gecko.Field.TYPE_FILE,
            format: 'url',
            mimetype: 'image/*'
        }, {
            id: 'header_logo',
            label: 'Header Logo (mobile)',
            description: 'The logo you wish to be used on public forms - 670px(w) x 180px(h) in <code>JPG</code> or <code>PNG</code>.',
            type: Gecko.Field.TYPE_FILE,
            format: 'url',
            mimetype: 'image/*'
        }, {
            label: 'Analytics',
            type: Gecko.Field.TYPE_TITLE
        }, {
            id: 'gtm_id',
            label: 'Global Google Tag Manager ID',
            description: 'The Google Tag Manager ID to be used for all forms and landing pages - unless overwritten on an individual form or landing page.',
            required: false,
            colClass: 'col-sm-6 col-xs-12',
            autocomplete: false
        }];

        ctrl.saveAccount = function () {

            $scope.account.settings = { make_public: true };

            // Add name and country to address value
            $scope.account.address.customer_name = $scope.account.name;

            $scope.account.hardSave().then(function (account) {
                // Clear app data cache (as account data has changed)
                Gecko.Token.appData.clearCache();

                // Set uploaded image urls back to account on scope (stop double upload on multiple saves)
                $scope.account.logo = account.logo;
                $scope.account.header_bg = account.header_bg;
                $scope.account.header_logo = account.header_logo;

                // Navbar cosmetics
                Gecko.account.color = $scope.account.color;
                Gecko.account.logo = $scope.account.logo;

                // Sender id update
                Gecko.account.call_sender_id = account.call_sender_id;
                Gecko.account.sms_sender_id = account.sms_sender_id;

                // Refresh incoming call sound
                Gecko.account.ringtone_url = account.ringtone_url;
                $geckoSounds.init();

                // Update gtm_id so it can be used on forms / landing pages without page reload
                Gecko.account.gtm_id = account.gtm_id;

                // Success message
                GeckoUI.messenger.success('Your changes have been saved');

                // Refresh if date format changed
                if (ctrl.originalDateFormat !== account.short_date_format) {
                    window.location.reload();
                }

                unsavedAlert.clear();
            }).catch(function (error) {
                GeckoUI.messenger.error(error.errors);
            });
        };

        // Footer buttons
        ctrl.footerBtns = [{
            preset: 'save',
            safeMode: true,
            action: ctrl.saveAccount
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Account Settings',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('SettingsAccountCtrl', SettingsAccountCtrl);
})();