(function () {
    'use strict';

    function geckoStatWidgetPie() {
        return {
            restrict: 'EA',
            template: '\n                <canvas\n                    ng-if="ctrl.show" \n                    class="chart chart-doughnut"  \n                    options="ctrl.options"  \n                    data="ctrl.widget.data" \n                    labels="ctrl.widget.related_stats[0].labels" \n                    legend="true" \n                    series="ctrl.widget.series">\n                </canvas>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                widget: '='
            },
            controller: function controller($timeout) {
                var ctrl = this;
                ctrl.options = {
                    segmentShowStroke: false,
                    animation: false,
                    legendTemplate: '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%> !important"></span><%if(segments[i].label){%><%=segments[i].label%> <strong>(<%=segments[i].value%>)</strong><%}%></li><%}%></ul>',
                    responsive: true
                };

                // Tooltips dont display correctly on first load, sigh
                $timeout(function () {
                    return ctrl.show = true;
                }, 300);
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoStatWidgetPie', geckoStatWidgetPie);
})();