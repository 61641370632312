(function () {
    'use strict';

    function geckoCallRecentCallsCard($geckoCall, Gecko) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-recent-calls-card.html',

            require: '^geckoCallPanePrepare',

            link: function link(scope, iElement, iAttrs, ctrl) {

                // Show loading indicator
                // $geckoCall.loadingIndicator(true);

                ctrl.callIcon = function (type) {
                    switch (type) {
                        case Gecko.Call.TYPE_INBOUND:
                            return '/images/icons/call-inbound.054133b8.svg';
                        case Gecko.Call.TYPE_OUTBOUND:
                            return '/images/icons/call-outbound.f5443e4a.svg';
                    }
                };

                // Get recent calls
                new Gecko.Call().include('contact', 'number').rfields({ call: ['number_id', 'contact_id', 'type', 'created_at'], number: ['cleaned', 'original'], contact: ['full_name', 'email'] }).where('call_user_id', Gecko.user.id).where('status', [Gecko.Call.COMPLETE, Gecko.Call.ABORTED]).limit(8).get().then(function (calls) {
                    ctrl.previousCalls = calls.toArray();
                    // Hide loading indicator
                    // $geckoCall.loadingIndicator(false);
                }).catch(function () {
                    ctrl.previousCalls = [];
                    // Hide loading indicator
                    // $geckoCall.loadingIndicator(false);
                });

                ctrl.redialCall = function (call) {
                    // Open call pane with contact id
                    if (call.contact_id) {
                        // Reset prepare data
                        $geckoCall.reset(true);
                        $geckoCall.open(call.contact_id);
                    }
                    // Populate number to dial
                    if (!call.contact_id) {
                        if (ctrl.isType('contact')) {
                            $geckoCall.prepare('dial', call.number.cleaned || call.number.original);
                        } else {
                            ctrl.number = call.number.cleaned || call.number.original;
                        }
                    }
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallRecentCallsCard', geckoCallRecentCallsCard);
})();