(function () {
    'use strict';

    function geckoStatWidgetInteger() {
        return {
            restrict: 'EA',
            template: '\n                <div class="text-center" ng-repeat="stat in ::(ctrl.widget.related_stats) track by $index">\n\t\t\t        <h1>{{ ::(stat.result | number) }}</h1>\n\t\t\t        <span>{{ ::stat.title }}<span>\n\t\t\t    </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                widget: '='
            },
            controller: function controller() {
                var ctrl = this;
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoStatWidgetInteger', geckoStatWidgetInteger);
})();