(function () {
    'use strict';

    function RecurringImportEditCtrl($state, $scope, csv_import) {
        var ctrl = this;

        ctrl.csv_import = csv_import.toInstance();

        ctrl.tabs = [{
            title: 'Import Options',
            state: 'importrecurringedit.options'
        }, {
            title: 'Workflows',
            state: 'importrecurringedit.workflows',
            hideWhen: function hideWhen() {
                return ctrl.csv_import.import_to !== 'contacts';
            },
            lockedWhen: function lockedWhen() {
                return ctrl.csv_import.selected_fields === null;
            },
            lockedMessage: 'You must save your CSV mapping before setting up a Workflow for this import.'
        }];

        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                return $state.go('settings');
            }
        }, {
            label: 'Imports',
            click: function click() {
                return $state.go('settingsimports');
            }
        }, {
            label: 'Recurring Import',
            active: true
        }];

        ctrl.saveChanges = function () {
            // Emit a broadcast and let the child options and workdlows routes handle the logic
            $scope.$broadcast('saveImport');
        };
    }
    angular.module('GeckoEngage').controller('RecurringImportEditCtrl', RecurringImportEditCtrl);
})();