(function () {
    'use strict';

    function eventSessionModalDetails() {

        return {
            restrict: 'E',
            scope: {
                event: '=',
                session: '=',
                hosts: '='
            },
            template: '\n                <gecko-fields fields="ctrl.fields" values="ctrl.session"></gecko-fields>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: eventSessionModalDetailsCtrl
        };
    }

    function eventSessionModalDetailsCtrl($geckoModal) {
        var ctrl = this;

        // Parse the session tags into select options
        ctrl.session.tags = ctrl.session.tags && Array.isArray(ctrl.session.tags) ? ctrl.session.tags : [];
        var tagId = -1;

        // Parse hosts
        ctrl.session.hosts = (ctrl.session.related_hosts || []).map(function (s) {
            return s.id;
        });

        ctrl.openSessionHostModal = function () {
            return $geckoModal.addHostModal(new Gecko.Host()).result.then(function (host) {
                // Update options
                ctrl.hosts.push({ id: host.id, email: host.email, name: host.name });
                ctrl.fields.find(function (f) {
                    return f.id === 'hosts';
                }).options = ctrl.hosts;
                // Update hosts array on event
                ctrl.session.hosts = (ctrl.session.hosts || []).concat(host.id);
                // Hacky fix, re-render the dropdown :(
                ctrl.hostsAreBeingUpdated = true;
                setTimeout(function () {
                    ctrl.hostsAreBeingUpdated = false;
                });
                // Re-add open host modal listener (the dom has refreshed)
                addHostModalFn();
            }).catch(console.error);
        };

        var addHostModalFn = function addHostModalFn() {
            setTimeout(function () {
                angular.element('#openSessionHostModal').on('click', function () {
                    ctrl.openSessionHostModal();
                });
            }, 300);
        };

        addHostModalFn();

        ctrl.fields = [{
            id: 'title',
            label: 'Title',
            colClass: 'col-xs-12',
            required: true
        }, {
            id: 'start_date',
            label: 'Start Date and Time',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_DATETIME,
            required: true,
            utc: true
        }, {
            id: 'end_date',
            label: 'End Date and Time',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_DATETIME,
            required: true,
            utc: true
        }, {
            id: 'closing_date',
            label: 'Registration Closing Date (Optional)',
            description: 'This will apply for all session times within the session.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_DATETIME,
            utc: true,
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            }
        }, {
            id: 'tags',
            label: 'Session Tags',
            description: 'Tags allow users to filter sessions on an event field.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_TAGGER,
            options: ctrl.event.available_tags || [],
            newItem: function newItem(string) {
                return {
                    title: string,
                    type: 'tag',
                    id: tagId--
                };
            },
            optionsKey: 'id',
            optionsLabelKey: 'title',
            hideWhen: function hideWhen() {
                return !ctrl.session.id;
            }
        }, {
            id: 'description',
            label: 'Description',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_RICHTEXT,
            config: { toolbar: 'simple' },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_BRANDING) || !ctrl.session.id;
            }
        }, {
            id: 'hosts',
            label: 'Hosts',
            description: 'Display host details within the session. To create a new host, click <a id=\'openSessionHostModal\' >here</a>.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_MULTI,
            options: ctrl.hosts,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return ctrl.hostsAreBeingUpdated || !Gecko.able(Gecko.ABILITY_HOSTS_VIEW) || !ctrl.session.id;
            }
        }];
    }

    angular.module('GeckoEngage').directive('eventSessionModalDetails', eventSessionModalDetails).controller('eventSessionModalDetailsCtrl', eventSessionModalDetailsCtrl);
})();