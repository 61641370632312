(function () {
    'use strict';

    function SettingsNumbersCtrl($state, $stateParams, numbers) {
        var ctrl = this;
        ctrl.pagination = numbers.pagination;
        ctrl.numbers = numbers.toInstances();

        // Table structure
        ctrl.cols = [{
            title: 'Call',
            key: 'do_not_call',
            status_title_none: true,
            expandable: true,
            status_styles: [{
                id: 1,
                label: 'label-danger',
                title: 'Do Not Call'
            }, {
                id: 0,
                label: 'label-success',
                title: 'Callable'
            }]
        }, {
            title: 'SMS',
            key: 'do_not_sms',
            status_title_none: true,
            expandable: true,
            status_styles: [{
                id: 1,
                label: 'label-danger',
                title: 'Do Not SMS'
            }, {
                id: 0,
                label: 'label-success',
                title: 'SMS Usable'
            }, {
                id: null,
                label: 'label-success',
                title: 'SMS Usable'
            }]
        }, {
            title: 'Number',
            key: 'original',
            sortKey: 'original',
            colClass: 'col-xs-2'
        }, {
            title: 'Formatted',
            key: 'cleaned',
            sortKey: 'cleaned',
            colClass: 'col-xs-2'
        }, {
            title: 'National',
            key: 'national',
            sortKey: 'national',
            colClass: 'col-xs-2'
        }, {
            title: 'Contacts',
            key: 'related_contacts',
            colClass: 'col-xs-6',
            render: function render(contacts) {
                if (!contacts) return '';
                var _contacts = GeckoUI.getIdArray(contacts, 'full_name');
                return _contacts.join(', ');
            }
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View Contacts',
            action: function action(number) {
                return $state.go('contacts', { number: number.id });
            }
        }, {
            title: 'Set Do Not Call',
            render: function render(item, data) {
                if (!data.do_not_call) return 'Revoke call permission';
                return 'Grant call permission';
            },
            action: function action(number, index) {
                number.do_not_call = !number.do_not_call;
                number.include('contacts:all').save().then(function (number) {
                    ctrl.numbers[index] = number;
                    GeckoUI.messenger.success('Number call status updated');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }, {
            title: 'Set Do Not SMS',
            render: function render(item, data) {
                if (!data.do_not_sms) return 'Revoke SMS permission';
                return 'Grant SMS permission';
            },
            action: function action(number, index) {
                number.do_not_sms = !number.do_not_sms;
                number.include('contacts:all').save().then(function (number) {
                    ctrl.numbers[index] = number;
                    GeckoUI.messenger.success('Number SMS status updated');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }];

        // Satus filter
        ctrl.filters = [{
            title: 'Call Status',
            type: 'checkbox',
            options: [{ label: 'Callable', value: 0 }, { label: 'Do not call', value: 1 }],
            stateParam: 'status'
        }, {
            title: 'SMS Status',
            type: 'checkbox',
            options: [{ label: 'SMS Usable', value: 0 }, { label: 'Do not SMS', value: 1 }],
            stateParam: 'sms_status'
        }];

        ctrl.numberStates = $stateParams.status ? angular.isArray($stateParams.status) ? $stateParams.status : [$stateParams.status] : [];

        ctrl.numberSMSStates = $stateParams.sms_status ? angular.isArray($stateParams.sms_status) ? $stateParams.sms_status : [$stateParams.sms_status] : [];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Numbers',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('SettingsNumbersCtrl', SettingsNumbersCtrl);
})();