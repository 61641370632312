(function () {
    'use strict';

    function EventStreamCtrl($scope, $state, $stateParams, $filter, event, sessions, times, geckoDates) {
        var ctrl = this;
        ctrl.event = event;
        ctrl.pagination = times.pagination;
        ctrl.sessions = sessions.toArray();
        // Map session titles
        ctrl.times = times.toArray().map(function (t) {
            t.title = geckoDates.printDateString(t.start_datetime, 'Do MMMM YYYY [at] ' + geckoDates.timeFormat());
            t.session_title = (ctrl.sessions.find(function (s) {
                return s.id === t.parent_id;
            }) || {}).title;
            return t;
        });

        ctrl.fields = [{
            id: 'id',
            label: 'Pick a Session',
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            colClass: 'none',
            options: ctrl.sessions,
            optionsValueKey: 'id',
            optionsLabelKey: 'title',
            onChange: function onChange(obj) {
                return obj ? changeFn(obj.value) : null;
            }
        }];

        ctrl.add = function (timeId) {
            return new Gecko().call('/videos', 'POST', { type: 'mux', event_id: timeId }, true, false).then(function (video) {
                ctrl.times.find(function (t) {
                    return t.id === timeId;
                }).video = video;
                $scope.$digest();
            }).catch(function (error) {
                return GeckoUI.messenger.error(GeckoUI.renderError(error));
            });
        };

        ctrl.remove = function (time) {
            return new Gecko().call('/videos/' + time.video.uuid, 'DELETE', null, true, false).then(function () {
                ctrl.times.find(function (t) {
                    return t.id === time.id;
                }).video = null;
                $scope.$digest();
            }).catch(function (error) {
                return GeckoUI.messenger.error(GeckoUI.renderError(error));
            });
        };
    }

    angular.module('GeckoEngage').controller('EventStreamCtrl', EventStreamCtrl);
})();