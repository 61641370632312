
(function () {
    'use strict';

    var datePicker = {
        require: '?ngModel',
        restrict: 'A',
        link: function link($scope, element, attrs, controller) {
            var momentFormat = Gecko.dateFormat.short;
            var datepickerFormat = Gecko.dateFormat.datepicker;

            var updateModel;

            // The following doesn't quite work since the date picker doesn't
            // Run the render function unless the page changes
            var minDate, maxDate;
            // Attrs.$observe('minDate', function (value) {
            //     If (!(value == null || value === ''))
            //         MinDate = moment(value, 'X');
            //     Else
            //         MinDate = null;
            //     Element.datepicker();
            // });

            // Attrs.$observe('maxDate', function (value) {
            //     If (!(value == null || value === ''))
            //         MaxDate = moment(value, 'X');
            //     Else
            //         MaxDate = null;
            //     Element.datepicker();
            // });

            var isUTC = function isUTC() {
                return angular.isDefined(attrs.utc) && attrs.utc;
            };

            var renderFunction = function renderFunction(date) {
                date = moment(date);
                return (!minDate || date.isAfter(minDate)) && (!maxDate || date.isBefore(maxDate)) ? '' : 'disabled';
            };

            var getMomentDate = function getMomentDate(value) {
                if (String(value).indexOf('/') === -1) {
                    return isUTC() ? moment.utc(value, 'X') : moment(value, 'X');
                    return moment(value, 'X');
                }
                return moment.utc(value, momentFormat);
            };

            element.on('keydown', function (event) {
                var keyCode = event.keyCode || event.which;
                if (keyCode == 9) {
                    element.datepicker('hide');
                }
            });

            element.on('click', function () {
                element.datepicker('show');
            });

            if (controller) {

                updateModel = function updateModel(event) {
                    var newDate = moment(new Date(event.date));
                    var currDate = getMomentDate(controller.$viewValue);

                    if (event.viewMode === 'days') {
                        element.datepicker('hide');
                    } else if (event.viewMode === 'years' && controller.$viewValue) {
                        var year = newDate.year();
                        newDate = currDate.set('year', year);
                    } else if (event.viewMode === 'months' && controller.$viewValue) {
                        var month = newDate.month();
                        newDate = currDate.set('month', month);
                    }

                    controller.$setViewValue(newDate.format(momentFormat));
                    controller.$render();
                };

                controller.$parsers.push(function (value) {
                    var isValid = !!String(value).match(/\d\d\/\d\d\/\d\d\d\d/g);
                    if (!isValid) {
                        return null;
                    }
                    var date;
                    if (isUTC()) {
                        date = moment.utc(value, momentFormat);
                        if (date.isValid()) return date.format('X');
                        return;
                    } else {
                        date = moment(value, momentFormat);
                        if (date.isValid()) return date.format('X');
                        return;
                    }
                });

                controller.$render = function () {
                    if (controller.$modelValue) {
                        var date = void 0;
                        if (isUTC()) {
                            date = moment.utc(controller.$modelValue, 'X').format(momentFormat);
                        } else {
                            date = moment(controller.$modelValue, 'X').format(momentFormat);
                        }
                        element.datepicker('setValue', date);
                        element.datepicker('update');
                    }
                    return controller.$viewValue;
                };
            }

            var picker = element.datepicker({
                format: datepickerFormat,
                onRender: renderFunction
            }).on('changeDate', updateModel);

            // This attribute is added to the picker to allow it to be targeted in tests correctly
            if (attrs.id) picker.data('datepicker').picker.attr('data-for', attrs.id);

            // Remove the picker element that is left on the body
            $scope.$on('$destroy', function () {
                try {
                    picker.data('datepicker').picker.remove();
                } catch (e) {
                    // Pass
                }
            });

            return picker;
        }
    };

    function bDatepicker() {
        return datePicker;
    }

    angular.module('GeckoEngage').directive('bDatepicker', bDatepicker);
})();