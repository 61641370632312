(function () {

    'use strict';

    function UserGroupsRoute($stateProvider) {
        $stateProvider.state('usersgroups', {
            url: "/settings/groups?{page}&{search}",
            templateUrl: "/modules/users_groups/views/index.2940cde3.html",
            controller: 'GroupsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Groups',
            redirectKey: 'module_groups',
            requires: [Gecko.ABILITY_GROUPS_VIEW],
            resolve: {
                groups: function groups($stateParams) {
                    var groups = new Gecko.Group().perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).include('permissions:all');
                    if ($stateParams.search) groups.where('keyword', $stateParams.search);
                    return groups.rfields({ group: ['created_at', 'name', 'type', 'user_count'] }).get();
                }
            }
        }).state('usersgroup', {
            url: "/settings/groups/{group_id}?{page}&{order}&{sort}",
            templateUrl: "/modules/users_groups/views/edit.6cd2ca29.html",
            controller: 'GroupCtrl',
            controllerAs: 'ctrl',
            page_title: 'Edit Group',
            redirectKey: 'module_group',
            requires: [function ($stateParams) {
                if (Number($stateParams.group_id) === 0) return Gecko.ABILITY_GROUPS_CREATE;
                return Gecko.ABILITY_GROUPS_VIEW;
            }],
            editRequires: [Gecko.ABILITY_GROUPS_UPDATE],
            resolve: {
                group: function group($stateParams) {
                    if ($stateParams.group_id != 0) {
                        return new Gecko.Group().include('permissions:all').get($stateParams.group_id);
                    } else {
                        return new Gecko.Group();
                    }
                },
                users: function users($stateParams) {
                    var order = $stateParams.order !== undefined ? $stateParams.order : 'full_name',
                        sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC',
                        users = new Gecko.User().where('group_id', $stateParams.group_id);
                    return users.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ user: ['created_at', 'full_name', 'email'] }).get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(UserGroupsRoute);
})();