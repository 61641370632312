

(function () {
				'use strict';

				function geckoAdvancedSearchModalDependants() {
								return {
												restrict: 'E',
												template: '\n\t            <div class="col-xs-12 clearfix">\n\t                <div class="alert alert-warning" style="margin:0;">\n\t                    This search is used in:\n\t                    <ul style="padding:10px 0; list-style-position:inside;">\n\t                        <li ng-repeat="dep in ctrl.results track by $index">\n\t                            {{dep.type}}: <a>{{dep.name}}</a>\n\t                        </li>\n\t                    </ul>\n\t                    making changes here could affect the contacts in other saved searches or campaigns.\n\t                </div>\n\t            </div>\n            ',
												scope: {
																dependants: '='
												},
												controllerAs: 'ctrl',
												bindToController: true,
												controller: geckoAdvancedSearchModalDependantsCtrl
								};
				}

				function geckoAdvancedSearchModalDependantsCtrl($scope) {
								var ctrl = this;

								ctrl.buildResults = function () {
												var results = [];
												(ctrl.dependants || []).forEach(function (d) {
																if (d.saved) {
																				d.type = 'Saved Search';
																				results.push(d);
																} else {
																				if ((d.related_campaigns || []).length) {
																								d.related_campaigns.forEach(function (campaign) {
																												results.push({
																																type: 'Campaign',
																																name: campaign.title
																																//sref : 'campaignsedit.view({campaign_id: ' + campaign.id + '})',
																												});
																								});
																				}
																				if (d.related_parent) {
																								results.push({
																												type: 'Segment: for Campaign',
																												name: d.related_parent.related_campaigns[0].title
																												//sref : 'campaignssegments({campaign_id: ' + filter.related_parent.related_campaigns[0].id + '})',
																								});
																				}
																}
												});
												return results;
								};

								ctrl.results = ctrl.buildResults();

								$scope.$watch('ctrl.dependants', function (val, oldVal) {
												if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
																ctrl.results = ctrl.buildResults();
												}
								}, true);
				}

				angular.module('GeckoEngage').directive('geckoAdvancedSearchModalDependants', geckoAdvancedSearchModalDependants).controller('geckoAdvancedSearchModalDependantsCtrl', geckoAdvancedSearchModalDependantsCtrl);
})();