
(function () {
    'use strict';

    function filterDate() {

        var invalid = /invalid/i;

        return {
            restrict: 'E',
            scope: {
                from: '=from',
                to: '=to',
                noClearBtn: '=',
                extraParams: '&'
            },
            templateUrl: '/components/filter-date/filter-date.html',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($state, $attrs) {
                var ctrl = this;

                ctrl.filterPage = function () {

                    // Default from date to current day
                    if (!ctrl.from || invalid.test(ctrl.from)) ctrl.from = moment().format('X');

                    // Make sure from is at start of day
                    ctrl.from = moment(ctrl.from, 'X').hour(0).minute(0).second(0).format('X');

                    // Default to date to from date
                    if (!ctrl.to || invalid.test(ctrl.to)) ctrl.to = ctrl.from;

                    // Make sure to date is at the end of the day.
                    ctrl.to = moment(ctrl.to, 'X').hour(23).minute(59).second(59).format('X');

                    if ($attrs.state) $state.go($attrs.state, Gecko.mergeObjects({ from: ctrl.from, to: ctrl.to, page: null }, ctrl.extraParams()));
                };

                ctrl.reset = function () {
                    ctrl.from = null;
                    ctrl.to = null;
                    $state.go($attrs.state, Gecko.mergeObjects({ from: ctrl.from, to: ctrl.to, page: null }, ctrl.extraParams()));
                };
            },
            link: function link(scope, element, attr, ctrl) {

                var fromInput = element.find('[ng-model="ctrl.from"]');
                var toInput = element.find('[ng-model="ctrl.to"]');

                // Auto focus the to date on dismissing the from date
                fromInput.on('changeDate', function (e) {
                    if (e.viewMode !== 'days') {
                        return;
                    }
                    if (ctrl.to) {
                        ctrl.filterPage();return;
                    }
                    toInput.focus();
                });

                // Hide date picker on tab
                fromInput.on('keydown', function (e) {
                    if (e.keyCode == 9) {
                        fromInput.datepicker('hide');
                    }
                });

                // Auto filter the page on dismissing the to date
                toInput.on('changeDate', function () {
                    ctrl.filterPage();
                });

                // Hide Datepicker and filter the page on enter.
                toInput.on('keyup', function (e) {
                    if (e.keyCode == 13) {
                        ctrl.filterPage();
                        toInput.datepicker('hide');
                    }
                });

                var firstRule = true;
                toInput.on('focus', function () {
                    var toPicker = $("[data-for='dateToFilter" + scope.$id + "']");
                    var leftDiff = toPicker.position().left + toPicker.width() - $(window).width(); // Calculate if and how far off screen the datepicker is
                    var padding = 10;
                    if (leftDiff > 0) {
                        toPicker.css('left', Number(toPicker.position().left - leftDiff - padding) + 'px'); // Move the datepicker back on screen and give it a small padding
                        if (!firstRule) {
                            // Delete exsisting css rules if they exist
                            document.styleSheets[0].deleteRule(1);
                            document.styleSheets[0].deleteRule(1);
                        }
                        // Add some custome CSS rules to move the :before and :after for the arrow thingy to line up with the unput box
                        document.styleSheets[0].insertRule("[data-for='dateToFilter" + scope.$id + "']:before { left: " + (6 + leftDiff + padding) + "px !important; }", 1);
                        document.styleSheets[0].insertRule("[data-for='dateToFilter" + scope.$id + "']:after { left: " + (7 + leftDiff + padding) + "px !important; }", 1);
                        firstRule = false;
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('filterDate', filterDate);
})();