(function () {
    'use strict';

    function $matchTypes() {
        return {
            basic: [{
                value: '=',
                label: 'equals'
            }, {
                value: '!=',
                label: 'not equals'
            }],

            event: [{
                value: '=',
                label: 'equals'
            }, {
                value: 'C',
                label: 'is in'
            }],

            standard: [{
                value: '=',
                label: 'equals'
            }, {
                value: '!=',
                label: 'not equals'
            }, {
                value: '%',
                label: 'contains'
            }, {
                value: '!%',
                label: 'does not contain'
            }, {
                value: '--',
                label: 'is blank'
            }, {
                value: '!--',
                label: 'is not blank'
            }],

            numeric: [{
                value: '=',
                label: 'equals'
            }, {
                value: '!=',
                label: 'not equals'
            }, {
                value: '>',
                label: 'greater than'
            }, {
                value: '<',
                label: 'less than'
            }],

            label: [{
                value: '==',
                label: 'has all'
            }, {
                value: '=',
                label: 'has any'
            }, {
                value: '!=',
                label: 'doesn\'t have any'
            }, {
                value: '!==',
                label: 'doesn\'t have all'
            }],

            response_label: [{
                value: '%',
                label: 'contains'
            }, {
                value: '!%',
                label: 'does not contain'
            }],

            date: [{
                value: '<',
                label: 'Before'
            }, {
                value: '=',
                label: 'Is'
            }, {
                value: '!=',
                label: 'Is Not'
            }, {
                value: '>',
                label: 'After'
            }],

            contained: [{
                value: '=',
                label: 'is in'
            }, {
                value: '!=',
                label: 'is not in'
            }],

            contactDates: [{
                value: '<',
                label: 'Before'
            }, {
                value: '>',
                label: 'After'
            }, {
                value: 'C',
                label: 'Relative'
            }],

            model: {
                basic: [Gecko.Condition.MODEL_OWNER, Gecko.Condition.MODEL_ASSIGNED, Gecko.Condition.MODEL_IMPORT, Gecko.Condition.MODEL_RESPONSE, Gecko.Condition.MODEL_OUTCOME, Gecko.Condition.MODEL_STATUS, Gecko.Condition.MODEL_EVENT],
                standard: [Gecko.Condition.MODEL_CONTACT_FIELD, Gecko.Condition.MODEL_FILTER, Gecko.Condition.MODEL_SMS_OUTCOME, Gecko.Condition.MODEL_CALL_OUTCOME, Gecko.Condition.MODEL_FIELD],
                numeric: [Gecko.Condition.MODEL_CALL_COUNT, Gecko.Condition.MODEL_ENGAGEMENT_SCORE],
                label: [Gecko.Condition.MODEL_LABEL, Gecko.Condition.MODEL_CONTACT_LABEL],
                date: [Gecko.Condition.MODEL_CONTACT_FIELD],
                contained: [Gecko.Condition.MODEL_LABEL, Gecko.Condition.MODEL_CONTACT_LABEL]
            }
        };
    }

    angular.module('GeckoEngage').factory('$matchTypes', $matchTypes);

    function geckoConditions($matchTypes, $geckoModal, $modal, $rootScope, getTopParentField, $filter, geckoTranslateService) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-conditions/gecko-conditions.html',
            scope: {
                type: '@',
                campaign: '=',
                conditions: '=',
                workflow: '=',
                multiData: '=',
                segment: '&'
            },
            bindToController: true,
            controllerAs: 'ctrl',
            controller: angular.noop,
            require: ['geckoConditions', '?^form', '?^geckoSegment'],
            link: function geckoConditionsLink(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];
                var segmentCtrl = ctrls[2];

                // Configuration of key names used to access type/model match/type.
                if (ctrl.workflow) {

                    ctrl.conditionTypeKey = 'type';
                    ctrl.conditionMatchKey = 'match';
                } else {
                    ctrl.conditionTypeKey = 'model';
                    ctrl.conditionMatchKey = 'type';
                }

                // Create all of the variables we need
                angular.forEach(ctrl.multiData, function (value, key) {
                    ctrl[key] = value.toArray ? value.toArray() : value;
                });

                scope.Gecko = Gecko;
                scope.GeckoUI = GeckoUI;

                ctrl.matchTypes = $matchTypes;

                if (ctrl.workflow) {
                    /**
                     * Open the Condition model to create a new condition or edit an existing one
                     * @param  {object} condition Condition Object
                     * @return {null}
                     */
                    ctrl.openCondition = function (condition) {

                        // Copy the object so that you can't edit it without saving
                        var modalCondition;
                        if (condition) {
                            modalCondition = angular.copy(condition);
                        }

                        $geckoModal.condition(modalCondition, ctrl.campaign, ctrl.multiData, ctrl.type, ctrl.responseFields, ctrl.dataFields, ctrl.geoFields).result.then(function (response) {

                            if (formCtrl) formCtrl.$setDirty();

                            // Blank the value field if is_blank or is_not_blank is selected
                            if (response.condition.match === '--' || response.condition.match === '!--') {
                                response.condition.value = '';
                            }
                            // If there was already an existing condition we just want to update it
                            if (condition) {
                                // If this is the save method
                                if (response.method == 'save') {
                                    // We want to copy this over and replace it
                                    angular.copy(response.condition, condition);

                                    // Or we assume this is a delete
                                } else {
                                    // Find the condition in the existing conditions
                                    var index = ctrl.conditions.indexOf(condition);
                                    if (index !== -1) {
                                        // And splice it out
                                        ctrl.conditions.splice(index, 1);
                                    }
                                }

                                // Otherwise we want to push this new one into the array of conditions
                            } else {
                                ctrl.conditions.push(response.condition);
                            }
                        });
                    };
                } else {
                    ctrl.openCondition = function () {
                        var modalInstance = $geckoModal.searchConditions(ctrl.conditions, ctrl.type === 'segment');

                        modalInstance.result.then(function () {
                            $rootScope.$broadcast('geckoConditions:updated');
                            if (segmentCtrl) {
                                segmentCtrl.contactAmount(ctrl.segment());
                            }
                        }).finally(function () {
                            if (modalInstance.conditionsDirty && formCtrl) formCtrl.$setDirty();
                        });
                    };
                }

                // Remove Condition
                ctrl.removeCondition = function (condition) {
                    var index = ctrl.conditions.indexOf(condition);
                    if (index !== -1) {
                        ctrl.conditions.splice(index, 1);
                        if (formCtrl) formCtrl.$setDirty();
                    }
                };

                /**
                 * Get the pretty label for a match type
                 * @param  {string} match The symbol description for the match type
                 * @return {string}       A pretty string for displaying
                 */
                ctrl.getMatchLabel = function (condition) {

                    var field;
                    if (condition.contact_field_id) {
                        field = GeckoUI.getField(ctrl.contact_fields, condition.contact_field_id);
                    } else if (condition.id) {
                        // For workflows! ugh!
                        field = GeckoUI.getField(ctrl.fields, condition.id);
                    }

                    switch (condition[ctrl.conditionMatchKey]) {
                        case '%':
                            return 'contains';
                        case '!%':
                            return 'does not contain';
                        case '=':
                            return 'is';
                        case '!=':
                            return 'is not';
                        case '==':
                            return 'has all';
                        case '!==':
                            return 'does not have all';
                        case '>':
                            if (field && field.data_type && field.data_type === Gecko.Field.DATA_TYPE_TIMESTAMP) {
                                return 'is after';
                            } else {
                                return 'is greater than';
                            }
                        case '<':
                            if (field && field.data_type && field.data_type === Gecko.Field.DATA_TYPE_TIMESTAMP) {
                                return 'is before';
                            } else {
                                return 'is less than';
                            }
                        case '--':
                            return 'is blank';
                        case '!--':
                            return 'is not blank';
                        case 'C':
                            return 'is in';
                        default:
                            return condition[ctrl.conditionMatchKey];
                    }
                };

                ctrl.getEventLabel = function (condition) {
                    if (ctrl.type === 'form') return 'Event the capture app was used';

                    if (condition[ctrl.conditionMatchKey] === 'C') {
                        return 'Session/Time';
                    }
                    return 'Event/Session';
                };

                ctrl.formatEventStatus = function (value) {
                    value = GeckoUI.wrapArray(value).map(function (id) {
                        return GeckoUI.getObjectByKey(Gecko.Event.statuses, 'id', id).title;
                    });

                    if (value.length === 1) {
                        return value[0];
                    }
                    var last = value.splice(value.length - 1);

                    return value.join(', ') + ' or ' + last[0];
                };

                /**
                 * Toggles the value of the requirment
                 * @return {object} Workflow Object
                 */
                ctrl.toggleRequirement = function () {
                    // We don't support requirement ANY for event workflows, so prevent toggle.
                    if (ctrl.campaign instanceof Gecko.Event) {
                        return;
                    }
                    // We only want to toggle if there is a workflow setup
                    if (ctrl.workflow) {
                        if (ctrl.workflow.requirement == 'ANY') {
                            ctrl.workflow.requirement = 'ALL';
                        } else {
                            ctrl.workflow.requirement = 'ANY';
                        }
                    }
                };

                ctrl.requirementTitle = function () {
                    if (ctrl.type == 'segment' || ctrl.campaign instanceof Gecko.Event) {
                        return 'AND';
                    } else if (ctrl.workflow && ctrl.workflow.requirement == 'ANY') {
                        return 'OR';
                    } else if (ctrl.workflow && ctrl.workflow.requirement == 'ALL') {
                        return 'AND';
                    } else {
                        return 'OR';
                    }
                };

                ctrl.responseFields = [{
                    label: 'Form',
                    value: 'form_id'
                }, {
                    label: 'Assigned user',
                    value: 'assigned_id'
                }, {
                    label: 'Event',
                    value: 'event_id'
                }, {
                    label: 'Import',
                    value: 'import_id'
                }];

                ctrl.dataFields = [{
                    label: 'Calls in campaign',
                    value: 'calls',
                    module: Gecko.Campaign.MODULE_CALL
                }, {
                    label: 'SMS in campaign',
                    value: 'sms',
                    module: Gecko.Campaign.MODULE_SMS
                }];

                ctrl.geoFields = [{
                    label: 'City',
                    value: 'city_name'
                }, {
                    label: 'Country',
                    value: 'country_name'
                }];

                ctrl.isBrokenFieldCondition = function (condition) {
                    if (condition.type === 'field') {
                        var field = (ctrl.fields || []).find(function (f) {
                            return f.id === condition.id;
                        });

                        // Cant find field
                        if (!field) return true;

                        // Cant find option
                        // Show an alert if condition values are missing from field values
                        if (angular.isArray(condition.value)) {
                            var field = (ctrl.fields || []).find(function (f) {
                                return f.id === condition.id;
                            });

                            if (field && [Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI].indexOf(field.type) !== -1) {
                                var conditionValues = condition.value;
                                // Get nested values for parent child option list
                                if (field.parent_id) {
                                    var parentField = getTopParentField(field, ctrl.fields);

                                    // Flatten nested options
                                    if (parentField && !parentField.flattenedOptions) {
                                        parentField.flattenedOptions = [];

                                        var addOptionValue = function addOptionValue(value) {
                                            parentField.flattenedOptions.push(value);
                                            if (value.options && value.options.length) {
                                                value.options.forEach(function (v) {
                                                    return addOptionValue(v);
                                                });
                                            }
                                        };

                                        (parentField.option.values || []).forEach(function (v) {
                                            return addOptionValue(v);
                                        });
                                    }
                                    var optionValues = (parentField || {}).flattenedOptions || [];

                                    // Inline or option list?
                                } else {
                                    var optionValues = (field.option_id ? (field.option || {}).values : field.values) || [];
                                }

                                // Race condition fix
                                if (!Array.isArray(optionValues) || optionValues.length === 0) return false;

                                return conditionValues.filter(function (v) {
                                    return !optionValues.find(function (vv) {
                                        return vv.value == v;
                                    });
                                }).length;
                            }
                        }
                    }
                    return false;
                };

                ctrl.renderFieldString = function (condition, fields, key) {
                    var field = GeckoUI.getField(fields, condition[key]);

                    // Cant find any field ref
                    if (!field) return '';

                    var label = Object.keys(field).length ? field.label : '&lt;???&gt;';
                    var operator = ctrl.getMatchLabel(condition);
                    var value;

                    if (field.data_type === 'timestamp') {
                        value = $filter('formatDateUTC')(condition.value, Gecko.dateFormat.short);
                    } else if (field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE && Array.isArray(condition.value)) {
                        value = geckoTranslateService.renderLanguageValue(condition.value);
                    } else if (field.data_type !== 'timestamp' && Array.isArray(condition.value)) {
                        value = condition.value.join(', ');
                    } else if (['--', '!--'].indexOf(condition[ctrl.conditionMatchKey]) !== -1) {
                        value = '';
                    } else {
                        value = condition.value;
                    }

                    return '<b>' + label + '</b> ' + operator + ' <b>' + value + '</b>';
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoConditions', geckoConditions);
})();