(function () {
    'use strict';

    function ContactMessagesCtrl($scope, $state, $filter, $geckoModal, $geckoChannel, messages) {
        var ctrl = this;
        ctrl.messages = messages.toArray();
        ctrl.pagination = messages.pagination;

        // Sync data
        $geckoChannel.register('contact.messages', ['messages'], ctrl);
        $geckoChannel.set('messages', ctrl.messages);

        ctrl.cols = [{
            key: 'direction',
            data_type: 'image',
            render: function render(value) {
                if (value == Gecko.Message.TYPE_INBOUND) {
                    return 'Inbound Message';
                }
                if (value == Gecko.Message.TYPE_OUTBOUND) {
                    return 'Outbound Message';
                }
            },
            renderSrc: function renderSrc(message) {
                if (message.direction == Gecko.Message.TYPE_INBOUND) {
                    return '/images/icons/direction-inbound.c27978f6.svg';
                }
                if (message.direction == Gecko.Message.TYPE_OUTBOUND) {
                    return '/images/icons/direction-outbound.fa535824.svg';
                }
            }
        }, {
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Sent',
            key: 'created_at',
            sortKey: 'created_at'
        }, {
            type: Gecko.Field.TYPE_NAME,
            title: 'User',
            key: 'sender.name',
            colClass: 'col-xs-2',
            render: function render(value, col, message) {
                if (message.sender) {
                    return message.sender.name;
                } else if (message.user) {
                    return message.user.full_name;
                }
            }
        }, {
            title: 'Recipient',
            key: 'recipients'
        }, {
            title: 'Type',
            key: 'type',
            sortKey: 'type',
            render: function render(value) {
                if (value === Gecko.Message.TYPE_SMS) return value.toUpperCase();
                return $filter('capitalize')(value);
            }
        }, {
            title: 'Subject',
            key: 'subject',
            colClass: 'col-xs-3',
            renderHtml: true
        }, {
            title: 'Status',
            key: 'status',
            status_styles: Gecko.Message.status_titles,
            colClass: 'col-xs-1',
            render: function render(val) {
                return val || 'unknown';
            }
        }];

        // Table row dropdown
        ctrl.rowOptionsBtn = {};
        ctrl.rowOptionsBtn.items = [{
            title: 'View message',
            action: function action(message) {
                $geckoModal.messageViewModal(message);
            }
        }, {
            title: 'View message activity',
            action: function action(message) {
                $geckoModal.messageEventsViewModal(message.message_events);
            }
        }, {
            title: 'View message template',
            action: function action(message) {
                $state.go('settingstemplate.editor', { template_id: message.template_id });
            },
            hideWhen: function hideWhen(message) {
                return !message.template_id;
            }
        }];
    }

    angular.module('GeckoEngage').controller('ContactMessagesCtrl', ContactMessagesCtrl);
})();