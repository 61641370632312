(function () {
    'use strict';

    function EventsShareCtrl($scope, $state, forms, events, categories, asyncOptions, portalRequestService, webShareUrl) {
        var ctrl = this;
        ctrl.events = events.toArray();
        ctrl.categories = categories.toArray();
        ctrl.forms = forms.toArray();

        ctrl.eventsShareService = {

            isStage: function isStage() {
                return window.location.host.indexOf('stage') !== -1;
            },

            buildShareURL: function buildShareURL(uuid, style, event_id, safe, cat_id) {
                var _url = webShareUrl() + '/events-embed/#/events-embed' + (style === 'iframe' ? '/iframe' : '') + '?uuid=' + uuid;

                // Add event id
                if (ctrl.values.type === 'single_event' && event_id) _url += '&event_field_id=' + event_id;
                // Add cat id
                if (ctrl.values.type === 'by_category' && cat_id) _url += '&cat_id=' + cat_id;

                // Add date format
                _url += '&date_format=' + (safe ? encodeURIComponent(Gecko.account.short_date_format) : Gecko.account.short_date_format);

                // Add time format
                _url += '&time_format=' + (safe ? encodeURIComponent(Gecko.account.time_format) : Gecko.account.time_format);

                // URI encode url
                if (safe) return encodeURIComponent(_url);

                return _url;
            },

            buildIframeShareURL: function buildIframeShareURL(uuid, event_id, cat_id) {
                var _url = webShareUrl() + '/events-embed/dist/index.js';
                _url += '?uuid=' + uuid;

                // Add event id
                if (ctrl.values.type === 'single_event' && event_id) _url += '&event_field_id=' + event_id;
                // Add cat id
                if (ctrl.values.type === 'by_category' && cat_id) _url += '&cat_id=' + cat_id;

                // Add date format
                _url += '&date_format=' + Gecko.account.short_date_format;

                // Add time format
                _url += '&time_format=' + Gecko.account.time_format;

                return _url;
            },

            buildShareUrlWrapper: function buildShareUrlWrapper(url, iframe) {
                if (iframe) {
                    return '<iframe src="' + url + '" width="100%" height="300" frameborder="0" seamless title="GeckoForm Events Embed"></iframe>';
                }
                return '<script src="' + url + '" type="application/javascript" id="gecko-events-embed-script"></script><link href="' + url.replace('js', 'css') + '" rel="stylesheet" type="text/css" />';
            },

            getShortUrl: function getShortUrl(url) {
                if (!url) return Promise.reject('No url provided');
                return portalRequestService.get('/short-urls?url=' + url).then(function (res) {
                    return res.data;
                });
            }
        };

        ctrl.formFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Pick a Booking Form',
            description: 'Select a form to be used for registrations. Please note that only forms that contain event fields will be shown in the dropdown.',
            colClass: 'no-class share-page-title'
        }, {
            id: 'form_uuid',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'no-class',
            options: ctrl.forms,
            optionsKey: 'uuid',
            optionsLabel: 'name'
        }];

        ctrl.eventFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Pick Event(s)',
            colClass: 'no-class clear share-page-title'
        }, {
            id: 'type',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            onChange: function onChange() {
                return updateFn();
            },
            options: [{
                value: 'all_events',
                icon: 'fa-list',
                title: 'All Events'
            }, {
                value: 'by_category',
                icon: 'fa-tag',
                title: 'By Category'
            }, {
                value: 'single_event',
                icon: 'fa-calendar-alt',
                title: 'Single Event'
            }]
        }, {
            id: 'cat_id',
            label: 'Pick a Category',
            description: 'Show all upcoming events belonging to a category.',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.categories,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            colClass: 'event-dropdown clear',
            choiceTemplate: 'event-choice',
            hideWhen: function hideWhen() {
                return ctrl.values.type !== 'by_category';
            }
        }, {
            id: 'event_id',
            label: 'Pick an Event',
            description: 'Show an individual event (or leave blank to show all upcoming events).',
            type: Gecko.Field.TYPE_SELECT,
            options: ctrl.events,
            getOptions: function getOptions(field) {
                var match = function match(e) {
                    return (e.nice_title || '').toLowerCase().indexOf(field.search.toLowerCase()) !== -1;
                };
                ctrl.eventFields.find(function (f) {
                    return f.id === 'event_id';
                }).options = (ctrl.events || []).filter(match);
            },
            optionsKey: 'id',
            optionsLabelKey: 'nice_title',
            colClass: 'event-dropdown clear',
            choiceTemplate: 'event-choice',
            hideWhen: function hideWhen() {
                return ctrl.values.type !== 'single_event';
            }
        }];

        //Fields
        ctrl.shareFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Webform Link',
            description: 'Use this URL to access your form on a Gecko hosted landing page. This URL will change when you configure the \'Captured at event\' or \'Prepopulate\' options. Find out more on <a href="https://academy.geckoengage.com/en/articles/6956130-sharing-your-form" target="_blank">Gecko Academy</a>.',
            colClass: 'no-class'
        }, {
            id: 'link',
            type: Gecko.Field.TYPE_LINK,
            colClass: 'no-class gecko-field-code'
        }];

        ctrl.embedFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Embed Your Event on a Website',
            description: 'Paste the following code within the <code>body</code> tags of your website\'s HTML. If you experience issues with your embed, please click on the "Get Alternative Code" option and re-attempt with the updated code.',
            colClass: 'no-class'
        }, {
            id: 'iframe',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'no-class gecko-field-code',
            readonly: true,
            hideWhen: function hideWhen() {
                return ctrl.showLegacyEmbedCode;
            }
        }, {
            id: 'iframeLegacy',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'no-class gecko-field-code',
            readonly: true,
            hideWhen: function hideWhen() {
                return !ctrl.showLegacyEmbedCode;
            }
        }];

        //Values
        ctrl.values = {
            type: 'all_events',
            uuid: null,
            style: 'modern',
            event_id: null,
            link: null,
            iframe: null,
            iframeLegacy: null,
            safeLink: null,
            gecko_base_url: null
        };

        // Cog drop down
        ctrl.optionsBtn = {
            title: 'Get QR Code',
            iconRight: 'fa-angle-down',
            iconLeft: 'fa-qrcode',
            btnClass: 'gecko-ui-material btn-sm btn-default'
        };

        ctrl.optionsBtn.items = [{
            title: 'Standard',
            action: function action() {
                return ctrl.getQr('plain');
            }
        }, {
            title: 'Snapchat',
            action: function action() {
                return ctrl.getQr('snapchat');
            }
        }, {
            title: 'Gecko',
            action: function action() {
                return ctrl.getQr('gecko');
            }
        }, {
            title: 'Wechat',
            action: function action() {
                return ctrl.getQr('wechat');
            }
        }];

        ctrl.getQr = function (style) {
            //Open new window (to get around popup blocker)
            var qrWindow = window.open('', '_blank');
            return ctrl.eventsShareService.getShortUrl(ctrl.values.safeLink).then(function (data) {
                var _url = Gecko.base_url + '/qr?url=' + data.shortURL + '&style=' + style;
                // Open QR in the new window (to get around popup blocker)
                qrWindow.location.href = _url;
            }).catch(function (err) {
                return GeckoUI.messenger.error(err);
            });
        };

        // Copy embed code
        ctrl.copy = function (element) {
            try {
                var copyText = document.getElementById(element);

                // Text area copy
                if (copyText.select) {
                    copyText.select();
                    var result = document.execCommand('copy');
                    GeckoUI.messenger.info('Copied to your clipboard');
                    // Div copy
                } else {
                    if (document.selection) {
                        var range = document.body.createTextRange();
                        range.moveToElementText(document.getElementById(element));
                        range.select().createTextRange();
                        document.execCommand("Copy");
                    } else if (window.getSelection) {
                        var range = document.createRange();
                        document.getElementById(element).style.display = "block";
                        range.selectNode(document.getElementById(element));
                        window.getSelection().addRange(range);
                        document.execCommand("Copy");
                    }
                    GeckoUI.messenger.info('Copied to your clipboard');
                }
            } catch (e) {}
        };

        ctrl.shortUrlAvailable = function () {
            return (ctrl.values.link || '').includes('{{') ? false : true;
        };

        ctrl.getShortUrl = function () {
            ctrl.eventsShareService.getShortUrl(ctrl.values.safeLink).then(function (data) {
                ctrl.values.link = data.shortURL;
                ctrl.values.safeLink = data.shortURL;
            }).catch(function (err) {
                return GeckoUI.messenger.error(err);
            });
        };

        var updateFn = function updateFn() {
            ctrl.values.link = ctrl.eventsShareService.buildShareURL(ctrl.values.form_uuid, ctrl.values.style, ctrl.values.event_id, false, ctrl.values.cat_id);
            ctrl.values.safeLink = ctrl.eventsShareService.buildShareURL(ctrl.values.form_uuid, ctrl.values.style, ctrl.values.event_id, true, ctrl.values.cat_id);
            ctrl.values.iframeLegacy = ctrl.eventsShareService.buildShareUrlWrapper(ctrl.eventsShareService.buildShareURL(ctrl.values.form_uuid, 'iframe', ctrl.values.event_id, false, ctrl.values.cat_id), true);
            ctrl.values.iframe = ctrl.eventsShareService.buildShareUrlWrapper(ctrl.eventsShareService.buildIframeShareURL(ctrl.values.form_uuid, ctrl.values.event_id, ctrl.values.cat_id));
        };

        //Watch for param changes
        var _tempValues = {
            form_uuid: ctrl.values.form_uuid,
            event_id: ctrl.values.event_id,
            cat_id: ctrl.values.cat_id
        };
        Object.defineProperties(ctrl.values, {
            'form_uuid': {
                get: function get() {
                    return _tempValues['form_uuid'];
                },
                set: function set(value) {
                    _tempValues['form_uuid'] = value;
                    updateFn();
                }
            },
            'event_id': {
                get: function get() {
                    return _tempValues['event_id'];
                },
                set: function set(value) {
                    _tempValues['event_id'] = value;
                    updateFn();
                }
            },
            'cat_id': {
                get: function get() {
                    return _tempValues['cat_id'];
                },
                set: function set(value) {
                    _tempValues['cat_id'] = value;
                    updateFn();
                }
            }
        });
    }

    angular.module('GeckoEngage').controller('EventsShareCtrl', EventsShareCtrl);
})();