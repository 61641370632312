(function () {
    'use strict';

    function TemplatesRoute($stateProvider) {
        $stateProvider.state('settingstemplates', {
            url: "/settings/templates?{page}&{search}&{order}&{sort}&{type}&{category_id}",
            templateUrl: "/modules/templates/views/index.c6f02e23.html",
            controller: 'SettingsTemplatesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Templates',
            redirectKey: 'module_templates',
            resolve: {
                categories: function categories() {
                    return new Gecko.Category().rfields({ category: ['title'] }).where('type', Gecko.Category.TYPE_TEMPLATE).orderBy('title').perPage('all').get();
                },
                templates: function templates($stateParams) {
                    var templates = new Gecko.Template();

                    if ($stateParams.search) templates.where('keyword', $stateParams.search);

                    if ($stateParams.type) {
                        templates.where('type', $stateParams.type);
                    } else {
                        templates.where('type', [Gecko.Template.TYPE_SMS, Gecko.Template.TYPE_EMAIL]);
                    }

                    if ($stateParams.category_id) {
                        templates.where('category_id', $stateParams.category_id);
                    }

                    if ($stateParams.order) templates.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    return templates.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ template: ['name', 'type', 'subject'] }).get();
                }
            }
        }).state('deletedtemplates', {
            url: "/settings/templates/deleted?{page}&{search}&{order}&{sort}&{type}&{category_id}",
            templateUrl: "/modules/templates/views/deleted.97ee4143.html",
            controller: 'SettingsDeletedTemplatesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Deleted Templates',
            redirectKey: 'module_templates_deleted',
            resolve: {
                categories: function categories() {
                    return new Gecko.Category().rfields({ category: ['title'] }).where('type', Gecko.Category.TYPE_TEMPLATE).orderBy('title').perPage('all').get();
                },
                templates: function templates($stateParams) {
                    var templates = new Gecko.Template();

                    if ($stateParams.search) templates.where('keyword', $stateParams.search);

                    if ($stateParams.type) {
                        templates.where('type', $stateParams.type);
                    } else {
                        templates.where('type', [Gecko.Template.TYPE_SMS, Gecko.Template.TYPE_EMAIL]);
                    }

                    if ($stateParams.category_id) {
                        templates.where('category_id', $stateParams.category_id);
                    }

                    if ($stateParams.order) templates.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');

                    return templates.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ template: ['name', 'type', 'subject'] }).where('trashed', 'only').get();
                }
            }
        }).state('settingstemplate', {
            url: "/settings/templates/{template_id}",
            templateUrl: "/modules/templates/views/template.a317fc3a.html",
            controller: 'TemplateCtrl',
            controllerAs: 'ctrl',
            page_title: 'Template',
            resolve: {
                data: function data($stateParams) {
                    var _query = {};
                    // Template
                    var templateRfields = {
                        template: ['body_json', 'disable_editor', 'editor_type', 'name', 'type']
                    };
                    if ($stateParams.template_id != 'new') _query.template = new Gecko.Template().include('categories', 'consents').rfields(templateRfields).path($stateParams.template_id);

                    return new Gecko.multi(_query);
                },
                template: function template($stateParams, $state, data) {
                    if ($stateParams.template_id !== "new" && !(data.template || {}).id) {
                        return $state.go('settingstemplates').then(function () {
                            return GeckoUI.messenger.error("Message Template has been deleted");
                        });
                    }
                    return data.template || new Gecko.Template();
                }
            }
        }).state('settingstemplate.settings', {
            url: "/settings",
            templateUrl: "/modules/templates/views/settings.e6d20052.html",
            controller: 'TemplateSettingsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Template Settings',
            redirectKey: 'module_template_settings',
            resolve: {
                data: function data($stateParams) {
                    var _query = {};
                    // Template
                    var templateRfields = {
                        template: ['disable_editor', 'editor_type', 'module', 'name', 'type', 'subject'],
                        consent: ['title'],
                        category: ['id', 'title', 'type']
                    };
                    if ($stateParams.template_id != 'new') _query.template = new Gecko.Template().include('categories', 'current_granted_consents').rfields(templateRfields).path($stateParams.template_id);

                    // Consents
                    if (Gecko.able(Gecko.ABILITY_CONSENTS_VIEW)) _query.consents = new Gecko.Consent().orderBy('title').perPage('all');

                    // Categories
                    _query.categories = new Gecko.Category().where('type', Gecko.Category.TYPE_TEMPLATE);
                    return new Gecko.multi(_query);
                },
                template: function template(data) {
                    return data.template || new Gecko.Template();
                },
                consents: function consents(data) {
                    return data.consents ? data.consents.toArray() : [];
                },
                categories: function categories(data) {
                    return data.categories ? data.categories.toArray() : [];
                }
            }
        }).state('settingstemplate.editor', {
            url: "/editor",
            templateUrl: "/modules/templates/views/editor.9ee9d0f6.html",
            controller: 'TemplateEditorCtrl',
            controllerAs: 'ctrl',
            page_title: 'Template Editor',
            redirectKey: 'module_template_editor',
            resolve: {
                data: function data($stateParams, templateService) {
                    var _query = {};
                    // Template
                    var templateRfields = {
                        template: ['body', 'body_json', 'disable_editor', 'editor_type', 'include_wrapper', 'name', 'type']
                    };

                    if ($stateParams.template_id != 'new') _query.template = new Gecko.Template().include('consents').rfields(templateRfields).path($stateParams.template_id);

                    return new Gecko.multi(_query);
                },
                template: function template(data) {
                    return data.template || new Gecko.Template();
                },
                mergeFields: function mergeFields() {
                    return new Gecko.Field().include('option').where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).perPage('all').orderBy('order', 'asc').rfields({
                        field: ['label', 'type', 'tag', 'is_uneditable', 'is_social'],
                        option: ['name']
                    }).get();
                }
            }
        }).state('settingstemplate.stats', {
            url: "/stats?{date_from}&{date_to}",
            templateUrl: "/modules/templates/views/stats.172afabe.html",
            controller: 'TemplateStatsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Template Stats',
            requires: [Gecko.ABILITY_MESSAGE_STATS],
            resolve: {
                dashboard: function dashboard($stateParams, template) {
                    var id = template.type + '_broadcast';
                    var config = {};
                    config.template_id = $stateParams.template_id;

                    // Filter by date ranger
                    if ($stateParams.date_from && $stateParams.date_to) {
                        config.override_date = $stateParams.date_from + ':' + $stateParams.date_to;
                    }

                    return new Gecko.Dashboard().display(id, config);
                }
            }
        }).state('settingstemplate.usage', {
            url: "/usage?{page}&{search}&{order}&{sort}",
            templateUrl: "/modules/templates/views/usage.edcffe0a.html",
            controller: 'TemplateUsageCtrl',
            controllerAs: 'ctrl',
            page_title: 'Template Usage',
            redirectKey: 'module_template_usage',
            resolve: {
                usage: function usage($stateParams) {
                    var templateId = $stateParams.template_id;
                    var endpoint = '/templates/' + templateId + '/usage';
                    var params = {
                        page: $stateParams.page ? $stateParams.page : 1,
                        per_page: GeckoUI.getPageLimit(this.self.name)
                        //order_by: `${$stateParams.order || 'created_at'}|${$stateParams.sort || 'ASC'}`
                    };

                    return new Gecko.BaseModel().call(endpoint, 'GET', params, false);
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(TemplatesRoute);
})();