(function () {
    'use strict';

    function ContactCampaignsCtrl($scope, $state, $stateParams, $geckoModal, $filter, contact, subscribers, module) {
        var ctrl = this;
        ctrl.module = module;
        ctrl.contact = contact;
        ctrl.subscribers = subscribers.toInstances();
        ctrl.pagination = subscribers.pagination;

        // Table structure
        ctrl.cols = [{
            data_type: Gecko.Field.DATA_TYPE_TIMESTAMP,
            title: 'Date added',
            key: 'created_at',
            sortKey: 'created_at'
        }, {
            title: 'Name',
            key: 'related_campaign.title',
            colClass: 'col-md-4'
        }, {
            title: 'Type',
            key: 'related_campaign.module',
            colClass: 'col-md-4',
            render: function render(value) {
                if (value === Gecko.Campaign.MODULE_EMAIL) {
                    return 'Email';
                } else if (value === Gecko.Campaign.MODULE_SMS) {
                    return 'SMS';
                } else if (value === Gecko.Campaign.MODULE_CALL) {
                    return 'Call';
                }
            }
        }, {
            title: 'Status',
            key: 'status',
            sortKey: 'status',
            status_styles: Gecko.Subscriber.status_titles,
            colClass: 'col-md-4'
        }];

        // Table row action
        ctrl.rowAction = {
            state: 'contact.subscriber',
            params: { contact_id: 'contact_id', subscriber_id: 'id' }
        };

        // Table row dropdown
        ctrl.rowOptionsBtn = {
            hideWhen: function hideWhen(subscriber) {
                return [Gecko.Subscriber.ASSIGNED, Gecko.Subscriber.IN_PROGRESS, Gecko.Subscriber.REMOVED].indexOf(subscriber.status) != -1;
            }
        };
        ctrl.rowOptionsBtn.items = [{
            title: 'Remove from Campaign',
            action: function action(subscriber) {
                if (ctrl.module == Gecko.Campaign.MODULE_CALL) {
                    if (!GeckoUI.ableWithReason(Gecko.ABILITY_CALL_CAMPAIGNS_UPDATE)) {
                        return;
                    }
                } else {
                    if (!GeckoUI.ableWithReason(Gecko.ABILITY_BROADCASTS_UPDATE)) {
                        return;
                    }
                }
                GeckoUI.dialog.confirm('Are you sure you want to remove this contact from this campaign?', function (value) {
                    if (value) {
                        angular.copy(subscriber).remove().then(function () {
                            // Mark subscriber as removed
                            subscriber.status = Gecko.Subscriber.REMOVED;
                            GeckoUI.messenger.success('Contact removed from campaign');
                            $scope.$digest();
                        });
                    }
                });
            }
        }];

        // Add to campaign
        ctrl.headerBtns = [{
            title: ctrl.module == Gecko.Campaign.MODULE_CALL ? 'Add to campaign' : 'Add to broadcast',
            icon: 'fa-plus',
            action: function action() {
                if (ctrl.module == Gecko.Campaign.MODULE_CALL) {
                    if (!GeckoUI.ableWithReason(Gecko.ABILITY_CALL_CAMPAIGNS_UPDATE)) {
                        return;
                    }
                } else {
                    if (!GeckoUI.ableWithReason(Gecko.ABILITY_BROADCASTS_UPDATE)) {
                        return;
                    }
                }

                var wheres = [{
                    key: 'contact_not_in',
                    value: ctrl.contact.id
                }, {
                    key: 'module',
                    value: ctrl.module
                }];

                // Exclude completed broadcasts
                if (ctrl.module !== Gecko.Campaign.MODULE_CALL) {
                    wheres.push({
                        key: 'status',
                        value: [Gecko.Campaign.PENDING, Gecko.Campaign.ACTIVE, Gecko.Campaign.RUNNING, Gecko.Campaign.PAUSED, Gecko.Campaign.INVALID]
                    });
                }

                $geckoModal.selectorModal({
                    title: 'Select a ' + (ctrl.module === Gecko.Campaign.MODULE_CALL ? 'Campaign' : 'Broadcast'),
                    model: 'campaign',
                    fieldLabel: ctrl.module === Gecko.Campaign.MODULE_CALL ? 'Campaign' : 'Broadcast',
                    optionsKey: 'id',
                    optionsLabel: 'title',
                    wheres: wheres
                }).result.then(function (campaign_id) {
                    if (campaign_id === false) {
                        return false;
                    }
                    ctrl.contact.addToCampaign(campaign_id, 'campaign').then(function () {
                        $state.go($state.$current.name, $stateParams, { reload: true });
                        GeckoUI.messenger.success('Contact added to ' + (Gecko.Campaign.MODULE_CALL ? 'Campaign' : 'Broadcast'));
                    });
                });
            }
        }];
    }

    angular.module('GeckoEngage').controller('ContactCampaignsCtrl', ContactCampaignsCtrl);
})();