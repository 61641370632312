(function () {
    'use strict';

    function ContactSubscriberCtrl($scope, $state, $stateParams, $filter, $geckoModal, $geckoChannel, contact, values, subscriber, subscribers, subscriberIncludes, campaign, campaign_fields, outcomes, history) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.subscriber = subscriber;
        ctrl.subscriberIncludes = subscriberIncludes;
        ctrl.campaign = campaign;
        ctrl.script = ctrl.campaign && ctrl.campaign.related_form ? ctrl.campaign.related_form : false;
        ctrl.history = history.data;
        ctrl.pagination = {
            current_page: history.current_page,
            total_pages: history.last_page,
            total_items: history.total,
            per_page: history.per_page,
            last_page: history.last_page,
            hideLimit: true
        };
        ctrl.showAllActivity = $stateParams.activity === 'all';
        ctrl.campaign_fields = campaign_fields;
        ctrl.fields = [];
        ctrl.values = values;

        // Register channel
        $geckoChannel.register($state.$current.name, ['subscriber'], ctrl);

        // Prepare campaign fields
        angular.forEach(ctrl.campaign_fields, function (campaign_field) {
            var field = campaign_field.related_field || new Gecko.Field();

            // Deal with Json rendering
            if (field.type === Gecko.Field.TYPE_TEL || field.type === Gecko.Field.TYPE_NAME) {
                if (field.type === Gecko.Field.TYPE_TEL) {
                    field.render = function (val) {
                        if (val && (val.cleaned || val.original)) {
                            return val.cleaned || val.original;
                        }
                        return '';
                    };
                }
                if (field.type === Gecko.Field.TYPE_NAME) {
                    field.render = function (val) {
                        if (val && (val.first_name || val.last_name)) {
                            return val.first_name + ' ' + val.last_name;
                        }
                        return '';
                    };
                }
            }

            //Display date values in utc
            if (field.type === Gecko.Field.TYPE_DATE) field.utc = true;

            if (campaign_field.read_only) field.type = Gecko.Field.TYPE_NOEDIT;
            field.order = campaign_field.order;
            ctrl.fields.push(field);
        });

        // Cancel subscriber reschedule
        ctrl.toogleReschedule = function () {
            ctrl.subscriber.status = Gecko.Subscriber.PENDING;
            ctrl.subscriber.call_at = null;
            // Save subscriber
            ctrl.subscriber.include(ctrl.subscriberIncludes).save().then(function (subscriber) {
                ctrl.subscriber = subscriber;
                GeckoUI.messenger.success('Subscriber updated');
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        };

        // Collapse is_calculated values
        ctrl.collapseFilter = function (field) {
            if (ctrl.subscriber && [Gecko.Subscriber.IN_PROGRESS, Gecko.Subscriber.SUCCESS, Gecko.Subscriber.FAIL, Gecko.Subscriber.DO_NOT_CALL].indexOf(ctrl.subscriber.status) == -1) return field.type != Gecko.Field.TYPE_TEL && !field.is_calculated;
            return !field.is_calculated;
        };

        // Edit subscriber call outcome
        ctrl.changeCall = function (call, subscriber) {
            if (call) {
                $geckoModal.CallOutcomeModal(call, false, subscriber);
            }
        };

        ctrl.optionsBtn = {
            title: 'Change Campaign',
            icon: 'fa-angle-down',
            items: []
        };

        angular.forEach(subscribers.toArray(), function (subscriber) {
            if (subscriber.campaign) {
                ctrl.optionsBtn.items.push({
                    title: subscriber.campaign.title,
                    action: function action() {
                        return $state.go($state.$current.name, { contact_id: ctrl.contact.id, subscriber_id: subscriber.id });
                    }
                });
            }
        });
    }

    angular.module('GeckoEngage').controller('ContactSubscriberCtrl', ContactSubscriberCtrl);
})();