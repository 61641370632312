(function () {
    'use strict';

    /*
        Munge is a library for transforming data with chainable functions.
     */

    var Munge = function Munge(data) {
        this.data = data;
    };

    Munge.prototype.done = function () {
        return this.data;
    };

    /**
     *  Get value from an object using a dot literal path..
     *  @param Obj object
     *  @param Path string
     */

    var getNestedObjectValue = function getNestedObjectValue(obj, path) {
        path = path.split('.');
        var val = obj;
        for (var prop = 0; prop < path.length; prop++) {
            if (!val[path[prop]]) {
                val = undefined;
                break;
            }
            val = val[path[prop]];
        }
        return val;
    };

    /** *************************************************************************************
            __  ___               ____                 __  _
           /  |/  /___ _____     / __/_  ______  _____/ /_(_)___  ____  _____
          / /|_/ / __ `/ __ \   / /_/ / / / __ \/ ___/ __/ / __ \/ __ \/ ___/
         / /  / / /_/ / /_/ /  / __/ /_/ / / / / /__/ /_/ / /_/ / / / (__  )
        /_/  /_/\__,_/ .___/  /_/  \__,_/_/ /_/\___/\__/_/\____/_/ /_/____/
                    /_/
    ****Map functions********************************************************************* */

    /**
     *
     * @param  {String} key Key value used to
     * @return {[type]}     [description]
     */
    Munge.prototype.byKey = function (key) {

        this.data = this.data.map(function (obj) {
            return getNestedObjectValue(obj, key);
        });

        return this;
    };

    /** *************************************************************************************
            ____           __                   ____                 __  _
           / __ \___  ____/ /_  __________     / __/_  ______  _____/ /_(_)___  ____  _____
          / /_/ / _ \/ __  / / / / ___/ _ \   / /_/ / / / __ \/ ___/ __/ / __ \/ __ \/ ___/
         / _, _/  __/ /_/ / /_/ / /__/  __/  / __/ /_/ / / / / /__/ /_/ / /_/ / / / (__  )
        /_/ |_|\___/\__,_/\__,_/\___/\___/  /_/  \__,_/_/ /_/\___/\__/_/\____/_/ /_/____/
    
    ****Reduce functions****************************************************************** */

    /**
     * Turns an array of arrays into a single flat array.
     * @return {this}
     */
    Munge.prototype.flatten = function () {

        this.data = this.data.reduce(function (acc, obj) {
            return acc.concat(obj);
        });

        return this;
    };

    /** *************************************************************************************
           ____  __      _           __     ____                 __  _
          / __ \/ /_    (_)__  _____/ /_   / __/_  ______  _____/ /_(_)___  ____  _____
         / / / / __ \  / / _ \/ ___/ __/  / /_/ / / / __ \/ ___/ __/ / __ \/ __ \/ ___/
        / /_/ / /_/ / / /  __/ /__/ /_   / __/ /_/ / / / / /__/ /_/ / /_/ / / / (__  )
        \____/_.___/_/ /\___/\___/\__/  /_/  \__,_/_/ /_/\___/\__/_/\____/_/ /_/____/
                  /___/
    ****Object functions****************************************************************** */

    /**
     * Turns an object into an array of the values in the object
     * @return {this}
     */
    Munge.prototype.objValues = function () {

        this.data = Object.keys(this.data).map(function (key) {
            return this.data[key];
        }, this);

        return this;
    };

    Munge.prototype.keyFilter = function (search) {

        var obj = {};

        Object.keys(this.data).forEach(function (key) {

            if (typeof search === 'string') {
                if (key.indexOf(search) === -1) return;
            } else if (search instanceof RegExp) {
                if (!search.test(key)) return;
            }

            obj[key] = this.data[key];
        }, this);

        this.data = obj;

        return this;
    };

    Munge.prototype.objectKeysToArray = function () {
        var array = [];

        angular.forEach(this.data, function (value, key) {
            if (value) array.push(key);
        });

        this.data = array;

        return this;
    };

    /** *************************************************************************************
            ___                             ____                 __  _
           /   |  ______________ ___  __   / __/_  ______  _____/ /_(_)___  ____  _____
          / /| | / ___/ ___/ __ `/ / / /  / /_/ / / / __ \/ ___/ __/ / __ \/ __ \/ ___/
         / ___ |/ /  / /  / /_/ / /_/ /  / __/ /_/ / / / / /__/ /_/ / /_/ / / / (__  )
        /_/  |_/_/  /_/   \__,_/\__, /  /_/  \__,_/_/ /_/\___/\__/_/\____/_/ /_/____/
                               /____/
    ****Array functions******************************************************************* */

    /**
     * Turns an array into an object with the items of the array as values
     * and keys based on a value of the object
     * @param  {string} key        Attribute to use as the key in the object
     * @param  {string} [valueKey] Attribute to use as the value against the key
     * @return {this}
     */
    Munge.prototype.toObjectByKey = function (key, valueKey) {

        var obj = {};

        this.data.forEach(function (val) {

            var objKey = getNestedObjectValue(val, key);
            if (!objKey) return;

            if (valueKey) obj[objKey] = getNestedObjectValue(val, valueKey);else obj[objKey] = val;
        });

        this.data = obj;

        return this;
    };

    /**
     * Turns an array of vales into an object with those values as Keys with the value passed in
     * @param  {any}  defaultValue Value to set to all keys in result object
     * @return {this}
     */
    Munge.prototype.toObjectKeys = function (defaultValue) {

        defaultValue = !arguments.length ? true : defaultValue;
        var obj = {};

        angular.forEach(this.data, function (value) {
            obj[value] = defaultValue;
        });

        this.data = obj;

        return this;
    };

    /** *************************************************************************************
            ______           __                      ____                 __  _
           / ____/___ ______/ /_____  _______  __   / __/_  ______  _____/ /_(_)___  ____
          / /_  / __ `/ ___/ __/ __ \/ ___/ / / /  / /_/ / / / __ \/ ___/ __/ / __ \/ __ \
         / __/ / /_/ / /__/ /_/ /_/ / /  / /_/ /  / __/ /_/ / / / / /__/ /_/ / /_/ / / / /
        /_/    \__,_/\___/\__/\____/_/   \__, /  /_/  \__,_/_/ /_/\___/\__/_/\____/_/ /_/
                                        /____/
    ****factor function******************************************************************* */

    function mungeFactory() {
        return function munge(data) {
            return new Munge(data);
        };
    }

    angular.module('GeckoEngage').factory('munge', mungeFactory);
})();