(function () {
    'use strict';

    // Supported langs

    var LANG_CATALAN = 'ca';
    var LANG_WELSH = 'cy';
    var LANG_ENGLISH = 'en';
    //var LANG_ENGLISH_GB = 'en-gb';
    //var LANG_ENGLISH_US = 'en-us';
    var LANG_SPANISH = 'es';
    var LANG_GERMAN = 'de';
    var LANG_CHINESE = 'zh-cn';

    var STR_KEY = {};

    STR_KEY.BUTTON = {};
    STR_KEY.BUTTON.ADD = 'button.add';
    STR_KEY.BUTTON.CONTINUE = 'button.continue';
    STR_KEY.BUTTON.CLOSE = 'button.close';
    STR_KEY.BUTTON.EDIT = 'button.edit';
    STR_KEY.BUTTON.NEXT = 'button.next';
    STR_KEY.BUTTON.PREVIOUS = 'button.previous';
    STR_KEY.BUTTON.REMOVE = 'button.remove';
    STR_KEY.BUTTON.SAVE = 'button.save';
    STR_KEY.BUTTON.SUBMIT = 'button.submit';
    STR_KEY.BUTTON.UPLOAD = 'button.upload';

    STR_KEY.INPUT = {};
    STR_KEY.INPUT.FIRST_NAME = 'input.first_name';
    STR_KEY.INPUT.LAST_NAME = 'input.last_name';
    STR_KEY.INPUT.DATE = 'input.date';
    STR_KEY.INPUT.DAY = 'input.day';
    STR_KEY.INPUT.MONTH = 'input.month';
    STR_KEY.INPUT.YEAR = 'input.year';
    STR_KEY.INPUT.ADDRESS_STREET = 'input.address_street';
    STR_KEY.INPUT.ADDRESS_LINE_2 = 'input.address_line_2';
    STR_KEY.INPUT.ADDRESS_CITY = 'input.address_city';
    STR_KEY.INPUT.ADDRESS_REGION = 'input.address_region';
    STR_KEY.INPUT.ADDRESS_POSTCODE = 'input.address_postcode';
    STR_KEY.INPUT.ADDRESS_COUNTRY = 'input.address_country';
    STR_KEY.INPUT.DROPDOWN = 'input.dropdown';
    STR_KEY.INPUT.VERIFY_EMAIL = 'input.verify_email';

    STR_KEY.FILE_UPLOAD_FIELD = {};
    STR_KEY.FILE_UPLOAD_FIELD.ERROR_1 = 'file_upload_field.error_1';
    STR_KEY.FILE_UPLOAD_FIELD.ERROR_2 = 'file_upload_field.error_2';
    STR_KEY.FILE_UPLOAD_FIELD.ERROR_3 = 'file_upload_field.error_3';
    STR_KEY.FILE_UPLOAD_FIELD.CHOOSE_A_FILE = 'file_upload_field.choose_a_file';
    STR_KEY.FILE_UPLOAD_FIELD.DRAG_FILES_HERE = 'file_upload_field.drag_files_here';

    STR_KEY.ORGANISATION_FIELD = {};
    STR_KEY.ORGANISATION_FIELD.TYPE_TO_SEARCH = 'organisation_field.type_to_search';
    STR_KEY.ORGANISATION_FIELD.NO_RESULTS_FOUND = 'organisation_field.no_results_found';
    STR_KEY.ORGANISATION_FIELD.SEARCHING = 'organisation_field.searching';

    STR_KEY.CONSENT_FIELD = {};
    STR_KEY.CONSENT_FIELD.CONSENTS_LOADING = 'consent_field.consents_loading';
    STR_KEY.CONSENT_FIELD.NO_CONSENTS = 'consent_field.no_consents';
    STR_KEY.CONSENT_FIELD.CONSENT_TO_ALL = 'consent_field.consent_to_all';
    STR_KEY.CONSENT_FIELD.REMOVE_ALL = 'consent_field.remove_all';
    STR_KEY.CONSENT_FIELD.PRIVACY_POLICY = 'consent_field.privacy_policy';

    STR_KEY.ALERT = {};
    STR_KEY.ALERT.VALIDATION_DESCRIPTION = 'alert.validation_description';
    STR_KEY.ALERT.NO_MORE_ENTRIES_ALLOWED = 'alert.no_more_entries_allowed';
    STR_KEY.ALERT.MAXIMUM_ENTRIES_ALLOWED = 'alert.maximum_entries_allowed';
    STR_KEY.ALERT.SUCCESS = 'alert.success';
    STR_KEY.ALERT.EVENT_ENDED = 'alert.event_ended';
    STR_KEY.ALERT.FORM_CLOSED = 'alert.form_closed';
    //STR_KEY.ALERT.LOADING_ERROR            = 'alert.loading_error';
    STR_KEY.ALERT.SUBMITTING = 'alert.submitting';
    STR_KEY.ALERT.FULLY_BOOKED = 'alert.fully_booked';
    STR_KEY.ALERT.NO_SESSIONS_ON_DATES = 'alert.no_sessions_on_dates';
    STR_KEY.ALERT.NO_SESSIONS_AVAILABLE = 'alert.no_sessions_available';

    STR_KEY.MISC = {};
    STR_KEY.MISC.TOTAL_CHARACTERS = 'misc.total_characters';
    STR_KEY.MISC.POWERED_BY = 'misc.powered_by';
    STR_KEY.MISC.PAGE = 'misc.page';
    STR_KEY.MISC.DISPLAYING = 'misc.displaying';
    STR_KEY.MISC.OF = 'misc.of';
    STR_KEY.MISC.SESSIONS = 'misc.sessions';

    STR_KEY.EVENT_FIELD = {};
    STR_KEY.EVENT_FIELD.EVENT = 'event_field.event';
    STR_KEY.EVENT_FIELD.SESSION = 'event_field.session';
    STR_KEY.EVENT_FIELD.ADD = 'event_field.add';
    STR_KEY.EVENT_FIELD.REGISTER = 'event_field.register';
    STR_KEY.EVENT_FIELD.BOOK = 'event_field.book';
    STR_KEY.EVENT_FIELD.CANCEL = 'event_field.cancel';
    STR_KEY.EVENT_FIELD.SELECT_TIME = 'event_field.select_time';
    STR_KEY.EVENT_FIELD.WAITLISTED = 'event_field.waitlisted';
    STR_KEY.EVENT_FIELD.FULL = 'event_field.full';
    STR_KEY.EVENT_FIELD.WAITLIST = 'event_field.waitlist';
    STR_KEY.EVENT_FIELD.JOIN_WAITLIST = 'event_field.join_waitlist';
    STR_KEY.EVENT_FIELD.YOUR_BOOKING = 'event_field.your_booking';
    STR_KEY.EVENT_FIELD.VIEW_EVENT_INFO = 'event_field.view_event_info';
    STR_KEY.EVENT_FIELD.VIEW_SESSION_INFO = 'event_field.view_session_info';
    STR_KEY.EVENT_FIELD.PICK_EVENT = 'event_field.pick_event';
    STR_KEY.EVENT_FIELD.PICK_DATE = 'event_field.pick_date';
    STR_KEY.EVENT_FIELD.NUMBER_OF_GUESTS = 'event_field.number_of_guests';
    STR_KEY.EVENT_FIELD.CONFIG_ERROR = 'event_field.config_error';
    STR_KEY.EVENT_FIELD.LOADING = 'event_field.loading';
    STR_KEY.EVENT_FIELD.NO_RESULTS = 'event_field.no_results';
    STR_KEY.EVENT_FIELD.NO_SESSION_RESULTS = 'event_field.no_session_results';
    STR_KEY.EVENT_FIELD.CLASHING_SESSIONS_ENABLED = 'event_field.clashing_sessions_enabled';
    STR_KEY.EVENT_FIELD.CLASHING_SESSIONS_DISABLED = 'event_field.clashing_sessions_disabled';
    STR_KEY.EVENT_FIELD.WAITLIST_ALERT_EVENT = 'event_field.waitlist_alert_event';
    STR_KEY.EVENT_FIELD.WAITLIST_QUESTION = 'event_field.waitlist_question';
    STR_KEY.EVENT_FIELD.WAITLIST_ALERT_SESSION = 'event_field.waitlist_alert_session';
    STR_KEY.EVENT_FIELD.FILTER_KEYWORD = 'event_field.filter_keyword';
    STR_KEY.EVENT_FIELD.FILTER_TAG = 'event_field.filter_tag';
    STR_KEY.EVENT_FIELD.FILTER_SORT = 'event_field.filter_sort';
    STR_KEY.EVENT_FIELD.FILTER_SORT_TITLE = 'event_field.filter_sort_title';
    STR_KEY.EVENT_FIELD.FILTER_SORT_DATE = 'event_field.filter_sort_date';
    STR_KEY.EVENT_FIELD.FILTER_NO_RESULTS = 'event_field.filter_no_results';
    STR_KEY.EVENT_FIELD.FILTER_FILTERED_BY = 'event_field.filter_filtered_by';
    STR_KEY.EVENT_FIELD.FILTER_CLEAR = 'event_field.filter_clear';
    STR_KEY.EVENT_FIELD.MODAL_ABOUT_THE_HOST = 'event_field.modal_about_the_host';
    STR_KEY.EVENT_FIELD.MODAL_ABOUT_THE_HOSTS = 'event_field.modal_about_the_hosts';
    STR_KEY.EVENT_FIELD.MODAL_ABOUT_THE_EVENT = 'event_field.modal_about_the_event';
    STR_KEY.EVENT_FIELD.MODAL_ABOUT_THE_SESSION = 'event_field.modal_about_the_session';
    STR_KEY.EVENT_FIELD.MODAL_LOCATION = 'event_field.modal_location';
    STR_KEY.EVENT_FIELD.MODAL_GET_DIRECTIONS = 'event_field.modal_get_directions';
    STR_KEY.EVENT_FIELD.ONLINE = 'event_field.online';
    STR_KEY.EVENT_FIELD.VARIOUS_TIMES = 'event_field.various_times';
    STR_KEY.EVENT_FIELD.VENUE_EVENT = 'event_field.venue_event';
    STR_KEY.EVENT_FIELD.ONLINE_EVENT = 'event_field.online_event';
    STR_KEY.EVENT_FIELD.HYBRID_EVENT = 'event_field.hybrid_event';
    STR_KEY.EVENT_FIELD.VENUE_SESSION = 'event_field.venue_session';
    STR_KEY.EVENT_FIELD.ONLINE_SESSION = 'event_field.online_session';
    STR_KEY.EVENT_FIELD.HYBRID_SESSION = 'event_field.hybrid_session';

    function geckoTranslateService() {
        var _this = {

            lang: null,

            keys: STR_KEY,

            data: {},

            languages: [{
                id: LANG_CATALAN,
                name: 'Catalan - ' + LANG_CATALAN
            }, {
                id: LANG_CHINESE,
                name: 'Chinese - ' + LANG_CHINESE
            }, {
                id: LANG_ENGLISH,
                name: 'English - ' + LANG_ENGLISH
            }, {
                id: LANG_GERMAN,
                name: 'German - ' + LANG_GERMAN
            }, {
                id: LANG_SPANISH,
                name: 'Spanish - ' + LANG_SPANISH
            }, {
                id: LANG_WELSH,
                name: 'Welsh - ' + LANG_WELSH
            }],

            get: function get(key) {
                if ((key || '').indexOf('.') !== -1) {
                    var keys = key.split('.');
                    var value = _this.data;
                    keys.forEach(function (k) {
                        if (value[k]) {
                            value = value[k];
                        } else {
                            value = '';
                        }
                    });
                    return value;
                }
                return _this.data[key];
            },

            init: function init(lang, data, scope) {
                // Set lang
                _this.lang = _this.languages.find(function (l) {
                    return l.id === lang;
                }) ? lang : LANG_ENGLISH;
                // Load bindings
                _this.data = data;
            },

            prepare: function prepare(scope) {
                scope.__ = _this.get;
                scope.STR_KEY = _this.keys;
                return scope;
            },

            prepareCustomLanguageDisplayValues: function prepareCustomLanguageDisplayValues(values) {
                return (Array.isArray(values) ? values : []).map(function (v) {
                    return v.value;
                });
            },

            prepareCustomLanguageSaveValues: function prepareCustomLanguageSaveValues(values) {
                return (Array.isArray(values) ? values : []).map(function (v) {
                    return { value: v, selected: false };
                });
            },

            prepareCustomLanguageOptions: function prepareCustomLanguageOptions(values) {
                return (Array.isArray(values) ? values : []).map(function (v) {
                    return { value: v.value, label: (_this.languages.find(function (l) {
                            return l.id === v.value;
                        }) || {}).name };
                });
            },

            renderLanguageValue: function renderLanguageValue(value) {
                if (Array.isArray(value)) {
                    return value.length ? value.map(function (v) {
                        return (_this.languages.find(function (l) {
                            return l.id === v;
                        }) || {}).name;
                    }).filter(function (v) {
                        return v;
                    }).join(', ') : '';
                }
                return (_this.languages.find(function (l) {
                    return l.id === value;
                }) || {}).name || '';
            }

        };
        return _this;
    }

    angular.module('GeckoEngage').service('geckoTranslateService', geckoTranslateService);
})();