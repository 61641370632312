(function () {
    'use strict';

    angular.module('directives').directive("selectField", function () {
        return {
            controller: function controller($scope) {

                $scope.fieldOptions[$scope.field.id] = [];

                $scope.convertOptions = function (options) {

                    if (options == '[]') return;
                    // Pre-process options
                    var _options = [];

                    angular.forEach(options, function (value, index) {
                        _options.push({
                            index: index,
                            value: value
                        });
                    });

                    return _options;
                };

                if ($scope.field.option_id !== null && $scope.field.option !== undefined) {
                    $scope.fieldOptions[$scope.field.id] = $scope.convertOptions($scope.field.option.values);
                } else {
                    $scope.fieldOptions[$scope.field.id] = $scope.convertOptions($scope.field.values);
                }

                $scope.updateValue = function () {

                    if ($scope.fieldOptions[$scope.field.id][$scope.selectedValue[$scope.field.id]] !== undefined) {

                        if ($scope.field.children !== undefined && $scope.field.children.length > 0) {
                            if ($scope.fieldOptions[$scope.field.id][$scope.selectedValue[$scope.field.id]].value.options !== undefined) {
                                angular.forEach($scope.field.children, function (child) {
                                    $scope.fieldOptions[child.id] = $scope.convertOptions($scope.fieldOptions[$scope.field.id][$scope.selectedValue[$scope.field.id]].value.options);
                                    angular.element("#field" + child.id).show();
                                });
                            } else {
                                angular.element("#field" + $scope.field.child.id).hide();
                            }
                        }

                        $scope.values[$scope.field.id] = $scope.fieldOptions[$scope.field.id][$scope.selectedValue[$scope.field.id]].value.value;
                    } else {
                        // Reset to default values
                        $scope.values[$scope.field.id] = "";
                        if ($scope.field.children.length) {
                            angular.forEach($scope.field.children, function (child) {
                                $scope.values[child.id] = "";
                                $scope.selectedValue[child.id] = "";
                                $scope.fieldOptions[child.id] = [];
                            });
                        }
                    }
                };

                $scope.getField = function ($id) {
                    if ($id === undefined) {
                        return false;
                    }
                    var _field = {};

                    angular.forEach($scope.form.related_fields, function (field) {
                        if ($id === field.id) {
                            _field = field;
                        }
                    });

                    return _field;
                };
            },
            scope: false,
            restrict: 'A',
            templateUrl: '/views/fields/select.8be9fb0e.html'
        };
    }).directive("googleTagManager", function () {
        return {};
    }).directive("addressSearch", function () {
        return {
            controller: function controller($scope) {
                if ($scope.field.settings && Number($scope.field.settings.auto_populate) === 1) {
                    $scope.addressSearchEnabled = true;
                } else {
                    $scope.addressSearchEnabled = false;
                }

                function convertOptions(options) {

                    if (options == '[]') return;
                    // Pre-process options
                    var _options = [];

                    angular.forEach(options, function (value, index) {
                        _options.push(value.value);
                    });

                    return _options;
                }

                if ($scope.field.option_id !== 0 && $scope.field.option_id !== null) {
                    $scope.addressCountries = convertOptions($scope.field.option.values);
                } else {
                    $scope.addressCountries = countries;
                }

                if ($scope.addressSearchEnabled) {

                    var script = document.createElement("script");
                    script.src = "https://services.postcodeanywhere.co.uk/js/captureplus-2.30.min.js?key=" + $scope.field.settings.auto_populate_key;
                    script.type = "text/javascript";
                    document.getElementsByTagName("head")[0].appendChild(script);

                    var link = document.createElement("link");
                    link.href = "https://services.postcodeanywhere.co.uk/css/captureplus-2.30.min.css?key=" + $scope.field.settings.auto_populate_key;
                    link.type = "text/css";
                    link.rel = "stylesheet";
                    document.getElementsByTagName("head")[0].appendChild(link);

                    setTimeout(function () {
                        var fields = [{ element: "_address_search", field: "", mode: pca.fieldMode.SEARCH }];

                        try {
                            var control = new pca.Address(fields, { key: $scope.field.settings.auto_populate_key });
                            control.load();

                            control.listen("populate", function (address) {

                                $scope.values[$scope.field.id].street_address = address.Line1;
                                $scope.values[$scope.field.id].address_line_2 = address.Line2;
                                $scope.values[$scope.field.id].city = address.City;
                                $scope.values[$scope.field.id].state = address.Province;
                                $scope.values[$scope.field.id].postal_code = address.PostalCode;
                                $scope.values[$scope.field.id].country = address.CountryName;

                                $scope.$digest();
                            });
                        } catch (err) {}
                    }, 2000);
                }
            },
            scope: false,
            restrict: 'A',
            templateUrl: '/public/views/_fields/address.59886999.html'
        };
    });
})();