(function () {

    'use strict';

    function DataSecurityRoute($stateProvider) {
        $stateProvider.state('datasecurity', {
            url: '/settings/data-security',
            templateUrl: "/modules/data_security/views/datasecurity.44e6c6b6.html",
            controller: 'DataSecurityCtrl',
            controllerAs: 'ctrl',
            page_title: 'Data Security'
        }).state('datasecurity.preferences', {
            url: '/preferences',
            templateUrl: '/modules/data_security/views/preferences.f619ad4a.html',
            controller: 'DataSecurityPreferencesCtrl',
            controllerAs: 'ctrl',
            page_title: 'Preferences',
            redirectKey: 'module_data_security_preferences',
            //requires     : [Gecko.ABILITY_CONSENTS_VIEW],
            resolve: {
                account: function account() {
                    return new Gecko.Account().rfields({ account: ['privacy_policy', 'auto_delete_contact_days', 'color'] }).get(Gecko.account.id);
                }
            }
        }).state('datasecurity.activity', {
            url: '/activity?{page}&{order}&{sort}&{from}&{to}&{action}&{user}&{type}',
            templateUrl: '/modules/data_security/views/activity.477fb692.html',
            controller: 'DataSecurityActivityCtrl',
            controllerAs: 'ctrl',
            page_title: 'Activity Log',
            redirectKey: 'module_data_security_activity_log',
            requires: [Gecko.ABILITY_ACTIVITY_VIEW, Gecko.ABILITY_AUDIT],
            resolve: {
                activities: function activities($stateParams) {
                    var activities = new Gecko.Activity().include('user' /*,'actionable'*/).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1);

                    if ($stateParams.type) {
                        activities.where('model', $stateParams.type);
                    }

                    if ($stateParams.user) {
                        activities.where('user_id', $stateParams.user);
                    }

                    if ($stateParams.action) {
                        activities.where('type', $stateParams.action);
                    }

                    if ($stateParams.from && $stateParams.to) {
                        activities.dates($stateParams.from, $stateParams.to);
                    }

                    var order = $stateParams.order !== undefined ? $stateParams.order : null;
                    var sort = $stateParams.sort !== undefined ? $stateParams.sort : null;
                    activities.orderBy(order, sort);

                    return activities.rfields({ user: ['full_name', 'color'], activity: ['type', 'user_id', 'actionable_model_name', 'created_at', 'message'] }).get();
                },
                integrations: function integrations(geckoDataService) {
                    return geckoDataService.fetch(['integrations']).then(function (data) {
                        return data.integrations;
                    });
                },
                filterValues: function filterValues($stateParams, asyncOptions) {
                    return asyncOptions.getSelectedValues({
                        user: { model: 'User', id: $stateParams.user, fields: { user: ['full_name'] } }
                    });
                }
            }
        }).state('datasecurity.deletedcontacts', {
            url: '/deleted-contacts?{page}&{order}&{sort}&{type}&{search}', //&{action}&{user}&{type}
            templateUrl: '/modules/data_security/views/deletedcontacts.3e35700e.html',
            controller: 'DataSecurityDeletedContactsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Deleted Contacts',
            redirectKey: 'module_data_security_deleted_contacts',
            resolve: {
                contactRfields: function contactRfields() {
                    return {
                        contact: ['full_name', 'email', 'delete_reason', 'deleted_at']
                    };
                },
                contacts: function contacts($stateParams, contactRfields) {
                    var contacts = new Gecko.Contact().rfields(contactRfields).where('contact_trashed', 'only');
                    if ($stateParams.search) contacts.where('contact_keyword', $stateParams.search);
                    if ($stateParams.type) contacts.where('delete_reason', $stateParams.type);
                    return contacts.orderBy($stateParams.order, $stateParams.sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                }
            }
        }).state('datasecurity.consentreasons', {
            url: '/consent-reasons',
            templateUrl: '/modules/data_security/views/consentreasons.d1ba6a0c.html',
            controller: 'DataSecurityConsentReasonsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Consent Reasons',
            redirectKey: 'module_data_security_consent_reasons',
            requires: [Gecko.ABILITY_CONSENTS_VIEW],
            resolve: {
                reasons: function reasons() {
                    return new Gecko.Consent().perPage('all').orderBy('order').get();
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(DataSecurityRoute);
})();