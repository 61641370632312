(function () {
    'use strict';

    function inputAllowTab() {

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function link(scope, iElement, iAttrs, ngModel) {
                iElement.on('keydown', function (event) {

                    if (event.key === 'Tab') {
                        // Get caret position/selection
                        var start = iElement[0].selectionStart;
                        var end = iElement[0].selectionEnd;

                        var value = ngModel.$viewValue;

                        // Set textarea value to: text before caret + tab + text after caret
                        ngModel.$setViewValue(value.substring(0, start) + '\t' + value.substring(end));

                        ngModel.$render();
                        // Put caret at right position again (add one for the tab)
                        iElement[0].selectionStart = iElement[0].selectionEnd = start + 1;

                        // Prevent the focus lose
                        event.preventDefault();
                        return false;
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('inputAllowTab', inputAllowTab);
})();