(function () {
    'use strict';

    function TemplateSettingsCtrl($scope, $state, $stateParams, unsavedAlert, template, consents, categories, asyncOptions, messageService, templateService) {

        var ctrl = this;
        ctrl.template = template;

        ctrl.template.categories = ctrl.template.related_categories ? ctrl.template.related_categories.map(function (cat) {
            return cat.toObject();
        }) : [];

        if ($stateParams.template_id == 'new') ctrl.template.module = 'form';
        if (!ctrl.template.type) ctrl.template.type = Gecko.Template.TYPE_EMAIL;

        //Returns Editor Type based on type & disabled_editor or existing editor_type
        ctrl.template.editor_type = templateService.getEditorType(ctrl.template.type, ctrl.template.disable_editor, ctrl.template.editor_type);

        ctrl.prepareConsentOptions = function (type) {
            if (type) {
                return consents.filter(function (o) {
                    return o['group_' + type];
                });
            }
            return consents.filter(function (o) {
                return o['group_' + Gecko.Template.TYPE_EMAIL] || o['group_' + Gecko.Template.TYPE_SMS];
            });
        };

        // Parse categories
        var prepareCategory = function prepareCategory(category) {
            category.optgroup = 'Existing Categories';
            return category;
        };
        ctrl.categories = categories.map(prepareCategory);

        ctrl.prepareConsentIds = function (consents) {
            return (consents || ctrl.template.related_current_granted_consents || []).map(function (c) {
                return c.id;
            });
        };

        ctrl.prepareConsentIdField = function (type) {
            return {
                label: 'Check the following consent reasons',
                id: type + '_consent_ids',
                required: true,
                type: Gecko.Field.TYPE_MULTI,
                colClass: 'col-xs-12',
                options: ctrl.prepareConsentOptions(type),
                optionsKey: 'id',
                optionsLabelKey: 'title',
                description: 'Contacts will recieve messages from this template if they have consented to the above',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK) || ctrl.template.type !== type;
                }
            };
        };

        // Map consents ids (to type)
        if ($stateParams.template_id === 'new') {
            ctrl.template[Gecko.Template.TYPE_EMAIL + '_consent_ids'] = ctrl.prepareConsentIds(consents.filter(function (o) {
                return o['group_' + Gecko.Template.TYPE_EMAIL];
            }));
            ctrl.template[Gecko.Template.TYPE_SMS + '_consent_ids'] = ctrl.prepareConsentIds(consents.filter(function (o) {
                return o['group_' + Gecko.Template.TYPE_SMS];
            }));
        } else {
            ctrl.template[Gecko.Template.TYPE_EMAIL + '_consent_ids'] = ctrl.prepareConsentIds();
            ctrl.template[Gecko.Template.TYPE_SMS + '_consent_ids'] = ctrl.prepareConsentIds();
        }

        if ($stateParams.template_id === 'new') {
            // Footer buttons
            ctrl.footerBtns = [{
                preset: 'next',
                action: function action() {
                    return ctrl.saveTemplate();
                }
            }];
        }

        // Email or SMS radio buttons
        ctrl.typeFields = [{
            id: 'type',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            disabled: $stateParams.template_id !== 'new',
            options: [{
                value: Gecko.Template.TYPE_EMAIL,
                icon: 'fa-envelope',
                title: 'Email'
            }, {
                value: Gecko.Template.TYPE_SMS,
                icon: 'fa-comment',
                title: 'SMS'
            }]
        }];

        // WYSIWYG, Basic or DRAG & DROP radio buttons for Email Editor Type
        ctrl.templateFields = [{
            id: 'editor_type',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            disabled: $stateParams.template_id !== 'new',
            hideWhen: function hideWhen() {
                return ctrl.template.type === Gecko.Template.TYPE_SMS;
            },
            options: [{
                value: Gecko.Template.EDITOR_TYPE_WYSIWYG,
                icon: 'fa-font',
                title: 'Basic Editor'
            }, {
                value: Gecko.Template.EDITOR_TYPE_DRAGDROP,
                icon: 'fa-mouse-pointer',
                title: 'Drag & Drop',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_TEMPLATES_DRAGDROP);
                }
            }, {
                value: Gecko.Template.EDITOR_TYPE_BASIC,
                icon: 'fa-code',
                title: 'Import HTML'
            }]
        }];

        ctrl.fields = [{
            id: 'name',
            label: 'Name',
            description: 'This will be used to identify this template within Gecko.',
            colClass: 'col-xs-6',
            required: true
        }, {
            id: 'subject',
            label: 'Subject',
            description: 'Set the subject line for your email.',
            colClass: 'col-xs-6',
            required: true,
            hideWhen: function hideWhen() {
                return ctrl.template.type == Gecko.Template.TYPE_SMS;
            }
        }, ctrl.prepareConsentIdField(Gecko.Template.TYPE_EMAIL), ctrl.prepareConsentIdField(Gecko.Template.TYPE_SMS), {
            label: 'Category',
            id: 'categories',
            required: false,
            type: Gecko.Field.TYPE_TAGGER,
            colClass: 'col-xs-12',
            options: ctrl.categories,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            description: 'Allows you to easily track and group your templates',
            newItem: function newItem(string) {
                return {
                    title: string,
                    type: 'template'
                };
            }
        }];

        ctrl.saveTemplate = function () {

            // Set consent ids (get from type)
            ctrl.template.consent_ids = ctrl.template[ctrl.template.type + '_consent_ids'];

            // Returns Editor Type based on type & disabled_editor or existing editor_type - required here for SMS editor type to save
            ctrl.template.editor_type = templateService.getEditorType(ctrl.template.type, ctrl.template.disable_editor, ctrl.template.editor_type);

            // Set default include_wrapper value
            if ($stateParams.template_id == 'new') {
                ctrl.template.include_wrapper = templateService.setIncludeWrapper(ctrl.template.editor_type);
            }

            // Save the template
            ctrl.template.save().then(function (data) {
                GeckoUI.messenger.success('Template has been saved');

                // Clear unsaved alert
                unsavedAlert.clear();

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Template Save Success', {
                    'template id': data.id || 'New',
                    'template title': data.name
                });

                // Reload page after creating new template
                if ($stateParams.template_id == 'new') {
                    return $state.go('settingstemplate.editor', {
                        template_id: data.id
                    }, { reload: true });
                }

                ctrl.template = data;
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Template Save Error', {
                    'template id': ctrl.template.id || 'New',
                    'template title': ctrl.template.name,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        $scope.$on('saveTemplate', ctrl.saveTemplate);
    }

    angular.module('GeckoEngage').controller('TemplateSettingsCtrl', TemplateSettingsCtrl);
})();