(function () {
    'use strict';

    function AttachmentsModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('attachment', function (_model, _id) {
            return {
                templateUrl: '/modules/attachments/attachment-modal.a10a4f19.html',
                controllerAs: 'ctrl',
                controller: 'attachmentModalCtrl',
                resolve: {
                    model: function model() {
                        return _model;
                    },
                    id: function id() {
                        return _id;
                    }
                }
            };
        });
    }

    function attachmentModalCtrl($modalInstance, model, id, geckoFileUploadService) {
        var ctrl = this;

        ctrl.values = {
            file: undefined,
            title: ''
        };

        ctrl.fields = [{
            id: 'title',
            label: 'Title',
            length: 255,
            required: true,
            colClass: 'col-md-12'
        }, {
            id: 'file',
            label: 'File',
            type: Gecko.Field.TYPE_FILE,
            mimetype: geckoFileUploadService.defaultAllowedMimetypes,
            required: true,
            colClass: 'col-md-12'
        }];

        ctrl.footerBtns = [{
            title: 'Attach',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            action: function action() {

                var val = [];
                if (!ctrl.values.title) val.push('Title is required');

                if (!ctrl.values.file) val.push('File is required');

                if (val.length) {
                    GeckoUI.messenger.error(val.join(', '));
                    return false;
                }

                var file = new Gecko.File(); // create file

                // Set contact/organisation id
                file[model + '_id'] = id;
                file.title = ctrl.values.title || ctrl.values.file.filename;
                file.file = ctrl.values.file;
                file.type = Gecko.File.ATTACHMENT;

                file.include('user', 'contact').save().then(function (newFile) {

                    GeckoUI.messenger.success('Attachment successfully added to ' + model);

                    $modalInstance.close(newFile);
                }).catch(function (error) {
                    GeckoUI.messenger.error(error.errors);
                });
            }
        }];
    }

    angular.module('GeckoEngage').config(AttachmentsModal).controller('attachmentModalCtrl', attachmentModalCtrl);
})();