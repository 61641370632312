(function () {
    'use strict';

    function RecurringImportEditWorkflowsCtrl($state, $scope, csv_import, multi_data) {
        var ctrl = this;

        ctrl.csv_import = csv_import.toInstance();
        ctrl.csv_import.related_workflows = ctrl.csv_import.related_workflows || [];
        ctrl.multi_data = multi_data;

        ctrl.saveChanges = function () {
            ctrl.csv_import.workflows = Gecko.convertInstancesToObjects(ctrl.csv_import.related_workflows);

            angular.copy(ctrl.csv_import).include('workflows', 'workflows.condtions').save().then(function () {
                // Tell the user it was ok
                GeckoUI.messenger.success('Import workflows saved');
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Recurring Import Workflows saved', {
                    'import id': ctrl.csv_import.id,
                    'import name': ctrl.csv_import.title
                });
                $state.reload();
            }).catch(function (errors) {
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Recurring Import Workflows save error', {
                    'import id': ctrl.csv_import.id,
                    'import name': ctrl.csv_import.title,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        // When we recieve the save event from the top level edit route save the Import
        $scope.$on('saveImport', ctrl.saveChanges);
    }
    angular.module('GeckoEngage').controller('RecurringImportEditWorkflowsCtrl', RecurringImportEditWorkflowsCtrl);
})();