(function () {
    'use strict';

    function noUnsaved() {

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function link(scope, iElement, iAttrs, modelCtrl) {
                iElement.on('focus', function () {
                    // NgModel only sets parent form dirty if the control changes when it was pristine.
                    // So setting the control to dirty prevents that.
                    // We do this on focus incase the control is set pristine again.
                    modelCtrl.$dirty = true;
                    modelCtrl.$pristine = false;
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('noUnsaved', noUnsaved);
})();