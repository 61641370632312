(function () {
    'use strict';

    function unix2Format($filter) {
        return function (date, format) {
            if (date === undefined || !date || typeof date != 'number') return false;
            format = format !== undefined ? format : 'd MMM yyyy HH:mm';
            return $filter('date')(date * 1000, format);
        };
    }

    function dateOnly($filter) {
        return function (date) {
            return date - parseInt($filter('unix2Format')(date, 'HH')) * 60 * 60 - parseInt($filter('unix2Format')(date, 'mm')) * 60;
        };
    }

    function datePlusTime($filter) {
        return function (date, hours, minutes) {
            if (hours) {
                date += parseInt(hours) * 60 * 60;
            }
            if (minutes) {
                date += parseInt(minutes) * 60;
            }
            return date;
        };
    }

    function unixtodate() {
        return function (text) {
            return moment(text * 1000).fromNow();
        };
    }

    function datetounix() {
        return function (text) {
            text = String(text);

            if (text.indexOf('/')) {
                var peices = text.split('/');
                return moment().set('day', peices[0]).set('month', peices[1]).set('year', peices[2]).unix();
            } else {
                return null;
            }
        };
    }

    function unix2date() {
        return function (text) {
            return moment(text * 1000).toDate();
        };
    }

    function secondsAsTime() {
        return function (secs) {
            var hr = Math.floor(secs / 3600);
            var min = Math.floor((secs - hr * 3600) / 60);
            var sec = Math.floor(secs - hr * 3600 - min * 60);

            if (hr < 10) {
                hr = "0" + hr;
            }
            if (min < 10) {
                min = "0" + min;
            }
            if (sec < 10) {
                sec = "0" + sec;
            }

            return hr + ':' + min + ':' + sec;
        };
    }

    function nicedate() {
        return function (value) {
            if (isNaN(value)) return '';

            return moment(value).format("ddd, Do MMM YYYY @ HH:mm");
        };
    }

    function humanize() {
        return function (time, isTime) {
            if (isTime) {
                var time = Number(time),
                    hours = Math.floor(time / 3600),
                    minutes = Math.floor((time - hours * 60 * 60) / 60),
                    seconds = Math.floor(time - hours * 60 * 60 - minutes * 60);
                if (time > 3600) {
                    return (hours ? hours + "h " : "") + (minutes ? minutes + "m " : "") + (seconds ? seconds + "s" : "0s");
                    // Minutes
                } else if (time >= 60) {
                    return (minutes ? minutes + "m " : "") + (seconds ? seconds + "s" : "0s");
                    // Seconds
                } else {
                    return seconds ? seconds + "s" : "0s";
                }
            } else {
                var time = Number(time),
                    unit = "";
                if (time >= 86400) {
                    time = time / 86400;
                    unit = 'Days';

                    // Hours
                } else if (time > 3600) {
                    time = time / 3600;
                    unit = 'Hours';

                    // Minutes
                } else if (time >= 60) {
                    time = time / 60;
                    unit = 'Minutes';

                    // Seconds
                } else {
                    if (time < 0) return '';
                    unit = 'Seconds';
                }
                return Math.round(time) + " " + unit;
            }
        };
    }

    function formatDate() {
        return function (date, format, utc) {
            var _format = format || (Gecko.dateFormat && Gecko.dateFormat.longTime ? Gecko.dateFormat.longTime : 'MMM Do YYYY [at] HH:mm');
            if (utc) {
                return date ? moment.utc(date, 'X').format(_format) : '';
            }
            return date ? moment(date, 'X').format(_format) : '';
        };
    }

    function formatDateUTC() {
        return function (date, format) {
            var _format = format || (Gecko.dateFormat && Gecko.dateFormat.longTime ? Gecko.dateFormat.longTime : 'MMM Do YYYY [at] HH:mm');
            return date ? moment.utc(date, 'X').format(_format) : '';
        };
    }

    function formatDateStringUTC(geckoDates) {
        return function (date, format) {
            return geckoDates.printDateString(date, format);
        };
    }

    // function formatDateTimezone() {
    //     return function(date, timezone, format) {
    //         var _format = format || (Gecko.dateFormat && Gecko.dateFormat.longTime ? Gecko.dateFormat.longTime : 'MMM Do YYYY [at] HH:mm');
    //         return date ? moment(date, 'X').tz(GeckoUI.fixGmtTimezone(timezone)).format(_format) : '';
    //     }
    // }

    function formatDateOnlyUTC($filter) {
        return function (date, format) {
            if (typeof date != 'number') date = Number(date);
            if (date === undefined || !date || typeof date != 'number') return "";
            format = format !== undefined ? format : 'd MMM yyyy';
            return $filter('date')(date * 1000, format, 'UTC');
        };
    }

    angular.module('GeckoEngage').filter('unix2Format', unix2Format).filter('dateOnly', dateOnly).filter('datePlusTime', datePlusTime).filter('unixtodate', unixtodate).filter('datetounix', datetounix).filter('unix2date', unix2date).filter('secondsAsTime', secondsAsTime).filter('nicedate', nicedate).filter('humanize', humanize).filter('formatDate', formatDate).filter('formatDateUTC', formatDateUTC).filter('formatDateStringUTC', formatDateStringUTC)
    //.filter('formatDateTimezone' , formatDateTimezone)
    .filter('formatDateOnlyUTC', formatDateOnlyUTC);
})();