(function () {
    'use strict';

    function focusOn() {
        return function (scope, elem, attr) {
            scope.$on(attr.focusOn, function (e) {
                elem[0].value = '';
                elem[0].focus();
            });
        };
    }

    function tooltip() {
        return {
            restrict: 'A',
            link: function link(scope, element, attrs) {
                $(element).hover(function () {
                    // On mouseenter
                    $(element).tooltip('show');
                }, function () {
                    // On mouseleave
                    $(element).tooltip('hide');
                });
            }
        };
    }

    function editable($timeout, $parse) {
        // Overide default buttons of x-editable
        $.fn.editableform.buttons = '<button type="submit" class="btn btn-primary btn-sm editable-submit">' + '<i class="fa fa-check"></i>' + '</button>' + '<button type="button" class="btn btn-default btn-sm editable-cancel">' + '<i class="fa fa-times"></i>' + '</button>';

        return {
            restrict: "AE",
            scope: true,
            require: "ngModel",
            link: function link(scope, element, attrs, ngModel) {
                element.editable({
                    success: function success(response, newValue) {
                        $timeout(function () {
                            if (attrs.default && !newValue) {
                                newValue = $parse(attrs.default)(scope.$parent);
                                element.editable('setValue', newValue);
                            }
                            ngModel.$setViewValue(newValue);
                            ngModel.$render();
                        });
                    }
                });
                element.on('shown', function () {
                    var editablePopover = element.siblings('.editable-container')[0];
                    editablePopover.style.zIndex = attrs.zIndex || attrs.zIndex == 0 ? attrs.zIndex : '400'; //400 as default, this is under the top of page header but over the sidebar
                    $('body').append(editablePopover);
                });

                element.on('hidden', function () {
                    $timeout(function () {
                        return $(element).removeClass('editable-unsaved');
                    }, 500);
                });
                scope.$watch(attrs.ngModel, function (newValue) {
                    element.editable('setValue', newValue);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('focusOn', focusOn).directive('tooltip', tooltip).directive('editable', editable);
})();