function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

(function () {
    'use strict';

    function contactSearchService(portalRequestService) {
        var _this = {
            search: function search(_ref) {
                var keyword = _ref.keyword,
                    page = _ref.page,
                    perPage = _ref.perPage,
                    direction = _ref.direction,
                    orderBy = _ref.orderBy,
                    labels = _ref.labels,
                    labelOperator = _ref.labelOperator,
                    conditions = _ref.conditions,
                    conditionOperator = _ref.conditionOperator,
                    savedSearchId = _ref.savedSearchId;


                var params = {
                    page: page,
                    perPage: perPage,
                    conditions: [keyword && {
                        service: 'engage',
                        model: 'contactValues',
                        matchType: 'must',
                        queryType: 'contains',
                        value: keyword
                    }, labels && {
                        service: 'engage',
                        model: 'contactLabels',
                        matchType: 'must',
                        queryType: 'equals',
                        field: 'id',
                        value: labels.map(Number),
                        valueMatchCriteria: labelOperator
                    }, savedSearchId && {
                        service: 'engage',
                        model: 'contact',
                        matchType: 'must',
                        queryType: 'equals',
                        value: savedSearchId,
                        field: 'savedSearches'
                    }].concat(_toConsumableArray(conditions.map(function (condition) {
                        return conditionToQuery(condition, conditionOperator);
                    }))).filter(Boolean).flat()
                };

                if (direction && orderBy) {
                    params.sort = {
                        direction: direction,
                        orderBy: {
                            service: 'engage',
                            model: 'contactValues',
                            field: orderBy.id,
                            fieldType: orderBy.data_type === 'integer' ? 'number' : 'string'
                        }
                    };
                }

                // use mock data in local
                if (TokenManager.getRegion() === 'local') {
                    console.log('Built query:', params);
                    return Promise.resolve().then(function () {
                        return {
                            data: [],
                            pagination: {
                                current_page: null,
                                from: null,
                                to: null,
                                last_page: null,
                                next_page: null,
                                per_page: null,
                                total_items: null,
                                total_pages: null
                            }
                        };
                    });
                }

                return portalRequestService.post('/search/1', params).then(function (res) {
                    var current_page = parseInt(res.pagination.page);
                    var per_page = parseInt(res.pagination.perPage);
                    var total_pages = parseInt(res.pagination.totalPages);
                    var total_items = parseInt(res.pagination.total);
                    var uncapped_total = res.pagination.uncappedTotal ? parseInt(res.pagination.uncappedTotal) : null;

                    var from = (current_page - 1) * per_page + 1;
                    var to = current_page * per_page;

                    var last_page = current_page - 1 > 1 ? current_page - 1 : 1;
                    var next_page = current_page + 1 < total_pages ? current_page + 1 : total_pages;

                    var out = {
                        data: res.data,
                        pagination: {
                            current_page: current_page,
                            from: from,
                            to: to,
                            last_page: last_page,
                            next_page: next_page,
                            per_page: per_page,
                            total_items: total_items,
                            total_pages: total_pages,
                            uncapped_total: uncapped_total
                        }
                    };

                    return out;
                });
            },

            canUse: function canUse() {
                var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
                var conditions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
                var filterId = arguments[2];
                var keyword = params.search,
                    label = params.label;


                var isSupportUser = Gecko.account && Gecko.user && Gecko.user.related_group && Gecko.user.related_group.type === Gecko.Group.SUPER;
                var hasConditions = Array.isArray(conditions) && conditions.length;

                var hasQuickSearch = keyword || label;

                if (!hasConditions && !hasQuickSearch && !filterId) {
                    return false;
                }

                var isOnForAllUsers = Gecko.has(Gecko.Package.FEATURE_NEW_QUICKSEARCH);
                var isOnForSupportUsersOnly = isSupportUser && Gecko.has(Gecko.Package.FEATURE_NEW_QUICKSEARCH_SUPPORT_ONLY);
                return !!(isOnForAllUsers || isOnForSupportUsersOnly);
            },
            getOrder: function getOrder(order, fieldList) {
                if (!order) {
                    return null;
                }

                var idx = parseInt(order.replace('field_', '')) - 1;

                return fieldList[idx];
            },
            parseContacts: function parseContacts(contacts) {
                return contacts.map(function (contact) {
                    return {
                        id: contact.legacy.contact.id,
                        full_name: contact.legacy.contact.full_name,
                        email: contact.legacy.contact.email
                    };
                });
            },
            contactsToResult: function contactsToResult(contacts, labels, fieldList) {
                var fields = fieldList.map(function (field, idx) {
                    return {
                        key: 'field_' + (idx + 1),
                        id: field.id
                    };
                });

                return contacts.map(function (contact) {
                    return fields.reduce(function (result, field) {
                        return Object.assign({}, result, _defineProperty({}, field.key, !contact.legacy || !contact.legacy.contact || !contact.legacy.contact.values ? null : parseValue(contact.legacy.contact.values.find(function (value) {
                            return value.contact_field_id === field.id;
                        }))));
                    }, {
                        id: contact.legacy.contact.id,
                        labels: (contact.legacy.contact.labels || []).map(function (contactLabel) {
                            return labels.find(function (label) {
                                return label.id === contactLabel.id;
                            });
                        }).filter(Boolean)
                    });
                });
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').factory('contactSearchService', contactSearchService);
})();

var SearchOperators = {
    equals: 'equals',
    notEquals: 'notEquals',
    contains: 'contains',
    notContains: 'notContains',
    lessThan: 'lessThan',
    greaterThan: 'greaterThan',
    blank: 'blank',
    notBlank: 'notBlank'
};

var ValueMatchCriteria = {
    any: 'any',
    all: 'all',
    notAny: 'notAny',
    notAll: 'notAll'
};

var MatchTypes = {
    must: 'must',
    should: 'should',
    mustNot: 'mustNot'
};

var searchOperatorFromCondition = function searchOperatorFromCondition(type) {
    switch (type) {
        case '=':
        case '==':
            return SearchOperators.equals;
        case '!=':
        case '!==':
            return SearchOperators.notEquals;
        case '%':
        case 'C':
            return SearchOperators.contains;
        case '!%':
            return SearchOperators.notContains;
        case '<':
            return SearchOperators.lessThan;
        case '>':
            return SearchOperators.greaterThan;
        case '--':
            return SearchOperators.blank;
        case '!--':
            return SearchOperators.notBlank;
        default:
            throw new Error('Unknown condition type: ' + type);
    }
};

var valueMatchCriteriaFromCondition = function valueMatchCriteriaFromCondition(type) {
    switch (type) {
        case '=':
            return ValueMatchCriteria.any;
        case '!=':
            return ValueMatchCriteria.notAny;
        case '==':
            return ValueMatchCriteria.all;
        case '!==':
            return ValueMatchCriteria.notAll;
        default:
            throw new Error('Could not get match type from condition type: ' + type);
    }
};

var conditionModelToSearchModel = function conditionModelToSearchModel(model) {
    switch (model) {
        case Gecko.Condition.MODEL_CONTACT_FIELD:
            return 'contactValues';
        case Gecko.Condition.MODEL_LABEL:
            return 'contactLabels';
        case Gecko.Condition.MODEL_IMPORT:
            return 'imports';
        case Gecko.Condition.MODEL_ATTENDANCE:
            return 'attendances';
        case Gecko.Condition.MODEL_RESPONSE:
            return 'contactResponses';
        case Gecko.Condition.MODEL_CALL_OUTCOME:
            return 'contactCallCampaigns';
        case Gecko.Condition.MODEL_CALL_COUNT:
            return 'contactCallCounts';
        case Gecko.Condition.MODEL_EMAIL_OUTCOME:
            return 'contactEmailBroadcasts';
        case Gecko.Condition.MODEL_SMS_OUTCOME:
            return 'contactSMSBroadcasts';
        case Gecko.Condition.MODEL_ENROLMENT:
            return 'contactEnrolments';
        default:
            return 'contact';
    }
};

var searchFieldFromCondition = function searchFieldFromCondition(condition) {
    switch (condition.model) {
        case Gecko.Condition.MODEL_CONTACT_FIELD:
            return condition.contact_field_id;
        case Gecko.Condition.MODEL_LABEL:
            return 'id';
        case Gecko.Condition.MODEL_IMPORT:
            return 'id';
        case Gecko.Condition.MODEL_CALL_OUTCOME:
        case Gecko.Condition.MODEL_EMAIL_OUTCOME:
        case Gecko.Condition.MODEL_SMS_OUTCOME:
            return condition.campaign_id || 'outcomeId';
        case Gecko.Condition.MODEL_CALL_COUNT:
            return condition.campaign_id || 'campaignId';
        case Gecko.Condition.MODEL_ATTENDANCE:
            return condition.event_id;
        case Gecko.Condition.MODEL_ENROLMENT:
            return 'organisation_id';
        case Gecko.Condition.MODEL_CONSENT:
            return 'consents';
        case Gecko.Condition.MODEL_FILTER:
            return 'savedSearches';
    }
    return condition.property ? condition.property : condition.contact_field_id ? condition.contact_field_id : null;
};

var getSimplestValue = function getSimplestValue(value) {
    var toNumber = function toNumber(value) {
        var number = Number(value);
        return isNaN(number) || /^0+/.test(value) ? value : number;
    };

    return Array.isArray(value) ? value.map(toNumber) : toNumber(value);
};

var getFieldType = function getFieldType(condition) {

    switch (condition.model) {
        case Gecko.Condition.MODEL_CONTACT_FIELD:
            return ['integer', 'timestamp'].includes(condition.fieldType) ? 'number' : 'string';
        case Gecko.Condition.MODEL_EMAIL_OUTCOME:
        case Gecko.Condition.MODEL_SMS_OUTCOME:
        case Gecko.Condition.MODEL_CALL_OUTCOME:
        case Gecko.Condition.MODEL_ATTENDANCE:
        case Gecko.Condition.MODEL_ENROLMENT:
        case Gecko.Condition.MODEL_CALL_COUNT:
            return 'number';
        case Gecko.Condition.MODEL_CONSENT:
            return 'number';
        default:
            return;
    }
};

var getCallCountsZeroCondition = function getCallCountsZeroCondition(condition) {

    var other = JSON.parse(JSON.stringify(condition));
    other.value = condition.campaign_id;
    other.campaign_id = null;
    other.type = condition.type === '=' ? '!=' : '=';
    other.fieldType = 'integer';

    return conditionToQuery(other, 'ALL');
};

var isCallCountZero = function isCallCountZero(condition) {

    if (condition.model !== Gecko.Condition.MODEL_CALL_COUNT) {
        return false;
    }

    if (['=', '!='].includes(condition.type) && parseInt(condition.value) === 0) {
        return true;
    }

    return false;
};

var getCallCountsLessThanOneCondition = function getCallCountsLessThanOneCondition(condition) {

    var other = JSON.parse(JSON.stringify(condition));
    other.value = condition.campaign_id;
    other.campaign_id = null;
    other.type = '!=';
    other.fieldType = 'integer';

    return conditionToQuery(other, 'ALL');
};

var isCallCountLessThanOne = function isCallCountLessThanOne(condition) {

    if (condition.model !== Gecko.Condition.MODEL_CALL_COUNT) {
        return false;
    }

    if (condition.type === '<' && parseInt(condition.value) === 1) {
        return true;
    }

    return false;
};

var getCallCountsLessThanConditions = function getCallCountsLessThanConditions(query, condition) {

    var other = JSON.parse(JSON.stringify(condition));
    other.value = condition.campaign_id;
    other.campaign_id = null;
    other.type = '!=';
    other.fieldType = 'integer';

    query.matchType = MatchTypes.should;

    return [query, conditionToQuery(other, 'ANY')];
};

var isCallCountLessThan = function isCallCountLessThan(condition) {

    if (condition.model !== Gecko.Condition.MODEL_CALL_COUNT) {
        return false;
    }

    if (condition.type === '<' && parseInt(condition.value) > 1) {
        return true;
    }

    return false;
};

var createRangeQueryFromExactTimestamp = function createRangeQueryFromExactTimestamp(condition, timestamp, queryType) {
    var date = new Date(parseInt(timestamp) * 1000);
    date.setUTCHours(0);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    var startRange = parseInt((date.getTime() / 1000).toFixed(0));

    date.setUTCHours(23);
    date.setUTCMinutes(59);
    date.setUTCSeconds(59);
    var endRange = parseInt((date.getTime() / 1000).toFixed(0));

    var isEqualsQuery = queryType === SearchOperators.equals;

    //clone by value
    var from = JSON.parse(JSON.stringify(condition));
    //For equals, we want to include 00:00:00 on the specified day rather than being greater or less than it
    from.value = isEqualsQuery ? startRange - 1 : startRange;
    from.type = isEqualsQuery ? '>' : '<';
    from.fieldType = 'integer';

    var to = JSON.parse(JSON.stringify(condition));
    //For equals, we want to include 23:59:59 on the specified day rather than being greater or less than it
    to.value = isEqualsQuery ? endRange + 1 : endRange;
    to.type = isEqualsQuery ? '<' : '>';
    to.fieldType = 'integer';

    var conditionOperator = isEqualsQuery ? 'ALL' : 'ANY';

    var conditions = [conditionToQuery(from, conditionOperator), conditionToQuery(to, conditionOperator)];

    if (!isEqualsQuery) {
        //empty/null could be considered out of range too
        var blankQuery = JSON.parse(JSON.stringify(condition));
        blankQuery.value = null;
        blankQuery.type = '--';
        blankQuery.fieldType = 'integer';
        conditions.push(conditionToQuery(blankQuery));
    }

    return conditions;
};

var conditionToQuery = function conditionToQuery(condition, conditionOperator) {
    var model = condition.model,
        type = condition.type,
        value = condition.value,
        fieldType = condition.fieldType,
        import_id = condition.import_id,
        in_filter_ulid = condition.in_filter_ulid;


    var isLabelModel = model === Gecko.Condition.MODEL_LABEL;
    var isConsentModel = model === Gecko.Condition.MODEL_CONSENT;
    var valueToUse = function valueToUse() {
        switch (model) {
            case Gecko.Condition.MODEL_IMPORT:
                return import_id;
            case Gecko.Condition.MODEL_FILTER:
                return in_filter_ulid;
            default:
                return value;
        }
    };

    var formattedValue = getSimplestValue(valueToUse());

    if (isConsentModel) {
        type = type + '=';
    }

    var valueIsArray = Array.isArray(formattedValue);
    var queryType = searchOperatorFromCondition(type);

    if (isCallCountZero(condition)) {
        return getCallCountsZeroCondition(condition);
    }

    if (isCallCountLessThanOne(condition)) {
        return getCallCountsLessThanOneCondition(condition);
    }

    if (fieldType === 'timestamp' && (queryType === SearchOperators.equals || queryType === SearchOperators.notEquals)) {
        return createRangeQueryFromExactTimestamp(condition, formattedValue, queryType);
    }

    var query = {
        service: 'engage',
        model: conditionModelToSearchModel(model),
        value: formattedValue,
        field: searchFieldFromCondition(condition),
        queryType: isLabelModel || isConsentModel ? SearchOperators.equals : queryType,
        matchType: conditionOperator === 'ALL' ? MatchTypes.must : MatchTypes.should,
        fieldType: getFieldType(condition)
    };

    if (valueIsArray) {
        query.valueMatchCriteria = isLabelModel || isConsentModel ? valueMatchCriteriaFromCondition(type) : ValueMatchCriteria.any;
    }

    if (isCallCountLessThan(condition)) {
        return getCallCountsLessThanConditions(query, condition);
    }

    return query;
};

var parseValue = function parseValue(value) {
    if (!value) {
        return null;
    } else if (value.numeric !== null) {
        return value.numeric;
    }

    return value.safe_db;
};