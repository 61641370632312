(function () {
    'use strict';

    function FormDesignerCtrl(Gecko, $scope, $state, $stateParams, $formBuilderService, data, form, formIncludesDesigner, formRfieldsDesigner, module) {
        var ctrl = this;
        ctrl.form = form;
        ctrl.pages = ctrl.form.pages;

        //Set data on service (to share across modals + components)
        $formBuilderService.setData(data);
        $formBuilderService.setModule(ctrl.form.module);

        var moduleTitle = $formBuilderService.getModuleTitle(ctrl.form.module);

        //Build field to integartion mapping
        if ($formBuilderService.data.integrations && $formBuilderService.data.integrations.length) {
            $formBuilderService.integrationFieldsMap = $formBuilderService.buildIntegrationFields($formBuilderService.data.integrations, $formBuilderService.getFieldsArray(ctrl.pages));
        }

        //Save form (function)
        ctrl.saveForm = function () {
            var _form = angular.copy(ctrl.form);

            // Process integration field mapping
            if ($formBuilderService.data.integrations && $formBuilderService.data.integrations.length) {
                $formBuilderService.includeIntegrationFields(_form);
            }

            // Remove UUIDs from condition objs
            _form.conditions = _form.conditions.map(function (c) {
                if (!Number(c.id)) delete c.id;
                return c;
            });

            // Final Field Check
            $formBuilderService.finalFieldCheck(_form);

            var endPoint = '/forms/' + ($stateParams.form_id != 'new' ? _form.id : 0);

            new Gecko.Form().include(formIncludesDesigner).rfields(formRfieldsDesigner).call(endPoint, 'POST', _form, true).then(function (form) {
                ctrl.form = form.form;
                ctrl.pages = ctrl.form.pages;

                // Rebuild field to integartion mapping
                if ($formBuilderService.data.integrations && $formBuilderService.data.integrations.length) {
                    $formBuilderService.integrationFieldsMap = $formBuilderService.buildIntegrationFields($formBuilderService.data.integrations, $formBuilderService.getFieldsArray(ctrl.pages));
                }

                //Redirect if new form
                if ($stateParams.form_id == 'new') {
                    GeckoUI.messenger.success(moduleTitle + ' created');
                    $state.go($state.$current.name, { form_id: ctrl.form.id }, { reload: true });
                } else {
                    GeckoUI.messenger.success(moduleTitle + ' updated');
                }

                // Fire Intercom Event
                $formBuilderService.triggerIntercomEvent(ctrl.form, 'New ' + moduleTitle + ' Save Success');
            }).catch(function (err) {
                //No GeckoJS formatting as we are not using save method
                GeckoUI.messenger.error(err);
                // Fire Intercom Event
                $formBuilderService.triggerIntercomEvent(ctrl.form, 'New ' + moduleTitle + ' Save Error', err.message);
            });
        };

        //Save form (listener)
        $scope.$on('saveForm:' + $state.$current.name, ctrl.saveForm);
    }

    angular.module('GeckoEngage').controller('FormDesignerCtrl', FormDesignerCtrl);
})();