(function () {
    'use strict';

    function $geckoChannel($rootScope, $state) {

        var _this = {},
            _map = {},
            _states = {},
            _values = {};

        // Update scope(s) utility
        var update = function update(key) {
            var scopes = _map[key];
            for (var scope in scopes) {
                scopes[scope][key] = _values[key];
            }
        };

        _this.get = function (key) {
            // Check value is not null
            if (!_values[key]) return '';
            return angular.copy(_values[key]);
        };

        _this.set = function (key, value) {
            // Check key has been mapped
            if (!_map[key]) return false;
            // Update value
            _values[key] = value;
            // Update scopes
            update(key);
        };

        _this.register = function (state, vars, scope) {
            // Check for all params
            if (!state || !vars || !scope) return false;
            if (!angular.isArray(vars)) vars = [vars];

            // Map scopes
            for (var i = 0; i < vars.length; i++) {
                // Build new mapping and value
                if (!_map[vars[i]]) _map[vars[i]] = {};
                if (!_values[vars[i]]) _values[vars[i]] = null;
                // Set scope
                _map[vars[i]][state] = scope;
            }

            // Reference state
            _states[state] = true;
        };

        _this.unregister = function (state) {
            if (!state) return false;
            for (var channel in _map) {
                if (_map[channel][state]) delete _map[channel][state];
            }
            // Remove state reference
            delete _states[state];
        };

        // Unregister old states
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if (_states[fromState.name]) {
                _this.unregister(fromState.name);
            }
        });

        return _this;
    }

    angular.module('GeckoEngage').service('$geckoChannel', $geckoChannel);
})();